import { Box } from '@mui/material';
import { useSweepFieldsLabels } from '../../../../../../../sweep-fields/useSweepFieldsLabels';
import { humanReadableCriterion } from '../../../../../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { Typography, colors } from '@sweep-io/sweep-design';
import { TruncatedTextTooltip } from '../../../../../../common/TruncatedTextTooltip';

interface CriterionComponentProps {
  criterion: SweepCriterion;
  idx: number;
}

export const CriterionComponent = ({ criterion, idx }: CriterionComponentProps) => {
  const boxHeight = '37px';
  const boxPadding = '7px 8px';
  const { enrichCriteriaWithLabels: populateCriteriaWithLabels } = useSweepFieldsLabels();
  return (
    <Box
      sx={{
        background: colors.grey[100],
        borderRadius: '6px',
        border: '1px solid',
        borderColor: colors.grey[300],
        height: boxHeight,
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          height: boxHeight,
          textAlign: 'center',
          width: '29px',
          borderRight: '1px solid',
          borderColor: colors.grey[300],
          padding: boxPadding,
        }}
      >
        <Typography variant="body">{idx + 1}</Typography>
      </Box>
      <Box
        sx={{
          height: boxHeight,
          padding: boxPadding,
          display: 'flex',
          alignItems: 'center',
          overflow: 'hidden',
        }}
      >
        <TruncatedTextTooltip variant="body">
          {humanReadableCriterion(populateCriteriaWithLabels(criterion))}
        </TruncatedTextTooltip>
      </Box>
    </Box>
  );
};
