import { Typography, colors } from '@sweep-io/sweep-design';
import { Sort } from '../Sort';
import { Box } from '@mui/material';
import { useSortElements } from '../useSortElements';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import pluralize from 'pluralize';
import { DownloadAsCsvButton } from '../../shared/csv/DownloadAsCsvButton';
import { getColumns } from '../expanded-mode/tableColumns';
import { getCsvHeaders } from '../../shared/csv/utils';
import { ConfigurationItem } from '../../dependencies/DependenciesTypes';
import { useSelector } from 'react-redux';
import { selectCrmOrg } from '../../../pages/environments/environmentsReducer';

interface ElementSubheaderProps {
  items: ConfigurationItem[];
  objectLabel?: string;
  activeTab: DocumentationTabTypes;
  isManagePackageInstalled?: boolean;
  crmOrgId: string;
}

export const ElementSubheader = ({
  items,
  isManagePackageInstalled = false,
  objectLabel,
  activeTab,
  crmOrgId,
}: ElementSubheaderProps) => {
  const crmOrg = useSelector(selectCrmOrg(crmOrgId));
  const { sortedItems, _sortOptions, _sortKey, onSortChange } = useSortElements({
    items,
    activeTab,
    isManagePackageInstalled,
  });

  const label =
    DocumentationTabTypes.CPQ_DATA === activeTab
      ? objectLabel + pluralize(' Record', sortedItems?.length)
      : activeTab;

  const columns = getColumns(activeTab);
  const csvHeaders = getCsvHeaders(columns);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pl: 2,
        pr: 3,
        pb: 2,
      }}
    >
      <Box display="flex" alignItems="center" gap={0.5}>
        <Typography variant="body-medium" color={colors.grey[700]}>
          {sortedItems?.length ?? ''} {label}
        </Typography>

        {activeTab !== DocumentationTabTypes.CARDS_LIST && (
          <DownloadAsCsvButton
            fileName={`${crmOrg?.name}_${label}_Export_from_Sweep`}
            headers={csvHeaders}
            rows={sortedItems}
          />
        )}
      </Box>

      <Sort sortKey={_sortKey} onSortChange={onSortChange} options={_sortOptions} />
    </Box>
  );
};
