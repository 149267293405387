import * as React from "react";
const SvgI = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M13.7381 5.99585C13.7381 5.60925 13.4247 5.29585 13.0381 5.29585H10.9629C10.5763 5.29585 10.2629 5.60925 10.2629 5.99585V18.0041C10.2629 18.3907 10.5763 18.7041 10.9629 18.7041H13.0381C13.4247 18.7041 13.7381 18.3907 13.7381 18.0041V5.99585Z", fill: "#9462FF", stroke: "white", style: {
  fill: "#9462FF",
  fill: "color(display-p3 0.5807 0.3833 1.0000)",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.4, strokeLinejoin: "round" }));
export default SvgI;
