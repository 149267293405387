import * as React from "react";
const SvgD = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#DFF8D5", style: {
  fill: "#DFF8D5",
  fill: "color(display-p3 0.8745 0.9725 0.8353)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.35078 18.0041C6.35078 18.3907 6.66418 18.7041 7.05078 18.7041H11.12C12.4311 18.7041 13.597 18.4454 14.5897 17.8956C15.5847 17.3466 16.349 16.5541 16.8727 15.5338C17.3997 14.5148 17.6482 13.3247 17.6482 11.9883C17.6482 10.6559 17.4017 9.46964 16.8786 8.45448C16.3559 7.44005 15.5989 6.65145 14.6157 6.1058C13.635 5.55546 12.4881 5.29585 11.2021 5.29585H7.05078C6.66418 5.29585 6.35078 5.60925 6.35078 5.99585V18.0041ZM13.7328 9.99488L13.7335 9.99643C13.9591 10.4821 14.0905 11.1356 14.0905 11.9883C14.0905 12.84 13.9594 13.4988 13.7324 13.9941C13.5103 14.475 13.1913 14.8183 12.7709 15.051C12.3421 15.2844 11.7684 15.422 11.0145 15.422H9.92611V8.57801H11.079C11.8073 8.57801 12.3622 8.71477 12.7787 8.94685L12.7787 8.94688L12.7839 8.94972C13.1938 9.17354 13.5096 9.51145 13.7328 9.99488Z", fill: "#59CE2F", stroke: "white", style: {
  fill: "#59CE2F",
  fill: "color(display-p3 0.3476 0.8083 0.1836)",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.4, strokeLinejoin: "round" }));
export default SvgD;
