import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import type { UserDefinedRoleGroupsInterface } from '@server/role-group-interface';

export const useRolesApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_roleGroups: useCallback(async () => {
      const response = await sweepApi.get(`/roles/groups`);
      return response.data as UserDefinedRoleGroupsInterface[];
    }, [sweepApi]),

    post_roleGroup: useCallback(
      async (
        roleGroup: Pick<UserDefinedRoleGroupsInterface, 'name' & 'description' & 'roleLevels'>,
      ) => {
        const response = await sweepApi.post(`/roles/group`, roleGroup);
        return response.data as UserDefinedRoleGroupsInterface;
      },
      [sweepApi],
    ),

    put_roleGroup: useCallback(
      async (
        roleGroup: Pick<
          UserDefinedRoleGroupsInterface,
          'id' | 'name' | 'description' | 'roleLevels'
        >,
      ) => {
        const response = await sweepApi.put(`/roles/group/${roleGroup.id}`, roleGroup);
        return response.data as UserDefinedRoleGroupsInterface;
      },
      [sweepApi],
    ),

    delete_roleGroup: useCallback(
      async (roleGroupId: string) => {
        const response = await sweepApi.delete(`/roles/group/${roleGroupId}`);
        return response.data as UserDefinedRoleGroupsInterface;
      },
      [sweepApi],
    ),
  };
};
