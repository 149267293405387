import { AdvancedFilterItem } from '../../common/advanced-filter/AdvancedFilter';

export const getAdvanceFilterItemStructure = ({
  items,
  valueKey,
  labelKey,
  decorationFunc,
}: {
  items: any;
  valueKey: string;
  labelKey: string;
  decorationFunc?: (item: any) => void;
}): AdvancedFilterItem[] =>
  items.map((item: any) => ({
    label: item[labelKey],
    value: item[valueKey],
    decoration: decorationFunc && decorationFunc(item),
  }));
