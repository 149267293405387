import { Box, CircularProgress } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { TimeAgo } from '../../../../timeAgo';
import StyledTooltip from '../../../common/StyledTooltip';
import { Clock as SavedIcon, Warning as ErrorIcon } from '@sweep-io/sweep-design/dist/icons';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { AxiosError } from 'axios';

const timeAgo = new TimeAgo('en-US');

interface AutoSaveIndicatorProps {
  isAutoSaving?: boolean;
  error?: { code: string } | AxiosError;
  lastSave?: Date;
  readonly?: boolean;
}

enum AutoSaveIndicatorStates {
  SAVED,
  SAVING,
  ERROR,
}

export const AutoSaveIndicator = ({
  isAutoSaving,
  lastSave,
  error,
  readonly,
}: AutoSaveIndicatorProps) => {
  const lastEditedLabel = lastSave ? `Last Edited ${timeAgo.format(lastSave, 'round-minute')}` : '';

  let componentState = error ? AutoSaveIndicatorStates.ERROR : AutoSaveIndicatorStates.SAVED;
  componentState = isAutoSaving ? AutoSaveIndicatorStates.SAVING : componentState;

  const errorsTooltipAndLabel =
    error?.code === 'ERR_NETWORK'
      ? {
          tooltip: 'Changes will be automatically saved once network connection is restored',
          label: ' Network Problem',
        }
      : {
          tooltip: 'Error occurred. Please refresh',
          label: 'Failed saving',
        };

  return (
    <StyledTooltip
      title={error ? errorsTooltipAndLabel.tooltip : lastEditedLabel}
      disableHoverListener={readonly}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        {componentState === AutoSaveIndicatorStates.SAVING && (
          <>
            <CircularProgress size="10px" />
            <Typography variant="body" color={colors.grey[800]}>
              Saving
            </Typography>
          </>
        )}
        {componentState === AutoSaveIndicatorStates.ERROR && (
          <>
            <ErrorIcon color={colors.blush[500]} />
            <Typography variant="body" color={colors.grey[800]}>
              {errorsTooltipAndLabel.label}
            </Typography>
          </>
        )}
        {componentState === AutoSaveIndicatorStates.SAVED && (
          <>
            <SavedIcon />
            <Typography variant="body" color={colors.grey[800]}>
              {(!readonly || !lastSave)
                ? 'Saved'
                : `Last saved at ${humanizeDate({
                    dateOrTimestamp: lastSave.toString(),
                  })}`}
            </Typography>
          </>
        )}
      </Box>
    </StyledTooltip>
  );
};
