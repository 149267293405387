import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { HighlightEntities } from '../../funnel-map-canvas/funnelMapCanvasTypes';
import { getRecordTypeIdentifier, getStepsFromAutomation } from '../../Automations/helper';
import { CanvasPillTypes } from '../../multi-canvas/canvasTypes';
import { selectAssignments } from '../../../reducers/global/assignmentReducers';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { AutomationFormVariant } from '../../../types/enums/AutomationFormVariant';
import { selectAutomations } from '../../../reducers/global/automationReducers';
import { selectAlerts } from '../../../reducers/global/alertsReducers';
import { selectDedupMatching } from '../../../reducers/global/dedupMatchingReducers';
import { selectLeftPanelHoveredItem } from '../../../reducers/leftPanelReducer';
import { selectScheduledAssignments } from '../../../reducers/global/scheduledAssignmentReducers';

const getByVariant = (automationVariant?: AutomationFormVariant) => {
  switch (automationVariant) {
    case AutomationFormVariant.ASSIGNMENT_RULES: {
      return {
        selectItems: selectAssignments,
        singlePillType: CanvasPillTypes.assignmentRule,
        groupPillType: CanvasPillTypes.groupAssignmentRule,
      };
    }
    case AutomationFormVariant.PLAYBOOK_ALERT: {
      return {
        selectItems: selectAlerts,
        singlePillType: CanvasPillTypes.playbookAlert,
        groupPillType: CanvasPillTypes.groupPlaybookAlert,
      };
    }
    case AutomationFormVariant.DEDUP_MATCHING: {
      return {
        selectItems: selectDedupMatching,
        groupPillType: CanvasPillTypes.groupMatching,
        //TODO differentiate between matching and dedup. Currently, hover on dedup OR matching item is highlighting the MATCHING pill
      };
    }
    case AutomationFormVariant.SCHEDULED_ASSIGNMENTS: {
      return {
        selectItems: selectScheduledAssignments,
        singlePillType: CanvasPillTypes.scheduledAssignment,
        groupPillType: CanvasPillTypes.groupScheduledAssignment,
      };
    }
    case AutomationFormVariant.AUTOMATIONS:
    default:
      return {
        selectItems: selectAutomations,
        singlePillType: CanvasPillTypes.automation,
        groupPillType: CanvasPillTypes.groupAutomation,
      };
  }
};

const useEntitiesToHighlight = (automationVariant?: AutomationFormVariant) => {
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const activeHoveredItem = useSelector(selectLeftPanelHoveredItem);
  const byVariant = useMemo(() => getByVariant(automationVariant), [automationVariant]);
  const automationsArray = useSelector(byVariant.selectItems);

  const hoveredAutomation = useMemo(() => {
    if (!activeHoveredItem.automationId) {
      return undefined;
    }

    return automationsArray?.find(
      (automation) => automation.automationId === activeHoveredItem.automationId,
    );
  }, [activeHoveredItem.automationId, automationsArray]);

  const allFunnelAndRecordTypeIDsForObject = useMemo(() => {
    if (!hoveredAutomation) {
      return [];
    }
    const relevantFunnelIds = Object.values(funnelsData)
      .filter((funnel) => funnel.recordType.objectName === hoveredAutomation.objectName)
      .map((funnel) => funnel.id);
    const relevantRecordTypeIds = Object.entries(recordTypesData)
      .filter(([, rt]) => rt.objectName === hoveredAutomation.objectName)
      .map(([recordTypeId]) => recordTypeId);
    return relevantFunnelIds.concat(relevantRecordTypeIds);
  }, [funnelsData, hoveredAutomation, recordTypesData]);

  const entitiesToHighlight: HighlightEntities[] = useMemo(() => {
    if (!hoveredAutomation) {
      return [];
    }
    // step-level automation
    if (hoveredAutomation?.automationDetails.funnelId) {
      return byVariant && byVariant.singlePillType
        ? [
            {
              funnelOrRecordTypeID: hoveredAutomation.automationDetails.funnelId,
              stepId: getStepsFromAutomation(hoveredAutomation.automationDetails, '_stageId')[0],
              type: 'pill',
              pillType: byVariant.singlePillType,
            },
          ]
        : [];
    }

    // record-type-level automation
    if (hoveredAutomation?.automationDetails.recordTypes) {
      return byVariant
        ? hoveredAutomation.automationDetails.recordTypes.map((recordType) => ({
            funnelOrRecordTypeID: getRecordTypeIdentifier(
              recordType.name,
              hoveredAutomation.objectName,
            ),
            type: 'pill',
            pillType: byVariant.groupPillType,
          }))
        : [];
    }

    // object-level automation
    return byVariant
      ? allFunnelAndRecordTypeIDsForObject.map((funnelOrRecordTypeID) => ({
          funnelOrRecordTypeID,
          type: 'pill',
          pillType: byVariant.groupPillType,
        }))
      : [];
  }, [byVariant, hoveredAutomation, allFunnelAndRecordTypeIDsForObject]);

  return { entitiesToHighlight };
};

export default useEntitiesToHighlight;
