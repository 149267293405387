import { useSelector } from 'react-redux';
import { AssignmentGroupConditionalLimitDetails } from '../../../../reducers/assignmentGroupTypes';
import { SweepObjectSelector } from '../../../common/sweep-object-field-selectors/SweepObjectSelector';
import { Box } from '@mui/material';
import { SweepConditionRuleBuilder } from '../../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { useCallback, useMemo } from 'react';
import { useSweepFieldsLabels } from '../../../../sweep-fields/useSweepFieldsLabels';
import { validateSweepCriteria } from '../../../common/rule-builder/validateSweepCriteria';
import { Tooltip, Typography } from '@sweep-io/sweep-design';
import { Info } from '@sweep-io/sweep-design/dist/icons';
import { selectCrmOrgAutomations } from '../../../../reducers/global/automationReducers';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { selectCrmOrgAssignments } from '../../../../reducers/global/assignmentReducers';
import { selectCrmOrgAlerts } from '../../../../reducers/global/alertsReducers';
import { selectCrmOrgMatching } from '../../../../reducers/global/dedupMatchingReducers';
import { DeployStatusForTable } from '../../../../types/enums/DeployStatusForTable';
import uniq from 'lodash/uniq';

interface ConditionalLimitPanelProps {
  onChange: (limitDetails: DeepPartial<AssignmentGroupConditionalLimitDetails>) => void;
  limitDetails?: DeepPartial<AssignmentGroupConditionalLimitDetails>;
  children?: JSX.Element | null;
}

const useSelectAutomationsObjectNames = () => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId) || '';
  const automations = useSelector(selectCrmOrgAutomations(crmOrgId)) ?? [];
  const assignments = useSelector(selectCrmOrgAssignments(crmOrgId)) ?? [];
  const alerts = useSelector(selectCrmOrgAlerts(crmOrgId)) ?? [];
  const dedupedAutomations = useSelector(selectCrmOrgMatching(crmOrgId)) ?? [];
  const allAutomations = [...automations, ...assignments, ...alerts, ...dedupedAutomations].filter(
    (automation) => automation.status === DeployStatusForTable.Deployed,
  );

  const objectNames = uniq(allAutomations.map((automation) => automation.objectName));

  return objectNames;
};

const promotedObjectTypes = ['Lead', 'Account', 'Opportunity', 'Contact', 'Case'];

export const ConditionalLimitPanel = ({
  onChange,
  limitDetails,
  children,
}: ConditionalLimitPanelProps) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId) || '';
  const { enrichCriteriaWithLabels, removeLabelsFromCriteria } = useSweepFieldsLabels();

  const automationsObjectNames = useSelectAutomationsObjectNames();

  const objectsFilterBy = useCallback(
    (objectType: ObjectTypeName) =>
      promotedObjectTypes.includes(objectType.objectType) ||
      automationsObjectNames?.includes(objectType.objectType),
    [automationsObjectNames],
  );

  const onObjectSelectorChange = useCallback(
    (objectType: string) => {
      onChange({
        ...limitDetails,
        object: objectType,
        condition: {
          criteria: [],
          criteriaLogic: '',
        },
      });
    },
    [limitDetails, onChange],
  );

  const onConditionRuleChange = useCallback(
    (newSweepCondition: SweepConditionWithLabels) => {
      onChange({
        ...limitDetails,
        condition: {
          criteria: newSweepCondition.criteria.map(removeLabelsFromCriteria),
          criteriaLogic: newSweepCondition.criteriaLogic,
        },
      });
    },
    [limitDetails, onChange, removeLabelsFromCriteria],
  );

  const currentFilter: SweepConditionWithLabels | undefined = useMemo(() => {
    let sweepConditionWithLabels: SweepConditionWithLabels | undefined;
    const sweepCondition = {
      criteria: [],
      criteriaLogic: '',
      ...limitDetails?.condition,
    } as SweepCondition;

    if (sweepCondition) {
      sweepConditionWithLabels = {
        criteria: sweepCondition.criteria?.map(enrichCriteriaWithLabels),
        criteriaLogic: sweepCondition.criteriaLogic,
      };
    }

    return sweepConditionWithLabels;
  }, [enrichCriteriaWithLabels, limitDetails?.condition]);

  const renderRuleBuilder = () => {
    if (!limitDetails?.object) {
      return null;
    }

    return (
      <Box
        sx={{
          '> div': {
            padding: '0px ',
          },
          position: 'relative',
        }}
      >
        <SweepConditionRuleBuilder
          onChange={onConditionRuleChange}
          crmOrgId={crmOrgId}
          objectType={limitDetails.object}
          sweepCondition={currentFilter}
          disableLookupItemsResolve
          hideSelectValuesFromRecord
          headerRowComponent={
            <Box sx={{ position: 'absolute', top: 4 }}>
              <Typography variant="body">Filters:</Typography>
            </Box>
          }
        />
      </Box>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <Box display="flex" gap={'12px'} alignItems="center">
        {children}
        <Box sx={{ minWidth: 159 }}>
          <SweepObjectSelector
            value={limitDetails?.object}
            crmOrgId={crmOrgId}
            onChange={onObjectSelectorChange}
            fullWidth
            filterBy={objectsFilterBy}
          />
        </Box>
        <Tooltip
          title={
            'Note that assignment limit would be applied only when routing record of this object'
          }
        >
          <Info />
        </Tooltip>
      </Box>
      {renderRuleBuilder()}
    </Box>
  );
};

export const validateConditionalLimitPanel = (
  limitDetails?: Partial<AssignmentGroupConditionalLimitDetails>,
) => {
  if (limitDetails?.condition?.criteria && limitDetails.object) {
    return validateSweepCriteria(limitDetails.condition.criteria, false).length === 0;
  }
};
