import { Box } from '@mui/material';
import classNames from 'classnames';
import { Typography, colors } from '@sweep-io/sweep-design';

export const GATE_SIZE = 26;

const CanvasGate = ({
  label,
  dashed,
  color,
  highlighted,
  onClick,
}: {
  label: string;
  dashed?: boolean;
  color: string;
  highlighted?: boolean;
  onClick: () => any;
  readonly?: boolean;
}) => (
  <Box
    sx={{
      border: '2px solid',
      borderColor: color,
      backgroundColor: color,
      borderStyle: dashed ? 'dashed' : 'solid',
      borderRadius: '50%',
      width: GATE_SIZE,
      height: GATE_SIZE,
      '&.highlighted': {
        borderColor: colors.blue[500],
      },
      '&:hover': {
        cursor: 'pointer',
      },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    className={classNames({ highlighted })}
    onClick={onClick}
  >
    <Typography variant="caption-bold">{label}</Typography>
  </Box>
);

export default CanvasGate;
