import { useEffect, useState } from 'react';
import { useFunnelMapFlowPageApi } from '../useFunnelMapFlowPageApi';
import usePermission from '../../../common/permissions/usePermission';
import { simpleHash } from '../../../../third-party/simpleHash';
import { telemetry } from '../../../../telemetry';
import { useAutoSaveContext } from './AutoSaveContext';
import isEqual from 'lodash/isEqual';
import _ from 'lodash';

type FunnelSavePayload = Pick<Funnel, 'id' | 'funnelDetails' | 'name' | 'description'>;

const getSavableFunnelData = ({
  id,
  name,
  description,
  funnelDetails,
}: Funnel): FunnelSavePayload => ({
  id,
  name,
  description,
  funnelDetails,
});

const omitNotChangedProperties = ({
  previousVersion,
  currentVersion,
}: {
  previousVersion: FunnelSavePayload;
  currentVersion: FunnelSavePayload;
}) => {
  Object.keys(currentVersion).forEach((key) => {
    const funnelSavePayloadKey = key as keyof FunnelSavePayload;

    if (
      isEqual(currentVersion[funnelSavePayloadKey], previousVersion[funnelSavePayloadKey]) &&
      key !== 'id'
    ) {
      delete currentVersion[funnelSavePayloadKey];
    }
  });

  return currentVersion;
};

interface SfFunnelAutoSaveProps {
  funnelsData: FunnelsData;
  crmOrgId?: string | null;
  setLastUpdatedFor: (object: string, date: string) => void;
}
export const useSfFunnelsAutoSave = ({
  funnelsData,
  crmOrgId,
  setLastUpdatedFor,
}: SfFunnelAutoSaveProps) => {
  const {
    getErrorHashFor,
    removeError,
    setErrorsFor,
    setFunnelEntityIsDirty,
    getIsSavingFor,
    setIsSavingFor,
  } = useAutoSaveContext();

  const [hasEditFunnelPermissions] = usePermission(['edit:funnels']);
  const { saveFunnelData } = useFunnelMapFlowPageApi();

  const [savedFunnelById, setSavedFunnelById] = useState(() =>
    Object.fromEntries(
      Object.keys(funnelsData).map((funnelId) => [
        funnelId,
        getSavableFunnelData(funnelsData[funnelId]),
      ]),
    ),
  );

  useEffect(() => {
    if (!hasEditFunnelPermissions) {
      return;
    }
    const funnelsToSave: FunnelSavePayload[] = [];
    const funnelDetailsById = Object.fromEntries(
      Object.keys(funnelsData).map((funnelId) => [funnelId, funnelsData[funnelId].funnelDetails]),
    );

    const funnelIds = Object.keys(funnelDetailsById);

    // Checks witch funnels have changed and adds them to the funnelsToSave list
    funnelIds.forEach((funnelId) => {
      const savableFunnelData = getSavableFunnelData(funnelsData[funnelId]);
      const previousFunnelVersion = savedFunnelById[funnelId];

      const isFirstTimeInFunnel = !Boolean(previousFunnelVersion);

      if (isFirstTimeInFunnel) {
        // Do not save since this funnel already exists and saved in the current condition
        // however add it to the savedFunnelDetailsById
        setSavedFunnelById((savedFunnel) => {
          savedFunnel[funnelId] = savableFunnelData;
          return { ...savedFunnel };
        });
      } else {
        // Verify if there are changes in the current funnel and add
        // to the funnelsToSave list

        const cleanedFunnel = _(savableFunnelData).omitBy(_.isUndefined).value();
        const cleanedPreviousFunnelVersion = _(previousFunnelVersion).omitBy(_.isUndefined).value();

        const isSavingFunnel = getIsSavingFor(funnelId);
        const funnelHasChanges = () => !_.isEqual(cleanedPreviousFunnelVersion, cleanedFunnel);

        if (!isSavingFunnel && funnelHasChanges()) {
          setFunnelEntityIsDirty(funnelId, true);
          funnelsToSave.push(
            omitNotChangedProperties({
              currentVersion: savableFunnelData,
              previousVersion: previousFunnelVersion,
            }),
          );
        }
      }
    });

    const saveFunnels = async () => {
      const promises = funnelsToSave.map(async (payload) => {
        const { id: funnelId } = payload;
        const payloadHash = simpleHash(JSON.stringify(payload));
        if (getErrorHashFor(funnelId) === payloadHash) {
          return;
        }
        try {
          setIsSavingFor(funnelId, true);
          const funnel = await saveFunnelData({ ...payload, crmOrgId });
          removeError(funnelId);

          setLastUpdatedFor(funnelId, funnel.updatedAt);
          setFunnelEntityIsDirty(funnelId, false);

          setSavedFunnelById((_funnelsById) => {
            _funnelsById[funnelId] = funnel;
            return { ..._funnelsById };
          });
        } catch (err) {
          telemetry.captureError(err);
          setErrorsFor(funnelId, payloadHash);
        } finally {
          setIsSavingFor(funnelId, false);
        }
      });
      await Promise.all(promises);
    };

    saveFunnels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [funnelsData, hasEditFunnelPermissions]);
};
