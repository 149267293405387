const extractErrorMsg = (error: any) => {
  if (!error) return '';
  const msg = 'Unknown error';
  const tempMsg = error.response?.data?.message ?? error.response?.data?.error;
  if (typeof tempMsg === 'string') {
    return tempMsg;
  }
  if (error.toString()) {
    return error.toString();
  }
  return msg;
};

export default extractErrorMsg;
