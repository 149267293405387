import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { BaseDialog } from '../../common/dialogs/BaseDialog';
import PaywallBlockUpgrade from './PaywallBlockUpgrade';
import { ReactNode } from 'react';

const PaywallBlockUpgradeDialog = ({
  closeDialog,
  title,
  featureDescription,
  img,
}: {
  closeDialog: () => void;
  title: string;
  featureDescription: string;
  img: ReactNode;
}) => {
  return (
    <BaseDialog
      open={true}
      onClose={closeDialog}
      PaperProps={{
        sx: {
          minWidth: '600px',
          maxWidth: '640px',
          overflow: 'hidden',
          padding: '32px',
        },
      }}
    >
      <Box sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 2 }}>
        <IconButton variant="flat" size="large" onClick={closeDialog}>
          <Close />
        </IconButton>
      </Box>
      <Box display="flex" height="100%" justifyContent="center" alignItems="center">
        <PaywallBlockUpgrade title={title} featureDescription={featureDescription} img={img} />
      </Box>
    </BaseDialog>
  );
};

export default PaywallBlockUpgradeDialog;
