import { SlidersVertical } from '@sweep-io/sweep-design/dist/icons';
import { SingleSelectFilter } from '../../common/single-select-filter/SingleSelectFilter';
import { ActiveState } from './helpers';

const activeFilterOptions = [
  { label: 'Show only active', value: ActiveState.activeOnly, hideDot: true },
  { label: 'Show active and inactive', value: ActiveState.activeAndInactive },
];

interface ActiveInactiveSelectProps {
  withIcon?: boolean;
  onSelect: (value: ActiveState) => void;
  selectedItem: ActiveState;
}

export const ActiveInactiveSelect = ({
  withIcon,
  onSelect,
  selectedItem,
}: ActiveInactiveSelectProps) => {
  return (
    <SingleSelectFilter
      icon={withIcon ? <SlidersVertical /> : undefined}
      options={activeFilterOptions}
      selectedItemValue={selectedItem}
      onSelectItem={(value: string) => onSelect(value as ActiveState)}
    />
  );
};
