import { Box } from '@mui/material';
import LabelChip from '../../common/LabelChip';
import StyledTooltip from '../../common/StyledTooltip';
import { getObjectTypeColor } from '../../multi-canvas/helpers/getObjectTypeColor';


const RuleChips = ({
  chips,
  differentColorChips,
  differentColor = '',
}: {
  chips: string[];
  differentColorChips?: string[];
  differentColor?: string;
}) => {
  const chipsToShow = chips.slice(0, 2);
  const chipsLeft = chips.slice(2);
  const chipsLeftCount = chipsLeft.length;
  const tooltipText = chipsLeftCount ? chipsLeft.join(', ') : '';
  const { step: defaultColor } = getObjectTypeColor();
  return (
    <Box alignItems="flex-end" display="flex" gap={0.5}>
      {chipsToShow?.map((text, index) => {
        const { step: objectColor } = getObjectTypeColor(text); //if the chip is not an object, the color will be the default
        return (
          <LabelChip
            data-testid="configuration-chip"
            key={`${text}-${index}`}
            bgColor={differentColorChips?.includes(text) ? differentColor : objectColor}
          >
            {text}
          </LabelChip>
        );
      })}
      {chipsLeftCount > 0 && (
        <Box>
          <StyledTooltip title={tooltipText}>
            <Box display="flex">
              <LabelChip bgColor={defaultColor}>{'+' + chipsLeftCount}</LabelChip>
            </Box>
          </StyledTooltip>
        </Box>
      )}
    </Box>
  );
};

export default RuleChips;
