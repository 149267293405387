import { useSelector } from 'react-redux';
import { RootState } from '../reducers';
import { OAuthError, useAuth0 } from '@auth0/auth0-react';
import { useCallback, useEffect } from 'react';
import extractErrorMsg from '../components/helpers/extractErrorMsg';
import useQueryParams from './useQueryParams';
import { setUserForHubspot } from '../services/hubspot';
import { appRoutes } from '../constants/appRoutes';
import { useLocation } from 'react-router';
import useUserInfo from './useUserInfo';
import { useRunOnceWhenTruthy } from '../components/common/useRunOnceWhenTruthy';
import { useCookies } from 'react-cookie';
import {
  AUTH_INVITATION_QUERY_PARAM_NAME,
  AUTH_ORG_QUERY_PARAM_NAME,
  IS_INVITATION_QUERY_PARAM_NAME,
} from '../auth/authConsts';
import {
  ACTIVATE_TRIAL_COOKIE_NAME,
  ACTIVATE_TRIAL_QUERY_PARAM,
} from '../components/dashboard/paywall/paywallConsts';
import isEqual from 'lodash/isEqual';

const useCheckAuthVerification = () => {
  const query = useQueryParams();
  const { error: authError } = useAuth0();
  let authErrorObj;

  try {
    const authErrorDescription = (authError as OAuthError)?.error_description;
    authErrorObj = authErrorDescription ? JSON.parse(authErrorDescription) : undefined;
  } catch (e) {
    const errorDescriptionFromQuery = query.get('error_description');
    try {
      authErrorObj = errorDescriptionFromQuery ? JSON.parse(errorDescriptionFromQuery) : undefined;
    } catch (e) {}
  }

  const isVerificationNeeded = authErrorObj
    ? authErrorObj.error === 'Email must be verified'
    : false;
  const authUserId = authErrorObj ? (authErrorObj.userId as string) : undefined;
  const authUserEmail = authErrorObj ? (authErrorObj.userEmail as string) : undefined;

  return { isVerificationNeeded, authUserId, authUserEmail };
};


const useAuthenticateUser = () => {
  const userProps = useSelector((state: RootState) => state.userInfo, isEqual);
  const { data: userInfo, isLoading, error } = userProps;
  const query = useQueryParams();
  const location = useLocation();
  const { fetchCurrentUser } = useUserInfo();
  const [, setCookie] = useCookies();
  const activateTrialQuery = query.has(ACTIVATE_TRIAL_QUERY_PARAM);

  //need to set a cookie because "email verification" flow doesn't support authorizationParams (so we don't have appState.returnTo)
  useRunOnceWhenTruthy(() => {
    setCookie(ACTIVATE_TRIAL_COOKIE_NAME, true, { path: '/' });
  }, activateTrialQuery);

  const {
    isLoading: authIsLoading,
    isAuthenticated,
    loginWithRedirect,
    error: authError,
    user: authUser,
  } = useAuth0();

  const notVerifiedAuthUserDetails = useCheckAuthVerification();

  //create/update the contact in hubspot
  useRunOnceWhenTruthy(() => {
    if (authUser) {
      setUserForHubspot(authUser);
    }
  }, !!authUser);

  const apiGetUser = useCallback(async () => {
    const user = await fetchCurrentUser();
    if (!user) {
      return;
    }
  }, [fetchCurrentUser]);

  const login = useCallback(async () => {
    const invitation = query.get(AUTH_INVITATION_QUERY_PARAM_NAME);
    const organization = query.get(AUTH_ORG_QUERY_PARAM_NAME);
    const isInvitation = !!invitation && !!organization;
    const funnelMapIdToNavigateTo = query.get('funnelMapId'); //This is relevant only for user email invitations link
    const isRedirectToSignUp = location.pathname === '/signup';

    const baseReturnTo = funnelMapIdToNavigateTo
      ? `${appRoutes.canvasUnited.route}/${funnelMapIdToNavigateTo}`
      : window.location.pathname;

    //In case of signup from invitation
    if (isInvitation) {
      query.set(IS_INVITATION_QUERY_PARAM_NAME, 'true');
      query.delete(AUTH_INVITATION_QUERY_PARAM_NAME);
      query.delete(AUTH_ORG_QUERY_PARAM_NAME);

      await loginWithRedirect({
        authorizationParams: {
          invitation,
          organization,
        },
        appState: {
          returnTo: baseReturnTo + '?' + query.toString(),
        },
      });
      return;
    }
    //In case of regular signup
    if (isRedirectToSignUp) {
      await loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
        },
        appState: {
          returnTo: baseReturnTo + '?' + query.toString(),
        },
      });
      return;
    }

    //In case of sign in
    await loginWithRedirect({
      appState: {
        returnTo: baseReturnTo + '?' + query.toString(),
      },
    });
  }, [query, location.pathname, loginWithRedirect]);


  useRunOnceWhenTruthy(() => {
    if (isAuthenticated) {
      apiGetUser();
    }
  }, isAuthenticated);

  useEffect(() => {
    if (!isAuthenticated && !authIsLoading && !authError) {
      login();
    }
  }, [authIsLoading, authError, isAuthenticated, login]);

  return {
    userInfo,
    error: error || extractErrorMsg(authError),
    isLoading: authIsLoading || isLoading,
    notVerifiedAuthUserDetails,
  };
};

export { useAuthenticateUser };
