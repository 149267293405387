// Do not put any dispatcher here.
// Only pure API calls
// The Facades should mimic exactly the corresponding API.

import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import { LeadingField } from '../../components/parser/ParserTypes';
import buildURLQuery from '../../lib/buildURLQuery';
import { useDispatch } from 'react-redux';
import {
  assignFieldLabelsFromFunnel,
  assignFieldLabelsFromFunnels,
} from '../../sweep-fields/sweepFieldsLabelsReducer';
import { FunnelWithFieldsAndDeduce, FunnelWithMaybeFields } from '../../sweep-fields/types';

type FunnelsGetResponse = FunnelWithMaybeFields[];
type FunnelGetResponse = FunnelWithMaybeFields;
type FunnelPatchResponse = FunnelWithFieldsAndDeduce;

export type FunnelPatchPayload = {
  funnelDetails?: FunnelDetails;
  defaultCrmOrgId?: string | null;
  name?: string;
  description?: string;
};

export interface ConvertRecordTypePayload {
  defaultCrmOrgId: string;
  funnelName: string;
  objectName: string;
  recordTypeName: string;
  recordTypeLabel: string;
  recordTypeDescription?: string;
  leadingField: LeadingField;
}

export const useFunnelsApiFacade = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();

  return {
    get_funnels: useCallback(async () => {
      const response = await sweepApi.get(`/funnels`);

      const funnels = response.data as FunnelsGetResponse;
      dispatch(assignFieldLabelsFromFunnels({ funnels }));

      return funnels;
    }, [dispatch, sweepApi]),
    get_funnel: useCallback(
      async (id: string) => {
        const response = await sweepApi.get(`/funnels/${id}`);

        const funnel = response.data as FunnelGetResponse;
        dispatch(assignFieldLabelsFromFunnel({ funnel }));

        return funnel;
      },
      [dispatch, sweepApi],
    ),
    get_funnelsByName: useCallback(
      async (name: string, objectName?: string) => {
        const query = buildURLQuery({ name, objectName });
        const response = await sweepApi.get(`/funnels?${query}`);

        const funnels = response.data as FunnelsGetResponse;
        dispatch(assignFieldLabelsFromFunnels({ funnels }));

        return funnels;
      },
      [dispatch, sweepApi],
    ),
    get_funnelSnapshot: useCallback(
      async (snapshotId: string, funnelId: string) => {
        const response = await sweepApi.get(`/funnels/${funnelId}/snapshots/${snapshotId}`);
        return response.data as FunnelGetResponse;
      },
      [sweepApi],
    ),
    post_funnel: useCallback(
      async (funnelName: string, defaultCrmOrgId: string, templateDetails: FunnelDetails) => {
        const response = await sweepApi.post(`/funnels?crmOrgId=${defaultCrmOrgId}`, {
          funnelName,
          templateDetails,
        });
        const funnel = response.data as FunnelWithMaybeFields;
        dispatch(assignFieldLabelsFromFunnel({ funnel }));

        return funnel;
      },
      [dispatch, sweepApi],
    ),
    patch_funnel: useCallback(
      async (funnelId: string, payload: FunnelPatchPayload, crmOrgId?: string | null) => {
        let url = `/funnels/${funnelId}`;
        if (crmOrgId) {
          url += `?crmOrgId=${crmOrgId}`;
        }
        const response = await sweepApi.patch(url, payload);
        const funnel = response.data as FunnelPatchResponse;
        dispatch(assignFieldLabelsFromFunnel({ funnel }));

        return funnel;
      },
      [dispatch, sweepApi],
    ),
    post_convertRecordType: useCallback(
      async (body: ConvertRecordTypePayload) => {
        const result = await sweepApi.post(`/funnels/convert-record-type`, body);
        return result.data as Funnel;
      },
      [sweepApi],
    ),
    put_funnelFunnelDetailsAlerts: useCallback(
      async ({
        funnelId,
        crmOrgId,
        alerts,
      }: {
        funnelId: string;
        crmOrgId: string;
        alerts: DeprecatedAutomationStructure[];
      }) => {
        const result = await sweepApi.put(
          `/funnels/${funnelId}/funnelDetails/alerts?crmOrgId=${crmOrgId}`,
          alerts,
        );
        return result.data as Funnel;
      },
      [sweepApi],
    ),
  };
};
