import { Box, FormControl, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import SweepSelect from '../../common/SweepSelect';

interface TimeSelectorProps {
  readonly?: boolean;
  onChangeValue: (val: string) => any;
  onChangeValueType: (val: string) => any;
  object: AutomationTimeSelector;
  intervalsArray?: string[];
}

export const TimeSelector = ({
  readonly,
  onChangeValue,
  onChangeValueType,
  object,
  intervalsArray = ['Hours', 'Days', 'Weeks'],
}: TimeSelectorProps) => {
  return (
    <>
      <Box sx={{ marginRight: '22px' }}>for</Box>
      <FormControl sx={{ minWidth: '120px', marginRight: '22px' }}>
        <TextField
          type={'number'}
          value={object.offsetValue ?? ''}
          onChange={(event: { target: { value: string } }) => {
            onChangeValue(event.target.value);
          }}
          InputProps={{
            inputProps: {
              min: 1,
            },
            sx: {
              padding: '8px 6px',
              height: '39px',
              width: '122px',
            },
          }}
          placeholder={'Value'}
        />
      </FormControl>
      <Box>
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '140px', marginRight: '22px' },
          }}
          SelectProps={{
            disabled: readonly,
            placeholder: 'Value Type',
            value: object?.offsetType ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value;
              onChangeValueType(val as string);
            },
          }}
        >
          {intervalsArray.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </SweepSelect>
      </Box>
    </>
  );
};
