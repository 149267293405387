import { Skeleton, Box, Stack } from '@mui/material';

export const GroupMembersSkeletonTable = () => {
  return (
    <>
      <Box padding="48px" display="flex" flexDirection="column" gap={6}>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" gap={3}>
            <Box sx={{ width: '72px', height: '72px' }}>
              <Skeleton variant="circular" width={'72px'} height="72px" />
            </Box>
            <Box display="flex" flexDirection="column" gap="12px">
              <Skeleton variant="rounded" width="301px" height="32px" />
              <Skeleton variant="rounded" width="377px" height="16px" />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rounded" width="100px" height="40px" sx={{ borderRadius: '40px' }} />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap={2}>
          <Skeleton variant="rounded" width="132px" height="16px" />
          <Stack spacing={'72px'}>
            <Skeleton variant="rounded" width="100%" height="72px" />
            <Skeleton variant="rounded" width="100%" height="72px" />
            <Skeleton variant="rounded" width="100%" height="72px" />
          </Stack>
        </Box>
      </Box>
    </>
  );
};
