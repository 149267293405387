import {
  FormControl,
  InputAdornment,
  InputBaseComponentProps,
  InputProps,
  SxProps,
  TextField,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { SelectorValueTypes } from '../../../../types/enums/SelectorValueTypes';
import { OnChangeProps } from '../../../SweepFieldsValueSelector/SweepFieldsValueSelector';
import { CloseIconButton } from '../../CloseIconButton';
import { colors } from '@sweep-io/sweep-design';
import FieldLabel from '../../FieldLabel';

export interface TextFieldSelectorProps {
  initialValue?: any;
  selectorType?: 'regular' | 'textarea' | 'number';
  shouldBeDisabled?: boolean;
  isRequired?: boolean;
  onChange?: (data?: any) => void;
  inputProps?: InputBaseComponentProps;
  InputProps?: InputProps;
  sx?: SxProps;
  placeholder?: string;
  isReferencedValue?: boolean;
  onSelectValuesFromRecordChange?: (props: OnChangeProps) => void;
  removeBorders?: boolean;
  error?: boolean;
  isReadOnly?: boolean;
  fullWidth?: boolean;
  label?: string;
  id?: string;
}

const TextFieldSelector = (params: TextFieldSelectorProps) => {
  const {
    id,
    initialValue,
    selectorType = 'regular',
    shouldBeDisabled,
    isReadOnly, //referenced value is disabled by default, additional prop to define readonly mode needed
    isRequired,
    onChange,
    inputProps,
    InputProps,
    sx,
    placeholder,
    isReferencedValue,
    onSelectValuesFromRecordChange,
    removeBorders,
    label,
    fullWidth,
    ...restParams
  } = params;

  const [innerValue, setInnerValue] = useState(initialValue);
  const isTextArea = useMemo(() => selectorType === 'textarea', [selectorType]);

  useEffect(() => {
    // To support controlled components eg. Date, DateTime
    setInnerValue(initialValue);
  }, [initialValue]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = event.target;

    setInnerValue(value);
    onChange && onChange(value);
  };

  return (
    <FormControl fullWidth={fullWidth}>
      {label && <FieldLabel label={label} id={id} />}
      <TextField
        {...restParams}
        id={id}
        multiline={isTextArea}
        maxRows={1}
        required={isRequired}
        type={selectorType}
        value={innerValue}
        disabled={shouldBeDisabled}
        onChange={handleOnChange}
        placeholder={placeholder}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {isReferencedValue && !isReadOnly && (
                <CloseIconButton
                  handleClose={() =>
                    onSelectValuesFromRecordChange &&
                    onSelectValuesFromRecordChange({
                      _value: '',
                      valueType: SelectorValueTypes.LITERAL,
                      _valueLabels: [],
                    })
                  }
                  sx={{
                    fontSize: '14px',
                  }}
                />
              )}
            </InputAdornment>
          ),
          sx: {
            fontFamily: 'Inter, sans-serif',
            lineHeight: '21px',
            border: removeBorders ? 'none' : 'auto',
            justifyContent: 'space-between',
            textTransform: 'none',
            height: '37px',
            '& .MuiInputBase-input': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },

            '&.Mui-disabled': {
              color: isReferencedValue && !isReadOnly ? colors.black : 'inherit',
            },
            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              //TODO move to SweepTextField and create new noBorder component
              border: removeBorders ? 'none' : 'auto',
            },

            '& > textarea': {
              lineHeight: '28px',
              '&.MuiInputBase-input': {
                overflowY: 'auto',
              },
            },
          },
          ...InputProps,
        }}
        inputProps={{
          ...inputProps,
          value: innerValue,
          sx: {
            ...inputProps?.sx,
            '&.Mui-disabled': {
              //MUI styles grays out font color, if the value is referenced we need it in black
              WebkitTextFillColor:
                isReferencedValue && !isReadOnly ? colors.black : 'rgba(0, 0, 0, 0.38)',
            },
          },
        }}
        sx={sx}
      />
    </FormControl>
  );
};

export { TextFieldSelector };
