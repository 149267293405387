import { AutomationTypes } from '../../../types/enums/AutomationTypes';
import { FunnelSelector } from '../FunnelSelector';
import { StepSelector } from '../StepSelector';
import { TimeSelector } from './TimeSelector';

interface TriggerTimeInStepProps {
  crmOrgId: string;
  automationJson: DeepPartial<AutomationStructureNew>;
  readonly?: boolean;
  onChangeStage: (stageDetails: AutomationStageDetails, _objectName: string | undefined) => any;
  onChangeValue: (val: string) => any;
  onChangeValueType: (val: string) => any;
  objectName: ObjectTypeValues;
  onFunnelChange: (funnelId: string) => any;
  funnels?: ShortFunnel[];
}

export const TriggerTimeInStep = ({
  crmOrgId,
  automationJson,
  readonly,
  onChangeStage,
  onChangeValue,
  onChangeValueType,
  objectName,
  onFunnelChange,
  funnels,
}: TriggerTimeInStepProps) => {
  const JsonTimeInStep = automationJson.automationDetails?.automationParams?.timeInStep;

  return (
    <>
      <FunnelSelector
        onChange={onFunnelChange}
        selectedFunnel={automationJson.automationDetails?.funnelId ?? ''}
        funnels={funnels}
        disabled={readonly}
      />
      {funnels && funnels.length > 0 && (
        <>
          <StepSelector
            crmOrgId={crmOrgId}
            stageDetails={JsonTimeInStep?.stage}
            onChange={(stageDetails, _objectName) => {
              onChangeStage(stageDetails, _objectName);
            }}
            stages={
              funnels.find((el) => el.id === automationJson.automationDetails?.funnelId)
                ?.funnelDetails?.stages || []
            }
            disabled={!automationJson.automationDetails?.funnelId || readonly}
            objectName={objectName}
          />
          {automationJson.automationDetails?.triggerType === AutomationTypes.TimeInStep &&
            JsonTimeInStep?.stage && (
              <TimeSelector
                onChangeValue={onChangeValue}
                onChangeValueType={onChangeValueType}
                object={JsonTimeInStep}
              />
            )}
        </>
      )}
    </>
  );
};
