// Converts a partial visibility map to a full visibility map by filling
// in the missing keys with false.

import { VisibilityMap } from '../../../../types/VisibilityTypes';
import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';

// This is to avoid having to pass the full visibility map every time.
export const partialVisibilityMapToVisibilityMap = (
  partialVisibilityMap?: Partial<VisibilityMap>,
): VisibilityMap => {
  const visibilityMap = Object.keys(VisibilityLayers).reduce((acc, key) => {
    acc[key] = false;
    return acc;
  }, {} as any);

  return {
    ...visibilityMap,
    ...partialVisibilityMap,
  };
};
