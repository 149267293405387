import { Box } from '@mui/material';

import { useCallback } from 'react';
import { Typography } from '@sweep-io/sweep-design';
import { StepSelector } from '../StepSelector';
import { FunnelSelector } from '../FunnelSelector';

interface TriggerStageExitProps {
  crmOrgId: string;
  automationJson: DeepPartial<AutomationStructureNew>;
  readonly?: boolean;
  onChangeFromStage: (stageDetails: AutomationStageDetails, _objectName: string | undefined) => any;
  onChangeToStage: (stageDetails: AutomationStageDetails) => any;
  objectName: ObjectTypeValues;
  onFunnelChange: (funnelId: string) => any;
  funnels?: ShortFunnel[];
}

export const TriggerStageExit = ({
  crmOrgId,
  automationJson,
  readonly,
  onChangeFromStage,
  onChangeToStage,
  objectName,
  onFunnelChange,
  funnels,
}: TriggerStageExitProps) => {
  const JsonStageExit = automationJson.automationDetails?.automationParams?.stageExit;
  const getStagesByStageObjectAndAddAny = useCallback(() => {
    const _stages =
      funnels
        ?.find((el) => el.id === automationJson.automationDetails?.funnelId)
        ?.funnelDetails?.stages?.filter(
          (el) => el._stageId !== JsonStageExit?.fromStage?._stageId,
        ) ?? [];

    _stages.unshift({
      _stageId: 'ANY_STEP',
      stageName: 'Any step',
      stageDescription: '',
      assignmentRules: [], //TODO remove after removed from BE SweepStage class
      exitCriteria: [],
      entryInits: [],
      exitInits: [],
      apexClasses: [],
      _branchIndex: 0,
    });
    return _stages;
  }, [JsonStageExit?.fromStage?._stageId, automationJson.automationDetails?.funnelId, funnels]);

  return (
    <>
      <FunnelSelector
        onChange={onFunnelChange}
        selectedFunnel={automationJson.automationDetails?.funnelId ?? ''}
        funnels={funnels}
        disabled={readonly}
      />
      {funnels && funnels.length > 0 && (
        <>
          <StepSelector
            crmOrgId={crmOrgId}
            stageDetails={JsonStageExit?.fromStage}
            onChange={(stageDetails, _objectName) => {
              onChangeFromStage(stageDetails, _objectName);
            }}
            stages={
              funnels.find((el) => el.id === automationJson.automationDetails?.funnelId)
                ?.funnelDetails?.stages || []
            }
            disabled={!automationJson.automationDetails?.funnelId || readonly}
            objectName={objectName}
          />
          <Box sx={{ marginRight: '8px' }}>
            <Typography variant="body">and Entering</Typography>
          </Box>
          <StepSelector
            crmOrgId={crmOrgId}
            isShowObjectMark={true}
            disabled={!JsonStageExit?.fromStage || readonly}
            stageDetails={JsonStageExit?.toStage}
            onChange={(stageDetails) => {
              onChangeToStage(stageDetails);
            }}
            stages={getStagesByStageObjectAndAddAny()}
            objectName={objectName}
          />
        </>
      )}
    </>
  );
};
