import { useCallback, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearDocumentationDialog,
  clearFilters,
  setActiveObjectElementsRawData,
  setDependenciesConfigurationItem,
  setTab,
  updateOrAddFieldToParsedConfigurationItems,
} from '../../reducers/documentationReducer';
import { DocumentationTabTypes } from '../../types/enums/DocumentationTabTypes';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import { ConfigurationType } from '@server/parser';
import {
  selectEnvironments,
  selectParsedFields,
  selectParsedRecordTypes,
} from '../../reducers/global/globalReducer';
import { setSfFieldName } from '../pages/rollups/rollupHelpers';
import useParser from '../parser/useParser';
import { createStepId, findRTStepFromFunnelStep } from './selected-object/filters/utils';
import { ACTIONS_EVENTS } from '../../services/events';
import useSendBiEvent from '../../hooks/useSendBiEvent';
import { telemetry } from '../../telemetry';
import { DependenciesContentViewType } from './dependencies/types';
import { useGetFilteredParsedRules } from './selected-object/filters/useGetFilteredParsedRules';
import { ActiveState } from './activity-state/helpers';
import { FiltersMap } from './selected-object/filters/filtersOptions';
import { MASTER_KEY } from '../pages/funnel-map-flow/dialogs/import-funnel/utils';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { DocumentationDialogContext } from './context/DocumentationContextProvider';
import { ConfigurationItem } from './dependencies/DependenciesTypes';

export type onFieldClickProps = {
  objectName: string;
  fieldName: string;
  id: string;
  crmOrgId: string;
  isRollup: boolean;
  contentType: DependenciesContentViewType;
};

export const useDocumentation = () => {
  const [isLoadingSingleField, setIsLoadingSingleField] = useState(false);
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId) ?? '';
  const { filterValues, funnelsData } = useContext(DocumentationDialogContext);

  const sendBiEvent = useSendBiEvent();

  const dispatch = useDispatch();
  const { get_crmOrgField } = useCrmOrgsApiFacade();
  const { parseObject, objectsBeingParsed, toggleObjectVisibility } = useParser({
    crmOrgId,
  });

  const globalEnvironments = useSelector(selectEnvironments);
  const fields = useSelector(selectParsedFields(crmOrgId));

  const activeOnly =
    filterValues?.[FiltersMap.activeState]?.selectedValues[0] !== ActiveState.activeAndInactive;

  const parsedRecordTypes = useSelector(selectParsedRecordTypes(crmOrgId));
  const parsedRules = useGetFilteredParsedRules(crmOrgId, activeOnly);

  const _clearFilters = useCallback(() => {
    dispatch(clearFilters());
  }, [dispatch]);

  const getRollup = useCallback(
    ({
      fieldName,
      objectName,
      crmOrgId,
    }: {
      fieldName: string;
      objectName: string;
      crmOrgId: string;
    }) => {
      const rollups = globalEnvironments?.[crmOrgId]?.data?.rollups;
      return rollups?.find((rollup) => rollup.name === setSfFieldName(fieldName, objectName));
    },
    [globalEnvironments],
  );

  const getParsedField = useCallback(
    (objectName: string, fieldName: string) => {
      return fields?.[objectName]?.find((field) => field.name === fieldName);
    },
    [fields],
  );

  const handleRollup = useCallback(
    ({ fieldName, objectName, crmOrgId, contentType }: onFieldClickProps) => {
      const rollup = getRollup({ fieldName, objectName, crmOrgId });

      if (rollup) {
        dispatch(
          setDependenciesConfigurationItem({
            id: rollup?.rollupId,
            parentType: ConfigurationType.rollups,
            dependencyType: rollup.fieldType,
            name: fieldName,
            objectName,
            clearHistory: true,
            contentType,
          }),
        );
      } else {
        telemetry.captureError(new Error('Field marked as rollup was not found: '), {
          fieldName,
          objectName,
          crmOrgId,
        });
      }
    },
    [getRollup, dispatch],
  );

  const onFieldClick = useCallback(
    async (props: onFieldClickProps) => {
      const { isRollup, fieldName, objectName, crmOrgId, contentType, id } = props;

      if (isRollup) {
        handleRollup(props);
        return;
      }

      const existingParsedField = getParsedField(objectName, fieldName);

      if (existingParsedField) {
        dispatch(
          setDependenciesConfigurationItem({
            id: id ?? existingParsedField.id,
            parentType: ConfigurationType.fields,
            dependencyType: existingParsedField.type,
            name: fieldName,
            objectName,
            clearHistory: true,
            contentType,
          }),
        );
        return;
      }

      setIsLoadingSingleField(true);
      try {
        const field = await get_crmOrgField({ crmOrgId, objectApiName: objectName, fieldName });

        dispatch(
          updateOrAddFieldToParsedConfigurationItems({
            crmOrgId,
            field,
            objectName,
          }),
        );

        dispatch(
          setDependenciesConfigurationItem({
            id: field.id,
            parentType: ConfigurationType.fields,
            dependencyType: field.type,
            name: fieldName,
            objectName,
            clearHistory: true,
            contentType,
          }),
        );
      } catch (e) {
        telemetry.captureError(e);
      }
      setIsLoadingSingleField(false);
    },
    [dispatch, get_crmOrgField, getParsedField, handleRollup],
  );

  const onTabChange = (
    event: React.SyntheticEvent<Element, Event>,
    activeTab: DocumentationTabTypes,
  ) => {
    _clearFilters();
    dispatch(setTab({ tab: activeTab }));
  };

  const onAddObject = useCallback(
    async (objectName: string) => {
      sendBiEvent({
        name: ACTIONS_EVENTS.documentationAddObject,
        props: { object: objectName },
      });

      try {
        await parseObject(objectName);
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [parseObject, sendBiEvent],
  );

  const onRemoveObject = useCallback(
    async (objectName: string) => {
      sendBiEvent({
        name: ACTIONS_EVENTS.documentationAddObject,
        props: { object: objectName },
      });

      return await toggleObjectVisibility(objectName, false, true);
    },
    [toggleObjectVisibility, sendBiEvent],
  );

  const handleClose = useCallback(() => {
    dispatch(clearDocumentationDialog());
  }, [dispatch]);

  const setActiveObjectElements = useCallback(
    (list: { [key in DocumentationTabTypes]: ConfigurationItem[] }) => {
      dispatch(setActiveObjectElementsRawData({ list }));
    },
    [dispatch],
  );

  const translateStepId = useCallback(
    (stepId: string) => {
      let selectedStage: SweepStage | undefined;
      const funnel = Object.values(funnelsData ?? {})?.find((_funnel) =>
        _funnel.funnelDetails.stages.find((_stage) => {
          if (_stage._stageId === stepId) {
            selectedStage = _stage;
            return _stage;
          }
        }),
      );
      if (!funnel || !selectedStage) {
        return stepId;
      }
      return createStepId(
        funnel.recordType.name ?? MASTER_KEY,
        funnel.recordType.objectName,
        findRTStepFromFunnelStep(funnel, selectedStage.stageName, parsedRecordTypes)?.name ?? '',
      );
      return stepId;
    },
    [funnelsData, parsedRecordTypes],
  );

  return {
    _clearFilters,
    isLoadingSingleField,
    onFieldClick,

    onTabChange,
    getRollup,

    parsedRules,
    parsedRecordTypes,

    objectsBeingParsed,
    onAddObject,
    onRemoveObject,
    translateStepId,
    handleClose,

    setActiveObjectElements,
  };
};
