import { Box } from '@mui/material';
import { Note } from './Note';
import { RadioList } from '../../../../common/RadioList';
import { OrgDot } from '../../../../common/OrgDot';
import { sortOrgs } from '../../../../../lib/sortOrgs';

export const EnvironmentToDeploy = ({
  orgs,
  setSelectedOrgId,
  selectedOrgId,
  onNoteChange,
  showNote,
}: {
  orgs: CrmOrg[];
  setSelectedOrgId: (id: string) => void;
  selectedOrgId: string;
  onNoteChange?: (note: string) => void;
  showNote: boolean;
}) => {
  const shortOrgStructure = sortOrgs(orgs).map((org) => ({
    value: org.id,
    label: org.name,
    decorator: org.isMain ? <OrgDot styles={{ paddingLeft: '5px' }} /> : <></>,
  }));

  return (
    <>
      <Box sx={{ paddingTop: '24px' }}>
        <RadioList
          onChange={setSelectedOrgId}
          value={selectedOrgId}
          listItems={shortOrgStructure}
          maxListHeight={700}
        />
      </Box>

      {showNote && onNoteChange && (
        <Box padding={4} sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Note onNoteChange={onNoteChange} />
        </Box>
      )}
    </>
  );
};
