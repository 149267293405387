import { useCallback, useState } from 'react';

export type WizardStep = {
  name: string;
  isCompleted: boolean;
};

export type WizardProps = {
  goNext: () => void;
  goBack: () => void;
  goToStep: (index: number) => void;
  activeStep: number;
  isLastStep: boolean;
  isFirstStep: boolean;
  steps: WizardStep[];
};

const useWizard = (stepsNames: string[]): WizardProps => {
  const [steps, setSteps] = useState<WizardStep[]>(() =>
    stepsNames.map((name) => ({
      name,
      isCompleted: false,
    })),
  );
  const [activeStep, setActiveStep] = useState(0);

  const isLastStep = activeStep === steps.length - 1;
  const isFirstStep = activeStep === 0;

  const goNext = useCallback(() => {
    if (isLastStep) {
      return;
    }
    //update "isCompleted" for the current step
    setSteps((currSteps) =>
      currSteps.map((step, index) =>
        index === activeStep ? { ...step, isCompleted: true } : step,
      ),
    );
    setActiveStep((curr) => curr + 1);
  }, [isLastStep, activeStep, setSteps, setActiveStep]);

  const goBack = useCallback(() => {
    if (isFirstStep) {
      return;
    }
    setActiveStep(activeStep - 1);
  }, [isFirstStep, setActiveStep, activeStep]);

  const goToStep = useCallback(
    (index: number) => {
      //can go directly only to completed steps
      if (steps[index].isCompleted) {
        setActiveStep(index);
      }
    },
    [steps, setActiveStep],
  );

  return { goNext, goBack, goToStep, activeStep, isLastStep, isFirstStep, steps };
};

export default useWizard;
