import { Box, Typography } from '@mui/material';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { CustomWebhookInterface, useWebhooksContext } from './WebhooksContext';
import { useIntegrations } from '../../../hooks/useIntegrations';

export const WebhooksHeader = () => {
  const { setWebhooks, setEditWebhook, editWebhook } = useWebhooksContext();
  const { createCustomIntegration, updateCustomIntegration } = useIntegrations();

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mr: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton
            variant="flat"
            onClick={() => {
              setWebhooks(false);
              setEditWebhook(undefined);
            }}
          >
            <ArrowLeft />
          </IconButton>

          <Typography variant="h2">Add new integration</Typography>
        </Box>
        <Box>
          <Button
            size="small"
            onClick={() => {
              if (editWebhook?.id) {
                updateCustomIntegration(editWebhook as CustomWebhookInterface);
              } else {
                createCustomIntegration(editWebhook as CustomWebhookInterface);
              }
              setWebhooks(false);
            }}
          >
            Done
          </Button>
        </Box>
      </Box>
    </>
  );
};
