import Stack from '@mui/material/Stack';
import { colors, Typography } from '@sweep-io/sweep-design';
import Search from './atoms/search.svg?react';

export const UniversalSearchEmptyState = () => (
  <Stack justifyContent="center" alignItems="center" direction="column" gap={2} pt={8}>
    <Search />
    <Typography variant="body" color={colors.grey[700]}>
      Your search results will appear here
    </Typography>
  </Stack>
);
