import { useAuth0 } from '@auth0/auth0-react';
import { logoutUserBI } from '../services/mixpanel';
import { useAuthOrg } from './useAuthOrg';
import { useCallback } from 'react';

const useLogout = () => {
  const { logout } = useAuth0();
  const { authOrgQueryParam } = useAuthOrg();

  const logoutFn = useCallback(() => {
    let returnTo = window.location.origin;
    if (authOrgQueryParam) {
      returnTo += '?' + authOrgQueryParam;
    }
    logoutUserBI();
    logout({
      logoutParams: { returnTo },
    });
  }, [authOrgQueryParam, logout]);

  return { logout: logoutFn };
};

export default useLogout;
