import { Alert, Box, MenuItem, SelectChangeEvent } from '@mui/material';
import SweepSelect from '../common/SweepSelect';
import { Warning } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';

interface FunnelSelectorProps {
  onChange: (funnelId: string) => any;
  selectedFunnel: string;
  funnels?: ShortFunnel[];
  disabled?: boolean;
}

export const FunnelSelector = ({
  onChange,
  funnels,
  selectedFunnel,
  disabled,
}: FunnelSelectorProps) => {
  if (!funnels)
    return (
      <Box mr={1}>
        <CenteredCircularProgress circularProgressProps={{ size: 20 }} />
      </Box>
    );

  return (
    <Box>
      {funnels.length === 0 ? (
        <Alert
          icon={<Warning />}
          sx={{
            background: colors.sun[300],
            color: colors.night[900],
            alignItems: 'center',
            mr: '8px',
          }}
          severity="warning"
        >
          To use this trigger, create a funnel under this object
        </Alert>
      ) : (
        <SweepSelect
          FormControlProps={{ sx: { minWidth: '120px', marginRight: '8px' } }}
          SelectProps={{
            placeholder: 'Choose Funnel',
            disabled,
            value: selectedFunnel,
            onChange: async (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as string;
              onChange(val);
            },
            renderValue: (val: any) => {
              const funnel = funnels?.find((el) => el.id === val);
              return funnel?.name ?? '';
            },
          }}
        >
          {funnels.map((funnel) => (
            <MenuItem key={funnel.id} value={funnel.id}>
              {funnel.name}
            </MenuItem>
          ))}
        </SweepSelect>
      )}
    </Box>
  );
};
