import { Box, SelectChangeEvent, MenuItem, FormControl, TextField } from '@mui/material';
import SweepSelect from '../../../common/SweepSelect';
import { AutomationActionRowProps } from '../types';
import { getRelativeDateList } from '../utils/getRelativeDateList';

export const RelativeSelect = ({
  readonly,
  onChange,
  _field,
}: Pick<AutomationActionRowProps, 'readonly' | 'onChange'> & { _field: AutomationActionField }) => {
  if (typeof _field.value !== 'string' && !(_field.value instanceof String)) return null;
  const fieldValue = _field.value as string;

  return (
    <Box sx={{ display: 'flex' }}>
      <Box>
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px', marginRight: '8px' },
          }}
          SelectProps={{
            disabled: readonly,
            placeholder: 'Choose Option',
            value: _field.value ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value;
              onChange({
                ..._field,
                value: val as string,
              });
            },
            renderValue: (val: any) => {
              const nonNumeric = val.match(/\D/g).join('');
              const output = getRelativeDateList(_field).find((d) => d.value === nonNumeric);
              if (output) {
                return output.description;
              }
            },
          }}
        >
          {getRelativeDateList(_field).map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.description}
            </MenuItem>
        ))}
        </SweepSelect>
      </Box>

      {_field.value && _field.value !== 'today' && _field.value !== 'now' && (
        <FormControl>
          <TextField
            disabled={readonly}
            variant="outlined"
            type={'number'}
            value={_field.value.split('-')[1] || _field.value.split('+')[1]}
            onChange={(event) => {
              const nonNumericPrefix = fieldValue.match(/\D/g)?.join('');
              onChange({
                ..._field,
                value: nonNumericPrefix + event.target.value,
              });
            }}
            InputProps={{
              inputProps: {
                min: 1,
              },
              sx: {
                padding: '8px 6px',
                height: '39px',
                width: '90px',
              },
            }}
            placeholder={'Days'}
          />
        </FormControl>
      )}
    </Box>
  );
};
