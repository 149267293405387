import { useCallback } from 'react';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { useDispatch, useSelector } from 'react-redux';
import {
  requestRefreshGlobal,
  deleteAutomation as deleteAutomationAction,
  toggleAutomation as toggleAutomationAction,
  addAutomation,
  updateAutomation,
  updateAutomationDeployStatus,
} from '../../reducers/global/globalReducer';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { getEditOptimisticStatus } from '../pages/helper';
import { useSweepNotifications } from '../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../reducers/notificationsReducer';
import { FailedAction } from '../../types/enums/FailedAction';
import { telemetry } from '../../telemetry';

const useAutomations = () => {
  const {
    create_automation,
    update_automation,
    toggle_automation,
    delete_automation,
    deploy_automation,
  } = useAutomationsApiFacade();
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const crmOrgId = crmOrg?.id;
  const dispatch = useDispatch();
  const { addNotification } = useSweepNotifications();

  const handleError = useCallback(
    (e: any, failedAction: FailedAction = FailedAction.deploy): ErrorInApi => {
      addNotification({
        message: `Failed to ${failedAction} due to technical issues`,
        keepOpen: true,
        details: e.response?.data.message,
        variant: SweepNotificationVariant.Error,
      });
      telemetry.captureError(e);
      return { isError: true };
    },
    [addNotification],
  );

  const deployOrRefresh = useCallback(
    ({
      deployToOrgIds,
      deployAutomationDto,
    }: {
      deployToOrgIds: string[];
      deployAutomationDto: AutomationStructureNew;
    }) => {
      if (deployToOrgIds.length > 0) {
        deploy_automation(deployAutomationDto.automationId, deployAutomationDto.versionId, {
          ...deployAutomationDto,
          deployToOrgIds,
        });
        //when deploy is finished, a web socket will be sent in order to trigger "requestRefresh"
      } else if (crmOrgId) {
        dispatch(requestRefreshGlobal({ crmOrgId }));
      }
    },
    [crmOrgId, deploy_automation, dispatch],
  );

  const createAutomation = useCallback(
    async ({
      deployToOrgIds,
      createAutomationDto,
    }: {
      deployToOrgIds: string[];
      createAutomationDto: AutomationStructureNew;
    }) => {
      try {
        const toSend = {
          ...createAutomationDto,
          deployToOrgIds,
        };
        const newAutomation = await create_automation(toSend);
        const shouldDeploy = deployToOrgIds.length > 0;
        if (crmOrgId) {
          dispatch(
            addAutomation({
              crmOrgId,
              automation: newAutomation,
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : DeployStatusForTable.Draft,
            }),
          );
        }
        deployOrRefresh({
          deployToOrgIds,
          deployAutomationDto: newAutomation,
        });
        return newAutomation;
      } catch (e) {
        return handleError(e, FailedAction.create);
      }
    },
    [create_automation, crmOrgId, deployOrRefresh, dispatch, handleError],
  );

  const editAutomation = useCallback(
    async ({
      deployToOrgIds,
      updateAutomationDto,
      versionId,
    }: {
      updateAutomationDto: AutomationStructureNew;
      deployToOrgIds: string[];
      versionId: string;
    }) => {
      try {
        const toSend = {
          ...updateAutomationDto,
          deployToOrgIds,
        };
        const updatedAutomation = await update_automation(toSend);
        const shouldDeploy = deployToOrgIds.length > 0;
        if (crmOrgId) {
          dispatch(
            updateAutomation({
              crmOrgId,
              automationToUpdate: updatedAutomation,
              versionIdToUpdate: versionId, //updatedAutomation.versionId ? because "updatedRollup" has a new versionId, that doesn't exist yet in the store
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : getEditOptimisticStatus(updateAutomationDto.status),
            }),
          );
        }
        deployOrRefresh({
          deployToOrgIds,
          deployAutomationDto: updatedAutomation,
        });
        return updatedAutomation;
      } catch (e) {
        return handleError(e, FailedAction.save);
      }
    },
    [update_automation, crmOrgId, deployOrRefresh, dispatch, handleError],
  );

  const deleteAutomation = useCallback(
    async ({
      automationId,
      automationType,
    }: {
      automationId: string;
      automationType: AutomationType;
    }) => {
      try {
        await delete_automation(automationId, automationType);
        if (crmOrgId) {
          dispatch(
            deleteAutomationAction({
              crmOrgId,
              automationIdToDelete: automationId,
            }),
          );
        }
        //when deploy is finished, a web socket will be sent in order to trigger "requestRefresh"
      } catch (e) {
        return handleError(e, FailedAction.delete);
      }
    },
    [delete_automation, crmOrgId, dispatch, handleError],
  );

  const toggleAutomation = useCallback(
    async ({
      deployToOrgIds,
      updateAutomationDto,
      versionId,
    }: {
      updateAutomationDto: AutomationStructureNew;
      deployToOrgIds: string[];
      versionId: string;
    }) => {
      try {
        const toSend = {
          ...updateAutomationDto,
          deployToOrgIds,
        };
        await toggle_automation(toSend);
        const shouldDeploy = deployToOrgIds.length > 0;

        if (crmOrgId) {
          dispatch(
            toggleAutomationAction({
              automationToUpdate: toSend,
              crmOrgId,
              versionIdToUpdate: versionId,
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : updateAutomationDto.status,
            }),
          );
        }
      } catch (e) {
        return handleError(
          e,
          updateAutomationDto.isActive ? FailedAction.activate : FailedAction.deactivate,
        );
      }
    },
    [toggle_automation, crmOrgId, dispatch, handleError],
  );

  const deployAutomation = useCallback(
    async ({
      automationId,
      versionId,
      deployToOrgIds,
      deployAutomationDto,
    }: {
      deployAutomationDto: AutomationStructureNew;
      automationId: string;
      versionId: string;
      deployToOrgIds: string[];
    }) => {
      try {
        const toSend = {
          ...deployAutomationDto,
          deployToOrgIds,
        };
        deploy_automation(automationId, versionId, toSend);
        if (crmOrgId) {
          dispatch(
            updateAutomationDeployStatus({
              crmOrgId,
              automationId,
              versionId,
              optimisticStatus: DeployStatusForTable.Deployed,
            }),
          );
        }
        //when deploy is finished, a web socket will be sent in order to trigger "requestRefresh"
      } catch (e) {
        return handleError(e, FailedAction.deploy);
      }
    },
    [deploy_automation, crmOrgId, dispatch, handleError],
  );

  return { editAutomation, createAutomation, deleteAutomation, deployAutomation, toggleAutomation };
};

export default useAutomations;
