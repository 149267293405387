import useEventListener from '../components/common/useEventListener';
import useSendBiEvent from './useSendBiEvent';
import { USER_EVENTS } from '../services/events';
import usePaywall from '../components/dashboard/paywall/usePaywall';
import { useLocation } from 'react-router';

const useHandleMessages = () => {
  const sendBiEvent = useSendBiEvent();
  const { paywallState } = usePaywall();
  const { pathname } = useLocation();
  const mainPath = pathname.split('/')[1];

  const handleMessage = (event: MessageEvent) => {
    if (!event.data.action) {
      return;
    }
    const isMeetingBooked = event.data.action === 'booked';
    const isMeetingConfirmed = event.data.action === 'booking-confirmed';
    if (isMeetingBooked) {
      sendBiEvent({
        name: USER_EVENTS.meetingBooked,
        props: {
          source: mainPath,
          trialStatus: paywallState,
        },
      });
    }
    if (isMeetingConfirmed) {
      sendBiEvent({
        name: USER_EVENTS.meetingConfirmed,
      });
    }
  };

  useEventListener('message', handleMessage);
};

export default useHandleMessages;
