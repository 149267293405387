import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '..';
import { FieldsDialogContentOptions } from '../../constants/fieldsManagementConsts';

type FunnelSelectData = { id: string; name: string; objectName: string };

export interface FieldUsageWithFieldMetadata extends MultiFunnelsFieldUsage {
  field: SweepField;
}

export interface FieldsManagementState {
  dialogContent: FieldsDialogContentOptions;
  isOpen: boolean;
  funnelMapId?: string;
  search: string;
  funnelsSelectData: FunnelSelectData[];
  selectedFunnels: string[];
  fieldsUsage?: FieldUsageWithFieldMetadata[];
  fieldToEdit?: FieldUsageWithFieldMetadata;
}

const initialFieldManagementState: FieldsManagementState = {
  dialogContent: FieldsDialogContentOptions.FieldsTable,
  isOpen: false,
  search: '',
  funnelsSelectData: [],
  selectedFunnels: [],
};

const fieldsManagementSlice = createSlice({
  name: 'fieldsManagement',
  initialState: initialFieldManagementState,
  reducers: {
    openFieldsDialog: (
      state,
      action: PayloadAction<{ funnelMapId: string; funnelsSelectData: FunnelSelectData[] }>,
    ) => {
      state.isOpen = true;
      state.funnelMapId = action.payload.funnelMapId;
      state.funnelsSelectData = action.payload.funnelsSelectData;
    },
    closeFieldsDialog: () => {
      return initialFieldManagementState;
    },
    setFieldsUsageData: (
      state,
      action: PayloadAction<{
        fieldsData: FunnelMapFieldsUsageData;
      }>,
    ) => {
      state.fieldsUsage = action.payload.fieldsData.fieldsUsage.map((usage) => {
        const sweepField = action.payload.fieldsData.sweepFields[usage.sweepFieldId];

        const field: FieldUsageWithFieldMetadata = {
          ...usage,
          field: sweepField,
        };
        return field;
      });
    },
    createOrUpdateField: (state, action: PayloadAction<{ field: SweepField }>) => {
      const { field } = action.payload;
      if (!state.isOpen || !state.fieldsUsage || !field.id) {
        return; //if the FieldManagement-dialog is closed, no need to update its cache
      }
      const idx = state.fieldsUsage.findIndex((fieldUsage) => fieldUsage.sweepFieldId === field.id);
      if (idx >= 0) {
        state.fieldsUsage[idx].field = field;
      } else {
        state.fieldsUsage.push({
          field,
          isPinned: true,
          leadingInFunnels: [],
          sections: {},
          sweepFieldId: field.id,
          usedInFunnels: [],
        });
      }
    },
    setFieldsSearch: (state, action: PayloadAction<{ search: string }>) => {
      state.search = action.payload.search;
    },
    setDialogContent: (
      state,
      action: PayloadAction<{
        dialogContent: FieldsDialogContentOptions;
        fieldToEdit?: FieldUsageWithFieldMetadata;
      }>,
    ) => {
      const { dialogContent, fieldToEdit } = action.payload;
      state.dialogContent = dialogContent;
      if (fieldToEdit) {
        state.fieldToEdit = fieldToEdit;
      }
    },
    setSelectedFunnels: (state, action: PayloadAction<{ selectedFunnels: string[] }>) => {
      state.selectedFunnels = action.payload.selectedFunnels;
    },
  },
});

export const selectIsFieldsDialogOpen = (state: RootState) => state.fieldsManagement.isOpen;
export const selectFieldsUsage = (state: RootState) => state.fieldsManagement.fieldsUsage;
export const selectFieldsFunnelMapId = (state: RootState) => state.fieldsManagement.funnelMapId;
export const selectFieldsSearch = (state: RootState) => state.fieldsManagement.search;
export const selectFieldsDialogContent = (state: RootState) => state.fieldsManagement.dialogContent;
export const selectFieldToEdit = (state: RootState) => state.fieldsManagement.fieldToEdit;

export const selectFunnelsSelectData = (state: RootState) =>
  state.fieldsManagement.funnelsSelectData;
export const selectSelectedFunnels = (state: RootState) => state.fieldsManagement.selectedFunnels;

export const {
  openFieldsDialog,
  closeFieldsDialog,
  setFieldsUsageData,
  createOrUpdateField,
  setFieldsSearch,
  setSelectedFunnels,
  setDialogContent,
} = fieldsManagementSlice.actions;

export default fieldsManagementSlice.reducer;
