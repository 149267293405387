import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import { DataTableVariant } from './TableTypes';
import { colors } from '@sweep-io/sweep-design';

type DataTableVariantProps = {
  [variant in DataTableVariant]: {
    fontVariant: SweepTypographyVariants;
    cellPadding: string;
    minRowHeight: string;
    headerFontVariant: SweepTypographyVariants;
    fontColor: string;
  };
};

export const dataTableVariants: DataTableVariantProps = {
  [DataTableVariant.narrow]: {
    headerFontVariant: 'caption-medium',
    fontVariant: 'caption',
    fontColor: colors.grey[800],
    cellPadding: '10px 10px 10px 0',
    minRowHeight: '40px',
  },
  [DataTableVariant.default]: {
    headerFontVariant: 'body-medium',
    fontVariant: 'body',
    fontColor: colors.grey[800],
    cellPadding: '7px 10px 7px 0',
    minRowHeight: '60px',
  },
};
