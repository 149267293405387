import { ConfirmDialog } from '../dialogs/ConfirmDialog';
import { Typography } from '@sweep-io/sweep-design';

interface DeleteCommentDialogProps {
  onDelete: () => void;
  handleClose: () => void;
}

const DeleteCommentDialog = ({ onDelete, handleClose }: DeleteCommentDialogProps) => (
  <ConfirmDialog
    title="Delete comment"
    confirmText="Delete"
    confirmButtonType="error"
    onConfirm={onDelete}
    isOpen
    handleClose={handleClose}
    PaperProps={{
      sx: {
        width: '452px',
      },
    }}
  >
    <Typography variant="body">
      Are you sure you want to delete this comment? <br />
      This action cannot be undone.
    </Typography>
  </ConfirmDialog>
);

export default DeleteCommentDialog;
