const trimmedValues = (values: any) =>
  Object.keys(values).reduce((acc: any, key) => {
    acc[key] = (values as any)[key].trim();
    return acc;
  }, {});

type FunctionValues = (values: any) => any;

export const withTrimmedValues = (func: FunctionValues): FunctionValues => {
  return (values) => func(trimmedValues(values));
};
