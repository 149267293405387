import { CONFIGURATION_CANVAS_RIGHT_PANEL_WIDTH } from '../configuration-canvas/consts';
import { AllocateRightPanelSpace } from '../configuration-canvas/panels-reserve-space/AllocateRightPanelSpace';
import {
  ConfigurationCanvasPanelProps,
  ConfigurationCanvasPanel,
} from './ConfigurationCanvasPanel';

export const ConfigurationCanvasRightPanel: React.FC<ConfigurationCanvasPanelProps> = (props) => {
  const _width = props.width ?? 800;
  return (
    <AllocateRightPanelSpace size={_width ?? CONFIGURATION_CANVAS_RIGHT_PANEL_WIDTH}>
      <ConfigurationCanvasPanel
        positionSx={{
          position: 'absolute',
          top: 0,
          left: 'auto',
          right: 0,
        }}
        width={_width}
        {...props}
      />
    </AllocateRightPanelSpace>
  );
};
