import { useDispatch } from 'react-redux';
import { setInitialFunnelMap } from '../../../../reducers/united-canvas/unitedCanvasReducer';
import { useFunnelsApiFacade } from '../../../../apis/facades/useFunnelsApiFacade';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { useSnapshots } from '../../../../hooks/useSnapshots';
import { appRoutes } from '../../../../constants/appRoutes';
import { useNavigate } from 'react-router';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import {
  closeIsUsedInFunnelMapsDialog,
  selectDeployment,
  setIsUsedInFunnelMapsDialogOpen,
  setMapsForIsUsedInFunnelMaps,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { useFunnelMapApiFacade } from '../../../../apis/facades/funnel-map/useFunnelMapApiFacade';
import { getFunnelMapSingleFunnelSkeleton } from '../../../../utils/funnelMapSkeleton';
import useSweepNavigate from '../../../common/useSweepNavigate';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getCrmOrgById } from '../../environments/environmentsReducer';
import useQueryParams from '../../../../hooks/useQueryParams';
import { telemetry } from '../../../../telemetry';
import { selectFunnelMap } from '../../../../reducers/united-canvas/unitedCanvasReducer';

const useFunnelMapReadOnly = () => {
  const query = useQueryParams();
  const crmOrgId = query.get('crmOrgId') as string;

  const { addNotification } = useSweepNotifications();
  const { restoreSnapshot } = useSnapshots();
  const { get_funnel, get_funnelSnapshot } = useFunnelsApiFacade();
  const { post_funnelMap, get_funnelMaps } = useFunnelMapApiFacade();
  const { goBack } = useSweepNavigate();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const funnelMap = useSelector(selectFunnelMap);
  const environment = useSelector(getCrmOrgById(crmOrgId));

  const funnel = Object.values(funnelMap.funnelsData)[0];

  const deployment = useSelector(selectDeployment);

  const name = !!deployment?.name ? deployment?.name : funnelMap.name;

  const getName = useCallback(() => {
    const funnelPart = name ? `${name} - ` : '';
    return funnelPart + ` (view only)`;
  }, [name]);

  const createTemporaryFunnelMap = async ({
    funnelId,
    snapshotId,
  }: {
    funnelId: string;
    snapshotId?: string;
  }) => {
    const funnel = snapshotId
      ? await get_funnelSnapshot(snapshotId, funnelId)
      : await get_funnel(funnelId);

    if (funnel) {
      const tempFunnelMap = getFunnelMapSingleFunnelSkeleton({
        funnel,
        updatedAt: funnel.updatedAt ?? funnel.createdAt,
        updatedById: funnel.createdById,
      }) as FunnelMap;

      dispatch(setInitialFunnelMap(tempFunnelMap));
    }

    return funnel;
  };

  const onViewLatestVersionCallback = async ({
    funnelId,
    crmOrgId,
    funnelName,
  }: {
    funnelId: string;
    crmOrgId: string;
    funnelName: string;
  }) => {
    dispatch(setIsUsedInFunnelMapsDialogOpen());

    try {
      const funnelMaps = (await get_funnelMaps({
        includeFunnelId: funnelId,
      })) as PartialFunnelMap[];

      if (!!funnelMaps.length) {
        dispatch(setMapsForIsUsedInFunnelMaps({ funnelMaps }));
      } else {
        dispatch(closeIsUsedInFunnelMapsDialog());
        const funnelMap = await post_funnelMap({
          name: funnelName,
          defaultCrmOrgId: crmOrgId,
          funnels: [
            {
              funnelId,
              row: 0,
              column: 0,
            },
          ],
        });
        navigate(`${appRoutes.canvasUnited.route}/${funnelMap.id}`);
      }
    } catch (e) {
      telemetry.captureError(e);
      dispatch(setMapsForIsUsedInFunnelMaps({ funnelMaps: [] }));
    }
  };

  const onRestoreCallback = async ({
    snapshotId,
    funnelId,
    funnelName,
  }: {
    snapshotId: string;
    funnelId: string;
    funnelName: string;
  }) => {
    try {
      await restoreSnapshot(funnelId, snapshotId, true);
      goBack();
      addNotification({
        message: `${funnelName} was successfully restored`,
        variant: SweepNotificationVariant.Success,
      });
    } catch (e) {
      addNotification({
        message: `Restoring ${funnelName} failed`,
        variant: SweepNotificationVariant.Error,
      });
      return;
    }
  };

  return {
    funnel,
    funnelName: name,
    restoreFunnelName: name,
    versionName: deployment?.snapshotName,
    funnelMapName: getName(),
    environmentName: deployment?.id ? environment?.name : 'Sweep',
    deployedAt: deployment?.deployedAt,

    createTemporaryFunnelMap,
    onViewLatestVersionCallback,
    onRestoreCallback,
  };
};

export { useFunnelMapReadOnly };
