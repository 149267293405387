import { VisibilityMap } from '../../../../../types/VisibilityTypes';
import { VisibilityLayers } from '../../../../../types/enums/VisibilityLayers';
import { partialVisibilityMapToVisibilityMap } from '../../utils/partialVisibilityMapToVisibilityMap';

const {
  APEX,
  ASSIGNMENT_RULES,
  FUNNEL_APEX,
  FUNNEL_ASSIGNMENT_RULES,
  FUNNEL_PLAYBOOK_ALERTS,
  FUNNEL_SFDC_AUTOMATIONS,
  FUNNEL_SWEEP_AUTOMATIONS,
  FUNNEL_VALIDATION_RULES,
  PLAYBOOK_ALERTS,
  SFDC_AUTOMATIONS,
  SWEEP_AUTOMATIONS,
  VALIDATION_RULES,
  FUNNEL_MATCHING_AND_DEDUPLICATION,
  FUNNEL_MATCHING,
  FUNNEL_DEDUPLICATION,
} = VisibilityLayers;

const layersToMultipleLayersMap = {
  [APEX]: [APEX, FUNNEL_APEX],
  [SFDC_AUTOMATIONS]: [SFDC_AUTOMATIONS, FUNNEL_SFDC_AUTOMATIONS],
  [VALIDATION_RULES]: [VALIDATION_RULES, FUNNEL_VALIDATION_RULES],
  [SWEEP_AUTOMATIONS]: [SWEEP_AUTOMATIONS, FUNNEL_SWEEP_AUTOMATIONS],
  [PLAYBOOK_ALERTS]: [PLAYBOOK_ALERTS, FUNNEL_PLAYBOOK_ALERTS],
  [ASSIGNMENT_RULES]: [ASSIGNMENT_RULES, FUNNEL_ASSIGNMENT_RULES],
  [FUNNEL_MATCHING_AND_DEDUPLICATION]: [
    FUNNEL_MATCHING_AND_DEDUPLICATION,
    FUNNEL_MATCHING,
    FUNNEL_DEDUPLICATION,
  ],
};

const excludeLayers = [
  FUNNEL_SFDC_AUTOMATIONS,
  FUNNEL_VALIDATION_RULES,
  FUNNEL_APEX,
  FUNNEL_PLAYBOOK_ALERTS,
  FUNNEL_ASSIGNMENT_RULES,
  FUNNEL_MATCHING,
  FUNNEL_DEDUPLICATION,
  FUNNEL_SWEEP_AUTOMATIONS,
];

const layersToMultipleLayers = (layer: VisibilityLayers): VisibilityLayers[] => {
  if (layersToMultipleLayersMap.hasOwnProperty(layer)) {
    return layersToMultipleLayersMap[layer as keyof typeof layersToMultipleLayersMap];
  }
  return [layer];
};

// This function is used to convert a partial visibility map to a full visibility map
// That is used in the layers selector
// It filters out the layers that are not in the layers selector
// And maps the layers that are in the layers selector to their multiple layers
// Not all layers have a representation in the layers selector.
// Example: FUNNEL_SWEEP_AUTOMATIONS is set by the SWEEP_AUTOMATIONS value
export const visibilityMapToLayersSelectorVisibilityMap = (
  partialVisibilityMap: Partial<VisibilityMap>,
): VisibilityMap => {
  const newVisibilityMap = Object.entries(partialVisibilityMap)
    .filter(([key]) => !excludeLayers.includes(key as keyof VisibilityMap))
    .map(([key, value]) => {
      const layers = layersToMultipleLayers(key as keyof VisibilityMap);

      return layers.map((layer) => {
        return [layer, value];
      });
    })
    .flat();

  return partialVisibilityMapToVisibilityMap(
    Object.fromEntries(newVisibilityMap) as Partial<VisibilityMap>,
  );
};
