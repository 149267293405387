import AutomationSchedule from './AutomationSchedule';

interface AutomationDefaultTriggersProps {
  readonly?: boolean;
  automationJson: DeepPartial<AutomationStructureNew>;
  objectName: ObjectTypeValues;
  callBack: (data: DeepPartial<AutomationStructureNew>) => void;
}

export const AutomationScheduledAssignmentTriggers = ({
  readonly,
  automationJson,
  objectName,
  callBack,
}: AutomationDefaultTriggersProps) => {

  const setSchedule = (_newSchedule: AutomationSchedule) => {
    callBack({
      ...automationJson,
      objectName: objectName,
      automationDetails: {
        ...automationJson.automationDetails,
        automationParams: {
          schedule: _newSchedule,
        },
      },
    });
  };

  return (
    <AutomationSchedule
      callBack={setSchedule}
      schedule={automationJson.automationDetails?.automationParams?.schedule}
      readonly={readonly}
      entityName="assignment"
    />
  );
};
