import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import { Typography } from '@sweep-io/sweep-design';

const ConnectProgressInDialog = () => {
  return (
    <Stack
      mx="auto"
      display="flex"
      flexDirection="column"
      justifyContent="space-around"
      padding={3}
    >
      <Stack>
        <Box mb={1}>
          <Typography variant="h1">Connecting to Salesforce...</Typography>
        </Box>
        <Typography variant="body">Do not close this tab!</Typography>
      </Stack>
    </Stack>
  );
};

export default ConnectProgressInDialog;
