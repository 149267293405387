import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { AutomationsTemplatesList } from './AutomationsTemplatesList';
import { AutoTemplatesStruct } from '../../constants/automationTemplates';
import { PageTemplateCanvasDialog, PageTemplates } from '../pages/pages-templates';

export enum WrapperType {
  fullPage = 'fullPage',
  dialog = 'dialog',
  box = 'box',
}

interface AutomationsTemplatesWrapperProps {
  crmOrgId: string;
  automationVariant: AutomationFormVariant;
  onSelectTemplate: (item: AutoTemplatesStruct) => void;
  createEmptyButton: JSX.Element;
  wrapperType: WrapperType;
  title: string;
  onCancel?: () => any; //needed only when "isFullPage" is "false"
}

export const AutomationsTemplatesWrapper = ({
  automationVariant,
  crmOrgId,
  createEmptyButton,
  onSelectTemplate,
  wrapperType,
  title,
  onCancel,
}: AutomationsTemplatesWrapperProps) => {
  const templatesList = (
    <AutomationsTemplatesList
      crmOrgId={crmOrgId}
      automationVariant={automationVariant}
      onSelectTemplate={onSelectTemplate}
      createEmptyButton={createEmptyButton}
    />
  );

  switch (wrapperType) {
    case WrapperType.fullPage:
      return <PageTemplates title={title} isFullPage={true}>{templatesList}</PageTemplates>;
    case WrapperType.dialog:
      return (
        <PageTemplateCanvasDialog isOpen={true} onCancel={onCancel} title={title}>
          {templatesList}
        </PageTemplateCanvasDialog>
      );
    case WrapperType.box:
      return <PageTemplates title={title} isFullPage={false}>{templatesList}</PageTemplates>;
  }
};
