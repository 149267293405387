import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useMemo } from 'react';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../Automations/AutomationsContext';
import DrawerEmptyImage from '../common/empty-state/svgs/drawerEmpty.svg?react';
import { UI_EVENTS } from '../../services/events';
import { AutomationType } from '../../types/enums/AutomationType';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';

export const AutomationAssignmentsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { patch_assignments_admin_notification } = useAutomationsApiFacade();
  const contextValue: AutomationsContextType = useMemo(() => {
    return {
      automationType: AutomationType.Assignment,
      permissions: {
        create: 'create:assignments',
        edit: 'edit:assignments',
        deploy: 'deploy:assignments',
        delete: 'delete:assignments',
        notifyAdmin: 'edit:assignments:notify-admin',
      },
      emptyStateTitle: 'Create your first Assignment rule',
      emptyStateImage: <DrawerEmptyImage />,
      defaultName: 'New Assignment',
      placeholder: 'New Assignment',
      nameInMessage: 'assignment rules',
      nameInMessageSingular: 'assignment rule',
      openEvent: UI_EVENTS.assignmentsOpen,
      automationVariant: AutomationFormVariant.ASSIGNMENT_RULES,
      templateSupport: true,
      requestDeployNotifyAdmin: patch_assignments_admin_notification,
    };
  }, [patch_assignments_admin_notification]);
  return <AutomationsContextProvider value={contextValue}>{children}</AutomationsContextProvider>;
};
