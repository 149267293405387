import RightPanel from '../common/RightPanel';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import CopilotChatsExpanded from './CopilotChatsExpanded';
import CopilotChatsCollapsed from './CopilotChatsCollapsed';
import { ChatShort, Chat } from './copilotChatsReducer';

const CopilotChats = ({
  closeCopilot,
  isOpened,
  isExpanded,
  toggleIsExpanded,
  chats,
  activeChat,
  isLoadingChats,
}: {
  closeCopilot: () => void;
  isOpened: boolean;
  isExpanded: boolean;
  toggleIsExpanded: () => void;
  chats: ChatShort[];
  activeChat?: Chat;
  isLoadingChats: boolean;
}) => {
  const onCreateClick = () => {};

  const newButton = (
    <Button startIconName="Plus" variant="outlined" onClick={onCreateClick} size="small">
      New chat
    </Button>
  );

  const toggleExpandButton = (
    <Button
      startIconName={isExpanded ? 'Minimize' : 'Expand'}
      variant="outlined"
      onClick={toggleIsExpanded}
      size="small"
    >
      {isExpanded ? 'Minimize' : 'Expand'}
    </Button>
  );

  const closeButton = (
    <IconButton onClick={closeCopilot} variant="flat" size="small">
      <Close />
    </IconButton>
  );

  return (
    <RightPanel isOpened={isOpened} isExpanded={isExpanded}>
      {isExpanded && (
        <CopilotChatsExpanded
          newButton={newButton}
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
          chats={chats}
          activeChat={activeChat}
          isLoadingChats={isLoadingChats}
        />
      )}
      {!isExpanded && (
        <CopilotChatsCollapsed
          newButton={newButton}
          toggleExpandButton={toggleExpandButton}
          closeButton={closeButton}
          activeChat={activeChat}
        />
      )}
    </RightPanel>
  );
};

export default CopilotChats;
