import { IconButton, Tooltip } from '@sweep-io/sweep-design';
import { MarkUnread } from '@sweep-io/sweep-design/dist/icons';

const UnreadButton = ({ isRead, markAsUnread }: { isRead: boolean; markAsUnread: () => void }) => {
  return (
    <Tooltip title="Mark as unread" placement="top">
      <span>
        <IconButton
          variant="flat"
          disabled={!isRead}
          onClick={(e) => {
            e.stopPropagation(); //to prevent zoom on the comment
            markAsUnread();
          }}
        >
          <MarkUnread />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default UnreadButton;
