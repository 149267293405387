import { Box, Divider, Grid, Paper } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import React, { useCallback, useMemo } from 'react';
import { ActionsMenu, ActionsMenuAction } from '../actions-menu/ActionsMenu';

interface RuleBuilderSelectionRowProps {
  lineNumber: number;
  showDelete?: boolean;
  showDrag?: boolean;
  showDuplicate?: boolean;
  onDelete?: (lineNumber: number) => any;
  onDrag?: (lineNumber: number) => any;
  onDuplicate?: (lineNumber: number) => any;
  readonly?: boolean;
  firstComponent: React.ReactNode;
  secondComponent: React.ReactNode;
  thirdComponent: React.ReactNode;
}

export const RuleBuilderSelectionRow = ({
  lineNumber,
  showDelete,
  showDrag,
  showDuplicate,
  onDelete,
  onDrag,
  onDuplicate,
  readonly,
  firstComponent,
  secondComponent,
  thirdComponent,
}: RuleBuilderSelectionRowProps) => {
  const actions: ActionsMenuAction[] = useMemo(() => {
    return [
      {
        label: 'Drag',
        value: 'Drag',
        hidden: !showDrag,
      },
      {
        label: 'Duplicate',
        value: 'Duplicate',
        hidden: !showDuplicate,
      },
      {
        label: 'Delete',
        value: 'Delete',
        hidden: !showDelete,
      },
    ];
  }, [showDelete, showDuplicate, showDrag]);

  const onActionItemClick = useCallback(
    (action: ActionsMenuAction) => {
      switch (action.value) {
        case 'Delete':
          onDelete && onDelete(lineNumber);
          break;
        case 'Drag':
          onDrag && onDrag(lineNumber);
          break;
        case 'Duplicate':
          onDuplicate && onDuplicate(lineNumber);
          break;
      }
    },
    [lineNumber, onDelete, onDrag, onDuplicate],
  );

  const isActionsMenuVisible = !readonly && (showDrag || showDelete || showDuplicate);

  return (
    <Box>
      <Paper
        elevation={0}
        sx={{
          borderRadius: '4px',
          border: '1px solid #D9D9D9',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              width: '36px',
              height: '40px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography variant="body" color={colors.black} textAlign="center">
              {lineNumber || '-'}
            </Typography>
          </Box>

          <Grid container flexWrap="nowrap">
            <Divider orientation="vertical" flexItem />
            <Grid item xs={4}>
              {firstComponent}
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={3}>
              {secondComponent}
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={5}>
              {thirdComponent}
            </Grid>
            <Divider orientation="vertical" flexItem />
          </Grid>
          <Box
            sx={{
              width: '36px',
              height: '40px',
              verticalAlign: 'middle',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: readonly ? colors.grey[100] : 'initial',
            }}
          >
            {isActionsMenuVisible && <ActionsMenu actions={actions} onClick={onActionItemClick} />}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};
