import { Box, Tooltip } from '@mui/material';
import { Warning } from '@sweep-io/sweep-design/dist/icons/Warning';
import { colors } from '@sweep-io/sweep-design';
import { ActionsRequireMp } from '../../common/install-managed-package/getInstallManagedPackageText';
import InstallManagedPackagePopoverContent from '../../common/install-managed-package/InstallManagedPackagePopoverContent';

export const Utilization = ({ columnName }: { columnName: string }) => {
  return (
    <Tooltip
      title={<InstallManagedPackagePopoverContent action={ActionsRequireMp.FIELDS} />}
      placement="right-end"
      PopperProps={{
        sx: {
          '.MuiTooltip-tooltip': {
            padding: '8px',
            overflow: 'hidden',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.25)',
            backgroundColor: colors.white,
            color: colors.black,
            maxWidth: 'unset',
            borderRadius: '16px',
          },
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, position: 'relative' }}>
        {columnName}
        <Box sx={{ display: 'flex', alignItems: 'center' }} data-testid="warning-icon">
          <Warning />
        </Box>
      </Box>
    </Tooltip>
  );
};
