import { CONFIGURATION_CANVAS_LEFT_PANEL_WIDTH } from '../configuration-canvas/consts';
import { AllocateLeftPanelSpace } from '../configuration-canvas/panels-reserve-space/AllocateLeftPanelSpace';
import { useExpandedMode } from '../configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import {
  ConfigurationCanvasPanelProps,
  ConfigurationCanvasPanel,
} from './ConfigurationCanvasPanel';

export const ConfigurationCanvasLeftPanel: React.FC<
  ConfigurationCanvasPanelProps & { withExpandedModeButton?: boolean }
> = (props) => {
  const { isExpandedMode } = useExpandedMode();
  const _width = props.width ?? CONFIGURATION_CANVAS_LEFT_PANEL_WIDTH;

  const panel = (
    <ConfigurationCanvasPanel
      positionSx={{
        position: 'absolute',
        top: 0,
        right: 'auto',
        left: 0,
        // bottom: 0,
      }}
      {...props}
      isExpandedMode={isExpandedMode && props.withExpandedModeButton}
      width={_width}
      id="configuration-canvas-left-panel"
    />
  );

  if (props.skipSizeAllocation) {
    return panel;
  }

  return (
    <AllocateLeftPanelSpace size={_width ?? CONFIGURATION_CANVAS_LEFT_PANEL_WIDTH}>
      {panel}
    </AllocateLeftPanelSpace>
  );
};
