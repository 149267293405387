import get from 'lodash/get';
import isArray from 'lodash/isArray';
import uniq from 'lodash/uniq';
import upperFirst from 'lodash/upperFirst';
import toLower from 'lodash/toLower';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import {
  AutomationDataActionType,
  AutomationTypes,
  ScheduledParametersOffsetType,
} from '../../types/enums/AutomationTypes';
import { NestedSelectorPath } from '../common/NestedSelector/NestedSelector';
import { Box, Divider, styled } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { AutomationType } from '../../types/enums/AutomationType';
import { SweepSortOption } from '../common/SweepSort';
import { AddDedupMatchingButton } from './AddDedupMatchingButton';
import { AddAutomationButton, AddAutomationButtonProps } from './AddAutomationButton';
import { DedupMatchingType } from '../../types/enums/DedupMatchingType';
import { uniqueId } from '../../lib/uniqueId';
import { DedupMergeActionType } from '../routing/dedupMatching/helpers';
import { PopoverProps } from '@mui/material/Popover/Popover';
import { humanizeDate, humanizeDateVariants } from '../helpers/humanizeDate';
import { DateTime } from 'luxon';
import { SweepFieldOperator } from '../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { ACTIONS_EVENTS } from '../../services/events';
import flatten from 'lodash/flatten';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { SlackConnectionStatus } from '../../reducers/integrationsReducer';
import { validateChannelRef, validateSlackActionParams } from './slack/validateSlack';
import { SFDCObjectType } from '../../types/enums/SFDCObjectType';
import { Commands } from '../../types/enums/Common';
import {
  Automations,
  Copy,
  Delete,
  Edit,
  LightningSlash,
  SlackLogo,
  SweepBug,
} from '@sweep-io/sweep-design/dist/icons';
import { OrgDot } from '../common/OrgDot';
import { SortOrder } from '../common/types';

export const AUTOMATIONS_LAYOUT_MAX_WIDTH = '1400px';
export const SEARCH_BOX_WIDTH = '300px';

enum Constant {
  exact = 'exact',
}

export const validateEmails = (emailsArr: string[]) => {
  const regexExp = /\S+@\S+\.\S+/;
  const isValid = emailsArr.every((x) => regexExp.test(x));
  return isValid;
};

export const getNestedPath = (
  _fieldIds: string | string[] | undefined,
  _fieldLabels: string[] | undefined,
): NestedSelectorPath => {
  if (!_fieldIds || _fieldIds.length === 0 || !_fieldLabels || _fieldLabels.length === 0) {
    return [];
  }
  if (!isArray(_fieldIds)) {
    _fieldIds = [_fieldIds];
  }
  return _fieldIds.map((fieldId, idx) => ({
    label: _fieldLabels[idx + 1] || '',
    value: fieldId,
  }));
};

export const parseTime = (time: string) => {
  const timeInt = parseInt(time);
  const minutes = time.substring(3, 5);
  if (time > '12:00') {
    return `${timeInt === 12 ? timeInt : timeInt - 12}:${minutes} pm`;
  } else {
    return `${timeInt}:${minutes} am`;
  }
};

export const getDisplayLineTextForActionList = (actions: AutomationAction[]) => {
  return actions.map((action) => {
    switch (action.actionType) {
      case AutomationActionType.SendEmail:
        return 'Send an email notification';
      case AutomationActionType.CreateRecord:
        return `Create a new ${action?.actionParams?.objectTypeName?.label}`;
      case AutomationActionType.UpdateRecords:
        return 'Update record';
      case AutomationActionType.ConvertLead:
        return 'Convert Lead';
      case AutomationActionType.Slack:
        return 'Post to Slack';
      case AutomationActionType.Assignment:
        return 'Assign record';
      case AutomationActionType.AssignmentRules:
        return (
          <Box display="inline-flex" flexDirection="column">
            <Typography variant="body">Run assignment rules:</Typography>
            {action.actionParams.assignmentRules.map((rule, i) => (
              <Typography variant="body" key={rule.name + i}>
                {i + 1}. {rule.name}
              </Typography>
            ))}
          </Box>
        );
      case AutomationActionType.SlackDealRoom:
        return 'Create Slack deal room';
      case AutomationActionType.SlackAddMember:
        return 'Add members to deal room';
      case AutomationActionType.SendReportToSlack:
        return (
          <Box display="inline-flex" flexDirection="column">
            <Typography variant="body">Send a Salesforce report:</Typography>
            <Typography variant="body">{action.actionParams.reportName}</Typography>
          </Box>
        );
      case AutomationActionType.VerifyURL:
        return 'Verify URL';
      case AutomationActionType.SlackRenameDealroom:
        return 'Rename deal room';
      case AutomationActionType.SlackArchiveDealroom:
        return 'Archive deal room';
      case AutomationActionType.PrioritizedActions:
        return (
          <Box display="inline-flex" flexDirection="column">
            <Typography variant="body">Run branch actions:</Typography>
            {action.actionParams.prioritizedActions?.map((rule, i) => (
              <Typography variant="body" key={rule._name + i}>
                {i + 1}. {rule._name}
              </Typography>
            ))}
          </Box>
        );
      default:
        return '';
    }
  });
};

export const getDisplayLineTexts = (automation: AutomationStructureNew) => {
  let triggerText = '',
    actionText: ReactNode[] = [];
  const { stageEnter, stageExit, fieldUpdate, dateArrive, timeInStep, schedule } =
    automation.automationDetails.automationParams || {};
  switch (automation.automationDetails?.triggerType) {
    case AutomationTypes.StageEnter:
      triggerText = 'When entering ' + stageEnter?.toStage?.stageName;
      break;
    case AutomationTypes.StageExit:
      triggerText = `When exiting ${stageExit?.fromStage?.stageName} and entering ${stageExit?.toStage?.stageName}`;
      break;
    case AutomationTypes.FieldUpdate:
      triggerText =
        'When ' + automation.objectName + '.' + fieldUpdate?._leadingFieldLabels[1] + ' is updated';
      break;
    case AutomationTypes.DateArrive:
      const { offsetValue, offsetType, relativeTime, sourceField } = dateArrive || {};

      if (relativeTime === Constant.exact) {
        triggerText = `When ${automation.objectName}.${sourceField?._leadingFieldLabels[1]} arrives`;
      } else {
        triggerText = `${offsetValue} ${offsetType} ${relativeTime} ${sourceField?._leadingFieldLabels[1]} arrives`;
      }
      break;
    case AutomationTypes.TimeInStep:
      const { offsetValue: _offsetValue, offsetType: _offsetType, stage } = timeInStep || {};
      triggerText = `When ${stage?.stageName} is in the same step for ${_offsetValue} ${_offsetType}`;
      break;
    case AutomationTypes.OnCreate:
      triggerText = 'When a record is created';
      break;
    case AutomationTypes.OnUpdate:
      triggerText = 'When a record is updated';
      break;
    case AutomationTypes.OnUpsert:
      triggerText = 'When a record is created or updated';
      break;
  }
  if (automation.type === AutomationType.Dedupe || automation.type === AutomationType.Matching) {
    triggerText = '';
  }
  if (
    automation.type === AutomationType.ScheduledAssignment ||
    automation.type === AutomationType.ScheduledReport ||
    automation.automationDetails?.triggerType === AutomationTypes.Scheduled
  ) {
    const offsetRecurring = automation.automationDetails.automationParams.schedule?.offset;
    const startDate = humanizeDate({
      dateOrTimestamp: schedule?.startDate ?? '',
      variant: humanizeDateVariants.SHORT_WITHOUT_TIME,
    });
    const startOn = 'starting on ' + startDate;
    const startTime = parseTime(schedule?.executeTime ?? '');
    if (offsetRecurring) {
      const frequent = offsetRecurring?.offsetValue;
      const isOne = frequent === 1;
      const dateObject = new Date(schedule?.startDate ?? '');
      switch (offsetRecurring?.offsetType) {
        case ScheduledParametersOffsetType.DAY:
          triggerText = isOne
            ? `Daily at ${startTime}, ${startOn}`
            : `Every ${frequent} days at ${startTime}, ${startOn}`;
          break;
        case ScheduledParametersOffsetType.WEEK:
          const weekDays = (offsetRecurring as ScheduleWeekOffset)?.weekDays.map(
            (el) => upperFirst(toLower(el)) + ' ',
          );
          triggerText = isOne
            ? `Weekly on ${weekDays} at ${startTime}, ${startOn}`
            : `Every ${frequent} weeks on ${weekDays} at ${startTime}, ${startOn}`;
          break;
        case ScheduledParametersOffsetType.MONTH:
          triggerText = isOne
            ? `Monthly on day ${dateObject.getDate()} at ${startTime}, ${startOn}`
            : `Every ${frequent} months on day ${dateObject.getDate()} at ${startTime}, ${startOn}`;
          break;
        case ScheduledParametersOffsetType.YEAR:
          triggerText = isOne
            ? `Yearly on ${dateObject.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
              })} at ${startTime}, ${startOn}`
            : `Every ${frequent} years on ${dateObject.toLocaleDateString('en-US', {
                month: 'long',
                day: 'numeric',
              })} at ${startTime}, ${startOn}`;
          break;
      }
    } else {
      triggerText = `On ${startDate} at ${startTime}`;
    }
  }
  actionText = getDisplayLineTextForActionList(automation.automationDetails.actions);
  if (automation.type === AutomationType.Dedupe) {
    const getText = () => {
      const propToCheck = (
        automation?.automationDetails?.actions?.[0] as DeduplicationAutomationAction
      )?.actionParams?.mergeActionType;
      switch (propToCheck) {
        case DedupMergeActionType.OVERRIDE_NOTHING:
          return ['Match leads and merge manually'];
        case DedupMergeActionType.OVERRIDE_NEW_VALUES:
          return ['Enrich existing lead and merge'];
        case DedupMergeActionType.OVERRIDE_ALL:
          return ['Override existing lead and merge'];
        case DedupMergeActionType.OVERRIDE_BY_CONVERT:
          return ['Convert lead into the duplicate contact'];
        default:
          return ['Match leads and merge manually'];
      }
    };
    actionText = getText();
  }
  if (automation.type === AutomationType.Matching) {
    const getAssignAction = () => {
      return (
        automation?.automationDetails?.actions?.[0] as MatchingAutomationAction
      )?.actionParams?.followUpActions?.[0]?.actions?.find(
        (el) => el.actionType === AutomationActionType.Assignment,
      ) as AssignmentAutomationAction;
    };
    const assignAction = getAssignAction();
    if (!assignAction) {
      actionText = ['Only match'];
    } else if (assignAction?.actionParams?.when) {
      actionText = ['Match and assign (if conditions are met)'];
    } else {
      actionText = ['Match and assign'];
    }
  }
  if (automation.type === AutomationType.ScheduledAssignment) {
    if (
      automation?.automationDetails?.getDataAction?.actionType ===
      AutomationDataActionType.QUERY_RESULTS
    ) {
      actionText = ['Assign records that meet a criteria'];
    } else {
      actionText = ['Assign records from Salesforce report'];
    }
  }

  return { triggerText, actionText };
};

export type AutomationSortOption = SweepSortOption & {
  dataAccessor: string;
  getManualValue?: (automation: AutomationStructureNew) => string;
};

export const isAutomationsInStage = (
  automationDetails: AutomationDetailsDtoNew,
  searchValue: string,
  propToUse: '_stageId' | 'stageName',
) => {
  const isNotFieldUpdate = automationDetails.triggerType !== AutomationTypes.FieldUpdate;
  const isToStage =
    automationDetails.automationParams.stageEnter?.toStage?.[propToUse] === searchValue ||
    automationDetails.automationParams.stageExit?.toStage?.[propToUse] === searchValue;

  const isFromStage =
    automationDetails.automationParams.stageExit?.fromStage?.[propToUse] === searchValue;

  const isInStepFor =
    automationDetails.automationParams.timeInStep?.stage?.[propToUse] === searchValue;

  return isNotFieldUpdate && (isToStage || isFromStage || isInStepFor);
};

const ANY_STEP_NAME = 'Any step';
const ANY_STEP_ID = 'ANY_STEP';

export const getStepsFromAutomation = (
  automationDetails: AutomationDetailsDtoNew,
  propToUse: '_stageId' | 'stageName',
): string[] => {
  if (automationDetails.triggerType === AutomationTypes.FieldUpdate) {
    return [];
  }

  const steps = [
    automationDetails.automationParams.stageEnter?.toStage?.[propToUse],
    automationDetails.automationParams.stageExit?.toStage?.[propToUse],
    automationDetails.automationParams.stageExit?.fromStage?.[propToUse],
    automationDetails.automationParams.timeInStep?.stage?.[propToUse],
  ];

  return steps.filter(
    (nameOrId) => !!nameOrId && nameOrId !== ANY_STEP_NAME && nameOrId !== ANY_STEP_ID,
  ) as string[];
};

export const getRecordTypeLabelsFromAutomation = (automation?: AutomationStructureNew) => {
  if (!automation) {
    return [];
  }

  const funnelRecordTypeLabel = automation.funnelRecordType?.label ?? '';
  const rtLabels = automation.automationDetails?.recordTypes?.map((rt) => rt.label) ?? [];

  return [funnelRecordTypeLabel, ...rtLabels].filter((rtName) => rtName !== '');
};

export const getRecordTypeNamesFromAutomation = (automation?: AutomationStructureNew) => {
  if (!automation) {
    return [];
  }

  const funnelRecordTypeName = automation.funnelRecordType?.name ?? '';
  const rtNames = automation.automationDetails?.recordTypes?.map((rt) => rt.name) ?? [];

  return [funnelRecordTypeName, ...rtNames].filter((rtName) => rtName !== '');
};

export const getRecordTypeIdentifier = (recordTypeName: string, objectName: string) =>
  `${objectName}.${recordTypeName}`;

export const getObjectNameFromRecordTypeId = (id: string) => id.split('.')[0];
export const getRecordTypeNameFromRecordTypeId = (id: string) => id.split('.')[1];

export const getAutomationsForStageId = (
  stageId: string,
  automations?: AutomationStructureNew[],
) => {
  const byStep = automations?.filter((el) => {
    return isAutomationsInStage(el.automationDetails, stageId, '_stageId');
  });
  return byStep ?? [];
};

//According to product, "FunnelAutomation" is : object-level OR record-type level (with this specific RT) OR step-level (steps in this funnel)
export const getFunnelAutomations = ({
  funnelId,
  objectName,
  recordType,
  automations,
}: {
  funnelId: string;
  objectName: string;
  recordType: FunnelRecordType;
  automations?: AutomationStructureNew[];
}) => {
  return automations?.filter((automation) => {
    const isSameObject = automation.objectName === objectName;
    if (!isSameObject) return false;
    const isObjectLevel = isObjectLevelAutomation(automation);
    const isRecordTypeLevel = isRecordTypeLevelAutomation(automation, recordType.name);
    const isStepLevel = isStepLevelAutomation(automation, funnelId);
    return isObjectLevel || isRecordTypeLevel || isStepLevel;
  });
};

export const isObjectLevelAutomation = (automation: AutomationStructureNew) =>
  !automation.automationDetails.funnelId && !automation.automationDetails.recordTypes;

export const isRecordTypeLevelAutomation = (
  automation: AutomationStructureNew,
  recordTypeName: string,
) =>
  !automation.automationDetails.funnelId &&
  automation.automationDetails.recordTypes &&
  automation.automationDetails.recordTypes.map((rt) => rt.name).includes(recordTypeName);

export const isStepLevelAutomation = (
  automation: AutomationStructureNew,
  funnelId: string,
  stepId?: string, //if empty, return all automations for this funnel
) => {
  const isSameFunnel = automation.automationDetails.funnelId === funnelId;
  if (!isSameFunnel) return false;
  const stepsInAutomation = getStepsFromAutomation(automation.automationDetails, '_stageId');
  return stepId ? stepsInAutomation.includes(stepId) : true;
};

export const getAddAutomationButtonByType = ({
  variant,
  props,
}: {
  variant: any;
  props: Omit<AddAutomationButtonProps, 'popoverProps' | 'startIconName'> & {
    isEmptyState: boolean;
  };
}) => {
  const { isEmptyState } = props;
  const popoverProps: Pick<PopoverProps, 'anchorOrigin' | 'transformOrigin' | 'slotProps'> = {
    anchorOrigin: {
      vertical: isEmptyState ? 'top' : 'bottom',
      horizontal: isEmptyState ? 'right' : 'left',
    },
    transformOrigin: {
      vertical: isEmptyState ? 'top' : -4,
      horizontal: isEmptyState ? -4 : 'left',
    },
    slotProps: {
      paper: {
        style: {
          width: '277px',
        },
        sx: {
          marginTop: 0,
        },
      },
    },
  };
  if (variant === AutomationFormVariant.DEDUP_MATCHING) {
    return <AddDedupMatchingButton {...props} startIconName={isEmptyState ? '' : 'Plus'} />;
  } else
    return (
      <AddAutomationButton
        {...props}
        popoverProps={popoverProps}
        startIconName={isEmptyState ? '' : 'Plus'}
      />
    );
};

export const getDedupMatchingDefaultAutomation = (
  type: DedupMatchingType,
): Partial<AutomationStructureNew> => {
  switch (type) {
    case DedupMatchingType.LEAD_TO_LEAD_DEDUP:
      return {
        name: 'Lead to Lead Deduplication',
        objectName: SFDCObjectType.Lead,
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.LEAD_TO_LEAD_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };

    case DedupMatchingType.CONTACT_TO_CONTACT_DEDUP:
      return {
        name: 'Contact to Contact Deduplication',
        objectName: SFDCObjectType.Contact,
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.CONTACT_TO_CONTACT_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };

    case DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP:
      return {
        name: 'Account to Account Deduplication',
        objectName: SFDCObjectType.Account,
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };

    case DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING:
      return {
        name: 'Lead to Account Matching',
        objectName: SFDCObjectType.Lead,
        type: AutomationType.Matching,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnUpsert,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'MATCHING',
              actionParams: {
                useContinuousSync: false,
                fieldMapping: [],
                dmType: DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING,
              },
            },
          ],
          automationParams: {},
        },
      };
    case DedupMatchingType.LEAD_TO_CONTACT_DEDUP:
      return {
        name: 'Lead to Contact Deduplication',
        objectName: SFDCObjectType.Lead,
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.LEAD_TO_CONTACT_DEDUP,
              },
            },
          ],
          automationParams: {},
        },
      };
    case DedupMatchingType.LEAD_TO_LEAD_MATCHING:
      return {
        name: 'Lead to Lead Matching',
        objectName: SFDCObjectType.Lead,
        type: AutomationType.Matching,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnUpsert,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'MATCHING',
              actionParams: {
                useContinuousSync: false,
                fieldMapping: [],
                dmType: DedupMatchingType.LEAD_TO_LEAD_MATCHING,
              },
            },
          ],
          automationParams: {},
        },
      };
    case DedupMatchingType.CASE_TO_CASE_DEDUP:
      return {
        name: 'Case to Case deduplication',
        objectName: SFDCObjectType.Case,
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.CASE_TO_CASE_DEDUP,
                matchingLogic: {
                  criteriaLogic: '1',
                  criteria: [
                    {
                      criterionId: uniqueId(),
                      fieldType: '',
                      operator: '',
                      value: '',
                      _fieldIds: [],
                      valueType: '',
                    },
                  ],
                },
              },
            },
          ],
          automationParams: {},
        },
      };
    case DedupMatchingType.OPP_TO_OPP_DEDUP:
      return {
        name: 'Opportunity to Opportunity deduplication',
        objectName: SFDCObjectType.Opportunity,
        type: AutomationType.Dedupe,
        isActive: true,
        automationDetails: {
          triggerType: AutomationTypes.OnCreate,
          actions: [
            {
              _id: uniqueId(),
              actionType: 'DEDUP',
              actionParams: {
                mergeActionType: DedupMergeActionType.OVERRIDE_NOTHING,
                dmType: DedupMatchingType.OPP_TO_OPP_DEDUP,
                matchingLogic: {
                  criteriaLogic: '1',
                  criteria: [
                    {
                      criterionId: uniqueId(),
                      fieldType: '',
                      operator: '',
                      value: '',
                      _fieldIds: [],
                      valueType: '',
                    },
                  ],
                },
              },
            },
          ],
          automationParams: {},
        },
      };
  }
};

export const getFunnelMapObjects = (funnelsData: FunnelsData, recordTypesData: RecordTypesData) => {
  const allObjectsUsedInFunnels = Object.values(funnelsData).map((funnel) => {
    return funnel.funnelDetails.leadingObject.objectName;
  });
  const allRecordTypesUsedInFunnels = Object.values(recordTypesData).map((record) => {
    return record.objectName;
  });
  return uniq([...allObjectsUsedInFunnels, ...allRecordTypesUsedInFunnels]).sort();
};

export const getTypeNameFromVariant = (automationVariant: AutomationFormVariant) => {
  switch (automationVariant) {
    case AutomationFormVariant.PLAYBOOK_ALERT:
      return 'alert';
    case AutomationFormVariant.ASSIGNMENT_RULES:
      return 'assignment rule';
    case AutomationFormVariant.DEDUP_MATCHING:
      return 'dedupe matching';
    default:
      return 'automation';
  }
};

export const sortAutomations = ({
  sortBy,
  automations,
  selectedSortOption,
  sortOrder,
}: {
  sortBy?: string;
  automations: AutomationStructureNew[];
  selectedSortOption: any;
  sortOrder?: SortOrder;
}) => {
  return sortBy
    ? automations.sort((automationA, automationB) => {
        let aValue, bValue;
        if (selectedSortOption?.getManualValue) {
          aValue = selectedSortOption?.getManualValue(automationA);
          bValue = selectedSortOption?.getManualValue(automationB);
        } else {
          aValue = get(automationA, selectedSortOption?.dataAccessor ?? '');
          bValue = get(automationB, selectedSortOption?.dataAccessor ?? '');
        }
        if (typeof aValue === 'boolean') {
          aValue = aValue ? '1' : '0';
        }
        if (typeof bValue === 'boolean') {
          bValue = bValue ? '1' : '0';
        }
        return (
          (sortOrder === SortOrder.DESC
            ? bValue?.localeCompare(aValue)
            : aValue?.localeCompare(bValue)) ?? 1
        );
      })
    : automations;
};

export const getIsScheduleValid = (schedule?: AutomationSchedule) => {
  const forAll = schedule?.startDate && schedule?.executeTime && schedule?.timezone;
  let isOffset = !!schedule?.offset;
  if (isOffset) {
    isOffset =
      schedule?.offset?.offsetType &&
      !!schedule?.offset?.offsetValue &&
      !!(schedule?.offset?.offsetValue >= 1) &&
      (schedule?.offset?.offsetType === ScheduledParametersOffsetType.WEEK
        ? (schedule?.offset as ScheduleWeekOffset)?.weekDays &&
          (schedule?.offset as ScheduleWeekOffset)?.weekDays?.length > 0
        : true);
    if (schedule?.endDate) {
      isOffset =
        !!schedule?.endDate &&
        DateTime.fromISO(schedule?.startDate) < DateTime.fromISO(schedule?.endDate);
    }
  } else {
    isOffset = true;
  }
  return forAll && isOffset;
};

export const getOperatorsWithoutRecordsComparison = (operatorList: OperatorWithIndex[]) => {
  const listOfOperatorsToRemove = [
    SweepFieldOperator.HAS_DECREASED,
    SweepFieldOperator.HAS_INCREASED,
    SweepFieldOperator.IS_CHANGED_FALSE,
    SweepFieldOperator.IS_CHANGED_TRUE,
  ];
  return operatorList.filter(({ operator }) => {
    return !listOfOperatorsToRemove.includes(operator);
  });
};

export const AutomationDeploySuccessEventMap = {
  [AutomationType.Default]: ACTIONS_EVENTS.automationsDeploySuccess,
  [AutomationType.Alert]: ACTIONS_EVENTS.alertsDeploySuccess,
  [AutomationType.Dedupe]: ACTIONS_EVENTS.matchingDedupeDeploySuccess,
  [AutomationType.Matching]: ACTIONS_EVENTS.matchingDedupeDeploySuccess,
  [AutomationType.Assignment]: ACTIONS_EVENTS.assignmentsDeploySuccess,
  [AutomationType.ScheduledAssignment]: ACTIONS_EVENTS.scheduledAssignmentsDeploySuccess,
  [AutomationType.ScheduledReport]: ACTIONS_EVENTS.scheduledReportsDeploySuccess,
};

export const AutomationAddEventMap = {
  [AutomationFormVariant.AUTOMATIONS]: ACTIONS_EVENTS.automationsAdd,
  [AutomationFormVariant.PLAYBOOK_ALERT]: ACTIONS_EVENTS.alertsAdd,
  [AutomationFormVariant.DEDUP_MATCHING]: ACTIONS_EVENTS.matchingDedupeAdd,
  [AutomationFormVariant.ASSIGNMENT_RULES]: ACTIONS_EVENTS.assignmentsAdd,
  [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: ACTIONS_EVENTS.scheduledAssignmentsAdd,
  [AutomationFormVariant.SCHEDULED_REPORTS]: ACTIONS_EVENTS.scheduledReportsAdd,
};

export const AutomationDeleteEventMap = {
  [AutomationFormVariant.AUTOMATIONS]: ACTIONS_EVENTS.automationsDelete,
  [AutomationFormVariant.PLAYBOOK_ALERT]: ACTIONS_EVENTS.alertsDelete,
  [AutomationFormVariant.DEDUP_MATCHING]: ACTIONS_EVENTS.matchingDedupeDelete,
  [AutomationFormVariant.ASSIGNMENT_RULES]: ACTIONS_EVENTS.assignmentsDelete,
  [AutomationFormVariant.SCHEDULED_ASSIGNMENTS]: ACTIONS_EVENTS.scheduledAssignmentsDelete,
  [AutomationFormVariant.SCHEDULED_REPORTS]: ACTIONS_EVENTS.scheduledReportsDelete,
};

export const getPropsForBiEvent = (automationId: string, items?: AutomationStructureNew[]) => {
  const item = items?.find((item) => item.automationId === automationId);
  return item
    ? {
        isDeployed: [DeployStatusForTable.Deployed, DeployStatusForTable.Pending].includes(
          item.status,
        ),
        object: item.objectName,
      }
    : undefined;
};

export const validateAnAction = (
  action: DeepPartial<AutomationAction>,
  slackStatus: SlackConnectionStatus,
  objectName?: string,
) => {
  const isFieldValid = (field: AutomationActionField) =>
    AutomationActionType.UpdateRecords
      ? field._fieldInfo?.properties?._required === true
        ? field.fieldType && field._id && field.value
        : field.fieldType && field._id
      : field.fieldType && field._id && field.value;

  switch (action.actionType) {
    case AutomationActionType.AssignmentRules:
      if (action.actionParams?.assignmentRules && action.actionParams.assignmentRules.length > 0) {
        return true;
      }
      break;
    case AutomationActionType.SendEmail:
      return (
        action.actionParams?.emailTemplate &&
        action.actionParams?.toEmailRecipients &&
        action.actionParams.toEmailRecipients.length > 0
      );
    case AutomationActionType.Slack:
      return validateSlackActionParams(slackStatus, action.actionParams);
      break;
    case AutomationActionType.SlackDealRoom:
      if (
        action.actionParams?.members &&
        action.actionParams?.members.length > 0 &&
        action.actionParams?.channel &&
        action.actionParams?.channel?.name &&
        action.actionParams?.channel?.name?.template
      ) {
        return true;
      }
      break;
    case AutomationActionType.SendReportToSlack:
      if (
        action.actionParams?.recipients &&
        action.actionParams?.recipients.length > 0 &&
        action.actionParams?.reportName
      ) {
        return true;
      }
      break;
    case AutomationActionType.SlackAddMember:
      if (
        action.actionParams?.members &&
        action.actionParams?.members.length > 0 &&
        validateChannelRef(action)
      ) {
        return true;
      }
      break;
    case AutomationActionType.SlackRenameDealroom:
      if (
        action.actionParams?.name &&
        action.actionParams?.name?.template &&
        validateChannelRef(action)
      ) {
        return true;
      }
      break;
    case AutomationActionType.SlackArchiveDealroom:
      return validateChannelRef(action);
    case AutomationActionType.ConvertLead:
      if (action.actionParams?.contact && action.actionParams?.contact.recordType === -1) {
        return false;
      }
      if (action.actionParams?.account && action.actionParams?.account.recordType === -1) {
        return false;
      }
      if (action.actionParams?.opportunity && action.actionParams?.opportunity.recordType === -1) {
        return false;
      }
      if (
        action.actionParams?.account?._fields &&
        action.actionParams?.account?._fields?.length > 0
      ) {
        const isInvalidField = action.actionParams.account._fields.find(
          (field) => !isFieldValid(field),
        );
        if (isInvalidField) return false;
      }
      if (
        action.actionParams?.contact?._fields &&
        action.actionParams?.contact?._fields?.length > 0
      ) {
        const isInvalidField = action.actionParams.contact._fields.find(
          (field) => !isFieldValid(field),
        );
        if (isInvalidField) return false;
      }
      if (
        action.actionParams?.opportunity?._fields &&
        action.actionParams?.opportunity?._fields?.length > 0
      ) {
        const isInvalidField = action.actionParams.opportunity._fields.find(
          (field) => !isFieldValid(field),
        );
        if (isInvalidField) return false;
      }
      if (action.actionParams?.convertedStatus) {
        return true;
      }
      break;
    case AutomationActionType.CreateRecord:
    case AutomationActionType.UpdateRecords:
      if (!action.actionParams?._fields) {
        return false;
      }
      if (action.actionParams._fields.length === 0) {
        return false;
      }
      if (
        action.actionParams.relationsRecords &&
        objectName === 'Contact' &&
        action.actionParams.relationsRecords.find(
          (el) => el.objectType === 'OpportunityContactRole',
        )?._isActive &&
        !action.actionParams.relationsRecords.find(
          (el) => el.objectType === 'OpportunityContactRole',
        )?.fields
      ) {
        return false;
      }
      if (action.actionParams.isCriteria && !action.actionParams.criteria) {
        return false;
      }
      return !action.actionParams._fields.find((field) => !isFieldValid(field));
    // eslint-disable-next-line no-duplicate-case
    case AutomationActionType.CreateRecord:
      if (!action.actionParams?.objectTypeName) {
        return false;
      }
      break;
    case AutomationActionType.VerifyURL:
      if (!action.actionParams?.urlFieldId) {
        return false;
      }

      const allValidateActions = flatten(
        action?.actionParams?.verifyResultFollowUps?.map((el) => {
          return flatten(el?.followUpActions?.map((action) => action?.actions));
        }),
      ).filter((el) => !!el);

      if (allValidateActions?.length === 0) {
        return false;
      }
      const areValidUrlActionsValid = allValidateActions.every((action) =>
        validateAnAction(action, slackStatus, objectName),
      );
      if (areValidUrlActionsValid) {
        return true;
      }
      break;
    case AutomationActionType.PrioritizedActions:
      if (
        action?.actionParams?.prioritizedActions &&
        action?.actionParams?.prioritizedActions?.length > 0
      ) {
        return true;
      }
      break;
  }
  return false;
};

// The undefined are for actions that we don't show the labels for now
export const AutomationActionDescriptionByType = {
  [AutomationActionType.CreateRecord]: 'Create record',
  [AutomationActionType.UpdateRecords]: 'Update record',
  [AutomationActionType.SendEmail]: 'Send email',
  [AutomationActionType.ConvertLead]: 'Convert lead',
  [AutomationActionType.Slack]: 'Post to Slack',
  [AutomationActionType.SlackDealRoom]: 'Create deal room',
  [AutomationActionType.SlackAddMember]: 'Add members to deal room',
  [AutomationActionType.VerifyURL]: 'Verify URL',
  [AutomationActionType.SlackRenameDealroom]: 'Rename deal room',
  [AutomationActionType.SlackArchiveDealroom]: 'Archive deal room',
  [AutomationActionType.PrioritizedActions]: 'Branch actions',
  [AutomationActionType.Assignment]: undefined,
  [AutomationActionType.AssignmentRules]: undefined,
  [AutomationActionType.mergeRecordsWebhook]: undefined,
  [AutomationActionType.SendReportToSlack]: undefined,
};

export const buildActionsMenuArray = ({
  automation,
  defaultCreationEnvironment,
  productionEnvironment,
  isAllowedEdit,
  isAllowedCreate,
  isAllowedDeploy,
  isAllowedDelete,
}: {
  automation: AutomationStructureNew;
  defaultCreationEnvironment?: CrmOrg;
  productionEnvironment?: CrmOrg;
  isAllowedEdit: boolean;
  isAllowedCreate: boolean;
  isAllowedDeploy: boolean;
  isAllowedDelete: boolean;
}) => {
  const { isActive, status } = automation;
  const defaultIsProd = defaultCreationEnvironment?.id === productionEnvironment?.id;
  const showOnlyDefault = defaultIsProd || !productionEnvironment;

  const editAndDuplicateItems = [
    {
      label: Commands.Edit,
      value: Commands.Edit,
      disabled: !isAllowedEdit,
      actionIcon: <Edit />,
    },
    {
      label: Commands.Duplicate,
      value: Commands.Duplicate,
      disabled: !isAllowedCreate,
      actionIcon: <Copy />,
    },
  ];
  if (
    automation.automationDetails.actions.find(
      (action) => action.actionType === 'SEND_SLACK_MESSAGE',
    )
  ) {
    editAndDuplicateItems.push({
      label: Commands.Slack_Activity,
      value: Commands.Slack_Activity,
      disabled: !isAllowedEdit,
      actionIcon: <SlackLogo />,
    });
  }
  const activationItem = {
    label: isActive ? 'Deactivate' : 'Activate',
    value: 'onToggleDefault',
    disabled: !isAllowedDeploy,
    actionIcon: isActive ? <LightningSlash /> : <Automations />,
    isNested: !showOnlyDefault,
    nestedTitle: !showOnlyDefault
      ? `Deploy as ${isActive ? 'deactivated' : 'activated'} to:`
      : undefined,
    nestedActions: !showOnlyDefault
      ? [
          {
            label: defaultCreationEnvironment?.name ?? '',
            value: 'onToggleDefault',
          },
          {
            label: (
              <Box display="flex" gap={0.5} alignItems="center">
                <Typography variant="body">
                  {defaultCreationEnvironment?.name} + {productionEnvironment?.name}
                </Typography>
                <OrgDot />
              </Box>
            ),
            value: 'onToggleBoth',
          },
        ]
      : undefined,
  };

  const deleteItem = {
    addBottomDivider: true,
    label: Commands.Delete,
    value: Commands.Delete,
    disabled: !isAllowedDelete,
    actionIcon: <Delete color={colors.blush[600]} />,
    actionSx: { color: colors.blush[600] },
  };

  const deployItem = {
    label: 'Deploy',
    value: 'onDeployDefault',
    disabled: !isAllowedDeploy,
    actionIcon: <SweepBug />,
    isNested: !showOnlyDefault,
    nestedTitle: !showOnlyDefault
      ? `Deploy ${
          automation.type === AutomationType.Default
            ? 'automation'
            : automation.type === AutomationType.ScheduledAssignment
              ? 'scheduled assignment'
              : automation.type.toLowerCase()
        }:`
      : undefined,
    nestedActions: !showOnlyDefault
      ? [
          {
            label: (
              <Box display="flex" gap={0.5} alignItems="center">
                <Typography variant="body">Deploy to {defaultCreationEnvironment?.name}</Typography>
                {defaultIsProd && <OrgDot />}
              </Box>
            ),
            value: 'onDeployDefault',
          },
          {
            label: (
              <Box display="flex" gap={0.5} alignItems="center">
                <Typography variant="body">
                  {defaultCreationEnvironment?.name} + {productionEnvironment?.name}
                </Typography>
                <OrgDot />
              </Box>
            ),
            value: 'onDeployBoth',
          },
        ]
      : undefined,
  };

  const noActivation = status === DeployStatusForTable.Draft;

  return noActivation
    ? [...editAndDuplicateItems, deleteItem, deployItem]
    : [...editAndDuplicateItems, activationItem, deleteItem, deployItem];
};

export const DividerLighter = styled(Divider)({
  borderColor: colors.grey[200],
});
