import { FunnelType } from '../../types/enums/FunnelType';
import { TRANSIENT_GROUP_ID } from './useFunnelMapElements';

export const FULL_NAME_SEPARATOR = '%&?';

export const recordTypeToNodeId = (recordTypeId: string, fullName: string) => {
  return `${recordTypeId}${FULL_NAME_SEPARATOR}${fullName}`;
};

export const nodeIdToRecordTypePicklistFullName = (id: string) => {
  const [, recordTypePicklistFullName] = id.split(FULL_NAME_SEPARATOR);
  return recordTypePicklistFullName;
};

export const getFunnelTypeFromNodeId = (
  funnelMap: FunnelMap,
  nodeId: string,
): FunnelType | undefined => {
  if (Object.keys(funnelMap.funnels).includes(nodeId)) {
    return FunnelType.SALESFORCE;
  }
  if (Object.keys(funnelMap.hubspotFunnels).includes(nodeId)) {
    return FunnelType.HUBSPOT;
  }
  if (Object.keys(funnelMap.thirdPartyFunnels || {}).includes(nodeId)) {
    return FunnelType.THIRD_PARTY;
  }
  if (!Object.keys(funnelMap.recordTypes).includes(nodeId) && nodeId !== TRANSIENT_GROUP_ID) {
    return undefined;
  }
  return FunnelType.RECORD_TYPE;
};
