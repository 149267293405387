import { countBy, uniq } from 'lodash';
import { AutomationTypes } from '../../types/enums/AutomationTypes';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

const getStepIdsFromAutomation = (
  automation: AutomationStructureNew,
  includeDraft?: boolean,
): string[] => {
  const { automationDetails, status } = automation;

  if (!includeDraft && status === DeployStatusForTable.Draft) {
    return [];
  }

  if (automationDetails.triggerType === AutomationTypes.FieldUpdate) {
    return [];
  }

  const stepIds = [
    automationDetails.automationParams.stageEnter?.toStage?._stageId,
    automationDetails.automationParams.stageExit?.toStage?._stageId,
    automationDetails.automationParams.stageExit?.fromStage?._stageId,
    automationDetails.automationParams.timeInStep?.stage?._stageId,
  ];

  return uniq(stepIds.filter(Boolean) as string[]);
};

const calculateAutomationPills = (automations: AutomationStructureNew[]) => {
  //Calculates the number of automations for each step
  const stepIds = automations.flatMap((automation) => getStepIdsFromAutomation(automation, true));
  const stepsCount = countBy(stepIds, (stepId) => stepId);

  //Calculates the number of automations for each funnel
  const funnelIds = automations
    .map((automation) => automation.automationDetails.funnelId)
    .filter(Boolean);

  const funnelsCount = countBy(funnelIds, (funnelId) => funnelId);

  const automationObjectTypes = automations
    .filter((automation) => !Boolean(automation.automationDetails.funnelId))
    .filter((automation) => !Boolean(automation.automationDetails?.recordTypes?.length))
    .map((automation) => automation.objectName);

  const automationObjectTypesCount = countBy(automationObjectTypes, (objectType) => objectType);

  const recordTypes = automations
    .filter((automation) => !Boolean(automation.automationDetails.funnelId))
    .flatMap((automation) =>
      automation.automationDetails?.recordTypes?.map(
        (recordType) => `${automation.objectName}.${recordType.name}`,
      ),
    )
    .filter(Boolean);

  const recordTypesCount = countBy(recordTypes, (recordType) => recordType);

  return {
    steps: stepsCount,
    funnels: funnelsCount,
    objectType: automationObjectTypesCount,
    recordType: recordTypesCount,
  };
};

export default calculateAutomationPills;
