import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useDependencies } from './useDependencies';
import { useSelector } from 'react-redux';
import { selectOpenAiDescriptionForRule } from '../../../../reducers/documentationReducer';
import { SWEEP_ELEMENTS } from '../helpers';
import { whatDoesItDoPossibleTypes } from '../configuration-chat/configurationChatConsts';
import { ConfigurationType } from '../types';
import { ConfigurationItem } from '../DependenciesTypes';

export const useInitializeOpenAiDescriptionForRule = ({
  configurationItem,
  crmOrgId,
  type,
}: {
  configurationItem: ConfigurationItem;
  crmOrgId: string;
  type: ConfigurationType;
}) => {
  const { getOpenAiDescriptionForRule } = useDependencies(crmOrgId);
  const { id, name, objectApiNames, objectName } = configurationItem ?? {};
  const openAiDescription = useSelector(selectOpenAiDescriptionForRule(id, crmOrgId));
  const isSweepElement = SWEEP_ELEMENTS.includes(type);
  const firstObjectApiName = objectName ?? objectApiNames?.[0];
  const isAiAllowed = type && whatDoesItDoPossibleTypes.includes(type);

  useEffect(() => {
    if (
      !openAiDescription &&
      id &&
      name &&
      type &&
      isAiAllowed &&
      !isNil(firstObjectApiName) &&
      !isSweepElement
    ) {
      getOpenAiDescriptionForRule({
        objectName: firstObjectApiName,
        ruleName: name,
        ruleType: type,
        ruleId: id,
        crmOrgId,
      });
    }
  }, [
    crmOrgId,
    openAiDescription,
    getOpenAiDescriptionForRule,
    name,
    firstObjectApiName,
    type,
    id,
    isAiAllowed,
    isSweepElement,
  ]);
};
