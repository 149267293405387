import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { GroupDropZoneData } from '../canvasTypes/nodeTypesData';
import { calculateGroupDimensionsAndPosition } from './helpers';

const VirtualDropGroupNode = ({ data: { positions } }: NodeProps<GroupDropZoneData>) => (
  <div
    className="sweep-group-node"
    style={{
      position: 'relative',
      borderRadius: 8,
      background: 'black',
      opacity: 0.04,
      ...calculateGroupDimensionsAndPosition(positions),
    }}
  />
);

const _memoVirtualDropGroupNode = memo(VirtualDropGroupNode);

export { _memoVirtualDropGroupNode as VirtualDropGroupNode };
