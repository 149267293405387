import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SweepCanvasFiltersMap } from './filterTypes';
import { RootState } from '../../../reducers';
import { FiltersMap } from '../../documentation/selected-object/filters/filtersOptions';
import { ActiveState } from '../../documentation/activity-state/helpers';

const CONSTANT_EMPTY_ARRAY: string[] = [];

export interface CanvasFiltersState {
  funnelMap?: FunnelMap;
  automations?: AutomationStructureNew[];
  crmOrgId?: string;
  filters: SweepCanvasFiltersMap;
  canvasFilterSelectedValues: { [filterKey: string]: string[] };
  canvasFilterItemsData: {
    [filterKey: string]: {
      [value: string]: {
        label: string;
        value: string;
        data: any;
      };
    };
  };
}

const initialState: CanvasFiltersState = {
  filters: {},
  canvasFilterSelectedValues: {},
  canvasFilterItemsData: {},
};

export const canvasFiltersSlice = createSlice({
  name: 'canvasFilters',
  initialState,
  reducers: {
    setCanvasFiltersFilters: (
      state,
      action: PayloadAction<{
        filters: SweepCanvasFiltersMap;
      }>,
    ) => {
      state.filters = action.payload.filters;

      // Remove selected values for filters that are not in the new filters
      Object.keys(state.canvasFilterSelectedValues).forEach((filterKey) => {
        if (filterKey === FiltersMap.activeState) {
          state.canvasFilterSelectedValues[filterKey] = [ActiveState.activeOnly];
          return;
        }
        if (state.filters.hasOwnProperty(filterKey)) return;
        delete state.canvasFilterSelectedValues[filterKey];
      });
    },
    setCanvasFiltersFunnelMap: (state, action: PayloadAction<{ funnelMap: FunnelMap }>) => {
      state.funnelMap = action.payload.funnelMap;
    },
    setCanvasFiltersCrmOrgId: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      state.crmOrgId = action.payload.crmOrgId;
    },
    setCanvasFiltersAutomations: (
      state,
      action: PayloadAction<{
        automations: AutomationStructureNew[];
      }>,
    ) => {
      state.automations = action.payload.automations;
    },
    setCanvasFilterItemsDataFor: (
      state,
      action: PayloadAction<{
        filterKey: string;
        itemsDataByValue: {
          [value: string]: {
            label: string;
            value: string;
            data: any;
          };
        };
      }>,
    ) => {
      state.canvasFilterItemsData[action.payload.filterKey] = action.payload.itemsDataByValue;
    },
    setCanvasFilterItemsDataFromFilterItems: (
      state,
      action: PayloadAction<{
        filterKey: string;
        items: { label: string; value: string; data?: any }[];
      }>,
    ) => {
      const items = action.payload.items;
      const canvasFilterItemsData = Object.fromEntries(
        items.map(({ value, label, data }) => [value, { value, label, data }]),
      );
      state.canvasFilterItemsData[action.payload.filterKey] = canvasFilterItemsData;
    },
    setCanvasFilterSelectedValuesFor: (
      state,
      action: PayloadAction<{
        filterKey: string;
        selectedValues: string[];
      }>,
    ) => {
      state.canvasFilterSelectedValues[action.payload.filterKey] = action.payload.selectedValues;
    },
    setMultipleCanvasFilterSelectedValues: (
      state,
      action: PayloadAction<{
        [filterKey: string]: string[];
      }>,
    ) => {
      const filterValues = action.payload;
      Object.entries(filterValues).forEach(([filterKey, selectedValues]) => {
        state.canvasFilterSelectedValues[filterKey] = selectedValues;
      });
    },
  },
});

export const {
  setCanvasFiltersFilters,
  setCanvasFiltersFunnelMap,
  setCanvasFiltersCrmOrgId,
  setCanvasFiltersAutomations,
  setCanvasFilterItemsDataFor,
  setCanvasFilterSelectedValuesFor,
  setMultipleCanvasFilterSelectedValues,
  setCanvasFilterItemsDataFromFilterItems,
} = canvasFiltersSlice.actions;

export const selectCanvasFiltersFilters = (state: RootState) => state.canvasFilters.filters;
export const selectCanvasFiltersFiltersFor = (filterKey: string) => (state: RootState) =>
  state.canvasFilters.filters[filterKey];
export const selectCanvasFiltersFunnelMap = (state: RootState) => state.canvasFilters.funnelMap;
export const selectCanvasFiltersCrmOrgId = (state: RootState) => state.canvasFilters.crmOrgId;
export const selectCanvasFiltersAutomations = (state: RootState) => state.canvasFilters.automations;

export const selectCanvasFilterSelectedValues = (state: RootState) =>
  state.canvasFilters.canvasFilterSelectedValues;

export const selectCanvasFilterSelectedValuesFor = (filterKey: string) => (state: RootState) =>
  state.canvasFilters.canvasFilterSelectedValues[filterKey] || CONSTANT_EMPTY_ARRAY;

export const selectItemsDataByValue = (state: RootState) =>
  state.canvasFilters.canvasFilterItemsData;

export default canvasFiltersSlice.reducer;
