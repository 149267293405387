import CreateEditField from './CreateEditField';
import { EditMode, FunnelField } from '../../../../../../constants/fieldsManagementConsts';
import { BaseDialog } from '../../../../../common/dialogs/BaseDialog';
import { useState } from 'react';
import useConfirm from '../../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import {
  ConfirmLeaveWithoutSaveDialog
} from '../../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';

const CreateEditFieldDialog = ({
  crmOrgId,
  onCreateFieldCb,
  mode,
  initialValues,
  isOpened,
  closeDialog,
  onUpdateFieldCb,
}: {
  crmOrgId: string;
  isOpened: boolean;
  onCreateFieldCb?: (newField: SweepField) => void;
  onUpdateFieldCb?: (updatedField: SweepField) => void;
  mode: EditMode;
  initialValues: Partial<FunnelField>;
  closeDialog: () => void;
}) => {
  const [isPristine, setIsPristine] = useState(true);
  const { openConfirm } = useConfirm();

  const handleClose = async () => {
    if (!isPristine) {
      const isConfirmed = await openConfirm(
        <ConfirmLeaveWithoutSaveDialog/>);
      if (!isConfirmed) return;
    }
    closeDialog();
  };

  return (
    <BaseDialog
      open={isOpened}
      onClose={handleClose}
      PaperProps={{
        sx: {
          height: 'calc(100vh - 150px)',
          width: '1266px',
        },
      }}
    >
      <CreateEditField
        mode={mode}
        crmOrgId={crmOrgId}
        onCreateFieldCb={onCreateFieldCb}
        onUpdateFieldCb={onUpdateFieldCb}
        initialValues={initialValues}
        closeDialog={closeDialog}
        handleTryCloseDialog={handleClose}
        setIsPristine={setIsPristine}
      />
    </BaseDialog>
  );
};

export default CreateEditFieldDialog;
