import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useThirdPartyFunnelsApi } from './useThirdPartyFunnelsApi';
import { editThirdPartyFunnel } from './thirdPartyFunnelsReducer';
import { FunnelDialogFormValues, ThirdPartyFunnelDialog } from './ThirdPartyFunnelDialog';
import { editThirdPartyFunnelOnCanvas } from '../../reducers/united-canvas/unitedCanvasReducer';

const ConnectedEditThirdPartyFunnelDialog = ({
  isOpen,
  onClose,
  thirdPartyFunnel,
}: {
  thirdPartyFunnel: ThirdPartyFunnelData;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { saveThirdPartyFunnel } = useThirdPartyFunnelsApi();

  const onConfirm = useCallback(
    async ({ name, system, description }: FunnelDialogFormValues) => {
      setIsLoading(true);
      const savedThirdPartyFunnel = await saveThirdPartyFunnel(thirdPartyFunnel.id, {
        name,
        system,
        description,
        funnelLinks: thirdPartyFunnel.funnelLinks,
        steps: thirdPartyFunnel.steps,
        id: thirdPartyFunnel.id,
      });
      dispatch(editThirdPartyFunnel(savedThirdPartyFunnel));
      dispatch(editThirdPartyFunnelOnCanvas(savedThirdPartyFunnel));

      setIsLoading(false);
      onClose();
    },
    [saveThirdPartyFunnel, thirdPartyFunnel, dispatch, onClose],
  );

  return (
    <ThirdPartyFunnelDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      thirdPartyFunnel={thirdPartyFunnel}
      isLoading={isLoading}
      confirmButtonText="Save"
      title="Edit 3rd party funnel"
    />
  );
};

export { ConnectedEditThirdPartyFunnelDialog as EditThirdPartyFunnelDialog };
