import { MouseEvent } from 'react';
import {
  DIMENSION_AVATAR_BIG,
  DIMENSION_AVATAR_MEDIUM,
  DIMENSION_AVATAR_SMALL,
  DIMENSION_AVATAR_BIG_MEDIUM,
  DIMENSION_AVATAR_BIG_BIG_MEDIUM,
  DIMENSION_AVATAR_MEDIUM_SMALL,
} from '../../constants';
import isNil from 'lodash/isNil';

interface Props {
  content: string;
  bgColor: string;
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void;
  dimension: number;
  cursor?: string;
  index?: number;
}

const Emoji = ({ content, bgColor, onClick, dimension, cursor, index }: Props) => {
  let fontSize, centeringFix;
  switch (dimension) {
    case DIMENSION_AVATAR_BIG_BIG_MEDIUM:
      fontSize = '48px';
      break;
    case DIMENSION_AVATAR_BIG:
      fontSize = '85px';
      centeringFix = { top: '2px' };
      break;
    case DIMENSION_AVATAR_BIG_MEDIUM:
      fontSize = '34px';
      centeringFix = { top: '1px' };
      break;
    case DIMENSION_AVATAR_MEDIUM:
      fontSize = '29px';
      centeringFix = { top: '1px' };
      break;
    case DIMENSION_AVATAR_MEDIUM_SMALL:
      fontSize = '24px';
      centeringFix = { top: '1px', left: '1px' };
      break;
    case DIMENSION_AVATAR_SMALL:
    default:
      fontSize = '16px';
      centeringFix = { top: '1px' };
  }

  const dataTestId = isNil(index) ? 'emoji-main' : `emoji-${index}`;

  return (
    <div
      className="avatar-emoji"
      data-testid={dataTestId}
      onClick={onClick}
      style={{
        borderRadius: '50%',
        backgroundColor: bgColor,
        width: dimension,
        height: dimension,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize,
        cursor: onClick ? 'pointer' : cursor,
      }}
    >
      <div style={{ position: 'relative', ...centeringFix }} data-testid="emoji-content">
        {content}
      </div>
    </div>
  );
};

export { Emoji };
