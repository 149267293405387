import { Box, Menu } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { ChevronDown } from '@sweep-io/sweep-design/dist/icons';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { MenuItemsUser } from '../user-menu/MenuItemsUser';
import { UserDialogTabTypes, UserDialogWrapper } from '../user-dialog/UserDialogWrapper';
import { UserSummaryForHeader } from './UserSummaryForHeader';
import usePopover from '../../../hooks/usePopover';
import { useUserDialogContext } from '../user-dialog/UserDialogContext';
import { NotificationsPopover } from '../notification-center/NotificationsPopover';
import useNotifications from './useNotifications';
import WithNotificationDot from '../../common/WithNotificationDot';
import { RoleManagerProvider } from '../role-manager/RoleManagerContext';
import { WebhooksProvider } from '../webhooks/WebhooksContext';

const UserMenu = () => {
  const userInfo = useSelector(selectUserInfoData);
  const { isOpen: isUserDialogOpen, openDialog: openUserDialog } = useUserDialogContext();
  const { isPopoverOpen, closePopover, openPopover, anchorElement, anchorRef } = usePopover();
  const dialogPaperProps = {
    sx: {
      width: '600px',
      height: '780px',
      '.MuiDialogContent-root': {
        padding: '0 24px 8px',
      },
      '.confirm-dialog-header': {
        padding: 3,
      },
    },
  };
  const { notifications, totalUnread } = useNotifications();
  const hasUnreadNotifications = totalUnread > 0;
  const {
    isPopoverOpen: isNotificationsPopoverOpen,
    closePopover: closeNotificationsPopover,
    openPopover: openNotificationsPopover,
  } = usePopover();

  return (
    <Box>
      <Box
        ref={anchorRef}
        id="basic-button"
        aria-controls={isPopoverOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isPopoverOpen ? 'true' : undefined}
        onClick={openPopover}
        sx={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <WithNotificationDot showDot={hasUnreadNotifications}>
          <UserSummaryForHeader user={userInfo} isActive={isPopoverOpen} />
        </WithNotificationDot>
        <IconButton variant="dark" size={'tiny'}>
          <ChevronDown />
        </IconButton>
      </Box>

      <Menu
        id="user-menu"
        anchorEl={anchorElement}
        open={isPopoverOpen}
        onClose={() => closePopover()}
        MenuListProps={{
          'aria-labelledby': 'user-menu-button',
        }}
        slotProps={{
          paper: {
            sx: (theme) => ({
              marginTop: '12px',
              width: '280px',
              boxShadow: theme.shadows[7],
            }),
          },
        }}
        anchorOrigin={{ vertical: 32, horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItemsUser
          openMyProfileDialog={() => openUserDialog(UserDialogTabTypes.PROFILE)}
          openOrgDialog={() => openUserDialog(UserDialogTabTypes.ORG)}
          openIntegrationsDialog={() => openUserDialog(UserDialogTabTypes.INTEGRATIONS)}
          closeMenu={closePopover}
          openNotificationsPopover={openNotificationsPopover}
          isNotificationsPopoverOpen={isNotificationsPopoverOpen}
          hasUnreadNotifications={hasUnreadNotifications}
        />
      </Menu>

      {isUserDialogOpen && (
        <WebhooksProvider>
          <RoleManagerProvider>
            <UserDialogWrapper PaperProps={dialogPaperProps} />
          </RoleManagerProvider>
        </WebhooksProvider>
      )}

      <NotificationsPopover
        notifications={notifications}
        onClose={closeNotificationsPopover}
        anchorEl={anchorElement}
        isOpen={isNotificationsPopoverOpen}
        closeUserMenu={closePopover}
      />
    </Box>
  );
};

export default UserMenu;
