import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface ImportFunnelState {
  objects: { [crmOrgId: string]: ParsedRecordTypesAndFunnels };
}

const initialState: ImportFunnelState = {
  objects: {},
};

export const importFunnelSlice = createSlice({
  name: 'importFunnel',
  initialState,
  reducers: {
    setImportFunnelObjects: (
      state,
      action: PayloadAction<{ objects: ParsedRecordTypesAndFunnels; crmOrgId: string }>,
    ) => {
      const { crmOrgId, objects } = action.payload;
      state.objects[crmOrgId] = objects;
    },
    clearObjects: (state) => {
      state.objects = {};
    },
  },
});

export const { setImportFunnelObjects, clearObjects } = importFunnelSlice.actions;

export const selectObjects = (state: RootState) => state.importFunnel.objects;

export default importFunnelSlice.reducer;
