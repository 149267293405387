import { Box } from '@mui/material';
import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
  ActionableDataTableRow,
} from '../../common/table/ActionableDataTable';
import {
  DataTableDraggableColumn,
  DataTableOnOrderChangeClbk,
  DataTableVariant,
} from '../../common/table/TableTypes';
import { Checkbox, colors, Tooltip, Typography } from '@sweep-io/sweep-design';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { Info } from '@sweep-io/sweep-design/dist/icons';

interface SlackField<T> {
  fieldIds: string[];
  isRequired?: boolean;
  fieldData: T;
  sendInThread?: boolean;
}

interface SlackFieldsProps<
  T extends SlackActionFields | AutomationActionField | SlackFieldsStruct,
> {
  onChange: (fields: SlackField<T>[], fieldsData: T[]) => any;
  actionFields: SlackField<T>[];
  readonly?: boolean;
  isShowThreads?: boolean;
}

interface SlackFieldDataRow {
  id: string;
  priority: string;
  value: string;
}

interface SlackField<T> {
  fieldIds: string[];
  isRequired?: boolean;
  fieldData: T;
}

export function SlackFields<
  T extends SlackActionFields | AutomationActionField | SlackFieldsStruct,
>({ onChange, actionFields, readonly, isShowThreads }: SlackFieldsProps<T>) {
  const { getLabelFromIdsWithObjectType } = useSweepFieldsLabels();

  const getRuleId = (rule: SlackField<T>) => getLabelFromIdsWithObjectType(rule.fieldIds).join('.');

  const columns: DataTableDraggableColumn[] = [
    { field: 'handle', headerName: '', showDragHandle: true, width: '40px' },
    { field: 'value', headerName: '', showDragHandle: false, width: '300px' },
    { field: 'thread', headerName: '', showDragHandle: false, width: '20px' },
  ];

  let rows: (SlackFieldDataRow & ActionableDataTableRow & { id: string })[] = [];
  if (actionFields?.length > 0) {
    rows = actionFields?.map((rule, idx) => {
      const value = getRuleId(rule);
      return {
        id: value,
        priority: '' + idx,
        value,
        thread: isShowThreads && (
          <Checkbox
            checked={rule.sendInThread}
            onChange={(value) => {
              const isChecked = value.target.checked;
              const newElem = { ...rule, sendInThread: isChecked ? true : undefined };
              const newFields = [...actionFields];
              newFields[idx] = newElem;
              onChange(
                newFields,
                newFields.map((f) => f.fieldData),
              );
            }}
          />
        ),
        disabledActions: rule.isRequired ? [ActionableDataTableActionTypes.DELETE] : [],
      };
    });
  }

  const onOrderChange: DataTableOnOrderChangeClbk = ({ sourceIndex, destinationIndex }) => {
    const newFields = [...actionFields];
    newFields.splice(sourceIndex, 1);
    newFields.splice(destinationIndex, 0, actionFields[sourceIndex]);
    onChange(
      newFields,
      newFields.map((f) => f.fieldData),
    );
  };

  return (
    <Box
      className={readonly ? 'readonly' : ''}
      sx={{
        width: '563px',
        border: actionFields?.length > 0 ? `1px solid ${colors.grey[300]}` : 'none',
        borderRadius: '3px',
        position: 'relative',
        '& thead': {
          display: 'none',
        },
      }}
    >
      {rows && rows.length > 0 && (
        <ActionableDataTable
          allowReorder={!readonly}
          columns={columns}
          rows={rows}
          variant={DataTableVariant.narrow}
          sxRowFunction={() => ({
            '& td.SweepDataTableCell.MuiTableCell-root:first-of-type': {
              paddingLeft: '12px !important',
            },
            '.actions button': {
              marginRight: '15px',
            },
            '& td.SweepDataTableCell.MuiTableCell-root:last-of-type': {
              width: '50px',
            },
          })}
          onOrderChange={onOrderChange}
          actions={
            readonly
              ? []
              : [
                  {
                    type: ActionableDataTableActionTypes.DELETE,
                    confirm: false,
                  },
                ]
          }
          onActionClick={({ actionType, row }) => {
            switch (actionType) {
              case ActionableDataTableActionTypes.DELETE:
                const newFields = actionFields.filter((r) => {
                  const value = getRuleId(r);
                  return value !== row.id;
                });
                onChange(
                  newFields,
                  newFields.map((f) => f.fieldData),
                );
                break;
            }
          }}
        />
      )}
      {isShowThreads && (
        <Box
          sx={{
            position: 'absolute',
            top: '-26px',
            right: '80px',
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
          }}
        >
          <Typography variant="body">Send in thread</Typography>
          <Tooltip
            title={
              'Checked items will be sent in thread, unchecked items will be sent in main message'
            }
          >
            <Info />
          </Tooltip>
        </Box>
      )}
    </Box>
  );
}
