const EXPIRE_TOKEN_MS = 30 * 60000; // 30 Minutes
const SLACK_STATE_TOKEN = 'SLACK_STATE_TOKEN';

interface SlackConnectionTransientData {
  randomId: string;
  expires: number;
}

const setSlackStateToken = (randomId: string) => {
  const data: SlackConnectionTransientData = {
    randomId,
    expires: Date.now() + EXPIRE_TOKEN_MS,
  };
  localStorage.setItem(SLACK_STATE_TOKEN, JSON.stringify(data));
};

const getSlackStateToken = () => {
  const slackStateTokenData = localStorage.getItem(SLACK_STATE_TOKEN);
  if (!slackStateTokenData) return;
  try {
    const slackStateToken = JSON.parse(slackStateTokenData) as SlackConnectionTransientData;

    if (slackStateToken.expires > Date.now()) {
      return slackStateToken.randomId;
    }
    localStorage.removeItem(SLACK_STATE_TOKEN);
  } catch {}
};

function randomId(length: number) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export { setSlackStateToken, getSlackStateToken, randomId };
