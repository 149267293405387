import { Box } from '@mui/material';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import StyledTooltip from './StyledTooltip';

const InfoTooltip = ({ title }: { title: NonNullable<React.ReactNode> }) => (
  <StyledTooltip title={title}>
    <Box sx={{ margin: '0 6px', display: 'flex', alignContent: 'center' }}>
      <Icons.Info />
    </Box>
  </StyledTooltip>
);

export { InfoTooltip };
