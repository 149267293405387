import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { DEPLOY_STATUS } from '../types/enums/DeployStatus';

export interface DeploymentsState {
  deploymentsList: FunnelDeployment[];
  isLoading: boolean;
  lastDeploymentStatus: DEPLOY_STATUS | null;
  lastDeploymentErrors: string[];
}

const initialState: DeploymentsState = {
  deploymentsList: [],
  isLoading: true,
  lastDeploymentStatus: null,
  lastDeploymentErrors: [],
};

type DeploymentsActionPayload = {
  deploymentsList?: FunnelDeployment[];
};

export const deploymentsSlice = createSlice({
  name: 'deployments',
  initialState,
  reducers: {
    setDeploymentsList: (state, action: PayloadAction<DeploymentsActionPayload>) => {
      if (action.payload.deploymentsList) {
        state.deploymentsList = action.payload.deploymentsList;
      }
      state.isLoading = false;
    },
    setIsLoadingDeployments: (state) => {
      state.deploymentsList = [];
      state.isLoading = true;
    },
    startDeployment: (state) => {
      state.lastDeploymentStatus = DEPLOY_STATUS.IN_PROGRESS;
    },
    //This action corresponds with websocket message "DEPLOYMENT_COMPLETED" (see "sweep-api/apps/sweep-api/src/websockets/websockets.service.ts"
    deploymentCompleted: (state, { payload }) => {
      if (payload.success) {
        state.lastDeploymentStatus = DEPLOY_STATUS.SUCCESS;
      } else {
        state.lastDeploymentStatus = DEPLOY_STATUS.ERROR;
        state.lastDeploymentErrors = payload['error_message']
          ? payload['error_message'].split('\n')
          : [];
      }
    },
  },
});

export const { setDeploymentsList, setIsLoadingDeployments, startDeployment, deploymentCompleted } =
  deploymentsSlice.actions;

export const selectLastDeploymentErrors = (state: RootState) =>
  state.deployments.lastDeploymentErrors;
export const selectLastDeploymentStatus = (state: RootState) =>
  state.deployments.lastDeploymentStatus;

export const selectDeploymentsList = (state: RootState) => state.deployments.deploymentsList;

export const isLoadingDeploymentsList = (state: RootState) => state.deployments.isLoading;

export default deploymentsSlice.reducer;
