import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

type AssignmentReducersProps = {
  addAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignment: AutomationStructureNew;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignmentToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  toggleAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignmentToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignmentIdToDelete: string;
    }>,
  ) => any;
  updateAssignmentDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const assignmentReducers: AssignmentReducersProps = {
  addAssignment: (state, action) => {
    const { crmOrgId, assignment, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    if (!state.environments[crmOrgId].data.assignments) {
      state.environments[crmOrgId].data.assignments = [];
    }
    state.environments[crmOrgId].data.assignments?.push({
      ...assignment,
      status: optimisticStatus,
    });
  },
  updateAssignment: (state, action) => {
    const { crmOrgId, assignmentToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.assignments) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.assignments?.findIndex(
      (r) =>
        r.automationId === assignmentToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.assignments[indexToUpdate] = {
        ...assignmentToUpdate,
        status: optimisticStatus,
      };
    }
  },
  deleteAssignment: (state, action) => {
    const { crmOrgId, assignmentIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.assignments) {
      return;
    }
    const indexToDelete = state.environments[crmOrgId].data.assignments?.findIndex(
      (r) => r.automationId === assignmentIdToDelete,
    );
    if (indexToDelete !== undefined) {
      state.environments[crmOrgId].data.assignments?.splice(indexToDelete, 1);
    }
  },
  toggleAssignment: (state, action) => {
    const { crmOrgId, assignmentToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.assignments) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.assignments?.findIndex(
      (r) =>
        r.automationId === assignmentToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.assignments[indexToUpdate] = {
        ...assignmentToUpdate,
        status: optimisticStatus,
      };
    }
  },
  updateAssignmentDeployStatus: (state, action) => {
    const { crmOrgId, automationId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.assignments) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.assignments?.findIndex(
      (r) => r.automationId === automationId && r.versionId === versionId,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.assignments[indexToUpdate].status = optimisticStatus;
    }
  },
};

export const selectCrmOrgAssignments = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.assignments;
};

export const selectAssignments = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgAssignments(crmOrg.id)(state);
  }
};
