import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useDeploymentsApi } from '../../../apis/facades/useDeploymentsApi';
import { useFunnelMapApiFacade } from '../../../apis/facades/funnel-map/useFunnelMapApiFacade';
import { FunnelPatchPayload, useFunnelsApiFacade } from '../../../apis/facades/useFunnelsApiFacade';
import { setInitialFunnelMap } from '../../../reducers/united-canvas/unitedCanvasReducer';
import { updateStageMetadataForLeadingField } from '../../../reducers/multiFunnelFlowNoHistoryReducer';
import usePermission from '../../common/permissions/usePermission';
import SweepStagesModel from '../../../models/stagesModel';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import { StageMetadataType } from '../../../types/enums/StageMetadataTypes';
import { FunnelWithMaybeFields } from '../../../sweep-fields/types';
import { telemetry } from '../../../telemetry';

type SaveFunnelDataProps = {
  id: string;
  crmOrgId?: string | null;
} & FunnelPatchPayload;

export const useFunnelMapFlowPageApi = () => {
  const dispatch = useDispatch();

  const { get_funnelMap } = useFunnelMapApiFacade();
  const { patch_funnel } = useFunnelsApiFacade();
  const { post_deploymentsCrmOrg } = useDeploymentsApi();
  const [isAllowedSaveFunnel] = usePermission(['edit:funnels']);
  const { editField } = useSweepFields();

  const loadFunnelMap = async (funnelMapId: string) => {
    const funnelMap = (await get_funnelMap(funnelMapId, true)) as FunnelMap;

    dispatch(setInitialFunnelMap(funnelMap));
  };

  const saveFunnelData = useCallback(
    async (props: SaveFunnelDataProps) => {
      const { id, crmOrgId, ...payload } = props;
      const funnel = await patch_funnel(id, payload, crmOrgId);
      dispatch(
        updateStageMetadataForLeadingField({ leadingFieldStageMetadata: funnel.stageMetadata }),
      );

      if (funnel.deduceLeadingFieldValuesOrderEnabled) {
        //This function is checking if the order of the valueSet of the leading field is matching a certain product logic
        //If it doesn't, the field is updated accordingly
        //product deck: https://docs.google.com/presentation/d/1JXL13YyWH-I6u4SokcwkhzntmhFgT7rNDDQOFyk-Iq0/edit#slide=id.g157ca2e4dde_0_0
        await syncLeadingFieldsWithStagesOrder({ funnel, editField });
      }

      return funnel;
    },
    [dispatch, editField, patch_funnel],
  );

  const deployFunnelDetail = async ({
    funnelId,
    snapshotId,
    orgId,
  }: {
    funnelId?: string;
    snapshotId?: string;
    orgId: string;
  }) =>
    post_deploymentsCrmOrg({
      crmOrgId: orgId,
      async: true,
      payload: { funnelId, snapshotId },
    });

  const saveAndDeployFunnelDetail = async ({
    funnelId,
    snapshotId,
    funnelDetail,
    orgId,
    onFailedSaveCallback,
  }: {
    snapshotId?: string;
    funnelId: string;
    orgId: string;
    funnelDetail: FunnelDetails;
    onFailedSaveCallback: () => void;
  }) => {
    if (isAllowedSaveFunnel) {
      try {
        await saveFunnelData({ id: funnelId, funnelDetails: funnelDetail, crmOrgId: orgId });
      } catch (e) {
        onFailedSaveCallback();
        return;
      }
    }
    await deployFunnelDetail({
      funnelId,
      snapshotId,
      orgId,
    });
  };

  return { loadFunnelMap, saveFunnelData, saveAndDeployFunnelDetail };
};

const syncLeadingFieldsWithStagesOrder = async ({
  funnel,
  editField,
}: {
  funnel: FunnelWithMaybeFields;
  editField: ({ field }: { field: SweepField }) => void;
}) => {
  const stagesModel = new SweepStagesModel(funnel.funnelDetails.stages);
  const { _leadingFieldId } = funnel.funnelDetails.leadingObject;
  if (!funnel.sweepFields) {
    throw new Error(`funnel.sweepFields in funnel ${funnel.id} is undefined`);
  }
  const leadingField = funnel.sweepFields[_leadingFieldId];

  const deducedOrderStageIds = stagesModel.deduceStageOrder();
  const deducedOrderStageNames = deducedOrderStageIds.map((stageId) =>
    stagesModel.stageById(stageId).getStageName(),
  );

  const closedStagesNames =
    funnel.stageMetadata
      ?.filter(
        (metadata) =>
          metadata.leadingFieldId === _leadingFieldId &&
          [StageMetadataType.CLOSED_LOST, StageMetadataType.CLOSED_WON].includes(metadata.type),
      )
      .map((stage) => stage.stageName) ?? [];

  const currentValueSet: PicklistValue[] = leadingField.properties.valueSet ?? [];
  const currentOrderStageNames = currentValueSet?.map((valueSet) => valueSet.fullName) ?? [];

  const deducedClosedAtEnd = moveClosedToEnd(deducedOrderStageNames, closedStagesNames);

  if (
    !SweepStagesModel.isCorrectValueSetOrder(
      deducedClosedAtEnd,
      currentOrderStageNames,
      closedStagesNames,
    )
  ) {
    const newValueSet =
      SweepStagesModel.getValueSetNewOrder(
        deducedClosedAtEnd,
        currentValueSet,
        closedStagesNames,
      ) ?? [];

    const updatedField: SweepField = {
      ...leadingField,
      properties: { ...leadingField.properties, valueSet: newValueSet },
    };

    try {
      editField({ field: updatedField });
    } catch (e) {
      telemetry.captureError(e);
    }
  }
};

const moveClosedToEnd = (allStageNames: string[], closedStagesNames: string[]) => {
  const res = [...allStageNames];
  closedStagesNames.forEach((closedStageName) => {
    res.push(res.splice(res.indexOf(closedStageName), 1)[0]);
  });
  return res;
};
