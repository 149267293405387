import { useCallback } from 'react';
import useGlobalApiFacade from '../../apis/facades/useGlobalApiFacade';
import { getRollupsListForUi } from '../../components/pages/rollups/rollupHelpers';
import {
  selectLoadingStatuses,
  setGlobalError,
  setGlobalData,
  setGlobalIsLoading,
} from '../../reducers/global/globalReducer';
import { GlobalData } from '../../reducers/global/globalReducerTypes';
import { useDispatch, useSelector } from 'react-redux';
import { transformResponse } from '../../components/parser/parserUtils';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import axios from 'axios';
import { selectCrmOrgs } from '../../components/pages/environments/environmentsReducer';
import { isOrgConnectionError } from './environmentsHelper';
import isEqual from 'lodash/isEqual';
import { telemetry } from '../../telemetry';

type AutomationsMap = { [automationId: string]: AutomationStructureNew };

const getAutomationsListForUi = (automations?: AutomationStructureNew[]) => {
  const automationsMap: AutomationsMap | undefined = automations?.reduce(
    (res: AutomationsMap, automation) => {
      if (res[automation.automationId]) {
        //If the automation already exist in the map, it means this automation should be presented with status "Pending"
        //the newItem should get the versionId from the "Draft"
        let newItem;
        if (res[automation.automationId].status === 'Deployed') {
          newItem = {
            ...automation,
            deployedVersionId: res[automation.automationId].versionId,
            status: DeployStatusForTable.Pending,
          };
        } else {
          newItem = {
            ...res[automation.automationId],
            deployedVersionId: automation.versionId,
            status: DeployStatusForTable.Pending,
          };
        }
        res[automation.automationId] = newItem;
      } else {
        res[automation.automationId] = automation;
      }
      return res;
    },
    {},
  );
  return automationsMap ? Object.values(automationsMap) : undefined;
};

export const useFetchGlobal = () => {
  const dispatch = useDispatch();
  const { get_global } = useGlobalApiFacade();
  const isLoadingStatuses = useSelector(selectLoadingStatuses);
  const crmOrgs = useSelector(selectCrmOrgs, isEqual);

  const fetchGlobal = useCallback(
    async ({ crmOrgId }: { crmOrgId: string }) => {
      const isLoading = isLoadingStatuses[crmOrgId]?.isLoading;
      if (isLoading) return;

      const orgIdx = crmOrgs.findIndex((crmOrg) => crmOrg.id === crmOrgId);
      if (orgIdx < 0) return;

      const orgFetchStatus = crmOrgs[orgIdx].fetchStatus;
      const isConnectionError = isOrgConnectionError(orgFetchStatus);
      if (isConnectionError) return;

      dispatch(setGlobalIsLoading({ crmOrgId }));
      try {
        const data = await get_global(crmOrgId);

        const {
          // we are not storing comments on this reducer
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          comments,
          rollups,
          assignments,
          automations,
          alerts,
          dedup,
          matching,
          scheduledAssignments,
          scheduledReports,
          ...rest
        } = data;
        const globalData: GlobalData = {
          ...rest,
          rollups: getRollupsListForUi(rollups),
          assignments: getAutomationsListForUi(assignments),
          automations: getAutomationsListForUi(automations),
          documentation: transformResponse(data),
          alerts: getAutomationsListForUi(alerts),
          dedup: getAutomationsListForUi(dedup),
          matching: getAutomationsListForUi(matching),
          scheduledAssignments: getAutomationsListForUi(scheduledAssignments),
          scheduledReports: getAutomationsListForUi(scheduledReports),
        };

        dispatch(
          setGlobalData({
            crmOrgId,
            globalData,
          }),
        );
        return;
      } catch (error) {
        telemetry.captureError(error);
        if (axios.isAxiosError(error)) {
          dispatch(setGlobalError({ crmOrgId, error: error.response?.data }));
        }
      }
    },
    //removed "isLoadingStatuses" from dep array because it cases an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, get_global, crmOrgs],
  );

  return { fetchGlobal };
};
