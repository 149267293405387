import { useEffect, useRef } from 'react';

const useRunOnceWhenTruthy = (cb: () => any, condition: boolean) => {
  const isRun = useRef(false);

  useEffect(() => {
    if (condition && !isRun.current) {
      cb && cb();
      isRun.current = true;
    }
  }, [cb, condition]);
};

export { useRunOnceWhenTruthy };
