import { SearchSelect, SearchSelectItem } from '../../../../common/SearchSelect';
import { selectHubspotFields } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { HubspotField } from '../hubspot.types';

interface HubspotFieldSelectorProps {
  onChange: (field: SearchSelectItem<HubspotField>) => void;
  value?: string;
  disabled?: boolean;
}

export const HubspotFieldSelector = ({ onChange, value, disabled }: HubspotFieldSelectorProps) => {
  const hubspotFields = useSelector(selectHubspotFields);

  const items: SearchSelectItem<HubspotField>[] = useMemo(() => {
    return (
      hubspotFields?.map((field) => ({
        label: field.label,
        value: field.name,
        data: field,
      })) || []
    );
  }, [hubspotFields]);

  return (
    <SearchSelect
      items={items}
      FormControlProps={{ fullWidth: true }}
      onChange={onChange}
      SelectProps={{
        value,
      }}
      removeBorders
      disabled={disabled}
    />
  );
};
