import {
  AttributeProps,
  textAreaCommonProps,
} from '../../../../../constants/fieldsManagementConsts';
import { Box } from '@mui/material';
import SweepTextField from '../../../../common/SweepTextField';

interface AttributeTextFieldProps extends AttributeProps {
  id: string;
  label: string;
  attributeName: keyof SweepFieldProperties;
  type?: string;
  disabled?: boolean;
  disabledTooltip?: string;
  isRequired?: boolean;
  inputProps?: Object;
}

const AttributeTextField = ({
  attributeName,
  label,
  id,
  setFieldValue,
  values,
  errors,
  type = 'text',
  disabled,
  disabledTooltip,
  isRequired,
  inputProps,
}: AttributeTextFieldProps) => {
  const errorText = typeof errors.properties === 'string' ? '' : errors.properties?.[attributeName];
  return (
    <Box>
      <SweepTextField
        label={label}
        autoFocus={false}
        labelTooltip={disabledTooltip ? disabledTooltip : ''}
        id={id}
        value={values.properties?.[attributeName]?.toString() ?? ''}
        onChange={(e) => {
          setFieldValue(
            `properties.${attributeName}`,
            type === 'number' ? Number(e?.target.value) : e?.target.value,
          );
        }}
        disableConfirmPropagation
        markLabelAsRequired={isRequired}
        TextFieldProps={{
          placeholder: 'Add',
          disabled,
          helperText: errorText?.toString(),
          error: Boolean(errorText),
          type,
          ...(type === 'textarea' ? textAreaCommonProps : {}),
          inputProps,
        }}
        FormControlProps={{
          fullWidth: true,
        }}
      />
    </Box>
  );
};

export default AttributeTextField;
