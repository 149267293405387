import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import { GlobalDto } from '../../reducers/global/globalReducerTypes';
import { useDispatch } from 'react-redux';
import { assignFieldLabelsFromGlobalDTO } from '../../sweep-fields/sweepFieldsLabelsReducer';

const useGlobalApiFacade = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();

  const get_global = useCallback(
    async (crmOrgId: string) => {
      const response = await sweepApi.get(`/global/${crmOrgId}`);

      const globalDto = response.data as GlobalDto;
      dispatch(assignFieldLabelsFromGlobalDTO({ globalDto }));
      return globalDto;
    },
    [dispatch, sweepApi],
  );

  return { get_global };
};

export default useGlobalApiFacade;
