import { v4 as uuidv4 } from 'uuid';
import { NextStageTypeOptions } from '../types/enums/NextStageTypeOptions';
import { criteriaModel } from './criteriaModel';

const anExitCriterion = ({
  _exitCriteriaId = uuidv4(),
  criteria = [],
  criteriaLogic = '',
  _nextStageId = '',
  name,
  nextStageType = NextStageTypeOptions.STAGE,
  isAutoMoveToNextStage = false,
}: Partial<SweepExitCriteria>): SweepExitCriteria => ({
  _exitCriteriaId,
  criteria,
  criteriaLogic,
  _nextStageId: _nextStageId,
  name,
  nextStageType,
  isAutoMoveToNextStage,
});

const exitCriterionModel = (exitCriteria: SweepExitCriteria) => {
  const criteriaByIdx = (idx: number) => criteriaModel(exitCriteria.criteria[idx]);

  const getExitCriteriaId = () => exitCriteria._exitCriteriaId;

  const deleteCriteriaByIdx = (idx: number) => {
    const _criteria = [...exitCriteria.criteria];
    _criteria.splice(idx, 1);

    exitCriteria = {
      ...exitCriteria,
      criteria: [..._criteria],
    };
  };

  const toExitCriteria = () => exitCriteria;
  const addCriteria = (criteria: SweepCriterion) => {
    if (!exitCriteria.criteria) {
      exitCriteria.criteria = [];
    }

    exitCriteria = {
      ...exitCriteria,
      criteria: [...exitCriteria.criteria, criteria],
    };
  };

  const setCriteriaAtIndex = (criteria: SweepCriterion, index: number) => {
    let newCriteria = exitCriteria.criteria;
    newCriteria = newCriteria.map((item, inx) => {
      if (inx !== index) {
        return item;
      }
      return criteria;
    });
    exitCriteria = {
      ...exitCriteria,
      criteria: newCriteria,
    };
  };

  const setCriteriaLogic = (criteriaLogic: string) => {
    exitCriteria.criteriaLogic = criteriaLogic;
  };

  const setIsAutoMoveToNextStage = (isAutoMoveToNextStage: boolean) => {
    exitCriteria.isAutoMoveToNextStage = isAutoMoveToNextStage;
  };

  const setNextStage = (stage: SweepStage) => {
    exitCriteria._nextStageId = stage._stageId;
  };

  const setNextStageId = (stageId: string) => {
    exitCriteria._nextStageId = stageId;
  };

  const getNextStageId = () => exitCriteria._nextStageId;

  const toJSON = (): SweepExitCriteria => exitCriteria;

  const setGuidance = (guidance?: string) => {
    exitCriteria.guidance = guidance;
  };

  const setPartialExitCriteria = (partialExitCriteria: Partial<SweepExitCriteria>) => {
    Object.keys(partialExitCriteria).forEach((k) => {
      (exitCriteria as any)[k] = partialExitCriteria[k as keyof SweepExitCriteria];
    });
  };

  const updateIsAutoMoveToNextStageBasedOnCriteria = () => {
    if (!exitCriteria.criteria.length) {
      exitCriteria.isAutoMoveToNextStage = false;
    }
  };

  return {
    criteriaByIdx,
    getExitCriteriaId,
    deleteCriteriaByIdx,
    toExitCriteria,
    addCriteria,
    setCriteriaLogic,
    setNextStage,
    setNextStageId,
    getNextStageId,
    setIsAutoMoveToNextStage,
    toJSON,
    setCriteriaAtIndex,
    setGuidance,
    setPartialExitCriteria,
    updateIsAutoMoveToNextStageBasedOnCriteria,
  };
};

export { exitCriterionModel, anExitCriterion };
