import { createContext, useContext, useState } from 'react';

type ValueFromAnotherObjectEditorState = {
  [key: string]: boolean;
};

interface ValueFromAnotherObjectEditorStateContextType {
  valueFromAnotherObjectEditorState?: ValueFromAnotherObjectEditorState;
  anyOtherObjectEditorOpenExists?: boolean;
  setValueFromAnotherObjectEditorStatePerKey?: ({
    key,
    value,
  }: {
    key: string;
    value: boolean;
  }) => void;
  isValueFromAnotherObjectEditorOpenedPerKey?: (key: string) => boolean;
}

const ValueFromAnotherObjectEditorStateContext =
  createContext<ValueFromAnotherObjectEditorStateContextType>({});

const ValueFromAnotherObjectEditorStateContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [valueFromAnotherObjectEditorState, setValueFromAnotherObjectEditorState] =
    useState<ValueFromAnotherObjectEditorState>({});

  const setValueFromAnotherObjectEditorStatePerKey = ({
    key,
    value,
  }: {
    key: string;
    value: boolean;
  }) => {
    setValueFromAnotherObjectEditorState({ ...valueFromAnotherObjectEditorState, [key]: value });
  };
  const isValueFromAnotherObjectEditorOpenedPerKey = (key: string): boolean =>
    valueFromAnotherObjectEditorState[key] ?? false;
  const anyOtherObjectEditorOpenExists = Object.values(valueFromAnotherObjectEditorState).some(
    (val) => val,
  );

  return (
    <ValueFromAnotherObjectEditorStateContext.Provider
      children={children}
      value={{
        valueFromAnotherObjectEditorState,
        anyOtherObjectEditorOpenExists,
        setValueFromAnotherObjectEditorStatePerKey,
        isValueFromAnotherObjectEditorOpenedPerKey,
      }}
    />
  );
};

const useValueFromAnotherObjectEditorState = () => {
  const context = useContext(ValueFromAnotherObjectEditorStateContext);
  if (!context.valueFromAnotherObjectEditorState) {
    throw new Error(
      'useValueFromAnotherObjectEditorState must be used within a ValueFromAnotherObjectEditorStateContextProvider!',
    );
  }
  return context;
};

export { ValueFromAnotherObjectEditorStateContextProvider, useValueFromAnotherObjectEditorState };
