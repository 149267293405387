import { useEffect, useState } from 'react';
import { useSweepFieldsApiFacade } from '../../../../apis/facades/useSweepFieldsApiFacade';

export const useLoadCampaignStatusAndTypePklValues = ({ crmOrgId }: { crmOrgId: string }) => {
  const { get_sweepFields } = useSweepFieldsApiFacade();

  const [campaignTypeOptions, setCampaignTypeOptions] = useState<{ name: string; label: string }[]>(
    [],
  );
  const [campaignStatusOptions, setCampaignStatusOptions] = useState<
    { name: string; label: string }[]
  >([]);

  useEffect(() => {
    const get = async () => {
      const fields = await get_sweepFields({
        crmOrgId,
        objectType: ['Campaign'],
        fieldIds: ['Campaign.Type', 'Campaign.Status'],
        includeSiblings: false,
      });
      const campaignFields = fields.sweepFields.find((field) => field.objectType === 'Campaign');
      const campaignTypeField = campaignFields?.fields.find(
        (field) => field.id === 'Campaign.Type',
      );
      const campaignStatusField = campaignFields?.fields.find(
        (field) => field.id === 'Campaign.Status',
      );

      if (campaignStatusField?.properties?.valueSet) {
        setCampaignStatusOptions(
          campaignStatusField.properties.valueSet.map(({ label, fullName }) => ({
            name: fullName,
            label,
          })),
        );
      }
      if (campaignTypeField?.properties?.valueSet) {
        setCampaignTypeOptions(
          campaignTypeField.properties.valueSet.map(({ label, fullName }) => ({
            name: fullName,
            label,
          })),
        );
      }
    };
    get();
  }, [crmOrgId, get_sweepFields]);

  return { campaignTypeOptions, campaignStatusOptions };
};
