import { Box } from '@mui/material';
import CloudDisconnectedIllustration from '../../../../icons/svg/CloudDisconnectedIllustration.svg?react';
import { Button, Typography } from '@sweep-io/sweep-design';

export const DefaultEnvConnectionErrorPage = () => {
  const message: any = 'Salesforce environment temporarily unavailable.';
  const buttonText = 'Reload';
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexDirection: 'column',
      }}
    >
      <CloudDisconnectedIllustration />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
        <Typography variant="h3">Connection Failed</Typography>
        <Typography variant="body">{message}</Typography>
      </Box>

      <Button
        onClick={() => {
          window.location.reload();
        }}
      >
        {buttonText}
      </Button>
    </Box>
  );
};
