import { Box } from '@mui/material'
import { Button, colors, Typography } from '@sweep-io/sweep-design'
import { useState } from 'react';
import { CompleteSfSetupDialog } from '../../../common/complete-sf-setup/CompleteSfSetupDialog';

export enum SfErrorType {
  Mp = 'Mp', // Managed Package
  Unknown = 'Unknown',
}

const errorData = {
  [SfErrorType.Mp]: {
    title: 'Sweep Managed Package must be installed',
    description: 'To add users to your group, first install Sweep Managed Package in Salesforce.',
  },
  [SfErrorType.Unknown]: {
    title: `Couldn't access your Salesforce Users`,
    description: `Due to technical reasons, we couldn't access your users. Please reach out to Sweep for further assistance.`,
  },
};


export const MembersAutocompleteError = ({ serverError }: { serverError: SfErrorType }) => {
  const [completeSetupDialogOpen, setCompleteSetupDialogOpen] = useState(false);
  return <>
    <Box
      sx={{
        display: 'flex',
        border: '1px dashed',
        borderColor: colors.grey[300],
        borderRadius: '12px',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '4px',
            flexDirection: 'column',
            color: colors.grey[800],
          }}
        >
          <Typography variant="body-bold">{errorData[serverError].title}</Typography>
          <Typography variant="body">{errorData[serverError].description}</Typography>
        </Box>
        <Box>
          {serverError === SfErrorType.Mp && (
            <Button onClick={() => setCompleteSetupDialogOpen(true)}>Complete setup</Button>
          )}
        </Box>
      </Box>
    </Box>
    {completeSetupDialogOpen && (
      <CompleteSfSetupDialog
        cancelDialog={() => setCompleteSetupDialogOpen(false)}
        onCompleteItemsCallback={() => setCompleteSetupDialogOpen(false)}
        title="Complete Your Setup"
        subtitle="Get started with Sweep by completing these quick steps."
      />
    )}
  </>
}