import { Box, Button, ButtonBase, TableCell, TableRow as MuiTableRow } from '@mui/material';
import { useState, ReactNode } from 'react';
import {
  Copy as CopyIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@sweep-io/sweep-design/dist/icons';
import { DataTable } from './DataTable';
import {
  DataTableDraggableColumn,
  DataTableDraggableProps,
  DataTableRow,
  DataTableSortableProps,
  RenderRow,
} from './TableTypes';
import { Typography, colors } from '@sweep-io/sweep-design';

export type DataTableOnActionClickClbk = ({
  actionType,
  row,
}: {
  actionType: ActionableDataTableActionTypes;
  row: DataTableRow;
}) => any;

export enum ActionableDataTableActionTypes {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  DUPLICATE = 'DUPLICATE',
}

type ActionableDataTableActionItemType<T = string> = T | ((id: string, row: DataTableRow) => T);

export interface ActionableDataTableAction {
  type: ActionableDataTableActionTypes;
  confirm?: boolean;
  confirmTitle?: ActionableDataTableActionItemType;
  confirmSubtitle?: ActionableDataTableActionItemType;
  confirmButtonCancelLabel?: ActionableDataTableActionItemType;
  confirmButtonOkLabel?: ActionableDataTableActionItemType;
  confirmActionIcon?: ActionableDataTableActionItemType<ReactNode | undefined>;
}

const dataTableActionsIconMap = {
  [ActionableDataTableActionTypes.EDIT]: <EditIcon />,
  [ActionableDataTableActionTypes.DELETE]: <DeleteIcon />,
  [ActionableDataTableActionTypes.DUPLICATE]: <CopyIcon />,
};

interface ActionableDataTableOnlyProps {
  actions?: ActionableDataTableAction[];
  onActionClick?: DataTableOnActionClickClbk;
}

export interface ActionableDataTableRow extends DataTableRow {
  disabledActions?: ActionableDataTableActionTypes[];
}

type ActionableDataTableProps<TRow extends ActionableDataTableRow = any> = (
  | DataTableDraggableProps<TRow>
  | DataTableSortableProps<TRow>
) &
  ActionableDataTableOnlyProps;

const ACTION_ICON_WIDTH = 16;
const ACTIONS_PADDING = 48;

export function ActionableDataTable<TRow extends ActionableDataTableRow = any>({
  rows,
  columns,
  actions,
  onActionClick,
  ...dataTableProps
}: ActionableDataTableProps<TRow>) {
  const [confirmAction, setConfirmAction] = useState<{
    action: ActionableDataTableAction;
    rowId: string;
  }>();

  const hasActions = Boolean(actions?.length);
  const width = ((actions?.length || 0) + 1) * ACTION_ICON_WIDTH + ACTIONS_PADDING;

  const columnsWithAction: DataTableDraggableColumn[] = hasActions
    ? [
        ...columns,
        {
          field: '__actions',
          headerName: ' ',
          width: width + 'px',
        },
      ]
    : columns;

  const onActionClickWithPossibleConfirmation = ({
    row,
    action,
  }: {
    row: TRow;
    action: ActionableDataTableAction;
  }) => {
    if (action.confirm) {
      setConfirmAction({ action, rowId: row.id });
    } else {
      onActionClick?.({
        actionType: action.type,
        row,
      });
    }
  };

  const ResizedIcon = ({ children }: { children: ReactNode }) => (
    <Box
      sx={{
        svg: {
          width: '16px',
          height: '16px',
        },
        'svg path': {
          stroke: colors.grey[800],
        },
      }}
    >
      {children}
    </Box>
  );

  const rowsWithActions = hasActions
    ? rows.map((row) => ({
        ...row,
        __actions: row.disabled ? null : (
          <Box className="SweepActionableDataActions">
            {actions
              ?.filter((action) => !row.disabledActions?.includes(action.type))
              .map((action) => (
                <ButtonBase
                  key={action.type}
                  disableRipple
                  onClick={(e) => {
                    e.stopPropagation();
                    onActionClickWithPossibleConfirmation({
                      action,
                      row,
                    });
                  }}
                >
                  <ResizedIcon>{dataTableActionsIconMap[action.type]}</ResizedIcon>
                </ButtonBase>
              ))}
          </Box>
        ),
      }))
    : rows;

  const renderRow: RenderRow = ({ row }) => {
    const { id } = row;
    if (!confirmAction || confirmAction.rowId !== id) {
      return undefined;
    }
    const processActionItem = (action?: ActionableDataTableActionItemType<string | ReactNode>) => {
      if (!action) {
        return;
      }
      if (typeof action === 'function') {
        return action(id, row);
      } else {
        return action;
      }
    };
    return (
      <MuiTableRow>
        <TableCell colSpan={columnsWithAction.length} sx={{ padding: '0' }}>
          <Box
            display="flex"
            justifyContent="space-between"
            sx={{
              border: '1px solid',
              borderColor: colors.blush[500],
              background: colors.grey[100],
              color: colors.grey[800],
              padding: '12px 24px',
            }}
          >
            <Box display="flex" gap="8px" sx={{ alignItems: 'center' }}>
              <ResizedIcon>
                {processActionItem(confirmAction.action.confirmActionIcon) ||
                  dataTableActionsIconMap[confirmAction.action.type]}
              </ResizedIcon>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h4">
                  {processActionItem(confirmAction.action.confirmTitle) || 'Confirm'}
                </Typography>
                <Typography variant="body">
                  {processActionItem(confirmAction.action.confirmSubtitle) || 'Are you sure?'}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '8px',
              }}
            >
              <Button
                sx={{ height: '32px' }}
                size="small"
                color="secondary"
                onClick={() => setConfirmAction(undefined)}
              >
                {processActionItem(confirmAction.action.confirmButtonCancelLabel) || 'Cancel'}
              </Button>
              <Button
                sx={{ height: '32px' }}
                size="small"
                color="error"
                onClick={() => {
                  setConfirmAction(undefined);
                  onActionClick &&
                    onActionClick({
                      actionType: confirmAction.action.type,
                      row,
                    });
                }}
              >
                {processActionItem(confirmAction.action.confirmButtonOkLabel) || 'Ok'}
              </Button>
            </Box>
          </Box>
        </TableCell>
      </MuiTableRow>
    );
  };

  const _allowReorder = 'allowReorder' in dataTableProps ? dataTableProps.allowReorder : false;

  return (
    <DataTable
      {...dataTableProps}
      allowReorder={!confirmAction && _allowReorder}
      columns={columnsWithAction}
      rows={rowsWithActions}
      renderRow={renderRow}
      sxRowFunction={(isDragging: boolean) => ({
        '.SweepActionableDataActions': {
          display: 'none',
        },
        '&:hover .SweepActionableDataActions': {
          display: isDragging ? 'none' : 'flex',
          gap: '16px',
          justifyContent: 'flex-end',
          flex: 1,
        },
        ...(dataTableProps.sxRowFunction ? dataTableProps.sxRowFunction(isDragging) : {}),
      })}
    />
  );
}
