import { AvatarPicture } from '../avatar/AvatarPicture';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../constants';
import { getIsUserInactive } from '../../lib/getIsUserActive';
import { Box } from '@mui/material';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';
import keyBy from 'lodash/keyBy';
import { useSelector } from 'react-redux';
import { selectAccountUsers } from '../../reducers/accountUsersReducer';

const UserNameForTable = ({ userId }: { userId: string }) => {
  const accountUsers = keyBy(useSelector(selectAccountUsers), 'id');
  const user = accountUsers[userId];

  if (!user) return null;

  return (
    <Box display="flex" gap={0.5} alignItems="center" overflow="hidden">
      <AvatarPicture
        avatar={{ emoji: user.emoji, imageUrl: user.imageUrl }}
        dimension={DIMENSION_AVATAR_VERY_SMALL}
        clickable={false}
        isInactive={getIsUserInactive(user.status)}
      />
      <TruncatedTextTooltip variant="body">{user.name}</TruncatedTextTooltip>
    </Box>
  );
};

export default UserNameForTable;
