import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { AppRoutesWithTitles } from '../constants/appRoutes';

export const DEFAULT_TITLE = 'Sweep';

const useUpdatePageTitles = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const relevantAppRoute = AppRoutesWithTitles.find((appRoute) =>
      pathname.startsWith(appRoute.route),
    );
    const title = relevantAppRoute?.documentTitle ?? DEFAULT_TITLE;
    document.title = title;
  }, [pathname]);
};

export default useUpdatePageTitles;
