import * as React from "react";
const SvgAddSweepTemplate = (props) => /* @__PURE__ */ React.createElement("svg", { width: 122, height: 80, viewBox: "0 0 122 80", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 30.5, y: 13, width: 67, height: 66, rx: 1.5, fill: "#F8F9FA", stroke: "#EDEDED", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1,
  stroke: "#EDEDED",
  stroke: "color(display-p3 0.9292 0.9292 0.9292)",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 38, y: 20.5, width: 24, height: 12, rx: 2, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { y: 52.1982, width: 24, height: 12, rx: 2, transform: "rotate(-16.2059 0 52.1982)", fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 9, y: 31.9023, width: 24, height: 12, rx: 2, transform: "rotate(-3.35015 9 31.9023)", fill: "#25D5F0", stroke: "white", style: {
  fill: "#25D5F0",
  fill: "color(display-p3 0.1466 0.8343 0.9425)",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 2, strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("rect", { x: 38, y: 36.5, width: 52, height: 12, rx: 2, fill: "#F8BCF2", style: {
  fill: "#F8BCF2",
  fill: "color(display-p3 0.9708 0.7362 0.9474)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 71.072, y: 1.6718, width: 50, height: 10, rx: 1, transform: "rotate(10.9539 71.072 1.6718)", fill: "#F8BCF2", stroke: "white", style: {
  fill: "#F8BCF2",
  fill: "color(display-p3 0.9708 0.7362 0.9474)",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 2 }), /* @__PURE__ */ React.createElement("rect", { x: 38, y: 52.5, width: 52, height: 19, rx: 2, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 66, y: 20.5, width: 24, height: 12, rx: 2, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M97.2846 11.5889C97.0576 11.4317 96.7461 11.4884 96.5889 11.7154C96.4317 11.9424 96.4884 12.2539 96.7154 12.4111L97.2846 11.5889ZM79.2293 41.5796C78.9971 41.7291 78.9301 42.0385 79.0796 42.2707L81.5156 46.0543C81.6651 46.2865 81.9745 46.3535 82.2067 46.204C82.4389 46.0545 82.5059 45.7451 82.3564 45.5129L80.1911 42.1497L83.5543 39.9844C83.7865 39.8349 83.8535 39.5255 83.704 39.2933C83.5545 39.0611 83.2451 38.9941 83.0129 39.1436L79.2293 41.5796ZM96.7154 12.4111C103.04 16.7896 106.081 25.6895 103.898 32.6631C102.813 36.1276 100.44 39.1203 96.5158 40.8634C92.5803 42.6116 87.0397 43.122 79.6059 41.5113L79.3941 42.4887C86.9603 44.128 92.7322 43.6384 96.9217 41.7773C101.123 39.9109 103.687 36.6849 104.852 32.9619C107.169 25.5605 103.96 16.2104 97.2846 11.5889L96.7154 12.4111Z", fill: "#F371E6", style: {
  fill: "#F371E6",
  fill: "color(display-p3 0.9542 0.4413 0.9029)",
  fillOpacity: 1
} }));
export default SvgAddSweepTemplate;
