import { Box, Popover } from '@mui/material';
import { Button, IconButton, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';
import { ActionsMenu, ActionsMenuAction } from '../../../../common/actions-menu/ActionsMenu';
import ConfirmDestructiveDialog from '../../../../common/dialogs/ConfirmDestructiveDialog';
import { useState } from 'react';
export interface PluginPanelData {
  funnelId: string;
  anchorEl: HTMLElement;
  isReadOnly?: boolean;
}

export interface BasePluginPanelProps extends BasePluginPanelHeaderProps {
  anchorEl: HTMLElement;
  children: React.ReactNode;
}
const MARGIN_THRESHOLD = 100;

export const BasePluginPanel = ({
  maybeClosePanel,
  anchorEl,
  children,
  ...otherHeaderProps
}: BasePluginPanelProps) => {
  return (
    <Popover
      open
      anchorEl={anchorEl}
      onClose={() => maybeClosePanel()}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{ vertical: -20, horizontal: 6 }}
      slotProps={{
        paper: {
          sx: {
            // Arrow
            overflow: 'visible',
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 16,
              width: 10,
              height: 10,
              backgroundColor: 'inherit',
              transform: 'translateY(-50%) rotate(45deg)',
              boxShadow: '-3px -3px 5px -2px rgba(0,0,0,0.1)',
            },
          },
        },
      }}
    >
      <Box>
        <Box p={3} pb={0}>
          <BasePluginPanelHeader {...otherHeaderProps} maybeClosePanel={maybeClosePanel} />
        </Box>
        <Box
          sx={{
            maxHeight: `calc(100vh - ${
              MARGIN_THRESHOLD + anchorEl?.getBoundingClientRect().bottom
            }px)`,
            overflowY: 'auto',
            overflowX: 'hidden',
          }}
        >
          <Box p={3} pt={0}>
            {children}
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

interface BasePluginPanelHeaderProps {
  title: string;
  pluginIcon: React.ReactNode;
  maybeClosePanel: () => void;
  onRemove: () => void;
  headerButton?: {
    buttonTxt?: string;
    disabled: boolean;
    onConfirm: () => void;
    isButtonLoading?: boolean;
  };
  onDeactivate?: () => void;
  withActiveTag?: boolean;
}

const BasePluginPanelHeader = ({
  title,
  pluginIcon,
  maybeClosePanel,
  onRemove,
  headerButton,
  withActiveTag,
  onDeactivate,
}: BasePluginPanelHeaderProps) => {
  const { onConfirm, buttonTxt = 'Done', disabled, isButtonLoading } = headerButton ?? {};
  const [showDeactivateDialog, setShowDeactivateDialog] = useState(false);

  const removeAction: ActionsMenuAction = {
    label: 'Remove',
    value: 'remove',
  };

  const deactivateAction: ActionsMenuAction = {
    label: 'Deactivate',
    value: 'deactivate',
  };

  const onActionClick = (action: ActionsMenuAction) => {
    switch (action.value) {
      case removeAction.value:
        onRemove();
        break;
      case deactivateAction.value:
        setShowDeactivateDialog(true);
        break;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        gap: 3,
        marginBottom: '12px',
      }}
    >
      <ConfirmDestructiveDialog
        open={showDeactivateDialog}
        title="Deactivate Dynamic Path Plugin?"
        onClose={() => setShowDeactivateDialog(false)}
        onConfirm={async () => {
          onDeactivate && (await onDeactivate());
          setShowDeactivateDialog(false);
        }}
        confirmText="Deactivate"
        cancelText="Cancel"
        children="Deactivating this plugin will remove it from the Salesforce layout, but it will remain in the funnel on the canvas."
      />
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
        <Box
          sx={{
            svg: {
              viewbox: '0 0 32 32',
              width: '32px',
              height: '32px',
            },
          }}
        >
          {pluginIcon}
        </Box>
        <Typography variant="h2">{title}</Typography>
        {withActiveTag && <Tag label={'Active'} color={colors.mint[100]} />}
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {!!headerButton && (
          <Button
            variant="filled"
            size="small"
            onClick={onConfirm}
            disabled={disabled}
            loading={isButtonLoading}
          >
            {buttonTxt}
          </Button>
        )}
        <ActionsMenu
          iconSize="small"
          actions={withActiveTag ? [deactivateAction, removeAction] : [removeAction]}
          onClick={onActionClick}
        />
        <IconButton variant="flat" size="small" onClick={() => maybeClosePanel()}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
};
