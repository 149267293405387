import { colors } from '@sweep-io/sweep-design';
import { Divider } from '@mui/material';

const HeaderDivider = () => (
  <Divider
    orientation="vertical"
    sx={{
      height: '21px',
      borderColor: colors.night[700],
    }}
  />
);

export default HeaderDivider;
