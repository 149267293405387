import { colors } from '@sweep-io/sweep-design';
import { AutomationActionType } from '../../../types/enums/AutomationActionType';
import { AssignmentToType } from '../../../types/enums/AssignmentToType';
import _ from 'lodash';
import { SweepFilterEndpoint } from '../../../types/enums/DedupMatchingType';
import { FormControlLabel, styled } from '@mui/material';
import { uniqueId } from '../../../lib/uniqueId';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';

export const minMaxSupportedTypes = [SweepFieldTypes.Geolocation];

export const greatestSupportedTypes = [
  SweepFieldTypes.Number,
  SweepFieldTypes.Currency,
  SweepFieldTypes.Percent,
];

export const latestSupportedTypes = [
  SweepFieldTypes.DateTime,
  SweepFieldTypes.Date,
  SweepFieldTypes.Time,
];

export const concatenateSupportedTypes = [
  SweepFieldTypes.TextArea,
  SweepFieldTypes.Text,
  SweepFieldTypes.LongTextArea,
];

export const prioritizeSupportedTypes = [
  SweepFieldTypes.Picklist,
  SweepFieldTypes.MultiselectPicklist,
];

export enum DedupMergeActionType {
  OVERRIDE_NOTHING = 'OVERRIDE_NOTHING',
  OVERRIDE_NEW_VALUES = 'OVERRIDE_NEW_VALUES',
  OVERRIDE_ALL = 'OVERRIDE_ALL',
  OVERRIDE_BY_CONVERT = 'OVERRIDE_BY_CONVERT',
}

export enum AssignmentActionSelectionType {
  Empty = 'Empty',
  Assign = 'Assign',
  Condition = 'Condition',
}
export enum DefaultFieldsData {
  AccountOwnerId = 'Account.OwnerId',
  LeadOwnerId = 'Lead.OwnerId',
  OwnerLabel = 'Owner ID',
}
export const greyLayout = {
  backgroundColor: colors.grey[100],
  padding: '24px 24px 24px 0',
  marginTop: '12px',
  borderRadius: '4px',
};
const pseudoArea = {
  content: '""',
  position: 'absolute',
  left: 0,
  width: '100%',
  height: '24px',
};
export const settingsPanelLayout = {
  borderRadius: '4px',
  border: '1px solid',
  borderColor: colors.grey[300],
  padding: '16px',
  marginTop: '12px',
  position: 'relative',
  '.panelHeader': {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    '&::after': {
      ...pseudoArea,
      bottom: 0,
    },
    '&::before': {
      ...pseudoArea,
      top: 0,
    },
  },
};

export const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  width: 'fit-content',
}));

const getMatchingAction = (matchingObject: DeepPartial<AutomationStructureNew>) => {
  return matchingObject?.automationDetails?.actions?.[0] as
    | MatchingAutomationAction
    | DeduplicationAutomationAction;
};

export const getAssignAction = (matchingObject: DeepPartial<AutomationStructureNew>) => {
  return getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0]?.actions?.find(
    (el) => el.actionType === AutomationActionType.Assignment,
  ) as AssignmentAutomationAction;
};

export const getMatchingCondition = (matchingObject: DeepPartial<AutomationStructureNew>) => {
  return getMatchingAction(matchingObject).actionParams?.followUpActions?.[0]?.when?.criteria;
};

export const getMergeRecordWebhookAction = (
  matchingObject: DeepPartial<AutomationStructureNew>,
) => {
  return getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0]?.actions?.find(
    (el) => el.actionType === AutomationActionType.mergeRecordsWebhook,
  ) as DeduplicationMergeRecordsWebhookAction;
};

export const getFollowUpAction = (matchingObject: DeepPartial<AutomationStructureNew>) => {
  return getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0]?.actions ?? [];
};

export const getAssignActionType = (matchingObject: DeepPartial<AutomationStructureNew>) => {
  const assignObj = getAssignAction(matchingObject);
  const assignCondition = getMatchingCondition(matchingObject);
  // This returns the  value of the Assign Action.
  // There are 3 types of possible Assignment action based on existence of Condition and / or fieldMapping:
  // 1. Empty - no fieldMapping & no condition - no Action,
  // 2. Field - only fieldMapping, no Condition,
  // 3. Condition - both fieldMapping and Condition.
  if (!assignObj) return AssignmentActionSelectionType.Empty;
  const assignParams = assignObj.actionParams;
  if (!assignParams) return AssignmentActionSelectionType.Empty;
  if (assignParams.assignedTo.type === AssignmentToType.Field) {
    if (assignCondition) return AssignmentActionSelectionType.Condition;
    return AssignmentActionSelectionType.Assign;
  }
  return AssignmentActionSelectionType.Empty;
};

export const getMappingAssignArray = (matchingObject: DeepPartial<AutomationStructureNew>) => {
  const tmpObj = getAssignAction(matchingObject)?.actionParams?.assignedTo?.fieldMapping;
  if (tmpObj && !_.isArray(tmpObj)) return [tmpObj];
  else return [];
};

export const getNotifyAction = (matchingObject: DeepPartial<AutomationStructureNew>) => {
  return getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0]?.actions?.find(
    (el) =>
      el.actionType === AutomationActionType.SendEmail ||
      el.actionType === AutomationActionType.Slack,
  ) as EmailAutomationAction | SlackAutomationAction;
};

export const getHandleUpdateCondition = (
  matchingObject: DeepPartial<AutomationStructureNew>,
  exitCriteria: SweepCondition,
) => {
  const newElem2 = {
    ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
    when: {
      isCriteria: true,
      criteria: {
        criteria: exitCriteria.criteria,
        criteriaLogic: exitCriteria.criteriaLogic,
      },
    },
  };
  return [newElem2];
};

export const getFiltersByType = (
  matchingObject: DeepPartial<AutomationStructureNew>,
  endpoint: SweepFilterEndpoint,
) => {
  const _filters = getMatchingAction(matchingObject)?.actionParams?.filters;
  const byType = _filters?.find((el) => el?.endpoint === endpoint);
  return byType ? [byType] : undefined;
};

export const getFiltersByTypeToSet = (
  matchingObject: DeepPartial<AutomationStructureNew>,
  filter: SweepFilter | undefined,
  endpoint: SweepFilterEndpoint,
) => {
  const currentEndpoint = filter?.endpoint ?? endpoint;
  const _filters = getMatchingAction(matchingObject)?.actionParams?.filters;
  const filterToSave = _filters?.filter((el) => el?.endpoint !== currentEndpoint);
  return filterToSave
    ? filter
      ? [...filterToSave, filter]
      : filterToSave
    : filter
      ? [filter]
      : undefined;
};

export const handleInnerNotifyAction = (
  matchingObject: DeepPartial<AutomationStructureNew>,
  automationAction: EmailAutomationAction | SlackAutomationAction | null,
) => {
  let newArr: any = [];
  const notifyAction = getNotifyAction(matchingObject);
  if (automationAction) {
    if (getFollowUpAction(matchingObject).length === 0) {
      const tempAction: AutomationConditionalActions = {
        _id: automationAction._id,
        _name: automationAction.actionType,
        actions: [automationAction],
      };
      newArr = [tempAction];
    } else if (notifyAction) {
      const onlyNewArr = getFollowUpAction(matchingObject)?.map((el) => {
        return el.actionType === AutomationActionType.SendEmail ||
          el.actionType === AutomationActionType.Slack
          ? automationAction
          : el;
      });
      newArr = [
        {
          ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
          actions: onlyNewArr,
        },
      ];
    } else {
      newArr = [
        {
          ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
          actions: [...getFollowUpAction(matchingObject), automationAction],
        },
      ];
    }
  } else {
    if (notifyAction) {
      const oldNewArr = getFollowUpAction(matchingObject)?.filter(
        (el) =>
          el?.actionType !== AutomationActionType.SendEmail &&
          el?.actionType !== AutomationActionType.Slack,
      );
      newArr = [
        {
          ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
          actions: oldNewArr,
        },
      ];
    }
  }
  return newArr;
};

export const handleMatchSelection = (
  matchingObject: DeepPartial<AutomationStructureNew>,
  value: AssignmentActionSelectionType,
  defaultFieldStruct: FieldMappingStruct,
) => {
  if (value === AssignmentActionSelectionType.Empty) {
    const followUps = getFollowUpAction(matchingObject);
    const newArray = followUps.filter((el) => el?.actionType !== 'ASSIGNMENT');
    if (newArray.length) {
      return [
        {
          ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
          actions: newArray,
          when: undefined,
        },
      ];
    } else return undefined;
  }
  if (value === AssignmentActionSelectionType.Assign) {
    const assignObj = getAssignAction(matchingObject);
    let newObj: AssignmentAutomationAction;
    if (assignObj) {
      newObj = {
        ...assignObj,
        actionParams: {
          ...assignObj.actionParams,
          when: undefined,
        },
      };
      const newArr = getFollowUpAction(matchingObject).map((el) =>
        el?.actionType === 'ASSIGNMENT' ? newObj : el,
      );
      const newElem = {
        ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
        actions: newArr,
        when: undefined,
      };
      return [newElem];
    } else {
      newObj = {
        _id: uniqueId(),
        actionType: 'ASSIGNMENT',
        actionParams: {
          assignedTo: {
            id: uniqueId(),
            type: AssignmentToType.Field,
            fieldMapping: defaultFieldStruct,
          },
        },
      };
      if (getFollowUpAction(matchingObject).length === 1) {
        const newElem = {
          ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
          actions: [...getFollowUpAction(matchingObject), newObj],
          when: undefined,
        };
        return [newElem];
      } else {
        const tempAction: AutomationConditionalActions = {
          _id: newObj._id,
          _name: newObj.actionType,
          actions: [newObj],
          when: undefined,
        };
        return [tempAction];
      }
    }
  }
  if (value === AssignmentActionSelectionType.Condition) {
    const assignObj = getAssignAction(matchingObject);
    let newObj: AssignmentAutomationAction;
    if (assignObj) {
      newObj = {
        ...assignObj,
        actionParams: {
          ...assignObj.actionParams,
        },
      };
      const newArr = getFollowUpAction(matchingObject).map((el) =>
        el?.actionType === 'ASSIGNMENT' ? newObj : el,
      );
      const newElem = {
        ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
        actions: newArr,
        when: {
          isCriteria: true,
          criteria: {
            criteria: [],
            criteriaLogic: '',
          },
        },
      };
      return [newElem];
    } else {
      newObj = {
        _id: uniqueId(),
        actionType: 'ASSIGNMENT',
        actionParams: {
          assignedTo: {
            id: uniqueId(),
            type: AssignmentToType.Field,
            fieldMapping: defaultFieldStruct,
          },
        },
      };
      if (getFollowUpAction(matchingObject).length === 1) {
        const newElem = {
          ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
          actions: [...getFollowUpAction(matchingObject), newObj],
          when: {
            isCriteria: true,
            criteria: {
              criteria: [],
              criteriaLogic: '',
            },
          },
        };
        return [newElem];
      } else {
        const tempAction: AutomationConditionalActions = {
          _id: newObj._id,
          _name: newObj.actionType,
          actions: [newObj],
          when: {
            isCriteria: true,
            criteria: {
              criteria: [],
              criteriaLogic: '',
            },
          },
        };
        return [tempAction];
      }
    }
  }
};

export const getAssignSelection = (
  matchingObject: DeepPartial<AutomationStructureNew>,
  fields: FieldMappingStruct[],
) => {
  const tmpObj = { ...getAssignAction(matchingObject) };
  const newObj = {
    ...tmpObj,
    actionParams: {
      ...tmpObj.actionParams,
      assignedTo: {
        ...tmpObj.actionParams.assignedTo,
        fieldMapping: fields[0],
      },
    },
  };
  const newArr = getFollowUpAction(matchingObject).map((el) =>
    el?.actionType === 'ASSIGNMENT' ? newObj : el,
  );
  const newElem = {
    ...getMatchingAction(matchingObject)?.actionParams?.followUpActions?.[0],
    actions: newArr,
  };
  return [newElem];
};
