import { Stack } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectProductionCrmOrg } from '../../../environments/environmentsReducer';
import useUserInfo from '../../../../../hooks/useUserInfo';
import { Hubspot } from '@sweep-io/sweep-design/dist/icons';

// This design is a placeholder for the HubspotSwitchToProduction component
// Not yet fully designed

export const HubspotSwitchToProduction = () => {
  const productionOrg = useSelector(selectProductionCrmOrg);
  const { updateDefaultCreationCrmOrgId } = useUserInfo();
  if (!productionOrg) {
    throw new Error('Production org not found');
  }

  return (
    <Stack gap={3} height="100%" alignItems="center" justifyContent="center">
      <Stack alignItems="center" gap={4}>
        <Stack gap={1} maxWidth="384px" alignItems="center" textAlign="center">
          <Stack direction="row" gap={1} alignItems="center">
            <Hubspot variant="large" />
            <Typography variant="h3">Hubspot Campaign Enrollments</Typography>
          </Stack>

          <Typography variant="body">Hubspot only works in production</Typography>
        </Stack>
        <Stack>
          <Button
            onClick={() => {
              updateDefaultCreationCrmOrgId(productionOrg.id);
            }}
          >
            Switch to Production
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
