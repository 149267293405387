import DialogOnCanvas from '../canvas-pages/DialogOnCanvas';
import { PageTemplateDialogContent } from './PageTemplateDialogContent';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { useExpandedMode } from '../configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { useAutomationsContext } from '../../Automations/AutomationsContext';
import { AutomationFormVariant } from '../../../types/enums/AutomationFormVariant';

export const PageTemplateCanvasDialog = ({
  children,
  isOpen,
  onCancel,
  title,
  transitionDuration,
}: {
  isOpen: boolean;
  onCancel?: () => any;
  children: React.ReactNode;
  title: string;
  transitionDuration?: number;
}) => {
  const { expandedModeRouting } = useFeatureToggle();
  const { automationVariant } = useAutomationsContext();
  const { isExpandedMode } = useExpandedMode();
  const relevantFeatureToggle = [
    AutomationFormVariant.ASSIGNMENT_RULES,
    AutomationFormVariant.SCHEDULED_ASSIGNMENTS,
  ].includes(automationVariant)
    ? expandedModeRouting
    : true;

  const _isExpandedMode = relevantFeatureToggle && isExpandedMode;

  return (
    <DialogOnCanvas
      open={isOpen}
      onClose={onCancel}
      blockFully={_isExpandedMode}
      forceWideScreen={_isExpandedMode}
      transitionDuration={transitionDuration}
    >
      <PageTemplateDialogContent title={title} onCancel={onCancel}>
        {children}
      </PageTemplateDialogContent>
    </DialogOnCanvas>
  );
};
