import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { selectAlerts } from '../../reducers/global/alertsReducers';
import { automationsSortOptions } from '../pages/canvas-pages/consts';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { useGetAutomationFilters } from '../pages/canvas-pages/automationFilters';
import { AutomationAlertsContextProvider } from './AutomationAlertsContextProvider';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';

interface AlertsProps {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  disableCanvasTemplates?: boolean;
  setHoveredItem?: (automationId?: string | undefined) => void;
}

const Alerts = ({
  recordTypesData,
  funnelsData,
  disableCanvasTemplates,
  setHoveredItem,
}: AlertsProps) => {
  const alerts = useSelector(selectAlerts);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.PLAYBOOK_ALERT });
  const filters = useGetAutomationFilters(alerts);
  const { isExpandedMode } = useExpandedMode();
  const renderCanvasFilters = !isExpandedMode;

  if (!alerts) return <CenteredCircularProgress />;

  return (
    <>
      {crmOrg && (
        <AutomationDialogContent
          automations={alerts ?? []}
          onDeleteAutomation={onDeleteAutomation}
          crmOrgId={crmOrg.id}
          onSaveOrCreate={onSaveOrCreate}
          recordTypesData={recordTypesData}
          funnelsData={funnelsData}
          onToggleActivation={onToggleActivation}
          sortOptions={automationsSortOptions}
          disableCanvasTemplates={disableCanvasTemplates}
          setHoveredItem={setHoveredItem}
          onDeployAutomation={onDeployAutomation}
          filtersForExpandedMode={filters}
        />
      )}
      {renderCanvasFilters && <ConfigurationCanvasFilters filters={filters} automations={alerts} />}
    </>
  );
};

const AlertsWithContext = (alertsProps: AlertsProps) => {
  return (
    <AutomationAlertsContextProvider>
      <Alerts {...alertsProps} />
    </AutomationAlertsContextProvider>
  );
};

export default AlertsWithContext;
