import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

export const useTerritoriesApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_territories: useCallback(async () => {
      const response = await sweepApi.get(`/territories`);
      return response.data as Territory[];
    }, [sweepApi]),
    post_territories: useCallback(
      async (toBeCreated: Territory) => {
        const response = await sweepApi.post(`/territories`, toBeCreated);
        return response.data as Territory;
      },
      [sweepApi],
    ),
    put_territories: useCallback(
      async (_id: string, toBeCreated: Territory) => {
        const response = await sweepApi.put(`/territories/${_id}`, toBeCreated);
        return response.data as Territory;
      },
      [sweepApi],
    ),
    delete_territories: useCallback(
      async (_id: string) => {
        const response = await sweepApi.delete(`/territories/${_id}`);
        return response.data as boolean;
      },
      [sweepApi],
    ),
  };
};
