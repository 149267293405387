import { useState } from 'react';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import SelectNoBorder from './SelectNoBorder';
import { Typography, colors } from '@sweep-io/sweep-design';

const useSortWidget = ({
  defaultValue,
  sortOptions,
}: {
  defaultValue: string;
  sortOptions: string[];
}) => {
  const [sortBy, setSortBy] = useState(defaultValue);

  const onChange = (e: SelectChangeEvent<unknown>) => {
    const value = e.target.value as string;
    setSortBy(value);
  };

  const SortWidget = (
    <Grid container width="auto" alignItems="center" flexWrap="nowrap" data-testid="sort-widget">
      <Typography variant="body-bold">Sort:</Typography>
      <Grid container justifyContent="flex-end">
        <SelectNoBorder
          value={sortBy}
          onChange={onChange}
          sx={{
            '& .MuiSelect-select': {
              display: 'flex',
              alignContent: 'center',
              flexWrap: 'wrap',
            },
            '.MuiSelect-icon': { top: 'unset' },
          }}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
            sx: {
              '.MuiMenu-paper': {
                width: '160px',
              },
            },
          }}
        >
          {sortOptions.map((option) => (
            <MenuItem key={option} value={option}>
              <Typography variant="body" color={colors.black}>
                {option}
              </Typography>
            </MenuItem>
          ))}
        </SelectNoBorder>
      </Grid>
    </Grid>
  );

  return { SortWidget, sortBy };
};

export default useSortWidget;
