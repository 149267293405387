export enum UserInputTypes {
  Tags = 'tags',
  Owner = 'owner',
  Collaborators = 'collaborators',
}

export type TagId = string;
export type UserId = string;

export interface UpdatableTagProperties {
  name?: string;
  color?: string;
}

export interface ConfigurationUserInputs {
  [UserInputTypes.Tags]: TagId[];
  [UserInputTypes.Owner]: UserId;
  [UserInputTypes.Collaborators]: UserId[];
}

export interface UserInputsByConfigurationId {
  [configurationId: string]: ConfigurationUserInputs;
}

export interface UserConfigurationsByUserType {
  [UserInputTypes.Owner]: string[];
  [UserInputTypes.Collaborators]: string[];
}

export interface ConfigurationCommentProperties {
  pinned: boolean;
}
