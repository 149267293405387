import { Grid, Box } from '@mui/material';
import { Tooltip, colors, Tag } from '@sweep-io/sweep-design';
import { TruncatedTextTooltip } from '../../../common/TruncatedTextTooltip';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { useSelector } from 'react-redux';
import { selectUserById } from '../../../../reducers/accountUsersReducer';
import { DIMENSION_AVATAR_SMALL } from '../../../../constants';
import { AvatarPicture } from '../../../avatar/AvatarPicture';
import { getIsUserInactive } from '../../../../lib/getIsUserActive';

const FunnelMapMetaInfo = ({
  currentVersion,
  isDeployment,
  name,
  hasRequestedChanges,
}: {
  currentVersion: CurrentVersion;
  name?: string;
  isDeployment: boolean;
  hasRequestedChanges?: boolean;
}) => {
  const lastUpdate = currentVersion?.updatedAt;
  const userId = currentVersion?.updatedById;
  const user = useSelector(selectUserById(userId));
  const displayDate = humanizeDate({
    dateOrTimestamp: lastUpdate,
  });

  return (
    <Grid container alignItems="center" justifyContent={name ? 'space-between' : 'flex-end'}>
      {name && (
        <Grid
          item
          display="flex"
          alignItems="center"
          flexWrap="nowrap"
          maxWidth="85%"
          data-testid="funnel-card-name"
        >
          <TruncatedTextTooltip variant="h4">{name}</TruncatedTextTooltip>
          {hasRequestedChanges && (
            <Box mx={1}>
              <Tag color={colors.blush[300]} label="Pending requests" />
            </Box>
          )}
        </Grid>
      )}
      <Grid item display="flex" alignItems="center" flex="0 0 40px" justifyContent="flex-end">
        {user && (
          <Tooltip
            placement="top"
            title={`${isDeployment ? 'Deployed by' : 'Last updated by'} ${user.name}${
              displayDate ? `, ${displayDate}` : ''
            }`}
          >
            <Box height="32px" display="flex" alignItems="center">
              <AvatarPicture
                avatar={{ emoji: user?.emoji, imageUrl: user?.imageUrl }}
                dimension={DIMENSION_AVATAR_SMALL}
                clickable={false}
                isInactive={getIsUserInactive(user.status)}
              />
            </Box>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export { FunnelMapMetaInfo };
