import { ElementsUsedByClient } from '@server/read-only-elements.consts';
import {
  Timer,
  Settings,
  Notification,
  Filter,
  Mail,
  Play,
  Automations,
  MathOperations,
  Cube,
  Assign,
  PlaybookAlert,
  Copy,
  Target,
  Hash,
  Path,
  Users,
  Clock,
} from '@sweep-io/sweep-design/dist/icons';
import { ReactNode } from 'react';
import { ConfigurationType } from '../../../../types';

export const propertiesToIcon: { [key: string]: ReactNode } = {
  name: <Hash />,
  trigger: <Play />,
  conditions: <Path />,
  delay: <Timer />,
  actions: <Automations />,
  settings: <Settings />,
  notification: <Notification />,
  calculation: <MathOperations />,
  filter: <Filter />,
  lookup_relations: <Filter />,
  field: <Cube />,
  [ElementsUsedByClient.ASSIGNMENT_ASSIGN_TO]: <Assign />,
  [ElementsUsedByClient.SEND_SLACK_MESSAGE]: <Notification />,
  [ElementsUsedByClient.MET_CONDITIONS]: <Path />,
  [ElementsUsedByClient.ASSIGNMENT_DO_NOT_NOTIFY]: <Notification />,
  [ElementsUsedByClient.SEND_EMAIL]: <Mail />,
  [ElementsUsedByClient.ASSIGNMENT_RULES]: <Users />,
  [ElementsUsedByClient.UPDATE_RECORDS]: <Automations />,
  [ElementsUsedByClient.CREATE_RECORD]: <Automations />,
};

export const configurationItemTypeToIcon = {
  [ConfigurationType.sweepAssignment]: <Assign />,
  [ConfigurationType.sweepAlert]: <PlaybookAlert />,
  [ConfigurationType.sweepAutomation]: <Automations />,
  [ConfigurationType.sweepDedupe]: <Copy />,
  [ConfigurationType.sweepMatching]: <Target />,
  [ConfigurationType.sweepScheduledAssignment]: <Clock />,
};
