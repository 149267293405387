import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { useEffect } from 'react';
import { AutomationAssignmentsContextProvider } from './AutomationAssignmentsContextProvider';
import { useFeatureToggle } from '../common/useFeatureToggle';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { useGetAssignmentFilters } from '../pages/canvas-pages/automationFilters';

const assignmentsSortOptions = [
  {
    label: 'Name',
    value: 'ASSIGNMENT_NAME',
    dataAccessor: 'name',
  },

  {
    label: 'Object',
    value: 'OBJECT',
    dataAccessor: 'objectName',
  },
];

export type AssignmentsProps = {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  disableCanvasTemplates?: boolean;
  setHoveredItem?: (automationId?: string | undefined) => void;
  goToRoutingHome: () => void;
};

const Assignments = ({
  recordTypesData,
  funnelsData,
  disableCanvasTemplates,
  setHoveredItem,
  goToRoutingHome,
}: AssignmentsProps) => {
  const assignmentRules = useSelector(selectAssignments);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.ASSIGNMENT_RULES });

  const { expandedModeRouting } = useFeatureToggle();
  const { isExpandedMode } = useExpandedMode();
  const _isExpandedMode = expandedModeRouting && isExpandedMode;
  const renderCanvasFilters = expandedModeRouting ? !isExpandedMode : true;

  const filters = useGetAssignmentFilters();

  useEffect(() => {
    if (assignmentRules?.length === 0 && !_isExpandedMode) {
      goToRoutingHome();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentRules]);

  if (!assignmentRules) return <CenteredCircularProgress />;

  return (
    <>
      {crmOrg && (
        <AutomationDialogContent
          automations={assignmentRules ?? []}
          onDeleteAutomation={onDeleteAutomation}
          crmOrgId={crmOrg.id}
          onSaveOrCreate={onSaveOrCreate}
          recordTypesData={recordTypesData}
          funnelsData={funnelsData}
          onToggleActivation={onToggleActivation}
          sortOptions={assignmentsSortOptions}
          disableCanvasTemplates={disableCanvasTemplates}
          setHoveredItem={setHoveredItem}
          onDeployAutomation={onDeployAutomation}
          filtersForExpandedMode={filters}
        />
      )}
      {renderCanvasFilters && (
        <ConfigurationCanvasFilters filters={filters} automations={assignmentRules} />
      )}
    </>
  );
};

const AssignmentsWithContext = (assignmentProps: AssignmentsProps) => {
  return (
    <AutomationAssignmentsContextProvider>
      <Assignments {...assignmentProps} />
    </AutomationAssignmentsContextProvider>
  );
};

export default AssignmentsWithContext;
