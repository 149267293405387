import { Position, XYPosition } from 'reactflow';
import { EDGE_EXIT_ENTRY_GAP, SWEEP_GRID_SIZE } from '../const';
import { ArrowDirection } from '../edges/FloatingEdge';
import { SweepNodeHandles } from '../nodes/step-nodes/RegularStep';
export interface SweepCanvasNodePosition {
  row: number;
  column: number;
}

export const calculateHandleXYPositions = ({
  nodeXyPosition = { x: 0, y: 0 },
  parentXyPosition = { x: 0, y: 0 },
  width,
  height,
  type,
}: {
  nodeXyPosition?: XYPosition;
  parentXyPosition?: XYPosition;
  width: number;
  height: number;
  type: 'exit' | 'entry';
}) => {
  const xYPosition: XYPosition = {
    x: nodeXyPosition.x + parentXyPosition.x,
    y: nodeXyPosition.y + parentXyPosition.y,
  };

  const transformations = {
    exit: {
      [Position.Right]: 0,
      [Position.Left]: EDGE_EXIT_ENTRY_GAP,
      [Position.Bottom]: 0,
      [Position.Top]: EDGE_EXIT_ENTRY_GAP,
    },
    entry: {
      [Position.Right]: EDGE_EXIT_ENTRY_GAP,
      [Position.Left]: 0,
      [Position.Bottom]: EDGE_EXIT_ENTRY_GAP,
      [Position.Top]: 0,
    },
  };

  return {
    [Position.Top]: {
      x: xYPosition.x + width / 2 + transformations[type][Position.Top],
      y: xYPosition.y,
    },
    [Position.Bottom]: {
      x: xYPosition.x + width / 2 + transformations[type][Position.Bottom],
      y: xYPosition.y + height,
    },
    [Position.Left]: {
      x: xYPosition.x,
      y: xYPosition.y + height / 2 + transformations[type][Position.Left],
    },
    [Position.Right]: {
      x: xYPosition.x + width,
      y: xYPosition.y + height / 2 + transformations[type][Position.Right],
    },
  };
};

export const calculateHandlePositions = (
  sourceNodePosition: SweepCanvasNodePosition | undefined,
  targetNodePosition: SweepCanvasNodePosition | undefined,
) => {
  let sourceHandle = SweepNodeHandles.SourceRight;
  let targetHandle = SweepNodeHandles.TargetLeft;
  let arrowDirection = ArrowDirection.LeftRight;
  let sourcePosition: Position = Position.Right;
  let targetPosition: Position = Position.Left;

  if (!sourceNodePosition || !targetNodePosition) {
    return {
      sourceHandle,
      targetHandle,
      arrowDirection,
      sourcePosition,
      targetPosition,
    };
  }
  const { row: sourceRow, column: sourceColumn } = sourceNodePosition;
  const { row: targetRow, column: targetColumn } = targetNodePosition;

  if (targetRow > sourceRow) {
    if (targetColumn === sourceColumn) {
      sourceHandle = SweepNodeHandles.SourceTop;
      targetHandle = SweepNodeHandles.TargetBottom;
      arrowDirection = ArrowDirection.BottomTop;
      sourcePosition = Position.Top;
      targetPosition = Position.Bottom;
    }
    if (targetColumn < sourceColumn) {
      sourceHandle = SweepNodeHandles.SourceLeft;
      targetHandle = SweepNodeHandles.TargetRight;
      arrowDirection = ArrowDirection.RightLeft;
      sourcePosition = Position.Left;
      targetPosition = Position.Right;
    }

    if (targetColumn > sourceColumn) {
      sourceHandle = SweepNodeHandles.SourceRight;
      targetHandle = SweepNodeHandles.TargetLeft;
      arrowDirection = ArrowDirection.LeftRight;
      sourcePosition = Position.Right;
      targetPosition = Position.Left;
    }
  }
  if (targetRow < sourceRow) {
    if (targetColumn < sourceColumn) {
      sourceHandle = SweepNodeHandles.SourceLeft;
      targetHandle = SweepNodeHandles.TargetRight;
      arrowDirection = ArrowDirection.RightLeft;
      sourcePosition = Position.Left;
      targetPosition = Position.Right;
    }
    if (targetColumn === sourceColumn) {
      sourceHandle = SweepNodeHandles.SourceBottom;
      targetHandle = SweepNodeHandles.TargetTop;
      arrowDirection = ArrowDirection.TopBottom;
      sourcePosition = Position.Bottom;
      targetPosition = Position.Top;
    }
  }

  if (targetRow === sourceRow && targetColumn < sourceColumn) {
    sourceHandle = SweepNodeHandles.SourceLeft;
    targetHandle = SweepNodeHandles.TargetRight;
    arrowDirection = ArrowDirection.RightLeft;
    sourcePosition = Position.Left;
    targetPosition = Position.Right;
  }

  return {
    sourceHandle,
    targetHandle,
    arrowDirection,
    sourcePosition,
    targetPosition,
  };
};

export const reactFlowPositionToCanvasIndexPosition = (
  position: XYPosition,
): SweepCanvasNodePosition => ({
  column: position.x / SWEEP_GRID_SIZE.width,
  row: -(position.y / SWEEP_GRID_SIZE.height),
});

export const _reactFlowPositionToRoundedCanvasIndexPosition = (
  position: XYPosition = { x: 0, y: 0 },
): SweepCanvasNodePosition => ({
  column: Math.round(position.x / SWEEP_GRID_SIZE.width),
  row: Math.round(-(position.y / SWEEP_GRID_SIZE.height)),
});

export const _canvasIndexPositionToReactFlowPosition = (
  position: SweepCanvasNodePosition = { column: 0, row: 0 },
): XYPosition => ({
  x: position.column * SWEEP_GRID_SIZE.width,
  y: -(position.row * SWEEP_GRID_SIZE.height),
});
