import { InfoDialog } from '../../../common/dialogs/InfoDialog';
import { ManageEnv } from './ManageEnv';

interface ManageEnvDialogProps {
  onClose: () => void;
  allowDeleteLastEnv: boolean;
}

export const ManageEnvDialog = ({ onClose, allowDeleteLastEnv }: ManageEnvDialogProps) => {
  return (
    <InfoDialog
      handleClose={onClose}
      open
      showCloseButton
      titleJsx={'Environments'}
      sx={{
        '.MuiDialogContent-root': {
          p: 3,
          pt: 2,
        },
      }}
      titleTypographyVariant="h2"
      closeButtonSize="small"
    >
      <ManageEnv allowDeleteLastEnv={allowDeleteLastEnv} />
    </InfoDialog>
  );
};
