import { Box, Alert } from '@mui/material';
import { Warning as WarningIcon } from '@sweep-io/sweep-design/dist/icons';
import { SlackConnectionStatus } from '../../../reducers/integrationsReducer';
import { SlackConnectButton } from '../../dashboard/user-menu/SlackConnectButton';
import { colors } from '@sweep-io/sweep-design';

export const SlackNotConnected = ({ slackStatus }: { slackStatus: SlackConnectionStatus }) => {
  const getAction = () => {
    if (slackStatus !== SlackConnectionStatus.Connected) {
      return <SlackConnectButton variant="flat" />;
    }
  };

  return (
    <>
      <Box
        sx={{
          width: 'fit-content',
        }}
      >
        <Alert
          icon={<WarningIcon />}
          sx={{
            background: colors.grey[100],
            fontWeight: '500',
            borderRadius: '4px',
            color: colors.night[900],
            alignItems: 'center',
            '& .MuiAlert-action': {
              padding: 0,
              margin: '0 0 0 40px',
            },
          }}
          severity="warning"
          action={getAction()}
        >
          Connect to Slack
        </Alert>
      </Box>
    </>
  );
};
