import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { DialogLoader } from '../../../common/dialogs/DialogLoader';
import { FieldsTable } from '../FieldsTable';
import { useDocumentation } from '../../useDocumentation';
import { getCardListFromConfigurationItem, getCardListFromParsedRecordTypes } from '../utils';
import { useSelector } from 'react-redux';
import { selectDependenciesConfigurationItem } from '../../../../reducers/documentationReducer';
import { useSortElements } from '../useSortElements';
import { useDependencies } from '../../dependencies/hooks/useDependencies';
import { ElementSubheader } from './ElementSubheader';
import { CardsList } from './CardsList';
import { useFilterTabs } from '../filters/useFilterTabs';
import { useContext } from 'react';
import { DocumentationDialogContext } from '../../context/DocumentationContextProvider';

interface ElementContentProps {
  crmOrgId: string;
  objectTypeName: ObjectTypeName;
  activeTab: DocumentationTabTypes;
  isManagePackageInstalled: boolean;
}

export const ElementContent = ({
  activeTab,
  objectTypeName,
  isManagePackageInstalled,
  crmOrgId,
}: ElementContentProps) => {
  const { onFieldClick, isLoadingSingleField } = useDocumentation();
  const { handleSetConfigurationItem } = useDependencies(crmOrgId);
  const { recordTypesUsedInCanvas: recordTypeNamesUsedInCanvas } = useContext(
    DocumentationDialogContext,
  );

  const items = useFilterTabs();

  const { sortedItems } = useSortElements({
    items,
    activeTab,
    isManagePackageInstalled,
  });

  const selectedDependency = useSelector(selectDependenciesConfigurationItem);
  const firstHistoryItem = selectedDependency?.history?.[0];

  const renderActiveTab = () => {
    switch (activeTab) {
      case DocumentationTabTypes.FIELDS:
        return (
          <FieldsTable
            crmOrgId={crmOrgId}
            fields={sortedItems}
            isManagePackageInstalled={isManagePackageInstalled}
            objectName={objectTypeName.objectType}
            onFieldClick={onFieldClick}
          />
        );

      case DocumentationTabTypes.RECORD_TYPES:
        return (
          <CardsList
            list={getCardListFromParsedRecordTypes(
              sortedItems,
              objectTypeName.objectType,
              recordTypeNamesUsedInCanvas,
            )}
            firstHistoryItem={firstHistoryItem}
            handleSetConfigurationItem={handleSetConfigurationItem}
          />
        );

      default:
        return (
          <CardsList
            list={getCardListFromConfigurationItem(sortedItems, objectTypeName.objectType)}
            firstHistoryItem={firstHistoryItem}
            handleSetConfigurationItem={handleSetConfigurationItem}
          />
        );
    }
  };

  return (
    <>
      <DialogLoader visible={isLoadingSingleField} variant="light" />

      <ElementSubheader
        objectLabel={objectTypeName.label}
        items={sortedItems}
        activeTab={activeTab}
        isManagePackageInstalled={isManagePackageInstalled}
        crmOrgId={crmOrgId}
      />

      {renderActiveTab()}
    </>
  );
};
