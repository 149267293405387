import { Box } from '@mui/material';
import { EnvConnectionError } from './envConnectionErrorTypes';
import CloudDisconnectedIllustration from '../../../../icons/svg/CloudDisconnectedIllustration.svg?react';
import { Button, Typography } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { selectCrmOrg } from '../environmentsReducer';
import { useCallback, useState } from 'react';
import WithConnectOrgPermission from '../../../common/WithConnectOrgPermission';
import { ManageEnvDialog } from '../connect-env/ManageEnvDialog';

export const SfEnvConnectionErrorPage = ({
  envConnectionError,
  allowDeleteLastEnv,
}: {
  envConnectionError: EnvConnectionError;
  allowDeleteLastEnv: boolean;
}) => {
  const { crmOrgId } = envConnectionError;
  const crmOrg = useSelector(selectCrmOrg(crmOrgId));
  const crmOrgEnvName = crmOrg?.name || '';

  const message = (
    <>
      We had an issue connecting your <b>{crmOrgEnvName}</b> environment
    </>
  );

  const buttonText = 'Open Environment Settings';

  const [isEnvDialogOpen, setIsEnvDialogOpen] = useState(false);
  const closeEnvDialog = useCallback(() => setIsEnvDialogOpen(false), [setIsEnvDialogOpen]);
  const openEnvDialog = useCallback(() => setIsEnvDialogOpen(true), []);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexDirection: 'column',
      }}
    >
      <CloudDisconnectedIllustration />
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center' }}>
        <Typography variant="h3">Connection Failed</Typography>
        <Typography variant="body">{message}</Typography>
      </Box>

      <WithConnectOrgPermission>
        <Button onClick={openEnvDialog}>{buttonText}</Button>
      </WithConnectOrgPermission>
      {isEnvDialogOpen && (
        <ManageEnvDialog onClose={closeEnvDialog} allowDeleteLastEnv={allowDeleteLastEnv} />
      )}
    </Box>
  );
};
