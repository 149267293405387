import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

type ScheduledAssignmentReducersProps = {
  addScheduledAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignment: AutomationStructureNew;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateScheduledAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignmentToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  toggleScheduledAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignmentToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteScheduledAssignment: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      assignmentIdToDelete: string;
    }>,
  ) => any;
  updateScheduledAssignmentDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const scheduledAssignmentReducers: ScheduledAssignmentReducersProps = {
  addScheduledAssignment: (state, action) => {
    const { crmOrgId, assignment, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    if (!state.environments[crmOrgId].data.scheduledAssignments) {
      state.environments[crmOrgId].data.scheduledAssignments = [];
    }
    state.environments[crmOrgId].data.scheduledAssignments?.push({
      ...assignment,
      status: optimisticStatus,
    });
  },
  updateScheduledAssignment: (state, action) => {
    const { crmOrgId, assignmentToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledAssignments) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.scheduledAssignments?.findIndex(
      (r) =>
        r.automationId === assignmentToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.scheduledAssignments[indexToUpdate] = {
        ...assignmentToUpdate,
        status: optimisticStatus,
      };
    }
  },
  deleteScheduledAssignment: (state, action) => {
    const { crmOrgId, assignmentIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledAssignments) {
      return;
    }
    const indexToDelete = state.environments[crmOrgId].data.scheduledAssignments?.findIndex(
      (r) => r.automationId === assignmentIdToDelete,
    );
    if (indexToDelete !== undefined) {
      state.environments[crmOrgId].data.scheduledAssignments?.splice(indexToDelete, 1);
    }
  },
  toggleScheduledAssignment: (state, action) => {
    const { crmOrgId, assignmentToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledAssignments) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.scheduledAssignments?.findIndex(
      (r) =>
        r.automationId === assignmentToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.scheduledAssignments[indexToUpdate] = {
        ...assignmentToUpdate,
        status: optimisticStatus,
      };
    }
  },
  updateScheduledAssignmentDeployStatus: (state, action) => {
    const { crmOrgId, automationId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.scheduledAssignments) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.scheduledAssignments?.findIndex(
      (r) => r.automationId === automationId && r.versionId === versionId,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.scheduledAssignments[indexToUpdate].status =
        optimisticStatus;
    }
  },
};

export const selectCrmOrgScheduledAssignments = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.scheduledAssignments;
};

export const selectScheduledAssignments = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgScheduledAssignments(crmOrg.id)(state);
  }
};
