import { AiChatInput } from '../common/ai-chat/AiChatInput';

const CopilotInput = ({
  onConfirm,
  isLoading,
  predefinedPrompts,
  disabled,
}: {
  onConfirm: (message: string) => Promise<void>;
  isLoading: boolean;
  disabled: boolean;
  predefinedPrompts?: { [key: string]: string };
}) => {
  return (
    <AiChatInput
      textFieldVariant="secondary"
      onConfirm={onConfirm}
      isLoading={isLoading}
      predefinedPrompts={predefinedPrompts}
      isCollapsiblePrompts={false}
      disabled={disabled}
    />
  );
};

export default CopilotInput;
