import { Report, ReportingType } from '../../../../types/Reports';

export const reportsData: Report[] = [
  {
    name: 'Velocity Tracking',
    type: ReportingType.VELOCITY,
    description:
      'Track the velocity of records through your funnels to identify bottlenecks and areas for optimization.',
    dashboardInfo: '1 Dashboard per funnel',
    reportsInfo: '# of reports varies by number of steps in the funnel',
    sfObjects: ['Lead', 'Opportunity', 'Account', 'Contact', 'Case'],
    keyMetrics: ['Median Duration'],
    img: 'dashboard1.png',
  },
  {
    name: 'Funnel Conversion Rates',
    type: ReportingType.FUNNEL_CONVERSION,
    description:
      'Track conversion rates across your funnel to optimize your process and improve pipeline predictability.',
    dashboardInfo: '1 Dashboard per funnel',
    reportsInfo: '# of reports varies by number of steps in the funnel',
    sfObjects: ['Lead', 'Opportunity', 'Account', 'Contact', 'Case'],
    keyMetrics: ['Conversion %'],
    img: 'dashboard2.png',
  },
  {
    name: 'Forecasting',
    type: ReportingType.FORECASTING,
    description:
      'Understand the future outlook of your pipeline based on opportunity stages and forecast categories.',
    dashboardInfo: '1 Dashboard per funnel',
    reportsInfo: '5 Reports',
    sfObjects: ['Opportunity'],
    keyMetrics: ['Total Amount', 'Closed Won Amount', 'Win Rate', 'Expected Revenue'],
    img: 'dashboard3.png',
    isComingSoon: true,
  },
  {
    name: 'AE Performance',
    type: ReportingType.AE_PERFORMANCE,
    description: 'Track AE Performance to gain insights on individuals overall performance.',
    dashboardInfo: '1 Dashboard',
    reportsInfo: '7 Reports',
    sfObjects: ['Opportunity'],
    keyMetrics: ['Total Amount', 'Won ARR', 'Won Opportunities'],
    img: 'dashboard4.png',
    isComingSoon: true,
  },
  {
    name: 'Leads Overview',
    type: ReportingType.LEAD_OVERVIEW,
    description: 'Track leads and activities across your funnels.',
    dashboardInfo: '1 Dashboard per funnel',
    reportsInfo: '8 Reports',
    sfObjects: ['Lead'],
    keyMetrics: [
      'Total Amount',
      'Total Leads Converted',
      'Total Leads',
      '% Leads Converted',
      'Annual Revenue',
    ],
    img: 'dashboard5.png',
    isComingSoon: true,
  },
];
