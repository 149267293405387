import { useCallback, useState } from 'react';
import useIsManagedPackageInstalled from '../../../common/install-managed-package/useIsManagedPackageInstalled';
import { CompleteSfSetupDialog } from '../../../common/complete-sf-setup/CompleteSfSetupDialog';

export const useVerifyManagePackageDialog = () => {
  const isManagedPackagedInstalled = useIsManagedPackageInstalled();
  const [completeSetupDialogOpen, setCompleteSetupDialogOpen] = useState(false);

  const verifyManagedPackageAndCall = useCallback(
    (callback: () => any) => {
      if (!isManagedPackagedInstalled) {
        setCompleteSetupDialogOpen(true);
      } else {
        callback();
      }
    },
    [isManagedPackagedInstalled],
  );

  const maybeInstallManagePackageDialogDialog = completeSetupDialogOpen ?
    <CompleteSfSetupDialog
      cancelDialog={() => setCompleteSetupDialogOpen(false)}
      onCompleteItemsCallback={() => setCompleteSetupDialogOpen(false)}
      title="Complete Your Setup"
      subtitle="Get started with Sweep by completing these quick steps."
    /> : null

  return { verifyManagedPackageAndCall, maybeInstallManagePackageDialogDialog, completeSetupDialogOpen, setCompleteSetupDialogOpen };
};
