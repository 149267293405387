import { useDispatch, useSelector } from 'react-redux';
import uniq from 'lodash/uniq';
import useParser from '../../parser/useParser';
import { useEffect, useMemo, useState } from 'react';
import useObjectTypesWithFetch from '../../../hooks/useObjectTypesWithFetch';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';
import { useInitializeObjectsStructure } from './useInitializeObjectsStructure';
import { setShowLoader } from '../../../reducers/documentationReducer';
import { selectParserInfo } from '../../../reducers/global/globalReducer';
import { getObjectApiNamesFromFunnelMap, getObjectApiNamesFromRecordTypes } from '../helpers';

export const useInitializeNonParsedObjectsInCanvas = (funnelMap?: FunnelMap) => {
  const dispatch = useDispatch();
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const crmOrgId = defaultCreationEnvironment?.id ?? '';

  const { parseObjectOnDemand } = useParser({ crmOrgId });
  const { objectTypes, isLoading } = useObjectTypesWithFetch({ crmOrgId });

  const [isAddingObjects, setIsAddingObjects] = useState(false);

  const funnelsData = useMemo(() => funnelMap?.funnelsData ?? {}, [funnelMap?.funnelsData]);
  const recordTypesData = useMemo(
    () => funnelMap?.recordTypesData ?? {},
    [funnelMap?.recordTypesData],
  );

  useInitializeObjectsStructure({ funnelsData, recordTypesData, crmOrgId, objectTypes });

  const funnelsObjects = useMemo(() => getObjectApiNamesFromFunnelMap(funnelsData), [funnelsData]);
  const recordTypeObjects = useMemo(
    () => getObjectApiNamesFromRecordTypes(recordTypesData),
    [recordTypesData],
  );
  const mergedObjectNames: string[] = useMemo(
    () => uniq([...funnelsObjects, ...recordTypeObjects]),
    [funnelsObjects, recordTypeObjects],
  );

  const parsedInfo = useSelector(selectParserInfo(crmOrgId));
  const parsedObjectNamesInOrg = useMemo(
    () => parsedInfo?.parsedObjectNames ?? [],
    [parsedInfo?.parsedObjectNames],
  );

  const missingObjectNames = useMemo(
    () =>
      parsedObjectNamesInOrg?.length > 0
        ? mergedObjectNames.filter((objectName) => !parsedObjectNamesInOrg.includes(objectName))
        : [],
    [mergedObjectNames, parsedObjectNamesInOrg],
  );

  const missingObjectTypes = useMemo(
    () =>
      objectTypes?.filter((objectType) => missingObjectNames.includes(objectType.objectType)) ?? [],
    [objectTypes, missingObjectNames],
  );

  useEffect(() => {
    if (missingObjectTypes.length > 0 && !isAddingObjects && crmOrgId && !isLoading) {
      setIsAddingObjects(true);

      (async () => {
        dispatch(setShowLoader({ showLoader: true }));
        await Promise.all(
          missingObjectTypes.map((objectType) => parseObjectOnDemand(objectType.objectType)),
        );
        setIsAddingObjects(false);
        dispatch(setShowLoader({ showLoader: false }));
      })();
    }
  }, [dispatch, missingObjectTypes, crmOrgId, isAddingObjects, parseObjectOnDemand, isLoading]);
};
