import { styled } from '@mui/material/styles';

const UserSummaryBasic = styled('div')({
  display: 'flex',
  gap: '12px',
  lineHeight: 1,
  alignItems: 'center',
  width: '100%',
  '.user-summary-chevron.MuiSvgIcon-root': {
    visibility: 'hidden',
    position: 'absolute',
    left: '53px',
    top: '13px',
  },
  '.user-summary-avatar': {
    position: 'relative',
  },
});

export default UserSummaryBasic;
