import { Button, Tooltip } from '@sweep-io/sweep-design';
import useIsManagedPackageInstalled from '../install-managed-package/useIsManagedPackageInstalled';
import { useCrmOrgs } from '../../pages/environments/useCrmOrgs';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import AssignPermissionSetDialog from './AssignPermissionSetDialog';
import usePermission from '../permissions/usePermission';
import { Permission } from '@server/permissions';
import RestrictedTooltip from '../permissions/RestrictedTooltip';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';

const AssignPermissionSetButton = ({ disabled }: { disabled?: boolean }) => {
  const isManagedPackagedInstalled = useIsManagedPackageInstalled();
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { id: crmOrgId, connectedUser } = crmOrg ?? {};
  const { assignPermissionSetGroup } = useCrmOrgs();
  const { addNotification } = useSweepNotifications();
  const [isDialogOpened, setIsDialogOpened] = useState(false);
  const permissionStr: Permission[] = ['create:sweep-permission-set-group-assignment'];
  const [isAllowed] = usePermission(permissionStr);

  const openDialog = useCallback(() => setIsDialogOpened(true), []);
  const closeDialog = useCallback(() => setIsDialogOpened(false), []);

  const onConfirm = async ({ assignToAll }: { assignToAll: boolean }) => {
    try {
      if (crmOrgId) {
        await assignPermissionSetGroup({ crmOrgId, assignToAll });
        addNotification({
          variant: SweepNotificationVariant.Success,
          message: 'Permission Set Group was successfully assigned in Salesforce',
        });
        closeDialog();
      }
    } catch (e) {
      addNotification({
        variant: SweepNotificationVariant.Error,
        message: 'There was an error assigning Permission Set Group in Salesforce',
      });
    }
  };

  const button = (
    <Button
      disabled={!isManagedPackagedInstalled || !isAllowed || disabled}
      onClick={openDialog}
      size="small"
    >
      Assign permissions
    </Button>
  );

  return (
    <>
      {isDialogOpened && (
        <AssignPermissionSetDialog
          onConfirm={onConfirm}
          closeDialog={closeDialog}
          connectedUser={connectedUser}
        />
      )}
      <RestrictedTooltip
        to={permissionStr}
        notAllowedTitle="To assign Sweep permissions, please contact your admin"
      >
        {isManagedPackagedInstalled ? (
          button
        ) : (
          <Tooltip title="To assign permissions, first install the managed package">
            <span>{button}</span>
          </Tooltip>
        )}
      </RestrictedTooltip>
    </>
  );
};

export default AssignPermissionSetButton;
