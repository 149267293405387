import { Box, FormControlLabel } from '@mui/material';
import { Checkbox, Typography, colors } from '@sweep-io/sweep-design';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { useRunOnce } from '../../common/useRunOnce';
import { useSelector } from 'react-redux';
import {
  selectSlackIncludeSandboxes,
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';

export const SlackIncludeSandboxesCheckbox = () => {
  const { updateIncludeSandboxes, getSlackStatus } = useIntegrations();
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const includeSandboxes = useSelector(selectSlackIncludeSandboxes);

  useRunOnce(() => {
    getSlackStatus();
  });

  //we display checkbox only when connected - BE limitation
  if (slackStatus !== SlackConnectionStatus.Connected) {
    return <></>;
  }

  return (
    <Box pt={2}>
      <FormControlLabel
        label={
          <Typography variant="caption" color={colors.grey[700]}>
            Connect to Sandboxes
          </Typography>
        }
        control={
          <Checkbox
            checked={includeSandboxes}
            onChange={(value) => {
              const isChecked = value.target.checked;
              updateIncludeSandboxes(isChecked);
            }}
          />
        }
      />
    </Box>
  );
};
