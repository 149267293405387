import { UNAUTHORIZED_CONNECT_TOOLTIP } from '../../constants';
import React from 'react';
import WithPermissions from './WithPermissions';

const WithConnectOrgPermission = (props: any) => (
  <WithPermissions
    permissionStr={['create:orgs']}
    disabledTooltip={UNAUTHORIZED_CONNECT_TOOLTIP}
    {...props}
  />
);

export default WithConnectOrgPermission;
