import { useCallback } from 'react';
import { ChevronButton } from './shared/ChevronButton';
import { TableWithHeader, TableWithHeaderProps } from './shared/TableWithHeader';
import { TableTypography } from './shared/helpers';
import { DataTableRow } from '../../common/table/TableTypes';
import { humanizeDate } from '../../helpers/humanizeDate';
import pluralize from 'pluralize';
import {
  ConfigurationType,
  DependenciesContentViewType,
  NewDependencyProps,
} from '../dependencies/types';
import { InactiveTag } from '../activity-state/InactiveTag';

interface DefaultConfigurationTableProps
  extends Pick<TableWithHeaderProps, 'headerVariant' | 'tableHeader'> {
  items: DefaultConfigurationTableItem[];
  onItemClick: (props: NewDependencyProps) => void;
  shortListViewCounter?: number;
  parentType: ConfigurationType;
  activeItemId?: string;
  clearHistory?: boolean;
}

interface DefaultConfigurationTableItem {
  id: string;
  name: string;
  label: string;
  lastModified: string;
  objectName?: string;
  isActive: boolean;
}

const columns = [
  { field: 'label', headerName: 'Name', width: '58%' },
  { field: 'lastModified', headerName: 'Last Modified', width: '22%' },
  { field: '__actions', headerName: '', width: '10%' },
];

const renderRows = ({
  items,
  activeItemId,
}: {
  items: DefaultConfigurationTableItem[];
  activeItemId?: string;
}) => {
  return items.map((row) => ({
    id: row.id,
    name: row.name,
    isRowActive: activeItemId === row.id || activeItemId === row.name,
    label: (
      <>
        <TableTypography key={row.id}>{row.label}</TableTypography>
        <InactiveTag isActive={row.isActive} />
      </>
    ),
    lastModified: (
      <TableTypography key={row.id + row.lastModified}>
        {humanizeDate({ dateOrTimestamp: row.lastModified })}
      </TableTypography>
    ),
    objectName: row.objectName,
    __actions: <ChevronButton />,
    isClickable: true,
  }));
};

export const DefaultConfigurationTable = (props: DefaultConfigurationTableProps) => {
  const { items, onItemClick, tableHeader, parentType, activeItemId, clearHistory } = props;
  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: DataTableRow) => {
      onItemClick({
        id: row.id,
        name: row.name,
        parentType,
        dependencyType: row.type,
        objectName: row.objectName,
        clearHistory,
        contentType: DependenciesContentViewType.default,
      });
    },
    [onItemClick, parentType, clearHistory],
  );

  const rows = renderRows({ items, activeItemId });

  return (
    <TableWithHeader
      {...props}
      key={`tableHeader_${tableHeader}`}
      tableHeader={pluralize(tableHeader, rows.length)}
      columns={columns}
      rows={rows}
      onRowClick={onRowClick}
    />
  );
};
