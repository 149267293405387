import { Box, Divider, ButtonProps } from '@mui/material';
import { Button, colors, IconButton, Spinner } from '@sweep-io/sweep-design';
import { JSXElementConstructor, ReactElement, ReactNode } from 'react';

//TODO move "SplitButton" to the design system
const SplitButton = ({
  leftText,
  rightIcon,
  leftButtonProps,
  rightButtonProps,
  isLoading,
}: {
  leftText: string;
  rightIcon: ReactElement<any, string | JSXElementConstructor<any>> & ReactNode;
  leftButtonProps: Pick<ButtonProps, 'onClick' | 'disabled'> & { pressed?: boolean };
  rightButtonProps: Pick<ButtonProps, 'onClick' | 'disabled'> & { pressed?: boolean };
  isLoading: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        '& > button:first-of-type': {
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          paddingRight: 1.5,
        },
      }}
    >
      <Button {...leftButtonProps} disabled={isLoading || leftButtonProps.disabled}>{isLoading ? <Spinner color={colors.grey[500]}/> : leftText}</Button>

      <Box
        padding={1}
        sx={{
          paddingLeft: 0,
          display: 'flex',
          backgroundColor: (rightButtonProps.disabled || isLoading) ? colors.grey[200] : colors.blue[500],
          borderBottomRightRadius: '100px',
          borderTopRightRadius: '100px',
        }}
      >
        <Divider
          orientation="vertical"
          flexItem
          sx={{
            borderColor: (rightButtonProps.disabled || isLoading) ? colors.grey[500] : colors.blue[100],
            mr: 0.5,
          }}
        />
        <IconButton variant="filled" size="tiny" {...rightButtonProps} disabled={isLoading || rightButtonProps.disabled}>
          {rightIcon}
        </IconButton>
      </Box>
    </Box>
  );
};

export default SplitButton;
