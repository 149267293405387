import { useCallback, useState } from 'react';
import { useRolesApiFacade } from '../../../apis/facades/useRolesApiFacade';
import { useDispatch } from 'react-redux';
import {
  addRoleGroup as _addRoleGroup,
  updateRoleGroup as _updateRoleGroup,
  setIsLoading,
  setRoleGroups,
  deleteRoleGroup as _deleteRoleGroup,
} from '../../../reducers/roleGroupsReducer';
import { telemetry } from '../../../telemetry';
import { UserDefinedRoleGroupsInterface } from '@server/role-group-interface';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import { AxiosError } from 'axios';

export const useRoleGroups = () => {
  const { get_roleGroups, post_roleGroup, put_roleGroup, delete_roleGroup } = useRolesApiFacade();
  const { addNotification } = useSweepNotifications();

  const [isSavingUser, setIsSavingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  const dispatch = useDispatch();

  const notifyUser = useCallback(
    (failedAction: string, message?: string) => {
      addNotification({
        message: message ?? `Failed to ${failedAction} due to technical issues`,
        keepOpen: true,
        variant: SweepNotificationVariant.Error,
      });
    },
    [addNotification],
  );

  const fetchRoleGroups = useCallback(async () => {
    dispatch(setIsLoading({ isLoading: true }));

    try {
      const response = await get_roleGroups();
      dispatch(setRoleGroups({ roleGroups: response }));
    } catch (error) {
      dispatch(setIsLoading({ isLoading: false }));
      telemetry.captureError(error);
    }
  }, [get_roleGroups, dispatch]);

  const addRoleGroup = useCallback(
    async (roleGroup: Pick<UserDefinedRoleGroupsInterface, 'name' & 'description' & 'roles'>) => {
      setIsSavingUser(true);
      try {
        const response = await post_roleGroup(roleGroup);
        dispatch(_addRoleGroup({ roleGroup: response }));
      } catch (error) {
        telemetry.captureError(error);
        notifyUser('add role');
      }
      setIsSavingUser(false);
    },
    [post_roleGroup, dispatch, notifyUser],
  );

  const updateRoleGroup = useCallback(
    async (roleGroup: UserDefinedRoleGroupsInterface) => {
      setIsSavingUser(true);
      try {
        const response = await put_roleGroup(roleGroup);
        dispatch(_updateRoleGroup({ newRoleGroup: response }));
      } catch (error) {
        telemetry.captureError(error);
        notifyUser('update role');
      }
      setIsSavingUser(false);
    },
    [put_roleGroup, dispatch, notifyUser],
  );

  const saveRoleGroup = useCallback(
    async (roleGroup: UserDefinedRoleGroupsInterface) => {
      const updateable = {
        name: roleGroup.name,
        description: roleGroup.description,
        roleLevels: roleGroup.roleLevels,
      } as UserDefinedRoleGroupsInterface;

      if (roleGroup.id) {
        await updateRoleGroup({ ...updateable, id: roleGroup.id });
      } else {
        await addRoleGroup(updateable);
      }
    },
    [addRoleGroup, updateRoleGroup],
  );

  const deleteRoleGroup = useCallback(
    async (roleGroupId: string) => {
      setIsDeletingUser(true);
      try {
        await delete_roleGroup(roleGroupId);
        dispatch(_deleteRoleGroup({ roleGroupId }));
      } catch (error) {
        const isError409 = (error as AxiosError).response?.status === 409;

        if (isError409) {
          notifyUser(
            '',
            'Unable to delete role. Users are assigned to that role. Before deleting it, make sure it isn’t used.',
          );
          setIsDeletingUser(false);
          return;
        }

        telemetry.captureError(error);
        notifyUser('delete role');
      }
      setIsDeletingUser(false);
    },
    [delete_roleGroup, dispatch, notifyUser],
  );

  return {
    fetchRoleGroups,
    saveRoleGroup,
    deleteRoleGroup,

    isDeletingUser,
    isSavingUser,
  };
};
