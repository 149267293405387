import { Box, Stack } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { Chat } from './copilotChatsReducer';
import CopilotChat from '../copilot/CopilotChat';

const CopilotChatsCollapsed = ({
  newButton,
  toggleExpandButton,
  closeButton,
  activeChat,
}: {
  newButton: ReactNode;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  activeChat?: Chat;
}) => {
  return (
    <Stack height="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        height="56px"
        sx={{
          padding: '12px',
          borderBottom: `1px solid ${colors.grey[200]}`,
        }}
      >
        <Box display="flex" gap={1} alignItems="center">
          {newButton}
          {toggleExpandButton}
        </Box>
        {closeButton}
      </Box>
      <CopilotChat
        messages={activeChat?.messages ?? []}
        onConfirm={() => Promise.resolve()}
        isLoading={!!activeChat?.isLoading}
        errorMsg={activeChat?.error}
        disableInput={false}
        noteAlignment="left"
      />
    </Stack>
  );
};

export default CopilotChatsCollapsed;
