import { PluginCard, PluginCardProps } from '../../../plugins-tab/PluginCard';
import { PluginMeddpiccSvg } from '../../icons';
import { meddpiccTexts } from './texts';

export const MeddpiccPluginCard = (props: Pick<PluginCardProps, 'onClick' | 'onAdd'>) => {
  return (
    <PluginCard
      svgIcon={<PluginMeddpiccSvg />}
      title={meddpiccTexts.title}
      description={meddpiccTexts.description}
      {...props}
    />
  );
};
