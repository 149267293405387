import { useCallback, useEffect } from 'react';
import {
  selectAddOrImportDialogOpen,
  selectThirdPartyFunnels,
  selectThirdPartyFunnelsLoaded,
  selectThirdPartyFunnelsLoading,
  setAddOrImportDialogOpen,
} from './thirdPartyFunnelsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { ImportThirdPartyFunnelDialog } from './ImportThirdPartyFunnelDialog';
import { useThirdPartyFunnelsApi } from './useThirdPartyFunnelsApi';
import { NewThirdPartyFunnelDialog } from './NewThirdPartyFunnelDialog';
import { useRunOnce } from '../common/useRunOnce';

export const AddOrImportThirdPartyFunnel = ({ addedFunnelIds }: { addedFunnelIds: string[] }) => {
  const dispatch = useDispatch();

  const { getThirdPartyFunnels } = useThirdPartyFunnelsApi();
  const loaded = useSelector(selectThirdPartyFunnelsLoaded);
  const loading = useSelector(selectThirdPartyFunnelsLoading);
  const addOrImportDialogOpen = useSelector(selectAddOrImportDialogOpen);
  const thirdPartyFunnels = useSelector(selectThirdPartyFunnels);

  const closeDialog = useCallback(() => {
    dispatch(setAddOrImportDialogOpen(false));
  }, [dispatch]);

  useRunOnce(getThirdPartyFunnels);

  useEffect(() => {
    if (!loading && addOrImportDialogOpen) {
      getThirdPartyFunnels();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOrImportDialogOpen]);

  if (!loaded) {
    return null;
  }

  if (addOrImportDialogOpen === false) {
    return null;
  }

  if (addOrImportDialogOpen === 'add' || thirdPartyFunnels.length === 0) {
    return <NewThirdPartyFunnelDialog isOpen onClose={closeDialog} />;
  }
  return (
    <ImportThirdPartyFunnelDialog
      onClose={closeDialog}
      thirdPartyFunnels={thirdPartyFunnels}
      addedFunnelIds={addedFunnelIds}
    />
  );
};
