import { Box } from '@mui/material';

export const PageTemplateGrid = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) => {
  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: 3,
      }}
    >
      {children}
    </Box>
  );
};
