import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import {
  SfChangeFeedListItem,
  SfChangeFeedNotification,
} from '../../components/pages/sf-change-feed/types';
import { SfChangeFeedFilters } from '../../components/pages/sf-change-feed/types';

export interface GetSfChangeFeedResponse {
  changeFeedList: SfChangeFeedListItem[];
  filters: Partial<SfChangeFeedFilters>;
}

export const useSfChangeFeedApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_sfChangeFeed: useCallback(
      async (queryString: string) => {
        const { data } = await sweepApi.get(`/sf-change-feed${queryString}`);
        return data as GetSfChangeFeedResponse;
      },
      [sweepApi],
    ),
    get_sfChangeFeedNotifications: useCallback(async () => {
      const { data } = await sweepApi.get(`/sf-change-feed/notifications`);
      return data as SfChangeFeedNotification[];
    }, [sweepApi]),
    post_sfChangeFeedNotification: useCallback(
      async (notification: Omit<SfChangeFeedNotification, 'id'>) => {
        const { data } = await sweepApi.post(`/sf-change-feed/notifications`, notification);
        return data as SfChangeFeedNotification;
      },
      [sweepApi],
    ),
    put_sfChangeFeedNotification: useCallback(
      async (notification: SfChangeFeedNotification) => {
        const { data } = await sweepApi.put(
          `/sf-change-feed/notifications/${notification.id}`,
          notification,
        );
        return data as SfChangeFeedNotification;
      },
      [sweepApi],
    ),
    delete_sfChangeFeedNotification: useCallback(
      async (notificationId: string) => {
        return await sweepApi.delete(`/sf-change-feed/notifications/${notificationId}`);
      },
      [sweepApi],
    ),
  };
};
