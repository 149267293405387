import usePaywall from '../paywall/usePaywall';
import { useEffect, useRef } from 'react';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import TrialCarousel from '../paywall/TrialCarousel';
import { useFeatureToggle } from '../../common/useFeatureToggle';

//This hook makes the Trial Into Carousel appear when a trial starts
const usePaywallTrialCarousel = () => {
  const { salesActivatedTrial } = useFeatureToggle();
  const { isPaywallActive } = usePaywall();
  const isPaywallActiveInitial = useRef(isPaywallActive);
  const { openConfirm, onCancel } = useConfirm();

  useEffect(() => {
    if (isPaywallActiveInitial.current && !isPaywallActive && !salesActivatedTrial) {
      openConfirm(<TrialCarousel closeDialog={onCancel} disableOpenAnimation={false} />);
      isPaywallActiveInitial.current = false;
    }
  }, [openConfirm, isPaywallActive, onCancel, salesActivatedTrial]);
};

export default usePaywallTrialCarousel;
