import { AttributeProps } from '../../../../../constants/fieldsManagementConsts';
import { Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';

import FieldLabel from '../../../../common/FieldLabel';
import TextToggle from '../../../../common/TextToggle';
import { useRunOnce } from '../../../../common/useRunOnce';
import isNil from 'lodash/isNil';

interface AttributeTextToggleProps extends AttributeProps {
  attributeName: keyof SweepFieldProperties;
  label?: string;
  prefix?: string;
  items: any[];
  defaultValue?: any;
  disabled?: boolean;
  onChangeSideEffect?: (value: any) => void;
}

const AttributeTextToggle = ({
  setFieldValue,
  values,
  attributeName,
  items,
  label,
  prefix,
  defaultValue,
  disabled,
  onChangeSideEffect,
}: AttributeTextToggleProps) => {
  useRunOnce(() => {
    if (isNil(values.properties?.[attributeName]) && !isNil(defaultValue)) {
      setFieldValue(`properties.${attributeName}`, defaultValue);
    }
  });
  return (
    <Box display="flex" flexDirection="column">
      {label && <FieldLabel label={label} />}
      <Box display="flex" gap="8px" alignItems="center">
        {prefix && <Typography variant="body">{prefix}</Typography>}
        <TextToggle
          items={items}
          selectedItem={items.find(
            (item) => item.value === (values.properties?.[attributeName] ?? defaultValue),
          )}
          onChange={(item) => {
            setFieldValue(`properties.${attributeName}`, item.value);
            onChangeSideEffect && onChangeSideEffect(item.value);
          }}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
};

export default AttributeTextToggle;
