import { Fragment } from 'react/jsx-runtime';
import { ReachedLastRow } from './ReachedLastRow';
import { DataTableSortableProps, DataTableRow, DataTableVariant } from './TableTypes';
import TableRow from './TableRow';

export function TableBodyRows<TRow extends DataTableRow = any>({
  rows,
  columns,
  reachedLastRow,
  containerRef,
  renderRow,
  onRowClick,
  ...dataTableProps
}: Pick<
  DataTableSortableProps<TRow>,
  | 'rows'
  | 'columns'
  | 'renderRow'
  | 'sxRowFunction'
  | 'onRowClick'
  | 'actionableButtonsOnHover'
  | 'reachedLastRow'
  | 'containerRef'
> & { variant: DataTableVariant }) {

  const renderSingleRow = (row: TRow, rowIdx: number, rowKey: string) => {
    const renderedRow =
      renderRow &&
      renderRow({
        rowIdx,
        row,
        rowKey,
      });

    if (renderedRow) {
      return renderedRow;
    }

    return (
      <TableRow
        row={row}
        columns={columns}
        tableRowProps={{ id: `table_row_${rowIdx}` }}
        onClick={(e, column) => {
          //verify that the click is on the row (and not on a row's "actions menu popover" backdrop)
          if (e.target instanceof Element && e.target?.matches('tr *')) {
            onRowClick && onRowClick(e, row, column);
          }
        }}
        {...dataTableProps}
      />
    );
  };

  return (
    <>
      {rows.map((row, rowIdx) => (
        <Fragment key={`wrapper_${row.id}`}>
          {renderSingleRow(row, rowIdx, `base_row_${rowIdx}`)}
          {row?.nestedRows &&
            row.nestedRows.map((row: any, idx: number) =>
              renderSingleRow(row, idx, `nested_row_${row.id}`),
            )}
        </Fragment>
      ))}

      {rows.length === reachedLastRow?.rowsMaxCount && (
        <ReachedLastRow
          columnsCount={columns.length}
          reachedLastRowTxt={reachedLastRow.txt}
          scrollToTop={() => {
            containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        />
      )}
    </>
  );
}
