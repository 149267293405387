import * as Sentry from '@sentry/react';
import { datadogRum } from '@datadog/browser-rum';

export const telemetry = {
  captureError: (error: any, extra?: any, level: Sentry.SeverityLevel = 'error') => {
    Sentry.captureException(error, {
      extra,
      level,
    });
    datadogRum.addError(error, {
      extra,
    });
    if (level === 'error' || level === 'fatal') {
      console.error(error, extra);
    } else if (level === 'warning') {
      console.warn(error, extra);
    } else {
      console[level || 'log'](error, extra);
    }
  },
  addBreadcrumb: Sentry.addBreadcrumb,
};
