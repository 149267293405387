import { Box } from '@mui/material';
import { useDependencies } from '../hooks/useDependencies';
import { ConfigurationType } from '../types';
import { Panel } from './atoms/Panel';
import { BackButton } from './atoms/BackButton';
import { TitleWithSubtitle } from './atoms/TitleWithSubtitle';
import { ConfigurationItem } from '../DependenciesTypes';

interface CommentHeaderProps {
  configurationItem: ConfigurationItem;
  parentType: ConfigurationType;
  crmOrgId: string;
}

export const CommentHeader = ({ configurationItem, parentType, crmOrgId }: CommentHeaderProps) => {
  const { onGoBackInHistory } = useDependencies(crmOrgId);

  return (
    <Panel>
      <BackButton onClick={onGoBackInHistory} />

      <Box flex={1} display="flex" justifyContent="space-between" alignItems="flex-start">
        <TitleWithSubtitle
          configurationItem={configurationItem}
          crmOrgId={crmOrgId}
          parentType={parentType}
        />
      </Box>
    </Panel>
  );
};
