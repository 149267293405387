import { Box } from '@mui/material';
import { AssignmentRulesTable } from './AssignmentRulesTable';
import { Button } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { AssignmentRuleDialog } from './AssignmentRuleDialog';
import cloneDeep from 'lodash/cloneDeep';
import { uniqueId } from '../../lib/uniqueId';

const dialogInitialState = { open: false };

const AssignmentRulesEditor = ({
  assignmentRules,
  onChange,
  crmOrgId,
  objectName,
  readonly,
}: {
  assignmentRules: AutomationAssignmentRule[];
  onChange: (assignmentRules: AutomationAssignmentRule[]) => void;
  crmOrgId: string;
  objectName: string;
  readonly?: boolean;
  objectTypes?: ObjectTypeName[];
}) => {
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    editMode?: boolean;
    assignmentRule?: AutomationAssignmentRule;
  }>(dialogInitialState);
  return (
    <>
      {dialogState.open && (
        <AssignmentRuleDialog
          onCreateEdit={(updatedRule) => {
            const existingRuleIdx = assignmentRules.findIndex(
              (rule) => rule._id === updatedRule._id,
            );
            if (existingRuleIdx > -1) {
              const clonedRules = assignmentRules.slice();
              clonedRules[existingRuleIdx] = updatedRule;
              onChange(clonedRules);
            } else {
              onChange([...assignmentRules, updatedRule]);
            }
            setDialogState(dialogInitialState);
          }}
          crmOrgId={crmOrgId}
          objectType={objectName}
          onCancel={() => setDialogState(dialogInitialState)}
          editMode={dialogState.editMode}
          initialAssignmentRule={dialogState.assignmentRule}
        />
      )}
      <Box display="flex" flexDirection="column" gap={2}>
        {assignmentRules.length > 0 && (
          <AssignmentRulesTable
            readonly={readonly}
            assignmentRules={assignmentRules}
            onAssignmentRulesChange={onChange}
            onAssignmentRuleDuplicate={(assignmentRuleId) => {
              const existingRule = assignmentRules.find((rule) => rule._id === assignmentRuleId);
              if (!existingRule) {
                return;
              }
              const newRule = cloneDeep(existingRule);
              newRule._id = uniqueId();
              newRule.name = `${newRule.name} (copy)`;
              setDialogState({
                open: true,
                assignmentRule: newRule,
                editMode: false,
              });
            }}
            onAssignmentRuleEdit={(assignmentRuleId) => {
              const existingRule = assignmentRules.find((rule) => rule._id === assignmentRuleId);
              if (!existingRule) {
                return;
              }
              setDialogState({
                open: true,
                assignmentRule: existingRule,
                editMode: true,
              });
            }}
          />
        )}
        <Button
          variant="flat"
          startIconName="Plus"
          disabled={readonly}
          onClick={() => setDialogState({ open: true, editMode: false })}
        >
          New rule
        </Button>
      </Box>
    </>
  );
};

export default AssignmentRulesEditor;
