import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { AvatarPicture } from '../avatar/AvatarPicture';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../constants';
import { UserInfo } from '../../types/UserInfoTypes';
import { getIsUserInactive } from '../../lib/getIsUserActive';

const AvatarWithBorder = ({ userInfo }: { userInfo: UserInfo }) => {
  return (
    <Box
      sx={{
        width: '26px',
        height: '26px',
        borderRadius: '50%',
        backgroundColor: colors.grey[300],
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AvatarPicture
        avatar={{ emoji: userInfo?.emoji, imageUrl: userInfo?.imageUrl }}
        dimension={DIMENSION_AVATAR_VERY_SMALL}
        clickable={false}
        isInactive={getIsUserInactive(userInfo?.status)}
      />
    </Box>
  );
};

export default AvatarWithBorder;
