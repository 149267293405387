import { Typography } from '@sweep-io/sweep-design';
import InstallManagedPackage from './InstallManagedPackage';
import { ActionsRequireMp } from './getInstallManagedPackageText';
import { Stack } from '@mui/material';

const InstallManagedPackagePopoverContent = ({ action }: { action: ActionsRequireMp }) => (
  <Stack gap="30px" width="560px" padding={2}>
    <Typography variant="h1">Managed Package Required</Typography>
    <InstallManagedPackage action={action} closeDialog={() => {}} />
  </Stack>
);

export default InstallManagedPackagePopoverContent;
