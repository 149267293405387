import { ConfigurationItemWithStatus } from '../../dependencies/DependenciesTypes';

const DELIMITER = ';';

//csv accepts 4 delimiters by default: comma, tab, space, and semicolon - they should be escaped if used as part of value
const escapeCsvValue = (value?: unknown) => {
  return value ? `"${value}"` : value === 0 ? '0' : '-';
};

const getCSVFromJson = (
  rows: ConfigurationItemWithStatus[],
  headers: { label: string; value: string }[],
) => {
  let csv = '';

  csv += headers.map((header) => escapeCsvValue(header.label)).join(DELIMITER) + '\n';

  rows.forEach((row) => {
    const data = headers
      .map((header) => escapeCsvValue(row[header.value as keyof ConfigurationItemWithStatus]))
      .join(DELIMITER);
    csv += data + '\n';
  });

  return csv;
};

export const downloadCSV = ({
  rows,
  fileName,
  headers,
}: {
  rows: ConfigurationItemWithStatus[];
  fileName: string;
  headers: { label: string; value: string }[];
}) => {
  const csvData = getCSVFromJson(rows, headers);

  if (csvData === '' || !headers.length || !rows.length) {
    //nothing to export
    return false;
  }

  const blob = new Blob([csvData], { type: `text/csv;charset=utf-8${DELIMITER}` });
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);

  return true;
};
