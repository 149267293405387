import { Box } from '@mui/material';
import DialogOnCanvas from '../../DialogOnCanvas';
import { IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { useCallback, useContext } from 'react';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';

export const HubspotCampaignDialog = ({
  isOpen,
  onClose,
  children,
}: {
  isOpen: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}) => {
  const { isPristine } = useContext(HubspotCampaignCtx);
  const { openConfirm } = useConfirm();

  const _onClose = useCallback(async () => {
    if (isPristine) {
      onClose?.();
    } else {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (isConfirmed) {
        onClose?.();
      }
    }
  }, [isPristine, onClose, openConfirm]);

  return (
    <DialogOnCanvas
      open={isOpen}
      onClose={_onClose}
      sx={{
        '.MuiPaper-root': {
          overflow: 'hidden',
        },
      }}
      blockFully={!isPristine}
    >
      <Box
        margin={4}
        position="relative"
        sx={{
          height: '100%',
          '& > div ': {
            height: '100%',
          },
          '& .MuiTabPanel-root': {
            height: '100%',
          },
          flex: '1 1 auto',
          '& > div': { height: '100%' },
          '& > div > .MuiTabs-root': { pl: 2, pr: 2 },
          '.MuiTabPanel-root': { height: 'calc(100% - 70px)', overflow: 'scroll' },
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 1,
            height: 'fit-content !important',
          }}
        >
          <IconButton onClick={onClose} variant="flat">
            <Close />
          </IconButton>
        </Box>

        {children}
      </Box>
    </DialogOnCanvas>
  );
};
