export const notSetFunnelMap: FunnelMap = {
  id: 'NOT_SET',
  funnels: {},
  recordTypes: {},
  name: 'NOT_SET',
  isPinned: false,
  createdAt: 'NOT_SET',
  createdById: 'NOT_SET',
  updatedAt: 'NOT_SET',
  updatedById: 'NOT_SET',
  funnelsData: {},
  recordTypesData: {},
  hubspotFunnels: {},
  hubspotFunnelsData: {},
  thirdPartyFunnels: {},
  thirdPartyFunnelsData: {},
};
