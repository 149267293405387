import { useSelector } from 'react-redux';
import pick from 'lodash/pick';
import { selectEnvironments } from '../../../../reducers/global/globalReducer';
import { GlobalData } from '../../../../reducers/global/globalReducerTypes';
import { DeployStatusForTable } from '../../../../types/enums/DeployStatusForTable';
import { getRecordTypeNamesFromAutomation } from '../../../Automations/helper';
import { isRuleUsedByRecordType } from '../../../parser/parserUtils';
import { ConfigurationItemMap } from '../../configuration-item-tables/shared/helpers';
import { checkIfActive, createConfigurationItemFromAutomation } from '../../helpers';
import { useDocumentation } from '../../useDocumentation';
import { ConfigurationItem } from '../DependenciesTypes';
import { selectAccountUsers } from '../../../../reducers/accountUsersReducer';

export const useGetRecordTypeDependencies = ({
  recordTypeName,
  isSupportedType,
  objectName,
  crmOrgId,
  onlyActive,
}: {
  crmOrgId: string;
  isSupportedType: boolean;
  recordTypeName: string;
  objectName: string;
  onlyActive: boolean;
}) => {
  const { parsedRules } = useDocumentation();
  const users = useSelector(selectAccountUsers);
  const globalEnvs = useSelector(selectEnvironments);

  if (!isSupportedType) {
    return {};
  }

  const attributions = {} as ConfigurationItemMap;

  //by product definition object level attributions should be included
  parsedRules?.forEach((rule) => {
    if (
      isRuleUsedByRecordType(recordTypeName, objectName)(rule) &&
      rule.objectApiNames.includes(objectName)
    ) {
      const type = rule.type;

      if (!attributions[type]) {
        attributions[type] = [];
      }

      if (checkIfActive(onlyActive, rule)) {
        attributions[type].push(rule as ConfigurationItem);
      }
    }
  });

  //adding sweep elements
  const sweepElementsAttributions = {} as ConfigurationItemMap;
  const elements = globalEnvs?.[crmOrgId]?.data;
  const automationElements: Partial<GlobalData> = pick(
    elements ?? {},
    'automations',
    'alerts',
    'scheduledAssignments',
    'assignments',
    'dedup',
    'matching',
  );

  Object.entries(automationElements ?? {})?.forEach(([key, values]) => {
    sweepElementsAttributions[key as keyof ConfigurationItemMap] = [];
    (values as AutomationStructureNew[]).forEach((automation) => {
      if (
        ![DeployStatusForTable.Pending, DeployStatusForTable.Deployed].includes(
          automation.status,
        ) ||
        automation.objectName !== objectName
      ) {
        return;
      }

      const rtNames = getRecordTypeNamesFromAutomation(automation);

      if (rtNames.includes(recordTypeName) || rtNames.length === 0) {
        const configurationAutomation = createConfigurationItemFromAutomation(automation, users);

        if (configurationAutomation) {
          sweepElementsAttributions[key as keyof ConfigurationItemMap].push(
            configurationAutomation,
          );
        }
      }
    });
  });

  return { attributions, sweepElementsAttributions };
};
