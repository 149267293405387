import { Box } from '@mui/material';
import './dot-flashing.css';

const ChatLoader = () => {
  return (
    <Box display="flex" gap={1} alignItems="center">
      <Box className="dot-flashing number1" />
      <Box className="dot-flashing number2" />
      <Box className="dot-flashing number3" />
    </Box>
  );
};

export default ChatLoader;
