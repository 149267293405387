import { ReportingStatus } from '../components/pages/reports/ReportActivationStatuses';

export enum ReportingType {
  AE_PERFORMANCE = 'AePerformance',
  FORECASTING = 'Forecasting',
  FUNNEL_CONVERSION = 'FunnelConversion',
  LEAD_OVERVIEW = 'LeadOverview',
  VELOCITY = 'Velocity',
}

export type Report = {
  name: string;
  type: ReportingType;
  description: string;
  dashboardInfo: string;
  reportsInfo: string;
  sfObjects: string[];
  keyMetrics: string[];
  img: string;
  isComingSoon?: boolean;

  //BE data
  id?: string;
  status?: ReportingStatus;
  accountId?: string;
  url?: string;
};
