import { getDefaultMetadataValues } from '../../../../../lib/getDefaultMetadataValues';

export const getLeadingFieldMetadata = ({
  allStageMetadata,
  stage,
  funnelDetails,
}: {
  allStageMetadata: StageMetadata[];
  stage?: SweepStage;
  funnelDetails?: FunnelDetails;
}): StageMetadata | undefined => {
  if (!funnelDetails || !stage) return undefined;
  const relevantStageMetadata = allStageMetadata.find(
    (s) =>
      s.stageName === stage.stageName &&
      s.leadingFieldId === funnelDetails.leadingObject._leadingFieldId,
  );
  return relevantStageMetadata ?? getDefaultMetadataValues(stage, funnelDetails);
};
