import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { RoleGroupType, UserDefinedRoleGroupsInterface } from '@server/role-group-interface';
import { systemRolesDetails } from '../components/dashboard/role-manager/systemRolesDetails';

export interface RoleGroupState {
  roleGroups?: UserDefinedRoleGroupsInterface[];
  isLoading: boolean;
}

const initialState: RoleGroupState = {
  isLoading: false,
};

export const roleGroupsSlice = createSlice({
  name: 'roleGroups',
  initialState,
  reducers: {
    setRoleGroups: (
      state,
      action: PayloadAction<{ roleGroups: UserDefinedRoleGroupsInterface[] }>,
    ) => {
      const withSystemRolesDetails = action.payload.roleGroups.map((role) => {
        if (role.type === RoleGroupType.SYSTEM) {
          const roleDetails = systemRolesDetails.find((_role) => _role.name === role.name) ?? {};
          return { ...role, ...roleDetails };
        }
        return role;
      });
      state.roleGroups = withSystemRolesDetails;
      state.isLoading = false;
    },
    setIsLoading: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading;
    },
    addRoleGroup: (state, action: PayloadAction<{ roleGroup: UserDefinedRoleGroupsInterface }>) => {
      if (state.roleGroups) {
        state.roleGroups = [...state.roleGroups, action.payload.roleGroup];
      }
    },
    deleteRoleGroup: (state, action: PayloadAction<{ roleGroupId: string }>) => {
      if (state.roleGroups) {
        state.roleGroups = state.roleGroups.filter(
          (roleGroup) => roleGroup.id !== action.payload.roleGroupId,
        );
      }
    },
    updateRoleGroup: (
      state,
      action: PayloadAction<{ newRoleGroup: UserDefinedRoleGroupsInterface }>,
    ) => {
      const { newRoleGroup } = action.payload;
      if (state.roleGroups) {
        state.roleGroups = state.roleGroups.map((_oldRoleGroup) => {
          if (_oldRoleGroup.id === newRoleGroup.id) {
            return { ..._oldRoleGroup, ...newRoleGroup };
          }

          return _oldRoleGroup;
        });
      }
    },
  },
});

export const selectRoleGroups = (state: RootState) => state.roleGroups.roleGroups;
export const selectIsLoadingRoleGroups = (state: RootState) => state.roleGroups.isLoading;

export const { setRoleGroups, setIsLoading, addRoleGroup, updateRoleGroup, deleteRoleGroup } =
  roleGroupsSlice.actions;

export default roleGroupsSlice.reducer;
