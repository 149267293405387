import { useDependencies } from '../hooks/useDependencies';
import { useEffect, useMemo, useState } from 'react';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';
import { useSelector } from 'react-redux';
import {
  selectConfigurationSourceCode,
  selectUniversalSearch,
} from '../../../../reducers/documentationReducer';
import { ListWithScroll } from './ListWithScroll';
import { SourceCodeHeader } from '../header/SourceCodeHeader';
import { ConfigurationType } from '../types';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { ConfigurationItem } from '../DependenciesTypes';

interface SourceViewProps {
  crmOrgId: string;
  configurationItem: ConfigurationItem;
  parentType: ConfigurationType;
}

export const SourceView = ({ crmOrgId, configurationItem, parentType }: SourceViewProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const { getSourceCode } = useDependencies(crmOrgId);
  const { addNotification } = useSweepNotifications();
  const universalSearch = useSelector(selectUniversalSearch);

  const id = useMemo(
    () => configurationItem.id ?? configurationItem.name,
    [configurationItem.id, configurationItem.name],
  ); //name for objects
  const sourceCode = useSelector(selectConfigurationSourceCode(crmOrgId, id));

  useEffect(() => {
    (async () => {
      if (!sourceCode && configurationItem.filename) {
        setIsLoading(true);
        await getSourceCode(configurationItem.filename, id);
        setIsLoading(false);
      }

      if (!configurationItem.filename) {
        addNotification({
          variant: SweepNotificationVariant.Error,
          message:
            'We are unable to find this configuration file, please contact our team for details',
        });
      }
    })();
  }, [sourceCode, configurationItem.filename, id, getSourceCode, addNotification]);

  if (isLoading) {
    return <CenteredCircularProgress />;
  }

  return (
    <>
      <SourceCodeHeader
        configurationItem={configurationItem}
        type={parentType}
        crmOrgId={crmOrgId}
      />
      <ListWithScroll sourceCode={sourceCode} searchTxt={universalSearch?.searchText} />
    </>
  );
};
