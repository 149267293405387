import { Box, Stack } from '@mui/material';
import { ChatShort } from './copilotChatsReducer';
import { colors, Typography } from '@sweep-io/sweep-design';

const ChatsSection = ({ title, chats }: { title: string; chats: ChatShort[] }) => {
  return (
    <Stack>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          padding: '0 16px',
          height: '32px',
        }}
      >
        <Typography variant="body-bold">{title}</Typography>
      </Box>
      {chats.map((chat) => (
        <Box
          key={chat.id}
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '48px',
            padding: '0 16px',
            '&:hover': {
              backgroundColor: colors.grey[200],
            },
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${colors.grey[300]}`,
            }}
          >
            <Typography variant="body">{chat.name}</Typography>
          </Box>
        </Box>
      ))}
    </Stack>
  );
};

export default ChatsSection;
