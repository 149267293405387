import { useEffect, useMemo } from 'react';
import { setUserDetailsForIntercom } from '../services/intercom';
import { setUserDetailsForMonitoring } from '../services/monitoring';
import { isDevEnv } from '../environment';
import { IS_INVITATION_QUERY_PARAM_NAME, UTM_COOKIE_NAME } from '../auth/authConsts';
import { setUserDetailsForBi } from '../services/mixpanel';
import { selectUserInfoData } from '../reducers/userInfoReducer';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useSweepRoleGroups } from '../components/common/useSweepRoleGroups';
import useSweepNavigate from '../components/common/useSweepNavigate';
import useQueryParams from './useQueryParams';
import { ClearbitEnrichment } from '../types/UserInfoTypes';
import useUserInfo from './useUserInfo';
import { selectConnectedCrmOrgs } from '../components/pages/environments/environmentsReducer';
import isEqual from 'lodash/isEqual';
import { humanizeDate } from '../components/helpers/humanizeDate';
import usePaywall from '../components/dashboard/paywall/usePaywall';

const getClearbitProps = (props?: ClearbitEnrichment) => {
  if (!props) return {};

  const company = props?.company ?? {};
  const person = props?.person ?? {};

  return {
    //person
    CB_title: person.employment?.title,
    CB_role: person.employment?.role,
    CB_seniority: person.employment?.seniority,
    CB_location: person.location,
    CB_linkedin: person.linkedin?.handle,
    //company
    CB_companyName: company.name,
    CB_domain: company.domain,
    CB_industry: company.category?.industry,
    CB_subIndustry: company.category?.subIndustry,
    CB_sector: company.category?.sector,
    CB_industryGroup: company.category?.industryGroup,
    CB_employees: company.metrics?.employees,
    CB_revenue: company.metrics?.annualRevenue,
    CB_foundedYear: company.foundedYear,
    CB_company_location: company.location,
    CB_company_linkedin: company.linkedin?.handle,
    CB_tech: company.techCategories?.join(', '),
  };
};

const useSetupMonitoring = () => {
  const userInfo = useSelector(selectUserInfoData, isEqual);
  const [cookies] = useCookies();
  const ldClient = useLDClient();
  const { getUserRole } = useSweepRoleGroups();
  const { removeQueryParams } = useSweepNavigate();
  const query = useQueryParams();
  const { getClearbitEnrichment } = useUserInfo();
  const crmOrgs = useSelector(selectConnectedCrmOrgs);
  const crmOrgsCount = crmOrgs.length;
  const { paywallState } = usePaywall();

  const { id, name, email, account, preferences, roleGroupId } = userInfo ?? {};
  const {
    name: accountName,
    id: accountId,
    type: accountType,
    trialStartDate: trialStartDateRaw,
    trialEndDate: trialEndDateRaw,
  } = account ?? {};
  const { questionnaire } = preferences ?? {};
  const questionnaireRole = questionnaire?.data?.role;
  const questionnaireUseCases = questionnaire?.data?.interests?.join(',');

  const trialStartDate = trialStartDateRaw
    ? humanizeDate({ dateOrTimestamp: trialStartDateRaw })
    : undefined;
  const trialEndDate = trialEndDateRaw
    ? humanizeDate({ dateOrTimestamp: trialEndDateRaw })
    : undefined;
  const isInvitation = query.get(IS_INVITATION_QUERY_PARAM_NAME) === 'true';

  const role = useMemo(() => getUserRole(roleGroupId ?? ''), [roleGroupId, getUserRole]);

  /** set user details should be in "useEffect" to support updates (e.g. user name or account name, that can be changed during the session) **/

  useEffect(() => {
    (async () => {
      if (id && role?.name) {
        const utmParams = cookies[UTM_COOKIE_NAME];
        const clearbitData = await getClearbitEnrichment();

        setUserDetailsForBi(
          {
            id: id ?? '',
            name: name ?? '',
            email: email ?? '',
            accountName,
            accountId,
            accountType,
            questionnaireRole,
            questionnaireUseCases,
            role: role?.name ?? '',
            userSource: isInvitation ? 'Product Invite' : undefined,
          },
          utmParams,
          getClearbitProps(clearbitData),
        );

        if (isInvitation) {
          removeQueryParams([IS_INVITATION_QUERY_PARAM_NAME]);
        }
      }
    })();
    // adding "cookies" is causing extra calls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountId,
    accountName,
    accountType,
    email,
    getClearbitEnrichment,
    getUserRole,
    id,
    isInvitation,
    name,
    questionnaireRole,
    questionnaireUseCases,
    removeQueryParams,
    role?.name,
  ]);

  useEffect(() => {
    if (id) {
      setUserDetailsForIntercom({
        id,
        name,
        email,
        accountType,
        trialStatus: paywallState,
        trialStartDate,
        trialEndDate,
      });
    }
  }, [
    accountType,
    trialEndDate,
    trialStartDate,
    paywallState,
    id,
    name,
    email,
    questionnaireRole,
    questionnaireUseCases,
  ]);

  useEffect(() => {
    if (id) {
      setUserDetailsForMonitoring({
        id,
        email,
        name,
        accountName,
        accountId,
        accountType,
        trialStatus: paywallState,
        trialStartDate,
        trialEndDate,
        crmOrgsCount,
      });
    }
  }, [
    id,
    email,
    name,
    accountName,
    accountId,
    accountType,
    paywallState,
    trialStartDate,
    trialEndDate,
    crmOrgsCount,
  ]);

  useEffect(() => {
    if (ldClient && id) {
      const nameOrEmail = name || email || 'Unknown';
      const _name = isDevEnv ? nameOrEmail + ' (DEV)' : nameOrEmail;

      /**
       * Important!
       * Exclude auth0 generated users from LD experiments to keep the quota
       * For each run of e2e tests, we will use the same user for LD.
       */
      const sweepEmailAutomationSuffix = '@sweepteam.testinator.com';
      if (email?.endsWith(sweepEmailAutomationSuffix)) {
        ldClient.identify({ key: 'sweep_automation_user', name: 'Sweep Automation User' });
      } else {
        ldClient.identify({ key: id, email, name: _name });
      }
    }
  }, [email, id, ldClient, name]);
};

export default useSetupMonitoring;
