import keyBy from 'lodash/keyBy';
import { PayloadAction } from '@reduxjs/toolkit';
import { UnitedCanvasReducerState } from './unitedCanvasReducer';
import { FunnelType } from '../../types/enums/FunnelType';
import { uniqueId } from '../../lib/uniqueId';
import { RootState } from '..';

export const thirdPartyCanvasReducer = {
  addThirdPartyFunnelStageToCanvas: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      newStep: ThirdPartyStep;
      stepPositionChanges: {
        stepId: string;
        newPosition: FunnelMapPosition;
      }[];
      stepConnectionChanges: {
        id: string;
        originId: string;
        targetId: string;
      }[];
      newStepConnection?: {
        id: string;
        originId: string;
        targetId: string;
      };
    }>,
  ) => {
    if (!state.funnelMap) {
      return;
    }

    const funnel = state.funnelMap.thirdPartyFunnelsData[action.payload.funnelId];
    funnel.steps.push(action.payload.newStep);
    if (action.payload.newStepConnection) {
      const { id, originId, targetId } = action.payload.newStepConnection;
      const step = funnel.steps.find((step) => step.id === originId);

      if (step) {
        step.stepConnections.push({
          _id: id,
          targetId,
        });
      }
    }
    if (action.payload.stepConnectionChanges) {
      const filteredStepConnections = action.payload.stepConnectionChanges.filter(
        (change) => change.id !== action.payload.newStepConnection?.id,
      );
      const stepConnectionChangesById = keyBy(filteredStepConnections, 'id');
      const stepConnectionChangesIds = Object.keys(stepConnectionChangesById);

      funnel.steps.forEach((step) => {
        step.stepConnections.forEach((connection) => {
          if (stepConnectionChangesIds.includes(connection._id)) {
            connection.targetId = stepConnectionChangesById[connection._id].targetId;
          }
        });
      });
    }
    if (action.payload.stepPositionChanges) {
      action.payload.stepPositionChanges.forEach((change) => {
        const step = funnel.steps.find((step) => step.id === change.stepId);
        if (step) {
          step.position = change.newPosition;
        }
      });
    }
  },
  addThirdPartyFunnelToCanvas: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      thirdPartyFunnel: ThirdPartyFunnelData;
      position: FunnelMapPosition;
    }>,
  ) => {
    if (state.funnelMap) {
      const { position, thirdPartyFunnel } = action.payload;
      state.funnelMap.thirdPartyFunnels = state.funnelMap.thirdPartyFunnels || {};
      state.funnelMap.thirdPartyFunnelsData = state.funnelMap.thirdPartyFunnelsData || {};

      state.funnelMap.thirdPartyFunnels[thirdPartyFunnel.id] = { position };
      state.funnelMap.thirdPartyFunnelsData[thirdPartyFunnel.id] = thirdPartyFunnel;
    }
  },
  moveThirdPartyFunnelStagesOnCanvas: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      stepPositionChanges: {
        funnelId: string;
        stepId: string;
        newPosition: FunnelMapPosition;
      }[];
    }>,
  ) => {
    const funnel = state.funnelMap?.thirdPartyFunnelsData;
    if (!funnel) {
      return;
    }
    action.payload.stepPositionChanges.forEach((change) => {
      const step = funnel[change.funnelId].steps.find((step) => step.id === change.stepId);
      if (step) {
        step.position = change.newPosition;
      }
    });
  },
  moveThirdPartyFunnelsOnCanvas: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<
      {
        funnelId: string;
        newPosition: FunnelMapPosition;
      }[]
    >,
  ) => {
    const { thirdPartyFunnels = {} } = state.funnelMap || {};
    action.payload.forEach((change) => {
      if (Object.keys(thirdPartyFunnels).includes(change.funnelId)) {
        thirdPartyFunnels[change.funnelId].position = change.newPosition;
      }
    });
  },
  editThirdPartyFunnelOnCanvas: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<ThirdPartyFunnelData>,
  ) => {
    if (state.funnelMap) {
      const { id } = action.payload;
      const { thirdPartyFunnelsData } = state.funnelMap;
      if (thirdPartyFunnelsData) {
        thirdPartyFunnelsData[id] = action.payload;
      }
    }
  },
  connectThirdPartyFunnelSteps: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      stageA: {
        funnelId: string;
        stageId?: string;
      };
      stageB: {
        funnelId: string;
        stageId?: string;
        funnelType: FunnelType;
      };
    }>,
  ) => {
    if (state.funnelMap) {
      const { stageA, stageB } = action.payload;
      const originFunnel = state.funnelMap.thirdPartyFunnelsData[stageA.funnelId];
      if (!originFunnel) return;
      // Stage to stage connection
      if (stageA.stageId) {
        const originStepIdx = originFunnel.steps.findIndex((step) => step.id === stageA.stageId);
        if (originStepIdx === -1) return;
        const originStep = originFunnel.steps[originStepIdx];

        // Same funnel stage connection
        if (stageB.stageId && stageA.funnelId === stageB.funnelId) {
          originStep.stepConnections.push({
            _id: uniqueId(),
            targetId: stageB.stageId,
          });
        } else {
          // Different funnel stage connection
          originStep.funnelLinks = [
            ...(originStep.funnelLinks || []),
            {
              _id: uniqueId(),
              funnelId: stageB.funnelId,
              stageId: stageB.stageId,
              _type: stageB.funnelType,
            },
          ];
        }
      }
    }
  },
  removeThirdPartyFunnelStepFunnelConnection: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      linkId: string;
      funnelId: string;
      stepId: string;
    }>,
  ) => {
    if (state.funnelMap) {
      const { funnelId, stepId, linkId } = action.payload;
      const funnel = state.funnelMap.thirdPartyFunnelsData[funnelId];
      if (!funnel) return;

      const step = funnel.steps.find((step) => step.id === stepId);
      if (!step) return;
      if (!step.funnelLinks) return;

      step.funnelLinks = step.funnelLinks?.filter((link) => link._id !== linkId);
      step.stepConnections = step.stepConnections.filter((connection) => connection._id !== linkId);
    }
  },
  removeThirdPartyFunnelFromCanvas: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<string>,
  ) => {
    if (state.funnelMap) {
      const funnelId = action.payload;
      const { thirdPartyFunnels, thirdPartyFunnelsData, funnelsData } = state.funnelMap;
      if (thirdPartyFunnels && thirdPartyFunnelsData) {
        // Remove all references to the funnel
        Object.values(thirdPartyFunnelsData).forEach((funnel) => {
          funnel.steps.forEach((step) => {
            step.stepConnections = step.stepConnections.filter(
              (connection) => connection.targetId !== action.payload,
            );
            step.funnelLinks = step.funnelLinks?.filter(
              (link) => !(link.funnelId === funnelId && link._type === FunnelType.THIRD_PARTY),
            );
          });
        });
        Object.values(funnelsData).forEach((funnel) => {
          funnel.funnelDetails.funnelLinks = funnel.funnelDetails.funnelLinks?.filter(
            (link) => !(link.funnelId === funnelId && link._type === FunnelType.THIRD_PARTY),
          );
          funnel.funnelDetails.stages.forEach((stage) => {
            stage.funnelLinks = stage.funnelLinks?.filter(
              (link) => !(link.funnelId === funnelId && link._type === FunnelType.THIRD_PARTY),
            );
          });
        });
        delete thirdPartyFunnels[funnelId];
        delete thirdPartyFunnelsData[funnelId];
      }
    }
  },
  renameThirdPartyFunnelStep: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{ funnelId: string; stepId: string; newName: string }>,
  ) => {
    if (state.funnelMap) {
      const { funnelId, stepId, newName } = action.payload;
      const funnel = state.funnelMap.thirdPartyFunnelsData[funnelId];
      if (!funnel) return;
      const step = funnel.steps.find((step) => step.id === stepId);
      if (!step) return;
      step.name = newName;
    }
  },
  removeThirdPartyFunnelStep: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{ funnelId: string; stepId: string }>,
  ) => {
    if (state.funnelMap) {
      const { funnelId, stepId } = action.payload;
      const funnel = state.funnelMap.thirdPartyFunnelsData[funnelId];
      if (!funnel) return;
      funnel.steps = funnel.steps.filter((step) => step.id !== stepId);

      // Remove all references to the funnel step
      Object.values(state.funnelMap.thirdPartyFunnelsData).forEach((funnel) => {
        funnel.steps.forEach((step) => {
          step.stepConnections = step.stepConnections.filter(
            (connection) => connection.targetId !== stepId,
          );
          step.funnelLinks = step.funnelLinks?.filter(
            (link) => !(link.stageId === stepId && link.funnelId === funnelId),
          );
        });
      });
      Object.values(state.funnelMap.funnelsData).forEach((funnel) => {
        funnel.funnelDetails.stages.forEach((stage) => {
          stage.funnelLinks = stage.funnelLinks?.filter(
            (link) => !(link.stageId === stepId && link.funnelId === funnelId),
          );
        });
      });
    }
  },
};

export const selectThirdPartyFunnelStep =
  (funnelId: string, stepId: string) => (state: RootState) => {
    const funnel = state.unitedCanvas?.present.funnelMap?.thirdPartyFunnelsData[funnelId];
    if (!funnel) return;
    return funnel.steps.find((step) => step.id === stepId);
  };
