import { Box, MenuItem } from '@mui/material';
import SweepSelect from '../../common/SweepSelect';

export type TerritorySelectorProps = {
  readonly: boolean;
  fieldLabels: any;
  onChange: any;
};

export const TerritorySelector = ({ readonly, fieldLabels, onChange }: TerritorySelectorProps) => {
  return (
    <Box>
      <SweepSelect
        FormControlProps={{
          sx: { width: '100%' },
        }}
        removeBorders={true}
        SelectProps={{
          placeholder: 'Select item',
          disabled: readonly,
          value: fieldLabels[0] ?? '',
          onChange,
        }}
      >
        {['Country', 'State', 'Postal Code', 'City'].map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </SweepSelect>
    </Box>
  );
};
