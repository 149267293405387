import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '.';
import { AssignmentGroupsListModel } from '../models/AssignmentGroupsListModel';
import { AssignmentGroup, AssignmentGroupMember } from './assignmentGroupTypes';

export interface AssignmentGroupsPageState {
  assignmentGroupsLoaded: boolean;
  assignmentGroupsList?: AssignmentGroup[];
  latestAssignmentGroupInServer?: AssignmentGroup;
}

const initialState: AssignmentGroupsPageState = {
  assignmentGroupsLoaded: false,
};

const assignmentGroupsPageReducer = createSlice({
  name: 'assignmentGroupsPage',
  initialState,
  reducers: {
    loadAssignmentGroups: (state, action: PayloadAction<AssignmentGroup[]>) => {
      state.assignmentGroupsList = action.payload;
      state.assignmentGroupsLoaded = true;
    },
    addAssignmentGroup: (state, action: PayloadAction<AssignmentGroup>) => {
      state.assignmentGroupsList = state.assignmentGroupsList || [];
      state.assignmentGroupsList.push(action.payload);
    },
    deleteAssignmentGroup: (state, action: PayloadAction<{ groupId: string }>) => {
      state.assignmentGroupsList &&
        new AssignmentGroupsListModel(state.assignmentGroupsList).removeGroupById(
          action.payload.groupId,
        );
    },
    setAssignmentGroupData: (
      state,
      action: PayloadAction<{ groupId: string; assignmentGroup: AssignmentGroup }>,
    ) => {
      state.assignmentGroupsList = state.assignmentGroupsList || [];

      new AssignmentGroupsListModel(state.assignmentGroupsList).upsertAssignmentGroup({
        ...action.payload.assignmentGroup,
        id: action.payload.groupId,
      });
    },
    setAssignmentGroupMembers: (
      state,
      action: PayloadAction<{ groupId: string; members: AssignmentGroupMember[] }>,
    ) => {
      if (state.assignmentGroupsList) {
        new AssignmentGroupsListModel(state.assignmentGroupsList)
          .assignmentGroupById(action.payload.groupId)
          ?.setMembers(action.payload.members);
      }
    },
    removeAssignmentGroupMemberAtIdx: (
      state,
      action: PayloadAction<{ groupId: string; index: number }>,
    ) => {
      if (state.assignmentGroupsList) {
        new AssignmentGroupsListModel(state.assignmentGroupsList)
          .assignmentGroupById(action.payload.groupId)
          ?.removeMemberAtIdx(action.payload.index);
      }
    },
    setAssignmentGroupMemberActiveStatusAtIdx: (
      state,
      action: PayloadAction<{ groupId: string; index: number; active: boolean }>,
    ) => {
      if (state.assignmentGroupsList) {
        new AssignmentGroupsListModel(state.assignmentGroupsList)
          .assignmentGroupById(action.payload.groupId)
          ?.setMemberActiveStatusAtIdx(action.payload.index, action.payload.active);
      }
    },
    setAssignmentGroupMemberWeightAtIdx: (
      state,
      action: PayloadAction<{ groupId: string; index: number; weight: number }>,
    ) => {
      if (state.assignmentGroupsList) {
        new AssignmentGroupsListModel(state.assignmentGroupsList)
          .assignmentGroupById(action.payload.groupId)
          ?.setMemberWeightAtIdx(action.payload.index, action.payload.weight);
      }
    },
    setAssignmentGroupMemberLimitAtIdx: (
      state,
      action: PayloadAction<{ groupId: string; index: number; limit: number | null }>,
    ) => {
      if (state.assignmentGroupsList) {
        new AssignmentGroupsListModel(state.assignmentGroupsList)
          .assignmentGroupById(action.payload.groupId)
          ?.setMemberLimitAtIdx(action.payload.index, action.payload.limit);
      }
    },

    setLatestAssignmentGroupInServer: (state, action: PayloadAction<AssignmentGroup>) => {
      const group = action.payload;
      if (
        state.latestAssignmentGroupInServer &&
        state.latestAssignmentGroupInServer.id === group.id
        // TODO: Ask ron to return the updated_at
        // && state.latestAssignmentGroupInServer.updatedAt &&
        // group.updatedAt
      ) {
        // if (new Date(state.latestAssignmentGroupInServer.updatedAt) < new Date(group.updatedAt)) {
        state.latestAssignmentGroupInServer = action.payload;
        if (state.assignmentGroupsList) {
          new AssignmentGroupsListModel(state.assignmentGroupsList)
            .assignmentGroupById(group.id)
            ?.updateIsUpNext(group);
          // .setUpdatedAt(group.updatedAt);
        }
        // }
      } else {
        state.latestAssignmentGroupInServer = action.payload;
      }
    },
    revertToLatestAssignmentGroupInServer: (state) => {
      if (state.latestAssignmentGroupInServer) {
        state.assignmentGroupsList = state.assignmentGroupsList || [];
        const idx = state.assignmentGroupsList.findIndex(
          (group) => group.id === state.latestAssignmentGroupInServer?.id,
        );
        if (idx >= 0) {
          state.assignmentGroupsList[idx] = state.latestAssignmentGroupInServer;
        }
      }
    },
    clearAllAssignmentGroups: (state) => {
      state.assignmentGroupsList = initialState.assignmentGroupsList;
      state.assignmentGroupsLoaded = initialState.assignmentGroupsLoaded;
      state.latestAssignmentGroupInServer = initialState.latestAssignmentGroupInServer;
    },
  },
});

export const {
  loadAssignmentGroups,
  addAssignmentGroup,
  deleteAssignmentGroup,
  removeAssignmentGroupMemberAtIdx,
  setAssignmentGroupData,
  setAssignmentGroupMemberActiveStatusAtIdx,
  setAssignmentGroupMemberWeightAtIdx,
  setAssignmentGroupMemberLimitAtIdx,
  setAssignmentGroupMembers,
  setLatestAssignmentGroupInServer,
  revertToLatestAssignmentGroupInServer,
  clearAllAssignmentGroups,
} = assignmentGroupsPageReducer.actions;

export const selectAssignmentGroupsList = (state: RootState) =>
  state.assignmentGroupsPage.assignmentGroupsList;
export const selectAssignmentGroupById = (groupId: string) => (state: RootState) =>
  state.assignmentGroupsPage.assignmentGroupsList?.find(({ id }) => groupId === id);

export const selectIsAssignmentGroupsLoaded = (state: RootState) =>
  state.assignmentGroupsPage.assignmentGroupsLoaded;

export default assignmentGroupsPageReducer.reducer;
