import { Box, ListItemIcon, MenuItem, SelectChangeEvent } from '@mui/material';
import { colors, Tooltip, Typography } from '@sweep-io/sweep-design';
import SweepSelect from '../common/SweepSelect';
import { useMemo } from 'react';
import { TriggerStageEnter } from './Triggers/TriggerStageEnter';
import { TriggerStageExit } from './Triggers/TriggerStageExit';
import { TriggerFieldUpdate } from './Triggers/TriggerFieldUpdate';
import { TriggerDateArrive } from './Triggers/TriggerDateArrive';
import { AutomationTypes } from '../../types/enums/AutomationTypes';
import { TriggerTimeInStep } from './Triggers/TriggerTimeInStep';
import { FormAddConditions } from './FormAddConditions';
import { labelBoxDesign } from './AutomationsForm';
import { RecordTypeSelector } from './RecordTypeSelector';
import AutomationScheduleRecurring from './AutomationScheduleRecurring';
import { AutomationType } from '../../types/enums/AutomationType';
import { cloneDeep } from 'lodash';
import {
  Calendar,
  CreatedUpdated,
  DelayedRefresh,
  EnterStep,
  ExitStep,
  FieldUpdated,
  PlusCircle,
  RecordInStep,
  Refresh,
} from '@sweep-io/sweep-design/dist/icons';
import { DividerLighter } from './helper';

type WhenTypesProps = {
  value: AutomationTypes;
  description: string;
  label: string;
  disabled?: boolean;
  icon: JSX.Element;
  groupIndex: number;
};

interface AutomationDefaultTriggersProps {
  readonly?: boolean;
  automationJson: DeepPartial<AutomationStructureNew>;
  objectName: ObjectTypeValues;
  callBack: (data: DeepPartial<AutomationStructureNew>) => void;
  recordTypesAndFunnelsDataForObject?: ParsedRecordTypesAndFunnelsObjectData;
  crmOrgId: string;
  filterFunnelIds?: string[];
}

export const AutomationDefaultTriggers = ({
  readonly,
  automationJson,
  objectName,
  callBack,
  recordTypesAndFunnelsDataForObject,
  crmOrgId,
  filterFunnelIds,
}: AutomationDefaultTriggersProps) => {
  let funnelsList = recordTypesAndFunnelsDataForObject?.funnels;
  if (funnelsList && filterFunnelIds) {
    funnelsList = funnelsList.filter((funnel) => filterFunnelIds.includes(funnel.id));
  }
  const isDisableFunnelTriggers = !funnelsList || funnelsList?.length === 0;

  const WhenTypes: WhenTypesProps[] = useMemo(
    () => [
      {
        value: AutomationTypes.OnUpsert,
        description: 'Record is created or updated',
        label: 'Record is created or updated',
        icon: <CreatedUpdated />,
        groupIndex: 0,
      },
      {
        value: AutomationTypes.OnCreate,
        description: 'Record is created',
        label: 'Record is created',
        icon: <PlusCircle />,
        groupIndex: 0,
      },
      {
        value: AutomationTypes.OnUpdate,
        description: 'Record is updated',
        label: 'Record is updated',
        icon: <Refresh />,
        groupIndex: 0,
      },
      {
        value: AutomationTypes.FieldUpdate,
        description: 'Field is updated',
        label: 'Field is updated',
        icon: <FieldUpdated />,
        groupIndex: 0,
      },
      {
        value: AutomationTypes.Scheduled,
        description: 'Scheduled time arrives',
        label: 'Scheduled time arrives',
        icon: <DelayedRefresh />,
        groupIndex: 1,
      },
      {
        value: AutomationTypes.DateArrive,
        description: 'Date arrives',
        label: 'Date arrives',
        icon: <Calendar />,
        groupIndex: 1,
      },

      {
        value: AutomationTypes.StageEnter,
        description: 'Entering a step',
        label: 'Entering',
        disabled: isDisableFunnelTriggers,
        icon: <EnterStep />,
        groupIndex: 2,
      },
      {
        value: AutomationTypes.StageExit,
        description: 'Exiting a step',
        label: 'Exiting',
        disabled: isDisableFunnelTriggers,
        icon: <ExitStep />,
        groupIndex: 2,
      },
      {
        value: AutomationTypes.TimeInStep,
        description: 'Record is in step for',
        label: 'Record is in step',
        disabled: isDisableFunnelTriggers,
        icon: <RecordInStep />,
        groupIndex: 2,
      },
    ],
    [isDisableFunnelTriggers],
  );

  const isDateOrFieldUpdateTrigger = () =>
    automationJson.automationDetails?.triggerType === AutomationTypes.FieldUpdate ||
    automationJson.automationDetails?.triggerType === AutomationTypes.DateArrive;

  const isRecordType =
    recordTypesAndFunnelsDataForObject &&
    recordTypesAndFunnelsDataForObject?.recordTypes?.length > 1;

  const addRecordTypeSelector = isRecordType && (
    <RecordTypeSelector
      onChange={(recordTypesList) => {
        const selectedRecordTypes =
          recordTypesList.length === recordTypesAndFunnelsDataForObject?.recordTypes.length
            ? undefined
            : recordTypesAndFunnelsDataForObject?.recordTypes?.filter((elem) =>
                recordTypesList.includes(elem.name),
              );
        const temp: DeepPartial<AutomationDetailsDtoNew> = {
          ...automationJson.automationDetails,
          recordTypes: selectedRecordTypes,
        };
        callBack({
          ...automationJson,
          automationDetails: temp,
        });
      }}
      value={automationJson.automationDetails?.recordTypes}
      disabled={readonly}
      recordTypesList={recordTypesAndFunnelsDataForObject?.recordTypes ?? []}
    />
  );

  const addConditionsToTrigger = () => {
    callBack({
      ...automationJson,
      automationDetails: {
        ...automationJson.automationDetails,
        when: { isCriteria: true },
      },
    });
  };
  const addDelayToAutomation = () => {
    callBack({
      ...automationJson,
      automationDetails: {
        ...automationJson.automationDetails,
        delay: {},
      },
    });
  };

  const isTriggerScheduled =
    automationJson?.automationDetails?.triggerType === AutomationTypes.Scheduled;
  const isShowTriggerConditions = !isTriggerScheduled;

  const isHideTriggerSelector = automationJson?.type === AutomationType.ScheduledReport;

  const getWhenTypesList = useMemo(() => {
    let currentGroupIndex = WhenTypes?.[0]?.groupIndex ?? 0;
    const elemList = WhenTypes.map((type) => {
      const item = type.disabled ? (
        <Tooltip key={type.value} title={'Please add a funnel to enable this trigger'}>
          <span style={{ marginBottom: '8px', display: 'block' }}>
            <MenuItem value={type.value} disabled={type.disabled}>
              <ListItemIcon>{type.icon}</ListItemIcon>
              {type.description}
            </MenuItem>
          </span>
        </Tooltip>
      ) : (
        <MenuItem key={type.value} value={type.value}>
          <ListItemIcon>{type.icon}</ListItemIcon>
          {type.description}
        </MenuItem>
      );
      if (type.groupIndex > currentGroupIndex) {
        currentGroupIndex = type.groupIndex;
        return [<DividerLighter key={`divider${currentGroupIndex}`} />, item];
      } else {
        return item;
      }
    });

    return elemList;
  }, [WhenTypes]);

  return (
    <Box sx={{ marginBottom: '16px' }}>
      <Box
        sx={{
          ...labelBoxDesign,
          background: colors.sun[500],
        }}
      >
        <Typography variant="caption-bold" textAlign="center" color={colors.white}>
          WHEN
        </Typography>
      </Box>
      {!isHideTriggerSelector && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              position: 'relative',
            }}
          >
            <SweepSelect
              FormControlProps={{
                sx: { minWidth: '120px', marginRight: '8px', position: 'relative' },
              }}
              menuMaxHeight={'430px'}
              SelectProps={{
                placeholder: 'This Happens',
                disabled: readonly,
                MenuProps: {
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  sx: {
                    '.MuiMenu-paper .MuiMenuItem-root .MuiListItemIcon-root': {
                      minWidth: 2,
                      mr: 1,
                    },
                  },
                },
                value: automationJson?.automationDetails?.triggerType ?? '',
                onChange: (event: SelectChangeEvent<unknown>) => {
                  const val = event.target.value;
                  const _object = objectName;
                  const _params =
                    val === AutomationTypes.OnCreate ||
                    val === AutomationTypes.OnUpdate ||
                    val === AutomationTypes.OnUpsert
                      ? {}
                      : undefined;
                  let _delay = automationJson.automationDetails?.delay;
                  if (val === AutomationTypes.DateArrive || val === AutomationTypes.TimeInStep) {
                    _delay = undefined;
                  }
                  let _when = cloneDeep(automationJson?.automationDetails?.when);
                  if (val === AutomationTypes.Scheduled) {
                    _when = undefined;
                  } else {
                    // This removes the field context from the criteria when the trigger is not FieldUpdate or OnUpdate
                    // because the field context is not supported by other triggers
                    // but we don't want to loose all the criteria
                    if (val !== AutomationTypes.FieldUpdate && val !== AutomationTypes.OnUpdate) {
                      if (_when?.criteria?.criteria) {
                        _when.criteria.criteria = _when.criteria.criteria.map((criterion) => ({
                          ...criterion,
                          fieldContext: undefined,
                        }));
                      }
                    }
                  }

                  callBack({
                    ...automationJson,
                    objectName: _object,
                    automationDetails: {
                      ...automationJson.automationDetails,
                      automationParams: _params,
                      delay: _delay,
                      triggerType: val as AutomationTypes,
                      funnelId: undefined,
                      getDataAction: undefined,
                      when: _when,
                    },
                  });
                },
                renderValue: (val: any) => {
                  const output = WhenTypes.filter((d) => d.value === val);
                  if (output.length > 0) {
                    return output[0].label;
                  }
                  return val ?? '';
                },
              }}
            >
              {getWhenTypesList}
            </SweepSelect>
          </Box>

          {(automationJson.automationDetails?.triggerType === AutomationTypes.OnCreate ||
            automationJson.automationDetails?.triggerType === AutomationTypes.OnUpdate ||
            automationJson.automationDetails?.triggerType === AutomationTypes.OnUpsert) &&
            addRecordTypeSelector}

          {automationJson.automationDetails?.triggerType === AutomationTypes.StageEnter && (
            <TriggerStageEnter
              onFunnelChange={(funnelId) => {
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    funnelId: funnelId,
                  },
                });
              }}
              objectName={objectName}
              onChange={(stageDetails, _objectName) => {
                const temp: DeepPartial<AutomationsParams> = {
                  ...automationJson.automationDetails?.automationParams,
                  stageEnter: { toStage: stageDetails },
                };
                const isObjectChanged = automationJson.objectName !== _objectName;
                callBack({
                  ...automationJson,
                  objectName: _objectName,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: temp,
                    actions: isObjectChanged ? [] : automationJson.automationDetails?.actions,
                    when: isObjectChanged ? undefined : automationJson.automationDetails?.when,
                  },
                });
              }}
              crmOrgId={crmOrgId}
              automationJson={automationJson}
              funnels={funnelsList}
              readonly={readonly}
            />
          )}
          {automationJson.automationDetails?.triggerType === AutomationTypes.StageExit && (
            <TriggerStageExit
              funnels={funnelsList}
              objectName={objectName}
              crmOrgId={crmOrgId}
              automationJson={automationJson}
              readonly={readonly}
              onFunnelChange={(funnelId) => {
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    funnelId: funnelId,
                  },
                });
              }}
              onChangeFromStage={(stageDetails, _objectName) => {
                const temp: AutomationsParams = {
                  ...automationJson.automationDetails?.automationParams,
                  stageExit: { fromStage: stageDetails },
                };
                const isObjectChanged = automationJson.objectName !== _objectName;
                callBack({
                  ...automationJson,
                  objectName: _objectName,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: temp,
                    actions: isObjectChanged ? [] : automationJson.automationDetails?.actions,
                    when: isObjectChanged ? undefined : automationJson.automationDetails?.when,
                  },
                });
              }}
              onChangeToStage={(stageDetails) => {
                const temp: AutomationsParams = {
                  ...automationJson.automationDetails?.automationParams,
                  stageExit: {
                    ...automationJson.automationDetails?.automationParams?.stageExit,
                    toStage: stageDetails,
                  },
                };
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: temp,
                  },
                });
              }}
            />
          )}
          {automationJson.automationDetails?.triggerType === AutomationTypes.FieldUpdate && (
            <TriggerFieldUpdate
              objectName={objectName}
              readonly={readonly}
              crmOrgId={crmOrgId}
              automationJson={automationJson}
              onChangeField={(leadingFieldId, leadingFieldLabels, fieldType, sweepField) => {
                const fieldUpdate: AutomationParamsFieldUpdate = {
                  _leadingFieldId: leadingFieldId,
                  _leadingFieldLabels: leadingFieldLabels,
                  fieldType: fieldType,
                  operator: undefined,
                  operatorValue: undefined,
                  field: sweepField,
                };
                callBack({
                  ...automationJson,
                  objectName: objectName,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: { fieldUpdate },
                  },
                });
              }}
              onChangeOperator={(_operator, _value) => {
                if (automationJson.automationDetails?.automationParams?.fieldUpdate) {
                  const fieldUpdate = {
                    ...automationJson.automationDetails?.automationParams?.fieldUpdate,
                    operator: _operator,
                    operatorValue: _value,
                  };
                  callBack({
                    ...automationJson,
                    automationDetails: {
                      ...automationJson.automationDetails,
                      automationParams: { fieldUpdate },
                    },
                  });
                }
              }}
              onChangeValue={(value) => {
                if (automationJson.automationDetails?.automationParams?.fieldUpdate) {
                  const fieldUpdate = {
                    ...automationJson.automationDetails?.automationParams?.fieldUpdate,
                    operatorValue: value,
                  };
                  callBack({
                    ...automationJson,
                    automationDetails: {
                      ...automationJson.automationDetails,
                      automationParams: { fieldUpdate },
                    },
                  });
                }
              }}
            />
          )}
          {automationJson.automationDetails?.triggerType === AutomationTypes.DateArrive && (
            <TriggerDateArrive
              objectName={objectName}
              readonly={readonly}
              crmOrgId={crmOrgId}
              automationJson={automationJson}
              onChangeType={(val) => {
                const dateArrive = {
                  ...automationJson.automationDetails?.automationParams?.dateArrive,
                  relativeTime: val,
                };
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: { dateArrive },
                  },
                });
              }}
              onChangeValue={(val) => {
                const dateArrive = {
                  ...automationJson.automationDetails?.automationParams?.dateArrive,
                  offsetValue: val,
                };
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: { dateArrive },
                  },
                });
              }}
              onChangeValueType={(val) => {
                const dateArrive = {
                  ...automationJson.automationDetails?.automationParams?.dateArrive,
                  offsetType: val,
                };
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: { dateArrive },
                  },
                });
              }}
              onChangField={(leadingFieldId, leadingFieldLabels) => {
                const dateArrive = {
                  ...automationJson.automationDetails?.automationParams?.dateArrive,
                  sourceField: {
                    _leadingFieldId: leadingFieldId,
                    _leadingFieldLabels: leadingFieldLabels,
                  },
                };
                callBack({
                  ...automationJson,
                  objectName: objectName,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: { dateArrive },
                  },
                });
              }}
            />
          )}
          {automationJson.automationDetails?.triggerType === AutomationTypes.TimeInStep && (
            <TriggerTimeInStep
              onFunnelChange={(funnelId) => {
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    funnelId: funnelId,
                  },
                });
              }}
              objectName={objectName}
              crmOrgId={crmOrgId}
              automationJson={automationJson}
              funnels={funnelsList}
              readonly={readonly}
              onChangeStage={(stageDetails, _objectName) => {
                const temp: AutomationsParams = {
                  ...automationJson.automationDetails?.automationParams,
                  timeInStep: {
                    ...automationJson.automationDetails?.automationParams?.timeInStep,
                    stage: stageDetails,
                  },
                };
                const isObjectChanged = automationJson.objectName !== _objectName;
                callBack({
                  ...automationJson,
                  objectName: _objectName,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: temp,
                    actions: isObjectChanged ? [] : automationJson.automationDetails?.actions,
                  },
                });
              }}
              onChangeValue={(val) => {
                const timeInStep = {
                  ...automationJson.automationDetails?.automationParams?.timeInStep,
                  offsetValue: val,
                };
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: { timeInStep },
                  },
                });
              }}
              onChangeValueType={(val) => {
                const timeInStep = {
                  ...automationJson.automationDetails?.automationParams?.timeInStep,
                  offsetType: val,
                };
                callBack({
                  ...automationJson,
                  automationDetails: {
                    ...automationJson.automationDetails,
                    automationParams: { timeInStep },
                  },
                });
              }}
            />
          )}
          {!(isRecordType && isDateOrFieldUpdateTrigger()) && isShowTriggerConditions && (
            <FormAddConditions
              readonly={readonly}
              automationJson={automationJson}
              addConditionsToTrigger={addConditionsToTrigger}
              addDelayToAutomation={addDelayToAutomation}
            />
          )}
        </Box>
      )}

      {isRecordType && isDateOrFieldUpdateTrigger() && isShowTriggerConditions && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '8px' }}>
          {addRecordTypeSelector}
          <FormAddConditions
            readonly={readonly}
            automationJson={automationJson}
            addConditionsToTrigger={addConditionsToTrigger}
            addDelayToAutomation={addDelayToAutomation}
          />
        </Box>
      )}
      {isTriggerScheduled && (
        <AutomationScheduleRecurring
          setSchedule={(_newSchedule: Partial<AutomationSchedule>) => {
            const _scheduled = {
              ...automationJson?.automationDetails?.automationParams?.schedule,
              ..._newSchedule,
            };
            callBack({
              ...automationJson,
              automationDetails: {
                ...automationJson.automationDetails,
                automationParams: {
                  schedule: _scheduled as AutomationSchedule,
                },
              },
            });
          }}
          schedule={automationJson?.automationDetails?.automationParams?.schedule}
          readonly={readonly}
        />
      )}
    </Box>
  );
};
