import { ConfigurationType } from '@server/parser';
import { Dictionary } from 'lodash';
import { ConfigurationToName } from '../types';
import { FunnelFieldLabels } from '../../../../constants/fieldsManagementConsts';

export const getItemType = ({
  parentType,
  dependencyType,
  objectTypesByName,
  _objectName,
}: {
  parentType: ConfigurationType;
  dependencyType: string;
  objectTypesByName: Dictionary<ObjectTypeName>;
  _objectName?: string;
}) => {
  switch (parentType) {
    case ConfigurationType.fields:
      return FunnelFieldLabels[dependencyType as SweepFieldTypes] ?? dependencyType;

    case ConfigurationType.cpqData:
      return _objectName ? (objectTypesByName[_objectName]?.label ?? _objectName) + ' Record' : '';

    default:
      return ConfigurationToName[parentType] ?? parentType;
  }
};
