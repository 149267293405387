import { useDispatch, useSelector } from 'react-redux';
import {
  removeExitCriteriaById,
  selectFunnelMap,
  setExitCriteria,
} from '../../../../../reducers/united-canvas/unitedCanvasReducer';
import { GateDialog } from './GateDialog';
import { useCallback } from 'react';
import { funnelDetailModel } from '../../../../../models/funnelDetailModel';
import { GatePanelDataType } from './gatePanelType';
import { ACTIONS_EVENTS } from '../../../../../services/events';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import {
  selectSfStageById,
  selectSfGateById,
} from '../../../../../reducers/united-canvas/sfFunnelReducer';

interface ConnectedGatesDialogProps {
  crmOrgId: string;
  readonly?: boolean;
  closeDialog: () => void;
  setIsDirty: (isDirty: boolean) => void;
  isOpen: boolean;
  data: GatePanelDataType;
}

export const ConnectedGateDialog = ({
  crmOrgId,
  readonly,
  closeDialog,
  setIsDirty,
  isOpen,
  data,
}: ConnectedGatesDialogProps) => {
  const sendBiEvent = useSendBiEvent();
  const dispatch = useDispatch();
  const funnelMap = useSelector(selectFunnelMap);
  const { exitCriteriaId, funnelId, stageId } = data;
  const stage = useSelector(selectSfStageById(funnelId, stageId));
  const exitCriteria = useSelector(selectSfGateById(funnelId, stageId, exitCriteriaId));
  const funnelDetails = funnelMap.funnelsData[funnelId || '']?.funnelDetails;

  const saveGate = useCallback(
    (exitCriteria: SweepExitCriteria) => {
      stageId &&
        funnelId &&
        dispatch(
          setExitCriteria({
            funnelId,
            newExitCriteria: exitCriteria,
            stageId: stageId,
          }),
        );
    },
    [dispatch, funnelId, stageId],
  );

  const deleteGate = useCallback(() => {
    exitCriteria &&
      funnelId &&
      stageId &&
      dispatch(
        removeExitCriteriaById({
          funnelId,
          exitCriteriaId: exitCriteria._exitCriteriaId,
          stageId,
        }),
      );
    sendBiEvent({ name: ACTIONS_EVENTS.gatesDelete });
    closeDialog();
  }, [closeDialog, dispatch, exitCriteria, funnelId, sendBiEvent, stageId]);

  if (!stage) {
    return null;
  }

  if (!isOpen || !exitCriteria || !stageId || !funnelId || !funnelDetails) {
    return null;
  }
  const objectName = funnelDetails.leadingObject.objectName;
  const fromStageName = stage.stageName;
  const toStageName = funnelDetailModel(funnelDetails)?.findNextStageName(
    exitCriteria._nextStageId,
  );
  const criteriaPlugins = funnelDetailModel(funnelDetails).criteriaPluginsForStage(stage._stageId);
  return (
    <GateDialog
      exitCriteria={exitCriteria}
      fromStageName={fromStageName}
      toStageName={toStageName}
      readonly={readonly}
      objectName={objectName}
      crmOrgId={crmOrgId}
      onGateSave={saveGate}
      onGateDelete={deleteGate}
      setGateDirtyState={setIsDirty}
      closeDialog={closeDialog}
      criteriaPlugins={criteriaPlugins}
      funnelId={funnelId}
    />
  );
};
