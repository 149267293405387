import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export const CenteredBox = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100%',
});

export const VerticalCenteredBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  minHeight: '100%',
});
