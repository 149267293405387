import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import { ConfigurationCommentProperties, UserInputsByConfigurationId } from '@server/user-inputs';
import { ConfigurationComment } from '../../components/documentation/dependencies/annotations/comments/types';

export interface Tag {
  id: string;
  name: string;
  color: string;
}

interface PatchProps {
  commentId: string;
  commentProperties: ConfigurationCommentProperties;
  configurationId: string;
  crmOrgId: string;
}

export const useUserInputsApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    //tags are per account
    get_tags: useCallback(async () => {
      const response = await sweepApi.get(`/user-inputs/tags`);
      return response.data as Tag[];
    }, [sweepApi]),

    post_tag: useCallback(
      async (tag: Omit<Tag, 'id'>) => {
        const response = await sweepApi.post(`/user-inputs/tags`, tag);
        return response.data?.id;
      },
      [sweepApi],
    ),

    patch_tag: useCallback(
      async (tag: Tag) => {
        const response = await sweepApi.patch(`/user-inputs/tags/${tag.id}`, tag);
        return response.data;
      },
      [sweepApi],
    ),

    delete_tag: useCallback(
      async (tagId: string) => {
        const response = await sweepApi.delete(`/user-inputs/tags/${tagId}`);
        return response.data;
      },
      [sweepApi],
    ),

    get_tagConfigurations: useCallback(
      async (tagId: string, crmOrgId: string) => {
        const response = await sweepApi.get(`/user-inputs/tags/${tagId}?crmOrgId=${crmOrgId}`);
        return response.data;
      },
      [sweepApi],
    ),

    get_userConfigurations: useCallback(
      async (userId: string, crmOrgId: string) => {
        const response = await sweepApi.get(`/user-inputs/users/${userId}?crmOrgId=${crmOrgId}`);
        return response.data;
      },
      [sweepApi],
    ),

    delete_userConfigurations: useCallback(
      async (userId: string) => {
        const response = await sweepApi.delete(`/user-inputs/users/${userId}`);
        return response.data;
      },
      [sweepApi],
    ),

    get_userInputsForOrg: useCallback(
      async ({ crmOrgId }: { crmOrgId: string }) => {
        const response = await sweepApi.get(`/user-inputs?crmOrgId=${crmOrgId}`);
        return response.data as UserInputsByConfigurationId;
      },
      [sweepApi],
    ),

    put_userInputsForConfiguration: useCallback(
      async ({
        crmOrgId,
        userInputs,
      }: {
        crmOrgId: string;
        userInputs: UserInputsByConfigurationId;
      }) => {
        const [[configurationId, userInputData]] = Object.entries(userInputs);
        const response = await sweepApi.put(
          `/user-inputs/${configurationId}?crmOrgId=${crmOrgId}`,
          userInputData,
        );

        return response.data as UserInputsByConfigurationId;
      },
      [sweepApi],
    ),

    get_configurationComments: useCallback(
      async (configurationId: string, crmOrgId: string) => {
        const response = await sweepApi.get(
          `/user-inputs/${configurationId}/comments?crmOrgId=${crmOrgId}`,
        );
        return response.data as ConfigurationComment[];
      },
      [sweepApi],
    ),

    post_configurationComment: useCallback(
      async (
        comment: Pick<ConfigurationComment, 'commentBody' & 'mentionedUserIds' & 'properties'>,
        configurationId: string,
        crmOrgId: string,
      ) => {
        const response = await sweepApi.post(
          `/user-inputs/${configurationId}/comments?crmOrgId=${crmOrgId}`,
          comment,
        );
        return response.data as ConfigurationComment;
      },
      [sweepApi],
    ),

    put_configurationComment: useCallback(
      async (comment: ConfigurationComment, configurationId: string, crmOrgId: string) => {
        const response = await sweepApi.put(
          `/user-inputs/${configurationId}/comments/${comment.id}?crmOrgId=${crmOrgId}`,
          comment,
        );
        return response.data as string;
      },
      [sweepApi],
    ),

    patch_configurationCommentProperties: useCallback(
      async ({ commentId, commentProperties, crmOrgId, configurationId }: PatchProps) => {
        await sweepApi.patch(
          `/user-inputs/${configurationId}/comments/${commentId}/properties?crmOrgId=${crmOrgId}`,
          commentProperties,
        );
      },
      [sweepApi],
    ),

    delete_configurationComment: useCallback(
      async (commentId: string, configurationId: string, crmOrgId: string) => {
        await sweepApi.delete(
          `/user-inputs/${configurationId}/comments/${commentId}?crmOrgId=${crmOrgId}`,
        );
      },
      [sweepApi],
    ),
  };
};
