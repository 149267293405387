import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { GroupSettingsProvider, useGroupSettingsContext } from './GroupSettingsContext';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';

import { CloseButton } from './CloseButton';
import { useCallback, useMemo } from 'react';
import { useDialogLoader } from '../../../common/dialogs/DialogLoader';
import { GroupSettingsAvatar } from './group-settings-wizard/GroupSettingsAvatarHeader';
import {
  isNameAndDescriptionValid,
  NameAndDescriptionStepContent,
} from './group-settings-wizard/NameAndDescriptionStep';
import {
  LimitMembersPanelWithContext,
  useValidateLimitMembersPanel,
} from './LimitMembersPanelWithContext';

interface GroupSettingsDialogProps {
  onClose: () => void;
  onSave: (
    assignmentGroupData: Partial<AssignmentGroup>,
    resetAllMembersLimit?: number,
  ) => void | Promise<void>;
}

const DIALOG_CONTENT_WIDTH = 963;

const GroupSettingsDialog = ({ onClose, onSave }: GroupSettingsDialogProps) => {
  const { assignmentGroup, resetAllMembersLimit } = useGroupSettingsContext();
  const { DialogLoader, showLoader, hideLoader } = useDialogLoader();

  const saveChanges = useCallback(async () => {
    showLoader();
    await onSave(assignmentGroup as Partial<AssignmentGroup>, resetAllMembersLimit);
    hideLoader();
    onClose();
  }, [assignmentGroup, hideLoader, onClose, onSave, resetAllMembersLimit, showLoader]);

  const { isLimitMembersSectionValid } = useValidateLimitMembersPanel();

  const isValid = useMemo(
    () => isNameAndDescriptionValid(assignmentGroup) && isLimitMembersSectionValid(),
    [assignmentGroup, isLimitMembersSectionValid],
  );

  return (
    <Dialog
      open
      onClose={onClose}
      sx={{
        '.MuiDialogTitle-root': {
          paddingBottom: 3,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <GroupSettingsAvatar title="Group settings" />
          <CloseButton onClick={onClose} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: DIALOG_CONTENT_WIDTH }}>
        <Stack gap={3}>
          <NameAndDescriptionStepContent />
          <LimitMembersPanelWithContext />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={saveChanges} disabled={!isValid}>
          Save changes
        </Button>
      </DialogActions>
      {DialogLoader}
    </Dialog>
  );
};

const GroupSettingsDialogWithProviders = ({
  onClose,
  onSave,
  assignmentGroup,
}: GroupSettingsDialogProps & { assignmentGroup: AssignmentGroup }) => {
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  if (!crmOrgId) {
    return null;
  }
  return (
    <GroupSettingsProvider crmOrgId={crmOrgId} initialAssignmentGroup={assignmentGroup}>
      <GroupSettingsDialog onClose={onClose} onSave={onSave} />
    </GroupSettingsProvider>
  );
};
export { GroupSettingsDialogWithProviders as GroupSettingsDialog };
