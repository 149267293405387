import { useCallback } from 'react';
import { useTabFiltersFunctions } from './filters/useTabFilterFunctions';
import { SortOrder } from '../../common/types';
import {
  getSortOptions,
  getSelectedSortOption,
  sortByProperty,
  defaultSortOptionsMap,
} from './sortUtilsAndConsts';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { useSelector } from 'react-redux';
import { selectSingleObjectTabSortKey } from '../../../reducers/documentationReducer';

export const useSortElements = ({
  activeTab,
  isManagePackageInstalled,
  items,
}: {
  activeTab: DocumentationTabTypes;
  isManagePackageInstalled: boolean;
  items: any;
}) => {
  const documentationElementsSortKey = useSelector(selectSingleObjectTabSortKey);
  const _sortKey = documentationElementsSortKey ?? defaultSortOptionsMap[activeTab];

  const { registerSortChange } = useTabFiltersFunctions();

  const onSortChange = useCallback(
    (sortKey: string) => {
      registerSortChange({
        sortKey,
      });
    },
    [registerSortChange],
  );

  const _sortOptions = getSortOptions(activeTab, isManagePackageInstalled);
  const selectedSortOption = getSelectedSortOption(_sortOptions, _sortKey);

  let sortedItems = items ?? [];
  const itemsNotElementsMap = items;

  switch (activeTab) {
    case DocumentationTabTypes.CARDS_LIST:
      sortedItems = sortByProperty(items, _sortKey, selectedSortOption?.sortOrder ?? SortOrder.ASC);
      break;

    default:
      sortedItems = sortByProperty(
        itemsNotElementsMap,
        _sortKey,
        selectedSortOption?.sortOrder ?? SortOrder.ASC,
      );
      break;
  }

  return {
    onSortChange,
    sortedItems,
    _sortOptions,
    _sortKey,
  };
};
