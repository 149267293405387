import { IconButton } from '@sweep-io/sweep-design';
import { IconButtonSize } from '@sweep-io/sweep-design/dist/components/IconButton/types';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';

export const CloseButton = ({
  onClick,
  size,
}: {
  size?: IconButtonSize;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const _size = size || 'small';
  return (
    <IconButton variant="flat" size={_size} onClick={onClick}>
      <Close />
    </IconButton>
  );
};
