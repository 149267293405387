import { useState } from 'react';
import { Box, Grid, FormControlLabel } from '@mui/material';
import {
  Checkbox,
  Button,
  Typography,
  colors,
  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___,
} from '@sweep-io/sweep-design';
import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';
import { StyledBox, StageMetadataRelatedFields } from './StageMetadataRelatedFields';

export const StageSettingsTab = ({
  removeStage,
  readonly,
  stage,
  crmOrgId,
  funnelDetails,
  leadingFieldMetadata,
  opportunityTypes,
  onStageChange,
  onStageMetadataChange,
}: {
  removeStage?: () => void;
  readonly?: boolean;
  stage: SweepStage;
  crmOrgId: string;
  funnelDetails: FunnelDetails;
  leadingFieldMetadata?: StageMetadata;
  opportunityTypes?: OpportunityType[];
  onStageChange: (newVal: Partial<SweepStage>) => void;
  onStageMetadataChange: (leadingFieldMetadata: StageMetadata) => Promise<boolean>;
}) => {
  const { preventBackwardStages } = stage;
  const [isRemoveDialogVisible, setIsRemoveDialogVisible] = useState(false);

  return (
    <>
      <ConfirmDialog
        title="Do you want to delete?"
        confirmText="Delete step"
        cancelText="Go back"
        children={
          <Typography variant="body">
            Deleting this step would also remove all Gates and Automations associated with it,
            including Gates leading to this step.
          </Typography>
        }
        isOpen={isRemoveDialogVisible}
        handleClose={() => setIsRemoveDialogVisible(false)}
        onConfirm={() => removeStage && removeStage()}
        PaperProps={{ sx: { width: '334px' } }}
        confirmButtonType="error"
      />

      <Box sx={{ marginTop: '22px' }}>
        <Grid container direction="row" spacing={2}>
          {leadingFieldMetadata && (
            <StageMetadataRelatedFields
              leadingFieldMetadata={leadingFieldMetadata}
              readonly={readonly}
              crmOrgId={crmOrgId}
              funnelDetails={funnelDetails}
              opportunityTypes={opportunityTypes}
              onStageMetadataChange={onStageMetadataChange}
            />
          )}

          <Grid item xs={12} display="flex" flexDirection="column">
            <FormControlLabel
              label="Prevent records in this step from moving to earlier steps"
              control={
                <Checkbox
                  disabled={readonly}
                  checked={preventBackwardStages || false}
                  onChange={(event) => {
                    onStageChange({
                      preventBackwardStages: event.target.checked,
                    });
                  }}
                />
              }
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontSize,
                  fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontWeight,
                },
              }}
            />
            <StyledBox>
              <Typography variant="caption" color={colors.grey[800]}>
                By checking this box, end users in Salesforce won’t be able to move records manually
                from this step to earlier steps in the funnel. Trying to do this will result in an
                error while saving the record.
              </Typography>
            </StyledBox>
          </Grid>
        </Grid>

        {!readonly && (
          <Box
            sx={{
              marginTop: '30px',
            }}
          >
            <Button variant="flat" type="error" onClick={() => setIsRemoveDialogVisible(true)}>
              Delete step
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};
