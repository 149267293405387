import useSingleGatePanel from './useSingleGatePanel';
import _isEqual from 'lodash/isEqual';
import { GatePanel } from './GatePanel';
import { PluginTypAndCriterion } from '../../../../../models/PluginModel';
import { RuleBuilderCollapsePanel } from '../stage/collapse-panels/RuleBuilderCollapsePanel';
import range from 'lodash/range';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
  ConfigurationCanvasRightPanel,
} from '../../../configuration-canvas-panel';

interface GatesDialogProps {
  exitCriteria: SweepExitCriteria;
  objectName: string;
  fromStageName: string;
  toStageName: string;
  readonly?: boolean;
  crmOrgId: string;
  onGateSave: (exitCriteria: SweepExitCriteria) => any;
  onGateDelete: () => void;
  setGateDirtyState: (isDirty: boolean) => void;
  closeDialog: () => void;
  criteriaPlugins: PluginTypAndCriterion[];
  funnelId: string;
}

const GateDialog = ({
  exitCriteria,
  objectName,
  fromStageName,
  toStageName,
  readonly,
  crmOrgId,
  onGateSave,
  onGateDelete,
  setGateDirtyState,
  closeDialog,
  criteriaPlugins,
  funnelId,
}: GatesDialogProps) => {
  const {
    localState: _exitCriteria,
    onChangeAutoMove,
    gateRef,
    onChangeCondition,
    discardChanges,
    saveChanges,
  } = useSingleGatePanel({
    onGateSave,
    exitCriteria,
    setGateDirtyState,
  });
  const isChanged = !_isEqual(_exitCriteria, exitCriteria);
  const hasNoCriteria = exitCriteria.criteria.length === 0;

  if (!exitCriteria) {
    return null;
  }

  return (
    <ConfigurationCanvasRightPanel
      sx={{
        '.configuration-canvas-panel-content': {
          marginTop: '-48px',
        },
        '&.configuration-canvas-panel': {
          overflow: 'scroll',
        },
      }}
    >
      <ConfigurationCanvasPanelHeader onClose={closeDialog} />
      <ConfigurationCanvasPanelContent>
        <GatePanel
          stageObjectName={objectName}
          readonly={readonly}
          crmOrgId={crmOrgId}
          onGateDelete={onGateDelete}
          isChanged={isChanged}
          discardChanges={discardChanges}
          hasNoCriteria={hasNoCriteria}
          onChangeAutoMove={onChangeAutoMove}
          gateRef={gateRef}
          onChange={onChangeCondition}
          exitCriteria={_exitCriteria}
          saveChanges={saveChanges}
          withConditionsHeader
          fromStageName={fromStageName}
          toStageName={toStageName}
        />
        {criteriaPlugins.map(({ criterion, pluginType }) => {
          if (!criterion?.length) return null;

          const pluginExitCriteria: SweepExitCriteria = {
            ...exitCriteria,
            _exitCriteriaId: `plugin-${pluginType}`,
            criteria: criterion.map((criterion) => criterion.criterion),
            criteriaLogic: range(1, criterion.length + 1)
              .map((number) => `${number}`)
              .join(' AND '),
          };
          return (
            <RuleBuilderCollapsePanel
              key={pluginType}
              exitCriteria={pluginExitCriteria}
              fromStageName={fromStageName}
              toStageName={toStageName}
              readonly
              stageObjectName={objectName}
              crmOrgId={crmOrgId}
              onGateSave={() => {}}
              onGateDelete={() => {}}
              setGateDirtyState={() => {}}
              isPluginType={pluginType}
              funnelId={funnelId}
            />
          );
        })}
      </ConfigurationCanvasPanelContent>
    </ConfigurationCanvasRightPanel>
  );
};

export { GateDialog };
