import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { AccountUsersData, InviteeInfo, UserInfo } from '../types/UserInfoTypes';

export interface AccountUsersState {
  data: AccountUsersData;
  isLoading: boolean;
  error?: string;
}

const initialState: AccountUsersState = {
  data: {
    invitations: undefined,
    users: undefined,
  },
  isLoading: true,
};

export const accountUsersSlice = createSlice({
  name: 'accountUsers',
  initialState,
  reducers: {
    setAccountUsersData: (
      state,
      action: PayloadAction<{
        invitations: InviteeInfo[];
        users: UserInfo[];
      }>,
    ) => {
      state.isLoading = false;
      state.data = { ...state.data, ...action.payload };
    },
  },
});

export const { setAccountUsersData } = accountUsersSlice.actions;

export const selectAccountUsers = (state: RootState) => state.accountUsers.data.users;
export const selectAccountUsersData = (state: RootState) => state.accountUsers.data;

export const selectUserById = (userId: string) => (state: RootState) =>
  state.accountUsers.data.users?.find((user) => user.id === userId);

export default accountUsersSlice.reducer;
