import { PluginMeddpiccSvg } from '../../icons';
import { BaseCriteriaPage } from '../../base-criteria-plugin-panel/BaseCriteriaPage';
import { useOnAddMeddpiccPlugin } from '../useOnAddMeddpiccPlugin';
import { meddpiccTexts } from './texts';
import { meddpiccPluginStepMapping } from '../panel/pluginData';

export const MeddpiccPage = ({ onBackClick }: { onBackClick: () => void }) => {
  const onAddPlugin = useOnAddMeddpiccPlugin();

  return (
    <BaseCriteriaPage
      onBackClick={onBackClick}
      onAddPlugin={onAddPlugin}
      title={meddpiccTexts.title}
      imageIcon={<PluginMeddpiccSvg />}
      detailTitle="Description"
      detailDescription={meddpiccTexts.description}
      pluginData={meddpiccPluginStepMapping}
    />
  );
};
