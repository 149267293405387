import { Box } from '@mui/material';
import { IconButton, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { ReactNode } from 'react';
import { HORIZONTAL_PADDING } from './consts';
import { ExpandedModeButton } from '../configuration-canvas/panels-reserve-space/ExpandedModeButton';

interface ConfigurationCanvasPanelHeaderProps {
  children?: ReactNode;
  onClose?: React.MouseEventHandler<HTMLButtonElement>; // If this is passed, a close button will be shown
  withExpandedModeButton?: boolean;
  customButton?: ReactNode;
}

export const ConfigurationCanvasPanelHeader: React.FC<ConfigurationCanvasPanelHeaderProps> = ({
  children,
  onClose,
  withExpandedModeButton,
  customButton,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 1,
        ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h3'],
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: `0 ${HORIZONTAL_PADDING}px`,
        minHeight: '24px',
      }}
      className="configuration-canvas-panel-header"
    >
      {children ?? <div />}

      <Box display="flex" gap={1} alignItems="center">
        {customButton}

        {withExpandedModeButton && (
          <ExpandedModeButton />
        )}

        {onClose && (
          <Box>
            <IconButton onClick={onClose} variant="flat" size="tiny">
              <Close />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
