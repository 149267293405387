import { PluginBantSvg } from '../../icons';
import { BaseCriteriaPage } from '../../base-criteria-plugin-panel/BaseCriteriaPage';
import { useOnAddBantPlugin } from '../useOnAddBantPlugin';
import { bantTexts } from './texts';
import { bantPluginStepMapping } from '../panel/pluginData';

export const BantPage = ({ onBackClick }: { onBackClick: () => void }) => {
  const onAddPlugin = useOnAddBantPlugin();

  return (
    <BaseCriteriaPage
      onBackClick={onBackClick}
      onAddPlugin={onAddPlugin}
      title={bantTexts.title}
      imageIcon={<PluginBantSvg />}
      detailTitle="Description"
      detailDescription={bantTexts.description}
      pluginData={bantPluginStepMapping}
    />
  );
};
