import { Box } from '@mui/material';
import { getAddAutomationButtonByType, getTypeNameFromVariant } from './helper';
import { Permission } from '@server/permissions';
import EmptyStateWithButton from '../common/empty-state/EmptyStateWithButton';
import { ReactNode } from 'react';
import EmptyState from '../common/empty-state/EmptyState';
import { useAutomationsContext } from './AutomationsContext';

interface EmptyStateProps {
  onSelect: (object: string, type?: DedupMatchingType) => void;
  disabled: boolean;
  crmOrgId: string;
  subtitle?: string;
  title: string;
  excludeAutomationsNotInFunnelMapObjects?: boolean;
  defaultObjectType?: string;
  funnelsData?: FunnelsData;
  recordTypesData?: RecordTypesData;
  image: ReactNode;
}

export const AutomationsEmptyState = ({
  onSelect,
  disabled,
  crmOrgId,
  subtitle,
  title,
  excludeAutomationsNotInFunnelMapObjects,
  defaultObjectType,
  funnelsData,
  recordTypesData,
  image,
}: EmptyStateProps) => {
  const { permissions, automationVariant } = useAutomationsContext();
  const permissionStr: Permission[] = [permissions.create];
  return (
    <Box
      sx={{
        textAlign: 'center',
        ml: 'auto',
        mr: 'auto',
        p: '160px 16px',
      }}
    >
      {defaultObjectType && (
        <EmptyStateWithButton
          image={image}
          line1={subtitle}
          titleLine={title}
          onClick={() => {
            onSelect(defaultObjectType);
          }}
          buttonDisabled={disabled}
          permissionString={permissionStr}
          notAllowedTitle={`To create a new ${getTypeNameFromVariant(
            automationVariant,
          )}, please contact your admin.`}
          buttonSize="small"
        />
      )}
      {!defaultObjectType && (
        <EmptyState image={image} line1={subtitle} titleLine={title}>
          {getAddAutomationButtonByType({
            variant: automationVariant,
            props: {
              disabled,
              onSelect: (object: string, type?: DedupMatchingType | undefined) => {
                onSelect(object, type);
              },
              crmOrgId,
              buttonText: 'Get started',
              excludeAutomationsNotInFunnelMapObjects,
              funnelsData,
              recordTypesData,
              automationVariant,
              isEmptyState: true,
            },
          })}
        </EmptyState>
      )}
    </Box>
  );
};
