import { useState } from 'react';
import { Typography, Button } from '@sweep-io/sweep-design';
import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';

const DeleteGateButton = ({ handleConnectionRemoval }: { handleConnectionRemoval: () => void }) => {
  const [isRemoveDialogVisible, setIsRemoveDialogVisible] = useState(false);

  return (
    <>
      <ConfirmDialog
        title="Do you want to delete?"
        confirmText="Delete Gate"
        cancelText="Go back"
        children={
          <Typography variant="body">
            Deleting this Gate will remove the connection between steps
          </Typography>
        }
        isOpen={isRemoveDialogVisible}
        handleClose={() => setIsRemoveDialogVisible(false)}
        onConfirm={() => {
          handleConnectionRemoval();
          setIsRemoveDialogVisible(false);
        }}
        PaperProps={{ sx: { width: '334px' } }}
        confirmButtonType="error"
      />

      <Button
        variant="flat"
        type="error"
        size="small"
        onClick={() => setIsRemoveDialogVisible(true)}
      >
        Delete Gate
      </Button>
    </>
  );
};

export { DeleteGateButton };
