import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { CARD_MIN_WIDTH, CARDS_LAYOUT_GRID_GAP_NUMBER } from '../../constants';

const CardsGridLayout = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'minWidth' && prop !== 'gap',
})<{ minWidth: string; gap?: string }>(({ minWidth, gap }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, 1fr))`,
  gridGap: gap ?? CARDS_LAYOUT_GRID_GAP_NUMBER + 'px',
}));

const DefaultCardsLayout = (props: any) => <CardsGridLayout minWidth={CARD_MIN_WIDTH} {...props} />;

export { DefaultCardsLayout };
