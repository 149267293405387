import { colors, Typography } from '@sweep-io/sweep-design';
import { Box } from '@mui/material';

const PromptBubble = ({
  onClick,
  prompt,
  disabled,
}: {
  onClick: () => void;
  prompt: string;
  disabled: boolean;
}) => {
  return (
    <Box
      sx={{
        cursor: disabled ? 'default' : 'pointer',
        border: `1.5px solid ${disabled ? colors.grey[500] : colors.blue[300]}`,
        borderRadius: '24px',
        padding: '8.5px 10.5px',
        width: 'fit-content',
        backgroundColor: colors.white,
        ':hover': {
          borderColor: disabled ? colors.grey[500] : colors.blue[500],
        },
      }}
      onClick={disabled ? () => {} : onClick}
    >
      <Typography variant="body" color={colors.grey[800]}>
        {prompt}
      </Typography>
    </Box>
  );
};

export default PromptBubble;
