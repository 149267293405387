import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ObjectTypesAutocomplete,
  ObjectTypesAutocompleteItem,
} from '../common/ObjectTypesAutocomplete';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { SweepFieldsRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import {
  useRefForMultipleRuleBuilders,
  useValidateAllAndReturnIsValid,
} from '../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import { NestedFieldsSelector } from '../common/fieldsSelectors/NestedFieldsSelector';
import SweepSelect from '../common/SweepSelect';
import { getNestedPath } from './helper';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';

const ValueFromAnotherObjectEditor = ({
  crmOrgId,
  initialObjectName,
  initialValue,
  closeEditor,
  onChange,
  initialFieldType,
}: {
  crmOrgId: string;
  initialObjectName: string;
  initialValue?: ValueFromAnotherObject;
  closeEditor: (value?: ValueFromAnotherObject) => void;
  onChange: (value: ValueFromAnotherObject) => void;
  initialFieldType: SweepFieldTypes;
}) => {
  const { getLabelFromIdsWithObjectType } = useSweepFieldsLabels();
  const [tempValue, setTempValue] = useState(initialValue ?? {});
  const objectName = tempValue?.objectName?.objectType ?? '';
  const [operatorsByFieldType, setOperatorsByFieldType] = useState<
    { value: string; label: string }[]
  >([]);

  const onSave = useCallback(() => {
    onChange(tempValue);
    closeEditor(tempValue);
  }, [closeEditor, onChange, tempValue]);

  const { objectTypes, isLoading } = useObjectTypesWithFetch({ crmOrgId });
  const validateAllRuleBuilders = useValidateAllAndReturnIsValid();
  const ruleBuilderRef = useRefForMultipleRuleBuilders();
  const { getSweepFieldsById } = useSweepFields();

  const multipleRecordsFilterByList = [
    SweepFieldTypes.Currency,
    SweepFieldTypes.Date,
    SweepFieldTypes.DateTime,
    SweepFieldTypes.Number,
    SweepFieldTypes.Percent,
  ];

  const getOperatorsByFieldType = useCallback(async () => {
    const _fields = await getSweepFieldsById({
      fieldIds: tempValue?.tieBreakerFieldIds ?? [],
    });
    const _fieldType = _fields?.[0]?.fieldType;

    if (_fieldType === SweepFieldTypes.Date || _fieldType === SweepFieldTypes.DateTime) {
      setOperatorsByFieldType([
        { value: 'EARLIEST', label: 'Earliest' },
        { value: 'LATEST', label: 'Latest' },
      ]);
    } else {
      setOperatorsByFieldType([
        { value: 'MIN', label: 'Smallest' },
        { value: 'MAX', label: 'Largest' },
      ]);
    }
  }, [getSweepFieldsById, tempValue?.tieBreakerFieldIds]);

  useEffect(() => {
    getOperatorsByFieldType();
  }, [getOperatorsByFieldType, tempValue.tieBreakerFieldIds]);

  const isValid = useMemo(() => {
    const isRBValid = validateAllRuleBuilders();
    return (
      isRBValid &&
      objectName &&
      tempValue?.fieldIds &&
      tempValue?.tieBreakerOperator &&
      tempValue?.tieBreakerFieldIds
    );
  }, [objectName, tempValue, validateAllRuleBuilders]);

  return (
    <Box
      sx={{
        mt: '20px',
        minHeight: '375px',
        ml: '-172px',
        p: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: colors.grey[100],
        borderRadius: '8px',
      }}
    >
      <Box sx={{ background: colors.white, borderRadius: 2, p: 2 }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1.5,
          }}
        >
          <Typography variant="body">Object</Typography>
          <Box sx={{ minWidth: '155px' }}>
            <ObjectTypesAutocomplete
              objectTypes={objectTypes}
              isLoading={isLoading}
              onChange={(event: any, newValue: ObjectTypesAutocompleteItem | null) => {
                newValue && setTempValue({ objectName: newValue });
              }}
              value={objectName}
            />
          </Box>
        </Box>
        <Box
          sx={{
            borderBottom: `1px solid ${colors.grey[200]}`,
            padding: '16px 0',
            m: '16px 0',
            borderTop: `1px solid ${colors.grey[200]}`,
            display: 'flex',
            flexDirection: 'column',
            gap: 1.5,
          }}
        >
          <Typography variant="body">Get records that meet the following criteria:</Typography>
          <SweepFieldsRuleBuilder
            disableResolvePolymorphic={true}
            ref={ruleBuilderRef}
            crmOrgId={crmOrgId ?? ''}
            objectType={objectName}
            sweepCondition={tempValue?.criteria}
            referenceObjectType={initialObjectName}
            onChange={(newSweepCondition) => {
              setTempValue({ ...tempValue, criteria: newSweepCondition });
            }}
            selectValuesFromRecordCustomButtonText={'Use a value from the triggering record'}
            nestedSelectorFilterBy={(field) => field.fieldType !== SweepFieldTypes.LongTextArea}
          />
        </Box>
        <Box>
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center', mb: 2 }}>
            <Typography variant="body">Use value from field:</Typography>
            <Box
              sx={{
                marginRight: '8px',
                borderRadius: '4px',
                border: `1px solid ${colors.grey[300]}`,
                position: 'relative',
              }}
            >
              <NestedFieldsSelector
                placeholder={'Choose field'}
                disableLookupItemsResolve={true}
                crmOrgId={crmOrgId}
                objectType={objectName}
                nestedPath={getNestedPath(
                  tempValue?.fieldIds,
                  getLabelFromIdsWithObjectType(tempValue?.fieldIds ?? []),
                )}
                readonly={!objectName}
                onChange={(_sweepField) => {
                  setTempValue({ ...tempValue, fieldIds: _sweepField?.fieldIds });
                }}
                filterBy={(field: SweepField) => field?.fieldType === initialFieldType}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
            <Typography variant="body">
              If multiple records meet the criteria, use the value from the record where:
            </Typography>
            <Box
              sx={{
                marginRight: '8px',
                borderRadius: '4px',
                border: `1px solid ${colors.grey[300]}`,
                position: 'relative',
              }}
            >
              <NestedFieldsSelector
                disableResolvePolymorphic={true}
                placeholder={'Choose field'}
                disableLookupItemsResolve={true}
                crmOrgId={crmOrgId}
                objectType={objectName}
                nestedPath={getNestedPath(
                  tempValue?.tieBreakerFieldIds,
                  getLabelFromIdsWithObjectType(tempValue?.tieBreakerFieldIds ?? []),
                )}
                readonly={!objectName}
                onChange={(_sweepField) => {
                  setTempValue({
                    ...tempValue,
                    tieBreakerFieldIds: _sweepField?.fieldIds,
                  });
                }}
                filterBy={(field: SweepField) =>
                  multipleRecordsFilterByList.includes(field?.fieldType)
                }
              />
            </Box>
            <Typography variant="body">is</Typography>
            <SweepSelect
              FormControlProps={{
                sx: { minWidth: '120px', marginRight: '8px' },
              }}
              menuMaxHeight={'360px'}
              SelectProps={{
                placeholder: 'Value',
                disabled: !objectName,
                value: tempValue?.tieBreakerOperator,

                onChange: (event: SelectChangeEvent<unknown>) => {
                  const val = event.target.value as string;
                  setTempValue({ ...tempValue, tieBreakerOperator: val });
                },
              }}
            >
              {operatorsByFieldType.map((elem) => {
                return (
                  <MenuItem key={elem.value} value={elem.value}>
                    {elem.label}
                  </MenuItem>
                );
              })}
            </SweepSelect>
          </Box>
        </Box>
      </Box>

      <Box display="flex" justifyContent="flex-end" gap="12px">
        <Button size="small" variant="outlined" onClick={() => closeEditor()}>
          Cancel
        </Button>
        <Button variant="filled" size="small" disabled={!isValid} onClick={onSave}>
          Save
        </Button>
      </Box>
    </Box>
  );
};

export default ValueFromAnotherObjectEditor;
