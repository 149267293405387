import { useAuth0 } from '@auth0/auth0-react';
import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../reducers/userInfoReducer';

export const ImpersonationMode = () => {

  const loggedInUser = useSelector(selectUserInfoData);
  const { user: authenticatedUser } = useAuth0();

  const isImpersonating = authenticatedUser && loggedInUser && loggedInUser.email && authenticatedUser?.email !== loggedInUser.email;

  if (!isImpersonating) {
    return null;
  }

  return (
    <Alert
      sx={{ position: 'absolute', top: 5, left: '50%', transform: 'translateX(-50%)', zIndex: 2000 }}
      variant="filled"
      severity="error">
      You are in the impersonation mode
    </Alert>
  );
} 