import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';
import {
  RELATIVE_DATE_OPERATORS,
  TEXTFIELD_FOR_PICKLIST_OPERATORS,
} from '../SweepFieldsOperatorSelector/SweepFieldsOperatorSelector';
import { ApiSweepFieldOperator } from '../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';

export const getParentFieldTypeByOperator = (
  parentFieldType: string,
  _operator?: ApiSweepFieldOperator | '',
) => {
  //Displaying number value selector for date fieldtype
  if (_operator && RELATIVE_DATE_OPERATORS.includes(_operator)) {
    return SweepFieldTypes.Number;
  }

  if (
    _operator &&
    TEXTFIELD_FOR_PICKLIST_OPERATORS.includes(_operator) &&
    [
      SweepFieldTypes.Picklist,
      SweepFieldTypes.MultiselectPicklist,
      SweepFieldTypes.Combobox,
    ].includes(parentFieldType as any)
  ) {
    return SweepFieldTypes.Text;
  }

  return parentFieldType;
};
