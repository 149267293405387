import * as yup from 'yup';
import {
  DEFAULT_VALUE,
  NumberLikeFieldTypes,
  SWEEP_FIELD_NAME,
} from '../../../../../constants/fieldsManagementConsts';
import { isNumberLikeFieldType } from './helpers';
import { SweepFieldTypes } from '../../../../../types/enums/SweepFieldTypes';

const MAX_PRECISION = 18;
const MAX_LENGTH_TEXT = 255;
const MAX_LENGTH_LONGTEXTAREA = 131072;
const MIN_LENGTH = 1;
const MAX_LENGTH_NUMBER = 18;
const MIN_LENGTH_LONGTEXTAREA = 255;

// SF rules:
const PRECISION_ERROR = `The sum of the length and decimal places must be an integer less than or equal to ${MAX_PRECISION}`;
const NUMBER_LENGTH_ERROR_MAX = `Length must be an integer less than or equal to ${MAX_LENGTH_NUMBER}`;
const LENGTH_ERROR_MIN = `Length must be an integer greater than or equal to ${MIN_LENGTH}`;
const LENGTH_LONGTEXTAREA_ERROR_MIN = `Length must be an integer greater than or equal to ${MIN_LENGTH_LONGTEXTAREA}`;
const TEXT_LENGTH_ERROR_MAX = `Length must be an integer less than or equal to ${MAX_LENGTH_TEXT}`;
const LONGTEXTAREA_LENGTH_ERROR_MAX = `Length must be an integer less than or equal to ${MAX_LENGTH_LONGTEXTAREA}`;

const numberPropertiesShape = {
  length: yup
    .number()
    .min(1, LENGTH_ERROR_MIN)
    .max(MAX_LENGTH_NUMBER, NUMBER_LENGTH_ERROR_MAX)
    .required('Length is required'),
  scale: yup
    .number()
    .min(0)
    .test('max', PRECISION_ERROR, (value: number | undefined, context: any) => {
      if (!value) return true;
      const { length } = context.parent;
      return value <= 18 - (length ?? 0);
    })
    .required('Decimal is required'),
};

const textPropertiesShape = {
  length: yup
    .number()
    .min(1, LENGTH_ERROR_MIN)
    .max(MAX_LENGTH_TEXT, TEXT_LENGTH_ERROR_MAX)
    .required('Length is required'),
};

const longTextAreaPropertiesShape = {
  length: yup
    .number()
    .min(MIN_LENGTH_LONGTEXTAREA, LENGTH_LONGTEXTAREA_ERROR_MIN)
    .max(MAX_LENGTH_LONGTEXTAREA, LONGTEXTAREA_LENGTH_ERROR_MAX)
    .required('Length is required'),
};

const commonPropertiesShape = {
  unique: yup.boolean(),
  displayLocationInDecimal: yup.boolean(),
  caseSensitive: yup.boolean(),
  valueSet: yup.array().of(
    yup.object().shape({
      label: yup.string().required('Value set label is required'),
      fullName: yup.string(),
      default: yup.boolean(),
    }),
  ),
};

const fieldPropertiesValidationSchema = yup
  .object()
  .nullable()
  .when('fieldType', ([value]) => {
    if (value === SweepFieldTypes.Email) {
      return yup.object().shape({
        ...commonPropertiesShape,
        [DEFAULT_VALUE]: yup.string().email('Invalid email'),
      });
    }
    if (isNumberLikeFieldType(value)) {
      return yup.object().shape({
        ...commonPropertiesShape,
        ...numberPropertiesShape,
      });
    }
    if (value === SweepFieldTypes.Text) {
      return yup.object().shape({
        ...commonPropertiesShape,
        ...textPropertiesShape,
      });
    }
    if (value === SweepFieldTypes.LongTextArea) {
      return yup.object().shape({
        ...commonPropertiesShape,
        ...longTextAreaPropertiesShape,
      });
    }
    return yup.object().shape(commonPropertiesShape);
  });

export const fieldsValidationSchema = yup.object().shape({
  fieldType: yup.string().nullable().required('Field Type is required'),
  description: yup.string().nullable().notRequired(),
  helpText: yup.string().nullable().notRequired(),
  isRequired: yup.boolean().nullable(),
  objectName: yup.string().nullable().required('Object is required'),
  [SWEEP_FIELD_NAME]: yup.string().nullable().required('Label is required'),
  properties: fieldPropertiesValidationSchema,
});

//If further validations of defaultValue will be needed, this method can be used:

// properties: yup.object().test('defaultValue', 'not valid', (value:any, context:any) => {
//   switch(context.parent.fieldType) {
//     case FunnelFieldTypes.Email: {
//       return yup.string().email('Invalid email').isValidSync(value.defaultValue)
//     }
//   }
//   return true
// }),

const numberLikeDefaultProps = {
  length: 18,
  scale: 0,
};

const textLikeDefaultProps = {
  length: 0,
};

export const getDefaultProps = (fieldType: SweepFieldTypes) => {
  if (NumberLikeFieldTypes.includes(fieldType)) {
    return numberLikeDefaultProps;
  }
  if ([SweepFieldTypes.Text, SweepFieldTypes.LongTextArea].includes(fieldType)) {
    return textLikeDefaultProps;
  }
  return {};
};
