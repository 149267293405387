import { TabContext } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { stageModel } from '../../../../../models/stageModel';
import { StageGatesTab } from './StageGatesTab';
import { StageSettingsTab } from './StageSettingsTab';
import { StageDialogTabTypes } from '../../../../../types/enums/StageDialogTabTypes';
import { TabList } from '@sweep-io/sweep-design';
import { useStageDialog } from './useStageDialog';
import usePermission from '../../../../common/permissions/usePermission';
import { Box } from '@mui/material';
import { MultipleRuleBuildersProvider } from '../../../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import useCalculateTabCounter from './useCalculateTabCounter';
import { useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import { selectStageDialogHasChanges } from '../../../../../reducers/funnel-flow-page/stageDialogReducer';
import { Permission } from '@server/permissions';
import { RightPanelAutomationsTab } from './RightPanelAutomationsTab';
import { AutomationFormVariant } from '../../../../../types/enums/AutomationFormVariant';
import { selectAutomations } from '../../../../../reducers/global/automationReducers';
import { selectAlerts } from '../../../../../reducers/global/alertsReducers';
import { getAutomationsForStageId } from '../../../../Automations/helper';
import { AutomationTypes } from '../../../../../types/enums/AutomationTypes';
import { selectAssignments } from '../../../../../reducers/global/assignmentReducers';
import uniqueId from 'lodash/uniqueId';
import { AutomationActionType } from '../../../../../types/enums/AutomationActionType';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasRightPanel,
} from '../../../configuration-canvas-panel';
import { StageDialogHeader } from './StageDialogHeader';
import { DialogLoader } from '../../../../common/dialogs/DialogLoader';
import { AutomationAlertsContextProvider } from '../../../../alerts/AutomationAlertsContextProvider';
import { AutomationAutomationsContextProvider } from '../../../../alerts/AutomationAutomationsContextProvider';
import { AutomationAssignmentsContextProvider } from '../../../../routing/AutomationAssignmentsContextProvider';

const StyledPanelTab = styled(Box)({
  marginTop: '48px',
});

interface StageDialogProps {
  readonly?: boolean;
  stage: SweepStage;
  crmOrgId: string;
  funnelId: string;
  closeDialog: () => void;
  setIsDirty: (isDirty: boolean) => void;
}

const StageDialog = ({
  readonly,
  stage,
  crmOrgId,
  funnelId,
  setIsDirty,
  closeDialog,
}: StageDialogProps) => {
  const {
    activeTab,
    showLoader,
    onStageNameConfirm,
    funnel,
    onStageMetadataChange,
    onGateSave,
    onGateDelete,
    leadingFieldMetadata,
    onStageChange,
    removeStageAndCloseDialog,
    opportunityTypes,
    tempStageName,
    onTabChange,
    setGateIsDirty,
  } = useStageDialog({ crmOrgId, stage, funnelId });

  const { funnelDetails } = funnel ?? {};
  const leadingObject = funnelDetails?.leadingObject ?? {
    objectName: 'Lead',
    _leadingFieldId: '',
    _leadingFieldLabels: [],
    fieldName: 'Lead',
  };
  const { calculateGatesTabCounter } = useCalculateTabCounter({ stage });
  const permissionString: Permission[] = ['edit:funnels'];
  const [isAllowedBtn] = usePermission(permissionString);

  const stageDialogHasChanges = useSelector(selectStageDialogHasChanges);
  const automationsList = useSelector(selectAutomations);
  const alertsList = useSelector(selectAlerts);
  const assignmentsList = useSelector(selectAssignments);

  const assignments = useMemo(
    () => getAutomationsForStageId(stage._stageId, assignmentsList),
    [assignmentsList, stage],
  );
  const automations = useMemo(
    () => getAutomationsForStageId(stage._stageId, automationsList),
    [automationsList, stage],
  );
  const alerts = useMemo(
    () => getAutomationsForStageId(stage._stageId, alertsList),
    [alertsList, stage],
  );

  useEffect(() => {
    setIsDirty(Boolean(stageDialogHasChanges));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stageDialogHasChanges]);

  if (!funnelDetails) {
    return <></>;
  }

  const shouldGatesBeCollapsed =
    stageModel(stage).countConnectedStages() +
      (stageModel(stage).getStageFunnelLinks()?.length || 0) ===
    1;

  const currentTabs: any = [
    {
      badgeLabel: String(calculateGatesTabCounter() || ''),
      content: (
        <StyledPanelTab>
          <StageGatesTab
            funnelStage={stage}
            readonly={readonly || !isAllowedBtn}
            funnelDetails={funnelDetails}
            defaultIsCollapsed={shouldGatesBeCollapsed}
            crmOrgId={crmOrgId}
            setGateIsDirty={setGateIsDirty}
            onGateSave={onGateSave}
            onGateDelete={onGateDelete}
            funnelId={funnelId}
          />
        </StyledPanelTab>
      ),
      label: 'Gates',
      value: StageDialogTabTypes.GATES,
    },
  ];
  if (!readonly) {
    currentTabs.push(
      ...[
        {
          content: (
            <StyledPanelTab>
              <AutomationAutomationsContextProvider>
                <RightPanelAutomationsTab
                  readonly={readonly || !isAllowedBtn}
                  funnelDetails={funnelDetails}
                  automations={automations}
                  onCreateData={{
                    newElement: {
                      objectName: leadingObject.objectName,
                      automationDetails: {
                        triggerType: AutomationTypes.StageEnter,
                        funnelId,
                        actions: [],
                        automationParams: {
                          stageEnter: {
                            toStage: stage,
                          },
                        },
                      },
                    },
                  }}
                  automationVariant={AutomationFormVariant.AUTOMATIONS}
                  excludeAutomationsNotInFunnelMapObjects
                />
              </AutomationAutomationsContextProvider>
            </StyledPanelTab>
          ),
          label: 'Automations',
          value: StageDialogTabTypes.AUTOMATIONS,
          badgeLabel: String(automations.length || ''),
        },
        {
          badgeLabel: String(alerts.length || ''),
          content: (
            <StyledPanelTab>
              <AutomationAlertsContextProvider>
                <RightPanelAutomationsTab
                  readonly={readonly}
                  funnelDetails={funnelDetails}
                  automationVariant={AutomationFormVariant.PLAYBOOK_ALERT}
                  automations={alerts}
                  excludeAutomationsNotInFunnelMapObjects
                  onCreateData={{
                    newElement: {
                      objectName: leadingObject.objectName,
                      automationDetails: {
                        triggerType: AutomationTypes.StageEnter,
                        funnelId,
                        actions: [],
                        automationParams: {
                          stageEnter: {
                            toStage: stage,
                          },
                        },
                      },
                    },
                  }}
                />
              </AutomationAlertsContextProvider>
            </StyledPanelTab>
          ),
          label: 'Alerts',
          value: StageDialogTabTypes.ALERTS,
        },
        {
          content: (
            <StyledPanelTab>
              <AutomationAssignmentsContextProvider>
                <RightPanelAutomationsTab
                  readonly={readonly}
                  funnelDetails={funnelDetails}
                  automationVariant={AutomationFormVariant.ASSIGNMENT_RULES}
                  automations={assignments}
                  excludeAutomationsNotInFunnelMapObjects
                  onCreateData={{
                    newElement: {
                      objectName: leadingObject.objectName,
                      automationDetails: {
                        triggerType: AutomationTypes.StageEnter,
                        funnelId,
                        actions: [
                          {
                            _id: uniqueId(),
                            actionType: AutomationActionType.AssignmentRules,
                            actionParams: {
                              assignmentRules: [],
                            },
                          },
                        ],
                        automationParams: {
                          stageEnter: {
                            toStage: stage,
                          },
                        },
                      },
                    },
                  }}
                />
              </AutomationAssignmentsContextProvider>
            </StyledPanelTab>
          ),
          label: 'Assignments',
          value: StageDialogTabTypes.ASSIGNMENTS,
          badgeLabel: String(assignments.length || ''),
        },
      ],
    );
  }
  currentTabs.push({
    content: (
      <StyledPanelTab>
        <StageSettingsTab
          removeStage={removeStageAndCloseDialog}
          readonly={readonly || !isAllowedBtn}
          stage={stage}
          crmOrgId={crmOrgId}
          funnelDetails={funnelDetails}
          opportunityTypes={opportunityTypes}
          leadingFieldMetadata={leadingFieldMetadata}
          onStageChange={onStageChange}
          onStageMetadataChange={onStageMetadataChange}
        />
      </StyledPanelTab>
    ),
    label: 'Settings',
    value: StageDialogTabTypes.SETTINGS,
  });

  return (
    <>
      <TabContext value={activeTab}>
        <ConfigurationCanvasRightPanel>
          <StageDialogHeader
            stageName={tempStageName ?? stage.stageName}
            funnelDetails={funnelDetails}
            readonly={readonly || !isAllowedBtn}
            onStageNameConfirm={onStageNameConfirm}
            closeDialog={closeDialog}
          />

          <ConfigurationCanvasPanelContent sx={{ overflowY: 'scroll' }}>
            <TabList
              variant="scrollable"
              value={activeTab}
              list={currentTabs}
              onChange={onTabChange}
            />
          </ConfigurationCanvasPanelContent>
          <DialogLoader visible={showLoader} />
        </ConfigurationCanvasRightPanel>
      </TabContext>
    </>
  );
};

const StageDialogWithProviders = (props: StageDialogProps) => (
  <MultipleRuleBuildersProvider>
    <StageDialog {...props} />
  </MultipleRuleBuildersProvider>
);

export { StageDialogWithProviders as StageDialog };
