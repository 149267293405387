import {
  FormControl,
  InputLabelProps,
  TextField,
  TextFieldProps,
  FormControlProps,
} from '@mui/material';
import { ChangeEvent, ReactNode, Ref, useEffect, useState } from 'react';
import FieldLabel from './FieldLabel';

interface SweepTextFieldProps {
  id?: string;
  label?: string;
  labelTooltip?: ReactNode;
  value?: string;
  onChange?: (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
    value: string,
  ) => any;
  FormControlProps?: FormControlProps;
  TextFieldProps?: TextFieldProps;
  changeOnlyOnBlur?: boolean;
  markLabelAsRequired?: boolean;
  disableConfirmPropagation?: boolean;
  color?: 'primary' | 'secondary';
  autoFocus?: boolean;
  InputLabelProps?: InputLabelProps;
  onSelect?: () => any;
  inputRef?: Ref<any> | undefined;
}

// This hook is used to change the behavior of onChange.
// Insted of triggering the event on every key stroke,
// it triggers only on blur. This is helpful if you want
// for undo/redo to not register every key stroke.
const useOnChangeTriggeredOnBlurBehavior = ({
  value,
  changeOnlyOnBlur,
  onChange,
}: {
  value?: string;
  changeOnlyOnBlur?: boolean;
  onChange?: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | undefined,
    value: string,
  ) => any;
}) => {
  const [localValue, setLocalValue] = useState(value || '');

  useEffect(() => {
    setLocalValue(value || '');
  }, [value]);

  const newOnChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    setLocalValue(e.target.value);
    !changeOnlyOnBlur && onChange && onChange(e, e.target.value);
  };
  const onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined = (
    e,
  ) => {
    changeOnlyOnBlur && onChange && onChange(e, localValue);
  };

  return {
    newOnChange,
    onBlur,
    localValue,
    setLocalValue,
  };
};

const SweepTextField = (props: SweepTextFieldProps) => {
  const {
    id,
    label,
    labelTooltip,
    value,
    onChange,
    changeOnlyOnBlur,
    markLabelAsRequired,
    disableConfirmPropagation,
    color = 'primary',
    autoFocus = true,
    FormControlProps,
    TextFieldProps,
    InputLabelProps,
    onSelect,
    inputRef,
  } = props;

  const { newOnChange, onBlur, localValue } = useOnChangeTriggeredOnBlurBehavior({
    changeOnlyOnBlur,
    value,
    onChange,
  });

  const onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && disableConfirmPropagation) {
      event.stopPropagation();
    }
  };

  return (
    <FormControl {...FormControlProps}>
      {label && (
        <FieldLabel
          label={label}
          id={id}
          infoTooltipTitle={labelTooltip ?? ''}
          showTooltip={Boolean(labelTooltip)}
          markLabelAsRequired={markLabelAsRequired}
          {...InputLabelProps}
        />
      )}
      <TextField
        id={id}
        color={color}
        autoFocus={autoFocus}
        onKeyDown={onKeyDown}
        fullWidth
        value={localValue}
        onSelect={onSelect}
        inputRef={inputRef}
        onChange={(e) => {
          newOnChange(e);
        }}
        onBlur={onBlur}
        {...TextFieldProps}
      />
    </FormControl>
  );
};

export default SweepTextField;
