import { Box, Menu, MenuItem } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { UserSettings } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';
import { AutomationPermissionMode } from '../../types/enums/AutomationTypes';
import { useAutomationsContext } from './AutomationsContext';

export const AUTOMATION_PERMISSION_MODES_TRANSLATION = {
  [AutomationPermissionMode.UserMode]: 'User Mode',
  [AutomationPermissionMode.SystemMode]: 'System mode',
};

export const AutomationPermissionModeSelector = () => {
  const { automationJson, setAutomationJson } = useAutomationsContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const itemLayout = (isSelected: boolean) => {
    return {
      display: 'flex',
      flexDirection: 'column',
      width: '345px',
      gap: '4px',
      color: colors.grey[800],
      whiteSpace: 'normal',
      '& >span:first-of-type': {
        color: isSelected ? colors.blue[500] : 'initial',
        fontWeight: isSelected ? '600' : 'initial',
      },
    };
  };

  const options = [
    {
      value: AutomationPermissionMode.UserMode,
      title: AUTOMATION_PERMISSION_MODES_TRANSLATION[AutomationPermissionMode.UserMode],
      subTitle: 'Run as the triggering user, following their permissions and security settings.',
      isSelected:
        !automationJson?.automationDetails?.permissionMode ||
        automationJson?.automationDetails?.permissionMode === AutomationPermissionMode.UserMode,
    },
    {
      value: AutomationPermissionMode.SystemMode,
      title: AUTOMATION_PERMISSION_MODES_TRANSLATION[AutomationPermissionMode.SystemMode],
      subTitle: 'Run with system-level access, bypassing user-specific security restrictions.',
      isSelected:
        automationJson?.automationDetails?.permissionMode === AutomationPermissionMode.SystemMode,
    },
  ];

  return (
    <>
      <IconButton onClick={handleClick} variant="flat">
        <UserSettings />
      </IconButton>

      <Menu
        disableAutoFocusItem
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        slotProps={{
          paper: {
            style: {
              width: '421px',
            },
          },
        }}
      >
        <Box sx={{ p: 1 }}>
          <Typography variant="body-medium">Run as</Typography>
        </Box>

        {options.map((option) => (
          <MenuItem
            sx={{
              borderRadius: '4px',
              '&.MuiMenuItem-root.Mui-selected': {
                backgroundColor: colors.grey[100],
                '&::after': {
                  backgroundImage: `url('data:image/svg+xml;utf8,<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.5 4.5L6.5 11.5L3 8" stroke="rgb(17 152 255)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>')`,
                },
              },
            }}
            key={option.value}
            selected={option.isSelected}
            onClick={() => {
              setAutomationJson({
                ...automationJson,
                automationDetails: {
                  ...automationJson.automationDetails,
                  permissionMode: option.value,
                },
              });
              handleClose();
            }}
          >
            <Box sx={{ ...itemLayout(option.isSelected) }}>
              <Typography variant="body">{option.title}</Typography>
              <Typography variant="caption">{option.subTitle}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
