import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronUp, WhereUsed } from '@sweep-io/sweep-design/dist/icons';
import { Typography } from '@sweep-io/sweep-design';
import { SweepFieldsRuleBuilder } from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { useRefForMultipleRuleBuilders } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import {
  greatestSupportedTypes,
  greyLayout,
  latestSupportedTypes,
  minMaxSupportedTypes,
  prioritizeSupportedTypes,
  settingsPanelLayout,
} from './helpers';
import { uniqueId } from '../../../lib/uniqueId';
import { SweepFieldOperator } from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';

interface SetMasterRecordPanelProps {
  setMasterRecord?: SweepCondition;
  readonly?: boolean;
  crmOrgId: string;
  objectType: string;
  onChange: (condition?: SweepCondition) => any;
  referenceObjectType?: string;
}

const listOfOperatorsToRemove = [
  SweepFieldOperator.IS_CHANGED_FALSE,
  SweepFieldOperator.IS_CHANGED_TRUE,
  SweepFieldOperator.HAS_INCREASED,
  SweepFieldOperator.HAS_DECREASED,
];

const SetMasterRecordPanel = ({
  readonly,
  crmOrgId,
  objectType,
  setMasterRecord,
  onChange,
}: SetMasterRecordPanelProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(!!setMasterRecord);
  const ruleBuilderRefInclude = useRefForMultipleRuleBuilders();
  const _setMasterRecord = setMasterRecord ?? {
    criteriaLogic: '1',
    criteria: [
      {
        criterionId: uniqueId(),
        fieldType: 'DateTime',
        operator: SweepFieldOperator.MIN,
        value: 'true',
        _fieldIds: [`${objectType}.CreatedDate`],
        valueType: 'Literal',
      },
    ],
  };

  const filterOperatorBy = (operatorList: OperatorWithIndex[], fieldType?: SweepFieldTypes) => {
    //for date fields remove more  operators
    if (fieldType && latestSupportedTypes.includes(fieldType)) {
      listOfOperatorsToRemove.push(
        SweepFieldOperator.GREATER_THAN,
        SweepFieldOperator.GREATER_OR_EQUAL,
        SweepFieldOperator.LOWER_THAN,
        SweepFieldOperator.LOWER_OR_EQUAL,
      );
    }

    const filteredList = operatorList.filter(({ operator }) => {
      return !listOfOperatorsToRemove.includes(operator);
    });

    const newOperators = [];

    if (fieldType && minMaxSupportedTypes.includes(fieldType)) {
      newOperators.push(
        { label: 'Is max', operator: SweepFieldOperator.MAX, groupIndex: 0 },
        { label: 'Is min', operator: SweepFieldOperator.MIN, groupIndex: 0 },
      );
    }
    if (fieldType && greatestSupportedTypes.includes(fieldType)) {
      newOperators.push(
        { label: 'Is greatest', operator: SweepFieldOperator.MAX, groupIndex: 0 },
        { label: 'Is lowest', operator: SweepFieldOperator.MIN, groupIndex: 0 },
      );
    }
    if (fieldType && latestSupportedTypes.includes(fieldType)) {
      newOperators.push(
        { label: 'Is latest', operator: SweepFieldOperator.MAX, groupIndex: 0 },
        { label: 'Is earliest', operator: SweepFieldOperator.MIN, groupIndex: 0 },
      );
    }
    if (fieldType && prioritizeSupportedTypes.includes(fieldType)) {
      newOperators.push({
        label: 'Prioritize',
        operator: SweepFieldOperator.PRIORITIZE,
        groupIndex: 0,
      });
    }

    return [...newOperators, ...filteredList];
  };

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <WhereUsed variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Master record settings</Typography>
              <Typography variant="body">
                Define the criteria to determine default record to take values when merging records
              </Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <Box sx={{ ...greyLayout }}>
            <>
              <SweepFieldsRuleBuilder
                filterOperatorBy={filterOperatorBy}
                hideSelectValuesFromRecord={true}
                disableLookupItemsResolve={false}
                readOnly={readonly}
                crmOrgId={crmOrgId}
                objectType={objectType}
                sweepCondition={_setMasterRecord}
                ref={ruleBuilderRefInclude}
                onChange={(sweepCondition) => {
                  onChange(sweepCondition);
                }}
                disableResolvePolymorphic
              />
            </>
          </Box>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SetMasterRecordPanel;
