import { useReserveSpaceForLeftPanel } from './ReserveSpaceForPanelsCtx';

export const AllocateLeftPanelSpace = ({
  children,
  size,
}: {
  children: React.ReactNode;
  size: number;
}) => {
  useReserveSpaceForLeftPanel(size);
  return children;
};

