import * as React from 'react';
import Menu from '@mui/material/Menu';
import StyledTooltip from '../StyledTooltip';
import { MoreActionsVertical } from '@sweep-io/sweep-design/dist/icons';
import { IconButton } from '@sweep-io/sweep-design';
import { SxProps } from '@mui/material';
import {
  IconButtonSize,
  IconButtonVariants,
} from '@sweep-io/sweep-design/dist/components/IconButton/types';
import { ActionsMenuItem } from './ActionsMenuItem';

const ITEM_HEIGHT = 48;

export interface ActionsMenuAction<ItemValueT = any> {
  label: string | React.ReactElement;
  value: ItemValueT;
  hidden?: boolean;
  disabled?: boolean;
  disabledTitle?: string;
  isToggle?: boolean;
  isToggleChecked?: boolean;
  onToggleChange?: (active: boolean) => any;
  actionIcon?: React.ReactElement;
  actionSx?: SxProps;
  isNested?: boolean;
  nestedTitle?: string;
  nestedActions?: ActionsMenuAction[];
  addBottomDivider?: boolean;
  isSelected?: boolean;
  disabledTooltip?: string;
}

const isActionVisible = (action: ActionsMenuAction) => !action.hidden;

export interface ActionsMenuProps<ItemValueT = any> {
  actions: ActionsMenuAction<ItemValueT>[];
  onClick?: (action: ActionsMenuAction<ItemValueT>) => any;
  MenuButtonIcon?: React.ReactElement;
  iconTooltip?: string;
  iconSize?: IconButtonSize;
  iconVariant?: IconButtonVariants;
}

export function ActionsMenu<ItemValueT = any>({
  actions,
  onClick,
  MenuButtonIcon = <MoreActionsVertical />,
  iconTooltip,
  iconSize = 'small',
  iconVariant = 'flat',
}: ActionsMenuProps<ItemValueT>) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      {actions.length > 0 && (
        <>
          <StyledTooltip title={iconTooltip} placement="top">
            <span>
              <IconButton onClick={handleClick} variant={iconVariant} size={iconSize}>
                {MenuButtonIcon}
              </IconButton>
            </span>
          </StyledTooltip>

          <Menu
            disableAutoFocusItem
            id="long-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              paper: {
                style: {
                  maxHeight: ITEM_HEIGHT * 6,
                  width: '268px',
                },
              },
            }}
          >
            {actions.filter(isActionVisible).map((action) => (
              <ActionsMenuItem
                action={action}
                handleClose={handleClose}
                onClick={onClick}
                key={JSON.stringify(action.value)}
              />
            ))}
          </Menu>
        </>
      )}
    </div>
  );
}
