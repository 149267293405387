import { Menu, MenuItem, Stack } from '@mui/material';
import React, { ReactNode, SyntheticEvent, useRef } from 'react';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';

interface ReplyMenuProps {
  isMenuOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  deleteReplyCb: () => void;
  activateEditReply: () => void;
  onPinToTop?: () => void;
  isPinned?: boolean;
  deleteText: string;
}

const ReplyMenu = ({
  isMenuOpen,
  openMenu,
  closeMenu,
  deleteReplyCb,
  activateEditReply,
  onPinToTop,
  isPinned,
  deleteText,
}: ReplyMenuProps) => {
  const buttonElem = useRef<HTMLButtonElement>();
  const anchorEl = buttonElem?.current;
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    openMenu();
  };
  const handleMenuClose = (event: SyntheticEvent) => {
    event?.stopPropagation();
    closeMenu();
  };

  return (
    <>
      <IconButton
        ref={buttonElem}
        size="tiny"
        variant="flat"
        onClick={handleOpenMenu}
        pressed={isMenuOpen}
      >
        <Icons.MoreActionsVertical />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {Boolean(onPinToTop) && (
          <ReplyMenuItem
            text={isPinned ? 'Unpin from top' : 'Pin to top'}
            onClick={(event) => {
              event.stopPropagation();
              onPinToTop && onPinToTop();
              handleMenuClose(event);
            }}
            IconComponent={isPinned ? <Icons.Unpin /> : <Icons.Pin />}
          />
        )}

        <ReplyMenuItem
          text="Edit"
          onClick={(event) => {
            event.stopPropagation();
            activateEditReply();
            handleMenuClose(event);
          }}
          IconComponent={<Icons.NotePencil />}
        />

        <ReplyMenuItem
          text={deleteText}
          onClick={(event) => {
            event.stopPropagation();
            deleteReplyCb();
            handleMenuClose(event);
          }}
          IconComponent={<Icons.Delete color={colors.blush[600]} />}
          textColor={colors.blush[600]}
        />
      </Menu>
    </>
  );
};

const ReplyMenuItem = ({
  onClick,
  text,
  disabled,
  IconComponent,
  textColor,
}: {
  onClick: (event: SyntheticEvent) => void;
  text: string;
  disabled?: boolean;
  IconComponent: ReactNode;
  textColor?: string;
}) => {
  return (
    <MenuItem
      disableRipple
      disableGutters
      disabled={disabled}
      onClick={onClick}
      sx={{
        width: '200px',
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Stack alignItems="center">{IconComponent}</Stack>
      <Typography variant="body" color={textColor}>
        {text}
      </Typography>
    </MenuItem>
  );
};

export default ReplyMenu;
