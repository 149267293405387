import { useContext, useEffect } from 'react';
import { Node as ReactFlowNode, XYPosition } from 'reactflow';
import { CanvasElementType, SweepCanvasNode } from './canvasTypes';
import { DropZoneNodes } from './canvasTypes/nodeTypesData';
import {
  _canvasIndexPositionToReactFlowPosition,
  _reactFlowPositionToRoundedCanvasIndexPosition,
} from './helpers/calculateHandlePositionsBasedOnCoords';
import { useCalculateDragAndDrop } from './useCalculateDragAndDrop';
import { NODE_WIDTH, NODE_HEIGHT } from './const';
import { InternalCanvasCtx } from './internal-context/InternalCanvasCtx';

export interface DraggingInfo {
  initialPosition: XYPosition;
  node: ReactFlowNode<any>;
}

export const useDropZoneNode = ({
  internalSweepNodes,
}: {
  internalSweepNodes: SweepCanvasNode[];
}) => {
  const { draggingNodeInfo, setDraggingNodeInfo } = useContext(InternalCanvasCtx);

  useEffect(() => {
    const nodeId = draggingNodeInfo?.node?.id;
    if (!internalSweepNodes.find((_node) => _node.id === nodeId || _node.parentId === nodeId)) {
      setDraggingNodeInfo(undefined);
    }
  }, [internalSweepNodes, draggingNodeInfo?.node?.id, setDraggingNodeInfo]);

  const { getDropInvalidReason } = useCalculateDragAndDrop({ internalSweepNodes });
  const draggingNode = draggingNodeInfo?.node;

  let dropZoneNode: DropZoneNodes | undefined;

  if (
    draggingNode?.position &&
    internalSweepNodes.find(
      (_node) => _node.id === draggingNode?.id || _node.parentId === draggingNode?.id,
    )
  ) {
    if (draggingNode.type === CanvasElementType.GROUP) {
      const position = _canvasIndexPositionToReactFlowPosition(
        _reactFlowPositionToRoundedCanvasIndexPosition(draggingNode.position),
      );

      const canvasIndexPosition = _reactFlowPositionToRoundedCanvasIndexPosition(position);

      dropZoneNode = {
        id: 'dropping-zone-node',
        position: position,
        zIndex: -1,
        parentNode: draggingNode.parentNode,
        data: {
          positions: { ...draggingNode.data.positions, sweepCanvasPosition: canvasIndexPosition },
        },
        type: CanvasElementType.GROUP_DROP_ZONE_NODE,
      };
    } else {
      const possiblePosition = _canvasIndexPositionToReactFlowPosition(
        _reactFlowPositionToRoundedCanvasIndexPosition(draggingNode.position),
      );

      const dropInvalidReason = getDropInvalidReason(draggingNode.position, draggingNode.id);
      const droppingZoneNodePosition = dropInvalidReason
        ? draggingNodeInfo?.initialPosition || { x: 0, y: 0 }
        : possiblePosition;

      const canvasIndexPosition =
        _reactFlowPositionToRoundedCanvasIndexPosition(droppingZoneNodePosition);

      dropZoneNode = {
        width: NODE_WIDTH,
        height: NODE_HEIGHT,
        id: 'dropping-zone-node',
        position: droppingZoneNodePosition,
        zIndex: -1,
        parentNode: draggingNode.parentNode,
        data: {
          dropZoneType: CanvasElementType.REGULAR,
          positions: { sweepCanvasPosition: canvasIndexPosition },
        },
        type: CanvasElementType.DROP_ZONE_NODE,
      };
    }
  }

  return {
    dropZoneNode,
    draggingNode,
  };
};
