import { useState } from 'react';
import useCheckManagedPackage from '../../install-managed-package/useCheckManagedPackage';
import { useRunOnce } from '../../useRunOnce';
import { Box, Stack } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { ConnectSweepTo } from '../../connect-sweep-to/ConnectSweepTo';
import { CompleteSfSetupForDeployDialog } from './CompleteSfSetupForDeployDialog';
import pluralize from 'pluralize';
import { useIsSetupCompleted } from '../../../../hooks/useIsSetupCompleted';

export const CompleteSetupToDeploy = ({ entityName }: { entityName: string }) => {
  const { getIsManagedPackagedInstalledWithRecheck } = useCheckManagedPackage();
  const [isCompleteSetupOpen, setIsCompleteSetupOpen] = useState(false);
  const canDeploy = useIsSetupCompleted();

  useRunOnce(async () => {
    await getIsManagedPackagedInstalledWithRecheck({
      withPermissionsCheck: true,
    });
  });

  if (canDeploy) {
    return null;
  }

  return (
    <>
      <Stack
        sx={{
          border: `1px solid ${colors.blue[500]}`,
          borderRadius: '4px',
          gap: '12px',
          padding: 2,
          textAlign: 'center',
          alignItems: 'center',
        }}
      >
        <ConnectSweepTo connectTo={'Salesforce'} />
        <Stack gap={0.5}>
          <Typography variant="body-medium">Deploy your saved {pluralize(entityName)}</Typography>
          <Box>
            <Typography variant="body" color={colors.grey[800]}>
              Complete setup and see {pluralize(entityName)} live in Salesforce
            </Typography>
          </Box>
        </Stack>
        <Box>
          <Button
            size="small"
            onClick={() => {
              setIsCompleteSetupOpen(true);
            }}
          >
            Complete setup
          </Button>
        </Box>
      </Stack>
      {isCompleteSetupOpen && (
        <CompleteSfSetupForDeployDialog
          cancelDialog={() => {
            setIsCompleteSetupOpen(false);
          }}
          onCompleteItemsCallback={() => {
            setIsCompleteSetupOpen(false);
          }}
          entityName={entityName}
        />
      )}
    </>
  );
};
