import {
  NestedFieldsSelector,
  NestedFieldsSelectorProps,
} from '../common/fieldsSelectors/NestedFieldsSelector';
import { SfChangeFeedSelectorProps } from './selectors/SfChangeFeedSelector';
import { SfChangeFeedSelector } from './selectors/SfChangeFeedSelector';
import { TerritorySelector, TerritorySelectorProps } from './selectors/TerritorySelector';

export enum MainSelectors {
  Territories = 'territories',
  SfChangeFeedNotifications = 'SfChangeFeedNotifications',
}

export const SweepFieldsMainSelector = ({
  mainSelectorKey,
  territorySelectorProps,
  nestedSelectorProps,
  sfChangeFeedSelectorProps,
}: {
  mainSelectorKey?: MainSelectors;
  territorySelectorProps?: TerritorySelectorProps;
  nestedSelectorProps?: NestedFieldsSelectorProps;
  sfChangeFeedSelectorProps?: SfChangeFeedSelectorProps;
}) => {
  switch (mainSelectorKey) {
    case MainSelectors.Territories:
      return territorySelectorProps ? <TerritorySelector {...territorySelectorProps} /> : <></>;

    case MainSelectors.SfChangeFeedNotifications:
      return sfChangeFeedSelectorProps ? (
        <SfChangeFeedSelector {...sfChangeFeedSelectorProps} />
      ) : (
        <></>
      );
  }

  if (!nestedSelectorProps) {
    return <></>;
  }

  return <NestedFieldsSelector {...nestedSelectorProps} />;
};
