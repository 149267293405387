import { useFeatureToggle } from '../common/useFeatureToggle';
import CopilotChatConnectedDeprecated from './CopilotChatConnectedDeprecated';
import CopilotChatsConnected from '../copilotChats/CopilotChatsConnected';

const CopilotChatEntryPoint = ({
  closeCopilot,
  isOpened,
}: {
  closeCopilot: () => void;
  isOpened: boolean;
}) => {
  const { copilotChatsHistory } = useFeatureToggle();

  return copilotChatsHistory ? (
    <CopilotChatsConnected closeCopilot={closeCopilot} isOpened={isOpened} />
  ) : (
    <CopilotChatConnectedDeprecated closeCopilot={closeCopilot} isOpened={isOpened} />
  );
};

export default CopilotChatEntryPoint;
