import { ChatCenteredText } from '@sweep-io/sweep-design/dist/icons';
import StyledTooltip from '../../../../common/StyledTooltip';
import { DependenciesContentViewType } from '../../types';
import { IconButton } from '@sweep-io/sweep-design';
import { useDependencies } from '../../hooks/useDependencies';

interface ChatIconProps {
  crmOrgId: string;
}

export const ChatIcon = ({ crmOrgId }: ChatIconProps) => {
  const { setContentType } = useDependencies(crmOrgId);

  return (
    <StyledTooltip placement="top" title="Comments">
      <IconButton
        variant="outlined"
        size={'tiny'}
        onClick={() => setContentType(DependenciesContentViewType.comments)}
      >
        <ChatCenteredText />
      </IconButton>
    </StyledTooltip>
  );
};
