import { BasePluginPanel, PluginPanelData } from '../../../panel/BasePluginPanel';
import { FunnelBiSettingsBox } from '../FunnelBiSettingsBox';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  removePlugin,
  setPlugin,
} from '../../../../../../../reducers/united-canvas/unitedCanvasReducer';
import { PluginTypes } from '../../../../../../../types/enums/PluginTypes';
import { PluginDataManagementSvg } from '../../icons';
import { selectSfFunnelPlugin } from '../../../../../../../reducers/united-canvas/sfFunnelReducer';

interface DataManagementPanelProps {
  plugin: DataManagementPlugin;
  anchorEl: HTMLElement;
  onChange: (plugin: DataManagementPlugin) => void;
  onClose: () => void;
  onRemove: () => void;
}

const DataManagementPanel = ({
  plugin,
  anchorEl,
  onClose,
  onChange,
  onRemove,
}: DataManagementPanelProps) => {
  return (
    <BasePluginPanel
      anchorEl={anchorEl}
      maybeClosePanel={onClose}
      pluginIcon={<PluginDataManagementSvg />}
      title="Data Management Plug-in"
      onRemove={onRemove}
    >
      <Box marginTop={'12px'}>
        <FunnelBiSettingsBox
          biSettings={{ flags: plugin.flags }}
          onChange={(biSettings) => {
            onChange({
              ...plugin,
              flags: biSettings.flags || [],
            });
          }}
        />
      </Box>
    </BasePluginPanel>
  );
};

const ConnectedDataManagementPanel = ({
  funnelId,
  anchorEl,
  onClose,
}: PluginPanelData & { onClose: () => void }) => {
  const dispatch = useDispatch();
  const plugin =
    useSelector(selectSfFunnelPlugin(funnelId, PluginTypes.DATA_MANAGEMENT)) ||
    ({
      id: PluginTypes.DATA_MANAGEMENT,
      flags: [],
    } as DataManagementPlugin);

  if (!plugin) throw new Error('Plugin not found');

  return (
    <DataManagementPanel
      plugin={plugin as DataManagementPlugin}
      anchorEl={anchorEl}
      onChange={(newPlugin) => {
        dispatch(setPlugin({ funnelId, plugin: newPlugin }));
      }}
      onClose={onClose}
      onRemove={() => {
        onClose();
        dispatch(removePlugin({ funnelId, pluginId: plugin.id }));
      }}
    />
  );
};

export { ConnectedDataManagementPanel as DataManagementPanel };
