import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { ChevronRight } from '@sweep-io/sweep-design/dist/icons';

export const ChevronButton = () => (
  <Box>
    <IconButton variant="flat" size="tiny">
      <ChevronRight variant="default" />
    </IconButton>
  </Box>
);
