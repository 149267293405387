import {
  Fields,
  SalesforceAutomations,
  ValidationRules,
  Apex,
} from '@sweep-io/sweep-design/dist/icons';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { colors } from '@sweep-io/sweep-design';
import { PillPropsExtended } from '../object-list/ObjectCardPills';

export const pillOptions: PillPropsExtended[] = [
  {
    key: DocumentationTabTypes.FIELDS,
    tooltipTitle: 'Fields',
    startIconName: 'Fields',
    icon: <Fields color={colors.night[700]} />,
    value: 0,
  },
  {
    key: DocumentationTabTypes.SF_AUTOMATIONS,
    tooltipTitle: 'Salesforce automations',
    startIconName: 'SalesforceAutomations',
    icon: <SalesforceAutomations color={colors.night[700]} />,
    value: 0,
  },
  {
    key: DocumentationTabTypes.VALIDATION_RULES,
    tooltipTitle: 'Validation rules',
    startIconName: 'ValidationRules',
    icon: <ValidationRules color={colors.night[700]} />,
    value: 0,
  },
  {
    key: DocumentationTabTypes.APEX,
    tooltipTitle: 'Apex triggers',
    startIconName: 'Apex',
    icon: <Apex color={colors.night[700]} />,
    value: 0,
  },
];
