import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import SweepSelect from '../../common/SweepSelect';

export type SfChangeFeedSelectorProps = {
  readonly: boolean;
  onChange: (event: SelectChangeEvent<unknown>) => void;
  fieldLabels: string[];
};

export const SfChangeFeedSelector = ({
  readonly,
  onChange,
  fieldLabels,
}: SfChangeFeedSelectorProps) => {
  return (
    <Box>
      <SweepSelect
        FormControlProps={{
          sx: { width: '100%' },
        }}
        removeBorders={true}
        SelectProps={{
          placeholder: 'Select item',
          disabled: readonly,
          onChange,
          value: fieldLabels[0] ?? '',
        }}
      >
        {['Action', 'User', 'Section'].map((type) => (
          <MenuItem key={type} value={type}>
            {type}
          </MenuItem>
        ))}
      </SweepSelect>
    </Box>
  );
};
