import { Avatar, Box } from '@mui/material';
import { AvatarType } from './avatarTypes';
import { Emoji } from './Emoji';
import { colors } from '@sweep-io/sweep-design';

export interface AvatarPictureProps {
  avatar?: AvatarType;
  dimension: number;
  clickable?: boolean;
  greyscale?: boolean;
  isInactive: boolean;
}

const AvatarPicture = ({
  avatar = {},
  dimension,
  clickable,
  greyscale,
  isInactive,
}: AvatarPictureProps) => {
  const { imageUrl, emoji } = avatar;
  return (
    <Box sx={{ filter: greyscale ? 'grayscale(100%)' : 'none' }}>
      {isInactive && (
        <Box
          data-testid="profile-picture-avatar"
          sx={{
            width: dimension,
            height: dimension,
            borderRadius: '40px',
            backgroundColor: colors.grey[100],
            border: `1px solid ${colors.grey[200]}`,
          }}
        />
      )}
      {!isInactive && imageUrl && (
        <Avatar
          data-testid="profile-picture-avatar"
          src={imageUrl}
          sx={{
            width: dimension,
            height: dimension,
            cursor: clickable ? 'pointer' : 'default',
          }}
        />
      )}
      {!isInactive && !imageUrl && emoji && (
        <Emoji
          content={emoji.content}
          bgColor={emoji.bgColor}
          dimension={dimension}
          cursor={clickable ? 'pointer' : 'default'}
        />
      )}
    </Box>
  );
};

export { AvatarPicture };
