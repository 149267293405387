import { Box, ListItem } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';

export const Subtext = ({ text, asListItem = false }: { text: string; asListItem?: boolean }) => (
  <Box>
    <>{asListItem ? <SubtextListItem text={text} /> : <Text text={text} />}</>
  </Box>
);

const SubtextListItem = ({ text }: { text: string }) => (
  <ListItem
    key={'list_item-' + text}
    sx={{
      display: 'list-item',
      p: 0,
      mb: 0,
      '&:hover': {
        background: 'transparent',
      },
      '&::marker': { marginRight: '8px', color: colors.grey[800] },
    }}
  >
    <Text text={text} />
  </ListItem>
);

const Text = ({ text }: { text: string }) => (
  <Typography color={colors.grey[800]} variant="body">
    {text}
  </Typography>
);
