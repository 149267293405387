import { Box, styled } from '@mui/material';
import { DASHBOARD_HEADER_HEIGHT } from '../../../../constants';
import { colors } from '@sweep-io/sweep-design';

export const DashboardHeaderContainer = styled(Box)({
  height: DASHBOARD_HEADER_HEIGHT,
  borderBottom: `1px solid ${colors.grey[300]}`,
  position: 'sticky',
  top: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '12px',
});
