import { ListItemIcon, ListItemText, Box } from '@mui/material';
import { SxProps, Theme, styled } from '@mui/material/styles';
import classNames from 'classnames';
import { Typography, colors } from '@sweep-io/sweep-design';
import {
  SweepTypographyVariants,
  TextDecorationOptions,
} from '@sweep-io/sweep-design/dist/components/Typography/types';
import { StyledDashboardListItem } from './StyledDashboardListItem';

interface SidebarListItemProps {
  text: string;
  callback: (val: any) => any;
  icon: JSX.Element;
  animatedIcon?: JSX.Element;
  isSelected?: boolean;
  typographyVariant?: SweepTypographyVariants;
  listItemSx?: SxProps<Theme>;
  textDecoration?: TextDecorationOptions;
}

const SidebarListItem = ({
  text,
  icon,
  animatedIcon,
  callback,
  isSelected,
  typographyVariant = 'body',
  textDecoration,
}: SidebarListItemProps) => {
  const color = isSelected ? colors.blue[500] : colors.black;

  return (
    <StyledDashboardListItem
      onClick={callback}
      isSelected={!!isSelected}
      sx={{
        height: '40px',
        padding: '10px',
        '.icon-static': {
          display: 'flex',
        },
        '.icon-animated': {
          display: ' none',
        },
        '&:hover': {
          '.icon-static:not(.selected)': {
            display: 'none',
          },
          '.icon-animated:not(.selected)': {
            display: 'flex',
          },
        },
      }}
    >
      <ListItemIcon
        sx={{
          minWidth: '24px',
          'svg>path, svg>g>path': {
            stroke: color,
          },
          svg: { width: '16px' },
          mr: 0,
        }}
      >
        <CenteredBox className={classNames({ 'icon-static': true, selected: isSelected })}>
          {icon}
        </CenteredBox>
        <CenteredBox className={classNames({ 'icon-animated': true, selected: isSelected })}>
          {animatedIcon ?? icon}
        </CenteredBox>
      </ListItemIcon>

      <ListItemText disableTypography>
        <Typography
          variant={isSelected ? `body-bold` : typographyVariant}
          color={color}
          textDecoration={textDecoration}
        >
          {text}
        </Typography>
      </ListItemText>
    </StyledDashboardListItem>
  );
};

const CenteredBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
});

export { SidebarListItem };
