import { useCallback } from 'react';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { LimitMembersPanel, LimitMembersVars } from './deprecated/LimitMembersPanel';
import { useGroupSettingsContext } from './GroupSettingsContext';
import { validateLimitMembersPanel } from './LimitMembersTimePanel';
import { validateConditionalLimitPanel } from './ConditionalLimitPanel';

export const useValidateLimitMembersPanel = () => {
  const {
    assignmentGroup: { groupLimit },
    resetAllMembersLimit,
  } = useGroupSettingsContext();

  const isLimitMembersSectionValid = useCallback(() => {
    if (groupLimit?.type === 'TIME_LIMIT') {
      return validateLimitMembersPanel({
        groupLimit: groupLimit,
        resetAllMembersLimit,
      });
    }
    if (groupLimit?.type === 'CONDITIONAL_LIMIT') {
      return validateConditionalLimitPanel(groupLimit.details);
    }

    return true;
  }, [groupLimit, resetAllMembersLimit]);

  return { isLimitMembersSectionValid };
};

export const LimitMembersPanelWithContext = () => {
  const {
    assignmentGroup: { groupLimit },
    setAssignmentGroup,
    resetAllMembersLimit,
    setResetAllMembersLimit,
  } = useGroupSettingsContext();

  return (
    <LimitMembersPanel
      onChange={({ groupLimit, resetAllMembersLimit }: LimitMembersVars) => {
        setAssignmentGroup(
          (localGroupData) =>
            ({
              ...localGroupData,
              groupLimit,
            }) as DeepPartial<AssignmentGroup>,
        );
        setResetAllMembersLimit(resetAllMembersLimit);
      }}
      vars={{
        groupLimit,
        resetAllMembersLimit,
      }}
    />
  );
};
