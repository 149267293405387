import { ChatCompletionRequestMessageRole } from '@server/ai';
import { Fragment } from 'react';
import { AiChatAssistantMessageBox } from './message-atoms/AiChatAssistantMessageBox';
import { AiChatUserMessageBox } from './message-atoms/AiChatUserMessageBox';
import { AiChatMessage } from './aiChatTypes';
import usePaywall from '../../dashboard/paywall/usePaywall';
import AiChatPaywallBlockMessage from './AiChatPaywallBlockMessage';
import { DateTime } from 'luxon';
import { Stack } from '@mui/material';
import { useUnhappyStates } from '../../pages/environments/useUnhappyStates';
import AiChatNoOrgConnectedMessage from './AiChatNoOrgConnectedMessage';
import AiChatInitialFetchMessage from './AiChatInitialFetchMessage';

interface AiChatMessagesProps {
  scrollToBottom?: () => void;
  messages: AiChatMessage[];
  isLoading?: boolean;
  errorMsg?: string;
  introMessage?: AiChatMessage;
}

export const AiChatMessages = ({
  scrollToBottom,
  messages,
  isLoading,
  errorMsg,
  introMessage,
}: AiChatMessagesProps) => {
  const { isPaywallActive } = usePaywall();
  const {noConnectedOrg, isInitialFetch} = useUnhappyStates();

  const userMessagesCount = messages.filter(
    (msg) => msg.role === ChatCompletionRequestMessageRole.USER,
  ).length;

  const shouldAddAutoResponse = isPaywallActive || noConnectedOrg || isInitialFetch;
  const showAutoResponse = shouldAddAutoResponse && userMessagesCount > 0;

  const messagesAfterPaywall = shouldAddAutoResponse
    ? messages.filter((msg) => msg.role !== ChatCompletionRequestMessageRole.ASSISTANT)
    : messages;

  const messagesToRender = introMessage
    ? [introMessage].concat(messagesAfterPaywall)
    : messagesAfterPaywall;

  return (
    <Stack gap={2}>
      {messagesToRender.map((message, idx) => {
        switch (message.role) {
          case ChatCompletionRequestMessageRole.USER:
            return (
              <AiChatUserMessageBox
                key={`user_message_${idx}`}
                content={message.content}
                createdAt={message?.createdAt ?? ''}
              />
            );

          case ChatCompletionRequestMessageRole.ASSISTANT:
            return (
              <AiChatAssistantMessageBox
                key={`assistant_message_${idx}`}
                content={message.content}
                createdAt={message?.createdAt ?? ''}
                scrollToBottom={scrollToBottom}
              />
            );

          default:
            return <Fragment key={`no_message_${idx}`} />;
        }
      })}

      {!!errorMsg && !isPaywallActive && (
        <AiChatAssistantMessageBox
          key="error-assistant"
          content={errorMsg}
          createdAt=""
          scrollToBottom={scrollToBottom}
          errorState
        />
      )}

      {isLoading && !isPaywallActive && (
        <AiChatAssistantMessageBox
          isLoading
          scrollToBottom={scrollToBottom}
          content=""
          createdAt=""
        />
      )}

      {showAutoResponse && isPaywallActive && (
        <AiChatAssistantMessageBox
          content={<AiChatPaywallBlockMessage />}
          createdAt={DateTime.now().toISO()}
          scrollToBottom={scrollToBottom}
        />
      )}
      {showAutoResponse && !isPaywallActive && noConnectedOrg && (
        <AiChatAssistantMessageBox
          content={<AiChatNoOrgConnectedMessage />}
          createdAt={DateTime.now().toISO()}
          scrollToBottom={scrollToBottom}
        />
      )}
      {showAutoResponse && !isPaywallActive && isInitialFetch && (
        <AiChatAssistantMessageBox
          content={<AiChatInitialFetchMessage />}
          createdAt={DateTime.now().toISO()}
          scrollToBottom={scrollToBottom}
        />
      )}
    </Stack>
  );
};
