import { Box, Collapse } from '@mui/material';
import React from 'react';
import { ReactNode } from 'react';
import { ChevronDown } from '@sweep-io/sweep-design/dist/icons';
import { IconButton, colors } from '@sweep-io/sweep-design';

interface CollapsiblePanelTitleProps {
  children: ReactNode;
}

interface CollapsiblePanelContentProps {
  children: ReactNode;
}

export const CollapsiblePanelTitle: React.FC<CollapsiblePanelTitleProps> = ({
  children,
}: {
  children: ReactNode;
}) => children;

export const CollapsiblePanelContent: React.FC<CollapsiblePanelContentProps> = ({
  children,
}: {
  children: ReactNode;
}) => children;

// Example on how to consume CollapsiblePanel:
/**
  <CollapsiblePanel expanded={true}>
    <CollapsiblePanelTitle>Collapsible Panel Title</CollapsiblePanelTitle>
    <CollapsiblePanelContent>Collapsible Panel Content</CollapsiblePanelContent>
  </CollapsiblePanel>
 */

interface CollapsiblePanelProps {
  expanded?: boolean;
  onChange?: (expanded: boolean) => void;
  children: ReactNode;
  onTransitionEnd?: React.TransitionEventHandler<HTMLElement>;
  enableHeaderClick?: boolean;
}

export const CollapsiblePanel: React.FC<CollapsiblePanelProps> = ({
  children,
  expanded,
  onChange,
  onTransitionEnd,
  enableHeaderClick,
}) => {
  let title: ReactNode = <div />;
  let content: ReactNode = <div />;

  // Iterate through the children to find CollapsiblePanelTitle and CollapsiblePanelContent
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === CollapsiblePanelTitle) {
        title = child;
      } else if (child.type === CollapsiblePanelContent) {
        content = child;
      }
    }
  });

  if (!title && !content) {
    throw new Error(
      'CollapsiblePanel must have at least one CollapsiblePanelTitle and one CollapsiblePanelContent',
    );
  }

  return (
    <Box
      sx={{
        border: `1px solid ${colors.grey[300]}`,
        borderRadius: '6px',
        p: 2,
        pt: 1,
        pb: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          cursor: enableHeaderClick ? 'pointer' : 'default',
          gap: 2,
          '.collapsible-panel__expand-icon': {
            display: 'flex',
            svg: {
              transition: 'transform 0.2s',
              transform: expanded ? 'rotate(-180deg)' : 'rotate(0deg)',
              translate: '14px', // The iconButton link is aligned to the left. This is to center the icon. TODO: Fix this is design system
            },
          },
        }}
        onClick={() => {
          onChange && enableHeaderClick && onChange(Boolean(expanded));
        }}
      >
        {title}
        <div className="collapsible-panel__expand-icon">
          <IconButton
            variant="flat"
            onClick={() => {
              onChange && onChange(Boolean(expanded));
            }}
            size="small"
          >
            <ChevronDown color={colors.grey[800]} />
          </IconButton>
        </div>
      </Box>
      <Collapse in={expanded} onTransitionEnd={onTransitionEnd}>
        {content}
      </Collapse>
    </Box>
  );
};
