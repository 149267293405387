import { IconButton, Tooltip, Typography, colors } from '@sweep-io/sweep-design';
import { Star } from '@sweep-io/sweep-design/dist/icons';
import { Box, CircularProgress } from '@mui/material';
import { useDocumentation } from '../useDocumentation';
import { useFeatureToggle } from '../../common/useFeatureToggle';
import { useCallback, useState } from 'react';
import { telemetry } from '../../../telemetry';

export enum AddObjectButtonVersion {
  small = 'small', //only icon button
  default = 'default', //with scanning object
}

interface AddObjectButtonProps {
  objectName: string;
  isLoading?: boolean;
  buttonVersion?: AddObjectButtonVersion;
  isInFunnelMap?: boolean;
}

export const AddObjectButton = ({
  objectName,
  isLoading,
  buttonVersion = AddObjectButtonVersion.default,
  isInFunnelMap,
}: AddObjectButtonProps) => {
  const { removeFromFavorites } = useFeatureToggle();
  const { onAddObject, onRemoveObject } = useDocumentation();
  const [isRemoving, setIsRemoving] = useState(false);

  const isDefaultVersion = buttonVersion === AddObjectButtonVersion.default;

  const onClick = useCallback(async () => {
    if (isInFunnelMap) {
      try {
        setIsRemoving(true);
        await onRemoveObject(objectName);
        setIsRemoving(false);
      } catch (e) {
        telemetry.captureError(e);
      }
    } else {
      onAddObject(objectName);
    }
  }, [isInFunnelMap, onAddObject, onRemoveObject, objectName]);

  if (isLoading && isDefaultVersion) {
    return (
      <Box display="flex" gap={1} pl={1} alignItems="center" minHeight="32px">
        <Typography variant="caption" whiteSpace="nowrap">
          Scanning object
        </Typography>

        <CircularProgress size={10} />
      </Box>
    );
  }

  if (removeFromFavorites) {
    return (
      <Tooltip
        placement="top"
        title={
          isLoading
            ? 'Scanning object...'
            : isRemoving
              ? 'Removing object...'
              : isInFunnelMap
                ? 'Remove from favorites'
                : 'Add to favorites'
        }
      >
        <Box
          sx={{
            '& svg path': {
              //TODO support in design system
              fill: isInFunnelMap ? colors.spark : '',
              stroke: isInFunnelMap ? colors.spark : '',
            },
          }}
        >
          <IconButton
            variant={isDefaultVersion ? 'flat' : 'outlined'}
            size="tiny"
            loading={isRemoving || isLoading}
            onClick={(e) => {
              e.stopPropagation();
              onClick();
            }}
          >
            <Star />
          </IconButton>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip placement="top" title={'Add to favorites'}>
      <IconButton
        variant={'flat'}
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onAddObject(objectName);
        }}
      >
        <Star />
      </IconButton>
    </Tooltip>
  );
};
