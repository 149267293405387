export const RELATIVE_DATE_LIST = {
  DateTime: [
    {
      value: 'now',
      description: 'Time Automation is Triggered (NOW)',
    },
    {
      value: 'now-',
      description: 'X Day(s) Before Automation is Triggered',
    },
    {
      value: 'now+',
      description: 'X Day(s) After the Automation is Triggered',
    },
  ],
  Date: [
    {
      value: 'today',
      description: 'Day Automation is Triggered (TODAY)',
    },
    {
      value: 'today-',
      description: 'X Day(s) Before Automation is Triggered',
    },
    {
      value: 'today+',
      description: 'X Day(s) After the Automation is Triggered',
    },
  ],
};
