import { useState } from 'react';
import { useObjectFlags } from './useObjectFlags';

export const useCalculateLastUpdate = ({
  funnelsData,
  funnelMapLastUpdatedAt,
}: {
  funnelsData: FunnelsData;
  funnelMapLastUpdatedAt: string;
}) => {
  const [initialLastUpdatedFlags] = useState(() => {
    const funnelsLastUpdateAt = Object.fromEntries(
      Object.keys(funnelsData).map((funnelId) => [funnelId, funnelsData[funnelId].updatedAt]),
    );
    funnelsLastUpdateAt['FUNNEL_MAP'] = funnelMapLastUpdatedAt;
    return funnelsLastUpdateAt;
  });

  const { setObjectFlagFor: setLastUpdatedFor, objectFlags: lastUpdatedFunnelEntities } =
    useObjectFlags<string>(initialLastUpdatedFlags);

  const allLastUpdated = Object.values(lastUpdatedFunnelEntities);

  const lastUpdated = allLastUpdated.reduce((prev, curr) => {
    const prevDate = new Date(prev).getTime();
    const currDate = new Date(curr).getTime();
    return currDate > prevDate ? curr : prev;
  }, allLastUpdated[0]);

  return {
    setLastUpdatedFor,
    lastUpdated,
  };
};
