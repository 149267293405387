// Do not put any dispatcher here.
// Only pure API calls
// The Facades should mimic exactly the corresponding API.
import { useCallback } from 'react';
import { useSweepApi } from '../sweep';

interface DeploymentRequestDto {
  snapshotId?: string;
  funnelId?: string;
}

export const useDeploymentsApi = () => {
  const sweepApi = useSweepApi();

  return {
    get_deployment: useCallback(
      async ({ deploymentId }: { deploymentId: string }) => {
        const response = await sweepApi.get(`/deployments/${deploymentId}`);

        return response.data as FunnelDeployment;
      },
      [sweepApi],
    ),
    post_deploymentsCrmOrg: useCallback(
      async ({
        crmOrgId,
        async,
        payload: { funnelId, snapshotId },
      }: {
        crmOrgId: string;
        async?: boolean;
        payload: DeploymentRequestDto;
      }) => {
        const url = `/deployments/crm-orgs/${crmOrgId}` + (async ? '?async=true' : '');
        const response = await sweepApi.post(url, {
          funnelId,
          snapshotId,
          syncRollups: false,
          automationsV1: false,
        });
        return response.data as FunnelDeployment;
      },
      [sweepApi],
    ),
  };
};
