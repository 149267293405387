import { List, ListItem } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';

interface ListWithBulletsProps {
  listItems?: string[];
  paddingLeft?: number;
}

export const ListWithBullets = ({ listItems }: ListWithBulletsProps) => {
  return (
    <List sx={{ listStyleType: 'disc', p: 0, pl: 1 }}>
      {listItems?.map((item) => {
        return (
          <ListItem
            key={'list_item-' + item}
            sx={{
              display: 'list-item',
              p: 0,
              mb: 0,
              '&:hover': {
                background: 'transparent',
              },
              '&::marker': { color: colors.grey[800] },
            }}
          >
            <Typography variant="body" color={colors.grey[800]}>
              {item}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};
