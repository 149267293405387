import { Box } from '@mui/material';
import CloudDisconnectedIllustration from '../../../../icons/svg/CloudDisconnectedIllustration.svg?react';
import { Button, Typography } from '@sweep-io/sweep-design';
import { openIntercom } from '../../../dashboard/customerSupportHelper';

export const RequestLimitEnvConnectionErrorPage = () => {
  const message: any = `Sorry! You've exceeded your Salesforce API limit`;
  const buttonText = 'Talk to us';
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        flexDirection: 'column',
      }}
    >
      <CloudDisconnectedIllustration />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          alignItems: 'center',
          width: '270px',
          textAlign: 'center',
        }}
      >
        <Typography variant="h3">Sorry! You've exceeded your Salesforce API limit</Typography>
        <Typography variant="body">{message}</Typography>
      </Box>

      <Button
        onClick={() => {
          openIntercom();
        }}
      >
        {buttonText}
      </Button>
      <Button
        variant="link"
        onClick={() => {
          window.open(
            'https://help.salesforce.com/s/articleView?language=en_US&id=000385367&type=1',
            '_blank',
          );
        }}
      >
        Learn more about Salesforce's API limit
      </Button>
    </Box>
  );
};
