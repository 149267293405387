import { Box, Dialog } from '@mui/material';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';

const PlayStorylaneModal = ({ closeDialog, url }: { closeDialog: () => void; url: string }) => {
  return (
    <Dialog
      open={true}
      onClose={closeDialog}
      onClick={closeDialog}
      PaperProps={{
        sx: {
          width: '1160px',
          overflow: 'hidden',
          padding: 5,
          paddingBottom: 0,
          backgroundColor: 'transparent',
          aspectRatio: '1360/872',
        },
      }}
      sx={{
        '.MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          backdropFilter: 'blur(3px)',
        },
      }}
    >
      <Box
        onClick={closeDialog}
        sx={{
          position: 'absolute',
          right: 0,
          top: 0,
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '40px',
          height: '40px',
        }}
      >
        <Close color={colors.blush[100]} variant="large" />
      </Box>
      <Box onClick={(e) => {
        e.stopPropagation();
      }}>
        <script src="https://js.storylane.io/js/v1/storylane.js" />
        <div
          className="sl-embed"
          style={{
            position: 'relative',
            paddingBottom: 'calc(64.88% + 2px)',
            width: '100%',
            height: 0,
            transform: 'scale(1)',
          }}
        >
          <iframe
            className="sl-demo"
            src={url}
            allow="fullscreen"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 'none',
              borderRadius: '8px',
            }}
          ></iframe>
        </div>
      </Box>
    </Dialog>
  );
};

export default PlayStorylaneModal;
