import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { getRecordTypeIdentifier } from '../../Automations/helper';
import {
  CanvasPillTypes,
  GroupLabelEntityType,
  GroupPillEntityType,
  NodeEntityTypes,
  StepEntityType,
  StepPillEntityType,
} from '../../multi-canvas/canvasTypes';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { useCanvasFilters } from '../configuration-canvas-filters/useCanvasFilters';
import { AutomationCanvasFilter } from './canvasAutomationsTypes';
import { DEDUP_OPERATIONS, MATCHING_OPERATIONS } from './dedup-and-matching/DedupMatching';
import { setLeftPanelHoveredItem } from '../../../reducers/leftPanelReducer';

const useAutomationsCanvasInteractions = () => {
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { setFilterValues } = useCanvasFilters();
  const dispatch = useDispatch();

  const setHoveredItem = useCallback(
    (automationId?: string) => {
      dispatch(setLeftPanelHoveredItem({ automationId }));
    },
    [dispatch],
  );

  const onEntityClick = useCallback(
    (props: {
      stepId?: string;
      funnelId?: string;
      recordTypeApiName?: string;
      entity: StepEntityType | StepPillEntityType | GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      const { entity, stepId, funnelId, recordTypeApiName } = props;

      if (![NodeEntityTypes.GroupPill, NodeEntityTypes.StepPill].includes(entity.type)) {
        return; //handle only pills click
      }

      const handleFilters = () => {
        let _recordTypeApiName = recordTypeApiName;
        let _objectName = _recordTypeApiName
          ? canvasFunnelMap.recordTypesData?.[_recordTypeApiName]?.objectName
          : '';
        if (!recordTypeApiName && funnelId) {
          const relevantFunnel = canvasFunnelMap.funnelsData[funnelId];
          _recordTypeApiName = getRecordTypeIdentifier(
            relevantFunnel.recordType.name,
            relevantFunnel.recordType.objectName,
          );
          _objectName = relevantFunnel.recordType.objectName;
        }
        if (_recordTypeApiName) {
          if (stepId) {
            setFilterValues({
              [AutomationCanvasFilter.OBJECTS]: _objectName ? [_objectName] : [],
              [AutomationCanvasFilter.RECORD_TYPES]: [_recordTypeApiName],
              [AutomationCanvasFilter.STEPS]: [stepId],
            });
          } else {
            setFilterValues({
              [AutomationCanvasFilter.OBJECTS]: _objectName ? [_objectName] : [],
              [AutomationCanvasFilter.RECORD_TYPES]: [_recordTypeApiName],
              [AutomationCanvasFilter.STEPS]: [],
            });
          }
        }
      };

      if ('canvasPillType' in entity) {
        switch (entity.canvasPillType) {
          case CanvasPillTypes.automation:
          case CanvasPillTypes.playbookAlert:
          case CanvasPillTypes.assignmentRule:
          case CanvasPillTypes.scheduledAssignment:
          case CanvasPillTypes.groupAutomation:
          case CanvasPillTypes.groupPlaybookAlert:
          case CanvasPillTypes.groupScheduledAssignment:
          case CanvasPillTypes.groupAssignmentRule: {
            handleFilters();
            break;
          }

          case CanvasPillTypes.groupDeduplication:
            setFilterValues({
              [AutomationCanvasFilter.OPERATIONS]: DEDUP_OPERATIONS,
            });
            break;

          case CanvasPillTypes.groupMatching:
            setFilterValues({
              [AutomationCanvasFilter.OPERATIONS]: MATCHING_OPERATIONS,
            });
            break;
          default:
            break;
        }
      }
    },
    [canvasFunnelMap, setFilterValues],
  );

  return { setHoveredItem, onEntityClick };
};

export default useAutomationsCanvasInteractions;
