import { Box } from '@mui/material';
import LayersSelector from './LayersSelector';
import {
  enableLayerFor,
  selectVisibilityMapFor,
  setDefaultVisibilityMapFor,
} from '../../../../../reducers/canvasLayersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useConfigurationCanvasContext } from '../../ConfigurationCanvasContext';
import { useRunOnceWhenTruthy } from '../../../../common/useRunOnceWhenTruthy';
import useQueryParams from '../../../../../hooks/useQueryParams';
import { VisibilityElementLayersSelector } from '../../types';
import { VisibilityLayers } from '../../../../../types/enums/VisibilityLayers';

export const ConfigurationCanvasLayersSelector = ({
  visibilityElements,
}: {
  visibilityElements: VisibilityElementLayersSelector;
}) => {
  const { layersId, visibilityLayersSelectors, defaultVisibilityMap } = visibilityElements;
  const dispatch = useDispatch();
  const visibilityMap = useSelector(selectVisibilityMapFor(layersId));
  const { setCommentsOn } = useConfigurationCanvasContext();

  const query = useQueryParams();
  const commentId = query.get('comment');

  useEffect(() => {
    dispatch(setDefaultVisibilityMapFor({ layersId, visibilityMap: defaultVisibilityMap || {} }));
  }, [defaultVisibilityMap, dispatch, layersId]);

  useEffect(() => {
    if (!visibilityMap[VisibilityLayers.COMMENTS]) {
      setCommentsOn(false);
    }
  }, [visibilityMap, dispatch, setCommentsOn, layersId]);

  useRunOnceWhenTruthy(() => {
    dispatch(
      enableLayerFor({
        layer: VisibilityLayers.COMMENTS,
        layersId: layersId,
      }),
    );
  }, Boolean(commentId));

  const layersSelector = (
    <LayersSelector
      visibilityMap={visibilityMap}
      visibilityLayersSelectors={visibilityLayersSelectors}
      position="bottom-right"
      layersId={layersId}
      buttonVariant="outlined"
    />
  );

  return (
    <Box
      sx={{
        zIndex: 100,
        position: 'absolute',
        left: 16,
        bottom: 16,
        display: 'flex',
        gap: 1,
        alignItems: 'center',
      }}
    >
      {layersSelector}
    </Box>
  );
};
