import Box from '@mui/material/Box/Box';
import { Typography, colors } from '@sweep-io/sweep-design';

export const DotDivider = ({ dotColor }: { dotColor?: string }) => (
  <Box display="flex" alignItems="center">
    <Typography variant="caption" color={dotColor ?? colors.grey[700]}>
      •
    </Typography>
  </Box>
);
