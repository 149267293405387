import { Grid, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Switch, colors } from '@sweep-io/sweep-design';
import React from 'react';
import RestrictedTooltip from '../../../../../common/permissions/RestrictedTooltip';

interface BoxWithSwitchProps {
  isDisabled?: boolean;
  renderedIconComponent: React.ReactElement;
  title: string;
  subtitle: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  checked?: boolean;
}

export const BoxWithSwitch = ({
  isDisabled,
  renderedIconComponent,
  title,
  subtitle,
  onChange,
  checked,
}: BoxWithSwitchProps) => {
  return (
    <StyledBoxWithSwitch isDisabled={isDisabled}>
      <Grid container gap="16px" flexWrap="nowrap" alignItems="flex-start">
        <StyledIcon isDisabled={isDisabled}>{renderedIconComponent}</StyledIcon>
        <ColumnBox>
          <Typography variant="body-bold">{title}</Typography>
          <Typography variant="caption" color={colors.grey[800]}>
            {subtitle}
          </Typography>
        </ColumnBox>
      </Grid>

      <RestrictedTooltip
        to={['edit:funnels:bi-settings']}
        notAllowedTitle={'To modify the BI settings, please contact your admin.'}
      >
        <Switch onChange={onChange} checked={!!checked} disabled={isDisabled} />
      </RestrictedTooltip>
    </StyledBoxWithSwitch>
  );
};

const StyledBoxWithSwitch = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDisabled' && prop !== 'showBorder',
})<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: isDisabled ? colors.grey[800] : colors.black,
}));

const ColumnBox = ({ children }: any) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', maxWidth: '80%' }}>{children}</Box>
);

const StyledIcon = ({
  children,
  isDisabled,
}: {
  children: React.ReactElement;
  isDisabled?: boolean;
}) => <Box sx={{ stroke: isDisabled ? colors.grey[700] : colors.black }}>{children}</Box>;
