import { Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { MoreActionsHorizontal } from '@sweep-io/sweep-design/dist/icons';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import { IconButton } from '@sweep-io/sweep-design';
import BoxWithDropShadow from '../../../common/BoxWithDropShadow';
import { InvitationsDialog } from '../../funnel-map-flow/dialogs/invitations/InvitationsDialog';
import { UNTITLED_FUNNEL_MAP_NAME } from '../../../../constants';

interface FunnelMapCardButtonsProps {
  openRenameDialog: Function;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isMenuOpen: boolean;
  isHovered: boolean;
  openRemoveDialogOpen: () => void;
  funnelMapName: string;
  funnelMapId: string;
}

const FunnelMapCardMenu = ({
  isMenuOpen,
  isHovered,
  setIsMenuOpen,
  openRenameDialog,
  openRemoveDialogOpen,
  funnelMapName,
  funnelMapId,
}: FunnelMapCardButtonsProps) => {
  const [isInvitationDialogOpen, setIsInvitationDialogOpen] = useState(false);
  const buttonElem = useRef<any>();
  const anchorEl = buttonElem?.current;
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setIsMenuOpen(true);
  };
  const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event?.stopPropagation();
    setIsMenuOpen(false);
  };

  const handleOpenRenameDialog = (event: React.MouseEvent<HTMLButtonElement>) => {
    setIsMenuOpen(false);
    openRenameDialog(event);
  };

  return (
    <>
      {isInvitationDialogOpen && (
        <InvitationsDialog
          isOpen={isInvitationDialogOpen}
          onClose={(event) => {
            event.stopPropagation();
            setIsInvitationDialogOpen(false);
          }}
          funnelMapName={funnelMapName ? funnelMapName : UNTITLED_FUNNEL_MAP_NAME}
          funnelMapId={funnelMapId}
        />
      )}

      <BoxWithDropShadow
        ref={buttonElem}
        sx={{
          position: 'absolute',
          zIndex: 100,
          right: '10px',
          top: '10px',
          display: isMenuOpen || isHovered ? 'block' : 'none',
        }}
      >
        <IconButton
          variant="outlined"
          size="tiny"
          onClick={openMenu}
          data-testid="funnel-map-card-menu-button"
        >
          <MoreActionsHorizontal />
        </IconButton>
      </BoxWithDropShadow>

      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        data-testid="funnel-map-card-menu"
      >
        <CardMenuItem
          text="Share"
          onClick={(event: any) => {
            event?.stopPropagation && event?.stopPropagation();
            setIsMenuOpen(false);
            setIsInvitationDialogOpen(true);
          }}
          dataTestId="funnel-map-rename-menu-item"
        />

        <CardMenuItem
          text="Edit"
          onClick={() => {
            //no event.stopPropagation here, because the propagation will trigger the card click, which will navigate to edit mode
          }}
        />
        <CardMenuItem
          text="Rename"
          onClick={(event: any) => {
            event?.stopPropagation && event?.stopPropagation();
            handleOpenRenameDialog(event);
          }}
          dataTestId="funnel-map-rename-menu-item"
        />
        <RestrictedTooltip
          to={['delete:funnel-maps']}
          notAllowedTitle="To delete this funnel, please contact your admin."
          component={(isAllowed) => (
            <CardMenuItem
              text="Remove"
              onClick={(event: any) => {
                event?.stopPropagation && event?.stopPropagation();
                setIsMenuOpen(false);
                openRemoveDialogOpen();
              }}
              dataTestId="funnel-map-remove-menu-item"
              disabled={!isAllowed}
            />
          )}
        ></RestrictedTooltip>
      </Menu>
    </>
  );
};

export default FunnelMapCardMenu;

const CardMenuItem = ({
  onClick,
  text,
  disabled,
  dataTestId,
}: {
  onClick: (event: any) => void;
  text: string;
  disabled?: boolean;
  dataTestId?: string;
}) => {
  return (
    <MenuItem
      disableRipple
      disableGutters
      disabled={disabled}
      onClick={onClick}
      sx={{
        width: '206px',
      }}
      data-testid={dataTestId}
    >
      {text}
    </MenuItem>
  );
};
