import * as React from "react";
const SvgTiming = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E1EBFF", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.9307 7.95649C5.93246 8.34308 6.24728 8.65648 6.63388 8.65648H9.93983L9.98501 18.5827C9.98677 18.9693 10.3016 19.2827 10.6882 19.2827H13.3964C13.783 19.2827 14.095 18.9693 14.0933 18.5827L14.0481 8.65648H17.3352C17.7218 8.65648 18.0338 8.34308 18.032 7.95649L18.0205 5.41748C18.0187 5.03089 17.7039 4.71749 17.3173 4.71749H6.61595C6.22936 4.71749 5.91738 5.03089 5.91914 5.41748L5.9307 7.95649Z", fill: "#577EE4", stroke: "white", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1,
  stroke: "white",
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.4, strokeLinejoin: "round" }));
export default SvgTiming;
