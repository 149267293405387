import { createContext, useCallback, useContext, useState } from 'react';
import { useRoleManagerContext } from '../role-manager/RoleManagerContext';

interface UserDialogContextType {
  chosenTab?: string;
  setChosenTab: (tab: string | undefined) => void;
}
const UserDialogContext = createContext<UserDialogContextType>({
  setChosenTab: () => {},
});

export const UserDialogProvider = ({ children }: { children: React.ReactNode }) => {
  const [chosenTab, setChosenTab] = useState<string>();

  return (
    <UserDialogContext.Provider
      value={{
        chosenTab: chosenTab,
        setChosenTab: setChosenTab,
      }}
    >
      {children}
    </UserDialogContext.Provider>
  );
};

export const useUserDialogContext = () => {
  const { setChosenTab, chosenTab } = useContext(UserDialogContext);
  const { setRoleManager } = useRoleManagerContext();

  const openDialog = useCallback(
    (chosenTab: string) => {
      setChosenTab(chosenTab);
    },
    [setChosenTab],
  );

  const closeDialog = useCallback(() => {
    setChosenTab(undefined);
    setRoleManager(false);
  }, [setChosenTab, setRoleManager]);

  return {
    chosenTab,
    setChosenTab,
    openDialog,
    closeDialog,
    isOpen: chosenTab !== undefined,
  };
};

export { UserDialogContext };
