import { NavigateOptions, useLocation, useNavigate } from 'react-router';
import { useCallback } from 'react';
import { appRoutes } from '../../constants/appRoutes';
import useQueryParams from '../../hooks/useQueryParams';
import isNil from 'lodash/isNil';

interface SweepNavigateOptions extends NavigateOptions {
  keepSearch?: boolean;
}

const useSweepNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = useQueryParams();

  const goBack = useCallback(() => {
    const { state } = location;
    let destinationPath: string | undefined = undefined;
    if (state) {
      Object.entries(state).forEach((entry) => {
        const [key, value] = entry as [string, string];
        if (key === 'from') {
          destinationPath = value;
        }
      });
    }

    navigate(destinationPath ?? appRoutes.home.route);
  }, [location, navigate]);

  //"destination" can include query params.
  // Current/existing query params will be removed, unless "keepSearch: true" and then they will be merged.
  const navigateWithHistory = useCallback(
    (destination: string, options?: SweepNavigateOptions) => {
      const { keepSearch, ...navigateOptions } = options ?? {};
      const _destination = keepSearch
        ? keepSearchDestination(destination, searchParams)
        : destination;

      return navigate(_destination, {
        state: { from: location.pathname + location.search },
        ...navigateOptions,
      });
    },
    [navigate, location, searchParams],
  );
  const removeQueryParams = useCallback(
    (paramsToDelete: string[]) => {
      const updatedSearchParams = new URLSearchParams(location.search);
      paramsToDelete.forEach((param) => {
        if (!isNil(updatedSearchParams.get(param))) {
          updatedSearchParams.delete(param);
        }
      });
      const target = location.pathname + '?' + updatedSearchParams.toString();
      navigate(target);
    },
    [location.pathname, location.search, navigate],
  );

  return { goBack, navigate: navigateWithHistory, removeQueryParams };
};

export default useSweepNavigate;

const keepSearchDestination = (destination: string, searchParams: URLSearchParams) => {
  const destinationSplit = destination.split('?');
  const destinationPath = destinationSplit[0];
  const destinationQuery = destinationSplit[1];
  const destinationParams = new URLSearchParams(destinationQuery);
  const mergedQueryString = getMergedQueryString(destinationParams, searchParams);
  return `${destinationPath}${mergedQueryString}`;
};

const getMergedQueryString = (
  destinationParams: URLSearchParams,
  currentParams: URLSearchParams,
) => {
  const mergedParams = new URLSearchParams();
  destinationParams.forEach((value: string, key: string) => {
    mergedParams.set(key, value);
  });
  currentParams.forEach((value: string, key: string) => {
    mergedParams.set(key, value);
  });
  return '?' + mergedParams.toString();
};
