export enum VisibilityLayers {
  SWEEP_AUTOMATIONS = 'AUTOMATIONS',
  NURTURING_STEPS = 'NURTURING',
  GATES = 'GATES',
  VALIDATION_RULES = 'VALIDATION',
  SFDC_AUTOMATIONS = 'SFDC_AUT',
  APEX = 'APEX',
  COMMENTS = 'COMMENTS',
  PLAYBOOK_ALERTS = 'ALERTS',
  ASSIGNMENT_RULES = 'ASSIGNMENT',
  SCHEDULED_ASSIGNMENTS = 'SCHEDULED_ASSIGNMENTS',
  // Funnel pills
  FUNNEL_SFDC_AUTOMATIONS = 'F_SFDC_AUT',
  FUNNEL_VALIDATION_RULES = 'F_VALIDATION',
  FUNNEL_APEX = 'F_APEX',
  FUNNEL_ASSIGNMENT_RULES = 'F_ASSIGNMENT',
  FUNNEL_DEDUPLICATION = 'F_DEDUP',
  FUNNEL_MATCHING = 'F_MATCH',
  FUNNEL_SWEEP_AUTOMATIONS = 'F_AUTOMATIONS',
  FUNNEL_PLAYBOOK_ALERTS = 'F_ALERTS',
  FUNNEL_MATCHING_AND_DEDUPLICATION = 'F_MATCH_DEDUP',
  FUNNEL_HUBSPOT_ENROLLMENTS = 'F_HUBSPOT_ENROLLMENTS',
}
