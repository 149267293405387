import * as React from "react";
const SvgBant = (props) => /* @__PURE__ */ React.createElement("svg", { width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#A5BEFF", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M26.7442 33.4394C26.7442 33.7135 26.6353 33.9764 26.4415 34.1703C26.2476 34.3641 25.9847 34.473 25.7106 34.473H15.374C15.0998 34.473 14.8369 34.3641 14.6431 34.1703C14.4492 33.9764 14.3403 33.7135 14.3403 33.4394C14.3403 33.1652 14.4492 32.9023 14.6431 32.7085C14.8369 32.5146 15.0998 32.4057 15.374 32.4057H25.7106C25.9847 32.4057 26.2476 32.5146 26.4415 32.7085C26.6353 32.9023 26.7442 33.1652 26.7442 33.4394ZM31.9125 16.9009C31.917 18.624 31.5277 20.3254 30.7744 21.8752C30.0211 23.425 28.9237 24.7822 27.566 25.8433C27.3121 26.0379 27.1061 26.288 26.9637 26.5744C26.8212 26.8608 26.7462 27.176 26.7442 27.4959V28.2711C26.7442 28.8194 26.5264 29.3452 26.1387 29.7329C25.751 30.1206 25.2252 30.3384 24.6769 30.3384H16.4076C15.8594 30.3384 15.3335 30.1206 14.9458 29.7329C14.5581 29.3452 14.3403 28.8194 14.3403 28.2711V27.4959C14.3401 27.1798 14.2674 26.868 14.1279 26.5845C13.9884 26.3009 13.7857 26.0531 13.5354 25.8601C12.181 24.8053 11.0844 23.4563 10.3284 21.915C9.57247 20.3738 9.17704 18.6808 9.17204 16.9642C9.13845 10.8062 14.1155 5.67794 20.2684 5.53064C21.7844 5.49411 23.2924 5.76122 24.7037 6.31625C26.115 6.87127 27.401 7.70301 28.486 8.76249C29.571 9.82197 30.4331 11.0878 31.0216 12.4855C31.61 13.8831 31.9129 15.3844 31.9125 16.9009ZM29.8452 16.9009C29.8456 15.66 29.5977 14.4317 29.1162 13.2881C28.6347 12.1445 27.9292 11.1088 27.0414 10.2419C26.1536 9.37508 25.1014 8.69459 23.9466 8.24051C22.7918 7.78644 21.5579 7.56797 20.3175 7.59796C15.2784 7.71683 11.2122 11.9122 11.2394 16.9513C11.2441 18.3552 11.5681 19.7397 12.1868 21C12.8055 22.2602 13.7027 23.3633 14.8106 24.2256C15.3087 24.6128 15.7115 25.1088 15.9883 25.6756C16.2651 26.2424 16.4086 26.8651 16.4076 27.4959V28.2711H24.6769V27.4959C24.6783 26.8633 24.8242 26.2393 25.1035 25.6717C25.3827 25.1041 25.7879 24.6077 26.2881 24.2205C27.3994 23.352 28.2976 22.241 28.9141 20.9725C29.5306 19.7039 29.849 18.3113 29.8452 16.9009Z", fill: "#343330", style: {
  fill: "#343330",
  fill: "color(display-p3 0.2039 0.2000 0.1882)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16.4423 28.2706C16.4423 23.6503 14.0958 25.1639 12.1882 21.0023L12.1868 20.9994C11.5691 19.7412 11.2452 18.3592 11.2394 16.9576C11.2394 16.9559 11.2394 16.9541 11.2394 16.9524L11.2394 16.9507C11.2123 11.9117 15.2784 7.7163 20.3175 7.59743C21.5577 7.56754 22.7921 7.786 23.9466 8.23998C25.1013 8.69405 26.1537 9.3746 27.0414 10.2414C27.9291 11.1081 28.6346 12.1438 29.1161 13.2872C29.5975 14.4306 29.8455 15.6591 29.8452 16.8997C29.8452 16.9015 29.8452 16.9039 29.8452 16.9057C29.8483 18.3138 29.53 19.7041 28.9147 20.9708L28.9141 20.972C28.3016 22.2324 27.4109 23.3373 26.3093 24.2033C26.3043 24.2073 26.2992 24.2113 26.2941 24.2153C26.2921 24.2168 26.2901 24.2184 26.2882 24.2199C26.1203 24.3498 25.9632 24.492 25.8179 24.6451C25.7814 24.6836 25.7456 24.7228 25.7106 24.7626C25.4742 25.0316 25.2729 25.3308 25.1124 25.6533C25.1094 25.6592 25.1064 25.6652 25.1035 25.6712C24.9407 26.0021 24.8233 26.3521 24.7533 26.7118C24.7488 26.7352 24.7444 26.7586 24.7403 26.7821C24.6995 27.0127 24.6782 27.2471 24.677 27.4827C24.677 27.4869 24.6769 27.4911 24.6769 27.4953V28.2706", fill: "#FFD28D", style: {
  fill: "#FFD28D",
  fill: "color(display-p3 1.0000 0.8217 0.5542)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M25.153 19.2543L25.153 13.9337C25.153 13.8101 25.1287 13.6877 25.0815 13.5735C25.0342 13.4593 24.9649 13.3555 24.8775 13.2681C24.7901 13.1807 24.6863 13.1114 24.5721 13.0641C24.4579 13.0169 24.3355 12.9926 24.2119 12.9926L18.8913 12.9926C18.6417 12.9926 18.4024 13.0918 18.2259 13.2683C18.0494 13.4447 17.9502 13.6841 17.9502 13.9337C17.9502 14.1833 18.0494 14.4227 18.2259 14.5991C18.4024 14.7756 18.6417 14.8748 18.8913 14.8748L21.9415 14.8739L11.413 25.4024C11.2366 25.5788 11.1375 25.8181 11.1375 26.0675C11.1375 26.317 11.2366 26.5562 11.413 26.7326C11.5894 26.909 11.8286 27.0081 12.0781 27.0081C12.3275 27.0081 12.5668 26.909 12.7431 26.7326L23.2716 16.2041L23.2708 19.2543C23.2708 19.5039 23.37 19.7432 23.5464 19.9197C23.7229 20.0962 23.9623 20.1953 24.2119 20.1953C24.4615 20.1953 24.7008 20.0962 24.8773 19.9197C25.0538 19.7432 25.153 19.5039 25.153 19.2543Z", fill: "#343330", style: {
  fill: "#343330",
  fill: "color(display-p3 0.2039 0.2000 0.1882)",
  fillOpacity: 1
} }));
export default SvgBant;
