import { SimpleFieldSelector, SimpleFieldSelectorProps } from './SimpleFieldSelector';

type LeadingFieldSelectorProps = Omit<
  SimpleFieldSelectorProps,
  'groupByLeadingCandidates' | 'autoSelectDefaultFieldForObjectType'
>;

const excludeCompoundFieldsFilterAndNonPicklist = (field: SweepField) =>
  field.fieldType === 'Picklist' && !field.isCompound;

export const LeadingFieldSelector = (props: LeadingFieldSelectorProps) => {
  return (
    <SimpleFieldSelector
      {...props}
      groupByLeadingCandidates
      showApiNameTooltip
      autoSelectDefaultFieldForObjectType
      filterBy={excludeCompoundFieldsFilterAndNonPicklist}
    />
  );
};
