import { createContext, useState } from 'react';
import { ReactNode } from 'react';
import { DocumentationContextType } from './types';
import { HoveredPin } from '../types';

export const DocumentationDialogContext = createContext<DocumentationContextType>({
  objectNamesUsedInCanvas: [],
  stepNamesUsedInCanvas: [],
  recordTypesUsedInCanvas: {},
  filterValues: {},
});

export const DocumentationContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: DocumentationContextType;
}) => {
  const {
    objectNamesUsedInCanvas,
    stepNamesUsedInCanvas,
    recordTypesUsedInCanvas,
    filterValues,
    funnelsData,
    recordTypesData,
  } = value;

  const [hoveredPin, setHoveredPin] = useState<HoveredPin | undefined>();

  return (
    <DocumentationDialogContext.Provider
      value={{
        objectNamesUsedInCanvas,
        stepNamesUsedInCanvas,
        recordTypesUsedInCanvas,
        filterValues,
        hoveredPin,
        funnelsData,
        recordTypesData,
        setHoveredPin,
      }}
    >
      {children}
    </DocumentationDialogContext.Provider>
  );
};
