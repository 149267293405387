import { Box } from '@mui/material';
import { UserInfo } from '../../types/UserInfoTypes';
import { AvatarPicture } from '../avatar/AvatarPicture';
import { getIsUserInactive } from '../../lib/getIsUserActive';

interface NewReplySuggestionItemProps {
  userInfo: UserInfo;
}

export const NewReplySuggestionItem = ({ userInfo }: NewReplySuggestionItemProps) => {
  const { emoji, imageUrl } = userInfo;
  return (
    <Box display="flex" alignItems="center" gap={1}>
      <AvatarPicture
        avatar={{ emoji, imageUrl }}
        dimension={32}
        isInactive={getIsUserInactive(userInfo?.status)}
      />
      <div>{!!userInfo.name ? userInfo.name : userInfo.email}</div>
    </Box>
  );
};
