import { ConfigurationType } from '../dependencies/types';

export enum ActiveState {
  activeOnly = 'activeOnly',
  activeAndInactive = 'activeAndInactive',
}

export const CONFIGURATION_ITEMS_WITH_ACTIVITY_STATE = [
  ConfigurationType.recordTypes,
  ConfigurationType.approvalProcesses,
  ConfigurationType.flows,
  ConfigurationType.processBuilderFlows,
  ConfigurationType.workflowRules,
  ConfigurationType.validationRules,
];

export const activeFilterOptions = [
  { label: 'Only active', value: ActiveState.activeOnly },
  { label: 'Active and inactive', value: ActiveState.activeAndInactive },
];
