import { DefaultEnvConnectionErrorPage } from './DefaultEnvConnectionErrorPage';
import { EnvConnectionError, SweepError } from './envConnectionErrorTypes';
import { SfEnvConnectionErrorPage } from './SfEnvConnectionErrorPage';
import { RequestLimitEnvConnectionErrorPage } from './RequestLimitEnvConnectionErrorPage';

export const EnvConnectionErrorPage = ({
  envConnectionError,
  allowDeleteLastEnv,
}: {
  envConnectionError: EnvConnectionError;
  allowDeleteLastEnv: boolean;
}) => {
  const { error } = envConnectionError;

  if (error === SweepError.CrmOrgBadCredentials) {
    return (
      <SfEnvConnectionErrorPage
        envConnectionError={envConnectionError}
        allowDeleteLastEnv={allowDeleteLastEnv}
      />
    );
  }
  if (
    [
      SweepError.RequestLimitExceededTotalRequests,
      SweepError.RequestLimitExceededConcurrentRequests,
    ].includes(error)
  ) {
    return <RequestLimitEnvConnectionErrorPage />;
  }

  return <DefaultEnvConnectionErrorPage />;
};
