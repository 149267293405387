import { Box } from '@mui/material';
import { SelectAvatar } from '../../../../avatar/SelectAvatar';
import { useGroupSettingsContext } from '../GroupSettingsContext';
import { assignmentGroupEmojis } from '../../groups/assignmentGroupEmojis';
import { AvatarType } from '../../../../avatar/avatarTypes';
import { useCallback } from 'react';
import { Typography } from '@sweep-io/sweep-design';

const AVATAR_SIZE = 40;

export const GroupSettingsAvatar = ({ title }: { title: string }) => {
  const { assignmentGroup, setAssignmentGroup } = useGroupSettingsContext();

  const handleAvatarChange = useCallback(
    (avatar: AvatarType) => {
      setAssignmentGroup((localGroupData) => ({
        ...localGroupData,
        avatar,
      }));
    },
    [setAssignmentGroup],
  );

  return (
    <Box display="flex" gap="12px" alignItems="center">
      <Box sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
        <SelectAvatar
          avatar={assignmentGroup.avatar}
          avatarPictureSize={AVATAR_SIZE}
          avatarEmojiSize={AVATAR_SIZE}
          emojis={assignmentGroupEmojis}
          onChange={handleAvatarChange}
          disableImageUpload
        />
      </Box>
      <Typography variant="h1">{title}</Typography>
    </Box>
  );
};
