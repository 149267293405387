import * as React from "react";
const SvgNotify = (props) => /* @__PURE__ */ React.createElement("svg", { width: 65, height: 36, viewBox: "0 0 65 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M13.3332 14V29.4625C13.3348 29.6287 13.2943 29.7926 13.2157 29.939C13.137 30.0854 13.0227 30.2095 12.8832 30.3L11.5082 31.2125C11.375 31.3014 11.2224 31.3571 11.0632 31.3747C10.904 31.3924 10.7429 31.3716 10.5935 31.314C10.444 31.2564 10.3106 31.1638 10.2044 31.0439C10.0982 30.924 10.0223 30.7803 9.98324 30.625L8.33324 24", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M8.33325 23.9998C7.00717 23.9998 5.7354 23.473 4.79772 22.5353C3.86004 21.5976 3.33325 20.3259 3.33325 18.9998C3.33325 17.6737 3.86004 16.4019 4.79772 15.4643C5.7354 14.5266 7.00717 13.9998 8.33325 13.9998H13.3333C13.3333 13.9998 20.1458 13.9998 26.6958 8.51229C26.8414 8.39173 27.0183 8.31496 27.2059 8.29086C27.3934 8.26677 27.584 8.29635 27.7554 8.37618C27.9268 8.456 28.0721 8.5828 28.1744 8.74185C28.2767 8.9009 28.3318 9.0857 28.3333 9.27479V28.7248C28.3318 28.9139 28.2767 29.0987 28.1744 29.2577C28.0721 29.4168 27.9268 29.5436 27.7554 29.6234C27.584 29.7032 27.3934 29.7328 27.2059 29.7087C27.0183 29.6846 26.8414 29.6078 26.6958 29.4873C20.1458 23.9998 13.3333 23.9998 13.3333 23.9998H8.33325Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("rect", { x: 28.3333, width: 24, height: 24, rx: 12, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_338_11157)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M37.8521 8.2959H33.596C32.7145 8.2959 31.9999 9.01046 31.9999 9.89196C31.9999 10.7735 32.7145 11.488 33.596 11.488H37.8521C38.7336 11.488 39.4482 10.7735 39.4482 9.89196C39.4482 9.01046 38.7336 8.2959 37.8521 8.2959Z", fill: "#36C5F0", style: {
  fill: "#36C5F0",
  fill: "color(display-p3 0.2118 0.7725 0.9412)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M43.7044 9.89177V5.63561C43.7044 4.75411 42.9898 4.03955 42.1083 4.03955C41.2268 4.03955 40.5122 4.75411 40.5122 5.63561V9.89177C40.5122 10.7733 41.2268 11.4878 42.1083 11.4878C42.9898 11.4878 43.7044 10.7733 43.7044 9.89177Z", fill: "#2EB67D", style: {
  fill: "#2EB67D",
  fill: "color(display-p3 0.1804 0.7137 0.4902)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.1083 15.7434H46.3645C47.246 15.7434 47.9605 15.0288 47.9605 14.1473C47.9605 13.2658 47.246 12.5513 46.3645 12.5513H42.1083C41.2268 12.5513 40.5122 13.2658 40.5122 14.1473C40.5122 15.0288 41.2268 15.7434 42.1083 15.7434Z", fill: "#ECB22E", style: {
  fill: "#ECB22E",
  fill: "color(display-p3 0.9255 0.6980 0.1804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36.2561 14.1473V18.4035C36.2561 19.285 36.9706 19.9995 37.8521 19.9995C38.7336 19.9995 39.4482 19.285 39.4482 18.4035V14.1473C39.4482 13.2658 38.7336 12.5513 37.8521 12.5513C36.9706 12.5513 36.2561 13.2658 36.2561 14.1473Z", fill: "#E01E5A", style: {
  fill: "#E01E5A",
  fill: "color(display-p3 0.8784 0.1176 0.3529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M37.8521 4.03955C36.9706 4.03955 36.2561 4.75411 36.2561 5.63561C36.2561 6.51711 36.9706 7.23167 37.8521 7.23167H39.4482V5.63561C39.4482 4.75411 38.7336 4.03955 37.8521 4.03955Z", fill: "#36C5F0", style: {
  fill: "#36C5F0",
  fill: "color(display-p3 0.2118 0.7725 0.9412)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M47.9605 9.89196C47.9605 9.01046 47.2459 8.2959 46.3644 8.2959C45.4829 8.2959 44.7684 9.01046 44.7684 9.89196V11.488H46.3644C47.2459 11.488 47.9605 10.7735 47.9605 9.89196Z", fill: "#2EB67D", style: {
  fill: "#2EB67D",
  fill: "color(display-p3 0.1804 0.7137 0.4902)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.1083 19.9997C42.9898 19.9997 43.7044 19.2852 43.7044 18.4037C43.7044 17.5222 42.9898 16.8076 42.1083 16.8076H40.5122V18.4037C40.5122 19.2852 41.2268 19.9997 42.1083 19.9997Z", fill: "#ECB22E", style: {
  fill: "#ECB22E",
  fill: "color(display-p3 0.9255 0.6980 0.1804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.9999 14.1473C31.9999 15.0288 32.7145 15.7434 33.596 15.7434C34.4775 15.7434 35.192 15.0288 35.192 14.1473V12.5513H33.596C32.7145 12.5513 31.9999 13.2658 31.9999 14.1473Z", fill: "#E01E5A", style: {
  fill: "#E01E5A",
  fill: "color(display-p3 0.8784 0.1176 0.3529)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_338_11157" }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, transform: "translate(31.9999 4)" }))));
export default SvgNotify;
