import isNil from 'lodash/isNil';
import {
  FunnelField,
  NumberLikeFieldTypes,
  TextLikeFieldTypes,
} from '../../../../../constants/fieldsManagementConsts';

export const isNumberLikeFieldType = (type?: SweepFieldTypes) => {
  if (!type) return false;
  return NumberLikeFieldTypes.includes(type);
};

export const isTextLikeFieldType = (type?: SweepFieldTypes) => {
  if (!type) return false;
  return TextLikeFieldTypes.includes(type);
};

const isValidNumber = (val?: any) => !isNil(val) && Number(val) === val;

const isRegularNumber = (value: string) => {
  const valueAsNumber = Number(value);
  return !isNaN(valueAsNumber);
};

// converting from "form values" to "field"
export const formatNumberPropsToField = (
  properties: SweepFieldProperties,
): SweepFieldProperties => {
  const { length, scale } = properties ?? {};
  const formattedProps = { ...properties };

  const isValidNumbers = isValidNumber(length) && isValidNumber(scale);
  if (isValidNumbers) {
    formattedProps.precision = Number(length) + Number(scale); //UI is showing "length" and "scale" - precision is a calculated value
    delete formattedProps.length;
  }
  return formattedProps;
};

// converting from "field" to "form values"
export const formatNumberPropsToValues = (
  properties: SweepFieldProperties,
): SweepFieldProperties => {
  const { scale, precision, defaultValueFormula } = properties ?? {};
  const formattedProps = { ...properties };

  const isValidNumbers = isValidNumber(precision) && isValidNumber(scale);
  if (isValidNumbers) {
    formattedProps.length = Number(precision) - Number(scale); //UI is showing "length" and "scale" (precision is a calculated value)
  }
  if (defaultValueFormula && isRegularNumber(defaultValueFormula)) {
    formattedProps.defaultValue = Number(defaultValueFormula);
    delete formattedProps.defaultValueFormula;
  }
  return formattedProps;
};

// Comparison logic : according to product, leading field values can only be reordered and removed
// (can't rename or add values - blocked by the UI)
export const isLeadingFieldPicklistOrderChanged = (
  initialField: Partial<FunnelField>,
  finalField: Partial<SweepField | FunnelField>,
) => {
  const initialValues = initialField.properties?.valueSet?.map((vs) => vs.fullName);
  const finalValues = finalField.properties?.valueSet?.map((vs) => vs.fullName);
  const initialWithoutDeleted = initialValues?.filter((v) => finalValues?.includes(v));
  return initialWithoutDeleted?.some((v, i) => v !== finalValues?.[i]);
};
