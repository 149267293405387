import { useCallback, useState } from 'react';
import { VisibilityLayers } from '../../../../../types/enums/VisibilityLayers';
import { WithConfigurationCanvas } from '../../../configuration-canvas/WithConfigurationCanvas';
import { VisibilityElementLayersSelector } from '../../../configuration-canvas/types';
import { CanvasUnitedFlow } from './CanvasUnitedFlow';
import {
  CanvasPillTypes,
  GroupLabelEntityType,
  GroupPillEntityType,
  NodeEntityTypes,
  StepEntityType,
  StepPillEntityType,
  SweepCanvasEntities,
} from '../../../../multi-canvas/canvasTypes';
import { usePanels } from '../../../../panels/PanelsContext';
import { documentationPanelType } from '../../../../documentation/types';
import { Box } from '@mui/material';
import { DEFAULT_LAYERS_ID } from '../../../../../reducers/canvasLayersReducer';
import useAutomationsCanvasInteractions from '../../useAutomationsCanvasInteractions';
import { automationsPanelType, playbookAlertsPanelType } from '../../../../Automations/panelTypes';
import SweepStagesModel from '../../../../../models/stagesModel';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';
import { useDispatch } from 'react-redux';
import { stagePanelType } from '../../../funnel-map-flow/dialogs/stage/panelType';
import {
  closeStageDialog,
  openStageDialog,
} from '../../../../../reducers/funnel-flow-page/stageDialogReducer';
import { StageDialogTabTypes } from '../../../../../types/enums/StageDialogTabTypes';
import { funnelPanelType } from '../../../funnel-map-flow/dialogs/funnel-panel/funnelPanelTypes';
import { gatePanelType } from '../../../funnel-map-flow/dialogs/gates/gatePanelType';
import { useDocumentationCanvasInteractions } from '../../../../documentation/useCanvasDocumentationInteractions';
import { useCanvasFilters } from '../../../configuration-canvas-filters/useCanvasFilters';
import { useInitializeObjectsStructure } from '../../../../documentation/hooks/useInitializeObjectsStructure';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';

const visibilityElementsLayerSelector: VisibilityElementLayersSelector = {
  type: 'layersSelector',
  layersId: DEFAULT_LAYERS_ID,
  visibilityLayersSelectors: {
    [VisibilityLayers.SWEEP_AUTOMATIONS]: true,
    [VisibilityLayers.NURTURING_STEPS]: true,
    [VisibilityLayers.COMMENTS]: true,
    [VisibilityLayers.VALIDATION_RULES]: true,
    [VisibilityLayers.SFDC_AUTOMATIONS]: true,
    [VisibilityLayers.APEX]: true,
    [VisibilityLayers.PLAYBOOK_ALERTS]: true,
    [VisibilityLayers.ASSIGNMENT_RULES]: true,
    [VisibilityLayers.FUNNEL_MATCHING_AND_DEDUPLICATION]: true,
    [VisibilityLayers.GATES]: true,
  },
  defaultVisibilityMap: {
    [VisibilityLayers.NURTURING_STEPS]: true,
    [VisibilityLayers.COMMENTS]: true,
    [VisibilityLayers.SFDC_AUTOMATIONS]: true,
    [VisibilityLayers.APEX]: true,
    [VisibilityLayers.VALIDATION_RULES]: true,
    [VisibilityLayers.GATES]: true,
  },
};

const documentationPills = [
  CanvasPillTypes.apexTrigger,
  CanvasPillTypes.groupApex,
  CanvasPillTypes.sfdcAutomation,
  CanvasPillTypes.groupSfdcAutomation,
  CanvasPillTypes.validationRule,
  CanvasPillTypes.groupValidationRule,
  CanvasPillTypes.groupDeduplication,
  CanvasPillTypes.groupMatching,
  CanvasPillTypes.assignmentRule,
  CanvasPillTypes.groupAssignmentRule,
  CanvasPillTypes.scheduledAssignment,
  CanvasPillTypes.groupScheduledAssignment,
];

const automationPills = [CanvasPillTypes.automation, CanvasPillTypes.groupAutomation];
const alertsPills = [CanvasPillTypes.playbookAlert, CanvasPillTypes.groupPlaybookAlert];

const isDocumentationPill = (entity: SweepCanvasEntities) => {
  if ('canvasPillType' in entity) {
    return documentationPills.includes(entity.canvasPillType);
  }
  return false;
};

const isAutomationsPill = (entity: SweepCanvasEntities) => {
  if ('canvasPillType' in entity) {
    return automationPills.includes(entity.canvasPillType);
  }
  return false;
};

const isAlertsPill = (entity: SweepCanvasEntities) => {
  if ('canvasPillType' in entity) {
    return alertsPills.includes(entity.canvasPillType);
  }
  return false;
};

const useOpenStepDialog = () => {
  const dispatch = useDispatch();
  const { maybeOpenPanel } = usePanels();
  const { canvasFunnelMap } = useConfigurationCanvas();

  const openStepDialog = useCallback(
    ({ stepId, funnelId }: { stepId: string; funnelId: string }) => {
      const funnelDetails = canvasFunnelMap.funnelsData[funnelId]?.funnelDetails;
      const stagesModel = new SweepStagesModel(funnelDetails?.stages || []);
      const stage = stagesModel.stageByIdOrUndefined(stepId)?.getStage();

      if (stage) {
        maybeOpenPanel({
          panelType: stagePanelType,
          instanceId: stage._stageId,
          onOpen: () => {
            dispatch(
              openStageDialog({
                stageId: stage._stageId,
                activeTab: StageDialogTabTypes.GATES,
                funnelId: funnelId,
              }),
            );
          },
          onClose: () => {
            dispatch(closeStageDialog());
          },
        });
      }
    },
    [canvasFunnelMap.funnelsData, dispatch, maybeOpenPanel],
  );
  return {
    openStepDialog,
  };
};

export const CanvasUnitedConfigurationPage = () => {
  const { maybeOpenPanel } = usePanels();
  const { canvasCrmOrgId, canvasFunnelMap } = useConfigurationCanvas();
  const { setFilterValues, focusOnCanvasElements } = useCanvasFilters();

  const { objectTypes } = useObjectTypesWithFetch({ crmOrgId: canvasCrmOrgId });
  const { openStepDialog } = useOpenStepDialog();
  const { onEntityClick: onAutomationsEntityClick } = useAutomationsCanvasInteractions();
  const { onDocumentationEntityClick } = useDocumentationCanvasInteractions({
    focusOnCanvasElements,
    setFilterValues,
    onSweepElementClick: onAutomationsEntityClick,
    crmOrgId: canvasCrmOrgId,
  });

  //required for documentation filters
  useInitializeObjectsStructure({
    funnelsData: canvasFunnelMap.funnelsData,
    recordTypesData: canvasFunnelMap.recordTypesData,
    crmOrgId: canvasCrmOrgId,
    objectTypes,
  });

  const [selectedGateId, setSelectedGateId] = useState<string>();

  const onEntityClick = useCallback(
    ({
      entity,
      event,
      funnelId,
      stepId,
    }: {
      stepId?: string;
      funnelId: string;
      entity: StepEntityType | StepPillEntityType | GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      if (entity.type === NodeEntityTypes.Step && stepId && funnelId) {
        openStepDialog({ stepId, funnelId });
      }

      if (entity.type === NodeEntityTypes.GroupLabel) {
        maybeOpenPanel({
          panelType: funnelPanelType,
          data: {
            funnelId,
          },
        });
      }
      if (isDocumentationPill(entity)) {
        maybeOpenPanel({
          panelType: documentationPanelType,
          onOpen: () => {
            onDocumentationEntityClick({
              stepId: stepId,
              funnelId: funnelId,
              entity: entity,
              event: event,
              funnelsData: canvasFunnelMap.funnelsData,
              recordTypesData: canvasFunnelMap.recordTypesData,
            });
          },
        });
      }
      if (isAutomationsPill(entity)) {
        maybeOpenPanel({
          panelType: automationsPanelType,
          onOpen: () => {
            setTimeout(() => {
              onAutomationsEntityClick({
                stepId: stepId,
                funnelId: funnelId,
                entity: entity,
                event: event,
              });
            }, 0); // Quick fix. TODO: pass this as arguments to the panel
          },
        });
      }
      if (isAlertsPill(entity)) {
        maybeOpenPanel({
          panelType: playbookAlertsPanelType,
          onOpen: () => {
            setTimeout(() => {
              onAutomationsEntityClick({
                stepId: stepId,
                funnelId: funnelId,
                entity: entity,
                event: event,
              });
            }, 0);
          },
        });
      }
    },
    [
      maybeOpenPanel,
      onAutomationsEntityClick,
      onDocumentationEntityClick,
      openStepDialog,
      canvasFunnelMap,
    ],
  );

  const onStageGateClick = useCallback(
    (funnelId: string, stageId: string, exitCriteriaId: string) => {
      maybeOpenPanel({
        panelType: gatePanelType,
        onOpen: () => {
          setSelectedGateId(exitCriteriaId);
        },
        onClose: () => {
          setSelectedGateId(undefined);
        },
        data: {
          funnelId,
          stageId,
          exitCriteriaId,
        },
      });
    },
    [maybeOpenPanel],
  );

  return (
    <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, height: '100%' }}>
      <WithConfigurationCanvas
        visibilityElements={visibilityElementsLayerSelector}
        onFunnelStepClick={onEntityClick}
        onFunnelLabelClick={onEntityClick}
        onFunnelLabelPillClick={onEntityClick}
        onStageGateClick={onStageGateClick}
        selectedGateId={selectedGateId}
      >
        <CanvasUnitedFlow />
      </WithConfigurationCanvas>
    </Box>
  );
};
