import CopilotButton from './CopilotButton';
import useCopilotChats from '../../copilotChats/useCopilotChats';

const CopilotChatDashboardButton = () => {
  const { toggleIsOpened, isOpened } = useCopilotChats();

  return (
    <CopilotButton
      onClick={() => {
        if (!isOpened) {
          toggleIsOpened();
        }
      }}
      variant="primary"
    />
  );
};

export default CopilotChatDashboardButton;
