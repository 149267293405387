import { Typography } from '@mui/material';
import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';
import { humanizeDateVariants, humanizeDate } from '../../../../helpers/humanizeDate';

export const RestoreDialog = ({
  funnelName,
  versionName,
  versionDate,
  onConfirm,
  closeDialog,
}: {
  funnelName?: string;
  versionName?: string;
  versionDate: string;
  onConfirm: any;
  closeDialog?: (event: any) => any;
}) => {
  return (
    <ConfirmDialog
      isOpen={true}
      title="Restore this version?"
      confirmText="Restore"
      onConfirm={onConfirm}
      handleClose={closeDialog}
    >
      <Typography>
        {funnelName} funnel will be restored to this version: {versionName} from{' '}
        {humanizeDate({
          dateOrTimestamp: versionDate,
          variant: humanizeDateVariants.SHORT_WITH_TIME,
        })}
      </Typography>
      <Typography>Current version of this funnel will be saved into version history.</Typography>
    </ConfirmDialog>
  );
};
