import {
  Apex,
  Automations,
  Gate,
  Nurturing,
  PlaybookAlert,
  SalesforceAutomations,
  UnreadComment,
  Assign,
  ValidationRules as ValidationRulesIcon,
  Target,
  Clock,
} from '@sweep-io/sweep-design/dist/icons';
import { VisibilityLayers } from '../types/enums/VisibilityLayers';
import { VisibilityMap } from '../types/VisibilityTypes';

export const DEFAULT_VISIBILITY_MAP: VisibilityMap = {
  [VisibilityLayers.SWEEP_AUTOMATIONS]: false,
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.GATES]: true,
  [VisibilityLayers.COMMENTS]: true,
  [VisibilityLayers.VALIDATION_RULES]: false,
  [VisibilityLayers.SFDC_AUTOMATIONS]: false,
  [VisibilityLayers.APEX]: false,
  [VisibilityLayers.PLAYBOOK_ALERTS]: false,
  [VisibilityLayers.ASSIGNMENT_RULES]: false,
  [VisibilityLayers.SCHEDULED_ASSIGNMENTS]: false,
  [VisibilityLayers.FUNNEL_APEX]: false,
  [VisibilityLayers.FUNNEL_SFDC_AUTOMATIONS]: false,
  [VisibilityLayers.FUNNEL_VALIDATION_RULES]: false,
  [VisibilityLayers.FUNNEL_ASSIGNMENT_RULES]: false,
  [VisibilityLayers.FUNNEL_DEDUPLICATION]: false,
  [VisibilityLayers.FUNNEL_MATCHING]: false,
  [VisibilityLayers.FUNNEL_SWEEP_AUTOMATIONS]: false,
  [VisibilityLayers.FUNNEL_PLAYBOOK_ALERTS]: false,
  [VisibilityLayers.FUNNEL_MATCHING_AND_DEDUPLICATION]: false,
  [VisibilityLayers.FUNNEL_HUBSPOT_ENROLLMENTS]: false,
};

export const SWEEP_AUTOMATIONS_LABEL = 'Automations';
export const NURTURING_STEPS_LABEL = 'Nurturing steps';
export const GATES_LABEL = 'Gates';
export const COMMENTS_LABEL = 'Comments';
export const VALIDATION_RULES_LABEL = 'Validation rules';
export const SFDC_AUTOMATIONS_LABEL = 'Salesforce automations';
export const APEX_TRIGGERS_LABEL = 'Apex triggers';
export const PLAYBOOK_ALERTS_LABEL = 'Alerts';
export const ASSIGNMENT_RULES_LABEL = 'Assignment rules';
export const SCHEDULED_ASSIGNMENTS_LABEL = 'Scheduled assignments rules';
export const DEDUPLICATION_LABEL = 'Deduplication';
export const MATCHING_LABEL = 'Matching';
export const FUNNEL_MATCHING_AND_DEDUPLICATION_LABEL = 'Dedupe & Matching';
export const FUNNEL_HUBSPOT_ENROLLMENT_LABEL = 'Active Enrollment rules';

export const SWEEP_MENU_PART = [
  {
    icon: Automations,
    layer: VisibilityLayers.SWEEP_AUTOMATIONS,
    text: SWEEP_AUTOMATIONS_LABEL,
  },
  {
    icon: PlaybookAlert,
    layer: VisibilityLayers.PLAYBOOK_ALERTS,
    text: PLAYBOOK_ALERTS_LABEL,
  },
  {
    icon: Assign,
    layer: VisibilityLayers.ASSIGNMENT_RULES,
    text: ASSIGNMENT_RULES_LABEL,
  },
  {
    icon: Clock,
    layer: VisibilityLayers.SCHEDULED_ASSIGNMENTS,
    text: SCHEDULED_ASSIGNMENTS_LABEL,
  },
  {
    icon: Target,
    layer: VisibilityLayers.FUNNEL_MATCHING_AND_DEDUPLICATION,
    text: FUNNEL_MATCHING_AND_DEDUPLICATION_LABEL,
  },
  {
    icon: Nurturing,
    layer: VisibilityLayers.NURTURING_STEPS,
    text: NURTURING_STEPS_LABEL,
  },
  {
    icon: Gate,
    layer: VisibilityLayers.GATES,
    text: GATES_LABEL,
  },
  {
    icon: UnreadComment,
    layer: VisibilityLayers.COMMENTS,
    text: COMMENTS_LABEL,
  },
];

export const SF_MENU_PART = [
  {
    icon: ValidationRulesIcon,
    layer: VisibilityLayers.VALIDATION_RULES,
    text: VALIDATION_RULES_LABEL,
  },
  {
    icon: SalesforceAutomations,
    layer: VisibilityLayers.SFDC_AUTOMATIONS,
    text: SFDC_AUTOMATIONS_LABEL,
  },
  {
    icon: Apex,
    layer: VisibilityLayers.APEX,
    text: APEX_TRIGGERS_LABEL,
  },
];
