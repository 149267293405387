import { useLocation } from 'react-router';
import { appRoutes } from '../../../../constants/appRoutes';
import { useHubspot } from './useHubspot';

export const useAuthenticatedAppHubspotLoad = () => {
  const { pathname } = useLocation();
  const { hasLoadedHubspotOrgs } = useHubspot();
  const hubspotMustLoad = pathname.startsWith(appRoutes.hubspot.route); // Hubspot page must load hubspot orgs

  // We only need to block the app until we load the hubspot orgs if we are on the hubspot page
  // otherwise we can let the app load without waiting for the hubspot orgs
  const hubspotInitialized = hasLoadedHubspotOrgs || !hubspotMustLoad;
  return {
    hubspotInitialized,
  };
};
