export enum ActionStatus {
  NOT_COMPLETED = 'NOT_COMPLETED',
  COMPLETED = 'COMPLETED',
}

export enum GetStartedActionId {
  documentationNavigate = 'documentationNavigate',
  changeFeedNavigate = 'changeFeedNavigate',
  rollupsNavigate = 'rollupsNavigate',
  assignmentsNavigate = 'assignmentsNavigate',
  automationsNavigate = 'automationsNavigate',
  slackNavigate = 'slackNavigate',
  dedupeNavigate = 'dedupeNavigate',
  visualizeNavigate = 'visualizeNavigate',
  optimizeNavigate = 'optimizeNavigate',
}
