import { SearchInput } from './SearchInput';
import { ChevronUp as ExpandLess } from '@sweep-io/sweep-design/dist/icons';
import { ChevronDown as ExpandMore } from '@sweep-io/sweep-design/dist/icons';
import { VerticalCenteredBox } from '../pages/environments/styledComponents';
import { Box, Collapse, Divider, ListItemButton, ListItemText } from '@mui/material';
import { Badge, Typography } from '@sweep-io/sweep-design';
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ObjectTypeChip } from './ObjectTypeChip';
import { filterItemsBySearch } from '../../lib/filterItemsBySearch';
import { useRunOnceWhenTruthy } from './useRunOnceWhenTruthy';
import { highlightMatch } from '../../lib/highlightMatch';
import { StyledListItem } from './StyledListItem';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

interface CollapseListProps {
  isOpen: boolean;
  groups: ListGroup[];
  showSearchField?: boolean;
  onItemClick?: (item: ListItem, parentGroup: ListGroup) => any;
  openAllExpanded?: boolean;
  openFirstExpanded?: boolean;
  componentHeaderText?: string;
  noItemsFoundJsx?: React.ReactNode;
  selectedItem?: ListItemWithObjectName;
  withCounter?: boolean;
  withDivider?: boolean;
  disableVirtuoso?: boolean;
}

export interface ListGroup {
  label: string;
  labelColor?: string;
  objectName?: string;
  value: string; //ID
  items: ListItem[];
  parentName?: string; //if we want to display label with tag
}

export interface ListItem {
  value: string;
  label: string;
  ListItemTag?: React.ReactNode;
  EndAdornment?: React.ReactNode;
}

export interface ListItemWithObjectName extends ListItem {
  objectName: string; //objectApiName
}

interface GroupIdOpenStatusMap {
  [groupId: string]: boolean;
}

const setAllGroupsOpenStatus = (groups: ListGroup[], openStatus: boolean): GroupIdOpenStatusMap => {
  return groups?.reduce((prev: GroupIdOpenStatusMap, group, idx) => {
    prev[idx] = openStatus;
    return prev;
  }, {});
};

export const CollapseList = forwardRef<any, CollapseListProps>(
  (
    {
      isOpen,
      componentHeaderText,
      showSearchField,
      groups,
      onItemClick,
      openAllExpanded,
      openFirstExpanded,
      selectedItem,
      noItemsFoundJsx,
      withCounter,
      withDivider,
      disableVirtuoso,
    },
    ref,
  ) => {
    const virtuoso = useRef<VirtuosoHandle>(null);
    const [search, setSearch] = useState('');
    const [groupsOpenStatus, setGroupsOpenStatus] = useState<GroupIdOpenStatusMap>(
      setAllGroupsOpenStatus(groups, !!openAllExpanded),
    );

    const toggleGroupStatus = useCallback(
      (groupIdx: number) => {
        const chosenGroup = groupsOpenStatus[groupIdx];

        setGroupsOpenStatus({
          ...groupsOpenStatus,
          [groupIdx]: !chosenGroup,
        });
      },
      [groupsOpenStatus],
    );

    // Filters items and groups based on the search
    //
    // By product definition:
    // display entire children list if str matches parent label
    // display only relevant children if str doesn't match parent label
    const filteredGroups = useMemo(() => {
      return groups.reduce((filteredElements: ListGroup[], element) => {
        //Check if string matches parent label
        const parentContainsString = filterItemsBySearch(
          [{ label: element.label }],
          search,
          'label',
        );
        const childrenContainsString = filterItemsBySearch<ListItem>(
          element.items,
          search,
          'label',
        );

        if (
          (parentContainsString.length && element.items.length) ||
          childrenContainsString.length
        ) {
          filteredElements.push({
            ...element,
            label: element.label,
            items: parentContainsString.length ? element.items : childrenContainsString,
          });
        }

        return filteredElements;
      }, []);
    }, [groups, search]);

    // Expands every group that has items matching the search
    useEffect(() => {
      if (search !== '') {
        const newGroupsOpenStatus = { ...groupsOpenStatus };
        filteredGroups.filter((group) => !!group.items.length);
        // .forEach((group, groupIdx) => ([groupIdx] = true));
        setGroupsOpenStatus(newGroupsOpenStatus);
      }
      //to reduce amount of rerenders
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredGroups, search]);

    useEffect(() => {
      if (!isOpen) {
        setSearch('');
        setGroupsOpenStatus(setAllGroupsOpenStatus(groups, !!openAllExpanded));
      }
    }, [isOpen, openAllExpanded, groups]);

    const setSearchFunc = (searchTerm: string) => {
      setSearch(searchTerm);
      virtuoso.current?.scrollToIndex({
        index: 0,
      });
      if (searchTerm === '') {
        setGroupsOpenStatus(setAllGroupsOpenStatus(groups, false));
      }
    };

    useRunOnceWhenTruthy(() => {
      toggleGroupStatus(0);
    }, !!openFirstExpanded);

    useImperativeHandle(ref, () => {
      return {
        clearSearch: () => {
          setSearch('');
        },
        search,
      };
    }, [search]);

    const renderItem = (group: ListGroup, rowIndex: number) => {
      return (
        <>
          <ListItemButton
            key={group.value}
            onClick={() => toggleGroupStatus(rowIndex)}
            sx={{ display: 'flex', alignContent: 'center' }}
          >
            <ListItemText
              sx={{
                display: 'flex',
                gap: '12px',
                alignItems: 'center',
              }}
              primary={
                group?.parentName ? (
                  <TruncatedTextTooltip variant="body-bold">
                    {group.parentName}
                  </TruncatedTextTooltip>
                ) : (
                  <></>
                )
              }
              secondary={
                <Box sx={{ display: 'flex', gap: 0.5 }}>
                  <ObjectTypeChip
                    label={highlightMatch(group.label, search)}
                    objectType={group.objectName ?? group.value}
                    background={group.labelColor}
                  />
                  {withCounter && <Badge variant="secondary" showZero label={group.items.length} />}
                </Box>
              }
            />
            {groupsOpenStatus[rowIndex] ? (
              <IconWrapper IconComponent={<ExpandLess />} />
            ) : (
              <IconWrapper IconComponent={<ExpandMore />} />
            )}
          </ListItemButton>

          <Collapse in={groupsOpenStatus[rowIndex]} timeout="auto">
            {group.items.map((item, idx) => {
              const isActive = !selectedItem?.objectName
                ? selectedItem?.value === item.value
                : selectedItem?.value === item.value && selectedItem?.objectName === group.value;

              return (
                <StyledListItem
                  key={item.value}
                  onClick={onItemClick ? () => onItemClick(item, group) : undefined}
                  isActive={isActive}
                  title={highlightMatch(item.label, search)}
                  sx={{
                    display: 'flex',
                    marginTop: idx === 0 ? 1 : '',
                    pl: 2,
                  }}
                  ListItemTag={item.ListItemTag}
                  EndAdornment={item.EndAdornment}
                />
              );
            })}
          </Collapse>

          {withDivider && (
            <Box mb={1} mt={1}>
              <Divider />
            </Box>
          )}
        </>
      );
    };

    const maybeRenderVirtuosoList = () => {
      if (!disableVirtuoso) {
        return (
          <Virtuoso
            ref={virtuoso}
            data={filteredGroups}
            style={{ height: '100%', minHeight: '150px', overflowX: 'hidden' }}
            totalCount={filteredGroups.length}
            itemContent={(rowIndex: number, group) => (
              <Fragment key={group.value + rowIndex}>{renderItem(group, rowIndex)}</Fragment>
            )}
          />
        );
      }
    };

    const maybeRenderRawList = () => {
      if (disableVirtuoso) {
        return filteredGroups.map((group, rowIndex) => (
          <Fragment key={group.value + rowIndex}>{renderItem(group, rowIndex)}</Fragment>
        ));
      }
    };

    return (
      <Box sx={{ height: '100%' }}>
        <Box sx={{ background: 'white', position: 'sticky', top: 0, zIndex: 1 }}>
          {componentHeaderText && (
            <VerticalCenteredBox
              px={2}
              sx={{
                height: 55,
              }}
            >
              <Typography variant="caption-bold">{componentHeaderText}</Typography>
            </VerticalCenteredBox>
          )}

          {showSearchField && (
            <Box sx={{ padding: '0 16px 12px 16px' }} className="search-input-wrapper">
              <SearchInput
                withFixedMagnifyingGlassIcon
                TextFieldProps={{
                  ref,
                  value: search,
                  onChange: (event) => {
                    setSearchFunc(event.target.value);
                  },
                }}
                onClearButtonClick={() => setSearchFunc('')}
              />
            </Box>
          )}
        </Box>

        <Box className="collapse-list" pt={1} sx={{ height: '100%', minWidth: '300px' }}>
          {!filteredGroups.length && !!noItemsFoundJsx && noItemsFoundJsx}
          {maybeRenderVirtuosoList()}
          {maybeRenderRawList()}
        </Box>
      </Box>
    );
  },
);

const IconWrapper = ({ IconComponent }: { IconComponent: React.ReactNode }) => (
  <Box component="span">{IconComponent}</Box>
);
