import React, { createRef, useRef } from 'react';
import useResizeObserver from 'use-resize-observer';

export const CanvasWrapperResizeObserverContext = React.createContext<{
  canvasWrapperRef: React.RefObject<HTMLDivElement>;
  width?: number;
  height?: number;
}>({
  canvasWrapperRef: createRef<HTMLDivElement>(),
});

export const CanvasWrapperResizeObserverProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const canvasWrapperRef = useRef<HTMLDivElement>(null);
  const { width, height } = useResizeObserver<HTMLDivElement>({ ref: canvasWrapperRef });

  return (
    <CanvasWrapperResizeObserverContext.Provider
      value={{ width, height, canvasWrapperRef: canvasWrapperRef }}
    >
      {children}
    </CanvasWrapperResizeObserverContext.Provider>
  );
};
