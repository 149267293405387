import { HsFilterOperatorsOperators } from '../../pages/canvas-pages/canvas-hubspot-page/hubspot.types';

const allowedEmptyValueOperators = [
  HsFilterOperatorsOperators.IS_UNKNOWN,
  HsFilterOperatorsOperators.IS_KNOWN,
];

const getExitCriteriaErrorIds = (
  _criteria: DeepPartial<SweepCriterion>[],
  isHubspotFieldType: boolean,
) => {
  const _errorIds = _criteria
    .filter((criteria) => {
      const pickedByField = (({ fieldType, operator, value }) => ({
        fieldType,
        operator,
        value,
      }))(criteria);
      if (!pickedByField.fieldType || !pickedByField.operator) {
        return true;
      }

      if (isHubspotFieldType) {
        const _operator = pickedByField.operator as HsFilterOperatorsOperators;
        if (allowedEmptyValueOperators.includes(_operator)) {
          return false;
        }
      }
      const hasAllValues = Object.values(pickedByField).every((value) => !!value);
      return !hasAllValues;
    })
    .map((v) => v.criterionId)
    .filter(Boolean) as string[];
  return _errorIds;
};

const isEmptyEntry = (entry: DeepPartial<SweepCriterion>) => {
  return !Boolean(entry.fieldType || entry.operator || entry.value);
};

export const hasOnlyOneCriteriaAndItIsEmpty = (criteria: DeepPartial<SweepCriterion>[]) => {
  return criteria.length === 1 && isEmptyEntry(criteria[0]);
};

export const validateSweepCriteria = (
  criteria?: DeepPartial<SweepCriterion>[],
  excludeFirstEntryFromValidationIfEmpty = true,
  isHubspotFieldType = false,
) => {
  if (!criteria) {
    return [];
  }

  if (excludeFirstEntryFromValidationIfEmpty) {
    if (hasOnlyOneCriteriaAndItIsEmpty(criteria)) {
      return [];
    }
  }
  return getExitCriteriaErrorIds(criteria, isHubspotFieldType);
};
