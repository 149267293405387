import {
  FormControl,
  MenuItem,
  ListItemText,
  ListSubheader,
  SelectChangeEvent,
  SxProps,
  Box,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSweepFields } from '../../../../sweep-fields/useCachedSweepFields';
import { SearchInput } from '../../SearchInput';
import StyledTooltip from '../../StyledTooltip';
import SelectorEmptyState from '../../SelectorEmptyState';
import SweepSelect from '../../SweepSelect';
import { colors, Checkbox, IconButton } from '@sweep-io/sweep-design';
import ManagePicklistDialog from '../../../pages/funnel-map-flow/dialogs/fields-management/ManagePicklistDialog';
import { CenteredCircularProgress } from '../../CenteredCircularProgress';
import { Edit } from '@sweep-io/sweep-design/dist/icons';

export interface PicklistSelectorProps {
  shouldBeDisabled?: boolean;
  chosenValues?: string;
  field?: SweepField;
  onChange: (chosenValues: string) => void;
  crmOrgId: string;
  fieldIds?: string[];
  sx?: SxProps;
  placeholder?: string;
  isMultiple?: boolean;
  removeBorders?: boolean;
}

// Changed to ; on SF team request
const SEPARATOR = '; ';

const PicklistSelector = ({
  shouldBeDisabled,
  chosenValues,
  field: providedField,
  onChange,
  fieldIds,
  sx,
  placeholder,
  isMultiple = true,
  removeBorders,
  crmOrgId,
}: PicklistSelectorProps) => {
  const { getSweepFieldsById } = useSweepFields();
  const [field, setField] = useState<SweepField | undefined>(providedField);
  const [isManagePicklistOpened, setIsManagePicklistOpened] = useState(false);
  const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const fieldIdToFetch = fieldIds?.[fieldIds ? fieldIds.length - 1 : 0];
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const valueSet = field?.properties.valueSet as PicklistValueSetItem[];

  const convertToPicklistItemsArray = useCallback(
    (chosenValues?: string) => {
      if (!chosenValues) {
        return [];
      }

      const chosenValsArray = chosenValues.split(SEPARATOR);
      return valueSet?.filter((item) => chosenValsArray.includes(item.fullName));
    },
    [valueSet],
  );

  const localChosenValuesArray = convertToPicklistItemsArray(chosenValues) ?? [];
  const localPicklistValues = valueSet?.filter((value) => {
    const regexp = new RegExp(searchValue, 'gi');
    return value.label.match(regexp);
  });

  const fetchAndUpdateField = useCallback(
    async ({ fieldIds }: { fieldIds: string[] }) => {
      setIsLoading(true);
      const lastIdx = fieldIds.length - 1;
      const fields = await getSweepFieldsById({
        fieldIds: [fieldIds[lastIdx]],
        crmOrgId,
      });
      const relevantField = fields[0]; //fields has only 1 item
      setField(relevantField);
      setIsLoading(false);
    },
    [setField, getSweepFieldsById, crmOrgId],
  );

  /**
   * providedField is provided when selecting a field (in the nested selector) ,
   * but when data is loaded from db
   * we need to fetch the values (using getSweepFields).
   * Also when the field selection changes.
   */

  useEffect(() => {
    if (isSelectorOpen && fieldIdToFetch && field?.id !== fieldIdToFetch) {
      fetchAndUpdateField({ fieldIds: [fieldIdToFetch] });
    }
  }, [fieldIdToFetch, fetchAndUpdateField, isSelectorOpen, field?.id]);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value;
    let fullNameString = '';

    if (Array.isArray(event.target.value)) {
      const picklistValue = value as PicklistValueSetItem[];
      fullNameString = picklistValue
        ?.map((element: PicklistValueSetItem) => element.fullName)
        .join(SEPARATOR);
    } else {
      const singlePicklistItem = event.target.value as PicklistValueSetItem;
      fullNameString = singlePicklistItem.fullName;
    }

    onChange && onChange(fullNameString);
  };

  return (
    <>
      <FormControl fullWidth>
        <SweepSelect
          onOpenCb={() => setIsSelectorOpen(true)}
          onCloseCb={() => {
            setIsSelectorOpen(false);
            setSearchValue('');
          }}
          renderValue={(selected: any) => {
            if (!localChosenValuesArray.length && chosenValues) {
              //render the saved values, before the actual fetch is done
              return chosenValues.replaceAll(';', ',');
            }
            if (!localChosenValuesArray.length) {
              return <Box sx={{ color: colors.grey[500] }}>{placeholder}</Box>;
            }
            return Array.isArray(selected)
              ? selected.map((x: ObjectLiteral) => x.label).join(', ')
              : [];
          }}
          MenuProps={{
            autoFocus: false,
            disablePortal: true, //"disablePortal" so the manage dialog will in front of the select dropdown
          }}
          SelectProps={{
            value: localChosenValuesArray,
            onChange: handleChange,
            disabled: shouldBeDisabled,
            multiple: isMultiple,
            displayEmpty: true,
            sx: {
              ...sx,
              '.MuiPaper-root': { width: '300px' },
              '.MuiList-root': {
                paddingRight: '8px !important', //TODO check why is it overwritten with header
              },
            },
          }}
          removeBorders={removeBorders}
        >
          <ListSubheader //TODO move listsubheader outside of select list (hover issues)
            sx={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: 1,
              borderBottom: '1px solid',
              borderColor: colors.grey[300],
              //TODO
              //will be removed when listsubheader is moved outside of select list
              margin: '0px -8px',
              padding: '6px 15px 15px',
              gap: 1,
            }}
          >
            <SearchInput
              TextFieldProps={{
                value: searchValue,
                autoFocus: true,
                onChange: (event) => setSearchValue(event.target.value),
                onKeyDown: (e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                },
              }}
              onClearButtonClick={() => setSearchValue('')}
            />

            <StyledTooltip title="Manage picklist values" placement="top">
              <IconButton
                variant="outlined"
                size="small"
                onClick={() => setIsManagePicklistOpened(true)}
              >
                <Edit />
              </IconButton>
            </StyledTooltip>
          </ListSubheader>

          {localPicklistValues?.map((item: PicklistValueSetItem) => (
            //item as any is a hack to provide object to select
            <MenuItem key={item.fullName} value={item as any}>
              <Checkbox
                checked={
                  localChosenValuesArray?.findIndex(
                    (chosenValue) => chosenValue.fullName === item.fullName,
                  ) >= 0
                }
              />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
          {!localPicklistValues?.length && !isLoading && (
            <SelectorEmptyState
              callback={() => setIsManagePicklistOpened(true)}
              title="No results. You can manage the picklist values directly from here"
              buttonText="Manage picklist values"
            />
          )}

          {isLoading && <Loader />}
        </SweepSelect>
      </FormControl>

      {field && (
        <ManagePicklistDialog
          isOpened={isManagePicklistOpened}
          fieldToManage={field}
          crmOrgId={crmOrgId}
          closeDialog={() => setIsManagePicklistOpened(false)}
          onUpdateFieldCb={(field) => {
            setField(field);
          }}
        />
      )}
    </>
  );
};

export const Loader = () => (
  <Box
    sx={{
      width: '100%',
      height: '200px',
      zIndex: 11111100,
    }}
  >
    <CenteredCircularProgress />
  </Box>
);

export { PicklistSelector };
