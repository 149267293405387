import { PluginMeddicSvg } from '../icons';
import { useOnAddCriteriaPlugin } from '../base-criteria-plugin-panel/useOnAddCriteriaPlugin';
import { PluginTypes } from '../../../../../../types/enums/PluginTypes';

export const useOnAddMeddicPlugin = () => {
  const onAdd = useOnAddCriteriaPlugin({
    pluginImage: <PluginMeddicSvg />,
    type: PluginTypes.MEDDIC,
  });

  return onAdd;
};
