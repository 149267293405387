import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { Box } from '@mui/material';
import './quill.custom.css'; //overriding the default hover color

const RichTextEditor = ({
  isReadOnly,
  value,
  onChange,
  onBlur,
  placeholder,
}: {
  isReadOnly: boolean;
  value: string;
  placeholder: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
}) => {
  return (
    <Box
      sx={{
        background: colors.white,
        fontWeight: '400',
        '.quill': {
          '.ql-container .ql-editor ul>li:before': {
            content: '"\\2022"',
          },
          //style the placeholder:
          '.ql-container.ql-snow .ql-editor.ql-blank::before': {
            fontStyle: 'normal',
            color: colors.grey[700],
            fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
            fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
            opacity: 1,
          },

          //style the border:
          '.ql-container.ql-snow': {
            fontFamily: 'Inter',
            fontSize: '12px',
            borderBottomRightRadius: '4px',
            borderBottomLeftRadius: '4px',
            border: `1px solid ${colors.grey[300]}`,
            borderTop: 0,
          },
          '.ql-toolbar.ql-snow': {
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px',
            border: `1px solid ${colors.grey[300]}`,
          },
          '&:hover': {
            '.ql-toolbar.ql-snow': {
              borderColor: colors.blue[500],
            },
            '.ql-container.ql-snow': {
              borderColor: colors.blue[500],
            },
          },

          '.ql-tooltip': {
            left: '10px!important', //by default the tooltip is centered, but then it is being cut by the "plugin" frame.
            borderRadius: '4px',
            input: {
              borderRadius: '4px',
              border: `1px solid ${colors.grey[300]}`,
              '&:hover, &:active, &:focus, &:focus-visible': {
                borderColor: colors.blue[500],
                outline: 'none', //there's a default outline for "focus-visible" that we need to override
              },
            },
          },
        },
      }}
    >
      <ReactQuill
        readOnly={isReadOnly}
        theme="snow"
        value={value}
        placeholder={placeholder}
        onChange={(value) => {
          onChange(value);
        }}
        onBlur={(previousSelection, source, editor) => {
          onBlur(editor.getHTML());
        }}
      />
    </Box>
  );
};

export default RichTextEditor;
