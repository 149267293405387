import { colors, IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Dialog, DialogContent, Box } from '@mui/material';
import _isEqual from 'lodash/isEqual';
import { CreateEditDialogHeader } from '../common/create-edit-dialog/CreateEditDialogHeader';
import { uniqueId } from '../../lib/uniqueId';
import { CustomHeader } from '../common/rule-builder/CustomHeader';
import { SweepConditionRuleBuilder } from '../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { SweepConditionRuleBuilderRef } from '../common/sweep-condition-rule-builder/sweep-condition-rule-builder-types';
import { MainSelectors } from '../SweepFieldsMainSelector/SweepFieldsMainSelector';
import { DialogLoader } from '../common/dialogs/DialogLoader';
import useConfirm from '../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';

interface TerritoriesDialogProps {
  isOpen: boolean;
  initialTerritory?: Partial<Territory>;
  isAllowedBtn?: boolean;
  readonly?: boolean;
  onBackButtonClick: () => any;
  onSave: (t: Territory, isEdit: boolean) => any;
}

const CreateEditTerritoryDialog = ({
  isOpen,
  initialTerritory,
  isAllowedBtn,
  readonly,
  onBackButtonClick,
  onSave,
}: TerritoriesDialogProps) => {
  const isEditState = !!initialTerritory?.id;
  const [_initialTerritory] = useState<Territory>({
    id: uniqueId(),
    name: 'New territory',
    criteria: [],
    criteriaLogic: '',
    ...(initialTerritory || {}),
  });
  const [territoryObject, setTerritoryObject] = useState<Territory>(_initialTerritory);
  const { openConfirm } = useConfirm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleBeforeClose = async () => {
    if (!_isEqual(territoryObject, initialTerritory)) {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (!isConfirmed) return;
    }
    onBackButtonClick();
  };

  const updateTerritoryObject = useCallback(
    (sweepCondition: SweepConditionWithLabels) => {
      const temp = {
        ...territoryObject,
        criteria: sweepCondition.criteria.map((elem) => ({
          ...elem,
          fieldName: elem?._fieldLabels[0],
        })),
        criteriaLogic: sweepCondition.criteriaLogic,
      };
      setTerritoryObject(temp);
    },
    [territoryObject],
  );

  const sweepCondition: SweepConditionWithLabels = useMemo(() => {
    return {
      criteria: territoryObject.criteria.map((obj) => ({
        ...obj,
        _fieldIds: [],
        valueType: 'LITERAL',
        fieldType: 'Territory',
        _fieldLabels: obj.fieldName ? [obj.fieldName] : [],
      })),
      criteriaLogic: territoryObject.criteriaLogic,
    };
  }, [territoryObject.criteria, territoryObject.criteriaLogic]);

  const ruleBuilderRef = useRef<SweepConditionRuleBuilderRef>(null);

  return (
    <Dialog
      open={isOpen}
      onClose={handleBeforeClose}
      PaperProps={{
        sx: { width: '1000px', height: '887px' },
      }}
    >
      <DialogContent sx={{ padding: '32px' }}>
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <CreateEditDialogHeader
            buttonStartIcon={isEditState ? undefined : 'Plus'}
            buttonContent={`${isEditState ? 'Save changes' : 'Create territory'}`}
            onBackClick={handleBeforeClose}
            maxLength={40}
            onButtonClick={async () => {
              const errors = ruleBuilderRef.current?.triggerValidation();
              if (errors?.length) {
                return;
              }
              setIsSubmitting(true);
              await onSave(territoryObject, isEditState);
              setIsSubmitting(false);
            }}
            onChange={(value) => {
              setTerritoryObject({
                ...territoryObject,
                name: value,
              });
            }}
            placeholder={'Untitled Territory'}
            value={territoryObject?.name ?? ''}
            buttonDisabled={!isAllowedBtn || readonly || territoryObject.criteria?.length === 0}
            editDisabled={!isAllowedBtn || readonly}
          />
          <IconButton onClick={handleBeforeClose} variant="flat" size="small">
            <Close color={colors.grey[800]} />
          </IconButton>
        </Box>

        <Box
          sx={{
            backgroundColor: colors.grey[100],
            paddingTop: '24px',
            borderRadius: '6px',
            marginTop: '32px',
          }}
        >
          <>
            <CustomHeader customHeader="Define territory’s conditions:" />
            <SweepConditionRuleBuilder
              ref={ruleBuilderRef}
              mainSelectorKey={MainSelectors.Territories}
              readOnly={!isAllowedBtn || readonly}
              crmOrgId={''}
              objectType={''}
              sweepCondition={sweepCondition}
              onChange={updateTerritoryObject}
            />
          </>
        </Box>
        <DialogLoader visible={isSubmitting} />
      </DialogContent>
    </Dialog>
  );
};

export default CreateEditTerritoryDialog;
