import { Box, ButtonBase } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';

type Item = {
  value: any;
  label: string;
};
const TextToggle = ({
  items,
  selectedItem,
  onChange,
  disabled,
}: {
  items: Item[];
  selectedItem?: Item;
  onChange: (item: Item) => void;
  disabled?: boolean;
}) => {
  return (
    <Box display="flex">
      {items.map((item) => {
        const isSelected = item.value === selectedItem?.value;
        return (
          <ButtonBase
            disableRipple
            disabled={disabled}
            onClick={() => onChange(item)}
            key={item.value}
            sx={{
              border: `1px solid ${colors.grey[300]}`,
              backgroundColor: isSelected ? colors.blue[100] : colors.white,
              padding: '10px',
              '&:hover': {
                backgroundColor: colors.blue[300],
                cursor: 'pointer',
              },
              '&:first-of-type': {
                borderBottomLeftRadius: '10px',
                borderTopLeftRadius: '10px',
              },
              '&:last-of-type': {
                borderBottomRightRadius: '10px',
                borderTopRightRadius: '10px',
              },
              '&:not(:last-of-type)': {
                borderRight: 'none',
              },
            }}
          >
            <Typography variant="body" color={isSelected ? colors.blue[500] : colors.black}>
              {item.label}
            </Typography>
          </ButtonBase>
        );
      })}
    </Box>
  );
};

export default TextToggle;
