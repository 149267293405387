import * as React from "react";
const SvgE = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#E1EBFF", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.07806 18.1664C7.07806 18.5612 7.39813 18.8813 7.79297 18.8813H16.0342C16.429 18.8813 16.7491 18.5612 16.7491 18.1664V16.2537C16.7491 15.8589 16.429 15.5388 16.0342 15.5388H10.7896V13.5307H15.5606C15.9555 13.5307 16.2755 13.2106 16.2755 12.8158V10.9031C16.2755 10.5082 15.9555 10.1882 15.5606 10.1882H10.7896V8.1985H15.985C16.3798 8.1985 16.6999 7.87843 16.6999 7.48359V5.57089C16.6999 5.17606 16.3798 4.85598 15.985 4.85598H7.79297C7.39813 4.85598 7.07806 5.17606 7.07806 5.57089V18.1664Z", fill: "#577EE4", stroke: "white", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.42982, strokeLinejoin: "round" }));
export default SvgE;
