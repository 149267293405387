import { RoleLevel } from '@server/role-group-interface';

export const systemRolesDetails = [
  {
    name: 'Admin',
    roleLevels: {
      automations: RoleLevel.DEPLOY,
      assignments: RoleLevel.DEPLOY,
      rollups: RoleLevel.DEPLOY,
      dedupMatching: RoleLevel.DEPLOY,
      funnels: RoleLevel.DEPLOY,
      alerts: RoleLevel.DEPLOY,
    },
    description: 'Has full access. Can make edits and deployments within the entire system.',
  },
  {
    name: 'Alert Manager',
    roleLevels: {
      automations: RoleLevel.VIEWER,
      assignments: RoleLevel.VIEWER,
      rollups: RoleLevel.VIEWER,
      dedupMatching: RoleLevel.VIEWER,
      funnels: RoleLevel.VIEWER,
      alerts: RoleLevel.DEPLOY,
    },
    description:
      'Full access to alerts, including edits and deployments. Can view other parts of the system.',
  },
  {
    name: 'Collaborator',
    roleLevels: {
      automations: RoleLevel.REQUEST_TO_DEPLOY,
      assignments: RoleLevel.REQUEST_TO_DEPLOY,
      rollups: RoleLevel.REQUEST_TO_DEPLOY,
      dedupMatching: RoleLevel.REQUEST_TO_DEPLOY,
      funnels: RoleLevel.REQUEST_TO_DEPLOY,
      alerts: RoleLevel.REQUEST_TO_DEPLOY,
    },
    description: 'Can view, edit and save. Can request to deploy any element.',
  },
  {
    name: 'Viewer',
    roleLevels: {
      automations: RoleLevel.VIEWER,
      assignments: RoleLevel.VIEWER,
      rollups: RoleLevel.VIEWER,
      dedupMatching: RoleLevel.VIEWER,
      funnels: RoleLevel.VIEWER,
      alerts: RoleLevel.VIEWER,
    },
    description: 'View access to the entire system.',
  },
];
