import { ListElementWrapper } from '../documentation/shared/ListElementWrapper';
import { Badge, colors, Typography } from '@sweep-io/sweep-design';
import { Box, Stack } from '@mui/material';
import { RoutingTabs } from './useRoutingTabs';

export type RoutingListItem = {
  value: string;
  label: string;
  elements: any[];
};

type RoutingTabsListProps = {
  items: RoutingListItem[];
  onClick: (event: any, key: any) => void;
  title: string;
  activeItem?: RoutingTabs;
  textBadgeAlignment?: 'space-between';
};

const RoutingTabsList = ({
  items,
  onClick,
  title,
  activeItem,
  textBadgeAlignment,
}: RoutingTabsListProps) => {
  return (
    <Stack>
      <Stack pl={2} pr={2} pb={0.5}>
        <Typography variant="body-medium">{title}</Typography>
      </Stack>
      <Stack>
        {items.map((item) => {
          const key = item.value as any;
          const isActive = activeItem === item.value;

          return (
            <ListElementWrapper
              key={key}
              onWrapperClick={(event) => onClick(event, key)}
              sx={{ height: '48px', backgroundColor: isActive ? colors.grey[100] : 'transparent' }}
              leftContent={
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: textBadgeAlignment,
                    gap: 1,
                    width: '100%',
                  }}
                >
                  <Typography variant="body" color={colors.grey[800]}>
                    {item.label}
                  </Typography>
                  <Badge showZero variant="secondary" label={item.elements?.length ?? 0} />
                </Box>
              }
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default RoutingTabsList;
