import { PluginMeddpiccSvg } from '../../icons';
import {
  ConnectedBaseCriteriaPanel,
  CustomCriteriaPanelProps,
} from '../../base-criteria-plugin-panel/ConnectedBaseCriteriaPanel';
import { meddpiccPluginStepMapping } from './pluginData';
import { PluginTypes } from '../../../../../../../types/enums/PluginTypes';

const ConnectedMeddpiccPanel = (props: CustomCriteriaPanelProps) => (
  <ConnectedBaseCriteriaPanel
    {...props}
    title="MEDDPICC Methodology"
    pluginIcon={<PluginMeddpiccSvg />}
    pluginStepMapping={meddpiccPluginStepMapping}
    pluginType={PluginTypes.MEDDPICC}
  />
);

export { ConnectedMeddpiccPanel as MeddpiccPanel };
