import uniq from 'lodash/uniq';

export class FunnelMapModel {
  private _funnelMap: FunnelMap;

  constructor(funnelMap: FunnelMap) {
    this._funnelMap = funnelMap;
  }

  public getFunnelMapLeadingFieldIds = (): string[] => {
    const funnelsData = Object.values(this._funnelMap.funnelsData);
    return uniq(
      funnelsData
        .map((funnel) => funnel.funnelDetails?.leadingObject._leadingFieldId ?? '')
        .filter((id) => !!id),
    );
  };
}
