import { ConfigurationType } from '../types';

export const possibleTypes = [
  // changes should be consulted with BE
  ConfigurationType.validationRules,
  ConfigurationType.approvalProcesses,
  ConfigurationType.workflowRules,
  ConfigurationType.flows,
  ConfigurationType.processBuilderFlows,
  ConfigurationType.apexTriggers,
  ConfigurationType.apexClasses,
  ConfigurationType.cpqData,
  ConfigurationType.fields,
  ConfigurationType.rollups,
];

export const whatDoesItDoPossibleTypes = [
  // changes should be consulted with BE
  ConfigurationType.validationRules,
  ConfigurationType.approvalProcesses,
  ConfigurationType.workflowRules,
  ConfigurationType.flows,
  ConfigurationType.processBuilderFlows,
  ConfigurationType.apexTriggers,
  ConfigurationType.apexClasses,
  ConfigurationType.cpqData,
];
