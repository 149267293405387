import {
  Box,
  Checkbox,
  ListItemText,
  MenuItem,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import SweepSelect from '../common/SweepSelect';
import { useCallback, useEffect, useState } from 'react';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';

interface RecordTypeSelectorProps {
  onChange: (recordTypesList: string[]) => any;
  disabled?: boolean;
  recordTypesList: ShortParserRecordType[];
  value?: ShortParserRecordType[];
}

export const RecordTypeSelector = ({
  onChange,
  disabled,
  recordTypesList,
  value,
}: RecordTypeSelectorProps) => {
  const [recordTypesNames, setRecordTypesNames] = useState<string[]>([]);

  useRunOnceWhenTruthy(() => {
    setRecordTypesNames(recordTypesList.map((el) => el.name));
  }, !value);

  useEffect(() => {
    if (value) {
      setRecordTypesNames(value.map((i) => i.name));
    }
  }, [value]);

  const isAll = useCallback(() => {
    return recordTypesNames.length === recordTypesList.length || recordTypesNames.length === 0;
  }, [recordTypesList.length, recordTypesNames.length]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ marginRight: '8px' }}>
        <Typography variant="body">in record type</Typography>
      </Box>
      <SweepSelect
        FormControlProps={{ sx: { minWidth: '120px', marginRight: '8px' } }}
        SelectProps={{
          // set the placeholder color to black as it pre-selected as All
          sx: {
            '& .MuiSelect-select .notranslate::after': {
              color: 'black',
            },
          },
          placeholder: 'all',
          disabled,
          multiple: true,
          value: recordTypesNames,
          onChange: async (event: SelectChangeEvent<unknown>) => {
            const val = event.target.value as string[];
            setRecordTypesNames(val);
            onChange(val);
          },
          renderValue: (selected: any) => (
            <Box>
              {isAll()
                ? 'all'
                : selected?.map((typename: string) => (
                    <span key={typename}>
                      {recordTypesList?.find((e) => e.name === typename)?.label}
                      {selected.length > 1 ? ',' : ''}
                    </span>
                  ))}
            </Box>
          ),
        }}
      >
        {recordTypesList.map((record) => (
          <MenuItem key={`${record.label}${record.name}`} value={record.name}>
            <Checkbox checked={isAll() || recordTypesNames.includes(record.name)} />

            <ListItemText primary={record.label} />
          </MenuItem>
        ))}
      </SweepSelect>
    </Box>
  );
};
