import { useDispatch } from 'react-redux';
import { typography } from '@sweep-io/sweep-design/dist/theme/typography';
import { updateFunnelMapName } from '../../../../reducers/united-canvas/unitedCanvasReducer';
import { EditableLabel } from '../../../common/EditableLabel';
import { useFunnelMapPage } from '../useFunnelMapPage';
import { UNTITLED_FUNNEL_MAP_NAME } from '../../../../constants';

export const FunnelMapNameEdit = () => {
  const dispatch = useDispatch();
  const { funnelMapName } = useFunnelMapPage();

  return (
    <EditableLabel
      data-testid="funnel-left-menu-name"
      value={funnelMapName}
      onValueConfirm={(value) => {
        dispatch(updateFunnelMapName(value));
      }}
      placeholder={UNTITLED_FUNNEL_MAP_NAME}
      maxCharLength={40}
      minCharLength={0}
      inputSx={{
        ...typography.h2,
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
      }}
      height={24}
      defaultIsEditing={!Boolean(funnelMapName)}
    />
  );
};
