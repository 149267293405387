import { useSelector } from 'react-redux';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { selectPillsMap } from '../../canvas-pills/canvasPillsReducer';

export const useConfigurationCanvasState = () => {
  const { appFunnelMap, canvasFunnelMap, setCanvasFunnelMap } = useConfigurationCanvasContext();

  const pills = useSelector(selectPillsMap);

  return {
    appFunnelMap,
    canvasFunnelMap,
    setCanvasFunnelMap,
    pills,
  };
};
