import noop from 'lodash/noop';
import { CurrentRecord } from './RecordTypeLeadingObjectPanel';
import { createContext, useState } from 'react';

interface RecordTypeLeadingObjectPanelCtx {
  currentRecord?: CurrentRecord;
  setCurrentRecord: (record: CurrentRecord | undefined) => void;
  setLeadingFieldAndValuesForRecordType: (params: {
    recordTypeApiName: string;
    recordTypeName: string;
    recordTypeDataValues: PicklistValue[];
    leadingField: string;
    crmOrgId: string;
  }) => void;
  clearLeadingFieldForRecordType: (params: { crmOrgId: string; recordTypeApiName: string }) => void;
  hideRecordType: (params: { crmOrgId: string; recordTypeApiName: string }) => void;
  addRecordTypeDataEntries: (params: { crmOrgId: string; recordTypes: SingleRecordType[] }) => void;
}

export const RecordTypeLeadingObjectCtx = createContext<RecordTypeLeadingObjectPanelCtx>({
  setCurrentRecord: noop,
  setLeadingFieldAndValuesForRecordType: noop,
  addRecordTypeDataEntries: noop,
  clearLeadingFieldForRecordType: noop,
  hideRecordType: noop,
});

export const RecordTypeLeadingObjectProvider = ({
  children,
  setLeadingFieldAndValuesForRecordType,
  addRecordTypeDataEntries,
  clearLeadingFieldForRecordType,
  hideRecordType,
}: {
  children: React.ReactNode;
  setLeadingFieldAndValuesForRecordType: (params: {
    recordTypeApiName: string;
    recordTypeName: string;
    recordTypeDataValues: PicklistValue[];
    leadingField: string;
    crmOrgId: string;
  }) => void;
  addRecordTypeDataEntries: (params: { crmOrgId: string; recordTypes: SingleRecordType[] }) => void;
  clearLeadingFieldForRecordType: (params: { crmOrgId: string; recordTypeApiName: string }) => void;
  hideRecordType: (params: { crmOrgId: string; recordTypeApiName: string }) => void;
}) => {
  const [currentRecord, setCurrentRecord] = useState<CurrentRecord>();

  return (
    <RecordTypeLeadingObjectCtx.Provider
      value={{
        currentRecord,
        setCurrentRecord,
        setLeadingFieldAndValuesForRecordType,
        addRecordTypeDataEntries,
        clearLeadingFieldForRecordType,
        hideRecordType,
      }}
    >
      {children}
    </RecordTypeLeadingObjectCtx.Provider>
  );
};
