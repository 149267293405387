import Box from '@mui/material/Box/Box';
import { ReadOnlyAutomationAction } from '@server/read-only-elements.types';
import { HeaderWithIconOrNumber } from './atoms/HeaderWithIconOrNumber';
import { border } from '../consts';
import isString from 'lodash/isString';
import { ListWithBullets } from './atoms/ListWithBullets';
import { Subtext } from './atoms/Subtext';
import { propertiesToIcon } from './helpers/icons';

interface NotificationProps {
  action: ReadOnlyAutomationAction;
  noHeader?: boolean;
}

export const Notification = ({ action, noHeader }: NotificationProps) => {
  const { title = '', details } = action;
  const onlyStrings = details.every((detail) => typeof detail === 'string');
  const hasHeader = !(noHeader || !title);

  return (
    <Box pt={1}>
      {!noHeader && (
        <HeaderWithIconOrNumber headerText={title} HeaderIcon={propertiesToIcon[title]} />
      )}

      <Box
        pl={hasHeader ? 4 : 0}
        mt={hasHeader ? 1 : 0}
        sx={{ ...(hasHeader ? border : {}) }}
        position="relative"
      >
        {onlyStrings && (
          <ListWithBullets key={'list_bullets_details'} listItems={details as string[]} />
        )}

        {!onlyStrings &&
          details.map((detail, idx) => {
            if (isString(detail)) {
              return <Subtext key={'email' + detail} text={detail} />;
            }

            return (
              <ListWithBullets key={'list_bullets_' + idx} listItems={detail.details as string[]} />
            );
          })}
      </Box>
    </Box>
  );
};
