import { Box, MenuItem, SelectChangeEvent, TextField } from '@mui/material';
import { FormSection } from './FormSection';
import SweepSelect from '../../common/SweepSelect';
import { useWebhooksContext } from './WebhooksContext';
import { AuthenticationComponents } from './AuthenticationComponents';

export enum CustomIntegrationAuthenticationType {
  BASIC = 'BASIC',
  API_KEY = 'API_KEY',
  BEARER_TOKEN = 'BEARER_TOKEN',
  TOKEN_TOKEN = 'TOKEN_TOKEN',
}

const AuthenticationTypeLabels = [
  { type: CustomIntegrationAuthenticationType.BASIC, label: 'Basic (user name and password)' },
  { type: CustomIntegrationAuthenticationType.API_KEY, label: 'API key' },
  { type: CustomIntegrationAuthenticationType.BEARER_TOKEN, label: 'Bearer token' },
  { type: CustomIntegrationAuthenticationType.TOKEN_TOKEN, label: 'Token' },
];

export const WebhookContent = () => {
  const { editWebhook, setEditWebhook } = useWebhooksContext();

  return (
    <Box>
      <FormSection label="Integration name">
        <TextField
          sx={{ width: '100%' }}
          placeholder={'e.g. Acme'}
          value={editWebhook?.name}
          onChange={(event) => {
            const newValue = event?.target.value;
            setEditWebhook({ ...editWebhook, name: newValue });
          }}
        />
      </FormSection>
      <FormSection label="Integration logo (optional)">
        <TextField
          sx={{ width: '100%' }}
          placeholder={'Choose logo'}
          value={editWebhook?.baseUrl}
          onChange={(event) => {
            const newValue = event?.target.value;
            setEditWebhook({ ...editWebhook, name: newValue });
          }}
        />
      </FormSection>
      <FormSection label="Integration's URL">
        <TextField
          sx={{ width: '100%' }}
          placeholder={'e.g. www.acme.io'}
          value={editWebhook?.baseUrl}
          onChange={(event) => {
            const newValue = event?.target.value;
            setEditWebhook({ ...editWebhook, baseUrl: newValue });
          }}
        />
      </FormSection>
      <FormSection label="Authentication method">
        <SweepSelect
          FormControlProps={{
            sx: { width: 'fit-content' },
          }}
          SelectProps={{
            placeholder: 'Choose method',
            value: editWebhook?.authentication?.type,
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as CustomIntegrationAuthenticationType;
              setEditWebhook({ ...editWebhook, authentication: { type: val, key: '' } });
            },
          }}
        >
          {AuthenticationTypeLabels.map((elem) => {
            return (
              <MenuItem key={elem.type} value={elem.type}>
                {elem.label}
              </MenuItem>
            );
          })}
        </SweepSelect>
      </FormSection>
      <AuthenticationComponents />
    </Box>
  );
};
