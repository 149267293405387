import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';
import { environment, isProdEnvIndeed } from '../environment';

const INIT_MAP = {
  sentry: false,
  datadog: false,
  logrocket: false,
  clarity: false,
};

type MonitoringUser = {
  id?: string;
  email?: string;
  name?: string;
  accountName?: string;
  accountId?: string;
  accountType?: string;
  trialStatus: string;
  trialStartDate?: string;
  trialEndDate?: string;
  crmOrgsCount: number;
};

const initDataDog = () => {
  datadogRum.init({
    applicationId: '1d06024f-c19e-4726-8564-a8a92e5edb52',
    clientToken: 'pubb3cb1e056c1dea96db4341541fd87d9d',
    site: 'datadoghq.com',
    proxy: (options) => `https://ddrum.sweep.io${options.path}/?${options.parameters}`,
    service: 'sweep.io',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
  datadogRum.startSessionReplayRecording();
  INIT_MAP.datadog = true;
};

const initSentry = () => {
  Sentry.init({
    dsn: 'https://d72ec60a869348d48d3b745c8f6f7bfd@o1189061.ingest.sentry.io/6309317',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    environment: environment,
  });
  INIT_MAP.sentry = true;
};

const initLogRocket = () => {
  LogRocket.init('7759z2/sweep');
  INIT_MAP.logrocket = true;
  LogRocket.getSessionURL((sessionURL) => {
    if (INIT_MAP.sentry) {
      Sentry.getCurrentScope().setExtra('sessionURL', sessionURL);
    }
  });
};
const initClarity = () => {
  if ((window as any).clarity) {
    INIT_MAP.clarity = true;
  }
};

const setUserInDataDog = (user: MonitoringUser) => {
  datadogRum.setUser({
    id: user.id,
    email: user.email,
    name: user.name,
    accountName: user.accountName,
    accountId: user.accountId,
    plan: user.accountType,
    trialStartDate: user.trialStartDate,
    trialEndDate: user.trialEndDate,
    trialStatus: user.trialStatus,
    crmOrgsCount: user.crmOrgsCount,
  });
};

const setUserInLogRocket = (user: MonitoringUser) => {
  LogRocket.identify(user.id ?? '', {
    name: user.name ?? 'unknown',
    email: user.email ?? 'unknown',
    accountId: user.accountId ?? 'unknown',
    accountName: user.accountName ?? 'unknown',
  });
};
const setUserInClarity = (user: MonitoringUser) => {
  (window as any).clarity('set', 'email', user.email ?? 'unknown');
  (window as any).clarity('set', 'accountId', user.accountId ?? 'unknown');
  (window as any).clarity('set', 'accountName', user.accountName ?? 'unknown');
};

export const initMonitoring = (force = false) => {
  if (isProdEnvIndeed() || force) {
    initDataDog();
    initSentry();
    initLogRocket();
    initClarity();
  }
};

export const setUserDetailsForMonitoring = (user: MonitoringUser) => {
  if (INIT_MAP.datadog) {
    setUserInDataDog(user);
  }
  if (INIT_MAP.logrocket) {
    setUserInLogRocket(user);
  }
  if (INIT_MAP.clarity) {
    setUserInClarity(user);
  }
};
