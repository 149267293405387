import { DateTimeFormatOptions } from 'luxon';

interface HumanizeDateProps {
  dateOrTimestamp: string | number;
  variant?: humanizeDateVariants;
  timeZone?: string;
}

//TODO: talk with designers
export enum humanizeDateVariants {
  SHORT_WITHOUT_TIME = 'shortWithoutTime', // eg. Aug 04, 2023
  SHORT_WITH_TIME = 'shortWithTime', //  eg. Aug 04, 2023 02:20pm
  WITH_DAY_AND_TIME = 'withDayAndTime', // eg. Friday, August 4, 2023 at 11:32 AM
  TWO_DIGITS_WITH_TIME = 'twoDigitsWithTime', // eg. 8/4/2023, 10:44 am
  TIME_WITHOUT_DATE = 'timeWithoutDate', // eg. 12:20 pm
}

const getVariantProperties = (variant: humanizeDateVariants): DateTimeFormatOptions | undefined => {
  switch (variant) {
    case humanizeDateVariants.SHORT_WITHOUT_TIME:
      return {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      };

    case humanizeDateVariants.SHORT_WITH_TIME:
      return {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: 'numeric',
        minute: 'numeric',
      };

    case humanizeDateVariants.WITH_DAY_AND_TIME:
      return {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

    case humanizeDateVariants.TWO_DIGITS_WITH_TIME:
      return {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };

    case humanizeDateVariants.TIME_WITHOUT_DATE:
      return {
        hour: 'numeric',
        minute: 'numeric',
      };

    default:
      return undefined;
  }
};

const customizeRenderedValue = (converted: string, variant: humanizeDateVariants) => {
  switch (variant) {
    case humanizeDateVariants.TWO_DIGITS_WITH_TIME:
      return converted;
  }

  return converted;
};

export const humanizeDate = ({
  dateOrTimestamp,
  variant = humanizeDateVariants.SHORT_WITHOUT_TIME,
  timeZone = 'UTC',
}: HumanizeDateProps) => {
  const dateObject = dateOrTimestamp ? new Date(dateOrTimestamp) : '';

  if (dateObject) {
    const options = getVariantProperties(variant);
    const converted =
      variant === humanizeDateVariants.TIME_WITHOUT_DATE
        ? dateObject.toLocaleTimeString('en-US', { ...options, timeZone })
        : dateObject.toLocaleDateString('en-US', { ...options, timeZone });

    return customizeRenderedValue(converted, variant);
  }

  return '';
};
