import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronUp, SelectionBackground } from '@sweep-io/sweep-design/dist/icons';
import { Typography } from '@sweep-io/sweep-design';

import { settingsPanelLayout } from './helpers';
import { MergeFieldRow } from './MergeFieldRow';

interface MergeRecordFieldsFilterPanelProps {
  mergeRecordsFields: SweepCriterion[];
  readonly?: boolean;
  crmOrgId: string;
  objectType: string;
  onChange: (fields?: SweepCriterion[]) => any;
}

const MergeRecordFieldsFilterPanel = ({
  readonly,
  crmOrgId,
  objectType,
  mergeRecordsFields,
  onChange,
}: MergeRecordFieldsFilterPanelProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(mergeRecordsFields?.length > 0);

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <SelectionBackground variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Advanced merge settings</Typography>
              <Typography variant="body">Set up the merging logic for specific fields</Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <>
            {mergeRecordsFields.length === 0 && (
              <MergeFieldRow
                disableLookupItems={true}
                readonly={readonly}
                objectName={objectType}
                crmOrgId={crmOrgId}
                onChange={(field) => {
                  onChange([field]);
                }}
              />
            )}
            {mergeRecordsFields?.map((field, idx) => (
              <MergeFieldRow
                allFields={mergeRecordsFields}
                disableLookupItems={true}
                readonly={readonly}
                crmOrgId={crmOrgId}
                key={field.criterionId}
                objectName={objectType}
                field={field}
                onChange={(field) => {
                  const newFields = [...(mergeRecordsFields || [])];
                  if (newFields) {
                    newFields[idx] = field;
                    onChange(newFields);
                  }
                }}
                onDelete={() => {
                  const tempFields = [...(mergeRecordsFields || [])];
                  tempFields.splice(idx, 1);
                  onChange(tempFields);
                }}
                onAdd={(emptyField) => {
                  let tempFields = mergeRecordsFields || [];
                  tempFields = [...tempFields, emptyField];
                  onChange(tempFields);
                }}
              />
            ))}
          </>
        </Box>
      </Collapse>
    </Box>
  );
};

export default MergeRecordFieldsFilterPanel;
