import { useCallback, useContext, useEffect, useState } from 'react';
import { CanvasHighlightContext } from './internal-context/HighlightContext';
import { colors } from '@sweep-io/sweep-design';

const HIGHLIGHT_TIMEOUT = 2000;

export interface HighlightEdgeData {
  highlightedEdgeId: string;
}

export const useHighlightEdgeStyle = () => {
  const { highlightedEdgeId } = useContext(CanvasHighlightContext);
  if (!highlightedEdgeId) {
    return {};
  }

  return {
    [`.edge-${highlightedEdgeId}`]: {
      path: {
        stroke: colors.blue[500] + ' !important',
      },
      '.bullet': {
        fill: colors.blue[500] + ' !important',
      },
    },
  };
};

export const useHighlightEdge = ({ disableHighlight }: { disableHighlight?: boolean }) => {
  const { setHighlightedEdgeId, highlightedEdgeId } = useContext(CanvasHighlightContext);
  const [highlightTimeoutInstance, setHighlightTimeoutInstance] = useState<NodeJS.Timeout>();

  const highlightEdge = useCallback(
    (id: string) => {
      if (highlightTimeoutInstance) {
        clearTimeout(highlightTimeoutInstance);
      }

      if (!disableHighlight) {
        const _highlightTimeoutInstance = setTimeout(
          () => setHighlightedEdgeId(undefined),
          HIGHLIGHT_TIMEOUT,
        );
        setHighlightTimeoutInstance(_highlightTimeoutInstance);
        setHighlightedEdgeId(id);
      } else {
        setHighlightedEdgeId(undefined);
      }
    },
    [disableHighlight, highlightTimeoutInstance, setHighlightedEdgeId],
  );

  const removeEdgeHighlight = useCallback(() => {
    highlightTimeoutInstance && clearTimeout(highlightTimeoutInstance);
    setHighlightedEdgeId(undefined);
  }, [highlightTimeoutInstance, setHighlightedEdgeId]);

  useEffect(() => {
    if (disableHighlight) {
      removeEdgeHighlight();
    }
  }, [disableHighlight, removeEdgeHighlight]);

  return {
    highlightedEdgeId,
    highlightEdge,
    removeEdgeHighlight,
  };
};
