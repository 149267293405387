import { memo } from 'react';
import { Box } from '@mui/material';
import NodePill, { PillType } from './NodePill';
import { Typography } from '@sweep-io/sweep-design';
import { NodeEntityTypes, CanvasPill, OnNodeClickProps } from '../../../canvasTypes';
import { chunk } from 'lodash';
import pluralize from 'pluralize';
import { pillsMap } from './pillsIconMap';

interface PillsProps {
  onPillClick?: ({ id, parentId, entity }: OnNodeClickProps) => any;
  objectType: ObjectTypeValues;
  nodeId: string;
  parentNodeId?: string;
  pills: CanvasPill[];
  entityType: NodeEntityTypes;
  pillsMax?: number;
  nodeLabel: string;
}

export const Pills = memo(
  ({
    onPillClick,
    nodeId,
    parentNodeId,
    objectType,
    pills,
    entityType,
    pillsMax,
    nodeLabel,
  }: PillsProps) => {
    const moreAmount = (pills: CanvasPill[]) => pills.reduce((acc, pill) => acc + pill.amount, 0);

    const moreAmountLabel = (pills: CanvasPill[]) => {
      return (
        <>
          {pills.map((pill) => (
            <div key={pill.type + pill.amount}>
              {pill.amount} {pluralize(pillsMap[pill.type].tooltip, pill.amount)}
            </div>
          ))}
        </>
      );
    };

    const maxAmount = pillsMax ?? 100000;

    const [regularPills, morePills] =
      pills.length > maxAmount ? chunk(pills, maxAmount - 1) : [pills, undefined];

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '5px',
          '& > div': {
            flexGrow: 1,
            minWidth: '30px',
            maxWidth: '38px',
          },
        }}
      >
        {regularPills.map((pill) => {
          const { type: canvasPillType, amount, highlight } = pill;
          const { tooltip, type: pillType } = pillsMap[canvasPillType];

          return (
            <Box
              data-testid={'node-pill-' + canvasPillType + '-' + nodeLabel}
              key={canvasPillType}
              onClick={
                onPillClick
                  ? (event) => {
                      event.stopPropagation();
                      onPillClick({
                        id: nodeId,
                        parentId: parentNodeId,
                        entity: {
                          type: entityType,
                          canvasPillType,
                        },
                        event,
                      });
                    }
                  : undefined
              }
            >
              <NodePill tooltip={tooltip} type={pillType} object={objectType} highlight={highlight}>
                <Typography variant="caption">{amount}</Typography>
              </NodePill>
            </Box>
          );
        })}
        {morePills && (
          <Box>
            <NodePill tooltip={moreAmountLabel(morePills)} type={PillType.more} object={objectType}>
              <Typography variant="caption">{moreAmount(morePills)}</Typography>
            </NodePill>
          </Box>
        )}
      </Box>
    );
  },
);
