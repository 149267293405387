import { Dialog, DialogTitle, Typography, Box, DialogContent, DialogActions } from '@mui/material';
import { setEditThirdPartyStep } from './thirdPartyFunnelsReducer';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import {
  removeThirdPartyFunnelStep,
  renameThirdPartyFunnelStep,
} from '../../reducers/united-canvas/unitedCanvasReducer';
import { selectThirdPartyFunnelStep } from '../../reducers/united-canvas/thirdPartyCanvasReducer';
import { useState } from 'react';
import SweepTextField from '../common/SweepTextField';

export const EditThirdPartyStepDialog = ({
  funnelId,
  stepId,
}: {
  funnelId: string;
  stepId: string;
}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(setEditThirdPartyStep(undefined));

  const onRemove = () => {
    dispatch(removeThirdPartyFunnelStep({ funnelId, stepId }));
    onClose();
  };
  const currentName = useSelector(selectThirdPartyFunnelStep(funnelId, stepId))?.name;

  const [name, setName] = useState<string>(currentName || '');
  const onSave = () => {
    dispatch(renameThirdPartyFunnelStep({ funnelId, stepId, newName: name.trim() }));
    onClose();
  };

  const isValueValid = name.trim().length > 0;

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h2">Edit step</Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton size="small" variant="flat" onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          paddingTop: 2,
          width: '374px',
        }}
      >
        <SweepTextField
          FormControlProps={{ fullWidth: true }}
          label="Name"
          id="funnel-description"
          autoFocus
          TextFieldProps={{
            fullWidth: true,
            placeholder: 'Type a name for the integration step',

            value: name,
            onChange: (e) => setName(e.target.value),
          }}
        />
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Button onClick={onRemove} variant="link" type="error">
          Delete
        </Button>
        <Button onClick={onSave} disabled={!isValueValid}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
