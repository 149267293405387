import { colors, Typography } from '@sweep-io/sweep-design';
import { DotDivider } from '../../../common/DotDivider';
import { CenteredRowWithGap } from '../atoms/CenteredRowWithGap';
import { ReactNode } from 'react';

export const WrapperWithDotDivider = ({ txt, isActive }: { txt: ReactNode; isActive: boolean }) => (
  <CenteredRowWithGap gap={0.5} ml={-0.5}>
    <DotDivider dotColor={isActive ? colors.grey[700] : colors.grey[500]} />
    {typeof txt === 'string' && (
      <Typography
        variant="caption"
        color={isActive ? colors.grey[700] : colors.grey[500]}
        whiteSpace="nowrap"
      >
        {txt}
      </Typography>
    )}
    {typeof txt !== 'string' && txt}
  </CenteredRowWithGap>
);
