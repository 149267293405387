import { Box } from '@mui/material';
import { Typography, colors, Tag } from '@sweep-io/sweep-design';

export const EnvironmentNameTagWithPrefix = ({
  prefixText,
  crmOrgName,
  isMain,
}: {
  prefixText: string;
  crmOrgName: string;
  isMain: boolean;
}) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
    <Typography variant="caption">{prefixText}</Typography>
    <Tag label={crmOrgName} color={colors.grey[100]} withProductionDot={isMain} />
  </Box>
);
