import { Box } from '@mui/material';
import dependenciesBlurredImage from './images/dependenciesBlurredImage.png';
import upgradeImage from './images/upgradeImage.png';
import PaywallBlockUpgrade from '../../dashboard/paywall/PaywallBlockUpgrade';
import PaywallBlockStartTrial from '../../dashboard/paywall/PaywallBlockStartTrial';
import PaywallVerticalLayout from '../../dashboard/paywall/sales-activated-trial/PaywallVerticalLayout';
import { PaywallSource } from '../../dashboard/paywall/paywallTypes';
import usePaywall, { PaywallAction } from '../../dashboard/paywall/usePaywall';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';

const TITLE = 'Upgrade to access custom object Documentation';
const FEATURE_DESCRIPTION = 'AI-powered documentation for any Salesforce object';

const DependenciesPaywallBlock = ({ objectName }: { objectName?: string }) => {
  const { paywallAction, sendEncounterPaywallEvent } = usePaywall();

  useRunOnceWhenTruthy(() => {
    sendEncounterPaywallEvent({ source: PaywallSource.DEPENDENCIES, objectName });
  }, paywallAction !== PaywallAction.NONE);

  //TODO change to "isPaywallActive" SWEEP-5282
  if (paywallAction === PaywallAction.NONE) {
    return null;
  }

  //TODO change to "isPaywallActive" SWEEP-5282
  if (paywallAction === PaywallAction.TALK_TO_SALES) {
    return (
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          padding: '24px',
        }}
      >
        <PaywallVerticalLayout paywallSource={PaywallSource.DEPENDENCIES} variant="regular" />
      </Box>
    );
  }

  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
      <img src={dependenciesBlurredImage} width={'100%'} alt="Dependencies paywall image" />
      <Box
        sx={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          top: '20%',
          padding: '0 74px',
        }}
      >
        {paywallAction === PaywallAction.START_TRIAL && (
          <PaywallBlockStartTrial
            title={TITLE}
            featureDescription={FEATURE_DESCRIPTION}
            source={PaywallSource.DEPENDENCIES}
          />
        )}
        {paywallAction === PaywallAction.UPGRADE && (
          <PaywallBlockUpgrade
            title={TITLE}
            featureDescription={FEATURE_DESCRIPTION}
            img={<img src={upgradeImage} width="148px" alt="Dependencies paywall image" />}
          />
        )}
      </Box>
    </Box>
  );
};

export default DependenciesPaywallBlock;
