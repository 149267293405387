import { useDispatch } from 'react-redux';
import { useSweepApi } from '../apis/sweep';
import { setDeploymentsList, setIsLoadingDeployments } from '../reducers/deploymentsReducer';
import { useCallback } from 'react';
import { useDeploymentsApi } from '../apis/facades/useDeploymentsApi';

const useDeployments = () => {
  const dispatch = useDispatch();
  const sweepApi = useSweepApi();
  const { get_deployment } = useDeploymentsApi();

  const fetchDeployments = useCallback(
    async ({
      chosenOrgId,
      funnelId,
      active,
    }: {
      chosenOrgId?: string;
      funnelId?: string;
      active?: boolean;
    }) => {
      dispatch(setIsLoadingDeployments());
      const funnelIdStr = funnelId ? `?funnelId=${funnelId}&active=${!!active}` : '';
      const chosenOrgIdStr = chosenOrgId ? `/crm-orgs/${chosenOrgId}` : '';

      const response = await sweepApi.get(`/deployments${chosenOrgIdStr}${funnelIdStr}`);
      const deploymentsList = response.data;
      dispatch(setDeploymentsList({ deploymentsList }));
    },
    [dispatch, sweepApi],
  );

  const fetchDeployment = useCallback(
    async ({ deploymentId }: { deploymentId: string }) => get_deployment({ deploymentId }),
    [get_deployment],
  );

  return {
    fetchDeployments,
    fetchDeployment,
  };
};

export { useDeployments };
