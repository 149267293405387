import { useEffect, useState } from 'react';
import { ReactFlowInstance } from 'reactflow';
import { SweepCanvasNode, DEFAULT_CANVAS_ZOOM } from './canvasTypes';

export const useCenterOnFirstNode = ({
  reactFlowInstance,
  sweepNodes,
}: {
  reactFlowInstance?: ReactFlowInstance;
  sweepNodes: SweepCanvasNode[];
}) => {
  const [wasFirstZoomSet, setWasFirstZoomSet] = useState(false);
  useEffect(() => {
    if (!wasFirstZoomSet && reactFlowInstance && sweepNodes.length === 0) {
      reactFlowInstance.setCenter(0, 0, {
        zoom: DEFAULT_CANVAS_ZOOM,
      });
      setWasFirstZoomSet(true);
    }
  }, [reactFlowInstance, sweepNodes.length, wasFirstZoomSet]);
};
