import { Tabs } from '@sweep-io/sweep-design';
import { useCallback, useContext, useEffect, useState } from 'react';
import { HubspotCampaignForm, HubspotCampaignFormHeader } from './HubspotCampaignForm';
import { CampaignBase, Campaign } from './campaignTypes';
import { selectCampaignById } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { EnrollmentRulesTab } from './EnrollmentRulesTab';
import { useCampaigns } from './useCampaigns';
import { isEqual } from 'lodash';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';
import { telemetry } from '../../../../../telemetry';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { Box, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import { ACTIONS_EVENTS } from '../../../../../services/events';

interface HubspotCampaignContentProps {
  initialBaseCampaignId?: string;
}

const emptyCampaign: CampaignBase = {
  Name: '',
};

export const HubspotCampaignContent = ({ initialBaseCampaignId }: HubspotCampaignContentProps) => {
  const [baseCampaignId, setBaseCampaignId] = useState(initialBaseCampaignId);
  const { addNotification } = useSweepNotifications();
  const sendBiEvent = useSendBiEvent();

  const baseCampaign = useSelector(selectCampaignById(baseCampaignId || ''));

  const { createCampaign, updateCampaign } = useCampaigns();
  const [tab, setTab] = useState<'settings' | 'rules'>('settings');

  const [editingCampaign, setEditingCampaign] = useState<
    Campaign | (CampaignBase & { Id?: string })
  >(baseCampaign || emptyCampaign);

  useEffect(() => {
    // If base campaign changes, reset editing campaign
    // This happens when a new campaign is created
    setEditingCampaign(baseCampaign ?? emptyCampaign);
  }, [baseCampaign]);

  const [isSaving, setIsSaving] = useState(false);

  const { setIsPristine } = useContext(HubspotCampaignCtx);
  const isPristine = isEqual(editingCampaign, baseCampaign);

  useEffect(() => {
    setIsPristine(isPristine);
  }, [isPristine, setIsPristine]);

  const handleCampaignChange = useCallback((partialCampaign: Partial<CampaignBase>) => {
    setEditingCampaign((workflow) => ({ ...workflow, ...partialCampaign }));
  }, []);

  const handleCampaignDeployCreate = useCallback(async () => {
    setIsSaving(true);
    let savedCampaign: Campaign;
    try {
      if (editingCampaign.Id) {
        await updateCampaign(editingCampaign as Campaign);
        savedCampaign = editingCampaign as Campaign;
      } else {
        savedCampaign = await createCampaign(editingCampaign);
        setTab('rules');
      }
      sendBiEvent({ name: ACTIONS_EVENTS.salesforceCampaignDeploySuccess });
      setBaseCampaignId(savedCampaign.Id);
    } catch (error: any) {
      telemetry.captureError('Error saving campaign', error);
      addNotification({
        message: `Fail to save campaign`,
        keepOpen: true,
        details: error.response.data.message,
        variant: SweepNotificationVariant.Error,
      });
    }

    setIsSaving(false);
  }, [editingCampaign, sendBiEvent, updateCampaign, createCampaign, addNotification]);

  const maybeRenderCampaignForm = () => {
    if (tab === 'settings') {
      return (
        <HubspotCampaignForm
          campaign={editingCampaign}
          onChange={handleCampaignChange}
          isSaving={isSaving}
        />
      );
    }
  };
  const maybeRenderRulesTab = () => {
    if (tab === 'rules') {
      return (
        <EnrollmentRulesTab
          campaignId={baseCampaign?.Id}
          campaignName={baseCampaign?.Name || ''}
          campaignIsActive={baseCampaign?.IsActive}
        />
      );
    }
  };

  const maybeRenderCampaignHeader = () => {
    if (tab === 'settings') {
      return (
        <HubspotCampaignFormHeader
          campaign={editingCampaign}
          onChange={handleCampaignChange}
          onDeploy={handleCampaignDeployCreate}
          isSaving={isSaving}
        />
      );
    }
  };

  // The DialogOnCanvas needs to be fixed for the content scroll. IT's not working properly.
  // This is a temporary fix.
  const height = tab === 'settings' ? 'calc(100% - 150px)' : 'calc(100% - 90px)';
  return (
    <>
      <DialogTitle sx={{ padding: 0 }}>
        <Box ml={2}>
          <Tabs
            tabs={[
              {
                label: 'Campaign Settings',
                value: 'settings',
              },
              {
                label: 'Enrollment Rules',
                value: 'rules',
              },
            ]}
            value={tab}
            onChange={(e, tab) => {
              setTab(tab as 'settings' | 'rules');
            }}
          />
        </Box>
        {maybeRenderCampaignHeader()}
      </DialogTitle>
      <DialogContent
        sx={{
          '&.MuiDialogContent-root': {
            height,
            overflow: 'scroll',
            padding: 0,
          },
        }}
      >
        {maybeRenderCampaignForm()}
        {maybeRenderRulesTab()}
      </DialogContent>
      <DialogActions></DialogActions>
    </>
  );
};
