import { useCallback } from 'react';
import { usePlacingPluginContext } from '../../placing-plugin/PlacingPluginContext';
import { usePanels } from '../../../../../panels/PanelsContext';
import { PluginTypes } from '../../../../../../types/enums/PluginTypes';

export const useOnAddCriteriaPlugin = ({
  pluginImage,
  type,
}: {
  type: PluginTypes;
  pluginImage: React.ReactNode;
}) => {
  const { setPlacingPlugin } = usePlacingPluginContext();
  const { forceCloseAllPanels } = usePanels();

  const onAdd = useCallback(() => {
    forceCloseAllPanels();
    setPlacingPlugin({
      pluginImage,
      type,
    });
  }, [forceCloseAllPanels, pluginImage, setPlacingPlugin, type]);

  return onAdd;
};
