import { useDispatch } from 'react-redux';
import { useTerritoriesApiFacade } from '../apis/facades/useTerritoriesApiFacade';
import {
  createTerritoriesListItem,
  deleteTerritoriesListItem,
  setTerritoriesList,
  updateTerritoriesListItem,
} from '../reducers/territoriesReducer';
import { ACTIONS_EVENTS } from '../services/events';
import useSendBiEvent from './useSendBiEvent';
import { telemetry } from '../telemetry';

const useTerritoriesActions = () => {
  const sendBiEvent = useSendBiEvent();
  const dispatch = useDispatch();
  const { get_territories, post_territories, put_territories, delete_territories } =
    useTerritoriesApiFacade();

  const getTerritories = async () => {
    try {
      const territories = await get_territories();
      dispatch(setTerritoriesList({ territories }));
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const createTerritory = async (toBeCreated: Territory) => {
    try {
      const newItem = await post_territories(toBeCreated);
      dispatch(createTerritoriesListItem({ newItem }));
      sendBiEvent({ name: ACTIONS_EVENTS.territoriesAdd });
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const updateTerritory = async (_id: string, toBeCreated: Territory) => {
    try {
      const newItem = await put_territories(_id, toBeCreated);
      dispatch(updateTerritoriesListItem({ newItem }));
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const deleteTerritory = async (_id: string) => {
    try {
      const isDeleted = await delete_territories(_id);
      if (isDeleted) {
        dispatch(deleteTerritoriesListItem({ _id }));
        sendBiEvent({ name: ACTIONS_EVENTS.territoriesDelete });
      }
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  return {
    getTerritories,
    createTerritory,
    updateTerritory,
    deleteTerritory,
  };
};

export { useTerritoriesActions };
