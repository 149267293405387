import * as React from "react";
const SvgDataManagement = (props) => /* @__PURE__ */ React.createElement("svg", { width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#C5F2AC", style: {
  fill: "#C5F2AC",
  fill: "color(display-p3 0.7725 0.9490 0.6745)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M23.141 26.5264L10.081 26.5264C9.83108 26.5264 9.5914 26.4271 9.41468 26.2504C9.23795 26.0737 9.13867 25.834 9.13867 25.5841V9.5644C9.13867 9.31448 9.23795 9.0748 9.41468 8.89807C9.5914 8.72135 9.83108 8.62207 10.081 8.62207H16.3593C16.5631 8.62291 16.7612 8.68895 16.9247 8.81054L20.1993 11.2606C20.3628 11.3822 20.561 11.4482 20.7647 11.4491H30.8123C31.0623 11.4491 31.302 11.5484 31.4787 11.7251C31.6554 11.9018 31.7547 12.1415 31.7547 12.3914V21.919", fill: "#A5BEFF", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M23.141 26.5264L10.081 26.5264C9.83108 26.5264 9.5914 26.4271 9.41468 26.2504C9.23795 26.0737 9.13867 25.834 9.13867 25.5841V9.5644C9.13867 9.31448 9.23795 9.0748 9.41468 8.89807C9.5914 8.72135 9.83108 8.62207 10.081 8.62207H16.3593C16.5631 8.62291 16.7612 8.68895 16.9247 8.81054L20.1993 11.2606C20.3628 11.3822 20.561 11.4482 20.7647 11.4491H30.8123C31.0623 11.4491 31.302 11.5484 31.4787 11.7251C31.6554 11.9018 31.7547 12.1415 31.7547 12.3914V21.919", stroke: "#373650", style: {
  stroke: "#373650",
  stroke: "color(display-p3 0.2157 0.2118 0.3137)",
  strokeOpacity: 1
}, strokeWidth: 1.74722, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M22.0768 18.5454C22.4032 18.3994 22.7416 18.2817 23.0882 18.1935L24.7055 16.2601C25.9673 16.2217 27.2223 16.459 28.3829 16.9556L29.17 19.3581C29.4594 19.5681 29.7304 19.8025 29.9798 20.0587L32.469 20.4921C33.129 21.5691 33.5507 22.775 33.7057 24.0286L32.0196 25.9141C31.9776 26.2693 31.9102 26.6211 31.818 26.9667L32.6815 29.3318C32.0806 30.4445 31.2449 31.4133 30.2325 32.1711L27.7677 31.6559C27.4376 31.7994 27.0966 31.9165 26.748 32.0062L25.1306 33.9395C23.8688 33.978 22.6138 33.7406 21.4532 33.244L20.6662 30.8416C20.3767 30.6316 20.1058 30.3972 19.8564 30.1409L17.3672 29.7076C16.7072 28.6306 16.2855 27.4247 16.1304 26.1711L17.8165 24.2856C17.8586 23.9303 17.9259 23.5786 18.0181 23.2329L17.1546 20.8678C17.7556 19.7551 18.5912 18.7863 19.6036 18.0285L22.0768 18.5454Z", fill: "#FFADF7", stroke: "#373650", style: {
  fill: "#FFADF7",
  fill: "color(display-p3 1.0000 0.6792 0.9679)",
  fillOpacity: 1,
  stroke: "#373650",
  stroke: "color(display-p3 0.2157 0.2118 0.3137)",
  strokeOpacity: 1
}, strokeWidth: 1.74722, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M28.0899 25.7089C28.4253 23.9571 27.2771 22.2651 25.5254 21.9296C23.7736 21.5941 22.0815 22.7423 21.7461 24.4941C21.4106 26.2459 22.5588 27.938 24.3106 28.2734C26.0624 28.6089 27.7544 27.4607 28.0899 25.7089Z", fill: "#A5BEFF", stroke: "#373650", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1,
  stroke: "#373650",
  stroke: "color(display-p3 0.2157 0.2118 0.3137)",
  strokeOpacity: 1
}, strokeWidth: 1.65415, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgDataManagement;
