import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@sweep-io/sweep-design';

const RequiredDot = styled(Box)({
  height: '5px',
  width: '5px',
  backgroundColor: colors.blush[500],
  borderRadius: '50%',
  display: 'inline-block',
  marginLeft: '5px',
});

export default RequiredDot;
