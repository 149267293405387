import { useState } from 'react';
import { Node as ReactFlowNode, Edge as ReactFlowEdge } from 'reactflow';

interface HoveredElement {
  type: 'node' | 'edge';
}
interface HoveredNode extends HoveredElement {
  type: 'node';
  node: ReactFlowNode;
}

interface HoveredEdge extends HoveredElement {
  type: 'edge';
  edge: ReactFlowEdge;
}

export type HoveredElements = HoveredNode | HoveredEdge;

export const useHoveredElement = () => {
  const [hoveredElement, setHoveredElement] = useState<HoveredElements>();

  return {
    hoveredElement,
    setHoveredElement,
  };
};
