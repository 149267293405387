export enum DocumentationTabTypes {
  RECORD_TYPES = 'Record Types',
  SF_AUTOMATIONS = 'Salesforce Automations',
  APEX = 'Apex',
  FIELDS = 'Fields',
  VALIDATION_RULES = 'Validation Rules',
  SWEEP_AUTOMATIONS = 'Sweep Automations',
  PLAYBOOK_ALERTS = 'Alerts',
  MATCHING_DEDUPE = 'Dedupe & Matching',
  ASSIGNMENTS = 'Assignments',
  SCHEDULED_ASSIGNMENTS = 'Scheduled Assignments',
  CPQ_DATA = 'CPQ Configuration',
  CARDS_LIST = 'elements', //main view
  LAYOUTS = 'Layouts',
}

export const SWEEP_ELEMENTS_TAB_TYPES = [
  DocumentationTabTypes.MATCHING_DEDUPE,
  DocumentationTabTypes.PLAYBOOK_ALERTS,
  DocumentationTabTypes.SWEEP_AUTOMATIONS,
  DocumentationTabTypes.SCHEDULED_ASSIGNMENTS,
  DocumentationTabTypes.ASSIGNMENTS,
];
