import { memo } from 'react';
import { NODE_WIDTH, NODE_HEIGHT } from '../const';
import { NodeComponent } from './components/SweepNodeComponent';
import { SweepNode } from './SweepNode';

const VirtualDropNode = () => (
  <SweepNode>
    <NodeComponent
      sx={{
        width: `${NODE_WIDTH}px`,
        height: `${NODE_HEIGHT}px`,
        border: '2px solid transparent',
        cursor: 'default',
        background: 'black',
        opacity: 0.04,
      }}
    />
  </SweepNode>
);

const _memoVirtualDropNode = memo(VirtualDropNode);

export { _memoVirtualDropNode as VirtualDropNode };
