import { ParsedRecordType, ParsedRule } from '../../../parser/ParserTypes';
import { FULL_NAME_SEPARATOR } from '../../../funnel-map-canvas/helper';
import { DeployStatusForTable } from '../../../../types/enums/DeployStatusForTable';
import { ConfigurationType } from '../../dependencies/types';
import { MASTER_KEY } from '../../../pages/funnel-map-flow/dialogs/import-funnel/utils';

export const createRecordTypeId = (recordTypeName: string, objectName: string) =>
  [objectName, recordTypeName].join('.');

export const findRTStepFromFunnelStep = (
  funnel: Funnel,
  stepName: string,
  parsedRecordTypes?: ParsedRecordType[],
) => {
  const recordTypeName = funnel.recordType.name ?? MASTER_KEY;
  const leadingField = funnel.funnelDetails.leadingObject.fieldName;
  const parsedRT = parsedRecordTypes?.find(
    (rt) => rt.name === recordTypeName && rt.objectApiName.includes(funnel.recordType.objectName),
  );
  const parsedLF = parsedRT?.leadingFields.find((lf) => lf.name === leadingField);

  return parsedLF?.values.find((value) => value.label === stepName);
};

export const createStepId = (recordTypeName: string, objectName: string, stepFullName: string) =>
  `${createRecordTypeId(recordTypeName, objectName)}${FULL_NAME_SEPARATOR}${stepFullName}`;

export const getStepFullNameFromId = (id: string) => id.split(FULL_NAME_SEPARATOR)?.[1];
export const getParentFromStepId = (id: string) => id.split(FULL_NAME_SEPARATOR)?.[0];

export const updateParsedRuleWithParentElementType = (
  parentType: ConfigurationType,
  items?: ParsedRule[],
) => items?.map((item) => ({ ...item, parentType })) ?? [];

export const updateStructureWithParentElementType = (
  parentType: ConfigurationType,
  objectName: string,
  items?: AutomationStructureNew[],
) =>
  items
    ?.map((item) => ({ ...item, parentType }))
    .filter((item) => item.objectName === objectName) ?? [];

export const getDeployedVersions = (automations?: AutomationStructureNew[]) =>
  automations?.filter((automation) => automation.status !== DeployStatusForTable.Draft) ?? [];

export const getOnlyAutomationsOfObject = (
  automations: AutomationStructureNew[],
  objectName: string,
) => automations?.filter((automation) => automation.objectName !== objectName);
