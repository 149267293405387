import { useEffect, useState } from 'react';
import useWebSocket from 'react-use-websocket';
import { useAuth0 } from '@auth0/auth0-react';
import { WEBSOCKET_MESSAGES } from '@server/websocket-messages';
import { useRunOnceWhenTruthy } from '../components/common/useRunOnceWhenTruthy';
import { useDispatch } from 'react-redux';
import useSocketHandlers from './useSocketHandlers';

type Message = {
  msgName: string;
  payload: any;
};

const WEBSOCKET_ENDPOINT = import.meta.env.VITE_WEBSOCKET_URL;

const useWebSockets = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();
  const [authToken, setAuthToken] = useState<string>();
  const [socketUrl, setSocketUrl] = useState('');

  const {
    fetchCompletedHandler,
    slackConnectionCompletedHandler,
    slackDisconnectionCompletedHandler,
    reportingActivationCompletedHandler,
    notificationsNewHandler,
    sfChangeFeedNotificationReceived,
    automationsDeployCompletedHandler,
    automationsDeleteCompletedHandler,
    rollupsDeployCompletedHandler,
    devOpsCenterElementsDeployCompletedHandler,
    deploymentCompletedHandler,
    parseCompletedHandler,
  } = useSocketHandlers();

  useEffect(() => {
    if (authToken) {
      setSocketUrl(`${WEBSOCKET_ENDPOINT}?auth=${authToken}`);
    }

    //disconnect upon unmount
    return () => {
      setSocketUrl('');
    };
  }, [authToken]);

  useRunOnceWhenTruthy(async () => {
    getAccessTokenSilently().then(setAuthToken, () =>
      console.warn('Failed to get auth token. No sockets will be opened.'),
    );
  }, isAuthenticated);

  const onMessage = async (event: MessageEvent) => {
    const msgJson = await event?.data;
    const msg: Message = JSON.parse(msgJson);
    const { msgName, payload } = msg;
    switch (msgName) {
      case WEBSOCKET_MESSAGES.FETCH_COMPLETED: {
        fetchCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.DATA_INTEGRATION_SLACK_CONNECTION_COMPLETED: {
        slackConnectionCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.DATA_INTEGRATION_SLACK_DISCONNECTION_COMPLETED: {
        slackDisconnectionCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.REPORTING_ACTIVATION_COMPLETED: {
        reportingActivationCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.NOTIFICATIONS_REFETCH: {
        notificationsNewHandler();
        break;
      }
      case WEBSOCKET_MESSAGES.NEW_CHANGE_FEED_LIST_ITEMS: {
        sfChangeFeedNotificationReceived();
        break;
      }
      case WEBSOCKET_MESSAGES.AUTOMATIONS_TOGGLE_ACTIVATION_COMPLETED:
      case WEBSOCKET_MESSAGES.AUTOMATIONS_DEPLOY_COMPLETED: {
        automationsDeployCompletedHandler(payload, msgName);
        break;
      }
      case WEBSOCKET_MESSAGES.ROLLUPS_DEPLOY_COMPLETED: {
        rollupsDeployCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.AUTOMATIONS_DELETE_COMPLETED:
      case WEBSOCKET_MESSAGES.ROLLUPS_DELETE_COMPLETED: {
        automationsDeleteCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.DEVOPS_CENTER_DEPLOY_ELEMENTS_COMPLETED: {
        devOpsCenterElementsDeployCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.DEPLOYMENT_COMPLETED: {
        deploymentCompletedHandler(payload);
        break;
      }
      case WEBSOCKET_MESSAGES.PARSE_COMPLETED: {
        parseCompletedHandler(payload);
        break;
      }
      default: {
        console.warn(
          `No dedicated handler was found for the emitted message of type: ${msgName}, going to dispatch a redux action `,
        );
        dispatch({
          type: msgName,
          payload,
        });
      }
    }
  };

  useWebSocket(
    socketUrl,
    {
      onMessage,
      shouldReconnect: () => true,
    },
    Boolean(socketUrl),
  );

  // const connectionStatus = {
  //   [ReadyState.CONNECTING]: 'Connecting',
  //   [ReadyState.OPEN]: 'Open',
  //   [ReadyState.CLOSING]: 'Closing',
  //   [ReadyState.CLOSED]: 'Closed',
  //   [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  // }[readyState];
};

export default useWebSockets;
