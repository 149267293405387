import { colors } from '@sweep-io/sweep-design';

export const OrgDot = ({ styles }: { styles?: any }) => (
  <span
    style={{
      position: 'relative',
      color: colors.mint[500],
      right: 0,
      top: 0,
      fontSize: '10px',
      ...styles,
    }}
  >
    &#9679;
  </span>
);
