import { useMemo, useState } from 'react';
import {
  DataTableSortableColumn,
  DataTableSortStateProps,
  DataTableVariant,
} from '../../../common/table/TableTypes';
import { groupBy, keyBy, uniq } from 'lodash';

import {
  InlineAutocomplete,
  InlineAutocompleteItem,
} from '../../../common/InlineAutocomplete/InlineAutocomplete';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { Box, Stack } from '@mui/material';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { Tooltip, Typography, colors, IconButton } from '@sweep-io/sweep-design';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons/Close';
import { useDispatch } from 'react-redux';
import { setCrmOrgUsersBase } from '../../../../reducers/crmOrgUsersReducer';
import { DateTime } from 'luxon';
import { HistoryTimeOffs } from './helpers';
import { SearchInput } from '../../../common/SearchInput';
import { filterItemsBySearch } from '../../../../lib/filterItemsBySearch';
import { changeSweepFamilyWeight } from '../../../../lib/sweepColorUtilities';
import { useErrorHandling } from '../../../../hooks/useErrorHandling';
import { SortOrder } from '../../../common/types';
import { useRunOnce } from '../../../common/useRunOnce';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { VirtualScrollDataTable } from '../../../common/table/VirtualScrollDataTable';
import { useAssignmentGroups } from './useAssignmentGroups';
import useIsManagedPackageInstalled from '../../../common/install-managed-package/useIsManagedPackageInstalled';
import InlineAutocompleteEmptyState from '../../../common/InlineAutocomplete/InlineAutocompleteEmptyState';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { RolesAndProfilesFilters, useRolesAndProfileFilterProps } from '../RolesAndProfilesFilters';
import { AdvancedFilter } from '../../../common/advanced-filter/AdvancedFilter';
import { MembersListActions } from './MembersListActions';
import { useVerifyManagePackageDialog } from './useVerifyManagePackageDialog';
import { TableFilteredEmptyState } from '../../../common/TableFilteredEmptyState';
import { useRoutingContext } from '../../../routing/RoutingContext';
import { SEARCH_BOX_WIDTH } from '../../../Automations/helper';
import { HORIZONTAL_PADDING } from '../../configuration-canvas-panel/consts';

const EMPTY_STATE_BUTTON_TXT = 'Add to group';
const EMPTY_STATE_TXT = 'Member not assigned';

const deprecatedColumns: DataTableSortableColumn[] = [
  {
    field: 'name',
    headerName: 'Name',
    width: 'auto',
    showSortIcon: true,
  },
  {
    field: 'role',
    headerName: 'Role',
    hidden: true,
  },
  {
    field: 'profile',
    headerName: 'Profile',
    hidden: true,
  },
  {
    field: 'assignmentGroups',
    headerName: 'Assignment Groups',
    className: 'assignment-members__assignment-groups',
    width: 360,
  },
  {
    field: 'actions',
    headerName: '',
    width: 'auto',
    justifyContent: 'flex-end',
  },
];

const getColumns = (showTimeouts: Boolean) => {
  const columns: DataTableSortableColumn[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 240,
      showSortIcon: true,
      className: 'assignment-members__name',
    },
    {
      field: 'timeouts',
      headerName: '',
      width: 'auto',
      hidden: !showTimeouts,
    },
    {
      field: 'role',
      headerName: 'Role',
      width: 120,
    },
    {
      field: 'profile',
      headerName: 'Profile',
      width: 'auto',
      className: 'assignment-members__profile',
    },
    {
      field: 'assignmentGroups',
      headerName: 'Assignment Groups',
      className: 'assignment-members__assignment-groups',
      width: 380,
    },
    {
      field: 'actions',
      headerName: '',
      width: 'auto',
      justifyContent: 'flex-end',
    },
  ];
  return columns;
};

const TooltipTitle = ({ title, subtitle }: { title: string; subtitle: string }) => {
  return (
    <Box lineHeight={'14px'}>
      <Typography variant="caption-bold" color={colors.grey[300]}>
        {title}
      </Typography>
      <br />
      <Typography variant="caption-bold" color={colors.grey[500]}>
        {subtitle}
      </Typography>
    </Box>
  );
};

interface MembersListProps {
  crmOrgUsersBase: CrmOrgUserBase[];
  assignmentGroups: AssignmentGroup[];
  crmOrgId: string;
  onClose?: () => void;
  isDialogLayout?: boolean;
}

const MembersList = ({
  assignmentGroups,
  crmOrgId,
  crmOrgUsersBase,
  onClose,
  isDialogLayout,
}: MembersListProps) => {
  const dispatch = useDispatch();
  const { addUserToGroup, removeUserFromGroup, createNewAssignmentGroupWithMember } =
    useAssignmentsApiWithReducer();
  const { assignmentGroupRoles } = useFeatureToggle();

  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const { membersSearch, setMembersSearch } = useRoutingContext();
  const { setCompleteSetupDialogOpen, maybeInstallManagePackageDialogDialog } =
    useVerifyManagePackageDialog();
  const isManagedPackagedInstalled = useIsManagedPackageInstalled();
  const { crmOrgUsers } = useAssignmentGroups();
  const isLoadingUsers = isManagedPackagedInstalled && !crmOrgUsers;

  const [isCreatingSet, setIsCreatingSet] = useState<{ [userId: string]: boolean }>({});
  const rolesAndProfileFilterProps = useRolesAndProfileFilterProps({
    crmOrgUsers: crmOrgUsersBase,
  });
  const { selectedProfileItems, selectedRoleItems } = rolesAndProfileFilterProps;

  const { errorHandlingBuilder } = useErrorHandling();

  const groupItems: InlineAutocompleteItem<any>[] = assignmentGroups.map((assignmentGroup) => {
    const bgColor = assignmentGroup.avatar?.emoji?.bgColor;
    return {
      label: assignmentGroup.name,
      labelDecorator: assignmentGroup.avatar?.emoji?.content,
      tagColor: bgColor ? changeSweepFamilyWeight(bgColor, 100) : undefined,
      value: assignmentGroup.id,
    };
  });
  let hasAnyTimeouts = false;

  const renderTimeouts = (crmOrgUserBase: CrmOrgUserBase) => {
    const crmOrgUser = crmOrgUsers?.find((user) => user.id === crmOrgUserBase.id);
    const timeOffs = new HistoryTimeOffs(crmOrgUser?.timeOffs || []);
    const _activeSnooze = timeOffs.getActiveSnooze();
    const _hasActiveTimeOff = timeOffs.getActiveTimeOffUntil();
    const { fontVariant } = dataTableVariants[DataTableVariant.default];
    if (_activeSnooze || _hasActiveTimeOff) {
      hasAnyTimeouts = true;
    }
    return (
      <>
        {_activeSnooze && !_hasActiveTimeOff && (
          <Tooltip
            title={
              <TooltipTitle
                title="⏱️ Snoozed"
                subtitle={`Until ${_activeSnooze._dateTimeEndDate.toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}`}
              />
            }
          >
            <Typography variant={fontVariant} color={colors.grey[500]} whiteSpace="nowrap">
              ⏱️ Snoozed
            </Typography>
          </Tooltip>
        )}
        {_hasActiveTimeOff && (
          <Tooltip
            title={
              <TooltipTitle
                title="🚫 Unavailable"
                subtitle={`Until ${_hasActiveTimeOff._dateTimeEndDate.toLocaleString(
                  DateTime.DATETIME_SHORT,
                )}`}
              />
            }
          >
            <Typography variant={fontVariant} color={colors.grey[500]} whiteSpace="nowrap">
              🚫 Unavailable
            </Typography>
          </Tooltip>
        )}
      </>
    );
  };
  const renderUserGroups = ({
    groups = [],
    crmOrgUser,
  }: {
    groups: { groupId: string }[];
    crmOrgUser: CrmOrgUser;
  }) => {
    const selectedItemValues = groups.map((group) => group.groupId);
    if (!isManagedPackagedInstalled) {
      return (
        <InlineAutocompleteEmptyState
          emptyStateTxt={EMPTY_STATE_TXT}
          emptyStateButtonTxt={EMPTY_STATE_BUTTON_TXT}
          onClick={() => setCompleteSetupDialogOpen(true)}
        />
      );
    }
    return (
      <InlineAutocomplete
        headerTxt="Select a group or create one"
        emptyState={{ text: EMPTY_STATE_TXT, buttonText: EMPTY_STATE_BUTTON_TXT }}
        placeholder="Add groups"
        items={groupItems}
        selectedItemValues={selectedItemValues}
        onSelectItem={(item) => {
          addUserToGroup(item.value, crmOrgUser);
          setSelectedGroups([]);
        }}
        onDeleteItem={(item) => {
          removeUserFromGroup(item.value, crmOrgUser.id);
        }}
        createInProgress={isCreatingSet[crmOrgUser.id]}
        onCreate={async (groupName) => {
          errorHandlingBuilder()
            .withErrorNotification('Error creating group')
            .withOnError(() => {
              delete isCreatingSet[crmOrgUser.id];
              setIsCreatingSet({ ...isCreatingSet });
            })
            .execute(async () => {
              const newIsCreatingSet = { ...isCreatingSet, [crmOrgUser.id]: true };
              setIsCreatingSet(newIsCreatingSet);
              await createNewAssignmentGroupWithMember(groupName, crmOrgUser);
              setSelectedGroups([]);
              delete newIsCreatingSet[crmOrgUser.id];
              setIsCreatingSet({ ...newIsCreatingSet });
            });
        }}
        width={360}
        closeOnItemSelection={selectedItemValues.length === 0} // Close on first item selected.
      />
    );
  };

  const groupsPerUserIdMap = groupBy(
    assignmentGroups
      .map((assignmentGroup) =>
        assignmentGroup.members.map((member) => {
          return {
            userId: member.userId,
            groupId: assignmentGroup.id,
          };
        }),
      )
      .flat(),
    'userId',
  );

  const assignmentsGroupListById = keyBy(assignmentGroups, 'id');
  const usedGroupItems = uniq(
    Object.values(groupsPerUserIdMap)
      .map((groups) => groups.map((g) => g.groupId))
      .flat(),
  )
    .map((id) => assignmentsGroupListById[id])
    .map((group) => ({
      label: group.name,
      value: group.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  useRunOnce(() => {
    // Sort by group existence, then by name.
    // But only when the page loads to avoid user jumps when
    // adding/removing users from groups.

    const sortedCrmOrgUsersBase = [...crmOrgUsersBase].sort((a, b) => {
      const userAHasGroups = Boolean(groupsPerUserIdMap[a.id]?.length);
      const userBHasGroups = Boolean(groupsPerUserIdMap[b.id]?.length);

      const sortByGroupSize = Number(userBHasGroups) - Number(userAHasGroups);

      if (sortByGroupSize === 0) {
        return a.name.localeCompare(b.name); // Sort by name if group size is the same.
      }
      return sortByGroupSize;
    });

    dispatch(setCrmOrgUsersBase({ crmOrgId, crmOrgUsersBase: sortedCrmOrgUsersBase }));
  });

  const filteredCrmOrgUsersBase = filterItemsBySearch<CrmOrgUserBase>(
    crmOrgUsersBase,
    membersSearch,
    (crmOrgUser) => crmOrgUser.name,
  );

  const rows = filteredCrmOrgUsersBase.map((crmOrgUser) => {
    return {
      id: crmOrgUser.id,
      name: crmOrgUser.name,
      timeouts: renderTimeouts(crmOrgUser),
      assignmentGroups: renderUserGroups({
        groups: groupsPerUserIdMap[crmOrgUser.id],
        crmOrgUser,
      }),
      role: crmOrgUser.roleName,
      profile: crmOrgUser.profileName,
      actions: !isLoadingUsers && (
        <MembersListActions
          crmOrgUserId={crmOrgUser.id}
          crmOrgId={crmOrgId}
          crmOrgUsers={crmOrgUsers}
        />
      ),
      data: crmOrgUser,
    };
  });

  const renderFilters = () => {
    const userItems = [
      {
        label: '(No group)',
        value: '__no-group__',
      },
      ...usedGroupItems,
    ];

    return (
      <Box
        alignItems="center"
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: isDialogLayout ? 'space-between' : 'start',
          flexDirection: isDialogLayout ? 'row' : 'row-reverse',
          gap: 2,
        }}
      >
        <Stack direction="row" spacing={1}>
          <AdvancedFilter
            items={userItems}
            selectedItems={selectedGroups}
            onSelectedItemsChange={setSelectedGroups}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            texts={{
              title: 'Show users from:',
              allSelected: 'All groups',
            }}
          />
          {assignmentGroupRoles && <RolesAndProfilesFilters {...rolesAndProfileFilterProps} />}
        </Stack>
        <Box width={SEARCH_BOX_WIDTH}>
          <SearchInput
            withFixedMagnifyingGlassIcon
            TextFieldProps={{
              value: membersSearch,
              onChange: (e) => {
                setMembersSearch(e.target.value);
              },
              placeholder: 'Search members',
            }}
            onClearButtonClick={() => setMembersSearch('')}
            variant="small"
          />
        </Box>
      </Box>
    );
  };

  const [sortState, setSortState] = useState<DataTableSortStateProps | undefined>({
    sortBy: 'name',
    sortOrder: SortOrder.ASC,
  });

  const sortedFilteredRows = useMemo(
    () =>
      rows
        .filter((row) => {
          if (selectedGroups.length === 0) {
            return true;
          }
          const groups = groupsPerUserIdMap[row.id];
          if (!groups || groups.length === 0) {
            return selectedGroups.includes('__no-group__');
          }
          return groups.some((group) => selectedGroups.includes(group.groupId));
        })
        .filter((row) => {
          if (selectedRoleItems.length === 0) {
            return true;
          }
          return selectedRoleItems.includes(row.data.roleName || '');
        })
        .filter((row) => {
          if (selectedProfileItems.length === 0) {
            return true;
          }
          return selectedProfileItems.includes(row.data.profileName || '');
        })
        .sort(
          (a, b) =>
            a.data.name.localeCompare(b.data.name) *
            (sortState?.sortOrder === SortOrder.ASC ? 1 : -1),
        ),
    [
      groupsPerUserIdMap,
      rows,
      selectedGroups,
      selectedProfileItems,
      selectedRoleItems,
      sortState?.sortOrder,
    ],
  );

  const columns = useMemo(
    () => (assignmentGroupRoles ? getColumns(hasAnyTimeouts) : deprecatedColumns),
    [assignmentGroupRoles, hasAnyTimeouts],
  );

  const clearFilters = () => {
    setMembersSearch('');
    setSelectedGroups([]);
    rolesAndProfileFilterProps.setSelectedRoleItems([]);
    rolesAndProfileFilterProps.setSelectedProfileItems([]);
  };

  return (
    <Stack
      gap={2}
      height="100%"
      sx={{
        padding: isDialogLayout ? undefined : `0 ${HORIZONTAL_PADDING}px`,
      }}
    >
      {isDialogLayout && (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Typography variant="h1">Members</Typography>
          </Box>
          <IconButton variant="flat" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        {renderFilters()}
        <Box
          height="calc(100vh - 235px)"
          sx={{
            'th, td': {
              pr: 2,
            },
            '.MuiTableCell-root': {
              paddingTop: '2px !important',
              paddingBottom: '2px !important',
            },
            '.SweepDataTableRow .add-to-group': {
              visibility: 'hidden',
            },
            '.SweepDataTableRow:hover .add-to-group': {
              visibility: 'visible',
            },
            '.SweepDataTableRow  .assignments-members-table__action-buttons': {
              visibility: 'hidden',
            },
            '.SweepDataTableRow:hover .assignments-members-table__action-buttons': {
              visibility: 'visible',
            },
            '.assignment-members__profile .MuiTypography-root, .assignment-members__name .MuiTypography-root':
              {
                whiteSpace: 'break-spaces',
              },
          }}
        >
          <VirtualScrollDataTable
            columns={columns}
            rows={sortedFilteredRows}
            tableEmptyStateJsx={
              <TableFilteredEmptyState clearFilters={clearFilters} isFiltered={true} />
            }
            onSortChange={setSortState}
            defaultSortState={sortState}
          />
        </Box>
        {maybeInstallManagePackageDialogDialog}
      </Box>
    </Stack>
  );
};

export default MembersList;
