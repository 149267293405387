import { useEffect } from 'react';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { useSave } from './useSave';
import { areFunnelMapsEquivalent } from './areFunnelMapsEquivalent';
import cloneDeep from 'lodash/cloneDeep';

import { useErrorHandling } from '../../../hooks/useErrorHandling';
import { updateRecordTypesFunnelsAndHubspotFunnels } from './updateRecordTypesFunnelsAndHubspotFunnels';
import { selectProductionCrmOrg } from '../environments/environmentsReducer';
import { useSelector } from 'react-redux';
import usePermission from '../../common/permissions/usePermission';

export const useAutoSave = () => {
  const { lastFunnelMapInServer, setCanvasFunnelMap, canvasFunnelMap, canvasCrmOrgId } =
    useConfigurationCanvasContext();
  const isProductionOrg = useSelector(selectProductionCrmOrg)?.id === canvasCrmOrgId;

  const { errorHandlingBuilder } = useErrorHandling();
  const { saveFunnelMap } = useSave();
  const [hasFunnelMapSavePermissions] = usePermission(['edit:funnel-maps']);

  useEffect(() => {
    if (canvasFunnelMap) {
      const { changes, funnelMap: newFunnelMap } = updateRecordTypesFunnelsAndHubspotFunnels(
        cloneDeep(canvasFunnelMap),
        isProductionOrg,
      );

      // TODO: Add notifications when they are ready in design

      // changes.forEach((change) => {
      //   addNotification({
      //     message: change,
      //     variant: SweepNotificationVariant.Default,
      //     keepOpen: false,
      //   });
      // });

      if (changes.length > 0) {
        setCanvasFunnelMap(newFunnelMap);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasFunnelMap?.recordTypesData]);

  useEffect(() => {
    if (!canvasFunnelMap) return;

    const funnelMapHasChanges = !areFunnelMapsEquivalent(canvasFunnelMap, lastFunnelMapInServer);

    if (funnelMapHasChanges && canvasFunnelMap && hasFunnelMapSavePermissions) {
      errorHandlingBuilder()
        .withErrorNotification('Error updating environment view ')
        .execute(async () => await saveFunnelMap(canvasFunnelMap));
    }
  }, [
    errorHandlingBuilder,
    lastFunnelMapInServer,
    saveFunnelMap,
    canvasFunnelMap,
    canvasCrmOrgId,
    hasFunnelMapSavePermissions,
  ]);
};
