import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { ChatCompletionRequestMessageRole } from '@server/ai';
import { AiChatMessages } from '../common/ai-chat/AiChatMessages';
import { Stack, Box } from '@mui/material';
import { useCallback, useRef } from 'react';
import { colors, Typography } from '@sweep-io/sweep-design';

const FIRST_MSG =
  'Ask me any question about your Salesforce and I’ll give you answers based on analyzing any part of your configuration, including relations between objects, fields, or flows. permissions, profiles, errors and more.';

const CopilotScrollArea = ({
  messages,
  errorMsg,
  isLoading,
}: {
  messages: AiChatMessage[];
  errorMsg?: string;
  isLoading: boolean;
}) => {
  const introMessage = {
    createdAt: new Date().toISOString(),
    role: ChatCompletionRequestMessageRole.ASSISTANT,
    content: FIRST_MSG,
  };
  const ref = useRef<HTMLDivElement>();
  const scrollToBottom = useCallback(() => {
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
  }, []);

  return (
    <Box display="flex" flexDirection="column" flex={1} sx={{ overflowY: 'auto' }} ref={ref}>
      <Stack padding={1.5} gap={2} paddingBottom={4}>
        {messages.length === 0 && (
          <Stack textAlign="center" mt="10%">
            <Typography variant="h2-bold" color={colors.grey[800]}>
              Hi 👋,
              <br /> I'm your AI Assistant.
            </Typography>
          </Stack>
        )}
        <AiChatMessages
          messages={messages}
          errorMsg={errorMsg}
          isLoading={isLoading}
          scrollToBottom={scrollToBottom}
          introMessage={introMessage}
        />
      </Stack>
    </Box>
  );
};

export default CopilotScrollArea;
