import Box from '@mui/material/Box';
import NoResultsImg from './noResults.svg?react';
import { Typography, colors } from '@sweep-io/sweep-design';

export const NoResults = () => {
  return (
    <Box display="inline-flex" gap={1} alignItems="center" pt={1} pb={1}>
      <NoResultsImg />
      <Typography variant="caption" color={colors.grey[700]}>
        No results
      </Typography>
    </Box>
  );
};
