import { Stack } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import React, { useCallback, useState } from 'react';
import EmptyCloud from './emptyCloud.svg?react';
import ConnectToSfDialog from '../pages/get-started/connect-org/ConnectToSfDialog';
import WithConnectOrgPermission from '../common/WithConnectOrgPermission';

const ConnectToSfPage = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <>
      {isDialogOpen && (
        <ConnectToSfDialog
          closeDialog={closeDialog}
          forceProduction={false}
          onFinish={closeDialog}
        />
      )}

      <Stack gap={3} height="100%" alignItems="center" justifyContent="center">
        <Stack alignItems="center">
          <EmptyCloud />
          <Stack gap={1} maxWidth="384px" alignItems="center" textAlign="center">
            <Typography variant="h3">Connect Sweep to Salesforce</Typography>
            <Typography variant="body">
              Unlock the full potential of Sweep by connecting your Salesforce account
            </Typography>
          </Stack>
        </Stack>
        <WithConnectOrgPermission>
          <Button onClick={openDialog}>Connect</Button>
        </WithConnectOrgPermission>
      </Stack>
    </>
  );
};

export default ConnectToSfPage;
