import { isProdEnvIndeed } from '../environment';
import { BiEvent } from './events';

//Note: this ID appears also in index.html
//in case of change, need to update there too.
const APP_ID = 'o0g0oxzh';

const INIT_MAP = {
  intercom: false,
};

export type IntercomUser = {
  id?: string;
  name?: string;
  email?: string;
  accountType?: string;
  questionnaireRole?: string;
  questionnaireUseCases?: string;
  trialStatus: string;
  trialStartDate?: string;
  trialEndDate?: string;
};

const init = () => {
  if ((window as any).Intercom) {
    (window as any).Intercom('boot', {
      app_id: APP_ID,
      hide_default_launcher: true, //should be shown only on "GetStarted" page or replaced by custom button if present
    });
    INIT_MAP.intercom = true;
  }
};

const sendEvent = (event: BiEvent) => {
  if ((window as any).Intercom) {
    (window as any).Intercom('trackEvent', event.name, event.props);
  }
};

const setUserInIntercom = (user: IntercomUser) => {
  if ((window as any).Intercom) {
    const settings = {
      app_id: APP_ID,
      email: user.email,
      name: user.name,
      user_id: user.id,
      hide_default_launcher: true, //should be shown only on "GetStarted" page or replaced by custom button if present
      Plan: user.accountType,
      trial_status: user.trialStatus,
      trial_start_date: user.trialStartDate,
      trial_end_date: user.trialEndDate,
    };

    (window as any).Intercom('update', settings);
  }
};

export const initIntercom = (force = false) => {
  if (isProdEnvIndeed() || force) {
    init();
  }
};

export const setUserDetailsForIntercom = (user: IntercomUser) => {
  if (INIT_MAP.intercom) {
    setUserInIntercom(user);
  }
};

export const sendEventInIntercom = (event: BiEvent) => {
  if (INIT_MAP.intercom) {
    sendEvent(event);
  }
};
