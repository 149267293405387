import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { AutomationDialogContent } from '../Automations/AutomationDialogContent';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import useAutomationApiPerType from '../Automations/useAutomationApiPerType';
import { ConfigurationCanvasFilters } from '../pages/configuration-canvas/ConfigurationCanvasFilters';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { scheduledAssignmentsSortOptions } from '../pages/canvas-pages/consts';
import { AutomationScheduledAssignmentsContextProvider } from './AutomationScheduledAssignmentsContextProvider';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { useGetScheduledAssignmentFilters } from '../pages/canvas-pages/automationFilters';

interface ScheduledAssignmentsProps {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  disableCanvasTemplates?: boolean;
  setHoveredItem?: (automationId?: string | undefined) => void;
}

const ScheduledAssignments = ({
  recordTypesData,
  funnelsData,
  disableCanvasTemplates,
  setHoveredItem,
}: ScheduledAssignmentsProps) => {
  const scheduledAssignments = useSelector(selectScheduledAssignments);
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { onSaveOrCreate, onToggleActivation, onDeleteAutomation, onDeployAutomation } =
    useAutomationApiPerType({ automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS });
  const { isExpandedMode } = useExpandedMode();
  const renderCanvasFilters = !isExpandedMode;

  const filters = useGetScheduledAssignmentFilters(scheduledAssignments);

  if (!scheduledAssignments) return <CenteredCircularProgress />;

  return (
    <>
      <Box pb={2} flex={1} height="100%">
        {crmOrg && (
          <AutomationDialogContent
            automations={scheduledAssignments ?? []}
            onDeleteAutomation={onDeleteAutomation}
            crmOrgId={crmOrg.id}
            onSaveOrCreate={onSaveOrCreate}
            recordTypesData={recordTypesData}
            funnelsData={funnelsData}
            onToggleActivation={onToggleActivation}
            sortOptions={scheduledAssignmentsSortOptions}
            disableCanvasTemplates={disableCanvasTemplates}
            setHoveredItem={setHoveredItem}
            onDeployAutomation={onDeployAutomation}
            filtersForExpandedMode={filters}
          />
        )}
      </Box>
      {renderCanvasFilters && (
        <ConfigurationCanvasFilters filters={filters} automations={scheduledAssignments} />
      )}
    </>
  );
};

const ScheduledAssignmentsWithContext = (scheduledAssignmentProps: ScheduledAssignmentsProps) => {
  return (
    <AutomationScheduledAssignmentsContextProvider>
      <ScheduledAssignments {...scheduledAssignmentProps} />
    </AutomationScheduledAssignmentsContextProvider>
  );
};

export default ScheduledAssignmentsWithContext;
