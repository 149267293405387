import { Box } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';

export const TableFilteredEmptyState = ({
  clearFilters,
  isFiltered,
}: {
  clearFilters: () => void;
  isFiltered: boolean;
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        textAlign: 'center',
        alignItems: 'center',
        marginTop: '160px',
      }}
    >
      <Typography variant={isFiltered ? 'h2' : 'body'}>No results found</Typography>

      {isFiltered && (
        <>
          <Typography variant="body" color={colors.grey[800]}>
            Try changing your filters or search term
          </Typography>

          <Box mt={2}>
            <Button size="small" onClick={clearFilters}>Clear filters</Button>
          </Box>
        </>
      )}
    </Box>
  );
};
