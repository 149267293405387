import { SearchSelect } from '../SearchSelect';
import { DateTime } from 'luxon';

interface TimezoneSelectorProps {
  timezone?: string;
  onChange: (timezone: string) => void;
  disabled?: boolean;
  width?: React.CSSProperties['width'];
  fullWidth?: boolean;
  label?: string;
}

// Explicit cast to any as Intl is not typed properly until typescript 5.1 https://github.com/microsoft/TypeScript/issues/49231
const allTimezonesOptions = (Intl as any).supportedValuesOf('timeZone').map((tz: string) => ({
  label: tz,
  value: tz,
}));

export const TimezoneSelector = ({
  timezone,
  onChange,
  width,
  fullWidth,
  label,
}: TimezoneSelectorProps) => {
  return (
    <SearchSelect
      label={label}
      items={allTimezonesOptions}
      searchPlaceholder="Search timezone"
      SelectProps={{
        placeholder: 'Select timezone',
        value: timezone,
        sx: { width },
      }}
      FormControlProps={{ fullWidth }}
      onChange={(item) => {
        onChange(item.value);
      }}
    />
  );
};

export const getSystemTimezone = () => DateTime.local().zoneName || 'Europe/London';
