import { useNotificationsFacade } from '../../../apis/facades/useNotificationsFacade';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadNotifications,
  // NotificationDto,
  selectNotificationsCenterNotifications,
  selectNotificationsCenterTotalUnread,
  selectNotificationsHasUpdates,
} from '../../../reducers/notificationsCenterReducers';
import { useEffect } from 'react';
import { requestRefreshGlobal } from '../../../reducers/global/globalReducer';
import { selectDefaultCreationCrmOrgId } from '../../../reducers/userInfoReducer';

// const isNotificationAnUnreadAutomationDeployRequest = (notification: NotificationDto) =>
//   !notification.isRead && notification.deepLink.match(/automation|alerts/);

const useNotifications = () => {
  const dispatch = useDispatch();
  const { get_notifications } = useNotificationsFacade();
  const notifications = useSelector(selectNotificationsCenterNotifications);
  const totalUnread = useSelector(selectNotificationsCenterTotalUnread);
  const hasUpdates = useSelector(selectNotificationsHasUpdates);
  const defaultCreationId = useSelector(selectDefaultCreationCrmOrgId);

  useEffect(() => {
    (async function () {
      if (hasUpdates) {
        const { notifications, totalUnread } = await get_notifications();
        // if unread notifications received are of type automation, then feth global again.
        if (defaultCreationId) {
          // We need a way to check if these are automation notifications
          // and a good way is not to check the link since the route can change
          // For now we will call global every time we get a notification with the side effect
          // of refreshing unnecessarily also on comment notifications

          // const automationNotifications = notifications.filter(
          //   isNotificationAnUnreadAutomationDeployRequest,
          // );
          // if (automationNotifications.length > 0) {
          //   dispatch(requestRefreshGlobal({ crmOrgId: defaultCreationId }));
          // }
          dispatch(requestRefreshGlobal({ crmOrgId: defaultCreationId }));
        }

        dispatch(loadNotifications({ notifications, totalUnread }));
      }
    })();
  }, [dispatch, get_notifications, hasUpdates, notifications, totalUnread, defaultCreationId]);

  return { notifications, totalUnread };
};

export default useNotifications;
