import Territories from '../routing/Territories';
import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';

const TerritoriesDialog = ({
  closeDialog,
  transitionDuration,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
}) => {
  return (
    <DialogOnCanvas
      onClose={closeDialog}
      open={true}
      transitionDuration={transitionDuration}
      sx={{
        '.MuiPaper-root': {
          padding: '24px',
        },
      }}
    >
      <Territories onClose={closeDialog} isDialogLayout={true} />
    </DialogOnCanvas>
  );
};

export default TerritoriesDialog;
