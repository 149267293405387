import { useContext, useEffect } from 'react';
import { ConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { ConfigurationCanvasProps } from './types';

export const WithConfigurationCanvas = ({
  children,
  onStageGateClick,
  readOnly,
  highlightEntities,
  onFunnelLabelClick,
  onFunnelLabelPillClick,
  onFunnelStepClick,
  onRecordTypeLabelClick,
  onRecordTypeLabelPillClick,
  onRecordTypeStepClick,
  visibilityElements,
  selectedGateId,
  selectedStageId,
}: ConfigurationCanvasProps & {
  children: React.ReactNode;
}) => {
  const { setConfigurationCanvasProps } = useContext(ConfigurationCanvasContext);

  useEffect(() => {
    setConfigurationCanvasProps({
      onStageGateClick,
      readOnly,
      highlightEntities,
      onFunnelLabelClick,
      onFunnelStepClick,
      onFunnelLabelPillClick,
      onRecordTypeLabelClick,
      onRecordTypeLabelPillClick,
      onRecordTypeStepClick,
      visibilityElements,
      selectedGateId,
      selectedStageId,
    });
  }, [
    readOnly,
    setConfigurationCanvasProps,
    highlightEntities,
    onFunnelLabelClick,
    onFunnelStepClick,
    onRecordTypeLabelClick,
    onFunnelLabelPillClick,
    onRecordTypeLabelPillClick,
    onRecordTypeStepClick,
    visibilityElements,
    onStageGateClick,
    selectedGateId,
    selectedStageId,
  ]);

  return children;
};
