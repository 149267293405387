export enum RoleGroupType {
  SYSTEM = 'SYSTEM',
  USER_CREATED = 'USER_CREATED',
}

interface RoleGroupBaseInterface {
  id: string;
  name: string;
  description?: string;
  type: RoleGroupType;

  createdAt: Date;
  createdById: string;
  updatedAt: Date;
  updatedById?: string;
}
export type RoleGroupInterface = UserDefinedRoleGroupsInterface | SystemRoleGroupInterface;

type SystemRoleGroupInterface = RoleGroupBaseInterface;
export interface UserDefinedRoleGroupsInterface extends RoleGroupBaseInterface {
  roleLevels: RoleLevels;
}
export enum RoleLevel {
  VIEWER = 'VIEWER',
  REQUEST_TO_DEPLOY = 'REQUEST_TO_DEPLOY',
  DEPLOY = 'DEPLOY',
}

export type RoleLevels = {
  [key in typeof Sections[number]]: RoleLevel;
};

export const Sections = [
  'alerts',
  'automations',
  'dedupMatching',
  'assignments',
  'funnels',
  'rollups',
] as const;
