import { useCallback } from 'react';
const CHATS_MOCK = [
  {
    id: '1',
    name: 'name1',
    lastUpdatedAt: '2024-12-07T13:42:19.042Z',
    isFavorite: false,
  },
  {
    id: '2',
    name: 'name2',
    lastUpdatedAt: '2024-12-01T13:42:19.042Z',
    isFavorite: false,
  },
  {
    id: '3',
    name: 'name3',
    lastUpdatedAt: '2024-11-01T13:42:19.042Z',
    isFavorite: false,
  },
  {
    id: '4',
    name: 'name4',
    lastUpdatedAt: '2022-11-18T13:42:19.042Z',
    isFavorite: true,
  },
  {
    id: '5',
    name: 'name5',
    lastUpdatedAt: '2024-12-08T13:42:19.042Z',
    isFavorite: false,
  },
];

export const useAiChatApiFacade = () => {
  return {
    get_chats: useCallback(async () => {
      return Promise.resolve(CHATS_MOCK)
      // await new Promise((resolve) => setTimeout(resolve, 2000));
      // return Promise.resolve([])
    }, []),
  }
}
