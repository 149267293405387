import { useCallback, useState } from 'react';

/* Explanation:
/* This hook is used to manage flags for objects. 
/* It is used in the Funnel Map Flow page to manage the state of the funnel map and its funnels.
/* The function useObjectFlags returns an object with the following properties:
/* - setObjectFlagFor: a function that sets a flag for an object.
/* - getObjectFlagFor: a function that returns the flag for an object.
/* - removeObject: a function that removes an object.
/* - hasAny: a boolean that indicates if any object has a flag set.
/* - objectFlags: an object that contains the flags for the objects.
*/

export function useObjectFlags<T = boolean>(initialFlags: { [object: string]: T } = {}) {
  const [objectFlags, setObjectFlags] = useState<Record<string, T>>(initialFlags);

  const setObjectFlagFor = useCallback(
    (object: string, flag: T) => {
      objectFlags[object] = flag;
      setObjectFlags({ ...objectFlags });
    },
    [objectFlags],
  );

  const getObjectFlagFor = useCallback((object: string) => objectFlags[object], [objectFlags]);

  const hasAny = Boolean(Object.values(objectFlags).find((objectFlag) => Boolean(objectFlag)));

  const removeObject = useCallback(
    (object: string) => {
      const newObjectFlags = { ...objectFlags };
      delete newObjectFlags[object];
      setObjectFlags(newObjectFlags);
    },
    [objectFlags],
  );

  return {
    setObjectFlagFor,
    getObjectFlagFor,
    removeObject,
    hasAny,
    objectFlags,
  };
}
