import { SweepMultiCanvasInternal } from '../multi-canvas/SweepCanvas';

import {
  OnNodeClickProps,
  SweepNodesChangeEvent,
  SweepNodesChangeType,
} from '../multi-canvas/canvasTypes';
import { useFunnelMapElements } from './useFunnelMapElements';
import { useCallback, useContext, useMemo } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { FunnelMapCanvasInternalCtx } from './FunnelMapCanvasInternalCtx';
import { FunnelMapCanvasProps } from './types';
import { useRecordTypeEventsHandler } from './calculate-events/useRecordTypeEventsHandler';
import { useSfFunnelsEventHandler } from './calculate-events/useSfFunnelsEventHandler';
import { useHubspotEventHandler } from './calculate-events/useHubspotEventHandler';
import { useThirdPartyEventHandler } from './calculate-events/useThirdParyEventHandler';
import { getFunnelTypeFromNodeId } from './helper';
import { FunnelType } from '../../types/enums/FunnelType';
import { useFunnelMapCanvasCtx } from './FunnelMapCanvasCtx';

const EMPTY_OBJECT = {};

const FunnelMapCanvas = () => {
  const {
    funnelMap,
    readonly,
    funnelStageMetadata,
    visibilityMap,
    hideGroupInfo,
    onRemoveFunnelLinkClick,
    onFunnelOverlayClick,
    onPluginClick,
    selectedGateId,
    holdNodeHighlighted,
    selectedStageId,
    isLoadingCursor,
    onRedPillClick,
    transientFunnel,
    onFunnelMapPositionsChange,
    onFunnelLabelClick,
    onRecordTypeLabelClick,
    onFunnelLabelPillClick,
    onRecordTypeLabelPillClick,
    highlightEntities,
    renderZeroCountPills,
    pills,
    moveGroups,
    autoFitViewOnFirstNodes,
    onSweepElementsChange,
    onConnectStepsClick,
    placingTemplate,
    controlsRightMargin,
    disableNodeHighlight,
    objectTypesByName,
  } = useContext(FunnelMapCanvasInternalCtx);
  const isPlacingPlugin = Boolean(onFunnelOverlayClick);

  const funnelsData: FunnelsData = funnelMap.funnelsData || EMPTY_OBJECT;
  const recordTypesData: RecordTypesData = funnelMap.recordTypesData || EMPTY_OBJECT;

  const _funnelMapWithTransientFunnel = cloneDeep(funnelMap);
  const _sfFunnelsDataWithTransientFunnel = cloneDeep(funnelsData);

  if (transientFunnel?.type === FunnelType.SALESFORCE && transientFunnel?.importType == 'import') {
    const _transientFunnel = transientFunnel.data.funnel;
    _funnelMapWithTransientFunnel.funnels[_transientFunnel.id] = {
      position: {
        column: 0,
        row: 0,
      },
    };
    _sfFunnelsDataWithTransientFunnel[_transientFunnel.id] = _transientFunnel;
  }
  if (transientFunnel?.type === FunnelType.THIRD_PARTY && transientFunnel?.importType == 'import') {
    const _transientThirdPartyFunnel = transientFunnel.data.thirdPartyFunnel;
    _funnelMapWithTransientFunnel.thirdPartyFunnels =
      _funnelMapWithTransientFunnel.thirdPartyFunnels || {};
    _funnelMapWithTransientFunnel.thirdPartyFunnelsData =
      _funnelMapWithTransientFunnel.thirdPartyFunnelsData || {};

    _funnelMapWithTransientFunnel.thirdPartyFunnels[_transientThirdPartyFunnel.id] = {
      position: {
        column: 0,
        row: 0,
      },
    };
    _funnelMapWithTransientFunnel.thirdPartyFunnelsData[_transientThirdPartyFunnel.id] =
      _transientThirdPartyFunnel;
  }
  if (placingTemplate) {
    placingTemplate.template.funnelTemplates.forEach((template) => {
      _funnelMapWithTransientFunnel.funnels[template.id] = {
        position: template.position,
      };
      _sfFunnelsDataWithTransientFunnel[template.id] = {
        id: template.id,
        name: template.name,
        recordType: {
          description: '',
          label: '',
          name: template.objectName,
          objectName: template.objectName,
        },
        funnelDetails: template.templateFunnelDetails,
        description: '',
        accountId: '',
        createdAt: '',
        createdById: '',
        updatedAt: '',
        snapshotsIds: [],
        stageMetadata: [],
        updatedById: '',
      };
    });
  }

  const newFunnelMap = useMemo(
    () => ({
      ..._funnelMapWithTransientFunnel,
      funnelsData: _sfFunnelsDataWithTransientFunnel,
      recordTypesData,
    }),
    [_funnelMapWithTransientFunnel, _sfFunnelsDataWithTransientFunnel, recordTypesData],
  );

  const { editingThirdPartyStepId, setEditingThirdPartyStepId } = useFunnelMapCanvasCtx();

  const { sweepEdges, sweepGroups, sweepNodes } = useFunnelMapElements({
    funnelMap: newFunnelMap,
    funnelStageMetadata,
    visibilityMap,
    transientFunnel,
    highlightEntities,
    renderZeroCountPills,
    pills,
    objectTypesByName,
    editingThirdPartyStepId,
  });

  const onSweepNodesChange = useCallback(
    (event: SweepNodesChangeEvent) => {
      onSweepElementsChange?.(event);
      if (!onFunnelMapPositionsChange) return;

      const { change, type } = event;
      switch (type) {
        case SweepNodesChangeType.MoveGroup: {
          const { groupsToMove } = change;
          groupsToMove.forEach((group) => {
            const { nodeId, newPosition } = group;
            const newFunnelMap = cloneDeep(funnelMap); // TODO: Remove this
            switch (getFunnelTypeFromNodeId(funnelMap, nodeId)) {
              case FunnelType.RECORD_TYPE: {
                newFunnelMap.recordTypes[nodeId].position = newPosition;
                onFunnelMapPositionsChange(newFunnelMap);
                break;
              }
              case FunnelType.SALESFORCE: {
                newFunnelMap.funnels[nodeId].position = newPosition;
                onFunnelMapPositionsChange(newFunnelMap);
                break;
              }
              case FunnelType.HUBSPOT: {
                newFunnelMap.hubspotFunnels[nodeId].position = newPosition;
                onFunnelMapPositionsChange(newFunnelMap);
                break;
              }
              case FunnelType.THIRD_PARTY: {
                newFunnelMap.thirdPartyFunnels[nodeId].position = newPosition;
                onFunnelMapPositionsChange(newFunnelMap);
                break;
              }
              default:
                break;
            }
          });
          break;
        }

        case SweepNodesChangeType.MoveNode:
          break;
      }
    },
    [funnelMap, onFunnelMapPositionsChange, onSweepElementsChange],
  );

  const recordTypeEventsHandler = useRecordTypeEventsHandler();
  const sfFunnelEventsHandler = useSfFunnelsEventHandler();
  const hubspotEventHandler = useHubspotEventHandler();
  const thirdPartyEventHandler = useThirdPartyEventHandler({
    setEditingThirdPartyStepId,
  });

  const eventHandlerBaseOnNodeType = useCallback(
    (nodeType: FunnelType | undefined) => {
      switch (nodeType) {
        case FunnelType.SALESFORCE:
          return sfFunnelEventsHandler;
        case FunnelType.RECORD_TYPE:
          return recordTypeEventsHandler;
        case FunnelType.HUBSPOT:
          return hubspotEventHandler;
        case FunnelType.THIRD_PARTY:
          return thirdPartyEventHandler;
        default:
          return null;
      }
    },
    [hubspotEventHandler, recordTypeEventsHandler, sfFunnelEventsHandler, thirdPartyEventHandler],
  );

  const onNodeClick = useCallback(
    (props: OnNodeClickProps) =>
      eventHandlerBaseOnNodeType(
        getFunnelTypeFromNodeId(funnelMap, props.parentId ?? props.id),
      )?.onNodeClick?.(props),
    [eventHandlerBaseOnNodeType, funnelMap],
  );
  const onPillClick = useCallback(
    (props: OnNodeClickProps) =>
      eventHandlerBaseOnNodeType(
        getFunnelTypeFromNodeId(funnelMap, props.parentId ?? props.id),
      )?.onPillClick?.(props),
    [eventHandlerBaseOnNodeType, funnelMap],
  );

  const onLabelClick = useCallback(
    (props: OnNodeClickProps) =>
      eventHandlerBaseOnNodeType(
        getFunnelTypeFromNodeId(funnelMap, props.parentId ?? props.id),
      )?.onLabelClick?.(props),
    [eventHandlerBaseOnNodeType, funnelMap],
  );

  const onGateClick = sfFunnelEventsHandler.onGateClick;

  const onNodeNameChange = useCallback(
    (name: string, funnelId: string) =>
      eventHandlerBaseOnNodeType(getFunnelTypeFromNodeId(funnelMap, funnelId))?.onNodeNameChange?.(
        name,
        funnelId,
      ) ?? { isValid: true },
    [eventHandlerBaseOnNodeType, funnelMap],
  );

  const hasPillClick = !!(onFunnelLabelPillClick || onRecordTypeLabelPillClick);
  const hasLabelClick = !!(onFunnelLabelClick || onRecordTypeLabelClick);

  const _onConnectStepsClick = useCallback(
    (stepId: string, funnelId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const funnelType = getFunnelTypeFromNodeId(funnelMap, funnelId);
      if (funnelType) {
        onConnectStepsClick?.(stepId, funnelId, funnelType, event);
      }
    },
    [funnelMap, onConnectStepsClick],
  );

  const _onRemoveFunnelLinkClick = useCallback(
    ({
      edgeId: linkId,
      sourceNodeId: stageId,
      sourceNodeParentId: funnelId,
      targetNodeId: targetStageId,
      targetNodeParentId: targetFunnelId,
    }: {
      edgeId: string;
      sourceNodeId: string;
      targetNodeId: string;
      sourceNodeParentId: string;
      targetNodeParentId: string;
    }) => {
      const funnelType = getFunnelTypeFromNodeId(funnelMap, funnelId);
      if (funnelType) {
        onRemoveFunnelLinkClick?.({
          linkId,
          stageId,
          targetStageId,
          funnelId,
          targetFunnelId,
          funnelType,
        });
      }
    },
    [funnelMap, onRemoveFunnelLinkClick],
  );

  return (
    <SweepMultiCanvasInternal
      selectedNodeId={selectedStageId}
      selectedEdgeId={selectedGateId}
      sweepGroups={sweepGroups}
      sweepNodes={sweepNodes}
      sweepEdges={sweepEdges}
      visibilityMap={visibilityMap}
      onNodeClick={onNodeClick}
      onPillClick={hasPillClick ? onPillClick : undefined}
      onLabelClick={hasLabelClick ? onLabelClick : undefined}
      onEdgeDeleteClick={_onRemoveFunnelLinkClick}
      readonly={readonly}
      onRedPillClick={onRedPillClick}
      holdNodeHighlighted={holdNodeHighlighted}
      hideGroupInfo={hideGroupInfo}
      autoFitViewOnFirstNodes={autoFitViewOnFirstNodes}
      isLoadingCursor={isLoadingCursor}
      showGroupOverlays={isPlacingPlugin}
      onPluginClick={onPluginClick}
      onSweepNodesChange={onSweepNodesChange}
      moveGroups={moveGroups}
      onConnectClick={_onConnectStepsClick}
      onGateClick={onGateClick}
      onNodeNameChange={onNodeNameChange}
      controlsRightMargin={controlsRightMargin}
      disableNodeHighlight={disableNodeHighlight}
    />
  );
};

const FunnelMapCanvasWithContext = (props: FunnelMapCanvasProps) => {
  return (
    <FunnelMapCanvasInternalCtx.Provider value={props}>
      <FunnelMapCanvas />
    </FunnelMapCanvasInternalCtx.Provider>
  );
};

export { FunnelMapCanvasWithContext as FunnelMapCanvas };
