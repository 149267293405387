import { Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import { DIMENSION_AVATAR_MEDIUM } from '../../../constants';
import UserSummaryBasic from './UserSummaryBasic';
import { UserInfo } from '../../../types/UserInfoTypes';
import { RolesDropdown } from './RolesDropdown';
import { getIsUserInactive } from '../../../lib/getIsUserActive';
import { UserDefinedRoleGroupsInterface } from '@server/role-group-interface';

export interface UserSummaryNewProps {
  user?: UserInfo;
  roles: UserDefinedRoleGroupsInterface[];
  onChangeRoleCb: (userId: string, newRole: string) => void;
  disableRoleDropdown?: boolean;
  onUserRemove?: (userId: string) => void;
}

const UserSummaryNew = ({
  user = {},
  roles,
  onChangeRoleCb,
  onUserRemove,
  disableRoleDropdown,
}: UserSummaryNewProps) => {
  const { name, email, roleGroupId: role, id, status } = user || {};
  const _userRole = roles.find((_role) => _role.id === role);

  return (
    <UserSummaryBasic className="user-summary" data-testid="user-summary" key={id}>
      <div className="user-summary-avatar">
        <AvatarPicture
          avatar={{ emoji: user.emoji, imageUrl: user.imageUrl }}
          dimension={DIMENSION_AVATAR_MEDIUM}
          isInactive={getIsUserInactive(status)}
        />
      </div>
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        className="text-info"
        gap="8px"
        flex="1 0"
      >
        <Typography data-testid="user-summary-name" ellipsis variant="body-bold">
          {name}
        </Typography>
        <Typography data-testid="user-summary-email" ellipsis variant="caption">
          {email}
        </Typography>
      </Box>

      {user && (
        <RolesDropdown
          onChangeRoleCb={onChangeRoleCb}
          accountRoles={roles}
          disableRoleDropdown={disableRoleDropdown}
          userRole={_userRole}
          userId={id}
          onUserRemove={onUserRemove}
        />
      )}
    </UserSummaryBasic>
  );
};

export { UserSummaryNew };
