import { useReducer, createContext, ReactNode } from 'react';
import { ConfirmLeaveWithoutSaveDialog } from './ConfirmLeaveWithoutSaveDialog';

export const HIDE_CONFIRM = 'HIDE_CONFIRM';
export const SHOW_CONFIRM = 'SHOW_CONFIRM';

type ConfirmContext = {
  dialogJsx?: ReactNode;
};

export type ConfirmActionData = ReactNode | undefined;

export type ConfirmAction = { type: string; payload?: ConfirmActionData };

const initialState: ConfirmContext = {
  dialogJsx: undefined,
};
export const ConfirmContext = createContext<{
  state: ConfirmContext;
  dispatch?: React.Dispatch<ConfirmAction>;
}>({ state: initialState });

const reducer = (state: ConfirmContext, action: ConfirmAction) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        dialogJsx: action.payload ?? <ConfirmLeaveWithoutSaveDialog />,
      };
    case HIDE_CONFIRM:
      return initialState;
    default:
      return initialState;
  }
};

export const ConfirmContextProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer<
    (state: ConfirmContext, action: ConfirmAction) => ConfirmContext
  >(reducer, initialState);

  return (
    <ConfirmContext.Provider value={{ state, dispatch }}>
      {state.dialogJsx}
      {children}
    </ConfirmContext.Provider>
  );
};
