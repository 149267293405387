import { AutoSaveProvider } from './AutoSaveContext';
import { AutoSaveIndicator } from './AutoSaveIndicator';
import { AutoSaveProps, useAutoSave } from './useAutoSave';

// TODO: Remove this Component and separate the logic .
// This needs to be done for UNDO/REDO
export const InnerAutoSave = (props: AutoSaveProps) => {
  const { isAutoSaving, autoSavingError, updatedAt } = useAutoSave(props);

  return (
    <AutoSaveIndicator
      lastSave={updatedAt}
      error={autoSavingError ? { code: 'ERR_NETWORK' } : undefined}
      isAutoSaving={isAutoSaving}
      readonly={props.readonly}
    />
  );
};

export const AutoSave = (props: AutoSaveProps) => {
  return (
    <AutoSaveProvider>
      <InnerAutoSave {...props} />
    </AutoSaveProvider>
  );
};
