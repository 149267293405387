import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import Assignments from './Assignments';
import { RoutingTabs } from './useRoutingTabs';
import ScheduledAssignments from './ScheduledAssignments';
import { FunctionComponent } from 'react';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { RootState } from '../../reducers';
import Members from './Members';
import Groups from './Groups';
import Territories from '../routing/Territories';

export const RoutingTabsInfo: {
  [key in RoutingTabs]: {
    label: string;
    ContentComponent: FunctionComponent<any> | null;
    automationVariant?: AutomationFormVariant;
    selector?: (state: RootState) => any[] | undefined;
    drillable: boolean;
  };
} = {
  [RoutingTabs.assignment]: {
    label: 'Assignment Rules',
    ContentComponent: Assignments,
    automationVariant: AutomationFormVariant.ASSIGNMENT_RULES,
    selector: selectAssignments,
    drillable: true,
  },
  [RoutingTabs.scheduled]: {
    label: 'Scheduled Assignments',
    ContentComponent: ScheduledAssignments,
    automationVariant: AutomationFormVariant.SCHEDULED_ASSIGNMENTS,
    selector: selectScheduledAssignments,
    drillable: true,
  },
  [RoutingTabs.members]: {
    label: 'Members',
    ContentComponent: Members,
    drillable: false,
  },
  [RoutingTabs.groups]: {
    label: 'Groups',
    ContentComponent: Groups,
    drillable: false,
  },
  [RoutingTabs.territories]: {
    label: 'Territories',
    ContentComponent: Territories,
    drillable: false,
  },
};
