import { Box } from '@mui/material';
import { ConditionalActionsTable } from './ConditionalActionsTable';
import { Button } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { ConditionalActionDialog } from './ConditionalActionDialog';
import cloneDeep from 'lodash/cloneDeep';
import { uniqueId } from '../../lib/uniqueId';

const dialogInitialState = { open: false };

const ConditionalActionsEditor = ({
  conditionalActions,
  onChange,
  crmOrgId,
  objectName,
  readonly,
  objectTypes,
}: {
  conditionalActions: AutomationConditionalAction[];
  onChange: (conditionalActions: AutomationConditionalAction[]) => void;
  crmOrgId: string;
  objectName: string;
  readonly?: boolean;
  objectTypes?: ObjectTypeName[];
}) => {
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    editMode?: boolean;
    conditionalAction?: AutomationConditionalAction;
  }>(dialogInitialState);
  return (
    <>
      {dialogState.open && (
        <ConditionalActionDialog
          objectTypes={objectTypes}
          onCreateEdit={(updatedRule) => {
            const existingRuleIdx = conditionalActions.findIndex(
              (rule) => rule._id === updatedRule._id,
            );
            if (existingRuleIdx > -1) {
              const clonedRules = conditionalActions.slice();
              clonedRules[existingRuleIdx] = updatedRule;
              onChange(clonedRules);
            } else {
              onChange([...conditionalActions, updatedRule]);
            }
            setDialogState(dialogInitialState);
          }}
          crmOrgId={crmOrgId}
          objectType={objectName}
          onCancel={() => setDialogState(dialogInitialState)}
          editMode={dialogState.editMode}
          initialConditionalAction={dialogState.conditionalAction}
        />
      )}
      <Box display="flex" flexDirection="column" gap={2}>
        {conditionalActions.length > 0 && (
          <ConditionalActionsTable
            readonly={readonly}
            conditionalActions={conditionalActions}
            onChange={onChange}
            onRuleDuplicate={(newActionsId) => {
              const existingRule = conditionalActions.find((rule) => rule._id === newActionsId);
              if (!existingRule) {
                return;
              }
              const newRule = cloneDeep(existingRule);
              newRule._id = uniqueId();
              newRule._name = `${newRule._name} (copy)`;
              setDialogState({
                open: true,
                conditionalAction: newRule,
                editMode: false,
              });
            }}
            onRuleEdit={(actionsId) => {
              const existingRule = conditionalActions.find((rule) => rule._id === actionsId);
              if (!existingRule) {
                return;
              }
              setDialogState({
                open: true,
                conditionalAction: existingRule,
                editMode: true,
              });
            }}
          />
        )}
        <Button
          variant="flat"
          startIconName="Plus"
          disabled={readonly}
          onClick={() => setDialogState({ open: true, editMode: false })}
        >
          New prioritized action
        </Button>
      </Box>
    </>
  );
};

export default ConditionalActionsEditor;
