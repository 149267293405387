import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RollupForTable } from '../components/pages/rollups/rollupTypes';
import { EditMode } from '../constants/fieldsManagementConsts';

export interface RollupsPageState {
  dialogData: {
    isOpen: boolean;
    initialData?: RollupForTable;
    mode?: EditMode.EDIT | EditMode.CREATE;
  };
}

const initialState: RollupsPageState = {
  dialogData: {
    isOpen: false,
  },
};

export const rollupsPageSlice = createSlice({
  name: 'rollupsPage',
  initialState,
  reducers: {
    openDialogWithData: (
      state,
      action: PayloadAction<{
        isOpen?: boolean;
        initialData?: RollupForTable;
        mode?: EditMode.EDIT | EditMode.CREATE;
      }>,
    ) => {
      const { isOpen, initialData, mode } = action.payload;
      if (isOpen !== undefined) {
        state.dialogData.isOpen = isOpen;
      }
      if (initialData) {
        state.dialogData.initialData = initialData;
      }
      if (mode) {
        state.dialogData.mode = mode;
      }
    },
    closeDialog: () => initialState,
  },
});

export const selectDialogData = (state: RootState) => state.rollupsPage.dialogData;

export const { openDialogWithData, closeDialog } = rollupsPageSlice.actions;
export default rollupsPageSlice.reducer;
