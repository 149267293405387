import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { SortOrder } from '../../common/types';
import {
  assignmentsSortOptions,
  automationsSortOptions,
  dedupMatchingSortOptions,
  scheduledAssignmentsSortOptions,
} from '../../pages/canvas-pages/consts';

export enum SortFieldValues {
  fieldLabel = 'label',
  usage = 'usage',
  createdAt = 'createdAt',
}

export enum SfElementsSortValues {
  label = 'label',
  changedAt = 'annotations.changedAt',
}

export enum CardElementsSortValues {
  cardLabel = 'label',
  badgeLabel = 'badgeLabel',
}

export const sortOptions: { value: string; label: string; sortOrder: SortOrder }[] = [
  { value: SfElementsSortValues.label, label: 'Name', sortOrder: SortOrder.ASC },
  { value: SfElementsSortValues.changedAt, label: 'Last Updated', sortOrder: SortOrder.DESC },
];

export const sortFieldsOptions: { value: SortFieldValues; label: string; sortOrder: SortOrder }[] =
  [
    { value: SortFieldValues.fieldLabel, label: 'Name', sortOrder: SortOrder.ASC },
    { value: SortFieldValues.usage, label: 'Utilization percentage', sortOrder: SortOrder.ASC },
    { value: SortFieldValues.createdAt, label: 'Field creation date', sortOrder: SortOrder.ASC },
  ];

export const sortOptionsForCardList = [
  { label: 'Item Count', value: CardElementsSortValues.badgeLabel, sortOrder: SortOrder.DESC },
  { label: 'Name', value: CardElementsSortValues.cardLabel, sortOrder: SortOrder.ASC },
];

export const documentationSortOptionsMap = {
  [DocumentationTabTypes.APEX]: sortOptions,
  [DocumentationTabTypes.VALIDATION_RULES]: sortOptions,
  [DocumentationTabTypes.SF_AUTOMATIONS]: sortOptions,
  [DocumentationTabTypes.FIELDS]: sortFieldsOptions,
  [DocumentationTabTypes.RECORD_TYPES]: sortOptions,
  [DocumentationTabTypes.ASSIGNMENTS]: assignmentsSortOptions,
  [DocumentationTabTypes.SCHEDULED_ASSIGNMENTS]: scheduledAssignmentsSortOptions,
  [DocumentationTabTypes.PLAYBOOK_ALERTS]: automationsSortOptions,
  [DocumentationTabTypes.SWEEP_AUTOMATIONS]: automationsSortOptions,
  [DocumentationTabTypes.MATCHING_DEDUPE]: dedupMatchingSortOptions,
  [DocumentationTabTypes.CARDS_LIST]: sortOptionsForCardList,
  [DocumentationTabTypes.CPQ_DATA]: sortOptions,
  [DocumentationTabTypes.LAYOUTS]: sortOptions,
};

export const defaultSortOptionsMap = {
  [DocumentationTabTypes.APEX]: SfElementsSortValues.label,
  [DocumentationTabTypes.VALIDATION_RULES]: SfElementsSortValues.label,
  [DocumentationTabTypes.SF_AUTOMATIONS]: SfElementsSortValues.label,
  [DocumentationTabTypes.FIELDS]: SortFieldValues.fieldLabel,
  [DocumentationTabTypes.RECORD_TYPES]: SfElementsSortValues.label,
  [DocumentationTabTypes.ASSIGNMENTS]: assignmentsSortOptions[0].value,
  [DocumentationTabTypes.SCHEDULED_ASSIGNMENTS]: scheduledAssignmentsSortOptions[0].value,
  [DocumentationTabTypes.PLAYBOOK_ALERTS]: automationsSortOptions[0].value,
  [DocumentationTabTypes.SWEEP_AUTOMATIONS]: automationsSortOptions[0].value,
  [DocumentationTabTypes.MATCHING_DEDUPE]: dedupMatchingSortOptions[0].value,
  [DocumentationTabTypes.CARDS_LIST]: sortOptionsForCardList[0].value,
  [DocumentationTabTypes.CPQ_DATA]: SfElementsSortValues.label,
  [DocumentationTabTypes.LAYOUTS]: SfElementsSortValues.label,
};

//sort items by key and nested key and if key doesn't exist return values at the end
export const sortByProperty = (array: any[], sortKey: string, sortDirection: SortOrder) => {
  //TODO add tests
  const hasNestedProp = (obj: any) => {
    const chain = sortKey.split('.');
    return !!chain.reduce((o, p) => o && o[p as any], obj);
  };

  return (array ?? []).toSorted((a, b) => {
    const getValue = (obj: any) => sortKey.split('.').reduce((o, p) => o && o[p], obj);

    const valueA = getValue(a);
    const valueB = getValue(b);

    if (typeof valueA !== 'number' && valueB !== 'number') {
      if (!hasNestedProp(a) && !hasNestedProp(b)) {
        return 0;
      } else if (!hasNestedProp(a)) {
        return 1;
      } else if (!hasNestedProp(b)) {
        return -1;
      }
    }

    let comparison = 0;

    if (typeof valueA === 'number' && typeof valueB === 'number') {
      comparison = valueA - valueB;
    } else if (typeof valueA === 'string' && typeof valueB === 'string') {
      comparison = valueA.toLowerCase() < valueB.toLowerCase() ? -1 : 1;
    } else {
      comparison = valueA < valueB ? -1 : 1;
    }

    return sortDirection === SortOrder.DESC ? comparison * -1 : comparison;
  });
};

export const getSelectedSortOption = (_sortOptions: any, sortValue: string) =>
  _sortOptions.find((option: any) => option.value === sortValue) ?? [];

export const getSortOptions = (tab: DocumentationTabTypes, isWithUtilization: boolean) => {
  const isFieldsWithNoUtilization = tab === DocumentationTabTypes.FIELDS && !isWithUtilization;

  if (isFieldsWithNoUtilization) {
    return documentationSortOptionsMap[tab].filter((item) => item.value !== SortFieldValues.usage);
  }

  return documentationSortOptionsMap[tab];
};
