import { useCallback } from 'react';
import { useSweepApi } from '../sweep';

export interface LogoDevBrand {
  name: string;
  domain: string;
}

const useLogoDevApiFacade = () => {
  const sweepApi = useSweepApi();

  const get_search = useCallback(
    async ({
      query,
      abortController,
    }: {
      query: { query: string };
      abortController?: AbortController;
    }) => {
      const { data } = await sweepApi.get<LogoDevBrand[]>(`/logo-dev/search?q=${query.query}`, {
        signal: abortController?.signal,
      });

      return data;
    },
    [sweepApi],
  );

  return { get_search };
};

export default useLogoDevApiFacade;
