import { createContext, useContext, useState } from 'react';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { generateNewAssignmentGroupData } from './group-settings-wizard/generateNewAssignmentGroupData';
import { EmojiType } from '../../../avatar/avatarTypes';

interface GroupSettingsContextType {
  assignmentGroup: DeepPartial<AssignmentGroup>;
  setAssignmentGroup: React.Dispatch<React.SetStateAction<DeepPartial<AssignmentGroup>>>;
  resetAllMembersLimit?: number;
  setResetAllMembersLimit: React.Dispatch<React.SetStateAction<number | undefined>>;
  crmOrgId: string;
}

const GroupSettingsContext = createContext<GroupSettingsContextType>({
  assignmentGroup: {},
  setAssignmentGroup: () => {},
  crmOrgId: '',
  setResetAllMembersLimit: () => {},
});

export const GroupSettingsProvider = ({
  children,
  initialAssignmentGroup,
  defaultEmoji,
  crmOrgId,
}: {
  children: React.ReactNode;
  initialAssignmentGroup?: Partial<AssignmentGroup>;
  defaultEmoji?: EmojiType | undefined;
  crmOrgId: string;
}) => {
  const [localGroupData, setLocalGroupData] = useState<DeepPartial<AssignmentGroup>>(
    initialAssignmentGroup || generateNewAssignmentGroupData(defaultEmoji),
  );
  const [resetAllMembersLimit, setResetAllMembersLimit] = useState<number | undefined>();

  return (
    <GroupSettingsContext.Provider
      value={{
        assignmentGroup: localGroupData,
        setAssignmentGroup: setLocalGroupData,
        crmOrgId,
        resetAllMembersLimit,
        setResetAllMembersLimit,
      }}
    >
      {children}
    </GroupSettingsContext.Provider>
  );
};

export const useGroupSettingsContext = () => {
  const context = useContext(GroupSettingsContext);
  if (context === undefined) {
    throw new Error('useGroupSettings must be used within a GroupSettingsProvider');
  }
  return context;
};
