import { GroupNodePositions } from '../canvasTypes/nodeTypesData';
import { NODE_HEIGHT, SWEEP_GRID_SIZE, NODE_WIDTH } from '../const';

const PADDING_WIDTH = 52;
const PADDING_HEIGHT = 70;

// The first row is reserved for the label. We don't need a full row.
const FIRST_ROW_DISCOUNT = PADDING_HEIGHT + NODE_HEIGHT - 50;

export const calculateGroupDimensionsAndPosition = (
  positions: GroupNodePositions,
  discountFirstRow?: boolean,
) => {
  const width =
    (positions.maxCol - positions.minCol) * SWEEP_GRID_SIZE.width + NODE_WIDTH + PADDING_WIDTH * 2;
  const marginLeft = positions.minCol * SWEEP_GRID_SIZE.width - PADDING_WIDTH;

  const firstRowDiscount = discountFirstRow ? FIRST_ROW_DISCOUNT : 0;
  const height =
    (positions.maxRow - positions.minRow) * SWEEP_GRID_SIZE.height +
    NODE_HEIGHT +
    PADDING_HEIGHT * 2 -
    firstRowDiscount;
  const marginTop =
    -(height - NODE_HEIGHT) - positions.minRow * SWEEP_GRID_SIZE.height + PADDING_HEIGHT;

  return {
    width: isNaN(width) ? 0 : width,
    height: isNaN(height) ? 0 : height,
    marginTop: isNaN(marginTop) ? 0 : marginTop,
    marginLeft: isNaN(marginLeft) ? 0 : marginLeft,
  };
};
