import { useState } from 'react';
import { ListSubheader, MenuItem } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { SearchInput } from '../common/SearchInput';
import { TruncatedTextTooltip } from '../common/TruncatedTextTooltip';
import SelectorEmptyState from '../common/SelectorEmptyState';
import { escapeRegexp } from '../../lib/escapeRegex';

export type FormulaMenuItem = {
  value: string;
  label?: string;
};

const FormulaMenu = ({
  onAddItem,
  closeMenu,
  menuItems,
}: {
  onAddItem: (item: string) => void;
  closeMenu: () => void;
  menuItems: FormulaMenuItem[];
}) => {
  const [searchValue, setSearchValue] = useState('');

  const itemsFiltered = menuItems.filter((item) => {
    const regexp = new RegExp(escapeRegexp(searchValue), 'gi');
    return item.value.match(regexp) || item.label?.match(regexp);
  });

  return (
    <>
      <ListSubheader
        sx={{
          display: 'flex',
          alignItems: 'center',
          lineHeight: 1,
          borderBottom: '1px solid',
          borderColor: colors.grey[300],
          padding: 1.5,
        }}
      >
        <SearchInput
          TextFieldProps={{
            value: searchValue,
            autoFocus: true,
            onChange: (event) => setSearchValue(event.target.value),
            onKeyDown: (e) => {
              if (e.key !== 'Escape') {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            },
          }}
          onClearButtonClick={() => setSearchValue('')}
        />
      </ListSubheader>
      {itemsFiltered.map(({ value, label }) => (
        <MenuItem
          key={value}
          onClick={() => {
            onAddItem(value);
            closeMenu();
          }}
        >
          <TruncatedTextTooltip variant="body" zIndex={999999}>
            {label ?? value}
          </TruncatedTextTooltip>
        </MenuItem>
      ))}
      {itemsFiltered.length === 0 && <SelectorEmptyState title="No results" />}
    </>
  );
};

export default FormulaMenu;
