import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface NotificationDto {
  id: string;
  senderId: string;
  title: string;
  body: string;
  isRead: boolean;
  createdAt: string;
  deepLink: string;
}

export interface NotificationsCenterState {
  notifications: NotificationDto[];
  totalUnread: number;
  hasUpdates: boolean;
}

const initialState: NotificationsCenterState = {
  notifications: [],
  totalUnread: 0,
  hasUpdates: true,
};

export const notificationsCenter = createSlice({
  name: 'notificationsCenter',
  initialState,
  reducers: {
    loadNotifications: (
      state,
      action: PayloadAction<{ totalUnread: number; notifications: NotificationDto[] }>,
    ) => {
      state.notifications = action.payload.notifications;
      state.totalUnread = action.payload.totalUnread;
      state.hasUpdates = false;
    },
    setNotificationAsRead: (
      state,
      action: PayloadAction<{ notificationId: string; totalUnread?: number }>,
    ) => {
      const notification = state.notifications.find(
        (not) => not.id === action.payload.notificationId,
      );
      if (notification) {
        notification.isRead = true;
      }
      if (action.payload.totalUnread !== undefined) {
        state.totalUnread = action.payload.totalUnread;
      }
    },
    setAllNotificationsAsRead: (state) => {
      state.notifications.forEach((notification) => (notification.isRead = true));
      state.totalUnread = 0;
    },
    setNotificationsHasUpdates: (state) => {
      state.hasUpdates = true;
    },
  },
});

export const {
  loadNotifications,
  setNotificationAsRead,
  setAllNotificationsAsRead,
  setNotificationsHasUpdates,
} = notificationsCenter.actions;

export const selectNotificationsCenterNotifications = (state: RootState) =>
  state[notificationsCenter.name].notifications;

export const selectNotificationsCenterTotalUnread = (state: RootState) =>
  state[notificationsCenter.name].totalUnread;

export const selectNotificationsHasUpdates = (state: RootState) =>
  state[notificationsCenter.name].hasUpdates;
