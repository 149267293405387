import { useContext, useCallback } from 'react';
import { XYPosition, NodeDragHandler, Node as ReactFlowNode } from 'reactflow';
import {
  CanvasElementType,
  SweepNodesChangeType,
  SweepNodesChangeMoveGroupEvent,
  SweepCanvasNode,
} from './canvasTypes';
import {
  SweepCanvasNodePosition,
  _reactFlowPositionToRoundedCanvasIndexPosition,
} from './helpers/calculateHandlePositionsBasedOnCoords';
import { InternalCanvasCtx } from './internal-context/InternalCanvasCtx';
import { NodeInfo } from './useGroupNodes';
import { GroupDataReactFlowNode } from './canvasTypes/nodeTypesData';
import { useCalculateDragAndDrop } from './useCalculateDragAndDrop';
import { SweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';

const nodeIsAnyOfTypes = (node: ReactFlowNode, types: CanvasElementType[]) => {
  return types.includes(node.type as CanvasElementType);
};

export const useDragEvents = ({
  groupNodes,
  internalSweepNodes,
}: {
  groupNodes: GroupDataReactFlowNode[];
  internalSweepNodes: SweepCanvasNode[];
}) => {
  const { sweepNodes, sweepGroups, onSweepNodesChange } = useContext(SweepCanvasPropsCtx);
  const { getDropInvalidReason } = useCalculateDragAndDrop({ internalSweepNodes });

  const {
    setDraggingNodeInfo,
    draggingNodeInfo,
    setDraggingGroupInfo,
    setTemporaryTransformations,
  } = useContext(InternalCanvasCtx);

  const extractNodeInfo = useCallback(
    (node: ReactFlowNode): NodeInfo => {
      if (node.type === CanvasElementType.GROUP_LABEL) {
        const parentNode = groupNodes.find((group) => group.id === node.parentNode);
        const position: XYPosition = {
          x:
            (node.positionAbsolute?.x || 0) -
            (parentNode?.data?.reactFlowPositions?.marginLeft || 0),
          y:
            (node.positionAbsolute?.y || 0) -
            (parentNode?.data?.reactFlowPositions?.marginTop || 0),
        };

        return {
          id: node.data.groupId,
          position,
        };
      }
      return {
        id: node.id,
        position: node.position,
      };
    },
    [groupNodes],
  );

  const onDropZoneHandler = useCallback(
    (node: ReactFlowNode, initialPosition: XYPosition = { x: 0, y: 0 }) => {
      if (
        nodeIsAnyOfTypes(node, [
          CanvasElementType.REGULAR,
          CanvasElementType.NURTURING_BUCKET,
          CanvasElementType.GROUP,
        ])
      ) {
        setDraggingNodeInfo({
          initialPosition,
          node,
        });
      }
    },
    [setDraggingNodeInfo],
  );

  const onDragStart: NodeDragHandler = useCallback(
    (even, node) => {
      onDropZoneHandler(node, node.position);
      if (nodeIsAnyOfTypes(node, [CanvasElementType.GROUP, CanvasElementType.GROUP_LABEL])) {
        setDraggingGroupInfo(extractNodeInfo(node));
      }
    },
    [extractNodeInfo, onDropZoneHandler, setDraggingGroupInfo],
  );

  const onDragStop: NodeDragHandler = useCallback(
    (event, node) => {
      if (
        nodeIsAnyOfTypes(node, [
          CanvasElementType.REGULAR,
          CanvasElementType.NURTURING_BUCKET,
          CanvasElementType.GROUP,
        ])
      ) {
        setDraggingNodeInfo(undefined);
      }
      if (nodeIsAnyOfTypes(node, [CanvasElementType.REGULAR, CanvasElementType.NURTURING_BUCKET])) {
        const dropInvalidReason = getDropInvalidReason(node.position, node.id);

        if (!dropInvalidReason) {
          if (onSweepNodesChange) {
            onSweepNodesChange({
              type: SweepNodesChangeType.MoveNode,
              change: {
                nodesToMove: [
                  {
                    parentId: node.parentNode as string,
                    nodeId: node.id,
                    oldPosition: sweepNodes.find((node) => node.id === node.id)
                      ?.position as SweepCanvasNodePosition,
                    newPosition: _reactFlowPositionToRoundedCanvasIndexPosition(node.position),
                  },
                ],
              },
            });
          }
        }
      }

      if (nodeIsAnyOfTypes(node, [CanvasElementType.GROUP, CanvasElementType.GROUP_LABEL])) {
        const nodeInfo = extractNodeInfo(node);
        const change: SweepNodesChangeMoveGroupEvent = {
          change: {
            groupsToMove: [
              {
                nodeId: nodeInfo.id,
                newPosition: _reactFlowPositionToRoundedCanvasIndexPosition(nodeInfo.position),
                oldPosition: sweepGroups.find((group) => group.id === nodeInfo.id)?.position || {
                  column: 0,
                  row: 1,
                },
              },
            ],
          },
          type: SweepNodesChangeType.MoveGroup,
        };
        onSweepNodesChange?.(change);
        setDraggingGroupInfo(undefined);
      }

      setTemporaryTransformations(undefined);
    },
    [
      extractNodeInfo,
      getDropInvalidReason,
      onSweepNodesChange,
      setDraggingGroupInfo,
      setDraggingNodeInfo,
      setTemporaryTransformations,
      sweepGroups,
      sweepNodes,
    ],
  );

  const onDrag: NodeDragHandler = useCallback(
    (event, node) => {
      onDropZoneHandler(node, draggingNodeInfo?.initialPosition);
      if (nodeIsAnyOfTypes(node, [CanvasElementType.GROUP, CanvasElementType.GROUP_LABEL])) {
        setDraggingGroupInfo(extractNodeInfo(node));
      }
    },
    [draggingNodeInfo?.initialPosition, extractNodeInfo, onDropZoneHandler, setDraggingGroupInfo],
  );

  return {
    onDrag,
    onDragStart,
    onDragStop,
  };
};
