import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Button, IconButton, Typography } from '@sweep-io/sweep-design';
import SweepTextField from '../common/SweepTextField';
import { useCallback, useState } from 'react';
import { LogoDevSelector } from '../common/logo-dev-selector/LogoDevSelector';
import { LogoDevBrand } from '../../apis/facades/useLogoDevApiFacade';
import { DialogLoader } from './DialogLoader';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import { useDispatch } from 'react-redux';
import { removeThirdPartyFunnelFromCanvas } from '../../reducers/united-canvas/unitedCanvasReducer';

export interface ThirdPartyFunnelDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (formValues: FunnelDialogFormValues) => void;
  thirdPartyFunnel?: ThirdPartyFunnelData;
  isLoading: boolean;
  confirmButtonText: string;
  title: string;
}

export interface FunnelDialogFormValues {
  name: string;
  system?: LogoDevBrand;
  description?: string;
}

export const ThirdPartyFunnelDialog = ({
  onClose,
  onConfirm,
  thirdPartyFunnel,
  isLoading,
  confirmButtonText,
  title,
}: ThirdPartyFunnelDialogProps) => {
  const [values, setValues] = useState<FunnelDialogFormValues>({
    name: thirdPartyFunnel?.name ?? '',
    system: thirdPartyFunnel?.system,
    description: thirdPartyFunnel?.description ?? '',
  });

  const handleChange = useCallback(
    (key: keyof FunnelDialogFormValues) => (value: any) => {
      setValues((prev) => ({
        ...prev,
        [key]: value,
      }));
    },
    [],
  );

  const _onConfirm = useCallback(() => {
    onConfirm(values);
  }, [onConfirm, values]);
  const dispatch = useDispatch();

  const onRemove = () => {
    if (thirdPartyFunnel) {
      dispatch(removeThirdPartyFunnelFromCanvas(thirdPartyFunnel?.id));
      onClose();
    }
  };

  const renderLoader = () => {
    if (!isLoading) return null;
    return <DialogLoader />;
  };

  const isValid = !values.name;

  return (
    <Dialog
      open
      onClose={onClose}
      PaperProps={{
        sx: {
          minWidth: '604px',
        },
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h2">{title}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton size="small" variant="flat" onClick={onClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            paddingTop: 2,
          }}
        >
          <SweepTextField
            FormControlProps={{ fullWidth: true }}
            label="Funnel Name"
            markLabelAsRequired
            id="funnel-name"
            autoFocus
            TextFieldProps={{
              fullWidth: true,
              placeholder: 'Type a name for the integration',

              value: values.name,
              onChange: (e) => handleChange('name')(e.target.value),
              // error: isSubmitted && Boolean(errors.name),
              // helperText: isSubmitted ? errors.name : '',
              inputProps: {
                'data-testid': '3th-party-funnel-name-input',
              },
            }}
          />
          <LogoDevSelector
            onChange={handleChange('system')}
            brand={values.system}
            label="3rd party system (optional)"
            placeholder="Choose system"
          />
          <SweepTextField
            FormControlProps={{ fullWidth: true }}
            label="Description (optional)"
            id="funnel-description"
            autoFocus
            TextFieldProps={{
              fullWidth: true,
              placeholder: 'Describe how the 3rd party funnel is connected to Salesforce',

              value: values.description,
              onChange: (e) => handleChange('description')(e.target.value),
              inputProps: {
                'data-testid': 'funnel-name-input',
              },
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {thirdPartyFunnel && (
            <Button onClick={onRemove} variant="link" type="error">
              Remove
            </Button>
          )}
          <Button onClick={_onConfirm} disabled={isValid}>
            {confirmButtonText}
          </Button>
        </DialogActions>
      </form>
      {renderLoader()}
    </Dialog>
  );
};
