import { Box } from '@mui/material';
import { escapeRegexp } from './escapeRegex';

export const highlightMatch = (label: string, searchString?: string) => {
  if (!searchString || !label) {
    return <>{label}</>;
  }

  const newLabel = label.replace(
    new RegExp(escapeRegexp(searchString), 'gi'),
    '<span class="bolded">$&</span>',
  );

  return (
    <Box
      component="span"
      sx={{ '& .bolded': { fontWeight: 700 } }}
      dangerouslySetInnerHTML={{ __html: newLabel }}
    />
  );
};
