import * as React from "react";
const SvgSalesforceCloudStroke = (props) => /* @__PURE__ */ React.createElement("svg", { width: 210, height: 194, viewBox: "0 0 210 194", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M88.1784 48.4381C94.7393 41.6082 103.871 37.3788 113.959 37.3788C127.409 37.3788 139.066 44.8513 145.343 55.9854C150.915 53.4946 156.95 52.2087 163.053 52.2117C187.264 52.2117 206.917 72.014 206.917 96.4492C206.917 120.884 187.264 140.687 163.053 140.687C160.094 140.687 157.21 140.389 154.385 139.827C148.893 149.616 138.394 156.267 126.438 156.267C121.57 156.277 116.765 155.172 112.389 153.039C106.822 166.116 93.8575 175.307 78.763 175.307C62.9959 175.307 49.6201 165.368 44.464 151.395C42.1692 151.879 39.83 152.123 37.4847 152.121C18.7286 152.121 3.55933 136.728 3.55933 117.822C3.55933 105.119 10.3892 94.0595 20.522 88.0814C18.3723 83.1294 17.2658 77.7876 17.2714 72.3891C17.2714 50.5693 34.9814 32.9341 56.8012 32.9341C69.5792 32.9341 81.0122 39.0242 88.1859 48.477", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 2.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("mask", { id: "path-2-outside-1_554_41354", maskUnits: "userSpaceOnUse", x: 18.5969, y: 0.588153, width: 145.763, height: 193.991, fill: "black" }, /* @__PURE__ */ React.createElement("rect", { fill: "white", x: 18.5969, y: 0.588153, width: 145.763, height: 193.991 }), /* @__PURE__ */ React.createElement("path", { d: "M162.876 190.929C162.361 191.308 161.637 191.197 161.258 190.683L23.3641 3.3728C22.9851 2.858 23.0952 2.13343 23.61 1.75445C24.1248 1.37546 24.8493 1.48556 25.2283 2.00037L163.122 189.31C163.501 189.825 163.391 190.55 162.876 190.929Z" })), /* @__PURE__ */ React.createElement("path", { d: "M162.876 190.929C162.361 191.308 161.637 191.197 161.258 190.683L23.3641 3.3728C22.9851 2.858 23.0952 2.13343 23.61 1.75445C24.1248 1.37546 24.8493 1.48556 25.2283 2.00037L163.122 189.31C163.501 189.825 163.391 190.55 162.876 190.929Z", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M162.876 190.929C160.137 192.945 156.283 192.359 154.267 189.62L22.3018 10.3636C20.2857 7.62501 20.8714 3.77055 23.61 1.75445L23.61 1.75445C23.8572 1.57245 24.1666 1.49611 24.4701 1.54223C24.7736 1.58835 25.0463 1.75315 25.2283 2.00037L163.122 189.31L163.808 190.242L163.122 189.31C163.304 189.557 163.38 189.867 163.334 190.17C163.288 190.474 163.123 190.747 162.876 190.929L162.876 190.929ZM24.5421 1.06823L163.808 190.242L24.5421 1.06823ZM163.808 190.242L161.944 191.615L163.808 190.242ZM22.6779 2.44066L24.5421 1.06823L22.6779 2.44066Z", fill: "#00A4E6", style: {
  fill: "#00A4E6",
  fillOpacity: 1
}, mask: "url(#path-2-outside-1_554_41354)" }));
export default SvgSalesforceCloudStroke;
