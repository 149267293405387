import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { DEBUG_USE_NEW_FIELD_NESTED_SELECTOR } from './debugVariableConsts';

export interface DebugInfoState {
  debugVars: any;
}

const initialState: DebugInfoState = {
  debugVars: {
    [DEBUG_USE_NEW_FIELD_NESTED_SELECTOR]: true,
  },
};

export const debugInfoSlice = createSlice({
  name: 'debugInfo',
  initialState,
  reducers: {
    spreadDebugVar: (state, action: PayloadAction<any>) => {
      state.debugVars = { ...state.debugVars, ...action.payload };
    },
  },
});

export const { spreadDebugVar } = debugInfoSlice.actions;

export const selectDebugInfoDebugVars = (state: RootState) => state.debugInfo.debugVars;

export default debugInfoSlice.reducer;
