import jsep from 'jsep';

export const getStrByLength = (logicString: string): any => {
  const tempParsed = jsep(logicString.toLocaleUpperCase());
  if (tempParsed.body) {
    return tempParsed.body as any;
  }
  if (
    tempParsed &&
    !Array.isArray(tempParsed) &&
    typeof tempParsed === 'object' &&
    tempParsed.type === 'SequenceExpression'
  ) {
    return tempParsed.expressions;
  }
  return [tempParsed];
};

export const extractIdentifierInCallExpressions = (data: any[]): any[] => {
  const newArray: any[] = [];
  if (data && data.length) {
    for (let index = 0; index < data.length; index++) {
      const line = data[index];
      if (line.type === 'CallExpression' && line.callee) {
        newArray.push(line.callee);
        const tmpArray = extractIdentifierInCallExpressions(line.arguments);
        newArray.push({ type: 'CallExpression', arguments: tmpArray });
      } else if (line.type === 'SequenceExpression') {
        const expArray = extractIdentifierInCallExpressions(line.expressions);
        newArray.push({ type: 'SequenceExpression', expressions: expArray });
      } else {
        newArray.push(line);
      }
    }
  }
  return newArray;
};

export const constructLogicString = (parsedLogicString: any): string => {
  let newLogicString = '';

  const construct = (_data: []) => {
    _data.forEach((line: any) => {
      switch (line.type) {
        case 'Identifier':
          return (newLogicString += line.name + ' ');

        case 'Literal':
          if (typeof line.value === 'string') {
            return (newLogicString += '"' + line.value + '" ');
          }
          return (newLogicString += '' + line.value + ' ');

        case 'CallExpression':
          newLogicString += '(';
          construct(line.arguments);
          newLogicString = newLogicString.trim();
          newLogicString += ') ';
          return newLogicString.trim();

        case 'SequenceExpression':
          newLogicString += '(';
          construct(line.expressions);
          newLogicString = newLogicString.trim();
          newLogicString += ') ';
          return newLogicString.trim();
      }
    });
  };

  construct(parsedLogicString);
  return newLogicString.trim();
};

export const handleUpdateLogicDelete = (arr: any[], _index: number) => {
  const index = _index + 1;
  const handleInnerDelete = (line: any, idx: number, isUpdate: boolean) => {
    if (arr[idx - 1] && arr[idx - 1].type === 'Identifier') {
      line.type = 'delete';
      arr[idx - 1].type = 'delete';
      isUpdate && (line.updated = true);
    } else if (arr[idx + 1] && arr[idx + 1].type === 'Identifier') {
      line.type = 'delete';
      arr[idx + 1].type = 'delete';
      isUpdate && (line.updated = true);
    } else {
      line.type = 'delete';
      isUpdate && (line.updated = true);
    }
  };

  arr.forEach((line: any, idx: number) => {
    if (line.type === 'CallExpression') {
      if (line.arguments.length === 0) {
        handleInnerDelete(line, idx, false);
      } else {
        return handleUpdateLogicDelete(line.arguments, index - 1);
      }
    }
    if (line.type === 'SequenceExpression') {
      if (line.expressions.length === 0) {
        handleInnerDelete(line, idx, false);
      } else {
        return handleUpdateLogicDelete(line.expressions, index - 1);
      }
    }
    if (line.type === 'Literal') {
      if (line.value === index && !line.updated) {
        handleInnerDelete(line, idx, true);
      } else if (line.value > (index as number) && !line.updated) {
        line.value = line.value - 1;
        line.raw = line.value.toString();
        line.updated = true;
      }
    }
  });

  return arr.filter((line) => line.type !== 'delete');
};

export function clearFirstConditionIfEmpty<T extends SweepCondition>(_sweepCondition: T) {
  if (_sweepCondition.criteria.length === 1) {
    const tempCondition = _sweepCondition.criteria[0];
    if (tempCondition._fieldIds.length === 0) {
      return { ..._sweepCondition, criteria: [], criteriaLogic: '' };
    }
  }
  return { ..._sweepCondition };
}
