import { Box, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { DIMENSION_AVATAR_MEDIUM } from '../../../../../constants';
import { BaseDialog } from '../../../../common/dialogs/BaseDialog';
import { Button, IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import { AvatarPicture } from '../../../../avatar/AvatarPicture';
import { DeploymentDiff } from './DeploymentDiff';
import { ObjectTypeChip } from '../../../../common/ObjectTypeChip';
import { humanizeDateVariants, humanizeDate } from '../../../../helpers/humanizeDate';
import { UserInfo } from '../../../../../types/UserInfoTypes';
import { RequestChange } from '../../../../../reducers/requestChangesApiReducer';
import { getIsUserInactive } from '../../../../../lib/getIsUserActive';

export const ReviewDialog = ({
  onClose,
  onApprove,
  onReject,
  onDeploy,
  author,
  requestChange,
  funnel,
  crmOrg,
}: {
  onClose: () => void;
  onApprove: () => void;
  onReject: () => void;
  onDeploy: () => void;
  requestChange?: RequestChange;
  author?: UserInfo;
  funnel?: Funnel;
  crmOrg?: CrmOrg;
}) => {
  return (
    <BaseDialog
      open={true}
      scroll="body"
      PaperProps={{ sx: { p: 1, maxWidth: '1200px' } }}
      onClose={onClose}
      hideBackdrop={true}
    >
      <DialogTitle
        component="div"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h1-bold">Review Request</Typography>
        <IconButton variant="flat" onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Box display="flex" gap={3} mb={4}>
          <AvatarPicture
            avatar={{ emoji: author?.emoji, imageUrl: author?.imageUrl }}
            dimension={DIMENSION_AVATAR_MEDIUM}
            clickable={false}
            isInactive={getIsUserInactive(author?.status)}
          />
          <Grid container columnSpacing={1}>
            <Grid item xs={6}>
              <Typography ellipsis={true} variant="h4">
                Requested by {author?.name}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color={colors.grey[800]}>
                Funnel
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="caption" color={colors.grey[800]}>
                Deploy to
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color={colors.grey[800]}>
                {humanizeDate({
                  dateOrTimestamp: requestChange?.createdAt ?? '',
                  variant: humanizeDateVariants.WITH_DAY_AND_TIME,
                })}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body">{funnel?.name}</Typography>
              {'\t'}
              {funnel?.recordType.objectName && (
                <ObjectTypeChip objectType={funnel?.recordType.objectName} />
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body">{crmOrg?.name}</Typography>
            </Grid>
          </Grid>
        </Box>
        {requestChange?.note && (
          <Box>
            <Typography variant="caption" color={colors.grey[800]}>
              Request note
            </Typography>
            <Box
              px={1.5}
              py={1}
              sx={{
                borderRadius: '4px',
                border: '1px solid',
                borderColor: colors.grey[300],
              }}
            >
              <Typography variant="body">{requestChange?.note}</Typography>
            </Box>
          </Box>
        )}
        <Box mt={4}>
          <Box mb={1}>
            <Typography variant="h4">Summary of changes</Typography>
          </Box>
          <DeploymentDiff crmOrgId={crmOrg?.id} snapshotId={requestChange?.snapshotId} />
        </Box>
      </DialogContent>
      <DialogActions sx={{ mt: 3 }}>
        <Button onClick={onReject} startIconName="Chats" variant="outlined">
          Reject and give feedback
        </Button>
        <Button onClick={onApprove} startIconName="Like" variant="outlined">
          Approve
        </Button>
        <Button onClick={onDeploy} startIconName="SweepBug">
          Deploy
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};
