import { ConfirmDialog } from '../ConfirmDialog';
import useConfirm from './useConfirm';

export const DEFAULT_TEXT = 'Leaving this screen will discard all changes';
export const DEFAULT_TITLE = 'Leave without saving?';

interface ConfirmLeaveWithoutSaveDialogProps {
  title?: string;
  text?: string;
}

interface PureConfirmLeaveWithoutSaveDialogProps extends ConfirmLeaveWithoutSaveDialogProps {
  onConfirm: () => void;
  onCancel: () => void;
  isOpen?: boolean;
}

const PureConfirmLeaveWithoutSaveDialog: React.FC<PureConfirmLeaveWithoutSaveDialogProps> = ({
  onCancel,
  onConfirm,
  text,
  title,
  isOpen,
}) => {
  return (
    <ConfirmDialog
      isOpen={Boolean(isOpen)}
      confirmText="Leave"
      confirmButtonType="error"
      cancelText="Cancel"
      title={title ?? DEFAULT_TITLE}
      children={text ?? DEFAULT_TEXT}
      onCancel={onCancel}
      onConfirm={onConfirm}
      dialogContentSx={{ pt: 1 }}
    />
  );
};

const ConfirmLeaveWithoutSaveDialog: React.FC<ConfirmLeaveWithoutSaveDialogProps> = ({
  title,
  text,
}) => {
  const { onConfirm, onCancel } = useConfirm();

  return (
    <PureConfirmLeaveWithoutSaveDialog
      title={title}
      text={text}
      onConfirm={onConfirm}
      onCancel={onCancel}
      isOpen
    />
  );
};

export { ConfirmLeaveWithoutSaveDialog, PureConfirmLeaveWithoutSaveDialog };
