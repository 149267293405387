import FetchProgressBar from './FetchProgressBar';
import HeaderDivider from '../header/HeaderDivider';
import { Stack } from '@mui/material';
import TextWithAnimatedThreeDots from '../../common/TextWithAnimatedThreeDots';
import useInitialFetchProgress from './useInitialFetchProgress';

const DashboardHeaderFetchProgressBar = () => {
  const { progressPercentage } = useInitialFetchProgress();

  return progressPercentage ? (
    <>
      <HeaderDivider />
      <Stack gap={0.5}>
        <TextWithAnimatedThreeDots text="Scanning the environment" />
        <FetchProgressBar
          progressPercentage={progressPercentage}
          totalWidth={159}
          withAnnotation={false}
        />
      </Stack>
    </>
  ) : null;
};

export default DashboardHeaderFetchProgressBar;
