import {
  RefObject,
  createContext,
  createRef,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SweepConditionRuleBuilderRef } from './sweep-condition-rule-builder-types';
import { uniqueId } from '../../../lib/uniqueId';

interface MultipleRuleBuildersContextType {
  generateReferenceFor: (instanceId: string) => void;
  validateAllAndReturnIsValid: () => boolean;
  getRef: (instanceId: string) => RefObject<SweepConditionRuleBuilderRef>;
  removeReferenceFor: (instanceId: string) => void;
}

const MultipleRuleBuildersContext = createContext<MultipleRuleBuildersContextType>({
  generateReferenceFor: () => {},
  validateAllAndReturnIsValid: () => false,
  getRef: () => ({ current: null }),
  removeReferenceFor: () => {},
});

const MultipleRuleBuildersProvider = ({ children }: any) => {
  const [referenceMap, setReferenceMap] = useState<
    Record<string, RefObject<SweepConditionRuleBuilderRef>>
  >({});

  const generateReferenceFor = (instanceId: string) => {
    setReferenceMap((_referenceMap) => {
      if (referenceMap[instanceId]) {
        return _referenceMap;
      }
      const ref = createRef<SweepConditionRuleBuilderRef>();
      return { ..._referenceMap, [instanceId]: ref };
    });
  };

  const getRef = useCallback(
    (instanceId: string) => {
      return referenceMap[instanceId];
    },
    [referenceMap],
  );

  const removeReferenceFor = useCallback(
    (instanceId: string) => {
      const newReferenceMap = { ...referenceMap };
      delete newReferenceMap[instanceId];
      setReferenceMap(newReferenceMap);
    },
    [referenceMap],
  );

  const validateAllAndReturnIsValid = useCallback(() => {
    let isValid = true;
    Object.values(referenceMap).forEach((ref) => {
      const errors = ref.current?.triggerValidation();
      if (errors?.length) {
        isValid = false;
      }
    });
    return isValid;
  }, [referenceMap]);

  return (
    <MultipleRuleBuildersContext.Provider
      value={{ generateReferenceFor, validateAllAndReturnIsValid, getRef, removeReferenceFor }}
    >
      {children}
    </MultipleRuleBuildersContext.Provider>
  );
};

const useRefForMultipleRuleBuilders = () => {
  const [instanceId] = useState<string>(uniqueId);
  const { generateReferenceFor, getRef, removeReferenceFor } = useContext(
    MultipleRuleBuildersContext,
  );

  useEffect(() => {
    generateReferenceFor(instanceId);
    return () => {
      removeReferenceFor(instanceId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getReference = useCallback(() => getRef(instanceId), [getRef, instanceId]);

  return getReference();
};

const useValidateAllAndReturnIsValid = () => {
  const { validateAllAndReturnIsValid } = useContext(MultipleRuleBuildersContext);

  return validateAllAndReturnIsValid;
};

export {
  MultipleRuleBuildersProvider,
  useRefForMultipleRuleBuilders,
  useValidateAllAndReturnIsValid,
};
