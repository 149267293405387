import { styled } from '@mui/material/styles';
import { Alert } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

export const StyledAlert = styled(Alert)({
  background: colors.blush[100],
  fontWeight: '500',
  borderRadius: '4px',
  color: colors.night[900],
});
