import { useEffect, useState } from 'react';
import { useRunOnce } from '../../common/useRunOnce';
import {
  SlackConnectionStatus,
  selectSlackIntegrationStatus,
} from '../../../reducers/integrationsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { useSweepFields } from '../../../sweep-fields/useCachedSweepFields';
import { FieldTypeGroup } from '../../../apis/facades/useSweepFieldsApiFacade';
import {
  selectSlackRecipients,
  setOptionsForRecipients,
  setSfRecipients,
  setSlackRecipients,
} from '../../../reducers/slackReducer';
import { selectSweepFieldLabels } from '../../../sweep-fields/sweepFieldsLabelsReducer';

export const useSlackRecipients = ({
  disableSalesforceRecipients,
  objectName,
  crmOrgId,
  setBeforeCallingFields,
  addSweepSlackChannel,
}: {
  disableSalesforceRecipients?: boolean;
  objectName?: string;
  crmOrgId?: string;
  setBeforeCallingFields?: () => void;
  addSweepSlackChannel?: boolean;
}) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const sweepFieldsLabels = useSelector(selectSweepFieldLabels);

  const integrations = useIntegrations();
  const dispatch = useDispatch();

  const { searchSweepFields } = useSweepFields();

  const [isLoading, setLoading] = useState(true);

  useRunOnce(async () => {
    await integrations.getSlackStatus();
  });
  const slackRecipients = useSelector(selectSlackRecipients);

  useEffect(() => {
    const setData = async () => {
      if (slackStatus === SlackConnectionStatus.Connected) {
        if (!slackRecipients.length) {
          try {
            const types = await integrations.getSlackRecipients();
            dispatch(setSlackRecipients({ slackRecipients: types }));
          } catch {
            dispatch(setSlackRecipients({ slackRecipients: [] }));
          }
        }
      }
      setLoading(false);

      if (disableSalesforceRecipients && objectName && crmOrgId) {
        dispatch(setSfRecipients({ objectName, sweepFields: {} }));
      }

      if (!disableSalesforceRecipients && objectName && crmOrgId) {
        setBeforeCallingFields && setBeforeCallingFields();
        const { sweepFields } = await searchSweepFields({
          objectType: [objectName],
          crmOrgId,
          typeGroups: [FieldTypeGroup.UserFieldReference],
        });
        dispatch(setSfRecipients({ objectName, sweepFields }));
      }
      dispatch(
        setOptionsForRecipients({
          objectName: objectName ?? '',
          addSweepSlackChannel: !!addSweepSlackChannel,
          sweepFieldsLabels,
        }),
      );
    };
    setData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slackStatus, disableSalesforceRecipients, addSweepSlackChannel, sweepFieldsLabels]);

  return {
    isLoading,
  };
};
