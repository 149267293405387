import { Box } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';

export interface PluginCardProps {
  svgIcon: React.ReactNode;
  title: string;
  description: string;
  onClick: () => void;
  onAdd: () => void;
}

export const PluginCard = ({ svgIcon, title, description, onClick, onAdd }: PluginCardProps) => {
  return (
    <Box
      sx={{
        border: `1px solid ${colors.grey[300]}`,
        padding: '12px 16px',
        borderRadius: '4px',
        cursor: 'pointer',
        '&:hover': {
          border: `1px solid ${colors.blue[500]}`,

          '& .plugin-button-on-hover': {
            display: 'block',
          },
        },
      }}
      onClick={onClick}
    >
      <Box display="flex" gap="12px" alignItems="center">
        <Box display="flex" alignItems="center">
          {svgIcon}
        </Box>
        <Box display="flex" flexDirection="column" gap="2px" overflow="hidden">
          <Typography variant="body-bold">{title}</Typography>
          <Typography variant="caption" ellipsis color={colors.grey[700]}>
            {description}
          </Typography>
        </Box>
        <Box className="plugin-button-on-hover" sx={{ display: 'none' }}>
          <Button variant="outlined" onClick={onAdd} size="small">
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
