import { PluginCard, PluginCardProps } from '../../../plugins-tab/PluginCard';
import { PluginBantSvg } from '../../icons';
import { bantTexts } from './texts';

type BantPluginCardProps = Pick<PluginCardProps, 'onClick' | 'onAdd'>;

export const BantPluginCard = (props: BantPluginCardProps) => {
  return (
    <PluginCard
      svgIcon={<PluginBantSvg />}
      title={bantTexts.title}
      description={bantTexts.description}
      {...props}
    />
  );
};
