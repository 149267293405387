import { createContext, useContext } from 'react';

interface CreateFieldContextType {
  funnelMapId?: string;
  hideCreateFieldDialog?: boolean;
}

const CreateFieldContext = createContext<CreateFieldContextType>({});

const CreateFieldContextProvider = ({
  children,
  funnelMapId,
  hideCreateFieldDialog,
}: {
  children: React.ReactNode;
  funnelMapId?: string;
  hideCreateFieldDialog?: boolean;
}) => {
  return (
    <CreateFieldContext.Provider
      children={children}
      value={{ funnelMapId, hideCreateFieldDialog }}
    />
  );
};

const useCreateFieldContext = () => useContext(CreateFieldContext);

export { CreateFieldContextProvider, useCreateFieldContext };
