import maxBy from 'lodash/maxBy';
import { DateTime } from 'luxon';

export const changeDateTimeTimezone = (date: DateTime, timezone: string) => {
  const { year, month, day, hour, minute, second } = date;
  const newDate = DateTime.local(year, month, day, hour, minute, second, {
    zone: timezone,
  });
  return newDate;
};

export function inNowBetweenDates(startDateISO: string, endDateISO: string) {
  const startDate = new Date(startDateISO);
  const endDate = new Date(endDateISO);
  const testDate = new Date();

  return startDate <= testDate && testDate <= endDate;
}

export interface TimeOffWithDates extends CrmOrgUserTimeOff {
  _dateTimeStartDate: DateTime;
  _dateTimeEndDate: DateTime;
  isPast?: boolean;
}

export class HistoryTimeOffs {
  private timeOffs: TimeOffWithDates[];

  constructor(timeOffs: CrmOrgUserTimeOff[]) {
    this.timeOffs = timeOffs
      .map((timeOff) => ({
        ...timeOff,
        _dateTimeStartDate: DateTime.fromISO(timeOff.startDate),
        _dateTimeEndDate: DateTime.fromISO(timeOff.endDate),
      }))
      .sort((a, b) => b._dateTimeStartDate.diff(a._dateTimeStartDate).milliseconds);
  }

  getCurrentAndFutureTimeOffs() {
    const now = DateTime.local();
    return this.timeOffs.filter(
      (timeOff) => timeOff.type === 'TIME_OFF' && timeOff._dateTimeEndDate > now,
    );
  }

  getActiveTimeOffUntil() {
    const now = DateTime.local();
    const activeTimeOffs = this.timeOffs.filter(
      (timeOff) =>
        timeOff.type === 'TIME_OFF' &&
        timeOff._dateTimeStartDate < now &&
        timeOff._dateTimeEndDate > now,
    );
    if (activeTimeOffs.length) {
      // return the time off that ends the latest
      return maxBy(activeTimeOffs, (timeOff) => timeOff._dateTimeEndDate);
    }
  }

  getActiveSnooze() {
    const now = DateTime.local();
    const activeSnoozes = this.timeOffs.filter(
      (timeOff) =>
        timeOff.type === 'SNOOZE' &&
        timeOff._dateTimeStartDate < now &&
        timeOff._dateTimeEndDate > now,
    );
    if (activeSnoozes.length) {
      // return the time off that ends the latest
      return maxBy(activeSnoozes, (timeOff) => timeOff._dateTimeEndDate);
    }
  }

  getAllTimeOffs = () =>
    this.timeOffs
      .filter((timeOff) => timeOff.type === 'TIME_OFF')
      .map((timeOff) => ({
        ...timeOff,
        isPast: timeOff._dateTimeEndDate < DateTime.local(),
      }));

  getPreviousTimeOffs() {
    const now = DateTime.local();
    return this.timeOffs.filter((timeOff) => timeOff._dateTimeEndDate < now);
  }
}
