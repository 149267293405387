import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import useQueryParams from '../hooks/useQueryParams';
import {
  AUTH_INVITATION_QUERY_PARAM_NAME,
  AUTH_ORG_COOKIE_NAME,
  AUTH_ORG_QUERY_PARAM_NAME,
} from './authConsts';

export const useAuthOrg = () => {
  const [cookies, setCookie] = useCookies();
  const queryParams = useQueryParams();

  const authOrgIdQueryParam = queryParams.get(AUTH_ORG_QUERY_PARAM_NAME);
  const invitationQueryParam = queryParams.get(AUTH_INVITATION_QUERY_PARAM_NAME);

  useEffect(() => {
    //relevant for orgs with SSO configured in Auth0 (not relevant for "invitation" flow)
    if (authOrgIdQueryParam && !invitationQueryParam) {
      setCookie(AUTH_ORG_COOKIE_NAME, authOrgIdQueryParam, { path: '/' });
    }
  }, [authOrgIdQueryParam, invitationQueryParam, setCookie]);

  const authOrgId = authOrgIdQueryParam ?? cookies[AUTH_ORG_COOKIE_NAME];
  const authOrgQueryParam = authOrgId ? `${AUTH_ORG_QUERY_PARAM_NAME}=${authOrgId}` : '';

  return { authOrgId, authOrgQueryParam };
};
