import { Box, Skeleton, Stack, colors, styled } from '@mui/material';

export const RightPanelSkeleton = ({ isLoading }: { isLoading: boolean }) => {
  const animate = isLoading ? 'wave' : false;
  return (
    <Box sx={{ width: '100%' }}>
      <Stack spacing={4} direction="column">
        <StyledSkeleton variant="rectangular" animation={animate} width={400} height={46} />
        <StyledSkeleton variant="rectangular" animation={animate} width={296} height={42} />
        <StyledSkeleton variant="rectangular" animation={animate} width={'100%'} height={260} />
        <StyledSkeleton variant="rectangular" animation={animate} width={'100%'} height={94} />
      </Stack>
    </Box>
  );
};

const StyledSkeleton = styled(Skeleton)({
  background: colors.grey[100],
});
