import { TableRow as MuiTableRow, TableCell as MuiTableCell } from '@mui/material';

export const TableEmptyStateComponentWrapper = ({
  columnsCount,
  TableEmptyStateJsx,
}: {
  columnsCount: number;
  TableEmptyStateJsx: React.ReactNode;
}) => (
  <MuiTableRow
    sx={{
      '&:hover': { backgroundColor: 'transparent !important' },
    }}
  >
    <MuiTableCell
      colSpan={columnsCount} //needed to cover all the columns
    >
      {TableEmptyStateJsx}
    </MuiTableCell>
  </MuiTableRow>
);
