import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export enum RequestChangesStatus {
  proposed,
  approved,
  rejected,
}

export type RequestChange = {
  id: string;
  createdById: string;
  crmOrgId: string;
  snapshotId: string;
  accountId: string;
  funnelId: string;
  note: string;
  status: RequestChangesStatus;
  createdAt: number;
};

export interface RequestChangesApiState {
  data: { [key: RequestChange['id']]: RequestChange };
  isLoading: boolean;
  error?: string;
}

const initialState: RequestChangesApiState = {
  data: {},
  isLoading: true,
};

export const requestChangesApi = createSlice({
  name: 'requestChangesApi',
  initialState,
  reducers: {
    fetchRequestChanges(state) {
      state.isLoading = true;
    },
    fetchRequestChangesSuccess(state, action: PayloadAction<RequestChange[]>) {
      state.isLoading = false;
      action.payload.forEach((rc) => {
        state.data[rc.id] = rc;
      });
    },
    updateRequestChangeStatus(state, action: PayloadAction<{ requestChangeId: string }>) {
      delete state.data[action.payload.requestChangeId];
    },
  },
});

export const { fetchRequestChanges, fetchRequestChangesSuccess, updateRequestChangeStatus } =
  requestChangesApi.actions;

const selectRequestChanges = (state: RootState) => state[requestChangesApi.name].data;

export const selectRequestChangesByFunnelIds = (funnelIds: string[]) =>
  createSelector([selectRequestChanges], (requestChanges) => {
    return Object.values(requestChanges).filter((rc) => funnelIds.includes(rc.funnelId));
  });

export const selectRequestChangesBySnapshotId = (snapshotId?: string) => (state: RootState) => {
  if (!snapshotId) {
    return;
  }
  return Object.values(state[requestChangesApi.name].data).find(
    (rc) => rc.snapshotId === snapshotId,
  );
};
