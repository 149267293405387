import {
  MenuItem,
  Box,
  CircularProgress,
  Select,
  FormHelperText,
  FormControl,
  FormControlProps,
  SelectProps,
  MenuProps,
} from '@mui/material';
import { ReactNode, SyntheticEvent, useState } from 'react';
import FieldLabel from './FieldLabel';
import { DEFAULT_SELECT_MENU_MAX_HEIGHT } from '../../constants';
import SelectNoBorder from './SelectNoBorder';
import { Typography, colors } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';

export interface SweepSelectProps {
  id?: string;
  renderValue?: (value: unknown) => ReactNode;
  dataTestId?: string;
  MenuProps?: Partial<MenuProps>;
  label?: string;
  labelTooltip?: ReactNode;
  markLabelAsRequired?: boolean;
  error?: string;
  onOpenCb?: (event: SyntheticEvent) => void;
  onCloseCb?: () => void;
  menuMaxHeight?: string;
  FormControlProps?: FormControlProps;
  SelectProps?: SelectProps;
  removeBorders?: boolean;
  children: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
}

const SweepSelect = ({
  id,
  renderValue,
  MenuProps,
  dataTestId,
  onOpenCb,
  label,
  labelTooltip,
  markLabelAsRequired,
  error,
  onCloseCb,
  menuMaxHeight = DEFAULT_SELECT_MENU_MAX_HEIGHT,
  FormControlProps = {},
  SelectProps = {},
  removeBorders,
  children,
  disabled,
  isLoading,
}: SweepSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const SelectComponent = removeBorders ? SelectNoBorder : Select;

  return (
    <FormControl {...FormControlProps}>
      {label && (
        <FieldLabel
          label={label}
          id={id}
          markLabelAsRequired={markLabelAsRequired}
          infoTooltipTitle={labelTooltip ?? ''}
          showTooltip={Boolean(labelTooltip)}
        />
      )}
      <SelectComponent
        disabled={disabled}
        open={isOpen}
        id={id}
        IconComponent={() => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingRight: '8px',
              position: 'absolute',
              right: '0',
              pointerEvents: 'none',
            }}
          >
            {isOpen ? <ChevronUp /> : <ChevronDown />}
          </Box>
        )}
        onOpen={(event) => {
          setIsOpen(true);
          onOpenCb && onOpenCb(event);
        }}
        onClose={() => {
          setIsOpen(false);
          onCloseCb && onCloseCb();
        }}
        renderValue={renderValue}
        MenuProps={{
          PaperProps: { style: { maxHeight: menuMaxHeight } },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          ...MenuProps,
        }}
        error={Boolean(error)}
        data-testid={dataTestId}
        sx={{
          '.MuiInputBase-input': { marginRight: '10px' },
        }}
        {...SelectProps}
      >
        {isLoading && (
          <MenuItem
            disableRipple
            sx={{
              '&.Mui-disabled': {
                opacity: 1,
              },
              cursor: 'auto',
            }}
          >
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              gap="16px"
            >
              <Typography variant="body" color={colors.grey[500]} fontStyle="italic">
                <CircularProgress
                  size="10px"
                  sx={{
                    marginRight: '14px',
                    color: colors.grey[500],
                  }}
                />
                Loading...
              </Typography>
            </Box>
          </MenuItem>
        )}

        {children}
      </SelectComponent>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SweepSelect;
