import { useDispatch, useSelector } from 'react-redux';
import {
  closeStageDialog,
  displayStageDialogLoader,
  resetGatesDirtyMap,
  selectOpportunityTypesList,
  selectStageDialogActiveTab,
  selectStageDialogHasChanges,
  selectStageDialogLoader,
  setGateIsDirtyAction,
  setStageDialogActiveTab,
} from '../../../../../reducers/funnel-flow-page/stageDialogReducer';
import {
  applyNewStageData,
  removeExitCriteriaById,
  removeStage,
  selectFunnelMap,
  setExitCriteria,
} from '../../../../../reducers/united-canvas/unitedCanvasReducer';
import { useStageMetadata } from '../../../../../hooks/useStageMetadata';
import { funnelDetailModel } from '../../../../../models/funnelDetailModel';
import { useCallback, useEffect, useState } from 'react';
import { getCrmOrgById } from '../../../environments/environmentsReducer';
import { selectFunnelStagesMetadata } from '../../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { getLeadingFieldMetadata } from './getLeadingFieldMetadata';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import { ConfirmMetadataChangeDialog } from './ConfirmMetadataChangeDialog';
import { ConfirmSharedMetadataUsage } from './ConfirmSharedMetadataUsage';
import { ACTIONS_EVENTS } from '../../../../../services/events';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';

const useStageDialog = ({
  crmOrgId,
  stage,
  funnelId,
}: {
  crmOrgId: string;
  stage: SweepStage;
  funnelId: string;
}) => {
  const sendBiEvent = useSendBiEvent();
  const dispatch = useDispatch();
  const { openConfirm, onConfirm, onCancel } = useConfirm();
  const { patchStageMetadataWithOverride, patchStageMetadataWithAbort } = useStageMetadata();
  const { stageName, _stageId: stageId } = stage;
  const funnelMap = useSelector(selectFunnelMap);
  const funnel = funnelMap.funnelsData[funnelId];
  const funnelDetails = funnelMap.funnelsData[funnelId]?.funnelDetails;
  const environment = useSelector(getCrmOrgById(crmOrgId));
  const activeTab = useSelector(selectStageDialogActiveTab);
  const showLoader = useSelector(selectStageDialogLoader);

  const opportunityTypes = useSelector(selectOpportunityTypesList);

  //This action triggers the canvas' "auto save"
  const onStageChange = useCallback(
    (newVal: Partial<SweepStage>) => {
      const updatedStage = { ...stage, ...newVal };
      dispatch(
        applyNewStageData({
          stage: updatedStage,
          funnelId,
        }),
      );
    },
    [dispatch, funnelId, stage],
  );

  /**  Logic related to "settings" tab:  **/
  const { getAllStagesMetadata, getSharedMetadataByStageName } = useStageMetadata();
  const allStageMetadata = useSelector(selectFunnelStagesMetadata);
  const leadingFieldMetadata = getLeadingFieldMetadata({ allStageMetadata, stage, funnelDetails });

  // The effect is because we want the most up to date stageMetadata (even if changes were made by other users in other funnels)
  useEffect(() => {
    try {
      getAllStagesMetadata(crmOrgId);
    } catch (e) {
      // silent pass
    }
  }, [getAllStagesMetadata, crmOrgId, stageId]);

  //return value means - "changes were confirmed and applied?"
  const onStageMetadataChange = useCallback(
    async (updatedLeadingFieldMetadata: StageMetadata): Promise<boolean> => {
      const funnelDetailsModel = funnelDetails && funnelDetailModel(funnelDetails);
      const isOpportunityStage = funnelDetailsModel?.isLeadingFieldOpportunityStage();

      if (updatedLeadingFieldMetadata && funnelId) {
        dispatch(displayStageDialogLoader({ showLoader: true }));

        if (!isOpportunityStage) {
          await patchStageMetadataWithOverride({
            funnelId,
            stageMetadata: updatedLeadingFieldMetadata,
          });
        }

        if (isOpportunityStage) {
          try {
            // Try to update metadata with "abort", if it is shared - will throw an error
            await patchStageMetadataWithAbort({
              funnelId,
              stageMetadata: updatedLeadingFieldMetadata,
            });
          } catch (error: any) {
            const affectedFunnels = error?.response?.data?.affectedFunnels;

            if (affectedFunnels?.length > 0) {
              //If duplicated outside the current funnel, open dialog
              const isConfirmed = await openConfirm(
                <ConfirmMetadataChangeDialog
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                  stageName={leadingFieldMetadata?.stageName ?? ''}
                />,
              );
              if (isConfirmed) {
                await patchStageMetadataWithOverride({
                  funnelId,
                  stageMetadata: updatedLeadingFieldMetadata,
                });
              }
              dispatch(displayStageDialogLoader({ showLoader: false }));
              return !!isConfirmed;
            } else {
              //If stage only in the current funnel apply changes
              await patchStageMetadataWithOverride({
                funnelId,
                stageMetadata: updatedLeadingFieldMetadata,
              });
            }
          }
        }
      }

      dispatch(displayStageDialogLoader({ showLoader: false }));
      return true;
    },
    [
      patchStageMetadataWithAbort,
      dispatch,
      funnelId,
      patchStageMetadataWithOverride,
      funnelDetails,
      leadingFieldMetadata?.stageName,
      onConfirm,
      onCancel,
      openConfirm,
    ],
  );
  /**  (End) Logic related to "settings" tab  **/

  /**  Logic related to change stage name:  **/
  //tempStageName is the temporary updated name, until it is confirmed (used by "StageDialogHeader")
  const [tempStageName, setTempStageName] = useState<string | undefined>();
  const updateStageName = useCallback(
    (newStageName: string) => {
      onStageChange({ stageName: newStageName });
    },
    [onStageChange],
  );
  const onStageNameConfirm = useCallback(
    async (_newStageName: string) => {
      const newStageName = _newStageName.trim();
      if (stageName !== newStageName) {
        setTempStageName(newStageName);
        const funnelDetailsModel = funnelDetails && funnelDetailModel(funnelDetails);
        if (
          leadingFieldMetadata &&
          funnelDetailsModel &&
          funnelDetailsModel.isLeadingFieldOpportunityStage()
        ) {
          dispatch(displayStageDialogLoader({ showLoader: true })); //Block UI until response comes

          try {
            const sharedStageMetadata = await getSharedMetadataByStageName({
              newStageName,
              crmOrgId,
              leadingFieldId: leadingFieldMetadata.leadingFieldId,
            });

            if (
              sharedStageMetadata.stageMetadata &&
              sharedStageMetadata?.usedInFunnels &&
              sharedStageMetadata?.usedInFunnels?.length > 0
            ) {
              const isConfirmed = await openConfirm(
                <ConfirmSharedMetadataUsage
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                  sharedMetadata={sharedStageMetadata.stageMetadata}
                />,
              );

              if (isConfirmed) {
                updateStageName(newStageName);
                setTempStageName(undefined); //reset the UI
              } else {
                setTempStageName(undefined); //reset the UI
              }

              dispatch(displayStageDialogLoader({ showLoader: false }));
              return;
            }
          } catch (e) {
            dispatch(displayStageDialogLoader({ showLoader: false }));
          }
        }

        if (leadingFieldMetadata) {
          await onStageMetadataChange({ ...leadingFieldMetadata, stageName: newStageName });
        }
        updateStageName(newStageName);
      }
    },
    [
      dispatch,
      getSharedMetadataByStageName,
      crmOrgId,
      funnelDetails,
      stageName,
      leadingFieldMetadata,
      onStageMetadataChange,
      updateStageName,
      onConfirm,
      onCancel,
      openConfirm,
    ],
  );
  /**  (End) Logic related to change stage name **/

  /**  Logic related to ConfirmLeaveWithoutSave:  **/
  const isStageDirty = useSelector(selectStageDialogHasChanges);
  const setGateIsDirty = ({
    isDirty,
    exitCriteriaId,
  }: {
    isDirty: boolean;
    exitCriteriaId: string;
  }) => {
    dispatch(setGateIsDirtyAction({ isDirty, exitCriteriaId }));
  };
  const onTabChange = async (event: React.SyntheticEvent, val: any) => {
    if (isStageDirty) {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (!isConfirmed) {
        return;
      }
    }
    dispatch(resetGatesDirtyMap());
    dispatch(setStageDialogActiveTab(val));
  };
  /**  (End) Logic related to ConfirmLeaveWithoutSave  **/

  const onGateDelete = useCallback(
    (exitCriteriaId: string) => {
      if (funnelId && stageId)
        dispatch(
          removeExitCriteriaById({
            funnelId,
            exitCriteriaId,
            stageId,
          }),
        );
    },
    [dispatch, stageId, funnelId],
  );

  const onGateSave = useCallback(
    (updatedExitCriteria: SweepExitCriteria) => {
      sendBiEvent({ name: ACTIONS_EVENTS.gatesAdd });
      stageId &&
        funnelId &&
        dispatch(
          setExitCriteria({
            funnelId,
            newExitCriteria: updatedExitCriteria,
            stageId: stageId,
          }),
        );
    },
    [sendBiEvent, stageId, funnelId, dispatch],
  );

  const removeStageAndCloseDialog = () => {
    if (!stage) {
      return;
    }

    dispatch(removeStage({ stage, funnelId }));
    dispatch(closeStageDialog());
  };

  return {
    removeStageAndCloseDialog,
    environmentName: environment?.name,
    activeTab,
    showLoader,
    onStageNameConfirm,
    funnel,
    onStageMetadataChange,
    onGateSave,
    onGateDelete,
    leadingFieldMetadata,
    onStageChange,
    opportunityTypes,
    tempStageName,
    onTabChange,
    setGateIsDirty,
  };
};

export { useStageDialog };
