import { MenuItem, Select } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../../../../reducers/userInfoReducer';
import { useRunOnce } from '../../../../common/useRunOnce';
import { useErrorHandling } from '../../../../../hooks/useErrorHandling';
import { useCampaignsApiFacade } from '../../../../../apis/facades/useCampaignsApiFacade';

export const WorkflowStatusField = ({
  value,
  onChange,
  isDisabled,
  campaignId,
}: {
  value?: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  campaignId: string;
}) => {
  const { get_campaign_members_status } = useCampaignsApiFacade();
  const defaultConfigurationId = useSelector(selectDefaultCreationCrmOrgId);
  const [campaignStatuses, setCampaignStatuses] = useState<string[]>();
  const { errorHandlingBuilder } = useErrorHandling();

  useRunOnce(async () => {
    if (defaultConfigurationId) {
      errorHandlingBuilder()
        .withErrorNotification('Error retrieving status field from salesforce')
        .execute(async () => {
          const status = await get_campaign_members_status(campaignId);
          setCampaignStatuses(status);
        });
    }
  });

  const renderMenuItems = () => {
    if (value && !campaignStatuses) {
      return <MenuItem value={value}>{value}</MenuItem>;
    }
    if (campaignStatuses) {
      return campaignStatuses.map((val) => (
        <MenuItem key={val} value={val}>
          {val}
        </MenuItem>
      ));
    }
  };
  return (
    <Select
      value={value}
      placeholder="Select status"
      disabled={isDisabled}
      onChange={(e) => onChange(e.target.value)}
    >
      {renderMenuItems()}
    </Select>
  );
};
