import { useCallback, useEffect, useMemo } from 'react';
import { DocumentationDialog } from '../../documentation/DocumentationDialog';
import { WithConfigurationCanvas } from '../configuration-canvas/WithConfigurationCanvas';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { VisibilityLayers } from '../../../types/enums/VisibilityLayers';
import { VisibilityElementLayersSelector } from '../configuration-canvas/types';
import { ConfigurationCanvasLeftPanel } from '../configuration-canvas-panel';
import { useSelector } from 'react-redux';
import { selectSingleObject } from '../../../reducers/documentationReducer';
import { getFunnelsStepNames } from '../../documentation/helpers';
import { useCanvasFilters } from '../configuration-canvas-filters/useCanvasFilters';
import { getRecordTypeNames } from '../../documentation/selected-object/utils';
import { useHighlightEntities } from '../../documentation/selected-object/filters/useHighlightEntities';
import { DocumentationContextProvider } from '../../documentation/context/DocumentationContextProvider';
import useAutomationsCanvasInteractions from './useAutomationsCanvasInteractions';
import { useDocumentationCanvasInteractions } from '../../documentation/useCanvasDocumentationInteractions';

const visibilityElementsLayerSelector: VisibilityElementLayersSelector = {
  type: 'layersSelector',
  layersId: 'configurationCanvas',
  visibilityLayersSelectors: {
    [VisibilityLayers.SWEEP_AUTOMATIONS]: true,
    [VisibilityLayers.NURTURING_STEPS]: true,
    [VisibilityLayers.COMMENTS]: true,
    [VisibilityLayers.VALIDATION_RULES]: true,
    [VisibilityLayers.SFDC_AUTOMATIONS]: true,
    [VisibilityLayers.APEX]: true,
    [VisibilityLayers.PLAYBOOK_ALERTS]: true,
    [VisibilityLayers.ASSIGNMENT_RULES]: true,
    [VisibilityLayers.SCHEDULED_ASSIGNMENTS]: true,
    [VisibilityLayers.FUNNEL_MATCHING_AND_DEDUPLICATION]: true,
  },
  defaultVisibilityMap: {
    [VisibilityLayers.NURTURING_STEPS]: true,
    [VisibilityLayers.COMMENTS]: true,
    [VisibilityLayers.SFDC_AUTOMATIONS]: true,
    [VisibilityLayers.APEX]: true,
    [VisibilityLayers.VALIDATION_RULES]: true,
  },
};

const CanvasDocumentationPage = () => {
  const { canvasFunnelMap, appCrmOrgId } = useConfigurationCanvas();
  const funnelsData = useMemo(() => canvasFunnelMap.funnelsData, [canvasFunnelMap]);
  const recordTypesData = useMemo(() => canvasFunnelMap.recordTypesData, [canvasFunnelMap]);

  const { _setCanvasFiltersFunnelMap, setFilterValues, focusOnCanvasElements } = useCanvasFilters();

  const { onEntityClick } = useAutomationsCanvasInteractions();
  const { onDocumentationEntityClick, dispatchSingleObjectNameAndFocusOnElement } =
    useDocumentationCanvasInteractions({
      crmOrgId: appCrmOrgId,
      onSweepElementClick: onEntityClick,
      focusOnCanvasElements,
      setFilterValues,
    });

  const highlightEntities = useHighlightEntities(canvasFunnelMap);
  const visibilityElements = visibilityElementsLayerSelector;

  useEffect(() => {
    //required to make zoom work properly when filters are not yet rendered
    if (canvasFunnelMap) {
      _setCanvasFiltersFunnelMap(canvasFunnelMap);
    }
  }, [canvasFunnelMap, _setCanvasFiltersFunnelMap]);

  const _onDocumentationEntityClick = useCallback(
    (props: any) => {
      onDocumentationEntityClick({ ...props, funnelsData, recordTypesData });
    },
    [funnelsData, recordTypesData, onDocumentationEntityClick],
  );

  return (
    <WithConfigurationCanvas
      onFunnelStepClick={_onDocumentationEntityClick}
      onRecordTypeStepClick={_onDocumentationEntityClick}
      onFunnelLabelClick={_onDocumentationEntityClick}
      onFunnelLabelPillClick={_onDocumentationEntityClick}
      onRecordTypeLabelClick={_onDocumentationEntityClick}
      onRecordTypeLabelPillClick={_onDocumentationEntityClick}
      highlightEntities={highlightEntities}
      visibilityElements={visibilityElements}
      readOnly
    >
      <ConfigurationCanvasLeftPanel withExpandedModeButton>
        <DocumentationDialog
          crmOrgId={appCrmOrgId}
          hideAddObjectButton={false}
          isOpen
          dispatchSingleObjectNameAndFocusOnElement={dispatchSingleObjectNameAndFocusOnElement}
        />
      </ConfigurationCanvasLeftPanel>
    </WithConfigurationCanvas>
  );
};

const CanvasDocumentationPageWithDocumentationContext = () => {
  const { canvasFunnelMap } = useConfigurationCanvas();
  const funnelsData = useMemo(() => canvasFunnelMap.funnelsData, [canvasFunnelMap]);
  const recordTypesData = useMemo(() => canvasFunnelMap.recordTypesData, [canvasFunnelMap]);

  const { filterValues } = useCanvasFilters();
  const objectType = useSelector(selectSingleObject);

  const stepNames = useMemo(
    () =>
      getFunnelsStepNames({
        funnelsData,
        recordTypesData,
      })[objectType?.objectType ?? ''] ?? [],
    [funnelsData, recordTypesData, objectType?.objectType],
  );

  const currentRecordTypesByObjectName = useMemo(
    () =>
      getRecordTypeNames({
        funnelsData,
        recordTypesData,
      }),
    [funnelsData, recordTypesData],
  );

  const objectNamesUsedInCanvas = useMemo(
    () => Object.keys(currentRecordTypesByObjectName),
    [currentRecordTypesByObjectName],
  );

  return (
    <DocumentationContextProvider
      value={{
        objectNamesUsedInCanvas,
        recordTypesUsedInCanvas: currentRecordTypesByObjectName,
        stepNamesUsedInCanvas: stepNames,
        funnelsData,
        recordTypesData,
        filterValues,
      }}
    >
      <CanvasDocumentationPage />
    </DocumentationContextProvider>
  );
};

export { CanvasDocumentationPageWithDocumentationContext as CanvasDocumentationPage };
