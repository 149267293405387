import { Box, Popper, PopperPlacementType, PopperProps, useTheme } from '@mui/material';
import { useState } from 'react';

const ARROW_HEIGHT = '12.5px';
const ARROW_WIDTH = '6.5px';

const PopperWithArrow = ({
  PopperProps,
  children,
  arrowColor,
  placement = 'top',
}: {
  PopperProps: PopperProps;
  children: any;
  arrowColor: string;
  placement?: PopperPlacementType;
}) => {
  const theme = useTheme();
  const [arrowElement, setArrowElement] = useState<any>();
  const { sx, ...restPopperProps } = PopperProps;

  return (
    <Popper
      placement={placement}
      disablePortal
      popperOptions={{
        modifiers: [
          {
            name: 'arrow',
            options: {
              element: arrowElement,
              enabled: true,
            },
          },
          {
            name: 'offset',
            options: {
              offset: [0, 15],
            },
          },
        ],
      }}
      sx={{
        zIndex: 5,
        padding: '5px 10px',
        borderRadius: '4px',
        boxShadow: theme.shadows[5],

        '&[data-popper-placement*="top"] > [data-popper-arrow*="true"]': {
          bottom: 0,
          marginBottom: `-${ARROW_HEIGHT}`,
          '&::before': {
            borderWidth: `${ARROW_HEIGHT} ${ARROW_WIDTH} 0 ${ARROW_WIDTH}`,
            borderColor: `${arrowColor} transparent transparent transparent`,
          },
        },
        '&[data-popper-placement*="bottom"] > [data-popper-arrow*="true"]': {
          top: 0,
          marginTop: `-${ARROW_HEIGHT}`,
          '&::before': {
            borderWidth: `0 ${ARROW_WIDTH} ${ARROW_HEIGHT} ${ARROW_WIDTH}`,
            borderColor: `transparent transparent ${arrowColor} transparent`,
          },
        },
        '&[data-popper-placement*="left"] > [data-popper-arrow*="true"]': {
          right: 0,
          marginRight: `-${ARROW_HEIGHT}`,
          '&::before': {
            borderWidth: `${ARROW_WIDTH} 0 ${ARROW_WIDTH} ${ARROW_HEIGHT}`,
            borderColor: `transparent transparent transparent ${arrowColor}`,
          },
        },
        '&[data-popper-placement*="right"] > [data-popper-arrow*="true"]': {
          left: 0,
          marginLeft: `-${ARROW_HEIGHT}`,
          '&::before': {
            borderWidth: `${ARROW_WIDTH} ${ARROW_HEIGHT} ${ARROW_WIDTH} 0`,
            borderColor: `transparent ${arrowColor} transparent transparent `,
          },
        },

        ...sx,
      }}
      {...restPopperProps}
    >
      <Box
        data-popper-arrow
        ref={setArrowElement}
        sx={{
          '&::before': {
            content: '""',
            margin: '1px',
            display: 'block',
            borderStyle: 'solid',
          },
        }}
      />
      {children}
    </Popper>
  );
};

export { PopperWithArrow };
