import { SxProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';

export const CenteredRowWithGap = ({
  children,
  gap = 1,
  ml = 0,
  sx,
}: {
  children: ReactNode;
  gap?: number;
  ml?: number;
  sx?: SxProps;
}) => (
  <Stack direction="row" alignItems="center" gap={gap} ml={ml} sx={sx}>
    {children}
  </Stack>
);
