import { useLocation } from 'react-router';
import { useEffect } from 'react';
import usePrevious from './usePrevious';

const useOnLocationChange = (action: (pathname: string, prevPath?: string) => void) => {
  const { pathname } = useLocation();

  const prevPath = usePrevious(pathname);
  useEffect(() => {
    if (pathname !== prevPath) {
      action(pathname, prevPath);
    }
  }, [action, pathname, prevPath]);
};

export default useOnLocationChange;
