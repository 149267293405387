import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

export interface FunnelMapsState {
  funnelMapsList: FunnelMap[];
  isLoading: boolean;
}

const initialState: FunnelMapsState = {
  funnelMapsList: [],
  isLoading: true,
};

export const funnelMapsSlice = createSlice({
  name: 'funnelMaps',
  initialState,
  reducers: {
    setFunnelMapsList: (state, action: PayloadAction<{ funnelMapsList: FunnelMap[] }>) => {
      state.isLoading = false;
      state.funnelMapsList = action.payload.funnelMapsList;
    },
    updateFunnelMapName: (
      state,
      action: PayloadAction<{ funnelMapNewName: string; funnelMapId: string }>,
    ) => {
      const { funnelMapNewName, funnelMapId } = action.payload;
      state.funnelMapsList = state.funnelMapsList.map((funnelMap) => {
        if (funnelMap.id === funnelMapId) {
          const updatedFunnelMapWrapper = funnelMap;
          updatedFunnelMapWrapper.name = funnelMapNewName;
          return updatedFunnelMapWrapper;
        }
        return funnelMap;
      });
    },
    deleteFunnelMapFromList: (state, action: PayloadAction<{ funnelMapId: string }>) => {
      state.funnelMapsList = state.funnelMapsList.filter(
        (funnelMap) => funnelMap.id !== action.payload.funnelMapId,
      );
    },
    setIsPinned: (state, action: PayloadAction<{ funnelMapId: string; isPinned: boolean }>) => {
      const { funnelMapId, isPinned } = action.payload;
      state.funnelMapsList = state.funnelMapsList.map((funnelMap) => {
        funnelMap.isPinned = isPinned && funnelMap.id === funnelMapId;
        return funnelMap;
      });
    },
  },
});

export const selectFunnelMapsList = (state: RootState) => state.funnelMaps.funnelMapsList;
const selectFunnelMapsIsLoading = (state: RootState) => state.funnelMaps.isLoading;

export const selectPinnedFunnelMap = (state: RootState) =>
  state.funnelMaps.funnelMapsList.find((funnelMap) => funnelMap.isPinned); //there should be only 1

export const selectNonPinnedFunnelMaps = createSelector(
  [selectPinnedFunnelMap, selectFunnelMapsList, selectFunnelMapsIsLoading],
  (pinnedFunnelMap, funnelMapsList, isLoading) => {
    if (!pinnedFunnelMap) {
      return {
        funnelMapsList: funnelMapsList ?? [],
        isLoading,
      };
    }
    //filtering like this to handle the (unlikely) case there is more than 1 pinned - want to return all the rest of the funnels
    return {
      funnelMapsList: funnelMapsList.filter((funnelMap) => funnelMap.id !== pinnedFunnelMap.id),
      isLoading,
    };
  },
);

export const { setFunnelMapsList, deleteFunnelMapFromList, updateFunnelMapName, setIsPinned } =
  funnelMapsSlice.actions;

export default funnelMapsSlice.reducer;
