import { useMemo } from 'react';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { HighlightEntities, TransientFunnel } from './funnelMapCanvasTypes';
import { PillsMap } from '../canvas-pills/canvasPillsReducer';
import { Dictionary } from 'lodash';
import { useCalculateThirdPartyNodes } from './calculate-nodes/useCalculateThirdPartyNodes';
import { useCalculateRecordTypeNodes } from './calculate-nodes/useCalculateRecordTypeNodes';
import { useCalculateSfFunnelsNodes } from './calculate-nodes/useCalculateSfFunnelsNodes';
import { useCalculateHubspotNodes } from './calculate-nodes/useCalculateHubspotTypeNodes';

export const TRANSIENT_GROUP_ID = 'TRANSIENT_GROUP';

export const useFunnelMapElements = ({
  funnelMap,
  funnelStageMetadata,
  transientFunnel,
  highlightEntities,
  renderZeroCountPills,
  visibilityMap,
  pills,
  objectTypesByName,
  editingThirdPartyStepId,
}: {
  funnelMap: FunnelMap;
  funnelStageMetadata?: StageMetadata[];
  visibilityMap: VisibilityMap;
  transientFunnel?: TransientFunnel;
  highlightEntities?: HighlightEntities[];
  renderZeroCountPills?: boolean;
  pills?: Partial<PillsMap>;
  objectTypesByName: Dictionary<ObjectTypeName>;
  editingThirdPartyStepId?: string;
}) => {
  const hubspotNodes = useCalculateHubspotNodes({
    funnelMap,
    pills,
    visibilityMap,
  });

  const sfFunnelNodes = useCalculateSfFunnelsNodes({
    funnelMap,
    visibilityMap,
    funnelStageMetadata,
    highlightEntities,
    renderZeroCountPills,
    pills,
    transientFunnel,
    objectTypesByName,
  });
  const thirdPartyNodes = useCalculateThirdPartyNodes({
    funnelMap,
    transientFunnel,
    editingThirdPartyStepId,
  });
  const recordTypeNodes = useCalculateRecordTypeNodes({
    funnelMap,
    objectTypesByName,
    highlightEntities,
    renderZeroCountPills,
    pills,
    visibilityMap,
  });

  return useMemo(() => {
    const sweepNodes = [
      ...hubspotNodes.nodes,
      ...sfFunnelNodes.nodes,
      ...thirdPartyNodes.nodes,
      ...recordTypeNodes.nodes,
    ];
    const sweepEdges = [
      ...hubspotNodes.edges,
      ...sfFunnelNodes.edges,
      ...thirdPartyNodes.edges,
      ...recordTypeNodes.edges,
    ];
    const sweepGroups = [
      ...hubspotNodes.groups,
      ...sfFunnelNodes.groups,
      ...thirdPartyNodes.groups,
      ...recordTypeNodes.groups,
    ];

    return {
      sweepGroups,
      sweepEdges,
      sweepNodes,
    };
  }, [
    hubspotNodes.edges,
    hubspotNodes.groups,
    hubspotNodes.nodes,
    recordTypeNodes.edges,
    recordTypeNodes.groups,
    recordTypeNodes.nodes,
    sfFunnelNodes.edges,
    sfFunnelNodes.groups,
    sfFunnelNodes.nodes,
    thirdPartyNodes.edges,
    thirdPartyNodes.groups,
    thirdPartyNodes.nodes,
  ]);
};
