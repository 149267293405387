import { XYPosition, Node as ReactFlowNode } from 'reactflow';
import { animate } from 'popmotion';
import { SWEEP_GRID_SIZE } from './const';
import { useCallback, useState } from 'react';
import { RFNodeData, SweepCanvasNodeTransformations } from './canvasTypes';

export interface NodeAnimateTransformations {
  [nodeId: string]: XYPosition;
}

export const useNodeAnimations = () => {
  const [animationTransformations, setAnimationTransformations] =
    useState<NodeAnimateTransformations>();

  const withAnimation = useCallback(
    ({
      nodeTransformations,
      onComplete,
    }: {
      nodeTransformations: SweepCanvasNodeTransformations;
      onComplete: () => any;
    }) => {
      const nodeTransformationsIds = Object.keys(nodeTransformations);
      if (nodeTransformationsIds.length > 0) {
        return animate({
          to: SWEEP_GRID_SIZE.width,
          duration: 150,
          onUpdate: (latest) => {
            const nodeAnimationTransformations = nodeTransformationsIds.reduce(
              (transformations, nodeId) => {
                transformations[nodeId] = { x: latest, y: 0 };
                return transformations;
              },
              {} as NodeAnimateTransformations,
            );

            setAnimationTransformations(nodeAnimationTransformations);
          },
          onComplete: () => {
            onComplete();
            setAnimationTransformations(undefined);
          },
        });
      }
      return onComplete();
    },
    [],
  );

  const addAnimationsToReactFlowNodes = useCallback(
    (reactFlowNodes: ReactFlowNode<RFNodeData>[]) => {
      if (animationTransformations) {
        reactFlowNodes.forEach((node) => {
          if (animationTransformations[node.id]) {
            const newPosition = node.position;
            newPosition.x += animationTransformations[node.id].x;
            newPosition.y += animationTransformations[node.id].y;
            node.position = newPosition;
            node.positionAbsolute = newPosition;
          }
        });
      }
    },
    [animationTransformations],
  );

  return {
    withAnimation,
    animationTransformations,
    addAnimationsToReactFlowNodes,
  };
};
