import { Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';

export const CustomHeader = ({ customHeader }: { customHeader: string }) => {
  return (
    <Box
      className="rbHeader"
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        margin: '10px 5px 0px',
      }}
    >
      <Box sx={{ fontSize: '12px', marginLeft: '24px' }}>
        <Typography variant="body-medium">{customHeader}</Typography>
      </Box>
    </Box>
  );
};
