import { CanvasHubspotPage } from '../canvas-pages/canvas-hubspot-page/CanvasHubspotPage';
import { CanvasAlertsPage } from '../canvas-pages/CanvasAlertsPage';
import { CanvasAutomationPage } from '../canvas-pages/CanvasAutomationPage';
import { CanvasDocumentationPage } from '../canvas-pages/CanvasDocumentationPage';
import { CanvasRoutingPage } from '../canvas-pages/CanvasRoutingPage';
import { CanvasDedupAndMatchingPage } from '../canvas-pages/dedup-and-matching/CanvasDedupAndMatchingPage';
import { CanvasUnitedConfigurationPage } from '../canvas-pages/united-canvas/canvas-page/CanvasUnitedConfigurationPage';

export enum ConfigurationPages {
  Documentation = 'documentation',
  Automations = 'automations',
  Routing = 'routing',
  Alerts = 'alerts',
  United = 'united',
  ReadOnlyUnited = 'read-only-united',
  Hubspot = 'hubspot',
  DedupAndMatching = 'dedup-and-matching',
}

export const configurationPages: {
  [key in ConfigurationPages]: React.FC;
} = {
  [ConfigurationPages.Documentation]: CanvasDocumentationPage,
  [ConfigurationPages.Automations]: CanvasAutomationPage,
  [ConfigurationPages.Routing]: CanvasRoutingPage,
  [ConfigurationPages.Alerts]: CanvasAlertsPage,
  [ConfigurationPages.United]: CanvasUnitedConfigurationPage,
  [ConfigurationPages.ReadOnlyUnited]: CanvasUnitedConfigurationPage,
  [ConfigurationPages.Hubspot]: CanvasHubspotPage,
  [ConfigurationPages.DedupAndMatching]: CanvasDedupAndMatchingPage,
};
