import { PluginCard, PluginCardProps } from '../../../plugins-tab/PluginCard';
import { PluginDataManagementSvg } from '../../icons';
import { dataManagementTexts } from './texts';

type DataManagementPluginCardProps = Pick<PluginCardProps, 'onClick' | 'onAdd'>;

export const DataManagementPluginCard = (props: DataManagementPluginCardProps) => {
  return (
    <PluginCard
      svgIcon={<PluginDataManagementSvg />}
      title={dataManagementTexts.title}
      description={dataManagementTexts.description}
      {...props}
    />
  );
};
