import { Box, CircularProgress } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';

export const LoadingNewEnvironment = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        textAlign: 'center',
      }}
    >
      <Box sx={{ padding: '16px 0 24px' }}>
        <CircularProgress />
      </Box>
      <Typography variant="h4">Adding New Environment...</Typography>
      <Typography variant="caption" color={colors.grey[800]}>
        it might take a few minutes
      </Typography>
    </Box>
  );
};
