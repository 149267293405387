import * as React from "react";
const SvgAddThirdParty = (props) => /* @__PURE__ */ React.createElement("svg", { width: 115, height: 72, viewBox: "0 0 115 72", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 6.53065, y: 1.40453, width: 82.1789, height: 36.7402, rx: 1.65658, transform: "rotate(7.99564 6.53065 1.40453)", fill: "#F8F9FA", stroke: "#EDEDED", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1,
  stroke: "#EDEDED",
  stroke: "color(display-p3 0.9292 0.9292 0.9292)",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 10.8257, y: 21.8745, width: 47.52, height: 7, rx: 2, transform: "rotate(7.96689 10.8257 21.8745)", fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 36.3958, y: 12.3267, width: 43.24, height: 7, rx: 2, transform: "rotate(7.96689 36.3958 12.3267)", fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 12.6272, y: 9, width: 19.24, height: 7, rx: 2, transform: "rotate(7.96689 12.6272 9)", fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M107.433 27.6171C107.247 27.8501 107.286 28.1893 107.519 28.3747C107.752 28.5601 108.091 28.5214 108.277 28.2884L107.433 27.6171ZM75.8783 17.4473C75.7337 17.7076 75.8275 18.0358 76.0878 18.1804L80.3295 20.5369C80.5898 20.6815 80.918 20.5877 81.0626 20.3275C81.2072 20.0672 81.1134 19.7389 80.8531 19.5943L77.0827 17.4997L79.1774 13.7293C79.322 13.469 79.2282 13.1408 78.9679 12.9961C78.7076 12.8515 78.3794 12.9453 78.2348 13.2056L75.8783 17.4473ZM108.277 28.2884C110.278 25.7723 111.603 23.6107 112.333 21.7618C113.062 19.9137 113.213 18.3326 112.795 17.0149C112.372 15.6852 111.401 14.717 110.081 14.0432C108.771 13.3741 107.093 12.9805 105.183 12.7917C97.5695 12.0395 85.6377 14.4947 76.2015 17.1907L76.4977 18.2275C85.9317 15.5321 97.6873 13.1346 105.077 13.8648C106.917 14.0467 108.447 14.4196 109.591 15.0035C110.725 15.5828 111.453 16.3542 111.767 17.3412C112.084 18.3401 112.006 19.6527 111.33 21.3661C110.654 23.0785 109.399 25.146 107.433 27.6171L108.277 28.2884Z", fill: "#9462FF", style: {
  fill: "#9462FF",
  fill: "color(display-p3 0.5807 0.3833 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 64.1577, y: 28.1387, width: 43.9346, height: 43.6827, rx: 2.15658, transform: "rotate(-9.98353 64.1577 28.1387)", fill: "#FFC6F6", style: {
  mixBlendMode: "multiply"
} }), /* @__PURE__ */ React.createElement("rect", { x: 67.7233, y: 30.5283, width: 37.7402, height: 37.7402, rx: 2.15658, transform: "rotate(-9.98353 67.7233 30.5283)", fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.96 45.375L95.6208 52.8622C95.3454 53.25 94.9961 53.5797 94.5929 53.8322C94.1897 54.0848 93.7407 54.2552 93.2715 54.3338C92.8023 54.4124 92.3222 54.3975 91.8588 54.2901C91.3953 54.1828 90.9577 53.9849 90.5709 53.708L84.9881 49.727C84.6003 49.4516 84.2706 49.1023 84.0181 48.6991C83.7655 48.2959 83.5951 47.8469 83.5165 47.3777C83.4379 46.9085 83.4527 46.4284 83.5601 45.965C83.6675 45.5015 83.8654 45.0639 84.1423 44.6771L89.4812 37.1899", fill: "#FEDFF9", style: {
  fill: "#FEDFF9",
  fill: "color(display-p3 0.9961 0.8745 0.9765)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.96 45.375L95.6208 52.8622C95.3454 53.25 94.9961 53.5797 94.5929 53.8322C94.1897 54.0848 93.7407 54.2552 93.2715 54.3338C92.8023 54.4124 92.3222 54.3975 91.8588 54.2901C91.3953 54.1828 90.9577 53.9849 90.5709 53.708L84.9881 49.727C84.6003 49.4516 84.2706 49.1023 84.0181 48.6991C83.7655 48.2959 83.5951 47.8469 83.5165 47.3777C83.4379 46.9085 83.4527 46.4284 83.5601 45.965C83.6675 45.5015 83.8654 45.0639 84.1423 44.6771L89.4812 37.1899M87.7795 51.7175L82.5708 59.022M92.09 39.0502L95.8105 33.8327M102.071 38.2973L98.351 43.5148M102.525 46.4912L87.916 36.0738", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 7, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M100.96 45.375L95.6208 52.8622C95.3454 53.25 94.9961 53.5797 94.5929 53.8322C94.1897 54.0848 93.7407 54.2552 93.2715 54.3338C92.8023 54.4124 92.3222 54.3975 91.8588 54.2901C91.3953 54.1828 90.9577 53.9849 90.5709 53.708L84.9881 49.727C84.6003 49.4516 84.2706 49.1023 84.0181 48.6991C83.7655 48.2959 83.5951 47.8469 83.5165 47.3777C83.4379 46.9085 83.4527 46.4284 83.5601 45.965C83.6675 45.5015 83.8654 45.0639 84.1423 44.6771L89.4812 37.1899", fill: "#FEDFF9", style: {
  fill: "#FEDFF9",
  fill: "color(display-p3 0.9961 0.8745 0.9765)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M100.96 45.375L95.6208 52.8622C95.3454 53.25 94.9961 53.5797 94.5929 53.8322C94.1897 54.0848 93.7407 54.2552 93.2715 54.3338C92.8023 54.4124 92.3222 54.3975 91.8588 54.2901C91.3953 54.1828 90.9577 53.9849 90.5709 53.708L84.9881 49.727C84.6003 49.4516 84.2706 49.1023 84.0181 48.6991C83.7655 48.2959 83.5951 47.8469 83.5165 47.3777C83.4379 46.9085 83.4527 46.4284 83.5601 45.965C83.6675 45.5015 83.8654 45.0639 84.1423 44.6771L89.4812 37.1899M87.7795 51.7175L82.5708 59.022M92.09 39.0502L95.8105 33.8327M102.071 38.2973L98.351 43.5148M102.525 46.4912L87.916 36.0738", stroke: "#F371E6", style: {
  stroke: "#F371E6",
  stroke: "color(display-p3 0.9542 0.4413 0.9029)",
  strokeOpacity: 1
}, strokeWidth: 1.61744, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M77.8862 55.0953C77.9389 54.8022 77.7441 54.5219 77.4511 54.4691L72.6755 53.6095C72.3825 53.5568 72.1021 53.7516 72.0494 54.0446C71.9966 54.3377 72.1914 54.618 72.4845 54.6708L76.7294 55.4349L75.9654 59.6798C75.9126 59.9729 76.1074 60.2532 76.4005 60.3059C76.6935 60.3587 76.9738 60.1639 77.0266 59.8708L77.8862 55.0953ZM45.3903 45.0666L45.4572 44.5317L44.3873 44.3979L44.3204 44.9329L45.3903 45.0666ZM44.3204 44.9329C43.8166 48.9631 43.7485 52.9324 44.4123 56.2994C45.0754 59.6626 46.4846 62.4966 48.998 64.1386C51.5184 65.7852 55.0237 66.1523 59.6951 64.8552C64.364 63.5587 70.265 60.5837 77.6632 55.4425L77.0479 54.557C69.6962 59.6658 63.9095 62.5658 59.4066 63.8162C54.906 65.0659 51.755 64.6518 49.5878 63.2359C47.4136 61.8154 46.104 59.3056 45.4702 56.0908C44.8371 52.8796 44.8941 49.0364 45.3903 45.0666L44.3204 44.9329Z", fill: "#9462FF", style: {
  fill: "#9462FF",
  fill: "color(display-p3 0.5807 0.3833 1.0000)",
  fillOpacity: 1
} }));
export default SvgAddThirdParty;
