import { Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { SweepTypographyVariants } from '@sweep-io/sweep-design/dist/components/Typography/types';
import { isNil } from 'lodash';

const HEIGHT = '20px';
const MAX_WIDTH = '240px';
interface PercentageProps {
  preCalculated?: number;
  numerator?: number;
  denominator?: number;
  active?: boolean;
  typographyVariant?: SweepTypographyVariants;
}

export const Percentage = ({
  preCalculated,
  numerator = 0,
  denominator = 1, //if both numerator and denominator will be 0 the outcome will be NaN
  active,
  typographyVariant = 'body',
}: PercentageProps) => {
  if (!active) {
    return <Box sx={{ background: colors.grey[200], height: HEIGHT, maxWidth: MAX_WIDTH }} />;
  }
  const percentage = (numerator / (denominator || 1)) * 100;
  const value = !isNil(preCalculated) ? preCalculated : Math.round(percentage);

  return (
    <Box
      sx={{
        width: '100%',
        mr: 1,
        maxWidth: MAX_WIDTH,
        height: HEIGHT,
        border: '1px solid',
        borderColor: 'transparent',
        backgroundColor: colors.grey[100],
        '.SweepDataTableRow:hover &': {
          borderColor: colors.grey[300],
          backgroundColor: colors.white,
        },
      }}
    >
      <Box
        sx={{
          width: `${value}%`,
          height: '100%',
          backgroundColor: value === 0 ? 'transparent' : colors.blue[100],
          paddingLeft: '4px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography variant={typographyVariant}>{value}%</Typography>
      </Box>
    </Box>
  );
};
