import { useSelector } from 'react-redux';
import { useContext, useEffect } from 'react';
import { Box } from '@mui/material';
import { TabList } from '@sweep-io/sweep-design';
import { selectSingleObjectActiveTab } from '../../../../reducers/documentationReducer';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { useDocumentation } from '../../useDocumentation';
import { SelectedObjectHeader } from '../SelectedObjectHeader';
import { useSelectedObjectTabsStructure } from '../useSelectedObjectTabStructure';
import { DocumentationDialogContext } from '../../context/DocumentationContextProvider';
import { DispatchSingleObjectNameAndFocusOnElementFuncProps } from '../../types';

interface ExpandedObjectProps {
  objectTypeName: ObjectTypeName;
  crmOrgId: string;
  onCloseDialog?: () => void;
  hideAddObjectButton?: boolean;
  dispatchSingleObjectNameAndFocusOnElement: DispatchSingleObjectNameAndFocusOnElementFuncProps;
}

export const ExpandedObject = ({
  objectTypeName,
  crmOrgId,
  onCloseDialog,
  hideAddObjectButton,
  dispatchSingleObjectNameAndFocusOnElement,
}: ExpandedObjectProps) => {
  const { onTabChange } = useDocumentation();
  const { objectNamesUsedInCanvas } = useContext(DocumentationDialogContext);
  const activeTab = useSelector(selectSingleObjectActiveTab);
  const { sfElements, sweepElements } = useSelectedObjectTabsStructure({
    crmOrgId,
    objectTypeName,
  });

  useEffect(() => {
    if (activeTab === DocumentationTabTypes.CARDS_LIST || !activeTab) {
      dispatchSingleObjectNameAndFocusOnElement({
        singleObjectName: objectTypeName,
        tab: DocumentationTabTypes.FIELDS,
      });
    }
  }, [activeTab, objectTypeName, dispatchSingleObjectNameAndFocusOnElement]);

  return (
    <>
      <SelectedObjectHeader
        withObjectCardTabActive
        objectTypeName={objectTypeName}
        withExpandedModeButton
        onCloseDialog={onCloseDialog}
        objectNamesUsedInCanvas={objectNamesUsedInCanvas}
        hideAddObjectButton={hideAddObjectButton}
        dispatchSingleObjectNameAndFocusOnElement={dispatchSingleObjectNameAndFocusOnElement}
      />

      <Box
        height="100%"
        overflow="hidden"
        sx={{
          '&  > .MuiBox-root, & .MuiTabPanel-root': {
            height: 'calc(100% - 32px)', //TODO add in design system, 32px is for tabs height
          },
          '& .MuiTabs-root': {
            pl: 2,
            pr: 2,
          },
          '&  > .MuiBox-root': {
            height: '100%',
            overflow: 'hidden',
          },
          //overwrite width to prevent recalculating columns width
          //40 & 90 - padding
          'div[data-virtuoso-scroller="true"] > div': {
            width: hideAddObjectButton
              ? 'calc(100vw - 40px) !important'
              : 'calc(100vw - 90px) !important',
          },
        }}
      >
        <TabList
          list={sfElements as Parameters<typeof TabList>['0']['list']}
          dropdown={{
            title: 'Sweep Elements',
            list: sweepElements as Parameters<typeof TabList>['0']['list'],
          }}
          value={activeTab}
          onChange={onTabChange}
        />
      </Box>
    </>
  );
};
