import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@sweep-io/sweep-design';

const NotificationDot = styled(Box)({
  height: '8px',
  width: '8px',
  backgroundColor: colors.blush[500],
  borderRadius: '50%',
});

export default NotificationDot;
