import { Box, DialogContent, DialogTitle } from '@mui/material';
import { colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import { FlexBox } from '../../common/FlexBox';

export const PageTemplateDialogContent = ({
  children,
  onCancel,
  title,
}: {
  onCancel?: () => any;
  children: React.ReactNode;
  title: string;
}) => {
  return (
    <>
      <DialogTitle sx={{ padding: 3 }}>
        <FlexBox alignItems="flex-start" justifyContent="space-between">
          <Box>
            <Typography variant="h1">{title}</Typography>
            <Box sx={{ mt: 1 }}>
              <Typography variant="body" color={colors.grey[800]}>
                Build it now and deploy whenever you're ready
              </Typography>
            </Box>
          </Box>
          {onCancel && (
            <IconButton onClick={onCancel} variant="flat">
              <Close color={colors.grey[800]} />
            </IconButton>
          )}
        </FlexBox>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: '24px' }}>{children}</DialogContent>
    </>
  );
};
