import useUserInfo from './useUserInfo';
import { useRunOnce } from '../components/common/useRunOnce';

const useLoadAccount = () => {
  const { getAccountUsersData } = useUserInfo();

  useRunOnce(() => {
    getAccountUsersData();
  });
};

export default useLoadAccount;
