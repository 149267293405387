import { Box, DialogActions, DialogContent, DialogTitle, FormControlLabel } from '@mui/material';
import { DateTime } from 'luxon';
import { BaseDialog } from '../../../common/dialogs/BaseDialog';
import { Button, Checkbox, IconButton, Typography } from '@sweep-io/sweep-design';
import {
  ActionableDataTable,
  ActionableDataTableActionTypes,
} from '../../../common/table/ActionableDataTable';
import { useState } from 'react';
import { HistoryTimeOffs } from './helpers';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { SortOrder } from '../../../common/types';
import { SortCompareTypes } from '../../../common/table/TableTypes';

interface TimeOffHistoryDialogProps {
  user: { name: string; timeOffs?: CrmOrgUserTimeOff[] };
  onClose: () => void;
  onAddTimeOff: () => void;
  onDeleteTimeOff: (timeOffId: string) => void;
  onEditTimeOff: (timeOffId: string) => void;
}

export const TimeOffHistoryDialog = ({
  user,
  onClose,
  onAddTimeOff,
  onDeleteTimeOff,
  onEditTimeOff,
}: TimeOffHistoryDialogProps) => {
  const [showPastEntries, setShowPastEntries] = useState(false);

  const timeOffsObject = new HistoryTimeOffs(user.timeOffs || []);
  const timeOffs = showPastEntries
    ? timeOffsObject.getAllTimeOffs()
    : timeOffsObject.getCurrentAndFutureTimeOffs();

  const rows = timeOffs.map((timeOff) => ({
    id: timeOff.id,
    description: timeOff.description,
    dates: `${timeOff._dateTimeStartDate.toLocaleString(
      DateTime.DATETIME_SHORT,
    )} - ${timeOff._dateTimeEndDate.toLocaleString(DateTime.DATETIME_SHORT)}`,
    data: timeOff,
    disabled: timeOff.isPast,
  }));

  return (
    <BaseDialog title={`Time off for  ${user.name}`} open={true}>
      <DialogTitle sx={{ paddingBottom: 3 }}>Time off for {user.name}</DialogTitle>
      <Box sx={{ position: 'absolute', right: '28px', top: '28px', zIndex: 2 }}>
        <IconButton onClick={onClose} variant="flat">
          <Close variant="large" />
        </IconButton>
      </Box>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Box width="599px">
          <ActionableDataTable
            columns={[
              {
                headerName: 'Description',
                field: 'description',
                //TODO uncomment when functionality is supported SWEEP-5507

                // showSortIcon: true,
                // sortCompare: {
                //   type: SortCompareTypes.String,
                // },
                width: '176px',
              },
              {
                headerName: 'Dates',
                field: 'dates',
                showSortIcon: true,
                sortCompare: {
                  type: SortCompareTypes.Custom,
                  compareFunction: (a, b, order) => {
                    const dateA = a.data._dateTimeStartDate;
                    const dateB = b.data._dateTimeStartDate;
                    const diff = order === SortOrder.ASC ? dateA.diff(dateB) : dateB.diff(dateA);
                    return diff.as('milliseconds');
                  },
                },
              },
            ]}
            actions={[
              {
                type: ActionableDataTableActionTypes.EDIT,
                confirm: false,
              },
              {
                type: ActionableDataTableActionTypes.DELETE,
                confirm: true,
                confirmButtonOkLabel: 'Yes, Delete',
                confirmButtonCancelLabel: 'Cancel',
                confirmTitle: 'Delete time off',
                confirmSubtitle: 'Are you sure you want to delete this time off?',
              },
            ]}
            rows={rows}
            //eslint-disable-next-line @typescript-eslint/no-unused-vars
            onActionClick={({ actionType, row }) => {
              switch (actionType) {
                case ActionableDataTableActionTypes.EDIT:
                  onEditTimeOff(row.id);
                  break;
                case ActionableDataTableActionTypes.DELETE:
                  onDeleteTimeOff(row.id);
                  break;
              }
            }}
          />

          <FormControlLabel
            label={<Typography variant="body">Show past entries</Typography>}
            control={
              <Checkbox
                checked={showPastEntries}
                onChange={() => setShowPastEntries(!showPastEntries)}
              />
            }
          />
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Button variant="flat" startIconName="Plus" onClick={onAddTimeOff}>
          Add time off
        </Button>
        <Button onClick={onClose}>Done</Button>
      </DialogActions>
    </BaseDialog>
  );
};
