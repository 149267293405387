import { exitCriterionModel } from './exitCriterionModel';

export const exitCriteriaModel = (exitCriteria: SweepExitCriteria[]) => {
  const byId = (exitCriteriaId: string) => {
    const idx = exitCriteria.findIndex(
      (exitCriterion) => exitCriterion._exitCriteriaId === exitCriteriaId,
    );
    if (idx !== undefined) {
      return exitCriterionModel(exitCriteria[idx]);
    }
  };

  return { byId };
};
