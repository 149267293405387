import { useCallback, useMemo } from 'react';
import useOnLocationChange from './useOnLocationChange';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../reducers/userInfoReducer';
import { useUnhappyStates } from '../components/pages/environments/useUnhappyStates';
import useSendBiEvent from './useSendBiEvent';
import { AppRoutesWithPageVisitEvent, RoutesThatRequireInitialFetch } from '../constants/appRoutes';
import { useLocation } from 'react-router';

const isNewPageVisit = (currentPath: string, previousPath?: string) => {
  const currRoute = currentPath.split('/')[1];
  const prevRoute = previousPath ? previousPath.split('/')[1] : undefined;
  return currRoute !== prevRoute;
};

const useSendPageVisitEvents = () => {
  const sendBiEvent = useSendBiEvent();
  const { pathname } = useLocation();

  const { connectionError, noConnectedOrg, isInitialFetch } = useUnhappyStates();

  //by product: send the "page visit" events only when meaningful content is rendered (happy state)
  const isHappyState = useMemo(
    () => {
      const isRequireInitialFetch = RoutesThatRequireInitialFetch.some((route) =>
        pathname.startsWith(route),
      );
      const isHappyInitialFetch = isRequireInitialFetch ? !isInitialFetch : true;
      return !connectionError && !noConnectedOrg && isHappyInitialFetch;
    },
    [noConnectedOrg, connectionError, isInitialFetch, pathname],
  );
  const user = useSelector(selectUserInfoData);
  const panelMode = user?.preferences?.canvasPanelModeExpanded ? 'full page' : 'canvas view';

  //Note: if adding a route with more than 1 segment, need to update the logic of "maybeSendEvent"
  const maybeSendEvent = useCallback(
    (currentPath: string, previousPath?: string) => {
      if (!isHappyState) return;
      const relevantAppRoute = AppRoutesWithPageVisitEvent.find((appRoute) =>
        currentPath.startsWith(appRoute.route),
      );
      const event = relevantAppRoute?.mixpanelPageVisitEvent;
      if (!event) return;
      if (isNewPageVisit(currentPath, previousPath)) {
        sendBiEvent({ name: event, props: { panelMode } });
      }
    },
    [isHappyState, sendBiEvent, panelMode],
  );

  useOnLocationChange(maybeSendEvent);
};

export default useSendPageVisitEvents;
