import { MenuItem } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

interface StyledMenuItemProps {
  text: string;
  onClick: any;
  icon: ReactNode;
  color?: string;
}

export const StyledMenuItem = ({
  onClick,
  icon,
  text,
  color = colors.black,
}: StyledMenuItemProps) => {
  return (
    <MenuItem sx={{ display: 'flex', alignItems: 'center', gap: 1 }} onClick={() => onClick()}>
      {icon}
      <Typography variant="body" color={color}>
        {text}
      </Typography>
    </MenuItem>
  );
};
