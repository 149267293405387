import { updateFunnelMapRecordTypesAndFunnels } from './updateFunnelMapRecordTypesAndFunnels';
import { funnelMapExtremePositions } from './utils/funnelMapExtremePositions';

const updateFunnelMapHubspotFunnels = (
  funnelMap: FunnelMap,
  initialPosition: FunnelMapPosition = { row: 0, column: 0 },
) => {
  let currentRow = initialPosition.row;
  const currentColumn = initialPosition.column;

  const changes: string[] = [];
  const { hubspotFunnels, hubspotFunnelsData } = funnelMap;

  const hubspotFunnelsToDelete = Object.keys(funnelMap.hubspotFunnels).filter((hubspotFunnelId) => {
    return !hubspotFunnelsData[hubspotFunnelId];
  });

  const hubspotFunnelsToAdd = Object.keys(hubspotFunnelsData).filter((hubspotFunnelId) => {
    return !hubspotFunnels[hubspotFunnelId];
  });

  hubspotFunnelsToDelete.forEach((hubspotFunnelId) => {
    delete hubspotFunnels[hubspotFunnelId];
  });

  hubspotFunnelsToAdd.forEach((hubspotFunnelId) => {
    hubspotFunnels[hubspotFunnelId] = {
      position: { row: currentRow, column: currentColumn },
    };
    currentRow += 2;
  });
  changes.push(
    ...hubspotFunnelsToDelete.map(
      (hubspotFunnelId) => `Hubspot funnel ${hubspotFunnelId} was removed.`,
    ),
    ...hubspotFunnelsToAdd.map((hubspotFunnelId) => `Hubspot funnel ${hubspotFunnelId} was added.`),
  );

  return {
    changes,
    funnelMap,
  };
};

export const updateRecordTypesFunnelsAndHubspotFunnels = (
  funnelMap: FunnelMap,
  includeHubspotFunnels = false,
) => {
  const { minRow, maxRow, minColumn } = funnelMapExtremePositions(funnelMap);

  let { changes } = updateFunnelMapRecordTypesAndFunnels(funnelMap, {
    row: minRow - 2,
    column: minColumn,
  });

  if (includeHubspotFunnels) {
    const { changes: hubspotFunnelChanges } = updateFunnelMapHubspotFunnels(funnelMap, {
      row: maxRow + 2,
      column: minColumn,
    });
    changes = [...changes, ...hubspotFunnelChanges];
  }

  return {
    changes,
    funnelMap,
  };
};
