import { FetchStatus } from '@server/fetch_status.type';

import useConnectToSf, { GetEventToSend } from './useConnectToSf';
import { Box } from '@mui/material';
import SalesforceCloudWithText from './svgs/salesforceCloudWithText.svg?react';
import SalesforceCloudEmpty from './svgs/salesforceCloudEmpty.svg?react';
import SalesforceCloudStroke from './svgs/salesforceCloudStroke.svg?react';
import ConnectStartInDialog from './ConnectStartInDialog';
import ConnectProgressInDialog from './ConnectProgressInDialog';
import { CenteredCircularProgress } from '../../../common/CenteredCircularProgress';
import { ConnectCrmOrgStateEnum } from '../../environments/ConnectCrmOrgStateEnum';
import { CrmOrgConnectingError } from '../../environments/connectCrmSessionHelper';
import { getCrmOrgConnectionErrorData } from './getCrmOrgConnectionErrorData';

const ConnectToSfInDialog = ({
  onFinish,
  forceProduction,
  getEventToSend,
}: {
  onFinish: () => void;
  forceProduction: boolean;
  getEventToSend?: GetEventToSend;
}) => {
  const { connectState, isSandbox, setIsSandboxCb, onConnectStartHandler } = useConnectToSf({
    getEventToSend,
    onFinish,
    forceProduction,
  });

  const isError =
    (connectState.type === 'connectState' &&
      connectState.connectState === ConnectCrmOrgStateEnum.Error) ||
    (connectState.type === 'fetchStatus' && connectState.fetchStatus === FetchStatus.Error);

  const isNotConnected =
    connectState.type === 'connectState' &&
    connectState.connectState === ConnectCrmOrgStateEnum.NotConnected;

  const renderConnect = isError || isNotConnected;

  const isInProgress =
    (connectState.type === 'connectState' &&
      connectState.connectState === ConnectCrmOrgStateEnum.Connecting) ||
    (connectState.type === 'fetchStatus' &&
      //In the following statuses, the entire component is not supposed to be rendered
      //Until removed from dom, rendering "ConnectProgress" for smooth experience
      [FetchStatus.Fetched, FetchStatus.InitialFetching, FetchStatus.Fetching].includes(
        connectState.fetchStatus,
      ));

  let illustration: React.ReactNode = isNotConnected ? (
    <SalesforceCloudWithText />
  ) : isInProgress ? (
    <SalesforceCloudWithLoader />
  ) : isError ? (
    <SalesforceCloudStroke />
  ) : null;

  let error: CrmOrgConnectingError | undefined = undefined;
  if (connectState.type === 'fetchStatus' && connectState.fetchStatus === FetchStatus.Error) {
    error = CrmOrgConnectingError.ERROR_CONNECTING;
  }
  if (
    connectState.type === 'connectState' &&
    connectState.connectState === ConnectCrmOrgStateEnum.Error
  ) {
    error = connectState.error;
  }

  let {
    title,
    description,
  }: {
    title: string;
    description: React.ReactNode;
  } = {
    title: 'Connect Sweep to Salesforce',
    description: <>Unlock the full potential of Sweep by connecting your Salesforce account.</>,
  };

  if (error) {
    const data = getCrmOrgConnectionErrorData({
      error,
      theme: 'dark',
    });
    title = data.title;
    description = <span dangerouslySetInnerHTML={{ __html: data.description.join('<br />') }} />;
    illustration = data.illustration;
  }

  return (
    <Box display="flex" height="100%" flex={1}>
      <Box
        sx={{
          flex: '0 0 296px',
          backgroundColor: '#00A4E6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {illustration}
      </Box>
      {renderConnect && (
        <ConnectStartInDialog
          onConnectStart={() =>
            onConnectStartHandler({
              isSandboxValue: forceProduction ? false : isSandbox,
            })
          }
          forceProduction={forceProduction}
          setIsSandbox={setIsSandboxCb}
          isSandbox={isSandbox}
          title={title}
          description={description}
        />
      )}
      {isInProgress && <ConnectProgressInDialog />}
    </Box>
  );
};

const SalesforceCloudWithLoader = () => {
  return (
    <Box position="relative">
      <SalesforceCloudEmpty />
      <CenteredCircularProgress
        circularProgressProps={{
          size: '32px',
          sx: {
            position: 'absolute',
            top: 'calc(50% - 16px)',
            right: 'calc(50% - 16px)',
            '.MuiCircularProgress-circle': { color: 'white' },
          },
        }}
      />
    </Box>
  );
};

export default ConnectToSfInDialog;
