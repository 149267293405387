import { getObjectApiNamesFromFunnelMap, getObjectApiNamesFromRecordTypes } from '../helpers';
import { CPQ_NAMESPACE_PREFIX } from './types';
import { sortLexicographically } from '../../../lib/sortLexicographically';
import { DocumentationPills } from '../../../reducers/global/globalReducerTypes';
import { ObjectWithPills } from '../types';

export const isCPQObject = (objectType: ObjectTypeName) =>
  objectType.objectType.startsWith(CPQ_NAMESPACE_PREFIX);

export const isCustomObject = (objectType: ObjectTypeName) =>
  isCustomObjectByName(objectType.objectType);
export const isCustomObjectByName = (objectName: string) => objectName.endsWith('__c');

export const getObjectsWithPillsPerCategory = ({
  recordTypesData,
  funnelsData,
  objectTypeNames,
  pills,
  transientObjects,
}: {
  recordTypesData: RecordTypesData;
  funnelsData: FunnelsData;
  objectTypeNames: ObjectTypeName[];
  pills?: DocumentationPills;
  transientObjects: { [objectName: string]: boolean };
}) => {
  const cpqNotInFunnelMap: ObjectTypeName[] = [];
  const favorites: ObjectTypeName[] = [];
  const standardObjectsNotInFunnelMap: ObjectTypeName[] = [];
  const customObjectsNotInFunnelMap: ObjectTypeName[] = [];

  const objectAPINamesInFunnel = getObjectApiNamesFromFunnelMap(funnelsData);
  const objectAPINamesInRecordTypes = getObjectApiNamesFromRecordTypes(recordTypesData);

  objectTypeNames.forEach((objectType) => {
    const _pills = pills?.[objectType.objectType];
    const objectTypeWithPills = { ...objectType, pills: _pills };

    const isInFunnels = objectAPINamesInFunnel.includes(objectTypeWithPills.objectType);
    const isInRecordTypes = objectAPINamesInRecordTypes.includes(objectTypeWithPills.objectType);
    const isTransientObject = transientObjects?.[objectTypeWithPills.objectType];

    if (isInFunnels || isInRecordTypes || isTransientObject) {
      favorites.push(objectTypeWithPills);
      return;
    }

    if (!isTransientObject) {
      if (isCPQObject(objectTypeWithPills)) {
        cpqNotInFunnelMap.push(objectTypeWithPills);
      } else {
        if (isCustomObject(objectTypeWithPills)) {
          customObjectsNotInFunnelMap.push(objectTypeWithPills);
        } else {
          standardObjectsNotInFunnelMap.push(objectTypeWithPills);
        }
      }
    }
  });

  return {
    cpq: (sortLexicographically({
      items: cpqNotInFunnelMap,
      sortKey: 'label',
    }) ?? []) as ObjectWithPills[],
    favorites: (sortLexicographically({
      items: favorites,
      sortKey: 'label',
    }) ?? []) as ObjectWithPills[],
    customObjectsNotInFM: (sortLexicographically({
      items: customObjectsNotInFunnelMap,
      sortKey: 'label',
    }) ?? []) as ObjectWithPills[],
    standardObjectsNotInFunnelMap: (sortLexicographically({
      items: standardObjectsNotInFunnelMap,
      sortKey: 'label',
    }) ?? []) as ObjectWithPills[],
  };
};
