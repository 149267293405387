export const initializePluginStages = (
  plugin: DynamicPathPlugin,
  stageId: string,
  _selectedExitCriteriaId?: string,
): DynamicPathStage[] => {
  const isStageInPlugin = plugin.stages.find((stage) => stage.stageId === stageId);

  if (isStageInPlugin) {
    return plugin.stages;
  }

  return [
    ...plugin.stages,
    {
      stageId,
      description: '',
      exitCriteria: initializeExitCriteria(plugin.stages, stageId, _selectedExitCriteriaId),
      additionalFields: [],
    },
  ];
};

export const initializeExitCriteria = (
  stages: DynamicPathStage[],
  stageId: string,
  _selectedExitCriteriaId?: string,
): DynamicPathExitCriteria[] => {
  const stage = stages.find((stage) => stage.stageId === stageId);
  const isExitCriteriaInPlugin = stage?.exitCriteria.find(
    (ec) => ec.exitCriteriaId === _selectedExitCriteriaId,
  );

  if (isExitCriteriaInPlugin || !_selectedExitCriteriaId) {
    return stage?.exitCriteria ?? [];
  }

  return [
    ...(stage?.exitCriteria ?? []),
    { exitCriteriaId: _selectedExitCriteriaId, guidance: '' },
  ];
};

export const mapIdToStageName = (stages: SweepStage[]) => {
  const idToStageName = {} as { [stageId: string]: string };
  stages.forEach((stage) => {
    idToStageName[stage._stageId] = stage.stageName;
  });
  return idToStageName;
};

export const generatePath = ({
  stages,
  order,
  currentStagesInPath,
  stageIdToReplaceWith,
}: {
  stages: SweepStage[];
  order: string[];
  currentStagesInPath?: SweepStage[];
  stageIdToReplaceWith?: string;
}): SweepStage[] => {
  const firstStage = stages.find((stage) => stage._stageId === order[0]);
  if (!firstStage) {
    return [];
  }

  const stagesInPath: SweepStage[] = [...(currentStagesInPath ?? [])];
  const lastStageInCurrentPath = currentStagesInPath?.[currentStagesInPath?.length - 1];

  let replaceWithId = stageIdToReplaceWith;

  const buildPath = (stage: SweepStage) => {
    let _stage = { ...stage };

    if (replaceWithId) {
      // assuming that last stage in currentStagesInPath is the one that should be replaced
      const newStage = stages.find((stage) => stage._stageId === replaceWithId);
      _stage = { ...(newStage as SweepStage) };
      replaceWithId = undefined;
    }

    //order exit criteria based on given order
    const orderedExitCriteria = orderByMostRelevantCriteria(_stage.exitCriteria, order);
    _stage.exitCriteria = orderedExitCriteria;

    //add stage to path
    stagesInPath.push(_stage);

    //if nextStage exists keep on building
    const nextStageId = _stage.exitCriteria[0]?._nextStageId;

    if (nextStageId) {
      const nextStage = stages.find((stage) => stage._stageId === nextStageId);

      if (nextStage) {
        buildPath(nextStage);
      }
    }
  };

  //if building path partially (after select option change) start in current path
  buildPath(lastStageInCurrentPath ?? firstStage);

  return stagesInPath;
};

const orderByMostRelevantCriteria = (exitCriteria: SweepExitCriteria[], order: string[]) => {
  const indexMap = {} as { [key: string]: number };
  order.forEach((id, index) => {
    indexMap[id] = index;
  });

  const _exitCriteria = [...exitCriteria];
  return _exitCriteria.sort((a, b) => indexMap[a._nextStageId] - indexMap[b._nextStageId]);
};

export const slicePath = (
  _path: SweepStage[],
  _stageIdPrevious: string,
  _stageIdToReplace: string,
) => {
  for (let i = _path.length; i > 0; i--) {
    const _stage = _path[i];
    const prevStage = _stageIdPrevious ? _stageIdPrevious === _path[i - 1]?._stageId : true;

    if (_stage?._stageId === _stageIdToReplace && prevStage) {
      _path.splice(i, _path.length - i);
      break;
    }
  }

  return _path;
};

export const getExitCriteria = (currentStage: SweepStage, nextStageId: string) => {
  const exitCriteria = currentStage?.exitCriteria.find((ec) => ec._nextStageId === nextStageId);
  return exitCriteria?._exitCriteriaId;
};

export const updateStage = ({
  stages,
  stageId,
  key,
  value,
}: {
  stages: DynamicPathStage[];
  stageId: string;
  key: keyof DynamicPathStage;
  value: any;
}) => {
  return stages.map((stage) =>
    stage.stageId === stageId
      ? {
          ...stage,
          [key]: value,
        }
      : stage,
  );
};

export const getStage = (
  plugin: DynamicPathPlugin,
  selectedStageId: string,
  selectedExitCriteriaId?: string,
) => {
  return (
    plugin.stages.find((stageData) => stageData.stageId === selectedStageId) || {
      stageId: selectedStageId,
      description: '',
      exitCriteria: initializeExitCriteria(plugin.stages, selectedStageId, selectedExitCriteriaId),
      additionalFields: [],
    }
  );
};
