import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectFunnelSettingFunnels } from './funnelSettingsReducer';
import { MainFunnelSettings } from './MainFunnelSettings';
import { SingleFunnelSettings } from './SingleFunnelSettings';

export const FunnelSettingsContent = ({
  handleChange,
  errors,
  crmOrgId,
}: {
  errors: any;
  handleChange?: any;
  crmOrgId: string;
}) => {
  const funnels = useSelector(selectFunnelSettingFunnels);

  return (
    <Box
      sx={{
        padding: '32px',
      }}
    >
      <MainFunnelSettings handleChange={handleChange} errors={errors} />

      {funnels?.map((funnel) => (
        <SingleFunnelSettings funnel={funnel} key={funnel.id} crmOrgId={crmOrgId} />
      ))}
    </Box>
  );
};
