export enum HubspotEventFieldIds {
  FORM = 'hs_form_id',
  PAGE = 'hs_content_id',
  EMAILS = 'hs_email_campaign_group_id',
  AD_INTERACTION_TYPE = 'hs_interaction_type',
  AD_NETWORK_PLACEMENT = 'hs_source',
  AD_NETWORK = 'hs_ad_network',
}

export enum HubspotFilterAddSearchFieldIds {
  FACEBOOK_AD_NAME = 'FACEBOOK.AD',
  FACEBOOK_AD_GROUP_NAME = 'FACEBOOK.ADGROUP',
  FACEBOOK_CAMPAIGN_NAME = 'FACEBOOK.CAMPAIGN',
  GOOGLE_AD_NAME = 'ADWORDS.AD',
  GOOGLE_AD_GROUP_NAME = 'ADWORDS.ADGROUP',
  GOOGLE_CAMPAIGN_NAME = 'ADWORDS.CAMPAIGN',
  LINKEDIN_AD_NAME = 'LINKEDIN.AD',
  LINKEDIN_AD_GROUP_NAME = 'LINKEDIN.ADGROUP',
  LINKEDIN_CAMPAIGN_NAME = 'LINKEDIN.CAMPAIGN',
}

export type HubspotFieldIds = HubspotEventFieldIds | HubspotFilterAddSearchFieldIds;
