import { useCallback, useRef, useState } from 'react';

const usePopover = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorRef = useRef<any>();
  const anchorElement = anchorRef?.current;
  const openPopover = (event?: any) => {
    if (event && 'stopPropagation' in event) {
      event.stopPropagation();
    }
    setIsPopoverOpen(true);
  };
  const closePopover = useCallback((event?: any) => {
    if (event && 'stopPropagation' in event) {
      event.stopPropagation();
    }
    setIsPopoverOpen(false);
  }, []);

  return { isPopoverOpen, closePopover, openPopover, anchorElement, anchorRef };
};

export default usePopover;
