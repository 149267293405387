import { Box, PopoverOrigin } from '@mui/material';
import { ForwardedRef, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import {
  DeprecatedAdvancedFilterPopover,
  AdvancedFilterPopoverTexts,
  defaultTexts as defaultPopoverTexts,
} from './DeprecatedAdvancedFilterPopover';

import { FilterButton } from '../FilterButton';
import { Badge, IconButton } from '@sweep-io/sweep-design';
import { FilterLines } from '@sweep-io/sweep-design/dist/icons';
import { useRunOnceWhenTruthy } from '../useRunOnceWhenTruthy';

interface AdvancedFilterTexts extends AdvancedFilterPopoverTexts {
  allSelected: string;
  noSelection: string;
}

const defaultTexts: AdvancedFilterTexts = {
  ...defaultPopoverTexts,
  allSelected: 'All',
  noSelection: 'No selection',
};

export type AdvancedFilterItemValue<DataT = any> = {
  value: string;
  label: string;
  data?: DataT;
};
export interface AdvancedFilterItem<DataT = any> extends AdvancedFilterItemValue<DataT> {
  labelDecoration?: React.ReactNode;
}

export interface AdvancedFilterProps<DataT = any> {
  items: AdvancedFilterItem<DataT>[];
  selectedItems: string[];
  onSelectedItemsChange: (
    items: string[],
    itemsData: AdvancedFilterItemValue<DataT>[],
    isAllSelected: boolean,
  ) => void;
  texts?: Partial<AdvancedFilterTexts>;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
  disableSelectAllOnEntry?: boolean;
  disabled?: boolean;
  onlyIcon?: boolean;
  hideLabels?: boolean;
  emptyAsSelectAll?: boolean; // clears selection instead of selecting all
}

export type AdvancedFilterRef = {
  clear: () => void;
};

export function itemToItemValue<DataT = any>(
  item: AdvancedFilterItem<DataT>,
): AdvancedFilterItemValue<DataT> {
  return {
    value: item.value,
    label: item.label,
    data: item.data,
  };
}

export const DeprecatedAdvancedFilter = forwardRef<AdvancedFilterRef, AdvancedFilterProps>(
  function <DataT = any>(
    {
      items,
      selectedItems,
      onSelectedItemsChange,
      texts,
      anchorOrigin,
      transformOrigin,
      disableSelectAllOnEntry,
      disabled,
      onlyIcon,
      hideLabels,
      emptyAsSelectAll,
    }: AdvancedFilterProps<DataT>,
    ref: ForwardedRef<AdvancedFilterRef>,
  ) {
    const _texts: AdvancedFilterTexts = {
      ...defaultTexts,
      ...texts,
    };
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    const isPressed = Boolean(anchorEl);

    let filterButtonText = emptyAsSelectAll ? _texts.allSelected : _texts.noSelection;
    let filterButtonTooltip = '';

    const hasSelectedItems = selectedItems.length > 0;
    const allSelected = selectedItems.length === items.length;
    if (hasSelectedItems) {
      if (allSelected) {
        filterButtonText = _texts.allSelected;
      } else {
        if (selectedItems.length > 0) {
          const firstSelectedItem = items.find((item) => item.value === selectedItems[0]);
          filterButtonText = firstSelectedItem?.label || '';

          if (selectedItems.length > 1) {
            filterButtonText += ` and ${selectedItems.length - 1} more`;

            const chosenItems = items
              .filter((item) => selectedItems.includes(item.value))
              ?.map((item) => item.label);
            filterButtonTooltip = chosenItems?.splice(1).join(', ');
          }
        } else {
          filterButtonText = emptyAsSelectAll ? _texts.allSelected : _texts.noSelection;
        }
      }
    }

    const showClearButton = hasSelectedItems && !allSelected;

    const selectAll = useCallback(() => {
      const itemsSet = new Set(items.map((item) => item.value));
      const itemsArray = Array.from(itemsSet);
      onSelectedItemsChange(
        itemsArray,
        items.filter((item) => itemsSet.has(item.value)).map(itemToItemValue),
        items.length === itemsArray.length,
      );
    }, [items, onSelectedItemsChange]);

    const clearOrSelectAll = useCallback(() => {
      emptyAsSelectAll ? onSelectedItemsChange([], [], true) : selectAll();
    }, [emptyAsSelectAll, onSelectedItemsChange, selectAll]);

    useImperativeHandle(
      ref,
      () => {
        return {
          clear: () => {
            clearOrSelectAll();
          },
        };
      },
      [clearOrSelectAll],
    );

    useRunOnceWhenTruthy(() => {
      clearOrSelectAll();
    }, !disableSelectAllOnEntry);

    return (
      <Box sx={{ display: 'flex', minWidth: 0 }}>
        {onlyIcon && (
          <IconButton onClick={handleClick} variant="outlined">
            <>
              <FilterLines variant="large" />
              {showClearButton && (
                <Box sx={{ position: 'absolute', top: '-5px', right: '-12px' }}>
                  <Badge variant="destructive" />
                </Box>
              )}
            </>
          </IconButton>
        )}
        {!onlyIcon && (
          <FilterButton
            isPressed={isPressed}
            handleClick={handleClick}
            text={filterButtonText}
            showClearBtn={showClearButton}
            onClearBtn={clearOrSelectAll}
            title={texts?.title ?? ''}
            tooltip={filterButtonTooltip}
            disabled={disabled}
          />
        )}

        <DeprecatedAdvancedFilterPopover
          items={items}
          selectedItems={selectedItems}
          anchorEl={anchorEl}
          onSelectedItemsChange={onSelectedItemsChange}
          onClose={() => setAnchorEl(null)}
          texts={texts}
          transformOrigin={transformOrigin}
          anchorOrigin={anchorOrigin}
          hideLabels={hideLabels}
          emptyAsSelectAll={emptyAsSelectAll}
        />
      </Box>
    );
  },
);
