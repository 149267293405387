import { createContext, useContext } from 'react';
import { useObjectFlags } from './useObjectFlags';

interface AutoSaveCtxProps {
  dirtyObjectsExists: boolean;
  setFunnelEntityIsDirty: (object: string, isDirty: boolean) => void;
  setErrorsFor: (object: string, error: string) => void;
  hasAnyErrors: boolean;
  getErrorHashFor: (object: string) => string;
  removeError: (object: string) => void;
  setIsSavingFor: (object: string, isSaving: boolean) => void;
  getIsSavingFor: (object: string) => boolean;
  isSaving: boolean;

  __initialized: boolean;
}

const AutoSaveContext = createContext<AutoSaveCtxProps>({
  dirtyObjectsExists: false,
  setFunnelEntityIsDirty: () => {},
  setErrorsFor: () => {},
  hasAnyErrors: false,
  getErrorHashFor: () => '',
  removeError: () => {},
  getIsSavingFor: () => false,
  setIsSavingFor: () => {},
  isSaving: false,

  __initialized: false,
});

export const AutoSaveProvider = ({ children }: { children: React.ReactNode }) => {
  const { setObjectFlagFor: setFunnelEntityIsDirty, hasAny: dirtyObjectsExists } = useObjectFlags();
  const {
    setObjectFlagFor: setErrorsFor,
    hasAny: hasAnyErrors,
    getObjectFlagFor: getErrorHashFor,
    removeObject: removeError,
  } = useObjectFlags<string>();

  const {
    setObjectFlagFor: setIsSavingFor,
    getObjectFlagFor: getIsSavingFor,
    hasAny: isSaving,
  } = useObjectFlags();

  return (
    <AutoSaveContext.Provider
      value={{
        dirtyObjectsExists,
        setFunnelEntityIsDirty,
        setErrorsFor,
        hasAnyErrors,
        getErrorHashFor,
        removeError,
        setIsSavingFor,
        getIsSavingFor,
        isSaving,

        __initialized: true,
      }}
    >
      {children}
    </AutoSaveContext.Provider>
  );
};

export const useAutoSaveContext = () => {
  const autoSaveCtx = useContext(AutoSaveContext);
  if (!autoSaveCtx.__initialized) {
    throw new Error('useAutoSaveContext must be used within a AutoSaveProvider');
  }
  return autoSaveCtx;
};
