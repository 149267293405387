import { ReactNode, useCallback, useMemo } from 'react';
import { UI_EVENTS } from '../../services/events';
import { AutomationType } from '../../types/enums/AutomationType';
import { Permission } from '@server/permissions';
import React from 'react';
import { DeployButtonContextProvider } from '../common/deploy-button/DeployButtonContext';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { PartialAutomationStructure } from './AutomationsForm';
import { uniqueId } from '../../lib/uniqueId';
import { AutomationActionType } from '../../types/enums/AutomationActionType';
import { AutomationTypes } from '../../types/enums/AutomationTypes';

export interface AutomationsContextType {
  automationType: AutomationType;
  emptyStateTitle: string;
  emptyStateSubTitle?: string;
  emptyStateImage: ReactNode;
  defaultName: string;
  placeholder: string;
  nameInMessage: string;
  nameInMessageSingular: string;
  openEvent: UI_EVENTS;
  automationVariant: AutomationFormVariant;
  templateSupport: boolean;
  permissions: {
    create: Permission;
    edit: Permission;
    deploy: Permission;
    delete: Permission;
    notifyAdmin: Permission;
  };
  initialAutomationJson?: DeepPartial<AutomationStructureNew>;
  requestDeployNotifyAdmin: (
    automationId: string,
    versionId: string,
    note: string,
  ) => Promise<void>;
}

const AutomationsPageContext = React.createContext<
  AutomationsContextType & {
    initialized: boolean;
    automationJson: DeepPartial<AutomationStructureNew>;
    setAutomationJson: (automationJson: DeepPartial<AutomationStructureNew>) => void;
  }
>({
  initialized: false,
  automationType: AutomationType.Default,
  permissions: {
    create: 'create:automations',
    edit: 'edit:automations',
    deploy: 'deploy:automations',
    delete: 'delete:automations',
    notifyAdmin: 'edit:automations:notify-admin',
  },
  emptyStateTitle: '',
  emptyStateImage: '',
  defaultName: '',
  placeholder: '',
  nameInMessage: '',
  nameInMessageSingular: '',
  openEvent: UI_EVENTS.alertsOpen,
  automationVariant: AutomationFormVariant.AUTOMATIONS,
  templateSupport: true,
  automationJson: {},
  setAutomationJson: () => {},
  requestDeployNotifyAdmin: async () => {},
});

export const useAutomationsContext = () => {
  const context = React.useContext(AutomationsPageContext);
  if (!context.initialized) {
    throw new Error('useAutomationsContext must be used within a AutomationsPageContext');
  }
  return context;
};

export const AutomationsContextProvider = ({
  children,
  value,
}: {
  children: ReactNode;
  value: AutomationsContextType;
}) => {
  const [automationJson, setAutomationJson] = React.useState<DeepPartial<AutomationStructureNew>>(
    value.initialAutomationJson ?? {},
  );

  const deployButtonContextValue = useMemo(
    () => ({
      permissions: value.permissions,
      entityNamePlural: value.nameInMessage,
      entityName: value.nameInMessageSingular,
    }),
    [value],
  );

  const setAutomationJsonWithDefaults = useCallback(
    (givenAutomation: PartialAutomationStructure) => {
      let newAutomation: PartialAutomationStructure = {};
      if (value?.automationVariant !== AutomationFormVariant.DEDUP_MATCHING) {
        newAutomation = {
          isActive: true,
          type: value.automationType,
          automationDetails: {
            actions: [],
            automationParams: {},
          },
          name: value.defaultName,
        };
      }

      if (
        (value.automationVariant === AutomationFormVariant.ASSIGNMENT_RULES ||
          value.automationVariant === AutomationFormVariant.SCHEDULED_ASSIGNMENTS) &&
        newAutomation.automationDetails
      ) {
        newAutomation.automationDetails.actions = [
          {
            _id: uniqueId(),
            actionType: AutomationActionType.AssignmentRules,
            actionParams: {
              assignmentRules: [],
            },
          },
        ];
      }
      if (
        value.automationVariant === AutomationFormVariant.SCHEDULED_ASSIGNMENTS &&
        newAutomation.automationDetails
      ) {
        newAutomation.automationDetails = {
          ...newAutomation.automationDetails,
          triggerType: AutomationTypes.Scheduled,
        };
      }
      if (
        value.automationVariant === AutomationFormVariant.SCHEDULED_REPORTS &&
        newAutomation.automationDetails
      ) {
        newAutomation.automationDetails = {
          ...newAutomation.automationDetails,
          triggerType: AutomationTypes.Scheduled,
          actions: [
            {
              _id: uniqueId(),
              actionType: AutomationActionType.SendReportToSlack,
              actionParams: {},
            },
          ],
        };
      }
      if (Object.keys(automationJson)?.length === 0) {
        setAutomationJson({
          ...newAutomation,
          ...(givenAutomation || {}),
        });
      } else {
        setAutomationJson(givenAutomation);
      }
    },
    [automationJson, value.automationType, value.automationVariant, value.defaultName],
  );
  const formContextValue = useMemo(
    () => ({
      ...value,
      automationJson,
      setAutomationJson: setAutomationJsonWithDefaults,
      initialized: true,
    }),
    [automationJson, setAutomationJsonWithDefaults, value],
  );

  return (
    <AutomationsPageContext.Provider value={formContextValue}>
      <DeployButtonContextProvider value={deployButtonContextValue}>
        {children}
      </DeployButtonContextProvider>
    </AutomationsPageContext.Provider>
  );
};
