import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';
import { useFunnelMapPage } from '../../../funnel-map-flow/useFunnelMapPage';
import { usePageHelpers } from '../usePageHelpers';
import { DashboardHeaderContainer } from '../DashboardHeaderContainer';
import { ReadOnlyCanvasUnitedRightMenu } from './ReadOnlyCanvasUnitedRightMenu';
import { ReadOnlyCanvasUnitedLeftMenu } from './ReadOnlyCanvasUnitedLeftMenu';

export const ReadOnlyUnitedDashboardHeader = () => {
  const { getFunnelFlowMode } = usePageHelpers();
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { isFunnelMapEmpty } = useFunnelMapPage();
  const funnelFlowMode = getFunnelFlowMode();
  return (
    <DashboardHeaderContainer>
      <ReadOnlyCanvasUnitedLeftMenu />
      <ReadOnlyCanvasUnitedRightMenu
        funnelMap={canvasFunnelMap}
        funnelFlowMode={funnelFlowMode}
        funnelMapEmpty={isFunnelMapEmpty}
        lastUpdatedAt={canvasFunnelMap.updatedAt}
      />
    </DashboardHeaderContainer>
  );
};
