import { usePanels } from '../../../panels/PanelsContext';
import { commentsPanelType } from '../../../comments/panelTypes';

export const useTurnOffCommentsModeFirst = () => {
  const { isPanelOpen, maybeCloseActivePanel } = usePanels();
  const isCommentModeOn = isPanelOpen(commentsPanelType);

  const turnOffCommentsModeFirst = (cb: any) => {
    if (isCommentModeOn) {
      maybeCloseActivePanel();
    }
    cb && cb();
  };

  return turnOffCommentsModeFirst;
};
