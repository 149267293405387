import * as React from "react";
const SvgNeed = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FFF0D9", style: {
  fill: "#FFF0D9",
  fill: "color(display-p3 1.0000 0.9411 0.8500)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.11817 5.15013C6.73157 5.15013 6.41511 5.46352 6.41133 5.8501L6.29113 18.1493C6.28735 18.5359 6.59769 18.8493 6.98429 18.8493H9.47594C9.86254 18.8493 10.179 18.5359 10.1828 18.1493L10.2363 12.6772L13.2923 18.4802C13.412 18.7075 13.6476 18.8493 13.9055 18.8493H16.8817C17.2683 18.8493 17.5847 18.5359 17.5885 18.1493L17.7087 5.8501C17.7125 5.46352 17.4022 5.15013 17.0156 5.15013H14.5239C14.1373 5.15013 13.8208 5.46352 13.8171 5.8501L13.7637 11.3099L10.7073 5.5187C10.5875 5.29172 10.352 5.15013 10.0943 5.15013H7.11817Z", fill: "#FF9900", stroke: "white", style: {
  fill: "#FF9900",
  fill: "color(display-p3 1.0000 0.6000 0.0000)",
  fillOpacity: 1,
  stroke: "white",
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.4, strokeLinejoin: "round" }));
export default SvgNeed;
