import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StageType } from '../../../../types/enums/StageType';

import {
  dropTransientNewFunnelInPlace,
  removeAllTransientFunnelData,
  setSfNewFunnelTransientDataStage,
  removeNewFunnelTransientData,
  updateStageMetadataForLeadingField,
  selectPlacingTemplate,
  setIsLoadingCursor,
  selectTransientCreateSfFunnel,
  selectTransientImportSfFunnel,
  selectTransientCreateThirdPartyFunnel,
  selectTransientImportThirdPartyFunnel,
  selectTransientFunnel,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import {
  CanvasElementType,
  NodeChangeAddNodeType,
  NodeChangeEscapeFromShadowStepType,
  NodeChangeMoveGroupType,
  NodeChangeMoveNodeType,
  NodeChangeRemoveGroupType,
  SweepNodesChangeEvent,
  SweepNodesChangeType,
} from '../../../multi-canvas/canvasTypes';
import {
  addSfFunnel,
  addMultipleSfFunnels,
  addSfStage,
  addThirdPartyFunnelToCanvas,
  moveSfStages,
  removeFunnel,
  moveSfFunnels,
  addThirdPartyFunnelStageToCanvas,
  moveThirdPartyFunnelStagesOnCanvas,
  moveThirdPartyFunnelsOnCanvas,
} from '../../../../reducers/united-canvas/unitedCanvasReducer';
import { aStage } from '../../../../models/stageModel';
import { DEFAULT_LAYERS_ID, enableLayerFor } from '../../../../reducers/canvasLayersReducer';
import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';
import useEventListener from '../../../common/useEventListener';
import cloneDeep from 'lodash/cloneDeep';
import noop from 'lodash/noop';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { MoveCanvasGroups } from '../../../multi-canvas/useMoveGroups';
import { useTemplatesFacade } from '../../../../apis/facades/useTemplatesFacade';
import { selectFieldsPerTemplateMapping } from '../../funnel-map-flow/templates/templates-tab/templateFieldsMappingReducer';
import { TemplateFieldMapping } from '../../funnel-map-flow/templates/templatesTypes';
import { useAddFunnel } from '../../funnel-map-flow/add-funnel/useAddFunnel';
import { telemetry } from '../../../../telemetry';
import { ACTIONS_EVENTS } from '../../../../services/events';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { useThirdPartyFunnelFacade } from '../../../../apis/facades/useThirdPartyFunnelFacade';
import { TRANSIENT_GROUP_ID } from '../../../funnel-map-canvas/useFunnelMapElements';
import { getFunnelTypeFromNodeId } from '../../../funnel-map-canvas/helper';
import { FunnelType } from '../../../../types/enums/FunnelType';
import { groupBy } from 'lodash';
import { addThirdPartyFunnel } from '../../../third-party-funnels/thirdPartyFunnelsReducer';
import { useFunnelMapCanvasCtx } from '../../../funnel-map-canvas/FunnelMapCanvasCtx';

const getFunnelIdBySweepStage = (funnelsData: FunnelsData): { [stageId: string]: string } =>
  Object.keys(funnelsData)
    .map((funnelId) =>
      funnelsData[funnelId].funnelDetails.stages.map((stage) => ({
        funnelId,
        stageId: stage._stageId,
      })),
    )
    .flat()
    .reduce(
      (
        prev: {
          [stageId: string]: string;
        },
        curr,
      ) => {
        prev[curr.stageId] = curr.funnelId;
        return prev;
      },
      {},
    );

export const useUnitedConnectedFunnelMapCanvas = ({
  funnelMap,
  crmOrgId,
  readonly,
  layersId = DEFAULT_LAYERS_ID,
}: {
  funnelMap: FunnelMap;
  crmOrgId: string;
  readonly?: boolean;

  layersId?: string;
}) => {
  const transientFunnel = useSelector(selectTransientFunnel);

  const dispatch = useDispatch();
  const sendBiEvent = useSendBiEvent();
  const { addNotification } = useSweepNotifications();
  const { onCreateFunnel } = useAddFunnel({ crmOrgId, addFunnelRef: null });
  const { post_third_party_funnel } = useThirdPartyFunnelFacade();
  const { setEditingThirdPartyStepId } = useFunnelMapCanvasCtx();

  const importedSfFunnel = useSelector(selectTransientImportSfFunnel)?.data.funnel;
  const importThirdPartyFunnel = useSelector(selectTransientImportThirdPartyFunnel)?.data
    .thirdPartyFunnel;

  const placingTemplate = useSelector(selectPlacingTemplate);

  const { funnelsData } = funnelMap;
  const _funnelMapWithTransientFunnel = cloneDeep(funnelMap);
  const _funnelsDataWithTransientFunnel = cloneDeep(funnelsData);

  if (importedSfFunnel) {
    _funnelMapWithTransientFunnel.funnels[importedSfFunnel.id] = {
      position: {
        column: 0,
        row: 0,
      },
    };
    _funnelsDataWithTransientFunnel[importedSfFunnel.id] = importedSfFunnel;
  }
  if (placingTemplate) {
    placingTemplate.template.funnelTemplates.forEach((template) => {
      _funnelMapWithTransientFunnel.funnels[template.id] = {
        position: template.position,
      };
      _funnelsDataWithTransientFunnel[template.id] = {
        id: template.id,
        name: template.name,
        recordType: {
          description: '',
          label: '',
          name: template.objectName,
          objectName: template.objectName,
        },
        funnelDetails: template.templateFunnelDetails,
        description: '',
        accountId: '',
        createdAt: '',
        createdById: '',
        updatedAt: '',
        snapshotsIds: [],
        stageMetadata: [],
        updatedById: '',
      };
    });
  }
  const funnelIdBySweepStage = getFunnelIdBySweepStage(_funnelsDataWithTransientFunnel);

  const funnelDetailsStages = Object.keys(funnelsData)
    .map((funnelId) => funnelsData[funnelId].funnelDetails.stages)
    .flat();

  const onSweepElementsChange = useCallback(
    (event: SweepNodesChangeEvent) => {
      const getGroupTypeFromNodeId = (parentId: string) => {
        const groupType =
          parentId === TRANSIENT_GROUP_ID
            ? transientFunnel?.type
            : getFunnelTypeFromNodeId(funnelMap, parentId);
        return groupType;
      };

      const onAddThirdPartyNode = async (change: NodeChangeAddNodeType) => {
        const { newNode, edgeTransformations, newEdge, nodesToMove } = change;

        if (!newNode.parentId) {
          throw new Error('Error. There is no parentID on the node');
        }

        const newStep: ThirdPartyStep = {
          id: newNode.id,
          name: newNode.name,
          stepConnections: [],
          position: newNode.position,
          funnelLinks: [],
        };
        dispatch(
          addThirdPartyFunnelStageToCanvas({
            newStep,
            funnelId: newNode.parentId,
            stepConnectionChanges: Object.values(edgeTransformations).map((edge) => ({
              id: edge.id,
              originId: edge.source,
              targetId: edge.target,
            })),
            stepPositionChanges: nodesToMove.map((nodeMove) => ({
              stepId: nodeMove.nodeId,
              newPosition: nodeMove.newPosition,
            })),
            newStepConnection: newEdge
              ? { id: newEdge.id, originId: newEdge.source, targetId: newEdge.target }
              : undefined,
          }),
        );
      };
      const onAddNode = async (change: NodeChangeAddNodeType) => {
        const { newNode } = change;
        if (!newNode.parentId) {
          throw new Error('Error. There is no parentID on the node');
        }

        const groupType = getGroupTypeFromNodeId(newNode.parentId);

        switch (groupType) {
          case FunnelType.SALESFORCE:
            onAddSfNode(change);
            break;
          case FunnelType.THIRD_PARTY:
            onAddThirdPartyNode(change);
            break;
          default:
            break;
        }
      };

      const onAddSfNode = async (change: NodeChangeAddNodeType<FunnelLeadingObject>) => {
        const { newNode, newGroup } = change;
        let funnelId = newNode.parentId;

        if (!funnelId) {
          throw new Error('Error. There is no parentID on the node');
        }

        const isNurturingBucket = newNode.type === CanvasElementType.NURTURING_BUCKET;
        const stageType: StageType = isNurturingBucket ? StageType.NURTURING : StageType.REGULAR;

        const newStage = aStage({
          _stageId: newNode.id,
          _branchIndex: newNode.position.row,
          _stageColumnIndex: newNode.position.column,
          stageName: newNode.name.trim(),
          stageDescription: newNode.name.trim(),
          stageType,
        });

        const stagesPositionChanges = change.nodesToMove.map((nodeMove) => ({
          stageId: nodeMove.nodeId,
          newPosition: nodeMove.newPosition,
        }));

        const stagesNextStageChanges = Object.keys(change.edgeTransformations).map((edgeId) => ({
          stageId: change.edgeTransformations[edgeId].source,
          exitCriteriaId: change.edgeTransformations[edgeId].id,
          newNextStageId: change.edgeTransformations[edgeId].target,
        }));
        const newExitCriteria = change.newEdge
          ? {
              stageId: change.newEdge?.source,
              nextStageId: change.newEdge?.target,
            }
          : undefined;
        const newFunnel = newGroup?.metadata
          ? {
              id: newGroup.id,
              leadingObject: newGroup.metadata,
              name: newGroup.name,
              position: newGroup.position,
            }
          : undefined;
        if (
          funnelId === TRANSIENT_GROUP_ID &&
          transientFunnel?.type === FunnelType.SALESFORCE &&
          transientFunnel?.importType === 'create' &&
          crmOrgId
        ) {
          dispatch(
            setSfNewFunnelTransientDataStage({
              stage: newStage,
            }),
          );
          try {
            const funnel = await onCreateFunnel({
              funnelDetails: {
                stages: [newStage],
                leadingObject: transientFunnel.data.leadingObject,
                plugins: {},
              },
              funnelName: transientFunnel.name,
              position: change.parentCanvasNodePosition || { column: 0, row: 0 },
            });

            funnelId = funnel.id;
          } catch (err) {
            telemetry.captureError(err);
            addNotification({
              message: 'Error creating funnel view',
              variant: SweepNotificationVariant.Error,
            });
            dispatch(removeNewFunnelTransientData());
          }
        } else {
          dispatch(
            addSfStage({
              funnelId,
              stagesPositionChanges,
              newStage,
              stagesNextStageChanges,
              newExitCriteria,
              newFunnel,
            }),
          );
        }

        if (isNurturingBucket) {
          dispatch(enableLayerFor({ layersId, layer: VisibilityLayers.NURTURING_STEPS }));
        }
      };

      const onMoveSfNodes = (change: NodeChangeMoveNodeType) => {
        let funnelId: string | undefined = undefined;
        const stagesPositionChanges = change.nodesToMove.map((nodeMove) => {
          if (nodeMove.parentId) {
            funnelId = nodeMove.parentId;
          }
          funnelId = nodeMove.parentId;
          return {
            stageId: nodeMove.nodeId,
            newPosition: nodeMove.newPosition,
          };
        });
        if (!funnelId) {
          throw new Error('Error. There is no parentID on the node');
        }
        dispatch(moveSfStages({ funnelId, stagesPositionChanges }));
      };

      const onMoveThirdPartyNodes = (change: NodeChangeMoveNodeType) => {
        const stepPositionChanges = change.nodesToMove
          .filter(({ parentId }) => parentId)
          .map((nodeMove) => ({
            funnelId: nodeMove.parentId as string,
            stepId: nodeMove.nodeId,
            newPosition: nodeMove.newPosition,
          }));
        dispatch(moveThirdPartyFunnelStagesOnCanvas({ stepPositionChanges }));
      };

      const onMoveNode = (change: NodeChangeMoveNodeType) => {
        if (!change.nodesToMove) {
          return;
        }
        const changesByGroupType = groupBy(change.nodesToMove, (node) =>
          getGroupTypeFromNodeId(node.parentId ?? node.nodeId),
        );

        Object.entries(changesByGroupType).forEach(([groupType, changes]) => {
          switch (groupType) {
            case FunnelType.SALESFORCE:
              onMoveSfNodes({ nodesToMove: changes });
              break;
            case FunnelType.THIRD_PARTY:
              onMoveThirdPartyNodes({ nodesToMove: changes });
              break;
            default:
              break;
          }
        });
      };

      const onMoveSfFunnels = (change: NodeChangeMoveGroupType) => {
        const groupChanges = change.groupsToMove.map((groupChange) => ({
          funnelId: groupChange.nodeId,
          newPosition: groupChange.newPosition,
        }));

        dispatch(moveSfFunnels(groupChanges));
      };

      const onMoveThirdPartyFunnel = (change: NodeChangeMoveGroupType) => {
        const groupChanges = change.groupsToMove.map((groupChange) => ({
          funnelId: groupChange.nodeId,
          newPosition: groupChange.newPosition,
        }));
        dispatch(moveThirdPartyFunnelsOnCanvas(groupChanges));
      };

      const onMoveGroup = (change: NodeChangeMoveGroupType) => {
        const groupsByType = groupBy(change.groupsToMove, (groupMove) =>
          getGroupTypeFromNodeId(groupMove.nodeId),
        );
        Object.entries(groupsByType).forEach(([groupType, groupsToMove]) => {
          switch (groupType) {
            case FunnelType.SALESFORCE:
              onMoveSfFunnels({ groupsToMove });
              break;
            case FunnelType.THIRD_PARTY:
              onMoveThirdPartyFunnel({ groupsToMove });
              break;
            default:
              break;
          }
        });
      };

      const onEscapeFromShadowStep = (change: NodeChangeEscapeFromShadowStepType) => {
        const type = getGroupTypeFromNodeId(change.parentId);
        if (type === FunnelType.SALESFORCE) {
          const isGroupEmpty = !Boolean(
            funnelDetailsStages.find(
              (stage) => funnelIdBySweepStage[stage._stageId] === change.parentId,
            ),
          );
          if (isGroupEmpty) {
            dispatch(removeFunnel({ funnelId: change.parentId }));
          }
        }
        if (type === FunnelType.THIRD_PARTY) {
          setEditingThirdPartyStepId(undefined);
        }
      };

      const onRemoveGroup = (change: NodeChangeRemoveGroupType) => {
        dispatch(removeFunnel({ funnelId: change.groupId }));
      };

      switch (event.type) {
        case SweepNodesChangeType.AddNode:
          onAddNode(event.change);
          break;
        case SweepNodesChangeType.MoveNode:
          onMoveNode(event.change);
          break;
        case SweepNodesChangeType.MoveGroup:
          onMoveGroup(event.change);
          break;
        case SweepNodesChangeType.EscapeFromShadowStep:
          onEscapeFromShadowStep(event.change);
          break;
        case SweepNodesChangeType.RemoveGroup:
          onRemoveGroup(event.change);
          break;
      }
    },
    [
      transientFunnel,
      funnelMap,
      dispatch,
      crmOrgId,
      onCreateFunnel,
      addNotification,
      layersId,
      funnelDetailsStages,
      funnelIdBySweepStage,
      setEditingThirdPartyStepId,
    ],
  );

  const createTransientSfFunnel = useSelector(selectTransientCreateSfFunnel);
  const createTransientThirdPartyFunnel = useSelector(selectTransientCreateThirdPartyFunnel);

  const { post_templateConvert } = useTemplatesFacade();

  const fieldsPerTemplateMapping = useSelector(
    selectFieldsPerTemplateMapping(crmOrgId, placingTemplate?.template.id),
  );

  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();
      dispatch(removeAllTransientFunnelData());
    }
  });

  const _moveGroups: MoveCanvasGroups | undefined = useMemo(() => {
    if (readonly) {
      return undefined;
    }

    // Handles the case when a template is being imported
    if (placingTemplate && crmOrgId) {
      const groupIds = placingTemplate.template.funnelTemplates.map((template) => template.id);

      const fieldMappings: TemplateFieldMapping[] = fieldsPerTemplateMapping?.enabled
        ? Object.entries(fieldsPerTemplateMapping.sweepFieldsByApiName).map(
            ([apiName, { fieldIds }]) => ({
              fromTemplateFieldId: apiName,
              toSweepFieldId: fieldIds[fieldIds.length - 1],
            }),
          )
        : [];

      return {
        groupIds: groupIds,
        onDrop: async (groupNodePositions) => {
          dispatch(setIsLoadingCursor(true));
          dispatch(removeAllTransientFunnelData());
          try {
            const { funnelsData, funnels } = await post_templateConvert({
              crmOrgId,
              templateId: placingTemplate.template.id,
              fieldMapping: placingTemplate.useFieldMapping ? fieldMappings : [],
            });

            const funnelsWithPositions = Object.entries(funnels).map(([funnelId, { position }]) => {
              return {
                funnel: funnelsData[funnelId],
                position: {
                  column: groupNodePositions.column + position.column,
                  row: groupNodePositions.row + position.row,
                },
              };
            });
            sendBiEvent({
              name: ACTIONS_EVENTS.funnelsAddFunnel,
              props: {
                object: Object.values(funnelsData)
                  .map((f) => f.funnelDetails.leadingObject.objectName)
                  .join(','),
              },
            });
            dispatch(addMultipleSfFunnels(funnelsWithPositions));
            dispatch(setIsLoadingCursor(false));
          } catch (err: any) {
            let message = 'Error importing template';

            if (err?.response?.data?.sweepError === 8) {
              message = err?.response?.data?.message;
            } else {
              telemetry.captureError(err);
            }

            addNotification({
              message,
              variant: SweepNotificationVariant.Error,
            });
          } finally {
            dispatch(setIsLoadingCursor(false));
          }
        },
      };
    }

    // Handles the case when a funnel is being imported
    const importingFunnelId = importedSfFunnel?.id;
    if (importingFunnelId) {
      return {
        groupIds: [importingFunnelId],
        onDrop: (groupNodePositions) => {
          dispatch(removeAllTransientFunnelData());
          sendBiEvent({
            name: ACTIONS_EVENTS.funnelsAddFunnel,
            props: { object: importedSfFunnel.funnelDetails.leadingObject.objectName },
          });
          dispatch(
            addSfFunnel({
              funnel: importedSfFunnel,
              position: groupNodePositions,
            }),
          );
          dispatch(
            updateStageMetadataForLeadingField({
              leadingFieldStageMetadata: importedSfFunnel?.stageMetadata,
            }),
          );
        },
      };
    }

    if (importThirdPartyFunnel) {
      return {
        groupIds: [importThirdPartyFunnel.id],
        onDrop: (groupNodePositions) => {
          dispatch(removeAllTransientFunnelData());
          dispatch(
            addThirdPartyFunnelToCanvas({
              thirdPartyFunnel: importThirdPartyFunnel,
              position: groupNodePositions,
            }),
          );
        },
      };
    }

    // Handles the case when a new funnel is being created
    if (createTransientSfFunnel && createTransientSfFunnel.isPlacingFunnel) {
      return {
        groupIds: [TRANSIENT_GROUP_ID],
        onDrop: (groupNodePositions) => {
          dispatch(dropTransientNewFunnelInPlace({ position: groupNodePositions }));
        },
      };
    }

    if (createTransientThirdPartyFunnel && createTransientThirdPartyFunnel.isPlacingFunnel) {
      return {
        groupIds: [TRANSIENT_GROUP_ID],
        onDrop: async (groupNodePositions) => {
          dispatch(dropTransientNewFunnelInPlace({ position: groupNodePositions }));
          const newThirdPartyFunnel = await post_third_party_funnel({
            name: createTransientThirdPartyFunnel.name,
            system: createTransientThirdPartyFunnel.data.system,
            description: createTransientThirdPartyFunnel.data.description,
            steps: [],
            funnelLinks: [],
          });
          dispatch(removeAllTransientFunnelData());
          dispatch(
            addThirdPartyFunnelToCanvas({
              thirdPartyFunnel: newThirdPartyFunnel,
              position: groupNodePositions,
            }),
          );
          dispatch(addThirdPartyFunnel(newThirdPartyFunnel));
        },
      };
    }
  }, [
    readonly,
    placingTemplate,
    crmOrgId,
    importedSfFunnel,
    importThirdPartyFunnel,
    createTransientSfFunnel,
    createTransientThirdPartyFunnel,
    fieldsPerTemplateMapping?.enabled,
    fieldsPerTemplateMapping?.sweepFieldsByApiName,
    dispatch,
    post_templateConvert,
    sendBiEvent,
    addNotification,
    post_third_party_funnel,
  ]);

  return {
    onSweepElementsChange: readonly ? noop : onSweepElementsChange,
    moveGroups: _moveGroups,
  };
};
