import { Box } from '@mui/material';
import { DataManagementPluginCard } from '../plugins/data-management/tab/DataManagementPluginCard';
import { BantPluginCard } from '../plugins/bant/tab/BantCard';
import { TemplatePageType } from '../dialog/TemplatesDialog';
import { useOnAddDataManagementPlugin } from '../plugins/data-management/useOnAddDataManagementPlugin';
import { useOnAddBantPlugin } from '../plugins/bant/useOnAddBantPlugin';
import { DynamicPathPluginCard } from '../plugins/dynamic-path/tab/DynamicPathPluginCard';
import { useOnAddDynamicPathPlugin } from '../plugins/dynamic-path/useOnAddDynamicPathPlugin';
import { MeddicPluginCard } from '../plugins/meddic/tab/MeddicCard';
import { useOnAddMeddicPlugin } from '../plugins/meddic/useOnAddMeddicPlugin';
import { MeddpiccPluginCard } from '../plugins/meddpicc/tab/MeddpiccCard';
import { useOnAddMeddpiccPlugin } from '../plugins/meddpicc/useOnAddMeddpiccPlugin';

interface TemplatePluginsTabProps {
  onPluginSelect: (pluginType: TemplatePageType) => void;
}

export const TemplatesPluginsTab = ({ onPluginSelect }: TemplatePluginsTabProps) => {
  const onAddDataManagementPlugin = useOnAddDataManagementPlugin();
  const onAddBantPlugin = useOnAddBantPlugin();
  const onAddMeddicPlugin = useOnAddMeddicPlugin();
  const onAddMeddpiccPlugin = useOnAddMeddpiccPlugin();
  const onAddDynamicPathPlugin = useOnAddDynamicPathPlugin();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        marginTop: 3,
      }}
    >
      <DataManagementPluginCard
        onClick={() => {
          onPluginSelect(TemplatePageType.DATA_MANAGEMENT_PLUGIN);
        }}
        onAdd={onAddDataManagementPlugin}
      />
      <BantPluginCard
        onClick={() => {
          onPluginSelect(TemplatePageType.BANT_PLUGIN);
        }}
        onAdd={onAddBantPlugin}
      />
      <MeddicPluginCard
        onClick={() => {
          onPluginSelect(TemplatePageType.MEDDIC_PLUGIN);
        }}
        onAdd={onAddMeddicPlugin}
      />
      <MeddpiccPluginCard
        onClick={() => {
          onPluginSelect(TemplatePageType.MEDDPICC_PLUGIN);
        }}
        onAdd={onAddMeddpiccPlugin}
      />
      <DynamicPathPluginCard
        onClick={() => {
          onPluginSelect(TemplatePageType.DYNAMIC_PATH);
        }}
        onAdd={onAddDynamicPathPlugin}
      />
    </Box>
  );
};
