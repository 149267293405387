import { useCallback } from 'react';
import {
  ShortTemplateInterface,
  TemplateFieldMapping,
  TemplateInterface,
} from '../../components/pages/funnel-map-flow/templates/templatesTypes';
import { useSweepApi } from '../sweep';
import { assignFieldLabelsFromFunnels } from '../../sweep-fields/sweepFieldsLabelsReducer';
import { useDispatch } from 'react-redux';
import { FunnelsDataWithFields } from '../../sweep-fields/types';

interface TemplateFunnelsConvertPayload {
  crmOrgId: string;
  templateId: string;
  fieldMapping: TemplateFieldMapping[];
}

export const useTemplatesFacade = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();

  return {
    get_templates: useCallback(async () => {
      const response = await sweepApi.get(`/templates`);
      return response.data as ShortTemplateInterface[];
    }, [sweepApi]),
    get_template: useCallback(
      async (templateId: string) => {
        const response = await sweepApi.get(`/templates/${templateId}`);
        return response.data as TemplateInterface;
      },
      [sweepApi],
    ),
    post_templateConvert: useCallback(
      async ({ crmOrgId, templateId, fieldMapping }: TemplateFunnelsConvertPayload) => {
        const response = await sweepApi.post(
          `/templates/${templateId}/convert?crmOrgId=${crmOrgId}`,
          { fieldMapping },
        );
        const responseData = response.data as Pick<FunnelMap, 'funnels'> & {
          funnelsData: FunnelsDataWithFields;
        };
        dispatch(
          assignFieldLabelsFromFunnels({ funnels: Object.values(responseData.funnelsData) }),
        );

        return responseData;
      },
      [dispatch, sweepApi],
    ),
  };
};
