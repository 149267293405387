import { Grid } from '@mui/material';
import { useState } from 'react';
import EditImage from 'react-avatar-edit';
import { ConfirmDialog } from '../common/dialogs/ConfirmDialog';

type UploadImageProps = {
  originSrc: string;
  onConfirmCb: (imageBase64Blob: string) => void;
  onCancelCb: () => void;
};

const UploadImage = ({ originSrc, onConfirmCb, onCancelCb }: UploadImageProps) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [src, setSrc] = useState<string | null>(originSrc);

  const onClose = () => {
    setPreview(null);
  };

  const onCrop = (preview: string) => {
    setPreview(preview);
  };

  const handleCancel = () => {
    onCancelCb && onCancelCb();
    setSrc(null);
  };

  const handleConfirm = async () => {
    if (onConfirmCb && preview) {
      await onConfirmCb(preview);
    }
    setSrc(null);
  };

  return (
    <ConfirmDialog
      confirmText="Upload"
      onConfirm={handleConfirm}
      handleClose={handleCancel}
      isOpen={!!src}
    >
      <Grid
        container
        height="100%"
        gap="30px"
        justifyContent="center"
        alignItems="center"
        data-testid="upload-image-dialog-content"
      >
        {src && (
          <EditImage
            width={300}
            imageWidth={300}
            height={250}
            onCrop={onCrop}
            onClose={onClose}
            closeIconColor="transparent"
            src={src}
          />
        )}
      </Grid>
    </ConfirmDialog>
  );
};

export { UploadImage };
