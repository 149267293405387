import PaywallBlockUpgradeDialog from '../../../dashboard/paywall/PaywallBlockUpgradeDialog';
import UpgradeImage from '../../../dashboard/header/generalUpgradeImage.svg?react';
import PaywallBlockStartTrialDialog from '../../../dashboard/paywall/PaywallBlockStartTrialDialog';
import { PaywallSource } from '../../../dashboard/paywall/paywallTypes';
import usePaywall, { PaywallAction } from '../../../dashboard/paywall/usePaywall';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import PaywallFunnelDeployImage from './paywallFunnelDeploy.svg?react'; //temporary until we get a dedicated one
import { PaywallBlockActionDialogV2 } from '../../../dashboard/paywall/PaywallBlockActionDialog';

const TITLE = 'Get Access to CSV Exports with a Free Trial';
const FEATURE_DESCRIPTION = 'exporting csv';

const TITLE_V2 = 'Get Access to CSV Exports with a Free Trial';
const FEATURE_DESCRIPTION_V2 =
  'Export Salesforce configurations to better understand your setup and share insights with your team. Schedule a meeting with our team to activate your trial.';

const DownloadCsvPaywallBlock = ({ closeDialog }: { closeDialog: () => void }) => {
  const { paywallAction, sendEncounterPaywallEvent } = usePaywall();

  useRunOnceWhenTruthy(() => {
    sendEncounterPaywallEvent({
      source: PaywallSource.DOWNLOAD_CSV,
    });
  }, paywallAction !== PaywallAction.NONE);

  //TODO change to "!isPaywallActive" SWEEP-5282
  if (paywallAction === PaywallAction.NONE) {
    return null;
  }

  //TODO change to "isPaywallActive" SWEEP-5282
  if (paywallAction === PaywallAction.TALK_TO_SALES) {
    return (
      <PaywallBlockActionDialogV2
        title={TITLE_V2}
        infoText={FEATURE_DESCRIPTION_V2}
        Image={<PaywallFunnelDeployImage />}
        closeDialog={closeDialog}
      />
    );
  }

  if (paywallAction === PaywallAction.START_TRIAL) {
    return (
      <PaywallBlockStartTrialDialog
        closeDialog={closeDialog}
        title={TITLE}
        featureDescription={FEATURE_DESCRIPTION}
        source={PaywallSource.DOWNLOAD_CSV}
      />
    );
  }

  if (paywallAction === PaywallAction.UPGRADE) {
    return (
      <PaywallBlockUpgradeDialog
        closeDialog={closeDialog}
        img={<UpgradeImage />}
        title={TITLE}
        featureDescription={FEATURE_DESCRIPTION}
      />
    );
  }

  return null;
};

export default DownloadCsvPaywallBlock;
