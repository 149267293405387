import { Box, Stack } from '@mui/material';
import AppExchange from './appExchange.png';
import AppExchangeButton from './AppExchangeButton';
import { ActionsRequireMp } from './getInstallManagedPackageText';
import InstallManagedPackageContent from './InstallManagedPackageContent';
import { TalkToProductExpert } from './TalkToProductExpert';

const InstallManagedPackage = ({
  action,
  closeDialog,
  children,
  removeTalkToProduct,
}: {
  action: ActionsRequireMp;
  closeDialog: () => void;
  children?: React.ReactNode;
  removeTalkToProduct?: boolean;
}) => (
  <Stack gap="44px">
    <Box display="flex" gap={3}>
      {!children && <InstallManagedPackageContent action={action} />}
      {children}
      <Box>
        <img src={AppExchange} />
      </Box>
    </Box>
    <Box display="flex" justifyContent="space-between">
      {!removeTalkToProduct && <TalkToProductExpert closeDialog={closeDialog} />}

      <Box display="flex" justifyContent="flex-end">
        <AppExchangeButton />
      </Box>
    </Box>
  </Stack>
);

export default InstallManagedPackage;
