export const hsValueToSweepConditionValue = (hsValue: string | number | boolean | string[]) => {
  let sweepValue: string | undefined;
  if (typeof hsValue === 'string') {
    sweepValue = hsValue;
  } else if (typeof hsValue === 'number') {
    sweepValue = hsValue.toString();
  } else if (typeof hsValue === 'boolean') {
    sweepValue = hsValue.toString();
  } else if (Array.isArray(hsValue)) {
    sweepValue = JSON.stringify(hsValue);
  }
  return sweepValue || '';
};
