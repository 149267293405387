import { useSelector } from 'react-redux';
import {
  selectDocumentationActiveObjectData,
  selectSingleObject,
  selectSingleObjectActiveTab,
  selectSingleObjectSearchTxt,
} from '../../../../reducers/documentationReducer';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { useCanvasFilters } from '../../../pages/configuration-canvas-filters/useCanvasFilters';
import {
  filterFields,
  filterItemsByQuerySearch,
  filterRules,
  FiltersMap,
  rulePropsToFilter,
} from './filtersOptions';
import { CanvasFilterTypes } from '../../../pages/configuration-canvas-filters/filterTypes';
import { useDocumentation } from '../../useDocumentation';
import { ActiveState } from '../../activity-state/helpers';
import { ConfigurationItem } from '../../dependencies/DependenciesTypes';

export const useFilterTabs = () => {
  const allElements = useSelector(selectDocumentationActiveObjectData);
  const activeTab = useSelector(selectSingleObjectActiveTab);
  const objectTypeName = useSelector(selectSingleObject);

  const elements = activeTab ? allElements?.[activeTab] : [];

  const { filterValues } = useCanvasFilters();
  const { translateStepId } = useDocumentation();

  const selectedRecordTypesData = filterValues?.[FiltersMap.recordTypes];
  const selectedStepsData = filterValues?.[FiltersMap.steps];
  const selectedAutomationTypesData = filterValues?.[FiltersMap.automationTypes];
  const selectedActiveOnly = filterValues?.[FiltersMap.activeState]
    ? filterValues[FiltersMap.activeState].selectedValues[0] === ActiveState.activeOnly
    : true;

  const selectedFieldTypes = filterValues?.[FiltersMap.fieldTypes]?.selectedValues;
  const selectedUtilization = filterValues?.[FiltersMap.utilization]?.selectedValues;

  const searchTxt = useSelector(selectSingleObjectSearchTxt);
  const _searchTxt = activeTab ? (searchTxt ?? '') : '';

  const isActiveTabFields = activeTab === DocumentationTabTypes.FIELDS;
  const isActiveTabRecordTypes = activeTab === DocumentationTabTypes.RECORD_TYPES;

  const recordTypes =
    elements?.filter(
      (rt) =>
        (selectedActiveOnly ? !!rt.isActive : true) &&
        (rt.objectApiNames?.includes(objectTypeName?.objectType ?? '') ||
          rt.objectName === objectTypeName?.objectType),
    ) ?? [];

  const filteredElements = filterItemsByQuerySearch({
    itemProps: rulePropsToFilter,
    searchTxt: _searchTxt,
    items: elements ?? [],
  }) as ConfigurationItem[];

  const typeFilterMock = {
    type: CanvasFilterTypes.MULTI_GENERIC,
    label: '',
    selectedValues: [],
    selectedValuesData: [],
    isAllSelected: true,
  };

  const fields = isActiveTabFields
    ? filterFields({
        fields: filteredElements ?? [],
        selectedUsageValues: selectedUtilization,
        selectedTypeValues: selectedFieldTypes,
      })
    : filteredElements;

  const otherTypeElements = filterRules({
    rules: filteredElements,
    selectedStepValuesData: selectedStepsData,
    selectedTypeValuesData: selectedAutomationTypesData,
    selectedRecordTypeValuesData: selectedRecordTypesData ?? typeFilterMock,
    translateStepId,
  });

  return isActiveTabFields
    ? (fields ?? [])
    : isActiveTabRecordTypes
      ? recordTypes
      : otherTypeElements;
};
