import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import {
  ChatShort,
  selectCopilotCurrentOrg,
  selectIsExpandedCopilot,
  selectIsOpenedCopilot,
  setChats,
  setLoadingChats,
  toggleExpandMode,
  toggleOpened,
} from './copilotChatsReducer';
import { sortByDate } from '../../utils/sortByDate';
import { SortOrder } from '../common/types';
import { useAiChatApiFacade } from '../../apis/facades/useAiChatApiFacade';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';

const useCopilotChats = () => {
  const { get_chats } = useAiChatApiFacade();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const isExpanded = useSelector(selectIsExpandedCopilot);
  const isOpened = useSelector(selectIsOpenedCopilot);
  const { data, isLoading } = useSelector(selectCopilotCurrentOrg) ?? {};
  const { chatsDetails, activeChatId, chatsList } = data ?? {};
  const activeChat = activeChatId ? chatsDetails?.[activeChatId] : undefined;
  const dispatch = useDispatch();

  const fetchChats = useCallback(async () => {
    if (!crmOrgId) return;
    dispatch(setLoadingChats({ crmOrgId }));
    const chats: ChatShort[] = await get_chats();
    dispatch(setChats({ crmOrgId, chats }));
  }, [crmOrgId, dispatch, get_chats]);

  const orderedChats = chatsList?.toSorted((a, b) =>
    sortByDate(a.lastUpdatedAt, b.lastUpdatedAt, SortOrder.DESC),
  ) ?? [];

  const toggleIsExpanded = useCallback(() => {
    dispatch(toggleExpandMode());
  }, [dispatch]);

  const toggleIsOpened = useCallback(() => {
    dispatch(toggleOpened());
  }, [dispatch]);

  return {
    isExpanded,
    toggleIsExpanded,
    toggleIsOpened,
    isOpened,
    chats: orderedChats,
    activeChat,
    isLoadingChats: !!isLoading,
    fetchChats,
  };
};

export default useCopilotChats;
