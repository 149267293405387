import { Box, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Checkbox, IconButton, Tooltip, Typography } from '@sweep-io/sweep-design';
import { Layers } from '@sweep-io/sweep-design/dist/icons';
import React from 'react';
import { useDispatch } from 'react-redux';
import { SF_MENU_PART, SWEEP_MENU_PART } from '../../../../../constants/visibility';
import { VisibilityMap } from '../../../../../types/VisibilityTypes';
import { VisibilityLayers } from '../../../../../types/enums/VisibilityLayers';
import { toggleLayerFor } from '../../../../../reducers/canvasLayersReducer';

interface LayerSelectorProps {
  visibilityMap: VisibilityMap;
  visibilityLayersSelectors: Partial<VisibilityMap>; // witch options to display in the LayersSelector
  position?: 'top-left' | 'bottom-right';
  layersId: string;
  buttonVariant?: 'outlined' | 'flat';
}

const MenuItemContainer = styled(MenuItem)`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;

const LayerSelectorContainer = ({
  visibilityMap,
  visibilityLayersSelectors,
  position = 'top-left',
  layersId,
  buttonVariant = 'flat',
}: LayerSelectorProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeLayers = [...SWEEP_MENU_PART, ...SF_MENU_PART]
    .filter((item) => Boolean(visibilityLayersSelectors[item.layer]))
    .map((item) => item.layer)
    .filter((layer) => visibilityMap[layer]).length;

  return (
    <Box>
      <LayersSummary
        data-testid="layers-button"
        toggleOpen={handleClick}
        activeCount={activeLayers}
        buttonVariant={buttonVariant}
      />
      <LayersPopover
        isOpen={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        visibilityMap={visibilityMap}
        visibilityLayersSelectors={visibilityLayersSelectors}
        position={position}
        layersId={layersId}
      />
    </Box>
  );
};

const LayersSummary = ({
  toggleOpen,
  activeCount,
  buttonVariant,
}: {
  toggleOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  activeCount: number;
  buttonVariant?: 'outlined' | 'flat';
}) => {
  return (
    <Tooltip title="Layers" data-testid="layers-button">
      <IconButton onClick={toggleOpen} badgeLabel={activeCount} variant={buttonVariant}>
        <Layers variant="large" />
      </IconButton>
    </Tooltip>
  );
};

const menuPositionMap: any = {
  'top-left': {
    transformOrigin: { horizontal: 'center', vertical: -12 },
  },
  'bottom-right': {
    anchorOrigin: {
      vertical: -30,
      horizontal: -10,
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
  },
};

const LayersPopover = ({
  isOpen,
  onClose,
  anchorEl,
  visibilityMap,
  visibilityLayersSelectors,
  position,
  layersId,
}: {
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
  visibilityLayersSelectors: Partial<VisibilityMap>;
  visibilityMap: VisibilityMap;
  environmentName?: string;
  position: 'top-left' | 'bottom-right';
  layersId: string;
}) => {
  const dispatch = useDispatch();

  const renderLayerMenuItem = ({
    icon,
    text,
    layer,
  }: {
    icon: React.FunctionComponent;
    text: string;
    layer: VisibilityLayers;
  }) => {
    return (
      <MenuItemContainer
        data-testid="layers-menu-button"
        key={layer}
        onClick={() => dispatch(toggleLayerFor({ layersId, layer }))}
      >
        <VisibilityLayer icon={icon} isActive={Boolean(visibilityMap[layer])}>
          {text}
        </VisibilityLayer>
      </MenuItemContainer>
    );
  };

  const sweepMenuItems = SWEEP_MENU_PART.filter((item) =>
    Boolean(visibilityLayersSelectors[item.layer]),
  );

  const sfMenuItems = SF_MENU_PART.filter((item) => Boolean(visibilityLayersSelectors[item.layer]));

  return (
    <Menu
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      sx={{ '& .MuiMenu-paper': { width: '366px' } }}
      {...menuPositionMap[position]}
      data-testid="layers-menu"
    >
      <Box p={1.5}>
        <Typography variant="caption-bold">Salesforce Configuration</Typography>
      </Box>
      {sfMenuItems.map(renderLayerMenuItem)}
      <Box p={1.5}>
        <Typography variant="caption-bold">Sweep Configuration</Typography>
      </Box>
      {sweepMenuItems.map(renderLayerMenuItem)}
    </Menu>
  );
};

const VisibilityLayer = ({
  children,
  isActive,
  icon: Icon,
}: {
  children: string;
  isActive: boolean;
  icon?: any;
}) => {
  return (
    <>
      {Icon && <Icon />}
      <Box sx={{ flex: 1 }}>{children}</Box>
      <Box>
        <Checkbox data-testid="layers-menu-checkbox" checked={isActive} />
      </Box>
    </>
  );
};

export default LayerSelectorContainer;
