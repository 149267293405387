import { Box } from '@mui/material';
import { BaseDialog } from '../../common/dialogs/BaseDialog';
import useWizard from '../../common/wizard/useWizard';
import TrialCarouselStep from './TrialCarouselStep';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons';
import { WIZARD_STEPS, WizardSteps } from './paywallConsts';
import CarouselProgressDots from '../../common/CarouselProgressDots';

const STEP_NAMES = Object.keys(WizardSteps);

const TrialCarousel = ({
  closeDialog,
  disableOpenAnimation,
}: {
  closeDialog: () => void;
  disableOpenAnimation: boolean;
}) => {
  const {
    goNext,
    goBack,
    activeStep: activeStepIndex,
    steps,
    isLastStep,
    isFirstStep,
  } = useWizard(STEP_NAMES);
  const activeStepName = steps[activeStepIndex].name as WizardSteps;
  const activeStep = WIZARD_STEPS[activeStepName];
  const { title, imgSrc, content1, content2 } = activeStep;

  return (
    <BaseDialog
      open={true}
      onClose={closeDialog}
      PaperProps={{ sx: { width: '476px', height: '459px', overflow: 'hidden' } }}
      transitionDuration={disableOpenAnimation ? 0 : undefined}
    >
      <Box
        sx={{ position: 'absolute', right: '16px', top: '16px', zIndex: 2 }}
        data-testid="close-trial-carousel-button"
      >
        <IconButton variant="flat" size="large" onClick={closeDialog}>
          <Close />
        </IconButton>
      </Box>
      <TrialCarouselStep title={title} content1={content1} content2={content2} imgSrc={imgSrc}>
        <Box display="flex" gap={2} justifyContent="space-between" alignItems="center">
          <Button
            onClick={goBack}
            variant="outlined"
            disabled={isFirstStep}
            startIconName="ChevronLeft"
          >
            Back
          </Button>
          <CarouselProgressDots count={steps.length} activeIndex={activeStepIndex} />
          <Button
            onClick={isLastStep ? closeDialog : goNext}
            endIconName={isLastStep ? undefined : 'ChevronRight'}
          >
            {isLastStep ? 'Done' : 'Next'}
          </Button>
        </Box>
      </TrialCarouselStep>
    </BaseDialog>
  );
};

export default TrialCarousel;
