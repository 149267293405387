import { DIMENSION_AVATAR_SMALL } from '../../../constants';
import { UserInfo } from '../../../types/UserInfoTypes';
import { AvatarPicture } from '../../avatar/AvatarPicture';
import UserSummaryBasic from '../../common/invite-users/UserSummaryBasic';
import { getIsUserInactive } from '../../../lib/getIsUserActive';

interface UserSummaryProps {
  user?: UserInfo;
  isActive?: boolean;
}

const UserSummaryForHeader = ({ user = {}, isActive }: UserSummaryProps) => {
  const { status } = user || {};

  return (
    <UserSummaryBasic className={`header-user-summary ${isActive ? 'active' : ''}`}>
      <div className="user-summary-avatar" data-testid="user-summary-avatar-header">
        <AvatarPicture
          avatar={{ emoji: user.emoji, imageUrl: user.imageUrl }}
          dimension={DIMENSION_AVATAR_SMALL}
          clickable={true}
          isInactive={getIsUserInactive(status)}
        />
      </div>
    </UserSummaryBasic>
  );
};

export { UserSummaryForHeader };
