import noop from 'lodash/noop';
import React, { useCallback, useState } from 'react';
import { MaybeClosePanelFunc, PanelType } from './types';

interface IContextPanel<DataT = unknown> {
  panelType: PanelType<DataT>;
  isDirty?: boolean;
  data?: DataT;
  onClose?: () => void;
}

type PanelConfirmOpen = ({ action }: { action: () => void }) => void;

interface IPanelsContext<DataT = unknown> {
  currentPanel?: IContextPanel<DataT>;
  setCurrentPanel: (panel: IContextPanel<DataT> | undefined) => void;
  confirmOpen: PanelConfirmOpen;
  isInitialized: boolean;
}

const PanelsContext = React.createContext<IPanelsContext>({
  setCurrentPanel: noop,
  confirmOpen: noop,
  isInitialized: false,
});

export const PanelsProvider = ({
  children,
  confirmOpen,
  forceConfirmOpen,
}: {
  children: React.ReactNode;
  confirmOpen?: PanelConfirmOpen;
  forceConfirmOpen?: boolean;
}) => {
  const [currentPanel, setCurrentPanel] = useState<IContextPanel | undefined>(undefined);
  if (Boolean(confirmOpen) === Boolean(forceConfirmOpen)) {
    // XOR
    throw new Error(
      'PanelsProvider requires one of confirmOpen or forceConfirmOpen to be passed, but not both',
    );
  }
  const _confirmOpen: PanelConfirmOpen =
    confirmOpen ||
    (({ action }) => {
      action();
    });

  return (
    <PanelsContext.Provider
      value={{
        currentPanel,
        setCurrentPanel,
        confirmOpen: _confirmOpen,
        isInitialized: true,
      }}
    >
      {children}
    </PanelsContext.Provider>
  );
};

interface PanelConsumerArgs<DataT = unknown> {
  isOpen: boolean;
  maybeClosePanel: MaybeClosePanelFunc;
  setIsDirty: (isDirty: boolean) => void;
  data?: DataT; // Does not passes data if isOpen is false
  onClose?: () => void;
}

interface IMaybeOpenPanel<DataT = unknown> {
  panelType: PanelType<DataT>;
  instanceId?: string;
  data?: DataT;
  onOpen?: () => void;
  onClose?: () => void;
}

export function usePanels() {
  const { currentPanel, setCurrentPanel, confirmOpen, isInitialized } =
    React.useContext(PanelsContext);
  if (!isInitialized) {
    throw new Error(
      'PanelsProvider is not initialized. Please wrap your app/component with PanelsProvider',
    );
  }

  const maybeOpenPanel = useCallback(
    <DataT,>({ panelType, instanceId, data, onOpen, onClose }: IMaybeOpenPanel<DataT>) => {
      const nameAndInstance = instanceId ? `${panelType.name}-${instanceId}` : panelType.name;
      const confirmAction = () => {
        setCurrentPanel({
          panelType: {
            ...panelType,
            name: nameAndInstance,
          },
          data,
          onClose,
        });
        if (onOpen) {
          onOpen();
        }
      };

      if (currentPanel?.isDirty) {
        confirmOpen({
          action: confirmAction,
        });
      } else {
        confirmAction();
      }
    },
    [confirmOpen, currentPanel?.isDirty, setCurrentPanel],
  );

  const forceCloseAllPanels = useCallback(() => setCurrentPanel(undefined), [setCurrentPanel]);

  const isPanelOpen = useCallback(
    <DataT,>(panelType: PanelType<DataT>, instanceId?: string) => {
      const nameAndInstance = instanceId ? `${panelType.name}-${instanceId}` : panelType.name;
      return currentPanel?.panelType.name === nameAndInstance;
    },
    [currentPanel?.panelType.name],
  );
  const maybeCloseActivePanel: MaybeClosePanelFunc = useCallback(
    (params) => {
      const closeAction = () => {
        setCurrentPanel(undefined);
        if (currentPanel?.onClose) {
          currentPanel?.onClose();
        }
        if (params?.onCloseConfirm) {
          params.onCloseConfirm();
        }
      };

      if (currentPanel?.isDirty && !params?.forceClose) {
        confirmOpen({
          action: closeAction,
        });
      } else {
        closeAction();
      }
    },
    [confirmOpen, currentPanel, setCurrentPanel],
  );

  return {
    currentPanel,
    maybeOpenPanel,
    forceCloseAllPanels,
    isPanelOpen,
    isCurrentPanelDirty: Boolean(currentPanel?.isDirty),
    maybeCloseActivePanel,
  };
}

export function PanelConsumer<DataT = unknown>({
  panelType,
  instanceId,
  children,
  defaultIsDirty,
}: {
  panelType: PanelType<DataT>;
  instanceId?: string;
  children: (args: PanelConsumerArgs<DataT>) => React.ReactNode;
  defaultIsDirty?: boolean;
}) {
  const nameAndInstance = instanceId ? `${panelType.name}-${instanceId}` : panelType.name;
  return (
    <PanelsContext.Consumer
      children={({ currentPanel, setCurrentPanel, confirmOpen }) => {
        const isOpen = currentPanel?.panelType.name === nameAndInstance;

        const maybeClosePanel: MaybeClosePanelFunc = (params) => {
          const closeAction = () => {
            setCurrentPanel(undefined);
            if (currentPanel?.onClose) {
              currentPanel?.onClose();
            }
            if (params?.onCloseConfirm) {
              params.onCloseConfirm();
            }
          };

          if (currentPanel?.isDirty && !params?.forceClose) {
            confirmOpen({
              action: closeAction,
            });
          } else {
            closeAction();
          }
        };

        const setIsDirty = (isDirty: boolean) => {
          if (currentPanel) {
            setCurrentPanel({ ...currentPanel, isDirty });
          }
        };
        if (defaultIsDirty) {
          setIsDirty(true);
        }

        if (isOpen) {
          return children({
            isOpen,
            setIsDirty,
            data: currentPanel?.data as DataT,
            maybeClosePanel,
          });
        }
        return children({
          isOpen,
          setIsDirty: noop,
          maybeClosePanel: noop,
        }); // Panel is closed so pass the noop functions
      }}
    />
  );
}
