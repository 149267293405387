import { DateTimePicker, PickersTimezone, renderTimeViewClock } from '@mui/x-date-pickers';
import { ReactNode } from 'react';
import { Box, FormControl, SxProps } from '@mui/material';
import { ClearIcon } from './ClearIcon';
import FieldLabel from '../../FieldLabel';
import { ApiSweepFieldOperator } from '../../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { DateTime } from 'luxon';
import { humanizeDate, humanizeDateVariants } from '../../../helpers/humanizeDate';

export interface DatetimeSelectorProps {
  value?: DateTime | null;
  onChange: (value: DateTime | null) => void;
  shouldBeDisabled?: boolean;
  textFieldSx?: SxProps;
  placeholder?: string;
  _operator?: ApiSweepFieldOperator | '';
  label?: string;
  infoTooltipTitle?: NonNullable<ReactNode>;
  showTooltip?: boolean;
  id?: string;
  removeBorders?: boolean;
  minDateTime?: DateTime;
  timezone?: PickersTimezone;
}

const DatetimeSelector = ({
  value: value,
  onChange,
  shouldBeDisabled,
  textFieldSx,
  placeholder,
  label,
  showTooltip,
  infoTooltipTitle,
  id,
  removeBorders,
  minDateTime,
  timezone,
}: DatetimeSelectorProps) => {
  return (
    <FormControl fullWidth>
      {label && (
        <FieldLabel
          label={label}
          id={id}
          infoTooltipTitle={infoTooltipTitle}
          showTooltip={showTooltip}
        />
      )}

      <Box>
        <DateTimePicker
          disabled={shouldBeDisabled}
          value={value}
          onChange={onChange}
          minDateTime={minDateTime}
          timezone={timezone}
          sx={{
            width: '100%',
            fieldset: {
              border: removeBorders ? 'none' : 'auto',
            },
          }}
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          slotProps={{
            textField: (params) => ({
              disabled: shouldBeDisabled,
              inputProps: {
                ...params.inputProps,
                placeholder: placeholder ?? 'Choose date',
                disabled: true,
                value: humanizeDate({
                  dateOrTimestamp: value?.toSQL({ includeOffset: false }) ?? '',
                  variant: humanizeDateVariants.SHORT_WITH_TIME,
                  timeZone: timezone,
                }),
              },
              sx: { ...params.sx, ...textFieldSx },
              error: false,
              InputProps: {
                endAdornment: (
                  <>
                    {value && !shouldBeDisabled && <ClearIcon clearValue={() => onChange(null)} />}
                    {params.InputProps?.endAdornment}
                  </>
                ),
              },
            }),
            desktopPaper: {
              elevation: 1,
            },
          }}
        />
      </Box>
    </FormControl>
  );
};

export { DatetimeSelector };
