import axios, { AxiosError, AxiosResponse } from 'axios';
import { telemetry } from '../../telemetry';

const axios_instance = axios.create({
  baseURL: import.meta.env.VITE_SERVER_URL,
});

const hubspotToken = document.cookie.replace(
  /(?:(?:^|.*;\s*)hubspotutk\s*\=\s*([^;]*).*$)|^.*$/,
  '$1',
);

axios_instance.defaults.withCredentials = true;
axios_instance.defaults.headers.common['hutk'] = hubspotToken;

axios_instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    telemetry.addBreadcrumb({
      level: 'error',
      category: 'axios',
      message: 'axios error',
      data: { data: error.response?.data },
    });

    return Promise.reject(error);
  },
);

export default axios_instance;
