import { Box } from '@mui/material';
import { AutomationTypes } from '../../../types/enums/AutomationTypes';
import { SelectorValueTypes } from '../../../types/enums/SelectorValueTypes';
import { SweepFieldsOperatorSelector } from '../../SweepFieldsOperatorSelector/SweepFieldsOperatorSelector';
import {
  ApiSweepFieldOperator,
  operatorHasAutomaticValue,
  SweepFieldOperatorsValues,
} from '../../SweepFieldsOperatorSelector/sweepFieldsTypesAndOperators';
import { SweepFieldsValueSelector } from '../../SweepFieldsValueSelector/SweepFieldsValueSelector';
import { getNestedPath } from '../helper';
import { NestedFieldsSelector } from '../../common/fieldsSelectors/NestedFieldsSelector';
import { colors } from '@sweep-io/sweep-design';

interface TriggerFieldUpdateProps {
  crmOrgId: string;

  automationJson: DeepPartial<AutomationStructureNew>;
  readonly?: boolean;
  onChangeField: (
    leadingFieldId: string,
    leadingFieldLabels: string[],
    fieldType: string | undefined,
    sweepField: SweepField | undefined,
  ) => any;
  onChangeOperator: (_operator: ApiSweepFieldOperator, _value: SweepFieldOperatorsValues) => any;
  onChangeValue: (value: string) => any;
  objectName: ObjectTypeValues;
}

export const TriggerFieldUpdate = ({
  crmOrgId,
  automationJson,
  readonly,
  onChangeField,
  onChangeOperator,
  onChangeValue,
  objectName,
}: TriggerFieldUpdateProps) => {
  const JsonFieldUpdate = automationJson.automationDetails?.automationParams?.fieldUpdate;
  return (
    <>
      <Box
        sx={{
          marginRight: '8px',
          borderRadius: '4px',
          border: `1px solid ${colors.grey[300]}`,
          position: 'relative',
        }}
      >
        <NestedFieldsSelector
          disableLookupItemsResolve={true}
          crmOrgId={crmOrgId}
          objectType={objectName}
          nestedPath={getNestedPath(
            JsonFieldUpdate?._leadingFieldId,
            JsonFieldUpdate?._leadingFieldLabels,
          )}
          readonly={readonly}
          onChange={(_sweepField) => {
            onChangeField(
              _sweepField.fieldIds[0],
              _sweepField.fieldLabels,
              _sweepField.fieldType,
              _sweepField.sweepField,
            );
          }}
        />
      </Box>
      {automationJson.automationDetails?.triggerType === AutomationTypes.FieldUpdate &&
        JsonFieldUpdate &&
        JsonFieldUpdate?.fieldType && (
          <Box
            sx={{
              marginRight: '8px',
            }}
          >
            <SweepFieldsOperatorSelector
              placeholder={'Choose option'}
              removeBorders={false}
              disabled={readonly}
              showAny
              operator={JsonFieldUpdate?.operator || undefined}
              value={JsonFieldUpdate.operatorValue || undefined}
              fieldType={JsonFieldUpdate.fieldType}
              onChange={(_operator, _value) => {
                onChangeOperator(_operator, _value);
              }}
            />
          </Box>
        )}
      {automationJson.automationDetails?.triggerType === AutomationTypes.FieldUpdate &&
        JsonFieldUpdate?.operator &&
        !operatorHasAutomaticValue(JsonFieldUpdate?.operator as ApiSweepFieldOperator) && (
          <Box
            sx={{
              marginRight: '8px',
            }}
          >
            <SweepFieldsValueSelector
              isPicklistMultiple={JsonFieldUpdate?.fieldType === 'Picklist' ? false : undefined}
              shouldBeDisabled={readonly}
              placeholder="Value"
              parentFieldType={JsonFieldUpdate.fieldType ?? ''}
              initialValue={JsonFieldUpdate?.operatorValue}
              onChange={(value) => {
                onChangeValue(value);
              }}
              field={JsonFieldUpdate.field}
              fieldIds={[JsonFieldUpdate._leadingFieldId]}
              crmOrgId={crmOrgId}
              valueType={SelectorValueTypes.LITERAL}
              _operator={JsonFieldUpdate.operator}
            />
          </Box>
        )}
    </>
  );
};
