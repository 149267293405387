import { useEffect, useMemo, useState } from 'react';
import { useFunnelMapApiFacade } from '../../../../apis/facades/funnel-map/useFunnelMapApiFacade';
import usePermission from '../../../common/permissions/usePermission';
import { simpleHash } from '../../../../third-party/simpleHash';
import { telemetry } from '../../../../telemetry';
import isEqual from 'lodash/isEqual';
import { useAutoSaveContext } from './AutoSaveContext';
import { FunnelMapSaveData } from './useAutoSave';
import { funnelMapToFunnelMapPutPayload } from '../../../../apis/facades/funnel-map/funnelMapToFunnelMapPutPayload';

const FUNNEL_MAP = 'FUNNEL_MAP';

interface UseFunnelMapAutoSave {
  funnelMap: FunnelMap;
  setLastUpdatedFor: (object: string, date: string) => void;
}

export const useFunnelMapAutoSave = ({ funnelMap, setLastUpdatedFor }: UseFunnelMapAutoSave) => {
  const {
    getErrorHashFor,
    removeError,
    setErrorsFor,
    setFunnelEntityIsDirty,
    setIsSavingFor,
    getIsSavingFor,
  } = useAutoSaveContext();

  const funnelMapSaveData: FunnelMapSaveData = useMemo(() => {
    const { id, funnels, name, thirdPartyFunnels } = funnelMap;
    return {
      id,
      funnels,
      name,
      thirdPartyFunnels,
    };
  }, [funnelMap]);

  const [lastSavedFunnelMapSaveData, setLastSavedFunnelMapSaveData] = useState(funnelMapSaveData);

  const [hasEditFunnelPermissions] = usePermission(['edit:funnels']);

  const { put_funnelMap } = useFunnelMapApiFacade();

  useEffect(() => {
    if (!hasEditFunnelPermissions) {
      return;
    }
    const isSavingFunnelMap = getIsSavingFor(FUNNEL_MAP);
    const funnelMapHasChanges = !isEqual(lastSavedFunnelMapSaveData, funnelMapSaveData);
    const funnelMapSaveDataHash = simpleHash(JSON.stringify(funnelMapSaveData));
    const funnelMapSavingError = getErrorHashFor(FUNNEL_MAP) === funnelMapSaveDataHash;

    if (!funnelMapSavingError && !isSavingFunnelMap && funnelMapHasChanges) {
      setFunnelEntityIsDirty(FUNNEL_MAP, true);
      setIsSavingFor(FUNNEL_MAP, true);

      const funnelMapPayload = funnelMapToFunnelMapPutPayload(funnelMap);

      put_funnelMap(funnelMap.id, funnelMapPayload)
        .then((funnelMap) => {
          setLastSavedFunnelMapSaveData(funnelMapSaveData);
          setLastUpdatedFor(FUNNEL_MAP, funnelMap.updatedAt);
          setFunnelEntityIsDirty(FUNNEL_MAP, false);
          removeError(FUNNEL_MAP);
        })
        .catch((err) => {
          telemetry.captureError(err);
          setErrorsFor(FUNNEL_MAP, funnelMapSaveDataHash);
        })
        .finally(() => {
          setIsSavingFor(FUNNEL_MAP, false);
        });
    }
  }, [
    funnelMapSaveData,
    getIsSavingFor,
    lastSavedFunnelMapSaveData,
    put_funnelMap,
    setErrorsFor,
    setFunnelEntityIsDirty,
    setIsSavingFor,
    getErrorHashFor,
    removeError,
    hasEditFunnelPermissions,
    setLastUpdatedFor,
    funnelMap,
  ]);
};
