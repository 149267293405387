import { Button, IconButton, Tooltip } from '@sweep-io/sweep-design';
import { CheckCircle } from '@sweep-io/sweep-design/dist/icons';

const ResolveButton = ({
  isResolved,
  updateResolveCommentThread,
}: {
  isResolved: boolean;
  updateResolveCommentThread: (resolve: boolean) => void;
}) => {
  return isResolved ? (
    <Button
      variant="flat"
      startIconName="DelayedRefresh"
      onClick={(e) => {
        e.stopPropagation(); //to prevent zoom on the comment
        updateResolveCommentThread(false);
      }}
    >
      Unresolve
    </Button>
  ) : (
    <Tooltip title="Resolve thread" placement="top">
      <span>
        {/*TODO Sergey should handle the span fix in design system*/}
        <IconButton
          variant="flat"
          onClick={(e) => {
            e.stopPropagation(); //to prevent zoom on the comment
            updateResolveCommentThread(true);
          }}
        >
          <CheckCircle />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default ResolveButton;
