import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { FUNNEL_NAME_MAX_LENGTH } from '../../../../constants';
import { withTrimmedValues } from '../../../../lib/trimmedValues';

const useFunnelMapForm = ({
  initialFunnelName,
  initialDefaultCrmOrgId,
  onSubmit,
  disableCrmOrgIdValidation,
}: {
  initialFunnelName: string | undefined;
  initialDefaultCrmOrgId: string | undefined;
  onSubmit: (values: any) => void;
  disableCrmOrgIdValidation?: boolean;
}) => {
  const vs: any = {
    name: yup.string().required('Name is a required'),
  };
  if (!disableCrmOrgIdValidation) {
    vs.defaultCrmOrgId = yup.string().required('Environment is required');
  }
  const getValidationSchema = () =>
    yup.object().shape({
      name: yup
        .string()
        .required('Name is a required')
        .matches(/^[a-zA-Z_][a-zA-Z0-9_]*/, `Funnel name must start with a letter`)
        .max(40, `Funnel view name can have maximum of ${FUNNEL_NAME_MAX_LENGTH} characters`),
    });

  const formik: FormikProps<SettingsValues> = useFormik({
    initialValues: {
      name: initialFunnelName,
      defaultCrmOrgId: initialDefaultCrmOrgId,
    },
    validationSchema: getValidationSchema(),
    onSubmit: withTrimmedValues(onSubmit),
  });

  const isSubmitted = Boolean(formik.submitCount);

  return { ...formik, isSubmitted };
};

export { useFunnelMapForm };
