import { Button } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';
import ConnectToSfDialog from '../../pages/get-started/connect-org/ConnectToSfDialog';
import WithConnectOrgPermission from '../WithConnectOrgPermission';

const ConnectToSfButton = ({ forceProduction }: { forceProduction: boolean }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = () => {
    setIsDialogOpen(true);
  };

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <>
      {isDialogOpen && (
        <ConnectToSfDialog
          closeDialog={closeDialog}
          forceProduction={forceProduction}
          onFinish={closeDialog}
        />
      )}
      <WithConnectOrgPermission>
        <Button onClick={openDialog} size="small">
          Connect
        </Button>
      </WithConnectOrgPermission>
    </>
  );
};

export default ConnectToSfButton;
