import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

const CarouselProgressDots = ({ count, activeIndex }: { count: number; activeIndex: number }) => {
  return (
    <Box display="flex" gap={1}>
      {[...Array(count).keys()].map((item, index) => (
        <Box
          key={`dot-${index}`}
          sx={{
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            backgroundColor: index === activeIndex ? colors.blue[500] : colors.grey[200],
          }}
        />
      ))}
    </Box>
  );
};

export default CarouselProgressDots;
