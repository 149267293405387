import { Typography } from '@sweep-io/sweep-design';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { ConfirmDialog } from '../../../common/dialogs/ConfirmDialog';

interface ConfirmDeleteAssignmentGroupDialogProps {
  assignmentGroup: AssignmentGroup;
  onConfirm: () => any;
  handleClose: () => any;
}

export const ConfirmDeleteGroupDialog = ({
  assignmentGroup,
  onConfirm,
  handleClose,
}: ConfirmDeleteAssignmentGroupDialogProps) => {
  const assignmentGroupInUse = false; //TODO add logic - waiting for product

  return (
    <ConfirmDialog
      title={`Delete ${assignmentGroup.name}?`}
      confirmText="Delete"
      confirmButtonType="error"
      cancelText="Cancel"
      onConfirm={onConfirm}
      isOpen
      handleClose={handleClose}
      PaperProps={{
        sx: {
          width: 470,
          minHeight: '199px',
        },
      }}
    >
      {assignmentGroupInUse && (
        <Typography variant="body">
          <Typography variant="body">This Assignment Group is used by...</Typography>
          <br />
          <br />
          <Typography variant="body">
            By deleting, records assigned to this group will no longer be assigned.
          </Typography>
        </Typography>
      )}
      {!assignmentGroupInUse && (
        <Typography variant="body">
          Deleting this assignment group will remove it from all environments
        </Typography>
      )}
    </ConfirmDialog>
  );
};
