import { Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';

const TextWithAnimatedThreeDots = ({text}: {text: string}) => (
  <Box
    lineHeight={1}
    sx={{
      '.dot1': {
        animation: 'visibility1 2s linear infinite',
      },
      '.dot2': {
        animation: 'visibility2 2s linear infinite',
      },
      '.dot3': {
        animation: 'visibility3 2s linear infinite',
      },
      '@keyframes visibility1': {
        '0%': {
          opacity: 1,
        },
        '65%': {
          opacity: 1,
        },
        '66%': {
          opacity: 0,
        },
        '100%': {
          opacity: 0,
        },
      },
      '@keyframes visibility2': {
        '0%': {
          opacity: 0,
        },
        '21%': {
          opacity: 0,
        },
        '22%': {
          opacity: 1,
        },
        '65%': {
          opacity: 1,
        },
        '66%': {
          opacity: 0,
        },
        '100%': {
          opacity: 0,
        },
      },
      '@keyframes visibility3': {
        '0%': {
          opacity: 0,
        },
        '43%': {
          opacity: 0,
        },
        '44%': {
          opacity: 1,
        },
        '65%': {
          opacity: 1,
        },
        '66%': {
          opacity: 0,
        },
        '100%': {
          opacity: 0,
        },
      },
    }}
  >
    <Typography variant="caption">
      {text}
      <span className="dot1">.</span>
      <span className="dot2">.</span>
      <span className="dot3">.</span>
    </Typography>
  </Box>
);

export default TextWithAnimatedThreeDots;
