import { useEffect, useState } from 'react';
import { useCrmOrgsApiFacade } from '../../../apis/facades/useCrmOrgsApiFacade';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import SweepSelect from '../../common/SweepSelect';

export const SingleRecordTypeSelector = ({
  recordTypesList,
  crmOrgId,
  objectName,
  recordType,
  onChange,
}: {
  recordType?: ShortParserRecordType;
  recordTypesList?: ShortParserRecordType[];
  objectName: string;
  crmOrgId: string;
  onChange: (recordType: ShortParserRecordType) => any;
}) => {
  const { getObjectFunnelsAndRecordTypes } = useCrmOrgsApiFacade();
  const [internalRecordTypesList, setInternalRecordTypesList] = useState<ShortParserRecordType[]>(
    recordTypesList || [],
  );

  useEffect(() => {
    if (!recordTypesList) {
      getObjectFunnelsAndRecordTypes({ crmOrgId, objectName }).then((data) => {
        setInternalRecordTypesList(data.recordTypes);
      });
    } else {
      setInternalRecordTypesList(recordTypesList);
    }
  }, [crmOrgId, getObjectFunnelsAndRecordTypes, objectName, recordTypesList]);

  return (
    <SweepSelect
      FormControlProps={
        {
          // sx: { minWidth: '140px', marginRight: '8px' },
        }
      }
      SelectProps={{
        placeholder: 'Record Type',
        value: recordType?.name,
        onChange: (event: SelectChangeEvent<unknown>) => {
          const recordType = internalRecordTypesList.find((rt) => rt.name === event.target.value);
          recordType && onChange(recordType);
        },
      }}
    >
      {internalRecordTypesList.map((rt) => (
        <MenuItem key={rt.name as any} value={rt.name}>
          {rt.label}
        </MenuItem>
      ))}
      {internalRecordTypesList.length === 0 && recordType && (
        <MenuItem value={recordType.name}>{recordType.label}</MenuItem>
      )}
    </SweepSelect>
  );
};
