import { useCrmOrgs } from '../../pages/environments/useCrmOrgs';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';

const useCheckManagedPackage = () => {
  const { getCrmOrg } = useCrmOrgs();
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const isManagedPackagedInstalled = defaultCreationEnvironment?.isManagedPackageInstalled;
  const isPermissionSetGroupAssigned = defaultCreationEnvironment?.isPermissionSetGroupAssigned;

  const recheckIsManagedPackagedInstalled = async (withPermissionsCheck?: boolean) => {
    if (!defaultCreationEnvironment) return false;
    const res = await getCrmOrg(defaultCreationEnvironment.id);
    const permissionsCheck = !withPermissionsCheck || res.isPermissionSetGroupAssigned;
    return res.isManagedPackageInstalled && permissionsCheck;
  };

  const getIsManagedPackagedInstalledWithRecheck = async ({
    withPermissionsCheck,
  }: {
    withPermissionsCheck?: boolean;
  } = {}) => {
    const permissionsCheck = !withPermissionsCheck || isPermissionSetGroupAssigned;
    if (isManagedPackagedInstalled && permissionsCheck) return true;
    return recheckIsManagedPackagedInstalled(withPermissionsCheck);
  };

  return { getIsManagedPackagedInstalledWithRecheck };
};

export default useCheckManagedPackage;
