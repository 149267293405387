import pluralize from 'pluralize';
import { useDispatch, useSelector } from 'react-redux';
import { setLongTemplate, selectLongTemplate } from '../dialog/templatesDialogReducer';
import { useRunOnceWhenTruthy } from '../../../../common/useRunOnceWhenTruthy';
import { Box } from '@mui/material';
import { Tag, Typography } from '@sweep-io/sweep-design';
import { TemplatePreview } from './TemplatesPreview';
import { NewTemplateFieldsMapping } from './NewTemplateFieldsMapping';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import { TemplateInterface } from '../templatesTypes';
import { useTemplatesFacade } from '../../../../../apis/facades/useTemplatesFacade';
import { TemplatesPageHeader } from '../dialog/TemplatesPageHeader';
import { ConfigurationCanvasPanelContent } from '../../../configuration-canvas-panel';

interface TemplateDetails {
  templateId: string;
  onBackClick: () => void;
  onAddTemplate: (template: TemplateInterface) => void;
  crmOrgId: string;
}

export const TemplateDetails = ({
  templateId,
  onBackClick,
  crmOrgId,
  onAddTemplate,
}: TemplateDetails) => {
  const dispatch = useDispatch();
  const template = useSelector(selectLongTemplate(templateId));
  const { get_template } = useTemplatesFacade();

  useRunOnceWhenTruthy(async () => {
    const template = await get_template(templateId);
    dispatch(setLongTemplate({ ...template, id: templateId }));
  }, template === undefined);

  if (!template) {
    return <CenteredCircularProgress />;
  }
  const {
    gates,
    automations,
    alerts: playbookAlerts,
    assignments: assignmentRules,
    assignmentGroups,
  } = template.stats;

  return (
    <>
      <TemplatesPageHeader
        onBackClick={onBackClick}
        title={template.name}
        onButtonClick={() => {
          onAddTemplate(template);
        }}
        buttonText="Use Template"
      />
      <ConfigurationCanvasPanelContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
          }}
        >
          <Box>
            <TemplatePreview template={template} />
          </Box>
          <Box>
            <Typography variant="body">{template.description}</Typography>
          </Box>
          {false && ( // Remove this temporarily
            <Box display="flex" gap="14px" flexDirection="column">
              <Typography variant="body-bold">This funnel includes</Typography>

              <Box display="flex" gap={1}>
                <Tag startIconName="Gate" size="medium" label={pluralize('Gate', gates, true)} />
                <Tag
                  startIconName="Automations"
                  size="medium"
                  label={pluralize('Automation', automations, true)}
                />
                <Tag
                  size="medium"
                  startIconName="PlaybookAlert"
                  label={pluralize('Alert', playbookAlerts, true)}
                />
              </Box>
              <Box display="flex" gap={1}>
                <Tag
                  size="medium"
                  startIconName="Gate"
                  label={pluralize('Assignment Rule', assignmentRules, true)}
                />
                <Tag
                  size="medium"
                  startIconName="Automations"
                  label={pluralize('Assignment Group', assignmentGroups, true)}
                />
              </Box>
            </Box>
          )}

          <NewTemplateFieldsMapping
            templateFields={template.fieldMapping}
            templateId={templateId}
            crmOrgId={crmOrgId}
          />
        </Box>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
