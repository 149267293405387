const currentVersionName = 'Current version';

const DIMENSION_AVATAR_BIG = 150;
const DIMENSION_AVATAR_BIG_BIG_MEDIUM = 72;
const DIMENSION_AVATAR_BIG_MEDIUM = 60;
const DIMENSION_AVATAR_MEDIUM = 48;
const DIMENSION_AVATAR_MEDIUM_SMALL = 40;
const DIMENSION_AVATAR_SMALL = 30;
const DIMENSION_AVATAR_VERY_SMALL = 24;

const PADDING_DASHBOARD_MENU = 17;
const HEIGHT_DASHBOARD_MENU_ITEM = 38;
const DASHBOARD_HEADER_HEIGHT = 56;
const DASHBOARD_HEADER_HEIGHT_PX = `${DASHBOARD_HEADER_HEIGHT}px`;

const CARD_MIN_WIDTH = '306px';
const CARD_HEIGHT_NUMBER = 248;
const CARD_HEIGHT = CARD_HEIGHT_NUMBER + 'px';
const CARDS_LAYOUT_GRID_GAP_NUMBER = 24;

const RECORD_TYPE_STEP_MAX_WIDTH = '250px';
const STYLED_CHIP_MARGIN = 6;

const FUNNEL_NAME_MAX_LENGTH = 40;
const FUNNEL_NAME = 'name';

const FUNNEL_VERSION_DIALOG_MAX_WIDTH_NUMBER = 974;
const FUNNEL_VERSION_DIALOG_HEIGHT = '95vh';
const FUNNEL_VERSIONS_TABLE_HEADER_HEIGHT = '160px';
const FUNNEL_VERSIONS_TABLE_PREVIEW_HEIGHT = '320px';

const DEFAULT_SELECT_MENU_MAX_HEIGHT = '300px';

const EXPANDED_FUNNEL_PREVIEW_HEIGHT = '320px';
const MINIMIZED_FUNNEL_PREVIEW_HEIGHT = '154px';

const SELECTOR_VALUE_SEPARATOR = '.';

const UNTITLED_FUNNEL_MAP_NAME = 'Untitled';

const UNAUTHORIZED_CONNECT_TOOLTIP = `To connect Sweep with Salesforce, please contact your admin.`;
const UNAUTHORIZED_FETCH_TOOLTIP = `To refresh the environment, please contact your admin.`;

export {
  currentVersionName,
  DIMENSION_AVATAR_BIG,
  DIMENSION_AVATAR_SMALL,
  DIMENSION_AVATAR_MEDIUM,
  DIMENSION_AVATAR_BIG_BIG_MEDIUM,
  DIMENSION_AVATAR_BIG_MEDIUM,
  PADDING_DASHBOARD_MENU,
  STYLED_CHIP_MARGIN,
  HEIGHT_DASHBOARD_MENU_ITEM,
  CARD_MIN_WIDTH,
  RECORD_TYPE_STEP_MAX_WIDTH,
  FUNNEL_NAME_MAX_LENGTH,
  FUNNEL_NAME,
  CARD_HEIGHT,
  CARD_HEIGHT_NUMBER,
  CARDS_LAYOUT_GRID_GAP_NUMBER,
  FUNNEL_VERSION_DIALOG_HEIGHT,
  DEFAULT_SELECT_MENU_MAX_HEIGHT,
  EXPANDED_FUNNEL_PREVIEW_HEIGHT,
  MINIMIZED_FUNNEL_PREVIEW_HEIGHT,
  FUNNEL_VERSION_DIALOG_MAX_WIDTH_NUMBER,
  SELECTOR_VALUE_SEPARATOR,
  FUNNEL_VERSIONS_TABLE_HEADER_HEIGHT,
  FUNNEL_VERSIONS_TABLE_PREVIEW_HEIGHT,
  DIMENSION_AVATAR_VERY_SMALL,
  DIMENSION_AVATAR_MEDIUM_SMALL,
  UNTITLED_FUNNEL_MAP_NAME,
  DASHBOARD_HEADER_HEIGHT_PX,
  UNAUTHORIZED_CONNECT_TOOLTIP,
  UNAUTHORIZED_FETCH_TOOLTIP,
  DASHBOARD_HEADER_HEIGHT,
};
