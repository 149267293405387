import * as React from "react";
const SvgUserTime = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 33, height: 32, viewBox: "0 0 33 32", fill: "none", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M12.254 24.2718C15.5546 24.2718 18.2302 21.5961 18.2302 18.2956C18.2302 14.995 15.5546 12.3193 12.254 12.3193C8.95342 12.3193 6.27778 14.995 6.27778 18.2956C6.27778 21.5961 8.95342 24.2718 12.254 24.2718ZM12.254 24.2718C10.418 24.2718 8.61437 24.7543 7.02439 25.6723C5.43441 26.5904 4.11414 27.9109 3.19629 29.501M12.254 24.2718C14.09 24.2718 15.8936 24.7543 17.4836 25.6723C19.0736 26.5904 20.3939 27.9109 21.3117 29.501", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M24.769 5.72289V8.00381H27.2085M30.6473 8.00381C30.6473 11.2503 28.0155 13.8821 24.769 13.8821C21.5224 13.8821 18.8906 11.2503 18.8906 8.00381C18.8906 4.7573 21.5224 2.12549 24.769 2.12549C28.0155 2.12549 30.6473 4.7573 30.6473 8.00381Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgUserTime;
