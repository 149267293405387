import { useSelector } from 'react-redux';
import { selectLatestDeployment } from '../../../../../reducers/documentationReducer';
import { Box } from '@mui/material';
import { MainSectionWithHeaderWrapper } from './organisms/MainSectionWithHeaderWrapper';
import { Subtext } from './organisms/atoms/Subtext';
import { keyToName } from './consts';
import { ConfigurationTypesWithIcon } from '../../types';
import { configurationItemTypeToIcon, propertiesToIcon } from './organisms/helpers/icons';
import { SubsectionWrapper } from './organisms/SubsectionWrapper';
import { ConditionsWrapper } from './organisms/ConditionsByLogicString';

export const AutomationTemplate = ({
  elementId,
  elementType,
}: {
  elementId: string;
  elementType: ConfigurationTypesWithIcon;
}) => {
  const latestDeployment = useSelector(selectLatestDeployment(elementId));

  if (!latestDeployment) {
    return <></>;
  }

  const { name, trigger, conditions, actions, delay, conditionsLogicString } =
    latestDeployment as ReadOnlyAutomation;

  return (
    <Box>
      <MainSectionWithHeaderWrapper
        title={keyToName['name']}
        titleIcon={propertiesToIcon['name']}
        withPaddingLeft
      >
        <Subtext text={name} />
      </MainSectionWithHeaderWrapper>

      <MainSectionWithHeaderWrapper
        title={keyToName['trigger']}
        titleIcon={propertiesToIcon['trigger']}
        withPaddingLeft
      >
        <Subtext text={trigger} />

        {!!conditions.length && conditionsLogicString && (
          <Box pt={0.5} ml={-3}>
            <ConditionsWrapper
              startLogic="And"
              readOnlyAutomationAction={{
                details: conditions,
                conditionsLogicString,
              }}
              noConditionExternalBorder
            />
          </Box>
        )}
      </MainSectionWithHeaderWrapper>

      <MainSectionWithHeaderWrapper
        title={keyToName['actions']}
        titleIcon={propertiesToIcon['actions']}
      >
        <SubsectionWrapper
          HeaderIcon={configurationItemTypeToIcon[elementType]}
          elementType={elementType}
          readonlyAutomationActions={actions}
          renderStartAdornmentsByMap={{ 0: 'icon', 1: 'number', 2: 'icon' }}
        />
      </MainSectionWithHeaderWrapper>

      {!!delay.length && (
        <MainSectionWithHeaderWrapper
          title={keyToName['delay']}
          titleIcon={propertiesToIcon['delay']}
          withPaddingLeft
          noPaddingBottom
        >
          {delay.map((item) => (
            <Subtext key={item} text={item} />
          ))}
        </MainSectionWithHeaderWrapper>
      )}
    </Box>
  );
};
