import { Button } from '@sweep-io/sweep-design';
import { Box, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTerritoriesActions } from '../../hooks/useTerritoriesActions';
import { useState } from 'react';
import RestrictedTooltip from '../common/permissions/RestrictedTooltip';
import usePermission from '../common/permissions/usePermission';
import CreateEditTerritoryDialog from './CreateEditTerritoryDialog';
import { selectTerritories } from '../../reducers/territoriesReducer';
import { Permission } from '@server/permissions';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';
import EmptyStateWithButton from '../common/empty-state/EmptyStateWithButton';
import EarthImage from '../common/empty-state/svgs/earth.svg?react';
import TerritoriesTable from './TerritoriesTable';
import { DialogLoader } from '../common/dialogs/DialogLoader';
import { SearchInput } from '../common/SearchInput';
import pluralize from 'pluralize';
import GroupsTerritoriesLayout from './GroupsTerritoriesLayout';
import { useRoutingContext } from './RoutingContext';
import { SEARCH_BOX_WIDTH } from '../Automations/helper';

interface TerritoriesProps {
  onClose?: () => void;
  isDialogLayout?: boolean;
}

const TerritoriesPageContent = ({ onClose, isDialogLayout }: TerritoriesProps) => {
  const territories = useSelector(selectTerritories);
  const { createTerritory, updateTerritory, deleteTerritory } = useTerritoriesActions();
  const [createEditDialogOpen, setCreateEditDialogOpen] = useState(false);
  const permissionStr: Permission[] = ['create:territories', 'edit:territories'];
  const [isAllowedBtnCreate, isAllowedBtnEdit] = usePermission(permissionStr);
  const [editableObject, setEditableObject] = useState<Partial<Territory>>();
  const { territoriesSearch, setTerritoriesSearch } = useRoutingContext();
  const territoriesWithSearch = territories?.filter((territory) =>
    territory.name.toLowerCase().includes(territoriesSearch.toLowerCase()),
  );
  const setEditAndOpenDialog = (territory?: Partial<Territory>) => {
    setEditableObject(territory);
    setCreateEditDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setCreateEditDialogOpen(false);
  };

  const handleUpdateObject = async (_territory: Territory, isEdit: boolean) => {
    isEdit ? await updateTerritory(_territory.id, _territory) : await createTerritory(_territory);
    setCreateEditDialogOpen(false);
  };

  const handleDelete = async (toDelete: Territory) => {
    await deleteTerritory(toDelete.id);
  };

  const isEmpty = territories?.length === 0;

  const renderContent = () => {
    if (isEmpty) {
      return (
        <Stack pt="160px">
          <EmptyStateWithButton
            image={<EarthImage />}
            line1="Optimize and create new assignment Territories"
            notAllowedTitle="To create a new territory, please contact your admin."
            permissionString={[permissionStr[0]]}
            onClick={() => setEditAndOpenDialog()}
          />
        </Stack>
      );
    }

    return (
      <>
        <DialogLoader visible={!territories} />
        {territories && territoriesWithSearch && (
          <TerritoriesTable
            territories={territoriesWithSearch}
            onEdit={setEditAndOpenDialog}
            onDuplicate={(territory) => {
              const newItem = {
                ...territory,
                name: 'Copy of ' + territory.name,
                id: undefined,
              };
              setEditAndOpenDialog(newItem);
            }}
            onDelete={handleDelete}
            onClearFilters={() => setTerritoriesSearch('')}
          />
        )}
      </>
    );
  };

  const newTerritoryButtonJsx = (
    <RestrictedTooltip
      to={permissionStr}
      notAllowedTitle={`To create a new territory, please contact your admin.`}
    >
      <Button
        onClick={() => setEditAndOpenDialog()}
        startIconName="Plus"
        variant="filled"
        disabled={!isAllowedBtnCreate}
        size="small"
      >
        New territory
      </Button>
    </RestrictedTooltip>
  );

  const renderDialogs = () => {
    if (createEditDialogOpen) {
      return (
        <CreateEditTerritoryDialog
          isOpen={createEditDialogOpen}
          onBackButtonClick={handleCloseDialog}
          initialTerritory={editableObject}
          onSave={handleUpdateObject}
          isAllowedBtn={isAllowedBtnEdit}
          readonly={!isAllowedBtnCreate || !isAllowedBtnEdit}
        />
      );
    }
  };

  const searchJsx = (
    <Box width={SEARCH_BOX_WIDTH}>
      <SearchInput
        withFixedMagnifyingGlassIcon
        TextFieldProps={{
          value: territoriesSearch,
          placeholder: 'Search territories',
          onChange: (e) => {
            setTerritoriesSearch(e.target.value);
          },
        }}
        onClearButtonClick={() => setTerritoriesSearch('')}
        variant="small"
      />
    </Box>
  );

  return (
    <GroupsTerritoriesLayout
      title="Territories"
      isDialogLayout={isDialogLayout}
      onClose={onClose}
      newButtonJsx={newTerritoryButtonJsx}
      searchJsx={searchJsx}
      countText={pluralize('territories', territories?.length, true)}
      renderContent={renderContent}
      renderDialogs={renderDialogs}
    />
  );
};

const Territories = (props: TerritoriesProps) => {
  const territories = useSelector(selectTerritories);
  const { getTerritories } = useTerritoriesActions();
  useRunOnceWhenTruthy(async () => {
    await getTerritories();
  }, !!territories);

  return <TerritoriesPageContent {...props} />;
};

export default Territories;
