import { Box, Divider, FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import SweepSelect from '../common/SweepSelect';
import { AutomationActionRows } from './AutomationActionRows';
import _get from 'lodash/get';

interface ConvertLeadPerObjectActionProps {
  recordTypeList: RecordTypeStruct[];
  readonly?: boolean;
  crmOrgId: string;
  action: ConvertLeadAutomationAction;
  onChange: (stage: ConvertLeadAutomationAction) => any;
  objectProperty: string;
  objectName: string;
  initialObjectName: string;
  excludeValueType?: (type: SelectorValueTypes) => boolean;
  isNestedVerifiedUrl?: string;
}

export const ConvertLeadPerObjectActionNew = ({
  recordTypeList,
  readonly,
  crmOrgId,
  action,
  onChange,
  objectProperty,
  objectName,
  initialObjectName,
  excludeValueType,
  isNestedVerifiedUrl,
}: ConvertLeadPerObjectActionProps) => {
  const recordTypeExist = recordTypeList.length > 1;
  const _objectProperty = objectProperty.toLocaleLowerCase();
  return (
    <FormControl
      sx={{
        mt: '12px',
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
      }}
    >
      {recordTypeExist && (
        <>
          <Box>
            <Box>
              <SweepSelect
                error={
                  _get(action, `actionParams[${_objectProperty}].recordType`) === '-1'
                    ? 'Please select'
                    : undefined
                }
                SelectProps={{
                  sx: { width: '185px' },
                  disabled: readonly,
                  placeholder: 'Choose Record Type',
                  value: _get(action, `actionParams[${_objectProperty}].recordType.name`) ?? '',
                  renderValue: (val: any) => {
                    const recordType = recordTypeList?.find((type) => type?.name === val);
                    return recordType?.label || val;
                  },
                  onChange: (event: SelectChangeEvent<unknown>) => {
                    const val = event.target.value;
                    const getRecordTypeByName = recordTypeList?.find((type) => type?.name === val);
                    onChange({
                      ...action,
                      actionParams: {
                        ...action.actionParams,
                        [_objectProperty]: {
                          ...(_get(action, `actionParams[${_objectProperty}]`) || {}),
                          recordType: getRecordTypeByName,
                        },
                      },
                    });
                  },
                }}
              >
                {recordTypeList?.map((type) => (
                  <MenuItem key={type.name} value={type.name}>
                    {type.label}
                  </MenuItem>
                ))}
              </SweepSelect>
            </Box>
          </Box>
          <Divider orientation="vertical" flexItem />
        </>
      )}

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', width: '100%' }}>
        <AutomationActionRows
          showVerifiedUrlOption={isNestedVerifiedUrl}
          excludeValueType={excludeValueType}
          disableLookupItems={true}
          hideRecordTypeIdField={true}
          readonly={readonly}
          objectName={objectName}
          cmOrgId={crmOrgId}
          initialObjectName={initialObjectName}
          onChange={(fields) => {
            onChange({
              ...action,
              actionParams: {
                ...action.actionParams,
                [_objectProperty]: {
                  ...(_get(action, `actionParams[${_objectProperty}]`) || {}),
                  _fields: fields,
                },
              },
            });
          }}
          fields={_get(action, `actionParams[${_objectProperty}]._fields`) || []}
        />
      </Box>
    </FormControl>
  );
};
