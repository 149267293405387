import DeployButton from './DeployButton';
import { useSelector } from 'react-redux';
import {
  selectDefaultCreationEnvironment,
  selectProductionCrmOrg,
} from '../../pages/environments/environmentsReducer';
import useCheckManagedPackage from '../install-managed-package/useCheckManagedPackage';
import usePaywall from '../../dashboard/paywall/usePaywall';
import { useState } from 'react';
import AutomationDeployPaywallBlock from './AutomationDeployPaywallBlock';
import { CompleteSfSetupForDeployDialog } from './complete-sf-setup/CompleteSfSetupForDeployDialog';
import { useDeployButtonContext } from './DeployButtonContext';
import { useShouldRequestToDeploy } from './useShouldRequestToDeploy';
import { useIsSetupCompleted } from '../../../hooks/useIsSetupCompleted';

/**
 * Props for the ConnectedDeployButton component.
 */
export interface ConnectedDeployButtonProps {
  /**
   * Callback function triggered when the deploy or save button is clicked.
   * @param crmOrgIds - An array of CRM organization IDs. If the array is empty, the operation is a save. otherwise, it is a deploy.
   * @returns A promise that resolves when when the save is completed.
   */
  onDeployOrSave: (crmOrgIds: string[]) => Promise<any>;

  /**
   * Specifies whether the button is disabled.
   */
  disabled?: boolean;

  /**
   * Callback function triggered when the diff is shown.
   */
  onShowDiff?: () => void;

  /**
   * Specifies whether the button is associated with a paywall.
   */
  isWithPaywall: boolean;
}

const ConnectedDeployButton = ({
  onDeployOrSave,
  disabled,
  onShowDiff,
  isWithPaywall,
}: ConnectedDeployButtonProps) => {
  const { entityNamePlural, entityName } = useDeployButtonContext();
  const productionEnvironment = useSelector(selectProductionCrmOrg);
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const { getIsManagedPackagedInstalledWithRecheck } = useCheckManagedPackage();
  const { isPaywallActive } = usePaywall();
  const [isPaywallDialogOpened, setIsPaywallDialogOpened] = useState(false);
  const [completeSfSetupDialog, setCompleteSfSetupDialog] = useState<{
    isOpen: boolean;
    crmOrgIdsToDeploy: string[];
  }>();
  const canDeploy = useIsSetupCompleted();

  const shouldRequestToDeploy = useShouldRequestToDeploy();

  const deployOrShowDialog = async (crmOrgIds: string[]) => {
    if (isWithPaywall && isPaywallActive) {
      setIsPaywallDialogOpened(true);
      return;
    }
    await getIsManagedPackagedInstalledWithRecheck({
      withPermissionsCheck: true,
    });
    if (canDeploy) {
      await onDeployOrSave(crmOrgIds);
    } else {
      setCompleteSfSetupDialog({
        isOpen: true,
        crmOrgIdsToDeploy: crmOrgIds,
      });
    }
  };

  return (
    <>
      {isPaywallDialogOpened && (
        <AutomationDeployPaywallBlock
          closeDialog={() => setIsPaywallDialogOpened(false)}
          entityNamePlural={entityNamePlural}
        />
      )}
      <DeployButton
        isRequestToDeploy={shouldRequestToDeploy}
        onShowDiff={onShowDiff}
        defaultCreationEnvironmentName={defaultCreationEnvironment?.name}
        prodEnvironmentName={productionEnvironment?.name}
        defaultIsProd={defaultCreationEnvironment?.id === productionEnvironment?.id}
        onDeployDefaultClick={
          defaultCreationEnvironment
            ? async () => await deployOrShowDialog([defaultCreationEnvironment.id])
            : undefined
        }
        onDeployBothClick={
          defaultCreationEnvironment && productionEnvironment
            ? async () =>
                await deployOrShowDialog([defaultCreationEnvironment.id, productionEnvironment.id])
            : undefined
        }
        onSaveClick={async () => await onDeployOrSave([])}
        disabled={disabled}
      />
      {completeSfSetupDialog?.isOpen && (
        <CompleteSfSetupForDeployDialog
          cancelDialog={async () => {
            setCompleteSfSetupDialog(undefined);
          }}
          entityName={entityName}
          onCompleteItemsCallback={async () => {
            onDeployOrSave(completeSfSetupDialog.crmOrgIdsToDeploy);
            setCompleteSfSetupDialog(undefined);
          }}
          title={`Your ${entityName} was saved 🎉`}
        />
      )}
    </>
  );
};

export default ConnectedDeployButton;
