// Do not put any dispatcher here.
// Only pure API calls
// The Facades should mimic exactly the corresponding API.

import { useSweepApi } from '../sweep';
import { AssignmentGroup } from '../../reducers/assignmentGroupTypes';
import { useCallback } from 'react';
import { assignFieldLabelsFromSummary } from '../../sweep-fields/sweepFieldsLabelsReducer';
import { useDispatch } from 'react-redux';
import { AxiosResponse } from 'axios';

interface AssignmentGroupsResponse {
  groups: AssignmentGroup[];
  sweepFields: SweepFieldsSummaryMap;
}

interface AssignmentGroupResponse {
  group: AssignmentGroup;
  sweepFields: SweepFieldsSummaryMap;
}

export const useAssignmentGroupsApiFacade = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();
  return {
    get_assignmentGroups: useCallback(async () => {
      const { data }: AxiosResponse<AssignmentGroupsResponse> = await sweepApi.get(
        `/assignment_groups`,
      );
      dispatch(assignFieldLabelsFromSummary(data));

      return data;
    }, [dispatch, sweepApi]),

    get_assignmentGroup: useCallback(
      async (groupId: string) => {
        const { data }: AxiosResponse<AssignmentGroupResponse> = await sweepApi.get(
          `/assignment_groups/${groupId}`,
        );
        dispatch(assignFieldLabelsFromSummary(data));

        return data;
      },
      [dispatch, sweepApi],
    ),

    put_assignmentGroup: useCallback(
      async (groupId: string, assignmentGroup: AssignmentGroup) => {
        const response = await sweepApi.put(`/assignment_groups/${groupId}`, assignmentGroup);
        return response.data as AssignmentGroup;
      },
      [sweepApi],
    ),
    post_assignmentGroup: useCallback(
      async (assignmentGroupWithoutId: Omit<AssignmentGroup, 'id'>) => {
        const response = await sweepApi.post(`/assignment_groups`, assignmentGroupWithoutId);
        return response.data as AssignmentGroup;
      },
      [sweepApi],
    ),
    delete_assignmentGroup: useCallback(
      async (groupId: string) => {
        const response = await sweepApi.delete(`/assignment_groups/${groupId}`);
        return response.data as AssignmentGroup;
      },
      [sweepApi],
    ),
  };
};
