import { useCallback } from 'react';
import { useConfigurationCanvasContext } from '../../ConfigurationCanvasContext';
import {
  getObjectNameFromRecordTypeId,
  getRecordTypeNameFromRecordTypeId,
} from '../../../../Automations/helper';
import cloneDeep from 'lodash/cloneDeep';
import { telemetry } from '../../../../../telemetry';

export const useRecordTypesVisibilityToggle = () => {
  const { setCanvasFunnelMap, canvasFunnelMap } = useConfigurationCanvasContext();

  const onVisibilityChange = useCallback(
    (recordTypeId: string) => {
      const isRt = canvasFunnelMap.recordTypes[recordTypeId];
      const _canvasFunnelMap = cloneDeep(canvasFunnelMap);

      if (isRt) {
        const isHidden = canvasFunnelMap.recordTypes[recordTypeId].isHidden;
        _canvasFunnelMap.recordTypes[recordTypeId].isHidden = !isHidden;
        setCanvasFunnelMap(_canvasFunnelMap);
        return;
      }

      const objectName = getObjectNameFromRecordTypeId(recordTypeId);
      const recordTypeName = getRecordTypeNameFromRecordTypeId(recordTypeId);

      const funnel = Object.values(canvasFunnelMap.funnelsData).find(
        (funnel) =>
          funnel.recordType.name === recordTypeName && funnel.recordType.objectName === objectName,
      );

      if (funnel && _canvasFunnelMap.funnels[funnel.id]) {
        const isHidden = canvasFunnelMap.funnels[funnel.id].isHidden;
        _canvasFunnelMap.funnels[funnel.id].isHidden = !isHidden;
        setCanvasFunnelMap(_canvasFunnelMap);
      } else {
        telemetry.captureError(
          new Error(`Cannot set isHidden, rt wasnt found in funnels and recordTypes`),
          {
            recordTypeId,
            canvasFunnelMap,
          },
        );
      }
    },
    [setCanvasFunnelMap, canvasFunnelMap],
  );

  return {
    onVisibilityChange,
  };
};
