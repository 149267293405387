import { SortOrder } from '../components/common/types';

export const sortByLastName = (a: string, b: string, order: SortOrder) => {
  const aNameArray = a.split(' ');
  const bNameArray = b.split(' ');
  const aLastName = aNameArray[aNameArray.length - 1];
  const bLastName = bNameArray[bNameArray.length - 1];
  return order === SortOrder.ASC
    ? aLastName.localeCompare(bLastName)
    : bLastName.localeCompare(aLastName);
};
