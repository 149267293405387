import { Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { ActionsRequireMp, getInstallManagedPackageText } from './getInstallManagedPackageText';

const InstallManagedPackageContent = ({
  action,
  textAlign,
  color,
}: {
  action: ActionsRequireMp;
  textAlign?: 'center';
  color?: string;
}) => {
  return (
    <Stack gap={2.5}>
      <Stack textAlign={textAlign}>
        <Typography variant="body" color={color}>
          {getInstallManagedPackageText(action)}
        </Typography>
        <Typography variant="body" color={color}>
          Don't forget to Install for "All Users" and complete the permission set group assignment.
        </Typography>
      </Stack>
    </Stack>
  );
};

export default InstallManagedPackageContent;
