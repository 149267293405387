import { colors } from '@sweep-io/sweep-design';

/**
 * This function extracts all color names that have shade 100
 * and returns random color name.
 *
 * @returns colorName
 */

export function getRandomColorName() {
  const colorNames = Object.keys(colors);
  const only100ColorNames = colorNames.filter((colorName) =>
    colors[colorName as keyof typeof colors].hasOwnProperty(100),
  );

  const generateRandomNumber = (max: number) => {
    return Math.floor(Math.random() * max);
  };

  const randomColorIdx = generateRandomNumber(only100ColorNames.length);

  return only100ColorNames[randomColorIdx];
}
