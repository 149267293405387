import { Button, styled } from '@mui/material';
import { colors, __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';

// Please don't reuse this component. It's only used in the CanvasFilters component.
// The moment you need to use this component in another component, please move it to a design system button.
export const ClearButton = styled(Button)({
  height: 32,
  borderRadius: 4,
  borderColor: colors.grey[300],
  '&.MuiButtonBase-root:hover': {
    borderColor: colors.blue[500],
  },
  '&, &:hover': {
    color: colors.blue[500],
  },
  ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold'],
});

ClearButton.defaultProps = {
  variant: 'squared',
  color: 'secondary',
  size: 'small',
};
