import { useCallback } from 'react';
import {
  CS_TEAM,
  SDR_TEAM,
  USA_EAST_COAST,
  USA_WEST_COAST,
} from '@server/first-time-experience.consts';
import _ from 'lodash';
import useTerritories from './useTerritories';
import { useAssignmentGroups } from '../components/pages/assignments/members/useAssignmentGroups';

const useCompileTemplate = () => {
  const territories = useTerritories();
  const { assignmentGroups } = useAssignmentGroups();

  const compileTemplate = useCallback(
    (template: Partial<AutomationStructureNew>) => {
      const defaultAssignmentGroup = assignmentGroups ? assignmentGroups[0] : undefined;
      const defaultTerritory = territories ? territories[0] : undefined;

      //Product spec: if the item is not found (was deleted or renamed), arbitrarily select the 1st

      const sdrTeam =
        assignmentGroups?.find((group) => group.name === SDR_TEAM) ?? defaultAssignmentGroup;
      const csTeam =
        assignmentGroups?.find((group) => group.name === CS_TEAM) ?? defaultAssignmentGroup;
      const usaEastCoastTerritory =
        territories?.find((territory) => territory.name === USA_EAST_COAST) ?? defaultTerritory;
      const usaWestCoastTerritory =
        territories?.find((territory) => territory.name === USA_WEST_COAST) ?? defaultTerritory;

      _.templateSettings.interpolate = /"<%=([\s\S]+?)%>"/g;
      const compiled = _.template(JSON.stringify(template));
      const assignment = compiled({
        sdrTeamId: sdrTeam?.id ? `"${sdrTeam?.id}"` : 'null',
        csTeamId: csTeam?.id ? `"${csTeam?.id}"` : 'null',
        usaEastCoastTerritoryId: usaEastCoastTerritory?.id
          ? `"${usaEastCoastTerritory?.id}"`
          : 'null',
        usaWestCoastTerritoryId: usaWestCoastTerritory?.id
          ? `"${usaWestCoastTerritory?.id}"`
          : 'null',
      });

      return JSON.parse(assignment) as Partial<AutomationStructureNew>;
    },
    [assignmentGroups, territories],
  );

  return compileTemplate;
};

export default useCompileTemplate;
