import type { ObjectWithPillsMap } from '../dependencies/types';

export const headerTitleLabels: {
  [key in keyof ObjectWithPillsMap]: string;
} = {
  favorites: 'Favorites',
  customObjects: 'Custom Objects',
  cpqObjects: 'Salesforce CPQ',
  standardObjects: 'Standard Objects',
};
