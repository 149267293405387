import { WithConfigurationCanvas } from '../configuration-canvas/WithConfigurationCanvas';
import { useConfigurationCanvas } from '../configuration-canvas/public/useConfigurationCanvas';
import { useSelector } from 'react-redux';
import { selectCrmOrgAutomations } from '../../../reducers/global/automationReducers';
import { VisibilityLayers } from '../../../types/enums/VisibilityLayers';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import useAutomationsCanvasInteractions from './useAutomationsCanvasInteractions';
import { AutomationFormVariant } from '../../../types/enums/AutomationFormVariant';
import { VisibilityElementMap } from '../configuration-canvas/types';
import useEntitiesToHighlight from './useEntitiesToHighlight';
import { ConfigurationCanvasAutomationsPanel } from './CanvasAutomationsPanel';
import { AutomationAutomationsContextProvider } from '../../alerts/AutomationAutomationsContextProvider';

const automationsPageVisibilityMap: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
  [VisibilityLayers.SWEEP_AUTOMATIONS]: true,
  [VisibilityLayers.FUNNEL_SWEEP_AUTOMATIONS]: true,
};
const visibilityElements: VisibilityElementMap = {
  type: 'map',
  visibilityMap: automationsPageVisibilityMap,
};

export const CanvasAutomationPage = () => {
  const { canvasCrmOrgId } = useConfigurationCanvas();

  const { onEntityClick } = useAutomationsCanvasInteractions();
  const automationsVariant = AutomationFormVariant.AUTOMATIONS;
  const { entitiesToHighlight } = useEntitiesToHighlight(automationsVariant);
  const automations = useSelector(selectCrmOrgAutomations(canvasCrmOrgId));

  return (
    <WithConfigurationCanvas
      visibilityElements={visibilityElements}
      onFunnelStepClick={onEntityClick}
      onRecordTypeStepClick={onEntityClick}
      onFunnelLabelPillClick={onEntityClick}
      onRecordTypeLabelPillClick={onEntityClick}
      highlightEntities={entitiesToHighlight}
    >
      <AutomationAutomationsContextProvider>
        <ConfigurationCanvasAutomationsPanel automations={automations} />
      </AutomationAutomationsContextProvider>
    </WithConfigurationCanvas>
  );
};
