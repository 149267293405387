import { useCallback, useEffect, useState } from 'react';
import { Box, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import { DateTime } from 'luxon';
import { BaseDialog } from '../../../common/dialogs/BaseDialog';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import SweepSelect from '../../../common/SweepSelect';
import {
  TimezoneSelector,
  getSystemTimezone,
} from '../../../common/timezone-selector/TimezoneSelector';
import { FlexBox } from '../../../common/FlexBox';
import { DatetimeSelector } from '../../../common/rule-builder/selectors/DatetimeSelector';
import { changeDateTimeTimezone } from './helpers';

interface SnoozeDialogProps {
  user: { name: string };
  onConfirm: (endDate: string, timezone: string) => void;
  onClose: () => void;
}

enum SnoozeForOptions {
  EndOfDay = 'endOffDay',
  XDays = 'xDays',
  SpecificDate = 'specificDate',
}

export const SnoozeDialog = ({ user, onConfirm, onClose }: SnoozeDialogProps) => {
  const [snoozeFor, setSnoozeFor] = useState<SnoozeForOptions>(SnoozeForOptions.EndOfDay);
  const [timezone, setTimezone] = useState(() => getSystemTimezone());
  const [numberOfDays, setNumberOfDays] = useState<number>(1);
  const [specificDate, setSpecificDate] = useState<DateTime | null>(() =>
    DateTime.now().endOf('day'),
  );

  const calculateCurrentValue = useCallback(() => {
    switch (snoozeFor) {
      case SnoozeForOptions.EndOfDay: {
        return DateTime.now().setZone(timezone).endOf('day');
      }
      case SnoozeForOptions.XDays: {
        return DateTime.now().setZone(timezone).plus({ days: numberOfDays }).endOf('day');
      }
      case SnoozeForOptions.SpecificDate: {
        if (specificDate) {
          return changeDateTimeTimezone(specificDate, timezone);
        }
      }
    }
    return undefined;
  }, [numberOfDays, snoozeFor, specificDate, timezone]);
  const [currentValue, setCurrentValue] = useState(() => calculateCurrentValue());

  useEffect(() => {
    setCurrentValue(calculateCurrentValue());
  }, [calculateCurrentValue]);

  const showNumberOfDays = snoozeFor === SnoozeForOptions.XDays;
  const showSpecificDate = snoozeFor === SnoozeForOptions.SpecificDate;

  return (
    <BaseDialog title={`Snooze ${user.name}`} open={true} onClose={() => {}}>
      <DialogTitle sx={{ paddingBottom: 3 }}>Snooze {user.name}</DialogTitle>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Box width="436px">
          <FlexBox flexDirection="column" gap={2}>
            <FlexBox flexDirection="column" gap={3}>
              <SweepSelect
                SelectProps={{
                  placeholder: 'select an item',
                  value: snoozeFor,
                  onChange: (e) => setSnoozeFor(e.target.value as SnoozeForOptions),
                }}
                label={'With label'}
                FormControlProps={{ fullWidth: true }}
              >
                <MenuItem value={SnoozeForOptions.EndOfDay} disableRipple>
                  End of day
                </MenuItem>
                <MenuItem value={SnoozeForOptions.XDays} disableRipple>
                  X Days
                </MenuItem>
                <MenuItem value={SnoozeForOptions.SpecificDate} disableRipple>
                  Specific Date
                </MenuItem>
              </SweepSelect>
              {showNumberOfDays && (
                <TextField
                  placeholder="Enter number of days"
                  type="number"
                  value={numberOfDays}
                  onChange={(e) => setNumberOfDays(Number(e.target.value))}
                  inputProps={{ min: 1 }}
                />
              )}
              {showSpecificDate && (
                <DatetimeSelector
                  minDateTime={DateTime.now()}
                  shouldBeDisabled={false}
                  onChange={(dateString) => {
                    setSpecificDate(dateString);
                  }}
                  value={specificDate}
                />
              )}
            </FlexBox>
            <FlexBox gap={1} alignItems="center">
              <Typography variant="caption" color={colors.grey[800]}>
                Timezone:
              </Typography>
              <TimezoneSelector timezone={timezone} onChange={setTimezone} />
            </FlexBox>
          </FlexBox>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <Box>
          {currentValue && (
            <Typography variant="body" color={colors.grey[700]}>
              Ends at {currentValue?.setZone(timezone).toFormat('MMM dd, hh:mma')}
            </Typography>
          )}
        </Box>
        <Box display="flex" gap="12px">
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={!currentValue}
            onClick={() => {
              currentValue && onConfirm(currentValue?.toISO() || '', timezone);
            }}
          >
            Snooze
          </Button>
        </Box>
      </DialogActions>
    </BaseDialog>
  );
};
