import { camelCase } from 'lodash';
import { useMemo } from 'react';
import {
  SweepCanvasNode,
  SweepCanvasEdge,
  SweepCanvasGroup,
  CanvasElementType,
} from '../../multi-canvas/canvasTypes';
import * as pillsHelper from '../pillsHelper';
import { SweepCanvasReactFlowEdgeDataType } from '../../multi-canvas/edges/SweepCanvasReactFlowEdgeDataType';
import { PillsMap } from '../../canvas-pills/canvasPillsReducer';
import { VisibilityMap } from '../../../types/VisibilityTypes';

export const useCalculateHubspotNodes = ({
  funnelMap,
  pills,
  visibilityMap,
}: {
  funnelMap: FunnelMap;
  pills?: Partial<PillsMap>;
  visibilityMap: VisibilityMap;
}) => {
  return useMemo(() => {
    const nodes: SweepCanvasNode[] = [];
    const edges: SweepCanvasEdge[] = [];
    const groups: SweepCanvasGroup[] = [];

    Object.entries(funnelMap.hubspotFunnels)
      .filter(([id]) => funnelMap.hubspotFunnelsData[id])
      .forEach(([hubspotFunnelId, hubspotFunnelPosition]) => {
        const hubspotFunnelData = funnelMap.hubspotFunnelsData[hubspotFunnelId];

        if (!hubspotFunnelData) return undefined;

        groups.push({
          id: hubspotFunnelId,
          name: hubspotFunnelData.label || '',
          objectType: '__hubspot__',
          position: hubspotFunnelPosition.position,
          objectLabel: hubspotFunnelData.objectName,
          startingIcon: 'hubspot',
          pills: pillsHelper.hubspotFunnelPills({ pills, visibilityMap }),
        });

        hubspotFunnelData.leadingField.values.forEach((value, idx) => {
          nodes.push({
            id: camelCase(`${hubspotFunnelId}-${value}-${idx}`),
            name: value,
            type: CanvasElementType.REGULAR,
            objectType: '__hubspot__',
            position: {
              column: idx,
              row: 0,
            },
            parentId: hubspotFunnelId,
          });
        });
        nodes.forEach((node, idx, nodes) => {
          if (idx < nodes.length - 1) {
            edges.push({
              id: `${hubspotFunnelId}-${node.id}-edge`,
              source: node.id,
              target: nodes[idx + 1].id,
              data: {
                type: SweepCanvasReactFlowEdgeDataType.SIMPLE,
              },
            });
          }
        });
      });
    return {
      nodes,
      edges,
      groups,
    };
  }, [funnelMap.hubspotFunnels, funnelMap.hubspotFunnelsData, pills, visibilityMap]);
};
