import { SWEEP_FIELD_NAME } from '../../../constants/fieldsManagementConsts';
import { SharingMode, RollupOperator, TextAggregationDelimiter } from '@server/rollup.consts';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import {
  formatNumberPropsToField,
  isNumberLikeFieldType,
} from '../funnel-map-flow/dialogs/fields-management/helpers';
import { RollupDto, RollupFiltersValues, RollupForTable } from './rollupTypes';
import { filterItemsBySearch } from '../../../lib/filterItemsBySearch';
import { DeployStatusForTable } from '../../../types/enums/DeployStatusForTable';
import { FieldOrigin } from '../../../types/enums/FieldOrigin';
import { RollupWizardData } from './rollup-wizard/rollupWizardTypes';

//convert "rollupField" to "wizardData"
export const getWizardData = (field: SweepField): RollupWizardData => {
  const { fieldType, objectName, rollup } = field;
  const {
    filter,
    childAggregatedFieldId,
    relationshipFieldId,
    operation,
    childObject,
    sharingMode,
    schedule,
    delimiter,
  } = rollup ?? {};

  return {
    isCreateFromExisting: false,
    createEditField: field,
    fieldType: fieldType as RollupFieldType,
    parentObject: objectName,
    childAggregatedFieldId,
    relationshipFieldId,
    operation,
    childObject,
    ...filter,
    sharingMode,
    schedule: schedule ? schedule : undefined,
    delimiter,
  };
};

export const getRollupApiBody = (values: RollupWizardData): SweepField => {
  //we know that all fields exist, because of the wizard validation, the defaults are for typescript

  const {
    isCreateFromExisting,
    existingField,
    createEditField,
    parentObject,
    fieldType,
    childAggregatedFieldId,
    relationshipFieldId = '',
    operation = RollupOperator.CNT,
    criteria,
    criteriaLogic = '',
    isFilterEnabled = false,
    sharingMode = SharingMode.SystemMode,
    childObject = '',
    schedule,
    delimiter,
  } = values;

  const rollup: Rollup = {
    childObject,
    relationshipFieldId,
    operation,
    childAggregatedFieldId,
    sharingMode,
    filter:
      isFilterEnabled && criteria && criteria.length > 0 && criteriaLogic
        ? {
            criteria,
            criteriaLogic,
          }
        : null,
    schedule: schedule ? schedule : null,
    delimiter,
  };

  const apiBodyDefaults: SweepField = {
    objectName: parentObject,
    fieldType: SweepFieldTypes.Number,
    properties: {},
    isRequired: false,
  };

  if (isCreateFromExisting) {
    return { ...apiBodyDefaults, ...existingField, rollup };
  } else {
    return {
      ...apiBodyDefaults,
      ...createEditField,
      properties:
        createEditField?.properties && isNumberLikeFieldType(fieldType)
          ? handleDefaultValue(formatNumberPropsToField(createEditField?.properties))
          : createEditField?.properties ?? {},
      rollup,
    };
  }
};

type RollupMap = { [rollupId: string]: RollupForTable };

export const setSfFieldName = (fieldName: string, objectName: string) =>
  `${objectName ? objectName + '.' : ''}${fieldName}`;

export const getDataFromSFFieldName = (sfFieldName?: string) => sfFieldName?.split('.') ?? [];

export const getRollupsListForUi = (rollupsRaw?: RollupDto[]): RollupForTable[] | undefined => {
  if (!rollupsRaw) {
    return undefined;
  }
  const rollupsMap: RollupMap = rollupsRaw.reduce((res: RollupMap, rollup) => {
    const additionalPropsForSort = {
      objectName: rollup.rollupField.objectName,
      sweepFieldName: rollup.rollupField.sweepFieldName,
      fieldType: rollup.rollupField.fieldType,
      sfFieldName: getDataFromSFFieldName(rollup.rollupField.sfFieldName)?.[1],
    };
    if (res[rollup.rollupId]) {
      //If the rollupId already exist in the map, it means this rollup should be presented with status "Pending"
      //the newItem should get the versionId from the "Draft"
      let newItem;
      if (res[rollup.rollupId].status === 'Deployed') {
        newItem = {
          ...rollup,
          ...additionalPropsForSort,
          deployedVersionId: res[rollup.rollupId].versionId,
          status: DeployStatusForTable.Pending,
        };
      } else {
        newItem = {
          ...res[rollup.rollupId],
          ...additionalPropsForSort,
          deployedVersionId: rollup.versionId,
          status: DeployStatusForTable.Pending,
        };
      }
      res[rollup.rollupId] = newItem;
    } else {
      res[rollup.rollupId] = { ...rollup, ...additionalPropsForSort };
    }
    return res;
  }, {});
  return Object.values(rollupsMap);
};

export const getFilteredRollups = (
  rollups: RollupForTable[],
  selectedFilters: RollupFiltersValues,
) => {
  const filteredRollups = rollups.filter((rollup) => {
    const isObjectNameMatch =
      selectedFilters.objectNames.length === 0 ||
      selectedFilters.objectNames.includes(rollup.rollupField.objectName);
    const isFieldTypeMatch =
      selectedFilters.fieldTypes.length === 0 ||
      selectedFilters.fieldTypes.includes(rollup.rollupField.fieldType);
    const isDeployStatusMatch =
      selectedFilters.deployStatuses.length === 0 ||
      // @ts-ignore
      selectedFilters.deployStatuses.includes(rollup.status);
    return isObjectNameMatch && isFieldTypeMatch && isDeployStatusMatch;
  });

  return filterItemsBySearch<RollupForTable>(
    filteredRollups,
    selectedFilters.searchText,
    (rollup) => [rollup.rollupField[SWEEP_FIELD_NAME], rollup.rollupField.description].join(' '),
  );
};

const handleDefaultValue = (properties: SweepFieldProperties) => {
  const updatedProperties = { ...properties };
  delete updatedProperties.defaultValue; //"defaultValue" is relevant only for checkbox
  return {
    ...updatedProperties,
    defaultValueFormula: '0',
  };
};

export const RollupSupportedFieldTypes = [
  SweepFieldTypes.Date,
  SweepFieldTypes.Number,
  SweepFieldTypes.DateTime,
  SweepFieldTypes.Time,
  SweepFieldTypes.Currency,
  SweepFieldTypes.Percent,
  SweepFieldTypes.Text,
  SweepFieldTypes.TextArea,
  SweepFieldTypes.LongTextArea,
] as const;

export type RollupFieldType = (typeof RollupSupportedFieldTypes)[number];
export const RollupSupportedFieldOrigins = [FieldOrigin.SF_CUSTOM, FieldOrigin.SWEEP_CUSTOM];

export const getDelimiterOptions = (fieldType?: SweepFieldTypes, operator?: RollupOperator) => {
  if (!fieldType) return [];
  const delimiters: TextAggregationDelimiter[] = [];
  switch (fieldType) {
    case SweepFieldTypes.Text:
    case SweepFieldTypes.TextArea:
    case SweepFieldTypes.LongTextArea:
      if (
        operator &&
        [RollupOperator.CONCATENATE, RollupOperator.CONCATENATE_DISTINCT].includes(operator)
      ) {
        delimiters.push(
          TextAggregationDelimiter.COMMA,
          TextAggregationDelimiter.PIPE,
          TextAggregationDelimiter.SEMICOLON,
          TextAggregationDelimiter.SLASH,
        );
      }
      break;

    default:
      break;
  }
  return delimiters.map((operation) => ({
    value: operation,
    label: getDelimiterLabelMap()[operation],
  }));
};

export const getOperationOptions = (fieldType?: SweepFieldTypes) => {
  if (!fieldType) return [];
  const operations: RollupOperator[] = [RollupOperator.MAX, RollupOperator.MIN];
  switch (fieldType) {
    case SweepFieldTypes.Percent:
    case SweepFieldTypes.Currency:
      operations.push(RollupOperator.AVG, RollupOperator.SUM);
      break;
    case SweepFieldTypes.Number:
      operations.push(RollupOperator.AVG, RollupOperator.SUM, RollupOperator.CNT);
      break;

    case SweepFieldTypes.Text:
    case SweepFieldTypes.TextArea:
    case SweepFieldTypes.LongTextArea:
      operations.push(RollupOperator.CONCATENATE, RollupOperator.CONCATENATE_DISTINCT);
      break;

    default:
      break;
  }
  return operations.map((operation) => ({
    value: operation,
    label: getOperationsLabelMap(fieldType)[operation],
  }));
};

export const getOperationsLabelMap = (fieldType: SweepFieldTypes) => {
  const isDate = [SweepFieldTypes.Date, SweepFieldTypes.DateTime, SweepFieldTypes.Time].includes(
    fieldType,
  );
  return {
    [RollupOperator.AVG]: 'Avg',
    [RollupOperator.SUM]: 'Sum',
    [RollupOperator.CNT]: 'Count',
    [RollupOperator.MAX]: isDate ? 'Latest' : 'Max',
    [RollupOperator.MIN]: isDate ? 'Earliest' : 'Min',
    [RollupOperator.CONCATENATE]: 'Contactenate',
    [RollupOperator.CONCATENATE_DISTINCT]: 'Contactenate distinct',
  };
};

export const getDelimiterLabelMap = () => {
  return {
    [TextAggregationDelimiter.COMMA]: 'Comma (,)',
    [TextAggregationDelimiter.SEMICOLON]: 'Semicolon (;)',
    [TextAggregationDelimiter.PIPE]: 'Pipe (|)',
    [TextAggregationDelimiter.SLASH]: 'Slash (/)',
  };
};

export const getFormulaType = (type?: RollupFieldType) => {
  if (!type) return;
  switch (type) {
    case SweepFieldTypes.Date:
      return SweepFieldTypes.FormulaDate;
    case SweepFieldTypes.Number:
      return SweepFieldTypes.FormulaNumber;
    case SweepFieldTypes.DateTime:
      return SweepFieldTypes.FormulaDateTime;
    case SweepFieldTypes.Time:
      return SweepFieldTypes.FormulaTime;
    case SweepFieldTypes.Currency:
      return SweepFieldTypes.FormulaCurrency;
    case SweepFieldTypes.Percent:
      return SweepFieldTypes.FormulaPercent;
    case SweepFieldTypes.Text:
      return SweepFieldTypes.FormulaText;
    case SweepFieldTypes.TextArea:
      return SweepFieldTypes.FormulaText;
    case SweepFieldTypes.LongTextArea:
      return SweepFieldTypes.FormulaText;
    default:
      return;
  }
};

export const isFormula = (type?: SweepFieldTypes) => type?.startsWith('Formula');
