import { Box } from '@mui/material';
import { Button, colors } from '@sweep-io/sweep-design';

//TODO add this as a button variant to the design system SWEEP-4721
const PinkButton = (props: any) => {
  const paddingSx = props.size === 'tiny' ? { padding: '3px 12px' } : {};
  return (
    <Box
      sx={{
        button: {
          ...paddingSx,
          backgroundColor: '#FF56EE',
          '&:hover': {
            backgroundColor: colors.gum[300],
          },
          '&:active': {
            backgroundColor: colors.gum[500],
          },
        },
      }}
    >
      <Button {...props} />
    </Box>
  );
};

export default PinkButton;
