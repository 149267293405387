import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { BasedStepNode } from './BasedStepNode';
import { RFNodeData } from '../../canvasTypes';

const NurturingBucketStep = ({ data, dragging, id, selected }: NodeProps<RFNodeData>) => (
  <BasedStepNode id={id} selected={selected} dragging={dragging} {...data} isNurturingBucket />
);

const _memoNurturingBucketStep = memo(NurturingBucketStep);

export { _memoNurturingBucketStep as NurturingBucketStep };
