import { FunnelFieldLabels } from '../../../constants/fieldsManagementConsts';
import { ObjectTypeChip } from '../../common/ObjectTypeChip';
import { DataTableRow } from '../../common/table/TableTypes';
import {
  ConfigurationType,
  DependenciesContentViewType,
  NewDependencyProps,
} from '../dependencies/types';
import { ChevronButton } from './shared/ChevronButton';
import { TableWithHeader, TableWithHeaderProps } from './shared/TableWithHeader';
import { TableTypography } from './shared/helpers';
import { ReactNode, useCallback } from 'react';

interface FieldTableProps extends Pick<TableWithHeaderProps, 'headerVariant' | 'tableHeader'> {
  items: FieldTableItem[];
  onFieldClick: (props: NewDependencyProps) => void;
  shortListViewCounter?: number;
  activeItemId?: string;
  clearHistory?: boolean;
}

interface FieldTableItem {
  id: string;
  name: string;
  label: string | ReactNode;
  objectName: string;
  objectLabel: string;
  type: ConfigurationType;
  isRollup: boolean;
}

const fieldsColumns = [
  { field: 'label', headerName: 'Label', width: '40%' },
  { field: 'objectName', headerName: 'Object', width: '22%' },
  { field: 'type', headerName: 'Type', width: '22%' },
  { field: '__actions', headerName: '', width: '10%' },
];

const renderRows = ({ items, activeItemId }: Pick<FieldTableProps, 'items' | 'activeItemId'>) => {
  return items.map((row) => ({
    id: row.id,
    label: <TableTypography>{row.label}</TableTypography>,
    name: row.name,
    isRowActive: activeItemId === row.id,
    _objectNameStr: row.objectName, //needed in onRowClick
    objectName: (
      <ObjectTypeChip label={row.objectLabel || row.objectName} objectType={row.objectName} />
    ), //displayed in table
    type: (
      <TableTypography>
        {FunnelFieldLabels[row.type as unknown as SweepFieldTypes] ?? row.type}
      </TableTypography>
    ), //TS is complaining without unknown but the conversion is correct
    __actions: <ChevronButton />,
    isClickable: true,
    isRollup: row.isRollup,
  }));
};

export const FieldsTable = ({
  items,
  onFieldClick,
  clearHistory,
  activeItemId,
  ...props
}: FieldTableProps) => {
  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: DataTableRow) => {
      onFieldClick({
        id: row.id ?? '',
        name: row.name,
        objectName: row._objectNameStr,
        parentType: row.isRollup ? ConfigurationType.rollups : ConfigurationType.fields,
        clearHistory,
        contentType: DependenciesContentViewType.default,
      });
    },
    [onFieldClick, clearHistory],
  );

  return (
    <TableWithHeader
      {...props}
      key="fields"
      columns={fieldsColumns}
      rows={renderRows({ items, activeItemId })}
      onRowClick={onRowClick}
    />
  );
};
