import { AssignmentGroup } from '../reducers/assignmentGroupTypes';
import { AssignmentGroupModel } from './AssignmentGroupModel';

export class AssignmentGroupsListModel {
  _assignmentGroupsList: AssignmentGroup[];
  constructor(assignmentGroupsList: AssignmentGroup[]) {
    this._assignmentGroupsList = assignmentGroupsList;
  }

  public assignmentGroupById(_id: string) {
    const assignmentGroup = this._assignmentGroupsList.find(({ id }) => id === _id);
    if (assignmentGroup) {
      return new AssignmentGroupModel(assignmentGroup);
    }
  }

  public upsertAssignmentGroup(assignmentGroup: AssignmentGroup) {
    if (!assignmentGroup.id) {
      throw new Error('upsertAssignmentGroup must contain an id');
    }
    const existingGroupIdx = this._assignmentGroupsList.findIndex(
      ({ id }) => id === assignmentGroup.id,
    );
    if (existingGroupIdx >= 0) {
      this._assignmentGroupsList[existingGroupIdx] = assignmentGroup;
    } else {
      this._assignmentGroupsList.push(assignmentGroup);
    }
  }

  public removeGroupById(_id: string) {
    const idx = this._assignmentGroupsList.findIndex(({ id }) => id === _id);
    if (idx >= 0) {
      this._assignmentGroupsList.splice(idx, 1);
    }
  }
}
