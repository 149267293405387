import { useCallback } from 'react';
import { useSweepApi } from '../sweep';
import { useDispatch } from 'react-redux';
import {
  fetchRequestChangesSuccess,
  updateRequestChangeStatus,
} from '../../reducers/requestChangesApiReducer';

const useRequestChangesApiFacade = () => {
  const sweepApi = useSweepApi();
  const dispatch = useDispatch();

  const createRequestChange = useCallback(
    async ({
      crmOrgId,
      funnelId,
      note,
      funnelMapId,
    }: {
      crmOrgId: string;
      funnelId: string;
      note?: string;
      funnelMapId: string;
    }) => {
      const { data } = await sweepApi.post(`/request-changes`, {
        crmOrgId,
        funnelId,
        funnelMapId,
        note,
      });
      return data;
    },
    [sweepApi],
  );

  const getRequestChanges = useCallback(
    async (funnelIds: string[]) => {
      if (!funnelIds || !funnelIds.length) {
        return;
      }
      const { data } = await sweepApi.get(`/request-changes?funnelIds=${funnelIds.join(',')}`);
      dispatch(fetchRequestChangesSuccess(data));
      return data;
    },
    [sweepApi, dispatch],
  );

  const approveChanges = useCallback(
    async (requestChangeId: string, note?: string) => {
      if (!requestChangeId) {
        return;
      }
      const { data } = await sweepApi.post(`/request-changes/actions/approve`, {
        requestChangeId,
        note,
      });
      dispatch(updateRequestChangeStatus({ requestChangeId }));
      return data;
    },
    [dispatch, sweepApi],
  );

  const rejectChanges = useCallback(
    async (requestChangeId: string, note?: string) => {
      if (!requestChangeId) {
        return;
      }
      const { data } = await sweepApi.post(`/request-changes/actions/reject`, {
        requestChangeId,
        note,
      });
      dispatch(updateRequestChangeStatus({ requestChangeId }));
      return data;
    },
    [dispatch, sweepApi],
  );

  return {
    createRequestChange,
    getRequestChanges,
    approveChanges,
    rejectChanges,
  };
};
export { useRequestChangesApiFacade };
