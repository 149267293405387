import { SweepLogo, HubspotLogo, SalesforceLogo } from './integrationIcons';
import { Box, Stack, styled } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import React from 'react';

const boxStyle = {
  borderRadius: '12px',
  border: `1px solid ${colors.grey[300]}`,
};

const IconSquare = styled(Box)({
  ...boxStyle,
  width: '48px',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
});

export const ConnectionLine = styled(Box)({
  height: '1px',
  background: colors.grey[300],
});

export type ConnectSweepToPossibleConnections = 'Salesforce' | 'Hubspot';

const connectTpIconMap: Record<ConnectSweepToPossibleConnections, React.ReactNode> = {
  Salesforce: <SalesforceLogo />,
  Hubspot: <HubspotLogo />,
};

export const ConnectSweepTo = ({
  connectTo,
}: {
  connectTo: React.ReactNode | ConnectSweepToPossibleConnections;
}) => {
  const icon =
    typeof connectTo === 'string'
      ? connectTpIconMap[connectTo as ConnectSweepToPossibleConnections]
      : connectTo;

  return (
    <Stack direction="row" alignItems="center">
      <IconSquare>
        <SweepLogo />
      </IconSquare>
      <ConnectionLine width="25px" />
      <IconSquare>{icon}</IconSquare>
    </Stack>
  );
};
