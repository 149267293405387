// A function to calculate indentation
export const getIndentation = (line: string) => {
  let indentationCount = 0;
  for (const char of line) {
    if (char === ' ') {
      indentationCount++;
    } else {
      break;
    }
  }
  return indentationCount;
};
