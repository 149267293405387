import { IconButton, Tooltip } from '@sweep-io/sweep-design';
import * as Icons from '@sweep-io/sweep-design/dist/icons';
import { usePanels } from '../../../../panels/PanelsContext';
import { importFunnelPanelType } from './importFunnelPanelType';
import { useTurnOffCommentsModeFirst } from '../../../canvas-pages/united-canvas/left-menu/useTurnOffCommentsModeFirst';
import { useConfigurationCanvasContext } from '../../../configuration-canvas/ConfigurationCanvasContext';

export const ImportFunnelButton = () => {
  const { commentsOn } = useConfigurationCanvasContext();
  const { isPanelOpen, maybeOpenPanel } = usePanels();
  const isImportFunnelPanelOpen = !commentsOn && isPanelOpen(importFunnelPanelType);
  const turnOffCommentsModeFirst = useTurnOffCommentsModeFirst();

  return (
    <Tooltip title="Import from Salesforce">
      <span>
        <IconButton
          variant="flat"
          onClick={() => {
            turnOffCommentsModeFirst(() => {
              maybeOpenPanel({ panelType: importFunnelPanelType });
            });
          }}
          pressed={!!isImportFunnelPanelOpen}
        >
          <Icons.CloudArrowDown variant="large" />
        </IconButton>
      </span>
    </Tooltip>
  );
};
