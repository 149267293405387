import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { useCrmOrgs } from '../../environments/useCrmOrgs';
import { useCallback, useState } from 'react';
import { FetchStatus } from '@server/fetch_status.type';

import { EnvironmentTypes } from '../../environments/EnvironmentTypeEnum';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { ACTIONS_EVENTS, SIGN_UP_EVENTS, GET_STARTED_EVENTS } from '../../../../services/events';
import { CrmOrgConnectingError } from '../../environments/connectCrmSessionHelper';
import { ConnectCrmOrgStateEnum } from '../../environments/ConnectCrmOrgStateEnum';
import { getCrmOrgConnectionErrorData } from './getCrmOrgConnectionErrorData';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';

export type GetEventToSend = (
  isSandbox: boolean,
) => SIGN_UP_EVENTS | ACTIONS_EVENTS | GET_STARTED_EVENTS;

interface AbstractConnectCrmOrgState {
  type: 'fetchStatus' | 'connectState';
}

interface ConnectCrmOrgStateFetchStatus extends AbstractConnectCrmOrgState {
  type: 'fetchStatus';
  fetchStatus: FetchStatus;
}
interface ConnectCrmOrgStateConnectState extends AbstractConnectCrmOrgState {
  type: 'connectState';
  connectState: ConnectCrmOrgStateEnum;
}

interface ConnectCrmOrgStateNotConnected extends ConnectCrmOrgStateConnectState {
  type: 'connectState';
  connectState: ConnectCrmOrgStateEnum.NotConnected | ConnectCrmOrgStateEnum.Connecting;
}

interface ConnectCrmOrgStateConnected extends ConnectCrmOrgStateConnectState {
  connectState: ConnectCrmOrgStateEnum.Connected;
}
interface ConnectCrmOrgStateError extends ConnectCrmOrgStateConnectState {
  connectState: ConnectCrmOrgStateEnum.Error;
  error: CrmOrgConnectingError;
}

export type ConnectCrmOrgState =
  | ConnectCrmOrgStateFetchStatus
  | ConnectCrmOrgStateNotConnected
  | ConnectCrmOrgStateConnected
  | ConnectCrmOrgStateError;

const useConnectToSf = ({
  getEventToSend,
  onFinish,
  forceProduction,
}: {
  getEventToSend?: GetEventToSend;
  forceProduction: boolean;
  onFinish?: () => void;
}) => {
  const sendBiEvent = useSendBiEvent();
  const { addNotification } = useSweepNotifications();
  const { connectOrg } = useCrmOrgs();
  const [connectState, setConnectState] = useState<ConnectCrmOrgState>({
    type: 'connectState',
    connectState: ConnectCrmOrgStateEnum.NotConnected,
  });
  const [isSandbox, setIsSandbox] = useState(false);

  const setIsSandboxCb = useCallback((value: boolean) => {
    setIsSandbox(value);
  }, []);

  const onSuccess = useCallback(
    (crmOrg: CrmOrg, isSandboxValue: boolean) => {
      const defaultEvent = isSandboxValue
        ? ACTIONS_EVENTS.sfSandboxConnect
        : ACTIONS_EVENTS.sfProdConnect;
      const eventToSend = getEventToSend ? getEventToSend(isSandboxValue) : defaultEvent;
      sendBiEvent({
        name: eventToSend,
        props: {
          instanceUrl: crmOrg.instanceUrl,
          connectedUser: crmOrg.connectedUser,
        },
      });
      onFinish && onFinish();
      setConnectState({
        type: 'fetchStatus',
        fetchStatus: crmOrg.fetchStatus,
      });
    },
    [getEventToSend, onFinish, sendBiEvent],
  );

  const onConnectStartHandler = useCallback(
    async ({
      isSandboxValue,
      forceMain,
      isDevelopment,
      withNotification,
    }: {
      isSandboxValue: boolean;
      forceMain?: boolean;
      isDevelopment?: boolean;
      withNotification?: boolean;
    }) => {
      const isSandboxValueToUse = isSandboxValue ?? isSandbox;
      setConnectState({
        type: 'connectState',
        connectState: ConnectCrmOrgStateEnum.Connecting,
      });
      const connectedResult = await connectOrg({
        type: isSandboxValueToUse
          ? EnvironmentTypes.Sandbox
          : isDevelopment
            ? EnvironmentTypes.Development
            : EnvironmentTypes.Production,
        forceMain,
      });

      if (connectedResult.result === 'error') {
        setConnectState({
          type: 'connectState',
          connectState: ConnectCrmOrgStateEnum.Error,
          error: connectedResult.error,
        });
        const { description, title } = getCrmOrgConnectionErrorData({
          error: connectedResult.error,
        });
        if (withNotification) {
          addNotification({
            message: title,
            details: description.join(' '),
            variant: SweepNotificationVariant.Error,
            keepOpen: true,
          });
        }
      } else {
        onSuccess(connectedResult.crmOrg, isSandboxValueToUse);
      }
      return connectedResult;
    },
    [isSandbox, connectOrg, addNotification, onSuccess],
  );

  //If we should connect Production, can skip the first step
  useRunOnceWhenTruthy(() => {
    onConnectStartHandler({ isSandboxValue: false });
  }, forceProduction);

  return { connectState, isSandbox, setIsSandboxCb, onConnectStartHandler };
};

export default useConnectToSf;
