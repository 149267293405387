import { useCallback } from 'react';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { FUNNEL_NAME_MAX_LENGTH } from '../../../../../constants';
import { useIsFunnelNameUnique } from '../../../../../hooks/useIsFunnelNameUnique';

const useFunnelSettingsForm = ({
  onSubmit,
  funnels,
}: {
  onSubmit: (values: any) => void;
  funnels?: Funnel[];
}) => {
  const isFunnelNameUnique = useIsFunnelNameUnique();

  const getValidationSchema = useCallback(
    () =>
      yup.object().shape({
        funnels: yup.array().of(
          yup.object().shape({
            name: yup
              .string()
              .trim(`Funnel name can't contain whitespace in beginning or end`)
              .strict(true)
              .matches(/^[a-zA-Z_][a-zA-Z0-9_]*/, `Funnel name must start with a letter`)
              .required('Funnel name is a required field')
              .max(40, `Funnel name can have maximum of ${FUNNEL_NAME_MAX_LENGTH} characters`)
              .test(
                'name',
                'There is already a funnel with this name for this object',
                async (value, context) => {
                  const funnelId = context.parent.id;
                  const objectName = context.parent.funnelDetails?.leadingObject.objectName;
                  const oldFunnel = funnels?.find((funnel) => funnel.id === funnelId);
                  const isNameChanged = oldFunnel?.name !== value;

                  if (!value || !objectName) return false;
                  return isNameChanged
                    ? await isFunnelNameUnique({ funnelId, name: value, objectName })
                    : true;
                },
              ),
          }),
        ),
      }),
    [isFunnelNameUnique, funnels],
  );

  const formik: FormikProps<{}> = useFormik({
    initialValues: {},
    validationSchema: getValidationSchema(),
    onSubmit,
  });

  const isSubmitted = Boolean(formik.submitCount);

  return { ...formik, isSubmitted };
};

export default useFunnelSettingsForm;
