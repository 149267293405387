import * as React from "react";
const SvgSlackLogo2 = (props) => /* @__PURE__ */ React.createElement("svg", { width: 32, height: 32, viewBox: "0 0 32 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_1339_107522)" }, /* @__PURE__ */ React.createElement("path", { d: "M8.71437 19.8014C8.71437 21.3342 7.47552 22.573 5.94271 22.573C4.40991 22.573 3.17106 21.3342 3.17106 19.8014C3.17106 18.2685 4.40991 17.0297 5.94271 17.0297H8.71437V19.8014ZM10.1002 19.8014C10.1002 18.2685 11.339 17.0297 12.8718 17.0297C14.4047 17.0297 15.6435 18.2685 15.6435 19.8014V26.7305C15.6435 28.2633 14.4047 29.5021 12.8718 29.5021C11.339 29.5021 10.1002 28.2633 10.1002 26.7305V19.8014Z", fill: "#E01E5A", style: {
  fill: "#E01E5A",
  fill: "color(display-p3 0.8784 0.1176 0.3529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M12.8733 8.67307C11.3405 8.67307 10.1017 7.43422 10.1017 5.90141C10.1017 4.36861 11.3405 3.12976 12.8733 3.12976C14.4061 3.12976 15.645 4.36861 15.645 5.90141V8.67307H12.8733ZM12.8733 10.0799C14.4061 10.0799 15.645 11.3187 15.645 12.8515C15.645 14.3844 14.4061 15.6232 12.8733 15.6232H5.92318C4.39037 15.6232 3.15153 14.3844 3.15153 12.8515C3.15153 11.3187 4.39037 10.0799 5.92318 10.0799H12.8733Z", fill: "#36C5F0", style: {
  fill: "#36C5F0",
  fill: "color(display-p3 0.2118 0.7725 0.9412)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M23.9804 12.8515C23.9804 11.3187 25.2192 10.0799 26.7521 10.0799C28.2849 10.0799 29.5237 11.3187 29.5237 12.8515C29.5237 14.3844 28.2849 15.6232 26.7521 15.6232H23.9804V12.8515ZM22.5946 12.8515C22.5946 14.3844 21.3557 15.6232 19.8229 15.6232C18.2901 15.6232 17.0513 14.3844 17.0513 12.8515V5.90141C17.0513 4.36861 18.2901 3.12976 19.8229 3.12976C21.3557 3.12976 22.5946 4.36861 22.5946 5.90141V12.8515Z", fill: "#2EB67D", style: {
  fill: "#2EB67D",
  fill: "color(display-p3 0.1804 0.7137 0.4902)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M19.8229 23.9588C21.3557 23.9588 22.5946 25.1977 22.5946 26.7305C22.5946 28.2633 21.3557 29.5021 19.8229 29.5021C18.2901 29.5021 17.0513 28.2633 17.0513 26.7305V23.9588H19.8229ZM19.8229 22.573C18.2901 22.573 17.0513 21.3342 17.0513 19.8014C17.0513 18.2685 18.2901 17.0297 19.8229 17.0297H26.7731C28.3059 17.0297 29.5447 18.2685 29.5447 19.8014C29.5447 21.3342 28.3059 22.573 26.7731 22.573H19.8229Z", fill: "#ECB22E", style: {
  fill: "#ECB22E",
  fill: "color(display-p3 0.9255 0.6980 0.1804)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_1339_107522" }, /* @__PURE__ */ React.createElement("rect", { width: 26.6667, height: 26.6667, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, transform: "translate(3.00342 3.00342)" }))));
export default SvgSlackLogo2;
