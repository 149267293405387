import { Grid, Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import React, { useCallback, useRef, useState } from 'react';
import { SelectAvatar } from '../../avatar/SelectAvatar';
import { ResetPasswordButton } from './ResetPasswordButton';
import { useAuth0Api } from '../../../apis/sweep';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';
import SweepTextField from '../../common/SweepTextField';
import FieldLabel from '../../common/FieldLabel';
import useUserInfo from '../../../hooks/useUserInfo';
import { UserInfo } from '../../../types/UserInfoTypes';

const UserProfileTab = ({
  user,
  currentUser,
  setCurrentUser,
  isValid,
}: {
  user: UserInfo;
  currentUser: UserInfo;
  setCurrentUser: (user: UserInfo) => any;
  isValid: boolean;
}) => {
  const { email, canResetPassword } = user || {};

  const authApi = useAuth0Api();
  const nameInput = useRef<HTMLInputElement>(null);
  const { addNewImage } = useUserInfo();

  const sendResetPasswordEmail = useCallback(async (): Promise<any> => {
    const options: any = {
      method: 'POST',
      url: `/dbconnections/change_password`,
      header: { 'content-type': 'application/json' },
      data: {
        client_id: import.meta.env.VITE_AUTH0_CLIENT_ID,
        email,
        connection: 'Username-Password-Authentication',
      },
    };
    await authApi.request(options);
  }, [email, authApi]);

  const handleInputKeyDown = (event: React.KeyboardEvent, element: HTMLInputElement | null) => {
    event.stopPropagation();
    if (event.key === 'Enter' && element) {
      element.blur();
    }
  };

  // In order to get the ref right after mount. This pattern is explained here:
  // https://medium.com/welldone-software/usecallback-might-be-what-you-meant-by-useref-useeffect-773bc0278ae
  const [jobTitleInput, setJobTitleInput] = useState<HTMLInputElement | null>(null);
  const jobTitleInputCb = useCallback((node: HTMLInputElement) => {
    setJobTitleInput(node);
  }, []);

  const updateUserData = (key: keyof UserInfo, value?: any) => {
    const _user = Object.assign({}, currentUser);

    if (_user) {
      _user[key] = value;
      setCurrentUser(_user);
    }
  };

  //Focus on jobTitle in case it is empty
  useRunOnceWhenTruthy(
    () => {
      if (jobTitleInput) {
        jobTitleInput.focus();
      }
    },
    !Boolean(currentUser?.jobTitle) && Boolean(jobTitleInput),
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        gap="16px"
        alignItems="center"
        sx={{ paddingTop: '0' }}
        data-testid="user-details"
      >
        <Box sx={{ alignSelf: 'baseline' }}>
          <Typography ellipsis variant="h2" data-testid="org-name">
            My profile
          </Typography>
        </Box>

        <SelectAvatar
          avatar={currentUser}
          onChange={async (avatar) => {
            let newUser = { ...currentUser };
            newUser.emoji = undefined;
            newUser.imageUrl = undefined;
            newUser = { ...newUser, ...avatar };

            if (avatar.imageUploadBase64) {
              const imageUrl = await addNewImage(avatar.imageUploadBase64 ?? undefined);
              if (imageUrl) {
                newUser.imageUrl = imageUrl;
              }
            }

            setCurrentUser(newUser);
          }}
        />

        <Grid container flexDirection="column" gap="16px">
          <SweepTextField
            label="Full Name"
            id="full-name"
            FormControlProps={{ fullWidth: true }}
            TextFieldProps={{
              placeholder: 'Add your full name',
              helperText: isValid ? '' : `Name must have at least one character`,
              error: !isValid,
              inputProps: {
                ref: nameInput,
                'data-testid': 'full-name-input',
              },
              onKeyDownCapture: (e) => handleInputKeyDown(e, nameInput.current),
            }}
            value={currentUser?.name}
            onChange={(e) => updateUserData('name', e?.target.value)}
          />

          <SweepTextField
            label="Job Title"
            id="user_jobTitle"
            FormControlProps={{ fullWidth: true }}
            TextFieldProps={{
              placeholder: 'Add your job title',
              onKeyDownCapture: (e) => handleInputKeyDown(e, jobTitleInput),
              inputProps: {
                ref: jobTitleInputCb,
                'data-testid': 'job-title-input',
              },
            }}
            value={currentUser?.jobTitle}
            onChange={(e) => updateUserData('jobTitle', e?.target.value)}
          />

          {canResetPassword && email && (
            <Box width="100%" position="relative">
              <FieldLabel label="Password" />
              <ResetPasswordButton onConfirmCb={sendResetPasswordEmail} email={email} />
            </Box>
          )}
        </Grid>
      </Box>
    </>
  );
};
export { UserProfileTab };
