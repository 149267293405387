import { useCallback, useState, useEffect } from 'react';
import { useRefForMultipleRuleBuilders } from '../../../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import _isEqual from 'lodash/isEqual';
import _cloneDeep from 'lodash/cloneDeep';

const useSingleGatePanel = ({
  exitCriteria,
  onGateSave,
  setGateDirtyState,
}: {
  exitCriteria: SweepExitCriteria;
  onGateSave: (updatedExitCriteria: SweepExitCriteria) => void;
  setGateDirtyState: (isDirty: boolean) => void;
}) => {
  const [localState, setLocalState] = useState(exitCriteria);
  const gateRef = useRefForMultipleRuleBuilders();

  //reflect external/saved changes
  useEffect(() => {
    setLocalState(exitCriteria);
  }, [exitCriteria]);

  const isDirty = !_isEqual(localState, exitCriteria);

  useEffect(() => {
    if (setGateDirtyState) {
      setGateDirtyState(isDirty);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const discardChanges = () => {
    setLocalState(exitCriteria);
    setTimeout(() => {
      gateRef.current?.triggerValidation();
    }, 0);
  };

  const saveChanges = async () => {
    const errors = gateRef.current?.triggerValidation();
    if (errors?.length) {
      return;
    }
    onGateSave(localState);
  };

  const onChangeCondition = useCallback(
    (newSweepCondition: SweepCondition) => {
      setLocalState((prevState) => {
        const newState = _cloneDeep(prevState);
        Object.keys(newSweepCondition).forEach((k) => {
          (newState as any)[k] = newSweepCondition[k as keyof SweepCondition];
        });

        if (!newSweepCondition.criteria.length) {
          newState.isAutoMoveToNextStage = false;
        }
        return newState;
      });
    },
    [setLocalState],
  );

  const onChangeAutoMove = useCallback(
    (checked: boolean) =>
      setLocalState((prevState) => ({
        ...prevState,
        isAutoMoveToNextStage: checked,
      })),
    [setLocalState],
  );

  return {
    gateRef,
    localState,
    onChangeAutoMove,
    onChangeCondition,
    discardChanges,
    saveChanges,
  };
};

export default useSingleGatePanel;
