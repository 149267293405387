import * as React from "react";
const SvgSearch = (props) => /* @__PURE__ */ React.createElement("svg", { width: 31, height: 31, viewBox: "0 0 31 31", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "Group 4" }, /* @__PURE__ */ React.createElement("path", { id: "Rectangle 1459", d: "M15.8756 18.7163L18.5615 16.0305L28.1295 25.5985C28.8712 26.3402 28.8712 27.5427 28.1295 28.2844C27.3878 29.026 26.1854 29.026 25.4437 28.2844L15.8756 18.7163Z", fill: "url(#paint0_linear_427_125930)", stroke: "#1198FF", style: {
  stroke: "#1198FF",
  stroke: "color(display-p3 0.0667 0.5968 1.0000)",
  strokeOpacity: 1
}, strokeWidth: 1.07985 }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse 61", cx: 12.3027, cy: 11.5388, r: 10.9988, fill: "url(#paint1_linear_427_125930)", stroke: "#1198FF", style: {
  stroke: "#1198FF",
  stroke: "color(display-p3 0.0667 0.5968 1.0000)",
  strokeOpacity: 1
}, strokeWidth: 1.07985 }), /* @__PURE__ */ React.createElement("circle", { id: "Ellipse 62", cx: 12.3026, cy: 11.5399, r: 7.93699, fill: "white", stroke: "#1198FF", style: {
  fill: "white",
  fillOpacity: 1,
  stroke: "#1198FF",
  stroke: "color(display-p3 0.0667 0.5968 1.0000)",
  strokeOpacity: 1
}, strokeWidth: 1.07985 })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("linearGradient", { id: "paint0_linear_427_125930", x1: 16.8368, y1: 16.9916, x2: 28.5113, y2: 28.6661, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#DBEFFF", style: {
  stopColor: "#DBEFFF",
  stopColor: "color(display-p3 0.8583 0.9388 1.0000)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.5, stopColor: "#E7E8FB", style: {
  stopColor: "#E7E8FB",
  stopColor: "color(display-p3 0.9059 0.9098 0.9843)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#E7E8FB", style: {
  stopColor: "#E7E8FB",
  stopColor: "color(display-p3 0.9059 0.9098 0.9843)",
  stopOpacity: 1
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "paint1_linear_427_125930", x1: 12.3027, y1: 0, x2: 12.3027, y2: 23.0775, gradientUnits: "userSpaceOnUse" }, /* @__PURE__ */ React.createElement("stop", { stopColor: "#DBEFFF", style: {
  stopColor: "#DBEFFF",
  stopColor: "color(display-p3 0.8583 0.9388 1.0000)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 0.5, stopColor: "#E7E8FB", style: {
  stopColor: "#E7E8FB",
  stopColor: "color(display-p3 0.9059 0.9098 0.9843)",
  stopOpacity: 1
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, stopColor: "#E7E8FB", style: {
  stopColor: "#E7E8FB",
  stopColor: "color(display-p3 0.9059 0.9098 0.9843)",
  stopOpacity: 1
} }))));
export default SvgSearch;
