import { Box, Skeleton, Stack } from '@mui/material';
import { Typography, Button } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import { useRunOnce } from '../../common/useRunOnce';
import { useMemo, useState } from 'react';
import { CARD_HEIGHT } from '../../../constants';
import useSortWidget from '../../common/useSortWidget';
import { DefaultCardsLayout } from '../../common/CardsGridLayout';
import RestrictedTooltip from '../../common/permissions/RestrictedTooltip';
import usePermission from '../../common/permissions/usePermission';
import { useFunnelMaps } from './useFunnelMaps';
import { FunnelMapCard } from './card/FunnelMapCard';
import times from 'lodash/times';
import { SearchInput } from '../../common/SearchInput';
import sortFunnelMapsListByDate from './helpers/sortFunnelMapsListByDate';
import {
  selectNonPinnedFunnelMaps,
  selectPinnedFunnelMap,
} from '../../../reducers/funnelMapsReducer';
import { useRequestChangesApiFacade } from '../../../apis/facades/useRequestChangesApiFacade';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';
import { Permission } from '@server/permissions';
import EmptyStateWithButton from '../../common/empty-state/EmptyStateWithButton';
import FunnelsEmptyImage from '../../common/empty-state/svgs/funnelsEmpty.svg?react';
import { escapeRegexp } from '../../../lib/escapeRegex';
import { selectHasConnectedCrmOrgs } from '../environments/environmentsReducer';
import useSendBiEvent from '../../../hooks/useSendBiEvent';
import { UI_EVENTS } from '../../../services/events';

const LAST_MODIFIED = 'Last modified';
const LAST_CREATED = 'Last created';

const options = [LAST_MODIFIED, LAST_CREATED];

const showOnlyFirstRowStyle = {
  maxHeight: CARD_HEIGHT,
  overflow: 'hidden',
};

export const ADD_NOT_ALLOWED_TITLE = 'To add new funnel view, please contact your admin.';

const FunnelMapsPage = () => {
  const { funnelMapsList, isLoading } = useSelector(selectNonPinnedFunnelMaps);
  const { fetchFunnelMaps, createNewFunnelMap } = useFunnelMaps();
  const { getRequestChanges } = useRequestChangesApiFacade();

  const hasAnyOrgs = useSelector(selectHasConnectedCrmOrgs);

  const [searchValue, setSearchValue] = useState('');

  const pinnedFunnelMap = useSelector(selectPinnedFunnelMap);

  const permissionStr: Permission[] = ['create:funnel-maps'];
  const [isAllowedButton] = usePermission(permissionStr);

  useRunOnce(() => {
    fetchFunnelMaps({ includeFunnelsData: true });
  });

  useRunOnceWhenTruthy(
    () => {
      const allFunnelMaps = [...funnelMapsList];
      if (pinnedFunnelMap) {
        allFunnelMaps.push(pinnedFunnelMap);
      }

      const funnelIdsSet = allFunnelMaps.reduce((acc, curr) => {
        Object.keys(curr.funnels).forEach((id) => acc.add(id));
        return acc;
      }, new Set<string>());

      getRequestChanges(Array.from(funnelIdsSet));
    },
    funnelMapsList.length > 0 || !!pinnedFunnelMap,
  );

  const { SortWidget, sortBy } = useSortWidget({
    defaultValue: LAST_MODIFIED,
    sortOptions: options,
  });

  const filteredAndSortedFunnelMapsList = useMemo(() => {
    const filteredList = funnelMapsList.filter((funnelMap) => {
      const regexp = new RegExp(escapeRegexp(searchValue), 'gi');
      return funnelMap.name.match(regexp);
    });

    return sortFunnelMapsListByDate({
      list: filteredList,
      sortKey: sortBy === LAST_CREATED ? 'createdAt' : 'updatedAt',
    });
  }, [searchValue, funnelMapsList, sortBy]);

  const sendBiEvent = useSendBiEvent();
  const onAddFunnelClick = () => {
    sendBiEvent({ name: UI_EVENTS.funnelsOpen, props: { mode: 'new' } });
    createNewFunnelMap();
  };

  const isEmpty = !isLoading && funnelMapsList.length === 0 && !pinnedFunnelMap;

  return (
    <>
      <Stack px={5} py={4} data-testid="funnel-maps-page">
        {!isEmpty && (
          <Stack gap={3}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h1">Funnel Editor</Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '24px',
                }}
              >
                {SortWidget}

                <Box>
                  <SearchInput
                    withFixedMagnifyingGlassIcon
                    placeholder="Search"
                    TextFieldProps={{
                      value: searchValue,
                      onChange: (event) => setSearchValue(event.target.value),
                    }}
                    onClearButtonClick={() => setSearchValue('')}
                  />
                </Box>

                <RestrictedTooltip
                  to={permissionStr}
                  notAllowedTitle={ADD_NOT_ALLOWED_TITLE}
                  forceDisallowTitle={
                    hasAnyOrgs
                      ? undefined
                      : 'You need to connect at least one environment to create a new funnel view'
                  }
                >
                  <Button
                    dataTestId="create-new-funnel-button"
                    disabled={!isAllowedButton || !hasAnyOrgs}
                    startIconName={'Plus'}
                    onClick={onAddFunnelClick}
                  >
                    New
                  </Button>
                </RestrictedTooltip>
              </Box>
            </Box>

            {pinnedFunnelMap && <FunnelMapCard funnelMap={pinnedFunnelMap} isPinned={true} />}

            <Box sx={isLoading ? showOnlyFirstRowStyle : {}}>
              <DefaultCardsLayout>
                {isLoading ? (
                  <SkeletonFunnelCards />
                ) : (
                  <>
                    {filteredAndSortedFunnelMapsList.map((funnelMap: FunnelMap) => (
                      <FunnelMapCard key={funnelMap.id} funnelMap={funnelMap} isPinned={false} />
                    ))}
                  </>
                )}
              </DefaultCardsLayout>
            </Box>
          </Stack>
        )}

        {isEmpty && (
          <Stack>
            <Typography variant="h1">Funnel Editor</Typography>
            <Box display="flex" justifyContent="center" pt="6vh">
              <EmptyStateWithButton
                image={<FunnelsEmptyImage />}
                line1="Optimize and create new business processes"
                line2="with funnel editor"
                onClick={onAddFunnelClick}
                permissionString={permissionStr}
                notAllowedTitle={ADD_NOT_ALLOWED_TITLE}
                buttonDisabled={!hasAnyOrgs}
              />
            </Box>
          </Stack>
        )}
      </Stack>
    </>
  );
};

const SkeletonFunnelCards = () => (
  //There is max 6 cards in a row
  <>
    {times(6, String).map((x, index) => (
      <Skeleton
        key={index}
        variant="rectangular"
        animation="wave"
        height={CARD_HEIGHT}
        sx={{ borderRadius: '6px' }}
      />
    ))}
  </>
);

export default FunnelMapsPage;
