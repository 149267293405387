import React, { useState } from 'react';
import useEventListener from '../../common/useEventListener';

export const MousePositionContext = React.createContext({ x: 0, y: 0 });

export const MousePositionProvider = ({ children }: { children: React.ReactNode }) => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  useEventListener('mousemove', (e) => setMousePosition({ x: e.clientX, y: e.clientY }));

  return (
    <MousePositionContext.Provider value={mousePosition}>{children}</MousePositionContext.Provider>
  );
};
