import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';

export interface PillsByFunnelEntity {
  steps: {
    [stepId: string]: number;
  };
  funnels: {
    [funnelId: string]: number;
  };
  objectType: {
    [objectType: string]: number;
  };
  recordType: {
    [recordType: string]: number;
  };
}

export interface PillsMap {
  sweepAutomations: PillsByFunnelEntity;
  alerts: PillsByFunnelEntity;
  assignments: PillsByFunnelEntity;
  scheduledAssignments: PillsByFunnelEntity;
  sdfcAutomations: PillsByFunnelEntity;
  validationRules: PillsByFunnelEntity;
  apex: PillsByFunnelEntity;
  funnelSfcAutomations: PillsByFunnelEntity;
  funnelValidationRules: PillsByFunnelEntity;
  funnelApex: PillsByFunnelEntity;
  dedupe: PillsByFunnelEntity;
  matching: PillsByFunnelEntity;
  hubspotEnrollments: PillsByFunnelEntity;
}

export interface CanvasPillsState {
  pillsMap: PillsMap;
}

const initialState: CanvasPillsState = {
  pillsMap: {
    sweepAutomations: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    alerts: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    assignments: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    scheduledAssignments: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    sdfcAutomations: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    validationRules: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    apex: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    funnelSfcAutomations: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    funnelValidationRules: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    funnelApex: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    dedupe: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    matching: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
    hubspotEnrollments: {
      steps: {},
      funnels: {},
      objectType: {},
      recordType: {},
    },
  },
};

export const canvasPillsSlice = createSlice({
  name: 'canvasPills',
  initialState,
  reducers: {
    setAutomationsPills: (state, action: PayloadAction<PillsByFunnelEntity>) => {
      state.pillsMap.sweepAutomations = action.payload;
    },
    setAlertsPills: (state, action: PayloadAction<PillsByFunnelEntity>) => {
      state.pillsMap.alerts = action.payload;
    },
    setAssignmentPills: (state, action: PayloadAction<PillsByFunnelEntity>) => {
      state.pillsMap.assignments = action.payload;
    },
    setScheduledAssignmentPills: (state, action: PayloadAction<PillsByFunnelEntity>) => {
      state.pillsMap.scheduledAssignments = action.payload;
    },
    setDedupePills: (state, action: PayloadAction<PillsByFunnelEntity>) => {
      state.pillsMap.dedupe = action.payload;
    },
    setMatchingPills: (state, action: PayloadAction<PillsByFunnelEntity>) => {
      state.pillsMap.matching = action.payload;
    },
    setDocumentationPills: (
      state,
      action: PayloadAction<{
        sdfcAutomations: PillsByFunnelEntity;
        validationRules: PillsByFunnelEntity;
        apex: PillsByFunnelEntity;
        funnelSfcAutomations: PillsByFunnelEntity;
        funnelValidationRules: PillsByFunnelEntity;
        funnelApex: PillsByFunnelEntity;
      }>,
    ) => {
      state.pillsMap.sdfcAutomations = action.payload.sdfcAutomations;
      state.pillsMap.validationRules = action.payload.validationRules;
      state.pillsMap.apex = action.payload.apex;
      state.pillsMap.funnelSfcAutomations = action.payload.funnelSfcAutomations;
      state.pillsMap.funnelValidationRules = action.payload.funnelValidationRules;
      state.pillsMap.funnelApex = action.payload.funnelApex;
    },
    setHubspotEnrollmentPills: (state, action: PayloadAction<number>) => {
      state.pillsMap.hubspotEnrollments = {
        funnels: {
          hubspot: action.payload,
        },
        objectType: {},
        recordType: {},
        steps: {},
      };
    },
  },
});

export const {
  setAutomationsPills,
  setAlertsPills,
  setAssignmentPills,
  setDocumentationPills,
  setDedupePills,
  setMatchingPills,
  setScheduledAssignmentPills,
  setHubspotEnrollmentPills,
} = canvasPillsSlice.actions;

export const selectPillsMap = (state: RootState) => state.canvasPills.pillsMap;

export default canvasPillsSlice.reducer;
