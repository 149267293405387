import { Box } from '@mui/material';
import { NextStageTypeOptions } from '../../../types/enums/NextStageTypeOptions';
import { SelectorValueTypes } from '../../../types/enums/SelectorValueTypes';
import { SweepFieldsRuleBuilder } from '../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';

const dummyData: SweepExitCriteria = {
  criteria: [
    {
      criterionId: 'cd93e91b-8019-4fc7-9f2f-ce5c023f159e',
      fieldType: 'Address',
      operator: 'CONTAINS',
      value: '1',
      _fieldIds: ['6bfadae9-8bc8-4801-9f09-516c5e62231f'],
      valueType: SelectorValueTypes.LITERAL,
    },
    {
      criterionId: 'b89872d6-be21-494f-9a97-a1cb3f58dde9',
      fieldType: 'Email',
      operator: 'IS_NULL',
      value: 'false',
      _fieldIds: ['621ff4f6-efca-4a42-a95c-be1153bd7c9c'],
      valueType: SelectorValueTypes.LITERAL,
    },
    {
      criterionId: '8f406253-ae73-43fd-96ed-1edae544bf33',
      fieldType: 'Picklist',
      operator: 'EQUALS',
      value: '1',
      _fieldIds: ['b449e982-c47d-47e5-9aa9-9fd2458ac420'],
      valueType: SelectorValueTypes.LITERAL,
    },
    {
      criterionId: '9ec78c17-d1a2-42c3-b38b-b946c6d50618',
      fieldType: 'Currency',
      operator: 'GREATER_THAN',
      value: '500',
      _fieldIds: ['4b5c4ae3-2119-482b-bfe9-49b40b49547f'],
      valueType: SelectorValueTypes.LITERAL,
    },
    // {
    //     criterionId: "cd93e91b-8019-4fc8-9f2f-ce5c023f159e",
    //     fieldType: "Address",
    //     operator: "CONTAINS",
    //     value: "1",
    //     _fieldIds: [
    //         "6bfadae9-8bc8-4801-9f09-516c5e62231f"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Address"
    //     ]
    // },
    // {
    //     criterionId: "b89872d6-be21-434f-9a97-a1cb3f58dde9",
    //     fieldType: "Email",
    //     operator: "IS_NULL",
    //     value: "false",
    //     _fieldIds: [
    //         "621ff4f6-efca-4a42-a95c-be1153bd7c9c"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Email"
    //     ]
    // },
    // {
    //     criterionId: "8f406253-ae73-43sd-96ed-1edae544bf33",
    //     fieldType: "Picklist",
    //     operator: "EQUALS",
    //     value: "1",
    //     _fieldIds: [
    //         "b449e982-c47d-47e5-9aa9-9fd2458ac420"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Rating"
    //     ]
    // },
    // {
    //     criterionId: "9ec7wc17-d1a2-42c3-b38b-b946c6d50618",
    //     fieldType: "Currency",
    //     operator: "GREATER_THAN",
    //     value: "500",
    //     _fieldIds: [
    //         "4b5c4ae3-2119-482b-bfe9-49b40b49547f"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Annual Revenue"
    //     ]
    // },
    // {
    //     criterionId: "cd93e91b-8019-4fc7-9f2f-ce5c021f159e",
    //     fieldType: "Address",
    //     operator: "CONTAINS",
    //     value: "1",
    //     _fieldIds: [
    //         "6bfadae9-8bc8-4801-9f09-516c5e62231f"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Address"
    //     ]
    // },
    // {
    //     criterionId: "b89875d6-be21-494f-9a97-a1cb3f58dde9",
    //     fieldType: "Email",
    //     operator: "IS_NULL",
    //     value: "false",
    //     _fieldIds: [
    //         "621ff4f6-efca-4a42-a95c-be1153bd7c9c"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Email"
    //     ]
    // },
    // {
    //     criterionId: "1f406253-ae73-43fd-96ed-1edae544bf33",
    //     fieldType: "Picklist",
    //     operator: "EQUALS",
    //     value: "1",
    //     _fieldIds: [
    //         "b449e982-c47d-47e5-9aa9-9fd2458ac420"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Rating"
    //     ]
    // },
    // {
    //     criterionId: "2ec78c17-d1a2-42c3-b38b-b946c6d50618",
    //     fieldType: "Currency",
    //     operator: "GREATER_THAN",
    //     value: "500",
    //     _fieldIds: [
    //         "4b5c4ae3-2119-482b-bfe9-49b40b49547f"
    //     ],
    //     _fieldLabels: [
    //         "Lead",
    //         "Annual Revenue"
    //     ]
    // }
  ],
  _nextStageId: 'bb561efd-f27f-4ea7-a092-4385fe41cf0e',
  criteriaLogic: '1 AND (2 OR 3) AND 4',
  //criteriaLogic: "1 AND 2",
  //criteriaLogic: "(1 AND 2) OR (1 AND 3)",
  //criteriaLogic: "",
  //criteriaLogic: "1 AND (2 OR (3 AND (4 OR 5)))",
  //criteriaLogic: "1 AND (2 OR 3 OR 6 OR 7 ) AND 8",
  //criteriaLogic: "1 AND (2 OR 3 OR (4 AND 2)) AND (6 OR 7) AND 9",
  //criteriaLogic: "1 AND (2 AND (3 AND (4 AND (5 AND (6 AND (7 AND (8 AND (9 AND (1 AND (2 AND (3 AND (4 AND (5 AND (6)))))))))))))) ",
  nextStageType: NextStageTypeOptions.FUNNEL,
  _exitCriteriaId: 'e3e8a8db-1506-4c7a-881c-3a71a776e573',
  isAutoMoveToNextStage: false,
};

const RuleBuilderDemoPage = () => {
  return (
    <>
      <Box sx={{ background: 'pink' }}>
        <Box
          sx={{
            width: '1040px',
            height: '1000px',
            background: '#FFFFFF',
            margin: '30px',
            padding: '30px',
            boxShadow: '-20px 10px 50px rgb(0 0 0 / 10%)',
            borderRadius: '16px',
          }}
        >
          <SweepFieldsRuleBuilder
            sweepCondition={dummyData}
            objectType="Lead"
            onChange={(ar) => {
              console.log(ar);
            }}
            crmOrgId="123"
          />
        </Box>
      </Box>
    </>
  );
};

export { RuleBuilderDemoPage };
