import { Box, FormControl, TextField } from '@mui/material';
import { SweepFieldOperator } from '../sweepFieldsTypesAndOperators';
import { Typography } from '@sweep-io/sweep-design';
import { ActionsMenuActionGrouped } from '../SweepFieldsOperatorSelector';

interface ExpendedOperatorProps {
  element: {
    label: string;
    operator: SweepFieldOperator;
  };
  selectedOperator?: SweepFieldOperator;
  disabled?: boolean;
  operatorValue?: OperatorOffset;
  onOperatorValueChange?: (operatorValue: OperatorOffset) => any;
}
export const getExpendedOperatorDatesApart = ({
  element,
  selectedOperator,
  disabled,
  operatorValue,
  onOperatorValueChange,
}: ExpendedOperatorProps) => {
  const actionsMenuAction: ActionsMenuActionGrouped = {
    isSelected: element.operator === selectedOperator,
    label: element.label,
    value: element.operator,
    isNested: true,
    groupIndex: 0,
    nestedActions: [
      {
        label: (
          <Box display="flex" gap={0.5} alignItems="center">
            <FormControl sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <TextField
                disabled={disabled}
                type={'number'}
                value={operatorValue?.offsetNumber ?? ''}
                onChange={(event) => {
                  event?.stopPropagation();
                  onOperatorValueChange?.({
                    offsetType: 'DAYS',
                    offsetNumber: event.target.value,
                  });
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  sx: {
                    width: 'min-content',
                    height: '40px',
                    minWidth: '80px',
                  },
                }}
                placeholder={'Value'}
              />
              <Typography variant="body">Day(s)</Typography>
            </FormControl>
          </Box>
        ),
        value: 'DISABLE_CLICK',
      },
    ],
  };
  return actionsMenuAction;
};

export const getExpendedOperatorDistance = ({
  element,
  selectedOperator,
  disabled,
  operatorValue,
  onOperatorValueChange,
}: ExpendedOperatorProps) => {
  const actionsMenuAction: ActionsMenuActionGrouped = {
    isSelected: element.operator === selectedOperator,
    label: element.label,
    value: element.operator,
    isNested: true,
    groupIndex: 0,
    nestedActions: [
      {
        label: (
          <Box display="flex" gap={0.5} alignItems="center">
            <FormControl sx={{ flexDirection: 'row', gap: 1, alignItems: 'center' }}>
              <TextField
                disabled={disabled}
                type={'number'}
                value={operatorValue?.offsetNumber ?? ''}
                onChange={(event) => {
                  event?.stopPropagation();
                  onOperatorValueChange?.({
                    offsetType: 'mi',
                    offsetNumber: event.target.value,
                  });
                }}
                InputProps={{
                  inputProps: {
                    min: 0.001,
                    step: 0.001,
                  },
                  sx: {
                    width: 'min-content',
                    height: '40px',
                    minWidth: '80px',
                  },
                }}
                placeholder={'Value'}
              />
              <Typography variant="body">miles</Typography>
            </FormControl>
          </Box>
        ),
        value: 'DISABLE_CLICK',
      },
    ],
  };
  return actionsMenuAction;
};
