import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import undoable, { StateWithHistory } from 'redux-undo';
import { RootState } from '..';

import { thirdPartyCanvasReducer } from './thirdPartyCanvasReducer';
import { sfFunnelReducer } from './sfFunnelReducer';
import { notSetFunnelMap } from '../../utils/notSetFunnelMap';

export type UnitedCanvasReducerState = {
  funnelMap: FunnelMap;
  loaded: boolean;
};

const initialState: UnitedCanvasReducerState = {
  funnelMap: notSetFunnelMap,
  loaded: false,
};

export const unifiedCanvasSlice = createSlice({
  name: 'unitedCanvas',
  initialState,
  reducers: {
    setInitialFunnelMap: (state: UnitedCanvasReducerState, action: PayloadAction<FunnelMap>) => {
      state.funnelMap = action.payload;
      state.loaded = true;
    },
    updateFunnelMapName: (state: UnitedCanvasReducerState, action: PayloadAction<string>) => {
      if (state.funnelMap) {
        state.funnelMap.name = action.payload;
      }
    },
    clearFunnelMap: (state: UnitedCanvasReducerState) => {
      state.funnelMap = notSetFunnelMap;
      state.loaded = false;
    },

    ...thirdPartyCanvasReducer,
    ...sfFunnelReducer,
  },
});

export const {
  // FunnelMap actions
  setInitialFunnelMap,
  clearFunnelMap,
  updateFunnelMapName,

  // Salesforce Funnel actions
  updateFunnelMapSfSettings,
  addSfStage,
  moveSfStages,
  moveSfFunnels,
  connectSfStages,
  addSfFunnel,
  addMultipleSfFunnels,
  removeFunnel,
  setExitCriteria,
  removeExitCriteriaById,
  applyNewStageData,
  removeStage,
  removeStepFunnelConnection,
  updateFunnelDescription,
  updateFunnelName,
  setPlugin,
  removePlugin,

  // ThirdParty Actions
  addThirdPartyFunnelToCanvas,
  addThirdPartyFunnelStageToCanvas,
  moveThirdPartyFunnelStagesOnCanvas,
  moveThirdPartyFunnelsOnCanvas,
  editThirdPartyFunnelOnCanvas,
  connectThirdPartyFunnelSteps,
  removeThirdPartyFunnelStepFunnelConnection,
  removeThirdPartyFunnelFromCanvas,
  renameThirdPartyFunnelStep,
  removeThirdPartyFunnelStep,
} = unifiedCanvasSlice.actions;

export const selectIsFunnelMapEmpty = (state: RootState) => {
  const funnelMap = state.unitedCanvas.present.funnelMap;
  const hasSfFunnels = Boolean(Object.keys(funnelMap?.funnels || []).length);
  const hasThirdPartyFunnels = Boolean(Object.keys(funnelMap?.thirdPartyFunnels || []).length);
  const hasRecordTypeFunnels = Boolean(Object.keys(funnelMap?.recordTypes || []).length);
  const hasHubspotFunnels = Boolean(Object.keys(funnelMap?.hubspotFunnels || []).length);
  const hasFunnels =
    hasSfFunnels || hasThirdPartyFunnels || hasRecordTypeFunnels || hasHubspotFunnels;

  return !hasFunnels;
};

export const selectFunnelMap = (state: RootState) => state.unitedCanvas.present.funnelMap;
export const selectFunnelMapIsLoaded = (state: RootState) => state.unitedCanvas.present.loaded;

export const selectCanUndo = (state: RootState) => state.unitedCanvas.past.length > 1;

export const selectCanRedo = (state: RootState) => state.unitedCanvas.future.length > 0;

export type UnitedCanvasWithHistoryState = StateWithHistory<UnitedCanvasReducerState>;

export default undoable(unifiedCanvasSlice.reducer);
