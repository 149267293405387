import { Divider, List, Box, Popover } from '@mui/material';
import { Notification as NotificationIcon } from '@sweep-io/sweep-design/dist/icons';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useCallback } from 'react';
import {
  NotificationDto,
  selectNotificationsCenterTotalUnread,
  setAllNotificationsAsRead,
} from '../../../reducers/notificationsCenterReducers';
import { useNotificationsFacade } from '../../../apis/facades/useNotificationsFacade';
import { NotificationCenterItem } from './NotificationCenterItem';
import { DASHBOARD_HEADER_HEIGHT } from '../../../constants';

interface NotificationsPopoverProps {
  notifications: NotificationDto[];
  onClose: () => any;
  anchorEl: any;
  isOpen: boolean;
  closeUserMenu?: () => void;
}

export const NotificationsPopover = ({
  onClose,
  notifications,
  anchorEl,
  isOpen,
  closeUserMenu,
}: NotificationsPopoverProps) => {
  const dispatch = useDispatch();

  const { post_notifications_events_read_all } = useNotificationsFacade();
  const totalUnread = useSelector(selectNotificationsCenterTotalUnread);

  const readAllNotifications = useCallback(async () => {
    await post_notifications_events_read_all();
    dispatch(setAllNotificationsAsRead());
  }, [dispatch, post_notifications_events_read_all]);

  const hasNotifications = notifications.length > 0;
  const hasUnread = totalUnread > 0;

  return (
    <Popover
      anchorEl={anchorEl}
      open={isOpen}
      onClose={onClose}
      anchorOrigin={{
        vertical: 38,
        horizontal: -228,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{ maxHeight: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT})` }}
    >
      <Box sx={{ width: '384px', padding: 0, overflow: 'hidden' }}>
        <Box
          height="60px"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            py: 0,
            px: 3,
            alignItems: 'center',
          }}
        >
          <Typography variant="h4">Notifications</Typography>
          {hasNotifications && hasUnread && (
            <Button variant="flat" onClick={readAllNotifications}>
              Mark all as read
            </Button>
          )}
        </Box>
        <Box px={1}>
          <Divider />
        </Box>
        {!hasNotifications && (
          <Box
            sx={{ px: 3, py: '30px', display: 'flex', gap: '12px', alignItems: 'center' }}
            color={colors.grey[800]}
          >
            <NotificationIcon color={colors.grey[800]} variant="large" />

            <Typography variant="body">You don’t have any notifications</Typography>
          </Box>
        )}
        <List disablePadding>
          {notifications.map((notification) => (
            <Fragment key={notification.id}>
              <NotificationCenterItem
                notification={notification}
                onClose={() => {
                  onClose();
                  closeUserMenu?.();
                }}
              />
              <Box px={1}>
                <Divider />
              </Box>
            </Fragment>
          ))}
        </List>
      </Box>
    </Popover>
  );
};
