import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCrmOrgsApiFacade } from '../../../apis/facades/useCrmOrgsApiFacade';
import { setLayouts } from '../../../reducers/documentationReducer';

export const useLayouts = () => {
  const { get_crmOrgObjectParsedLayouts } = useCrmOrgsApiFacade();
  const dispatch = useDispatch();

  const fetchLayoutsData = useCallback(
    async ({ crmOrgId, objectName }: { crmOrgId: string; objectName: string }) => {
      dispatch(setLayouts({ crmOrgId, objectName, isLoading: true, layouts: [] }));

      try {
        const response = await get_crmOrgObjectParsedLayouts({ crmOrgId, objectName });
        dispatch(setLayouts({ crmOrgId, objectName, isLoading: false, layouts: response }));
        return response;
      } catch (error) {
        console.error(
          `Error during layouts fetch for crmorg ${crmOrgId}, objectName ${objectName}`,
          error,
        );
        dispatch(setLayouts({ crmOrgId, objectName, isLoading: false, layouts: [] }));
      }
    },
    [dispatch, get_crmOrgObjectParsedLayouts],
  );

  return { fetchLayoutsData };
};
