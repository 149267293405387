import { Box, FormControl } from '@mui/material';
import {
  SlackActionButtonTextField,
  customButtonSx,
  showOnlyUpdatable,
  SlackActionRow,
} from './common';
import FieldLabel from '../../../common/FieldLabel';
import { SlackFields } from '../SlackFields';
import {
  NestedFieldsField,
  NestedFieldsSelector,
} from '../../../common/fieldsSelectors/NestedFieldsSelector';
import { Plus } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';

export const SlackActionUpdate = ({
  action,
  crmOrgId,
  idx,
  objectName,
  onUpdateAction,
  buttonAction,
}: {
  crmOrgId: string;
  objectName: string;
  onUpdateAction: (action: UpdateRecordSlackAction, index: number) => any;
  action: UpdateRecordSlackAction;
  idx: number;
  buttonAction: React.ReactNode;
}) => {
  return (
    <>
      <SlackActionRow>
        {buttonAction}
        <SlackActionButtonTextField action={action} idx={idx} onUpdateAction={onUpdateAction} />
      </SlackActionRow>
      <SlackActionRow>
        <Box>
          <FormControl
            sx={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <FieldLabel label={'Fields to update'} markLabelAsRequired={true} />
            <SlackFields
              actionFields={action.fields.map((f) => ({
                isRequired: f.isRequired,
                fieldIds: f.fieldIds,
                fieldData: f,
              }))}
              onChange={(_, fields) => {
                onUpdateAction({ ...action, fields }, idx);
              }}
            />
            <Box>
              <NestedFieldsSelector
                filterBy={showOnlyUpdatable}
                disableLookupItemsResolve={true}
                crmOrgId={crmOrgId}
                objectType={objectName}
                customButtonStartIcon={<Plus color={colors.blue[500]} />}
                useCustomButton
                customButtonText="Add field"
                nestedPath={[]}
                customButtonSx={customButtonSx}
                onChange={(sweepField: NestedFieldsField) => {
                  onUpdateAction(
                    {
                      ...action,
                      fields: [
                        ...action.fields,
                        {
                          fieldIds: sweepField.fieldIds,
                          isRequired: false,
                        },
                      ],
                    },
                    idx,
                  );
                }}
              />
            </Box>
          </FormControl>
        </Box>
      </SlackActionRow>
    </>
  );
};
