import { Button } from '@sweep-io/sweep-design';
import WithPermissions from '../../../../../common/WithPermissions';
import { ADD_NOT_ALLOWED_TITLE } from '../../../../funnel-maps/FunnelMapsPage';
import { ButtonSize } from '@sweep-io/sweep-design/dist/components/Button/types';

interface AddFunnelButtonProps {
  onAddClick: () => void;
  isLoading?: boolean;
  startIconName?: string;
  size?: ButtonSize;
}

export const AddFunnelButton = ({
  onAddClick,
  isLoading,
  startIconName,
  size = 'small',
}: AddFunnelButtonProps) => (
  <WithPermissions permissionStr={['edit:funnel-maps']} disabledTooltip={ADD_NOT_ALLOWED_TITLE}>
    <Button
      variant="filled"
      size={size}
      onClick={onAddClick}
      loading={isLoading}
      startIconName={startIconName}
    >
      Add
    </Button>
  </WithPermissions>
);
