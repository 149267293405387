import { SweepFieldsLabelInfoMap } from '../../../sweep-fields/sweepFieldsLabelsReducer';

export enum SlackConstant {
  Slack = 'Slack',
  Salesforce = 'Salesforce',
  Sweep_Created_Slack_Channel = 'Sweep_Created_Slack_Channel',
  Salesforce_Created_Slack_Channel = 'Salesforce_Created_Slack_Channel',
}

export const createSlackRecipientItem = (slackEl: SlackRecipient) => {
  return {
    type: 'Slack' as SlackRecipientType,
    value: slackEl.id,
    _label: slackEl
      ? `${(slackEl as SlackRecipient)?.label} [${(slackEl as SlackRecipient)?.type}]`
      : 'Private Channel',
  };
};
export const createSfRecipientItem = (
  sfEl: SlackRecipientStruct,
  sweepFieldsLabels: SweepFieldsLabelInfoMap,
) => {
  const ids = (sfEl.value as SlackFieldsStruct)?.fieldIds;
  let _label = '';
  if (ids.length > 0) {
    const objectName = sweepFieldsLabels[ids[0]]?.objectName;
    _label = [
      objectName || '',
      ...ids.map((id) => sweepFieldsLabels[id]?.sweepFieldName || ''),
    ].join('.');
  }

  return {
    type: 'Salesforce' as SlackRecipientType,
    value: sfEl.value,
    _label,
  };
};

export const createDealRoomRecipientItem = () => {
  return {
    type: 'Sweep_Created_Slack_Channel' as SlackRecipientType,
    _label: "Record's deal room",
  };
};

export const getConnectSlackTxt = (includeSandboxFT: boolean) =>
  includeSandboxFT
    ? 'Connect to Slack'
    : 'To send Slack notifications, connect Slack to your Production environment';
