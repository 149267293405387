import {
  CASE_STATUS_DEFAULT_METADATA,
  LEAD_DEFAULT_METADATA,
  NURTURING_OPPORTUNITY_STAGE_DEFAULT,
  REGULAR_OPPORTUNITY_STAGE_DEFAULT,
} from '../constants/stageMetadataDefaults';
import { funnelDetailModel } from '../models/funnelDetailModel';
import { SFDCObjectType } from '../types/enums/SFDCObjectType';
import { StageType } from '../types/enums/StageType';

export const getDefaultMetadataValues = (stage: SweepStage, funnelDetails: FunnelDetails) => {
  if (!stage) return undefined;

  let newLeadingFieldMetadata = {};
  const funnelDetailsModel = funnelDetailModel(funnelDetails);
  const { leadingObject } = funnelDetails;

  switch (leadingObject.objectName) {
    case SFDCObjectType.Opportunity:
      const isOpportunityStage = funnelDetailsModel.isLeadingFieldOpportunityStage();

      if (isOpportunityStage) {
        const isNurturingStep = stage.stageType === StageType.NURTURING;

        newLeadingFieldMetadata = isNurturingStep
          ? NURTURING_OPPORTUNITY_STAGE_DEFAULT
          : REGULAR_OPPORTUNITY_STAGE_DEFAULT;
      }
      break;

    case SFDCObjectType.Lead:
      newLeadingFieldMetadata = LEAD_DEFAULT_METADATA;
      break;

    case SFDCObjectType.Case:
      const isCaseStatus = funnelDetailsModel.isLeadingFieldCaseStatus();
      if (isCaseStatus) {
        newLeadingFieldMetadata = CASE_STATUS_DEFAULT_METADATA;
      }
      break;

    default:
      return undefined;
  }

  const res: StageMetadata | undefined = Object.keys(newLeadingFieldMetadata).length
    ? {
        ...newLeadingFieldMetadata,
        stageName: stage.stageName,
        leadingFieldId: leadingObject._leadingFieldId,
      }
    : undefined;

  return res;
};
