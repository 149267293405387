import { useUnhappyStates } from './useUnhappyStates';
import { useLocation } from 'react-router';
import {
  appRoutes,
  PaywallV2Routes,
  RoutesThatRequireDefaultCreationEnvConnection, RoutesThatRequireInitialFetch,
  RoutesToOpenSideMenu
} from '../../../constants/appRoutes';
import { useEffect } from 'react';
import ConnectToSfPage from '../../dashboard/ConnectToSfPage';
import { EnvConnectionErrorPage } from './env-connection-error-page/EnvConnectionErrorPage';
import FetchingPageWithTour from '../../dashboard/fetching-env-page/FetchingPageWithTour';
import { ConnectToSfAndHubspot } from '../canvas-pages/canvas-hubspot-page/unhappy-hubspot-pages/ConnectToSfAndHubspot';
import { HubspotSwitchToProduction } from '../canvas-pages/canvas-hubspot-page/unhappy-hubspot-pages/HubspotSwitchToProduction';
import PaywallPageBlock from '../../dashboard/paywall/sales-activated-trial/PaywallPageBlock';

export const WithUnhappyStates = ({
  children,
  setSideMenuOpen,
}: {
  children: React.ReactNode;
  setSideMenuOpen: (isOpen: boolean) => void;
}) => {
  const {
    connectionError,
    noConnectedOrg,
    isInitialFetch,
    noConnectedHubspot,
    noProductionCrmConnected,
    isCurrentlyProductionOrg,
    isPaywallV2Block,
  } = useUnhappyStates();

  const { pathname } = useLocation();
  const isPaywallPageBlock =
    isPaywallV2Block && PaywallV2Routes.some((route) => pathname.startsWith(route));

  const isRouteRequireDefaultCreationEnv = RoutesThatRequireDefaultCreationEnvConnection.some(
    (route) => pathname.startsWith(route),
  );
  const isRenderConnectionError = isRouteRequireDefaultCreationEnv && connectionError;
  const isRenderConnectToSf = isRouteRequireDefaultCreationEnv && noConnectedOrg;

  const isRouteRequireInitialFetch = RoutesThatRequireInitialFetch.some((route) => pathname.startsWith(route));
  const isRenderFetching = isRouteRequireInitialFetch && isInitialFetch;

  const isRenderUnhappyState =
    isPaywallPageBlock || isRenderConnectToSf || isRenderFetching || isRenderConnectionError;
  const isRenderHubspotConnectPage =
    pathname.startsWith(appRoutes.hubspot.route) && (noProductionCrmConnected || noConnectedHubspot);
  const isRenderHubspotNotInProductionPage =
    pathname.startsWith(appRoutes.hubspot.route) && !isCurrentlyProductionOrg;

  useEffect(() => {
    if (isRenderUnhappyState) {
      setSideMenuOpen(true);
      return;
    }
    const isRouteToOpenSideMenu = RoutesToOpenSideMenu.some((route) => pathname.startsWith(route));
    setSideMenuOpen(isRouteToOpenSideMenu);
  }, [pathname, setSideMenuOpen, isRenderUnhappyState]);

  if (isPaywallPageBlock) {
    return <PaywallPageBlock />;
  }

  if (isRenderHubspotConnectPage) {
    return <ConnectToSfAndHubspot />;
  }

  if (isRenderHubspotNotInProductionPage) {
    return <HubspotSwitchToProduction />;
  }

  if (isRenderConnectToSf) {
    return <ConnectToSfPage />;
  }

  if (isRenderFetching) {
    return <FetchingPageWithTour />;
  }

  if (isRenderConnectionError) {
    return <EnvConnectionErrorPage envConnectionError={connectionError} allowDeleteLastEnv />;
  }

  return children;
};
