import { SelectorValueTypes } from '../../../../types/enums/SelectorValueTypes';
import { AutomationActionRowProps } from '../types';

export const selectFirstValueTypeOption = ({
  _field,
  onChange,
}: Pick<AutomationActionRowProps, 'onChange'> & { _field: AutomationActionField }) =>
  onChange({
    ..._field,
    valueType: SelectorValueTypes.LITERAL,
    value: '',
  });
