import { createContext } from 'react';
import { VisibilityMap } from '../../types/VisibilityTypes';
import { notSetFunnelMap } from '../../utils/notSetFunnelMap';
import { FunnelMapCanvasProps } from './types';

const defaultFunnelMapCanvasProps: FunnelMapCanvasProps = {
  funnelMap: notSetFunnelMap,
  visibilityMap: {} as VisibilityMap,
  objectTypesByName: {},
};

export const FunnelMapCanvasInternalCtx = createContext<FunnelMapCanvasProps>(
  defaultFunnelMapCanvasProps,
);
