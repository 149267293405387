import { Box } from '@mui/material';
import { ReadOnlyAutomationAction } from '@server/read-only-elements.types';
import { Subtext } from './atoms/Subtext';
import { MainSectionWithHeaderWrapper } from './MainSectionWithHeaderWrapper';
import { SubsectionWrapper } from './SubsectionWrapper';
import { ConfigurationTypesWithIcon } from '../../../types';
import { configurationItemTypeToIcon } from './helpers/icons';

interface SettingsProps {
  settings: ReadOnlyAutomationAction[];
  type: ConfigurationTypesWithIcon;
}

export const Settings = ({ settings, type }: SettingsProps) => {
  return (
    <Box>
      {settings.map((setting) => {
        const { value, details, title } = setting;

        return (
          <Box pl={3} pt={title ? 2 : 0} key={'settings_' + value + '_' + title}>
            <MainSectionWithHeaderWrapper
              key={title}
              title={title ?? ''}
              titleIcon={configurationItemTypeToIcon[type]}
              noPaddingBottom
            >
              {value && <Subtext text={value} />}
              <SubsectionWrapper readonlyAutomationActions={details} elementType={type} />
            </MainSectionWithHeaderWrapper>
          </Box>
        );
      })}
    </Box>
  );
};
