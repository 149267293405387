import { MenuProps, Menu, MenuItem } from '@mui/material';
import React from 'react';

export interface GenericMenuEntry {
  label: string | React.ReactNode;
  value: string;
  onClick?: React.MouseEventHandler<HTMLLIElement>;
  disabled?: boolean;
}

interface GenericMenuProps
  extends Pick<MenuProps, 'anchorEl' | 'open' | 'transformOrigin' | 'anchorOrigin' | 'sx'> {
  entries: GenericMenuEntry[];
  onClose: (event: any, reason?: 'backdropClick' | 'escapeKeyDown') => any;
  onClick?: (event: React.MouseEvent<HTMLLIElement>, itemValue: string) => any;
  selectedValue?: string;
}

export const useGenericMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isOpen = Boolean(anchorEl);

  const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return {
    handleClickOpen,
    handleClose,
    isOpen,
    anchorEl,
  };
};

export const GenericMenu = ({
  entries,
  onClose,
  onClick,
  selectedValue,
  sx,
  ...menuProps
}: GenericMenuProps) => {
  return (
    <Menu
      onClose={onClose}
      sx={{
        marginTop: '12px',
        ...sx,
      }}
      PaperProps={{
        sx: {
          borderRadius: '4px',
        },
      }}
      {...menuProps}
    >
      {entries.map((entry, idx) => (
        <MenuItem
          key={idx}
          disableGutters
          disabled={entry.disabled}
          onClick={(event) => {
            onClick?.(event, entry.value);
            entry.onClick?.(event);
            onClose?.(event);
          }}
          selected={selectedValue === entry.value}
        >
          {entry.label}
        </MenuItem>
      ))}
    </Menu>
  );
};
