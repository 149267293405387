import { stageModel } from '../../../../../models/stageModel';

const useCalculateTabCounter = ({ stage }: { stage: SweepStage }) => {
  const calculateGatesTabCounter = (): number => {
    return stageModel(stage).countGates();
  };

  return {
    calculateGatesTabCounter,
  };
};

export default useCalculateTabCounter;
