import { styled } from '@mui/material/styles';
import { ListItem, ListItemProps } from '@mui/material';
import { HEIGHT_DASHBOARD_MENU_ITEM, PADDING_DASHBOARD_MENU } from '../../constants';
import { colors } from '@sweep-io/sweep-design';

interface ExtendedListItemProps extends ListItemProps {
  isSelected: boolean;
}

export const StyledDashboardListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ isSelected }: ExtendedListItemProps) => ({
  paddingLeft: PADDING_DASHBOARD_MENU + 'px',
  paddingRight: PADDING_DASHBOARD_MENU + 'px',
  paddingTop: '11.5px',
  paddingBottom: '11.5px',
  height: HEIGHT_DASHBOARD_MENU_ITEM,
  width: 'auto',
  borderRadius: '4px',
  marginBottom: '4px',

  backgroundColor: isSelected ? colors.grey[100] : 'transparent',
  '&:hover': {
    backgroundColor: colors.grey[100],
    cursor: 'pointer',
  },
}));
