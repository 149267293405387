import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronUp, UserSettings } from '@sweep-io/sweep-design/dist/icons';
import { Radio, Typography } from '@sweep-io/sweep-design';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { AutomationPermissionMode } from '../../../types/enums/AutomationTypes';
import { StyledFormControlLabel, settingsPanelLayout } from './helpers';
import { AUTOMATION_PERMISSION_MODES_TRANSLATION } from '../../Automations/AutomationPermissionModeSelector';

interface SettingsPermissionModePanelProps {
  readonly?: boolean;
  automationDetails?: DeepPartial<AutomationDetailsDtoNew>;
  onChange: (val: AutomationPermissionMode) => any;
}

const SettingsPermissionModePanel = ({
  readonly,
  automationDetails,
  onChange,
}: SettingsPermissionModePanelProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <UserSettings variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Execution mode</Typography>
              <Typography variant="body">Determine the action’s access level</Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <StyledRadioGroup
            className="alignTop"
            sx={{ marginTop: '12px' }}
            value={automationDetails?.permissionMode || AutomationPermissionMode.UserMode}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const val = (event.target as HTMLInputElement).value;
              onChange(val as AutomationPermissionMode);
            }}
          >
            <StyledFormControlLabel
              disabled={readonly}
              value={AutomationPermissionMode.UserMode}
              control={<Radio />}
              label={
                <>
                  <Typography variant="body">
                    {AUTOMATION_PERMISSION_MODES_TRANSLATION[AutomationPermissionMode.UserMode]}
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    Run as the triggering user, following their permissions and security settings.
                  </Typography>
                </>
              }
            />

            <StyledFormControlLabel
              disabled={readonly}
              value={AutomationPermissionMode.SystemMode}
              control={<Radio />}
              label={
                <>
                  <Typography variant="body">
                    {AUTOMATION_PERMISSION_MODES_TRANSLATION[AutomationPermissionMode.SystemMode]}
                  </Typography>
                  <br />
                  <Typography variant="caption">
                    Run with system-level access, bypassing user-specific security restrictions.
                  </Typography>
                </>
              }
            />
          </StyledRadioGroup>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SettingsPermissionModePanel;
