import { useCallback } from 'react';
import {
  openPredefinedStepRulesInSingleObjectScreen,
  setFiltersAndSort,
  setSingleObjectSearchTxt,
} from '../../../../reducers/documentationReducer';
import { useDispatch } from 'react-redux';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';

export const useTabFiltersFunctions = () => {
  const dispatch = useDispatch();

  const setSearchTxt = useCallback(
    (searchTxt: string) => {
      dispatch(setSingleObjectSearchTxt({ searchTxt }));
    },
    [dispatch],
  );

  const registerSortChange = useCallback(
    ({ sortKey }: { sortKey: string }) => {
      dispatch(setFiltersAndSort({ filtersAndSort: { sortKey } }));
    },
    [dispatch],
  );

  const registerPredefinedFilters = useCallback(
    (props: {
      singleObjectApiName: string;
      selectedStepName?: string;
      selectedRecordTypeValue?: string;
      defaultActiveTab: DocumentationTabTypes;
      objects?: ObjectTypeName[];
    }) => {
      dispatch(openPredefinedStepRulesInSingleObjectScreen(props));
    },
    [dispatch],
  );

  return {
    registerSortChange,
    setSearchTxt,
    registerPredefinedFilters,
  };
};
