import { useSelector } from 'react-redux';
import { selectTagConfigurations } from '../../../../../reducers/tagsReducer';
import { useActiveInactive } from '../../../activity-state/useActiveInactive';
import { UserInputTypes } from '@server/user-inputs';
import { ActiveState } from '../../../activity-state/helpers';
import { isItemActive } from '../../../helpers';
import { ConfigurationItemMap } from '../../../configuration-item-tables/shared/helpers';

export const useFilterConfigurations = (tagId: string) => {
  const configurations = useSelector(selectTagConfigurations(tagId));

  const {
    activeState: configurationsActiveState,
    onToggleActiveInactive: onToggleConfigurationsActiveInactive,
  } = useActiveInactive();

  const {
    activeState: userConfigurationsActiveState,
    onToggleActiveInactive: onToggleUserConfigurationsActiveInactive,
  } = useActiveInactive();

  const {
    activeState: collaborationsActiveState,
    onToggleActiveInactive: onToggleCollaborationsActiveInactive,
  } = useActiveInactive();

  const filterActive = (configurations: ConfigurationItemMap, activeState: ActiveState) => {
    return Object.keys(configurations).reduce((acc, key) => {
      const itemsOfKey = configurations[key as keyof ConfigurationItemMap];
      acc[key as keyof ConfigurationItemMap] = itemsOfKey.filter((item: any) =>
        activeState === ActiveState.activeOnly ? isItemActive(item) : item,
      );
      return acc;
    }, {} as ConfigurationItemMap);
  };

  const tagConfigurations = filterActive(
    configurations?.[UserInputTypes.Tags] ?? {},
    configurationsActiveState,
  );

  const configurationsOwned = filterActive(
    configurations?.[UserInputTypes.Owner] ?? {},
    userConfigurationsActiveState,
  );

  const collaboratorConfigurations = filterActive(
    configurations?.[UserInputTypes.Collaborators] ?? {},
    collaborationsActiveState,
  );

  return {
    tagConfigurations,
    configurationsOwned,
    collaboratorConfigurations,

    collaborationsActiveState,
    configurationsActiveState,
    userConfigurationsActiveState,

    onToggleCollaborationsActiveInactive,
    onToggleConfigurationsActiveInactive,
    onToggleUserConfigurationsActiveInactive,
  };
};
