import { SelectChangeEvent, MenuItem, Box } from '@mui/material';
import SweepSelect from '../SweepSelect';
import usePermission from '../permissions/usePermission';
import { Typography, colors } from '@sweep-io/sweep-design';
import { UserDefinedRoleGroupsInterface } from '@server/role-group-interface';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import { sortRoles } from '../../dashboard/role-manager/utils';
import { useState } from 'react';
import { useSweepRoleGroups } from '../useSweepRoleGroups';

interface RolesDropdownProps {
  userId?: string;
  userRole?: UserDefinedRoleGroupsInterface;
  accountRoles: UserDefinedRoleGroupsInterface[];
  onChangeRoleCb: (userId: string, roleId: string) => void;
  onUserRemove?: (userId: string) => void;
  disableRoleDropdown?: boolean;
}

export const RolesDropdown = ({
  userId,
  userRole,
  accountRoles,
  onChangeRoleCb,
  disableRoleDropdown,
  onUserRemove,
}: RolesDropdownProps) => {
  const { getUserRole } = useSweepRoleGroups();
  const [isAllowedBtn, isAllowedBtnDelete] = usePermission(['edit:users:roles', 'delete:users']);
  const [_userRole, setUserRole] = useState(userRole);

  if (disableRoleDropdown || !isAllowedBtn) {
    return (
      <Box sx={{ paddingRight: 1 }}>
        <Typography ellipsis variant="body">
          {userRole?.name}
        </Typography>
      </Box>
    );
  }

  const sorted = sortRoles(accountRoles);

  return (
    <SweepSelect
      removeBorders
      SelectProps={{
        renderValue: (value) => (
          <Typography variant="body">{getUserRole(value as string)?.name}</Typography>
        ),
        value: _userRole?.id ?? '',
        onChange: (event: SelectChangeEvent<unknown>) => {
          event.preventDefault();
          const roleId = event.target.value as string;

          if (roleId === 'remove_user' && userId) {
            onUserRemove && onUserRemove(userId);
            return;
          }

          const role = accountRoles.find((role) => role.id === roleId);
          onChangeRoleCb(userId || '', roleId);
          role && setUserRole(role);
        },
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        },
        sx: {
          maxHeight: '440px',
          overflow: 'hidden',
          '& .MuiSelect-select': {
            paddingRight: 3,
          },
        },
      }}
    >
      {sorted.map((role) => (
        <MenuItem
          key={role.id}
          value={role.id}
          selected={_userRole?.id === role.id}
          sx={{
            width: '299px',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', minWidth: 0 }}>
            <Typography variant="body">{role.name}</Typography>
            <TruncatedTextTooltip variant="caption" color={colors.grey[700]}>
              {role.description}
            </TruncatedTextTooltip>
          </Box>
        </MenuItem>
      ))}

      {isAllowedBtnDelete && onUserRemove && userId && (
        <MenuItem value="remove_user" sx={{ background: colors.white, color: colors.blush[500] }}>
          Remove
        </MenuItem>
      )}
    </SweepSelect>
  );
};
