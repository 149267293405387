import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addThirdPartyFunnelToCanvas,
  selectIsFunnelMapEmpty,
} from '../../reducers/united-canvas/unitedCanvasReducer';
import {
  removeAllTransientFunnelData,
  setTransientFunnel,
} from '../../reducers/multiFunnelFlowNoHistoryReducer';
import { FunnelType } from '../../types/enums/FunnelType';
import { useThirdPartyFunnelsApi } from './useThirdPartyFunnelsApi';
import {
  ThirdPartyFunnelDialogProps,
  FunnelDialogFormValues,
  ThirdPartyFunnelDialog,
} from './ThirdPartyFunnelDialog';

const ConnectedNewThirdPartyFunnelDialog = ({
  isOpen,
  onClose,
}: Omit<
  ThirdPartyFunnelDialogProps,
  'onConfirm' | 'isLoading' | 'confirmButtonText' | 'title'
>) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const isFunnelMapEmpty = useSelector(selectIsFunnelMapEmpty);
  const { createThirdPartyFunnel } = useThirdPartyFunnelsApi();

  const onConfirm = useCallback(
    async ({ name, system, description }: FunnelDialogFormValues) => {
      setIsLoading(true);
      if (isFunnelMapEmpty) {
        const newThirdPartyFunnel = await createThirdPartyFunnel({
          name,
          system,
          description,
          steps: [],
          funnelLinks: [],
        });
        dispatch(removeAllTransientFunnelData());
        dispatch(
          addThirdPartyFunnelToCanvas({
            thirdPartyFunnel: newThirdPartyFunnel,
            position: { row: 0, column: 0 },
          }),
        );
      } else {
        dispatch(
          setTransientFunnel({
            type: FunnelType.THIRD_PARTY,
            importType: 'create',
            name,
            position: { row: 0, column: 0 },
            data: {
              system,
              description,
            },
            isPlacingFunnel: true,
          }),
        );
      }
      setIsLoading(false);
      onClose();
    },
    [createThirdPartyFunnel, dispatch, isFunnelMapEmpty, onClose],
  );

  return (
    <ThirdPartyFunnelDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      isLoading={isLoading}
      confirmButtonText="Create"
      title="New 3rd party funnel"
    />
  );
};

export { ConnectedNewThirdPartyFunnelDialog as NewThirdPartyFunnelDialog };
