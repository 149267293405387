import { noop } from 'lodash';
import React, { createContext, useState } from 'react';

export interface PlacingPlugin {
  type: PluginTypes;
  pluginImage: React.ReactNode;
}

interface ConfiguringPluginType {
  funnelId: string;
  pluginType: PluginTypes;
}

interface PluginsCanvasLayerContextInterface {
  placingPlugin: PlacingPlugin | null;
  setPlacingPlugin: (plugin: PlacingPlugin | null) => void;
  configuringPluginType: ConfiguringPluginType | null;
  setConfiguringPluginType: (plugin: ConfiguringPluginType | null) => void;
}

const PlacingPluginContext = createContext<PluginsCanvasLayerContextInterface>({
  placingPlugin: null,
  setPlacingPlugin: noop,
  configuringPluginType: null,
  setConfiguringPluginType: noop,
});

export const PlacingPluginContextConsumer = PlacingPluginContext.Consumer;

interface PlacingPluginContextProviderProps {
  children: React.ReactNode;
}

export const PlacingPluginContextProvider = ({ children }: PlacingPluginContextProviderProps) => {
  const [placingPlugin, setPlacingPlugin] = useState<PlacingPlugin | null>(null);
  const [configuringPluginType, setConfiguringPluginType] = useState<ConfiguringPluginType | null>(
    null,
  );

  return (
    <PlacingPluginContext.Provider
      value={{
        placingPlugin,
        setPlacingPlugin,
        configuringPluginType,
        setConfiguringPluginType,
      }}
    >
      {children}
    </PlacingPluginContext.Provider>
  );
};

export const usePlacingPluginContext = () => {
  const { setPlacingPlugin, placingPlugin, configuringPluginType, setConfiguringPluginType } =
    React.useContext(PlacingPluginContext);

  const isPlacingPlugin = !!placingPlugin;

  return {
    setPlacingPlugin,
    isPlacingPlugin,
    placingPluginType: placingPlugin?.type,
    configuringPluginType,
    setConfiguringPluginType,
  };
};
