import { Box } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import { ArrowRight } from '@sweep-io/sweep-design/dist/icons';

interface ConditionsHeaderProps {
  fromStageName: string;
  toStageName: string;
}

export const ConditionsHeader = ({ fromStageName, toStageName }: ConditionsHeaderProps) => {
  return (
    <Box sx={{ flex: '0 0 100%' }}>
      <Box sx={{ paddingBottom: '10px' }}>
        <Box display="flex" alignItems="center" gap={0.5}>
          <Typography variant="body-bold">{fromStageName}</Typography>
          <ArrowRight />
          <Typography variant="body-bold">{toStageName}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
