import { Box, ButtonBase, Divider } from '@mui/material';
import { colors, Tooltip, Typography } from '@sweep-io/sweep-design';
import { SparklingWandColorIcon } from '../../../../../icons/generalIcons';
import { useFunnelMapPage } from '../../../funnel-map-flow/useFunnelMapPage';
import { useConfigurationCanvas } from '../../../configuration-canvas/public/useConfigurationCanvas';

export const ReadOnlyCanvasUnitedLeftMenu = () => {
  const { canvasFunnelMap: funnelMap } = useConfigurationCanvas();
  const funnelMapName = funnelMap.name;

  const { onBackCallback } = useFunnelMapPage();

  const gap = '16px';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap,
        padding: `0 ${gap}`,
        backgroundColor: colors.white,
        borderRadius: '6px',
        position: 'relative',
      }}
      data-testid="funnel-flow-left-menu"
    >
      <Tooltip title="Go back">
        <ButtonBase
          onClick={onBackCallback}
          sx={{
            padding: '14px',
            margin: '-14px',
            ':hover svg .hover-color': {
              fill: colors.blue[500],
            },
          }}
        >
          <SparklingWandColorIcon />
        </ButtonBase>
      </Tooltip>

      <Divider orientation="vertical" variant="middle" flexItem />
      <Typography variant="h2" data-testid="funnel-left-menu-name">
        {funnelMapName} - (view only)
      </Typography>
    </Box>
  );
};
