import { Box } from '@mui/material';
import { MoveToNextStage } from '../../../../common/rule-builder/MoveToNextStage';
import { DeleteGateButton } from './DeleteGateButton';
import { SweepFieldsRuleBuilder } from '../../../../common/sweep-condition-rule-builder/SweepConditionRuleBuilder';
import { Button } from '@sweep-io/sweep-design';
import { ConditionsHeader } from '../../../../common/rule-builder/ConditionsHeader';
import { SweepConditionRuleBuilderRef } from '../../../../common/sweep-condition-rule-builder/sweep-condition-rule-builder-types';

interface GatePanelProps {
  stageObjectName: string;
  readonly?: boolean;
  crmOrgId: string;
  onGateDelete: () => void;
  isChanged: boolean;
  discardChanges: () => void;
  hasNoCriteria: boolean;
  onChangeAutoMove: (checked: boolean) => void;
  gateRef: React.RefObject<SweepConditionRuleBuilderRef>;
  onChange: (newSweepCondition: SweepCondition) => void;
  exitCriteria: SweepExitCriteria;
  saveChanges: () => Promise<void>;
  withConditionsHeader?: boolean;
  toStageName?: string;
  fromStageName?: string;
}

const GatePanel = ({
  exitCriteria,
  onChange,
  stageObjectName,
  readonly,
  crmOrgId,
  onGateDelete,
  isChanged,
  hasNoCriteria,
  onChangeAutoMove,
  gateRef,
  saveChanges,
  discardChanges,
  withConditionsHeader,
  toStageName,
  fromStageName,
}: GatePanelProps) => {
  const { isAutoMoveToNextStage } = exitCriteria;

  return (
    <Box sx={{ padding: '0px' }}>
      <>
        <Box
          className="rbHeader"
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            margin: '10px 5px',
          }}
        >
          {withConditionsHeader && toStageName && fromStageName && (
            <ConditionsHeader fromStageName={fromStageName} toStageName={toStageName} />
          )}
          <MoveToNextStage
            checked={isAutoMoveToNextStage}
            disabled={readonly || hasNoCriteria}
            onChange={onChangeAutoMove}
          />
        </Box>
        <SweepFieldsRuleBuilder
          ref={gateRef}
          readOnly={readonly}
          sweepCondition={exitCriteria}
          onChange={onChange}
          crmOrgId={crmOrgId}
          objectType={stageObjectName}
          showFieldContextMenu
        />
        {!readonly && (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ height: '40px', marginTop: '15px' }}
          >
            <DeleteGateButton handleConnectionRemoval={onGateDelete} />

            <Box display="flex" alignItems="center" gap={1.5}>
              <Button
                variant="outlined"
                size="small"
                disabled={!isChanged}
                onClick={discardChanges}
              >
                Discard
              </Button>
              <Button variant="filled" size="small" disabled={!isChanged} onClick={saveChanges}>
                Save changes
              </Button>
            </Box>
          </Box>
        )}
      </>
    </Box>
  );
};

export { GatePanel };
