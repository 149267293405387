import { createContext, useCallback, useEffect } from 'react';
import { AxiosInstance } from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import axios_instance_sweep from './sweepApiClient';
import axios_instance_auth from './auth0ApiClient';
import axios_instance_sweep_unauthenticated from './sweepUnauthenticatedApiClient';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';

interface SweepApiContextType {
  dashboardAPI: AxiosInstance;
  auth0API: AxiosInstance;
  dashboardUnauthenticatedAPI: AxiosInstance;
}

const ApisContext = createContext<SweepApiContextType>({
  dashboardAPI: axios_instance_sweep,
  auth0API: axios_instance_auth,
  dashboardUnauthenticatedAPI: axios_instance_sweep_unauthenticated,
});

const ApiProvider = ({ children }: any) => {
  const { getAccessTokenSilently } = useAuth0();

  const defaultCreationEnv = useSelector(selectDefaultCreationCrmOrgId);

  useEffect(() => {
    axios_instance_sweep.defaults.headers.common['currentcrmorgid'] = defaultCreationEnv;
  }, [defaultCreationEnv]);

  const setAxiosTokenInterceptor = useCallback(() => {
    axios_instance_sweep.interceptors.request.use(async (config) => {
      const token = await getAccessTokenSilently();

      const requestConfig = config;
      if (token && requestConfig.headers) {
        (requestConfig.headers as any).Authorization = `Bearer ${token}`;
      }
      return requestConfig;
    });

    axios_instance_sweep.interceptors.response.use(undefined, async (axiosError) => {
      const { statusCode } = axiosError.response?.data || {};
      if (statusCode === 401) {
        const msg = 'User not authorized. Sweep is currently in private beta.';
        console.log(msg);
        return Promise.reject({
          ...axiosError,
          response: {
            ...axiosError.response,
            data: {
              ...axiosError.response.data,
              error: msg,
            },
          },
        });
      }
      return Promise.reject(axiosError);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, defaultCreationEnv]); // Do not remove defaultCreationEnv from the dependency array. It is required to update the header when the defaultCreationEnv changes.

  useEffect(() => {
    setAxiosTokenInterceptor();
  }, [setAxiosTokenInterceptor]);

  return (
    <ApisContext.Provider
      value={{
        dashboardAPI: axios_instance_sweep,
        auth0API: axios_instance_auth,
        dashboardUnauthenticatedAPI: axios_instance_sweep_unauthenticated,
      }}
    >
      {children}
    </ApisContext.Provider>
  );
};

export { ApisContext, ApiProvider };
