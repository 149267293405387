import _ from 'lodash';
import { useState, useEffect } from 'react';
import { telemetry } from '../../../../telemetry';
import { simpleHash } from '../../../../third-party/simpleHash';
import usePermission from '../../../common/permissions/usePermission';
import { useAutoSaveContext } from './AutoSaveContext';
import { useThirdPartyFunnelsApi } from '../../../third-party-funnels/useThirdPartyFunnelsApi';

export const useThirdPartyFunnelAutoSave = ({
  thirdPartyFunnelsData,
  setLastUpdatedFor,
}: {
  thirdPartyFunnelsData: ThirdPartyFunnelDataMap;
  setLastUpdatedFor: (object: string, date: string) => void;
}) => {
  const { saveThirdPartyFunnel } = useThirdPartyFunnelsApi();
  const {
    getErrorHashFor,
    removeError,
    setErrorsFor,
    setFunnelEntityIsDirty,
    getIsSavingFor,
    setIsSavingFor,
  } = useAutoSaveContext();

  const [hasEditTPFunnelPermissions] = usePermission(['edit:third-party-funnels']);

  const [savedTPFunnelById, setSavedTPFunnelById] = useState(() =>
    Object.fromEntries(
      Object.keys(thirdPartyFunnelsData).map((funnelId) => [
        funnelId,
        thirdPartyFunnelsData[funnelId],
      ]),
    ),
  );

  useEffect(() => {
    if (!hasEditTPFunnelPermissions) {
      return;
    }
    const tPFunnelsToSave: ThirdPartyFunnelData[] = [];
    const tPFunnelsById = Object.fromEntries(
      Object.keys(thirdPartyFunnelsData).map((funnelId) => [
        funnelId,
        thirdPartyFunnelsData[funnelId],
      ]),
    );

    const tPFunnelIds = Object.keys(tPFunnelsById);

    // Checks witch funnels have changed and adds them to the funnelsToSave list
    tPFunnelIds.forEach((tPFunnelId) => {
      const savableFunnelData = thirdPartyFunnelsData[tPFunnelId];
      const previousFunnelVersion = savedTPFunnelById[tPFunnelId];

      const isFirstTimeInFunnel = !Boolean(previousFunnelVersion);

      if (isFirstTimeInFunnel) {
        // Do not save since this funnel already exists and saved in the current condition
        // however add it to the savedFunnelDetailsById
        setSavedTPFunnelById((savedFunnel) => {
          savedFunnel[tPFunnelId] = savableFunnelData;
          return { ...savedFunnel };
        });
      } else {
        // Verify if there are changes in the current funnel and add
        // to the funnelsToSave list

        const cleanedFunnel = _(savableFunnelData).omitBy(_.isUndefined).value();
        const cleanedPreviousFunnelVersion = _(previousFunnelVersion).omitBy(_.isUndefined).value();

        const isSavingFunnel = getIsSavingFor(tPFunnelId);
        const funnelHasChanges = () => !_.isEqual(cleanedPreviousFunnelVersion, cleanedFunnel);

        if (!isSavingFunnel && funnelHasChanges()) {
          setFunnelEntityIsDirty(tPFunnelId, true);
          tPFunnelsToSave.push(savableFunnelData);
        }
      }
    });

    const saveTPFunnels = async () => {
      const promises = tPFunnelsToSave.map(async (payload) => {
        const { id: funnelId } = payload;
        const payloadHash = simpleHash(JSON.stringify(payload));
        if (getErrorHashFor(funnelId) === payloadHash) {
          return;
        }
        try {
          setIsSavingFor(funnelId, true);
          const funnel = await saveThirdPartyFunnel(funnelId, payload);
          removeError(funnelId);

          setLastUpdatedFor(funnelId, new Date().toISOString());
          setFunnelEntityIsDirty(funnelId, false);

          setSavedTPFunnelById((_funnelsById) => {
            _funnelsById[funnelId] = funnel;
            return { ..._funnelsById };
          });
        } catch (err) {
          telemetry.captureError(err);
          setErrorsFor(funnelId, payloadHash);
        } finally {
          setIsSavingFor(funnelId, false);
        }
      });
      await Promise.all(promises);
    };

    saveTPFunnels();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thirdPartyFunnelsData, hasEditTPFunnelPermissions]);
};
