import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCrmOrgs } from '../../environments/useCrmOrgs';
import { useGroupSettingsContext } from './GroupSettingsContext';
import { AssignmentGroupMember } from '../../../../reducers/assignmentGroupTypes';
import { Autocomplete, Box, colors, Grid, Popper, Stack, TextField } from '@mui/material';
import { selectCrmOrgUsers } from '../../../../reducers/crmOrgUsersReducer';
import { useSelector } from 'react-redux';
import {
  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___,
  Button,
  Tag,
  Typography,
} from '@sweep-io/sweep-design';
import { telemetry } from '../../../../telemetry';
import keyBy from 'lodash/keyBy';
import { MembersAutocompleteError, SfErrorType } from './MembersAutocompleteError';
import { RolesAndProfilesFilters, useRolesAndProfileFilterProps } from '../RolesAndProfilesFilters';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { useAssignmentGroups } from '../members/useAssignmentGroups';

const emptyArray: AssignmentGroupMember[] = [];

const memberToAutocompleteValue = (member: AssignmentGroupMember) => ({
  label: member.name,
  id: member.userId,
});

export const MembersAutocompleteField = ({ maxWidth }: { maxWidth?: string }) => {
  const { getCrmOrgUsers } = useCrmOrgs();
  const { assignmentGroupRoles } = useFeatureToggle();
  const { crmOrgUsersBase } = useAssignmentGroups();
  const rolesAndProfileFilterProps = useRolesAndProfileFilterProps({
    crmOrgUsers: crmOrgUsersBase || [],
  });
  const { selectedProfileItems, selectedRoleItems } = rolesAndProfileFilterProps;

  const filteredUserIds = useMemo(() => {
    return (crmOrgUsersBase || [])
      .filter((user) => {
        if (selectedRoleItems.length === 0) {
          return true;
        }
        return selectedRoleItems.includes(user.roleName || '');
      })
      .filter((user) => {
        if (selectedProfileItems.length === 0) {
          return true;
        }
        return selectedProfileItems.includes(user.profileName || '');
      })
      .map((user) => user.id);
  }, [crmOrgUsersBase, selectedProfileItems, selectedRoleItems]);

  const currentFetchingCrmOrgId = useRef<string | null | undefined>();
  const {
    assignmentGroup: { members: _members },
    crmOrgId,
    setAssignmentGroup,
  } = useGroupSettingsContext();
  const members = (_members || emptyArray) as AssignmentGroupMember[];
  const sfMembers = useSelector(selectCrmOrgUsers(crmOrgId));
  const [serverError, setServerError] = useState<SfErrorType>();

  useEffect(() => {
    (async function () {
      if (currentFetchingCrmOrgId.current === crmOrgId) {
        return;
      }
      try {
        currentFetchingCrmOrgId.current = crmOrgId;
        await getCrmOrgUsers(true);
      } catch (e: any) {
        const errorCode = e.response?.data?.sweepError;
        if (errorCode === 0) {
          setServerError(SfErrorType.Mp);
        } else {
          setServerError(SfErrorType.Unknown);
          telemetry.captureError(e);
        }
      }
    })();
  }, [crmOrgId, getCrmOrgUsers, members]);

  const values = members.map(memberToAutocompleteValue);

  const filteredSfMembers = useMemo(() => {
    const assignmentMemberIds = (members || []).map((member) => member.userId);
    const selectedIds = values.map((v) => v.id);
    const filteredMembers = sfMembers?.filter((sfUser) => {
      const _isActive =
        sfUser.isActive === sfUser.isActive || assignmentMemberIds?.includes(sfUser.id);
      if (!_isActive) {
        return false;
      }
      if (selectedIds.includes(sfUser.id)) {
        return false;
      }
      return true;
    });
    return (filteredMembers || []).map((member) => ({
      label: member.name,
      id: member.id,
      data: member,
    }));
  }, [members, sfMembers, values]);

  const originalGroupMembersByKey = keyBy(members, 'id');

  const setSelectedValues = useCallback(
    (values: { label: string; id: string }[]) => {
      const newMembers = values.map(
        ({ id, label }) =>
          originalGroupMembersByKey[id] ?? {
            userId: id,
            dateAdded: Date.now(),
            name: label || 'Unknown User',
            weight: 1,
            membershipActive: true,
            userActive: true,
          },
      );

      setAssignmentGroup((group) => ({
        ...group,
        members: newMembers,
      }));
    },
    [originalGroupMembersByKey, setAssignmentGroup],
  );

  const removeMember = useCallback(
    (memberId: string) => () => {
      setSelectedValues(values.filter((v) => v.id !== memberId));
    },
    [setSelectedValues, values],
  );

  const ref = useRef<HTMLDivElement | null>(null);
  const width = ref.current?.clientWidth || undefined;

  if (serverError) {
    return <MembersAutocompleteError serverError={serverError} />;
  }

  const hasFilters = selectedProfileItems.length > 0 || selectedRoleItems.length > 0;

  const noOptions = (
    <Stack>
      <Typography variant="body">No results matching your search</Typography>
      {hasFilters && (
        <Stack gap={1}>
          <Typography variant="body">Try removing some filters</Typography>
          <span>
            <Button
              variant="flat"
              onClick={() => {
                rolesAndProfileFilterProps.setSelectedRoleItems([]);
                rolesAndProfileFilterProps.setSelectedProfileItems([]);
              }}
            >
              Clear Filters{' '}
            </Button>
          </span>
        </Stack>
      )}
    </Stack>
  );

  return (
    <Stack direction="row" spacing={2} alignItems="top">
      <Autocomplete
        disableClearable
        fullWidth
        sx={{ maxWidth }}
        options={filteredSfMembers.filter((member) => filteredUserIds.includes(member.id))}
        multiple
        disableCloseOnSelect
        renderInput={(params) => <TextField {...params} ref={ref} placeholder="Search by name" />}
        renderTags={(values) => (
          <Grid container gap="8px" paddingTop={1} paddingBottom={1} width="auto">
            {values.map(({ label, id }) => (
              <Grid key={id} width="auto">
                <Tag label={label} key={id} onDelete={removeMember(id)} />
              </Grid>
            ))}
          </Grid>
        )}
        onChange={(event: React.ChangeEvent<unknown>, value: { label: string; id: string }[]) => {
          setSelectedValues(value);
        }}
        popupIcon={null}
        noOptionsText={noOptions}
        value={values}
        PopperComponent={(props) => (
          <Popper
            {...props}
            sx={{
              width: width,
              '.MuiAutocomplete-noOptions': {
                ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.caption,
                color: colors.grey[700],
                width: width,
              },
            }}
          />
        )}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;

          const sfUser = crmOrgUsersBase?.find((user) => user.id === option.id);

          const roleAndProfileOpts = [];
          if (sfUser?.roleName) {
            roleAndProfileOpts.push(`Role: ${sfUser.roleName}`);
          }
          if (sfUser?.profileName) {
            roleAndProfileOpts.push(`Profile: ${sfUser.profileName}`);
          }
          const roleAndProfile = roleAndProfileOpts.join(' | ');

          if (option.label)
            return (
              <Box
                width={width}
                key={key}
                component="li"
                {...optionProps}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start !important',
                }}
              >
                <span>
                  <Typography variant="body">{option.label}</Typography>
                </span>
                {assignmentGroupRoles && (
                  <span>
                    <Typography variant="caption" color={colors.grey[700]}>
                      {roleAndProfile}
                    </Typography>
                  </span>
                )}
              </Box>
            );
        }}
      />
      {assignmentGroupRoles && (
        <Stack direction="row" gap={2}>
          <RolesAndProfilesFilters {...rolesAndProfileFilterProps} />
        </Stack>
      )}
    </Stack>
  );
};
