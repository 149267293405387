import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import WizardFooter from '../../../../common/wizard/WizardFooter';
import useWizard from '../../../../common/wizard/useWizard';
import { GroupSettingsProvider, useGroupSettingsContext } from '../GroupSettingsContext';
import { useGetFirstAvailableEmoji } from '../../groups/useGetFirstAvailableEmoji';
import { useCallback, useMemo } from 'react';
import {
  isNameAndDescriptionValid,
  NameAndDescriptionStepContent,
  NameAndDescriptionStepHeader,
} from './NameAndDescriptionStep';
import { AddMembersStepContent, AddMembersStepHeader } from './AddMembersStep';
import { useValidateLimitMembersPanel } from '../LimitMembersPanelWithContext';
import { AssignmentGroup } from '../../../../../reducers/assignmentGroupTypes';
import { telemetry } from '../../../../../telemetry';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { CloseButton } from '../CloseButton';
import { useDialogLoader } from '../../../../common/dialogs/DialogLoader';

interface GroupSettingsWizardProps {
  onClose: () => void;
  onSave: (
    assignmentGroupData: Partial<AssignmentGroup>,
    resetAllMembersLimit?: number,
  ) => void | Promise<void>;
  crmOrgId: string;
}

const _steps = ['NameAndDescription', 'AddMembers'];

const GroupSettingsWizard = ({ onClose, onSave }: Omit<GroupSettingsWizardProps, 'crmOrgId'>) => {
  const wizardProps = useWizard(_steps);
  const { activeStep, isLastStep } = wizardProps;
  const { assignmentGroup, resetAllMembersLimit } = useGroupSettingsContext();
  const { isLimitMembersSectionValid } = useValidateLimitMembersPanel();
  const { addNotification } = useSweepNotifications();
  const { DialogLoader, showLoader, hideLoader } = useDialogLoader();

  const save = useCallback(async () => {
    showLoader();
    try {
      await onSave(assignmentGroup as Partial<AssignmentGroup>, resetAllMembersLimit);
    } catch (e) {
      telemetry.captureError(e);
      addNotification({
        message: 'Error Saving Assignment Group',
        variant: SweepNotificationVariant.Error,
      });
      return false;
    } finally {
      hideLoader();
    }

    onClose && onClose();
    return true;
  }, [
    addNotification,
    assignmentGroup,
    hideLoader,
    onClose,
    onSave,
    resetAllMembersLimit,
    showLoader,
  ]);

  const handleNext = useCallback(async () => {
    if (isLastStep) {
      return await save();
    }
    return true;
  }, [isLastStep, save]);

  const getTitle = () => {
    switch (activeStep) {
      case 0:
        return <NameAndDescriptionStepHeader />;
      case 1:
      default:
        return <AddMembersStepHeader />;
    }
  };

  const getContent = () => {
    switch (activeStep) {
      case 0:
        return <NameAndDescriptionStepContent />;
      case 1:
      default:
        return <AddMembersStepContent />;
    }
  };

  const isCurrentStepValid = useMemo(() => {
    switch (activeStep) {
      case 0:
        return isNameAndDescriptionValid(assignmentGroup);
      case 1:
      default:
        return isLimitMembersSectionValid();
    }
  }, [activeStep, assignmentGroup, isLimitMembersSectionValid]);

  return (
    <Dialog
      open
      sx={{
        '.MuiDialogTitle-root': {
          paddingBottom: 3,
        },
      }}
    >
      <DialogTitle>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {getTitle()}
          <CloseButton onClick={onClose} />
        </Stack>
      </DialogTitle>
      <DialogContent sx={{ minWidth: '643px', maxWidth: '1106px' }}>{getContent()}</DialogContent>
      <DialogActions sx={{ display: 'block' }}>
        <WizardFooter
          onNext={handleNext}
          {...wizardProps}
          lastNextText="Create group"
          isNextDisabled={!isCurrentStepValid}
        />
      </DialogActions>
      {DialogLoader}
    </Dialog>
  );
};

const GroupSettingsWizardWithProviders = ({
  onClose,
  crmOrgId,
  onSave,
}: GroupSettingsWizardProps) => {
  const getFirstAvailableEmoji = useGetFirstAvailableEmoji();

  return (
    <GroupSettingsProvider defaultEmoji={getFirstAvailableEmoji()} crmOrgId={crmOrgId}>
      <GroupSettingsWizard onClose={onClose} onSave={onSave} />
    </GroupSettingsProvider>
  );
};

export { GroupSettingsWizardWithProviders as GroupSettingsWizard };
