import { useContext } from 'react';
import { ReactFlowInstance, useReactFlow, XYPosition } from 'reactflow';
import { GroupDataReactFlowNode } from './canvasTypes/nodeTypesData';
import { SWEEP_GRID_SIZE } from './const';
import {
  SweepCanvasNodePosition,
  _canvasIndexPositionToReactFlowPosition,
  _reactFlowPositionToRoundedCanvasIndexPosition,
} from './helpers/calculateHandlePositionsBasedOnCoords';
import { MousePositionContext } from './internal-context/MousePositionContext';
import { CanvasWrapperResizeObserverContext } from './internal-context/CanvasWrapperDimensionsContext';
import { SweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';

export interface MoveCanvasGroups {
  groupIds: string[];
  onDrop: (groupDropPosition: SweepCanvasNodePosition) => void;
}

const calculateCanvasPositionFromMouseCoords = (
  groupNode: GroupDataReactFlowNode,
  currentMousePosition: XYPosition,
  reactFlowInstance: ReactFlowInstance,
) => {
  const { minCol, minRow } = groupNode.data.groupNodePositions;
  const zoom = reactFlowInstance.getZoom();
  const position = {
    x: currentMousePosition.x - minCol * SWEEP_GRID_SIZE.width * zoom,
    y: currentMousePosition.y + minRow * SWEEP_GRID_SIZE.height * zoom,
  };

  const reactFlowPosition = reactFlowInstance?.screenToFlowPosition(position);
  const canvasNodePosition = _reactFlowPositionToRoundedCanvasIndexPosition(
    reactFlowInstance?.screenToFlowPosition(position),
  );
  return { canvasNodePosition, reactFlowPosition };
};

export function useMoveGroups({ groupNodes }: { groupNodes: GroupDataReactFlowNode[] }) {
  const { moveGroups, sweepGroups } = useContext(SweepCanvasPropsCtx);
  const reactFlowInstance = useReactFlow();
  reactFlowInstance.getZoom();

  const currentMousePosition = useContext(MousePositionContext);
  const { canvasWrapperRef } = useContext(CanvasWrapperResizeObserverContext);

  if (moveGroups && canvasWrapperRef.current && reactFlowInstance) {
    moveGroups.groupIds.forEach((groupId) => {
      const idx = groupNodes.findIndex((node) => node.id === groupId);
      const sweepNode = sweepGroups.find((node) => node.id === groupId);

      if (idx !== -1) {
        const { reactFlowPosition } = calculateCanvasPositionFromMouseCoords(
          groupNodes[idx],
          currentMousePosition,
          reactFlowInstance,
        );

        const pos = _canvasIndexPositionToReactFlowPosition(sweepNode?.position);

        groupNodes[idx].position = {
          x: reactFlowPosition.x + pos.x,
          y: reactFlowPosition.y + pos.y,
        };
      }
    });
  }

  const handleOnGroupsDropClick = () => {
    if (!moveGroups || !reactFlowInstance) {
      return;
    }
    const groupNode = groupNodes.find((node) => node.id === moveGroups.groupIds[0]);

    if (groupNode) {
      const { canvasNodePosition } = calculateCanvasPositionFromMouseCoords(
        groupNode,
        currentMousePosition,
        reactFlowInstance,
      );
      moveGroups.onDrop(canvasNodePosition);
    }
  };

  return { handleOnGroupsDropClick };
}
