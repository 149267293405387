import { TimeAgo } from '../../timeAgo';
import { CommentReplyProps, CommentThreadProps } from './types';
import { FilterByOptions, FilterSortState, SortByOptions } from '../common/comments/types';
import _orderBy from 'lodash/orderBy';

const timeAgo = new TimeAgo('en-US');

export const humanizedTimeAgo = (date: string) => {
  if (!date) return '';
  const dateAsDate = new Date(date);
  return timeAgo.format(dateAsDate, 'round-minute');
};

export const getReplyText = (amountOfReplies: number) => {
  switch (amountOfReplies) {
    case 0:
      return '';
    case 1:
      return '1 reply';
    default:
      return `${amountOfReplies} replies`;
  }
};

export const getPositionRelativeToFunnel = (
  absoluteX: number,
  absoluteY: number,
  canvasX: number,
  canvasY: number,
  canvasZoom: number,
) => {
  const positionX = (absoluteX - canvasX) / canvasZoom;
  const positionY = (absoluteY - canvasY) / canvasZoom;
  return {
    positionX,
    positionY,
  };
};

export const getAbsolutePosition = (
  relativeX: number,
  relativeY: number,
  canvasX: number,
  canvasY: number,
  canvasZoom: number,
) => {
  const positionX = relativeX * canvasZoom + canvasX;
  const positionY = relativeY * canvasZoom + canvasY;
  return {
    positionX,
    positionY,
  };
};

export const isAllowedToResolve = (commentReplies: CommentReplyProps[], userId?: string) =>
  commentReplies.some(
    (reply) => reply.createdById === userId || reply.mentionedUserIds?.includes(userId as string),
  );

export const filterSortComments = (
  commentThreads: CommentThreadProps[],
  filterSortState: FilterSortState,
  showResolved: boolean,
  userId: string,
) => {
  const { sortBy, filterBy, searchTerm } = filterSortState;
  const { ONLY_RESOLVED, ONLY_YOURS, ONLY_UNREAD, SHOW_ALL } = FilterByOptions;

  const filtered = commentThreads.filter(({ isRead, isResolved, commentReplies }) => {
    if (!showResolved && isResolved) {
      return false;
    }
    switch (filterBy) {
      case ONLY_RESOLVED:
        return isResolved;
      case ONLY_UNREAD:
        return !isRead;
      case ONLY_YOURS:
        return isAllowedToResolve(commentReplies, userId);
      case SHOW_ALL:
      default:
        return true;
    }
  });

  const filteredBySearch = filtered.filter(({ commentReplies }) => {
    return commentReplies.some(({ commentBody }) =>
      commentBody.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  });

  return _orderBy(
    filteredBySearch,
    (comment) => {
      switch (sortBy) {
        case SortByOptions.SORT_BY_UNREAD:
          return !comment.isRead;
        case SortByOptions.SORT_BY_DATE:
        default:
          return comment.createdAt;
      }
    },
    ['desc'],
  );
};

export const hasMeaningfulText = (str: string) => {
  // no spaces and break lines
  return !/^\s*$/.test(str);
};
