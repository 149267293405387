import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import FieldLabel from '../../../common/FieldLabel';
import SweepSelect from '../../../common/SweepSelect';
import { SlackActionType } from '../../../../types/enums/SlackActionType';

import { SlackActionCreate } from './SlackActionCreate';
import { SlackActionUpdate } from './SlackActionUpdate';
import { slackActionEntryActions } from './common';

export const SlackActionEntry = ({
  action,
  crmOrgId,
  onUpdateAction,
  objectName,
  idx,
}: {
  action: SlackAction;
  idx: number;
  onUpdateAction: (action: SlackAction, index: number) => any;
  crmOrgId: string;
  objectName: string;
}) => {
  const buttonAction = (
    <Box>
      <FieldLabel label={'Button action'} />
      <SweepSelect
        SelectProps={{
          value: action.type ?? '',
          onChange: (event: SelectChangeEvent<unknown>) => {
            const type = event.target.value as SlackActionType;
            if (type === SlackActionType.CREATE_RECORD) {
              onUpdateAction(
                {
                  type,
                  fields: [],
                  buttonText: action.buttonText ?? '',
                  objectTypeName: {
                    label: '',
                    objectType: '',
                  },
                },
                idx,
              );
            }
            if (type === SlackActionType.UPDATE_RECORD) {
              onUpdateAction(
                {
                  type,
                  fields: [],
                  buttonText: action.buttonText ?? '',
                },
                idx,
              );
            }
          },
          renderValue: (val: any) => {
            const output = slackActionEntryActions.filter((d) => d.value === val);
            if (output.length > 0) {
              return output[0].label ?? '';
            }
          },
        }}
      >
        {slackActionEntryActions.map((type) => (
          <MenuItem key={type.value} value={type.value} disabled={type.disabled}>
            {type.label}
          </MenuItem>
        ))}
      </SweepSelect>
    </Box>
  );

  return (
    <>
      {action.type === SlackActionType.CREATE_RECORD && (
        <SlackActionCreate
          crmOrgId={crmOrgId}
          objectName={objectName}
          action={action}
          idx={idx}
          onUpdateAction={onUpdateAction}
          buttonAction={buttonAction}
        />
      )}
      {action.type === SlackActionType.UPDATE_RECORD && (
        <SlackActionUpdate
          action={action}
          crmOrgId={crmOrgId}
          idx={idx}
          objectName={objectName}
          onUpdateAction={onUpdateAction}
          buttonAction={buttonAction}
        />
      )}
    </>
  );
};
