import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SweepFieldsByObjectType } from '../sweep-fields/useCachedSweepFields';
import { SweepFieldsLabelInfoMap } from '../sweep-fields/sweepFieldsLabelsReducer';
import {
  createDealRoomRecipientItem,
  createSlackRecipientItem,
} from '../components/Automations/slack/utils';

export interface SlackState {
  slackRecipients: SlackRecipient[];
  sfRecipients: SlackRecipientStruct[];
  noChannelRecipients: SlackRecipient[];
  optionsForRecipients: any;
  optionsForNoChannelRecipients: any;
  isCached: boolean;
}

const initialState: SlackState = {
  slackRecipients: [],
  noChannelRecipients: [],
  sfRecipients: [],
  optionsForRecipients: [],
  optionsForNoChannelRecipients: [],
  isCached: false,
};

export const slackSlice = createSlice({
  name: 'slack',
  initialState,
  reducers: {
    setIsSlackRecipientsCached: (state, action: PayloadAction<{ isCached: boolean }>) => {
      state.isCached = action.payload.isCached;
    },
    setSlackRecipients: (state, action: PayloadAction<{ slackRecipients: SlackRecipient[] }>) => {
      state.slackRecipients = action.payload.slackRecipients.map((recipient) => ({
        ...recipient,
        _label: recipient
          ? `${(recipient as SlackRecipient)?.label} [${(recipient as SlackRecipient)?.type}]`
          : 'Private Channel',
      }));
      state.noChannelRecipients = state.slackRecipients.filter((item) => item?.type !== 'channel');
    },
    setSfRecipients: (
      state,
      action: PayloadAction<{ sweepFields: SweepFieldsByObjectType; objectName: string }>,
    ) => {
      const { sweepFields, objectName } = action.payload;
      state.sfRecipients =
        sweepFields?.[objectName]?.fields?.map((field) => {
          return {
            type: 'Salesforce',
            value: {
              fieldIds: [field.id ?? ''],
            },
          };
        }) ?? [];
    },
    setOptionsForRecipients: (
      state,
      action: PayloadAction<{
        addSweepSlackChannel: boolean;
        objectName: string;
        sweepFieldsLabels: SweepFieldsLabelInfoMap;
      }>,
    ) => {
      const { addSweepSlackChannel, objectName } = action.payload;
      const { slackRecipients, noChannelRecipients } = state;

      const optionsArray: SlackRecipientStruct[] = [];

      slackRecipients.forEach((el) => optionsArray.push(createSlackRecipientItem(el)));

      if (addSweepSlackChannel && objectName) {
        optionsArray.push(createDealRoomRecipientItem());
      }

      state.optionsForRecipients = optionsArray;
      state.optionsForNoChannelRecipients = noChannelRecipients.map((el) =>
        createSlackRecipientItem(el),
      );
    },
  },
});

export const selectSlackRecipients = (state: RootState) => state.slack.slackRecipients;
export const selectSfRecipients = (state: RootState) => state.slack.sfRecipients;
export const selectOptionsForRecipients = (state: RootState) => state.slack.optionsForRecipients;
export const selectOptionsForNoChannelRecipients = (state: RootState) =>
  state.slack.optionsForNoChannelRecipients;
export const selectIsSlackRecipientsCached = (state: RootState) => state.slack.isCached;

export const {
  setSlackRecipients,
  setSfRecipients,
  setOptionsForRecipients,
  setIsSlackRecipientsCached,
} = slackSlice.actions;
export default slackSlice.reducer;
