import { Badge } from '@sweep-io/sweep-design';
import { SingleSelectFilterPopoverOption } from './SingleSelectFilterPopover';
import { FunnelFieldLabels } from '../../../constants/fieldsManagementConsts';
import { ConfigurationType, ConfigurationToName } from '../../documentation/dependencies/types';
import { formatCamelCase } from '../../documentation/universal-search/utils';

export const OPTION_ALL = {
  value: 'all',
  label: 'All',
};

export const getTypeOptionsWithCounter = (
  typeOptions: SingleSelectFilterPopoverOption[],
  renderLabel: (option: SingleSelectFilterPopoverOption) => number,
) => {
  return typeOptions.map((type: any) => {
    if (type.value === OPTION_ALL.value) {
      return type;
    }

    return {
      ...type,
      label:
        FunnelFieldLabels[type.value as SweepFieldTypes] ??
        ConfigurationToName[type.value as ConfigurationType] ??
        formatCamelCase(type.value),
      addon: <Badge variant="secondary" label={renderLabel(type)} showZero />,
    };
  });
};
