import { TextFieldVariant, TextFieldWithButton } from './input-atoms/TextFieldWithButton';
import { PromptsBoxCollapsible } from './input-atoms/PromptsBoxCollapsible';
import { useState } from 'react';
import { colors } from '@sweep-io/sweep-design';
import { PromptsBoxSimple } from './input-atoms/PromptsBoxSimple';
import { PromptsBoxProps } from './input-atoms/promptsBoxTypes';
import { Box } from '@mui/material';

interface AiChatInputProps extends Omit<PromptsBoxProps, 'predefinedPrompts'> {
  textFieldVariant: TextFieldVariant;
  isLoading: boolean;
  predefinedPrompts?: { [key: string]: string };
  isCollapsiblePrompts: boolean;
}

export const AiChatInput = ({
  textFieldVariant,
  predefinedPrompts,
  onConfirm,
  isLoading,
  isCollapsiblePrompts,
  disabled,
}: AiChatInputProps) => {
  //by product definition: once user focuses on input, prompt box should stay visible
  const [showPromptBox, setShowPromptBox] = useState(!isCollapsiblePrompts || false);

  const arePromptsOpen = predefinedPrompts && showPromptBox;

  const collapsibleBackground = arePromptsOpen
    ? colors.grey[100]
    : 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%)';

  const backgroundSx = isCollapsiblePrompts ? { background: collapsibleBackground } : {};

  return (
    <Box p={textFieldVariant === 'primary' ? 2 : 1.5} sx={backgroundSx}>
      {isCollapsiblePrompts && arePromptsOpen && (
        <PromptsBoxCollapsible
          predefinedPrompts={predefinedPrompts}
          onConfirm={onConfirm}
          disabled={disabled}
        />
      )}
      {!isCollapsiblePrompts && predefinedPrompts && (
        <PromptsBoxSimple
          predefinedPrompts={predefinedPrompts}
          onConfirm={onConfirm}
          disabled={disabled}
        />
      )}
      <TextFieldWithButton
        inputVariant={textFieldVariant}
        onConfirm={onConfirm}
        inputPlaceholder="Ask anything..."
        isLoading={isLoading}
        readonly={disabled}
        onFocus={() => {
          setShowPromptBox(true);
        }}
      />
    </Box>
  );
};
