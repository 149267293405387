/* updateFunnelMapRecordTypesAndFunnels */

import { groupBy } from 'lodash';

// Scenarios:
// 1. Record type exists in recordTypesData but not in recordTypes (added)
// 2. Record type exists in recordTypes but not in recordTypesData
//     2.1. Record type is not present in any funnel (deleted)
//     2.2. Record type is present in a funnel (transformed to funnel, deleted from recordTypes)
// 3. Record type exists in both recordTypes and recordTypesData (unchanged)
// 4. Funnel exists in funnelsData but not in funnels (added)
// 5. Funnel exists in funnels but not in funnelsData (deleted)
// 6. Funnel exists in both funnels and funnelsData (unchanged)

export const updateFunnelMapRecordTypesAndFunnels = (
  funnelMap: FunnelMap,
  initialPosition: FunnelMapPosition = { row: 0, column: 0 },
) => {
  let currentRow = initialPosition.row;
  let currentColumn = initialPosition.column;

  const changes: string[] = [];

  const funnelRecordTypesByRecordTypeApiName = Object.values(funnelMap.funnelsData || {}).reduce(
    (acc, funnel) => {
      acc[`${funnel.recordType.objectName}.${funnel.recordType.name}`] = funnel;
      return acc;
    },
    {} as Record<string, Funnel>,
  );

  const recordTypesDataNames = Object.keys(funnelMap.recordTypesData || {});

  const recordTypesToDelete = Object.keys(funnelMap.recordTypes).filter(
    (recordTypeApiName) => !recordTypesDataNames.includes(recordTypeApiName),
  );

  const recordTypesToTransformToFunnel = recordTypesToDelete.filter((recordTypeApiName) => {
    const correspondingFunnelInFunnelData = funnelRecordTypesByRecordTypeApiName[recordTypeApiName];
    if (correspondingFunnelInFunnelData) {
      const isNotAlreadyPresentInFunnels =
        funnelMap.funnels[correspondingFunnelInFunnelData.id] === undefined;
      if (isNotAlreadyPresentInFunnels) {
        return true;
      }
    }
    return false;
  });

  recordTypesToTransformToFunnel.forEach((recordTypeApiName) => {
    const funnelId = funnelRecordTypesByRecordTypeApiName[recordTypeApiName].id;
    funnelMap.funnels = funnelMap.funnels || {};
    funnelMap.funnels[funnelId] = {
      position: funnelMap.recordTypes[recordTypeApiName].position,
    }; // adds to funnels with the position of the about to be deleted record type
    recordTypesToDelete.splice(recordTypesToDelete.indexOf(recordTypeApiName), 1); // remove from recordTypesToDelete
    delete funnelMap.recordTypes[recordTypeApiName];
    changes.push(`Record type ${recordTypeApiName} as transformed to funnel.`);
  });

  const recordTypesToAdd = recordTypesDataNames.filter(
    (recordTypeName) => !Object.keys(funnelMap.recordTypes).includes(recordTypeName),
  );

  recordTypesToDelete.forEach((recordTypeApiName) => {
    delete funnelMap.recordTypes[recordTypeApiName];
    changes.push(`Record type ${recordTypeApiName} as removed.`);
  });

  const recordTypesToAddGroupedByObject = groupBy(
    recordTypesToAdd,
    (recordTypeApiName) => recordTypeApiName.split('.')[0],
  );

  Object.values(recordTypesToAddGroupedByObject)
    .sort((a, b) => b.length - a.length)
    .forEach((recordTypes) => {
      currentRow = initialPosition.row;
      recordTypes.forEach((recordTypeApiName) => {
        funnelMap.recordTypes[recordTypeApiName] = {
          position: { row: currentRow, column: currentColumn },
        };
        const leadingFieldName = funnelMap.recordTypesData[recordTypeApiName].leadingField?.name;
        if (leadingFieldName) {
          funnelMap.recordTypes[recordTypeApiName].leadingFieldName = leadingFieldName;
        }

        currentRow -= 2;
        changes.push(`Record type ${recordTypeApiName} as added.`);
      });
      currentColumn += 2;
    });

  const funnelDataFunnelsIds = Object.keys(funnelMap.funnelsData || {});

  const funnelsToDelete = Object.keys(funnelMap.funnels).filter(
    (funnelId) => !funnelDataFunnelsIds.includes(funnelId),
  );

  const funnelsToAdd = funnelDataFunnelsIds.filter(
    (funnelId) => !Object.keys(funnelMap.funnels).includes(funnelId),
  );

  funnelsToDelete.forEach((funnelId) => {
    delete funnelMap.funnels[funnelId];
    changes.push(`Funnel ${funnelId} was removed.`);
  });
  currentColumn = initialPosition.column;

  funnelsToAdd.forEach((funnelId) => {
    funnelMap.funnels[funnelId] = {
      position: { row: currentRow, column: currentColumn },
    };
    currentRow -= 2;
    changes.push(`Funnel ${funnelId} was added.`);
  });

  return { funnelMap, changes };
};
