import { Box } from '@mui/material';
import { useConfigurationCanvasContext } from '../ConfigurationCanvasContext';

import { RecordTypeLeadingObjectPanel } from './record-type-leading-object-panel/RecordTypeLeadingObjectPanel';
import { useSweepCanvasFitView } from '../../../multi-canvas/useSweepCanvasFitView';
import CommentsLayer from '../../../comments/CommentsLayer';
import { Button } from '@sweep-io/sweep-design';
import { designSystemElevations } from '../../../../appTheme/appTheme';
import { ConfigurationCanvasLayersSelector } from './layers-selector/ConfigurationCanvasLayersSelector';
import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';
import { selectAllVisibilityMaps } from '../../../../reducers/canvasLayersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { visibilityMapToLayersSelectorVisibilityMap } from './layers-selector/utils';
import { useContext, useEffect } from 'react';
import { RecordTypeLeadingObjectCtx } from './record-type-leading-object-panel/RecordTypeLeadingObjectCtx';
import { useGetReservedSpaceForPanels } from '../panels-reserve-space/ReserveSpaceForPanelsCtx';
import { clearCommentsSearchTerm } from '../../../../reducers/commentsReducer';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { RecordTypeLeadingObjectPopover } from './record-type-leading-object-panel/RecordTypeLeadingObjectPopover';

export interface CanvasBoardProps {
  page: React.ReactNode;
  crmOrgId: string;
  funnelMap: FunnelMap;
  canvasLeftReservedSpace?: number;
  children: React.ReactNode;
  hideCommentsButton?: boolean;
}

export const CanvasBoard = ({
  crmOrgId,
  page,
  funnelMap,
  children,
  canvasLeftReservedSpace = 0,
  hideCommentsButton = false,
}: CanvasBoardProps) => {
  const dispatch = useDispatch();
  const { configurationCanvasProps, commentsOn, setCommentsOn } = useConfigurationCanvasContext();
  const { currentRecord } = useContext(RecordTypeLeadingObjectCtx);
  const { foldRecordType } = useFeatureToggle();

  const { fitView } = useSweepCanvasFitView();

  const toggleComments = () => {
    const _commentsOn = !commentsOn;
    setCommentsOn(_commentsOn);
    if (!_commentsOn) {
      dispatch(clearCommentsSearchTerm());
    }
  };

  const { visibilityElements } = configurationCanvasProps;

  useEffect(() => {
    setTimeout(() => {
      fitView({ duration: 300, maxZoom: 1 });
    }, 200);
  }, [crmOrgId, fitView]);

  const renderRecordTypeLeadingObjectPanel = () => {
    if (foldRecordType && currentRecord) {
      return <RecordTypeLeadingObjectPopover crmOrgId={crmOrgId} />;
    }
    if (currentRecord) {
      return <RecordTypeLeadingObjectPanel currentRecord={currentRecord} crmOrgId={crmOrgId} />;
    }
  };

  const allVisibilityMaps = useSelector(selectAllVisibilityMaps);
  const _visibilityMap =
    visibilityElements.type === 'map'
      ? {
          // Remove old layers from the canvas if present
          [VisibilityLayers.NURTURING_STEPS]: true,
          [VisibilityLayers.GATES]: false,
          ...visibilityElements.visibilityMap,
        }
      : visibilityMapToLayersSelectorVisibilityMap(
          allVisibilityMaps?.[visibilityElements.layersId] || {},
        );

  const allowComments = _visibilityMap[VisibilityLayers.COMMENTS];

  const { leftReservedSpace } = useGetReservedSpaceForPanels();

  return (
    <>
      {renderRecordTypeLeadingObjectPanel()}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
        id="canvas-board"
      >
        <Box sx={{ display: commentsOn ? 'none' : undefined }}>{page}</Box>
        {allowComments && (
          <CommentsLayer
            funnelMapId={funnelMap.id}
            isCommentModeOn={commentsOn}
            closeCommentMode={() => {
              setCommentsOn(false);
            }}
            reservedLeftSpace={leftReservedSpace}
          />
        )}
        <Box
          sx={{
            position: 'absolute',
            left: leftReservedSpace,
            top: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <>
            {allowComments && !hideCommentsButton && (
              <Box
                sx={{
                  position: 'absolute',
                  right: 16,
                  top: 16,
                  zIndex: 1,
                  'button, button:hover': {
                    boxShadow: designSystemElevations.Elevation2,
                  },
                }}
                className="comments-layer"
              >
                <Button
                  variant="outlined"
                  size="small"
                  onClick={toggleComments}
                  startIconName="UnreadComment"
                >
                  Comments
                </Button>
              </Box>
            )}
          </>
          {visibilityElements.type === 'layersSelector' && !commentsOn && (
            <ConfigurationCanvasLayersSelector visibilityElements={visibilityElements} />
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            left: canvasLeftReservedSpace,
            right: 0,
            bottom: 0,
            top: 0,
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};
