import { ConfigurationType } from '@server/parser';
import { NameProperties, FieldData } from '../documentation/dependencies/DependenciesTypes';

// *******  constants *******
export const OBJECTS_DATA_KEY = 'objectsData';
const INSTALLED_PACKAGES_KEY = 'installedPackages';
const APEX_CLASSES = 'apexClasses';

// ******* BE types *******
export interface ParserResponse {
  [OBJECTS_DATA_KEY]: ObjectData[];
  [INSTALLED_PACKAGES_KEY]?: string[];
  [APEX_CLASSES]?: string[];
  orgId: string;
}
export interface OpenAiResponse {
  data: string;
  error: string;
}

export type RelatedListData = { objectName: string; name: string; label: string };

export type LayoutData = ConfigurationInstanceData & {
  buttons: NameProperties[];
  relatedLists: RelatedListData[];
};

interface ObjectData {
  name: string;
  visible: boolean;
  recordTypes: RecordTypeData[];
  fields?: FieldData[];
  validationRules?: ValidationRuleData[];
  approvalProcesses?: ConfigurationInstanceData[];
  workflowRules?: ConfigurationInstanceData[];
  processBuilderFlows?: ConfigurationInstanceData[];
  flows?: FlowData[];
  paths?: PathData[];
  apexTriggers?: ApexTriggerData[];
  cpqData?: ConfigurationInstanceData[];
  layouts?: LayoutData[];
}

type Annotations = {
  changedBy?: string;
  changedAt?: string;
  createdAt?: string;
  createdBy?: string;
};

export type ConfigurationInstanceData = {
  id: string;
  name: string;
  label: string;
  description?: string;
  annotations?: Annotations;
  link?: string;
  filename?: string;
  isActive: boolean;
};

type Attributions = { [key in ConfigurationType]?: string[] };

export type RecordTypeData = ConfigurationInstanceData & {
  leadingFields: LeadingField[];
  attributions?: Attributions;
};

export type ValidationRuleData = ConfigurationInstanceData & {
  formula: string;
  errorMessage?: string;
};
export type ApexTriggerData = ConfigurationInstanceData & { relatedClasses?: ApexClass[] };

type RecordTriggeredFlowProperties = {
  runWhen: 'RecordAfterSave' | 'RecordBeforeSave' | 'RecordBeforeDelete';
  triggerOn: 'Update' | 'Create' | 'CreateAndUpdate' | 'Delete';
};
type ScheduleTriggeredFlowProperties = { schedule: FlowSchedule };
type FlowSchedule = {
  frequency: 'Daily' | 'Weekly' | 'Once';
  startDate: string;
  startTime: string;
};

export enum FlowTypes {
  SCREEN = 'Screen Flow',
  AUTO_LAUNCHED = 'Autolaunched Flow',
  RECORD_TRIGGERED = 'Record-Triggered Flow',
  SCHEDULED_TRIGGERED = 'Schedule-Triggered Flow',
  PLATFORM_EVENT = 'Platform Event Flow',
  ORCHESTRATION = 'Record-Triggered Orchestration',
}

export type FlowData = ConfigurationInstanceData & {
  flowType: FlowTypes;
  triggers?: RecordTriggeredFlowProperties[] | ScheduleTriggeredFlowProperties[];
  actions?: string[];
};

type PathData = ConfigurationInstanceData & { fieldName: string; recordTypeName: string };

export type LeadingField = {
  name: string;
  label: string;
  values: FieldValue[];
  hasPath: boolean;
  isLeadingCandidate?: boolean;
};

type SFDCPicklistField = { name: string; label: string; values: PicklistValue[] };
export type SFDCLeadingField = SFDCPicklistField & { isLeadingCandidate: boolean };

export type FieldValue = {
  name: string;
  label: string;
  validationRules?: string[];
  flows?: string[];
  workflowRules?: string[];
  approvalProcesses?: string[];
  processBuilderFlows?: string[];
  apexTriggers?: string[]; //preparation for when supported by BE
  paths?: string[]; //preparation for when supported by BE
  cpqData?: string[];
  layouts?: string[];
};

// ******* UI types (exist as a tab in "RuleTabs") ******* //
export enum RuleType {
  validationRules = 'validationRules',
  approvalProcesses = 'approvalProcesses',
  workflowRules = 'workflowRules',
  flows = 'flows',
  processBuilderFlows = 'processBuilderFlows',
  apexTriggers = 'apexTriggers',
  cpqData = 'cpqData',
  layouts = 'layouts',
}

export type OpenAiDescription = {
  type: 'error' | 'data';
  text: string;
};

export type ApexClass = {
  id: string;
  name: string;
  label: string;
  link: string;
  annotations?: Annotations;
  openAiDescription?: OpenAiDescription;
};

export type ParsedRule = {
  id: string;
  type: RuleType;
  name: string;
  label: string;
  description?: string;
  formula?: string;
  objectApiNames: string[];
  isUsedByRecordType?: boolean;
  usedByRecordTypes?: string[];
  stagesNames?: string[];
  annotations?: Annotations;
  link?: string;
  relatedApexClasses?: ApexClass[]; //relevant for apex triggers
  flowType?: FlowTypes; //relevant for "flow" ruleType
  flowDescription?: string; //relevant for "flow" ruleType
  errorMessage?: string; //relevant for validation rules
  filename?: string;
  isActive: boolean;
};

export type ParsedRecordType = {
  label: string;
  name: string;
  id: string;
  objectApiName: string;
  description?: string;
  leadingFields: LeadingField[];
  attributions?: Attributions;
  annotations?: Annotations;
  link?: string;
  withHideButton?: boolean;
  filename?: string;
  isActive: boolean;
};

//This type is for the API call: POST /parser  (the BE should unite the objectType types....)
export type ObjectType = {
  name: string;
  label?: string; //TBD if we need it here as we're displaying SF objects instead
  visible: boolean;
};
