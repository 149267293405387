export enum VerifyURLResultKeys {
  originalUrl = 'originalUrl',
  finalRedirectUrl = 'finalRedirectUrl',
  finalRedirectDomain = 'finalRedirectDomain',
  errorDetails = 'errorDetails',
  verifyUrlTimestamp = 'verifyUrlTimestamp',
}

export enum URLStatus {
  VALID = 'VALID',
  REDIRECT = 'REDIRECT',
  ERROR = 'ERROR',
}
