import { Box } from '@mui/material';

import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { useCallback, useState } from 'react';
import { WorkflowDialog, workflowWhenOptionLabels } from '../workflow-dialog/WorflowDialog';
import { VerticalFlexBox } from '../../../funnel-map-flow/templates/plugins/PluginDetail';
import { selectCampaignWorkflows } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import ListItemActionCard from '../../../../common/ListItemActionCard';
import { Commands } from '../../../../../types/enums/Common';
import { Edit } from '@sweep-io/sweep-design/dist/icons/Edit';
import { Delete } from '@sweep-io/sweep-design/dist/icons/Delete';
import { Copy } from '@sweep-io/sweep-design/dist/icons';
import { ActionsMenuAction } from '../../../../common/actions-menu/ActionsMenu';
import { WorkflowDto } from '../types';
import { useHubspotWorkflows } from '../workflow-dialog/useHubspotWorkflows';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { telemetry } from '../../../../../telemetry';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import usePermission from '../../../../common/permissions/usePermission';

const getWorkflowDescription = (workflow: WorkflowDto) => {
  const description =
    workflowWhenOptionLabels[workflow.workflowDetails.when.whenType] + ' -> Enroll';
  return description;
};

const EnrollmentRulesListEmpty = ({
  title,
  button,
  subtitle,
}: {
  title: string;
  subtitle?: string;
  button?: {
    label: string;
    onClick: () => void;
  };
}) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <VerticalFlexBox gap={1} alignItems="center">
          <Typography variant="h3">{title}</Typography>
          {subtitle && <Typography variant="body">{subtitle}</Typography>}
          {button && (
            <Box width="fit-content">
              <Button onClick={button.onClick} startIconName="Plus">
                {button.label}
              </Button>
            </Box>
          )}
        </VerticalFlexBox>
      </Box>
    </>
  );
};

type AvailableCommands = Commands.Edit | Commands.Duplicate | Commands.Delete;

const actionsMenuItems: ActionsMenuAction<AvailableCommands>[] = [
  {
    label: Commands.Edit,
    value: Commands.Edit,
    actionIcon: <Edit />,
    addBottomDivider: true,
  },
  {
    label: Commands.Duplicate,
    value: Commands.Duplicate,
    actionIcon: <Copy />,
    addBottomDivider: true,
  },
  {
    label: Commands.Delete,
    value: Commands.Delete,
    actionIcon: <Delete color={colors.blush[600]} />,
    actionSx: { color: colors.blush[600] },
  },
];

const disabledActionsMenuItems: ActionsMenuAction<AvailableCommands>[] = [];

const EnrollmentRulesList = ({
  campaignId,
  campaignName,
}: {
  campaignId: string;
  campaignName: string;
}) => {
  const campaignWorkflows = useSelector(selectCampaignWorkflows(campaignId));
  const { addNotification } = useSweepNotifications();
  const [workflowDialog, setWorkflowDialog] = useState<{
    open: boolean;
    workflowId?: string;
    isDuplicate?: boolean;
  }>();
  const { deleteHubspotWorkflow } = useHubspotWorkflows();

  const createWorkflow = useCallback(() => {
    setWorkflowDialog({ open: true });
  }, []);
  const closeWorkflowDialog = useCallback(() => {
    setWorkflowDialog(undefined);
  }, []);

  const editWorkflow = useCallback((workflowId: string) => {
    setWorkflowDialog({ open: true, workflowId });
  }, []);

  const duplicateWorkflow = useCallback((workflowId: string) => {
    setWorkflowDialog({ open: true, workflowId, isDuplicate: true });
  }, []);

  const hasCampaigns = Boolean(campaignWorkflows.length);

  const [isWorkflowCreationAllowed] = usePermission(['create:hubspot-orgs']);

  return (
    <>
      {!hasCampaigns && (
        <EnrollmentRulesListEmpty
          title="Your enrollment rules will appear here"
          subtitle="Create your first enrollment rule for this campaign"
          button={{
            label: 'New',
            onClick: createWorkflow,
          }}
        />
      )}
      <Box mt="24px">
        {hasCampaigns &&
          campaignWorkflows.map((workflow) => {
            return (
              <ListItemActionCard
                key={workflow.id}
                title={workflow.name}
                onLineClick={() => editWorkflow(workflow.hubspotWorkflowId)}
                content={getWorkflowDescription(workflow)}
                actionsMenu={
                  isWorkflowCreationAllowed ? actionsMenuItems : disabledActionsMenuItems
                }
                isAllowedBtn={false}
                onActionMenuClick={async (action) => {
                  switch (action) {
                    case Commands.Edit:
                      editWorkflow(workflow.hubspotWorkflowId);
                      break;
                    case Commands.Duplicate:
                      duplicateWorkflow(workflow.hubspotWorkflowId);
                      break;
                    case Commands.Delete:
                      try {
                        await deleteHubspotWorkflow(workflow.id);
                      } catch (e) {
                        addNotification({
                          message: `Failed to delete workflow`,
                          keepOpen: true,
                          variant: SweepNotificationVariant.Error,
                        });
                        telemetry.captureError(e);
                        return { isError: true };
                      }
                      break;
                  }
                }}
                horizontalMargin={0}
              />
            );
          })}
        {hasCampaigns && (
          <Box mt="12px">
            <Button onClick={createWorkflow} startIconName="Plus" variant="flat">
              New
            </Button>
          </Box>
        )}
      </Box>

      {workflowDialog?.open && (
        <WorkflowDialog
          campaignId={campaignId}
          workflowId={workflowDialog.workflowId}
          onClose={closeWorkflowDialog}
          campaignName={campaignName}
          isDuplicate={workflowDialog.isDuplicate}
        />
      )}
    </>
  );
};

export const EnrollmentRulesTab = ({
  campaignId,
  campaignName,
  campaignIsActive,
}: {
  campaignId?: string;
  campaignName: string;
  campaignIsActive?: boolean;
}) => {
  if (!campaignId) {
    return <EnrollmentRulesListEmpty title=" Deploy campaign to create enrollment rules" />;
  }
  if (!campaignIsActive) {
    return (
      <EnrollmentRulesListEmpty title="Activate the campaign and re-deploy to create enrollment rules" />
    );
  }

  return <EnrollmentRulesList campaignId={campaignId} campaignName={campaignName} />;
};
