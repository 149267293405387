import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import FieldLabel from '../../common/FieldLabel';
import SweepSelect from '../../common/SweepSelect';
import { SlackConstant } from './utils';
import {
  NestedFieldsField,
  NestedFieldsSelector,
} from '../../common/fieldsSelectors/NestedFieldsSelector';
import { useSweepFieldsLabels } from '../../../sweep-fields/useSweepFieldsLabels';
import { colors } from '@sweep-io/sweep-design';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import { parseSweepFieldPolymorphicID } from '../../common/fieldsSelectors/nestedFieldsSelectorHelper';

interface SlackSelectDealroomTypeProps {
  crmOrgId: string;
  objectName: string;
  value: SweepCreatedSlackChannel | SalesforceCreatedSlackChannel;
  onChange: (value: SweepCreatedSlackChannel | SalesforceCreatedSlackChannel) => any;
  readonly?: boolean;
}

export const SlackSelectDealroomType = ({
  crmOrgId,
  objectName,
  readonly,
  value,
  onChange,
}: SlackSelectDealroomTypeProps) => {
  const { getEnrichedNestedPath } = useSweepFieldsLabels();
  const filterByEndSelectionText = (field: SweepField) => {
    const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');
    if (isResolvable) {
      return true;
    }
    if (field.fieldType === SweepFieldTypes.Text) {
      return true;
    }
    return false;
  };

  return (
    <Box sx={{ mb: '20px' }}>
      <FieldLabel label={'Choose deal room'} />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <SweepSelect
          menuMaxHeight="320px"
          FormControlProps={{
            sx: { minWidth: '296px', marginRight: '8px', mt: '3px' },
          }}
          SelectProps={{
            disabled: readonly,
            value: value?.type ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value as any;
              onChange({ type: val });
            },
          }}
        >
          {[
            SlackConstant.Sweep_Created_Slack_Channel,
            SlackConstant.Salesforce_Created_Slack_Channel,
          ].map((type) => (
            <MenuItem key={type} value={type}>
              {type === SlackConstant.Sweep_Created_Slack_Channel
                ? "Record's deal room"
                : "Related record's deal room"}
            </MenuItem>
          ))}
        </SweepSelect>
        {value?.type === SlackConstant.Salesforce_Created_Slack_Channel && (
          <Box
            sx={{
              border: `1px solid ${colors.grey[300]}`,
              borderRadius: '4px',
              background: colors.white,
              maxWidth: '500px',
              minWidth: '296px',
            }}
          >
            <NestedFieldsSelector
              placeholder="Choose field"
              readonly={readonly}
              crmOrgId={crmOrgId}
              objectType={objectName}
              filterBy={filterByEndSelectionText}
              nestedPath={getEnrichedNestedPath(value?.value?.fieldIds)}
              onChange={(sweepField: NestedFieldsField) => {
                onChange({
                  type: SlackConstant.Salesforce_Created_Slack_Channel,
                  value: { fieldIds: sweepField.fieldIds },
                });
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};
