import { Box, FormControlLabel } from '@mui/material';
import { useState, useCallback } from 'react';
import { Checkbox, Switch, Typography, colors } from '@sweep-io/sweep-design';
import { ConvertLeadPerObjectActionNew } from './ConvertLeadPerObjectActionNew';
import FieldLabel from '../common/FieldLabel';
import { NestedFieldsSelector } from '../common/fieldsSelectors/NestedFieldsSelector';
import { SFDCObjectType } from '../../types/enums/SFDCObjectType';
import { ReferenceObjectType } from '../../types/enums/ReferenceObjectType';
import { useSweepFieldsLabels } from '../../sweep-fields/useSweepFieldsLabels';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';
import { useRunOnce } from '../common/useRunOnce';
import { useCrmOrgsApiFacade } from '../../apis/facades/useCrmOrgsApiFacade';
import { telemetry } from '../../telemetry';

interface ConvertLeadActionProps {
  readonly?: boolean;
  action: ConvertLeadAutomationAction;
  crmOrgId: string;
  onChange: (stage: ConvertLeadAutomationAction) => any;
  initialObjectName: string;
  excludeValueType?: (type: SelectorValueTypes) => boolean;
  isNestedVerifiedUrl?: string;
}

export const ConvertLeadActionNew = ({
  readonly,
  action,
  crmOrgId,
  onChange,
  initialObjectName,
  excludeValueType,
  isNestedVerifiedUrl,
}: ConvertLeadActionProps) => {
  const { getObjectFunnelsAndRecordTypes } = useCrmOrgsApiFacade();

  const [isCreateOpportunity, setIsCreateOpportunity] = useState(
    !!action.actionParams?.opportunity,
  );
  const { getEnrichedNestedPath } = useSweepFieldsLabels();
  const [isUseExistingAccount, setUseExistingAccount] = useState(
    (action.actionParams?.account?.matchingObjectField?.fieldIds &&
      action.actionParams?.account?.matchingObjectField?.fieldIds?.length > 0) ??
      false,
  );
  const [isUseExistingContact, setUseExistingContact] = useState(
    (action.actionParams?.contact?.matchingObjectField?.fieldIds &&
      action.actionParams?.contact?.matchingObjectField?.fieldIds?.length > 0) ??
      false,
  );

  const getRecordTypePerObject = useCallback(
    async (objectName: string) => {
      try {
        const result = await getObjectFunnelsAndRecordTypes({
          crmOrgId,
          objectName,
        });
        return result?.recordTypes || [];
      } catch (error) {
        telemetry.captureError(error);
        return [];
      }
    },
    [crmOrgId, getObjectFunnelsAndRecordTypes],
  );

  const [recordTypesForAccount, setRecordTypesForAccount] = useState<
    ShortParserRecordType[] | undefined
  >();
  const [recordTypesForContact, setRecordTypesForContact] = useState<
    ShortParserRecordType[] | undefined
  >();
  const [recordTypesForOpportunity, setRecordTypesForOpportunity] = useState<
    ShortParserRecordType[] | undefined
  >();

  useRunOnce(async () => {
    const [rtAccount, rtContact, rtOpportunity] = await Promise.all([
      getRecordTypePerObject(SFDCObjectType.Account),
      getRecordTypePerObject(SFDCObjectType.Contact),
      getRecordTypePerObject(SFDCObjectType.Opportunity),
    ]);
    setRecordTypesForAccount(rtAccount);
    setRecordTypesForContact(rtContact);
    setRecordTypesForOpportunity(rtOpportunity);
  });

  useRunOnceWhenTruthy(
    () => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          contact: {
            recordType: recordTypesForContact && recordTypesForContact.length > 1 ? -1 : undefined,
          },
          account: {
            recordType: recordTypesForAccount && recordTypesForAccount?.length > 1 ? -1 : undefined,
          },
        },
      });
    },
    !action.actionParams && !!recordTypesForAccount && !!recordTypesForContact,
  );

  return (
    <>
      <Box sx={{ ...greyWrapper, mt: '20px' }}>
        <Typography variant="body-bold">Set Account</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '12px',
            '& label': { paddingBottom: 0 },
          }}
        >
          <FormControlLabel
            label={<FieldLabel label={'Use existing account (optional)'} />}
            control={
              <Checkbox
                disabled={readonly}
                checked={isUseExistingAccount}
                onChange={(value) => {
                  const isChecked = value.target.checked;
                  setUseExistingAccount(isChecked);
                  if (!isChecked) {
                    onChange({
                      ...action,
                      actionParams: {
                        ...action.actionParams,
                        account: {
                          ...(action.actionParams?.account || {}),
                          matchingObjectField: undefined,
                        },
                      },
                    });
                  }
                }}
              />
            }
          />

          {isUseExistingAccount && (
            <Box>
              <Box
                sx={{
                  mt: '8px',
                  mb: '12px',
                  ml: '28px',
                  border: `1px solid ${colors.grey[300]}`,
                  background: colors.white,
                  borderRadius: '4px',
                  width: '230px',
                }}
              >
                <NestedFieldsSelector
                  readonly={readonly}
                  isReferencedValue
                  restrictReferencesToObject={ReferenceObjectType.Account}
                  placeholder={'Use account from this field'}
                  crmOrgId={crmOrgId}
                  objectType={SFDCObjectType.Lead}
                  nestedPath={getEnrichedNestedPath(
                    action?.actionParams?.account?.matchingObjectField?.fieldIds,
                  )}
                  onChange={(_sweepField) => {
                    onChange({
                      ...action,
                      actionParams: {
                        ...action.actionParams,
                        account: {
                          ...(action.actionParams?.account || {}),
                          matchingObjectField: {
                            fieldIds: _sweepField.fieldIds,
                          },
                        },
                      },
                    });
                  }}
                />
              </Box>
              <Typography variant={'body'}>
                If existing account wasn’t found, create a new account with these field value(s):
              </Typography>
            </Box>
          )}
        </Box>
        <ConvertLeadPerObjectActionNew
          isNestedVerifiedUrl={isNestedVerifiedUrl}
          excludeValueType={excludeValueType}
          recordTypeList={recordTypesForAccount ?? []}
          readonly={readonly}
          crmOrgId={crmOrgId}
          action={action}
          onChange={onChange}
          objectProperty={SFDCObjectType.Account}
          objectName={SFDCObjectType.Account}
          initialObjectName={initialObjectName}
        />
      </Box>
      <Box sx={{ ...greyWrapper }}>
        <Typography variant="body-bold">Set Contact</Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '12px',
            '& label': { paddingBottom: 0 },
          }}
        >
          <FormControlLabel
            label={
              <FieldLabel
                label={'Use existing contact (optional)'}
                showTooltip
                infoTooltipTitle={
                  'If an existing contact is found, we will use their account during conversion'
                }
              />
            }
            control={
              <Checkbox
                disabled={readonly}
                checked={isUseExistingContact}
                onChange={(value) => {
                  const isChecked = value.target.checked;
                  setUseExistingContact(isChecked);
                  if (!isChecked) {
                    onChange({
                      ...action,
                      actionParams: {
                        ...action.actionParams,
                        contact: {
                          ...(action.actionParams?.contact || {}),
                          matchingObjectField: undefined,
                        },
                      },
                    });
                  }
                }}
              />
            }
          />

          {isUseExistingContact && (
            <Box>
              <Box
                sx={{
                  mt: '8px',
                  mb: '12px',
                  ml: '28px',
                  border: `1px solid ${colors.grey[300]}`,
                  background: colors.white,
                  borderRadius: '4px',
                  width: '230px',
                }}
              >
                <NestedFieldsSelector
                  readonly={readonly}
                  isReferencedValue
                  restrictReferencesToObject={SFDCObjectType.Contact}
                  placeholder={'Use contact from this field'}
                  crmOrgId={crmOrgId}
                  objectType={SFDCObjectType.Lead}
                  nestedPath={getEnrichedNestedPath(
                    action?.actionParams?.contact?.matchingObjectField?.fieldIds,
                  )}
                  onChange={(_sweepField) => {
                    onChange({
                      ...action,
                      actionParams: {
                        ...action.actionParams,
                        contact: {
                          ...(action.actionParams?.contact || {}),
                          matchingObjectField: {
                            fieldIds: _sweepField.fieldIds,
                          },
                        },
                      },
                    });
                  }}
                />
              </Box>
              <Typography variant={'body'}>
                If existing contact wasn’t found, create a new contact with these field value(s):
              </Typography>
            </Box>
          )}
        </Box>
        <ConvertLeadPerObjectActionNew
          isNestedVerifiedUrl={isNestedVerifiedUrl}
          excludeValueType={excludeValueType}
          recordTypeList={recordTypesForContact ?? []}
          readonly={readonly}
          crmOrgId={crmOrgId}
          action={action}
          onChange={onChange}
          objectProperty={SFDCObjectType.Contact}
          objectName={SFDCObjectType.Contact}
          initialObjectName={initialObjectName}
        />
      </Box>
      <Box sx={{ ...greyWrapper }}>
        <Typography variant="body-bold">Create new Opportunity</Typography>
        <Box sx={{ ml: '8px', display: 'inline-block' }}>
          <Switch
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const val = event.target.checked;
              setIsCreateOpportunity(val);
              onChange({
                ...action,
                actionParams: {
                  ...action.actionParams,
                  opportunity: val
                    ? {
                        recordType:
                          recordTypesForOpportunity && recordTypesForOpportunity?.length > 1
                            ? -1
                            : undefined,
                      }
                    : undefined,
                },
              });
            }}
            checked={isCreateOpportunity}
            disabled={readonly || !recordTypesForOpportunity}
          />
        </Box>
        {isCreateOpportunity && (
          <ConvertLeadPerObjectActionNew
            isNestedVerifiedUrl={isNestedVerifiedUrl}
            excludeValueType={excludeValueType}
            recordTypeList={recordTypesForOpportunity ?? []}
            readonly={readonly}
            crmOrgId={crmOrgId}
            action={action}
            onChange={onChange}
            objectProperty={SFDCObjectType.Opportunity}
            objectName={SFDCObjectType.Opportunity}
            initialObjectName={initialObjectName}
          />
        )}
      </Box>
    </>
  );
};
const greyWrapper = {
  backgroundColor: colors.grey[100],
  padding: '16px',
  mb: '20px',
  borderRadius: '4px',
  minWidth: 'fit-content',
};
