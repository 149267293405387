import { EnvSelector } from './EnvSelector';
import HeaderDivider from '../../../dashboard/header/HeaderDivider';

export const DashboardHeaderEnvSelector = () => {
  return (
    <>
      <HeaderDivider />
      <EnvSelector allowDeleteLastEnv />
    </>
  );
};
