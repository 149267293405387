import { useDispatch } from 'react-redux';
import { useUserInputsApiFacade } from '../../../../apis/facades/useUserInputsApiFacade';
import { setAllConfigurationsUserInputs } from '../../../../reducers/documentationReducer';
import { useEffect } from 'react';

export const useInitializeUserInputs = (crmOrgId: string) => {
  const { get_userInputsForOrg } = useUserInputsApiFacade();
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      const userInputs = await get_userInputsForOrg({ crmOrgId });
      dispatch(setAllConfigurationsUserInputs({ userInputs }));
    })();
  }, [crmOrgId, get_userInputsForOrg, dispatch]);
};
