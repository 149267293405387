import { FunnelFieldLabels } from '../../../../constants/fieldsManagementConsts';
import { ClampedText } from '../../../common/ClampedText';
import { Percentage } from '../../../common/Percentage';
import { DataTableVariant } from '../../../common/table/TableTypes';
import { dataTableVariants } from '../../../common/table/dataTableVariants';
import { ConfigurationToName, ConfigurationType } from '../../dependencies/types';
import { InitialsAvatar } from '../../shared/InitialsAvatar';
import { colors, Tag } from '@sweep-io/sweep-design';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { Fragment } from 'react/jsx-runtime';
import { TableTypography } from '../../configuration-item-tables/shared/helpers';
import { ReactNode } from 'react';
import {
  Annotations,
  ConfigurationItem,
  NameProperties,
} from '../../dependencies/DependenciesTypes';
import Stack from '@mui/material/Stack';
import { RelatedListData } from '../../../parser/ParserTypes';

const tableTypographyVariant = dataTableVariants[DataTableVariant.default].fontVariant;

const getColor = (isInactive: boolean) =>
  isInactive ? `${colors.grey[500]} !important` : 'inherit';

const renderRowCell = (
  id: string,
  key: keyof ConfigurationItem,
  value:
    | string
    | number
    | boolean
    | string[]
    | NameProperties[]
    | Annotations
    | ReactNode[]
    | RelatedListData[]
    | undefined,
  isManagePackageInstalled: boolean,
  isInactive: boolean,
  isRollup: boolean,
) => {
  switch (key) {
    case 'createdAt':
    case 'updatedAt':
      return (
        <ClampedText
          key={(id + value) as string}
          text={
            value
              ? humanizeDate({
                  dateOrTimestamp: value as string,
                })
              : '-'
          }
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      );

    case 'usage':
      return (
        <Percentage
          key={(id + value) as string}
          preCalculated={value as number}
          active={isManagePackageInstalled}
          typographyVariant="caption"
        />
      );

    case 'type':
      return (
        <ClampedText
          key={(id + value) as string}
          text={
            FunnelFieldLabels[value as SweepFieldTypes] ??
            ConfigurationToName[value as ConfigurationType] ??
            value
          }
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      );

    case 'name':
      return (
        <ClampedText
          key={(id + value) as string}
          text={typeof value === 'string' ? decodeURI(value) : (value as ReactNode)}
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      );

    case 'actions':
      return (
        <Fragment key={(id + value) as string}>
          {(value as ReactNode[])?.map((val, idx) => {
            return typeof val === 'string' ? (
              <TableTypography
                textColor={getColor(isInactive)}
                key={val}
                variant={DataTableVariant.default}
              >
                {val}
              </TableTypography>
            ) : (
              <Fragment key={idx}>{val}</Fragment>
            );
          }) ?? '-'}
        </Fragment>
      );

    case 'label':
      return (
        <Stack direction="row" gap={0.5} alignItems="center">
          <ClampedText
            key={(id + value) as string}
            text={(value ?? '-') as ReactNode}
            typographyVariant={tableTypographyVariant}
            textColor={getColor(isInactive)}
          />
          {isRollup && <Tag label="Rollup" color={colors.blue[100]} />}
        </Stack>
      );

    case 'buttons':
    case 'relatedLists':
      //not supported
      return <></>;

    default:
      return (
        <ClampedText
          key={(id + value) as string}
          text={(value ?? '-') as ReactNode}
          typographyVariant={tableTypographyVariant}
          textColor={getColor(isInactive)}
        />
      );
  }
};

export const renderRows = ({
  items,
  activeItemId,
  isManagePackageInstalled,
}: {
  items: ConfigurationItem[];
  activeItemId: string;
  isManagePackageInstalled: boolean;
}) => {
  return items.map((row) => ({
    ...Object.assign(
      {},
      ...Object.keys(row).map((key) => ({
        [key]: renderRowCell(
          row.id,
          key as keyof ConfigurationItem,
          row[key as keyof ConfigurationItem],
          !!isManagePackageInstalled,
          !row.isActive,
          !!row.isRollup,
        ),
      })),
    ),
    status: (
      <ClampedText
        key={row.id + 'status'}
        text={row.isActive ? 'Active' : 'Inactive'}
        typographyVariant={tableTypographyVariant}
        textColor={getColor(!row.isActive)}
      />
    ),
    _sfApiName: row.name, //used in calls
    _type: row.type,
    parentType: row.parentType,
    id: row.id,
    isActive: activeItemId === row.id,
    isClickable: true,
    isRollup: row.isRollup,
    createdBy: (
      <InitialsAvatar
        isInactive={!row.isActive}
        showUserFullName
        userIdOrName={row.createdBy}
        changedAt={row.createdAt}
      />
    ),
    updatedBy: (
      <InitialsAvatar
        showUserFullName
        isInactive={!row.isActive}
        userIdOrName={row.updatedBy}
        changedAt={row.updatedAt}
      />
    ),
  }));
};
