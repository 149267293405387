import { useCallback, useMemo } from 'react';
import { useCanvasFilters } from '../pages/configuration-canvas-filters/useCanvasFilters';
import { StepsFilterData } from '../pages/configuration-canvas-filters/filterTypes';
import { getRecordTypeIdentifier, getStepsFromAutomation } from './helper';
import { classifyAutomation } from '../pages/configuration-canvas/utils/automationClassificationHelper';
import { AutomationCanvasFilter } from '../pages/canvas-pages/canvasAutomationsTypes';
import { filterItemsBySearch } from '../../lib/filterItemsBySearch';
import { AdvancedFilterItemValue } from '../common/advanced-filter/AdvancedFilter';

// Tests if an automation is of a record type
const isAutomationOfRecordTypes = (automation: AutomationStructureNew, recordTypeIds: string[]) => {
  if (recordTypeIds.length === 0) {
    return false;
  }
  return recordTypeIds.some((recordTypeId) => {
    if (automation.funnelRecordType) {
      const automationRecordTypeId = getRecordTypeIdentifier(
        automation.funnelRecordType.name,
        automation.funnelRecordType.objectName,
      );
      return recordTypeId === automationRecordTypeId;
    }
    if (
      automation.automationDetails.recordTypes &&
      automation.automationDetails.recordTypes.length > 0
    ) {
      return automation.automationDetails.recordTypes.some((recordType) => {
        const automationRecordTypeId = getRecordTypeIdentifier(
          recordType.name,
          automation.objectName,
        );
        return recordTypeId === automationRecordTypeId;
      });
    }
  });
};

const isAutomationOfStep = (
  automation: AutomationStructureNew,
  items: AdvancedFilterItemValue<StepsFilterData>[],
) => {
  const automationClassification = classifyAutomation(automation);
  if (automationClassification.level === 'step') {
    return items.some((item) => {
      const stepsInAutomation = getStepsFromAutomation(automation.automationDetails, '_stageId');
      return stepsInAutomation.includes(item.value);
    });
  }
  return false;
};

export const useCanvasAutomationFilters = ({
  automations,
  search,
  setSearch,
}: {
  automations: AutomationStructureNew[];
  search: string;
  setSearch: (search: string) => void;
}) => {
  const { filterValues: canvasFilterValues, clearAllValues } = useCanvasFilters();

  const clearFilters = useCallback(() => {
    clearAllValues();
    setSearch('');
  }, [clearAllValues, setSearch]);

  const objectsFilter = canvasFilterValues[AutomationCanvasFilter.OBJECTS];
  const recordTypesCanvasFilter = canvasFilterValues[AutomationCanvasFilter.RECORD_TYPES];
  const stepsFilter = canvasFilterValues[AutomationCanvasFilter.STEPS];

  const actionsFilter = canvasFilterValues[AutomationCanvasFilter.ACTIONS];
  const dedupAndMatchFilter = canvasFilterValues[AutomationCanvasFilter.OPERATIONS];
  const deployRequestsFilter = canvasFilterValues[AutomationCanvasFilter.DEPLOYMENT_REQUEST];

  const filteredAutomations = useMemo(() => {
    let _filteredAutomations = [...automations];

    if (stepsFilter?.selectedValuesData && stepsFilter?.selectedValuesData.length) {
      const stepsData = stepsFilter.selectedValuesData.map((stepData) => stepData);
      _filteredAutomations = _filteredAutomations.filter((automation) =>
        isAutomationOfStep(automation, stepsData),
      );
    } else if (
      recordTypesCanvasFilter?.selectedValues &&
      recordTypesCanvasFilter?.selectedValues.length
    ) {
      const recordTypeIds = recordTypesCanvasFilter.selectedValues;

      _filteredAutomations = _filteredAutomations.filter(
        (automation) =>
          !automation.funnelRecordType || isAutomationOfRecordTypes(automation, recordTypeIds),
      );
    }

    if (objectsFilter?.selectedValues && objectsFilter?.selectedValues.length) {
      const objectNames = objectsFilter.selectedValues;
      _filteredAutomations = _filteredAutomations.filter((automation) =>
        objectNames.includes(automation.objectName),
      );
    }
    if (actionsFilter?.selectedValues.length) {
      _filteredAutomations = _filteredAutomations.filter((automation) => {
        const actionsInUse = automation.automationDetails.actions.map(
          ({ actionType }) => actionType as AutomationActionType,
        );
        return actionsFilter.selectedValues.some((actionType) =>
          actionsInUse.includes(actionType as AutomationActionType),
        );
      });
    }

    if (dedupAndMatchFilter?.selectedValues?.length) {
      _filteredAutomations = _filteredAutomations.filter((automation) => {
        const action = automation?.automationDetails?.actions?.[0];
        if (!action) return false;

        if (action.actionType === 'DEDUP' || action.actionType === 'MATCHING') {
          return dedupAndMatchFilter.selectedValues.includes(action.actionParams.dmType as string);
        }
      });
    }

    if (
      deployRequestsFilter?.selectedValues.length === 1 &&
      deployRequestsFilter.selectedValues[0] === 'true'
    ) {
      _filteredAutomations = _filteredAutomations.filter((automation) => {
        return automation.notifyAdmin;
      });
    }

    return filterItemsBySearch<AutomationStructureNew>(_filteredAutomations, search, 'name');
  }, [
    automations,
    stepsFilter?.selectedValuesData,
    recordTypesCanvasFilter?.selectedValues,
    objectsFilter?.selectedValues,
    actionsFilter?.selectedValues,
    dedupAndMatchFilter?.selectedValues,
    deployRequestsFilter?.selectedValues,
    search,
  ]);

  return {
    filteredAutomations,
    clearFilters,
    filtersJsx: null,
  };
};
