import { TemplatesPageHeader } from '../../../dialog/TemplatesPageHeader';
import { PluginDynamicPathSvg } from '../../icons';
import { PluginDetail, VerticalFlexBox } from '../../PluginDetail';
import { useOnAddDynamicPathPlugin } from '../useOnAddDynamicPathPlugin';
import { ConfigurationCanvasPanelContent } from '../../../../../configuration-canvas-panel';

export const dynamicPathPluginDesc1 =
  'The Dynamic Path Plug-in enhances your business process in Salesforce by providing a visual representation of stages and statuses. To fully utilize this plug-in, ensure that the Sweep Lightning component is added to the layout in Salesforce.';

export const DynamicPathPage = ({ onBackClick }: { onBackClick: () => void }) => {
  const onDynamicPathPluginAdd = useOnAddDynamicPathPlugin();
  return (
    <>
      <TemplatesPageHeader
        onBackClick={onBackClick}
        title={'Dynamic Path Plugin'}
        onButtonClick={onDynamicPathPluginAdd}
        buttonText="Add to Funnel"
        imageIcon={<PluginDynamicPathSvg />}
      />
      <ConfigurationCanvasPanelContent>
        <VerticalFlexBox gap={3} pt={2}>
          <PluginDetail gap={1} title="Description" description={dynamicPathPluginDesc1} />
          <VerticalFlexBox gap={2}>
            <VerticalFlexBox gap={1}>
              <PluginDetail
                gap={1}
                title="This Lightning component offers several valuable features:"
                description="1. It visually represents the stages and current status of records, allowing users to easily track progress."
              />
              <PluginDetail description="2. Users can add customized instructions or guidance for each stage, improving clarity and consistency in the sales process." />
              <PluginDetail description="3. Important fields relevant to each stage can be displayed, ensuring quick access to key information." />
              <PluginDetail description="4. Furthermore, users have the ability to customize the component by adding additional important fields and tailoring it according to their specific needs." />
            </VerticalFlexBox>
            <PluginDetail
              gap={1}
              description="With the Dynamic Path Plug-in, you have the flexibility to edit the Lightning component, add step descriptions, and include additional fields, aligning the visualization and process tracking to perfectly match your business requirements."
            />
          </VerticalFlexBox>
        </VerticalFlexBox>
        <VerticalFlexBox gap={3} pt={3}>
          <VerticalFlexBox gap={2}>
            <VerticalFlexBox gap={1}>
              <PluginDetail
                gap={1}
                title="How to add the Sweep Lightning component to a page layout:"
                description="1. Navigate to the page where you want to add the lightning component (e.g., an opportunity page)."
              />
              <PluginDetail description="2. Go to Setup and click 'Edit Page.'" />
              <PluginDetail
                description="3. In the left Components panel, under the Custom - Managed dropdown, locate the Sweep Sales Guidance component. Drag and drop it onto the layout.
"
              />
              <PluginDetail description="4. Click 'Activation.'" />
              <PluginDetail description="5. Save your changes." />
              <PluginDetail
                description="5. Repeat these steps for any other page layouts where you want to add the lightning component.
"
              />
            </VerticalFlexBox>
          </VerticalFlexBox>
        </VerticalFlexBox>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
