import { TabList } from '@sweep-io/sweep-design';
import { useDispatch } from 'react-redux';
import { setLongTemplate, setShortTemplates } from './templatesDialogReducer';
import { useCallback, useState } from 'react';
import { TemplatesTab } from '../templates-tab/TemplatesTab';
import { TemplateDetails } from '../templates-tab/TemplateDetails';
import { TemplateInterface } from '../templatesTypes';
import { useTemplatesFacade } from '../../../../../apis/facades/useTemplatesFacade';
import { useRunOnce } from '../../../../common/useRunOnce';
import { TemplatesPluginsTab } from '../plugins-tab/TemplatePluginsTab';
import { DataManagementPage } from '../plugins/data-management/tab/DataManagementPage';
import { BantPage } from '../plugins/bant/tab/BantPage';
import { DynamicPathPage } from '../plugins/dynamic-path/tab/DynamicPathPage';
import { MeddicPage } from '../plugins/meddic/tab/MeddicPage';
import { MeddpiccPage } from '../plugins/meddpicc/tab/MeddpiccPage';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../../../configuration-canvas-panel';

enum TemplateTabTypes {
  Templates = 'TEMPLATES',
  Plugins = 'PLUGINS',
}

interface TemplateDialogProps {
  crmOrgId: string;
  onAddTemplate: (template: TemplateInterface, useFieldMapping: boolean) => void;
  closeDialog: () => void;
  isOpen: boolean;
}

export enum TemplatePageType {
  TABS = 'TABS',
  TEMPLATE_DETAILS = 'TEMPLATE_DETAILS',
  DATA_MANAGEMENT_PLUGIN = 'DATA_MANAGEMENT_PLUGIN',
  BANT_PLUGIN = 'BANT_PLUGIN',
  MEDDIC_PLUGIN = 'MEDDIC_PLUGIN',
  MEDDPICC_PLUGIN = 'MEDDPICC_PLUGIN',
  DYNAMIC_PATH = 'DYNAMIC_PATH',
}

interface AbstractTemplatePage {
  type: TemplatePageType;
}

interface TemplatePageTabs extends AbstractTemplatePage {
  type: TemplatePageType.TABS;
  currentTab: TemplateTabTypes;
}

interface TemplatePageTemplateDetails extends AbstractTemplatePage {
  type: TemplatePageType.TEMPLATE_DETAILS;
  templateId: string;
}

interface TemplatePageDataManagementPlugin extends AbstractTemplatePage {
  type: TemplatePageType.DATA_MANAGEMENT_PLUGIN;
}

interface TemplatePageBantPlugin extends AbstractTemplatePage {
  type: TemplatePageType.BANT_PLUGIN;
}

interface TemplatePageMeddicPlugin extends AbstractTemplatePage {
  type: TemplatePageType.MEDDIC_PLUGIN;
}

interface TemplatePageMeddpiccPlugin extends AbstractTemplatePage {
  type: TemplatePageType.MEDDPICC_PLUGIN;
}

interface TemplatePageDynamicPathPlugin extends AbstractTemplatePage {
  type: TemplatePageType.DYNAMIC_PATH;
}

type TemplatePage =
  | TemplatePageTabs
  | TemplatePageTemplateDetails
  | TemplatePageDataManagementPlugin
  | TemplatePageBantPlugin
  | TemplatePageMeddicPlugin
  | TemplatePageMeddpiccPlugin
  | TemplatePageDynamicPathPlugin;

export const TemplatesDialogContent = ({
  crmOrgId,
  onAddTemplate,
  closeDialog,
}: TemplateDialogProps) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<TemplatePage>({
    type: TemplatePageType.TABS,
    currentTab: TemplateTabTypes.Templates,
  });

  const { get_templates, get_template } = useTemplatesFacade();

  useRunOnce(async () => {
    const shortTemplates = await get_templates();
    dispatch(setShortTemplates(shortTemplates));
  });

  const onSelectTemplate = useCallback((templateId: string) => {
    setCurrentPage({ type: TemplatePageType.TEMPLATE_DETAILS, templateId });
  }, []);

  const onQuickAddTemplate = useCallback(
    async (templateId: string) => {
      const template = await get_template(templateId);
      dispatch(setLongTemplate({ ...template, id: templateId }));
      onAddTemplate(template, false);
      closeDialog();
    },
    [closeDialog, dispatch, get_template, onAddTemplate],
  );

  return (
    <>
      {currentPage.type === TemplatePageType.TABS && (
        <>
          <ConfigurationCanvasPanelHeader onClose={closeDialog}>
            Templates 🌈
          </ConfigurationCanvasPanelHeader>
          <ConfigurationCanvasPanelContent>
            <TabList
              onChange={(event, newValue) =>
                setCurrentPage({ type: TemplatePageType.TABS, currentTab: newValue })
              }
              defaultValue={currentPage.currentTab}
              list={[
                {
                  label: 'Templates',
                  content: (
                    <TemplatesTab
                      onSelectTemplate={onSelectTemplate}
                      onAddTemplate={onQuickAddTemplate}
                      crmOrgId={crmOrgId}
                      closeDialog={closeDialog}
                    />
                  ),
                  value: TemplateTabTypes.Templates,
                },
                {
                  label: 'Plugins',
                  content: (
                    <TemplatesPluginsTab
                      onPluginSelect={(pluginType) => {
                        switch (pluginType) {
                          case TemplatePageType.DATA_MANAGEMENT_PLUGIN:
                            setCurrentPage({
                              type: TemplatePageType.DATA_MANAGEMENT_PLUGIN,
                            });
                            break;
                          case TemplatePageType.BANT_PLUGIN:
                            setCurrentPage({
                              type: TemplatePageType.BANT_PLUGIN,
                            });
                            break;
                          case TemplatePageType.MEDDIC_PLUGIN:
                            setCurrentPage({
                              type: TemplatePageType.MEDDIC_PLUGIN,
                            });
                            break;
                          case TemplatePageType.MEDDPICC_PLUGIN:
                            setCurrentPage({
                              type: TemplatePageType.MEDDPICC_PLUGIN,
                            });
                            break;

                          case TemplatePageType.DYNAMIC_PATH:
                            setCurrentPage({
                              type: TemplatePageType.DYNAMIC_PATH,
                            });
                            break;

                          default:
                            break;
                        }
                      }}
                    />
                  ),
                  value: TemplateTabTypes.Plugins,
                },
              ]}
            />
          </ConfigurationCanvasPanelContent>
        </>
      )}

      {currentPage.type === TemplatePageType.TEMPLATE_DETAILS && (
        <TemplateDetails
          templateId={currentPage.templateId}
          onBackClick={() =>
            setCurrentPage({ type: TemplatePageType.TABS, currentTab: TemplateTabTypes.Templates })
          }
          crmOrgId={crmOrgId}
          onAddTemplate={(template) => {
            onAddTemplate(template, true);
            closeDialog();
          }}
        />
      )}
      {currentPage.type === TemplatePageType.DATA_MANAGEMENT_PLUGIN && (
        <DataManagementPage
          onBackClick={() => {
            setCurrentPage({ type: TemplatePageType.TABS, currentTab: TemplateTabTypes.Plugins });
          }}
        />
      )}
      {currentPage.type === TemplatePageType.BANT_PLUGIN && (
        <BantPage
          onBackClick={() => {
            setCurrentPage({ type: TemplatePageType.TABS, currentTab: TemplateTabTypes.Plugins });
          }}
        />
      )}
      {currentPage.type === TemplatePageType.MEDDIC_PLUGIN && (
        <MeddicPage
          onBackClick={() => {
            setCurrentPage({ type: TemplatePageType.TABS, currentTab: TemplateTabTypes.Plugins });
          }}
        />
      )}
      {currentPage.type === TemplatePageType.MEDDPICC_PLUGIN && (
        <MeddpiccPage
          onBackClick={() => {
            setCurrentPage({ type: TemplatePageType.TABS, currentTab: TemplateTabTypes.Plugins });
          }}
        />
      )}

      {currentPage.type === TemplatePageType.DYNAMIC_PATH && (
        <DynamicPathPage
          onBackClick={() => {
            setCurrentPage({ type: TemplatePageType.TABS, currentTab: TemplateTabTypes.Plugins });
          }}
        />
      )}
    </>
  );
};
