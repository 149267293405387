import { colors } from '@sweep-io/sweep-design';

interface TextWithMentionsProps {
  text: string;
  disableHighlight?: boolean;
  highlightColor?: string;
  highlightBold?: boolean;
}
const uuidRegex =
  /\([0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}\)/g;

export const TextWithMentions = ({
  text = '',
  disableHighlight,
  highlightColor = colors.blue[500],
  highlightBold = false,
}: TextWithMentionsProps) => {
  const parse = (text: string) => {
    return text
      .replaceAll(uuidRegex, '')
      .replaceAll(
        '@[',
        !disableHighlight
          ? `<span style="color: ${highlightColor}; font-weight: ${
              highlightBold ? 'bold' : 'normal'
            }">`
          : '',
      )
      .replaceAll(']', !disableHighlight ? `</span>` : '');
  };

  return <span dangerouslySetInnerHTML={{ __html: parse(text) }} />;
};
