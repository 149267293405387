import { Box, FormControl, FormControlLabel, FormLabel, RadioGroup } from '@mui/material';
import { Radio, Typography } from '@sweep-io/sweep-design';
import React from 'react';

interface RadioListItemProps {
  value: string;
  label: string;
  decorator?: React.ReactNode;
}

interface RadioListProps {
  listItems: RadioListItemProps[];
  onChange: (itemId: string) => void;
  title?: string;
  value: string;
  maxListHeight?: number;
}

export const RadioList = ({ listItems, title, onChange, value, maxListHeight }: RadioListProps) => {
  return (
    <FormControl sx={{ maxHeight: maxListHeight + 'px' }}>
      {title && <FormLabel id="demo-radio-buttons-group-label">{title}</FormLabel>}
      <RadioGroup value={value} onChange={(event) => onChange(event.target.value)}>
        {listItems.map((item) => (
          <FormControlLabel
            key={item.value}
            sx={{ padding: '8px 32px' }}
            value={item.value}
            control={<Radio />}
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="body">{item.label}</Typography>
                {item.decorator}
              </Box>
            }
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
