import { Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import {
  PageTemplateGrid,
  PageTemplateCard,
  PageTemplateActionButton,
  PageTemplateHeaderWrapper,
  getColorForTemplateCard,
} from '../../pages-templates';
import { DedupMatchingType } from '../../../../types/enums/DedupMatchingType';
import { ObjectTypeChip } from '../../../common/ObjectTypeChip';
import { useCallback } from 'react';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import { SFDCObjectType } from '../../../../types/enums/SFDCObjectType';

export const DedupTemplateContent = ({
  onSelect,
}: {
  onSelect: (object: string, type?: DedupMatchingType) => void;
}) => {
  const { caseToCaseDedupe, opp2OppDedupe } = useFeatureToggle();

  const tag = (objectType: string) => <ObjectTypeChip label={objectType} objectType={objectType} />;
  const leadTag = tag(SFDCObjectType.Lead);
  const contactTag = tag(SFDCObjectType.Contact);
  const accountTag = tag(SFDCObjectType.Account);
  const caseTag = tag(SFDCObjectType.Case);
  const opportunityTag = tag(SFDCObjectType.Opportunity);

  const onSelectBuilder = useCallback(
    (object: string, type?: DedupMatchingType) => () => onSelect(object, type),
    [onSelect],
  );

  return (
    <Stack gap={4}>
      <Stack gap={2}>
        <Typography variant="body-medium">Deduplication</Typography>
        <PageTemplateGrid>
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard(SFDCObjectType.Lead)}
            onCardClick={onSelectBuilder(SFDCObjectType.Lead, DedupMatchingType.LEAD_TO_LEAD_DEDUP)}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder(SFDCObjectType.Lead, DedupMatchingType.LEAD_TO_LEAD_DEDUP)}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to lead deduplication"
            headerContent={<PageTemplateHeaderWrapper firstItem={leadTag} secondItem={leadTag} />}
          />
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard(SFDCObjectType.Contact)}
            onCardClick={onSelectBuilder(
              SFDCObjectType.Contact,
              DedupMatchingType.CONTACT_TO_CONTACT_DEDUP,
            )}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder(
                  SFDCObjectType.Contact,
                  DedupMatchingType.CONTACT_TO_CONTACT_DEDUP,
                )}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Contact to contact deduplication"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={contactTag} secondItem={contactTag} />
            }
          />
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard(SFDCObjectType.Account)}
            onCardClick={onSelectBuilder(
              SFDCObjectType.Account,
              DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP,
            )}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder(
                  SFDCObjectType.Account,
                  DedupMatchingType.ACCOUNT_TO_ACCOUNT_DEDUP,
                )}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Account to account deduplication"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={accountTag} secondItem={accountTag} />
            }
          />
          {caseToCaseDedupe && (
            <PageTemplateCard
              stripeColor1={getColorForTemplateCard(SFDCObjectType.Case)}
              onCardClick={onSelectBuilder(
                SFDCObjectType.Case,
                DedupMatchingType.CASE_TO_CASE_DEDUP,
              )}
              actionsContent={
                <PageTemplateActionButton
                  onClick={onSelectBuilder(
                    SFDCObjectType.Case,
                    DedupMatchingType.CASE_TO_CASE_DEDUP,
                  )}
                >
                  Set up
                </PageTemplateActionButton>
              }
              description="Case to Case deduplication"
              headerContent={<PageTemplateHeaderWrapper firstItem={caseTag} secondItem={caseTag} />}
            />
          )}
          {opp2OppDedupe && (
            <PageTemplateCard
              stripeColor1={getColorForTemplateCard(SFDCObjectType.Opportunity)}
              onCardClick={onSelectBuilder(
                SFDCObjectType.Opportunity,
                DedupMatchingType.OPP_TO_OPP_DEDUP,
              )}
              actionsContent={
                <PageTemplateActionButton
                  onClick={onSelectBuilder(
                    SFDCObjectType.Opportunity,
                    DedupMatchingType.OPP_TO_OPP_DEDUP,
                  )}
                >
                  Set up
                </PageTemplateActionButton>
              }
              description="Opportunity to Opportunity deduplication"
              headerContent={
                <PageTemplateHeaderWrapper firstItem={opportunityTag} secondItem={opportunityTag} />
              }
            />
          )}
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard(SFDCObjectType.Lead)}
            stripeColor2={getColorForTemplateCard(SFDCObjectType.Contact)}
            onCardClick={onSelectBuilder(
              SFDCObjectType.Lead,
              DedupMatchingType.LEAD_TO_CONTACT_DEDUP,
            )}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder(
                  SFDCObjectType.Lead,
                  DedupMatchingType.LEAD_TO_CONTACT_DEDUP,
                )}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to contact deduplication"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={leadTag} secondItem={contactTag} />
            }
          />
        </PageTemplateGrid>
      </Stack>
      <Stack gap={2}>
        <Typography variant="body-medium">Matching</Typography>
        <PageTemplateGrid>
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard(SFDCObjectType.Lead)}
            onCardClick={onSelectBuilder(
              SFDCObjectType.Lead,
              DedupMatchingType.LEAD_TO_LEAD_MATCHING,
            )}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder(
                  SFDCObjectType.Lead,
                  DedupMatchingType.LEAD_TO_LEAD_MATCHING,
                )}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to lead matching"
            headerContent={<PageTemplateHeaderWrapper firstItem={leadTag} secondItem={leadTag} />}
          />
          <PageTemplateCard
            stripeColor1={getColorForTemplateCard(SFDCObjectType.Lead)}
            stripeColor2={getColorForTemplateCard(SFDCObjectType.Account)}
            onCardClick={onSelectBuilder(
              SFDCObjectType.Lead,
              DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING,
            )}
            actionsContent={
              <PageTemplateActionButton
                onClick={onSelectBuilder(
                  SFDCObjectType.Lead,
                  DedupMatchingType.LEAD_TO_ACCOUNT_MATCHING,
                )}
              >
                Set up
              </PageTemplateActionButton>
            }
            description="Lead to account matching"
            headerContent={
              <PageTemplateHeaderWrapper firstItem={leadTag} secondItem={accountTag} />
            }
          />
        </PageTemplateGrid>
      </Stack>
    </Stack>
  );
};
