import { useContext, useEffect } from 'react';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { DependenciesSideDialog } from './dependencies/DependenciesSideDialog';
import { ObjectListWrapper } from './object-list/ObjectListWrapper';
import { useDocumentation } from './useDocumentation';
import { SelectedObjectWithList } from './selected-object/list-mode/SelectedObjectWithList';
import { useDependencies } from './dependencies/hooks/useDependencies';
import { useInitializeObjectsStructure } from './hooks/useInitializeObjectsStructure';
import useObjectTypesWithFetch from '../../hooks/useObjectTypesWithFetch';
import { useSelector } from 'react-redux';
import { selectSingleObject } from '../../reducers/documentationReducer';
import { ExpandedObject } from './selected-object/expanded-mode/ExpandedObject';
import { useInitializeLayouts } from './hooks/useInitializeLayouts';
import { useInitializeUserInputs } from './dependencies/hooks/useInitializeUserInputs';
import { ConfigurationType } from '@server/parser';
import { usePrepareActiveObjectStructure } from './selected-object/usePrepareActiveObjectStructure';
import { DocumentationDialogContext } from './context/DocumentationContextProvider';
import { useExpandedMode } from '../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { DispatchSingleObjectNameAndFocusOnElementFuncProps } from './types';

interface DocumentationDialogProps {
  isOpen: boolean;
  crmOrgId: string;
  onCloseDialog?: () => void;
  hideAddObjectButton?: boolean;
  keepState?: boolean;
  dispatchSingleObjectNameAndFocusOnElement: DispatchSingleObjectNameAndFocusOnElementFuncProps;
}

export const DocumentationDialog = ({
  crmOrgId,
  isOpen,
  onCloseDialog,
  hideAddObjectButton,
  keepState,
  dispatchSingleObjectNameAndFocusOnElement,
}: DocumentationDialogProps & { hideAddObjectButton?: boolean }) => {
  const { isLoading, objectTypes } = useObjectTypesWithFetch({ crmOrgId });
  const { funnelsData, recordTypesData } = useContext(DocumentationDialogContext);
  const { isExpandedMode } = useExpandedMode();
  const objectTypeName = useSelector(selectSingleObject);

  useInitializeObjectsStructure({ funnelsData, recordTypesData, crmOrgId, objectTypes });
  useInitializeLayouts(crmOrgId);
  useInitializeUserInputs(crmOrgId);

  const { setActiveObjectElements } = useDocumentation();

  const list = usePrepareActiveObjectStructure(crmOrgId);

  useEffect(() => {
    if (objectTypeName?.objectType) {
      setActiveObjectElements(list);
    }
  }, [list, objectTypeName?.objectType, setActiveObjectElements]);

  const isLoadingObjects = isLoading;
  const singleObjectName = useSelector(selectSingleObject);

  const { handleClose } = useDocumentation();
  const { configurationItem, dependenciesData } = useDependencies(crmOrgId);

  const displayDependencies = !!(configurationItem && dependenciesData?.parentType);
  const displaySingleObject = !!singleObjectName;
  const displayObjectList = !singleObjectName && !isLoadingObjects;

  useEffect(() => {
    return () => {
      !keepState && handleClose();
    };
  }, [handleClose, keepState]);

  if (!isOpen) {
    return <></>;
  }

  return (
    <>
      {isLoadingObjects && <CenteredCircularProgress />}

      {displayObjectList && (
        <ObjectListWrapper
          crmOrgId={crmOrgId}
          onCloseDialog={onCloseDialog}
          hideAddObjectButton={hideAddObjectButton}
          withExpandedModeButton
          dispatchSingleObjectNameAndFocusOnElement={dispatchSingleObjectNameAndFocusOnElement}
        />
      )}

      {displaySingleObject && !isExpandedMode && (
        <SelectedObjectWithList
          crmOrgId={crmOrgId}
          objectTypeName={singleObjectName}
          onCloseDialog={onCloseDialog}
          withExpandedModeButton
          hideAddObjectButton={hideAddObjectButton}
          dispatchSingleObjectNameAndFocusOnElement={dispatchSingleObjectNameAndFocusOnElement}
        />
      )}

      {displaySingleObject && isExpandedMode && (
        <ExpandedObject
          crmOrgId={crmOrgId}
          objectTypeName={singleObjectName}
          onCloseDialog={onCloseDialog}
          hideAddObjectButton={hideAddObjectButton}
          dispatchSingleObjectNameAndFocusOnElement={dispatchSingleObjectNameAndFocusOnElement}
        />
      )}

      {displayDependencies && !isExpandedMode && (
        <DependenciesSideDialog
          crmOrgId={crmOrgId}
          configurationItem={configurationItem}
          type={dependenciesData?.parentType as ConfigurationType}
        />
      )}
    </>
  );
};
