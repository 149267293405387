import { useCallback, useState } from 'react';
import { Typography } from '@sweep-io/sweep-design';
import { ExpandButtonSimple } from '../../../../common/ExpandButtonSimple';
import { TagsLine } from './TagsLine';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useSelector } from 'react-redux';
import { parseIdToValue, parseIdToValueAndNameToLabel } from './utils';
import { selectAccountUsers } from '../../../../../reducers/accountUsersReducer';
import { UserInputTypes } from '@server/user-inputs';
import { selectDependencyCustomNotesById } from '../../../../../reducers/documentationReducer';
import { useConfigurationTags } from './useConfigurationTags';
import { selectTagsList } from '../../../../../reducers/tagsReducer';
import { SectionBox } from '../../helpers';
import { useFeatureToggle } from '../../../../common/useFeatureToggle';
import { selectParserInfo } from '../../../../../reducers/global/globalReducer';
import { InlineAutocompleteItem } from '../../../../common/InlineAutocomplete/InlineAutocomplete';
import { UserStatus } from '../../../../../types/UserInfoTypes';

interface TagsSectionProps {
  configurationItemId: string;
  crmOrgId: string;
  objectNames: string[];
}

export const TagsSection = ({ configurationItemId, crmOrgId, objectNames }: TagsSectionProps) => {
  const { annotationsV2 } = useFeatureToggle();
  const [isExpanded, setIsExpanded] = useState(true);

  const tags = useSelector(selectTagsList);
  const users = useSelector(selectAccountUsers) ?? [];
  const onlyActiveUsers = users.filter((user) => user.status === UserStatus.Active);
  const configurationTags = useSelector(selectDependencyCustomNotesById(configurationItemId));
  const _parsedUsers = parseIdToValueAndNameToLabel(onlyActiveUsers);
  const parserInfo = useSelector(selectParserInfo(crmOrgId));

  const { onSelectItem, onCreateTag, onDeleteItem, onTagClick } = useConfigurationTags(
    configurationItemId,
    crmOrgId,
  );

  const _onSelectItem = useCallback(
    (item: InlineAutocompleteItem, parentKey: UserInputTypes) => {
      const notParsedObjects = objectNames?.filter(
        (name) => !parserInfo?.parsedObjectNames?.includes(name),
      );
      onSelectItem(item, parentKey, notParsedObjects);
    },
    [onSelectItem, objectNames, parserInfo?.parsedObjectNames],
  );

  const _onCreateTag = useCallback(
    (value: string) => {
      const notParsedObjects = objectNames?.filter(
        (name) => !parserInfo?.parsedObjectNames?.includes(name),
      );
      onCreateTag(value, notParsedObjects);
    },
    [onCreateTag, objectNames, parserInfo?.parsedObjectNames],
  );

  return (
    <SectionBox>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ cursor: 'pointer' }}
      >
        <Typography variant="h4">Annotations</Typography>
        <ExpandButtonSimple buttonSize="tiny" isExpanded={isExpanded} />
      </Box>

      <Collapse in={isExpanded}>
        <Stack pl={2} pt={1.5}>
          <TagsLine
            title="Tags"
            placeholder="Select or create value"
            onSelectItem={(item) => _onSelectItem(item, UserInputTypes.Tags)}
            selectedItems={configurationTags?.[UserInputTypes.Tags] ?? []}
            tags={parseIdToValue(tags)}
            onCreate={_onCreateTag}
            onClick={annotationsV2 ? onTagClick : undefined}
            onDeleteItem={(item) => onDeleteItem(item, UserInputTypes.Tags)}
            headerTxt="Search tag or create one"
          />

          <TagsLine
            title="Owner"
            placeholder="Select Owner"
            onSelectItem={(item) => _onSelectItem(item, UserInputTypes.Owner)}
            selectedItems={
              configurationTags?.[UserInputTypes.Owner]
                ? [configurationTags[UserInputTypes.Owner]]
                : []
            }
            tags={_parsedUsers}
            onDeleteItem={(item) => onDeleteItem(item, UserInputTypes.Owner)}
            inlineComponentVariant="users"
            headerTxt="Users"
            closeOnItemSelection
            onClick={annotationsV2 ? onTagClick : undefined}
          />

          <TagsLine
            title="Collaborators"
            placeholder="Select Collaborators"
            onSelectItem={(item) => _onSelectItem(item, UserInputTypes.Collaborators)}
            selectedItems={configurationTags?.[UserInputTypes.Collaborators] ?? []}
            tags={_parsedUsers}
            onDeleteItem={(item) => onDeleteItem(item, UserInputTypes.Collaborators)}
            inlineComponentVariant="users"
            noBottomBorder
            headerTxt="Users"
            onClick={annotationsV2 ? onTagClick : undefined}
          />
        </Stack>
      </Collapse>
    </SectionBox>
  );
};
