import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLayoutsByObjectName, setLayouts } from '../../../reducers/documentationReducer';
import { useLayouts } from './useLayouts';
import { selectParserInfo } from '../../../reducers/global/globalReducer';

/**
 * Setting layouts to documentation reducer to have data accessible in one place
 */

export const useInitializeLayouts = (crmOrgId: string) => {
  const dispatch = useDispatch();
  const { fetchLayoutsData } = useLayouts();

  const parsedInfo = useSelector(selectParserInfo(crmOrgId));
  const crmOrgParsedObjects = useMemo(
    () => parsedInfo?.parsedObjectNames ?? [],
    [parsedInfo?.parsedObjectNames],
  );
  const layoutsByObjectFromGlobal = parsedInfo?.layouts;
  const layoutsByObjectInDocumentation = useSelector(selectLayoutsByObjectName(crmOrgId));

  const notAssignedObjects = useMemo(() => {
    const result: string[] = [];
    crmOrgParsedObjects.forEach((objectName) => {
      const { isLoading, layouts } = layoutsByObjectInDocumentation?.[objectName] ?? {};

      if (!isLoading && !layouts) {
        result.push(objectName);
      }
    });

    return result;
  }, [crmOrgParsedObjects, layoutsByObjectInDocumentation]);

  useEffect(() => {
    if (crmOrgId && notAssignedObjects.length > 0) {
      notAssignedObjects.forEach((objectName) => {
        if (layoutsByObjectFromGlobal?.[objectName]?.layouts) {
          dispatch(
            setLayouts({
              crmOrgId,
              objectName,
              isLoading: false,
              layouts: layoutsByObjectFromGlobal?.[objectName]?.layouts,
            }),
          );
        } else {
          fetchLayoutsData({ crmOrgId, objectName });
        }
      });
    }
  }, [dispatch, notAssignedObjects, layoutsByObjectFromGlobal, crmOrgId, fetchLayoutsData]);
};
