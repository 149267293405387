import SelectedGroup from '../pages/assignments/group-members/SelectedGroup';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import { GroupsList } from '../pages/assignments/groups/GroupsList';
import { useAssignmentGroups } from '../pages/assignments/members/useAssignmentGroups';
import { useRoutingContext } from './RoutingContext';

const Groups = ({
  onClose,
  isDialogLayout,
}: {
  onClose?: () => void;
  isDialogLayout?: boolean;
}) => {
  const { selectedGroupId, setSelectedGroupId } = useRoutingContext();
  const { assignmentGroups } = useAssignmentGroups();

  return (
    <>
      {!assignmentGroups && <CenteredCircularProgress />}
      {assignmentGroups && (
        <>
          {selectedGroupId ? (
            <SelectedGroup
              groupId={selectedGroupId}
              onBackClick={() => setSelectedGroupId('')}
              onClose={onClose}
              isDialogLayout={isDialogLayout}
            />
          ) : (
            <GroupsList
              assignmentsGroupList={assignmentGroups}
              onGroupClick={setSelectedGroupId}
              onClose={onClose}
              isDialogLayout={isDialogLayout}
            />
          )}
        </>
      )}
    </>
  );
};

export default Groups;
