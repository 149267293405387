import { Box, Stack } from '@mui/material';
import { ReactNode, useState } from 'react';
import ChatsHistory from './ChatsHistory';
import CopilotChat from '../copilot/CopilotChat';
import { ChatShort, Chat } from './copilotChatsReducer';

const CHATS_PANEL_WIDTH = '256px';
const ACTIVE_CHAT_MAX_WIDTH = '712px';

const CopilotChatsExpanded = ({
  newButton,
  toggleExpandButton,
  closeButton,
  chats,
  activeChat,
  isLoadingChats,
}: {
  newButton: ReactNode;
  toggleExpandButton: ReactNode;
  closeButton: ReactNode;
  chats: ChatShort[];
  activeChat?: Chat;
  isLoadingChats: boolean;
}) => {
  const [search, setSearch] = useState('');

  return (
    <Stack height="100%" direction="row" position="relative">
      <Stack
        sx={{
          flex: `0 0 ${CHATS_PANEL_WIDTH}`,
        }}
      >
        <ChatsHistory
          isLoadingChats={isLoadingChats}
          newButton={newButton}
          search={search}
          setSearch={setSearch}
          chats={chats}
        />
      </Stack>
      <Stack
        sx={{
          flex: 1,
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Stack
          sx={{
            flex: 1,
            maxWidth: ACTIVE_CHAT_MAX_WIDTH,
          }}
        >
          <CopilotChat
            messages={activeChat?.messages ?? []}
            onConfirm={() => Promise.resolve()}
            isLoading={!!activeChat?.isLoading}
            errorMsg={activeChat?.error}
            disableInput={false}
            noteAlignment="center"
          />
        </Stack>
      </Stack>

      <Box
        display="flex"
        gap={1}
        alignItems="center"
        sx={{
          position: 'absolute',
          right: '12px',
          top: '24px',
        }}
      >
        {toggleExpandButton}
        {closeButton}
      </Box>
    </Stack>
  );
};

export default CopilotChatsExpanded;
