export interface Campaign extends CampaignBase {
  Id: string;
  LastModifiedDate: string;
  CreatedDate: string;
}
export interface CampaignBase {
  Name: string;
  ParentId?: string;
  Type?: string;
  Status?: string;
  StartDate?: string;
  EndDate?: string;
  ExpectedRevenue?: number;
  BudgetedCost?: number;
  ActualCost?: number;
  ExpectedResponse?: string;
  IsActive?: boolean;
  NumberSent?: number;
  Description?: string;
}

type CampaignObject = Record<keyof Campaign, undefined>;

const campaignProperties: CampaignObject = {
  ActualCost: undefined,
  BudgetedCost: undefined,
  Description: undefined,
  EndDate: undefined,
  ExpectedResponse: undefined,
  ExpectedRevenue: undefined,
  Id: undefined,
  IsActive: undefined,
  Name: undefined,
  NumberSent: undefined,
  ParentId: undefined,
  StartDate: undefined,
  Status: undefined,
  Type: undefined,
  CreatedDate: undefined,
  LastModifiedDate: undefined,
};

// This is a workaround to get the keys of the interface as a type
export const CampaignKeys = Object.keys(campaignProperties) as (keyof Campaign)[];
