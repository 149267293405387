import { Pill, colors } from '@sweep-io/sweep-design';
import { GroupLabelNodeData } from '../../../canvasTypes/nodeTypesData';
import { getObjectTypeColor } from '../../../helpers/getObjectTypeColor';
import { CanvasPillTypes, NodeEntityTypes } from '../../../canvasTypes';
import { Box } from '@mui/material';
import { CONTEXT_ZOOM_LEVEL_2 } from '../../../const';
import { useStore } from 'reactflow';

export const AggregatedPill = ({ data }: { data: GroupLabelNodeData }) => {
  const zoomLevel = useStore((store) => store.transform[2]);

  const amount = data.pills?.reduce((acc, pill) => acc + pill.amount, 0) || 0;
  let color = getObjectTypeColor(data.objectType).light;

  color = zoomLevel < CONTEXT_ZOOM_LEVEL_2 && color === colors.blue[100] ? '#FFF' : color; // Exception for the default color

  return (
    <Box
      className="aggregated-pill"
      sx={{
        cursor: 'pointer',
      }}
      data-testid={data.objectType + '-aggregated-pill-' + data.label}
    >
      <Pill
        value={amount}
        startIconName={''}
        backgroundColor={color}
        onClick={(event) => {
          event.stopPropagation();
          data.onPillClick?.({
            id: data.groupId,
            entity: {
              type: NodeEntityTypes.GroupPill,
              canvasPillType: CanvasPillTypes.groupAggregated,
            },
            event,
          });
        }}
      />
    </Box>
  );
};
