import { CustomWebhookInterface } from '../../components/dashboard/webhooks/WebhooksContext';
import buildURLQuery from '../../lib/buildURLQuery';
import {
  CloudingoConnectionStatus,
  SlackConnectionStatus,
} from '../../reducers/integrationsReducer';
import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

export type SlackConnectionResponse = {
  status: SlackConnectionStatus;
  upgradeRequired?: boolean;
  includeSandboxes: boolean;
};

export const useDataIntegrationApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_slack_status: useCallback(async (): Promise<SlackConnectionResponse> => {
      const response = await sweepApi.get(`/data-integrations/slack`);
      return response.data;
    }, [sweepApi]),

    patch_slackIncludeSandboxes: useCallback(
      async (includeSandboxes: boolean): Promise<void> => {
        await sweepApi.patch(`/data-integrations/slack`, { includeSandboxes });
      },
      [sweepApi],
    ),

    get_slack_recipients: useCallback(
      async (
        idsArray?: string[],
        invalidateCache?: boolean,
      ): Promise<{ data: SlackRecipient[]; isCached: boolean }> => {
        // the ids and isCached are not sent together
        const response = await sweepApi.get(
          `/data-integrations/slack/recipients${idsArray ? `?${buildURLQuery({ ids: idsArray.join(',') })}` : ''}${invalidateCache ? '?invalidate-cache=true' : ''}`,
        );
        const isCached = !!response.headers['x-sweep-cache'];
        return { data: response.data, isCached };
      },
      [sweepApi],
    ),

    get_cloudingo_status: useCallback(async (): Promise<{ status: CloudingoConnectionStatus }> => {
      const response = await sweepApi.get(`/data-integrations/cloudingo`);
      return response.data;
    }, [sweepApi]),

    get_custom_integrations: useCallback(async (): Promise<CustomWebhookInterface[]> => {
      const response = await sweepApi.get(`/data-integrations/custom-integrations`);
      return response.data;
    }, [sweepApi]),

    post_custom_integration: useCallback(
      async (integration: CustomWebhookInterface): Promise<CustomWebhookInterface> => {
        const response = await sweepApi.post('/data-integrations/custom-integrations', integration);
        return response.data;
      },
      [sweepApi],
    ),
    put_custom_integration: useCallback(
      async (
        integrationId: string,
        integration: CustomWebhookInterface,
      ): Promise<CustomWebhookInterface> => {
        const response = await sweepApi.put(
          `/data-integrations/custom-integrations/${integrationId}`,
          integration,
        );
        return response.data;
      },
      [sweepApi],
    ),
    delete_custom_integration: useCallback(
      async (integrationId: string) => {
        await sweepApi.delete(`/data-integrations/custom-integrations/${integrationId}`);
      },
      [sweepApi],
    ),
  };
};
