import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

const WINDOW_CLOSE_VERIFICATION_INTERVAL = 250;

export const waitForWindowToClose = async (_window: Window) =>
  new Promise<void>((resolve) => {
    const _interval = setInterval(() => {
      if (_window.closed) {
        clearInterval(_interval);
        resolve();
      }
    }, WINDOW_CLOSE_VERIFICATION_INTERVAL);
  });

export const getEditOptimisticStatus = (existingStatus: DeployStatusForTable) => {
  if (existingStatus === DeployStatusForTable.Deployed) {
    return DeployStatusForTable.Pending;
  }
  return existingStatus;
};
