import { Box } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import CommentInputWithAvatar from '../../../../common/comments/CommentInputWithAvatar';
import CommentWithActions from '../../../../common/comments/CommentWithActions';
import { useConfigurationComments } from './useConfigurationComments';
import { useRunOnce } from '../../../../common/useRunOnce';
import { useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectConfigurationComments } from '../../../../../reducers/documentationReducer';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';

import type { MentionedUser } from '../../../../common/comments/types';
import DeleteCommentDialog from '../../../../common/comments/DeleteCommentDialog';
import { ConfigurationComment } from './types';
import { CommentHeader } from '../../header/CommentHeader';
import { ConfigurationType } from '../../types';
import { ConfigurationItem } from '../../DependenciesTypes';

interface CommentsListProps {
  configurationItem: ConfigurationItem;
  parentType: ConfigurationType;
  crmOrgId: string;
}

const SPACING = 2;

const CommentsList = ({ configurationItem, parentType, crmOrgId }: CommentsListProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [openCommentIdMenu, setOpenCommentIdMenu] = useState<string>();
  const [deleteCommentId, setDeleteCommentId] = useState<string>();

  const ref = useRef<HTMLDivElement>();

  const scrollToBottom = useCallback(() => {
    ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
  }, []);

  const {
    fetchConfigurationComments,
    updateConfigurationComment,
    deleteConfigurationComment,
    addConfigurationComment,
    pinConfigurationComment,
    isLoading: _isAddCommentLoading,
  } = useConfigurationComments();

  const comments = useSelector(selectConfigurationComments(crmOrgId, configurationItem.id));
  const pinnedComments = comments?.filter((comment) => comment.properties.pinned);

  useRunOnce(async () => {
    await fetchConfigurationComments(configurationItem.id, crmOrgId);
    setIsLoading(false);
  });

  const renderCommentWithAction = (comment: ConfigurationComment, isHighlighted: boolean) => (
    <CommentWithActions
      key={comment.id}
      reply={comment}
      isMenuOpen={openCommentIdMenu === comment.id}
      openMenu={() => setOpenCommentIdMenu(comment.id)}
      closeMenu={() => setOpenCommentIdMenu(undefined)}
      deleteReplyCb={() => setDeleteCommentId(comment.id)}
      editReplyCb={(commentBody, mentionedUserIds) => {
        const _comment = { ...comment, commentBody, mentionedUserIds };
        updateConfigurationComment(_comment, configurationItem.id, crmOrgId);
      }}
      deleteText={'Delete'}
      isPinned={comment.properties.pinned}
      isHighlighted={isHighlighted}
      pinReplyCb={() => {
        const _comment = {
          ...comment,
          properties: { pinned: !comment.properties.pinned },
        };
        pinConfigurationComment(_comment, configurationItem.id, crmOrgId);
      }}
    />
  );

  return (
    <>
      <CommentHeader
        configurationItem={configurationItem}
        parentType={parentType}
        crmOrgId={crmOrgId}
      />

      <Box p={2} display="flex" flexDirection="column">
        <Typography variant="h4">Comments</Typography>

        {Boolean(deleteCommentId) && (
          <DeleteCommentDialog
            handleClose={() => setDeleteCommentId(undefined)}
            onDelete={() =>
              deleteCommentId &&
              deleteConfigurationComment(deleteCommentId, configurationItem.id, crmOrgId)
            }
          />
        )}

        {isLoading && <CenteredCircularProgress />}

        {!isLoading && (
          <Box ml={-SPACING} mr={-SPACING}>
            {!!comments.length && (
              <Box
                pt={2}
                overflow="auto"
                height="calc(100vh - 240px)" //240px - summed height of elements on screen (top bar, comment input, comment title, dependencies header etc)
                sx={{
                  '& .comment-with-actions': {
                    //hover state should touch edges of the dialog
                    pr: SPACING,
                    pl: SPACING,
                  },
                }}
                ref={ref}
              >
                {pinnedComments.map((comment) => renderCommentWithAction(comment, true))}
                {comments.map((comment) => renderCommentWithAction(comment, false))}
                {_isAddCommentLoading && (
                  <CenteredCircularProgress
                    circularProgressProps={{ size: 40 }}
                    minHeight={'60px'}
                  />
                )}
              </Box>
            )}

            <Box
              width="100%"
              position="sticky"
              bottom="0"
              p={2}
              sx={{ background: colors.white, flex: '0 0 auto' }}
            >
              <CommentInputWithAvatar
                onConfirm={(commentBody: string, mentionedUserIds: MentionedUser[]) => {
                  addConfigurationComment(
                    { commentBody, mentionedUserIds, properties: { pinned: false } },
                    configurationItem.id,
                    crmOrgId,
                  );
                  scrollToBottom();
                }}
                placeholder="Add new comment. Use @ to mention"
              />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CommentsList;
