import { useSelector } from 'react-redux';
import {
  selectEnvFunnelMap,
  selectEnvFunnelsData,
  selectEnvHubspotFunnelsData,
  selectEnvRecordTypesData,
} from '../../../reducers/global/funnelMapsReducer';
import { useMemo } from 'react';

export const useSelectConfigurationCanvasEnvFunnelMap = (crmOrgId: string) => {
  const funnelMap = useSelector(selectEnvFunnelMap(crmOrgId));
  const recordTypesData = useSelector(selectEnvRecordTypesData(crmOrgId));
  const funnelsData = useSelector(selectEnvFunnelsData(crmOrgId));
  const hubspotFunnelsData = useSelector(selectEnvHubspotFunnelsData(crmOrgId));

  const _funnelMap: FunnelMap | undefined = useMemo(() => {
    if (!funnelMap || !recordTypesData || !funnelsData || !hubspotFunnelsData) return undefined;

    return { ...funnelMap, recordTypesData, funnelsData, hubspotFunnelsData };
  }, [funnelMap, funnelsData, hubspotFunnelsData, recordTypesData]);

  return _funnelMap;
};
