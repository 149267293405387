import { Box } from '@mui/material';

export const MandatoryStar = () => (
  <Box
    sx={{
      fontSize: '13px !important',
      color: '#ff5b5b',
      height: '10px',
      position: 'absolute',
      left: '-10px',
      top: '14px',
    }}
  >
    *
  </Box>
);
