import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { AutomationsActionsWrapper } from './AutomationsActionsWrapper';
import { AutomationActionPopupMenu } from './AutomationActionPopupMenu';
import { AutomationActionType } from '../../types/enums/AutomationActionType';

interface VerifyUrlActionListProps {
  readonly?: boolean;
  title: string;
  actionList?: Partial<AutomationAction>[];
  crmOrgId: string;
  onChange: (action: AutomationAction[]) => any;
  objectName: string;
  objectTypes?: ObjectTypeName[];
  type: string;
}

export const VerifyUrlActionList = ({
  readonly,
  title,
  crmOrgId,
  objectName,
  objectTypes,
  onChange,
  actionList,
  type,
}: VerifyUrlActionListProps) => {
  const onAddAction = (emptyAction: Partial<AutomationAction>, isBefore: boolean, idx: number) => {
    const temp = [...(actionList || [])];
    if (isBefore) {
      temp?.splice(idx, 0, emptyAction);
    } else {
      temp?.splice(idx + 1, 0, emptyAction);
    }
    onChange(temp as AutomationAction[]);
  };

  const onClearAction = (idx: number) => {
    const temp = [...(actionList || [])];
    temp?.splice(idx, 1);
    onChange(temp as AutomationAction[]);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        borderRadius: 1,
        background: colors.grey[100],
        border: `1px solid ${colors.grey[200]}`,
        p: 2,
        gap: 1.5,
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography variant="body-medium">{title}</Typography>

        <AutomationActionPopupMenu
          readonly={readonly}
          action={actionList && actionList[0]}
          onClearAction={() => onClearAction(0)}
          onAddAction={(emptyAction, isBefore) => onAddAction(emptyAction, isBefore, 0)}
          isClearable={
            actionList && actionList[0]?.actionType !== AutomationActionType.AssignmentRules
          }
        />
      </Box>

      <AutomationsActionsWrapper
        isNestedVerifiedUrl={type}
        isHideActionSelector={false}
        onAddAction={(emptyAction: Partial<AutomationAction>, isBefore: boolean, idx: number) =>
          onAddAction(emptyAction, isBefore, idx)
        }
        onClearAction={(idx: number) => onClearAction(idx)}
        onChange={(action: Partial<AutomationAction>, idx?: number) => {
          const temp = [...(actionList || [])];
          if (temp) {
            if (idx !== undefined) {
              temp[idx] = action;
            } else {
              temp.push(action);
            }
            onChange(temp as AutomationAction[]);
          }
        }}
        objectName={objectName}
        readonly={readonly}
        actionList={actionList ?? []}
        objectTypes={objectTypes}
        crmOrgId={crmOrgId}
        isActionEnabled={true}
      />
    </Box>
  );
};
