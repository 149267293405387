import { NestedSelectorPath } from '../NestedSelector/NestedSelector';
import { NestedFieldsSelector, NestedFieldsField } from './NestedFieldsSelector';
import { Box } from '@mui/material';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { Delete } from '@sweep-io/sweep-design/dist/icons';
import { ReactNode, useState } from 'react';

const DraggableFieldSelector = ({
  readonly,
  crmOrgId,
  objectType,
  nestedPath,
  onChange,
  onDeleteCb,
  dragHandle,
  isOverlay,
}: {
  readonly: boolean;
  isOverlay: boolean;
  crmOrgId: string;
  objectType: string;
  nestedPath: NestedSelectorPath;
  onChange: (sweepField: NestedFieldsField) => void;
  onDeleteCb: () => void;
  dragHandle: ReactNode;
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Box
        display="flex"
        alignItems="center"
        flex={1}
        height="36px"
        sx={{
          border: `1px solid ${colors.grey[300]}`,
          borderRadius: '4px',
          '&:hover': {
            borderColor: colors.blue[500],
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="29px"
          height="100%"
          sx={{
            borderRight: `1px solid ${colors.grey[300]}`,
            cursor: readonly ? 'default' : 'grab',
          }}
        >
          {dragHandle}
        </Box>
        <Box
          flex={1}
          sx={{
            '.MuiButton-root:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <NestedFieldsSelector
            readonly={readonly}
            crmOrgId={crmOrgId}
            objectType={objectType}
            nestedPath={nestedPath || []}
            onChange={(sweepField: NestedFieldsField) => {
              onChange(sweepField);
            }}
          />
        </Box>
      </Box>
      {!readonly && !isOverlay && isHovered && (
        <IconButton
          size="small"
          variant="flat"
          onClick={(e) => {
            e.stopPropagation();
            onDeleteCb();
          }}
        >
          <Delete />
        </IconButton>
      )}
    </Box>
  );
};
export default DraggableFieldSelector;
