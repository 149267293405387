import { Box, SxProps } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { DialogHeaderTextField } from './DialogHeaderTextField';

const baseCss = {
  display: 'flex',
  alignItems: 'center',
  gap: 5,
  flex: 1,
};
const stickyCss = {
  position: 'sticky',
  top: 0,
  zIndex: '10',
};

export interface CreateEditDialogHeaderBaseProps {
  value: string;
  placeholder: string;
  onChange: (value: string) => any;
  onBackClick?: () => any;
  hideBackButton?: boolean;
  maxLength?: number;
  editDisabled?: boolean;
  errorHelperText?: string;
  isStickyHeader?: boolean;
  sx?: SxProps;
  rightSideContent?: React.ReactNode;
  rightSidePermissionContent?: React.ReactNode;
}

export const CreateEditDialogHeaderBase = ({
  value,
  onBackClick,
  onChange,
  placeholder,
  hideBackButton,
  maxLength,
  editDisabled,
  errorHelperText,
  isStickyHeader,
  sx,
  rightSideContent,
}: CreateEditDialogHeaderBaseProps) => {
  const compSx = isStickyHeader ? { ...baseCss, ...stickyCss } : baseCss;
  return (
    <Box sx={{ ...compSx, ...sx }}>
      <Box sx={{ display: 'flex', gap: '12px', flex: 1 }}>
        {!hideBackButton && (
          <IconButton variant="flat" onClick={onBackClick}>
            <ArrowLeft />
          </IconButton>
        )}
        <DialogHeaderTextField
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          maxLength={maxLength}
          editDisabled={editDisabled}
          errorHelperText={errorHelperText}
        />
      </Box>
      {rightSideContent}
    </Box>
  );
};
