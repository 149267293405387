import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import { Button } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { StyledListItem } from '../../common/StyledListItem';

export const Sort = ({
  sortKey,
  onSortChange,
  options,
}: {
  sortKey: string;
  onSortChange: (newKey: string) => void;
  options: { label: string; value: string }[];
}) => {
  const [anchorEl, setAnchorEl] = useState<any>(null);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Button
        variant="flat"
        endIconName="ChevronDown"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        type="secondary"
      >
        Sort by
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box sx={{ p: 1, pb: 0, minWidth: '200px' }}>
          {options.map((option) => (
            <StyledListItem
              key={option.value}
              title={option.label}
              onClick={() => {
                onSortChange(option.value);
                setAnchorEl(null);
              }}
              isActive={option.value === sortKey}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};
