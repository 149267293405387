import { Stack, Box } from '@mui/material';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { Typography, Tag, colors } from '@sweep-io/sweep-design';
import CopilotScrollArea from './CopilotScrollArea';
import CopilotInput from './CopilotInput';
import { COPILOT_PROMPTS } from './copilotConsts';

const GRADIENT_HEIGHT = '42px';
type NoteAlignment = 'left' | 'center';

const CopilotChat = ({
  messages,
  onConfirm,
  isLoading,
  errorMsg,
  disableInput,
  noteAlignment,
}: {
  messages: AiChatMessage[];
  onConfirm: (message: string) => Promise<void>;
  isLoading: boolean;
  disableInput: boolean;
  errorMsg?: string;
  noteAlignment: NoteAlignment;
}) => {
  return (
    <Stack flex={1} overflow="auto">
      <CopilotScrollArea messages={messages} errorMsg={errorMsg} isLoading={isLoading} />
      <Stack
        sx={{
          position: 'relative',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: 'calc(100% - 5px)', //5px to not cover the scroll-bar
            height: GRADIENT_HEIGHT,
            top: `-${GRADIENT_HEIGHT}`,
            background: `linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%);`,
          }}
        ></Box>
        <CopilotInput
          onConfirm={onConfirm}
          isLoading={isLoading}
          predefinedPrompts={messages.length === 0 ? COPILOT_PROMPTS : undefined}
          disabled={disableInput}
        />
        <Stack p={1.5} pt={0} mt={-0.5}>
          <CopilotNote alignment={noteAlignment} />
        </Stack>
      </Stack>
    </Stack>
  );
};

const CopilotNote = ({ alignment }: { alignment: NoteAlignment }) => (
  <Box
    display="flex"
    gap={1}
    pl={alignment === 'left' ? 1.5 : 0}
    alignItems="center"
    justifyContent={alignment === 'left' ? 'start' : 'center'}
  >
    <Tag label="Beta" color={colors.storm[70]} />
    <Typography variant="caption" color={colors.grey[700]}>
      AI bots can make mistakes. Check important info.
    </Typography>
  </Box>
);

export default CopilotChat;
