import { useDispatch } from 'react-redux';
import { Campaign, CampaignBase, CampaignKeys } from './campaignTypes';
import { useCampaignsApiFacade } from '../../../../../apis/facades/useCampaignsApiFacade';
import {
  loadCampaigns,
  removeCampaign,
  startLoadingCampaigns,
} from '../../../../../reducers/hubspotReducer';
import { useCallback } from 'react';
import { pick } from 'lodash';

const cleanCampaignProperties = (campaign: Campaign): Campaign => pick(campaign, CampaignKeys);

export const useCampaigns = () => {
  const dispatch = useDispatch();
  const { post_campaign, patch_campaign, delete_campaign, get_campaigns } = useCampaignsApiFacade();

  const getCampaigns = useCallback(async (): Promise<Campaign[]> => {
    dispatch(startLoadingCampaigns());
    const campaigns = await get_campaigns();
    dispatch(loadCampaigns(campaigns));
    return campaigns;
  }, [dispatch, get_campaigns]);

  const createCampaign = useCallback(
    async (newCampaign: CampaignBase): Promise<Campaign> => {
      const campaign = await post_campaign(newCampaign);
      const _campaign = cleanCampaignProperties(campaign);
      await getCampaigns();
      return _campaign;
    },
    [getCampaigns, post_campaign],
  );

  const updateCampaign = useCallback(
    async (campaign: Campaign): Promise<void> => {
      const _campaign = cleanCampaignProperties(campaign);
      await patch_campaign(_campaign.Id, _campaign);
      await getCampaigns();
    },
    [getCampaigns, patch_campaign],
  );

  const deleteCampaign = async (campaignId: string): Promise<void> => {
    await delete_campaign(campaignId);
    dispatch(removeCampaign({ campaignId }));
  };

  return {
    createCampaign,
    updateCampaign,
    deleteCampaign,
    getCampaigns,
  };
};
