import React from 'react';
import { Box, colors } from '@mui/material';
import { ArrowRight } from '@sweep-io/sweep-design/dist/icons/ArrowRight';

export const PageTemplateHeaderWrapper = ({
  firstItem,
  secondItem,
}: {
  firstItem?: React.ReactNode;
  secondItem?: React.ReactNode;
}) => {
  return (
    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', height: '40px' }}>
      {firstItem}
      <ArrowRight color={colors.grey[700]} />
      {secondItem}
    </Box>
  );
};
