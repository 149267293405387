import { IconButton, colors, Typography } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { useState } from 'react';
import Collapse from '@mui/material/Collapse/Collapse';
import { PromptsBoxProps } from './promptsBoxTypes';
import PromptBubble from './PromptBubble';
import { Box } from '@mui/material';

export const PromptsBoxCollapsible = ({
  predefinedPrompts,
  onConfirm,
  disabled,
}: PromptsBoxProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true); //product requested to be open by default
  const [sentPromptKeys, setSentPromptKeys] = useState<string[]>([]);

  const promptKeysToRender = Object.keys(predefinedPrompts).filter((key) =>
    !sentPromptKeys.includes(key),
  );
  const isAllPromptsSent = Object.keys(predefinedPrompts).length === sentPromptKeys.length;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pb: 2,
          alignItems: 'center',
        }}
      >
        <Typography color={colors.night[900]} variant="h4">
          Use AI Assistant to ask a question
        </Typography>
        <IconButton size="tiny" variant="outlined" onClick={() => setIsCollapsed((prev) => !prev)}>
          {isCollapsed ? <ChevronDown /> : <ChevronUp />}
        </IconButton>
      </Box>
      <Collapse in={isCollapsed}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            pb: isAllPromptsSent ? 0 : 2,
          }}
        >
          {promptKeysToRender.map((key) => {
            const prompt = predefinedPrompts[key];
            return (
              <PromptBubble
                key={key}
                prompt={prompt}
                disabled={disabled}
                onClick={() => {
                  onConfirm(prompt);
                  setSentPromptKeys([...sentPromptKeys, key]);
                }}
              />
            );
          })}
        </Box>
      </Collapse>
    </>
  );
};
