import { AutomationsTemplatesList } from '../Automations/AutomationsTemplatesList';
import { AutoTemplatesStruct } from '../../constants/automationTemplates';
import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';
import { getAddAutomationButtonByType } from '../Automations/helper';
import { AutomationType } from '../../types/enums/AutomationType';
import useCompileTemplate from '../../hooks/useCompileTemplate';
import { PageTemplateCanvasDialog } from '../pages/pages-templates';

const AssignmentsTemplatesDialog = ({
  closeDialog,
  setAutomationInEdit,
  crmOrgId,
  title,
  transitionDuration,
}: {
  closeDialog: () => void;
  setAutomationInEdit: (automation: Partial<AutomationStructureNew>) => void;
  crmOrgId: string;
  title: string;
  transitionDuration?: number;
}) => {
  const compileTemplate = useCompileTemplate();
  const { getSweepFieldsById } = useSweepFields();
  const getFieldsList = async (fieldsList: string[]) => {
    await getSweepFieldsById({
      fieldIds: fieldsList,
    });
  };

  return (
    <PageTemplateCanvasDialog
      onCancel={closeDialog}
      isOpen={true}
      title={title}
      transitionDuration={transitionDuration}
    >
      <AutomationsTemplatesList
        crmOrgId={crmOrgId}
        automationVariant={AutomationFormVariant.ASSIGNMENT_RULES}
        onSelectTemplate={(item: AutoTemplatesStruct) => {
          const automationToEdit = compileTemplate(item.automation);
          setAutomationInEdit(automationToEdit);
          getFieldsList(item.fieldsList);
        }}
        createEmptyButton={getAddAutomationButtonByType({
          variant: AutomationFormVariant.ASSIGNMENT_RULES,
          props: {
            disabled: false,
            onSelect: (objectName: string) => {
              setAutomationInEdit({ objectName, type: AutomationType.Assignment });
            },
            crmOrgId,
            buttonText: 'Get started',
            automationVariant: AutomationFormVariant.ASSIGNMENT_RULES,
            isEmptyState: true,
            variant: 'outlined',
          },
        })}
      />
    </PageTemplateCanvasDialog>
  );
};

export default AssignmentsTemplatesDialog;
