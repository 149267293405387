import { anExitCriterion } from '../../../../../models/exitCriterionModel';
import { aStage } from '../../../../../models/stageModel';
import { ParsedRecordType, LeadingField } from '../../../../parser/ParserTypes';

export const MASTER_KEY = '__MASTER__';

export const mapRecordTypeToFunnel = (
  selectedItem?: ParsedRecordType,
  leadingField?: LeadingField,
) => {
  if (!selectedItem) {
    return;
  }

  return {
    id: 'MAPPED_RECORD_TYPE',
    usedInMaps: [],
    name: selectedItem.label ?? selectedItem.name,
    funnelDetails: {
      stages: getStages(leadingField ?? selectedItem.leadingFields?.[0] ?? undefined),
      leadingObject: {
        objectName: selectedItem.objectApiName,
        fieldName: '',
        _leadingFieldLabels: [],
        _leadingFieldId: '',
      },
      plugins: {},
    },
    updatedAt: '',
    updatedById: '',
    description: selectedItem.description ?? '',
    isDeployed: true, //not converted RTs aren't not deployed funnels
  } as ShortFunnel;
};

const getStages = (leadingField: LeadingField) => {
  const recordTypeStages = leadingField?.values ?? [];
  const createdStages = recordTypeStages.map((stage, idx) =>
    aStage({
      stageName: stage.name,
      _stageColumnIndex: idx,
    }),
  );

  const connectedStages = createdStages.map((stage, idx) => {
    const nextStage = createdStages[idx + 1];

    if (!nextStage?._stageId) {
      return stage;
    }

    return {
      ...stage,
      exitCriteria: [anExitCriterion({ _nextStageId: nextStage._stageId })],
    };
  });

  return recordTypeStages ? connectedStages : [];
};
