import { createContext, useContext, useState } from 'react';

type FormulaEditorState = {
  [key: string]: boolean;
};

interface FormulaEditorStateContextType {
  formulaEditorState?: FormulaEditorState;
  anyFormulaEditorOpenExists?: boolean;
  setFormulaEditorStatePerKey?: ({ key, value }: { key: string; value: boolean }) => void;
  isFormulaEditorOpenedPerKey?: (key: string) => boolean;
}

const FormulaEditorStateContext = createContext<FormulaEditorStateContextType>({});

const FormulaEditorStateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [formulaEditorState, setFormulaEditorState] = useState<FormulaEditorState>({});

  const setFormulaEditorStatePerKey = ({ key, value }: { key: string; value: boolean }) => {
    setFormulaEditorState({ ...formulaEditorState, [key]: value });
  };
  const isFormulaEditorOpenedPerKey = (key: string): boolean => formulaEditorState[key] ?? false;
  const anyFormulaEditorOpenExists = Object.values(formulaEditorState).some((val) => val);

  return (
    <FormulaEditorStateContext.Provider
      children={children}
      value={{
        formulaEditorState,
        anyFormulaEditorOpenExists,
        setFormulaEditorStatePerKey,
        isFormulaEditorOpenedPerKey,
      }}
    />
  );
};

const useFormulaEditorState = () => {
  const context = useContext(FormulaEditorStateContext);
  if (!context.formulaEditorState) {
    throw new Error(
      'useFormulaEditorState must be used within a FormulaEditorStateContextProvider!',
    );
  }
  return context;
};

export { FormulaEditorStateContextProvider, useFormulaEditorState };
