import { useCallback, useEffect, useMemo } from 'react';

import { getRecordTypeIdentifier } from '../configuration-canvas/utils/getRecordTypeIdentifier';
import { StepsFilterData } from './filterTypes';
import { recordTypeToNodeId } from '../../funnel-map-canvas/helper';
import { useFitAroundElements } from './useFitAroundElements';
import { classifyAutomation } from '../configuration-canvas/utils/automationClassificationHelper';
import { AdvancedFilter, AdvancedFilterItem } from '../../common/advanced-filter/AdvancedFilter';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanvasFilterSelectedValuesFor,
  setCanvasFilterItemsDataFromFilterItems,
  setCanvasFilterSelectedValuesFor,
} from './canvasFiltersReducer';
import { advancedFilterItemsToCanvasFilterItemsData } from './helper';

interface StepsFilterProps {
  funnelsData: FunnelsData;
  recordTypesData?: RecordTypesData;
  automations?: AutomationStructureNew[];
  filteredRecordTypes?: string[];
  filteredObjects?: string[];
  filterKey: string;
  filterButtonDataTestId?: string;
  disableFitOnCanvasElements?: boolean;
}

const getFunnelSteps = (funnelsData: FunnelsData) => {
  const funnelSteps: AdvancedFilterItem<StepsFilterData>[] = Object.values(funnelsData)
    .map((funnel) => {
      return funnel.funnelDetails.stages.map((step) => {
        return {
          value: step._stageId,
          label: step.stageName,
          data: {
            recordTypeIds: [
              getRecordTypeIdentifier(funnel.recordType.name, funnel.recordType.objectName),
            ],
            objectName: funnel.recordType.objectName,
            funnelId: funnel.id,
          },
        };
      });
    })
    .flat();
  return funnelSteps;
};

const getRecordTypeSteps = (recordTypesData: RecordTypesData) => {
  const recordTypeSteps: AdvancedFilterItem<StepsFilterData>[] = Object.values(recordTypesData)
    .map((recordType) => {
      return recordType.leadingField?.values.map((step) => {
        return {
          value: recordTypeToNodeId(
            getRecordTypeIdentifier(recordType.name, recordType.objectName),
            step.fullName,
          ),
          label: step.label,
          data: {
            recordTypeIds: [getRecordTypeIdentifier(recordType.name, recordType.objectName)],
            objectName: recordType.objectName,
            fullName: step.fullName,
          },
        };
      });
    })
    .flat()
    .filter(Boolean) as AdvancedFilterItem<StepsFilterData>[];

  return recordTypeSteps;
};

const getAutomationSteps = (automations: AutomationStructureNew[]) => {
  const automationSteps = automations
    .map((automation) => {
      const automationClassification = classifyAutomation(automation);
      if (automationClassification.level === 'step') {
        return automationClassification.steps.map((step) => {
          const item: AdvancedFilterItem<StepsFilterData> = {
            value: step._stageId,
            label: step.stageName,
            data: {
              recordTypeIds: [
                getRecordTypeIdentifier(
                  automationClassification.recordType.name,
                  automationClassification.objectName,
                ),
              ],
              objectName: automation.objectName,
            },
          };
          return item;
        });
      }
    })

    .flat()
    .filter(Boolean) as AdvancedFilterItem<StepsFilterData>[];

  return automationSteps;
};

const hasCommonElements = (arr1: any[], arr2: any[]) => {
  return arr1.some((item) => arr2.includes(item));
};

export const StepsFilter = ({
  funnelsData,
  automations,
  recordTypesData,
  filterKey,
  filteredRecordTypes,
  filteredObjects,
  filterButtonDataTestId,
  disableFitOnCanvasElements,
}: StepsFilterProps) => {
  const dispatch = useDispatch();
  const selectedValues = useSelector(selectCanvasFilterSelectedValuesFor(filterKey));

  const steps: AdvancedFilterItem<StepsFilterData>[] = useMemo(() => {
    let steps: AdvancedFilterItem<StepsFilterData>[] = [];

    if (funnelsData) {
      steps = getFunnelSteps(funnelsData);
    }
    if (recordTypesData) {
      steps.push(...getRecordTypeSteps(recordTypesData));
    }
    if (automations) {
      steps.push(...getAutomationSteps(automations));
    }

    if (filteredRecordTypes && filteredRecordTypes.length > 0) {
      steps = steps.filter(
        (step) =>
          step.data?.recordTypeIds &&
          hasCommonElements(filteredRecordTypes, step.data.recordTypeIds),
      );
    }
    if (filteredObjects && filteredObjects.length > 0) {
      steps = steps.filter(
        (step) =>
          step.data?.objectName && hasCommonElements(filteredObjects, [step.data.objectName]),
      );
    }

    return steps;
  }, [automations, filteredObjects, filteredRecordTypes, funnelsData, recordTypesData]);

  useEffect(() => {
    dispatch(
      setCanvasFilterItemsDataFromFilterItems({
        filterKey,
        items: advancedFilterItemsToCanvasFilterItemsData(steps),
      }),
    );
  }, [dispatch, filterKey, steps]);

  // This useEffect removes selected values that are not in the steps list
  useEffect(() => {
    if (selectedValues.length !== 0) {
      const newSelectedValues = selectedValues.filter((value) => {
        if (!steps.map((step) => step.value).includes(value)) {
          return false;
        }
        return true;
      });
      if (newSelectedValues.length !== selectedValues.length) {
        dispatch(
          setCanvasFilterSelectedValuesFor({ filterKey, selectedValues: newSelectedValues }),
        );
      }
    }
  }, [dispatch, filterKey, selectedValues, steps]);

  const { fitOnElements } = useFitAroundElements({ funnelsData });

  const onSelectedItemsChange = useCallback(
    (items: string[]) => {
      dispatch(setCanvasFilterSelectedValuesFor({ filterKey, selectedValues: items }));
      !disableFitOnCanvasElements && fitOnElements(items);
    },
    [disableFitOnCanvasElements, dispatch, filterKey, fitOnElements],
  );

  return (
    <AdvancedFilter
      items={steps}
      selectedItems={selectedValues}
      onSelectedItemsChange={onSelectedItemsChange}
      texts={{ title: 'Steps' }}
      filterButtonDataTestId={filterButtonDataTestId}
    />
  );
};
