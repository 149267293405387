import { ChangeEvent, useEffect, useState } from 'react';
import { Enter, SlidersVertical } from '@sweep-io/sweep-design/dist/icons';
import { SearchInput } from '../../common/SearchInput';
import { SingleSelectFilter } from '../../common/single-select-filter/SingleSelectFilter';
import { SingleSelectFilterPopoverOption } from '../../common/single-select-filter/SingleSelectFilterPopover';
import { OPTION_ALL } from '../../common/single-select-filter/utils';
import { ConfigurationType } from '../dependencies/types';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import Box from '@mui/material/Box/Box';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectUniversalSearch,
  setUniversalSearchListIsOpen,
} from '../../../reducers/documentationReducer';
import { colors, Tooltip, Typography } from '@sweep-io/sweep-design';
import { Stack } from '@mui/material';

interface UniversalSearchInputProps {
  searchTxt: string;
  onKeyDown: (event: React.KeyboardEvent<HTMLDivElement>) => void;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClearButtonClick: () => void;
  filterOptions: SingleSelectFilterPopoverOption[];
  onSelectedFilterItem: (key: ConfigurationType) => void;
  selectedFilterValue?: string;
  isUniversalSearchListOpen?: boolean;
  menuOptions?: SingleSelectFilterPopoverOption[];
  selectedMenuOption?: string;
  onSelectMenuOption?: (selectedValue: string) => void;
}

export const UniversalSearchInput = ({
  onKeyDown,
  searchTxt,
  onChange,
  onClearButtonClick,
  filterOptions,
  menuOptions,
  selectedMenuOption,
  onSelectMenuOption,
  selectedFilterValue = OPTION_ALL.value,
  onSelectedFilterItem,
  isUniversalSearchListOpen,
}: UniversalSearchInputProps) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const dispatch = useDispatch();
  const { isExpandedMode } = useExpandedMode();
  const universalSearch = useSelector(selectUniversalSearch);

  useEffect(() => {
    const condition = searchTxt && searchTxt !== universalSearch?.searchText;
    if (condition && !displayTooltip) {
      setDisplayTooltip(true);
    } else if (!condition && displayTooltip) {
      setDisplayTooltip(false);
    }
  }, [searchTxt, displayTooltip, universalSearch]);

  return (
    <Box maxWidth={isExpandedMode ? '348px' : '100%'}>
      <Tooltip
        open={displayTooltip}
        title={
          <Stack alignItems="center" direction="row" gap={0.75}>
            <Typography variant="caption">Press Enter for results</Typography>
            <Box
              sx={{
                border: '0.5px solid white',
                borderRadius: '2px',
                height: '16px',
                width: '16px',
                display: 'flex',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Enter variant="tiny" color={colors.white} />
            </Box>
          </Stack>
        }
        placement="bottom-start"
      >
        <span>
          <SearchInput
            onClearButtonClick={onClearButtonClick}
            endAdornment={
              isUniversalSearchListOpen && (
                <SingleSelectFilter
                  options={filterOptions}
                  selectedItemValue={selectedFilterValue}
                  onSelectItem={(key) => onSelectedFilterItem(key as ConfigurationType)}
                  icon={<SlidersVertical />}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  menuOptions={menuOptions}
                  selectedMenuOption={selectedMenuOption}
                  onSelectMenuOption={onSelectMenuOption}
                />
              )
            }
            withFixedMagnifyingGlassIcon
            TextFieldProps={{
              onChange,
              value: searchTxt,
              placeholder: 'Search all configurations',
              onKeyDown,
              onFocus: () => dispatch(setUniversalSearchListIsOpen({ isOpen: true })),
            }}
            forceCloseButton={isUniversalSearchListOpen}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
