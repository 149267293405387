import { Box } from '@mui/material';
import { IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { DynamicPathStage, MIN_STAGE_WIDTH_NUMBER } from './DynamicPathStage';
import { ArrowRight, ChevronLeft, ChevronRight } from '@sweep-io/sweep-design/dist/icons';
import { useState, useRef, Fragment, useCallback } from 'react';
import { isEllipsisActive } from '../../../../../../../../lib/isEllipsisActive';
import useResizeObserver from 'use-resize-observer';

interface DynamicPathStagesProps {
  stages: SweepStage[];
  objectName: string;
  onStageSelect: (stageId: string, exitCriteriaId?: string) => void;
  onChangePath: (
    stageSelectToReplace: string,
    stageSelectToReplaceFor: string,
    _stageIdPrevious: string,
  ) => void;
  selectedStageId: string;
  mappedIdToStageName: { [stageId: string]: string };
}

export const DynamicPathStages = ({
  stages,
  onStageSelect,
  onChangePath,
  selectedStageId,
  objectName,
  mappedIdToStageName,
}: DynamicPathStagesProps) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const [translate, setTranslate] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const stageRef = useRef<HTMLDivElement>(null);

  const handleOverflow = useCallback(() => {
    setIsOverflow(isEllipsisActive(ref.current));
    setTranslate(0);
  }, []);

  useResizeObserver<HTMLDivElement>({
    ref: ref,
    onResize: handleOverflow,
  });

  const onChevronClick = (value: number) => {
    setTranslate((prev) => prev + value);
  };

  //48 = 16px gap * 2 + width of arrow of 16px
  const widthOfGapsWithArrows = 48 * (stages.length - 1);
  const widthOfVisibleBlock = ref.current?.offsetWidth ?? 0;
  const maxStageWidth =
    stages.length > 3 //taking in consideration current maxWidth of container === 936px more than 3 steps will cause overflow
      ? MIN_STAGE_WIDTH_NUMBER
      : (widthOfVisibleBlock - widthOfGapsWithArrows) / stages.length;

  const stagePanelWidth = maxStageWidth
    ? maxStageWidth * stages.length + widthOfGapsWithArrows - widthOfVisibleBlock
    : 0;

  const disableRightArrow = stagePanelWidth && isOverflowed ? -translate > stagePanelWidth : false;
  const onClickTranslateBy = maxStageWidth ? maxStageWidth * 2 : MIN_STAGE_WIDTH_NUMBER;

  return (
    <Box mt={'12px'} mb={'12px'} sx={{ position: 'relative' }}>
      <Typography variant="body" color={colors.grey[800]}>
        Click on each of the steps to review and edit the information
      </Typography>

      {translate !== 0 && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '8px',
            left: '-5px',
            zIndex: 2,
          }}
        >
          <IconButton
            variant="outlined"
            size="tiny"
            onClick={() => onChevronClick(onClickTranslateBy)}
          >
            <ChevronLeft />
          </IconButton>
        </Box>
      )}

      <Box
        mt={'12px'}
        sx={{
          overflow: 'hidden',
          width: '100%',
        }}
        ref={ref}
      >
        <Box
          sx={{
            transform: `translate(${disableRightArrow ? -stagePanelWidth : translate}px, 0px)`, //finish scrolling at max position
            transition: '.5s ease-in-out',
            display: 'flex',
            gap: 2,
          }}
        >
          {stages.map((stage, idx) => {
            const nextStage = stages[idx + 1];
            return (
              <Fragment key={'stages_' + stage._stageId}>
                <Box ref={stageRef} sx={{ width: '100%', maxWidth: maxStageWidth + 'px' }}>
                  <DynamicPathStage
                    stage={stage}
                    objectName={objectName}
                    onStageClick={onStageSelect}
                    isActive={selectedStageId === stage._stageId}
                    mappedIdToStageName={mappedIdToStageName}
                    _nextStageId={nextStage?._stageId}
                    onChangePath={onChangePath}
                  />
                </Box>

                {idx < stages.length - 1 && (
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowRight />
                  </Box>
                )}
              </Fragment>
            );
          })}
        </Box>

        {isOverflowed && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '8px',
              right: '-5px',
              zIndex: 2,
            }}
          >
            <IconButton
              disabled={disableRightArrow}
              variant="outlined"
              size="tiny"
              onClick={() => onChevronClick(-onClickTranslateBy)}
            >
              <ChevronRight />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
