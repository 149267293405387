import { useSelector } from 'react-redux';
import useUserInfo from '../../hooks/useUserInfo';
import { useRunOnceWhenTruthy } from '../common/useRunOnceWhenTruthy';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import { AccountType } from '@server/account-type';
import { useFeatureToggle } from '../common/useFeatureToggle';

export const useExpandedDocumentationForFreeUsers = () => {
  const { expandedDocumentationForFreeUsers } = useFeatureToggle();
  const userInfo = useSelector(selectUserInfoData);
  const { updateCanvasMode } = useUserInfo();

  useRunOnceWhenTruthy(() => {
    if (
      !userInfo?.preferences?.hasOwnProperty('canvasPanelModeExpanded') &&
      userInfo?.account?.type === AccountType.Free &&
      expandedDocumentationForFreeUsers
    ) {
      updateCanvasMode(true);
    }
  }, !!userInfo);
};
