import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RollupForTable } from '../../components/pages/rollups/rollupTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

type RollupReducerProps = {
  addRollup: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      rollup: RollupForTable;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateRollup: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      rollupToUpdate: RollupForTable;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteRollup: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      rollupIdToDelete: string;
    }>,
  ) => any;
  updateDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      rollupId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const rollupReducers: RollupReducerProps = {
  addRollup: (state, action) => {
    const { crmOrgId, rollup, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    if (!state.environments[crmOrgId].data.rollups) {
      state.environments[crmOrgId].data.rollups = [];
    }
    state.environments[crmOrgId].data.rollups?.push({ ...rollup, status: optimisticStatus });
  },
  updateRollup: (state, action) => {
    const { crmOrgId, rollupToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.rollups) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.rollups?.findIndex(
      (r) => r.rollupId === rollupToUpdate.rollupId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.rollups[indexToUpdate] = {
        ...rollupToUpdate,
        status: optimisticStatus,
      };
    }
  },
  deleteRollup: (state, action) => {
    const { crmOrgId, rollupIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.rollups) {
      return;
    }
    const indexToDelete = state.environments[crmOrgId].data.rollups?.findIndex(
      (r) => r.rollupId === rollupIdToDelete,
    );
    if (indexToDelete !== undefined) {
      state.environments[crmOrgId].data.rollups?.splice(indexToDelete, 1);
    }
  },
  updateDeployStatus: (state, action) => {
    const { crmOrgId, rollupId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.rollups) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.rollups?.findIndex(
      (r) => r.rollupId === rollupId && r.versionId === versionId,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.rollups[indexToUpdate].status = optimisticStatus;
    }
  },
};

export const selectCrmOrgRollups = (crmOrgId: string) => (state: RootState) =>
  state.global.environments[crmOrgId]?.data?.rollups;

export const selectRollupsForTable = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return state.global.environments[crmOrg.id]?.data?.rollups;
  }
};
