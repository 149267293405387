import { PanelConsumer } from '../../../../panels/PanelsContext';
import { BantPanel } from '../plugins/bant/panel/BantPanel';
import { bantPanelType } from '../plugins/bant/panel/bantPanelType';
import { DataManagementPanel } from '../plugins/data-management/panel/DataManagementPanel';
import { dataManagementPanelType } from '../plugins/data-management/panel/dataManagementPanelType';
import { DynamicPathPanel } from '../plugins/dynamic-path/panel/DynamicPathPanel';
import { dynamicPathPanelType } from '../plugins/dynamic-path/panel/dynamicPathPanelType';
import { MeddicPanel } from '../plugins/meddic/panel/MeddicPanel';
import { meddicPanelType } from '../plugins/meddic/panel/meddicPanelType';
import { MeddpiccPanel } from '../plugins/meddpicc/panel/MeddpiccPanel';
import { meddpiccPanelType } from '../plugins/meddpicc/panel/meddpiccPanelType';
import { MaybeAllocateSpaceForPanelInCanvas } from './MaybeAllocateSpaceForPanelInCanvas';

export const FunnelMapPluginPanels = ({ isReadOnly }: { isReadOnly: boolean }) => {
  return (
    <>
      <PanelConsumer panelType={dataManagementPanelType}>
        {({ isOpen, data, maybeClosePanel }) => {
          return (
            <>
              {isOpen && data && (
                <MaybeAllocateSpaceForPanelInCanvas
                  anchorEl={data.anchorEl}
                  panelWidth={600}
                  panelHeight={260}
                >
                  <DataManagementPanel {...data} onClose={maybeClosePanel} />
                </MaybeAllocateSpaceForPanelInCanvas>
              )}
            </>
          );
        }}
      </PanelConsumer>
      <PanelConsumer panelType={bantPanelType}>
        {({ isOpen, data, maybeClosePanel, setIsDirty }) => {
          data?.anchorEl;
          return (
            <>
              {isOpen && data && (
                <MaybeAllocateSpaceForPanelInCanvas anchorEl={data.anchorEl}>
                  <BantPanel {...data} maybeClosePanel={maybeClosePanel} setIsDirty={setIsDirty} />
                </MaybeAllocateSpaceForPanelInCanvas>
              )}
            </>
          );
        }}
      </PanelConsumer>
      <PanelConsumer panelType={meddicPanelType}>
        {({ isOpen, data, maybeClosePanel, setIsDirty }) => {
          return (
            <>
              {isOpen && data && (
                <MaybeAllocateSpaceForPanelInCanvas anchorEl={data.anchorEl}>
                  <MeddicPanel
                    {...data}
                    maybeClosePanel={maybeClosePanel}
                    setIsDirty={setIsDirty}
                  />
                </MaybeAllocateSpaceForPanelInCanvas>
              )}
            </>
          );
        }}
      </PanelConsumer>
      <PanelConsumer panelType={meddpiccPanelType}>
        {({ isOpen, data, maybeClosePanel, setIsDirty }) => {
          return (
            <>
              {isOpen && data && (
                <MaybeAllocateSpaceForPanelInCanvas anchorEl={data.anchorEl}>
                  <MeddpiccPanel
                    {...data}
                    maybeClosePanel={maybeClosePanel}
                    setIsDirty={setIsDirty}
                  />
                </MaybeAllocateSpaceForPanelInCanvas>
              )}
            </>
          );
        }}
      </PanelConsumer>
      <PanelConsumer panelType={dynamicPathPanelType}>
        {({ isOpen, data, maybeClosePanel }) => {
          return (
            <>
              {isOpen && data && (
                <MaybeAllocateSpaceForPanelInCanvas
                  anchorEl={data.anchorEl}
                  panelWidth={1032}
                  panelHeight={476}
                >
                  <DynamicPathPanel
                    {...data}
                    isReadOnly={isReadOnly}
                    maybeClosePanel={maybeClosePanel}
                  />
                </MaybeAllocateSpaceForPanelInCanvas>
              )}
            </>
          );
        }}
      </PanelConsumer>
    </>
  );
};
