import { ConfigurationType, DependenciesContentViewType } from './types';
import { DependenciesContent } from './DependenciesContent';
import CommentsList from './annotations/comments/CommentsList';
import { useSelector } from 'react-redux';
import { selectDependenciesConfigurationItem } from '../../../reducers/documentationReducer';
import { SourceView } from './source-view/SourceView';
import { TagEditPage } from './annotations/tags/TagEditPage';
import { ConfigurationItem } from './DependenciesTypes';

interface DependenciesContentWrapperProps {
  crmOrgId: string;
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
}

export const DependenciesContentWrapper = ({
  crmOrgId,
  configurationItem,
  type,
}: DependenciesContentWrapperProps) => {
  const contentType = useSelector(selectDependenciesConfigurationItem)?.contentType;

  if (!contentType) {
    return <></>;
  }

  return (
    <>
      {contentType === DependenciesContentViewType.default && (
        <DependenciesContent
          key={configurationItem.id}
          crmOrgId={crmOrgId}
          configurationItem={configurationItem}
          type={type}
        />
      )}

      {contentType === DependenciesContentViewType.comments && (
        <CommentsList configurationItem={configurationItem} crmOrgId={crmOrgId} parentType={type} />
      )}

      {contentType === DependenciesContentViewType.sourceCode && (
        <SourceView configurationItem={configurationItem} crmOrgId={crmOrgId} parentType={type} />
      )}

      {contentType === DependenciesContentViewType.editTag && (
        <TagEditPage tagId={configurationItem.id} crmOrgId={crmOrgId} />
      )}
    </>
  );
};
