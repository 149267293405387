import { VerifyURLResultKeys, URLStatus } from '@server/verify-url';
import { SweepFieldTypes } from '../../../../types/enums/SweepFieldTypes';

export const UrlVerificationFieldTypes = [
  SweepFieldTypes.DateTime,
  SweepFieldTypes.Url,
  SweepFieldTypes.Text,
  SweepFieldTypes.TextArea,
  SweepFieldTypes.LongTextArea,
  SweepFieldTypes.Email,
  SweepFieldTypes.Html,
  SweepFieldTypes.EncryptedText,
  SweepFieldTypes.Phone,
];

export const getVerifiedUrlOptionsByType = (
  _field: AutomationActionField,
  showVerifiedUrlOption?: string,
) => {
  const isOptionRedirect = showVerifiedUrlOption === URLStatus.REDIRECT;
  const fieldType = _field
    ? UrlVerificationFieldTypes.includes(_field.fieldType as SweepFieldTypes)
    : false;

  if (!fieldType) {
    //please add your type to UrlVerificationFieldTypes if you want it supported
    return [];
  }

  switch (_field?.fieldType) {
    case SweepFieldTypes.DateTime:
      return [VerifyURLResultKeys.verifyUrlTimestamp];

    case SweepFieldTypes.Url:
      if (isOptionRedirect) {
        return [
          VerifyURLResultKeys.finalRedirectUrl,
          VerifyURLResultKeys.originalUrl,
          VerifyURLResultKeys.finalRedirectDomain,
        ];
      } else if (showVerifiedUrlOption === URLStatus.VALID) {
        return [VerifyURLResultKeys.originalUrl];
      } else {
        return [VerifyURLResultKeys.originalUrl];
      }

    case SweepFieldTypes.Text:
    case SweepFieldTypes.TextArea:
    case SweepFieldTypes.Email:
    case SweepFieldTypes.Html:
    case SweepFieldTypes.EncryptedText:
    case SweepFieldTypes.Phone:
    case SweepFieldTypes.LongTextArea:
      if (isOptionRedirect) {
        return [
          VerifyURLResultKeys.finalRedirectUrl,
          VerifyURLResultKeys.originalUrl,
          VerifyURLResultKeys.finalRedirectDomain,
        ];
      } else if (showVerifiedUrlOption === URLStatus.VALID) {
        return [VerifyURLResultKeys.originalUrl];
      } else {
        return [VerifyURLResultKeys.originalUrl, VerifyURLResultKeys.errorDetails];
      }
  }
};
