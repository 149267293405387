import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectParserInfo } from '../../../reducers/global/globalReducer';
import { selectTransientObjects, setObjects } from '../../../reducers/documentationReducer';

export const useInitializeObjectsStructure = ({
  recordTypesData = {},
  funnelsData = {},
  crmOrgId,
  objectTypes,
}: {
  recordTypesData?: RecordTypesData;
  funnelsData?: FunnelsData;
  crmOrgId: string;
  objectTypes?: ObjectTypeName[];
}) => {
  const dispatch = useDispatch();

  const pills = useSelector(selectParserInfo(crmOrgId))?.pills;
  const transientObjects = useSelector(selectTransientObjects(crmOrgId));

  useEffect(() => {
    dispatch(
      setObjects({
        funnelsData: funnelsData ?? {},
        recordTypesData: recordTypesData ?? {},
        objectTypeNames: objectTypes ?? [],
        transientObjects,
        pills,
      }),
    );
  }, [funnelsData, recordTypesData, objectTypes, dispatch, pills, transientObjects]);
};
