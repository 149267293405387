import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import useFunnelSettingsForm from './useFunnelSettingsForm';
import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';
import { FunnelSettingsContent } from './FunnelSettingsContent';
import { useFunnelSettingsDialog } from './useFunnelSettingsDialog';
import { selectFunnelSettingFunnels, selectFunnelSettingsName } from './funnelSettingsReducer';
import { useRunOnceWhenTruthy } from '../../../../common/useRunOnceWhenTruthy';
import { Typography } from '@sweep-io/sweep-design';

interface FunnelFlowSettingsDialogProps {
  title: string;
  crmOrgId: string;
}

export const FunnelFlowSettingsDialog = ({ title, crmOrgId }: FunnelFlowSettingsDialogProps) => {
  const currentFunnelName = useSelector(selectFunnelSettingsName);
  const funnels = useSelector(selectFunnelSettingFunnels);

  const { closeFunnelSettingsDialog, updateFunnelMapSettingsAndCloseDialog } =
    useFunnelSettingsDialog();

  /**
   * Reusing global check name uniqueness func on handleChange
   */
  const { handleChange, errors, isValid, setFieldValue } = useFunnelSettingsForm({
    onSubmit: () => {},
    funnels,
  });

  const onConfirm = useCallback(async () => {
    if (isValid) {
      updateFunnelMapSettingsAndCloseDialog({
        funnelData: funnels || [],
        funnelMap: { name: currentFunnelName || '' },
      });
    }
  }, [currentFunnelName, funnels, isValid, updateFunnelMapSettingsAndCloseDialog]);

  useRunOnceWhenTruthy(() => {
    if (funnels) {
      setFieldValue('funnels', Object.values(funnels));
    }
  }, !!funnels);

  return (
    <ConfirmDialog
      isOpen={true}
      handleClose={() => closeFunnelSettingsDialog()}
      onConfirm={onConfirm}
      onCancel={() => closeFunnelSettingsDialog()}
      maxWidth={false}
      PaperProps={{
        sx: {
          height: 'calc(100vh - 150px)',
          maxWidth: '990px',
          width: '100%',
        },
      }}
      disableConfirmButton={!isValid}
      dialogContentSx={{
        padding: 0,
      }}
      disableOnBackdrop
      showCloseButton
      dialogActionsSx={{ boxShadow: 5 }}
      titleComponent={
        <Typography variant="h1-bold" whiteSpace="nowrap">
          {title}
        </Typography>
      }
    >
      <FunnelSettingsContent handleChange={handleChange} errors={errors} crmOrgId={crmOrgId} />
    </ConfirmDialog>
  );
};
