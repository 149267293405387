import isNil from 'lodash/isNil';
import { SweepFieldTypes } from '../../../../types/enums/SweepFieldTypes';

export const isFormulaSupported = ({
  fieldSummary,
  field,
}: {
  fieldSummary?: SweepFieldSummary;
  field?: SweepField;
}) => {
  const _type = field?.fieldType ?? fieldSummary?.fieldType;
  if (!_type) return false;
  switch (_type) {
    case SweepFieldTypes.Text:
    case SweepFieldTypes.EncryptedText:
    case SweepFieldTypes.Checkbox:
    case SweepFieldTypes.Currency:
    case SweepFieldTypes.Date:
    case SweepFieldTypes.DateTime:
    case SweepFieldTypes.Email:
    case SweepFieldTypes.LongTextArea:
    case SweepFieldTypes.Number:
    case SweepFieldTypes.Percent:
    case SweepFieldTypes.Phone:
    case SweepFieldTypes.Url:
    case SweepFieldTypes.TextArea:
      return true;
    case SweepFieldTypes.Picklist:
    case SweepFieldTypes.MultiselectPicklist:
      const isRestricted = field?.properties?.restricted ?? fieldSummary?.isRestrictedPicklist;
      const isAllowed = isNil(isRestricted) ? false : !isRestricted;
      return isAllowed;
    default:
      return false;
  }
};
