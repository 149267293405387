import StyledTooltip from '../StyledTooltip';
import { IconButton } from '@sweep-io/sweep-design';
import { Plus } from '@sweep-io/sweep-design/dist/icons';

const AddFieldButton = ({ onAddFieldClick }: { onAddFieldClick: () => void }) => {
  return (
    <StyledTooltip title={'Create new field'} placement="top">
      <IconButton variant="outlined" size="small" onClick={onAddFieldClick}>
        <Plus />
      </IconButton>
    </StyledTooltip>
  );
};

export default AddFieldButton;
