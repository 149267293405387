import { useCallback, useState } from 'react';
import { useSave } from './useSave';
import { areFunnelMapsEquivalent } from './areFunnelMapsEquivalent';
import cloneDeep from 'lodash/cloneDeep';
import { useConfigurationCanvasState } from './useConfigurationCanvasState';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { useErrorHandling } from '../../../hooks/useErrorHandling';
import { updateRecordTypesFunnelsAndHubspotFunnels } from './updateRecordTypesFunnelsAndHubspotFunnels';
import { useSelector } from 'react-redux';
import { selectProductionCrmOrg } from '../environments/environmentsReducer';
import { telemetry } from '../../../telemetry';

export const useUpdateEnvironment = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { setLastFunnelMapInServer, canvasCrmOrgId } = useConfigurationCanvasContext();

  const { setCanvasFunnelMap } = useConfigurationCanvasState();

  const { saveFunnelMap } = useSave();
  const { errorHandlingBuilder } = useErrorHandling();
  const isProductionOrg = useSelector(selectProductionCrmOrg)?.id === canvasCrmOrgId;

  const updateEnvironment = useCallback(
    async (envFunnelMap: FunnelMap) => {
      setIsLoading(true);

      const funnelMap = cloneDeep(envFunnelMap);

      updateRecordTypesFunnelsAndHubspotFunnels(funnelMap, isProductionOrg);

      if (!areFunnelMapsEquivalent(funnelMap, envFunnelMap)) {
        try {
          errorHandlingBuilder()
            .withErrorNotification('Error updating environment view')
            .execute(async () => await saveFunnelMap(funnelMap));
        } catch (e) {
          telemetry.captureError(e, { message: 'Error saving funnel map' });
        }
      }
      setLastFunnelMapInServer(funnelMap);

      // TODO: Add notifications when they are ready in design

      // changes.forEach((change) => {
      //   addNotification({
      //     message: change,
      //     variant: SweepNotificationVariant.Default,
      //     keepOpen: false,
      //   });
      // });

      setCanvasFunnelMap(funnelMap);
      setIsLoading(false);
    },
    [
      errorHandlingBuilder,
      isProductionOrg,
      saveFunnelMap,
      setCanvasFunnelMap,
      setLastFunnelMapInServer,
    ],
  );

  return {
    updateEnvironment,
    isLoading,
  };
};
