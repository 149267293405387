import { PluginTypes } from '../types/enums/PluginTypes';

export interface PluginTypAndCriterion {
  pluginType: PluginTypes;
  criterion: PluginCriterionMap[];
}
export class PluginBaseModel {
  protected _plugin: PluginBase;
  constructor(plugin: FunnelPlugin) {
    this._plugin = plugin;
  }

  getType() {
    return this._plugin.id as PluginTypes;
  }
}

export class DataManagementPluginModel extends PluginBaseModel {
  constructor(plugin: DataManagementPlugin) {
    super(plugin);
  }
}

export class CriteriaPluginModel extends PluginBaseModel {
  criterionForStage(stageId: string) {
    return (this._plugin as CriteriaPlugin).criterionMap?.filter(
      (criterion) => criterion.stageId === stageId,
    );
  }
}

export class BantPluginModel extends CriteriaPluginModel {
  constructor(plugin: BantPlugin) {
    super(plugin);
  }
}

const criteriaPluginIds = [PluginTypes.BANT, PluginTypes.MEDDIC, PluginTypes.MEDDPICC];

export class FunnelPluginsModel {
  private _plugins: FunnelPluginsDto;

  constructor(plugins: FunnelPluginsDto) {
    this._plugins = plugins;
  }

  getPlugins(): FunnelPluginsDto {
    return this._plugins;
  }

  getBantPlugin(): BantPluginModel | undefined {
    return this._plugins[PluginTypes.BANT]
      ? new BantPluginModel(this._plugins[PluginTypes.BANT])
      : undefined;
  }

  getDataManagementPlugin(): DataManagementPluginModel | undefined {
    return this._plugins[PluginTypes.DATA_MANAGEMENT]
      ? new DataManagementPluginModel(this._plugins[PluginTypes.DATA_MANAGEMENT])
      : undefined;
  }

  getCriteriaPlugins(): CriteriaPluginModel[] {
    return Object.values(this._plugins)
      .filter((plugin) => criteriaPluginIds.includes(plugin.id))
      .map((plugin) => new CriteriaPluginModel(plugin));
  }
  criteriaPluginsForStage(stageId: string): PluginTypAndCriterion[] {
    return this.getCriteriaPlugins().map((plugin) => {
      return {
        pluginType: plugin.getType(),
        criterion: plugin.criterionForStage(stageId),
      };
    });
  }
}
