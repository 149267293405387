import _keyBy from 'lodash/keyBy';
import { SweepCanvasNode, SweepCanvasNodeTransformations } from '../canvasTypes';

interface StepsRightFuncProps {
  startingNode: SweepCanvasNode;
  sweepNodes: SweepCanvasNode[];
  includeOwn?: boolean;
}

export const getStepsOnTheRightOf = ({
  startingNode,
  sweepNodes,
  includeOwn,
}: StepsRightFuncProps): SweepCanvasNode[] => {
  const relativeColumn = includeOwn ? 0 : 1;
  const startingColumn = startingNode.position.column + relativeColumn;

  const startingNodeHasImmediatelyRightNode = () =>
    sweepNodes.find(
      (_node) =>
        _node.position.column === startingNode.position.column + 1 &&
        _node.parentId === startingNode.parentId,
    );

  const nodePositionTransformations =
    includeOwn || startingNodeHasImmediatelyRightNode()
      ? sweepNodes.filter(
          (_node) =>
            _node.position.row === startingNode.position.row &&
            _node.position.column >= startingColumn &&
            _node.parentId === startingNode.parentId,
        )
      : [];

  return nodePositionTransformations;
};

export const shiftStepsRight = (props: StepsRightFuncProps): SweepCanvasNodeTransformations => {
  const nodePositionTransformations = getStepsOnTheRightOf(props).map((node) => ({
    ...node,
    position: {
      ...node.position,
      column: node.position.column + 1,
    },
  }));

  return _keyBy(nodePositionTransformations, 'id');
};
