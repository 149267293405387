import { useEffect, useRef } from 'react';

const useRunOnce = (cb: () => any) => {
  const isRun = useRef(false);

  useEffect(() => {
    if (!isRun.current) {
      isRun.current = true;
      cb();
    }
  }, [cb]);
};

export { useRunOnce };
