import { Grid, Box, FormControlLabel } from '@mui/material';
import {
  Typography,
  Checkbox,
  colors,
  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___,
} from '@sweep-io/sweep-design';
import { OpportunityStageFields } from './StageOpportunityFields';
import { styled } from '@mui/material/styles';
import { funnelDetailModel } from '../../../../../models/funnelDetailModel';

interface StageMetadataRelatedFieldsProps {
  readonly?: boolean;
  crmOrgId: string;
  funnelDetails: FunnelDetails;
  leadingFieldMetadata: StageMetadata;
  onStageMetadataChange: (leadingFieldMetadata: StageMetadata) => Promise<boolean>;
  opportunityTypes?: OpportunityType[];
}

const StageMetadataRelatedFields = ({
  readonly,
  funnelDetails,
  leadingFieldMetadata,
  onStageMetadataChange,
  opportunityTypes,
}: StageMetadataRelatedFieldsProps) => {
  const funnelDetailsModel = funnelDetailModel(funnelDetails);
  const isLeadStatus = funnelDetailsModel.isLeadingFieldLeadStatus();
  const isCaseStatus = funnelDetailsModel.isLeadingFieldCaseStatus();
  const isOpportunityStage = funnelDetailsModel.isLeadingFieldOpportunityStage();

  const markStepAsClosed = leadingFieldMetadata?.closed || false;
  const markStepAsConverted = leadingFieldMetadata?.converted || false;

  return (
    <>
      {isLeadStatus && (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '14px' }}>
          <FormControlLabel
            label="Mark step as ‘converted’"
            control={
              <Checkbox
                disabled={readonly}
                checked={markStepAsConverted}
                onChange={(event) => {
                  onStageMetadataChange({
                    ...leadingFieldMetadata,
                    converted: event.target.checked,
                  });
                }}
              />
            }
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontSize,
                fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontWeight,
              },
            }}
          />
          <StyledBox>
            <Typography variant="caption" color={colors.grey[800]}>
              Checking this box will give any lead in this step a "Converted" status
            </Typography>
          </StyledBox>
        </Grid>
      )}

      {isCaseStatus && (
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', paddingBottom: '14px' }}>
          <FormControlLabel
            label="Mark step as ‘closed’"
            control={
              <Checkbox
                disabled={readonly}
                checked={markStepAsClosed}
                onChange={(event) => {
                  onStageMetadataChange({
                    ...leadingFieldMetadata,
                    closed: event.target.checked,
                  });
                }}
              />
            }
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontSize,
                fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontWeight,
              },
            }}
          />
          <StyledBox>
            <Typography variant="caption" color={colors.grey[800]}>
              Checking this box will give any lead in this step a "Closed" status
            </Typography>
          </StyledBox>
        </Grid>
      )}
      {isOpportunityStage && (
        <Grid item xs={12}>
          <OpportunityStageFields
            readonly={!!readonly}
            opportunityTypes={opportunityTypes}
            onStageMetadataChange={onStageMetadataChange}
            leadingFieldMetadata={leadingFieldMetadata}
          />
        </Grid>
      )}
    </>
  );
};

export const StyledBox = styled(Box)({
  marginTop: '16px',
  display: 'inline-flex',
  maxWidth: '405px',
});

export { StageMetadataRelatedFields };
