import { Box, Stack } from '@mui/material';
import fetchAnimation from '../pages/get-started/svgs/fetchAnimation.gif';
import { Typography } from '@sweep-io/sweep-design';

//This is temporary until getting the UX definition
const FetchingPanel = () => {
  return (
    <Stack height="100%" justifyContent="center" alignItems="center">
      <Box
        sx={{
          width: '504px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={fetchAnimation} alt="Loading" width="100%" height="100%" />
      </Box>
      <Stack gap={1} justifyContent="center" alignItems="center" maxWidth="408px">
        <Typography variant="h3">Scanning your Salesforce</Typography>

        <Box textAlign="center">
          <Typography variant="body">
            We will send you an email once the scan is complete.
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FetchingPanel;
