import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../Automations/AutomationsContext';
import { AutomationType } from '../../types/enums/AutomationType';
import { UI_EVENTS } from '../../services/events';
import DrawerEmptyImage from '../common/empty-state/svgs/drawerEmpty.svg?react';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { useMemo } from 'react';

export const AutomationAlertsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { patch_alerts_admin_notification } = useAutomationsApiFacade();
  const contextValue: AutomationsContextType = useMemo(
    () => ({
      automationType: AutomationType.Alert,
      permissions: {
        create: 'create:alerts',
        edit: 'edit:alerts',
        deploy: 'deploy:alerts',
        delete: 'delete:alerts',
        notifyAdmin: 'edit:alerts:notify-admin',
      },
      emptyStateTitle: 'Create your first alert',
      emptyStateImage: <DrawerEmptyImage />,
      defaultName: 'New Alert',
      placeholder: 'Alert name',
      nameInMessage: 'alerts',
      nameInMessageSingular: 'alert',
      openEvent: UI_EVENTS.alertsOpen,
      automationVariant: AutomationFormVariant.PLAYBOOK_ALERT,
      templateSupport: true,
      requestDeployNotifyAdmin: patch_alerts_admin_notification,
    }),
    [patch_alerts_admin_notification],
  );

  return <AutomationsContextProvider value={contextValue}>{children}</AutomationsContextProvider>;
};
