import { Box, Popover, Typography } from '@mui/material';
import { TruncatedTextTooltip } from '../../../../../../../common/TruncatedTextTooltip';

import { IconButton, colors } from '@sweep-io/sweep-design';
import { useRef, useState } from 'react';
import { ArrowRight, Check, ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { StyledListItem } from '../../../../../../../common/StyledListItem';
import { getObjectTypeColor } from '../../../../../../../multi-canvas/helpers/getObjectTypeColor';

export const MIN_STAGE_WIDTH_NUMBER = 198;
interface DynamicPathStageProps {
  stage?: SweepStage;
  objectName?: string;
  onStageClick: (stageId: string, exitCriteriaId?: string) => void;
  onChangePath: (
    stageSelectToReplace: string,
    stageSelectToReplaceFor: string,
    _stageIdPrevious: string,
  ) => void;
  isActive: boolean;
  mappedIdToStageName: { [stageId: string]: string };
  _nextStageId?: string;
}

export const DynamicPathStage = ({
  stage,
  _nextStageId,
  mappedIdToStageName,
  objectName,
  onStageClick,
  onChangePath,
  isActive,
}: DynamicPathStageProps) => {
  const wrapperRef = useRef<any>();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const bgLeadingColor = getObjectTypeColor(objectName)?.leadingColorName as keyof typeof colors;
  const bg = colors[bgLeadingColor] as any;
  const currentBg = isActive ? bg[300] : bg[100];

  const exitCriteria = stage?.exitCriteria.find((ec) => ec._nextStageId === _nextStageId);
  const criteriaIdx = stage?.exitCriteria.findIndex((ec) => ec._nextStageId === _nextStageId) ?? 0;

  if (!stage) {
    return <></>;
  }

  return (
    <Box
      sx={{
        minWidth: MIN_STAGE_WIDTH_NUMBER + 'px',
        width: '100%',
        height: '40px',
        background: currentBg,
        boxShadow: '0px 0.85px 2.124px 0px rgba(0, 0, 0, 0.15)',
        borderRadius: 0.5,
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        border: `1px solid ${Boolean(anchorEl) ? colors.blue[500] : currentBg}`,

        '&:hover': {
          borderColor: isActive ? currentBg : colors.blue[500],
        },
      }}
      onClick={() => onStageClick(stage._stageId, exitCriteria?._exitCriteriaId)}
    >
      <Box
        sx={{
          padding: '12px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
        component="div"
        ref={wrapperRef}
      >
        <TruncatedTextTooltip variant="body-bold">
          {mappedIdToStageName[stage._stageId]}
        </TruncatedTextTooltip>

        {stage.exitCriteria.length > 1 && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Typography variant="body-bold">
              ({criteriaIdx + 1}/{stage.exitCriteria.length})
            </Typography>
            <IconButton size="tiny" variant="flat" onClick={() => setAnchorEl(wrapperRef.current)}>
              {Boolean(anchorEl) ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </Box>
        )}
      </Box>

      {stage.exitCriteria.length > 1 && (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClick={(event) => event.stopPropagation()}
          onClose={() => setAnchorEl(null)}
        >
          <Box p={1}>
            {stage.exitCriteria.map((exitCriteria) => (
              <StyledListItem
                title={
                  <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', mr: 1 }}>
                    <Typography
                      variant={exitCriteria._nextStageId === _nextStageId ? 'body-bold' : 'body'}
                    >
                      {mappedIdToStageName[stage._stageId]}
                    </Typography>
                    <ArrowRight />
                    <Typography
                      variant={exitCriteria._nextStageId === _nextStageId ? 'body-bold' : 'body'}
                    >
                      {mappedIdToStageName[exitCriteria._nextStageId]}
                    </Typography>
                  </Box>
                }
                sx={{
                  height: '40px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  minWidth: '200px',
                }}
                key={'ec_' + exitCriteria._exitCriteriaId}
                isActive={exitCriteria._nextStageId === _nextStageId}
                onClick={() => {
                  onChangePath(_nextStageId ?? '', exitCriteria._nextStageId, stage._stageId);
                  setAnchorEl(null);
                }}
                EndAdornment={exitCriteria._nextStageId === _nextStageId && <Check />}
              />
            ))}
          </Box>
        </Popover>
      )}
    </Box>
  );
};
