import { useCallback, useMemo } from 'react';
import { selectIsLoadingRoleGroups, selectRoleGroups } from '../../reducers/roleGroupsReducer';
import { useSelector } from 'react-redux';
import { RoleGroupType, RoleLevel } from '@server/role-group-interface';
import { SweepRoles } from '../../hooks/useUserInfo';
import { useRoleGroups } from '../dashboard/role-manager/useRoleManager';
import { useRunOnceWhenTruthy } from './useRunOnceWhenTruthy';
import { selectUserInfoData } from '../../reducers/userInfoReducer';

/**
 * NOTE: THIS HOOKS INITIALIZES ROLE GROUPS
 */
export const useSweepRoleGroups = () => {
  const currentUser = useSelector(selectUserInfoData);
  const roleGroups = useSelector(selectRoleGroups);
  const isLoading = useSelector(selectIsLoadingRoleGroups);
  const { fetchRoleGroups } = useRoleGroups();

  useRunOnceWhenTruthy(
    () => {
      if (!roleGroups && !isLoading) {
        fetchRoleGroups();
      }
    },
    !roleGroups && !!currentUser && !isLoading,
  );

  const isAdmin = useCallback(
    (userRoleGroupId: string) => {
      const userRoleGroup = roleGroups?.find((roleGroup) => roleGroup.id === userRoleGroupId);
      return (
        userRoleGroup?.type === RoleGroupType.SYSTEM && userRoleGroup.name === SweepRoles.Admin
      );
    },
    [roleGroups],
  );

  const isCollaborator = useCallback(
    (userRoleGroupId: string) => {
      const userRoleGroup = roleGroups?.find((roleGroup) => roleGroup.id === userRoleGroupId);
      return (
        userRoleGroup?.type === RoleGroupType.SYSTEM &&
        userRoleGroup.name === SweepRoles.Collaborator
      );
    },
    [roleGroups],
  );

  const getUserRole = useCallback(
    (userRoleGroupId: string) => roleGroups?.find((roleGroup) => roleGroup.id === userRoleGroupId),
    [roleGroups],
  );

  const isUserWithRequestToDeployFunnels = useMemo(
    () =>
      isCollaborator(currentUser?.roleGroupId ?? '') ||
      getUserRole(currentUser?.roleGroupId ?? '')?.roleLevels.funnels ===
        RoleLevel.REQUEST_TO_DEPLOY,
    [getUserRole, currentUser?.roleGroupId, isCollaborator],
  );

  return {
    isAdmin,
    getUserRole,

    isUserWithRequestToDeployFunnels,
  };
};
