import { MenuItem, ListItemIcon, Box, Stack } from '@mui/material';
import { Button, Tag, Typography, colors } from '@sweep-io/sweep-design';
import { CustomWebhookInterface, useWebhooksContext } from '../webhooks/WebhooksContext';

interface CustomIntegrationOptionProps {
  integration: CustomWebhookInterface;
}

export const CustomIntegrationsOption = ({ integration }: CustomIntegrationOptionProps) => {
  const { setWebhooks, setEditWebhook } = useWebhooksContext();
  return (
    <MenuItem>
      <Stack width="100%">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <ListItemIcon>logo</ListItemIcon>
          <Box
            sx={{
              flex: '1',
              textWrap: 'wrap',
              gap: 1,
              display: 'flex',
            }}
          >
            <Typography variant="body-medium">{integration.name}</Typography>

            <Tag color={colors.blue[100]} label="Custom integration" textTheme="dark" />
          </Box>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <Button
              onClick={() => {
                setEditWebhook(integration);

                setWebhooks(true);
              }}
              size="small"
              startIconName=""
              variant="outlined"
            >
              Edit
            </Button>
          </Box>
        </Box>
      </Stack>
    </MenuItem>
  );
};
