import { cloneElement, ComponentType, forwardRef, HTMLAttributes } from 'react';
import { Virtuoso } from 'react-virtuoso';

export const ListboxComponent = forwardRef<HTMLUListElement>((props, ref) => {
  const { children, ...rest } = props as any;
  const data = children;

  return (
    <ul ref={ref} {...rest}>
      <Virtuoso
        style={{ height: '200px' }}
        data={data}
        itemContent={(index, child) => {
          return cloneElement(child, { index });
        }}
      />
    </ul>
  );
}) as ComponentType<HTMLAttributes<HTMLElement>>;
