import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { CanvasPillTypes } from '../../multi-canvas/canvasTypes';

const canvasPillToDocumentationTabMap: any = {
  [CanvasPillTypes.apexTrigger]: DocumentationTabTypes.APEX,
  [CanvasPillTypes.groupApex]: DocumentationTabTypes.APEX,
  [CanvasPillTypes.sfdcAutomation]: DocumentationTabTypes.SF_AUTOMATIONS,
  [CanvasPillTypes.groupSfdcAutomation]: DocumentationTabTypes.SF_AUTOMATIONS,
  [CanvasPillTypes.validationRule]: DocumentationTabTypes.VALIDATION_RULES,
  [CanvasPillTypes.groupValidationRule]: DocumentationTabTypes.VALIDATION_RULES,

  [CanvasPillTypes.automation]: DocumentationTabTypes.SWEEP_AUTOMATIONS,
  [CanvasPillTypes.groupAutomation]: DocumentationTabTypes.SWEEP_AUTOMATIONS,
  [CanvasPillTypes.playbookAlert]: DocumentationTabTypes.PLAYBOOK_ALERTS,
  [CanvasPillTypes.groupPlaybookAlert]: DocumentationTabTypes.PLAYBOOK_ALERTS,
  [CanvasPillTypes.assignmentRule]: DocumentationTabTypes.ASSIGNMENTS,
  [CanvasPillTypes.groupAssignmentRule]: DocumentationTabTypes.ASSIGNMENTS,
  [CanvasPillTypes.scheduledAssignment]: DocumentationTabTypes.SCHEDULED_ASSIGNMENTS,
  [CanvasPillTypes.groupScheduledAssignment]: DocumentationTabTypes.SCHEDULED_ASSIGNMENTS,
  [CanvasPillTypes.groupDeduplication]: DocumentationTabTypes.MATCHING_DEDUPE,
  [CanvasPillTypes.groupMatching]: DocumentationTabTypes.MATCHING_DEDUPE,
};

export const getDocumentationTabFromCanvasPill = (pillType: CanvasPillTypes) => {
  if (canvasPillToDocumentationTabMap.hasOwnProperty(pillType)) {
    return canvasPillToDocumentationTabMap[pillType] as DocumentationTabTypes;
  }
};
