import * as React from "react";
const SvgCloudLockedDark = (props) => /* @__PURE__ */ React.createElement("svg", { width: 128, height: 129, viewBox: "0 0 128 129", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M54.9398 50.1673C58.4242 46.5694 63.2738 44.3414 68.6313 44.3414C75.7747 44.3414 81.9656 48.2778 85.2992 54.1431C88.2583 52.831 91.4634 52.1536 94.7046 52.1552C107.563 52.1552 118 62.5867 118 75.4589C118 88.331 107.563 98.7625 94.7046 98.7625C93.1331 98.7625 91.6012 98.6059 90.1011 98.3098C87.1843 103.467 81.6084 106.97 75.2588 106.97C72.6736 106.975 70.1215 106.393 67.7979 105.269C64.8413 112.158 57.9559 117 49.9394 117C41.5658 117 34.4621 111.765 31.7238 104.403C30.5051 104.659 29.2628 104.787 28.0172 104.786C18.0561 104.786 10 96.677 10 86.7178C10 80.0259 13.6273 74.2 19.0086 71.0508C17.8669 68.4422 17.2793 65.6282 17.2823 62.7843C17.2823 51.29 26.6877 42 38.2759 42C45.0621 42 51.134 45.2082 54.9438 50.1878", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M44 41.8182V31.8788C44 26.6066 46.1598 21.5504 50.0043 17.8224C53.8488 14.0944 59.0631 12 64.5 12C69.9369 12 75.1512 14.0944 78.9957 17.8224C82.8402 21.5504 85 26.6066 85 31.8788V53", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M52 47V32.2727C52 29.0178 53.3696 25.8962 55.8076 23.5946C58.2456 21.293 61.5522 20 65 20C68.4478 20 71.7544 21.293 74.1924 23.5946C76.6304 25.8962 78 29.0178 78 32.2727V45.7727", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgCloudLockedDark;
