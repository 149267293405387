import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectObjects } from '../../../reducers/documentationReducer';
import { DispatchSingleObjectNameAndFocusOnElementFuncProps, ObjectWithPills } from '../types';
import { GroupedVirtuoso } from 'react-virtuoso';
import { Box } from '@mui/material';
import flatten from 'lodash/flatten';
import { ObjectCardPills } from './ObjectCardPills';
import { ObjectVirtualListHeader } from './ObjectVirtualListHeader';
import { useObjectListInteractions } from './useObjectListInteractions';
import { DialogLoader } from '../../common/dialogs/DialogLoader';
import { headerTitleLabels } from './headerTitleLabels';
import { ObjectCardTable } from './ObjectCardTable';
import { ObjectWithPillsMap } from '../dependencies/types';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';

interface ObjectListViewProps {
  hideAddObjectButton?: boolean;
  objectNamesUsedInCanvas?: string[];
  dispatchSingleObjectNameAndFocusOnElement: DispatchSingleObjectNameAndFocusOnElementFuncProps;
}

export const ObjectVirtualList = ({
  hideAddObjectButton,
  objectNamesUsedInCanvas,
  dispatchSingleObjectNameAndFocusOnElement,
}: ObjectListViewProps) => {
  const { isExpandedMode } = useExpandedMode();
  const { onCardClick, isLoading } = useObjectListInteractions({
    dispatchSingleObjectNameAndFocusOnElement,
  });

  const [isCollapsedPanel, setIsCollapsedPanel] = useState<{
    [key in keyof ObjectWithPillsMap]: boolean;
  }>({ favorites: true, standardObjects: false, cpqObjects: false, customObjects: false });

  const objects = useSelector(selectObjects);

  const { items, groups, groupCounts } = useMemo(() => {
    const groups = Object.keys(objects)
      .map((title) => ({
        title: title as keyof ObjectWithPillsMap,
        count: objects[title as keyof ObjectWithPillsMap].length,
      }))
      .filter((item) => item.count !== 0);

    const isExpandedFavorites = (idx: number) =>
      groups[idx].title === 'favorites' && isExpandedMode && groups[idx].count > 0;

    const dynamicallyCreatedItems: ObjectWithPills[] = flatten(
      groups.map((group) => {
        const isPanelOpen = isCollapsedPanel[group.title];
        const items = objects[group.title];

        if (group.title === 'favorites') {
          const firstItem = items[0];
          return isPanelOpen ? (isExpandedMode ? [firstItem] : items) : [];
        }

        return isPanelOpen ? items : [];
      }),
    );

    const groupCounts = groups.map((group, idx) =>
      isCollapsedPanel[group.title] ? (isExpandedFavorites(idx) ? 1 : group.count) : 0,
    );

    return { items: dynamicallyCreatedItems, groups, groupCounts };
  }, [objects, isCollapsedPanel, isExpandedMode]);

  return (
    <Box height="calc(100% - 40px)">
      <DialogLoader visible={isLoading} variant="light" />

      <GroupedVirtuoso
        style={{ height: '100%', overflow: 'scroll' }}
        groupCounts={groupCounts}
        groupContent={(index) => {
          return (
            <ObjectVirtualListHeader
              isCollapsedIn={isCollapsedPanel[groups[index].title]}
              setIsCollapsedIn={(panelState) =>
                setIsCollapsedPanel({ ...isCollapsedPanel, ...panelState })
              }
              itemCounter={groups[index].count}
              title={headerTitleLabels[groups[index].title]}
              objectWithPillsMapKey={groups[index].title}
            />
          );
        }}
        itemContent={(index, parentIdx) => {
          const item = items[index];

          if (!item) {
            return <></>;
          }

          if (isExpandedMode && groups[parentIdx].title === 'favorites') {
            return <ObjectCardTable items={objects.favorites} onClick={onCardClick} />;
          }

          return (
            <ObjectCardPills
              key={item.objectType}
              item={item}
              onCardClick={onCardClick}
              hideAddObjectButton={hideAddObjectButton}
              displayPills={!!objectNamesUsedInCanvas?.includes(item.objectType)}
            />
          );
        }}
      />
    </Box>
  );
};
