import { SxProps } from '@mui/material';
import { DateView, DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { Calendar, ChevronLeft, ChevronRight } from '@sweep-io/sweep-design/dist/icons';
import { DateTime } from 'luxon';

interface DatePickerElementProps {
  defaultValue?: DateTime | null;
  value?: DateTime | null;
  disableFuture?: boolean;
  shouldDisableDate?: (chosenDate: DateTime) => boolean;
  callback: (value: DateTime | null) => void;
  disablePast?: boolean;
  views?: DateView[];
  minDate?: DateTime;
  isError?: boolean;
  textFieldSx?: SxProps;
}
export const DatePickerElement = ({
  defaultValue,
  value,
  disableFuture,
  shouldDisableDate,
  callback,
  disablePast,
  views,
  minDate,
  isError = false,
  textFieldSx,
}: DatePickerElementProps) => {
  return (
    <MuiDatePicker
      slots={{
        openPickerIcon: Calendar,
      }}
      slotProps={{
        popper: {
          sx: {
            boxShadow: '0px 5px 25px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.10)',
            marginTop: '4px !important',
            borderRadius: '8px',
            overflow: 'hidden',

            '& .MuiDateCalendar-root': {
              width: '228px',
              height: 'auto',
            },

            '& .MuiPickersSlideTransition-root': {
              minHeight: '170px',
            },

            '& .MuiPickersCalendarHeader-root': {
              padding: 0,
              margin: '0 0 20px',
            },
            '& .MuiCalendarOrClockPicker-root > div, & .MuiCalendarPicker-root': {
              width: '228px',
              height: '252px',
              maxHeight: '252px',
            },
            '& .MuiCalendarPicker-viewTransitionContainer, & .PrivatePickersSlideTransition-root': {
              minHeight: '165px',
              paddingTop: '25px',
            },
            '& .MuiPickersCalendarHeader-label': {
              fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontSize,
              fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontWeight,
              color: colors.grey[800],
            },
            '& .MuiPickersCalendarHeader-labelContainer': {
              position: 'absolute',
              top: '16px',
              left: 0,
              right: 0,
              pointerEvents: 'none',
              justifyContent: 'center',

              '& > .MuiButtonBase-root': {
                display: 'none',
              },
            },
            '& .MuiPickersArrowSwitcher-root > .MuiPickersArrowSwitcher-button': {
              position: 'absolute',
              top: '4px',
              right: '8px',
              '&:first-of-type': {
                left: '8px',
                right: 'auto',
              },

              '&.Mui-disabled svg path': {
                stroke: colors.grey[500],
              },
            },
            '& .MuiDayPicker-weekDayLabel': {
              fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontSize,
              fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontWeight,
              color: colors.grey[500],
            },
            '& .MuiPickersDay-root, & .MuiDayCalendar-weekDayLabel': {
              width: '25px',
              height: '25px',
            },
            '& .MuiPickersDay-root': {
              color: colors.black,

              '&.Mui-disabled': {
                color: colors.grey[500],
              },

              '&.Mui-selected, &:focus, &:hover': {
                background: colors.blue[100],
                border: '2px solid white',
                color: colors.blue[500],
                fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['caption-bold']?.fontWeight,
              },

              '&.MuiPickersDay-today': {
                background: colors.grey[200],
                border: '2px solid white',
                color: colors.grey[500],
              },
            },
          },
        },
        previousIconButton: ChevronLeft,
        nextIconButton: ChevronRight,
        textField: (params) => ({
          error: isError,
          sx: { ...params.sx, ...textFieldSx },
        }),
      }}
      defaultValue={defaultValue}
      value={value}
      disableFuture={disableFuture}
      shouldDisableDate={shouldDisableDate}
      onChange={callback}
      disablePast={disablePast}
      views={views}
      minDate={minDate}
    />
  );
};
