import { Box } from '@mui/material';
import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { FieldAssignmentRow } from './FieldAssignmentRow';
import { useRef } from 'react';
import { ErrorPopover } from '../../common/ErrorPopover';

interface FieldAssignmentComponentProps {
  assignFromObject: string;
  assignToObject: string;
  showAddFields?: boolean;
  assignFromLabel: string;
  assignToLabel: string;
  crmOrgId: string;
  assignToPlaceholder?: string;
  assignFromPlaceholder?: string;
  mappingArray?: FieldMappingStruct[];
  onAddCallback?: (field: FieldMappingStruct) => any;
  onChange: (field: FieldMappingStruct[]) => any;
  displayError?: boolean;
  restrictToUser?: boolean;
  readonly?: boolean;
}

export const FieldAssignmentComponent = ({
  assignFromObject,
  assignToObject,
  showAddFields,
  assignFromLabel,
  assignToLabel,
  crmOrgId,
  assignToPlaceholder,
  assignFromPlaceholder,
  mappingArray,
  onAddCallback,
  onChange,
  displayError,
  restrictToUser,
  readonly,
}: FieldAssignmentComponentProps) => {
  const criterionRef = useRef<HTMLDivElement | unknown>();
  return (
    <>
      <Box
        sx={{ width: '50%', display: 'flex', gap: '12px', alignItems: 'end' }}
        ref={criterionRef}
      >
        <Box sx={{ flex: '1' }}>
          <Typography variant="caption" color={colors.grey[800]}>
            {assignFromLabel}
          </Typography>
        </Box>
        <Box sx={{ width: '16px' }}></Box>
        <Box sx={{ flex: '1' }}>
          <Typography variant="caption" color={colors.grey[800]}>
            {assignToLabel}
          </Typography>
        </Box>
      </Box>
      <Box>
        {mappingArray?.length === 0 ? (
          <FieldAssignmentRow
            readonly={readonly}
            restrictToUser={restrictToUser}
            assignFromObject={assignFromObject}
            assignToObject={assignToObject}
            assignToPlaceholder={assignToPlaceholder}
            assignFromPlaceholder={assignFromPlaceholder}
            crmOrgId={crmOrgId}
            onChange={(fieldMapping: Partial<FieldMappingStruct>) => {
              onChange([fieldMapping]);
            }}
          />
        ) : (
          mappingArray?.map((mappingObject, idx) => (
            <FieldAssignmentRow
              key={idx}
              readonly={readonly}
              restrictToUser={restrictToUser}
              assignFromObject={assignFromObject}
              assignToObject={assignToObject}
              assignToPlaceholder={assignToPlaceholder}
              assignFromPlaceholder={assignFromPlaceholder}
              crmOrgId={crmOrgId}
              mappingObject={mappingObject}
              onDelete={() => {
                const tempFields = [...(mappingArray || [])];
                tempFields.splice(idx, 1);
                onChange(tempFields);
              }}
              onChange={(fieldMapping: FieldMappingStruct) => {
                const newFields = [...(mappingArray || [])];
                if (newFields) {
                  newFields[idx] = fieldMapping;
                  onChange(newFields);
                }
              }}
            />
          ))
        )}
      </Box>
      {showAddFields && (
        <Box sx={{ marginTop: '16px' }}>
          <Button
            disabled={mappingArray?.length === 0 || readonly}
            onClick={() =>
              onAddCallback &&
              onAddCallback({
                fromField: { fieldIds: [] },
                toField: { fieldIds: [] },
              })
            }
            startIconName="Plus"
            variant="flat"
          >
            Add Mapping
          </Button>
        </Box>
      )}
      <ErrorPopover
        isOpen={!!displayError}
        errorText="Make sure all fields are filled"
        criterionRef={criterionRef.current || undefined}
        onClose={() => {}}
      />
    </>
  );
};
