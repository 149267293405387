import pluralize from 'pluralize';
import { CompleteSfSetupDialog } from '../../complete-sf-setup/CompleteSfSetupDialog';

export const CompleteSfSetupForDeployDialog = ({
  cancelDialog,
  entityName,
  onCompleteItemsCallback,
  title,
}: {
  cancelDialog: () => void;
  entityName: string;
  onCompleteItemsCallback: () => void;
  title?: string;
}) => {
  return (
    <CompleteSfSetupDialog
      cancelDialog={cancelDialog}
      onCompleteItemsCallback={onCompleteItemsCallback}
      title={title ?? `Deploy your saved ${pluralize(entityName)} 🎉`}
      subtitle="Complete the following 3 simple steps to see it live in Salesforce:"
    />
  );
};
