import { aStage, stageModel } from './stageModel';
import _intersectionWith from 'lodash/intersectionWith';
import _orderBy from 'lodash/orderBy';
import { StageType } from '../types/enums/StageType';
import { SFDCObjectType } from '../types/enums/SFDCObjectType';
import { clearFirstConditionIfEmpty } from '../components/common/rule-builder/helpers';
import { FunnelPluginsModel } from './PluginModel';
import flatten from 'lodash/flatten';

const funnelDetailModel = (funnel: FunnelDetails) => {
  const stageByStageName = (stageName: string) => {
    return funnel.stages.find((stage) => stage.stageName === stageName);
  };

  const addStage = (stage: SweepStage) => {
    funnel.stages = [...funnel.stages, stage];
    return stageById(stage._stageId);
  };

  const createStage = (stageName: string) => {
    const newStage = aStage({ stageName });
    funnel.stages.push(newStage);
    return newStage;
  };

  const updateStage = (newStageData: SweepStage) => {
    const updatedStages = funnel.stages.map((stage) =>
      stage._stageId === newStageData._stageId ? newStageData : stage,
    );
    funnel.stages = updatedStages;
  };

  const updateStageAndRemoveEmptyCriteria = (newStageData: SweepStage) => {
    const updatedStages = funnel.stages.map((stage) => {
      if (stage._stageId === newStageData._stageId) {
        const newEC = newStageData.exitCriteria.map((ec) => clearFirstConditionIfEmpty(ec));
        return { ...newStageData, exitCriteria: newEC };
      }
      return stage;
    });
    funnel.stages = updatedStages;
  };

  const removeStage = (_stageId: string) => {
    const idx = funnel.stages.findIndex((stage) => stage._stageId === _stageId);

    if (idx !== -1) {
      const currentStage = funnel.stages[idx];
      const { _branchIndex, _stageColumnIndex } = currentStage;

      if (!isFirstStage(_branchIndex, _stageColumnIndex)) {
        updateStagesConnections(currentStage);
      }

      funnel.stages = funnel.stages.filter((stage, index) => index !== idx);

      // Removes all referenced exit criteria.
      funnel.stages = funnel.stages.map((stage) => {
        stage.exitCriteria = stage.exitCriteria.filter(
          (exitCriteria) => exitCriteria._nextStageId !== _stageId,
        );
        return stage;
      });

      clearDynamicPathPlugin();
    }
  };

  const removeExitCriteriaById = (stageId: string, exitCriteriaId: string) => {
    funnel.stages = funnel.stages.map((stage) => {
      if (stage._stageId === stageId) {
        return {
          ...stage,
          exitCriteria: stage.exitCriteria.filter(
            (oldExitCriteria) => oldExitCriteria._exitCriteriaId !== exitCriteriaId,
          ),
        };
      }

      return stage;
    });

    clearDynamicPathPlugin();
  };

  const clearDynamicPathPlugin = () => {
    const currentDynamicPath = funnel.plugins['dynamic-path-plugin'];
    const currentPluginStages = currentDynamicPath?.stages;

    const funnelStagesIds = funnel.stages.map((stage) => stage._stageId);
    const funnelExitCriteriaIds = flatten(
      funnel.stages.map((stage) => stage.exitCriteria.map((ec) => ec._exitCriteriaId)),
    );

    if (currentPluginStages) {
      const filteredStages = currentPluginStages
        .filter((stage) => funnelStagesIds.includes(stage.stageId))
        .map((stage) => {
          return {
            ...stage,
            exitCriteria: stage.exitCriteria.filter((ec) =>
              funnelExitCriteriaIds.includes(ec.exitCriteriaId),
            ),
          };
        });

      funnel.plugins['dynamic-path-plugin'] = {
        ...currentDynamicPath,
        stages: filteredStages,
      };
    }
  };

  const updateExitCriteria = (stageId: string, updatedExitCriteria: SweepExitCriteria) => {
    funnel.stages = funnel.stages.map((stage) => {
      if (stage._stageId === stageId) {
        return {
          ...stage,
          exitCriteria: stage.exitCriteria.map((ec) =>
            ec._exitCriteriaId === updatedExitCriteria._exitCriteriaId ? updatedExitCriteria : ec,
          ),
        };
      }

      return stage;
    });
  };

  const isFirstStage = (branchIndex: number, columnIndex?: number) => {
    return branchIndex === 0 && (!columnIndex || (columnIndex && columnIndex === 0));
  };

  const updateStagesConnections = (oldStage: SweepStage) => {
    const { exitCriteria, _stageId } = oldStage;

    if (!exitCriteria.length) {
      return;
    }

    //get only ids
    const oldStageExitCriteriaNextStageIds = exitCriteria.map((ec) => ec._nextStageId);

    //get full old stage exit criterias next stages data
    const oldStageExitCriteriaNextStages = funnel.stages.filter((stage) =>
      oldStageExitCriteriaNextStageIds.includes(stage._stageId),
    );

    funnel.stages = funnel.stages.map((givenStage) => {
      if (oldStageExitCriteriaNextStageIds.includes(givenStage._stageId)) {
        // not the previous stage, so no update necessary for now
        return givenStage;
      }

      const isGivenStageConnectedWithOld = isOldStageInGivenStageExitCriterias(
        givenStage,
        _stageId,
      );

      if (!isGivenStageConnectedWithOld) {
        return givenStage;
      }

      const currentStageExitCriteriaNextStageIds = givenStage.exitCriteria.map(
        (ec) => ec._nextStageId,
      );

      const areStagesSharingExitCriterias = _intersectionWith(
        currentStageExitCriteriaNextStageIds,
        oldStageExitCriteriaNextStageIds,
      );

      if (areStagesSharingExitCriterias.length) {
        // if _nextStageId already exists in given stage just removing the old one
        return {
          ...givenStage,
          exitCriteria: givenStage.exitCriteria.filter((ec) => ec._nextStageId !== _stageId),
        };
      }

      const newNextStageData = connectToClosestStage(givenStage, oldStageExitCriteriaNextStages);

      const givenExitCriteria = givenStage.exitCriteria.map((ec) => {
        if (ec._nextStageId === _stageId && newNextStageData) {
          // overwritting old _nextStageId with new connection
          return {
            ...ec,
            _nextStageId: newNextStageData?._stageId,
          };
        }

        return ec;
      });

      return { ...givenStage, exitCriteria: givenExitCriteria };
    });
  };

  const isOnHappyPath = (branchIndex: number) => {
    return branchIndex === 0;
  };

  const getOnlyHappyPathItems = (stages: SweepStage[]) => {
    return stages.filter((stage) => isOnHappyPath(stage._branchIndex));
  };

  const connectToClosestStage = (
    currentStage: SweepStage,
    oldStageExitCriteriaNextStages: SweepStage[],
  ) => {
    const { _branchIndex } = currentStage;
    if (oldStageExitCriteriaNextStages.length === 1) {
      return oldStageExitCriteriaNextStages[0];
    }

    if (isOnHappyPath(_branchIndex)) {
      const happyPathItems = getOnlyHappyPathItems(oldStageExitCriteriaNextStages);
      return _orderBy(happyPathItems, '_branchIndex', 'asc')[0];
    }
  };

  const isOldStageInGivenStageExitCriterias = (stage: SweepStage, _oldStageId: string) => {
    return !!stage.exitCriteria.find((ec) => ec._nextStageId === _oldStageId);
  };

  const toJSON = (): FunnelDetails => funnel;

  const replaceStage = (stage: SweepStage) => {
    const idx = funnel.stages.findIndex((_stage) => _stage._stageId === stage._stageId);
    if (idx !== -1) {
      funnel.stages[idx] = stage;
    }
  };

  const stageById = (_stageId: string) => {
    const stage = funnel.stages.find((stage) => stage._stageId === _stageId);
    if (!stage) {
      throw new Error(`Stage "${_stageId}" not found`);
    }
    return stageModel(stage);
  };

  const stageByIdOrUndefined = (_stageId: string) => {
    const stage = funnel.stages.find((stage) => stage._stageId === _stageId);
    return stage ? stageModel(stage) : undefined;
  };

  const createStageBetween = (stageName: string, stageIdA: string, stageIdB?: string) => {
    const stageAModel = stageById(stageIdA);

    const newStage = createStage(stageName);
    const newStageModel = stageModel(newStage);

    // Copies leading field and stage team from origin
    const { _team } = stageAModel.getStage();
    newStageModel.setTeam(_team);

    const stageAExitCriteriaIfConnectedToStageB = stageIdB
      ? stageAModel.getExitCriteriaByNextStatusId(stageIdB)
      : undefined;

    const isConnectedToStageB = !!stageAExitCriteriaIfConnectedToStageB;

    if (isConnectedToStageB) {
      newStageModel.createExitCriteria({
        _nextStageId: stageAExitCriteriaIfConnectedToStageB.toExitCriteria()._nextStageId,
      });
      stageAModel.deleteExitCriteriaById(
        stageAExitCriteriaIfConnectedToStageB.toExitCriteria()._exitCriteriaId,
      );
      stageAModel.createExitCriteria({
        ...stageAExitCriteriaIfConnectedToStageB.toExitCriteria(),
        _nextStageId: newStage._stageId,
      });
      if (stageIdB) {
        const stageBModel = stageById(stageIdB);
        const branchIndexA = stageAModel.getBranchIndex();
        const branchIndexB = stageBModel.getBranchIndex();
        if (branchIndexA === branchIndexB) {
          newStageModel.setBranchIndex(branchIndexA);
        } else {
          newStageModel.setBranchIndex(1);
        }
      }
    } else {
      if (stageIdB) {
        // No Next Step
        newStageModel.createExitCriteria({ _nextStageId: stageIdB });
        newStageModel.setBranchIndex(1);
      } else {
        if (stageAModel.getBranchIndex() === 0) {
          newStageModel.setBranchIndex(-1);
        } else {
          newStageModel.setBranchIndex(stageAModel.getBranchIndex());
        }
      }
      stageAModel.createExitCriteria({
        _nextStageId: newStage._stageId,
      });
    }
  };

  const findNextStageName = (_nextStageId: string) => {
    const nextStage = funnel.stages?.find((stage: SweepStage) => stage._stageId === _nextStageId);
    return nextStage?.stageName ?? '';
  };

  const getAllStageIdsWithNoLostSteps = () =>
    funnel.stages
      .filter((stage) => stage.stageType !== StageType.LOST)
      .map(({ _stageId }) => _stageId);

  const hasDirectedPathBetween = (sA: string, sB: string): boolean => {
    const alreadyVisitedStages = new Set<string>();
    alreadyVisitedStages.add(sA);

    const _recursiveHasDirectedPathBetwen = (_sA: string, _sB: string): boolean => {
      alreadyVisitedStages.add(_sA);
      const allConnectedStageIds = stageByIdOrUndefined(_sA)?.getAllConnectedStageIds();

      if (!allConnectedStageIds) {
        return false;
      }
      for (const connectedStageId of allConnectedStageIds) {
        if (!alreadyVisitedStages.has(connectedStageId)) {
          if (connectedStageId === _sB) {
            return true;
          } else {
            if (_recursiveHasDirectedPathBetwen(connectedStageId, _sB)) {
              return true;
            }
          }
        }
      }
      return false;
    };

    return _recursiveHasDirectedPathBetwen(sA, sB);
  };

  const getPossibleConnectionsWithNoLoops = (stageId: string) => {
    const allStagesExceptOwn = getAllStageIdsWithNoLostSteps().filter(
      (_stageId) => _stageId !== stageId,
    );
    const allValid = allStagesExceptOwn.reduce((prev, stageIdB) => {
      if (!hasDirectedPathBetween(stageIdB, stageId)) {
        prev.push(stageIdB);
      }
      return prev;
    }, [] as string[]);

    return allValid;
  };

  const getAllStageValidConnections = (stageIdA: string) => {
    const possibleConnectionsWithNoLoops = getPossibleConnectionsWithNoLoops(stageIdA);
    const objectName = funnel.leadingObject.objectName;

    return funnel.stages.filter(({ _stageId: stageIdB }) => {
      const stageAModel = stageByIdOrUndefined(stageIdA);
      const stageBModel = stageByIdOrUndefined(stageIdB);
      if (!stageAModel || !stageBModel) {
        return false;
      }

      const alreadyHasConnection = () => stageAModel.getAllConnectedStageIds().includes(stageIdB);
      const hasOppositeConnection = () => stageBModel.getAllConnectedStageIds().includes(stageIdA);

      const isSameStep = stageIdA === stageIdB;
      const lead = 'Lead' as ObjectTypeValues;
      const isRevertToLead = objectName !== lead && objectName === lead;

      if (isSameStep || isRevertToLead) {
        return false;
      }

      if (stageAModel.isNurturingBucket()) {
        if (stageBModel.isNurturingBucket()) {
          return !alreadyHasConnection() && !hasOppositeConnection();
        } else {
          return !alreadyHasConnection();
        }
      } else {
        if (stageBModel.isNurturingBucket()) {
          return !alreadyHasConnection() && !hasOppositeConnection();
        } else {
          return (
            !alreadyHasConnection() &&
            !hasOppositeConnection() &&
            possibleConnectionsWithNoLoops.includes(stageIdB)
          );
        }
      }
    });
  };

  const getLostSteps = () => {
    return funnel.stages.filter((stage) => stage.stageType === StageType.LOST);
  };

  const getStagesUsedObjectTypes = () => {
    return [
      { objectName: funnel.leadingObject.objectName, id: funnel.leadingObject._leadingFieldId },
    ];
  };

  const isLeadingFieldOpportunityStage = () =>
    funnel.leadingObject.objectName === SFDCObjectType.Opportunity &&
    funnel.leadingObject.fieldName === 'StageName';

  const isLeadingFieldCaseStatus = () =>
    funnel.leadingObject.objectName === SFDCObjectType.Case &&
    funnel.leadingObject.fieldName === 'Status';

  const isLeadingFieldLeadStatus = () =>
    funnel.leadingObject.objectName === SFDCObjectType.Lead &&
    funnel.leadingObject.fieldName === 'Status';

  const isMetadataRelatedStage = () =>
    isLeadingFieldOpportunityStage() || isLeadingFieldCaseStatus() || isLeadingFieldLeadStatus();

  const criteriaPluginsForStage = (stageId: string) => {
    const plugins = new FunnelPluginsModel(funnel.plugins || []);
    return plugins.criteriaPluginsForStage(stageId);
  };

  return {
    addStage,
    updateStage,
    createStage,
    createStageBetween,
    removeStage,
    removeExitCriteriaById,
    updateExitCriteria,
    replaceStage,
    updateStageAndRemoveEmptyCriteria,
    criteriaPluginsForStage,
    stageById,
    stageByIdOrUndefined,
    stageByStageName,
    toJSON,
    findNextStageName,
    getAllStageIdsWithNoLostSteps,
    getAllStageValidConnections,
    getStagesUsedObjectTypes,
    getLostSteps,
    isLeadingFieldLeadStatus,
    isLeadingFieldOpportunityStage,
    isLeadingFieldCaseStatus,
    isMetadataRelatedStage,
  };
};

export { funnelDetailModel };
