import { Dialog, DialogProps } from '@mui/material';

export interface BaseDialogProps extends DialogProps {
  /**
   * Disables triggering onClose on backdrop click
   */
  disableBackdropClickClose?: boolean;
}

// Extends MuiDialog
export const BaseDialog = ({
  disableBackdropClickClose,
  onClose,
  ...dialogProps
}: BaseDialogProps) => {
  const _onClose = (event: any, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (disableBackdropClickClose && reason && reason === 'backdropClick') {
      return;
    }

    onClose && onClose(event, reason);
  };

  return <Dialog {...dialogProps} onClose={_onClose} />;
};
