import { Box, FormControlLabel, Stack } from '@mui/material';
import { Button, Checkbox, Typography } from '@sweep-io/sweep-design';
import { openIntercom } from '../../../dashboard/customerSupportHelper';
import WithConnectOrgPermission from '../../../common/WithConnectOrgPermission';

const ConnectStartInDialog = ({
  onConnectStart,
  forceProduction,
  isSandbox,
  setIsSandbox,
  title,
  description,
}: {
  onConnectStart: () => void;
  forceProduction: boolean;
  isSandbox: boolean;
  setIsSandbox: (isSandbox: boolean) => void;
  title: string;
  description: React.ReactNode;
}) => {
  return (
    <Stack padding={3}>
      <Stack flex={1} mt="108px">
        <Box display="flex" gap={0.5} alignItems="center" mb={1}>
          <Typography variant="h1">{title}</Typography>
        </Box>
        <Box mb={4}>
          <Typography variant="body">{description}</Typography>
        </Box>
        {!forceProduction && (
          <Box display="flex" gap={1.5} alignItems="center">
            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': { lineHeight: 2 },
              }}
              label={<Typography variant="body">Connect to a Salesforce Sandbox</Typography>}
              control={
                <Checkbox
                  checked={isSandbox ?? false}
                  onChange={(val) => {
                    setIsSandbox(val.target.checked);
                  }}
                />
              }
            />
          </Box>
        )}
      </Stack>
      <Box display="flex" gap={2} justifyContent="flex-end" alignItems="center">
        <Button startIconName="Chats" onClick={() => openIntercom()} variant="outlined">
          Talk to us
        </Button>
        <WithConnectOrgPermission>
          <Button onClick={onConnectStart}>Continue</Button>
        </WithConnectOrgPermission>
      </Box>
    </Stack>
  );
};

export default ConnectStartInDialog;
