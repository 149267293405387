import { useState } from 'react';
import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';
import { Note } from '../deploy/Note';
import { Box, Typography } from '@mui/material';

export const DeploymentChangesActionDialog = ({
  onBack,
  onConfirm,
  onCancel,
  onClose,
  title,
  confirmText,
  cancelText,
  placeholder,
  footNote,
}: {
  onBack: () => void;
  onConfirm: (note?: string) => void;
  onClose: () => void;
  onCancel?: (note?: string) => void;
  title: string;
  confirmText: string;
  cancelText?: string;
  placeholder?: string;
  footNote?: string;
}) => {
  const [note, setNote] = useState('');
  return (
    <ConfirmDialog
      hideBackdrop={true}
      isOpen={true}
      disableHandleCloseOnConfirm
      PaperProps={{
        sx: { '.MuiDialogContent-root': { minWidth: '600px' } },
      }}
      title={title}
      dialogActionsSx={{
        mt: 4,
        p: 4,
      }}
      showCloseButton
      handleClose={onClose}
      showOnlyConfirm={!cancelText}
      showBackButton={!!onBack}
      handleBack={onBack}
      onConfirm={() => onConfirm(note)}
      onCancel={() => onCancel && onCancel(note)}
      cancelText={cancelText}
      confirmText={confirmText}
    >
      <Note label="Feedback message (optional)" placeholder={placeholder} onNoteChange={setNote} />
      {footNote && (
        <Box mt={1}>
          <Typography variant="body">Note: {footNote}</Typography>
        </Box>
      )}
    </ConfirmDialog>
  );
};
