//same as BE
export enum ReportingStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  INACTIVE = 'inactive',
  ERROR = 'error',
  INVALID_CREDENTIALS = 'invalid_credentials',
}

export const isReportActive = (reportStatus: ReportingStatus) =>
  reportStatus === ReportingStatus.ACTIVE;

export const isReportPending = (reportStatus: ReportingStatus) =>
  reportStatus === ReportingStatus.PENDING;
