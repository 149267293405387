import { ConfigurationType } from '@server/parser';
import { FunnelFieldLabels } from '../../../../constants/fieldsManagementConsts';
import {
  ConfigurationItem,
  ConfigurationItemWithStatus,
} from '../../dependencies/DependenciesTypes';
import { ConfigurationToName } from '../../dependencies/types';
import { formatCamelCase } from '../../universal-search/utils';

export const getCsvHeaders = (
  columns: { field: string; headerName: string; showSortIcon?: boolean }[],
) => {
  return columns.map((column) => ({ label: column.headerName, value: column.field }));
};

export const addStatusAndUpdateType = (items: ConfigurationItem[]): ConfigurationItemWithStatus[] =>
  items.map((item) => ({
    ...item,
    status: item.isActive ? 'Active' : 'Inactive',
    type:
      FunnelFieldLabels[item.type as SweepFieldTypes] ??
      ConfigurationToName[item.type as ConfigurationType] ??
      formatCamelCase(item.type),
  }));
