import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { funnelDetailModel } from '../../../../../models/funnelDetailModel';
import SweepStagesModel from '../../../../../models/stagesModel';
import {
  connectSfStages,
  connectThirdPartyFunnelSteps,
} from '../../../../../reducers/united-canvas/unitedCanvasReducer';
import { ConnectStepProps } from './ConnectSteps';
import { FunnelType } from '../../../../../types/enums/FunnelType';
import { ListGroup, ListItem } from '../../../../common/CollapseList';
import { getFunnelTypeFromNodeId } from '../../../../funnel-map-canvas/helper';
import { colors } from '@sweep-io/sweep-design';

const FUNNEL_LINK_PREFIX = 'funnel_link';

type AbstractCurrentStep = {
  funnelType: FunnelType;
  stageId: string;
  funnelId: string;
};

interface SfCurrentStep extends AbstractCurrentStep {
  funnelType: FunnelType.SALESFORCE;
}

interface ThirdPartyCurrentStep extends AbstractCurrentStep {
  funnelType: FunnelType.THIRD_PARTY;
}

type CurrentStep = SfCurrentStep | ThirdPartyCurrentStep;

const stageToConnectStepItem = (stage: SweepStage): ListItem => ({
  value: stage._stageId,
  label: stage.stageName,
});

const isSalesforceLink = (link: FunnelLink) =>
  link._type === undefined || link._type === FunnelType.SALESFORCE;

const isThirdPartyLink = (link: FunnelLink) => link._type === FunnelType.THIRD_PARTY;

const withFunnelEntry = (
  funnelId: string,
  items: {
    value: string;
    label: string;
  }[],
  insertFunnelLink: boolean,
) => {
  if (insertFunnelLink) {
    items.unshift({ value: `${FUNNEL_LINK_PREFIX}:${funnelId}`, label: '(connect to funnel)' });
  }
  return [...items];
};

export const getGroupsForSalesforceStep = ({
  step,
  funnelsData,
  thirdPartyFunnelsData,
  withFunnelLink,
}: {
  step: SfCurrentStep;
  funnelsData: FunnelMap['funnelsData'];
  thirdPartyFunnelsData: FunnelMap['thirdPartyFunnelsData'];
  withFunnelLink: boolean;
}) => {
  const sfFunnel = funnelsData[step.funnelId];
  const sfStep = sfFunnel.funnelDetails.stages.find((s) => s._stageId === step.stageId);
  if (!sfFunnel || !sfStep) {
    return [];
  }
  const currentFunnelId = step.funnelId;
  const currentFunnelGroup = {
    value: funnelsData[currentFunnelId].id,
    parentName: funnelsData[currentFunnelId].name,
    label: funnelsData[currentFunnelId].funnelDetails.leadingObject.objectName,
    objectName: funnelsData[currentFunnelId].funnelDetails.leadingObject.objectName,
    items: funnelDetailModel(funnelsData[currentFunnelId].funnelDetails)
      .getAllStageValidConnections(step.stageId)
      .map(stageToConnectStepItem),
  };

  const stageLinks: FunnelLink[] | undefined = sfStep.funnelLinks;

  const doesNotHaveSfStageFunnelLink = (funnelId: string) => (stage: SweepStage) => {
    return !Boolean(
      stageLinks
        ?.filter(isSalesforceLink)
        .find((link) => link.funnelId === funnelId && stage._stageId === link.stageId),
    );
  };

  const doesNotHaveThirdPartyStageFunnelLink = (funnelId: string) => (step: ThirdPartyStep) => {
    return !Boolean(
      stageLinks
        ?.filter(isThirdPartyLink)
        .find((link) => link.funnelId === funnelId && step.id === link.stageId),
    );
  };
  const hasStepToFunnelLink = (funnelId: string) => {
    return Boolean(sfStep.funnelLinks?.some((link) => link.funnelId === funnelId));
  };

  const allOtherSfFunnelGroups: ListGroup[] = Object.keys(funnelsData)
    .filter((funnelId) => funnelId !== currentFunnelId)
    .map((funnelId) => ({
      value: funnelsData[funnelId].id,
      parentName: funnelsData[funnelId].name,
      label: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      objectName: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      items: withFunnelEntry(
        funnelId,
        new SweepStagesModel(funnelsData[funnelId].funnelDetails.stages)
          .nonLostSteps()
          .filter(doesNotHaveSfStageFunnelLink(funnelId))
          .map(stageToConnectStepItem),
        withFunnelLink && !hasStepToFunnelLink(funnelId),
      ),
    }));

  const allOtherThirdPartyFunnelGroups: ListGroup[] = Object.keys(thirdPartyFunnelsData)
    .filter((funnelId) => funnelId !== currentFunnelId)
    .map((funnelId) => ({
      value: thirdPartyFunnelsData[funnelId].id,
      parentName: thirdPartyFunnelsData[funnelId].name,
      label: '3rd party integration',
      objectName: '',
      labelColor: colors.storm[200],
      items: withFunnelEntry(
        funnelId,
        thirdPartyFunnelsData[funnelId].steps
          .filter(doesNotHaveThirdPartyStageFunnelLink(funnelId))
          .map((step) => ({
            value: step.id,
            label: step.name,
          })),
        withFunnelLink && !hasStepToFunnelLink(funnelId),
      ),
    }));

  return [currentFunnelGroup, ...allOtherSfFunnelGroups, ...allOtherThirdPartyFunnelGroups];
};

const getGroupsForThirdPartyStep = ({
  step,
  funnelsData,
  thirdPartyFunnelsData,
  withFunnelLink,
}: {
  step: ThirdPartyCurrentStep;
  funnelsData: FunnelMap['funnelsData'];
  thirdPartyFunnelsData: FunnelMap['thirdPartyFunnelsData'];
  withFunnelLink: boolean;
}) => {
  const tpFunnel = thirdPartyFunnelsData[step.funnelId];
  const tpStep = tpFunnel.steps.find((s) => s.id === step.stageId);
  if (!tpFunnel || !tpStep) {
    return [];
  }
  const tpStepConnectionsToSteps = tpStep.stepConnections.map(({ targetId }) => targetId);

  const currentFunnelGroup = {
    value: tpFunnel.id,
    parentName: tpFunnel.name,
    label: 'Third party integration',
    labelColor: colors.storm[200],
    objectName: tpFunnel.name,
    items: tpFunnel.steps
      .filter((s) => s.id !== tpStep.id && !tpStepConnectionsToSteps.includes(s.id))
      .map((s) => ({
        value: s.id,
        label: s.name,
      })),
  };

  const doesNotHaveSfStageFunnelLink = (funnelId: string) => (stage: SweepStage) => {
    return !Boolean(
      tpStep.funnelLinks
        ?.filter(isSalesforceLink)
        .find((link) => link.funnelId === funnelId && stage._stageId === link.stageId),
    );
  };

  const doesNotHaveThirdPartyStageFunnelLink = (funnelId: string) => (step: ThirdPartyStep) => {
    return !Boolean(
      tpStep.funnelLinks
        ?.filter(isThirdPartyLink)
        .find((link) => link.funnelId === funnelId && step.id === link.stageId),
    );
  };

  const hasStepToFunnelLink = (funnelId: string) => {
    return tpStep.funnelLinks?.some((link) => link.funnelId === funnelId);
  };

  const allOtherSfFunnelGroups: ListGroup[] = Object.keys(funnelsData)
    .filter((funnelId) => funnelId !== tpFunnel.id)
    .map((funnelId) => ({
      value: funnelsData[funnelId].id,
      parentName: funnelsData[funnelId].name,
      objectName: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      label: funnelsData[funnelId].funnelDetails.leadingObject.objectName,
      items: withFunnelEntry(
        funnelId,
        new SweepStagesModel(funnelsData[funnelId].funnelDetails.stages)
          .nonLostSteps()
          .filter(doesNotHaveSfStageFunnelLink(funnelId))
          .map(stageToConnectStepItem),
        withFunnelLink && !hasStepToFunnelLink(funnelId),
      ),
    }));

  const allOtherThirdPartyFunnelGroups: ListGroup[] = Object.keys(thirdPartyFunnelsData)
    .filter((funnelId) => funnelId !== tpFunnel.id)
    .map((funnelId) => ({
      value: thirdPartyFunnelsData[funnelId].id,
      parentName: thirdPartyFunnelsData[funnelId].name,
      objectName: '',
      label: '3rd party integration',
      labelColor: '#D9DBE7',
      items: withFunnelEntry(
        funnelId,
        thirdPartyFunnelsData[funnelId].steps
          .filter(doesNotHaveThirdPartyStageFunnelLink(funnelId))
          .map((step) => ({
            value: step.id,
            label: step.name,
          })),
        withFunnelLink && !hasStepToFunnelLink(funnelId),
      ),
    }));

  return [currentFunnelGroup, ...allOtherSfFunnelGroups, ...allOtherThirdPartyFunnelGroups];
};

export const useConnectSteps = (funnelMap: FunnelMap) => {
  const { funnelsData, thirdPartyFunnelsData } = funnelMap;
  // const { thirdPartyFunnels: thirdPartyFunnelsFt } = useFeatureToggle();
  const thirdPartyFunnelsFt = false; // Always false for now
  const dispatch = useDispatch();
  const [connectStepsButtonAnchorEl, setConnectStepsButtonAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [holdNodeHighlighted, setHoldNodeHighlighted] = useState(false);

  const [currentStageAndFunnel, setCurrentStageAndFunnel] = useState<CurrentStep>();

  const handleConnectStepsButtonClick = useCallback(
    (
      stageId: string,
      funnelId: string,
      funnelType: FunnelType,
      event: React.MouseEvent<HTMLButtonElement>,
    ) => {
      if (funnelType === FunnelType.SALESFORCE || funnelType === FunnelType.THIRD_PARTY) {
        setCurrentStageAndFunnel({ funnelId, stageId, funnelType });
        setConnectStepsButtonAnchorEl(event.currentTarget);
        setHoldNodeHighlighted(true);
      }
    },
    [],
  );

  const handleConnectStepsButtonClose = () => {
    setConnectStepsButtonAnchorEl(null);
    setHoldNodeHighlighted(false);
  };

  const stepGroups: ListGroup[] = useMemo(() => {
    if (!currentStageAndFunnel) {
      return [];
    }
    if (currentStageAndFunnel.funnelType === FunnelType.SALESFORCE) {
      return getGroupsForSalesforceStep({
        step: currentStageAndFunnel,
        funnelsData,
        thirdPartyFunnelsData,
        withFunnelLink: thirdPartyFunnelsFt,
      });
    }
    if (currentStageAndFunnel.funnelType === FunnelType.THIRD_PARTY) {
      return getGroupsForThirdPartyStep({
        step: currentStageAndFunnel,
        funnelsData,
        thirdPartyFunnelsData,
        withFunnelLink: thirdPartyFunnelsFt,
      });
    }
    return [];
  }, [currentStageAndFunnel, funnelsData, thirdPartyFunnelsFt, thirdPartyFunnelsData]);

  const onItemClick = useCallback(
    (item: ListItem, parentGroup: ListGroup) => {
      const funnelType =
        getFunnelTypeFromNodeId(funnelMap, parentGroup.value) ?? FunnelType.SALESFORCE;

      const destinationStageId = item.value.startsWith(FUNNEL_LINK_PREFIX) ? undefined : item.value;
      if (currentStageAndFunnel?.funnelType === FunnelType.SALESFORCE) {
        dispatch(
          connectSfStages({
            stageA: {
              funnelId: currentStageAndFunnel.funnelId,
              stageId: currentStageAndFunnel.stageId,
            },
            stageB: {
              funnelId: parentGroup.value,
              stageId: destinationStageId,
              funnelType,
            },
          }),
        );
      }

      if (currentStageAndFunnel?.funnelType === FunnelType.THIRD_PARTY) {
        dispatch(
          connectThirdPartyFunnelSteps({
            stageA: {
              funnelId: currentStageAndFunnel.funnelId,
              stageId: currentStageAndFunnel.stageId,
            },
            stageB: {
              funnelId: parentGroup.value,
              stageId: destinationStageId,
              funnelType,
            },
          }),
        );
      }

      handleConnectStepsButtonClose();
    },
    [currentStageAndFunnel, dispatch, funnelMap],
  );

  const connectStepsProps: ConnectStepProps = {
    anchorEl: connectStepsButtonAnchorEl || null,
    handleClose: handleConnectStepsButtonClose,
    stepGroups,
    onItemClick,
    startAllExpanded: false,
  };

  return {
    handleConnectStepsButtonClick,
    connectStepsProps,
    holdNodeHighlighted,
  };
};
