import { useConfigurationCanvasContext } from '../../../configuration-canvas/ConfigurationCanvasContext';

export const useTurnOffCommentsModeFirst = () => {
  const { setCommentsOn } = useConfigurationCanvasContext();

  const turnOffCommentsModeFirst = (cb: any) => {
    setCommentsOn(false);
    cb && cb();
  };

  return turnOffCommentsModeFirst;
};
