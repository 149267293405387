import { DEFAULT_VISIBILITY_MAP } from '../../../../constants/visibility';
import { FunnelMapCanvas } from '../../../funnel-map-canvas/FunnelMapCanvas';
import { useConfigurationCanvasContext } from '../ConfigurationCanvasContext';
import { useCallback, useContext, useEffect } from 'react';
import { useSweepCanvasFitView } from '../../../multi-canvas/useSweepCanvasFitView';
import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';
import { partialVisibilityMapToVisibilityMap } from '../utils/partialVisibilityMapToVisibilityMap';
import {
  CanvasPillTypes,
  GroupLabelEntityType,
  GroupPillEntityType,
  NodeEntityTypes,
} from '../../../multi-canvas/canvasTypes';
import { useFitAroundElements } from '../../configuration-canvas-filters/useFitAroundElements';
import { selectAllVisibilityMaps } from '../../../../reducers/canvasLayersReducer';
import { useSelector } from 'react-redux';
import { visibilityMapToLayersSelectorVisibilityMap } from './layers-selector/utils';
import { PillsMap } from '../../../canvas-pills/canvasPillsReducer';
import { RecordTypeLeadingObjectCtx } from './record-type-leading-object-panel/RecordTypeLeadingObjectCtx';
import useObjectTypesWithFetch from '../../../../hooks/useObjectTypesWithFetch';

export interface AppFunnelMapCanvasProps {
  crmOrgId: string;
  funnelMap: FunnelMap;
  setFunnelMap: (funnelMap: FunnelMap) => void;
  pills: PillsMap;
}
export const AppFunnelMapCanvas = ({
  crmOrgId,
  funnelMap,
  pills,
  setFunnelMap,
}: AppFunnelMapCanvasProps) => {
  const { configurationCanvasProps } = useConfigurationCanvasContext();
  const { setCurrentRecord } = useContext(RecordTypeLeadingObjectCtx);

  const { fitView } = useSweepCanvasFitView();
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId });

  const {
    onStageGateClick,
    onFunnelStepClick,
    onRecordTypeLabelClick,
    onRecordTypeStepClick,
    onFunnelLabelClick,
    onFunnelLabelPillClick,
    onRecordTypeLabelPillClick,
    // readOnly,
    highlightEntities,
    visibilityElements,
  } = configurationCanvasProps;

  useEffect(() => {
    setTimeout(() => {
      fitView({ duration: 300, maxZoom: 1 });
    }, 200);
  }, [crmOrgId, fitView]);

  const allVisibilityMaps = useSelector(selectAllVisibilityMaps);
  const _visibilityMap =
    visibilityElements.type === 'map'
      ? {
          // Remove old layers from the canvas if present
          [VisibilityLayers.NURTURING_STEPS]: true,
          [VisibilityLayers.GATES]: false,
          ...visibilityElements.visibilityMap,
        }
      : visibilityMapToLayersSelectorVisibilityMap(
          allVisibilityMaps?.[visibilityElements.layersId] || {},
        );

  const { funnelsData, recordTypesData } = funnelMap;

  const { fitOnElements } = useFitAroundElements({ funnelsData, recordTypesData });

  const _onFunnelLabelClick = useCallback(
    (props: {
      funnelId: string;
      entity: GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      if (
        props.entity.type === NodeEntityTypes.GroupPill &&
        props.entity.canvasPillType === CanvasPillTypes.groupAggregated
      ) {
        fitOnElements([props.funnelId], true);
        return;
      }

      if (onFunnelLabelClick) {
        onFunnelLabelClick(props);
      }
    },
    [fitOnElements, onFunnelLabelClick],
  );

  const _onFunnelLabelPillClick = useCallback(
    (props: {
      funnelId: string;
      entity: GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      if (
        props.entity.type === NodeEntityTypes.GroupPill &&
        props.entity.canvasPillType === CanvasPillTypes.groupAggregated
      ) {
        fitOnElements([props.funnelId], true);
        return;
      }

      if (onFunnelLabelPillClick) {
        onFunnelLabelPillClick(props);
      }
    },
    [fitOnElements, onFunnelLabelPillClick],
  );

  const _onRecordTypeLabelClick = useCallback(
    (props: {
      recordTypeApiName: string;
      entity: GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      if (
        props.entity.type === NodeEntityTypes.GroupPill &&
        props.entity.canvasPillType === CanvasPillTypes.groupAggregated
      ) {
        fitOnElements([props.recordTypeApiName], true);
        return;
      }

      if (onRecordTypeLabelClick) {
        onRecordTypeLabelClick(props);
      }
    },
    [fitOnElements, onRecordTypeLabelClick],
  );

  const _onRecordTypeLabelPillClick = useCallback(
    (props: {
      recordTypeApiName: string;
      entity: GroupPillEntityType | GroupLabelEntityType;
      event: React.MouseEvent;
    }) => {
      if (
        props.entity.type === NodeEntityTypes.GroupPill &&
        props.entity.canvasPillType === CanvasPillTypes.groupAggregated
      ) {
        fitOnElements([props.recordTypeApiName], true);
        return;
      }

      if (onRecordTypeLabelPillClick) {
        onRecordTypeLabelPillClick(props);
      }
    },
    [fitOnElements, onRecordTypeLabelPillClick],
  );

  const onGhostRecordTypeStepButtonClick = useCallback(
    ({ event, recordTypeId }: { event: React.MouseEvent; recordTypeId: string }) => {
      setCurrentRecord({ recordTypeId, anchorEl: event.currentTarget, origin: 'setRecord' });
    },
    [setCurrentRecord],
  );

  const onRecordTypeEditButtonClick = useCallback(
    ({ event, recordTypeId }: { event: React.MouseEvent; recordTypeId: string }) => {
      const leadingField = funnelMap.recordTypesData[recordTypeId].leadingField;
      setCurrentRecord({
        recordTypeId,
        anchorEl: event.currentTarget,
        leadingField,
        origin: 'editRecord',
      });
    },
    [funnelMap.recordTypesData, setCurrentRecord],
  );

  const onFunnelMapPositionsChange = useCallback(
    (funnelMap: FunnelMap) => {
      setFunnelMap(funnelMap);
    },
    [setFunnelMap],
  );

  return (
    <FunnelMapCanvas
      funnelMap={funnelMap}
      visibilityMap={
        _visibilityMap
          ? partialVisibilityMapToVisibilityMap(_visibilityMap)
          : DEFAULT_VISIBILITY_MAP
      }
      onStageGateClick={onStageGateClick}
      onFunnelStepClick={onFunnelStepClick}
      onRecordTypeLabelClick={onRecordTypeLabelClick ? _onRecordTypeLabelClick : undefined}
      onRecordTypeLabelPillClick={
        onRecordTypeLabelPillClick ? _onRecordTypeLabelPillClick : undefined
      }
      onRecordTypeStepClick={onRecordTypeStepClick}
      onFunnelLabelClick={onFunnelLabelClick ? _onFunnelLabelClick : undefined}
      onFunnelLabelPillClick={onFunnelLabelPillClick ? _onFunnelLabelPillClick : undefined}
      readonly
      onFunnelMapPositionsChange={onFunnelMapPositionsChange}
      onGhostRecordTypeStepButtonClick={onGhostRecordTypeStepButtonClick}
      highlightEntities={highlightEntities}
      pills={pills}
      disableNodeHighlight
      objectTypesByName={objectTypesByName}
      onRecordTypeEditButtonClick={onRecordTypeEditButtonClick}
    />
  );
};
