import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@sweep-io/sweep-design';

//TODO replace with Tag from sweep/design
export const StyledChip = styled(Chip)({
  gap: 6,
  borderRadius: 4,
  marginRight: 8,
  background: colors.storm[50],
  color: colors.grey[800],
  fontSize: 12,
  paddingRight: 4,
  '& .MuiChip-deleteIcon': {
    color: '#000',
    fontSize: 12,
  },
});
