import { useCallback } from 'react';
import { ChevronButton } from './shared/ChevronButton';
import { TableWithHeader, TableWithHeaderProps } from './shared/TableWithHeader';
import { TableTypography } from './shared/helpers';
import { DataTableRow } from '../../common/table/TableTypes';
import { humanizeDate } from '../../helpers/humanizeDate';
import {
  ConfigurationType,
  DependenciesContentViewType,
  NewDependencyProps,
} from '../dependencies/types';
import { InactiveTag } from '../activity-state/InactiveTag';

interface FlowsTableProps extends Pick<TableWithHeaderProps, 'headerVariant'> {
  items: FlowTableItem[];
  onFlowClick: (props: NewDependencyProps) => void;
  shortListViewCounter?: number;
  activeItemId?: string;
  clearHistory?: boolean;
}

interface FlowTableItem {
  id: string;
  name: string;
  label: string;
  type: string;
  lastModified: string;
  isActive: boolean;
}

const flowsColumns = [
  { field: 'label', headerName: 'Name', width: '40%' },
  { field: 'type', headerName: 'Type', width: '22%' },
  { field: 'lastModified', headerName: 'Last Modified', width: '23%' },
  { field: '__actions', headerName: '', width: '10%' },
];

const renderRows = ({ items, activeItemId }: Omit<FlowsTableProps, 'onFlowClick'>) => {
  return items.map((row) => ({
    id: row.id,
    isRowActive: activeItemId === row.id,
    label: (
      <>
        <TableTypography>{row.label}</TableTypography>
        <InactiveTag isActive={row.isActive} />
      </>
    ),
    type: <TableTypography>{row.type}</TableTypography>,
    lastModified: (
      <TableTypography>{humanizeDate({ dateOrTimestamp: row.lastModified })}</TableTypography>
    ),
    __actions: <ChevronButton />,
    isClickable: true,
  }));
};

export const FlowsTable = ({
  items,
  onFlowClick,
  activeItemId,
  clearHistory,
  ...props
}: FlowsTableProps) => {
  const onRowClick = useCallback(
    (e: React.MouseEvent<HTMLTableCellElement, MouseEvent>, row: DataTableRow) => {
      const item = items.find((_item) => _item.id === row.id);
      if (item) {
        onFlowClick({
          id: item.id,
          name: item.name,
          parentType: ConfigurationType.flows,
          clearHistory,
          contentType: DependenciesContentViewType.default,
        });
      }
    },
    [onFlowClick, items, clearHistory],
  );

  return (
    <TableWithHeader
      tableHeader="Flows"
      key="tableHeader_flows"
      columns={flowsColumns}
      rows={renderRows({ items, activeItemId })}
      onRowClick={onRowClick}
      {...props}
    />
  );
};
