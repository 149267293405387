import useSendBiEvent from '../../hooks/useSendBiEvent';
import { useEffect } from 'react';
import { useAutomationsContext } from './AutomationsContext';

const useSendOpenAutomationEvent = () => {
  const sendBiEvent = useSendBiEvent();
  const dataByVariant = useAutomationsContext();
  const { automationJson } = dataByVariant;
  useEffect(() => {
    if (automationJson) {
      const isEdit = !!automationJson.automationId;
      const isTemplate = !isEdit && !!automationJson.automationDetails;

      const eventName = dataByVariant.openEvent;
      const props = {
        type: isEdit ? 'edit' : isTemplate ? 'new_template' : 'new_blank',
      };
      sendBiEvent({ name: eventName, props });
    }
  }, [automationJson, dataByVariant.openEvent, sendBiEvent]);
};

export default useSendOpenAutomationEvent;
