import { useMemo } from 'react';
import {
  SweepCanvasGroup,
  SweepCanvasNode,
  SweepCanvasEdge,
  CanvasElementType,
} from '../../multi-canvas/canvasTypes';
import { TRANSIENT_GROUP_ID } from '../useFunnelMapElements';
import { TransientFunnel } from '../funnelMapCanvasTypes';
import { SweepCanvasReactFlowEdgeDataType } from '../../multi-canvas/edges/SweepCanvasReactFlowEdgeDataType';
import { FunnelType } from '../../../types/enums/FunnelType';

export const TPF_OBJECT_TYPE = '__third_party_funnel__';

export const useCalculateThirdPartyNodes = ({
  funnelMap,
  transientFunnel,
  editingThirdPartyStepId,
}: {
  funnelMap: FunnelMap;
  transientFunnel?: TransientFunnel;
  editingThirdPartyStepId?: string;
}) => {
  return useMemo(() => {
    const groups: SweepCanvasGroup[] = [];
    const nodes: SweepCanvasNode[] = [];
    const edges: SweepCanvasEdge[] = [];

    const sanitizedThirdPartyFunnelsEntries = Object.entries(
      funnelMap.thirdPartyFunnels || {},
    ).filter(([id]) => funnelMap.thirdPartyFunnelsData[id]);

    sanitizedThirdPartyFunnelsEntries.forEach(([id, { position }]) => {
      const { funnelLinks, name, steps, description, system } = funnelMap.thirdPartyFunnelsData[id];

      /// calculates groups
      groups.push({
        id,
        name,
        objectLabel: system?.name || '3rd party integration',
        objectLabelLogoDevBrand: system,
        objectType: TPF_OBJECT_TYPE,
        position,
        metadata: {
          funnelLinks,
          steps,
          description,
          system,
        },
        plugins: [],
        description,
      });

      // calculates nodes
      if (steps.length === 0) {
        const ghostNodeId = `${id}-ghost-node0`;

        if (editingThirdPartyStepId !== ghostNodeId) {
          nodes.push({
            id: `${id}-ghost-node0`,
            name: 'Add integration steps',
            type: CanvasElementType.GHOST_NODE,
            objectType: TPF_OBJECT_TYPE,
            position: { column: 0, row: 0 },
            parentId: id,
          });
        }
      } else {
        steps.forEach((step) => {
          nodes.push({
            id: step.id,
            name: step.name,
            type: CanvasElementType.REGULAR,
            objectType: TPF_OBJECT_TYPE,
            position: step.position,
            parentId: id,
            hideNbButton: true,
          });
          step.stepConnections.forEach((connection) => {
            edges.push({
              id: connection._id,
              source: step.id,
              target: connection.targetId,
              data: {
                type: SweepCanvasReactFlowEdgeDataType.REMOVABLE,
              },
            });
          });
          step.funnelLinks?.forEach((link) => {
            if (link._id) {
              edges.push({
                id: link._id,
                source: step.id,
                target: link.stageId || `label-${link.funnelId}`,
                data: {
                  type: SweepCanvasReactFlowEdgeDataType.REMOVABLE,
                  groupConnection: true,
                },
              });
            }
          });
        });
      }
    });

    if (
      transientFunnel?.type === FunnelType.THIRD_PARTY &&
      transientFunnel?.importType === 'create'
    ) {
      const {
        name,
        position,
        data: { system },
      } = transientFunnel;
      groups.push({
        id: TRANSIENT_GROUP_ID,
        name,
        position,
        objectType: TPF_OBJECT_TYPE,
        objectLabel: system?.name || '',
        objectLabelLogoDevBrand: system,
        plugins: [],
      });
      nodes.push({
        id: `${TRANSIENT_GROUP_ID}-ghost-node0`,
        name: 'Add integration steps',
        type: CanvasElementType.GHOST_NODE,
        objectType: TPF_OBJECT_TYPE,
        position: { column: 0, row: 0 },
        parentId: TRANSIENT_GROUP_ID,
      });
    }

    return { groups, nodes, edges };
  }, [
    funnelMap.thirdPartyFunnels,
    funnelMap.thirdPartyFunnelsData,
    editingThirdPartyStepId,
    transientFunnel,
  ]);
};
