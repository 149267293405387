import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';

const LabelChip = ({
  bgColor,
  color,
  startIcon,
  children,
}: {
  bgColor: string;
  color?: string;
  startIcon?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        justifyContent: 'space-between',
        gap: '4px',
        alignItems: 'center',
        color: color || 'inherit',
        background: bgColor,
        borderRadius: '2px',
        padding: '2px 4px',
        maxWidth: '100%',
      }}
    >
      {startIcon}
      <TruncatedTextTooltip color={color || 'inherit'} variant="caption">
        {children}
      </TruncatedTextTooltip>
    </Box>
  );
};

export default LabelChip;
