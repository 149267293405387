import { useLocation } from 'react-router';
import PaywallHorizontalLayout from './PaywallHorizontalLayout';
import usePaywall from '../usePaywall';
import { useEffect } from 'react';
import { getAppRouteByPath } from '../../../../constants/appRoutes';

const PaywallPageBlock = () => {
  const { pathname } = useLocation();
  const path = pathname.split('/')[1];
  const paywallSource = getAppRouteByPath('/' + path)?.paywallBlockSource;
  const { sendEncounterPaywallEvent } = usePaywall();

  useEffect(() => {
    if (paywallSource) {
      sendEncounterPaywallEvent({
        source: paywallSource,
      });
    }
  }, [paywallSource, sendEncounterPaywallEvent]);

  return <PaywallHorizontalLayout paywallSource={paywallSource} />;
};

export default PaywallPageBlock;
