// This file contains the reducer of the mapping between the template fields and sweep fields.
// We need to persist this mapping in the local storage, so that the user won't need to map the fields again and again.
// This is the reason why we need this reducer to be separated from the templatesDialogReducer.

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../reducers';
import { SweepFieldMetadataByApiName } from '../templatesTypes';

export interface TemplateFieldsMappingState {
  fieldPerTemplateMapping: {
    [crmOrgId: string]: {
      [templateId: string]: {
        enabled: boolean;
        sweepFieldsByApiName: SweepFieldMetadataByApiName;
      };
    };
  };
}

const initialState: TemplateFieldsMappingState = {
  fieldPerTemplateMapping: {},
};

export const templateFieldsMappingSlice = createSlice({
  name: 'templateFieldsMapping',
  initialState,
  reducers: {
    setFieldMappingEnabled: (
      state,
      action: PayloadAction<{ crmOrgId: string; templateId: string; enabled: boolean }>,
    ) => {
      const { crmOrgId, enabled, templateId } = action.payload;
      if (!state.fieldPerTemplateMapping[crmOrgId]) {
        state.fieldPerTemplateMapping[crmOrgId] = {};
      }
      if (!state.fieldPerTemplateMapping[crmOrgId][templateId] || !enabled) {
        state.fieldPerTemplateMapping[crmOrgId][templateId] = {
          enabled,
          sweepFieldsByApiName: {},
        };
      }
      state.fieldPerTemplateMapping[crmOrgId][templateId].enabled = enabled;
    },
    setFieldMappingForTemplateField: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        templateId: string;
        apiName: string;
        fieldIds: string[];
        fieldLabels: string[];
      }>,
    ) => {
      const { crmOrgId, templateId, apiName, fieldIds, fieldLabels } = action.payload;
      if (!state.fieldPerTemplateMapping[crmOrgId]) {
        state.fieldPerTemplateMapping[crmOrgId] = {};
      }
      if (!state.fieldPerTemplateMapping[crmOrgId][templateId]) {
        state.fieldPerTemplateMapping[crmOrgId][templateId] = {
          enabled: true,
          sweepFieldsByApiName: {},
        };
      }
      state.fieldPerTemplateMapping[crmOrgId][templateId].sweepFieldsByApiName[apiName] = {
        fieldIds,
        fieldLabels,
      };
    },
  },
});

export const selectFieldsPerTemplateMapping =
  (crmOrgId?: string, templateId?: string) => (state: RootState) => {
    if (!crmOrgId || !templateId) return undefined;

    return state[templateFieldsMappingSlice.name].fieldPerTemplateMapping[crmOrgId]?.[templateId];
  };

export const selectFieldMappingEnabled =
  (crmOrgId: string, templateId: string) => (state: RootState) =>
    state[templateFieldsMappingSlice.name].fieldPerTemplateMapping[crmOrgId]?.[templateId]?.enabled;

export const selectTemplateApiNameMapping =
  (crmOrgId: string, templateId: string, apiName: string) => (state: RootState) =>
    state[templateFieldsMappingSlice.name]?.fieldPerTemplateMapping[crmOrgId]?.[templateId]
      ?.sweepFieldsByApiName[apiName];

export const selectTemplatesSweepFieldsByApiName =
  (crmOrgId: string, templateId: string) => (state: RootState) =>
    state[templateFieldsMappingSlice.name].fieldPerTemplateMapping[crmOrgId]?.[templateId]
      .sweepFieldsByApiName;

export const { setFieldMappingEnabled, setFieldMappingForTemplateField } =
  templateFieldsMappingSlice.actions;

export default templateFieldsMappingSlice.reducer;
