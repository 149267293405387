export enum SharingMode {
  UserMode = 'WITH_SHARING',
  SystemMode = 'WITHOUT_SHARING',
}

export const ROLLUP_MODES_TRANSLATION = {
  [SharingMode.UserMode]: 'User Mode',
  [SharingMode.SystemMode]: 'System mode',
};

export enum RollupOperator {
  SUM = 'sum',
  AVG = 'avg',
  CNT = 'count',
  MIN = 'min',
  MAX = 'max',
  CONCATENATE = 'concatenate',
  CONCATENATE_DISTINCT = 'concatenate_distinct',
}

export enum TextAggregationDelimiter {
  COMMA = 'COMMA',
  SEMICOLON = 'SEMICOLON',
  PIPE = 'PIPE',
  SLASH = 'SLASH',
}
