import uniq from 'lodash/uniq';

export const objectsInFunnelMapScope = (funnelsData: FunnelsData) => {
  const allObjectsUsedInFunnels = uniq(
    Object.values(funnelsData).map((funnel) => {
      return funnel.funnelDetails.leadingObject.objectName;
    }),
  );
  return allObjectsUsedInFunnels;
};

export const automationsInFunnelMapScope = ({
  automations,
  funnelsData,
}: {
  automations?: AutomationStructureNew[];
  funnelsData: FunnelsData;
}) => {
  const allObjectsUsedInFunnels = objectsInFunnelMapScope(funnelsData);
  const allFunnelIds = Object.keys(funnelsData);
  return automations?.filter((automation) => {
    const isSameObject = allObjectsUsedInFunnels.includes(automation.objectName);
    const isNotFromOtherFunnel =
      !automation.automationDetails.funnelId ||
      allFunnelIds.includes(automation.automationDetails.funnelId);
    return isSameObject && isNotFromOtherFunnel;
  });
};
