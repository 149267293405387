import { useDispatch, useSelector } from 'react-redux';
import { selectCrmOrg } from '../../environments/environmentsReducer';
import {
  selectIsFreshlyCreatedFunnelMap,
  setFunnelMapIsFreshlyCreated,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { useFunnelMapPage } from '../useFunnelMapPage';
import { openFunnelSettingsDialog } from '../dialogs/funnel-settings-dialog/funnelSettingsReducer';

export const CrmOrgUsageNotification = ({ crmOrgId }: { crmOrgId: string }) => {
  const dispatch = useDispatch();

  const { getOnSettingsClickPayload } = useFunnelMapPage();
  const crmOrg = useSelector(selectCrmOrg(crmOrgId));

  const isFreshlyCreatedFunnelMap = useSelector(selectIsFreshlyCreatedFunnelMap);
  const { addNotification } = useSweepNotifications();

  useRunOnceWhenTruthy(
    () => {
      if (isFreshlyCreatedFunnelMap) {
        dispatch(setFunnelMapIsFreshlyCreated(false));
        const actionPayload = getOnSettingsClickPayload();

        actionPayload &&
          addNotification({
            message: `Using fields from ‘${crmOrg?.name}'s ${
              crmOrg?.isSandbox ? 'sandbox' : 'environment'
            }’`,
            action: {
              reduxAction: {
                type: openFunnelSettingsDialog.type,
                payload: actionPayload,
              },
              actionText: 'Change',
            },
            variant: SweepNotificationVariant.Default,
          });
      }
    },
    isFreshlyCreatedFunnelMap && Boolean(crmOrg?.name),
  );

  return null;
};
