import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface CrmOrgUsersState {
  crmOrgUsers: { [crmOrgId: string]: CrmOrgUser[] };
  crmOrgUsersBase: { [crmOrgId: string]: CrmOrgUserBase[] };
}

const initialState: CrmOrgUsersState = {
  crmOrgUsers: {},
  crmOrgUsersBase: {},
};

export const crmOrgUsersSlice = createSlice({
  name: 'crmOrgUsers',
  initialState,
  reducers: {
    setCrmOrgUsers: (
      state,
      action: PayloadAction<{ crmOrgId: string; crmOrgUsers: CrmOrgUser[] }>,
    ) => {
      const { crmOrgId, crmOrgUsers } = action.payload;
      state.crmOrgUsers[crmOrgId] = crmOrgUsers;
    },
    setCrmOrgUsersBase: (
      state,
      action: PayloadAction<{ crmOrgId: string; crmOrgUsersBase: CrmOrgUserBase[] }>,
    ) => {
      const { crmOrgId, crmOrgUsersBase } = action.payload;
      state.crmOrgUsersBase[crmOrgId] = crmOrgUsersBase;
    },
    updateCrmOrgUser: (
      state,
      action: PayloadAction<{ crmOrgId: string; crmOrgUser: CrmOrgUser }>,
    ) => {
      const { crmOrgId, crmOrgUser } = action.payload;
      state.crmOrgUsers[crmOrgId] = state.crmOrgUsers[crmOrgId] || [];
      const crmOrgUserIndex = state.crmOrgUsers[crmOrgId].findIndex(
        (user) => user.id === crmOrgUser.id,
      );
      if (crmOrgUserIndex !== -1) {
        state.crmOrgUsers[crmOrgId][crmOrgUserIndex] = crmOrgUser;
      }
    },
  },
});

export const { setCrmOrgUsers, updateCrmOrgUser, setCrmOrgUsersBase } = crmOrgUsersSlice.actions;

export const selectCrmOrgUsers = (crmOrgId?: string) => (state: RootState) =>
  crmOrgId ? state.crmOrgUsers.crmOrgUsers[crmOrgId] : undefined;

export const selectCrmOrgUsersBase = (crmOrgId?: string) => (state: RootState) =>
  crmOrgId ? state.crmOrgUsers.crmOrgUsersBase[crmOrgId] : undefined;
