export const enum SweepFieldTypes {
  Picklist = 'Picklist',
  Text = 'Text',
  TextArea = 'TextArea',
  LongTextArea = 'LongTextArea',
  Number = 'Number',
  Phone = 'Phone',
  Date = 'Date',
  DateTime = 'DateTime',
  Time = 'Time',
  Checkbox = 'Checkbox',
  Email = 'Email',
  Percent = 'Percent',
  Url = 'Url',
  Currency = 'Currency',
  MultiselectPicklist = 'MultiselectPicklist',
  EncryptedText = 'EncryptedText',
  Html = 'Html', // RichText
  Lookup = 'Lookup',
  Id = 'Id',
  FormulaCheckbox = 'FormulaCheckbox',
  FormulaCurrency = 'FormulaCurrency',
  FormulaDate = 'FormulaDate',
  FormulaDateTime = 'FormulaDateTime',
  FormulaNumber = 'FormulaNumber',
  FormulaPercent = 'FormulaPercent',
  FormulaText = 'FormulaText',
  FormulaTime = 'FormulaTime',
  Summary = 'Summary',
  Hierarchy = 'Hierarchy',
  MasterDetail = 'MasterDetail',
  Address = 'Address',
  Territory = 'Territory',
  Geolocation = 'Geolocation',
  AutoNumber = 'AutoNumber',
  Name = 'Name',
  Unknown = 'Unknown',
  Combobox = 'Combobox',
}
