import axios from 'axios';
import { useEffect, useState } from 'react';

export const useGetMpVersion = () => {
  const [mpVersion, setMpVersion] = useState<string | null>(null);

  useEffect(() => {
    axios
      .get('https://sweep-mp-version.s3.amazonaws.com/mp-version.txt')
      .then((response: any) => setMpVersion(response.data));
  }, []);

  return mpVersion;
};
