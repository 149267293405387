import React, { useMemo } from 'react';
import { Dialog, DialogContent, Grid } from '@mui/material';
import { Typography, Tag } from '@sweep-io/sweep-design';
import { DeployErrorIcon, DeploySuccessIcon } from '../../../../../icons/generalIcons';
import { DEPLOY_STATUS } from '../../../../../types/enums/DeployStatus';
import { WandLoader } from '../../../../animations/lottie/lottieAnimations';

const DeployProgress = ({
  selectedOrgId,
  setIsOpen,
  deployStatus,
  deployMessages,
  orgs,
}: {
  selectedOrgId?: string;
  setIsOpen: () => void;
  deployStatus: string | null;
  deployMessages: string[];
  orgs: CrmOrg[];
}) => {
  const selectedOrg = useMemo(
    () => orgs.find((org) => org.id === selectedOrgId),
    [orgs, selectedOrgId],
  );

  const disableClose = deployStatus === DEPLOY_STATUS.IN_PROGRESS;

  const defaultErrorText = 'Please try again later.';
  const buildDeploymentErrorMessage = () => {
    if (deployMessages.length > 0) {
      return `The following errors occurred:
      ${deployMessages.join('\n')}.`;
    }
    return defaultErrorText;
  };

  return (
    <Dialog
      open={true}
      onClose={() => {
        if (!disableClose) {
          setIsOpen();
        }
      }}
    >
      <DialogContent sx={{ width: '423px', padding: '68px 20px' }}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          gap="30px"
        >
          {deployStatus === DEPLOY_STATUS.IN_PROGRESS && (
            <>
              <WandLoader size={100} />
              {selectedOrg?.name && (
                <Grid container justifyContent="center" alignItems="center" gap="8px">
                  <Typography variant="body">Deploying to</Typography>
                  <Tag label={selectedOrg.name} />
                </Grid>
              )}
            </>
          )}
          {deployStatus === DEPLOY_STATUS.SUCCESS && (
            <>
              <DeploySuccessIcon />
              <Typography variant="body">Your funnel was successfully deployed!</Typography>
            </>
          )}
          {deployStatus === DEPLOY_STATUS.ERROR && (
            <>
              <DeployErrorIcon />
              <Typography variant="h4">Deployment Failed</Typography>
              <Typography variant="body">{buildDeploymentErrorMessage()}</Typography>
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeployProgress;
