import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { FUNNEL_NAME, UNTITLED_FUNNEL_MAP_NAME } from '../../../../../constants';
import { useFunnelSettingsDialog } from './useFunnelSettingsDialog';
import { selectFunnelSettingsName } from './funnelSettingsReducer';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../../common/permissions/usePermission';
import SweepTextField from '../../../../common/SweepTextField';
import { FullWidthBox } from './FullWidthBox';

export const MainFunnelSettings = ({
  handleChange,
  errors,
}: {
  handleChange: any;
  errors: any;
}) => {
  const currentFunnelName = useSelector(selectFunnelSettingsName);
  const { updateFunnelMapName } = useFunnelSettingsDialog();
  const [hasEditFunnelMapPermissions] = usePermission(['edit:funnel-maps']);
  const canEditName = hasEditFunnelMapPermissions;

  return (
    <Box
      sx={{
        display: 'flex',
        paddingBottom: '22px',
        width: '100%',
        gap: '24px',
      }}
    >
      <FullWidthBox>
        <RestrictedTooltip
          to={['edit:funnel-maps']}
          notAllowedTitle={'To modify the name of the funnel, please contact your admin.'}
        >
          <SweepTextField
            FormControlProps={{ fullWidth: true }}
            label={'Name'}
            id={FUNNEL_NAME}
            value={currentFunnelName}
            onChange={(e, value) => {
              handleChange(e);
              updateFunnelMapName(value || '');
            }}
            TextFieldProps={{
              placeholder: UNTITLED_FUNNEL_MAP_NAME,
              disabled: !canEditName,
              helperText: (errors.name as string) || '',
              error: Boolean(errors.name),
              inputProps: {
                maxLength: 40,
              },
              sx: { marginBottom: '24px' },
            }}
          />
        </RestrictedTooltip>
      </FullWidthBox>
    </Box>
  );
};
