import { useSelector } from 'react-redux';
import { selectConnectedCrmOrgs, selectCrmOrgsWasLoaded } from './environmentsReducer';

export const useNoConnectedOrgs = () => {
  const crmOrgs = useSelector(selectConnectedCrmOrgs);
  const crmOrgsWasLoaded = useSelector(selectCrmOrgsWasLoaded);

  const noConnectedOrgs = crmOrgsWasLoaded && crmOrgs.length === 0;

  return noConnectedOrgs;
};
