import DialogOnCanvas from '../pages/canvas-pages/DialogOnCanvas';
import Members from './Members';

const MembersDialog = ({
  closeDialog,
  transitionDuration,
}: {
  closeDialog: () => void;
  transitionDuration?: number;
}) => {
  return (
    <DialogOnCanvas
      onClose={closeDialog}
      open={true}
      transitionDuration={transitionDuration}
      sx={{
        '.MuiPaper-root': {
          padding: '24px',
        },
      }}
    >
      <Members onClose={closeDialog} isDialogLayout={true} />
    </DialogOnCanvas>
  );
};

export default MembersDialog;
