import { Box } from '@mui/material';
import { Plus } from '@sweep-io/sweep-design/dist/icons/Plus';
import { ActionsMenu } from '../common/actions-menu/ActionsMenu';
import { AutomationTypes } from '../../types/enums/AutomationTypes';

interface FormAddConditionsProps {
  readonly?: boolean;
  automationJson: DeepPartial<AutomationStructureNew>;
  addConditionsToTrigger: () => void;
  addDelayToAutomation: () => void;
}

export const FormAddConditions = ({
  readonly,
  automationJson,
  addConditionsToTrigger,
  addDelayToAutomation,
}: FormAddConditionsProps) => {
  return (
    <>
      {!readonly &&
        (!automationJson.automationDetails?.when?.isCriteria ||
          !automationJson.automationDetails?.delay) && (
          <>
            <Box>
              <ActionsMenu
                MenuButtonIcon={<Plus />}
                iconTooltip="Add Conditions"
                actions={[
                  {
                    label: 'Add Conditions',
                    value: 'Condition',
                    disabled:
                      automationJson.automationDetails?.triggerType === undefined ||
                      automationJson.objectName === undefined ||
                      automationJson.automationDetails?.when?.isCriteria,
                  },
                  {
                    label: 'Delay Action',
                    value: 'Delay',
                    disabled:
                      automationJson.automationDetails?.triggerType === undefined ||
                      automationJson.objectName === undefined ||
                      automationJson?.automationDetails?.delay !== undefined ||
                      automationJson.automationDetails?.triggerType ===
                        AutomationTypes.DateArrive ||
                      automationJson.automationDetails?.triggerType === AutomationTypes.TimeInStep,
                  },
                ]}
                onClick={(action) => {
                  switch (action.value) {
                    case 'Condition':
                      addConditionsToTrigger();
                      break;
                    case 'Delay':
                      addDelayToAutomation();
                      break;
                  }
                }}
              />
            </Box>
          </>
        )}
    </>
  );
};
