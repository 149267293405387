import { Box, Stack } from '@mui/material';
import { Typography } from '@sweep-io/sweep-design';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import PlayStorylaneModal from '../../pages/get-started/modals/PlayStorylaneModal';
import { useLocation } from 'react-router';
import { getAppRouteByPath } from '../../../constants/appRoutes';

const imgWidth = '668px';
const imgStyle = { maxWidth: imgWidth, width: '100%' };
const altCommon = 'Check out our product tour';

const FetchingPageWithTour = () => {
  const { pathname } = useLocation();
  const { openConfirm, onCancel } = useConfirm();

  const currentMainPath = '/' + pathname.split('/')[1];
  const appRoute = getAppRouteByPath(currentMainPath);
  const { initialFetchBlock } = appRoute ?? {};

  if (!initialFetchBlock) {
    return null;
  }

  const { url, title, imageSrc } = initialFetchBlock;

  const onClick = () => {
    openConfirm(<PlayStorylaneModal closeDialog={onCancel} url={url} />);
  };
  return (
    <Stack height="100%" justifyContent="center" alignItems="center" p={4}>
      <Box mb={6} textAlign="center">
        <Typography variant="body">
          Scanning your Salesforce environment for the first time!
          <br />
          Check out our product tour and see how {title} works.
        </Typography>
      </Box>
      <Box
        onClick={onClick}
        sx={{ cursor: 'pointer', position: 'relative' }}
        maxWidth={imgWidth}
        width="100%"
      >
        <img src={imageSrc} alt={altCommon} style={imgStyle} />,
      </Box>
    </Stack>
  );
};

export default FetchingPageWithTour;
