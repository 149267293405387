import { useCallback } from 'react';
import { sendBiEvent } from '../services/bi';
import { BiEvent } from '../services/events';
import useIsManagedPackageInstalled from '../components/common/install-managed-package/useIsManagedPackageInstalled';

const useSendBiEvent = () => {
  const isManagedPackagedInstalled = useIsManagedPackageInstalled();

  const sendEvent = useCallback(
    (event: BiEvent) => {
      const extendedProps = {
        ...event.props,
        managedPackagedInstalled: isManagedPackagedInstalled,
      };
      const updateEvent: BiEvent = {
        ...event,
        props: extendedProps,
      };
      sendBiEvent(updateEvent);
    },

    [isManagedPackagedInstalled],
  );

  return sendEvent;
};

export default useSendBiEvent;
