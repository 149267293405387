import { useRef, useEffect, useState } from 'react';
import LoadingSweep from '../../common/LoadingSweep';
import { CanvasBoard, CanvasBoardProps } from './canvas-container/CanvasBoard';
import { ConfigurationPages, configurationPages } from './configurationPages';
import { useUpdateEnvironment } from './useUpdateEnvironment';
import { useAutoSave } from './useAutoSave';
import { useCalculatePills } from '../../canvas-pills/useCalculatePills';
import { useSelectConfigurationCanvasEnvFunnelMap } from './useSelectConfigurationCanvasEnvFunnelMap';
import { useConfigurationCanvasState } from './useConfigurationCanvasState';
import { useConfigurationCanvasContext } from './ConfigurationCanvasContext';
import { AppFunnelMapCanvasProps, AppFunnelMapCanvas } from './canvas-container/AppFunnelMapCanvas';
import { CONFIGURATION_CANVAS_LEFT_PANEL_WIDTH } from './consts';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { selectProductionCrmOrg } from '../environments/environmentsReducer';

interface EnvironmentPageLoaderProps {
  configurationPage: ConfigurationPages;
  crmOrgId: string;
  showOnlyDeployedElementsInPills: boolean;
}

const AutoSaveCanvas = ({
  crmOrgId,
  funnelMap,
  configurationPage,
  pills,
  setFunnelMap,
  showOnlyDeployedElementsInPills,
}: Omit<CanvasBoardProps & AppFunnelMapCanvasProps, 'children' | 'page'> & {
  configurationPage: ConfigurationPages;
  showOnlyDeployedElementsInPills: boolean;
}) => {
  useAutoSave();
  useCalculatePills({ crmOrgId, funnelMap, onlyDeployed: showOnlyDeployedElementsInPills });

  const Page = configurationPages[configurationPage];

  return (
    <CanvasBoard
      crmOrgId={crmOrgId}
      funnelMap={funnelMap}
      page={<Page />}
      canvasLeftReservedSpace={CONFIGURATION_CANVAS_LEFT_PANEL_WIDTH}
    >
      <AppFunnelMapCanvas
        crmOrgId={crmOrgId}
        funnelMap={funnelMap}
        pills={pills}
        setFunnelMap={setFunnelMap}
      />
    </CanvasBoard>
  );
};

interface AbstractUseLoadEnvCanvasResponse {
  envLoadedAndUpdated: boolean;
}

interface UseLoadEnvCanvasUnloadedResponse extends AbstractUseLoadEnvCanvasResponse {
  envLoadedAndUpdated: false;
}

interface UseLoadEnvCanvasLoadedResponse extends AbstractUseLoadEnvCanvasResponse {
  envLoadedAndUpdated: true;
  envFunnelMap: FunnelMap;
  crmOrgId: string;
}

type UseLoadEnvCanvasResponse = UseLoadEnvCanvasUnloadedResponse | UseLoadEnvCanvasLoadedResponse;

export const useLoadEnvironmentCanvas = ({
  forceProduction,
}: {
  forceProduction?: boolean;
}): UseLoadEnvCanvasResponse => {
  const params = useParams<{ crmOrgId: string }>();
  const productionOrgId = useSelector(selectProductionCrmOrg)?.id;

  let crmOrgId = params.crmOrgId ?? '';
  if (forceProduction && productionOrgId) {
    crmOrgId = productionOrgId;
  }

  const updatingEnvMapIdRef = useRef<string>();
  const envFunnelMap = useSelectConfigurationCanvasEnvFunnelMap(crmOrgId);
  const [loadedEnvMapId, setLoadedEnvMapId] = useState<string>();

  const { updateEnvironment } = useUpdateEnvironment();

  useEffect(() => {
    const shouldUpdateEnv = envFunnelMap && updatingEnvMapIdRef.current !== crmOrgId;

    if (shouldUpdateEnv) {
      updatingEnvMapIdRef.current = crmOrgId;
      updateEnvironment(envFunnelMap).then(() => {
        setLoadedEnvMapId(crmOrgId);
      });
    }
  }, [crmOrgId, envFunnelMap, updateEnvironment, loadedEnvMapId]);

  const envLoadedAndUpdated = crmOrgId && envFunnelMap && loadedEnvMapId === crmOrgId;

  if (!envLoadedAndUpdated) {
    return {
      envLoadedAndUpdated: false,
    };
  }
  return {
    envLoadedAndUpdated: true,
    envFunnelMap,
    crmOrgId,
  };
};

export const EnvironmentPageLoader = ({
  configurationPage,
  crmOrgId,
  showOnlyDeployedElementsInPills,
}: EnvironmentPageLoaderProps) => {
  const { pills } = useConfigurationCanvasState();
  const { setCanvasFunnelMap } = useConfigurationCanvasContext();
  const { canvasFunnelMap } = useConfigurationCanvasState();

  if (!canvasFunnelMap) return <LoadingSweep />;
  return (
    <AutoSaveCanvas
      configurationPage={configurationPage}
      funnelMap={canvasFunnelMap}
      crmOrgId={crmOrgId}
      setFunnelMap={setCanvasFunnelMap}
      pills={pills}
      showOnlyDeployedElementsInPills={showOnlyDeployedElementsInPills}
    />
  );
};
