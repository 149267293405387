import { useSelector } from 'react-redux';
import {
  selectSingleObjectFilters,
  selectSingleObjectActiveTab,
  selectSingleObject,
} from '../../../../reducers/documentationReducer';
import { useContext, useEffect, useState } from 'react';
import { OPTION_ALL } from '../../../common/single-select-filter/utils';
import { HighlightEntities } from '../../../funnel-map-canvas/funnelMapCanvasTypes';
import { CanvasPillTypes } from '../../../multi-canvas/canvasTypes';
import { createRecordTypeId, getParentFromStepId, getStepFullNameFromId } from './utils';
import { ParsedRecordType } from '../../../parser/ParserTypes';
import { DocumentationDialogContext } from '../../context/DocumentationContextProvider';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';

const activeTabToCanvasPillsMap: { [key: string]: CanvasPillTypes[] } = {
  [DocumentationTabTypes.SF_AUTOMATIONS]: [
    CanvasPillTypes.sfdcAutomation,
    CanvasPillTypes.groupSfdcAutomation,
  ],
  [DocumentationTabTypes.VALIDATION_RULES]: [
    CanvasPillTypes.validationRule,
    CanvasPillTypes.groupValidationRule,
  ],
  [DocumentationTabTypes.APEX]: [CanvasPillTypes.apexTrigger, CanvasPillTypes.groupApex],
};

export const useHighlightEntities = (
  funnelMap: FunnelMap,
  parsedRecordTypes?: ParsedRecordType[],
): HighlightEntities[] | undefined => {
  const filtersAndSort = useSelector(selectSingleObjectFilters);
  const { selectedRecordTypeValue, selectedStageValue } = filtersAndSort ?? {};

  const activeTab = useSelector(selectSingleObjectActiveTab);
  const objectTypeName = useSelector(selectSingleObject);

  //starting with undefined state to trigger first highlight
  const [selectedInfo, setSelectedInfo] = useState<{
    _activeTab?: string;
    recordTypeValue?: string;
    stageValue?: string;
  }>({});

  const { hoveredPin } = useContext(DocumentationDialogContext);
  const { hoveredObjectTypeName, hoveredTab } = hoveredPin ?? {};

  const [highlightEntities, setHighlightEntities] = useState<HighlightEntities[]>();

  useEffect(() => {
    //on hover
    if (selectedInfo._activeTab !== hoveredTab && hoveredTab) {
      const _newEntities = generateNewHighlightEntities({
        activeTab: hoveredTab,
        funnelMap,
        objectName: hoveredObjectTypeName,
        parsedRecordTypes,
      });
      setHighlightEntities(_newEntities);
      setSelectedInfo({
        _activeTab: hoveredTab,
      });
    }
  }, [hoveredObjectTypeName, hoveredTab, funnelMap, selectedInfo._activeTab, parsedRecordTypes]);

  useEffect(() => {
    //on click
    if (
      (selectedInfo.recordTypeValue !== selectedRecordTypeValue ||
        selectedInfo.stageValue !== selectedStageValue ||
        selectedInfo._activeTab !== activeTab) &&
      !hoveredTab
    ) {
      const _newEntities = generateNewHighlightEntities({
        activeTab,
        funnelMap,
        selectedRecordTypeValue,
        selectedStageValue,
        objectName: objectTypeName?.objectType,
        parsedRecordTypes,
      });
      setHighlightEntities(_newEntities);
      setSelectedInfo({
        recordTypeValue: selectedRecordTypeValue,
        stageValue: selectedStageValue,
        _activeTab: activeTab,
      });
    }
  }, [
    selectedInfo,
    selectedStageValue,
    selectedRecordTypeValue,
    activeTab,
    funnelMap,
    objectTypeName,
    hoveredTab,
    parsedRecordTypes,
  ]);

  return highlightEntities;
};

const getParentId = ({
  funnelMap,
  funnelsData,
  recordTypeName,
  objectName,
}: {
  funnelMap: FunnelMap;
  funnelsData: FunnelsData;
  recordTypeName: string;
  objectName: string;
}) => {
  const recordType = Object.values(funnelMap?.recordTypesData ?? {}).find(
    (rt) => rt.objectName === objectName && rt.name === recordTypeName,
  );

  const funnel = Object.values(funnelsData).find(
    ({ recordType }) => recordType.objectName === objectName && recordType.name === recordTypeName,
  );

  return recordType ? createRecordTypeId(recordType?.name, recordType?.objectName) : funnel?.id;
};

const generateNewHighlightEntities = ({
  activeTab,
  objectName,
  funnelMap,
  selectedRecordTypeValue = OPTION_ALL.value,
  selectedStageValue = OPTION_ALL.value,
  parsedRecordTypes,
}: {
  funnelMap: FunnelMap;
  selectedRecordTypeValue?: string;
  selectedStageValue?: string;
  activeTab?: string;
  objectName?: string;
  parsedRecordTypes?: ParsedRecordType[];
}): HighlightEntities[] | undefined => {
  const isRTValueAll = selectedRecordTypeValue === OPTION_ALL.value;
  const isStageValueAll = selectedStageValue === OPTION_ALL.value;

  if (!activeTab || !objectName) {
    return [];
  }

  const { funnelsData, recordTypesData } = funnelMap;

  const rtValues = selectedRecordTypeValue?.split('.') ?? [];
  const selectedRecordTypeName = rtValues[1];

  const _funnelsData = Object.values(funnelsData);
  const allObjectFunnels = _funnelsData.filter(
    (funnel) => funnel.recordType.objectName === objectName,
  );

  const allObjectRecordTypes = Object.values(recordTypesData).filter(
    (rt) => rt?.objectName === objectName,
  );

  const pillTypesToHighlight = activeTab
    ? [activeTabToCanvasPillsMap[activeTab]?.[isStageValueAll ? 1 : 0]]
    : [];

  if (activeTab && isRTValueAll && isStageValueAll) {
    //highlight all funnels/RTs pills of given pillType
    const highlightFunnels: HighlightEntities[] = allObjectFunnels
      ?.map((funnel) => {
        const pillTypesToHighlight = activeTabToCanvasPillsMap[activeTab];
        if (!pillTypesToHighlight) return [];

        return pillTypesToHighlight.map(
          (pillType) =>
            ({
              type: 'pill',
              funnelOrRecordTypeID: funnel.id,
              pillType,
            }) as HighlightEntities,
        );
      })
      .flat();

    const highlightRecordTypes: HighlightEntities[] = allObjectRecordTypes
      ?.map((rt) => {
        if (!pillTypesToHighlight || !rt) return [];

        return pillTypesToHighlight.map((pillType) => {
          return {
            type: 'pill',
            funnelOrRecordTypeID: createRecordTypeId(rt.name, rt.objectName),
            pillType,
          } as HighlightEntities;
        });
      })
      .flat();

    return [...highlightFunnels, ...highlightRecordTypes];
  }

  const stageParent = getParentFromStepId(selectedStageValue);
  const [_objectName, _recordTypeName] = stageParent.split('.');

  const parentId = getParentId({
    funnelMap,
    funnelsData,
    recordTypeName: selectedRecordTypeName ?? _recordTypeName,
    objectName: objectName ?? _objectName,
  });

  if (activeTab && !isRTValueAll && parentId && isStageValueAll) {
    //when RT/funnel chosen highlight RT funnel pills
    return pillTypesToHighlight.map((pillType) => ({
      type: 'pill',
      funnelOrRecordTypeID: parentId,
      pillType,
    }));
  }

  if (activeTab && parentId && !isStageValueAll) {
    // can be both funnel and RT with leading field
    const chosenFunnel = funnelMap.funnelsData[parentId];
    const chosenRT = funnelMap.recordTypesData[parentId];

    if (!chosenFunnel && !chosenRT) return [];

    const stepIds = getStageIdsByName({
      allObjectFunnels,
      allObjectRecordTypes,
      selectedStageValue,
      parsedRecordTypes,
      objectName,
    });

    return stepIds.map((id) => ({
      type: 'pill',
      funnelOrRecordTypeId: parentId,
      pillType: pillTypesToHighlight[0],
      stepId: id,
    }));
  }
};

const getStageIdsByName = ({
  objectName,
  allObjectFunnels,
  allObjectRecordTypes,
  selectedStageValue,
  parsedRecordTypes,
}: {
  objectName: string;
  allObjectFunnels: Funnel[];
  allObjectRecordTypes: SingleRecordType[];
  selectedStageValue: string;
  parsedRecordTypes?: ParsedRecordType[];
}) => {
  const ids: string[] = [];

  if (!allObjectFunnels && !allObjectRecordTypes) {
    return [selectedStageValue];
  }

  allObjectFunnels.forEach((funnel) => {
    const relevantRecordType = parsedRecordTypes?.find(
      (rt) => rt.name === funnel.recordType.name && rt.objectApiName === objectName,
    );
    const leadingField = funnel?.funnelDetails?.leadingObject?.fieldName;
    const relevantLeadingField = relevantRecordType?.leadingFields.find(
      (lf) => lf.name === leadingField,
    );

    const relevantStage = relevantLeadingField?.values.find(
      (value) => value.name === getStepFullNameFromId(selectedStageValue),
    );

    const stepId = findStageInFunnel(
      funnel,
      relevantStage?.label ?? getStepFullNameFromId(selectedStageValue),
    );
    if (stepId) {
      ids.push(stepId);
    }
  });

  allObjectRecordTypes.forEach((rt) => {
    const rtStepId = findStageInRecordType(getStepFullNameFromId(selectedStageValue), rt);
    if (rtStepId) {
      ids.push(selectedStageValue);
    }
  });

  return ids;
};

const findStageInFunnel = (funnel: Funnel, selectedStageValue: string) =>
  funnel?.funnelDetails?.stages.find((stage) => stage.stageName === selectedStageValue)?._stageId;

const findStageInRecordType = (selectedStageValue: string, rt?: SingleRecordType) =>
  rt?.leadingField?.values.find((stage) => stage.label === selectedStageValue)?.fullName;
