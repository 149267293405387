import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';

export const DialogLoader = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        background: 'white',
        opacity: 0.8,
      }}
    >
      <CenteredCircularProgress />
    </Box>
  );
};
