import { uniqueId } from '../../../../../lib/uniqueId';
import { AssignmentGroup } from '../../../../../reducers/assignmentGroupTypes';
import { EmojiType } from '../../../../avatar/avatarTypes';

export const generateNewAssignmentGroupData = (emoji?: EmojiType): AssignmentGroup => ({
  id: uniqueId(),

  description: '',
  members: [],
  name: '',
  avatar: {
    emoji,
  },
});
