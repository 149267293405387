import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { Warning } from '@sweep-io/sweep-design/dist/icons';

export const ErrorState = () => (
  <Box sx={{ width: '100%', height: '100%', background: colors.grey[100] }}>
    <Box sx={{ padding: '36px 33px', display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Warning />
      <Typography variant="body" color={colors.grey[800]}>
        No business processes found
      </Typography>
    </Box>
  </Box>
);
