import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { VisibilityLayers } from '../types/enums/VisibilityLayers';
import { VisibilityMap } from '../types/VisibilityTypes';
import { DEFAULT_VISIBILITY_MAP } from '../constants/visibility';
import { partialVisibilityMapToVisibilityMap } from '../components/pages/configuration-canvas/utils/partialVisibilityMapToVisibilityMap';

export const DEFAULT_LAYERS_ID = 'default';

export interface CanvasLayersState {
  layersFor: { [layersId: string]: VisibilityMap };
}

export const canvasLayersInitialState: CanvasLayersState = {
  layersFor: {},
};

export const canvasLayers = createSlice({
  name: 'canvasLayersV2',
  initialState: canvasLayersInitialState,
  reducers: {
    toggleLayerFor: (
      state,
      action: PayloadAction<{ layer: VisibilityLayers; layersId: string }>,
    ) => {
      const { layer, layersId } = action.payload;
      if (!state.layersFor[layersId]) {
        state.layersFor[layersId] = { ...DEFAULT_VISIBILITY_MAP };
      }
      const value = !state.layersFor[layersId][layer];
      state.layersFor[layersId][layer] = value;
    },
    enableLayerFor: (
      state,
      action: PayloadAction<{ layer: VisibilityLayers; layersId: string }>,
    ) => {
      const { layer, layersId } = action.payload;
      if (!state.layersFor[layersId]) {
        state.layersFor[layersId] = { ...DEFAULT_VISIBILITY_MAP };
      }
      state.layersFor[layersId][layer] = true;
    },
    setLayerFor: (
      state,
      action: PayloadAction<{ layer: VisibilityLayers; value: boolean; key: string }>,
    ) => {
      const { layer, value, key } = action.payload;
      if (!state.layersFor[key]) {
        state.layersFor[key] = { ...DEFAULT_VISIBILITY_MAP };
      }
      state.layersFor[key][layer] = value;
    },
    setDefaultVisibilityMapFor: (
      state,
      action: PayloadAction<{ layersId: string; visibilityMap: Partial<VisibilityMap> }>,
    ) => {
      if (!state.layersFor[action.payload.layersId]) {
        state.layersFor[action.payload.layersId] = partialVisibilityMapToVisibilityMap(
          action.payload.visibilityMap,
        );
      }
    },
  },
});

export const { toggleLayerFor, setLayerFor, enableLayerFor, setDefaultVisibilityMapFor } =
  canvasLayers.actions;

export default canvasLayers.reducer;

export const selectAllVisibilityMaps = (state: RootState) => state.canvasLayersV2.layersFor;

export const selectVisibilityMapFor = (layersId: string) => (state: RootState) => {
  if (state.canvasLayersV2.layersFor.hasOwnProperty(layersId)) {
    return state.canvasLayersV2.layersFor[layersId];
  }
  return DEFAULT_VISIBILITY_MAP;
};
