// Do not put any dispatcher here.
// Only pure API calls
// The Facades should mimic exactly the corresponding API.

import { useCallback } from 'react';
import { useSweepApi } from '../sweep/useSweepApi';
import { NotificationDto } from '../../reducers/notificationsCenterReducers';

interface GetNotificationsResponseDto {
  totalUnread: number;
  notifications: NotificationDto[];
}

interface PostNotificationsEventsReadPayload {
  notificationIds: string[];
}

export const useNotificationsFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_notifications: useCallback(async () => {
      const response = await sweepApi.get(`/notifications`);
      return response.data as GetNotificationsResponseDto;
    }, [sweepApi]),
    post_notifications_events_read: useCallback(
      async (payload: PostNotificationsEventsReadPayload) => {
        const response = await sweepApi.post(`/notifications/events/read`, payload);
        return response.data as { totalUnread: number };
      },
      [sweepApi],
    ),
    post_notifications_events_read_all: useCallback(async () => {
      await sweepApi.post(`/notifications/events/read-all`, {});
    }, [sweepApi]),
  };
};
