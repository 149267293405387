import { sendEventInMixpanel } from './mixpanel';
import { sendEventInIntercom } from './intercom';
import { BiEvent, PAGE_VISIT_EVENTS, PAYWALL_EVENTS } from './events';

const pageVisitsEventNames: string[] = Object.values(PAGE_VISIT_EVENTS);

//Product definition
const isRelevantForIntercom = (eventName: BiEvent) => {
  const isPageVisit = pageVisitsEventNames.includes(eventName.name);
  const isMainTrialClick = eventName.name === PAYWALL_EVENTS.clickTrialMain;
  return !isPageVisit && !isMainTrialClick;
};

export const sendBiEvent = (event: BiEvent) => {
  sendEventInMixpanel(event);
  if (isRelevantForIntercom(event)) {
    sendEventInIntercom(event);
  }
};
