import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface TerritoriesState {
  territories: Territory[] | undefined;
}

const initialState: TerritoriesState = {
  territories: undefined,
};

export const territoriesSlice = createSlice({
  name: 'territories',
  initialState,
  reducers: {
    setTerritoriesList: (state, action: PayloadAction<{ territories: Territory[] }>) => {
      state.territories = action.payload.territories;
    },
    createTerritoriesListItem: (state, action: PayloadAction<{ newItem: Territory }>) => {
      const { newItem } = action.payload;
      state.territories?.push(newItem);
    },
    updateTerritoriesListItem: (state, action: PayloadAction<{ newItem: Territory }>) => {
      const { newItem } = action.payload;
      state.territories = state.territories?.map((item) =>
        item.id === newItem.id ? newItem : item,
      );
    },
    deleteTerritoriesListItem: (state, action: PayloadAction<{ _id: string }>) => {
      const { _id } = action.payload;
      state.territories = state.territories?.filter((territory) => territory.id !== _id);
    },
  },
});

export const {
  setTerritoriesList,
  createTerritoriesListItem,
  updateTerritoriesListItem,
  deleteTerritoriesListItem,
} = territoriesSlice.actions;
export const selectTerritories = (state: RootState) => state.territories.territories;

export default territoriesSlice.reducer;
