// Consider moving this as an utility to sweep-design

import { colors } from '@sweep-io/sweep-design';

const colorKeys = Object.keys(colors);

const colorToFamilyMap = colorKeys.reduce<{ [hexColor: string]: string }>((acc, key) => {
  const colorOrColors = colors[key as keyof typeof colors];
  if (typeof colorOrColors !== 'string') {
    const colorKeys = Object.keys(colorOrColors);
    colorKeys.forEach((colorKey) => {
      acc[colorOrColors[colorKey as keyof typeof colorOrColors]] = key;
    });
  } else {
    if (!acc[colorOrColors]) {
      acc[colorOrColors] = key;
    }
  }

  return acc;
}, {});

export const hexColorToSweepFamily = (hexColor: string) => colorToFamilyMap[hexColor];

// Returns the hex color for the given family and weight
// If the family or weight is not found, returns the original hexColor
export const changeSweepFamilyWeight = (hexColor: string, weight: string | number) => {
  const family = colorToFamilyMap[hexColor];
  if (!family) {
    return hexColor;
  }
  return ((colors as any)[family][weight] || hexColor) as string;
};
