import { Box, Button as MuiButton, ButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DEFAULT_SELECT_MENU_MAX_HEIGHT } from '../../../constants';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, colors } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';

export const FieldValue = styled('span')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
  display: 'block',

  '& svg path': {
    stroke: colors.black,
  },
});

export const ItemsPageBox = styled(Box)({
  width: 300,
  maxHeight: `calc(${DEFAULT_SELECT_MENU_MAX_HEIGHT} - 62px)`, //62 is the height of the list header (search)
  overflowY: 'scroll',
  overflowX: 'hidden',
  float: 'left',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});

type NestedSelectorButtonAdditionalProps = {
  isOpen?: boolean;
};

type NestedSelectorButtonPropsExtended = ButtonProps & NestedSelectorButtonAdditionalProps;

export const NestedSelectorButton = (props: NestedSelectorButtonPropsExtended) => {
  const { sx, isOpen, ...rest } = props;
  const endIcon = isOpen ? <ChevronUp variant="default" /> : <ChevronDown />;
  return (
    <MuiButton
      disableRipple
      variant="squared"
      color="secondary"
      size="medium"
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 0,
        fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
        fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
        lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
        justifyContent: 'space-between',
        border: 'none ',
        height: '38px',
        padding: '8px 18px 8px 12px', //to be aligned with sweep design system's "select",
        '&:hover': {
          backgroundColor: colors.white,
        },
        ...sx,
      }}
      fullWidth
      endIcon={endIcon}
      {...rest}
    />
  );
};
