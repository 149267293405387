const sortListByDate = (list: any, sortKey = 'updatedAt') => {
  if (Array.isArray(list)) {
    const newList = list;
    return newList.sort((a: any, b: any) => {
      return new Date(b[sortKey]).valueOf() - new Date(a[sortKey]).valueOf();
    });
  }

  return list;
};

export default sortListByDate;
