import { WithConfigurationCanvas } from '../configuration-canvas/WithConfigurationCanvas';
import { MultipleRuleBuildersProvider } from '../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';
import RoutingPage from '../../routing/RoutingPage';
import { VisibilityLayers } from '../../../types/enums/VisibilityLayers';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import useAutomationsCanvasInteractions from './useAutomationsCanvasInteractions';
import { RoutingTabs, useRoutingTabs } from '../../routing/useRoutingTabs';
import { useMemo } from 'react';
import { VisibilityElementMap } from '../configuration-canvas/types';
import useEntitiesToHighlight from './useEntitiesToHighlight';
import { RoutingTabsInfo } from '../../routing/routingTabsInfo';

const getRoutingVisibilityMap = (routingTab?: RoutingTabs): Partial<VisibilityMap> => {
  return {
    [VisibilityLayers.NURTURING_STEPS]: true,
    [VisibilityLayers.COMMENTS]: false,
    [VisibilityLayers.ASSIGNMENT_RULES]: routingTab === RoutingTabs.assignment,
    [VisibilityLayers.FUNNEL_ASSIGNMENT_RULES]: routingTab === RoutingTabs.assignment,
    [VisibilityLayers.SCHEDULED_ASSIGNMENTS]: routingTab === RoutingTabs.scheduled,
  };
};

export const CanvasRoutingPage = () => {
  const { routingTab } = useRoutingTabs();
  const { onEntityClick } = useAutomationsCanvasInteractions();
  const tabInfo = routingTab ? RoutingTabsInfo[routingTab] : undefined;
  const { entitiesToHighlight } = useEntitiesToHighlight(tabInfo?.automationVariant);

  const visibilityElements: VisibilityElementMap = useMemo(
    () => ({
      type: 'map',
      visibilityMap: getRoutingVisibilityMap(routingTab),
    }),
    [routingTab],
  );

  return (
    <WithConfigurationCanvas
      visibilityElements={visibilityElements}
      onFunnelStepClick={onEntityClick}
      onFunnelLabelPillClick={onEntityClick}
      onRecordTypeLabelPillClick={onEntityClick}
      highlightEntities={entitiesToHighlight}
    >
      <MultipleRuleBuildersProvider>
        <RoutingPage />
      </MultipleRuleBuildersProvider>
    </WithConfigurationCanvas>
  );
};
