import { Tooltip } from '@mui/material';
import { useRef, useState, useEffect, Fragment } from 'react';
import { isEllipsisActive } from '../../../lib/isEllipsisActive';
import { NestedSelectorPath } from './NestedSelector';
import { FieldValue } from './nestedSelectorStyledComponents';
import { ChevronRight } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';

export const NestedPathLabel = ({
  values,
  highlightLast,
  firstNodePretext,
}: {
  values: NestedSelectorPath;
  highlightLast?: boolean;
  firstNodePretext?: string;
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (ref) {
      setShowTooltip(isEllipsisActive(ref.current));
    }
  }, [values, ref]);

  const rendered = (
    <>
      {values.map(({ label, value }, idx) => {
        const isNotLast = idx + 1 < values.length;
        return (
          <Fragment key={value}>
            {
              <span
                style={{
                  fontWeight: isNotLast || !highlightLast ? 'it' : 700,
                }}
              >
                {idx === 0 && firstNodePretext && firstNodePretext + ' '}
                {label}
              </span>
            }{' '}
            {isNotLast && (
              <span style={{ fontSize: '10px', top: '3px', position: 'relative' }}>
                <ChevronRight color={colors.white} />{' '}
              </span>
            )}
          </Fragment>
        );
      })}
    </>
  );

  return (
    <Tooltip
      title={rendered}
      placement="top"
      arrow
      enterDelay={700}
      disableHoverListener={!showTooltip}
    >
      <FieldValue ref={ref}>{rendered}</FieldValue>
    </Tooltip>
  );
};
