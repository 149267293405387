import { Box } from '@mui/material';
import { LostStepStages } from './LostStepStages';

interface LostStepPanelProps {
  objectName: string;
  lostSteps: SweepStage[];
  onChangeStageName: (stageId: string, stageName: string) => any;
  onAddStage: () => any;
  onDeleteStage: (objectType: string, stageId: string) => any;
}

export const LostStepPanel = ({
  objectName,
  lostSteps,
  onAddStage,
  onDeleteStage,
  onChangeStageName,
}: LostStepPanelProps) => {
  return (
    <Box>
      <LostStepStages
        objectType={objectName}
        lostStepStages={lostSteps || []}
        onChangeStageName={onChangeStageName}
        onAddStage={onAddStage}
        onDeleteStage={onDeleteStage}
      />
    </Box>
  );
};
