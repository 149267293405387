import { useDispatch, useSelector } from 'react-redux';
import { useThirdPartyFunnelFacade } from '../../apis/facades/useThirdPartyFunnelFacade';
import { useCallback } from 'react';
import {
  selectThirdPartyFunnelsLoaded,
  selectAddOrImportDialogOpen,
  selectThirdPartyFunnels,
  loadThirdPartyFunnels,
  addThirdPartyFunnel,
  removeThirdPartyFunnel,
  editThirdPartyFunnel,
  selectThirdPartyFunnelsLoading,
  setThirdPartyFunnelsLoading,
} from './thirdPartyFunnelsReducer';

export const useThirdPartyFunnelsApi = () => {
  const dispatch = useDispatch();
  const {
    get_third_party_funnels,
    post_third_party_funnel,
    delete_third_party_funnel,
    put_third_party_funnel,
  } = useThirdPartyFunnelFacade();
  const loaded = useSelector(selectThirdPartyFunnelsLoaded);
  const loading = useSelector(selectThirdPartyFunnelsLoading);
  const addOrImportDialogOpen = useSelector(selectAddOrImportDialogOpen);
  const thirdPartyFunnels = useSelector(selectThirdPartyFunnels);

  const getThirdPartyFunnels = useCallback(async () => {
    dispatch(setThirdPartyFunnelsLoading(true));
    const funnels = await get_third_party_funnels();
    dispatch(loadThirdPartyFunnels(funnels));
    return funnels;
  }, [dispatch, get_third_party_funnels]);

  const createThirdPartyFunnel = useCallback(
    async (thirdPartyFunnel: Omit<ThirdPartyFunnelData, 'id'>) => {
      const newThirdPartyFunnel = await post_third_party_funnel(thirdPartyFunnel);
      dispatch(addThirdPartyFunnel(newThirdPartyFunnel));
      return newThirdPartyFunnel;
    },
    [dispatch, post_third_party_funnel],
  );

  const deleteThirdPartyFunnel = useCallback(
    async (funnelId: string) => {
      const response = await delete_third_party_funnel(funnelId);
      if (response.success) {
        dispatch(removeThirdPartyFunnel(funnelId));
      }
      return response;
    },
    [delete_third_party_funnel, dispatch],
  );

  const saveThirdPartyFunnel = useCallback(
    async (funnelId: string, thirdPartyFunnel: ThirdPartyFunnelData) => {
      const savedThirdPartyFunnel = await put_third_party_funnel(funnelId, thirdPartyFunnel);
      dispatch(editThirdPartyFunnel(thirdPartyFunnel));
      return savedThirdPartyFunnel;
    },
    [dispatch, put_third_party_funnel],
  );

  return {
    loading,
    loaded,
    addOrImportDialogOpen,
    thirdPartyFunnels,
    getThirdPartyFunnels,
    createThirdPartyFunnel,
    deleteThirdPartyFunnel,
    saveThirdPartyFunnel,
  };
};
