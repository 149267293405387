import { Box } from '@mui/material';
import _keyBy from 'lodash/keyBy';
import { User as UserCircleIcon } from '@sweep-io/sweep-design/dist/icons';
import { PresentationChartIcon, SuitcaseIcon } from '../../../../../../icons/generalIcons';
import { BiFlagName } from '../../../../../../types/enums/BiFlag';
import { BoxWithSwitch } from './BoxWithSwitch';

export const FunnelBiSettingsBox = ({
  biSettings,
  onChange,
  readOnly,
}: {
  biSettings: BiSettings;
  readOnly?: boolean;
  onChange: (biSettings: BiSettings) => void;
}) => {
  if (!biSettings) {
    return <>No Bi Settings found</>;
  }
  const biSettingsByKey = _keyBy(biSettings.flags, 'name');

  const timestamp = biSettingsByKey[BiFlagName.Timestamp]?.value;
  const owner = biSettingsByKey[BiFlagName.Owner]?.value;
  const ownerRole = biSettingsByKey[BiFlagName.OwnerRole]?.value;
  const isDisabled = !timestamp || readOnly;

  const _onChange =
    (flagName: BiFlagName) => (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newFlags = [...(biSettings.flags || [])];

      const newFlag = {
        name: flagName,
        value: checked,
      };
      const index = newFlags.findIndex((flag) => flag.name === flagName);
      if (index >= 0) {
        newFlags[index] = newFlag;
      } else {
        newFlags.push(newFlag);
      }
      onChange({ flags: newFlags });
    };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
      <BoxWithSwitch
        renderedIconComponent={<PresentationChartIcon color="inherit" />}
        title="Track velocity and duration"
        subtitle="Create duration and entry date/time fields per step for reporting and measurement"
        onChange={_onChange(BiFlagName.Timestamp)}
        checked={timestamp}
      />
      <BoxWithSwitch
        isDisabled={isDisabled}
        renderedIconComponent={<SuitcaseIcon color="inherit" />}
        title="By Role"
        subtitle="Add owner tracking by role"
        onChange={_onChange(BiFlagName.OwnerRole)}
        checked={ownerRole}
      />

      <BoxWithSwitch
        isDisabled={isDisabled}
        renderedIconComponent={<UserCircleIcon variant="large" color="inherit" />}
        title="By User"
        subtitle="Add owner tracking by user"
        onChange={_onChange(BiFlagName.Owner)}
        checked={owner}
      />
    </Box>
  );
};
