import { useMemo } from 'react';
import {
  CanvasElementType,
  SweepCanvasEdge,
  SweepCanvasGroup,
  SweepCanvasNode,
} from '../../multi-canvas/canvasTypes';
import { Dictionary } from 'lodash';
import { PillsMap } from '../../canvas-pills/canvasPillsReducer';
import { HighlightEntities } from '../funnelMapCanvasTypes';
import * as pillsHelper from '../pillsHelper';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import { recordTypeToNodeId } from '../helper';
import { SweepCanvasReactFlowEdgeDataType } from '../../multi-canvas/edges/SweepCanvasReactFlowEdgeDataType';

export const useCalculateRecordTypeNodes = ({
  funnelMap,
  objectTypesByName,
  highlightEntities,
  renderZeroCountPills,
  pills,
  visibilityMap,
}: {
  funnelMap: FunnelMap;
  objectTypesByName: Dictionary<ObjectTypeName>;
  highlightEntities?: HighlightEntities[];
  renderZeroCountPills?: boolean;
  pills?: Partial<PillsMap>;
  visibilityMap: VisibilityMap;
}) => {
  const { recordTypes = {}, recordTypesData = {} } = funnelMap;
  return useMemo(() => {
    const groups: SweepCanvasGroup[] = [];
    const nodes: SweepCanvasNode[] = [];
    const edges: SweepCanvasEdge[] = [];

    const sanitizedRecordTypesEntries = Object.entries(recordTypes).filter(
      ([id]) => recordTypesData[id] && !recordTypes[id].isHidden,
    );

    sanitizedRecordTypesEntries.forEach(([recordTypeId, { position }]) => {
      const recordType = recordTypesData[recordTypeId];
      const [objectName] = recordTypeId.split('.');
      const objectLabel = objectTypesByName[objectName]?.label ?? objectName;

      // calculates groups
      const group: SweepCanvasGroup = {
        id: recordTypeId,
        name: recordTypesData[recordTypeId]?.label,
        objectType: objectName,
        objectLabel,
        position,
        pills: pillsHelper.recordTypePills({
          recordType,
          recordTypeId,
          highlightEntities,
          renderZeroCountPills,
          visibilityMap,
          pills,
        }),
        showEditButton: Boolean(recordTypesData[recordTypeId]?.leadingField),
      };
      groups.push(group);

      // calculates nodes

      const leadingObjectFieldName = recordTypes[recordTypeId].leadingFieldName;
      if (leadingObjectFieldName) {
        if (!funnelMap.recordTypesData) return [];
        const values = funnelMap.recordTypesData[recordTypeId].leadingField?.values;
        if (!values) return [];

        values.forEach((recordTypeDataValue, idx) => {
          const [objectName] = recordTypeId.split('.');
          const recordType = recordTypesData[recordTypeId];

          const node: SweepCanvasNode = {
            id: recordTypeToNodeId(recordTypeId, recordTypeDataValue.fullName),
            name: recordTypeDataValue.label,
            type: CanvasElementType.REGULAR,
            objectType: objectName,
            position: { column: idx, row: 0 },
            parentId: recordTypeId,
            pills: pillsHelper.recordTypeStepPills({
              recordType,
              recordTypeDataValue,
              recordTypeId,
              highlightEntities,
              renderZeroCountPills,
              visibilityMap,
              pills,
            }),
          };
          nodes.push(node);
          if (idx < values.length - 1) {
            edges.push({
              id: `${recordTypeId}-${idx}-edge`,
              source: recordTypeToNodeId(recordTypeId, recordTypeDataValue.fullName),
              target: recordTypeToNodeId(recordTypeId, values[idx + 1].fullName),
              data: {
                type: SweepCanvasReactFlowEdgeDataType.SIMPLE,
              },
            });
          }
        });
      } else {
        nodes.push({
          id: `${recordTypeId}-ghost-node0`,
          name: 'Show Business Process',
          type: CanvasElementType.GHOST_NODE,
          objectType: objectName,
          position: { column: 0, row: 0 },
          parentId: recordTypeId,
        });
      }
    });

    return {
      groups,
      nodes,
      edges,
    };
  }, [
    funnelMap.recordTypesData,
    highlightEntities,
    objectTypesByName,
    pills,
    recordTypes,
    recordTypesData,
    renderZeroCountPills,
    visibilityMap,
  ]);
};
