import { useSelector } from 'react-redux';
import { selectLatestDeployment } from '../../../../../reducers/documentationReducer';
import { ReadOnlyDedupMatching } from '@server/read-only-elements.types';
import { Box } from '@mui/material';
import { Subtext } from './organisms/atoms/Subtext';
import { MainSectionWithHeaderWrapper } from './organisms/MainSectionWithHeaderWrapper';
import { keyToName } from './consts';
import { Settings } from './organisms/Settings';
import { Notification } from './organisms/Notification';
import { SubsectionWrapper } from './organisms/SubsectionWrapper';
import { ConfigurationTypesWithIcon } from '../../types';
import { propertiesToIcon } from './organisms/helpers/icons';
import { MergeActionTypeToTextMap } from '../../../../pages/devops-center/DiffDialog/dedupMatchingFormatter';

export const DedupAndMatchingTemplate = ({
  elementId,
  elementType,
}: {
  elementId: string;
  elementType: ConfigurationTypesWithIcon;
}) => {
  const latestDeployment = useSelector(selectLatestDeployment(elementId));

  if (!latestDeployment) {
    return <></>;
  }

  const { name, trigger, settings, notification } = latestDeployment as ReadOnlyDedupMatching;
  const _trigger = typeof trigger === 'string' ? MergeActionTypeToTextMap[trigger] : trigger;

  return (
    <Box>
      <MainSectionWithHeaderWrapper
        title={keyToName['name']}
        titleIcon={propertiesToIcon['name']}
        withPaddingLeft
      >
        <Subtext text={name} />
      </MainSectionWithHeaderWrapper>

      {!!_trigger && (
        <MainSectionWithHeaderWrapper
          title={keyToName['trigger']}
          titleIcon={propertiesToIcon['trigger']}
          withPaddingLeft
        >
          <Box ml={-3}>
            <SubsectionWrapper
              noConditionExternalBorder
              elementType={elementType}
              readonlyAutomationActions={[_trigger]}
            />
          </Box>
        </MainSectionWithHeaderWrapper>
      )}

      {!!notification?.details.length && (
        <MainSectionWithHeaderWrapper
          title={keyToName['notification']}
          titleIcon={propertiesToIcon['notification']}
          withPaddingLeft
        >
          <Notification action={notification} />
        </MainSectionWithHeaderWrapper>
      )}

      <MainSectionWithHeaderWrapper
        title={keyToName['settings']}
        titleIcon={propertiesToIcon['settings']}
        noPaddingBottom
      >
        <Settings settings={settings} type={elementType} />
      </MainSectionWithHeaderWrapper>
    </Box>
  );
};
