import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface DeploymentChangesState {
  isVisible: boolean;
  crmOrgId?: string;
  snapshotId?: string;
}

const initialState: DeploymentChangesState = {
  isVisible: false,
};

export const deploymentChangesUi = createSlice({
  name: 'deploymentChangesUi',
  initialState,
  reducers: {
    open(state, action) {
      const { snapshotId, crmOrgId } = action.payload;
      state.snapshotId = snapshotId;
      state.crmOrgId = crmOrgId;
      state.isVisible = true;
    },
    close(state) {
      state.isVisible = false;
      state.snapshotId = undefined;
      state.crmOrgId = undefined;
    },
  },
});

export const { open, close } = deploymentChangesUi.actions;

export const selectDialogVisibility = (state: RootState) =>
  state[deploymentChangesUi.name].isVisible;

const selectDeploymentChangesUiSnapshotId = (state: RootState) =>
  state[deploymentChangesUi.name].snapshotId;
const selectDeploymentChangesUiCrmOrgId = (state: RootState) =>
  state[deploymentChangesUi.name].crmOrgId;

export const compareDeployedWith = createSelector(
  [selectDeploymentChangesUiSnapshotId, selectDeploymentChangesUiCrmOrgId],
  (snapshotId, crmOrgId) => ({
    snapshotId,
    crmOrgId,
  }),
);
