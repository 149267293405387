import { Box, FormControl, TextField, SelectChangeEvent, MenuItem } from '@mui/material';
import { AutomationTypes } from '../../../types/enums/AutomationTypes';
import SweepSelect from '../../common/SweepSelect';
import { getNestedPath } from '../helper';
import { colors } from '@sweep-io/sweep-design';
import { NestedFieldsSelector } from '../../common/fieldsSelectors/NestedFieldsSelector';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';

interface TriggerDateArriveProps {
  crmOrgId: string;
  automationJson: DeepPartial<AutomationStructureNew>;
  onChangeType: (val: string) => any;
  onChangeValue: (val: string) => any;
  onChangeValueType: (val: string) => any;
  onChangField: (leadingFieldId: string, leadingFieldLabels: string[]) => any;
  readonly?: boolean;
  objectName: ObjectTypeValues;
}

export const TriggerDateArrive = ({
  crmOrgId,

  automationJson,
  onChangeType,
  onChangeValue,
  onChangeValueType,
  onChangField,
  readonly,
  objectName,
}: TriggerDateArriveProps) => {
  const JsonDateArrive = automationJson.automationDetails?.automationParams?.dateArrive;

  const filterDates = (field: SweepField) =>
    field.fieldType === SweepFieldTypes.Date ||
    field.fieldType === SweepFieldTypes.DateTime ||
    field.fieldType === SweepFieldTypes.FormulaDateTime;

  return (
    <>
      <Box>
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '140px', marginRight: '8px' },
          }}
          SelectProps={{
            disabled: readonly,
            placeholder: 'Choose value',
            value: JsonDateArrive?.relativeTime ?? '',
            onChange: (event: SelectChangeEvent<unknown>) => {
              const val = event.target.value;
              onChangeType(val as string);
            },
            renderValue: (val: any) => {
              if (val === 'exact') {
                return 'On exact date';
              } else if (val === 'before') {
                return 'Before date';
              } else {
                return 'After date';
              }
            },
          }}
        >
          {[
            {
              value: 'exact',
              label: 'On exact date',
            },
            {
              value: 'before',
              label: 'Before date',
            },
            {
              value: 'after',
              label: 'After date',
            },
          ].map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.label}
            </MenuItem>
          ))}
        </SweepSelect>
      </Box>
      {automationJson.automationDetails?.triggerType === AutomationTypes.DateArrive &&
        JsonDateArrive?.relativeTime &&
        JsonDateArrive?.relativeTime !== 'exact' && (
          <>
            <FormControl sx={{ minWidth: '120px', marginRight: '8px' }}>
              <TextField
                disabled={readonly}
                type={'number'}
                value={JsonDateArrive.offsetValue ?? ''}
                onChange={(event) => {
                  onChangeValue(event.target.value);
                }}
                InputProps={{
                  inputProps: {
                    min: 1,
                  },
                  sx: {
                    padding: '8px 6px',
                    height: '39px',
                    width: '122px',
                  },
                }}
                placeholder={'Value'}
              />
            </FormControl>
            <Box>
              <SweepSelect
                FormControlProps={{
                  sx: { minWidth: '140px', marginRight: '8px' },
                }}
                SelectProps={{
                  disabled: readonly,
                  placeholder: 'Value Type',
                  value: JsonDateArrive?.offsetType ?? '',
                  onChange: (event: SelectChangeEvent<unknown>) => {
                    const val = event.target.value;
                    onChangeValueType(val as string);
                  },
                }}
              >
                {['Hours', 'Days', 'Weeks'].map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </SweepSelect>
            </Box>
            <Box sx={{ marginRight: '8px' }}>
              {JsonDateArrive.relativeTime === 'before' ? 'before' : 'after'}
            </Box>
          </>
        )}

      {automationJson.automationDetails?.triggerType === AutomationTypes.DateArrive &&
        JsonDateArrive?.relativeTime && (
          <>
            <Box
              sx={{
                marginRight: '8px',
                borderRadius: '4px',
                border: `1px solid ${colors.grey[300]}`,
                position: 'relative',
              }}
            >
              <NestedFieldsSelector
                filterBy={filterDates}
                disableLookupItemsResolve={true}
                crmOrgId={crmOrgId}
                objectType={objectName}
                nestedPath={getNestedPath(
                  JsonDateArrive?.sourceField?._leadingFieldId,
                  JsonDateArrive?.sourceField?._leadingFieldLabels,
                )}
                readonly={readonly}
                onChange={(_sweepField) => {
                  onChangField(_sweepField.fieldIds[0], _sweepField.fieldLabels);
                }}
              />
            </Box>
          </>
        )}
    </>
  );
};
