export enum QuestionnaireStatus {
  COMPLETED = 'COMPLETED',
  NOT_COMPLETED = 'NOT_COMPLETED',
}

export enum QuestionnaireRole {
  ADMIN = 'ADMIN',
  BUSINESS_SYSTEMS = 'BUSINESS_SYSTEMS',
  OPERATIONS = 'OPERATIONS',
  SALES = 'SALES',
  MARKETING = 'MARKETING',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  CONSULTANT = 'CONSULTANT',
  OTHER = 'OTHER',
}

export enum QuestionnaireInterest {
  DOCUMENTATION = 'DOCUMENTATION',
  AUTOMATIONS = 'AUTOMATIONS',
  ALERTS = 'ALERTS',
  ROLLUPS = 'ROLLUPS',
  ROUTING = 'ROUTING',
  DEDUPE = 'DEDUPE',
  VISUALIZE = 'VISUALIZE',
  PLAN = 'PLAN',
  TRACK_CHANGES = 'TRACK_CHANGES',
  OTHER = 'OTHER',
}
