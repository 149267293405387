import { useSweepApi } from '../apis/sweep';
import { useCallback } from 'react';
import { AxiosError } from 'axios';
import usePermission from '../components/common/permissions/usePermission';
import { ConvertRecordTypePayload, useFunnelsApiFacade } from '../apis/facades/useFunnelsApiFacade';
import { telemetry } from '../telemetry';

const useFunnels = () => {
  const sweepApi = useSweepApi();
  const [permissionCreateFunnelFromRecordType] = usePermission(['create:funnels:record-types']);
  const { post_convertRecordType } = useFunnelsApiFacade();

  const fetchFunnels = useCallback(
    async (type = 'funnel') => {
      return await sweepApi.get(`/funnels?type=${type}`);
    },
    [sweepApi],
  );

  const convertRecordTypeToFunnel = async ({
    defaultCrmOrgId,
    objectName, //objectApiName
    recordTypeName,
    recordTypeLabel,
    recordTypeDescription,
    leadingField,
    funnelName,
  }: ConvertRecordTypePayload) => {
    const data = {
      objectName,
      recordTypeName,
      recordTypeLabel,
      recordTypeDescription,
      leadingField,
      funnelName,
      defaultCrmOrgId,
    };
    if (!permissionCreateFunnelFromRecordType) {
      throw new Error(`You lack permission to convert a funnel from a record type.`);
    }
    try {
      const result = await post_convertRecordType(data);
      return result as Funnel;
    } catch (e) {
      if ((e as AxiosError).response?.status === 409) {
        throw new Error(`This record type already exists as a funnel "${funnelName}".`);
      } else {
        telemetry.captureError(e);
        throw new Error(`Unknown error while converting record type`);
      }
    }
  };

  return {
    fetchFunnels,
    convertRecordTypeToFunnel,
  };
};

export { useFunnels };
