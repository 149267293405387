import Box from '@mui/material/Box';
import { Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

export const FormSection = ({ children, label }: { children: ReactNode; label: string }) => {
  return (
    <Box mb={3} display="flex" flexDirection="column" gap={1}>
      <Typography variant="body">{label}</Typography>
      {children}
    </Box>
  );
};
