import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

type AlertsReducersProps = {
  addAlert: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automation: AutomationStructureNew;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateAlert: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  toggleAlert: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteAlert: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationIdToDelete: string;
    }>,
  ) => any;
  updateAlertDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const alertsReducers: AlertsReducersProps = {
  addAlert: (state, action) => {
    const { crmOrgId, automation, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    if (!state.environments[crmOrgId].data.alerts) {
      state.environments[crmOrgId].data.alerts = [];
    }
    state.environments[crmOrgId].data.alerts?.push({ ...automation, status: optimisticStatus });
  },
  updateAlert: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.alerts) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.alerts?.findIndex(
      (r) =>
        r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.alerts[indexToUpdate] = {
        ...automationToUpdate,
        status: optimisticStatus,
      };
    }
  },
  deleteAlert: (state, action) => {
    const { crmOrgId, automationIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.alerts) {
      return;
    }
    const indexToDelete = state.environments[crmOrgId].data.alerts?.findIndex(
      (r) => r.automationId === automationIdToDelete,
    );
    if (indexToDelete !== undefined) {
      state.environments[crmOrgId].data.alerts?.splice(indexToDelete, 1);
    }
  },
  toggleAlert: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.alerts) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.alerts?.findIndex(
      (r) =>
        r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.alerts[indexToUpdate] = {
        ...automationToUpdate,
        status: optimisticStatus,
      };
    }
  },
  updateAlertDeployStatus: (state, action) => {
    const { crmOrgId, automationId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.alerts) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.alerts?.findIndex(
      (r) => r.automationId === automationId && r.versionId === versionId,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.alerts[indexToUpdate].status = optimisticStatus;
    }
  },
};

export const selectCrmOrgAlerts = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.alerts;
};

export const selectAlerts = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgAlerts(crmOrg.id)(state);
  }
};
