import {
  WorkflowCreateDto,
  WorkflowDto,
  WorkflowUpdateDto,
} from '../../components/pages/canvas-pages/canvas-hubspot-page/types';
import { useSweepApi } from '../sweep';
import { useCallback } from 'react';

export const useWorkflowsApiFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_workflows: useCallback(
      async (): Promise<WorkflowDto[]> => {
        const url = '/workflows';
        const response = await sweepApi.get(url);
        return response.data;
      },
      [sweepApi],
    ),
    post_workflow: useCallback(
      async (workflow: WorkflowCreateDto): Promise<WorkflowDto> => {
        const response = await sweepApi.post('/workflows', workflow);

        return response.data;
      },
      [sweepApi],
    ),
    put_workflow: useCallback(
      async (workflow: WorkflowUpdateDto): Promise<WorkflowDto> => {
        const response = await sweepApi.put(`/workflows`, workflow);

        return response.data;
      },
      [sweepApi],
    ),
    delete_workflow: useCallback(
      async (workflowId: string): Promise<void> => {
        await sweepApi.delete(`/workflows/${workflowId}`);
      },
      [sweepApi],
    ),
  };
};
