export enum ActionsRequireMp {
  DEPLOY = 'DEPLOY',
  ASSIGN = 'ASSIGN',
  FIELDS = 'FIELDS',
  SLACK = 'SLACK',
  CLOUDINGO = 'CLOUDINGO',
  AI = 'AI',
}

const ACTIONS = {
  [ActionsRequireMp.DEPLOY]: { text: 'deploy directly from Sweep', inProduction: false },
  [ActionsRequireMp.ASSIGN]: { text: 'assign members to Assignment Groups', inProduction: false },
  [ActionsRequireMp.FIELDS]: { text: 'see fields utilization in Sweep', inProduction: false },
  [ActionsRequireMp.SLACK]: { text: 'connect Slack', inProduction: true },
  [ActionsRequireMp.CLOUDINGO]: { text: 'connect Cloudingo', inProduction: true },
  [ActionsRequireMp.AI]: { text: 'add AI Assistant to Salesforce.', inProduction: false },
};

export const getInstallManagedPackageText = (action: ActionsRequireMp) =>
  `Install our managed package from Salesforce AppExchange ${ACTIONS[action].inProduction ? 'in your Production' : ''} to ${ACTIONS[action].text}.`;
