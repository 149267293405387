import { Box, FormControl, FormControlLabel, MenuItem, SelectChangeEvent } from '@mui/material';
import { useCallback, useState } from 'react';
import SweepSelect from '../common/SweepSelect';
import { Typography, colors, Checkbox } from '@sweep-io/sweep-design';
import FieldLabel from '../common/FieldLabel';
import { SelectorValueTypes } from '../../types/enums/SelectorValueTypes';
import {
  SweepFieldsDtoWithRefObjectNames,
  useSweepFields,
} from '../../sweep-fields/useCachedSweepFields';
import { useRunOnce } from '../common/useRunOnce';
import { SFDCObjectType } from '../../types/enums/SFDCObjectType';
import { SweepFieldTypes } from '../../types/enums/SweepFieldTypes';

interface ContactRoleSelectorProps {
  objectToSearch: string;
  fieldToSearch?: string;
  readonly?: boolean;
  crmOrgId: string;
  onChangeCheckbox: (
    isChecked: boolean,
    ocrElement: RelationsRecords,
    lookupFields?: OcrFieldsStruct[],
  ) => any;
  onSelectRole?: (_relationsObject: RelationsRecords) => any;
  actionOcrObject: RelationsRecords | undefined;
  label?: string;
  hideInnerSelection?: boolean;
  showTooltip?: boolean;
  lookupFieldsBy: lookupFieldsTypes;
  isShowPrimary?: boolean;
}

export enum lookupFieldsTypes {
  createContactOpp,
  cloneContactRole,
  CloneLineItem,
}

const Opportunity_ID = 'Opportunity ID';
const Contact_ID = 'Contact ID';
const OCRIsPrimary = 'OpportunityContactRole.IsPrimary';

//This function return the list of fields needed for every case of creating or cloning a relation object
const getLookupFieldsPerType = (lookupFieldsBy: lookupFieldsTypes) => {
  switch (lookupFieldsBy) {
    case lookupFieldsTypes.createContactOpp:
      return [
        {
          fieldsOnObject: Opportunity_ID,
          valueObject: SFDCObjectType.Opportunity,
          valueField: Opportunity_ID,
        },
        {
          fieldsOnObject: Contact_ID,
          valueObject: SFDCObjectType.Contact,
          valueField: Contact_ID,
        },
      ];

    case lookupFieldsTypes.CloneLineItem:
      return [
        {
          fieldsOnObject: Opportunity_ID,
          valueObject: SFDCObjectType.Opportunity,
          valueField: Opportunity_ID,
        },
      ];
    case lookupFieldsTypes.cloneContactRole:
      return [
        {
          fieldsOnObject: Opportunity_ID,
          valueObject: SFDCObjectType.Opportunity,
          valueField: Opportunity_ID,
        },
      ];
  }
};

export const ContactRoleSelector = ({
  readonly,
  crmOrgId,
  onChangeCheckbox,
  onSelectRole,
  actionOcrObject,
  label = 'Link this Opportunity record to the Contact record that triggered this automation',
  hideInnerSelection,
  objectToSearch,
  fieldToSearch,
  showTooltip,
  lookupFieldsBy,
  isShowPrimary,
}: ContactRoleSelectorProps) => {
  const [objectSweepFields, setObjectSweepFields] = useState<SweepFieldsDtoWithRefObjectNames>();
  const [ocrFields, setOcrFields] = useState<SweepField>();
  const { searchSweepFields } = useSweepFields();
  const NONE_VALUE = '___NONE___';
  const isPrimary =
    isShowPrimary && !!actionOcrObject?.fields?.find((el) => el?._fieldIds?.includes(OCRIsPrimary));
  const regularFieldValue =
    actionOcrObject?.fields?.filter((el) => el?._fieldIds?.[0] !== OCRIsPrimary)?.[0]?.value ||
    NONE_VALUE;

  const primaryField: OcrFieldsStruct = {
    _fieldIds: [OCRIsPrimary],
    fieldType: SweepFieldTypes.Checkbox,
    valueType: SelectorValueTypes.LITERAL,
    value: 'true',
  };

  useRunOnce(async () => {
    const { sweepFields } = await searchSweepFields({
      objectType: [objectToSearch],
      crmOrgId,
    });
    setObjectSweepFields(sweepFields[objectToSearch]);
    const fields = sweepFields[objectToSearch].fields.find(
      (el: SweepField) => el.sweepFieldName === fieldToSearch,
    );
    setOcrFields(fields);
  });

  const getLookupFields = useCallback(async () => {
    const listOfFields = getLookupFieldsPerType(lookupFieldsBy);
    const list: OcrFieldsStruct[] = [];

    await Promise.all(
      listOfFields.map(async (element) => {
        const { sweepFields } = await searchSweepFields({
          objectType: [element.valueObject],
          crmOrgId,
        });

        const fields = sweepFields[element.valueObject].fields;
        const valueField = fields.find((el) => el.sweepFieldName === element.valueField);

        const returnedField = objectSweepFields?.fields.find(
          (el) => el.sweepFieldName === element.fieldsOnObject,
        );

        list.push({
          _fieldIds: [returnedField?.id || ''],
          fieldName: returnedField?.sweepFieldName,
          fieldType: SweepFieldTypes.Lookup,
          valueType: SelectorValueTypes.REFERENCE,
          value: [
            {
              value: valueField?.id,
              label: valueField?.sweepFieldName,
            },
          ],
        });
      }),
    );

    return list;
  }, [crmOrgId, lookupFieldsBy, objectSweepFields?.fields, searchSweepFields]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '22px',
        '& label': { paddingBottom: 0 },
        '& span.MuiCheckbox-root': {
          ml: '1px',
        },
      }}
    >
      <FormControlLabel
        label={
          <FieldLabel
            label={label}
            infoTooltipTitle={
              showTooltip
                ? 'Check the box to create an Opportunity Contact Role that will connect between the Contact and the Opportunity'
                : ''
            }
            showTooltip
          />
        }
        control={
          <Checkbox
            disabled={readonly}
            checked={actionOcrObject?._isActive ?? false}
            onChange={async (value) => {
              const isChecked = value.target.checked;
              const lookupFields = await getLookupFields();
              const ocrElement = {
                objectType: objectToSearch,
                _isActive: isChecked,
                lookupFields,
              };
              onChangeCheckbox(isChecked, ocrElement);
            }}
          />
        }
      />

      {!hideInnerSelection && actionOcrObject?._isActive && ocrFields && (
        <FormControl
          sx={{
            minWidth: '120px',
            margin: '12px 0 0 28px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Box>
            <Typography variant="body" color={colors.grey[800]}>
              Choose Contact Role:
            </Typography>
          </Box>
          <Box sx={{ flexDirection: 'row', gap: 2, display: 'flex' }}>
            <SweepSelect
              disabled={readonly}
              SelectProps={{
                placeholder: 'Select Role',
                value: regularFieldValue,
                onChange: (event: SelectChangeEvent<unknown>) => {
                  const val = event.target.value as string;
                  const _ocrFields: OcrFieldsStruct = {
                    _fieldIds: [ocrFields.id || ''],
                    fieldName: ocrFields.sweepFieldName,
                    fieldType: ocrFields.fieldType,
                    valueType: SelectorValueTypes.LITERAL,
                    value: val === NONE_VALUE ? '' : val,
                  };
                  const _ocrFieldsExtend = [_ocrFields];
                  if (isShowPrimary && isPrimary) {
                    _ocrFieldsExtend.push(primaryField);
                  }
                  const _relationsObject = { ...actionOcrObject, fields: _ocrFieldsExtend };
                  onSelectRole && onSelectRole(_relationsObject);
                },
              }}
            >
              <MenuItem value={NONE_VALUE}>--None--</MenuItem>
              {ocrFields?.properties?.valueSet?.map((item) => (
                <MenuItem key={item.label} value={item.label}>
                  {item.label}
                </MenuItem>
              ))}
            </SweepSelect>
            {isShowPrimary && (
              <FormControlLabel
                label={<FieldLabel label={'Mark as primary'} />}
                control={
                  <Checkbox
                    disabled={readonly}
                    checked={isPrimary}
                    onChange={async (value) => {
                      const isChecked = value.target.checked;
                      let _relationsObjectFields = [];
                      if (isChecked) {
                        _relationsObjectFields = [...(actionOcrObject?.fields ?? []), primaryField];
                      } else {
                        _relationsObjectFields =
                          actionOcrObject?.fields?.filter(
                            (el) => el?._fieldIds?.[0] !== OCRIsPrimary,
                          ) ?? [];
                      }
                      const _relationsObject = {
                        ...actionOcrObject,
                        fields: _relationsObjectFields,
                      };
                      onSelectRole?.(_relationsObject);
                    }}
                  />
                }
              />
            )}
          </Box>
        </FormControl>
      )}
    </Box>
  );
};
