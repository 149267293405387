import { SweepFieldTypes } from '../../../../../../types/enums/SweepFieldTypes';

const NUMBERS_DEFAULTS = { scale: 0, precision: 18, unique: false };
const FORMULA_DEFAULTS = { formula: '' };

export const ANNOTATION_DEFAULTS: { [key in SweepFieldTypes]: SweepFieldProperties } = {
  [SweepFieldTypes.Checkbox]: { defaultValue: false },
  [SweepFieldTypes.Currency]: NUMBERS_DEFAULTS,
  [SweepFieldTypes.Email]: { unique: false },
  [SweepFieldTypes.Geolocation]: { scale: 6, displayLocationInDecimal: false },
  [SweepFieldTypes.LongTextArea]: { length: 32000 },
  [SweepFieldTypes.MultiselectPicklist]: { valueSet: [], visibleLines: 3 },
  [SweepFieldTypes.Picklist]: { valueSet: [] },
  [SweepFieldTypes.Number]: NUMBERS_DEFAULTS,
  [SweepFieldTypes.Percent]: NUMBERS_DEFAULTS,
  [SweepFieldTypes.Text]: {
    length: 80,
    caseSensitive: false,
    unique: false,
  },

  //Formula types
  [SweepFieldTypes.FormulaCheckbox]: { ...FORMULA_DEFAULTS, defaultValue: false },
  [SweepFieldTypes.FormulaCurrency]: { ...FORMULA_DEFAULTS, ...NUMBERS_DEFAULTS },
  [SweepFieldTypes.FormulaDate]: { ...FORMULA_DEFAULTS },
  [SweepFieldTypes.FormulaDateTime]: { ...FORMULA_DEFAULTS },
  [SweepFieldTypes.FormulaNumber]: { ...FORMULA_DEFAULTS, ...NUMBERS_DEFAULTS },
  [SweepFieldTypes.FormulaPercent]: { ...FORMULA_DEFAULTS, ...NUMBERS_DEFAULTS },
  [SweepFieldTypes.FormulaText]: { ...FORMULA_DEFAULTS, length: 80 },
  [SweepFieldTypes.FormulaTime]: { ...FORMULA_DEFAULTS },

  //No specific annotations
  [SweepFieldTypes.AutoNumber]: {},
  [SweepFieldTypes.Date]: {},
  [SweepFieldTypes.Address]: {},
  [SweepFieldTypes.DateTime]: {},
  [SweepFieldTypes.Hierarchy]: {},
  [SweepFieldTypes.Name]: {},
  [SweepFieldTypes.Phone]: {},
  [SweepFieldTypes.Unknown]: {},
  [SweepFieldTypes.Url]: {},
  [SweepFieldTypes.TextArea]: {},
  [SweepFieldTypes.Summary]: {},
  [SweepFieldTypes.Time]: {},
  [SweepFieldTypes.EncryptedText]: {},
  [SweepFieldTypes.Html]: {},
  [SweepFieldTypes.Lookup]: {},
  [SweepFieldTypes.MasterDetail]: {},
  [SweepFieldTypes.Territory]: {},
  [SweepFieldTypes.Id]: {},
  [SweepFieldTypes.Combobox]: {},
};
