import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { DataTable } from '../../../../common/table/DataTable';
import {
  FunnelFieldLabels,
  SWEEP_FIELD_NAME,
  USED_IN_AUTOMATIONS,
  USED_IN_FUNNELS,
} from '../../../../../constants/fieldsManagementConsts';
import { humanizeDate } from '../../../../helpers/humanizeDate';
import { ReactNode } from 'react';
import { TruncatedTextTooltip } from '../../../../common/TruncatedTextTooltip';
import LabelChip from '../../../../common/LabelChip';
import FieldsTableMenu from './FieldsTableMenu';
import { FieldUsageWithFieldMetadata } from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import { FunnelSweepFieldAnalysisCategory } from '../../../../../types/enums/FunnelSweepFieldAnalysisCategory';
import { SweepFieldTypes } from '../../../../../types/enums/SweepFieldTypes';
import { dataTableVariants } from '../../../../common/table/dataTableVariants';
import { DataTableVariant } from '../../../../common/table/TableTypes';
import useFieldIsRollup from '../../../rollups/useFieldIsRollup';

interface _FieldsTableRow {
  id: string;
  [SWEEP_FIELD_NAME]: ReactNode;
  fieldType: string;
  [USED_IN_FUNNELS]: string | ReactNode;
  [USED_IN_AUTOMATIONS]: string | ReactNode;
  updatedAt: string;
  menu: ReactNode;
}

const noneJsx = (
  <Typography
    variant={dataTableVariants[DataTableVariant.default].fontVariant}
    color={colors.grey[500]}
  >
    None
  </Typography>
);

const columns = [
  { field: 'sweepFieldName', headerName: 'Name', width: '25%' },
  { field: 'fieldType', headerName: 'Type', width: '10%' },
  { field: 'usedInFunnels', headerName: 'Used in steps', width: '22%' },
  {
    field: 'usedInAutomations',
    headerName: 'Used in automations',
    width: '22%',
  },
  { field: 'updatedAt', headerName: 'Last modified', width: '15%' },
  { field: 'menu', headerName: '', width: '6%' },
];

const getUsedInSectionString = (sectionUsagePerFunnels: SectionUsagePerFunnel[]) => {
  return sectionUsagePerFunnels?.map(({ funnelName, usage }) => {
    return funnelName + (Boolean(usage.length) ? ` (${usage.join(', ')})` : '');
  });
};

const FieldsTableSingleObject = ({
  fields,
  crmOrgId,
}: {
  fields: FieldUsageWithFieldMetadata[];
  crmOrgId: string;
}) => {
  const getFieldIsRollup = useFieldIsRollup();
  const isEmpty = fields.length === 0;

  const rows: _FieldsTableRow[] = fields.map((field) => {
    const {
      field: { updatedAt, fieldType, sweepFieldName },
      leadingInFunnels,
      sweepFieldId: id,
      sections,
    } = field;
    const isLeadingField = leadingInFunnels.length > 0;
    const isRollup = getFieldIsRollup(field.field.id ?? '');
    const usedInStages = getUsedInSectionString(
      sections[FunnelSweepFieldAnalysisCategory.STAGES] ?? [],
    );
    const automationsSection = sections[FunnelSweepFieldAnalysisCategory.AUTOMATIONS] ?? [];
    const alertsSection = sections[FunnelSweepFieldAnalysisCategory.ALERTS] ?? [];
    const assignmentsSection = sections[FunnelSweepFieldAnalysisCategory.ASSIGNMENTS] ?? [];
    const allAutomationRelatedSections = automationsSection
      .concat(alertsSection)
      .concat(assignmentsSection);
    const usedInAutomationsTotal = getUsedInSectionString(allAutomationRelatedSections);

    return {
      ...field,
      id: id ?? '',
      sweepFieldName: (
        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <TruncatedTextTooltip variant={dataTableVariants[DataTableVariant.default].fontVariant}>
            {sweepFieldName}
          </TruncatedTextTooltip>
          {isLeadingField && <LabelChip bgColor={colors.blue[100]}>Leading Field</LabelChip>}
        </Box>
      ),
      fieldType: FunnelFieldLabels[fieldType ?? SweepFieldTypes.Text] as SweepFieldTypes, //TEXT is just a default to make typescript happy
      usedInFunnels: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TruncatedTextTooltip variant={dataTableVariants[DataTableVariant.default].fontVariant}>
            {!usedInStages || usedInStages?.length === 0 ? noneJsx : usedInStages?.join(', ')}
          </TruncatedTextTooltip>
        </Box>
      ),
      usedInAutomations: (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <TruncatedTextTooltip variant={dataTableVariants[DataTableVariant.default].fontVariant}>
            {!usedInAutomationsTotal || usedInAutomationsTotal?.length === 0
              ? noneJsx
              : usedInAutomationsTotal?.join(', ')}
          </TruncatedTextTooltip>
        </Box>
      ),
      updatedAt:
        humanizeDate({
          dateOrTimestamp: updatedAt ?? '',
        }) ?? '',
      menu: (
        <FieldsTableMenu
          field={field}
          crmOrgId={crmOrgId}
          isLeadingField={isLeadingField}
          isRollup={!!isRollup}
        />
      ),
    };
  });

  if (isEmpty)
    return (
      <Typography color={colors.grey[800]} variant="caption">
        No matches found
      </Typography>
    );
  return (
    <Box display="flex" flexDirection="column">
      <DataTable columns={columns} rows={rows} />
    </Box>
  );
};

export default FieldsTableSingleObject;
