import { ReactNode, useState } from 'react';
import { FormControl, SxProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { ClearIcon } from './ClearIcon';
import FieldLabel from '../../FieldLabel';
import { DateTime } from 'luxon';
import { humanizeDate } from '../../../helpers/humanizeDate';
import { designSystemElevations } from '../../../../appTheme/appTheme';

export interface DateSelectorProps {
  initialValue?: string; //in SQL format
  onChange: (value: string) => void;
  shouldBeDisabled?: boolean;
  textFieldSx?: SxProps;
  placeholder?: string;
  label?: string;
  infoTooltipTitle?: NonNullable<ReactNode>;
  showTooltip?: boolean;
  id?: string;
  removeBorders?: boolean;
}

const DateSelector = ({
  initialValue,
  onChange,
  shouldBeDisabled,
  textFieldSx,
  placeholder,
  label,
  id,
  showTooltip,
  infoTooltipTitle,
  removeBorders,
}: DateSelectorProps) => {
  const [newValue, setValue] = useState(initialValue ? DateTime.fromISO(initialValue) : undefined);

  const handleSetValue = (value?: DateTime | null) => {
    const parsedVal = value?.toSQLDate() ?? '';
    setValue(value ?? undefined);
    onChange(parsedVal);
  };

  return (
    <FormControl fullWidth>
      {label && (
        <FieldLabel
          label={label}
          id={id}
          infoTooltipTitle={infoTooltipTitle}
          showTooltip={showTooltip}
        />
      )}
      <DatePicker
        disabled={shouldBeDisabled}
        value={newValue}
        defaultValue={newValue}
        onChange={handleSetValue}
        sx={{
          fieldset: {
            border: removeBorders ? 'none' : 'auto',
          },
        }}
        slotProps={{
          layout: {
            sx: {
              boxShadow: designSystemElevations.Elevation7,
              borderRadius: 2,
            },
          },
          textField: (params) => ({
            disabled: shouldBeDisabled,
            inputProps: {
              ...params.inputProps,
              placeholder: placeholder ?? 'Choose date',
              disabled: true,
              value: humanizeDate({
                dateOrTimestamp: newValue?.toSQLDate() ?? '',
              }),
            },
            sx: { ...params.sx, ...textFieldSx },
            error: false,
            InputProps: {
              endAdornment: (
                <>
                  {newValue && !shouldBeDisabled && (
                    <ClearIcon clearValue={() => handleSetValue(undefined)} />
                  )}
                  {params.InputProps?.endAdornment}
                </>
              ),
            },
          }),
        }}
      />
    </FormControl>
  );
};

export const displayCorrectFormat = (value?: string) => {
  if (!value) {
    return '';
  }

  const date = new Date(value);
  return date.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    timeZone: 'UTC',
  });
};

export { DateSelector };
