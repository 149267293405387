import { memo } from 'react';
import { NodeProps } from 'reactflow'; 
import { BasedStepNode } from './BasedStepNode';
import '../../css/react-flow-styles.css';
import { RFNodeData } from '../../canvasTypes/canvasTypes';

// This is a hack to hide the handles. We need them in react flow
// for arrow position calculations, but we don't want to show them
// so we hide them here.
export const hiddenHandleStyle = {
  background: 'rgba(0,0,0,0)',
  border: 'none',
};

export enum SweepNodeHandles {
  SourceTop = 'st',
  SourceRight = 'sr',
  SourceBottom = 'sb',
  SourceLeft = 'sl',
  TargetTop = 'tt',
  TargetRight = 'tr',
  TargetBottom = 'tb',
  TargetLeft = 'tl',
}

const RegularStep = ({ data, dragging, id, selected }: NodeProps<RFNodeData>) => (
  <BasedStepNode id={id} selected={selected} dragging={dragging} {...data} />
);

const _memoRegularStep = memo(RegularStep);

export { _memoRegularStep as RegularStep };
