import { styled } from '@mui/material/styles';
import { InfoTooltip } from './InfoTooltip';
import RequiredDot from './RequiredDot';
import { ReactNode } from 'react';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, Typography } from '@sweep-io/sweep-design';

const StyledFieldLabel = styled('label')({
  // color: colors.grey[800],
  ...__SWEEP_DESIGN_TYPOGRAPHY_STYLES___.caption,
  paddingBottom: '5px',
});

// TODO remove showTooltip - use Boolean(infoTooltipTitle) instead

const FieldLabel = ({
  label,
  id,
  showTooltip,
  infoTooltipTitle,
  markLabelAsRequired,
  ...rest
}: {
  label: string;
  id?: string;
  infoTooltipTitle?: NonNullable<ReactNode>;
  showTooltip?: boolean;
  markLabelAsRequired?: boolean;
}) => {
  return (
    <StyledFieldLabel htmlFor={id} sx={{ display: 'flex', alignItems: 'center' }} {...rest}>
      <Typography variant="body">{label}</Typography>
      {markLabelAsRequired && <RequiredDot />}
      {showTooltip && infoTooltipTitle && <InfoTooltip title={infoTooltipTitle} />}
    </StyledFieldLabel>
  );
};

export default FieldLabel;
