import { ActionCreators as UndoActionCreators } from 'redux-undo';
import { useDispatch, useSelector } from 'react-redux';
import useEventListener from '../../common/useEventListener';
import { selectCanRedo, selectCanUndo } from '../../../reducers/united-canvas/unitedCanvasReducer';

export const UndoRedo = ({ ele }: { ele?: any }) => {
  const dispatch = useDispatch();
  const canUndo = useSelector(selectCanUndo);
  const canRedo = useSelector(selectCanRedo);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e?.metaKey && e?.key === 'z') {
      e.preventDefault();
      if (e.shiftKey) {
        canRedo && dispatch(UndoActionCreators.redo());
      } else {
        canUndo && dispatch(UndoActionCreators.undo());
      }
    }
  };

  useEventListener('keydown', handleKeyDown, ele ?? undefined);

  return null;
};
