import { useCallback } from 'react';
import { useSweepNotifications } from '../components/notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../reducers/notificationsReducer';

export type AddErrorHandlingNotificationType = (
  errorMessage?: string,
  errorName?: string,
) => string;

export const useErrorHandling = () => {
  const { addNotification } = useSweepNotifications();

  const addErrorHandlingNotification: AddErrorHandlingNotificationType = (errorMessage?: string) =>
    addNotification({
      message: errorMessage || 'Error',
      variant: SweepNotificationVariant.Error,
      keepOpen: true,
    });

  class ErrorHandling {
    private _onErrorFn?: Function;
    private _errorName = 'Error';
    private _errorMessage = 'Error';
    private _sendNotification = false;
    private _finally?: Function;

    withOnError(revertFn: (e: unknown, addNotification: AddErrorHandlingNotificationType) => any) {
      this._onErrorFn = revertFn;
      return this;
    }
    withErrorNotification(errorMessage?: string, errorName?: string) {
      this._errorMessage = errorMessage || 'Error';
      this._errorName = errorName || errorMessage || 'Error';
      this._sendNotification = true;
      return this;
    }

    withFinally(fn: () => void) {
      this._finally = fn;
      return this;
    }

    async execute(fn: () => void) {
      try {
        await fn();
      } catch (e) {
        if (this._sendNotification) {
          addErrorHandlingNotification(this._errorMessage, this._errorName);
        }
        if (this._onErrorFn) {
          this._onErrorFn(e, addErrorHandlingNotification);
        }
      } finally {
        this._finally && this._finally();
      }
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const errorHandlingBuilder = useCallback(() => new ErrorHandling(), []);

  return { errorHandlingBuilder };
};
