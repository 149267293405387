import { PluginMeddicSvg } from '../../icons';
import {
  ConnectedBaseCriteriaPanel,
  CustomCriteriaPanelProps,
} from '../../base-criteria-plugin-panel/ConnectedBaseCriteriaPanel';
import { meddicPluginStepMapping } from './pluginData';
import { PluginTypes } from '../../../../../../../types/enums/PluginTypes';

const ConnectedMeddicPanel = (props: CustomCriteriaPanelProps) => (
  <ConnectedBaseCriteriaPanel
    {...props}
    title="MEDDIC Methodology"
    pluginIcon={<PluginMeddicSvg />}
    pluginStepMapping={meddicPluginStepMapping}
    pluginType={PluginTypes.MEDDIC}
  />
);

export { ConnectedMeddicPanel as MeddicPanel };
