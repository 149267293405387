import * as React from "react";
const SvgEnrichLeads = (props) => /* @__PURE__ */ React.createElement("svg", { width: 33, height: 32, viewBox: "0 0 33 32", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M19.1987 10.4102L22.9625 9.43066L21.983 5.66694", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M22.9622 9.43029L20.5905 8.03817C18.5423 6.83284 16.1587 6.32331 13.7966 6.58584C11.4346 6.84837 9.22106 7.86884 7.48743 9.49451", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M9.33357 21.9951C11.6028 21.9951 13.4423 20.1556 13.4423 17.8864C13.4423 15.6172 11.6028 13.7777 9.33357 13.7777C7.06439 13.7777 5.22485 15.6172 5.22485 17.8864C5.22485 20.1556 7.06439 21.9951 9.33357 21.9951Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M3.64441 24.9499C4.28596 24.037 5.1378 23.2918 6.12798 22.7774C7.11815 22.263 8.21758 21.9945 9.3334 21.9945C10.4492 21.9945 11.5486 22.263 12.5388 22.7774C13.529 23.2918 14.3808 24.037 15.0224 24.9499", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M23.3336 21.9951C25.6028 21.9951 27.4423 20.1556 27.4423 17.8864C27.4423 15.6172 25.6028 13.7777 23.3336 13.7777C21.0644 13.7777 19.2249 15.6172 19.2249 17.8864C19.2249 20.1556 21.0644 21.9951 23.3336 21.9951Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M17.6444 24.9499C18.286 24.037 19.1378 23.2918 20.128 22.7774C21.1182 22.263 22.2176 21.9945 23.3334 21.9945C24.4492 21.9945 25.5486 22.263 26.5388 22.7774C27.529 23.2918 28.3808 24.037 29.0224 24.9499", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgEnrichLeads;
