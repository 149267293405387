import { Box, FormControl, MenuItem } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import SweepTextField from '../../../../../common/SweepTextField';
import {
  AttributeProps,
  EditMode,
  FunnelFieldLabels,
  FIELD_LABEL_MAX_CHARS,
  SWEEP_FIELD_NAME,
  textAreaCommonProps,
} from '../../../../../../constants/fieldsManagementConsts';
import TextToggle from '../../../../../common/TextToggle';
import AttributesPerFieldType from '../AttributesPerFieldType';
import SweepSelect from '../../../../../common/SweepSelect';
import {
  ObjectTypesAutocompleteItem,
  ObjectTypesAutocomplete,
} from '../../../../../common/ObjectTypesAutocomplete';
import FieldLabel from '../../../../../common/FieldLabel';
import { getDefaultProps } from '../FieldsValidations';
import { SweepFieldTypes } from '../../../../../../types/enums/SweepFieldTypes';
import useObjectTypesWithFetch from '../../../../../../hooks/useObjectTypesWithFetch';

const isRequiredItems = [
  { value: false, label: 'Optional' },
  { value: true, label: 'Required' },
];

const supportedFieldTypes: SweepFieldTypes[] = [
  SweepFieldTypes.Checkbox,
  SweepFieldTypes.Currency,
  SweepFieldTypes.Date,
  SweepFieldTypes.DateTime,
  SweepFieldTypes.Email,
  SweepFieldTypes.Geolocation,
  SweepFieldTypes.Number,
  SweepFieldTypes.Percent,
  SweepFieldTypes.Phone,
  SweepFieldTypes.Picklist,
  SweepFieldTypes.MultiselectPicklist,
  SweepFieldTypes.Text,
  SweepFieldTypes.TextArea,
  SweepFieldTypes.Time,
  SweepFieldTypes.Url,
];

const fieldTypeOptions = supportedFieldTypes.map((type) => ({
  value: type,
  label: FunnelFieldLabels[type],
}));

interface CreateFieldProps extends AttributeProps {
  crmOrgId: string;
  mode: EditMode;
}

const CreateEditFieldForm = ({
  mode,
  values,
  errors,
  setFieldValue,
  crmOrgId,
  isLeadingField,
}: CreateFieldProps) => {
  const { fieldType, objectName } = values;
  const isEditMode = mode === EditMode.EDIT;
  const { objectTypes, isLoading } = useObjectTypesWithFetch({ crmOrgId });

  return (
    <Box display="flex" gap="32px" height="100%">
      <Box flex={1} display="flex" flexDirection="column" gap="32px">
        <Box>
          <FormControl fullWidth>
            <FieldLabel label="Object" markLabelAsRequired />
            <ObjectTypesAutocomplete
              objectTypes={objectTypes}
              isLoading={isLoading}
              onChange={(event: any, newValue: ObjectTypesAutocompleteItem | null) => {
                setFieldValue('objectName', newValue?.objectType);
              }}
              value={objectName}
              disabled={isEditMode}
              TextFieldProps={{
                helperText: (errors.objectName as string) || '',
                error: Boolean(errors.objectName),
              }}
              objectTypeFilters={{
                updateable: true,
              }}
            />
          </FormControl>
        </Box>
        <Box>
          <SweepTextField
            label="Field label"
            id="sweepFieldName"
            markLabelAsRequired={true}
            value={values[SWEEP_FIELD_NAME]}
            onChange={(e) => {
              setFieldValue(SWEEP_FIELD_NAME, e?.target.value);
            }}
            FormControlProps={{
              fullWidth: true,
            }}
            TextFieldProps={{
              disabled: isEditMode,
              helperText: (errors[SWEEP_FIELD_NAME] as string) || '',
              error: Boolean(errors[SWEEP_FIELD_NAME]),
              inputProps: {
                maxLength: FIELD_LABEL_MAX_CHARS,
              },
            }}
          />
        </Box>
        <Box>
          <SweepTextField
            label="Field description"
            value={values.description}
            onChange={(e) => {
              setFieldValue('description', e?.target.value);
            }}
            disableConfirmPropagation
            FormControlProps={{
              fullWidth: true,
            }}
            TextFieldProps={{
              helperText: (errors.description as string) || '',
              error: Boolean(errors.description),
              placeholder: 'This text will be visible to admins',
              ...textAreaCommonProps,
            }}
          />
        </Box>
        <Box>
          <SweepTextField
            label="Help text"
            value={values.helpText}
            onChange={(e) => {
              setFieldValue('helpText', e?.target.value);
            }}
            disableConfirmPropagation
            FormControlProps={{
              fullWidth: true,
            }}
            TextFieldProps={{
              helperText: (errors.helpText as string) || '',
              error: Boolean(errors.helpText),
              placeholder: 'This text will be visible to all users',
              ...textAreaCommonProps,
            }}
          />
        </Box>
        <Box display="flex" gap="8px" alignItems="center">
          <Typography variant="body">Field is</Typography>
          <TextToggle
            onChange={(item) => {
              setFieldValue('isRequired', item.value);
            }}
            selectedItem={isRequiredItems.find(
              (item) => item.value === (values.isRequired ?? false),
            )}
            items={isRequiredItems}
          />
        </Box>
      </Box>
      <Box flex={1} height="100%">
        <Box
          display="flex"
          flexDirection="column"
          gap="32px"
          sx={{
            backgroundColor: colors.grey[100],
            borderRadius: '16px',
            padding: '24px',
            maxHeight: '100%',
          }}
        >
          <SweepSelect
            label="Field Type"
            markLabelAsRequired
            FormControlProps={{
              fullWidth: true,
            }}
            error={(errors.fieldType as string) || ''}
            SelectProps={{
              placeholder: 'Select type',
              value: fieldType ?? '',
              onChange: (e) => {
                const newFieldType = e.target.value as SweepFieldTypes;
                setFieldValue('fieldType', newFieldType);
                setFieldValue('properties', getDefaultProps(newFieldType));
              },
              disabled: isEditMode,
            }}
            renderValue={(value) => {
              const selectedOption = fieldTypeOptions.find((option) => option.value === value);
              return selectedOption?.label;
            }}
          >
            {fieldTypeOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SweepSelect>
          {fieldType && (
            <AttributesPerFieldType
              setFieldValue={setFieldValue}
              errors={errors}
              values={values}
              mode={mode}
              isLeadingField={isLeadingField}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CreateEditFieldForm;
