import { throttle } from 'lodash';
import { loadState, saveState } from './localStorage';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer, { RootState } from './reducers';
import { isProdEnv } from './environment';
import { canvasLayersInitialState } from './reducers/canvasLayersReducer';

const persistedState = loadState();

export const storeBuilder = (preloadedState?: RootState) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState: preloadedState || persistedState,
    devTools: !isProdEnv,
  });

  store.subscribe(
    throttle(() => {
      // If you want to persist state variables in local storage
      // here is the place:
      const persistedStateOnLocalStorage = {
        debugInfo: store.getState().debugInfo,
        canvasLayersV2: { ...canvasLayersInitialState, ...store.getState().canvasLayersV2 },
        templateFieldsMapping: store.getState().templateFieldsMapping,
      };
      saveState(persistedStateOnLocalStorage);
    }, 1000),
  );

  return { store };
};
