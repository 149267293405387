import { Template } from '../../../../../types/UserInfoTypes';
import { InfoDialog } from '../../../../common/dialogs/InfoDialog';
import { InviteUsersInputWithList } from '../../../../common/invite-users/InviteUsersInputWithList';

export const InvitationsDialog = ({
  funnelMapId,
  funnelMapName,
  isOpen,
  onClose,
}: {
  funnelMapId: string;
  funnelMapName: string;
  isOpen: boolean;
  onClose: (event: any) => void;
}) => {
  return (
    <InfoDialog
      onClick={(event) => {
        event.stopPropagation(); //ugly hack to prevent propagation and redirect to canvas when rendered on Funnel Maps Page
      }}
      handleClose={onClose}
      open={isOpen}
      showCloseButton
      titleJsx={`Invite to Sweep`}
      dialogContentSx={{ height: '530px' }}
      PaperPropsSx={{ width: '530px' }}
    >
      <InviteUsersInputWithList
        funnelMapId={funnelMapId}
        funnelMapName={funnelMapName}
        disableRoleDropdown
        template={Template.Funnel}
        messagePlaceholder={`E.g. Here's the "${funnelMapName}" Funnel in Sweep. Let's collaborate 🌟`}
      />
    </InfoDialog>
  );
};
