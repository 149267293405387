import { PluginCard, PluginCardProps } from '../../../plugins-tab/PluginCard';
import { PluginMeddicSvg } from '../../icons';
import { meddicTexts } from './texts';

export const MeddicPluginCard = (props: Pick<PluginCardProps, 'onClick' | 'onAdd'>) => {
  return (
    <PluginCard
      svgIcon={<PluginMeddicSvg />}
      title={meddicTexts.title}
      description={meddicTexts.description}
      {...props}
    />
  );
};
