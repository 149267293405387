import {
  Automations,
  PlaybookAlert,
  Target,
  Users,
  Reporting,
  Devops,
  Sparkle,
  Hubspot,
} from '@sweep-io/sweep-design/dist/icons';
import { IconProps } from '@sweep-io/sweep-design/dist/icons/common';
import Pave from './logos/pave.svg?react';
import Empathy from './logos/empathy.svg?react';
import Yougov from './logos/yougov.svg?react';
import Anecdote from './logos/anecdote.svg?react';
import Levelai from './logos/levelai.svg?react';
import Deputy from './logos/deputy.svg?react';
import Passport from './logos/passport.svg?react';
import Eleos from './logos/eleos.svg?react';
import AutomationsHorizontalImage from './images/automationsHorizontal.svg?react';
import AutomationsVerticalImage from './images/automationsVertical.svg?react';
import AutomationsVerticalWideImage from './images/automationsVerticalWide.svg?react';
import AlertsHorizontalImage from './images/alertsHorizontal.svg?react';
import AlertsVerticalImage from './images/alertsVertical.svg?react';
import AlertsVerticalWideImage from './images/alertsVerticalWide.svg?react';
import DedupImage from './images/dedup.svg?react';
import AiImage from './images/ai.svg?react';
import CompareImage from './images/compare.svg?react';
import HubspotImage from './images/hubspot.svg?react';
import RoutingHorizontalImage from './images/routingHorizontal.svg?react';
import RoutingVerticalWideImage from './images/routingVerticalWide.svg?react';
import ReportingImage from './images/reporting.svg?react';
import DependenciesVerticalImage from './images/dependenciesVertical.svg?react';
import { AutomationFormVariant } from '../../../../types/enums/AutomationFormVariant';
import { PaywallSource, PaywallItemData } from '../paywallTypes';

export const getPaywallSourceFromAutomationVariant = (variant: AutomationFormVariant) => {
  switch (variant) {
    case AutomationFormVariant.AUTOMATIONS:
      return PaywallSource.AUTOMATIONS;
    case AutomationFormVariant.PLAYBOOK_ALERT:
      return PaywallSource.ALERTS;
    case AutomationFormVariant.ASSIGNMENT_RULES:
      return PaywallSource.ROUTING;
    default:
      return undefined;
  }
};

export const getPaywallBlockData = (source?: PaywallSource): PaywallItemData | undefined => {
  if (!source) {
    return undefined;
  }
  switch (source) {
    case PaywallSource.AUTOMATIONS:
      return {
        Icon: (props) => <Automations {...props} />,
        header: 'Build custom Salesforce automations in minutes',
        subHeader:
          'Create no-code, native Salesforce automations that simplify processes across your team. Contact a Sweep expert to activate your trial.',
        cta: 'Talk to us',
        gainAccessTo: [
          'Customizable native Salesforce automations',
          'Streamlined workflows across your organization',
          'No code tools that save time and bandwidth',
        ],
        quote: {
          text: 'Using Sweep has made managing our Salesforce automations incredibly easy. We saved countless hours every week, and the support was fantastic.',
          quoteBy: 'Nick, Revenue Operations, Pave',
          logo: <Pave />,
        },
        imageHorizontal: <AutomationsHorizontalImage />,
        imageVertical: <AutomationsVerticalImage />,
        imageVerticalWide: <AutomationsVerticalWideImage />,
      };
    case PaywallSource.ALERTS:
      return {
        Icon: (props: IconProps) => <PlaybookAlert {...props} />,
        header: 'Activate your team with custom Salesforce alerts',
        subHeader:
          'Send actionable notifications in Slack or Teams powered by real-time Salesforce data. Contact a Sweep expert to activate your trial.',
        cta: 'Talk to us',
        gainAccessTo: [
          'Building custom Slack or Teams alerts',
          'Updating Salesforce directly from Slack',
          'Streamline communication across cross-functional teams',
        ],
        quote: {
          text: `It's a game changer for staying on top of our Salesforce processes without missing a beat.`,
          quoteBy: 'Matt, Deputy',
          logo: <Deputy />,
        },
        imageHorizontal: <AlertsHorizontalImage />,
        imageVertical: <AlertsVerticalImage />,
        imageVerticalWide: <AlertsVerticalWideImage />,
      };
    case PaywallSource.DEDUP:
      return {
        Icon: (props: IconProps) => <Target {...props} />,
        header: 'Keep your data clean with deduplication and matching tools',
        subHeader:
          'Eliminate duplicate records and keep your data clean. Contact a Sweep expert to activate your trial.',
        cta: 'Talk to us',
        gainAccessTo: [
          'Easy-to-use deduplication tools',
          'Intuitive lead-to-account matching',
          'Better data hygiene',
        ],
        imageHorizontal: <DedupImage />,
        quote: {
          text: `“While I am comfortable building in the Salesforce UI, Sweep has enabled me to move and ship features much, much faster.`,
          quoteBy: 'Emma, Revenue Operations Manager, Passport',
          logo: <Passport />,
        },
      };
    case PaywallSource.ROUTING:
      return {
        Icon: (props: IconProps) => <Users {...props} />,
        header: 'Speed up your pipeline with lead routing that works',
        subHeader:
          'Easily create and manage groups, territories and assignment rules for any Salesforce object. Contact a Sweep expert to activate your trial.',
        cta: 'Talk to us',
        gainAccessTo: [
          'Native routing for any object',
          'Easy-to-create customizable assignment rules',
          'Optimize distribution to reps',
        ],
        imageHorizontal: <RoutingHorizontalImage />,
        imageVerticalWide: <RoutingVerticalWideImage />,
        quote: {
          text: `Sweep simplifies lead routing and assignment logic, the automation features are a serious productivity boost, and the Slack integration keeps the whole team in sync, real-time.`,
          quoteBy: 'Alex, Revenue Operations, YouGov',
          logo: <Yougov />,
        },
      };
    case PaywallSource.REPORTS:
      return {
        Icon: (props: IconProps) => <Reporting {...props} />,
        header: 'Access pipeline insights, instantly',
        subHeader:
          'Easily generate reports and dashboards using real-time Salesforce data. Contact a Sweep expert to activate your trial.',
        cta: 'Talk to us',
        gainAccessTo: [
          'Identifying bottlenecks and opportunities for optimization',
          'Tracking key metrics and conversion rates',
          'Creating custom Salesforce reports',
        ],
        imageHorizontal: <ReportingImage />,
        quote: {
          text: `The BI architecture in Sweep allowed us to generate reports and access key insights almost immediately.`,
          quoteBy: 'Tom, Revenue Operations, Anecdotes',
          logo: <Anecdote />,
        },
      };
    case PaywallSource.DEVOPS_CENTER:
      return {
        Icon: (props: IconProps) => <Devops {...props} />,
        header: 'Seamlessly review and deploy changes between any environment',
        subHeader: `Use the Compare & Deploy center to see what has changed between environments with a clear side-by-side view reflecting your exact configuration. Contact a Sweep expert to activate your trial.`,
        cta: 'Talk to us',
        gainAccessTo: [
          'Seeing every change between environments in a single view',
          'Testing any dedupe or matching logic',
          'Syncing deployment statuses',
        ],
        imageHorizontal: <CompareImage />,
        quote: {
          text: `The product is incredibly simple to use and I'm able to do the work of a team of 3 as a team of 1, which has saved me so much admin work time, that I'm actually able to do my job as a RevOps leader rather than just an admin.`,
          quoteBy: 'Matt, Revenue Operations , Level.ai',
          logo: <Levelai />,
        },
      };
    case PaywallSource.HUBSPOT:
      return {
        Icon: (props: IconProps) => <Hubspot {...props} />,
        header: 'Simplify Campaign Workflows Between HubSpot and Salesforce',
        subHeader: `Visualize and manage campaign workflows across platforms, ensuring both HubSpot and Salesforce stay in sync for full marketing attribution.`,
        cta: 'Talk to us',
        gainAccessTo: [
          'Visualize HubSpot workflows and processes',
          'Automate cross-platform campaign enrollment',
          'Centralize attribution reporting across both systems',
        ],
        imageHorizontal: <HubspotImage />,
        quote: {
          text: `We've saved so much time and money. We're able to manage our very complex Salesforce configuration internally. We couldn't be happier!`,
          quoteBy: 'Ron, Empathy',
          logo: <Empathy />,
        },
      };
    case PaywallSource.AI_ASSISTANT:
      return {
        Icon: (props: IconProps) => <Sparkle {...props} />,
        header: 'Transform Salesforce Support with AI',
        subHeader:
          'Respond faster, spend less time on support, and give end users a smoother experience. Automate routine tasks and provide real-time answers directly within Salesforce.',
        cta: 'Talk to us',
        gainAccessTo: [
          'Dedicated AI Agent for end user support in Salesforce',
          'Reporting and insights to quantify productivity increase',
          'Built-in integration with your ticketing system',
        ],
        imageHorizontal: <AiImage />,
        quote: {
          text: `With Sweep’s AI Agent, we significantly reduced time spent on support and provided faster, more accurate responses directly in Salesforce`,
          quoteBy: 'Elizabeth, Eleos Health',
          logo: <Eleos />,
        },
      };
    case PaywallSource.DEPENDENCIES:
      return {
        header: `See what's been built in Salesforce, instantly.`,
        subHeader: (
          <>
            Our visual workspace creates a custom map of your configuration - including custom
            objects! - that you can keep on hand, all the time. <br />
            <br />
            Contact a Sweep team member to activate your free trial.
          </>
        ),
        cta: 'Talk to us',
        imageVertical: <DependenciesVerticalImage />,
      };
    default:
      return undefined;
  }
};
