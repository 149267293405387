import { Box } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { DedupMatchingType } from '../../types/enums/DedupMatchingType';
import usePermission from '../common/permissions/usePermission';
import RestrictedTooltip from '../common/permissions/RestrictedTooltip';
import { useCallback, useState } from 'react';
import { PageTemplateCanvasDialog } from '../pages/pages-templates';
import { DedupTemplateContent } from '../pages/canvas-pages/dedup-and-matching/DedupTemplateContent';
import { useAutomationsContext } from './AutomationsContext';

interface AddDedupMatchingButtonProps {
  onSelect: (object: string, type?: DedupMatchingType) => void;
  disabled: boolean;
  buttonText: string;
  startIconName: string;
}

export const AddDedupMatchingButton = ({
  onSelect,
  disabled,
  buttonText,
  startIconName,
}: AddDedupMatchingButtonProps) => {
  const { permissions } = useAutomationsContext();
  const [isAllowedAddButton] = usePermission([permissions.create]);
  const [isTemplatesOpen, setIsTemplatesOpen] = useState(false);

  const onButtonClick = useCallback(() => {
    setIsTemplatesOpen(true);
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <RestrictedTooltip
        to={[permissions.create]}
        notAllowedTitle={`To create a new deduplication or matching, please contact your admin.`}
      >
        <Button
          disabled={!isAllowedAddButton || disabled}
          startIconName={startIconName}
          size="small"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      </RestrictedTooltip>

      <PageTemplateCanvasDialog
        isOpen={isTemplatesOpen}
        title={'Get started with Dedupe & Matching'}
        onCancel={() => setIsTemplatesOpen(false)}
      >
        <DedupTemplateContent onSelect={onSelect} />
      </PageTemplateCanvasDialog>
    </Box>
  );
};
