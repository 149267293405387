import * as React from "react";
const SvgAuthority = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M10.1854 5.0212C9.88986 5.0212 9.62697 5.20676 9.52841 5.48493L5.07886 18.0426C5.00288 18.257 5.03615 18.4955 5.16805 18.6818C5.29994 18.868 5.51396 18.9789 5.74175 18.9789H8.55824C8.85954 18.9789 9.12622 18.7861 9.22029 18.5002L10.1254 15.7498H13.9062L14.8347 18.5002C14.9312 18.7861 15.1995 18.9789 15.5008 18.9789H18.3173C18.5449 18.9789 18.7579 18.8682 18.8882 18.6821C19.0185 18.496 19.05 18.2578 18.9725 18.0435L14.4344 5.48576C14.3338 5.20717 14.0691 5.0212 13.7732 5.0212H10.1854ZM11.3702 11.9997L11.9972 10.1228L12.6332 11.9997H11.3702Z", fill: "#9462FF", stroke: "white", style: {
  fill: "#9462FF",
  fill: "color(display-p3 0.5807 0.3833 1.0000)",
  fillOpacity: 1,
  stroke: "white",
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.4, strokeLinejoin: "round" }));
export default SvgAuthority;
