import { AUTOMATIONS_LAYOUT_MAX_WIDTH } from '../../Automations/helper';
import { Dialog, DialogProps } from '@mui/material';
import { DASHBOARD_HEADER_HEIGHT_PX } from '../../../constants';
import { useEffect, useState } from 'react';

const MARGIN = '32px';

interface DialogOnCanvasProps extends DialogProps {
  /**
   * If true, the dialog will block the whole canvas.
   * If false, the dialog will block only the right side of the canvas. (default)
   * allowing the user to interact with the left side of the canvas.
   * @default false
   * @type boolean
   **/
  blockFully?: boolean;

  /**
   * If true, the dialog will force the dialog to be full screen.
   * @default false
   * @type boolean
   **/
  forceWideScreen?: boolean;
}

const DialogOnCanvas = ({
  children,
  blockFully,
  forceWideScreen,
  ...dialogProps
}: DialogOnCanvasProps) => {
  const { sx, PaperProps, ...rest } = dialogProps;

  // THIS hack will avoid a bunch of props drilling.
  // It basically checks if the sidebar is open and if it is,
  // it will calculate the width of the sidebar and pass it to the Dialog component.
  const ref = window.document.getElementById('sidebar-menu');
  const leftPanelRef = window.document.getElementById('configuration-canvas-left-panel');
  const [leftPanelWidth, setLeftPanelWidth] = useState(leftPanelRef?.clientWidth ?? 0);
  const sidebarMenuWidth = ref?.clientWidth ?? 0;

  //leftPanelWidth changes between expanded/collapsed modes so it has to be observed
  useEffect(() => {
    if (leftPanelRef) {
      const observer = new ResizeObserver(() => {
        if (leftPanelRef.clientWidth) {
          setLeftPanelWidth(leftPanelRef.clientWidth);
        }
      });
      observer.observe(leftPanelRef);
    }
  }, [leftPanelRef]);

  const leftPadding = sidebarMenuWidth + leftPanelWidth;

  const blockPartiallySx: DialogProps['sx'] = {
    zIndex: '1200',
    maxWidth: `calc(100% - ${sidebarMenuWidth}px - ${leftPanelWidth}px)`,
    ml: 'auto',
    marginTop: DASHBOARD_HEADER_HEIGHT_PX,
    '.MuiBackdrop-root.MuiModal-backdrop': {
      maxWidth: `calc(100% - ${sidebarMenuWidth}px - ${leftPanelWidth}px)`,
      ml: 'auto',
      top: DASHBOARD_HEADER_HEIGHT_PX,
    },
  };

  const blockFullySx: DialogProps['sx'] = {
    ml: 'auto',
    '.MuiBackdrop-root.MuiModal-backdrop': {
      ml: 'auto',
      opacity: 0,
      backgroundColor: 'transparent',
      '::after': {
        content: '""',
        position: 'absolute',
        top: DASHBOARD_HEADER_HEIGHT_PX,
        left: leftPadding,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        maxWidth: `calc(100% - ${sidebarMenuWidth}px - ${leftPanelWidth}px)`,
      },
    },
    '.MuiDialog-container': {
      marginLeft: leftPadding + 'px',
      marginTop: DASHBOARD_HEADER_HEIGHT_PX,
      height: `calc(100% - ${DASHBOARD_HEADER_HEIGHT_PX})`,
    },
  };

  const automationsSharedStyles = {
    width: `calc(100% - ${MARGIN})`,
    height: `calc(100% - ${MARGIN})`,
    maxHeight: `calc(100% - ${MARGIN})`,
    maxWidth: AUTOMATIONS_LAYOUT_MAX_WIDTH,
  };

  const blockSx = blockFully ? blockFullySx : blockPartiallySx;
  const _blockSx = forceWideScreen ? { top: DASHBOARD_HEADER_HEIGHT_PX } : blockSx;

  return (
    <Dialog
      fullScreen={false}
      maxWidth={false}
      sx={{
        ..._blockSx,
        ...sx,
      }}
      PaperProps={{
        ...PaperProps,
        sx: { ...automationsSharedStyles, margin: 0, ...PaperProps?.sx },
      }}
      {...rest}
    >
      {children}
    </Dialog>
  );
};

export default DialogOnCanvas;
