import { useMemo } from 'react';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { isCPQObject } from '../cpq/utils';
import { SelectedObjectListElements } from '../types';
import { ExpandedObjectContent } from './expanded-mode/ExpandedObjectContent';
import { useSelector } from 'react-redux';
import { selectDocumentationActiveObjectData } from '../../../reducers/documentationReducer';

interface UseSelectedObjectTabsStructureProps {
  crmOrgId: string;
  objectTypeName: ObjectTypeName;
}

export const useSelectedObjectTabsStructure = ({
  crmOrgId,
  objectTypeName,
}: UseSelectedObjectTabsStructureProps) => {
  const allElements = useSelector(selectDocumentationActiveObjectData);

  const sfElements = useMemo(() => {
    const content = <ExpandedObjectContent crmOrgId={crmOrgId} />;

    const objs = [
      {
        label: 'Fields',
        value: DocumentationTabTypes.FIELDS,
        badgeLabel: allElements?.[DocumentationTabTypes.FIELDS]?.length ?? 0,
        elements: allElements?.[DocumentationTabTypes.FIELDS] ?? [],
        content,
      },
      {
        label: 'Salesforce Automations',
        value: DocumentationTabTypes.SF_AUTOMATIONS,
        badgeLabel: allElements?.[DocumentationTabTypes.SF_AUTOMATIONS]?.length ?? 0,
        elements: allElements?.[DocumentationTabTypes.SF_AUTOMATIONS] ?? [],
        content,
      },
      {
        label: 'Apex',
        value: DocumentationTabTypes.APEX,
        badgeLabel: allElements?.[DocumentationTabTypes.APEX]?.length ?? 0,
        elements: allElements?.[DocumentationTabTypes.APEX] ?? [],
        content,
      },
      {
        label: 'Validation Rules',
        elements: allElements?.[DocumentationTabTypes.VALIDATION_RULES] ?? [],
        value: DocumentationTabTypes.VALIDATION_RULES,
        badgeLabel: allElements?.[DocumentationTabTypes.VALIDATION_RULES]?.length ?? 0,
        content,
      },
      {
        label: 'Layouts',
        elements: allElements?.[DocumentationTabTypes.LAYOUTS] ?? [],
        value: DocumentationTabTypes.LAYOUTS,
        badgeLabel: allElements?.[DocumentationTabTypes.LAYOUTS]?.length ?? 0,
        content,
      },
      {
        label: 'Record Types',
        elements: allElements?.[DocumentationTabTypes.RECORD_TYPES] ?? [],
        value: DocumentationTabTypes.RECORD_TYPES,
        badgeLabel:
          allElements?.[DocumentationTabTypes.RECORD_TYPES]?.filter((item) => item.isActive)
            .length ?? 0,
        content,
      },
    ] as SelectedObjectListElements;

    if (isCPQObject(objectTypeName)) {
      objs.push({
        label: 'CPQ Configurations',
        elements: allElements?.[DocumentationTabTypes.CPQ_DATA] ?? [],
        value: DocumentationTabTypes.CPQ_DATA,
        badgeLabel: allElements?.[DocumentationTabTypes.CPQ_DATA]?.length ?? 0,
        content,
      });
    }

    return objs;
  }, [allElements, objectTypeName, crmOrgId]);

  const sweepElements = useMemo(() => {
    const content = <ExpandedObjectContent crmOrgId={crmOrgId} />;

    return [
      {
        label: 'Sweep Automations',
        elements: allElements?.[DocumentationTabTypes.SWEEP_AUTOMATIONS] ?? [],
        value: DocumentationTabTypes.SWEEP_AUTOMATIONS,
        badgeLabel: allElements?.[DocumentationTabTypes.SWEEP_AUTOMATIONS]?.length ?? 0,
        content,
      },
      {
        label: 'Alerts',
        elements: allElements?.[DocumentationTabTypes.PLAYBOOK_ALERTS] ?? [],
        value: DocumentationTabTypes.PLAYBOOK_ALERTS,
        badgeLabel: allElements?.[DocumentationTabTypes.PLAYBOOK_ALERTS]?.length ?? 0,
        content,
      },
      {
        label: 'Assignment Rules',
        elements: allElements?.[DocumentationTabTypes.ASSIGNMENTS] ?? [],
        value: DocumentationTabTypes.ASSIGNMENTS,
        badgeLabel: allElements?.[DocumentationTabTypes.ASSIGNMENTS]?.length ?? 0,
        content,
      },
      {
        label: 'Scheduled Assignment Rules',
        elements: allElements?.[DocumentationTabTypes.SCHEDULED_ASSIGNMENTS] ?? [],
        value: DocumentationTabTypes.SCHEDULED_ASSIGNMENTS,
        badgeLabel: allElements?.[DocumentationTabTypes.ASSIGNMENTS]?.length ?? 0,
        content,
      },
      {
        label: 'Dedupe & Matching',
        elements: allElements?.[DocumentationTabTypes.MATCHING_DEDUPE] ?? [],
        value: DocumentationTabTypes.MATCHING_DEDUPE,
        badgeLabel: allElements?.[DocumentationTabTypes.MATCHING_DEDUPE]?.length ?? 0,
        content,
      },
    ] as SelectedObjectListElements;
  }, [allElements, crmOrgId]);

  return {
    sfElements,
    sweepElements,
  };
};
