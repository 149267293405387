import { uniqueId } from '../lib/uniqueId';
import { AutomationActionsModel } from './AutomationsActionsModel';

export const isEmptySweepCriterion = (entry: SweepCriterion) => {
  return !Boolean(entry.fieldType || entry.operator || entry.value);
};

export class AutomationsConditionalActionModelDeprecated {
  private _conditionalAction: AutomationConditionalAction;

  constructor(conditionalAction: AutomationConditionalAction) {
    this._conditionalAction = conditionalAction;
  }

  public get actions() {
    return new AutomationActionsModel(this._conditionalAction.actions);
  }

  public get conditionalAction() {
    return this._conditionalAction;
  }

  public hasOnlyOneCriteriaAndItIsEmpty() {
    if (this._conditionalAction.when?.criteria?.criteria) {
      if (
        this._conditionalAction.when.criteria.criteria.length === 1 &&
        isEmptySweepCriterion(this._conditionalAction.when.criteria.criteria[0])
      ) {
        return true;
      }
    }
    return false;
  }

  public sanitizeConditionalAction() {
    if (this.hasOnlyOneCriteriaAndItIsEmpty()) {
      if (this._conditionalAction.when?.criteria?.criteria) {
        this._conditionalAction.when.criteria = {
          criteria: [],
          criteriaLogic: '',
        };
      }
    }
  }

  static newAutomationConditionalAction(
    partialAutomationConditionalAction: Partial<AutomationConditionalAction> = {},
  ) {
    const { _id, _name, actions, when: condition } = partialAutomationConditionalAction;

    return new AutomationsConditionalActionModelDeprecated({
      _id: _id || uniqueId(),
      _name: _name || '',
      actions: actions || [],
      when: condition,
    });
  }
}

export class MultipleAutomationsConditionalActionsModelDeprecated {
  private _conditionalActions: AutomationConditionalAction[];

  constructor(conditionalActions: AutomationConditionalAction[]) {
    this._conditionalActions = conditionalActions;
  }

  public conditionalActionById(id: string) {
    const conditionalAction = this._conditionalActions?.find((action) => action._id === id);
    if (conditionalAction) {
      return new AutomationsConditionalActionModelDeprecated(conditionalAction);
    }
  }

  public upsertConditionalAction(conditionalAction: AutomationConditionalAction) {
    this._conditionalActions = this._conditionalActions || [];

    const index = this._conditionalActions.findIndex(
      (action) => action._id === conditionalAction._id,
    );
    if (index >= 0) {
      this._conditionalActions[index] = conditionalAction;
    } else {
      this._conditionalActions.push(conditionalAction);
    }
  }
}
