import { ButtonBase } from '@mui/material';
import { ReactNode } from 'react';
import { IconButton, Typography, colors } from '@sweep-io/sweep-design';
import { ChevronDown } from '@sweep-io/sweep-design/dist/icons';
import { IconButtonSize } from '@sweep-io/sweep-design/dist/components/IconButton/types';

export const ExpandButtonSimple = ({
  children,
  isExpanded,
  onClick,
  buttonSize = 'small',
}: {
  children?: ReactNode;
  isExpanded: boolean;
  onClick?: () => void;
  buttonSize?: IconButtonSize;
}) => (
  <ButtonBase
    disableRipple
    onClick={() => onClick && onClick()}
    sx={{
      svg: {
        transform: isExpanded ? 'rotateZ(-180deg)' : 'none',
        transition: 'transform 200ms',

        color: colors.grey[800],
      },
      '&:hover': {
        color: colors.blue[500],
      },
    }}
  >
    {children && <Typography variant="body">{children}</Typography>}
    <IconButton size={buttonSize} variant="flat">
      <ChevronDown />
    </IconButton>
  </ButtonBase>
);
