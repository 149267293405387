import { HsFilterOperatorsOperators, HsPropertyTypes } from '../hubspot.types';

const hubspotOperatorLabels: {
  [_operator in HsFilterOperatorsOperators]: string;
} = {
  [HsFilterOperatorsOperators.IS_KNOWN]: 'Is empty',
  [HsFilterOperatorsOperators.IS_UNKNOWN]: 'Is not empty',
  [HsFilterOperatorsOperators.IS_EQUAL_TO]: 'Is',
  [HsFilterOperatorsOperators.IS_NOT_EQUAL_TO]: 'Is not',
  [HsFilterOperatorsOperators.CONTAINS]: 'Contains',
  [HsFilterOperatorsOperators.DOES_NOT_CONTAIN]: 'Does not contain',
  [HsFilterOperatorsOperators.STARTS_WITH]: 'Starts with',
  [HsFilterOperatorsOperators.ENDS_WITH]: 'Ends with',
  [HsFilterOperatorsOperators.HAS_EVER_BEEN_EQUAL_TO]: 'Has ever been equal to',
  [HsFilterOperatorsOperators.HAS_NEVER_BEEN_EQUAL_TO]: 'Has never been equal to',
  [HsFilterOperatorsOperators.HAS_EVER_CONTAINED]: 'Has ever contained',
  [HsFilterOperatorsOperators.HAS_NEVER_CONTAINED]: 'Has never contained',
  [HsFilterOperatorsOperators.IS_BETWEEN]: 'Is between',
  [HsFilterOperatorsOperators.IS_NOT_BETWEEN]: 'Is not between',
  [HsFilterOperatorsOperators.HAS_EVER_BEEN_ANY_OF]: 'Has ever been any of',
  [HsFilterOperatorsOperators.HAS_NEVER_BEEN_ANY_OF]: 'Has never been any of',
  [HsFilterOperatorsOperators.IS_ON_DATE]: 'Is on date',
  [HsFilterOperatorsOperators.IS_BEFORE_DATE]: 'Is before date',
  [HsFilterOperatorsOperators.IS_AFTER_DATE]: 'Is after date',
  [HsFilterOperatorsOperators.IS_BEFORE]: 'Before',
  [HsFilterOperatorsOperators.IS_AFTER]: 'After',
  [HsFilterOperatorsOperators.IS_LESS_THAN_X_DAYS_AGO]: 'Is less than X days from today',
  [HsFilterOperatorsOperators.IS_MORE_THAN_X_DAYS_AGO]: 'Is more than X days from today',
  [HsFilterOperatorsOperators.IS_LESS_THAN_X_DAYS_FROM_NOW]: 'Is in the last X days',
  [HsFilterOperatorsOperators.IS_MORE_THAN_X_DAYS_FROM_NOW]: 'Is in the next X days',
  [HsFilterOperatorsOperators.UPDATED_IN_LAST_X_DAYS]: 'Updated in last X days',
  [HsFilterOperatorsOperators.NOT_UPDATED_IN_LAST_X_DAYS]: 'Not updated in last X days',
  [HsFilterOperatorsOperators.IS_GREATER_THAN]: 'Greater than',
  [HsFilterOperatorsOperators.IS_GREATER_THAN_OR_EQUAL_TO]: 'Greater than or equal',
  [HsFilterOperatorsOperators.IS_LESS_THAN]: 'Less than',
  [HsFilterOperatorsOperators.IS_LESS_THAN_OR_EQUAL_TO]: 'Less than or equal',
  [HsFilterOperatorsOperators.IS_NONE_OF]: 'Is none of',
  [HsFilterOperatorsOperators.IS_ANY_OF]: 'Is any of',
  [HsFilterOperatorsOperators.IS_EXACTLY]: 'Exactly',
  [HsFilterOperatorsOperators.IS_NOT_EXACTLY]: 'Not exactly',
  [HsFilterOperatorsOperators.CONTAINS_ALL]: 'Contains all',
  [HsFilterOperatorsOperators.DOES_NOT_CONTAIN_ALL]: 'Does nor contain all',
  [HsFilterOperatorsOperators.HAS_EVER_BEEN_EXACTLY]: 'Has ever been exactly',
  [HsFilterOperatorsOperators.HAS_NEVER_BEEN_EXACTLY]: 'Has never been exactly',
  [HsFilterOperatorsOperators.HAS_EVER_CONTAINED_ALL]: 'Has ever contained all',
  [HsFilterOperatorsOperators.HAS_NEVER_CONTAINED_ALL]: 'Has never contained all',
};

const hubspotFieldTypeOperators: {
  [_type in HsPropertyTypes]: HsFilterOperatorsOperators[];
} = {
  [HsPropertyTypes.STRING]: [
    HsFilterOperatorsOperators.IS_KNOWN,
    HsFilterOperatorsOperators.IS_UNKNOWN,
    HsFilterOperatorsOperators.IS_EQUAL_TO,
    HsFilterOperatorsOperators.IS_NOT_EQUAL_TO,
    HsFilterOperatorsOperators.CONTAINS,
    HsFilterOperatorsOperators.DOES_NOT_CONTAIN,
    HsFilterOperatorsOperators.STARTS_WITH,
    HsFilterOperatorsOperators.ENDS_WITH,
  ],

  [HsPropertyTypes.MULTISTRING]: [
    HsFilterOperatorsOperators.IS_KNOWN,
    HsFilterOperatorsOperators.IS_UNKNOWN,
    HsFilterOperatorsOperators.IS_EQUAL_TO,
    HsFilterOperatorsOperators.IS_NOT_EQUAL_TO,
  ],

  [HsPropertyTypes.NUMBER]: [
    HsFilterOperatorsOperators.IS_KNOWN,
    HsFilterOperatorsOperators.IS_UNKNOWN,
    HsFilterOperatorsOperators.IS_EQUAL_TO,
    HsFilterOperatorsOperators.IS_NOT_EQUAL_TO,
    HsFilterOperatorsOperators.HAS_EVER_BEEN_EQUAL_TO,
    HsFilterOperatorsOperators.HAS_NEVER_BEEN_EQUAL_TO,

    HsFilterOperatorsOperators.IS_GREATER_THAN,
    HsFilterOperatorsOperators.IS_GREATER_THAN_OR_EQUAL_TO,
    HsFilterOperatorsOperators.IS_LESS_THAN,
    HsFilterOperatorsOperators.IS_LESS_THAN_OR_EQUAL_TO,
  ],
  [HsPropertyTypes.BOOL]: [
    HsFilterOperatorsOperators.IS_KNOWN,
    HsFilterOperatorsOperators.IS_UNKNOWN,
    HsFilterOperatorsOperators.IS_EQUAL_TO,
    HsFilterOperatorsOperators.IS_NOT_EQUAL_TO,
    HsFilterOperatorsOperators.HAS_EVER_BEEN_EQUAL_TO,
    HsFilterOperatorsOperators.HAS_NEVER_BEEN_EQUAL_TO,
  ],
  [HsPropertyTypes.ENUMERATION]: [
    HsFilterOperatorsOperators.IS_KNOWN,
    HsFilterOperatorsOperators.IS_UNKNOWN,
    HsFilterOperatorsOperators.IS_NONE_OF,
    HsFilterOperatorsOperators.IS_ANY_OF,
    HsFilterOperatorsOperators.IS_EXACTLY,
    HsFilterOperatorsOperators.IS_NOT_EXACTLY,
    HsFilterOperatorsOperators.CONTAINS_ALL,
    HsFilterOperatorsOperators.DOES_NOT_CONTAIN_ALL,
    HsFilterOperatorsOperators.HAS_EVER_BEEN_ANY_OF,
    HsFilterOperatorsOperators.HAS_NEVER_BEEN_ANY_OF,
    HsFilterOperatorsOperators.HAS_EVER_BEEN_EXACTLY,
    HsFilterOperatorsOperators.HAS_NEVER_BEEN_EXACTLY,
    HsFilterOperatorsOperators.HAS_EVER_CONTAINED_ALL,
    HsFilterOperatorsOperators.HAS_NEVER_CONTAINED_ALL,
  ],
  [HsPropertyTypes.DATE_TIME]: [
    HsFilterOperatorsOperators.IS_KNOWN,
    HsFilterOperatorsOperators.IS_UNKNOWN,
    HsFilterOperatorsOperators.IS_BEFORE,
    HsFilterOperatorsOperators.IS_AFTER,
  ],
  [HsPropertyTypes.DATE]: [
    HsFilterOperatorsOperators.IS_KNOWN,
    HsFilterOperatorsOperators.IS_UNKNOWN,
    HsFilterOperatorsOperators.IS_BEFORE,
    HsFilterOperatorsOperators.IS_AFTER,
  ],
  // [HsPropertyTypes.PHONE_NUMBER]: [
  //   HsFilterOperatorsOperators.IS_KNOWN,
  //   HsFilterOperatorsOperators.IS_UNKNOWN,
  //   HsFilterOperatorsOperators.IS_EQUAL_TO,
  //   HsFilterOperatorsOperators.IS_NOT_EQUAL_TO,
  //   HsFilterOperatorsOperators.CONTAINS,
  //   HsFilterOperatorsOperators.DOES_NOT_CONTAIN,
  //   HsFilterOperatorsOperators.STARTS_WITH,
  //   HsFilterOperatorsOperators.ENDS_WITH,
  //   HsFilterOperatorsOperators.HAS_EVER_BEEN_EQUAL_TO,
  //   HsFilterOperatorsOperators.HAS_NEVER_BEEN_EQUAL_TO,
  //   HsFilterOperatorsOperators.UPDATED_IN_LAST_X_DAYS,
  //   HsFilterOperatorsOperators.NOT_UPDATED_IN_LAST_X_DAYS,
  // ],
  // [HsPropertyTypes.OBJECT_COORDINATES]: [
  //   HsFilterOperatorsOperators.IS_KNOWN,
  //   HsFilterOperatorsOperators.IS_UNKNOWN,
  //   HsFilterOperatorsOperators.IS_EQUAL_TO,
  //   HsFilterOperatorsOperators.IS_NOT_EQUAL_TO,
  //   HsFilterOperatorsOperators.UPDATED_IN_LAST_X_DAYS,
  //   HsFilterOperatorsOperators.NOT_UPDATED_IN_LAST_X_DAYS,
  // ],
  // [HsPropertyTypes.JSON]: [
  //   HsFilterOperatorsOperators.IS_KNOWN,
  //   HsFilterOperatorsOperators.IS_UNKNOWN,
  //   HsFilterOperatorsOperators.IS_EQUAL_TO,
  //   HsFilterOperatorsOperators.IS_NOT_EQUAL_TO,
  //   HsFilterOperatorsOperators.UPDATED_IN_LAST_X_DAYS,
  //   HsFilterOperatorsOperators.NOT_UPDATED_IN_LAST_X_DAYS,
  // ],
};

interface LabeledOperator {
  label: string;
  operator: HsFilterOperatorsOperators;
}

export const labeledOperator = (operator: HsFilterOperatorsOperators): LabeledOperator => ({
  label: hubspotOperatorLabels[operator],
  operator,
});

export const labeledOperatorsFromType = (hsPropertyType: HsPropertyTypes): LabeledOperator[] => {
  return hubspotFieldTypeOperators[hsPropertyType].map(labeledOperator);
};

export const labeledOperators = (operators: HsFilterOperatorsOperators[]): LabeledOperator[] => {
  return operators.map(labeledOperator);
};
