import { Box, MenuItem, Skeleton, Stack } from '@mui/material';
import { InfoDialog } from '../common/dialogs/InfoDialog';
import { Typography } from '@sweep-io/sweep-design';
import { useSelector } from 'react-redux';
import {
  selectDefaultCreationEnvironment,
  selectProductionCrmOrg,
} from '../pages/environments/environmentsReducer';
import { EnvironmentTypes } from '../pages/environments/EnvironmentTypeEnum';
import diffStyles from '../pages/devops-center/DiffDialog/styles';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';
import { useState } from 'react';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { formatDedupMatchingDiff } from '../pages/devops-center/DiffDialog/dedupMatchingFormatter';
import { formatAutomationDiff } from '../pages/devops-center/DiffDialog/automationsFormatter';
import { useRunOnce } from '../common/useRunOnce';
import { CreateEditDialogDeployButtons } from '../common/create-edit-dialog/CreateEditDialogDeployButtons';
import SweepSelect from '../common/SweepSelect';
import { telemetry } from '../../telemetry';

interface AutomationsDiffDialogProps {
  readonly?: boolean;
  onCloseDialog: () => void;
  automationItem: DeepPartial<AutomationStructureNew>;
  onDeployOrSave?: (
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  buttonDisabled?: boolean;
  hideDeployButtons?: boolean;
}
const getElementRep = (repElement: AutomationStructureNew) => {
  let originText = '';
  if (repElement.type === 'Dedupe' || repElement.type === 'Matching') {
    originText = formatDedupMatchingDiff(repElement.readVersion as any, repElement.type);
  } else {
    originText = formatAutomationDiff(repElement?.readVersion as ReadOnlyAutomation);
  }
  return originText;
};
export const AutomationsDiffDialog = ({
  onCloseDialog,
  automationItem,
  onDeployOrSave,
  buttonDisabled,
  hideDeployButtons,
}: AutomationsDiffDialogProps) => {
  const { get_automation, create_read_only_automation } = useAutomationsApiFacade();

  const productionEnvironment = useSelector(selectProductionCrmOrg);
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const defaultIsProd = defaultCreationEnvironment?.id === productionEnvironment?.id;
  const showOnlyDefault = defaultIsProd || !productionEnvironment;
  const [selectedEnv, setSelectedEnv] = useState(
    defaultIsProd ? EnvironmentTypes.Production : EnvironmentTypes.Sandbox,
  );

  const [originRepresentation, setOriginRepresentation] = useState('');
  const [targetSandboxRepresentation, setTargetSandboxRepresentation] = useState('');
  const [targetProdRepresentation, setTargetProdRepresentation] = useState('');

  useRunOnce(() => {
    Promise.allSettled([
      create_read_only_automation(automationItem as AutomationStructureNew),
      get_automation(automationItem.automationId ?? '', defaultCreationEnvironment?.id, true),
      get_automation(automationItem.automationId ?? '', productionEnvironment?.id, true),
    ])
      .then(([originElementRes, targetSandboxElementRes, targetProdElementRes]) => {
        if (originElementRes.status === 'fulfilled') {
          const _val = originElementRes.value;
          if (_val) {
            setOriginRepresentation(getElementRep(_val));
          }
        }
        if (targetSandboxElementRes.status === 'fulfilled') {
          const _val = targetSandboxElementRes.value;
          if (_val) {
            setTargetSandboxRepresentation(getElementRep(_val));
          }
        }
        if (targetProdElementRes.status === 'fulfilled') {
          const _val = targetProdElementRes.value;
          if (_val) {
            setTargetProdRepresentation(getElementRep(_val));
          }
        }
      })
      .catch((err) => {
        telemetry.captureError(err);
      });
  });

  const isShowLoading =
    !originRepresentation?.length &&
    !targetSandboxRepresentation?.length &&
    !targetProdRepresentation?.length;

  const getTargetRepresentation =
    selectedEnv === EnvironmentTypes.Production
      ? targetProdRepresentation
      : targetSandboxRepresentation;

  const renderDiffContent = (str: string) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: str,
        }}
      />
    );
  };

  const getRightElement = showOnlyDefault ? (
    defaultCreationEnvironment?.name
  ) : (
    <SweepSelect
      removeBorders={true}
      SelectProps={{
        value: selectedEnv,
        onChange: (e) => setSelectedEnv(e.target.value as EnvironmentTypes),
        sx: {
          '& .MuiSelect-select': {
            padding: '0 32px 0 0',
            '& > span': {
              whiteSpace: 'break-spaces',
              fontWeight: '600',
            },
          },
        },
      }}
    >
      <MenuItem key={EnvironmentTypes.Sandbox} value={EnvironmentTypes.Sandbox}>
        <Typography variant="body">{defaultCreationEnvironment?.name}</Typography>
      </MenuItem>
      <MenuItem key={EnvironmentTypes.Production} value={EnvironmentTypes.Production}>
        <Typography variant="body">{productionEnvironment?.name}</Typography>
      </MenuItem>
    </SweepSelect>
  );

  return (
    <InfoDialog
      PaperPropsSx={{
        width: '1416px',
        '.MuiDialogContent-root': { padding: '32px' },
        '.infoDialogCloseBtn': { top: '32px' },
      }}
      handleClose={onCloseDialog}
      open={true}
      showCloseButton={true}
    >
      <Box display={'flex'} gap={3} flexDirection={'column'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: 'calc(100% - 60px)',
          }}
        >
          <Typography variant="h1">Review changes in {automationItem.name}</Typography>
          <Box alignSelf={'flex-end'}>
            {!hideDeployButtons && onDeployOrSave && (
              <CreateEditDialogDeployButtons
                onDeployOrSave={onDeployOrSave}
                confirmButtonDisabled={buttonDisabled}
                disableDiff
                disableRequestToDeployDialog
              />
            )}
          </Box>
        </Box>

        <Box>
          {isShowLoading ? (
            <Box sx={{ width: '100%' }}>
              <Stack spacing={4} direction="row">
                <Skeleton variant="rectangular" animation={'wave'} width={'50%'} height={300} />
                <Skeleton variant="rectangular" animation={'wave'} width={'50%'} height={300} />
              </Stack>
            </Box>
          ) : (
            <ReactDiffViewer
              oldValue={getTargetRepresentation}
              newValue={originRepresentation}
              hideLineNumbers={true}
              leftTitle={getRightElement}
              rightTitle={'Current changes'}
              splitView={true}
              showDiffOnly={false}
              compareMethod={DiffMethod.LINES}
              renderContent={renderDiffContent}
              styles={diffStyles}
            />
          )}
        </Box>
      </Box>
    </InfoDialog>
  );
};
