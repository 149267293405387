import { Box } from '@mui/material';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import MembersList from '../pages/assignments/members/MembersList';
import { useAssignmentGroups } from '../pages/assignments/members/useAssignmentGroups';
import { useSelector } from 'react-redux';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';

const Members = ({
  onClose,
  isDialogLayout,
}: {
  onClose?: () => void;
  isDialogLayout?: boolean;
}) => {
  const { crmOrgUsersBase, assignmentGroups } = useAssignmentGroups();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const isLoading = !assignmentGroups || !crmOrgUsersBase || !crmOrgId;

  return isLoading ? (
    <Box flex={1}>
      <CenteredCircularProgress />
    </Box>
  ) : (
    <MembersList
      crmOrgUsersBase={crmOrgUsersBase}
      assignmentGroups={assignmentGroups}
      crmOrgId={crmOrgId}
      onClose={onClose}
      isDialogLayout={isDialogLayout}
    />
  );
};

export default Members;
