import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { useSweepApi } from '../apis/sweep';
import {
  selectUserInfoData,
  setUserGetStarted,
  setUserInfo,
  updateOnboardingStep,
  setUserFetchSuccess,
  setUserFetchError,
  setTrialEndDate,
  setCanvasPanelModeExpanded,
  setUserQuestionnaire,
} from '../reducers/userInfoReducer';
import { setAccountUsersData } from '../reducers/accountUsersReducer';
import { Template, UserInfo } from '../types/UserInfoTypes';
import { useUserApiFacade } from '../apis/facades/useUserApiFacade';
import {
  GetStartedActionIdDerived,
  GetStartedActionIdExtended,
} from '../components/pages/get-started/getStartedTypes';
import { ActionStatus, GetStartedActionId } from '@server/get-started';
import { DefaultUserLevelStatus } from '../components/pages/get-started/getStartedConsts';
import { AccountOnboardingStep } from '@server/account-onboarding';
import useDashboardApiFacade, {
  UpdateUserAndAccountDetails,
} from '../apis/facades/useDashboardApiFacade';
import { useSweepUnauthenticatedApi } from '../apis/sweep/useSweepUnauthenticatedApi';
import extractErrorMsg from '../components/helpers/extractErrorMsg';
import axios_instance_sweep from '../apis/sweep/sweepApiClient';
import { QuestionnaireData } from '../components/pages/questionnaire/questionnaireTypes';
import { QuestionnaireStatus } from '@server/questionnaire';
import { telemetry } from '../telemetry';
import { selectCrmOrgs } from '../components/pages/environments/environmentsReducer';

export enum SweepRoles {
  Viewer = 'Viewer',
  Collaborator = 'Collaborator',
  Admin = 'Admin',
  Alert_Manager = 'Alert Manager',
}

export const TRIAL_DAYS_DEFAULT = 14;

const useUserInfo = () => {
  const userInfo = useSelector(selectUserInfoData);
  const sweepApi = useSweepApi();
  const unauthenticatedApi = useSweepUnauthenticatedApi();
  const dispatch = useDispatch();
  const {
    fetch_current_user,
    delete_user,
    delete_invitation,
    patch_updateOnboardingStatus,
    update_userPreferences,
    post_accountTrial,
    get_clearbitEnrichment,
  } = useUserApiFacade();
  const { put_accountDetails } = useDashboardApiFacade();

  const fetchCurrentUser = useCallback(async () => {
    try {
      const user = await fetch_current_user();
      dispatch(setUserFetchSuccess(user));
      return user;
    } catch (error: any) {
      const msg = extractErrorMsg(error);
      dispatch(setUserFetchError(msg));
      const { statusCode } = error?.response?.data || {};
      if (statusCode !== 401) {
        telemetry.captureError(error, { message: msg });
      }
    }
  }, [dispatch, fetch_current_user]);

  const updateUserInfo = async (updatedUserInfo: Partial<UserInfo>) => {
    const nothingChanged = isEqual({ ...userInfo, ...updatedUserInfo }, userInfo);

    if (nothingChanged) return;
    try {
      await sweepApi.patch(`/users`, updatedUserInfo);
      dispatch(setUserInfo(updatedUserInfo));
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const updateGetStartedActionCompleted = useCallback(
    async (actionId: GetStartedActionIdExtended) => {
      const accountLevelActions = Object.values(GetStartedActionIdDerived);

      if (accountLevelActions.includes(actionId as GetStartedActionIdDerived)) {
        return;
      }

      //since we know the action is not account-level (checked by the above "if"), we can do the casting
      const _actionId = actionId as GetStartedActionId;

      const clonedGetStarted = cloneDeep(userInfo?.preferences?.getStarted);
      const updatedGetStarted = { ...DefaultUserLevelStatus, ...clonedGetStarted };
      if (updatedGetStarted?.[_actionId]) {
        updatedGetStarted[_actionId] = ActionStatus.COMPLETED;
      }
      const updatedPreferences = { ...userInfo?.preferences, getStarted: updatedGetStarted };
      try {
        await update_userPreferences(updatedPreferences);
        dispatch(setUserGetStarted(updatedGetStarted));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [dispatch, update_userPreferences, userInfo?.preferences],
  );

  const updateDefaultCreationCrmOrgId = useCallback(
    async (crmOrgId: string | null) => {
      const updatedPreferences = {
        ...userInfo?.preferences,
        defaultCreationCrmOrgId: crmOrgId,
      };
      try {
        axios_instance_sweep.defaults.headers.common['currentcrmorgid'] = crmOrgId;
        dispatch(setUserInfo({ preferences: updatedPreferences }));
        await update_userPreferences(updatedPreferences);
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [dispatch, update_userPreferences, userInfo?.preferences],
  );

  const updateCanvasPanelModeExpanded = useCallback(
    async (isExpanded: boolean) => {
      const updatedPreferences = { ...userInfo?.preferences, canvasPanelModeExpanded: isExpanded };
      try {
        await update_userPreferences(updatedPreferences);
        dispatch(setCanvasPanelModeExpanded({ isExpanded }));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [update_userPreferences, userInfo?.preferences, dispatch],
  );

  const updateUserQuestionnaireCompleted = useCallback(
    async (questionnaireData?: Partial<QuestionnaireData>) => {
      const updatedData = { ...userInfo?.preferences?.questionnaire?.data, ...questionnaireData };
      const questionnaire = {
        data: updatedData,
        status: QuestionnaireStatus.COMPLETED,
      };
      const updatedPreferences = {
        ...userInfo?.preferences,
        questionnaire,
      };
      try {
        await update_userPreferences(updatedPreferences);
        dispatch(setUserQuestionnaire(questionnaire));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [dispatch, update_userPreferences, userInfo?.preferences],
  );

  const updateUserQuestionnaire = useCallback(
    async (questionnaireData: Partial<QuestionnaireData>) => {
      const updatedData = {
        ...userInfo?.preferences?.questionnaire?.data,
        ...questionnaireData,
      };
      const questionnaire = {
        data: updatedData,
        status: userInfo?.preferences?.questionnaire?.status ?? QuestionnaireStatus.NOT_COMPLETED,
      };
      const updatedPreferences = {
        ...userInfo?.preferences,
        questionnaire,
      };
      try {
        await update_userPreferences(updatedPreferences);
        dispatch(setUserQuestionnaire(questionnaire));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [dispatch, update_userPreferences, userInfo?.preferences],
  );

  const addNewImage = async (imageBase64Blob: string) => {
    try {
      const response = await sweepApi.post(`/users/image`, { image: imageBase64Blob });
      return response.data as string;
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const getAccountUsersData = async () => {
    const response = await sweepApi.get(`/users/account?includeInvitations=true`);
    dispatch(setAccountUsersData(response.data));
    return response.data;
  };

  const updateUserRoles = async (userId: string, newRole: string) => {
    try {
      const response = await sweepApi.put(`/users/${userId}/role`, {
        roleGroupId: newRole,
      });
      return response.data as string;
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const sendInvitation = async ({
    email,
    roleGroupId,
    message,
    entryPointName,
    entryPointId,
    template = Template.Account,
  }: {
    email: string;
    roleGroupId: string;
    message?: string;
    entryPointName?: string;
    entryPointId?: string;
    template?: Template;
  }) =>
    await sweepApi.post('/users/account/invitations', {
      email,
      roleGroupId,
      message,
      entryPointName,
      entryPointId,
      template,
    });

  const removeUser = async (userId: string) => {
    return await delete_user(userId);
  };

  const removeInvitation = async (invitationId: string) => {
    return await delete_invitation(invitationId);
  };

  const updateOnboardingStatus = async ({ status }: { status: AccountOnboardingStep }) => {
    await patch_updateOnboardingStatus(status);
    dispatch(updateOnboardingStep(status));
  };

  const updateAccountDetails = async (userAndAccountDetails: UpdateUserAndAccountDetails) => {
    await put_accountDetails(userAndAccountDetails);
    dispatch(
      setUserInfo({
        name: userAndAccountDetails.userName,
        account: { ...userInfo?.account, name: userAndAccountDetails.accountName },
      }),
    );
  };

  const resendVerificationEmail = useCallback(
    async (userId: string) => {
      await unauthenticatedApi.post(`/users/${userId}/resend-verification-email`);
    },
    [unauthenticatedApi],
  );

  const startTrial = useCallback(async () => {
    await post_accountTrial();
    const endDate = new Date(Date.now() + 1000 * 60 * 60 * 24 * TRIAL_DAYS_DEFAULT).toISOString();
    dispatch(setTrialEndDate({ trialEndDate: endDate }));
  }, [dispatch, post_accountTrial]);

  const crmOrgs = useSelector(selectCrmOrgs);

  const updateDefaultCreationCrmOrgIdIfNecessary = useCallback(
    async (crmOrgId: string) => {
      if (crmOrgs.length === 0) {
        if (crmOrgId) {
          await updateDefaultCreationCrmOrgId(crmOrgId);
        }
      }
    },
    [crmOrgs.length, updateDefaultCreationCrmOrgId],
  );

  const getClearbitEnrichment = useCallback(async () => {
    try {
      return await get_clearbitEnrichment();
    } catch (e) {}
  }, [get_clearbitEnrichment]);

  return {
    fetchCurrentUser,
    getAccountUsersData,
    updateUserInfo,
    addNewImage,
    sendInvitation,
    updateUserRoles,
    removeUser,
    removeInvitation,
    updateOnboardingStatus,
    updateAccountDetails,
    resendVerificationEmail,
    updateDefaultCreationCrmOrgId,
    startTrial,
    updateCanvasMode: updateCanvasPanelModeExpanded,
    updateUserQuestionnaire,
    updateUserQuestionnaireCompleted,
    updateGetStartedActionCompleted,
    updateDefaultCreationCrmOrgIdIfNecessary,
    getClearbitEnrichment,
  };
};

export default useUserInfo;
