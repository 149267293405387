import _keyBy from 'lodash/keyBy';
import _sample from 'lodash/sample';
import { useSelector } from 'react-redux';
import { selectAssignmentGroupsList } from '../../../../reducers/assignmentGroupsPageReducer';
import { EmojiType } from '../../../avatar/avatarTypes';
import { assignmentGroupEmojis } from './assignmentGroupEmojis';
import { useCallback } from 'react';

export const useGetFirstAvailableEmoji = () => {
  const assignmentsGroupList = useSelector(selectAssignmentGroupsList);

  const getFirstAvailableEmoji = useCallback(() => {
    const emojisByContent = _keyBy(assignmentGroupEmojis, 'content');
    const emojisSet = new Set<string>(assignmentGroupEmojis.map((emoji) => emoji.content));
    assignmentsGroupList?.forEach((group) => {
      if (group.avatar?.emoji?.content) {
        emojisSet.delete(group.avatar.emoji.content);
      }
    });
    if (emojisSet.size > 0) {
      const { value } = emojisSet.values().next();
      return emojisByContent[value || 0] as EmojiType;
    }
    return _sample(assignmentGroupEmojis);
  }, [assignmentsGroupList]);

  return getFirstAvailableEmoji;
};
