import { IconButton, Tooltip, Button } from '@sweep-io/sweep-design';
import { useExpandedMode } from './ReserveSpaceForPanelsCtx';
import { ACTIONS_EVENTS } from '../../../../services/events';
import useSendBiEvent from '../../../../hooks/useSendBiEvent';
import { Expand } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';

export const ExpandedModeButton = () => {
  const sendBiEvent = useSendBiEvent();

  const { isExpandedMode, setIsExpandedMode } = useExpandedMode();
  const text = isExpandedMode ? 'Canvas View' : 'Full Page';

  const onClick = () => {
    const state = !isExpandedMode;
    setIsExpandedMode(state);

    const actionName = state
      ? ACTIONS_EVENTS.documentationFullPageClick
      : ACTIONS_EVENTS.documentationCanvasViewClick;

    sendBiEvent({
      name: actionName,
    });
  };

  return (
    <Box sx={{ button: { whiteSpace: 'nowrap' } }}>
      {isExpandedMode ? (
        <Button startIconName="Minimize" variant="outlined" size="tiny" onClick={onClick}>
          {text}
        </Button>
      ) : (
        <Tooltip title={text}>
          <IconButton variant="outlined" size="tiny" onClick={onClick}>
            <Expand />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
