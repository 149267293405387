import { LogoDevBrand } from '../../../apis/facades/useLogoDevApiFacade';

const LOGO_DEV_SERVER_PUBLIC_KEY = import.meta.env.VITE_LOGO_DEV_SERVER_PUBLIC_KEY;

const logoDevImgSrcForDomain = (domain?: string, size = 48) => {
  if (!domain) {
    return '';
  }
  return `https://img.logo.dev/${domain}?token=${LOGO_DEV_SERVER_PUBLIC_KEY}&format=png&size=${size}`;
};

const qualityToSize = {
  normal: 2,
  high: 4,
  higher: 8,
};

export const LogoDevIcon = ({
  brand,
  size = 24,
  quality = 'normal',
}: {
  brand: LogoDevBrand;
  size?: number;
  quality?: 'normal' | 'high' | 'higher';
}) => {
  return (
    <img
      src={logoDevImgSrcForDomain(brand.domain, size * qualityToSize[quality])}
      alt={brand.name}
      width={size}
      height={size}
    />
  );
};
