import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Tag } from '../apis/facades/useUserInputsApiFacade';
import { ConfigurationItemMap } from '../components/documentation/configuration-item-tables/shared/helpers';
import { UserInputTypes } from '@server/user-inputs';

export interface TagsState {
  tagsList: Tag[];
  tagsConfigurations: {
    [tagId: string]: { [tagKey in UserInputTypes]: ConfigurationItemMap };
  };
}

const initialState: TagsState = {
  tagsList: [],
  tagsConfigurations: {},
};

export const tagsSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setTagsList: (state, action: PayloadAction<{ tags: Tag[] }>) => {
      state.tagsList = action.payload.tags;
    },
    setTagConfigurations: (
      state,
      action: PayloadAction<{
        tagId: string;
        configurations: { [key in UserInputTypes]: ConfigurationItemMap };
      }>,
    ) => {
      const { configurations, tagId } = action.payload;
      state.tagsConfigurations[tagId] = configurations;
    },
    clearTagConfigurations: (state) => {
      state.tagsConfigurations = {};
    },
    addTag: (state, action: PayloadAction<{ tag: Tag }>) => {
      state.tagsList.push(action.payload.tag);
    },
    updateTag: (state, action: PayloadAction<{ tag: Tag }>) => {
      const { tag } = action.payload;
      state.tagsList = state.tagsList.map((_tag) => (_tag.id === tag.id ? tag : _tag));
    },
    deleteTag: (state, action: PayloadAction<{ tagId: string }>) => {
      const { tagId } = action.payload;
      state.tagsList = state.tagsList.filter((tag) => tag.id !== tagId);
    },
  },
});

export const {
  setTagsList,
  setTagConfigurations,
  addTag,
  updateTag,
  deleteTag,
  clearTagConfigurations,
} = tagsSlice.actions;

export const selectTagsList = (state: RootState) => state.tags.tagsList;
export const selectTagById = (tagId: string) => (state: RootState) =>
  state.tags.tagsList.find((tag) => tag.id === tagId);

export const selectTagConfigurations = (tagId: string) => (state: RootState) =>
  state.tags.tagsConfigurations[tagId];

export default tagsSlice.reducer;
