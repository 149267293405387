import { Box, Typography } from '@mui/material';
import { DASHBOARD_HEADER_HEIGHT_PX } from '../../../constants';
import { colors } from '@sweep-io/sweep-design';
import { AUTOMATIONS_LAYOUT_MAX_WIDTH } from '../../Automations/helper';
import { HORIZONTAL_PADDING } from '../configuration-canvas-panel/consts';

const SIDEBAR_MENU_WIDTH = '51px';

export const PageTemplates = ({
  title,
  isFullPage,
  children,
}: {
  title: string;
  isFullPage: boolean;
  children: React.ReactNode;
}) => {
  const fullPageSx = {
    width: `calc(100% - ${SIDEBAR_MENU_WIDTH})`,
    height: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT_PX})`,
    position: 'fixed',
    background: 'white',
    zIndex: '100',
    p: '72px 16px',
    top: '56px',
    left: SIDEBAR_MENU_WIDTH,
    overflow: 'scroll',
    display: 'flex',
    justifyContent: 'center',
  };

  const boxSx = {
    padding: `32px ${HORIZONTAL_PADDING}px`,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'auto',
  };
  return (
    <Box sx={isFullPage ? fullPageSx : boxSx}>
      <Box
        sx={{
          minWidth: '800px',
          maxWidth: AUTOMATIONS_LAYOUT_MAX_WIDTH,
          width: AUTOMATIONS_LAYOUT_MAX_WIDTH,
        }}
      >
        <Typography variant="h1">{title}</Typography>
        <Box sx={{ mt: 1 }}>
          <Typography variant="body" color={colors.grey[800]}>
            Build it now and deploy whenever you're ready
          </Typography>
        </Box>

        <Box sx={{ mt: '48px', overflow: 'scroll' }}>{children}</Box>
      </Box>
    </Box>
  );
};
