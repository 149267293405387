import { Box } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';
import { dataTableVariants } from '../table/dataTableVariants';
import { DataTableVariant } from '../table/TableTypes';

const InlineAutocompleteEmptyState = ({
  onClick,
  emptyStateTxt,
  emptyStateButtonTxt,
}: {
  onClick: () => void;
  emptyStateTxt: string;
  emptyStateButtonTxt: string;
}) => {
  return (
    <Box padding="0 16px" display="flex" gap={1} alignItems="center">
      <Typography
        variant={dataTableVariants[DataTableVariant.default].fontVariant}
        color={colors.grey[500]}
        whiteSpace="nowrap"
      >
        {emptyStateTxt}
      </Typography>
      <Box className="add-to-group" sx={{ '& .MuiButton-root': { whiteSpace: 'nowrap' } }}>
        <Button variant="flat" onClick={onClick}>
          {emptyStateButtonTxt}
        </Button>
      </Box>
    </Box>
  );
};

export default InlineAutocompleteEmptyState;
