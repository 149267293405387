//By product definition orgs should be sorted by Main first + rest of the list alphabetically

export const sortOrgs = (orgs: CrmOrg[]) => {
  const writableOrgs = [...orgs];
  const orgsWithOriginalIndex = writableOrgs
    .sort((a, b) => (a.name < b.name ? -1 : 1))
    .map((org, index) => ({ ...org, originalIndex: index }));

  const orgsSortedByMainFirst = [...orgsWithOriginalIndex].sort(
    (orgA, orgB) => Number(Boolean(orgB.isMain)) - Number(Boolean(orgA.isMain)),
  );

  return orgsSortedByMainFirst;
};
