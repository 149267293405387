import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AiChatMessage } from '../common/ai-chat/aiChatTypes';
import { RootState } from '../../reducers';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';

export interface Chat {
  id: string;
  name: string;
  messages: AiChatMessage[];
  error?: string;
  isLoading: boolean;
}

export interface ChatShort {
  id: string;
  name: string;
  lastUpdatedAt: string;
  isFavorite: boolean;
}

export interface CopilotChatsState {
  isExpanded: boolean;
  isOpened: boolean;
  environments: {
    [crmOrgId: string]: {
      isLoading: boolean;
      error?: string;
      data?: {
        chatsList?: ChatShort[];
        chatsDetails: {
          [chatId: string]: Chat;
        };
        activeChatId?: string;
      };
    };
  };
}

const initialState: CopilotChatsState = {
  isExpanded: false,
  isOpened: false,
  environments: {},
};

const TEMP_ID = 'TEMP_ID';

export const copilotChatsSlice = createSlice({
  name: 'copilotChats',
  initialState,
  reducers: {
    createNewChat: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
      }>,
    ) => {
      const { crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          isLoading: false,
          data: {
            chatsDetails: {},
          },
        };
      }
      if (!state.environments[crmOrgId].data) {
        state.environments[crmOrgId].data = {
          chatsDetails: {},
        };
      }
      state.environments[crmOrgId].data.activeChatId = TEMP_ID;
      state.environments[crmOrgId].data.chatsDetails[TEMP_ID] = {
        id: TEMP_ID,
        name: 'New chat',
        messages: [],
        isLoading: false,
      };
    },
    setActiveChat: (state, action: PayloadAction<{ crmOrgId: string; chatId: string }>) => {
      const { chatId, crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          isLoading: false,
          data: {
            chatsDetails: {},
          },
        };
      }
      if (!state.environments[crmOrgId].data) {
        state.environments[crmOrgId].data = {
          chatsDetails: {},
        };
      }
      state.environments[crmOrgId].data.activeChatId = chatId;
    },
    replaceTempIdWithChatId: (
      state,
      action: PayloadAction<{ crmOrgId: string; chatId: string }>,
    ) => {
      const { chatId, crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          isLoading: false,
          data: {
            chatsDetails: {},
          },
        };
      }
      if (!state.environments[crmOrgId].data) {
        state.environments[crmOrgId].data = {
          chatsDetails: {},
        };
      }
      const relevantChat = state.environments[crmOrgId].data.chatsDetails[TEMP_ID]; //TODO make sure this works - of not, need to clone
      if (!relevantChat) {
        return;
      }
      state.environments[crmOrgId].data.chatsDetails[chatId] = relevantChat;
      delete state.environments[crmOrgId].data.chatsDetails[TEMP_ID];
    },
    //Adding a message to an existing chat:
    addSyncMessage: (
      state,
      action: PayloadAction<{ crmOrgId: string; message: AiChatMessage; chatId: string }>,
    ) => {
      const { message, chatId, crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          isLoading: false,
          data: {
            chatsDetails: {},
          },
        };
      }
      if (!state.environments[crmOrgId].data) {
        state.environments[crmOrgId].data = {
          chatsDetails: {},
        };
      }
      if (!state.environments[crmOrgId].data.chatsDetails[chatId]?.messages) {
        return;
        //this shouldn't happen, because when creating a new chat the messages is reset to []
      }
      state.environments[crmOrgId].data.chatsDetails[chatId].messages.push(message);
      state.environments[crmOrgId].data.chatsDetails[chatId].isLoading = true;
    },
    addAsyncResponse: (
      state,
      //TODO check with Galia if the response is indeed array AiChatMessage[]
      action: PayloadAction<{
        crmOrgId: string;
        message?: AiChatMessage[];
        error?: string;
        chatId: string;
      }>,
    ) => {
      const { message, error, chatId, crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          isLoading: false,
          data: {
            chatsDetails: {},
          },
        };
      }
      if (!state.environments[crmOrgId].data) {
        state.environments[crmOrgId].data = {
          chatsDetails: {},
        };
      }
      if (!state.environments[crmOrgId].data.chatsDetails[chatId]?.messages) {
        return;
        //this shouldn't happen
      }
      state.environments[crmOrgId].data.chatsDetails[chatId].isLoading = false;
      state.environments[crmOrgId].error = error;
      const currentMessages = state.environments[crmOrgId].data.chatsDetails[chatId].messages;
      state.environments[crmOrgId].data.chatsDetails[chatId].messages = message
        ? [...currentMessages, ...message]
        : currentMessages;
    },
    toggleExpandMode: (state) => {
      state.isExpanded = !state.isExpanded;
    },
    toggleOpened: (state) => {
      state.isOpened = !state.isOpened;
    },
    setLoadingChats: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
      }>,
    ) => {
      const { crmOrgId } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          isLoading: true,
        };
      }
      state.environments[crmOrgId].isLoading = true;
      state.environments[crmOrgId].data = undefined;
    },
    setChats: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        chats: ChatShort[];
      }>,
    ) => {
      const { crmOrgId, chats } = action.payload;
      if (!state.environments[crmOrgId]) {
        state.environments[crmOrgId] = {
          isLoading: false,
        };
      }
      state.environments[crmOrgId].isLoading = false;
      state.environments[crmOrgId].data = {
        chatsList: chats,
        chatsDetails: {},
        activeChatId: undefined,
      };
    },
  },
});

export const {
  createNewChat,
  setActiveChat,
  replaceTempIdWithChatId,
  addAsyncResponse,
  addSyncMessage,
  toggleExpandMode,
  toggleOpened,
  setLoadingChats,
  setChats,
} = copilotChatsSlice.actions;

export const selectCopilotPerOrg = (crmOrgId: string) => (state: RootState) =>
  state.copilotChats.environments[crmOrgId];

export const selectIsExpandedCopilot = (state: RootState) => state.copilotChats.isExpanded;

export const selectIsOpenedCopilot = (state: RootState) => state.copilotChats.isOpened;

export const selectCopilotCurrentOrg = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCopilotPerOrg(crmOrg.id)(state);
  }
};

export default copilotChatsSlice.reducer;
