import { useEffect, useRef } from 'react';

const usePrevious = (value: string) => {
  const ref = useRef<string>();
  useEffect(() => {
    if (ref) {
      ref.current = value;
    }
  });

  return ref.current;
};

export default usePrevious;
