import { useCallback, useState } from 'react';
import { ActiveState } from './helpers';

export const useActiveInactive = () => {
  const [activeState, setActiveState] = useState(ActiveState.activeOnly);

  const onToggleActiveInactive = useCallback(
    (activeState: ActiveState) => setActiveState(activeState),
    [],
  );

  return {
    onToggleActiveInactive,
    activeState,
  };
};
