import { CommentThreadProps } from './types';
import { useSelector } from 'react-redux';
import { selectAccountUsers } from '../../reducers/accountUsersReducer';
import { getReplyText, humanizedTimeAgo } from './helper';
import CommentIcon from './CommentIcon';
import { colors, ExpandableTypography, Typography } from '@sweep-io/sweep-design';
import AvatarWithBorder from './AvatarWithBorder';
import { Box } from '@mui/material';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import { TextWithMentions } from '../common/comments/TextWithMentions';

const CommentPreview = ({
  commentThread,
  isCommentThreadOpened,
}: {
  commentThread: CommentThreadProps;
  isCommentThreadOpened: boolean;
}) => {
  const { commentReplies, isResolved, isRead } = commentThread;
  const firstReply = commentReplies[0];
  const accountUsers = keyBy(useSelector(selectAccountUsers), 'id');
  const creatorUserInfo = accountUsers[firstReply.createdById];
  const restOfParticipants = uniqBy(
    commentReplies
      .filter((reply) => reply.createdById !== creatorUserInfo?.id)
      .map((reply) => accountUsers[reply.createdById]),
    'id',
  );

  const repliesCount = commentReplies.length - 1;
  const repliesText = getReplyText(repliesCount);

  const createdAgo = humanizedTimeAgo(firstReply.createdAt ?? '');

  return (
    <CommentIcon
      isRead={isRead}
      expandableClass={isCommentThreadOpened ? '' : 'comment-content'}
      flexDirection={restOfParticipants.length ? 'column' : 'row'}
      isResolved={isResolved}
    >
      <Box display="flex" alignItems="center" gap="2px">
        {creatorUserInfo && <AvatarWithBorder userInfo={creatorUserInfo} />}
        {restOfParticipants.length === 1 && (
          <Box
            sx={{
              position: 'relative',
              right: '10px',
              width: '17px', // in order for the wrapping div to be exactly around the Avatar (compensation is needed due to moving it right)
            }}
          >
            <AvatarWithBorder userInfo={restOfParticipants[0]} />
          </Box>
        )}
        {restOfParticipants.length > 1 && (
          <Typography variant="caption-bold" color={colors.white}>
            +{restOfParticipants.length}
          </Typography>
        )}
      </Box>
      {!isCommentThreadOpened && (
        <Box className="comment-content" display="flex" flexDirection="column" gap={0.5}>
          <Box display="flex" gap={0.5}>
            <Typography
              variant="caption-bold"
              color={colors.white}
              wordBreak="keep-all"
              whiteSpace="nowrap"
            >
              {creatorUserInfo?.name}
            </Typography>
            <Typography
              variant="caption"
              color={isRead ? colors.grey[500] : colors.white}
              wordBreak="keep-all"
              whiteSpace="nowrap"
            >
              {createdAgo}
            </Typography>
          </Box>
          <ExpandableTypography rows={3} color={colors.white} variant="caption">
            <TextWithMentions text={firstReply.commentBody} disableHighlight />
          </ExpandableTypography>
          <Typography variant="caption" color={colors.white}>
            {repliesText}
          </Typography>
        </Box>
      )}
    </CommentIcon>
  );
};

export default CommentPreview;
