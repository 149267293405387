import { Box, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { Button, Switch, Typography } from '@sweep-io/sweep-design';
import { useCallback, useContext } from 'react';
import SweepSelect from '../../../../common/SweepSelect';
import { DateSelector } from '../../../../common/rule-builder/selectors/DateSelector';
import { TextFieldSelector } from '../../../../common/rule-builder/selectors/TextFieldSelector';
import { DialogHeaderTextField } from '../../../../common/create-edit-dialog/DialogHeaderTextField';
import { SearchSelect } from '../../../../common/SearchSelect';
import { selectCampaigns } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import { HubspotCampaignCtx } from './HubspotCampaignCtx';
import { CampaignBase } from './campaignTypes';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import AlertPermission from '../../../../common/AlertPermission';
import usePermission from '../../../../common/permissions/usePermission';

const SfCampaignSearchSelect = ({
  campaignId,
  onChange,
  id,
  disabled,
}: {
  id?: string;
  campaignId?: string;
  onChange: (campaignId: string) => void;
  disabled?: boolean;
}) => {
  const sfCampaigns = useSelector(selectCampaigns);

  return (
    <SearchSelect
      id={id}
      label="Parent Campaign"
      items={(sfCampaigns || [])?.map((campaign) => ({
        label: campaign.Name,
        value: campaign.Id,
      }))}
      onChange={(item) => onChange(item.value)}
      dataTestId="simple-field-selector"
      SelectProps={{
        value: campaignId,
        placeholder: 'Choose Campaign',
      }}
      FormControlProps={{ fullWidth: true }}
      valueRenderer={(item) => item?.label}
      disabled={disabled}
    />
  );
};

export const HubspotCampaignFormHeader = ({
  campaign,
  onChange,
  onDeploy,
  isSaving = true,
}: {
  campaign: CampaignBase;
  onChange: (campaignPartial: Partial<CampaignBase>) => any;
  onDeploy: () => any;
  isSaving?: boolean;
}) => {
  const _isSaving = Boolean(isSaving);
  const [isCreateCampaignAllowed] = usePermission(['create:campaigns']);

  const onChangeProperty = useCallback(
    (property: keyof CampaignBase) => (value: CampaignBase[typeof property]) => {
      onChange({ [property]: value });
    },
    [onChange],
  );

  const isValid = campaign.Name.length > 0;

  const isDisabled = _isSaving || !isCreateCampaignAllowed;

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      gap={2}
      mt={3}
      sx={{
        button: {
          minWidth: '200px',
        },
      }}
    >
      <DialogHeaderTextField
        value={campaign.Name}
        onChange={onChangeProperty('Name')}
        placeholder="Name this campaign"
        editDisabled={isDisabled}
        maxLength={80}
      />

      <RestrictedTooltip
        to={['create:campaigns']}
        notAllowedTitle={'To create Hubspot campaigns, please contact your admin'}
      >
        <Button
          onClick={onDeploy}
          size="small"
          disabled={!isValid || isDisabled}
          loading={_isSaving}
        >
          Deploy to Production
        </Button>
      </RestrictedTooltip>
      {!isCreateCampaignAllowed && (
        <AlertPermission
          alertMessage={`Your account doesn't have permission to edit this campaign. Please contact your admin to get the permission.`}
          addedStyle={{
            width: '470px',
            position: 'absolute',
            right: '8px',
            top: '98px',
            zIndex: '1000',
          }}
        />
      )}
    </Box>
  );
};

export const HubspotCampaignForm = ({
  campaign,
  onChange,
  isSaving = true,
}: {
  campaign: CampaignBase;
  onChange: (campaignPartial: Partial<CampaignBase>) => any;
  isSaving?: boolean;
}) => {
  const _isSaving = Boolean(isSaving);
  const { campaignStatusOptions, campaignTypeOptions } = useContext(HubspotCampaignCtx);
  const [isCreateCampaignAllowed] = usePermission(['create:campaigns']);

  const onChangeProperty = useCallback(
    (property: keyof CampaignBase) => (value: CampaignBase[typeof property]) => {
      onChange({ [property]: value });
    },
    [onChange],
  );
  const onChangeSelectProperty = useCallback(
    (property: keyof CampaignBase) => (event: SelectChangeEvent<unknown>) =>
      onChange({ [property]: event.target.value as string }),
    [onChange],
  );

  const onChangeNumberProperty = useCallback(
    (property: keyof CampaignBase) => (value: string) => {
      onChange({ [property]: parseInt(value) });
    },
    [onChange],
  );
  const isDisabled = _isSaving || !isCreateCampaignAllowed;

  return (
    <Box mt={3}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" gap={1}>
            <Switch
              checked={campaign.IsActive}
              onChange={(e, checked) => onChangeProperty('IsActive')(checked)}
              disabled={isDisabled}
            />
            <Typography variant="body">Active</Typography>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <SweepSelect
            label="Type"
            FormControlProps={{
              fullWidth: true,
            }}
            SelectProps={{
              placeholder: 'Type',
              value: campaign.Type,
              onChange: (event: SelectChangeEvent<unknown>) =>
                onChangeProperty('Type')(event.target.value as string),
            }}
            disabled={isDisabled}
          >
            {campaignTypeOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.label}
              </MenuItem>
            ))}
          </SweepSelect>
        </Grid>
        <Grid item xs={6}>
          <SweepSelect
            label="Status"
            FormControlProps={{
              fullWidth: true,
            }}
            SelectProps={{
              placeholder: 'Status',
              value: campaign.Status,
              onChange: onChangeSelectProperty('Status'),
            }}
            disabled={isDisabled}
          >
            {campaignStatusOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.label}
              </MenuItem>
            ))}
          </SweepSelect>
        </Grid>
        <Grid item xs={6}>
          <DateSelector
            initialValue={campaign.StartDate}
            onChange={onChangeProperty('StartDate')}
            shouldBeDisabled={isDisabled}
            label="Start Date"
          />
        </Grid>
        <Grid item xs={6}>
          <DateSelector
            initialValue={campaign.EndDate}
            onChange={onChangeProperty('EndDate')}
            shouldBeDisabled={isDisabled}
            label="End Date"
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-expected-revenue"
            shouldBeDisabled={isDisabled}
            onChange={onChangeNumberProperty('ExpectedRevenue')}
            selectorType="number"
            label="Expected Revenue"
            fullWidth
            initialValue={campaign.ExpectedRevenue}
            isReadOnly={isDisabled}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-budgeted-cost"
            shouldBeDisabled={isDisabled}
            onChange={onChangeNumberProperty('BudgetedCost')}
            selectorType="number"
            label="Budgeted Cost"
            fullWidth
            initialValue={campaign.BudgetedCost}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-actual-cost"
            shouldBeDisabled={isDisabled}
            onChange={onChangeNumberProperty('ActualCost')}
            selectorType="number"
            label="Actual Cost in Campaign"
            fullWidth
            initialValue={campaign.ActualCost}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-expected-response"
            shouldBeDisabled={isDisabled}
            onChange={onChangeNumberProperty('ExpectedResponse')}
            selectorType="number"
            label="Expected Response %"
            fullWidth
            initialValue={campaign.ExpectedResponse}
          />
        </Grid>
        <Grid item xs={6}>
          <TextFieldSelector
            id="hubspot-campaign-number-sent"
            shouldBeDisabled={isDisabled}
            onChange={onChangeNumberProperty('NumberSent')}
            selectorType="number"
            label="Number Sent in Campaign"
            fullWidth
            initialValue={campaign.NumberSent}
          />
        </Grid>
        <Grid item xs={6}>
          <SfCampaignSearchSelect
            id="hubspot-campaign-parent"
            campaignId={campaign.ParentId}
            onChange={onChangeProperty('ParentId')}
            disabled={isDisabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldSelector
            id="hubspot-campaign-description"
            shouldBeDisabled={isDisabled}
            onChange={onChangeProperty('Description')}
            label="Description"
            fullWidth
            initialValue={campaign.Description}
            InputProps={{
              multiline: true,
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
