import { DependenciesSection } from '../../DependenciesSection';
import { useDependencies } from '../../hooks/useDependencies';
import { TagsHeader, TagType } from '../../header/TagsHeader';
import { useSelector } from 'react-redux';
import { selectTagsList } from '../../../../../reducers/tagsReducer';
import { Box } from '@mui/material';
import { selectAccountUsers } from '../../../../../reducers/accountUsersReducer';
import { parseIdToValueAndNameToLabel } from './utils';
import { useTags } from './useTags';
import { useFilterConfigurations } from './useFilterConfigurations';
import { prepareConfigItemMapStructure } from '../../helpers';

interface TagEditPageProps {
  crmOrgId: string;
  tagId: string;
}

export const TagEditPage = ({ crmOrgId, tagId }: TagEditPageProps) => {
  const {
    tagConfigurations,
    configurationsOwned,
    collaboratorConfigurations,

    collaborationsActiveState,
    configurationsActiveState,
    userConfigurationsActiveState,

    onToggleCollaborationsActiveInactive,
    onToggleConfigurationsActiveInactive,
    onToggleUserConfigurationsActiveInactive,
  } = useFilterConfigurations(tagId);

  const { handleSetConfigurationItem } = useDependencies(crmOrgId);
  const { fetchTagConfigurations, fetchUserConfigurations } = useTags(crmOrgId);

  const tags = useSelector(selectTagsList);
  const users = useSelector(selectAccountUsers);
  const _parsedUsers = parseIdToValueAndNameToLabel(users ?? []);

  const tag = tags?.find((tag) => tag.id === tagId);
  const user = _parsedUsers?.find((user) => user.value === tagId);

  const item = {
    type: tag ? TagType.tag : TagType.user,
    id: tag?.id ?? user?.value ?? '',
    name: tag?.name ?? user?.label ?? '',
    color: tag?.color ?? user?.tagColor,
  };

  const isTag = item.type === TagType.tag;
  const isUser = item.type === TagType.user;

  return (
    <>
      <TagsHeader tagType={item.type} tag={item} userEmail={user?.email} crmOrgId={crmOrgId} />

      <Box p={2} gap={2} display="flex" flexDirection="column" overflow="auto" height="100%">
        {isTag && (
          <DependenciesSection
            crmOrgId={crmOrgId}
            key={`configurations`}
            id={item.id ?? ''}
            name={item.name ?? ''}
            objectName={'Unknown'}
            configurationItems={prepareConfigItemMapStructure(tagConfigurations)}
            title="Configurations"
            isSupportedType
            onChooseNewRule={handleSetConfigurationItem}
            onToggleActiveInactive={onToggleConfigurationsActiveInactive}
            activeState={configurationsActiveState}
            fetchConfigurationsApi={fetchTagConfigurations}
          />
        )}

        {isUser && (
          <DependenciesSection
            crmOrgId={crmOrgId}
            key={`owned`}
            id={item.id ?? ''}
            name={item.name ?? ''}
            objectName={'Unknown'}
            configurationItems={prepareConfigItemMapStructure(configurationsOwned)}
            title="Configurations Owned"
            isSupportedType
            onChooseNewRule={handleSetConfigurationItem}
            onToggleActiveInactive={onToggleUserConfigurationsActiveInactive}
            activeState={userConfigurationsActiveState}
            fetchConfigurationsApi={fetchUserConfigurations}
          />
        )}

        {isUser && (
          <DependenciesSection
            crmOrgId={crmOrgId}
            key={`collaborations`}
            id={item.id ?? ''}
            name={item.name ?? ''}
            objectName={'Unknown'}
            configurationItems={prepareConfigItemMapStructure(collaboratorConfigurations)}
            title="Collaborations"
            isSupportedType
            onChooseNewRule={handleSetConfigurationItem}
            onToggleActiveInactive={onToggleCollaborationsActiveInactive}
            activeState={collaborationsActiveState}
          />
        )}
      </Box>
    </>
  );
};
