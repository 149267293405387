import { Box } from '@mui/material';
import { Panel } from './atoms/Panel';
import { BackButton } from './atoms/BackButton';
import { ConfigurationType } from '../types';
import { useDependencies } from '../hooks/useDependencies';
import { ActionOption, ButtonWithActionMenu } from './atoms/ButtonWithActionMenu';
import { TitleWithSubtitle } from './atoms/TitleWithSubtitle';
import { selectDependenciesHistory } from '../../../../reducers/documentationReducer';
import { useSelector } from 'react-redux';
import { InitialsAvatar } from '../../shared/InitialsAvatar';
import { MiddlePanel } from './atoms/MiddlePanel';
import { ChatIcon } from './atoms/ChatIcon';
import { ConfigurationItem } from '../DependenciesTypes';

interface SourceCodeHeaderProps {
  configurationItem: ConfigurationItem;
  type: ConfigurationType;
  crmOrgId: string;
}

export const SourceCodeHeader = ({ configurationItem, type, crmOrgId }: SourceCodeHeaderProps) => {
  const { onGoToDependenciesMainView, onGoBackInHistory } = useDependencies(crmOrgId);
  const historyLength = useSelector(selectDependenciesHistory);
  const onGoBackFunc = historyLength > 1 ? onGoBackInHistory : onGoToDependenciesMainView;
  const isMainViewSupported =
    !!ConfigurationType[type as keyof typeof ConfigurationType] &&
    type !== ConfigurationType.objects;

  const { changedBy, changedAt } = configurationItem.annotations ?? {};

  return (
    <Panel>
      {isMainViewSupported && <BackButton onClick={onGoBackFunc} />}
      <Box flex={1} display="flex" justifyContent="space-between" alignItems="flex-start">
        <TitleWithSubtitle
          parentType={type}
          configurationItem={configurationItem}
          crmOrgId={crmOrgId}
        />

        <MiddlePanel>
          {changedBy && <InitialsAvatar changedAt={changedAt} userIdOrName={changedBy} />}
          {isMainViewSupported && <ChatIcon crmOrgId={crmOrgId} />}
          <ButtonWithActionMenu
            options={[ActionOption.openInSalesforce]}
            configurationItem={configurationItem}
            crmOrgId={crmOrgId}
          />
        </MiddlePanel>
      </Box>
    </Panel>
  );
};
