import { Box } from '@mui/material';
import { Button, colors, Tooltip, Typography } from '@sweep-io/sweep-design';
import FormulaBuilder from './FormulaBuilder';
import { useState } from 'react';
import { ast, extract } from 'formulon';
import { Warning } from '@sweep-io/sweep-design/dist/icons';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';

const FormulaEditor = ({
  crmOrgId,
  objectName,
  initialValue,
  closeEditor,
  updateFormula,
}: {
  crmOrgId: string;
  objectName: string;
  initialValue?: string;
  closeEditor: (value?: string) => void;
  updateFormula: ({
    value,
    valueFieldIdsArray,
  }: {
    value?: string;
    valueFieldIdsArray: string[][];
  }) => void;
}) => {
  const [value, setValue] = useState(initialValue);
  const [isLoading, setIsLoading] = useState(false);
  const [validationError, setValidationError] = useState('');
  const { getSweepFieldIdsByName } = useSweepFields();

  const onSave = async () => {
    const asted = ast(value);
    const isNotValid = asted.type === 'error';
    setValidationError(isNotValid ? asted.message : '');
    if (isNotValid) return;
    let valueFieldIdsArray: string[][] = [];
    try {
      const extractedVars: string[] = extract(value);
      if (extractedVars.length > 0) {
        setIsLoading(true);
        const res = await getSweepFieldIdsByName({ crmOrgId, fieldNames: extractedVars });
        const { fieldIds } = res;
        valueFieldIdsArray = fieldIds;
        setIsLoading(false);
      }
      updateFormula({ value, valueFieldIdsArray });
      closeEditor(value);
    } catch (e) {
      setValidationError(e?.toString() ?? 'Unknown error');
      setIsLoading(false);
    }
  };

  return (
    <Box
      width="608px"
      height="268px"
      padding={3}
      display="flex"
      flexDirection="column"
      gap={2}
      sx={{
        backgroundColor: colors.grey[100],
        borderRadius: '8px',
      }}
    >
      <FormulaBuilder
        value={value}
        setValue={setValue}
        crmOrgId={crmOrgId}
        objectName={objectName}
      />
      <Box display="flex" justifyContent="space-between">
        <Button size="small" variant="outlined" onClick={() => closeEditor()}>
          Cancel
        </Button>
        <Box display="flex" gap={1.5} alignItems="center">
          {validationError && (
            <Tooltip title={validationError} placement="top">
              <Box display="flex" gap={1} alignItems="center">
                <Warning color={colors.blush[500]} />
                <Typography variant="button" color={colors.blush[500]}>
                  Invalid formula
                </Typography>
              </Box>
            </Tooltip>
          )}
          <Button
            variant="filled"
            size="small"
            disabled={!value}
            loading={isLoading}
            onClick={onSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FormulaEditor;
