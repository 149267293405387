import { ArrowDirection } from '../edges/FloatingEdge';

export function calculateArrowPath(
  tx: number,
  ty: number,
  arrowDirection: ArrowDirection = ArrowDirection.LeftRight,
) {
  let arrowPath = '';
  switch (arrowDirection) {
    case ArrowDirection.LeftRight: {
      // M0 0L8 8L0 16
      const arrowY = ty - 8;
      arrowPath = `M${tx - 5} ${arrowY}L${tx + 3} ${arrowY + 8}L${tx - 5} ${arrowY + 16}`;
      break;
    }
    case ArrowDirection.BottomTop: {
      // M0 8L8 0L16 8
      const arrowX = tx - 8;
      arrowPath = `M${arrowX} ${ty + 8}L${arrowX + 8} ${ty}L${arrowX + 16} ${ty + 8}`;

      break;
    }
    case ArrowDirection.TopBottom: {
      // M16 0L8 8L0 0
      const arrowX = tx - 8;
      const arrowY = ty - 5;
      arrowPath = `M${arrowX + 16} ${arrowY}L${arrowX + 8} ${arrowY + 8}L${arrowX} ${arrowY}`;

      break;
    }

    case ArrowDirection.RightLeft: {
      // M8 16L0 8L8 0
      const arrowY = ty - 8;
      arrowPath = `M${tx + 8} ${arrowY + 16}L${tx} ${arrowY + 8}L${tx + 9} ${arrowY}`;

      break;
    }
  }
  return arrowPath;
}
