import { styled } from '@mui/material/styles';
import { Select } from '@mui/material';

const SelectNoBorder = styled(Select)({
  '& > .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export default SelectNoBorder;
