import React, { useMemo, useState } from 'react';

import { useViewport } from 'reactflow';
import { ContextZoomType, TemporaryTransformations } from '../SweepCanvas';
import { CONTEXT_ZOOM_LEVEL_1, CONTEXT_ZOOM_LEVEL_1_5, CONTEXT_ZOOM_LEVEL_2 } from '../const';
import { CanvasMode } from '../canvasTypes';
import { VisibilityMap } from '../../../types/VisibilityTypes';
import { DEFAULT_VISIBILITY_MAP } from '../../../constants/visibility';
import { DraggingInfo } from '../useDropZoneNode';
import { NodeInfo } from '../useGroupNodes';

interface CanvasCommunicationLayerContextType {
  contextZoomType?: ContextZoomType;
  visibilityMap: VisibilityMap;
  draggingNodeInfo?: DraggingInfo;
  setDraggingNodeInfo: React.Dispatch<React.SetStateAction<DraggingInfo | undefined>>;
  draggingGroupInfo?: NodeInfo;
  setDraggingGroupInfo: React.Dispatch<React.SetStateAction<NodeInfo | undefined>>;
  temporaryTransformations?: TemporaryTransformations;
  setTemporaryTransformations: React.Dispatch<
    React.SetStateAction<TemporaryTransformations | undefined>
  >;
}

export const InternalCanvasCtx = React.createContext<CanvasCommunicationLayerContextType>({
  visibilityMap: DEFAULT_VISIBILITY_MAP,
  setDraggingNodeInfo: () => {},
  setDraggingGroupInfo: () => {},
  setTemporaryTransformations: () => {},
});

export const CanvasCommunicationLayerProvider = ({
  children,
  canvasMode,
  visibilityMap,
}: {
  children: React.ReactNode;
  canvasMode?: CanvasMode;
  visibilityMap: VisibilityMap;
}) => {
  const [draggingNodeInfo, setDraggingNodeInfo] = useState<DraggingInfo>();
  const [draggingGroupInfo, setDraggingGroupInfo] = useState<NodeInfo>();
  const [temporaryTransformations, setTemporaryTransformations] =
    useState<TemporaryTransformations>();

  const { zoom } = useViewport();

  const contextZoomType = useMemo(() => {
    let _contextZoom = ContextZoomType.DEFAULT;
    if (zoom <= CONTEXT_ZOOM_LEVEL_1) {
      _contextZoom = ContextZoomType.LEVEL1;
    }
    if (zoom <= CONTEXT_ZOOM_LEVEL_1_5 || canvasMode === CanvasMode.PREVIEW2) {
      _contextZoom = ContextZoomType.LEVEL1_5;
    }
    if (zoom <= CONTEXT_ZOOM_LEVEL_2) {
      _contextZoom = ContextZoomType.LEVEL2;
    }
    if (canvasMode === CanvasMode.PREVIEW2) {
      _contextZoom = ContextZoomType.LEVEL1_5;
    }

    return _contextZoom;
  }, [canvasMode, zoom]);

  return (
    <InternalCanvasCtx.Provider
      value={{
        contextZoomType,
        visibilityMap,
        draggingNodeInfo,
        setDraggingNodeInfo,
        draggingGroupInfo,
        setDraggingGroupInfo,
        temporaryTransformations,
        setTemporaryTransformations,
      }}
    >
      {children}
    </InternalCanvasCtx.Provider>
  );
};
