import { Box, Stack } from '@mui/material';
import { ReactNode, useMemo, useState } from 'react';
import { colors, IconButton } from '@sweep-io/sweep-design';
import { SearchInput } from '../common/SearchInput';
import { Search as MagnifyingGlassIcon } from '@sweep-io/sweep-design/dist/icons';
import { ChatShort } from './copilotChatsReducer';
import ChatsSection from './ChatsSection';
import { DateTime } from 'luxon';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';

const FIRST_CHAT_GHOST: ChatShort = {
  id: 'FIRST_CHAT_GHOST',
  name: 'New chat',
  lastUpdatedAt: new Date().toISOString(),
  isFavorite: false,
};

const ChatsHistory = ({
  newButton,
  search,
  setSearch,
  chats,
  isLoadingChats,
}: {
  newButton: ReactNode;
  search?: string;
  setSearch: (v: string) => void;
  chats: ChatShort[];
  isLoadingChats: boolean;
}) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const isEmpty = !isLoadingChats && chats.length === 0;

  const [pinnedChats, lastWeekChats, lastMonthChats, earlierChats] = useMemo(() => {
    const pinnedChats: ChatShort[] = [];
    const lastWeekChats: ChatShort[] = [];
    const lastMonthChats: ChatShort[] = [];
    const earlierChats: ChatShort[] = [];
    chats.forEach((chat) => {
      if (chat.isFavorite) {
        pinnedChats.push(chat);
        return;
      }
      const now = DateTime.fromISO(new Date().toISOString());
      const updatedAt = DateTime.fromISO(chat.lastUpdatedAt);
      const diff = now.diff(updatedAt).as('days');
      if (diff < 8) {
        lastWeekChats.push(chat);
        return;
      }
      if (diff < 31) {
        lastMonthChats.push(chat);
        return;
      }
      earlierChats.push(chat);
    });
    return [pinnedChats, lastWeekChats, lastMonthChats, earlierChats];
  }, [chats]);

  return (
    <Stack
      sx={{
        height: '100%',
        padding: '24px 0',
        borderRight: `1px solid ${colors.grey[200]}`,
        backgroundColor: colors.grey[100],
      }}
    >
      {isLoadingChats && <CenteredCircularProgress />}
      {!isLoadingChats && (
        <Stack
          sx={{
            height: '100%',
            gap: '24px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between" height="40px" padding="0 16px">
            {!isSearchOpen && newButton}
            {!isSearchOpen && (
              <IconButton
                onClick={() => {
                  setIsSearchOpen(true);
                }}
                variant="flat"
                size="small"
              >
                <MagnifyingGlassIcon />
              </IconButton>
            )}
            {isSearchOpen && (
              <SearchInput
                TextFieldProps={{
                  value: search,
                  onChange: (e) => {
                    setSearch(e.target.value);
                  },
                }}
                onClearButtonClick={() => setIsSearchOpen(false)}
                withFixedMagnifyingGlassIcon
                forceCloseButton
              />
            )}
          </Box>
          {isEmpty && <ChatsSection title="Today" chats={[FIRST_CHAT_GHOST]}/>}
          {pinnedChats.length > 0 && <ChatsSection title="Pinned" chats={pinnedChats} />}
          {lastWeekChats.length > 0 && (
            <ChatsSection title="Previous 7 days" chats={lastWeekChats} />
          )}
          {lastMonthChats.length > 0 && (
            <ChatsSection title="Previous 30 days" chats={lastMonthChats} />
          )}
          {earlierChats.length > 0 && <ChatsSection title="Earlier" chats={earlierChats} />}
        </Stack>
      )}
    </Stack>
  );
};

export default ChatsHistory;
