import { PluginBantSvg } from '../icons';
import { useOnAddCriteriaPlugin } from '../base-criteria-plugin-panel/useOnAddCriteriaPlugin';
import { PluginTypes } from '../../../../../../types/enums/PluginTypes';

export const useOnAddBantPlugin = () => {
  const onAdd = useOnAddCriteriaPlugin({
    pluginImage: <PluginBantSvg />,
    type: PluginTypes.BANT,
  });

  return onAdd;
};
