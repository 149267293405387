import { Stack } from '@mui/material';
import { RoutingTabs, useRoutingTabs } from './useRoutingTabs';
import {
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../pages/configuration-canvas-panel';
import RoutingTabsList from './RoutingTabsList';
import useRoutingSectionElements from './useRoutingSectionElements';

export const RoutingElementsList = ({
  goToTab,
  withExpandedModeButton,
  isExpanded,
}: {
  goToTab: (tab: RoutingTabs) => void;
  withExpandedModeButton: boolean;
  isExpanded: boolean;
}) => {
  const { routingTab } = useRoutingTabs();
  const { rulesElements, setupElements } = useRoutingSectionElements();
  const textBadgeAlignment = isExpanded ? undefined : 'space-between';

  return (
    <>
      <ConfigurationCanvasPanelHeader withExpandedModeButton={withExpandedModeButton}>
        Routing
      </ConfigurationCanvasPanelHeader>
      <ConfigurationCanvasPanelContent>
        <Stack ml={-2} mr={-2} gap={4}>
          <RoutingTabsList
            title="Rules"
            items={rulesElements}
            onClick={(event: any, key: RoutingTabs) => {
              goToTab(key);
            }}
            activeItem={routingTab}
            textBadgeAlignment={textBadgeAlignment}
          />
          <RoutingTabsList
            title="Setup"
            items={setupElements}
            onClick={(event: any, key: RoutingTabs) => {
              goToTab(key);
            }}
            activeItem={routingTab}
            textBadgeAlignment={textBadgeAlignment}
          />
        </Stack>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
