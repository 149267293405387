import { SxProps } from '@mui/material';
import { DatePickerElement } from '../common/datepicker/DatePickerElement';
import { DateTime } from 'luxon';

const StartDatePicker = ({
  startDate,
  textFieldSx,
  callback,
}: {
  startDate?: string;
  textFieldSx?: SxProps;
  callback: (value: string) => void;
}) => {
  return (
    <DatePickerElement
      textFieldSx={textFieldSx}
      disablePast
      value={startDate ? DateTime.fromISO(startDate) : null}
      callback={(value: DateTime<boolean> | null) => {
        const _date = value?.toISODate();
        callback(_date as string);
      }}
    />
  );
};

export default StartDatePicker;
