import { ButtonSize } from '@sweep-io/sweep-design/dist/components/Button/types';
import { CreateEditDialogButtons } from './CreateEditDialogButtons';
import {
  CreateEditDialogHeaderBase,
  CreateEditDialogHeaderBaseProps,
} from './CreateEditDialogHeaderBase';

export interface CreateEditDialogHeaderProps extends CreateEditDialogHeaderBaseProps {
  buttonContent?: React.ReactNode;
  buttonStartIcon?: string;
  onCancelClick?: () => any;
  onButtonClick: (any: any) => any;
  isButtonLoading?: boolean;
  buttonDisabled?: boolean;
  showCancelButton?: boolean;
  rightSideContent?: React.ReactNode;
  confirmButtonSize?: ButtonSize;
}

export const CreateEditDialogHeader = ({
  buttonContent,
  buttonStartIcon,
  onButtonClick,
  buttonDisabled,
  isButtonLoading,
  confirmButtonSize,
  showCancelButton,
  onCancelClick,
  rightSideContent,
  ...restProps
}: CreateEditDialogHeaderProps) => {
  return (
    <CreateEditDialogHeaderBase
      {...restProps}
      rightSideContent={
        <>
          {rightSideContent}
          <CreateEditDialogButtons
            confirmButtonContent={buttonContent}
            confirmButtonStartIcon={buttonStartIcon}
            onConfirm={onButtonClick}
            confirmButtonDisabled={buttonDisabled}
            showCancelButton={showCancelButton}
            onCancel={onCancelClick}
            isButtonLoading={isButtonLoading}
            confirmButtonSize={confirmButtonSize}
          />
        </>
      }
    />
  );
};
