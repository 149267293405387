import { colors } from '@sweep-io/sweep-design';

export const assignmentGroupEmojis = [
  { content: '⚽', bgColor: colors.mint[100] },
  { content: '🤼‍♂️', bgColor: colors.sun[100] },
  { content: '🥇', bgColor: colors.blue[100] },
  { content: '🤝', bgColor: colors.lemon[300] },
  { content: '👯‍♂️', bgColor: colors.lemon[300] },
  { content: '🖇️', bgColor: colors.blush[100] },
  { content: '💭', bgColor: colors.mint[100] },
  { content: '🎎', bgColor: colors.blue[100] },
  { content: '💎', bgColor: colors.blue[100] },
  { content: '🌱', bgColor: colors.mint[100] },
  { content: '🎏', bgColor: colors.lemon[300] },
  { content: '🪆', bgColor: colors.sun[100] },
  { content: '👫', bgColor: colors.mint[100] },
  { content: '🏀', bgColor: colors.sun[100] },
  { content: '⛳', bgColor: colors.blue[100] },
  { content: '🐾', bgColor: colors.blush[100] },
  { content: '🚀', bgColor: colors.lemon[300] },
  { content: '💰', bgColor: colors.blue[100] },
  { content: '🍒', bgColor: colors.blush[100] },
  { content: '🍒', bgColor: colors.mint[100] },
];
