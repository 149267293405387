import { Box, Collapse } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronUp, CurserClick } from '@sweep-io/sweep-design/dist/icons';
import { Radio, Typography } from '@sweep-io/sweep-design';
import { StyledRadioGroup } from '../../common/StyledComponents';
import { AutomationTypes } from '../../../types/enums/AutomationTypes';
import { StyledFormControlLabel, settingsPanelLayout } from './helpers';

interface SettingsTriggerUpdatePanelProps {
  readonly?: boolean;
  automationDetails?: DeepPartial<AutomationDetailsDtoNew>;
  onChange: (type: AutomationTypes) => any;
  objectLabel: string;
}

const SettingsTriggerUpdatePanel = ({
  readonly,
  automationDetails,
  onChange,
  objectLabel,
}: SettingsTriggerUpdatePanelProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(false);

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <CurserClick variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Trigger settings</Typography>
              <Typography variant="body">
                Specify the actions that will trigger deduplication
              </Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <StyledRadioGroup
            className="alignTop"
            sx={{ marginTop: '12px' }}
            value={automationDetails?.triggerType}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const val = (event.target as HTMLInputElement).value;
              onChange(val as AutomationTypes);
            }}
          >
            <StyledFormControlLabel
              disabled={readonly}
              value={AutomationTypes.OnCreate}
              control={<Radio />}
              label={
                <>
                  <Typography variant="body">Only on create</Typography>
                  <br />
                  <Typography variant="caption">
                    {`We'll look for duplicates only when a new ${objectLabel} is created`}
                  </Typography>
                </>
              }
            />

            <StyledFormControlLabel
              disabled={readonly}
              value={AutomationTypes.OnUpsert}
              control={<Radio />}
              label={
                <>
                  <Typography variant="body">Both on create and on update</Typography>
                  <br />
                  <Typography variant="caption">
                    {`Deduplication will run when new ${objectLabel}s are created and on edit of existing ${objectLabel}s`}
                  </Typography>
                </>
              }
            />
          </StyledRadioGroup>
        </Box>
      </Collapse>
    </Box>
  );
};

export default SettingsTriggerUpdatePanel;
