import { Box } from '@mui/material';
import { StageDialogCollapsePanel } from './StageDialogCollapsePanel';
import { validateSweepCriteria } from '../../../../../common/rule-builder/validateSweepCriteria';
import _isEqual from 'lodash/isEqual';
import useSingleGatePanel from '../../gates/useSingleGatePanel';
import { GatePanel } from '../../gates/GatePanel';

interface RuleBuilderCollapsePanelProps {
  stageObjectName: string;
  exitCriteria: SweepExitCriteria;
  toStageName: string;
  readonly?: boolean;
  defaultIsCollapsed?: boolean;
  fromStageName: string;
  crmOrgId: string;
  onGateSave: (updatedExitCriteria: SweepExitCriteria) => void;
  onGateDelete: () => void;
  setGateDirtyState: (isDirty: boolean) => void;
  isPluginType?: PluginTypes;
  funnelId: string;
}

const RuleBuilderCollapsePanel = ({
  stageObjectName,
  exitCriteria,
  toStageName,
  readonly,
  defaultIsCollapsed,
  fromStageName,
  crmOrgId,
  onGateSave,
  onGateDelete,
  setGateDirtyState,
  isPluginType,
  funnelId,
}: RuleBuilderCollapsePanelProps) => {
  const {
    localState: _exitCriteria,
    onChangeAutoMove,
    gateRef,
    onChangeCondition,
    discardChanges,
    saveChanges,
  } = useSingleGatePanel({
    onGateSave,
    setGateDirtyState,
    exitCriteria,
  });

  const criteriaLength = _exitCriteria.criteria.length;
  const hasNoCriteria = _exitCriteria.criteria.length === 0;
  const hasErrors = validateSweepCriteria(_exitCriteria.criteria).length > 0;
  const isChanged = !_isEqual(_exitCriteria, exitCriteria);

  return (
    <StageDialogCollapsePanel
      defaultIsCollapsed={defaultIsCollapsed || hasErrors}
      from={fromStageName}
      to={toStageName}
      totalConditions={criteriaLength}
      isPluginType={isPluginType}
      funnelId={funnelId}
    >
      <Box sx={{ padding: '0px' }}>
        <GatePanel
          exitCriteria={_exitCriteria}
          onChange={onChangeCondition}
          saveChanges={saveChanges}
          discardChanges={discardChanges}
          stageObjectName={stageObjectName}
          readonly={readonly}
          crmOrgId={crmOrgId}
          onGateDelete={onGateDelete}
          isChanged={isChanged}
          hasNoCriteria={hasNoCriteria}
          onChangeAutoMove={onChangeAutoMove}
          gateRef={gateRef}
        />
      </Box>
    </StageDialogCollapsePanel>
  );
};

export { RuleBuilderCollapsePanel };
