import { Box } from '@mui/material';
import { Button, Typography, colors } from '@sweep-io/sweep-design';

export const DeleteEnvCard = ({
  onCancel, onConfirm,
}: {
  onCancel: () => void;
  onConfirm: () => void;
}) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={4}
      px={2}
      mx={-2}
      borderBottom={`1px solid ${colors.blush['300']}`}
      sx={{ backgroundColor: colors.grey[100] }}
    >
      <Box>
        <div>
          <Typography variant="body-bold">Remove Environment</Typography>
        </div>
        <div>
          <Typography variant="body">Are you sure you want to delete this environment?</Typography>
        </div>
      </Box>
      <Box gap={0.5} display="flex">
        <Button size="small" variant="outlined" onClick={onCancel}>
          Cancel
        </Button>
        <Button size="small" variant="filled" type="error" onClick={onConfirm}>
          Remove
        </Button>
      </Box>
    </Box>
  );
};
