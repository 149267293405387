import { SweepNode } from './SweepNode';
import { NodeComponent } from './components/SweepNodeComponent';
import { NODE_WIDTH } from '../const';
import { SxProps } from '@mui/material';

export const BaseGhostNode = ({
  children,
  handleColor,
  backgroundColor,
  halfNode,
  sx,
}: {
  children: React.ReactNode;
  handleColor: string;
  backgroundColor: string;
  halfNode?: boolean;
  sx?: SxProps;
}) => {
  return (
    <SweepNode>
      <NodeComponent
        sx={{
          border: '2px dashed',
          background: backgroundColor,
          borderColor: handleColor,
          width: halfNode ? NODE_WIDTH / 2 : NODE_WIDTH,
          borderRight: halfNode ? 'none' : undefined,
          ...sx,
        }}
      >
        {children}
      </NodeComponent>
    </SweepNode>
  );
};
