import { useRunOnceWhenTruthy } from '../components/common/useRunOnceWhenTruthy';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../reducers/userInfoReducer';
import { isDevEnvIndeed } from '../environment';

//This hook is exposing some props on the "window" object, to be used by the E2E
const useTestsHelper = () => {
  const user = useSelector(selectUserInfoData);
  const accountId = user?.account?.id;

  const isDev = isDevEnvIndeed();

  useRunOnceWhenTruthy(() => {
    (window as any).accountId = accountId;
  }, !!accountId && isDev);
};

export default useTestsHelper;
