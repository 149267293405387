import { DialogContent, DialogTitle } from '@mui/material';
import { BaseDialog } from '../dialogs/BaseDialog';
import { CreateEditDialogHeader, CreateEditDialogHeaderProps } from './CreateEditDialogHeader';

interface CreateEditDialogProps {
  open: boolean;
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => void;
  HeaderProps: CreateEditDialogHeaderProps;
  children: React.ReactElement;
  sx?: any;
}

export const CreateEditDialog = ({
  open,
  onClose,
  HeaderProps,
  children,
  sx,
}: CreateEditDialogProps) => {
  return (
    <BaseDialog open={open} onClose={onClose} disableBackdropClickClose sx={sx}>
      <DialogTitle sx={{ paddingBottom: '32px' }}>
        <CreateEditDialogHeader {...HeaderProps} />
      </DialogTitle>
      <DialogContent sx={{ width: '936px', paddingTop: 4 }}>{children}</DialogContent>
    </BaseDialog>
  );
};
