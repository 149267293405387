import { AutomationActionRow } from './automation-action-row/AutomationActionRow';

interface AutomationActionRowsProps {
  initialObjectName?: string;
  objectName?: string;
  onChange: (field: AutomationActionField[]) => any;
  cmOrgId: string;
  readonly?: boolean;
  fields: AutomationActionField[];
  hideRecordTypeIdField?: boolean;
  disableLookupItems: boolean;
  showCreatableFields?: boolean;
  excludeValueType?: (type: SelectorValueTypes) => boolean;
  showVerifiedUrlOption?: string;
}

export const AutomationActionRows = ({
  initialObjectName,
  objectName,
  onChange,
  cmOrgId,
  readonly,
  fields,
  hideRecordTypeIdField,
  disableLookupItems,
  showCreatableFields,
  excludeValueType,
  showVerifiedUrlOption,
}: AutomationActionRowsProps) => {
  return (
    <>
      {fields.length === 0 && (
        <AutomationActionRow
          showVerifiedUrlOption={showVerifiedUrlOption}
          excludeValueType={excludeValueType}
          showCreatableFields={showCreatableFields}
          disableLookupItems={disableLookupItems}
          hideRecordTypeIdField={hideRecordTypeIdField}
          readonly={readonly}
          objectName={objectName}
          crmOrgId={cmOrgId}
          initialObjectName={initialObjectName}
          onChange={(field) => {
            onChange([field]);
          }}
        />
      )}
      {fields?.map((field, idx) => (
        <AutomationActionRow
          showVerifiedUrlOption={showVerifiedUrlOption}
          excludeValueType={excludeValueType}
          showCreatableFields={showCreatableFields}
          disableLookupItems={disableLookupItems}
          hideRecordTypeIdField={hideRecordTypeIdField}
          readonly={readonly}
          crmOrgId={cmOrgId}
          key={field._id}
          objectName={objectName}
          initialObjectName={initialObjectName}
          field={field}
          onChange={(field) => {
            const newFields = [...(fields || [])];
            if (newFields) {
              newFields[idx] = field;
              onChange(newFields);
            }
          }}
          onDelete={() => {
            const tempFields = [...(fields || [])];
            tempFields.splice(idx, 1);
            onChange(tempFields);
          }}
          onAdd={(emptyField) => {
            let tempFields = fields || [];
            tempFields = [...tempFields, emptyField];
            onChange(tempFields);
          }}
        />
      ))}
    </>
  );
};
