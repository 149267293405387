import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { getRecordTypeIdentifier } from './getRecordTypeIdentifier';
import { classifyAutomation } from './automationClassificationHelper';
import { MASTER_KEY } from '../../funnel-map-flow/dialogs/import-funnel/utils';

export const getUsedRecordTypesAndObjects = ({
  funnelsData,
  recordTypesData,
  automations,
}: {
  recordTypesData?: RecordTypesData;
  funnelsData?: FunnelsData;
  automations?: AutomationStructureNew[];
}) => {
  const recordTypesItems = Object.values(recordTypesData ?? {}).map((recordType) => ({
    id: getRecordTypeIdentifier(recordType.name ?? MASTER_KEY, recordType.objectName), //equivalent to recordTypeId
    label: recordType.label,
    name: recordType.name ?? MASTER_KEY,
    objectName: recordType.objectName,
  }));

  const recordTypesFromFunnelsItems = Object.values(funnelsData ?? {}).map((funnel) => ({
    id: getRecordTypeIdentifier(funnel.recordType?.name, funnel.recordType?.objectName), //equivalent to recordTypeId
    label: funnel.recordType?.label,
    name: funnel.recordType?.name ?? MASTER_KEY,
    objectName: funnel.recordType?.objectName,
  }));

  const recordTypesFromAutomations = Object.values(automations ?? {})
    .map((automation) => {
      const automationClassification = classifyAutomation(automation);
      const _recordTypes: { id: string; label: string; name: string; objectName: string }[] = [];

      if (automationClassification.level === 'step') {
        _recordTypes.push({
          id: getRecordTypeIdentifier(
            automationClassification.recordType.name,
            automationClassification.objectName,
          ),
          label: automationClassification.recordType.label,
          name: automationClassification.recordType.name,
          objectName: automationClassification.objectName,
        });
      } else {
        if (automationClassification.level === 'recordType') {
          automationClassification.recordTypes.forEach((recordType) => {
            _recordTypes.push({
              id: getRecordTypeIdentifier(recordType.name, automationClassification.objectName),
              label: recordType.label,
              name: recordType.name ?? MASTER_KEY,
              objectName: automationClassification.objectName,
            });
          });
        }
      }

      return _recordTypes;
    })
    .flat();

  const usedRecordTypes = uniqBy(
    [...recordTypesItems, ...recordTypesFromFunnelsItems, ...recordTypesFromAutomations],

    (item) => item.id,
  );

  const usedObjectTypes = uniq(usedRecordTypes.map((recordType) => recordType.objectName));

  return {
    usedRecordTypes,
    usedObjectTypes,
  };
};
