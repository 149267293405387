import { Box, Popover } from '@mui/material';
import CommentInputWithAvatar from '../common/comments/CommentInputWithAvatar';
import CommentIcon from './CommentIcon';
import { MentionedUser } from '../common/comments/types';
import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import AvatarWithBorder from './AvatarWithBorder';

const popoverHeightCorrection = 41;

const NewCommentModal = ({
  anchorEl,
  handleClose,
  positionX,
  positionY,
  onConfirm,
}: {
  anchorEl: HTMLDivElement;
  handleClose: () => void;
  onConfirm: (body: string, mentionedUserIds: MentionedUser[]) => void;
  positionX: number;
  positionY: number;
}) => {
  const currentUser = useSelector(selectUserInfoData);
  const handleConfirm = (body: string, mentionedUserIds: MentionedUser[]) => {
    onConfirm(body, mentionedUserIds);
    handleClose();
  };

  return (
    <Popover
      id="comment-popover"
      open={true}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: positionY - popoverHeightCorrection,
        horizontal: positionX,
      }}
      PaperProps={{
        sx: { boxShadow: 'none', borderRadius: 0, backgroundColor: 'transparent', mt: 0 },
      }}
    >
      <Box display="flex" gap={1}>
        <Box sx={{ paddingTop: '6px' }}>
          <CommentIcon>{currentUser && <AvatarWithBorder userInfo={currentUser} />}</CommentIcon>
        </Box>
        <CommentInputWithAvatar
          noAvatar
          onConfirm={handleConfirm}
          placeholder="Add new comment. Use @ to mention"
        />
      </Box>
    </Popover>
  );
};

export default NewCommentModal;
