import * as React from "react";
const SvgStaleDeal = (props) => /* @__PURE__ */ React.createElement("svg", { width: 64, height: 36, viewBox: "0 0 64 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M4 20H9L11 17L15 23L17 20H20", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M3.5125 16C3.5 15.8375 3.5 15.6625 3.5 15.5C3.5 13.9974 4.02062 12.5411 4.97328 11.3791C5.92593 10.217 7.25178 9.42093 8.72525 9.12624C10.1987 8.83154 11.7288 9.05646 13.0551 9.76272C14.3814 10.469 15.4221 11.613 16 13C16.5779 11.613 17.6186 10.469 18.9449 9.76272C20.2712 9.05646 21.8013 8.83154 23.2748 9.12624C24.7482 9.42093 26.0741 10.217 27.0267 11.3791C27.9794 12.5411 28.5 13.9974 28.5 15.5C28.5 24 16 31 16 31C16 31 11.0125 28.2 7.3375 24", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("rect", { x: 28, width: 24, height: 24, rx: 12, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_338_11158)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M37.5189 8.2959H33.2627C32.3812 8.2959 31.6667 9.01046 31.6667 9.89196C31.6667 10.7735 32.3812 11.488 33.2627 11.488H37.5189C38.4004 11.488 39.1149 10.7735 39.1149 9.89196C39.1149 9.01046 38.4004 8.2959 37.5189 8.2959Z", fill: "#36C5F0", style: {
  fill: "#36C5F0",
  fill: "color(display-p3 0.2118 0.7725 0.9412)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M43.3711 9.89177V5.63561C43.3711 4.75411 42.6565 4.03955 41.775 4.03955C40.8935 4.03955 40.179 4.75411 40.179 5.63561V9.89177C40.179 10.7733 40.8935 11.4878 41.775 11.4878C42.6565 11.4878 43.3711 10.7733 43.3711 9.89177Z", fill: "#2EB67D", style: {
  fill: "#2EB67D",
  fill: "color(display-p3 0.1804 0.7137 0.4902)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M41.775 15.7434H46.0312C46.9127 15.7434 47.6273 15.0288 47.6273 14.1473C47.6273 13.2658 46.9127 12.5513 46.0312 12.5513H41.775C40.8935 12.5513 40.179 13.2658 40.179 14.1473C40.179 15.0288 40.8935 15.7434 41.775 15.7434Z", fill: "#ECB22E", style: {
  fill: "#ECB22E",
  fill: "color(display-p3 0.9255 0.6980 0.1804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M35.9228 14.1473V18.4035C35.9228 19.285 36.6374 19.9995 37.5189 19.9995C38.4004 19.9995 39.1149 19.285 39.1149 18.4035V14.1473C39.1149 13.2658 38.4004 12.5513 37.5189 12.5513C36.6374 12.5513 35.9228 13.2658 35.9228 14.1473Z", fill: "#E01E5A", style: {
  fill: "#E01E5A",
  fill: "color(display-p3 0.8784 0.1176 0.3529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M37.5189 4.03955C36.6374 4.03955 35.9228 4.75411 35.9228 5.63561C35.9228 6.51711 36.6374 7.23167 37.5189 7.23167H39.1149V5.63561C39.1149 4.75411 38.4004 4.03955 37.5189 4.03955Z", fill: "#36C5F0", style: {
  fill: "#36C5F0",
  fill: "color(display-p3 0.2118 0.7725 0.9412)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M47.6272 9.89196C47.6272 9.01046 46.9127 8.2959 46.0312 8.2959C45.1497 8.2959 44.4351 9.01046 44.4351 9.89196V11.488H46.0312C46.9127 11.488 47.6272 10.7735 47.6272 9.89196Z", fill: "#2EB67D", style: {
  fill: "#2EB67D",
  fill: "color(display-p3 0.1804 0.7137 0.4902)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M41.775 19.9997C42.6565 19.9997 43.3711 19.2852 43.3711 18.4037C43.3711 17.5222 42.6565 16.8076 41.775 16.8076H40.179V18.4037C40.179 19.2852 40.8935 19.9997 41.775 19.9997Z", fill: "#ECB22E", style: {
  fill: "#ECB22E",
  fill: "color(display-p3 0.9255 0.6980 0.1804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M31.6667 14.1473C31.6667 15.0288 32.3812 15.7434 33.2627 15.7434C34.1442 15.7434 34.8588 15.0288 34.8588 14.1473V12.5513H33.2627C32.3812 12.5513 31.6667 13.2658 31.6667 14.1473Z", fill: "#E01E5A", style: {
  fill: "#E01E5A",
  fill: "color(display-p3 0.8784 0.1176 0.3529)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_338_11158" }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, transform: "translate(31.6667 4)" }))));
export default SvgStaleDeal;
