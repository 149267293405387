import React, { createRef, useCallback, useState } from 'react';
import { createContext } from 'react';
import { ReactFlowProvider } from 'reactflow';
import { HoveredElements, useHoveredElement } from './useHoveredElement';

interface CanvasContextType {
  referencesById: { [key: string]: React.RefObject<HTMLElement> };
  setReferencesMap: (id: string, ref: React.RefObject<HTMLElement> | undefined) => void;
  hoveredElement?: HoveredElements;
  setHoveredElement: (hoveredElement?: HoveredElements) => void;
}

export const CanvasContext = createContext<CanvasContextType>({
  referencesById: {},
  setReferencesMap: () => {
    console.warn('CanvasContext not initialized');
  },
  setHoveredElement: () => {
    console.warn('CanvasContext not initialized');
  },
});

/* Internal API */
/* This is the API that should be used by components inside of the canvas */
/* This API is not supposed to be exposed to the outside world */

export const useCanvasInternalContext = () => {
  const { referencesById, setReferencesMap } = React.useContext(CanvasContext);

  const getOrCreateRefForId = useCallback(
    (id: string) => {
      if (referencesById[id]) {
        return referencesById[id];
      } else {
        const ref = createRef<HTMLElement>();
        setReferencesMap(id, ref);
        return ref;
      }
    },
    [referencesById, setReferencesMap],
  );

  const getOrCreateRefForPluginButton = useCallback(
    (groupId: string, pluginType: PluginTypes) => {
      const ref = getOrCreateRefForId(`${pluginType}-${groupId}`);
      return ref;
    },
    [getOrCreateRefForId],
  );

  return { getOrCreateRefForId, getOrCreateRefForPluginButton };
};

/* Public Exported API */
/* This is the API that should be used by components outside of the canvas */

export const CanvasContextConsumer = CanvasContext.Consumer;

interface CanvasContextProviderProps {
  children: React.ReactNode;
}

export const CanvasContextProvider = ({ children }: CanvasContextProviderProps) => {
  const [referencesById, setReferencesForId] = useState<{
    [key: string]: React.RefObject<HTMLElement>;
  }>({});

  const { hoveredElement, setHoveredElement } = useHoveredElement();

  const setReference = useCallback((id: string, ref: React.RefObject<HTMLElement> | undefined) => {
    setReferencesForId((prev) => {
      if (!ref) {
        delete prev[id];
        return { ...prev };
      }
      return { ...prev, [id]: ref };
    });
  }, []);

  return (
    <ReactFlowProvider>
      <CanvasContext.Provider
        value={{
          referencesById: referencesById,
          setReferencesMap: setReference,
          hoveredElement,
          setHoveredElement,
        }}
      >
        {children}
      </CanvasContext.Provider>
    </ReactFlowProvider>
  );
};
