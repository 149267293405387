import {
  UserDefinedRoleGroupsInterface,
  RoleGroupType,
  RoleLevel,
  Sections,
} from '@server/role-group-interface';
import { SweepRoles } from '../../../hooks/useUserInfo';

export const featureTranslations: { [key in (typeof Sections)[number]]: string } = {
  automations: 'Automations',
  alerts: 'Alerts',
  dedupMatching: 'Dedupe & Matching',
  rollups: 'Rollups',
  funnels: 'Funnel Editor',
  assignments: 'Assignments',
};

export const roleTranslations: { [key in RoleLevel]: string } = {
  DEPLOY: 'Deploy',
  VIEWER: 'Viewer',
  REQUEST_TO_DEPLOY: 'Request to deploy',
};

export const sortRoles = (roles: UserDefinedRoleGroupsInterface[]) => {
  const systemOrder = [
    SweepRoles.Admin,
    SweepRoles.Collaborator,
    SweepRoles.Alert_Manager,
    SweepRoles.Viewer,
  ];

  return [...roles].sort((a, b) => {
    const aIsCustom = a.type === RoleGroupType.USER_CREATED;
    const bIsCustom = b.type === RoleGroupType.USER_CREATED;

    if (aIsCustom && bIsCustom) {
      return a.name.localeCompare(b.name);
    }

    if (aIsCustom && !bIsCustom) {
      return -1;
    }

    if (!aIsCustom && bIsCustom) {
      return 1;
    }

    if (!aIsCustom && !bIsCustom) {
      return systemOrder.indexOf(a.name as SweepRoles) - systemOrder.indexOf(b.name as SweepRoles);
    }

    return 0;
  });
};
