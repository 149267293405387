import { Box } from '@mui/material';
import { RadioList } from '../../../../common/RadioList';
import { ObjectTypeChip } from '../../../../common/ObjectTypeChip';
import useObjectTypesWithFetch from '../../../../../hooks/useObjectTypesWithFetch';

interface FunnelToDeployProps {
  funnels: Funnel[];
  selectedFunnelId: string;
  setSelectedFunnelId: (id: string) => void;
  initialCrmOrgId: string;
}

export const FunnelToDeploy = ({
  funnels,
  selectedFunnelId,
  setSelectedFunnelId,
  initialCrmOrgId,
}: FunnelToDeployProps) => {
  const { objectTypesByName } = useObjectTypesWithFetch({ crmOrgId: initialCrmOrgId });
  const shortFunnelStructure = funnels.map((funnel) => {
    const objectType = funnel.funnelDetails.leadingObject.objectName;
    const objectLabel = objectTypesByName[objectType]?.label ?? objectType;
    return {
      value: funnel.id,
      label: funnel.name,
      decorator: (
        <ObjectTypeChip
          objectType={funnel.funnelDetails.leadingObject.objectName}
          label={objectLabel}
        />
      ),
    };
  });

  return (
    <Box sx={{ paddingTop: '24px' }}>
      <RadioList
        onChange={setSelectedFunnelId}
        value={selectedFunnelId}
        listItems={shortFunnelStructure}
      />
    </Box>
  );
};
