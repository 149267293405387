import { Box, Stack, styled } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { humanizeDate, humanizeDateVariants } from '../../../helpers/humanizeDate';
import { ReactNode } from 'react';

export const AiChatUserMessageBox = ({
  content,
  createdAt,
}: {
  content: string | ReactNode;
  createdAt: string;
}) => {
  return (
    <Stack gap={1}>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="caption" color={colors.grey[700]}>
          {createdAt
            ? humanizeDate({
                dateOrTimestamp: createdAt ?? '',
                variant: humanizeDateVariants.TIME_WITHOUT_DATE,
              })
            : ''}
        </Typography>
      </Box>
      <UserMessageBox>
        <Typography variant="body" color={colors.grey[800]}>
          {content}
        </Typography>
      </UserMessageBox>
    </Stack>
  );
};

const UserMessageBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  padding: '12px',
  borderRadius: '8px 8px 2px 8px',
  background: colors.storm[70],
  maxWidth: '356px',
  alignSelf: 'flex-end',
});
