import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../components/pages/environments/environmentsReducer';
import { useMemo } from 'react';

export const useIsSetupCompleted = () => {
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const isManagedPackageInstalled = crmOrg?.isManagedPackageInstalled;
  const isPermissionSetGroupAssigned = crmOrg?.isPermissionSetGroupAssigned;
  const isSetCompleted = useMemo(() => {
    return isManagedPackageInstalled && isPermissionSetGroupAssigned;
  }, [isManagedPackageInstalled, isPermissionSetGroupAssigned]);
  return !!isSetCompleted;
};
