import { ConfigurationCanvasPanelContent } from '../../../../../configuration-canvas-panel';
import { TemplatesPageHeader } from '../../../dialog/TemplatesPageHeader';
import { PluginDataManagementSvg } from '../../icons';
import { PluginDetail, VerticalFlexBox } from '../../PluginDetail';
import { useOnAddDataManagementPlugin } from '../useOnAddDataManagementPlugin';
import { dataManagementTexts } from './texts';

export const DataManagementPage = ({ onBackClick }: { onBackClick: () => void }) => {
  const onAddDataManagementPlugin = useOnAddDataManagementPlugin();

  return (
    <>
      <TemplatesPageHeader
        onBackClick={onBackClick}
        title={'Data Management Plug-in'}
        onButtonClick={onAddDataManagementPlugin}
        buttonText="Add to Funnel"
        imageIcon={<PluginDataManagementSvg />}
      />
      <ConfigurationCanvasPanelContent>
        <VerticalFlexBox gap={3}>
          <PluginDetail
            gap={1}
            title={dataManagementTexts.title}
            description={dataManagementTexts.description}
          />
          <VerticalFlexBox gap={2}>
            <PluginDetail
              title="Step Date Time:"
              description="The date at which the step was started."
            />
            <PluginDetail
              title="Step Duration:"
              description="The amount of time that it took to complete the step."
            />
            <PluginDetail
              title="Step First Date Time:"
              description="The first date and time at which the step was started."
            />
            <PluginDetail
              title="Step Owner:"
              description="The person who is responsible for completing the step."
            />
            <PluginDetail
              title="Step Owner ID:"
              description="The ID of the person who is responsible for completing the step."
            />
            <PluginDetail
              title="Owner role:"
              description="The role of the person who is responsible for completing the step."
            />
          </VerticalFlexBox>
        </VerticalFlexBox>
      </ConfigurationCanvasPanelContent>
    </>
  );
};
