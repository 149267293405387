import { Box, Stack } from '@mui/material';
import { Button, Typography } from '@sweep-io/sweep-design';
import usePaywall from './usePaywall';
import PinkButton from './PinkButton';
import { ReactNode, useState } from 'react';
import Star from '../../../icons/svg/star.svg?react';
import { telemetry } from '../../../telemetry';
import { PaywallSource } from './paywallTypes';

const StartTrial = ({
  title,
  text,
  source,
  onFinishCb,
  onConfirmCb,
  isDialogLayout,
}: {
  title: string | ReactNode;
  text: string | ReactNode;
  source: PaywallSource;
  onConfirmCb?: () => void;
  onFinishCb?: () => void;
  isDialogLayout: boolean;
}) => {
  const { startTrial, openIntercomForUpgrade } = usePaywall();
  const [isLoading, setIsLoading] = useState(false);

  const onClick = async () => {
    setIsLoading(true);
    try {
      await startTrial(source);
      onConfirmCb && onConfirmCb();
    } catch (e) {
      telemetry.captureError(e);
    }
    setIsLoading(false);
    onFinishCb && onFinishCb();
  };

  const buttonsJsx = (
    <>
      <Button startIconName="Chats" onClick={openIntercomForUpgrade} variant="outlined">
        Chat with us
      </Button>
      <Box data-testid="start-trial-dialog-button">
        <PinkButton startIconName="Star" onClick={onClick} loading={isLoading}>
          Start free trial
        </PinkButton>
      </Box>
    </>
  );

  if (isDialogLayout) {
    return (
      <Stack p={3} justifyContent="center">
        <Stack flex={1} mt="40px" justifyContent="center">
          <Box mb={2}>
            <Typography variant="h1">{title}</Typography>
          </Box>
          <Box mb={1}>
            <Typography variant="body">{text}</Typography>
          </Box>
        </Stack>
        <Box display="flex" gap={2} justifyContent="flex-end" alignItems="center">
          {buttonsJsx}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack alignItems="center">
      <Box mb={1.5}>
        <Star />
      </Box>
      <Box mb={3} textAlign="center">
        <Typography variant="h1">{title}</Typography>
      </Box>
      <Box mb={6} textAlign="center">
        <Typography variant="body">{text}</Typography>
      </Box>
      <Box display="flex" gap={2} justifyContent="center" alignItems="center">
        {buttonsJsx}
      </Box>
    </Stack>
  );
};

export default StartTrial;
