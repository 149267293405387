// Do not put any dispatcher here.
// Only pure API calls
// The Facades should mimic exactly the corresponding API.

import { useCallback } from 'react';
import { useSweepApi } from '../sweep';

export const useStageMetadataFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_allStagesStageMetadata: useCallback(
      async (leadingFieldIds: string[], crmOrgId: string) => {
        const response = await sweepApi.get(
          `/stages?leadingFieldIds=${leadingFieldIds}&crmOrgId=${crmOrgId}`,
        );
        return response.data;
      },
      [sweepApi],
    ),
    get_singleStageMetadata: useCallback(
      async ({
        leadingFieldId,
        stageName,
        crmOrgId,
      }: {
        leadingFieldId: string;
        stageName: string;
        crmOrgId: string;
      }) => {
        const response = await sweepApi.get(
          `/stages/${leadingFieldId}/metadata/${stageName}?crmOrgId=${crmOrgId}`,
        );
        return response.data as {
          funnels: { id: string; name: string }[];
          stageMetadata?: StageMetadata;
        };
      },
      [sweepApi],
    ),
    get_opportunityList: useCallback(
      async (crmOrgId: string) => {
        const response = await sweepApi.get(
          `/webapp/config/opportunity-stage-options?crmOrgId=${crmOrgId}`,
        );
        return response.data;
      },
      [sweepApi],
    ),
  };
};
