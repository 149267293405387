import { createSelector, PayloadAction } from '@reduxjs/toolkit';
import { clearFirstConditionIfEmpty } from '../../components/common/rule-builder/helpers';
import { funnelDetailModel } from '../../models/funnelDetailModel';
import SweepStagesModel from '../../models/stagesModel';
import { UnitedCanvasReducerState } from './unitedCanvasReducer';
import { RootState } from '..';
import { FunnelType } from '../../types/enums/FunnelType';

export const sfFunnelReducer = {
  updateFunnelMapSfSettings: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelMap: Pick<FunnelMap, 'name' & 'defaultCrmOrgId'>;
      funnelsData: FunnelsData;
    }>,
  ) => {
    const { funnelMap: partialFunnelMap, funnelsData } = action.payload;

    const oldFunnelMap = state.funnelMap;
    const funnelIds = Object.keys(oldFunnelMap.funnels);

    funnelIds.forEach((id) => {
      if (!funnelsData[id]) {
        delete oldFunnelMap.funnels[id];
      }
    });
    const newFunnelMap = {
      ...oldFunnelMap,
      ...partialFunnelMap,
    };

    state.funnelMap = newFunnelMap;
    state.funnelMap.funnelsData = funnelsData;
  },

  moveSfStages: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      stagesPositionChanges: {
        stageId: string;
        newPosition: { row: number; column: number };
      }[];
    }>,
  ) => {
    const funnelDetail = state.funnelMap.funnelsData[action.payload.funnelId].funnelDetails;

    if (funnelDetail) {
      const funnelModel = funnelDetailModel(funnelDetail);
      action.payload.stagesPositionChanges.forEach((change) => {
        const { column: col, row } = change.newPosition;
        funnelModel.stageById(change.stageId).setPosition(col, row);
      });
    }
  },
  addSfStage: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      newStage: SweepStage;
      stagesPositionChanges: {
        stageId: string;
        newPosition: { row: number; column: number };
      }[];
      stagesNextStageChanges: {
        stageId: string;
        exitCriteriaId: string;
        newNextStageId: string;
      }[];
      newExitCriteria?: {
        stageId: string;
        nextStageId: string;
      };
      newFunnel?: {
        id: string;
        leadingObject: FunnelLeadingObject;
        name: string;
        position: FunnelMapPosition;
      };
    }>,
  ) => {
    const { stagesPositionChanges, stagesNextStageChanges, newStage, newExitCriteria, newFunnel } =
      action.payload;
    if (newFunnel) {
      state.funnelMap.funnels[newFunnel.id] = {
        position: newFunnel.position,
      };

      // TODO: Remove this and replace by the New Funnel Flow
      state.funnelMap.funnelsData[newFunnel.id] = {
        accountId: 'temp',
        description: '',
        funnelDetails: {
          stages: [],
          _firstStageId: '',
          leadingObject: newFunnel.leadingObject,
          plugins: {},
        },
        createdAt: '',
        createdById: '',
        id: newFunnel.id,
        name: newFunnel.name,
        snapshotsIds: [],
        updatedAt: '',
        updatedById: '',
        recordType: {
          description: '',
          label: newFunnel.name,
          name: newFunnel.name,
          objectName: newFunnel.leadingObject.objectName,
        },
      };
    }

    const funnelDetail = state.funnelMap.funnelsData[action.payload.funnelId].funnelDetails;

    if (funnelDetail) {
      const funnelModel = funnelDetailModel(funnelDetail);

      // Adds the stage
      funnelModel.addStage(newStage);

      // Modifies possible stages positions
      stagesPositionChanges.forEach((change) => {
        const { column: col, row } = change.newPosition;
        funnelModel.stageById(change.stageId).setPosition(col, row);
      });

      // Modifies possible exit criteria next stages
      stagesNextStageChanges.forEach((change) => {
        funnelModel
          .stageById(change.stageId)
          .getExitCriteriaByIdOrUndefined(change.exitCriteriaId)
          ?.setNextStageId(change.newNextStageId);
      });

      // Adds a new exitCriteria if exists
      if (newExitCriteria) {
        funnelModel.stageById(newExitCriteria.stageId).createExitCriteria({
          _nextStageId: newExitCriteria.nextStageId,
        });
      }
    }
  },
  moveSfFunnels: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<
      {
        funnelId: string;
        newPosition: { row: number; column: number };
      }[]
    >,
  ) => {
    const funnels = state.funnelMap.funnels;

    action.payload.forEach((change) => {
      if (funnels[change.funnelId]) {
        funnels[change.funnelId].position = change.newPosition;
      }
    });
  },
  connectSfStages: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      stageA: {
        funnelId: string;
        stageId: string;
      };
      stageB: {
        funnelId: string;
        stageId?: string;
        funnelType: FunnelType;
      };
    }>,
  ) => {
    const { stageA, stageB } = action.payload;
    const funnelDetail = state.funnelMap.funnelsData[stageA.funnelId].funnelDetails;
    if (!funnelDetail) {
      return;
    }
    if (stageA.funnelId === stageB.funnelId) {
      new SweepStagesModel(funnelDetail.stages)
        .stageByIdOrUndefined(stageA.stageId)
        ?.createExitCriteria({ _nextStageId: stageB.stageId });
    } else {
      new SweepStagesModel(funnelDetail.stages)
        .stageByIdOrUndefined(stageA.stageId)
        ?.connectToStageInFunnel({
          funnelId: stageB.funnelId,
          stageId: stageB.stageId,
          _type: stageB.funnelType,
        });
    }
  },
  addSfFunnel: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{ funnel: Funnel; position: FunnelMapPosition }>,
  ) => {
    const { funnelsData, funnels } = state.funnelMap;
    const { position, funnel } = action.payload;
    funnelsData[funnel.id] = funnel;
    funnels[funnel.id] = { position };
  },
  addMultipleSfFunnels: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{ funnel: Funnel; position: FunnelMapPosition }[]>,
  ) => {
    const { funnelsData, funnels } = state.funnelMap;
    action.payload.forEach(({ funnel, position }) => {
      funnelsData[funnel.id] = funnel;
      funnels[funnel.id] = { position };
    });
  },

  removeFunnel: (state: UnitedCanvasReducerState, action: PayloadAction<{ funnelId: string }>) => {
    const { funnelsData, funnels } = state.funnelMap;
    delete funnelsData[action.payload.funnelId];
    delete funnels[action.payload.funnelId];
  },
  setExitCriteria: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      stageId: string;
      newExitCriteria: SweepExitCriteria;
    }>,
  ) => {
    const { funnelId, stageId, newExitCriteria } = action.payload;
    const funnelDetail = state.funnelMap.funnelsData[funnelId].funnelDetails;

    if (!funnelDetail) {
      return;
    }

    const stageIdx = funnelDetail.stages.findIndex((stage) => stage._stageId === stageId);
    if (stageIdx !== -1) {
      const ecId = funnelDetail.stages[stageIdx].exitCriteria.findIndex(
        (eC) => eC._exitCriteriaId === newExitCriteria._exitCriteriaId,
      );
      if (ecId !== -1) {
        funnelDetail.stages[stageIdx].exitCriteria[ecId] =
          clearFirstConditionIfEmpty(newExitCriteria);
      }
    }
  },
  removeExitCriteriaById: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      exitCriteriaId: string;
      stageId: string;
    }>,
  ) => {
    const { stageId, exitCriteriaId, funnelId } = action.payload;
    const funnelDetail = state.funnelMap.funnelsData[funnelId].funnelDetails;

    if (funnelDetail) {
      const funnelModel = funnelDetailModel(funnelDetail);
      funnelModel.removeExitCriteriaById(stageId, exitCriteriaId);
    }
  },
  applyNewStageData: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      stage: SweepStage;
    }>,
  ) => {
    const { funnelId, stage } = action.payload;
    const funnelsData = state.funnelMap.funnelsData;
    const funnelDetails = state.funnelMap.funnelsData[funnelId]?.funnelDetails;

    if (funnelDetails && funnelsData) {
      const funnelModel = funnelDetailModel(funnelDetails);
      funnelModel.updateStage(stage);
      funnelModel.updateStageAndRemoveEmptyCriteria(stage);
      funnelsData[funnelId].funnelDetails = funnelModel.toJSON();

      if (state.funnelMap?.funnelsData) {
        state.funnelMap.funnelsData = funnelsData;
      }
    }
  },

  removeStage: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      stage: SweepStage;
      funnelId: string;
    }>,
  ) => {
    const { stage, funnelId } = action.payload;
    const funnelsData = state.funnelMap?.funnelsData;
    const funnelDetails = state.funnelMap?.funnelsData[funnelId]?.funnelDetails;

    if (funnelDetails && funnelsData) {
      const funnelModel = funnelDetailModel(funnelDetails);
      funnelModel.removeStage(stage._stageId);

      if (state.funnelMap?.funnelsData) {
        state.funnelMap.funnelsData = funnelsData;
      }
    }
  },
  removeStepFunnelConnection: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      stageId: string;
      targetStageId: string;
      targetFunnelId: string;
    }>,
  ) => {
    const { funnelId, stageId, targetStageId, targetFunnelId } = action.payload;

    const funnelDetails = state.funnelMap?.funnelsData[funnelId]?.funnelDetails;

    if (funnelDetails) {
      funnelDetailModel(funnelDetails)
        .stageByIdOrUndefined(stageId)
        ?.removeFunnelLink(targetFunnelId, targetStageId);
    }
  },
  updateFunnelDescription: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{ funnelId: string; description: string }>,
  ) => {
    const { funnelId, description } = action.payload;
    if (state.funnelMap?.funnelsData[funnelId]) {
      state.funnelMap.funnelsData[funnelId].description = description;
    }
  },
  updateFunnelName: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{ funnelId: string; name: string }>,
  ) => {
    const { funnelId, name } = action.payload;
    if (state.funnelMap?.funnelsData[funnelId]) {
      state.funnelMap.funnelsData[funnelId].name = name;
    }
  },
  setPlugin: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{
      funnelId: string;
      plugin: DataManagementPlugin | BantPlugin | DynamicPathPlugin;
    }>,
  ) => {
    const { funnelId, plugin } = action.payload;
    if (state.funnelMap?.funnelsData[funnelId].funnelDetails) {
      state.funnelMap.funnelsData[funnelId].funnelDetails.plugins = {
        ...state.funnelMap.funnelsData[funnelId].funnelDetails.plugins,
        [plugin.id]: plugin,
      };
    }
  },
  removePlugin: (
    state: UnitedCanvasReducerState,
    action: PayloadAction<{ funnelId: string; pluginId: PluginTypes }>,
  ) => {
    const { funnelId, pluginId } = action.payload;
    if (state.funnelMap?.funnelsData[funnelId].funnelDetails) {
      delete state.funnelMap.funnelsData[funnelId].funnelDetails.plugins[pluginId];
    }
  },
};

export const selectFunnelMapSfFunnelById = (funnelId: string) => (state: RootState) =>
  state.unitedCanvas.present.funnelMap?.funnelsData[funnelId];

export const selectFunnelsData = (state: RootState) =>
  state.unitedCanvas.present.funnelMap?.funnelsData;

const selectFunnels = (state: RootState) => state.unitedCanvas.present.funnelMap?.funnels;

export const selectFunnelIds = createSelector([selectFunnels], (funnels) =>
  Object.keys(funnels ?? {}),
);

export const selectSfFunnelStageName = (funnelLink: FunnelLink) => (state: RootState) =>
  state.unitedCanvas.present.funnelMap?.funnelsData[funnelLink.funnelId]?.funnelDetails.stages.find(
    (stage) => stage._stageId === funnelLink.stageId,
  )?.stageName;

export const selectSfStageById = (funnelId?: string, stageId?: string) => (state: RootState) => {
  if (!funnelId || !stageId) return;
  return state.unitedCanvas.present.funnelMap?.funnelsData[funnelId]?.funnelDetails.stages.find(
    (stage) => stage._stageId === stageId,
  );
};
export const selectSfGateById =
  (funnelId?: string, stageId?: string, exitCriteriaId?: string) => (state: RootState) => {
    if (!funnelId || !stageId || !exitCriteriaId) return;
    const funnel = selectFunnelMapSfFunnelById(funnelId)(state);
    const stage = funnel?.funnelDetails.stages.find((stage) => stage._stageId === stageId);
    return stage?.exitCriteria.find((ec) => ec._exitCriteriaId === exitCriteriaId);
  };

export const selectSfFunnelPlugin = (funnelId: string, pluginId: PluginTypes) =>
  createSelector([selectFunnelsData], (funnelsData) => {
    return funnelsData?.[funnelId]?.funnelDetails.plugins[pluginId];
  });
