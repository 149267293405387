import * as React from "react";
const SvgNewLead = (props) => /* @__PURE__ */ React.createElement("svg", { width: 65, height: 36, viewBox: "0 0 65 36", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M24.425 22H30.425M27.425 19V25M2.20004 30C3.51411 28.4338 5.15525 27.1744 7.00812 26.3104C8.86099 25.4463 10.8806 24.9986 12.925 24.9986C14.9695 24.9986 16.9891 25.4463 18.842 26.3104C20.6948 27.1744 22.336 28.4338 23.65 30M20.425 17.5C20.425 21.6421 17.0672 25 12.925 25C8.78291 25 5.42504 21.6421 5.42504 17.5C5.42504 13.3579 8.78291 10 12.925 10C17.0672 10 20.425 13.3579 20.425 17.5Z", stroke: "#4B4B4B", style: {
  stroke: "#4B4B4B",
  stroke: "color(display-p3 0.2941 0.2941 0.2941)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("rect", { x: 28.6667, width: 24, height: 24, rx: 12, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("g", { clipPath: "url(#clip0_338_11159)" }, /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M38.1856 8.2959H33.9294C33.0479 8.2959 32.3333 9.01046 32.3333 9.89196C32.3333 10.7735 33.0479 11.488 33.9294 11.488H38.1856C39.0671 11.488 39.7816 10.7735 39.7816 9.89196C39.7816 9.01046 39.0671 8.2959 38.1856 8.2959Z", fill: "#36C5F0", style: {
  fill: "#36C5F0",
  fill: "color(display-p3 0.2118 0.7725 0.9412)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M44.0378 9.89177V5.63561C44.0378 4.75411 43.3232 4.03955 42.4417 4.03955C41.5602 4.03955 40.8457 4.75411 40.8457 5.63561V9.89177C40.8457 10.7733 41.5602 11.4878 42.4417 11.4878C43.3232 11.4878 44.0378 10.7733 44.0378 9.89177Z", fill: "#2EB67D", style: {
  fill: "#2EB67D",
  fill: "color(display-p3 0.1804 0.7137 0.4902)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.4417 15.7434H46.6979C47.5794 15.7434 48.2939 15.0288 48.2939 14.1473C48.2939 13.2658 47.5794 12.5513 46.6979 12.5513H42.4417C41.5602 12.5513 40.8457 13.2658 40.8457 14.1473C40.8457 15.0288 41.5602 15.7434 42.4417 15.7434Z", fill: "#ECB22E", style: {
  fill: "#ECB22E",
  fill: "color(display-p3 0.9255 0.6980 0.1804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M36.5895 14.1473V18.4035C36.5895 19.285 37.3041 19.9995 38.1856 19.9995C39.0671 19.9995 39.7816 19.285 39.7816 18.4035V14.1473C39.7816 13.2658 39.0671 12.5513 38.1856 12.5513C37.3041 12.5513 36.5895 13.2658 36.5895 14.1473Z", fill: "#E01E5A", style: {
  fill: "#E01E5A",
  fill: "color(display-p3 0.8784 0.1176 0.3529)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M38.1856 4.03955C37.3041 4.03955 36.5895 4.75411 36.5895 5.63561C36.5895 6.51711 37.3041 7.23167 38.1856 7.23167H39.7816V5.63561C39.7816 4.75411 39.0671 4.03955 38.1856 4.03955Z", fill: "#36C5F0", style: {
  fill: "#36C5F0",
  fill: "color(display-p3 0.2118 0.7725 0.9412)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M48.2939 9.89196C48.2939 9.01046 47.5794 8.2959 46.6979 8.2959C45.8164 8.2959 45.1018 9.01046 45.1018 9.89196V11.488H46.6979C47.5794 11.488 48.2939 10.7735 48.2939 9.89196Z", fill: "#2EB67D", style: {
  fill: "#2EB67D",
  fill: "color(display-p3 0.1804 0.7137 0.4902)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M42.4417 19.9997C43.3232 19.9997 44.0378 19.2852 44.0378 18.4037C44.0378 17.5222 43.3232 16.8076 42.4417 16.8076H40.8457V18.4037C40.8457 19.2852 41.5602 19.9997 42.4417 19.9997Z", fill: "#ECB22E", style: {
  fill: "#ECB22E",
  fill: "color(display-p3 0.9255 0.6980 0.1804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M32.3333 14.1473C32.3333 15.0288 33.0479 15.7434 33.9294 15.7434C34.8109 15.7434 35.5255 15.0288 35.5255 14.1473V12.5513H33.9294C33.0479 12.5513 32.3333 13.2658 32.3333 14.1473Z", fill: "#E01E5A", style: {
  fill: "#E01E5A",
  fill: "color(display-p3 0.8784 0.1176 0.3529)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("clipPath", { id: "clip0_338_11159" }, /* @__PURE__ */ React.createElement("rect", { width: 16, height: 16, fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, transform: "translate(32.3333 4)" }))));
export default SvgNewLead;
