import { memo } from 'react';

interface FloatingEdgeConnectionProps {
  id: string;
  sourceEdgeColor: string;
  targetEdgeColor: string;
  connectionPath: string;
  arrowPath: string;
  arrowCirclePath: string;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  pathReference?: React.RefObject<SVGPathElement>;
  strokeWidth: number;
}

const FloatingEdgeConnection = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourceEdgeColor,
  targetEdgeColor,
  pathReference,
  connectionPath,
  arrowPath,
  arrowCirclePath,
  strokeWidth,
}: FloatingEdgeConnectionProps) => {
  const gradientId = `paint-linear-${sourceX}-${sourceY}-${targetX}-${targetY}-${id}`;

  return (
    <>
      <defs>
        <linearGradient
          id={gradientId}
          x1={sourceX}
          y1={sourceY}
          x2={targetX}
          y2={targetY}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={sourceEdgeColor} />
          <stop offset="1" stopColor={targetEdgeColor} />
        </linearGradient>
      </defs>
      <path
        ref={pathReference}
        d={connectionPath}
        fill="none"
        stroke={`url(#${gradientId})`}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        opacity="0.8"
      />
      <path
        d={arrowPath}
        stroke={targetEdgeColor}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="none"
      />
      <path
        d={arrowCirclePath}
        stroke={sourceEdgeColor}
        fill={sourceEdgeColor}
        className="bullet"
      />
    </>
  );
};

export default memo(FloatingEdgeConnection);
