import Stack from '@mui/material/Stack';
import { Typography, colors } from '@sweep-io/sweep-design';
import CloudDisconnectedIllustration from '../../icons/svg/CloudDisconnectedIllustration.svg?react';
import OnboardingLayout from './onboarding/OnboardingLayout';

export const GeneralError = () => {
  return (
    <OnboardingLayout withLogout={true}>
      <Stack height="100%" alignItems="center" justifyContent="center" gap={2}>
        <CloudDisconnectedIllustration />
        <Stack display="flex" justifyContent="center" alignItems="center" gap={1}>
          <Typography variant="h2">Something went wrong</Typography>
          <Typography variant="body" color={colors.grey[800]}>
            Please refresh this page or try again later
          </Typography>
        </Stack>
      </Stack>
    </OnboardingLayout>
  );
};
