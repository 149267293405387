import { Box, Stack, TextField } from '@mui/material';
import { useDependencies } from '../hooks/useDependencies';
import { Panel } from './atoms/Panel';
import { BackButton } from './atoms/BackButton';
import {
  __SWEEP_DESIGN_TYPOGRAPHY_STYLES___,
  colors,
  Tag as TagComponent,
  Typography,
} from '@sweep-io/sweep-design';
import { Tag } from '../../../../apis/facades/useUserInputsApiFacade';
import { ActionOption, ButtonWithActionMenu } from './atoms/ButtonWithActionMenu';
import { useCallback, useState } from 'react';
import { useTags } from '../annotations/tags/useTags';
import ConfirmDestructiveDialog from '../../../common/dialogs/ConfirmDestructiveDialog';
import useConfirm from '../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { DotDivider } from '../../../common/DotDivider';

interface TagsHeaderProps {
  tag: Tag;
  crmOrgId: string;
  tagType: TagType;
  userEmail?: string;
}

export enum TagType {
  user = 'user',
  tag = 'tag',
}

const deleteTexts = {
  [TagType.user]: {
    cta: 'Unlink user',
    text: 'Unlinking this user will remove their associations from all configurations where they are assigned.',
  },
  [TagType.tag]: {
    cta: 'Delete tag',
    text: 'Deleting this tag will remove it from all configurations where its used.',
  },
};

export const TagsHeader = ({ tag, crmOrgId, tagType, userEmail }: TagsHeaderProps) => {
  const [editName, setEditName] = useState<string>();
  const [isDelete, setIsDelete] = useState(false);

  const { openConfirm, onConfirm, onCancel } = useConfirm();

  const { onDeleteTag, onUpdateTag } = useTags(crmOrgId);
  const { onGoBackInHistory } = useDependencies(crmOrgId);

  const isTag = tagType === TagType.tag;
  const isUser = tagType === TagType.user;
  const noEditActions = isUser;

  const updateName = (newName?: string) => {
    if (newName !== tag.name && newName) {
      const newTag = { ...tag, name: newName };
      onUpdateTag(newTag);
    }

    setEditName(undefined);
  };

  const updateColor = useCallback(
    (newColor: string) => {
      if (newColor !== tag.color) {
        const newTag = { ...tag, color: newColor };
        onUpdateTag(newTag);
      }
    },
    [tag, onUpdateTag],
  );

  return (
    <Panel>
      <BackButton onClick={onGoBackInHistory} />
      <Box flex={1} display="flex" justifyContent="space-between" alignItems="flex-start" gap={2}>
        <Box display="flex" flexDirection="column" gap={1} width="100%">
          {!editName && (
            <Box
              onClick={() => !noEditActions && setEditName(tag.name)}
              sx={{ cursor: noEditActions ? 'default' : 'pointer' }}
            >
              <Typography variant="h3" whiteSpace="break-spaces" wordBreak="break-word">
                {tag.name}
              </Typography>
            </Box>
          )}

          {Boolean(editName) && (
            <TextField
              disabled={!editName}
              fullWidth
              variant="standard"
              value={editName ?? tag.name}
              onChange={(e) => setEditName(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && updateName(editName)}
              onBlur={() => editName && updateName(editName)}
              sx={{
                whiteSpace: 'break-spaces',
                wordBreak: 'break-word',
                input: {
                  fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h3']?.fontSize,
                  fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h3']?.fontWeight,
                  lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h3']?.lineHeight,
                  padding: 0,
                },
              }}
            />
          )}

          {isTag && (
            <Typography variant="caption" color={colors.grey[700]}>
              Tag
            </Typography>
          )}

          {isUser && (
            <Stack direction="row" alignItems="center" gap={1}>
              <TagComponent label="Sweep User" />
              <DotDivider />
              <Typography variant="caption" color={colors.grey[700]}>
                {userEmail}
              </Typography>
            </Stack>
          )}
        </Box>

        <ButtonWithActionMenu
          deleteTitle={deleteTexts[tagType].cta}
          crmOrgId={crmOrgId}
          options={
            noEditActions
              ? [ActionOption.delete]
              : [ActionOption.changeName, ActionOption.changeColor, ActionOption.delete]
          }
          onChangeName={() => {
            setEditName(tag.name);
          }}
          onChangeColor={updateColor}
          onDelete={async () => {
            const isConfirmed = await openConfirm(
              <ConfirmDestructiveDialog
                open
                onConfirm={onConfirm}
                onClose={onCancel}
                title="Are you sure?"
                confirmText={deleteTexts[tagType].cta}
                cancelText="Cancel"
              >
                {deleteTexts[tagType].text}
              </ConfirmDestructiveDialog>,
            );

            if (isConfirmed) {
              setIsDelete(true);
              await onDeleteTag(tag.id, tagType);
              onGoBackInHistory();
            }
          }}
          selectedColor={tag.color}
          showLoader={isDelete}
        />
      </Box>
    </Panel>
  );
};
