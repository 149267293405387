export enum SelectorValueTypes {
  LITERAL = 'Literal',
  REFERENCE = 'Reference',
  RELATIVE = 'Relative',
  FORMULA = 'Formula',
  TERRITORY = 'Territory',
  PRIOR = 'Prior',
  VERIFY_URL = 'VerifyUrlResult',
  FROM_ANOTHER_OBJECT = 'ValueFromAnotherObject',
}
