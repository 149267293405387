import spaceHorizontal from './images/spaceHorizontal.jpg';
import dedupe from './images/dedupe.png';
import documentation from './images/documentation.png';
import automate from './images/automate.png';
import alerts from './images/alerts.png';

export enum WizardSteps {
  WELCOME = 'WELCOME',
  DEDUPE = 'DEDUPE',
  DOCUMENTATION = 'DOCUMENTATION',
  AUTOMATE = 'AUTOMATE',
  ALERTS = 'ALERTS',
}

//The order of the keys is the order of wizard steps
export const WIZARD_STEPS: {
  [key in WizardSteps]: { title: string; content1: string; content2?: string; imgSrc: string };
} = {
  [WizardSteps.WELCOME]: {
    title: 'Welcome to your Sweep trial!',
    content1: `Turn Salesforce into a visual workspace so you can manage, customize, and optimize your entire configuration efficiently and effectively.`,
    content2: `Let's get started.`,
    imgSrc: spaceHorizontal,
  },
  [WizardSteps.DOCUMENTATION]: {
    title: 'Enable AI-Powered Documentation',
    content1: `Keep track of everything, automatically. Create custom object documentation so you can maintain a source of truth that's always up-to-date.`,
    imgSrc: documentation,
  },
  [WizardSteps.AUTOMATE]: {
    title: 'Automate Everything',
    content1: `Save time and increase productivity by turning key pipeline events like step changes, record updates, dates and time-in-step duration into action triggers.`,
    imgSrc: automate,
  },
  [WizardSteps.DEDUPE]: {
    title: 'Apply Dedupe & Matching Rules',
    content1: `Design multiple rules for any combination of leads, contacts, and accounts. Include tie breakers, filters, and timing control to support your data needs.`,
    imgSrc: dedupe,
  },

  [WizardSteps.ALERTS]: {
    title: 'Create Actionable Alerts',
    content1: `Build automated notifications to keep everyone updated on Slack, email, or wherever your team communicates.`,
    imgSrc: alerts,
  },
  //The following steps were removed from the wizard, keeping it here in case they return

  // [WizardSteps.DEPLOY]: {
  //   title: 'Drag, Drop, and Deploy',
  //   content1: `Our easy-to-use interface gives you the power to customize funnels and deploy them to Salesforce, in a click.`,
  //   imgSrc: deploy,
  // },
  //
  // [WizardSteps.INSIGHT]: {
  //   title: 'Access Insights Instantly',
  //   content1: `Create custom reports and dashboards for every sales motion in a matter of clicks.`,
  //   imgSrc: insight,
  // },
  //
  //
  // [WizardSteps.ASSIGNMENTS]: {
  //   title: 'Customize Assignment Rules',
  //   content1: `Create rules that support any scenario, work on any Salesforce object, and connect the right lead with the right person, fast.`,
  //   imgSrc: assignments,
  // },
};

export const TRIAL_TEXT_PRE = 'Start a free 14-day trial to use premium features like';

export const ACTIVATE_TRIAL_COOKIE_NAME = 'activateTrial';
export const ACTIVATE_TRIAL_QUERY_PARAM = 'activateTrial';
