import { Outlet } from 'react-router';
import useGlobalDataFetcherListener from '../../hooks/global-reducer/useGlobalDataFetcherListener';
import useSendPageVisitEvents from '../../hooks/useSendPageVisitEvents';
import useTrackPageViews from '../../hooks/useTrackPageViews';
import useUpdatePageTitles from '../../hooks/useUpdatePageTitles';
import useOnboardingNavigation from '../../hooks/useOnboardingNavigation';
import useAutoActivateTrial from '../../hooks/useAutoActivateTrial';
import useHandleMessages from '../../hooks/useHandleMessages';
import useTestsHelper from '../../hooks/useTestsHelper';
import useWebSockets from '../../webSockets/useWebSockets';
import { useSweepRoleGroups } from '../common/useSweepRoleGroups';
import useLoadAccount from '../../hooks/useLoadAccount';

const Home = () => {
  useSendPageVisitEvents();
  useTrackPageViews();
  useUpdatePageTitles();
  useAutoActivateTrial();
  useOnboardingNavigation();
  useGlobalDataFetcherListener();
  useHandleMessages();
  useTestsHelper();
  useWebSockets();
  useSweepRoleGroups();
  useLoadAccount();

  return <Outlet />;
};

export default Home;
