import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import useQueryParams from '../../../../hooks/useQueryParams';
import { closeFieldsDialog } from '../../../../reducers/funnel-flow-page/fieldsReducer';
import { closeStageDialog } from '../../../../reducers/funnel-flow-page/stageDialogReducer';
import {
  selectIsPageLoading,
  selectHasFetchingErrors,
} from '../../../../reducers/multiFunnelFlowNoHistoryReducer';
import { selectIsNewFunnelDialogOpen } from '../../../../reducers/newFunnelReducer';
import { FunnelFlowModes } from '../../../../types/enums/FunnelFlowModes';
import { usePanels } from '../../../panels/PanelsContext';
import {
  selectIsFunnelSettingsDialogOpened,
  closeFunnelSettingsDialog,
} from '../../funnel-map-flow/dialogs/funnel-settings-dialog/funnelSettingsReducer';

const usePageHelpers = () => {
  const dispatch = useDispatch();
  const { forceCloseAllPanels } = usePanels();
  const isNewFunnelDialogOpen = useSelector(selectIsNewFunnelDialogOpen);
  const isFunnelSettingsOpen = useSelector(selectIsFunnelSettingsDialogOpened);
  const isLoading = useSelector(selectIsPageLoading);
  const hasFetchingErrors = useSelector(selectHasFetchingErrors);

  const { funnelId, funnelMapId } = useParams<{ funnelId: string; funnelMapId: string }>();

  const query = useQueryParams();

  const crmOrgId = query.get('crmOrgId');
  const snapshotId = query.get('snapshotId') ?? undefined;
  const deploymentId = query.get('deploymentId') ?? undefined;
  const enableRestore = query.get('enableRestore') === 'true';

  const closeDialogs = useCallback(() => {
    dispatch(closeFieldsDialog());
    dispatch(closeStageDialog());
    dispatch(closeFunnelSettingsDialog());
    forceCloseAllPanels();
  }, [dispatch, forceCloseAllPanels]);

  const shouldDisplayAddNewFunnel = !isNewFunnelDialogOpen && !isFunnelSettingsOpen;

  const getFunnelFlowMode = () => {
    if (snapshotId && crmOrgId && deploymentId) {
      return FunnelFlowModes.DEPLOYMENT;
    }

    if (snapshotId && crmOrgId) {
      return FunnelFlowModes.NOT_DEPLOYED;
    }

    return FunnelFlowModes.EDIT;
  };

  return {
    funnelId,
    funnelMapId,
    crmOrgId,
    snapshotId,
    deploymentId,
    enableRestore,
    shouldDisplayAddNewFunnel,
    isLoading,
    hasFetchingErrors,

    closeDialogs,
    getFunnelFlowMode,
  };
};

export { usePageHelpers };
