import { Box, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';
import { HORIZONTAL_PADDING } from './consts';

interface ConfigurationCanvasPanelContentProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

export const ConfigurationCanvasPanelContent: React.FC<ConfigurationCanvasPanelContentProps> = ({
  children,
  sx,
}) => {
  return (
    <Box
      sx={{
        padding: `0 ${HORIZONTAL_PADDING}px`,
        flex: '1 1 auto',
        overflowY: 'visible',
        ...sx,
      }}
      className="configuration-canvas-panel-content"
    >
      {children}
    </Box>
  );
};
