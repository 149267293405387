import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';
import { useMemo } from 'react';

const useIsManagedPackageInstalled = () => {
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);
  const isManagedPackagedInstalled = useMemo(
    () => defaultCreationEnvironment?.isManagedPackageInstalled,
    [defaultCreationEnvironment],
  );

  return !!isManagedPackagedInstalled;
};

export default useIsManagedPackageInstalled;
