import { useCallback } from 'react';
import { useSweepApi } from '../sweep';

export type UpdateUserAndAccountDetails = {
  accountName: string;
  userName: string;
};

const useDashboardApiFacade = () => {
  const sweepApi = useSweepApi();

  const put_accountDetails = useCallback(
    async (userAndAccountDetails: UpdateUserAndAccountDetails) => {
      const response = await sweepApi.put(`/dashboard/accountDetails`, userAndAccountDetails);
      return response.data as UpdateUserAndAccountDetails;
    },
    [sweepApi],
  );

  return { put_accountDetails };
};

export default useDashboardApiFacade;
