import { Box } from '@mui/material';
import { IconButton } from '@sweep-io/sweep-design';
import { Send } from '@sweep-io/sweep-design/dist/icons';
import keyBy from 'lodash/keyBy';
import { SyntheticEvent, useMemo, useState } from 'react';
import { Mention, MentionsInput, SuggestionDataItem } from 'react-mentions';
import { useSelector } from 'react-redux';
import { selectAccountUsers } from '../../../reducers/accountUsersReducer';
import { MentionedUser } from './types';
import useEventListener from '../useEventListener';
import { hasMeaningfulText } from '../../comments/helper';
import classNames from './CommentInputWithAvatar.module.css';
import { NewReplySuggestionItem } from '../../comments/NewReplySuggestionItem';
import { getIsUserInactive } from '../../../lib/getIsUserActive';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { DIMENSION_AVATAR_VERY_SMALL } from '../../../constants';
import { AvatarPicture } from '../../avatar/AvatarPicture';

interface CommentInputProps {
  onConfirm: (body: string, mentionedUserIds: MentionedUser[]) => void;
  placeholder?: string;
  initialValue?: string;
  noAvatar?: boolean;
  onBlur?: (event: SyntheticEvent) => void;
}

const CommentInputWithAvatar = ({
  onConfirm,
  placeholder,
  initialValue,
  noAvatar,
  onBlur,
}: CommentInputProps) => {
  const users = useSelector(selectAccountUsers);
  const usersById = keyBy(users, 'id');
  const currentUserInfo = useSelector(selectUserInfoData);

  const [body, setBody] = useState(initialValue ?? '');
  const [mentions, setMentions] = useState<MentionedUser[]>([]);
  const disableConfirm = body === '';

  const mentionsData: SuggestionDataItem[] = useMemo(() => {
    if (!users) {
      return [];
    }
    return users
      .filter((user) => !getIsUserInactive(user.status))
      .map(({ id, name, email }) => {
        return {
          id,
          display: !!name ? name : email,
        } as SuggestionDataItem;
      });
  }, [users]);

  const handleChange = (ev: any, markup: string, text: string, mentions: SuggestionDataItem[]) => {
    setBody(markup);
    const mentionedUserIds: MentionedUser[] = mentions.map((mention) => mention.id as string);
    setMentions(mentionedUserIds);
  };

  const handleConfirm = () => {
    if (hasMeaningfulText(body)) {
      onConfirm(body.trim(), mentions);
      setBody('');
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      if (body) {
        event.preventDefault(); //to prevent going down a line in the textarea
        handleConfirm();
      }
    }
  };

  useEventListener('keydown', onKeyDown);

  const renderSuggestion = ({ id }: SuggestionDataItem) => {
    const userInfo = usersById[id];
    return <NewReplySuggestionItem userInfo={userInfo} />;
  };

  return (
    <Box display="flex" gap={1} alignItems="center" width="100%">
      {!noAvatar && (
        <AvatarPicture
          avatar={{ emoji: currentUserInfo?.emoji, imageUrl: currentUserInfo?.imageUrl }}
          dimension={DIMENSION_AVATAR_VERY_SMALL}
          clickable={false}
          isInactive={getIsUserInactive(currentUserInfo?.status)}
        />
      )}
      <Box display="flex" gap={1} position="relative" alignItems="center" width="100%">
        <MentionsInput
          placeholder={placeholder}
          className="mentions"
          classNames={classNames}
          value={body}
          autoFocus={true}
          suggestionsPortalHost={document.querySelector('#comment-popover') as Element}
          allowSuggestionsAboveCursor
          onChange={handleChange}
          onBlur={onBlur}
        >
          <Mention
            trigger="@"
            data={mentionsData}
            className={classNames.mentions__mention}
            renderSuggestion={renderSuggestion}
          />
        </MentionsInput>
        <Box
          sx={{
            position: 'absolute',
            right: '12px',
          }}
        >
          <IconButton onClick={handleConfirm} size="tiny" disabled={disableConfirm}>
            <Send />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CommentInputWithAvatar;
