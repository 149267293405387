import * as yup from 'yup';
import { useIsFunnelNameUnique } from './useIsFunnelNameUnique';
import { useMemo } from 'react';
import { FUNNEL_NAME_MAX_LENGTH } from '../constants';

const useFunnelNameValidationSchema = ({
  getObjectName,
  initialName,
  funnelId,
}: {
  initialName: string;
  getObjectName: (context: yup.TestContext) => string | undefined;
  funnelId?: string; //can be undefined, in case of newly created funnels
}) => {
  const checkIsFunnelNameUnique = useIsFunnelNameUnique();

  const schema = useMemo(
    () =>
      yup
        .string()
        .required('Funnel name is a required field')
        .trim(`Funnel name can't contain whitespace in beginning or end`)
        .strict(true)
        .matches(/^[a-zA-Z_][a-zA-Z0-9_]*/, `Funnel name must start with a letter`)
        .max(40, `Funnel name can have maximum of ${FUNNEL_NAME_MAX_LENGTH} characters`)
        .test(
          'name',
          'There is already a funnel with this name for this object',
          async (value, context) => {
            const objectName = getObjectName(context);
            if (!value || !objectName) return false;
            /**
             * !funnelId as mark for newly created funnels
             * For existing funnels call the server only when the name changes. - (funnelId && value !== initialFunnelName)
             */
            if (value !== initialName) {
              return await checkIsFunnelNameUnique({ name: value, objectName, funnelId });
            }

            return true;
          },
        ),
    [checkIsFunnelNameUnique, funnelId, getObjectName, initialName],
  );

  return schema;
};

export default useFunnelNameValidationSchema;
