import { IconButton } from '@sweep-io/sweep-design';
import { Send } from '@sweep-io/sweep-design/dist/icons/Send';

interface ButtonProps {
  disabled: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const PrimaryButton = (props: ButtonProps) => {
  return (
    <IconButton size="small" {...props}>
      <Send />
    </IconButton>
  );
};

const SecondaryButton = (props: ButtonProps) => {
  return (
    <IconButton size="tiny" variant="flat" {...props}>
      <Send />
    </IconButton>
  );
};

export const buttons = {
  primary: PrimaryButton,
  secondary: SecondaryButton,
};
