import CopilotChat from './CopilotChat';
import useCopilotChat from './useCopilotChat';
import { selectDefaultCreationCrmOrgId } from '../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';
import RightPanel from '../common/RightPanel';
import CopilotHeaderDeprecated from './CopilotHeaderDeprecated';

const CopilotChatConnectedDeprecated = ({
  closeCopilot,
  isOpened,
}: {
  closeCopilot: () => void;
  isOpened: boolean;
}) => {
  const { messages, resetChat, onConfirm, isLoading, error, disableInput } = useCopilotChat();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  return (
    <RightPanel isOpened={isOpened} isExpanded={false}>
      <CopilotHeaderDeprecated closeCopilot={closeCopilot} resetChat={resetChat} />
      <CopilotChat
        messages={messages}
        onConfirm={(message) => onConfirm({ crmOrgId, message })}
        isLoading={isLoading}
        errorMsg={error}
        disableInput={disableInput}
        noteAlignment="left"
      />
    </RightPanel>
  );
};

export default CopilotChatConnectedDeprecated;
