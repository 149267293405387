import { Box } from '@mui/material';
import { HistoryTimeOffs } from './helpers';
import { Button } from '@sweep-io/sweep-design';
import { useState } from 'react';
import { useVerifyManagePackageDialog } from './useVerifyManagePackageDialog';
import { useDispatch } from 'react-redux';
import { updateCrmOrgUser } from '../../../../reducers/crmOrgUsersReducer';
import { useCrmOrgsApiFacade } from '../../../../apis/facades/useCrmOrgsApiFacade';
import { SnoozeDialog } from './SnoozeDialog';
import { AddTimeOffDialog } from './AddTimeoffDialog';
import { TimeOffHistoryDialog } from './TimeOffHistoryDialog';
import { useErrorHandling } from '../../../../hooks/useErrorHandling';
import { uniqueId } from '../../../../lib/uniqueId';
import { telemetry } from '../../../../telemetry';
import { DateTime } from 'luxon';
import cloneDeep from 'lodash/cloneDeep';


interface MembersListActionsProps {
  crmOrgUserId: string;
  crmOrgUsers?: CrmOrgUser[];
  crmOrgId: string;

}

export const MembersListActions = ({
  crmOrgUserId,
  crmOrgUsers,
  crmOrgId,
}: MembersListActionsProps) => {
  const dispatch = useDispatch();
  const [addTimeOffDialogUser, setAddTimeOffDialogUser] = useState<CrmOrgUser>();
  const [snoozeDialogUser, setSnoozeDialogUser] = useState<CrmOrgUser>();
  const [reopenHistoryDialog, setReopenHistoryDialog] = useState<boolean>(false);

  const { post_userTimeOff, delete_userTimeOff, put_userTimeOff } = useCrmOrgsApiFacade();

  const [editTimeOffDialogUser, setEditTimeOffDialogUser] = useState<{
    user: CrmOrgUser;
    timeOffId: string;
  }>();
  const [historyDialogUser, setHistoryDialogUser] = useState<CrmOrgUser>();
  const { errorHandlingBuilder } = useErrorHandling();
  const { verifyManagedPackageAndCall, maybeInstallManagePackageDialogDialog } = useVerifyManagePackageDialog()
  const crmOrgUser = crmOrgUsers?.find((user) => user.id === crmOrgUserId);
  const timeOffs = crmOrgUser?.timeOffs || [];
  const showUnsnooze = Boolean(new HistoryTimeOffs(timeOffs).getActiveSnooze());
  const currentAndFutureTimeOffs = new HistoryTimeOffs(timeOffs).getCurrentAndFutureTimeOffs();
  const hasActiveTimeOffs = currentAndFutureTimeOffs.length > 0;
  const totalActiveTimeOffs = currentAndFutureTimeOffs.length;
  const closeSnoozeDialog = () => setSnoozeDialogUser(undefined);
  const closeAddTimeOffDialog = () => setAddTimeOffDialogUser(undefined);
  const closeEditTimeOffDialog = () => setEditTimeOffDialogUser(undefined);
  const closeHistoryDialog = () => setHistoryDialogUser(undefined);

  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-end"
        gap={2}
        className="assignments-members-table__action-buttons"
        marginRight="2px"
        marginLeft="40px"
        sx={{
          '.MuiButtonBase-root': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        {showUnsnooze && (
          <Button
            variant="flat"
            startIconName="Timer"
            onClick={() =>
              verifyManagedPackageAndCall(async () => {
                if (!crmOrgUser) return;
                const timeOffsToDelete =
                  crmOrgUser?.timeOffs?.filter((timeOff) => {
                    return timeOff.type === 'SNOOZE';
                  }) || [];

                // Delete all snooze time offs
                await Promise.all(
                  timeOffsToDelete.map((timeOff) =>
                    delete_userTimeOff({
                      crmOrgId: crmOrgId,
                      timeOffId: timeOff.id,
                      userId: crmOrgUser.id,
                    }),
                  ),
                );

                const timeOffs =
                  crmOrgUser.timeOffs?.filter((timeOff) => {
                    return timeOff.type !== 'SNOOZE';
                  }) || [];

                dispatch(
                  updateCrmOrgUser({
                    crmOrgId: crmOrgId,
                    crmOrgUser: {
                      ...crmOrgUser,
                      timeOffs,
                    },
                  }),
                );
              })
            }
          >
            Unsnooze
          </Button>
        )}
        {!showUnsnooze && (
          <Button
            variant="flat"
            startIconName="Timer"
            onClick={() => verifyManagedPackageAndCall(() => setSnoozeDialogUser(crmOrgUser))}
          >
            Snooze
          </Button>
        )}
        {!hasActiveTimeOffs && (
          <Button
            variant="flat"
            startIconName="Calendar"
            onClick={() =>
              verifyManagedPackageAndCall(() => {
                setAddTimeOffDialogUser(crmOrgUser);
              })
            }
          >
            Add time off
          </Button>
        )}
        {hasActiveTimeOffs && (
          <Button
            variant="flat"
            startIconName="Calendar"
            onClick={() => verifyManagedPackageAndCall(() => setHistoryDialogUser(crmOrgUser))}
          >
            Time off ({totalActiveTimeOffs})
          </Button>
        )}
        {maybeInstallManagePackageDialogDialog}
      </Box>
      {snoozeDialogUser && (
        <SnoozeDialog
          user={snoozeDialogUser}
          onClose={closeSnoozeDialog}
          onConfirm={async (endDate, timezone) => {
            const timeOffs =
              snoozeDialogUser.timeOffs?.filter((timeOff) => {
                return timeOff.type !== 'SNOOZE';
              }) || [];
            const timeOff: CrmOrgUserTimeOff = {
              type: 'SNOOZE',
              description: '',
              id: uniqueId(),
              startDate: DateTime.now().toISO() || '',
              endDate,
              timezone,
            };
            errorHandlingBuilder()
              .withErrorNotification('Error snoozing users')
              .withOnError((e, addNotification) => {
                telemetry.captureError(e);
                // We have an optimistic update, so we need to revert it if the request fails.
                updateCrmOrgUser({
                  crmOrgId,
                  crmOrgUser: {
                    ...snoozeDialogUser,
                    timeOffs: [...timeOffs],
                  },
                });
                addNotification('Error snoozing user');
              })
              .execute(() => {
                post_userTimeOff({
                  crmOrgId,
                  timeOff,
                  userId: snoozeDialogUser.id,
                });
                dispatch(
                  updateCrmOrgUser({
                    crmOrgId,
                    crmOrgUser: {
                      ...snoozeDialogUser,
                      timeOffs: [...timeOffs, timeOff],
                    },
                  }),
                );
              });

            closeSnoozeDialog();
          }}
        />
      )}
      {addTimeOffDialogUser && (
        <AddTimeOffDialog
          user={addTimeOffDialogUser}
          onConfirm={async (
            description: string,
            startDate: string,
            endDate: string,
            timezone: string,
          ) => {
            const timeOffs = addTimeOffDialogUser.timeOffs || [];
            let newAddTimeOffDialogUser: CrmOrgUser = addTimeOffDialogUser;

            const timeOff: CrmOrgUserTimeOff = {
              type: 'TIME_OFF',
              description,
              id: uniqueId(),
              startDate,
              endDate,
              timezone,
            };
            errorHandlingBuilder()
              .withOnError((e, addNotification) => {
                telemetry.captureError(e);
                // We have an optimistic update, so we need to revert it if the request fails.
                newAddTimeOffDialogUser = {
                  ...addTimeOffDialogUser,
                  timeOffs: [...timeOffs],
                };
                updateCrmOrgUser({
                  crmOrgId,
                  crmOrgUser: newAddTimeOffDialogUser,
                });
                addNotification('Error creating time off');
              })
              .execute(() => {
                post_userTimeOff({
                  crmOrgId,
                  timeOff,
                  userId: addTimeOffDialogUser.id,
                });
                newAddTimeOffDialogUser = {
                  ...addTimeOffDialogUser,
                  timeOffs: [...timeOffs, timeOff],
                };
              });

            dispatch(
              updateCrmOrgUser({
                crmOrgId,
                crmOrgUser: newAddTimeOffDialogUser,
              }),
            );

            closeAddTimeOffDialog();
            if (reopenHistoryDialog) {
              setReopenHistoryDialog(false);
              setHistoryDialogUser(newAddTimeOffDialogUser);
            }
          }}
          onClose={() => {
            closeAddTimeOffDialog();
            if (reopenHistoryDialog) {
              setHistoryDialogUser(addTimeOffDialogUser);
              setReopenHistoryDialog(false);
            }
          }}
        />
      )}
      {editTimeOffDialogUser && (
        <AddTimeOffDialog
          user={editTimeOffDialogUser.user}
          timeOff={editTimeOffDialogUser.user.timeOffs?.find(
            (timeOff) => timeOff.id === editTimeOffDialogUser.timeOffId,
          )}
          onConfirm={(
            description: string,
            startDate: string,
            endDate: string,
            timezone: string,
          ) => {
            const timeOffs = cloneDeep(editTimeOffDialogUser.user.timeOffs || []);
            const idx = timeOffs.findIndex(
              (timeOff) => timeOff.id === editTimeOffDialogUser.timeOffId,
            );
            if (idx !== -1) {
              timeOffs[idx] = {
                type: 'TIME_OFF',
                description,
                id: timeOffs[idx].id,
                startDate,
                endDate,
                timezone,
              };
              errorHandlingBuilder()
                .withErrorNotification('Error updating time off')
                .execute(async () => {
                  await put_userTimeOff({
                    crmOrgId,
                    timeOff: timeOffs[idx],
                    userId: editTimeOffDialogUser.user.id,
                  });
                  dispatch(
                    updateCrmOrgUser({
                      crmOrgId,
                      crmOrgUser: {
                        ...editTimeOffDialogUser.user,
                        timeOffs,
                      },
                    }),
                  );
                  if (reopenHistoryDialog) {
                    setHistoryDialogUser({
                      ...editTimeOffDialogUser.user,
                      timeOffs,
                    });
                    setReopenHistoryDialog(false);
                  }
                })
                .finally(closeEditTimeOffDialog);
            } else {
              closeEditTimeOffDialog();
            }
          }}
          onClose={() => {
            if (reopenHistoryDialog) {
              setHistoryDialogUser(editTimeOffDialogUser.user);
              setReopenHistoryDialog(false);
            }
            closeEditTimeOffDialog();
          }}
        />
      )}
      {historyDialogUser && (
        <TimeOffHistoryDialog
          user={historyDialogUser}
          onClose={closeHistoryDialog}
          onAddTimeOff={() => {
            closeHistoryDialog();
            setReopenHistoryDialog(true);
            setAddTimeOffDialogUser(historyDialogUser);
          }}
          onDeleteTimeOff={async (timeOffId) => {
            const timeOffs = historyDialogUser.timeOffs?.filter(
              (timeOff) => timeOff.id !== timeOffId,
            );
            const newCrmOrgUser = {
              ...historyDialogUser,
              timeOffs,
            };
            errorHandlingBuilder()
              .withErrorNotification('Error deleting time off')
              .execute(async () => {
                await delete_userTimeOff({
                  crmOrgId,
                  timeOffId,
                  userId: newCrmOrgUser.id,
                });

                setHistoryDialogUser(newCrmOrgUser);

                dispatch(
                  updateCrmOrgUser({
                    crmOrgId,
                    crmOrgUser: newCrmOrgUser,
                  }),
                );
              });
          }}
          onEditTimeOff={(timeOffId) => {
            setEditTimeOffDialogUser({
              user: historyDialogUser,
              timeOffId,
            });
            closeHistoryDialog();
            setReopenHistoryDialog(true);
          }}
        />
      )}
    </>
  );

}