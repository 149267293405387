import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDeploymentsApi } from '../../../../../apis/facades/useDeploymentsApi';
import { selectUserById } from '../../../../../reducers/accountUsersReducer';
import {
  close,
  compareDeployedWith,
  selectDialogVisibility,
} from '../../../../../reducers/deploymentChangesUiReducer';
import { getCrmOrgById } from '../../../environments/environmentsReducer';
import { selectFunnelMapSfFunnelById } from '../../../../../reducers/united-canvas/sfFunnelReducer';
import { selectRequestChangesBySnapshotId } from '../../../../../reducers/requestChangesApiReducer';
import { DeploymentChangesActionDialog } from './DeploymentChangesActionDialog';
import { ReviewDialog } from './ReviewDialog';
import { BaseDialog } from '../../../../common/dialogs/BaseDialog';
import { useRequestChangesApiFacade } from '../../../../../apis/facades/useRequestChangesApiFacade';
import { telemetry } from '../../../../../telemetry';

const DeploymentChangesDialog = () => {
  const dispatch = useDispatch();

  const isVisible = useSelector(selectDialogVisibility);

  const [reviewDialogVisibility, setReviewDialogVisibility] = useState(true);
  const [approveDialogVisibility, setApproveDialogVisibility] = useState(false);
  const [rejectDialogVisibility, setRejectDialogVisibility] = useState(false);
  const [deployDialogVisibility, setDeployDialogVisibility] = useState(false);

  const toggleAllOff = () => {
    setReviewDialogVisibility(false);
    setApproveDialogVisibility(false);
    setRejectDialogVisibility(false);
    setDeployDialogVisibility(false);
  };
  const { snapshotId: snapshotIdForComparison } = useSelector(compareDeployedWith);

  const requestChange = useSelector(selectRequestChangesBySnapshotId(snapshotIdForComparison));
  const author = useSelector(selectUserById(requestChange?.createdById ?? ''));
  const funnel = useSelector(selectFunnelMapSfFunnelById(requestChange?.funnelId ?? ''));
  const crmOrg = useSelector(getCrmOrgById(requestChange?.crmOrgId));

  const { post_deploymentsCrmOrg } = useDeploymentsApi();
  const { approveChanges, rejectChanges } = useRequestChangesApiFacade();

  const handleCloseDialog = useCallback(() => {
    dispatch(close());
  }, [dispatch]);

  const handleDeploy = useCallback(
    async (note?: string) => {
      if (!requestChange?.crmOrgId) {
        return;
      }
      try {
        await post_deploymentsCrmOrg({
          crmOrgId: requestChange.crmOrgId,
          async: true,
          payload: {
            snapshotId: requestChange.snapshotId,
          },
        });
        await approveChanges(requestChange.id, note);
        handleCloseDialog();
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [post_deploymentsCrmOrg, requestChange, approveChanges, handleCloseDialog],
  );

  const handleApprove = useCallback(
    async (note?: string) => {
      if (!requestChange?.id) {
        return;
      }
      try {
        await approveChanges(requestChange.id, note);
        handleCloseDialog();
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [handleCloseDialog, approveChanges, requestChange],
  );

  const handleReject = useCallback(
    async (note?: string) => {
      if (!requestChange?.id) {
        return;
      }
      try {
        await rejectChanges(requestChange.id, note);
        handleCloseDialog();
        window.location.reload();
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [handleCloseDialog, rejectChanges, requestChange],
  );

  const moveToApprove = () => {
    toggleAllOff();
    setApproveDialogVisibility(true);
  };
  const moveToDeploy = () => {
    toggleAllOff();
    setDeployDialogVisibility(true);
  };
  const moveToReject = () => {
    toggleAllOff();
    setRejectDialogVisibility(true);
  };
  const moveToReview = () => {
    toggleAllOff();
    setReviewDialogVisibility(true);
  };

  return (
    <BaseDialog open={isVisible}>
      {reviewDialogVisibility && (
        <ReviewDialog
          onApprove={moveToApprove}
          onReject={moveToReject}
          onDeploy={moveToDeploy}
          onClose={handleCloseDialog}
          requestChange={requestChange}
          funnel={funnel}
          crmOrg={crmOrg}
          author={author}
        />
      )}
      {approveDialogVisibility && (
        <DeploymentChangesActionDialog
          title="Approve changes"
          confirmText="Done"
          onBack={moveToReview}
          onConfirm={handleApprove}
          onClose={handleCloseDialog}
          placeholder="This message will be visible only to your collaborator. You can use it to share your thoughts on the deployment request."
        />
      )}
      {rejectDialogVisibility && (
        <DeploymentChangesActionDialog
          title="Reject and give feedback"
          cancelText="Discard changes"
          confirmText="Keep changes"
          onBack={moveToReview}
          onConfirm={handleApprove}
          onCancel={handleReject}
          onClose={handleCloseDialog}
          placeholder="This message will be visible only to your collaborator. You can use it to explain why the deployment request was denied"
          footNote="Restoring previous version will automatically reject all subsequent requests (if exist)"
        />
      )}
      {deployDialogVisibility && (
        <DeploymentChangesActionDialog
          title="Deploy changes"
          confirmText="Deploy"
          onBack={moveToReview}
          onConfirm={handleDeploy}
          onClose={handleCloseDialog}
          placeholder="This message will be visible only to your collaborator. You can use it to share your thoughts on the deployment request."
          footNote="Deploying this request will automatically deploy all earlier requests (if exist)"
        />
      )}
    </BaseDialog>
  );
};

export default DeploymentChangesDialog;
