import { useCallback } from 'react';
import { useSweepApi } from '../sweep';

export const useDiffApiFacade = () => {
  const sweepApi = useSweepApi();

  return {
    get_diffBySnapshotId: useCallback(
      async ({ snapshotId, crmOrgId }: { snapshotId: string; crmOrgId: string }) => {
        const { data } = await sweepApi.get(`/diff/snapshots/${snapshotId}?crmOrgId=${crmOrgId}`);
        return data;
      },
      [sweepApi],
    ),
    get_diffByFunnelId: useCallback(
      async ({ funnelId, crmOrgId }: { funnelId: string; crmOrgId: string }) => {
        const { data } = await sweepApi.get(`/diff/funnels/${funnelId}?crmOrgId=${crmOrgId}`);
        return data;
      },
      [sweepApi],
    ),
  };
};
