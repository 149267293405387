import { useSelector } from 'react-redux';
import { selectTerritories } from '../reducers/territoriesReducer';
import { useTerritoriesActions } from './useTerritoriesActions';
import { useRunOnceWhenTruthy } from '../components/common/useRunOnceWhenTruthy';

const useTerritories = () => {
  const { getTerritories } = useTerritoriesActions();
  const territories = useSelector(selectTerritories);

  useRunOnceWhenTruthy(async () => {
    await getTerritories();
  }, !territories);

  return territories;
};

export default useTerritories;
