import * as React from "react";
const SvgM = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FEDFF9", style: {
  fill: "#FEDFF9",
  fill: "color(display-p3 0.9961 0.8745 0.9765)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M5.49316 5.09616C5.10656 5.09616 4.79316 5.40956 4.79316 5.79616V18.2038C4.79316 18.5904 5.10656 18.9038 5.49316 18.9038H7.64997C8.03657 18.9038 8.34997 18.5904 8.34997 18.2038V12.9845L10.5449 18.4292C10.6517 18.6941 10.9086 18.8675 11.1942 18.8675H12.8057C13.0911 18.8675 13.3478 18.6943 13.4547 18.4297L15.6499 12.9961V18.2038C15.6499 18.5904 15.9633 18.9038 16.3499 18.9038H18.5067C18.8933 18.9038 19.2067 18.5904 19.2067 18.2038V5.79616C19.2067 5.40956 18.8933 5.09616 18.5067 5.09616H15.7562C15.4721 5.09616 15.2161 5.26788 15.1084 5.53078L11.9999 13.1179L8.89144 5.53078C8.78373 5.26788 8.5278 5.09616 8.2437 5.09616H5.49316Z", fill: "#F371E6", stroke: "white", style: {
  fill: "#F371E6",
  fill: "color(display-p3 0.9542 0.4413 0.9029)",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.4, strokeLinejoin: "round" }));
export default SvgM;
