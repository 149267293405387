import { Menu, Box, MenuItem } from '@mui/material';
import { ChevronDown, Cloud, Devops } from '@sweep-io/sweep-design/dist/icons';
import { Button, Typography } from '@sweep-io/sweep-design';
import usePopover from '../../../hooks/usePopover';
import SplitButton from './SplitButton';
import { useState } from 'react';
import { OrgDot } from '../OrgDot';

const menuItemSx = { display: 'flex', alignItems: 'center', gap: 1 };

const DeployButton = ({
  defaultCreationEnvironmentName,
  prodEnvironmentName,
  defaultIsProd,
  onDeployDefaultClick,
  onDeployBothClick,
  onSaveClick,
  disabled,
  onShowDiff,
  isRequestToDeploy,
}: {
  defaultCreationEnvironmentName?: string;
  prodEnvironmentName?: string;
  defaultIsProd: boolean;
  onDeployDefaultClick?: () => Promise<void>;
  onDeployBothClick?: () => Promise<void>;
  onSaveClick: () => Promise<void>;
  disabled?: boolean;
  onShowDiff?: () => void;
  isRequestToDeploy?: boolean;
}) => {
  const { isPopoverOpen, closePopover, openPopover, anchorElement, anchorRef } = usePopover();
  const [isLoading, setIsLoading] = useState(false);

  const deployText = isRequestToDeploy ? 'Request to deploy to' : 'Deploy to';

  const callAsyncWithLoading = async (fn: () => Promise<void>) => {
    setIsLoading(true);
    await fn();
    setIsLoading(false);
  };

  const showDeployToSandboxPlusProd = Boolean(
    !defaultIsProd && !isRequestToDeploy && onDeployBothClick && prodEnvironmentName,
  );
  const showDiffItem = Boolean(onShowDiff);
  const totalMenuEntries = Number(showDeployToSandboxPlusProd) + Number(showDiffItem);
  const shouldRenderMenu = totalMenuEntries > 0;

  const buttonText = `${deployText} ${defaultCreationEnvironmentName}`;

  const renderMenu = () => {
    return (
      <Menu
        anchorEl={anchorElement}
        open={isPopoverOpen}
        onClose={() => closePopover()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {showDeployToSandboxPlusProd && (
          <MenuItem
            sx={menuItemSx}
            onClick={() => {
              onDeployBothClick && callAsyncWithLoading(onDeployBothClick);
              closePopover();
            }}
          >
            <Cloud />
            <Box display="flex" gap={0.5} alignItems="center">
              <Typography variant="body">
                {deployText} {defaultCreationEnvironmentName} + {prodEnvironmentName}
              </Typography>
              <OrgDot />
            </Box>
          </MenuItem>
        )}
        {showDiffItem && (
          <MenuItem
            sx={menuItemSx}
            onClick={() => {
              onShowDiff?.();
              closePopover();
            }}
          >
            <Devops />
            <Typography variant="body">Review Changes</Typography>
          </MenuItem>
        )}
      </Menu>
    );
  };

  return (
    <Box display="flex" gap="12px">
      <Button
        variant="outlined"
        onClick={async () => {
          callAsyncWithLoading(onSaveClick);
          closePopover();
        }}
        loading={isLoading}
        disabled={!defaultCreationEnvironmentName || disabled}
      >
        Save draft
      </Button>
      {shouldRenderMenu && (
        <>
          <Box display="inline-flex" ref={anchorRef}>
            <SplitButton
              leftText={buttonText}
              leftButtonProps={{
                onClick: async () => {
                  onDeployDefaultClick && callAsyncWithLoading(onDeployDefaultClick);
                },
                disabled: !defaultCreationEnvironmentName || disabled,
              }}
              rightButtonProps={{
                onClick: openPopover,
                pressed: isPopoverOpen,
                disabled: !defaultCreationEnvironmentName || disabled,
              }}
              rightIcon={<ChevronDown />}
              isLoading={isLoading}
            />
          </Box>
          {renderMenu()}
        </>
      )}

      {!shouldRenderMenu && (
        <Button
          loading={isLoading}
          disabled={!defaultCreationEnvironmentName || disabled}
          onClick={() => {
            onDeployDefaultClick && callAsyncWithLoading(onDeployDefaultClick);
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

export default DeployButton;
