import { SweepFieldTypes } from '../../../../../../../types/enums/SweepFieldTypes';
import { CriteriaPluginSection } from '../../base-criteria-plugin-panel/types';
import { AuthoritySvg, BudgetSvg, NeedSvg, TimingSvg } from './icons';

export const bantPluginStepMapping: CriteriaPluginSection[] = [
  {
    title: 'Budget',
    icon: <BudgetSvg />,
    pluginFields: [
      {
        pluginFieldId: 'budget',
        fieldName: 'Budget',
        apiName: 'Budget__c',
        type: SweepFieldTypes.Currency,
        description: 'Captures the allocated budget for the opportunity.',
      },
      {
        pluginFieldId: 'budgetStatus',
        fieldName: 'Budget Status',
        apiName: 'Budget_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: 'Indicates the current status of the budget assessment.',
      },
    ],
  },
  {
    title: 'Authority',
    icon: <AuthoritySvg />,
    pluginFields: [
      {
        pluginFieldId: 'authority',
        fieldName: 'Authority',
        apiName: 'Authority__c',
        type: SweepFieldTypes.Lookup,
        lookupFieldProps: {
          lookupRelationshipName: 'Contacts',
          lookupObjectName: 'Contact',
        },
        description: 'Links to the individual with decision-making authority.',
      },
      {
        pluginFieldId: 'authorityStatus',
        fieldName: 'Authority Status',
        apiName: 'Authority_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: 'Reflects the status of the decision-making authority.',
      },
    ],
  },
  {
    title: 'Need',
    icon: <NeedSvg />,
    pluginFields: [
      {
        pluginFieldId: 'need',
        fieldName: 'Need',
        apiName: 'Need__c',
        type: SweepFieldTypes.Text,
        description: 'Records the specific needs or pain points expressed by the prospect.',
      },
      {
        pluginFieldId: 'needStatus',
        fieldName: 'Need Status',
        apiName: 'Need_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: "Highlights the current status of addressing the prospect's needs.",
      },
    ],
  },
  {
    title: 'Timing',
    icon: <TimingSvg />,
    pluginFields: [
      {
        pluginFieldId: 'timing',
        fieldName: 'Timing Status',
        apiName: 'Timing_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: "Tracks the status of the timing for the opportunity's progression.",
      },
    ],
  },
];
