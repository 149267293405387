import { Box, Divider, Grid, Menu, MenuItem, Stack } from '@mui/material';
import { IconButton, Typography, Checkbox, colors } from '@sweep-io/sweep-design';
import { SlidersVertical } from '@sweep-io/sweep-design/dist/icons/SlidersVertical';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  clearCommentsSearchTerm,
  selectCommentsByFunnel,
  selectFilterSortState,
  selectIsResolvedFetched,
  selectShowResolved,
  setFilter,
  setSearchTerm,
  setShowResolved,
  setSortBy,
} from '../../reducers/commentsReducer';
import { CommentThreadProps } from './types';
import { FilterByOptions, SortByOptions } from '../common/comments/types';
import { SearchInput } from '../common/SearchInput';
import { CommentsPanelBox, CommentsPanelBoxEmpty } from './CommentsPanelBox';
import { selectUserInfoData } from '../../reducers/userInfoReducer';
import { filterSortComments } from './helper';
import useComments from './useComments';
import { CenteredCircularProgress } from '../common/CenteredCircularProgress';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../pages/configuration-canvas-panel';
import { FlexBox } from '../common/FlexBox';
import pluralize from 'pluralize';
import { SweepSort, SortDisplayTypes } from '../common/SweepSort';

interface CommentsPanelProps {
  funnelMapId: string;
  onClose: () => void;
  onCommentThreadClick: (commentThread: CommentThreadProps) => void;
  origin?: 'left' | 'right';
}

const { SHOW_ALL, ONLY_RESOLVED } = FilterByOptions;

const CommentsPanel = ({ onClose, funnelMapId, onCommentThreadClick }: CommentsPanelProps) => {
  const allComments = useSelector(selectCommentsByFunnel(funnelMapId));
  const currentUser = useSelector(selectUserInfoData);
  const filterSortState = useSelector(selectFilterSortState);
  const showResolved = useSelector(selectShowResolved(funnelMapId));
  const isResolvedFetched = useSelector(selectIsResolvedFetched(funnelMapId));
  const dispatch = useDispatch();
  const { filterBy, sortBy, searchTerm } = filterSortState;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const closeMenu = () => setIsMenuOpen(false);
  const openMenu = () => setIsMenuOpen(true);
  const buttonRef = useRef();
  const { fetchCommentThreads } = useComments();
  const [isFetchingResolved, setIsFetchingResolved] = useState(false);

  const onFilterChange = useCallback(
    (filterOption: FilterByOptions) => {
      dispatch(setFilter(filterOption));
    },
    [dispatch],
  );
  const onSortByChange = (sortBy: SortByOptions) => {
    dispatch(setSortBy(sortBy));
  };

  const onSearchTermChange = (searchTerm: string) => {
    dispatch(setSearchTerm(searchTerm));
  };

  const toggleShowResolved = async () => {
    const showResolvedTargetValue = !showResolved;
    if (showResolvedTargetValue && !isResolvedFetched) {
      try {
        setIsFetchingResolved(true);
        await fetchCommentThreads(funnelMapId, false);
      } finally {
        setIsFetchingResolved(false);
      }
    }
    dispatch(setShowResolved({ funnelMapId, showResolved: showResolvedTargetValue }));
  };

  const commentsToShow = useMemo(
    () =>
      allComments
        ? filterSortComments(allComments, filterSortState, showResolved, currentUser?.id ?? '')
        : [],
    [allComments, filterSortState, currentUser, showResolved],
  );

  useEffect(() => {
    if (filterBy === ONLY_RESOLVED && !showResolved) {
      onFilterChange(SHOW_ALL);
    }
  }, [showResolved, filterBy, onFilterChange]);

  useEffect(() => {
    return () => {
      dispatch(clearCommentsSearchTerm());
    };
  }, [dispatch]);

  const noSearchFilterResults =
    (searchTerm || filterBy !== SHOW_ALL) && allComments?.length > 0 && commentsToShow.length === 0;

  return (
    <>
      <ConfigurationCanvasLeftPanel skipSizeAllocation>
        <ConfigurationCanvasPanelHeader onClose={onClose}>Comments</ConfigurationCanvasPanelHeader>
        <ConfigurationCanvasPanelContent>
          <Grid container direction="column" height={'100%'} rowGap={2}>
            <Grid item xs="auto">
              <Box gap={1.5} display="flex" justifyContent="space-between" alignItems="center">
                <SearchInput
                  withFixedMagnifyingGlassIcon
                  TextFieldProps={{
                    value: searchTerm,
                    onChange: (ev) => onSearchTermChange(ev.target.value),
                  }}
                  onClearButtonClick={() => onSearchTermChange('')}
                />
                <IconButton
                  variant="flat"
                  size="tiny"
                  onClick={openMenu}
                  ref={buttonRef}
                  pressed={isMenuOpen}
                >
                  <SlidersVertical />
                </IconButton>
              </Box>
            </Grid>
            <Grid item>
              <FlexBox
                sx={{
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 1,
                }}
              >
                <Typography variant="body" color={colors.grey[700]}>
                  {pluralize('Comment', allComments?.length, true)}
                </Typography>

                <Box>
                  <SweepSort
                    selectedValue={sortBy}
                    onChange={(value) => onSortByChange(value as SortByOptions)}
                    options={[
                      { label: 'Date', value: SortByOptions.SORT_BY_DATE },
                      { label: 'Unread', value: SortByOptions.SORT_BY_UNREAD },
                    ]}
                    displayType={SortDisplayTypes.Label_Only}
                  />
                </Box>
              </FlexBox>
            </Grid>
            <Grid item xs overflow="auto">
              {noSearchFilterResults && (
                <CommentsPanelBoxEmpty text="No comments match your search." />
              )}
              {!noSearchFilterResults && (
                <>
                  {commentsToShow.length ? (
                    <Stack direction="column" divider={<Divider />}>
                      {commentsToShow.map((comment) => (
                        <CommentsPanelBox
                          key={comment.id}
                          onClick={onCommentThreadClick}
                          commentThread={comment}
                        />
                      ))}
                    </Stack>
                  ) : (
                    <CommentsPanelBoxEmpty
                      text="Give feedback, ask a question or just leave a note.
                    Click anywhere on the canvas to leave a comment."
                    />
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </ConfigurationCanvasPanelContent>
      </ConfigurationCanvasLeftPanel>
      {isMenuOpen && buttonRef.current && (
        <CommentsPanelMenu
          closeMenu={closeMenu}
          anchorEl={buttonRef.current}
          onFilterChange={onFilterChange}
          filterBy={filterBy}
          toggleShowResolved={toggleShowResolved}
          showResolved={showResolved}
          isFetchingResolved={isFetchingResolved}
        />
      )}
    </>
  );
};

const CommentsPanelMenu = ({
  closeMenu,
  anchorEl,
  onFilterChange,
  filterBy,
  showResolved,
  toggleShowResolved,
  isFetchingResolved,
}: {
  closeMenu: () => void;
  anchorEl: HTMLDivElement;
  filterBy: FilterByOptions;
  onFilterChange: (filterBy: FilterByOptions) => void;
  showResolved: boolean;
  toggleShowResolved: () => void;
  isFetchingResolved: boolean;
}) => {
  const { SHOW_ALL, ONLY_RESOLVED, ONLY_YOURS, ONLY_UNREAD } = FilterByOptions;
  return (
    <Menu
      anchorEl={anchorEl}
      open={true}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ sx: { width: '290px' } }}
    >
      <MenuItem sx={{ padding: '8px 8px 8px 12px', gap: 1 }} onClick={toggleShowResolved}>
        {!isFetchingResolved && <Checkbox checked={showResolved} />}
        {isFetchingResolved && <CenteredCircularProgress circularProgressProps={{ size: 15 }} />}
        <Typography variant="body">Show resolved comments</Typography>
      </MenuItem>

      <FilterMenuItem option={SHOW_ALL} filterBy={filterBy} onFilterChange={onFilterChange} />
      <FilterMenuItem option={ONLY_UNREAD} filterBy={filterBy} onFilterChange={onFilterChange} />
      <FilterMenuItem option={ONLY_YOURS} filterBy={filterBy} onFilterChange={onFilterChange} />
      {showResolved && (
        <FilterMenuItem
          option={ONLY_RESOLVED}
          filterBy={filterBy}
          onFilterChange={onFilterChange}
        />
      )}
    </Menu>
  );
};

const FilterMenuItem = ({
  option,
  filterBy,
  onFilterChange,
}: {
  option: FilterByOptions;
  filterBy: FilterByOptions;
  onFilterChange: (filterBy: FilterByOptions) => void;
}) => (
  <MenuItem
    selected={filterBy === option}
    onClick={() => {
      if (filterBy !== option) {
        onFilterChange(option);
      }
    }}
  >
    {option}
  </MenuItem>
);

export default CommentsPanel;
