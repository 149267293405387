import { createContext } from 'react';
import { OnPluginClickEvent, OnSweepNodesChange } from '../SweepCanvas';
import {
  SweepCanvasNode,
  SweepCanvasEdge,
  SweepCanvasGroup,
  OnNodeClickProps,
  CanvasMode,
} from '../canvasTypes';
import { MoveCanvasGroups } from '../useMoveGroups';
import { SweepCanvasBackgroundProps } from '../SweepCanvasBackground';
import { SweepCanvasControlsProps } from '../SweepCanvasControls';

interface BaseSweepCanvasProps<GroupMetadata = any> {
  sweepNodes: SweepCanvasNode[];
  sweepEdges: SweepCanvasEdge[];
  sweepGroups: SweepCanvasGroup<GroupMetadata>[];
  onSweepNodesChange?: OnSweepNodesChange;
  onNodeClick?: (props: OnNodeClickProps) => any;
  onPillClick?: (props: OnNodeClickProps) => any;
  onLabelClick?: (props: OnNodeClickProps) => any;
  onGateClick?: (sourceNodeId: string, targetNodeId: string, sourceNodeParentId: string) => any;
  onEdgeDeleteClick?: (params: {
    edgeId: string;
    sourceNodeId: string;
    targetNodeId: string;
    sourceNodeParentId: string;
    targetNodeParentId: string;
  }) => any;
  onConnectClick?: (
    nodeId: string,
    parentId: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => any;
  readonly?: boolean;
  selectedNodeId?: string;
  selectedEdgeId?: string;
  fitViewTimeoutValue?: number;
  onNodeNameChange?: (name: string, nodeId: string) => { isValid: boolean; error?: string };
  moveGroups?: MoveCanvasGroups;
  holdNodeHighlighted?: boolean;
  hideGroupInfo?: boolean;
  disableGroups?: boolean;
  autoFitViewOnFirstNodes?: boolean;
  showControls?: boolean;
  isLoadingCursor?: boolean;
  showGroupOverlays?: boolean;
  onPluginClick?: OnPluginClickEvent;
  canvasMode?: CanvasMode;
  disableNodeHighlight?: boolean;
}

export type SweepCanvasProps<GroupMetadata = any> = BaseSweepCanvasProps<GroupMetadata> &
  SweepCanvasBackgroundProps &
  SweepCanvasControlsProps;

const defaultSweepCanvasProps: SweepCanvasProps = {
  sweepNodes: [],
  sweepEdges: [],
  sweepGroups: [],
};

export const SweepCanvasPropsCtx = createContext<SweepCanvasProps>(defaultSweepCanvasProps);
