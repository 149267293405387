import { Box, FormControl, MenuItem, SelectChangeEvent, SxProps, Select } from '@mui/material';
import { ReactNode, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SelectNoBorder from '../../SelectNoBorder';
import { colors } from '@sweep-io/sweep-design';

const BOOLEAN_VALUES = ['true', 'false'];

export interface BooleanSelectorProps {
  initialValue?: string;
  onChange: Function;
  shouldBeDisabled?: boolean;
  sx?: SxProps;
  placeholder?: string;
  removeBorders?: boolean;
}

const BooleanSelector = ({
  initialValue,
  onChange,
  shouldBeDisabled,
  sx,
  placeholder,
  removeBorders,
}: BooleanSelectorProps) => {
  const [newValue, setValue] = useState(initialValue?.toString() ?? '');

  const SelectComponent = removeBorders ? SelectNoBorder : Select;

  return (
    <FormControl fullWidth>
      <SelectComponent
        value={newValue}
        onChange={(event: SelectChangeEvent<unknown>) => {
          const newValue = event.target.value as string;
          setValue(newValue);
          onChange(newValue);
        }}
        disabled={shouldBeDisabled}
        sx={sx}
        displayEmpty
        renderValue={(selected) =>
          newValue !== '' ? (
            (selected as ReactNode)
          ) : (
            <Box sx={{ color: colors.grey[500] }}>{placeholder}</Box>
          )
        }
      >
        {BOOLEAN_VALUES.map((value) => (
          <MenuItem value={value} key={uuidv4()}>
            {value}
          </MenuItem>
        ))}
      </SelectComponent>
    </FormControl>
  );
};

export { BooleanSelector };
