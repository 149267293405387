import { IconButton, SxProps } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export const CloseIconButton = ({ sx, handleClose }: { sx?: SxProps; handleClose: any }) => {
  return (
    <IconButton sx={sx} onClick={handleClose}>
      <CloseIcon fontSize="inherit" color="inherit" />
    </IconButton>
  );
};
