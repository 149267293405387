import * as React from "react";
const SvgCreateFunnel = (props) => /* @__PURE__ */ React.createElement("svg", { width: 153, height: 80, viewBox: "0 0 153 80", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 2.5, y: 8.8335, width: 59.8333, height: 28.1667, rx: 1.16667, fill: "#F8F9FA", stroke: "#EDEDED", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1,
  stroke: "#EDEDED",
  stroke: "color(display-p3 0.9292 0.9292 0.9292)",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 92.6667, y: 8.83325, width: 59.8333, height: 28.1667, rx: 1.16667, fill: "#F8F9FA", stroke: "#EDEDED", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1,
  stroke: "#EDEDED",
  stroke: "color(display-p3 0.9292 0.9292 0.9292)",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 92.6667, y: 51.3333, width: 59.8333, height: 28.1667, rx: 1.16667, fill: "#F8F9FA", stroke: "#EDEDED", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1,
  stroke: "#EDEDED",
  stroke: "color(display-p3 0.9292 0.9292 0.9292)",
  strokeOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 9.5, y: 25, width: 45.8333, height: 5.83333, rx: 1.66667, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 99.6667, y: 25, width: 45.8333, height: 5.83333, rx: 1.66667, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 99.6667, y: 67.5, width: 45.8333, height: 5.83333, rx: 1.66667, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 9.5, y: 14.1665, width: 31.6667, height: 5.83333, rx: 1.66667, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 99.6667, y: 14.1665, width: 31.6667, height: 5.83333, rx: 1.66667, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 99.6667, y: 56.6665, width: 31.6667, height: 5.83333, rx: 1.66667, fill: "#EDEDED", style: {
  fill: "#EDEDED",
  fill: "color(display-p3 0.9292 0.9292 0.9292)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M10 17.4998C14.1421 17.4998 17.5 14.1419 17.5 9.99976C17.5 5.85762 14.1421 2.49976 10 2.49976C5.85786 2.49976 2.5 5.85762 2.5 9.99976C2.5 14.1419 5.85786 17.4998 10 17.4998Z", fill: "#C7ADFF", style: {
  fill: "#C7ADFF",
  fill: "color(display-p3 0.7818 0.6792 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.875 9.99976H13.125M10 6.87476V13.1248M17.5 9.99976C17.5 14.1419 14.1421 17.4998 10 17.4998C5.85786 17.4998 2.5 14.1419 2.5 9.99976C2.5 5.85762 5.85786 2.49976 10 2.49976C14.1421 2.49976 17.5 5.85762 17.5 9.99976Z", stroke: "white", style: {
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M60.5833 22.5001C60.5833 23.9728 61.7772 25.1667 63.25 25.1667C64.7228 25.1667 65.9167 23.9728 65.9167 22.5001C65.9167 21.0273 64.7228 19.8334 63.25 19.8334C61.7772 19.8334 60.5833 21.0273 60.5833 22.5001ZM92.9786 22.8536C93.1738 22.6583 93.1738 22.3417 92.9786 22.1465L89.7966 18.9645C89.6013 18.7692 89.2847 18.7692 89.0895 18.9645C88.8942 19.1597 88.8942 19.4763 89.0895 19.6716L91.9179 22.5L89.0895 25.3284C88.8942 25.5237 88.8942 25.8403 89.0895 26.0355C89.2847 26.2308 89.6013 26.2308 89.7966 26.0355L92.9786 22.8536ZM63.25 23.0001L92.625 23L92.625 22L63.25 22.0001L63.25 23.0001Z", fill: "#9462FF", style: {
  fill: "#9462FF",
  fill: "color(display-p3 0.5807 0.3833 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M60.5833 22.5C60.5833 23.9728 61.7772 25.1667 63.25 25.1667C64.7228 25.1667 65.9167 23.9728 65.9167 22.5C65.9167 21.0272 64.7228 19.8333 63.25 19.8333C61.7772 19.8333 60.5833 21.0272 60.5833 22.5ZM92.5202 66.6036C92.7155 66.4083 92.7155 66.0917 92.5202 65.8964L89.3382 62.7145C89.143 62.5192 88.8264 62.5192 88.6311 62.7145C88.4359 62.9097 88.4359 63.2263 88.6311 63.4216L91.4596 66.25L88.6311 69.0784C88.4359 69.2737 88.4359 69.5903 88.6311 69.7855C88.8264 69.9808 89.143 69.9808 89.3382 69.7855L92.5202 66.6036ZM63.25 23C65.9773 23 68.0826 23.6614 69.741 24.7929C71.4032 25.9271 72.6524 27.5583 73.6233 29.5546C75.5768 33.5713 76.3557 38.9529 77.151 44.4466C77.9391 49.8911 78.7436 55.4475 80.8013 59.6346C81.836 61.7401 83.1992 63.5265 85.0591 64.7843C86.9226 66.0446 89.2489 66.75 92.1667 66.75V65.75C89.4178 65.75 87.2936 65.0882 85.6193 63.9559C83.9414 62.8212 82.6796 61.1895 81.6987 59.1935C79.7251 55.1775 78.9359 49.7964 78.1407 44.3034C77.3526 38.8596 76.5586 33.3037 74.5226 29.1173C73.4987 27.012 72.1489 25.2253 70.3046 23.9669C68.4564 22.7058 66.1477 22 63.25 22V23Z", fill: "#9462FF", style: {
  fill: "#9462FF",
  fill: "color(display-p3 0.5807 0.3833 1.0000)",
  fillOpacity: 1
} }));
export default SvgCreateFunnel;
