import { Handle, Position } from 'reactflow';
import { SweepNodeHandles, hiddenHandleStyle } from './step-nodes/RegularStep';
import { memo } from 'react';

const SweepNode = ({ children }: { children: any }) => {
  return (
    <div data-testid="sweep-node">
      <Handle
        type="target"
        position={Position.Right}
        isConnectable={false}
        id={SweepNodeHandles.TargetRight}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={false}
        id={SweepNodeHandles.SourceRight}
      />
      <Handle
        type="target"
        position={Position.Top}
        isConnectable={false}
        id={SweepNodeHandles.TargetTop}
      />
      <Handle
        type="source"
        position={Position.Top}
        isConnectable={false}
        id={SweepNodeHandles.SourceTop}
      />
      {children}
      <Handle
        type="target"
        position={Position.Left}
        style={hiddenHandleStyle}
        isConnectable={false}
        id={SweepNodeHandles.TargetLeft}
      />
      <Handle
        type="source"
        position={Position.Left}
        isConnectable={false}
        id={SweepNodeHandles.SourceLeft}
      />
      <Handle
        type="target"
        position={Position.Bottom}
        style={hiddenHandleStyle}
        isConnectable={false}
        id={SweepNodeHandles.TargetBottom}
      />
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={false}
        id={SweepNodeHandles.SourceBottom}
      />
    </div>
  );
};

const _memoSweepNode = memo(SweepNode);

export { _memoSweepNode as SweepNode };
