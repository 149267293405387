import { useCallback, useState } from 'react';

export enum LoadingState {
  NotLoading = 'NotLoading',
  Loading = 'Loading',
  FirstLoad = 'FirstLoad',
}

const getLoadingItem = (loadingState: LoadingState) => {
  let loadingItem:
    | {
        label: string;
        tooltip?: string;
      }
    | undefined = undefined;

  if (loadingState === LoadingState.Loading) {
    loadingItem = {
      label: 'Loading...',
    };
  }
  if (loadingState === LoadingState.FirstLoad) {
    loadingItem = {
      label: 'Loading configuration...',
    };
  }
  return loadingItem;
};

export const useLoadingItem = () => {
  const [loadingState, setLoadingState] = useState(LoadingState.Loading);
  const calculateAndSetLoadingState = useCallback((complete: boolean, isFirstLoad: boolean) => {
    let _loadingState;
    if (isFirstLoad) {
      _loadingState = LoadingState.FirstLoad;
    } else {
      _loadingState = complete ? LoadingState.NotLoading : LoadingState.Loading;
    }
    setLoadingState(_loadingState);

    return getLoadingItem(_loadingState);
  }, []);

  const loadingItem = getLoadingItem(loadingState);

  return {
    loadingState,
    loadingItem,
    calculateAndSetLoadingState,
  };
};
