import { Box } from '@mui/material';
import { AttributeProps } from '../../../../../constants/fieldsManagementConsts';
import { useCallback, useState } from 'react';
import PicklistTable from './PickistTable';
import { IconButton } from '@sweep-io/sweep-design';
import { Plus } from '@sweep-io/sweep-design/dist/icons';

const AttributePicklist = ({ setFieldValue, isLeadingField, values }: AttributeProps) => {
  const [counter, setCounter] = useState(2);

  const getInitialValue = useCallback(() => {
    const res = {
      default: false,
      fullName: `Value ${counter}`,
      label: `Value ${counter}`,
    } as PicklistValue;
    setCounter(counter + 1);
    return res;
  }, [counter]);

  const handleChange = useCallback(
    (updatedValueSet: PicklistValue[]) => {
      setFieldValue('properties.valueSet', updatedValueSet);
    },
    [setFieldValue],
  );

  const addRow = () => {
    let updated = values?.properties?.valueSet;
    updated = updated ? updated.concat([getInitialValue()]) : [getInitialValue()];
    setFieldValue('properties.valueSet', updated);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="8px"
      sx={{
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          overflowY: 'auto',
        }}
      >
        <PicklistTable
          valueSet={values.properties?.valueSet ?? []}
          handleChange={handleChange}
          isLeadingField={isLeadingField}
        />
      </Box>

      {!isLeadingField && (
        <Box flex="0 0 auto">
          <IconButton onClick={addRow} variant="outlined" size="small">
            <Plus />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default AttributePicklist;
