import { SweepFieldTypes } from '../../../../../../../types/enums/SweepFieldTypes';
import { CriteriaPluginSection } from '../../base-criteria-plugin-panel/types';
import { MSvg, ESvg, DSvg, PSvg, ISvg, CSvg } from '../../med-icons';

export const meddpiccPluginStepMapping: CriteriaPluginSection[] = [
  {
    title: 'Metrics',
    icon: <MSvg />,
    pluginFields: [
      {
        pluginFieldId: 'metrics',
        fieldName: 'Metrics',
        apiName: 'Metrics__c',
        type: SweepFieldTypes.Text,
        description: '',
      },
      {
        pluginFieldId: 'metricStatus',
        fieldName: 'Metric Status',
        apiName: 'Metrics_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
  {
    title: 'Economic Buyer',
    icon: <ESvg />,
    pluginFields: [
      {
        pluginFieldId: 'economicBuyer',
        fieldName: 'Economic Buyer',
        apiName: 'Economic_Buyer__c',
        type: SweepFieldTypes.Lookup,
        lookupFieldProps: {
          lookupRelationshipName: 'EconomicBuyerContacts',
          lookupObjectName: 'Contact',
        },
        description: '',
      },
      {
        pluginFieldId: 'economicBuyerStatus',
        fieldName: 'Economic Buyer Status',
        apiName: 'Economic_Buyer_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
  {
    title: 'Decision Criteria',
    icon: <DSvg />,
    pluginFields: [
      {
        pluginFieldId: 'decisionCriteria',
        fieldName: 'Decision Criteria',
        apiName: 'Decision_Criteria__c',
        type: SweepFieldTypes.Text, // FreeText ?
        description: '',
      },
      {
        pluginFieldId: 'decisionCriteriaStatus',
        fieldName: 'Decision Criteria Status',
        apiName: 'Decision_Criteria_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
  {
    title: 'Decision Process',
    icon: <DSvg />,
    pluginFields: [
      {
        pluginFieldId: 'decisionProcess',
        fieldName: 'Decision Process',
        apiName: 'Decision_Process__c',
        type: SweepFieldTypes.Text, // FreeText ?
        description: '',
      },
      {
        pluginFieldId: 'decisionProcessStatus',
        fieldName: 'Decision Process Status',
        apiName: 'Decision_Process_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
  {
    title: 'Paper Process',
    icon: <PSvg />,
    pluginFields: [
      {
        pluginFieldId: 'paperProcess',
        fieldName: 'Paper Process',
        apiName: 'Paper_Process__c',
        type: SweepFieldTypes.Text, // FreeText ?
        description: '',
      },
      {
        pluginFieldId: 'paperProcessStatus',
        fieldName: 'Paper Process Status',
        apiName: 'Paper_Process_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
  {
    title: 'Identify Pain',
    icon: <ISvg />,
    pluginFields: [
      {
        pluginFieldId: 'identifyPain',
        fieldName: 'Identify Pain',
        apiName: 'Identify_Pain__c',
        type: SweepFieldTypes.Text, // FreeText ?
        description: '',
      },
      {
        pluginFieldId: 'identifyPainStatus',
        fieldName: 'Identify Pain Status',
        apiName: 'Identify_Pain_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
  {
    title: 'Champion',
    icon: <CSvg />,
    pluginFields: [
      {
        pluginFieldId: 'champion',
        fieldName: 'Champion',
        apiName: 'Champion__c',
        type: SweepFieldTypes.Lookup,
        lookupFieldProps: {
          lookupRelationshipName: 'ChampionContacts',
          lookupObjectName: 'Contact',
        },
        description: '',
      },
      {
        pluginFieldId: 'championStatus',
        fieldName: 'Champion Status',
        apiName: 'Champion_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
  {
    title: 'Competition',
    icon: <CSvg />,
    pluginFields: [
      {
        pluginFieldId: 'competition',
        fieldName: 'Competition',
        apiName: 'Competition__c',
        type: SweepFieldTypes.Text, // FreeText ?
        description: '',
      },
      {
        pluginFieldId: 'competitionStatus',
        fieldName: 'Competition Status',
        apiName: 'Competition_Status__c',
        type: SweepFieldTypes.Picklist,
        picklistValueSet: [
          { default: false, fullName: 'Unknown', label: 'Unknown' },
          { default: false, fullName: 'In discovery', label: 'In discovery' },
          { default: false, fullName: 'Known', label: 'Known' },
        ],
        description: '',
      },
    ],
  },
];
