import { Grid, ListItem, Typography } from '@mui/material';
import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';

const ConfirmSharedMetadataUsage = ({
  sharedMetadata,
  onConfirm,
  onCancel,
}: {
  onConfirm: () => void;
  onCancel: () => void;
  sharedMetadata: StageMetadata;
}) => {
  return (
    <ConfirmDialog
      isOpen
      onConfirm={onConfirm}
      title="Shared Opportunity Stage"
      disableOnBackdrop
      disableKeyBindings
      onCancel={onCancel}
      PaperProps={{
        sx: {
          width: 350,
        },
      }}
      children={
        <Grid container gap="15px">
          <Typography>
            {sharedMetadata.stageName} already exists in the following funnels:
          </Typography>

          <Typography>
            {sharedMetadata.usedInFunnels?.map((funnel) => (
              <ListItem
                sx={{
                  display: 'list-item',
                  '&:hover': { cursor: 'default', background: 'transparent' },
                }}
                key={funnel.id}
              >
                {funnel.name}
              </ListItem>
            ))}
          </Typography>

          <Typography>
            Applying this change would override the current step's Type, Probability and Forecast
            Category with the values from {sharedMetadata?.stageName}.
          </Typography>
        </Grid>
      }
    />
  );
};

export { ConfirmSharedMetadataUsage };
