import { useContext, useMemo } from 'react';
import { XYPosition } from 'reactflow';
import { CanvasElementType, CanvasMode, SweepCanvasNode } from './canvasTypes';
import { _canvasIndexPositionToReactFlowPosition } from './helpers/calculateHandlePositionsBasedOnCoords';

import {
  DropZoneNodes,
  GroupDataReactFlowNode,
  GroupLabelDataReactFlowNode,
  GroupOverlayDataReactFlowNode,
} from './canvasTypes/nodeTypesData';
import { calculateGroupDimensionsAndPosition } from './nodes/helpers';
import { useCanvasInternalContext } from './CanvasContext';
import { InternalCanvasCtx } from './internal-context/InternalCanvasCtx';
import { SweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';

export interface NodeInfo {
  id: string;
  position: XYPosition;
}

interface UseGroupNodesProps {
  internalSweepNodes: SweepCanvasNode[];
  dropZoneNode?: DropZoneNodes;
}

export const useGroupNodes = ({ internalSweepNodes, dropZoneNode }: UseGroupNodesProps) => {
  const {
    hideGroupInfo,
    canvasMode = CanvasMode.DEFAULT,
    disableGroups,
    onPluginClick,
    showGroupOverlays,
    sweepGroups,
    readonly,
    onNodeClick,
    onPillClick,
    onLabelClick,
  } = useContext(SweepCanvasPropsCtx);
  const { draggingGroupInfo, temporaryTransformations } = useContext(InternalCanvasCtx);
  const { getOrCreateRefForPluginButton } = useCanvasInternalContext();

  const { groupLabelNodes, groupNodes, groupOverlayNodes, draggingGroupNodeId, firstEmptyGroup } =
    useMemo(() => {
      if (disableGroups) {
        return {
          groupNodes: [],
          groupLabelNodes: [],
          groupOverlayNodes: [],
        };
      }

      const ghostGroups = new Set(
        internalSweepNodes
          .filter((node) => node.type === CanvasElementType.GHOST_NODE)
          .map((node) => node.parentId),
      );

      const nonEmptyGroups = new Set(internalSweepNodes.map((node) => node.parentId));
      const firstEmptyGroup = sweepGroups
        .map((group) => group.id)
        .filter((groupId) => !nonEmptyGroups.has(groupId))
        .at(0);

      const _sweepGroups = [...sweepGroups];
      temporaryTransformations?.newGroup && _sweepGroups.push(temporaryTransformations.newGroup);

      const groupNodes: GroupDataReactFlowNode[] = [];
      const groupLabelNodes: GroupLabelDataReactFlowNode[] = [];
      const groupOverlayNodes: GroupOverlayDataReactFlowNode[] = [];

      _sweepGroups.forEach((sweepGroup) => {
        const isDragging = sweepGroup.id === draggingGroupInfo?.id;
        const position = isDragging
          ? draggingGroupInfo.position
          : _canvasIndexPositionToReactFlowPosition(sweepGroup.position);

        const rowsPositions = internalSweepNodes
          .filter((node) => node.parentId === sweepGroup.id)
          .map((node) => node.position.row);

        const colPositions = internalSweepNodes
          .filter((node) => node.parentId === sweepGroup.id)
          .map((node) => node.position.column);

        if (dropZoneNode && dropZoneNode?.parentNode === sweepGroup.id) {
          colPositions.push(dropZoneNode.data.positions.sweepCanvasPosition.column);
          rowsPositions.push(dropZoneNode.data.positions.sweepCanvasPosition.row);
        }
        if (rowsPositions.length === 0) {
          rowsPositions.push(0);
        }
        if (colPositions.length === 0) {
          colPositions.push(0);
        }

        const preview = canvasMode !== CanvasMode.DEFAULT;

        const groupNodePositions = {
          sweepCanvasPosition: sweepGroup.position,
          minRow: Math.min(...rowsPositions),
          maxRow: Math.max(...rowsPositions) + (preview ? 0 : 1), // +1 You need to accommodate for the label
          minCol: Math.min(...colPositions),
          maxCol: Math.max(...colPositions),
        };
        const reactFlowPositions = calculateGroupDimensionsAndPosition(
          groupNodePositions,
          !preview,
        );

        showGroupOverlays &&
          groupOverlayNodes.push({
            id: `overlay-${sweepGroup.id}`,
            data: {
              reactFlowPositions,
              onClick: onNodeClick,
              groupId: sweepGroup.id,
            },
            position,
            draggable: false,
            selectable: false,
            type: CanvasElementType.GROUP_OVERLAY,
            hidden: false,
            width: 1,
            height: 1,
            zIndex: 9999,
          });

        groupNodes.push({
          id: sweepGroup.id,
          data: {
            label: sweepGroup.name,
            groupNodePositions,
            reactFlowPositions,
            objectType: sweepGroup.objectType,
            readonly: readonly,
            hideGroupInfo,
            canvasMode,
            onClick: onNodeClick,
            isGhost: ghostGroups.has(sweepGroup.id),
            showEditButton: sweepGroup.showEditButton,
            groupId: sweepGroup.id,
          },
          position,
          draggable: true,
          selectable: false,
          type: CanvasElementType.GROUP,
          hidden: false,
          width: 1,
          height: 1,
        });

        if (!preview) {
          groupLabelNodes.push({
            id: `label-${sweepGroup.id}`,
            data: {
              label: sweepGroup.name,
              reactFlowPositions,
              objectType: sweepGroup.objectType,
              objectLabel: sweepGroup.objectLabel,
              readonly: readonly,
              hideGroupInfo,
              groupId: sweepGroup.id,
              onLabelClick,
              onPillClick,
              labelPlugins:
                sweepGroup.plugins?.map((pluginType) => ({
                  type: pluginType,
                  onClick: (event) =>
                    onPluginClick &&
                    onPluginClick({
                      parentId: sweepGroup.id,
                      pluginId: pluginType,
                      objectType: sweepGroup.objectType,
                      event,
                    }),
                  ref: getOrCreateRefForPluginButton(sweepGroup.id, pluginType),
                })) || [],

              pills: sweepGroup.pills,
              isDragging,
              startingIcon: sweepGroup.startingIcon,
              description: sweepGroup.description,
              objectLabelLogoDevBrand: sweepGroup.objectLabelLogoDevBrand,
            },
            position: { x: reactFlowPositions.marginLeft, y: reactFlowPositions.marginTop },
            draggable: true,
            selectable: false,
            type: CanvasElementType.GROUP_LABEL,
            parentNode: sweepGroup.id,
            zIndex: 1,
            height: 40,
            width: 1,
          });
        }
      });

      return {
        groupNodes,
        groupLabelNodes,
        groupOverlayNodes,
        draggingGroupNodeId: draggingGroupInfo?.id,
        firstEmptyGroup,
      };
    }, [
      canvasMode,
      disableGroups,
      draggingGroupInfo?.id,
      draggingGroupInfo?.position,
      dropZoneNode,
      getOrCreateRefForPluginButton,
      hideGroupInfo,
      internalSweepNodes,
      onPillClick,
      onLabelClick,
      onNodeClick,
      onPluginClick,
      readonly,
      showGroupOverlays,
      sweepGroups,
      temporaryTransformations?.newGroup,
    ]);

  return { groupLabelNodes, groupNodes, groupOverlayNodes, draggingGroupNodeId, firstEmptyGroup };
};
