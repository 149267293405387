import { useNavigate } from 'react-router';
import { Auth0Provider, AppState } from '@auth0/auth0-react';
import { RoutesToSkipRedirectCallbackAuth0 } from '../constants/appRoutes';
import { ReactNode } from 'react';
import { CookiesProvider } from 'react-cookie';
import { useAuthOrg } from './useAuthOrg';

const Auth0ProviderWithHistory = ({ children }: { children: ReactNode }) => {
  const domain = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const audience = import.meta.env.VITE_AUTH0_AUDIENCE;
  const navigate = useNavigate();
  const { authOrgId } = useAuthOrg();

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
  };

  return (
    <CookiesProvider>
      <Auth0Provider
        domain={domain ?? ''}
        clientId={clientId ?? ''}
        skipRedirectCallback={RoutesToSkipRedirectCallbackAuth0.includes(window.location.pathname)}
        onRedirectCallback={onRedirectCallback}
        authorizationParams={{
          redirect_uri: window.location.origin,
          useRefreshTokens: true,
          cacheLocation: 'localstorage',
          audience,
          ...(authOrgId && { organization: authOrgId }),
        }}
      >
        {children}
      </Auth0Provider>
    </CookiesProvider>
  );
};

export default Auth0ProviderWithHistory;
