import { useMemo, useState } from 'react';
import { useRunOnce } from '../../common/useRunOnce';
import { useSelector } from 'react-redux';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { selectSweepFieldLabels } from '../../../sweep-fields/sweepFieldsLabelsReducer';
import groupBy from 'lodash/groupBy';
import {
  createDealRoomRecipientItem,
  createSfRecipientItem,
  createSlackRecipientItem,
  SlackConstant,
} from './utils';
import { telemetry } from '../../../telemetry';

export const useGetSlackRecipientsLabels = ({
  recipients,
}: {
  recipients: SlackRecipientStruct[];
}) => {
  const [recipientsWithLabels, setRecipientsWithLabels] = useState(recipients ?? []);
  const { getSlackRecipients } = useIntegrations();
  const sweepFieldsLabels = useSelector(selectSweepFieldLabels);

  useRunOnce(async () => {
    const allGroups = groupBy(recipients, 'type');
    const slackRecipients = allGroups[SlackConstant.Slack] ?? [];
    const sfRecipients = [
      ...(allGroups[SlackConstant.Salesforce] ?? []),
      ...(allGroups[SlackConstant.Salesforce_Created_Slack_Channel] ?? []),
    ];
    const dealRoomRecipient = allGroups[SlackConstant.Sweep_Created_Slack_Channel] ?? [];
    if (Object.keys(allGroups).length) {
      let recipientsListLabels: SlackRecipientStruct[] = [];
      try {
        if (slackRecipients?.length > 0) {
          const _recipientsIds = slackRecipients?.map((el) => el.value) as string[];
          const recipientsList = await getSlackRecipients(_recipientsIds);
          recipientsListLabels = recipientsList?.map(createSlackRecipientItem);
        }
      } catch (e) {
        telemetry.captureError(e);
      }

      const sfWithLabel: SlackRecipientStruct[] =
        sfRecipients?.map((el) => createSfRecipientItem(el, sweepFieldsLabels)) ?? [];

      const dealRoomItem = dealRoomRecipient?.map(createDealRoomRecipientItem) ?? [];

      setRecipientsWithLabels([...sfWithLabel, ...recipientsListLabels, ...dealRoomItem]);
    }
  });

  const recipientWithLabelsArray = useMemo(
    () =>
      recipients?.map((el) => ({
        ...el,
        _label: !el._label
          ? recipientsWithLabels?.find((item) => item.value === el.value)?._label
          : el._label,
      })),
    [recipients, recipientsWithLabels],
  );

  return {
    recipientWithLabelsArray,
  };
};
