import { useLocation } from 'react-router-dom';
import { useRunOnce } from '../../common/useRunOnce';
import { useSweepApi } from '../../../apis/sweep';
import { telemetry } from '../../../telemetry';

const HubspotOAuth2Redirect = () => {
  const { search } = useLocation();
  const sweepApi = useSweepApi();

  useRunOnce(async () => {
    const urlSearchParams = new URLSearchParams(search);
    const code = urlSearchParams.get('code');
    const state = urlSearchParams.get('state');

    if (state && code) {
      try {
        await sweepApi.get(
          `/hubspot-connector/hubspot-oauth2-callback?code=${code}&state=${state}`,
        );
      } catch (error) {
        telemetry.captureError(error);
      }
    }
    window.close();
  });

  return null;
};

export { HubspotOAuth2Redirect };
