import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectFieldsUsage,
  setFieldsUsageData,
} from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import { useFunnelMapApiFacade } from '../../../../../apis/facades/funnel-map/useFunnelMapApiFacade';

import { useRunOnceWhenTruthy } from '../../../../common/useRunOnceWhenTruthy';

const useManageFields = ({ funnelMapId }: { funnelMapId: string }) => {
  const { get_funnelMapSweepFields } = useFunnelMapApiFacade();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const fieldsUsage = useSelector(selectFieldsUsage);

  useRunOnceWhenTruthy(() => {
    const fetchFunnelFieldsData = async () => {
      setIsLoading(true);
      const fieldsData = await get_funnelMapSweepFields(funnelMapId);
      setIsLoading(false);
      dispatch(
        setFieldsUsageData({
          fieldsData,
        }),
      );
    };

    fetchFunnelFieldsData();
  }, Boolean(funnelMapId));

  return { fieldsUsage, isLoading };
};

export default useManageFields;
