import { useContext, useState } from 'react';
import { RecordTypeLeadingObjectCtx } from './RecordTypeLeadingObjectCtx';
import { RecordTypeLeadingObjectPanel } from './RecordTypeLeadingObjectPanel';
import { StyledListItem } from '../../../../common/StyledListItem';
import { Box, Popover } from '@mui/material';
import { ChevronRight } from '@sweep-io/sweep-design/dist/icons';

export const RecordTypeLeadingObjectPopover = ({ crmOrgId }: { crmOrgId: string }) => {
  const { currentRecord, clearLeadingFieldForRecordType, setCurrentRecord, hideRecordType } =
    useContext(RecordTypeLeadingObjectCtx);
  const [selectBusinessProcess, setSelectBusinessProcess] = useState(
    currentRecord?.origin === 'setRecord',
  );

  if (!currentRecord) {
    return;
  }

  return (
    <>
      {selectBusinessProcess && (
        <RecordTypeLeadingObjectPanel currentRecord={currentRecord} crmOrgId={crmOrgId} />
      )}

      <Popover
        onClose={() => setCurrentRecord(undefined)}
        open={currentRecord.origin === 'editRecord'}
        anchorEl={currentRecord.anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={1} pb={0}>
          <StyledListItem
            onClick={() => setSelectBusinessProcess(true)}
            title="Select process stages"
            EndAdornment={<ChevronRight />}
          />

          <StyledListItem
            onClick={() => {
              clearLeadingFieldForRecordType({
                crmOrgId,
                recordTypeApiName: currentRecord.recordTypeId,
              });
              setCurrentRecord(undefined);
            }}
            title="Clear business process"
          />

          <StyledListItem
            onClick={() => {
              hideRecordType({
                crmOrgId,
                recordTypeApiName: currentRecord.recordTypeId,
              });
              setCurrentRecord(undefined);
            }}
            title="Hide this record type from view"
          />
        </Box>
      </Popover>
    </>
  );
};
