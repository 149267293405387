import * as React from 'react';
import { styled } from '@mui/material/styles';
import useResizeObserver from 'use-resize-observer';
import { Box, SxProps, Theme, Typography } from '@mui/material';
import { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import classNames from 'classnames';
import { TruncatedTextTooltip } from './TruncatedTextTooltip';
import { colors } from '@sweep-io/sweep-design';
import deferCallback from '../../lib/deferCallback';

const StyledInputElement = styled('input')(
  () => `border:none;
  background-image:none;
  background-color:transparent;
  box-shadow: none;
  &:focus {
    outline: none;
  }`,
);

interface EditableLabelProps {
  value?: string;
  placeholder?: string;
  padding?: string;
  height?: number;
  defaultIsEditing?: boolean;
  minCharLength?: number;
  maxCharLength?: number;
  onValueConfirm: (value: string) => any;
  onCancel?: (isConfirmed?: boolean) => any;
  validateClbk?: EditableLabelValidationFunction | null;
  validateOnChange?: boolean;
  inputSx?: SxProps<Theme>;
  errorSx?: SxProps<Theme>;
  readonly?: boolean;
}

type EditableLabelValidationResponse = {
  isValid: boolean;
  error?: string;
};

export type EditableLabelValidationFunction = (
  value: string,
) => EditableLabelValidationResponse | Promise<EditableLabelValidationResponse>;

const editableLabelValidationBuilderFunction =
  (minCharLength: number, maxCharLength: number): EditableLabelValidationFunction =>
  (value: string) => {
    if (value.length < minCharLength) {
      return {
        isValid: false,
        error: `Input must have at least ${minCharLength} character${
          minCharLength === 1 ? '' : 's'
        }`,
      };
    }
    if (value.length > maxCharLength) {
      return {
        isValid: false,
        error: `Input must have less than ${maxCharLength} character${
          maxCharLength === 1 ? '' : 's'
        }`,
      };
    }
    return {
      isValid: true,
    };
  };

const defaultSx: SxProps = {
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '15px',
  fontStretch: '100%',
  letterSpacing: 'normal',
};

export type EditableLabelRef = {
  edit: () => void;
};

export const EditableLabel = React.forwardRef<EditableLabelRef, EditableLabelProps>(
  (
    {
      value = '',
      placeholder,
      onValueConfirm,
      defaultIsEditing,
      padding = '2px 8px',
      height = 22,
      inputSx,
      errorSx,
      minCharLength = 2,
      maxCharLength = 30,
      validateClbk,
      validateOnChange,
      onCancel,
      readonly,
    },
    ref,
  ) => {
    const _inputSx = { ...defaultSx, ...inputSx };
    const [textFieldValue, setTextFieldValue] = useState(value);
    const [validationError, setValidationError] = useState<string>();

    const validateCallback =
      validateClbk || editableLabelValidationBuilderFunction(minCharLength, maxCharLength);

    const [autofocus, setAutofocus] = useState(defaultIsEditing);
    const inputRef = useRef<any>();

    const { ref: resizeObserverRef } = useResizeObserver();
    const [isEditing, setIsEditing] = useState(defaultIsEditing && !readonly);

    const validate = async (value?: string) => {
      if (validateCallback) {
        const validationResult = await validateCallback(value || textFieldValue);
        if (!validationResult.isValid) {
          setValidationError(validationResult.error || 'Validation Error');
        } else {
          setValidationError(undefined);
        }
        return validationResult.isValid;
      }
      return true;
    };

    const tryToConfirmValue = async () => {
      if (await validate()) {
        onValueConfirm(textFieldValue);
        setIsEditing(false);
        setValidationError(undefined);
      }
    };

    const confirmOrCancel = async () => {
      const isValid = await validate();
      const isChanged = textFieldValue !== value;
      if (isValid && isChanged) {
        onValueConfirm(textFieldValue);
      }
      setIsEditing(false);
      setValidationError(undefined);
      onCancel && onCancel(isValid);
    };

    const handleKeyCaptureUp = (event: React.KeyboardEvent) => {
      event.stopPropagation();
      if (event.key === 'Enter') {
        tryToConfirmValue();
      }
      if (event.key === 'Escape') {
        setTextFieldValue(value);
        setIsEditing(false);
        setValidationError(undefined);
        onCancel && onCancel();
      }
    };

    useEffect(() => {
      if (!isEditing) {
        setTextFieldValue(value);
        setAutofocus(false);
      } else {
        setAutofocus(true);
        const timeout = setTimeout(() => {
          inputRef?.current?.focus();
        }, 100);
        return () => {
          clearTimeout(timeout);
        };
      }
    }, [isEditing, value]);

    const edit = useCallback(() => {
      setIsEditing(true);
    }, []);

    useImperativeHandle(
      ref,
      () => ({
        edit,
      }),
      [edit],
    );

    return (
      <Box sx={{ position: 'relative' }}>
        <Box
          className={classNames({
            editing: isEditing,
            error: validationError,
            empty: textFieldValue.length === 0,
          })}
          height={`${height}px`}
          lineHeight={`${height}px`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 0,
            height: `${height}px`,
            lineHeight: `${height}px`,
            zIndex: isEditing ? 0 : 1,
            top: 0,
            left: 0,
            border: '1px solid transparent',
            width: '100%',
            overflow: 'hidden',
            '&:hover': {
              borderColor: readonly ? '' : colors.grey[300],
            },
            '&.editing': {
              borderColor: colors.blue[500],
            },
            '&.editing.error': {
              borderColor: colors.blush[500],
            },
            ..._inputSx,
          }}
          onClick={() => {
            !isEditing && !readonly && setIsEditing(true);
          }}
          ref={resizeObserverRef}
        >
          <TruncatedTextTooltip>
            <Typography
              component="span"
              visibility={isEditing ? 'hidden' : 'visible'}
              className={classNames({
                placeholder: !textFieldValue && placeholder,
              })}
              sx={{
                padding,
                whiteSpace: 'pre',
                '&.placeholder': {
                  color: 'lightgray',
                },
                ..._inputSx,
              }}
            >
              {textFieldValue || placeholder}
            </Typography>
          </TruncatedTextTooltip>
        </Box>
        <StyledInputElement
          value={textFieldValue}
          onChange={async (ev) => {
            setTextFieldValue(ev.target.value);
            if (validateOnChange && isEditing) {
              await validate(ev.target.value);
            } else {
              setValidationError(undefined);
            }
          }}
          ref={inputRef}
          autoFocus={autofocus}
          onKeyDownCapture={handleKeyCaptureUp}
          maxLength={maxCharLength}
          minLength={minCharLength}
          sx={{
            position: 'absolute',
            top: 0,
            zIndex: isEditing ? 1 : -1,
            height: `${height}px`,
            lineHeight: `${height - 1}px`,
            border: '1px solid transparent',
            padding,
            visibility: isEditing ? 'visible' : 'hidden',
            width: '100%',
            ..._inputSx,
          }}
          // using "deferCallback" to allow executing the "onClick" that triggered the "onBlur"
          // (e.g. click on other tab or on a dialog's "x")
          onBlur={deferCallback(confirmOrCancel)}
        />

        {validationError && (
          <Box
            sx={{
              '&.validation-error': {
                color: colors.blush[500],
              },
              ...errorSx,
            }}
            className="validation-error"
          >
            {validationError}
          </Box>
        )}
      </Box>
    );
  },
);
