import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SfChangeFeedListItem,
  SfChangeFeedFilters,
  SfChangeFeedSortAndFilter,
  SfChangeFeedNotification,
} from '../components/pages/sf-change-feed/types';
import {
  addBlanks,
  extractFilters,
  fadeBackground,
} from '../components/pages/sf-change-feed/helpers';
import { GetSfChangeFeedResponse } from '../apis/facades/useSfChangeFeedApiFacade';
import { AdvancedFilterItem } from '../components/common/deprecated-advanced-filter/DeprecatedAdvancedFilter';
import { getAdvanceFilterItemStructure } from '../components/pages/sf-change-feed/getAdvanceFilterItemStructure';

export interface SfChangeFeedState {
  list: SfChangeFeedListItem[];
  filtersOptions: { sfUserNames: AdvancedFilterItem[]; sections: AdvancedFilterItem[] };
  sortAndFilter: SfChangeFeedSortAndFilter;
  isNotificationDialogOpen: boolean;
  notifications: SfChangeFeedNotification[];
  temporaryListItems?: SfChangeFeedListItem[];
  initialElementForForm?: SfChangeFeedNotification;
}

const initialState: SfChangeFeedState = {
  list: [],
  filtersOptions: { sfUserNames: [], sections: [] },
  sortAndFilter: {},
  isNotificationDialogOpen: false,
  notifications: [],
};

export const sfChangeFeedSlice = createSlice({
  name: 'sfChangeFeed',
  initialState,
  reducers: {
    setSfChangeFeedList: (
      state,
      action: PayloadAction<{
        response: GetSfChangeFeedResponse;
        selectedFilters?: SfChangeFeedFilters;
        withFilters?: boolean;
      }>,
    ) => {
      const { selectedFilters, withFilters } = action.payload;
      const { changeFeedList, filters } = action.payload.response;
      state.list = changeFeedList;

      if (withFilters) {
        state.filtersOptions = {
          sfUserNames: getAdvanceFilterItemStructure(
            extractFilters(addBlanks(filters.sfUserNames ?? [])),
          ),
          sections: getAdvanceFilterItemStructure(
            extractFilters(addBlanks(filters.sections ?? [])),
          ),
        };

        state.sortAndFilter = {
          ...selectedFilters,
        };
      }
    },
    setSfChangeFeedFilter: (
      state,
      action: PayloadAction<{ newFilter: Partial<SfChangeFeedFilters>; clear?: boolean }>,
    ) => {
      const { clear, newFilter } = action.payload;
      state.sortAndFilter = {
        ...(clear ? {} : state.sortAndFilter),
        ...newFilter,
      };
    },
    setFiltersOptions: (state, action: PayloadAction<{ filters: SfChangeFeedFilters }>) => {
      const { filters } = action.payload;
      state.filtersOptions = {
        sfUserNames: getAdvanceFilterItemStructure(
          extractFilters(addBlanks(filters.sfUserNames ?? [])),
        ),
        sections: getAdvanceFilterItemStructure(extractFilters(addBlanks(filters.sections ?? []))),
      };
    },
    openNotificationDialog: (state, action: PayloadAction<{ toggleOpen: boolean }>) => {
      const { toggleOpen } = action.payload;
      state.isNotificationDialogOpen = toggleOpen;

      if (!toggleOpen) {
        state.initialElementForForm = undefined;
      }
    },
    setSfChangeFeedNotifications: (
      state,
      action: PayloadAction<{ notifications: SfChangeFeedNotification[] }>,
    ) => {
      state.notifications = action.payload.notifications;
    },
    saveNotification: (
      state,
      action: PayloadAction<{ notification: SfChangeFeedNotification }>,
    ) => {
      const { notification } = action.payload;

      if (!state.notifications.find((not) => not.id === notification.id)) {
        state.notifications.push(notification);
      } else {
        state.notifications = state.notifications.map((not) =>
          not.id === notification.id ? notification : not,
        );
      }
    },
    removeNotification: (state, action: PayloadAction<{ notificationId: string }>) => {
      const { notificationId } = action.payload;

      const index = state.notifications.findIndex(
        (notification) => notification.id === notificationId,
      );

      if (index >= 0) {
        state.notifications.splice(index, 1);
      }
    },
    setTemporaryRows: (
      state,
      action: PayloadAction<{ temporaryListItems: SfChangeFeedListItem[] }>,
    ) => {
      state.temporaryListItems = action.payload.temporaryListItems.map((item) => ({
        ...item,
        _animation: `${fadeBackground} 3s ease`,
      }));
    },
    displayChangedRows: (state) => {
      if (state.temporaryListItems) {
        state.list = [...(state.temporaryListItems ?? []), ...state.list];
      }

      state.temporaryListItems = undefined;
    },
    setFormInitialSkeleton: (
      state,
      action: PayloadAction<{ element?: SfChangeFeedNotification }>,
    ) => {
      state.initialElementForForm = action.payload.element;
    },
  },
});

export const {
  setSfChangeFeedList,
  setSfChangeFeedFilter,
  openNotificationDialog,
  setSfChangeFeedNotifications,
  saveNotification,
  removeNotification,
  displayChangedRows,
  setTemporaryRows,
  setFormInitialSkeleton,
  setFiltersOptions,
} = sfChangeFeedSlice.actions;

export const selectSfChangeFeedList = (state: RootState) => state.sfChangeFeed.list;
export const selectSfChangeFeedFilters = (state: RootState) => state.sfChangeFeed.sortAndFilter;
export const selectSfChangeFeedFiltersOptions = (state: RootState) =>
  state.sfChangeFeed.filtersOptions;
export const selectSearch = (state: RootState) => state.sfChangeFeed.sortAndFilter.actionText;

export const selectIsNotificationDialogOpen = (state: RootState) =>
  state.sfChangeFeed.isNotificationDialogOpen;
export const selectNotifications = (state: RootState) => state.sfChangeFeed.notifications;

export const selectInitialElementForForm = (state: RootState) =>
  state.sfChangeFeed.initialElementForForm;

export default sfChangeFeedSlice.reducer;
