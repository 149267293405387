import { useCallback, useState } from 'react';
import { AutomationsDiffDialog } from '../../Automations/AutomationsDiffDialog';
import ConnectedDeployButton, { ConnectedDeployButtonProps } from './ConnectedDeployButton';
import { useAutomationsContext } from '../../Automations/AutomationsContext';
import { DeployStatusForTable } from '../../../types/enums/DeployStatusForTable';
import { RequestToDeployDialog } from './RequestToDeployDialog';
import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';
import { useSweepNotifications } from '../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../reducers/notificationsReducer';
import { useShouldRequestToDeploy } from './useShouldRequestToDeploy';

interface AutomationsDeployButtonProps
  extends Omit<ConnectedDeployButtonProps, 'onShowDiff' | 'onDeployOrSave'> {
  onDeployOrSave: (
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  disableDiff?: boolean;
  disableRequestToDeployDialog?: boolean;
  disableRequestToDeployReviewChanges?: boolean;
}
export const AutomationsDeployButton = ({
  onDeployOrSave,
  disableDiff,
  disableRequestToDeployDialog,
  ...restProps
}: AutomationsDeployButtonProps) => {
  const { addNotification } = useSweepNotifications();

  const [isDiffDialogOpen, setIsDiffDialogOpen] = useState(false);
  const { automationJson, requestDeployNotifyAdmin } = useAutomationsContext();
  const [requestToDeployOpen, setRequestToDeployOpen] = useState(false);
  const defaultCreationEnvironment = useSelector(selectDefaultCreationEnvironment);

  const openDiffDialog = useCallback(() => {
    setIsDiffDialogOpen(true);
  }, []);

  const closeDiffDialog = useCallback(() => {
    setIsDiffDialogOpen(false);
  }, []);

  const isShowDiff =
    !disableDiff &&
    automationJson?.automationId &&
    automationJson?.status &&
    automationJson?.status !== DeployStatusForTable.Draft;

  const shouldRequestToDeploy = useShouldRequestToDeploy();
  const displayRequestToDeployDialog = !disableRequestToDeployDialog && shouldRequestToDeploy;

  const requestOrDeploy = useCallback(
    async (crmOrgIds: string[]) => {
      if (displayRequestToDeployDialog && crmOrgIds.length) {
        setRequestToDeployOpen(true);
        return {};
      } else {
        return await onDeployOrSave(crmOrgIds);
      }
    },
    [displayRequestToDeployDialog, onDeployOrSave],
  );

  const requestToDeployAndSave = useCallback(
    async (note: string) => {
      setRequestToDeployOpen(false);
      const automation = await onDeployOrSave([]);
      if (!automation?.isError && automation?.versionId && automation?.automationId) {
        await requestDeployNotifyAdmin(automation.automationId, automation.versionId, note);
        addNotification({
          message: `Automation was saved. Deploy request was sent to Sweep admins.`,
          variant: SweepNotificationVariant.Success,
        });
      }
    },
    [addNotification, onDeployOrSave, requestDeployNotifyAdmin],
  );

  return (
    <>
      {isDiffDialogOpen && (
        <AutomationsDiffDialog
          onCloseDialog={closeDiffDialog}
          automationItem={automationJson}
          onDeployOrSave={requestOrDeploy}
          buttonDisabled={restProps.disabled}
        />
      )}
      <ConnectedDeployButton
        {...restProps}
        onDeployOrSave={requestOrDeploy}
        onShowDiff={isShowDiff ? openDiffDialog : undefined}
      />
      {requestToDeployOpen && (
        <RequestToDeployDialog
          onCloseDialog={() => {
            setRequestToDeployOpen(false);
          }}
          onRequestToDeploy={requestToDeployAndSave}
          envName={defaultCreationEnvironment?.name || ''}
        />
      )}
    </>
  );
};
