import { CloudArrowDown, Integrations, Layout, Plus } from '@sweep-io/sweep-design/dist/icons';
import { ActionsMenu } from '../../../../common/actions-menu/ActionsMenu';
import { useFeatureToggle } from '../../../../common/useFeatureToggle';
import { importFunnelPanelType } from '../../../funnel-map-flow/dialogs/import-funnel/importFunnelPanelType';
import { useTurnOffCommentsModeFirst } from './useTurnOffCommentsModeFirst';
import { usePanels } from '../../../../panels/PanelsContext';
import { openNewFunnelDialog } from '../../../../../reducers/newFunnelReducer';
import { useDispatch } from 'react-redux';
import { useConfigurationCanvasContext } from '../../../configuration-canvas/ConfigurationCanvasContext';
import { useAreAllPermissionsAllowed } from '../../../../common/permissions/usePermission';
import { templatesPanelType } from '../../../funnel-map-flow/templates/dialog/panelTypes';
import { setAddOrImportDialogOpen } from '../../../../third-party-funnels/thirdPartyFunnelsReducer';

enum AddFunnelMenuActions {
  IMPORT_FUNNEL = 'import-funnel',
  NEW_FUNNEL = 'new-funnel',
  ADD_3TH_PARTY_FUNNEL = 'add-3th-party-funnel',
  TEMPLATES_AND_PLUGINS = 'templates-and-plugins',
}

export const AddFunnelMenu = () => {
  const { thirdPartyFunnels } = useFeatureToggle();
  const { appCrmOrgId } = useConfigurationCanvasContext();
  const { maybeOpenPanel } = usePanels();
  const turnOffCommentsModeFirst = useTurnOffCommentsModeFirst();
  const dispatch = useDispatch();
  const createFunnelsPermissions = useAreAllPermissionsAllowed(['create:funnels', 'edit:funnels']);

  if (!thirdPartyFunnels) {
    return null;
  }

  return (
    <>
      <ActionsMenu
        actions={[
          {
            label: 'Import funnel from Salesforce',
            value: AddFunnelMenuActions.IMPORT_FUNNEL,
            actionIcon: <CloudArrowDown />,
          },
          {
            label: 'New funnel',
            value: AddFunnelMenuActions.NEW_FUNNEL,
            actionIcon: <Plus />,
            disabled: !createFunnelsPermissions,
            disabledTooltip: 'To add new funnels, please contact your admin.',
          },
          {
            label: 'Add 3rd party funnel',
            value: AddFunnelMenuActions.ADD_3TH_PARTY_FUNNEL,
            actionIcon: <Integrations />,
            disabled: !createFunnelsPermissions,
            disabledTooltip: 'To add new funnels, please contact your admin.',
          },
          {
            label: 'Template and plugins',
            value: AddFunnelMenuActions.TEMPLATES_AND_PLUGINS,
            actionIcon: <Layout />,
            disabled: !createFunnelsPermissions,
            disabledTooltip: 'To add new funnels, please contact your admin.',
          },
        ]}
        onClick={(action) => {
          switch (action.value) {
            case AddFunnelMenuActions.IMPORT_FUNNEL:
              turnOffCommentsModeFirst(() => {
                maybeOpenPanel({ panelType: importFunnelPanelType });
              });
              break;
            case AddFunnelMenuActions.NEW_FUNNEL:
              dispatch(openNewFunnelDialog({ crmOrgId: appCrmOrgId }));
              break;
            case AddFunnelMenuActions.ADD_3TH_PARTY_FUNNEL:
              dispatch(setAddOrImportDialogOpen('import'));
              break;
            case AddFunnelMenuActions.TEMPLATES_AND_PLUGINS:
              turnOffCommentsModeFirst(() => {
                maybeOpenPanel({ panelType: templatesPanelType });
              });
              break;

            default:
              break;
          }
        }}
        MenuButtonIcon={<Plus />}
        iconSize="tiny"
        iconVariant="filled"
      />
    </>
  );
};
