import { isStageNurturingBucket, stageModel } from './stageModel';
import _isNil from 'lodash/isNil';
import _reject from 'lodash/reject';
import _clone from 'lodash/clone';
import _difference from 'lodash/difference';
import _isEqual from 'lodash/isEqual';
import _intersection from 'lodash/intersection';
import _intersectionWith from 'lodash/intersectionWith';
import { StageType } from '../types/enums/StageType';

type TStageNameValidationRule = {
  fn: (name: string) => boolean;
  errorMessage: string;
};

class SweepStagesModel {
  private _stages: SweepStage[];

  constructor(stages: SweepStage[]) {
    this._stages = stages;
  }

  public allStageNames = () => this._stages.map((stage) => stage.stageName);

  // TODO: Remove duplicate in funnelDetailsModel
  public stageById = (_stageId: string) => {
    const stage = this._stages.find((stage) => stage._stageId === _stageId);
    if (!stage) {
      throw new Error(`Stage "${_stageId}" not found`);
    }
    return stageModel(stage);
  };

  // TODO: Remove duplicate in funnelDetailsModel
  public stageByIdOrUndefined = (_stageId: string) => {
    const stage = this._stages.find((stage) => stage._stageId === _stageId);
    return stage ? stageModel(stage) : undefined;
  };

  public exitCriteriaStageNames = (stageId: string) => {
    return this.stageById(stageId)
      .getAllExitCriteria()
      .map((exitCriteria) =>
        this.stageByIdOrUndefined(exitCriteria.getNextStageId())?.getStageName(),
      );
  };

  public validateStageName = (name: string): { isValid: boolean; error?: string } => {
    const cannotBeEmpty: TStageNameValidationRule = {
      fn: (n) => n.length < 1,
      errorMessage: 'Stage name should have at least 1 character',
    };
    const longerThan30Chars: TStageNameValidationRule = {
      fn: (n) => n.length > 30,
      errorMessage: 'Stage name should have less than 30 character',
    };
    const nameAlreadyExists: TStageNameValidationRule = {
      fn: (n) => {
        const allNames = this.allStageNames().map((n) => n.toLowerCase());
        return allNames.includes(n.toLowerCase());
      },
      errorMessage: 'There is already a step with this name',
    };
    const rules = [cannotBeEmpty, longerThan30Chars, nameAlreadyExists];
    for (const rule of rules) {
      if (rule.fn(name)) {
        return {
          isValid: false,
          error: rule.errorMessage,
        };
      }
    }
    return {
      isValid: true,
    };
  };

  public static findAllPreviousStageIds = (stages: SweepStage[], stageId: string): string[] => {
    const previousStages = stages.reduce((connectedStages, stage) => {
      if (stageModel(stage).getAllConnectedStageIds().includes(stageId)) {
        connectedStages.add(stage._stageId);
      }

      return connectedStages;
    }, new Set<string>());
    return Array.from(previousStages);
  };

  public static findOldestParent = (stages: SweepStage[], stageId: string): string => {
    let oldestParent; //this is a stage with no parent
    let currentStage = stageId;
    while (currentStage) {
      const currentParents = this.findAllPreviousStageIds(stages, currentStage);
      if (currentParents.length === 0) {
        oldestParent = currentStage;
      }
      currentStage = currentParents[0];
    }
    return oldestParent ?? '';
  };

  //InitialStage - Product definition: the leftmost stage in the lowest branch (>=0)
  private getInitialStageForDeduce = (stages: SweepStage[]): SweepStage => {
    const lowestBranchIndex = Math.min(...stages.map((stage) => stage._branchIndex));
    const stagesInLowestBranch = stages.filter((stage) => stage._branchIndex === lowestBranchIndex);
    const lowestColumnIndex = Math.min(
      ...stagesInLowestBranch.map((stage) => stage._stageColumnIndex ?? Number.POSITIVE_INFINITY),
    );
    //should be only 1 initial (can't be 2 steps in same location)
    return (
      stagesInLowestBranch.find((stage) => stage._stageColumnIndex === lowestColumnIndex) ??
      stagesInLowestBranch[0] //this is just a fallback, should not occur
    );
  };

  private deduceOrder = (stages: SweepStage[], res: string[]): string[] => {
    const updatedRes = _clone(res);
    let updatedStages: SweepStage[] = [];

    const initialStage = this.getInitialStageForDeduce(stages);
    if (!initialStage) return []; //TODO handle this

    //There can be many parents, we're arbitrary taking one of them
    const parentStageId =
      SweepStagesModel.findOldestParent(stages, initialStage?._stageId ?? '') ?? '';

    updatedRes.push(parentStageId);
    updatedStages = _reject(stages, (stage) => stage._stageId === parentStageId);
    //removing the references to the deleted stage
    updatedStages = SweepStagesModel.removeNextStageReferences(updatedStages, parentStageId);
    if (updatedStages.length === 0) {
      return updatedRes;
    } else {
      return this.deduceOrder(updatedStages, updatedRes);
    }
  };

  public deduceStageOrder = (): string[] => {
    const nurturingBucketStages = this._stages.filter(isStageNurturingBucket);
    const nurturingBucketStagesSorted = nurturingBucketStages.sort((a, b) => {
      if (_isNil(a._stageColumnIndex)) return 1;
      if (_isNil(b._stageColumnIndex)) return -1;
      return a._stageColumnIndex - b._stageColumnIndex;
    });

    const relevantStages = _reject(this._stages, isStageNurturingBucket);

    //these are the stage id's
    const deducedOrder = this.deduceOrder(relevantStages, []);

    return [...deducedOrder, ...nurturingBucketStagesSorted.map((stage) => stage._stageId)];
  };

  public static removeNextStageReferences = (
    stages: SweepStage[],
    stageIdToRemove: string,
  ): SweepStage[] => {
    return stages.map((stage) => ({
      ...stage,
      exitCriteria: stage.exitCriteria.filter(
        (exitCriteria) => exitCriteria._nextStageId !== stageIdToRemove,
      ),
    }));
  };

  public static isCorrectValueSetOrder = (
    deducedOrderStageNames: string[],
    valueSetNames: string[],
    closedStageNames: string[],
  ): boolean => {
    //deducedOrderStageNames is a SUBSET of valueSetNames (because valueSetNames can have values from other funnels)
    const relevantPickListValues = _intersection(valueSetNames, deducedOrderStageNames);
    const areClosedAtTheEnd = checkIfClosedWonLostAtEnd(valueSetNames, closedStageNames);
    return areClosedAtTheEnd && _isEqual(relevantPickListValues, deducedOrderStageNames);
  };

  public static getValueSetNewOrder = (
    deducedOrderStageNames: string[],
    currentValueSet: PicklistValue[],
    closedStagesNames: string[],
  ): PicklistValue[] => {
    const valueSetNames = currentValueSet.map((value) => value.fullName);
    const nonRelevantPickListValueNames = _difference(valueSetNames, deducedOrderStageNames);
    const nonRelevantPickListValue = _intersectionWith(
      currentValueSet,
      nonRelevantPickListValueNames,
      (valueSet: PicklistValue, name: string) => valueSet.fullName === name,
    );

    const orderedPickListValues: PicklistValue[] = deducedOrderStageNames.map(
      (name) =>
        currentValueSet.find((value) => value.fullName === name) ?? {
          fullName: name,
          default: false,
          label: name,
        }, //if the item doesn't exist - create it
    );

    return moveClosedToEnd(
      orderedPickListValues.concat(nonRelevantPickListValue),
      closedStagesNames,
    );
  };

  public nonLostSteps = () => {
    return this._stages.filter((stage) => stage.stageType !== StageType.LOST);
  };
}

const checkIfClosedWonLostAtEnd = (
  currentOrderStageNames: string[],
  closedWonLostStageNames: string[],
) => {
  const closedWonLostCount = closedWonLostStageNames.length;
  const currentStagesCount = currentOrderStageNames.length;
  const lastValuesIndex = currentStagesCount - closedWonLostCount;
  const lastValues = currentOrderStageNames.slice(lastValuesIndex);
  return closedWonLostStageNames.every((name) => lastValues.includes(name));
};

export const moveClosedToEnd = (picklistValue: PicklistValue[], closedStagesNames: string[]) => {
  const res = [...picklistValue];
  closedStagesNames.forEach((closedStageName) => {
    res.push(
      res.splice(
        res.findIndex((picklistValue) => picklistValue.fullName === closedStageName),
        1,
      )[0],
    );
  });
  return res;
};

export default SweepStagesModel;
