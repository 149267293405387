import { PluginCard, PluginCardProps } from '../../../plugins-tab/PluginCard';
import { PluginDynamicPathSvg } from '../../icons';
import { dynamicPathPluginDesc1 } from './DynamicPathPage';

type DynamicPathPluginCardProps = Pick<PluginCardProps, 'onClick' | 'onAdd'>;

export const DynamicPathPluginCard = (props: DynamicPathPluginCardProps) => {
  return (
    <PluginCard
      svgIcon={<PluginDynamicPathSvg />}
      title="Dynamic Path Plugin"
      description={dynamicPathPluginDesc1}
      {...props}
    />
  );
};
