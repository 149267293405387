import { colors } from '@sweep-io/sweep-design';

//temporary button link variant styles - until they're replaced with new sweep-design buttons
export const sharedLinkVariantHoverState = {
  color: colors.blue[500],
  background: colors.storm[50],
};

export const sharedLinkVariantStyles = {
  fontSize: '12px',
  minWidth: 'auto',
  padding: '4px',
  fontWeight: 600,
  '&:hover': {
    ...sharedLinkVariantHoverState,
  },
};
