import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';

/** This reducer should hold only UI related state, not data !! **/

export interface LeftPanelState {
  hoveredItem: {
    automationId?: string;
  };
}

const initialState: LeftPanelState = {
  hoveredItem: {},
};

export const leftPanelSlice = createSlice({
  name: 'leftPanel',
  initialState,
  reducers: {
    setLeftPanelHoveredItem: (
      state,
      action: PayloadAction<{
        automationId?: string;
      }>,
    ) => {
      state.hoveredItem = action.payload;
    },
  },
});

export const { setLeftPanelHoveredItem } = leftPanelSlice.actions;

export const selectLeftPanelHoveredItem = (state: RootState) => state.leftPanel.hoveredItem;

export default leftPanelSlice.reducer;
