import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { selectDefaultCreationEnvironment } from '../environments/environmentsReducer';
import LoadingSweep from '../../common/LoadingSweep';
import { useRunOnceWhenTruthy } from '../../common/useRunOnceWhenTruthy';
import useSweepNavigate from '../../common/useSweepNavigate';

export const ConfigurationCanvasRedirectToDefaultCreationEnv = () => {
  const { navigate } = useSweepNavigate();
  const defaultCreationEnv = useSelector(selectDefaultCreationEnvironment);
  const { pathname } = useLocation();
  const targetPath = addCrmOrgIdToPath(pathname, defaultCreationEnv?.id);

  useRunOnceWhenTruthy(() => {
    // Redirect to the default creation environment
    // We can assume this is loaded by the global fetcher
    if (targetPath) {
      navigate(targetPath, { replace: true });
    }
  }, !!targetPath);

  return <LoadingSweep />;
};

//adds the crmOrgId after the first part of the path
const addCrmOrgIdToPath = (pathname: string, crmOrgId?: string) => {
  if (!crmOrgId) {
    return;
  }
  //pathname starts with '/'
  const path = pathname.split('/').filter(Boolean);
  const [base, ...rest] = path;
  return '/' + [base, crmOrgId, ...rest].join('/');
};
