import { Button } from '@sweep-io/sweep-design';
import { EnvironmentTypes } from '../EnvironmentTypeEnum';
import { Menu, MenuItem } from '@mui/material';
import { useState, useCallback } from 'react';
import useConnectToSf from '../../get-started/connect-org/useConnectToSf';
import { ConnectCrmOrgStateEnum } from '../ConnectCrmOrgStateEnum';

const optionsByEnvType = {
  [EnvironmentTypes.Production]: [
    {
      label: 'Production',
      value: EnvironmentTypes.Production,
    },
    {
      label: 'Sandbox',
      value: EnvironmentTypes.Sandbox,
    },
  ],
  [EnvironmentTypes.Sandbox]: [
    {
      label: 'Sandbox',
      value: EnvironmentTypes.Sandbox,
    },
    {
      label: 'Development',
      value: EnvironmentTypes.Development,
    },
  ],
};

export const ConnectEnvButton = ({
  desiredEnvType,
}: {
  desiredEnvType: EnvironmentTypes.Production | EnvironmentTypes.Sandbox;
}) => {
  const { connectState, setIsSandboxCb, onConnectStartHandler } = useConnectToSf({
    forceProduction: false,
  });

  const connectHandler = useCallback(
    (chosenEnvironment: EnvironmentTypes) => async () => {
      setMenuTarget(null);
      const forceMain = desiredEnvType === EnvironmentTypes.Production;
      const isSandbox = chosenEnvironment === EnvironmentTypes.Sandbox;
      const isDevelopment = chosenEnvironment === EnvironmentTypes.Development;
      setIsSandboxCb(isSandbox);
      await onConnectStartHandler({
        isSandboxValue: isSandbox,
        forceMain,
        isDevelopment,
        withNotification: true,
      });
    },
    [desiredEnvType, onConnectStartHandler, setIsSandboxCb],
  );

  const [menuTarget, setMenuTarget] = useState<null | HTMLElement>(null);

  if (
    connectState.type === 'connectState' &&
    connectState.connectState === ConnectCrmOrgStateEnum.Connecting
  ) {
    return <Button loading variant="flat" />;
  }
  return (
    <>
      <Button variant="flat" onClick={(ev) => setMenuTarget(ev.currentTarget)}>
        Connect
      </Button>
      <Menu
        anchorEl={menuTarget}
        open={!!menuTarget}
        onClose={() => setMenuTarget(null)}
        slotProps={{
          paper: {
            sx: {
              minWidth: '268px',
            },
          },
        }}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {optionsByEnvType[desiredEnvType].map((option) => (
          <MenuItem onClick={connectHandler(option.value)} key={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
