import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';
import { NestedSelectorPath, NestedItem } from './NestedSelector';
import {
  Info as InformationIcon,
  ChevronRight as NextIcon,
} from '@sweep-io/sweep-design/dist/icons';
import StyledTooltip from '../StyledTooltip';
import { Typography, colors, IconButton } from '@sweep-io/sweep-design';
import useQueryParams from '../../../hooks/useQueryParams';
import classNames from 'classnames';

export enum NestedItemClickType {
  EnterSubItem = 'EnterSubItem',
  Select = 'Select',
}

interface NestedSelectorItemProps {
  item: NestedItem;
  startIcon?: React.ReactNode;
  basePath: NestedSelectorPath;
  shouldFocus?: boolean;
  selected?: boolean;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  hideTooltipIcon?: boolean;
  variant?: 'regular' | 'italic';
  onClick?: (
    item: NestedItem,
    basePath: NestedSelectorPath,
    type: NestedItemClickType,
  ) => Promise<boolean>;
}

const fontStylesMap = {
  regular: {
    fontWeight: 600,
    fontSize: '12px',
  },
  italic: {
    fontWeight: 400,
    fontSize: '12px',
    fontStyle: 'italic',
    color: colors.grey[500],
  },
};

export const NestedSelectorItem = ({
  item,
  basePath,
  selected,
  onClick,
  shouldFocus,
  disabled,
  tooltip,
  hideTooltipIcon = true,
  startIcon,
  variant = 'regular',
}: NestedSelectorItemProps) => {
  const hasSubItems = item.subItems || item.shouldResolveSubItems;
  const [hasError, setHasError] = useState(false);

  const _onClick = (type: NestedItemClickType) => async () => {
    const success = onClick && (await onClick(item, basePath, type));
    if (!success) {
      setHasError(true);
      setTimeout(() => {
        setHasError(false);
      }, 3000);
    }
  };
  const query = useQueryParams();

  const debugData = query.get('debug')
    ? {
        'data-debug': JSON.stringify(item),
      }
    : {};

  const getListItemButton = () => (
    <ListItemButton
      autoFocus={selected && shouldFocus}
      onClick={disabled ? undefined : _onClick(NestedItemClickType.Select)}
      selected={false}
      data-testid="nested-selector-item"
      {...debugData}
    >
      <ListItemText
        primary={
          <Typography variant="body">
            {startIcon}
            {item.label}

            {tooltip && !hideTooltipIcon && <InformationIcon color={colors.grey[500]} />}
          </Typography>
        }
        sx={{
          '.MuiListItemText-primary': {
            lineHeight: '14.5px',
            color: hasError ? 'red' : 'black',
            ...fontStylesMap[variant],
          },
        }}
      />
    </ListItemButton>
  );

  return (
    <ListItem //TODO check if possible to move to menus
      sx={{
        '.MuiListItemSecondaryAction-root': {
          right: '24px',
        },
        '&.Mui-disabled': {
          opacity: 1,
        },
        '&.Mui-disabled .MuiTypography-root': {
          color: colors.grey[500],
        },
        '&.Mui-selected.HasSubItems': {
          '&:after': {
            right: '40px',
          },
        },
      }}
      className={classNames({
        HasSubItems: hasSubItems,
      })}
      disabled={disabled}
      disablePadding
      selected={selected}
      secondaryAction={
        hasSubItems &&
        !disabled && (
          <>
            <IconButton
              size="tiny"
              variant="flat"
              onClick={_onClick(NestedItemClickType.EnterSubItem)}
            >
              <NextIcon />
            </IconButton>
          </>
        )
      }
    >
      {tooltip && (
        <StyledTooltip title={tooltip || ''} placement="top-start" enterDelay={1000} leaveDelay={0}>
          {getListItemButton()}
        </StyledTooltip>
      )}
      {!tooltip && getListItemButton()}
    </ListItem>
  );
};
