import { IconButton } from '@mui/material';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';

const ClearIcon = ({ clearValue }: { clearValue: () => void }) => (
  <IconButton
    onClick={clearValue}
    sx={{
      position: 'absolute',
      right: '35px',
      top: '8px',
      cursor: 'pointer',
      padding: '4px',
    }}
  >
    <Close />
  </IconButton>
);

export { ClearIcon };
