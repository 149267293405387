import * as React from "react";
const SvgDynamicPath = (props) => /* @__PURE__ */ React.createElement("svg", { width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#F8BCF2", style: {
  fill: "#F8BCF2",
  fill: "color(display-p3 0.9708 0.7362 0.9474)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M9.52545 32.8684L15.3281 31.3136C15.5166 31.2631 15.5583 30.8074 15.4212 30.2957L14.1799 25.6633C14.0428 25.1516 13.7789 24.7778 13.5904 24.8283L7.78771 26.3831C7.5992 26.4336 7.55752 26.8894 7.69462 27.4011L8.93586 32.0334C9.07297 32.5451 9.33693 32.919 9.52545 32.8684Z", fill: "#FED044", stroke: "black", style: {
  fill: "#FED044",
  fill: "color(display-p3 0.9961 0.8157 0.2667)",
  fillOpacity: 1,
  stroke: "black",
  strokeOpacity: 1
}, strokeWidth: 1.40676, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M9.20505 22.2217L9.61001 19.8114", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M6.7941 20.2528L9.90903 18.034L12.1278 21.1489", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M18.2037 12.8101L20.816 12.4006", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M19.5067 9.8687L22.5971 12.1215L20.3443 15.2119", stroke: "black", style: {
  stroke: "black",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M7.68997 13.7929L13.6432 15.3881C13.8366 15.4399 14.1074 15.0564 14.2481 14.5314L15.5215 9.77885C15.6622 9.2539 15.6194 8.78633 15.426 8.73451L9.4728 7.13935C9.2794 7.08753 9.00858 7.47107 8.86792 7.99603L7.59447 12.7486C7.45381 13.2735 7.49657 13.7411 7.68997 13.7929Z", fill: "#FED044", stroke: "black", style: {
  fill: "#FED044",
  fill: "color(display-p3 0.9961 0.8157 0.2667)",
  fillOpacity: 1,
  stroke: "black",
  strokeOpacity: 1
}, strokeWidth: 1.40676, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M27.1581 15.3824L33.1113 13.7872C33.3047 13.7354 33.3475 13.2678 33.2068 12.7428L31.9334 7.99028C31.7927 7.46533 31.5219 7.08178 31.3285 7.1336L25.3753 8.72876C25.1819 8.78058 25.1391 9.24815 25.2798 9.7731L26.5532 14.5257C26.6939 15.0506 26.9647 15.4342 27.1581 15.3824Z", fill: "#FED044", stroke: "black", style: {
  fill: "#FED044",
  fill: "color(display-p3 0.9961 0.8157 0.2667)",
  fillOpacity: 1,
  stroke: "black",
  strokeOpacity: 1
}, strokeWidth: 1.40676, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgDynamicPath;
