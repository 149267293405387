import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';

type AutomationReducersProps = {
  addAutomation: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automation: AutomationStructureNew;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateAutomation: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  toggleAutomation: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteAutomation: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationIdToDelete: string;
    }>,
  ) => any;
  updateAutomationDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const automationReducers: AutomationReducersProps = {
  addAutomation: (state, action) => {
    const { crmOrgId, automation, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    if (!state.environments[crmOrgId].data.automations) {
      state.environments[crmOrgId].data.automations = [];
    }
    state.environments[crmOrgId].data.automations?.push({
      ...automation,
      status: optimisticStatus,
    });
  },
  updateAutomation: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.automations) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.automations?.findIndex(
      (r) =>
        r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.automations[indexToUpdate] = {
        ...automationToUpdate,
        status: optimisticStatus,
      };
    }
  },
  deleteAutomation: (state, action) => {
    const { crmOrgId, automationIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.automations) {
      return;
    }
    const indexToDelete = state.environments[crmOrgId].data.automations?.findIndex(
      (r) => r.automationId === automationIdToDelete,
    );
    if (indexToDelete !== undefined) {
      state.environments[crmOrgId].data.automations?.splice(indexToDelete, 1);
    }
  },
  toggleAutomation: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.automations) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.automations?.findIndex(
      (r) =>
        r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.automations[indexToUpdate] = {
        ...automationToUpdate,
        status: optimisticStatus,
      };
    }
  },
  updateAutomationDeployStatus: (state, action) => {
    const { crmOrgId, automationId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.automations) {
      return;
    }
    const indexToUpdate = state.environments[crmOrgId].data.automations?.findIndex(
      (r) => r.automationId === automationId && r.versionId === versionId,
    );
    if (indexToUpdate !== undefined) {
      // @ts-ignore
      state.environments[crmOrgId].data.automations[indexToUpdate].status = optimisticStatus;
    }
  },
};

export const selectCrmOrgAutomations = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.automations;
};

export const selectAutomations = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgAutomations(crmOrg.id)(state);
  }
};
