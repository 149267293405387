import { useDispatch, useSelector } from 'react-redux';
import { selectCrmOrgAutomations } from '../../reducers/global/automationReducers';
import { useEffect, useMemo } from 'react';
import {
  setAutomationsPills,
  setAlertsPills,
  setAssignmentPills,
  setDocumentationPills,
  setDedupePills,
  setMatchingPills,
  setScheduledAssignmentPills,
} from './canvasPillsReducer';
import calculateAutomationPills from './calculateAutomationPills';
import { selectCrmOrgAlerts } from '../../reducers/global/alertsReducers';
import { selectCrmOrgAssignments } from '../../reducers/global/assignmentReducers';
import { calculateDocumentationPills } from './calculateDocumentationPills';
import {
  selectCrmOrgDedup,
  selectCrmOrgMatching,
} from '../../reducers/global/dedupMatchingReducers';
import { selectCrmOrgScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import { getDeployedVersions } from '../documentation/selected-object/filters/utils';
import { useDocumentation } from '../documentation/useDocumentation';

export const useCalculatePills = ({
  crmOrgId,
  funnelMap,
  onlyDeployed,
}: {
  crmOrgId: string;
  funnelMap: FunnelMap;
  onlyDeployed?: boolean;
}) => {
  const dispatch = useDispatch();

  const automations = useSelector(selectCrmOrgAutomations(crmOrgId));
  const alerts = useSelector(selectCrmOrgAlerts(crmOrgId));
  const assignments = useSelector(selectCrmOrgAssignments(crmOrgId));
  const scheduledAssignments = useSelector(selectCrmOrgScheduledAssignments(crmOrgId));
  const dedupe = useSelector(selectCrmOrgDedup(crmOrgId));
  const matching = useSelector(selectCrmOrgMatching(crmOrgId));

  const _automations = useMemo(
    () => (onlyDeployed ? getDeployedVersions(automations ?? []) : automations),
    [onlyDeployed, automations],
  );
  const _alerts = useMemo(
    () => (onlyDeployed ? getDeployedVersions(alerts ?? []) : alerts),
    [onlyDeployed, alerts],
  );
  const _assignments = useMemo(
    () => (onlyDeployed ? getDeployedVersions(assignments ?? []) : assignments),
    [onlyDeployed, assignments],
  );
  const _scheduledAssignments = useMemo(
    () => (onlyDeployed ? getDeployedVersions(scheduledAssignments ?? []) : scheduledAssignments),
    [onlyDeployed, scheduledAssignments],
  );
  const _dedupe = useMemo(
    () => (onlyDeployed ? getDeployedVersions(dedupe ?? []) : dedupe),
    [onlyDeployed, dedupe],
  );
  const _matching = useMemo(
    () => (onlyDeployed ? getDeployedVersions(matching ?? []) : matching),
    [onlyDeployed, matching],
  );

  const { parsedRecordTypes, parsedRules } = useDocumentation();

  useEffect(() => {
    _automations && dispatch(setAutomationsPills(calculateAutomationPills(_automations)));
  }, [_automations, dispatch]);

  useEffect(() => {
    _alerts && dispatch(setAlertsPills(calculateAutomationPills(_alerts)));
  }, [_alerts, dispatch]);

  useEffect(() => {
    _assignments && dispatch(setAssignmentPills(calculateAutomationPills(_assignments)));
  }, [_assignments, dispatch]);

  useEffect(() => {
    _scheduledAssignments &&
      dispatch(setScheduledAssignmentPills(calculateAutomationPills(_scheduledAssignments)));
  }, [_scheduledAssignments, dispatch]);

  useEffect(() => {
    _dedupe && dispatch(setDedupePills(calculateAutomationPills(_dedupe)));
  }, [_dedupe, dispatch]);

  useEffect(() => {
    _matching && dispatch(setMatchingPills(calculateAutomationPills(_matching)));
  }, [_matching, dispatch]);

  useEffect(() => {
    if (funnelMap?.funnelsData && parsedRecordTypes && parsedRules) {
      dispatch(
        setDocumentationPills(
          calculateDocumentationPills({
            funnelsData: funnelMap.funnelsData,
            recordTypesData: funnelMap.recordTypesData || {},
            recordTypes: funnelMap.recordTypes || {},
            parsedRecordTypes,
            parsedRules,
          }),
        ),
      );
    }
  }, [
    dispatch,
    funnelMap?.funnelsData,
    funnelMap?.recordTypesData,
    funnelMap?.recordTypes,
    parsedRecordTypes,
    parsedRules,
    crmOrgId,
  ]);
};
