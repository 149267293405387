import { Box, Typography } from '@mui/material';
import Switch from '@sweep-io/sweep-design/dist/components/Switch';
import { useState } from 'react';
import { uniqueId } from '../../../lib/uniqueId';
import { NotifyUsersWrapper, NotifyWays } from '../../common/NotifyUsersWrapper';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';

interface MatchingNotificationProps {
  crmOrgId?: string;
  readonly?: boolean;
  action: EmailAutomationAction | SlackAutomationAction | undefined;
  onChange: (automationAction: SlackAutomationAction | EmailAutomationAction | null) => any;
  displayError?: boolean;
  objectName: SFDCObjectType;
}
export const MatchingNotification = ({
  crmOrgId,
  readonly,
  action,
  onChange,
  displayError = false,
  objectName,
}: MatchingNotificationProps) => {
  crmOrgId = crmOrgId ?? '';
  let notifyType = null;
  if (action) {
    action.actionType === 'SEND_EMAIL'
      ? (notifyType = NotifyWays.EMAIL)
      : (notifyType = NotifyWays.SLACK);
  }
  const [notificationType, setNotificationType] = useState<NotifyWays | null>(notifyType);

  return (
    <>
      <Box sx={{ marginTop: '32px', position: 'relative' }}>
        <Box display="flex" gap="12px">
          <Typography variant="h4">Send Notification</Typography>
          <Switch
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.checked) {
                setNotificationType(NotifyWays.EMAIL);
                const newObj: EmailAutomationAction = {
                  _id: uniqueId(),
                  actionType: 'SEND_EMAIL',
                  actionParams: {},
                };
                onChange(newObj);
              } else {
                setNotificationType(null);
                onChange(null);
              }
            }}
            checked={notificationType !== null}
            disabled={readonly}
          />
        </Box>

        {notificationType && (
          <NotifyUsersWrapper
            notificationType={notificationType}
            setNotificationType={setNotificationType}
            crmOrgId={crmOrgId}
            readonly={readonly}
            displayError={displayError}
            onChange={onChange}
            action={action}
            objectName={objectName}
          />
        )}
      </Box>
    </>
  );
};
