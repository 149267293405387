/* 
  This function is used to calculate the extreme positions of the funnels, record types and hubspot funnels
  in the funnel map. This is used to calculate the width and height of the canvas.
*/

export const funnelMapExtremePositions = (
  funnelMap: Pick<
    FunnelMap,
    | 'funnels'
    | 'funnelsData'
    | 'recordTypes'
    | 'recordTypesData'
    | 'hubspotFunnels'
    | 'hubspotFunnelsData'
  >,
): { minColumn: number; minRow: number; maxRow: number } => {
  let minColumn = 0;
  let minRow = 0;
  let maxRow = 0;

  const {
    funnels: funnelPositions,
    funnelsData,
    recordTypes: recordTypePositions,
    recordTypesData,
    hubspotFunnels: hubspotFunnelPositions,
    hubspotFunnelsData,
  } = funnelMap;

  Object.entries(funnelPositions).forEach(([funnelId, { position }]) => {
    const { column: funnelColumn, row: funnelRow } = position;
    const funnel = funnelsData[funnelId];
    if (funnel) {
      funnel.funnelDetails.stages.forEach((stage) => {
        const stageColumn = stage._stageColumnIndex ?? 0;
        const stageRow = stage._branchIndex;
        const stageAbsoluteColumn = funnelColumn + stageColumn;
        const stageAbsoluteRow = funnelRow + stageRow;

        if (stageAbsoluteColumn < minColumn) minColumn = stageAbsoluteColumn;
        if (stageAbsoluteRow < minRow) minRow = stageAbsoluteRow;
        if (stageAbsoluteRow > maxRow) maxRow = stageAbsoluteRow;
      });
    }
  });

  Object.entries(recordTypePositions).forEach(([recordTypeId, { position }]) => {
    const { column: recordTypeColumn, row: recordTypeRow } = position;
    const recordType = recordTypesData[recordTypeId];
    if (recordType) {
      if (recordTypeColumn < minColumn) minColumn = recordTypeColumn;
      if (recordTypeRow < minRow) minRow = recordTypeRow;
      if (recordTypeRow > maxRow) maxRow = recordTypeRow;
    }
  });
  Object.entries(hubspotFunnelPositions).forEach(([hubspotFunnelId, { position }]) => {
    const { column, row } = position;
    const hubspotFunnel = hubspotFunnelsData[hubspotFunnelId];
    if (hubspotFunnel) {
      if (column < minColumn) minColumn = column;
      if (row < minRow) minRow = row;
      if (row > maxRow) maxRow = row;
    }
  });

  return {
    minColumn,
    minRow: minRow,
    maxRow,
  };
};
