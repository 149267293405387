export enum ChatCompletionRequestMessageRole {
  SYSTEM = 'system',
  USER = 'user',
  ASSISTANT = 'assistant',
}
export type SalesforceConfigurationType = string;

export type ChatGptPrompt = string;

export interface ConfigurationDescription {
  data: string;
  error: string;
}

export interface ChatGptPromptMessage {
  role: ChatCompletionRequestMessageRole;
  content: ChatGptPrompt;
}

export enum AiChatContextType {
  CONFIGURATION = 'configuration',
  FIELD = 'field',
}

export interface AiChatConfigurationContext {
  type: AiChatContextType.CONFIGURATION;
  filename: string;
}

export interface AiChatFieldContext {
  type: AiChatContextType.FIELD;
  fieldName: string;
  objectName: string;
}

export type AiChatContextProperties = AiChatConfigurationContext | AiChatFieldContext;
