import { IconButton, Tooltip } from '@sweep-io/sweep-design';
import { useTurnOffCommentsModeFirst } from '../../left-menu/useTurnOffCommentsModeFirst';
import { Settings as SettingsIcon } from '@sweep-io/sweep-design/dist/icons';
import { useCallback } from 'react';

import { useSelector } from 'react-redux';
import { FunnelFlowSettingsDialog } from './FunnelFlowSettingsDialog';
import { useFunnelSettingsDialog } from './useFunnelSettingsDialog';
import { selectIsFunnelSettingsDialogOpened } from './funnelSettingsReducer';

export const SettingsButton = ({
  funnelMap,
  crmOrgId,
}: {
  funnelMap: FunnelMap;
  crmOrgId: string;
}) => {
  const turnOffCommentsModeFirst = useTurnOffCommentsModeFirst();
  const { openFunnelSettingsDialog } = useFunnelSettingsDialog();
  const isFunnelSettingsDialogOpen = useSelector(selectIsFunnelSettingsDialogOpened); //needs to be on higher level to trigger yup validation only on opening

  const getOnSettingsClickPayload = useCallback(() => {
    if (funnelMap) {
      const { funnelsData } = funnelMap;

      return {
        id: funnelMap.id,
        name: funnelMap.name,
        funnels: Object.values(funnelsData),
      };
    }
  }, [funnelMap]);

  const onSettingsClick = useCallback(() => {
    const payload = getOnSettingsClickPayload();
    if (payload) {
      openFunnelSettingsDialog(payload);
    }
  }, [getOnSettingsClickPayload, openFunnelSettingsDialog]);

  return (
    <>
      <Tooltip title="Settings">
        <span>
          <IconButton variant="flat" onClick={() => turnOffCommentsModeFirst(onSettingsClick)}>
            <SettingsIcon variant="large" />
          </IconButton>
        </span>
      </Tooltip>
      {isFunnelSettingsDialogOpen && (
        <FunnelFlowSettingsDialog title="Settings" crmOrgId={crmOrgId} />
      )}
    </>
  );
};
