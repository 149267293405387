import { Button } from '@sweep-io/sweep-design';
import { APP_EXCHANGE_URL } from '../url-consts';

const AppExchangeButton = ({
  buttonSize,
  disabled,
}: {
  buttonSize?: 'small';
  disabled?: boolean;
}) => {
  const goToAppExchange = () => {
    window.open(APP_EXCHANGE_URL, '_blank');
  };
  return (
    <Button
      startIconName="ArrowUpRight"
      onClick={goToAppExchange}
      size={buttonSize ?? 'large'}
      disabled={disabled}
    >
      Continue to AppExchange
    </Button>
  );
};

export default AppExchangeButton;
