import { Divider, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { RecordTypeVisibilityToggle } from '../../pages/configuration-canvas/public/record-types-visibility/RecordTypeVisibilityToggle';

export interface ElementWrapperProps {
  onWrapperClick: (event: any, tab?: DocumentationTabTypes) => void;
  leftContent: ReactNode;
  rightContent?: ReactNode;
  isActive?: boolean;
  isHighlighted?: boolean;
  sx?: SxProps;
  elementId?: string;
  withVisibilityToggle?: boolean;
}

export const ListElementWrapper = ({
  elementId,
  leftContent,
  rightContent,
  onWrapperClick,
  isActive,
  isHighlighted,
  sx = {},
  withVisibilityToggle,
}: ElementWrapperProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          background: isActive || isHighlighted ? colors.grey[100] : 'transparent',
          '&:hover': {
            background: colors.grey[100],
          },
          paddingLeft: 2,
          paddingRight: 2,
          ...sx,
        }}
        onClick={onWrapperClick}
        data-testid="wrapper-item"
      >
        {leftContent}

        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {rightContent}
          {withVisibilityToggle && elementId && (
            <RecordTypeVisibilityToggle recordTypeId={elementId} />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          paddingLeft: 2,
          paddingRight: 2,
          ...sx,
          pt: 0,
          pb: 0,
          minHeight: '1px',
          height: '1px',
        }}
      >
        <Divider sx={{ borderColor: colors.grey[200] }} />
      </Box>
    </>
  );
};
