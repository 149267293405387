import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { useState } from 'react';
import RequiredDot from '../common/RequiredDot';
import SweepSelect from '../common/SweepSelect';
import { Typography, colors } from '@sweep-io/sweep-design';
import { Warning } from '@sweep-io/sweep-design/dist/icons';
import { useRunOnce } from '../common/useRunOnce';
import { telemetry } from '../../telemetry';
import extractErrorMsg from '../helpers/extractErrorMsg';
import { useStageMetadataFacade } from '../../apis/facades/useStageMetadataFacade';

interface ConvertLeadConvertedStatusProps {
  readonly?: boolean;
  onChange: (stage: string) => any;
  value: string;
  crmOrgId: string;
}

export const ConvertLeadConvertedStatus = ({
  readonly,
  onChange,
  value,
  crmOrgId,
}: ConvertLeadConvertedStatusProps) => {
  const [convertedStages, setConvertedStages] = useState<string[]>();
  const { get_allStagesStageMetadata } = useStageMetadataFacade();

  useRunOnce(async () => {
    try {
      const fields = await get_allStagesStageMetadata(['Lead.Status'], crmOrgId);
      const convertedStatusFields = fields?.filter((el: StageMetadata) => el.converted === true);
      setConvertedStages(convertedStatusFields?.map((el: StageMetadata) => el.stageName));
    } catch (e) {
      telemetry.captureError(extractErrorMsg(e));
    }
  });

  return (
    <>
      {convertedStages && (
        <>
          <Box sx={{ mr: 1 }}>
            <Typography variant="body">
              Converted Status: <RequiredDot sx={{ marginBottom: '2px' }} />
            </Typography>
          </Box>
          {convertedStages.length > 0 && (
            <SweepSelect
              disabled={readonly}
              SelectProps={{
                placeholder: 'Select Status',
                value: value,
                onChange: (event: SelectChangeEvent<unknown>) => {
                  const val = event.target.value as string;
                  onChange(val);
                },
              }}
            >
              {convertedStages.map((stage) => (
                <MenuItem key={stage} value={stage}>
                  {stage}
                </MenuItem>
              ))}
            </SweepSelect>
          )}
          {convertedStages.length === 0 && (
            <Box display="flex" gap={1} alignItems="center">
              <Warning color={colors.blush[500]} />
              <Typography variant="caption-bold" color={colors.blush[500]}>
                Please make sure you have Converted status
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};
