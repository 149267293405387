import { useSelector } from 'react-redux';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { useDeployButtonContext } from './DeployButtonContext';
import { AutomationCanvasFilter } from '../../pages/canvas-pages/canvasAutomationsTypes';
import {
  CanvasFilterTypes,
  ToggleCanvasFilter,
} from '../../pages/configuration-canvas-filters/filterTypes';
import { useMemo } from 'react';

export const useShouldRequestToDeploy = () => {
  const { notifyAdmin, deploy } = useDeployButtonContext().permissions;
  const userInfo = useSelector(selectUserInfoData);
  const permissions = userInfo?.permissions || [];
  return permissions.includes(notifyAdmin) && !permissions.includes(deploy);
};

export const useShouldRequestToDeployFilter = (
  automations: AutomationStructureNew[],
  position: number,
) => {
  const hasRequestToDeployAutomations = automations.find((automation) => automation.notifyAdmin);

  const _filter = useMemo(() => {
    if (!hasRequestToDeployAutomations) {
      return;
    }

    const filter: ToggleCanvasFilter = {
      type: CanvasFilterTypes.TOGGLE_FILTER,
      position,
      label: 'Show only deployment requests',
      checked: false,
    };
    return {
      [AutomationCanvasFilter.DEPLOYMENT_REQUEST]: filter,
    };
  }, [hasRequestToDeployAutomations, position]);

  return _filter;
};
