import { ConfigurationType } from '../types';
import { possibleTypes } from './configurationChatConsts';
import { AiChatInput } from '../../../common/ai-chat/AiChatInput';

const symbol = '$item';
const prompts: { [key: string]: string } = {
  ai1: 'Break down in steps how this $item works',
  ai2: 'List all fields that are updated via this $item',
};

const excludeAI2FromTypes = [ConfigurationType.validationRules];

interface ConfigurationChatInputWrapperProps {
  onConfirm: (message: string) => void;
  isLoading: boolean;
  configurationKey: ConfigurationType;
  itemType?: string;
  disableInput: boolean;
  messagesCount: number;
}

export const ConfigurationChatInputWrapper = ({
  configurationKey,
  onConfirm,
  isLoading,
  itemType,
  disableInput,
  messagesCount,
}: ConfigurationChatInputWrapperProps) => {
  const isField =
    configurationKey === ConfigurationType.fields || configurationKey === ConfigurationType.rollups;
  const isCpq = configurationKey === ConfigurationType.cpqData;

  if (!possibleTypes.includes(configurationKey)) {
    return <></>;
  }

  const predefinedPrompts = Object.keys(prompts)
    .filter(
      (key) => key !== 'ai2' || (key === 'ai2' && !excludeAI2FromTypes.includes(configurationKey)),
    )
    .reduce(
      (obj, key) => {
        obj[key] = prompts[key].replace(symbol, itemType?.toLowerCase() ?? 'element');
        return obj;
      },
      {} as { [promptKey: string]: string },
    );

  return (
    <AiChatInput
      textFieldVariant="primary"
      predefinedPrompts={messagesCount === 0 && !isField && !isCpq ? predefinedPrompts : undefined}
      onConfirm={onConfirm}
      isLoading={isLoading}
      isCollapsiblePrompts={true}
      disabled={disableInput}
    />
  );
};
