import { useCallback, useContext } from 'react';
import { ConfirmContext, ConfirmActionData } from './ConfirmContext';
import { HIDE_CONFIRM, SHOW_CONFIRM } from './ConfirmContext';
import { telemetry } from '../../../../telemetry';

let resolveCallback: (val: boolean) => any;

const useConfirm = () => {
  const { dispatch } = useContext(ConfirmContext);

  const closeConfirm = useCallback(() => {
    if (!dispatch) {
      telemetry.captureError(new Error('Dispatch not provided'));
      return;
    }

    dispatch({
      type: HIDE_CONFIRM,
    });
  }, [dispatch]);

  const onConfirm = useCallback(() => {
    closeConfirm();
    resolveCallback(true);
  }, [closeConfirm]);

  const onCancel = useCallback(() => {
    closeConfirm();
    resolveCallback(false);
  }, [closeConfirm]);

  const openConfirm = useCallback(
    (confirmDialogJsx?: ConfirmActionData) => {
      if (!dispatch) {
        telemetry.captureError(new Error('Dispatch not provided'));
        return;
      }
      dispatch({
        type: SHOW_CONFIRM,
        payload: confirmDialogJsx,
      });
      return new Promise((res: (val: boolean) => any) => {
        resolveCallback = res;
      });
    },
    [dispatch],
  );

  return { openConfirm, onConfirm, onCancel };
};

export default useConfirm;
