import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { memo } from 'react';
import { NodeProps } from 'reactflow';
import { EditableLabel } from '../../common/EditableLabel';
import { getObjectTypeColor } from '../helpers/getObjectTypeColor';
import { RFNodeData } from '../canvasTypes';
import { BaseGhostNode } from './BaseGhostNode';

const BasedNewNode = ({
  id,
  data: {
    onEditNameConfirm,
    onEditNameCancel,
    objectType,
    editNameValidateClbk,
    isInGroupMouseMoveMode,
    parentId,
  },
}: NodeProps<RFNodeData> & {
  isNurturingBucket?: boolean;
}) => {
  const { step: backgroundColor, connection: handleColor } = getObjectTypeColor(
    objectType as ObjectTypeValues,
  );

  return (
    <BaseGhostNode
      handleColor={handleColor}
      backgroundColor={backgroundColor}
      sx={{
        justifyContent: 'space-between',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box>
        {!isInGroupMouseMoveMode && (
          <EditableLabel
            defaultIsEditing
            value={''}
            onValueConfirm={(value) => {
              if (!value.length) {
                onEditNameCancel && onEditNameCancel(id, parentId);
              } else {
                onEditNameConfirm && onEditNameConfirm(id, value);
              }
            }}
            maxCharLength={40}
            height={30}
            padding={'8px'}
            placeholder={'New Value'}
            inputSx={{
              fontSize: '16px',
              fontWeight: 600,
              color: colors.black,
              maxWidth: '220px',

              borderRadius: '4px',

              minWidth: '100px',

              '&.editing': {
                borderColor: colors.grey[500],
              },

              '&.editing.empty::before': {
                content: '"•"',
                position: 'absolute',
                fontWeight: 600,
                fontSize: '14px',
                left: '85px',
                display: 'inline-block',
                color: colors.blush[500],
              },
            }}
            errorSx={{
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '15px',
              marginTop: '20px',
            }}
            validateClbk={editNameValidateClbk(parentId)}
            onCancel={(isConfirmed) =>
              onEditNameCancel && onEditNameCancel(id, parentId, isConfirmed)
            }
          />
        )}
      </Box>
    </BaseGhostNode>
  );
};

const NewRegularNode = (props: NodeProps<RFNodeData>) => <BasedNewNode {...props} />;

const NewNurturingBucketNode = (props: NodeProps<RFNodeData>) => (
  <BasedNewNode {...props} isNurturingBucket />
);

const _memoNewRegularNode = memo(NewRegularNode);
const _memoNewNurturingBucketNode = memo(NewNurturingBucketNode);

export {
  _memoNewRegularNode as NewRegularNode,
  _memoNewNurturingBucketNode as NewNurturingBucketNode,
};
