import './App.css';
import { Outlet } from 'react-router';
import { useAuthenticateUser } from '../hooks/useAuthenticateUser';
import LoadingSweep from './common/LoadingSweep';
import { GeneralError } from './pages/GeneralError';
import VerificationNeeded from './pages/VerificationNeeded';
import { Notifications } from './notifications/Notifications';
import ErrorBoundary from '../ErrorBoundary';
import { useSelectDefaultCreationEnvHeuristic } from './pages/environments/useSelectDefaultCreationEnvHeuristic';
import { useAuthenticatedAppHubspotLoad } from './pages/canvas-pages/canvas-hubspot-page/useAuthenticatedAppHubspotLoad';
import useSetupMonitoring from '../hooks/useSetupMonitoring';

const AuthenticatedApp = () => {
  const { crmOrgsAreReady } = useSelectDefaultCreationEnvHeuristic();
  const { hubspotInitialized } = useAuthenticatedAppHubspotLoad();
  useSetupMonitoring();

  if (!crmOrgsAreReady || !hubspotInitialized) {
    return <LoadingSweep />;
  }
  return <Outlet />;
};

export const App = () => {
  const { userInfo, error, notVerifiedAuthUserDetails } = useAuthenticateUser();
  const { isVerificationNeeded, authUserId, authUserEmail } = notVerifiedAuthUserDetails;
  const renderError = !!error;

  return (
    <ErrorBoundary>
      <div data-testid="sweep-app" style={{ height: '100%' }}>
        <Notifications />
        {userInfo ? (
          <AuthenticatedApp />
        ) : isVerificationNeeded ? (
          <VerificationNeeded authUserId={authUserId} authUserEmail={authUserEmail} />
        ) : renderError ? (
          <GeneralError />
        ) : (
          <LoadingSweep />
        )}
      </div>
    </ErrorBoundary>
  );
};
