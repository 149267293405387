import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCrmOrgObjectTypes,
  selectIsLoadingObjectTypes,
  setIsLoadingObjectTypes,
  setObjectTypes,
} from '../components/pages/environments/environmentsReducer';
import {
  CrmOrgObjectTypesResponse,
  useCrmOrgsApiFacade,
} from '../apis/facades/useCrmOrgsApiFacade';
import { telemetry } from '../telemetry';

const useObjectTypes = ({ crmOrgId }: { crmOrgId: string }) => {
  const dispatch = useDispatch();
  const objectTypes = useSelector(selectCrmOrgObjectTypes(crmOrgId));

  const isLoadingCrmOrgObjectTypes = useSelector(selectIsLoadingObjectTypes(crmOrgId));
  const { get_crmOrgsObjectTypes } = useCrmOrgsApiFacade();

  const handleIsLoadingObjects = useCallback(
    (crmOrgId: string) => {
      dispatch(setIsLoadingObjectTypes({ crmOrgId }));
    },
    [dispatch],
  );

  const handleSetObjects = useCallback(
    (objectTypes: ObjectTypeName[], crmOrgId: string) => {
      dispatch(
        setObjectTypes({
          objectTypes,
          crmOrgId,
        }),
      );
    },
    [dispatch],
  );

  const fetchCrmOrgObjectTypes = useCallback(
    async ({
      crmOrgId: _crmOrgId,
    }: {
      crmOrgId?: string;
    }): Promise<CrmOrgObjectTypesResponse & { error?: boolean }> => {
      //isLoadingCrmOrgObjectTypes is relevant only in case crmOrgId exist
      //in case of using "_crmOrgId", we check is being done on a parent function
      if (isLoadingCrmOrgObjectTypes && crmOrgId) {
        return {
          objectTypes: [],
        };
      }
      const usedCrmOrgId = _crmOrgId ?? crmOrgId;

      handleIsLoadingObjects(usedCrmOrgId);

      try {
        const { objectTypes } = await get_crmOrgsObjectTypes({
          orgId: usedCrmOrgId,
        });
        const objectTypesSorted = objectTypes
          ?.map((obj) => ({ ...obj, label: obj.label.toString() }))
          .sort(sortByLabel);

        handleSetObjects(objectTypesSorted, usedCrmOrgId);

        return {
          objectTypes: objectTypesSorted,
        };
      } catch (e) {
        telemetry.captureError(e);
        handleSetObjects([], usedCrmOrgId); //so it will not cause infinite loop
        return {
          objectTypes: [],
          error: true,
        };
      }
    },
    [
      get_crmOrgsObjectTypes,
      crmOrgId,
      handleSetObjects,
      handleIsLoadingObjects,
      isLoadingCrmOrgObjectTypes,
    ],
  );

  return {
    objectTypes,
    fetchCrmOrgObjectTypes,
    isLoadingCrmOrgObjectTypes,
  };
};

const getLabel = (obj?: ObjectTypeName) => obj?.label || obj?.objectType || '';

const sortByLabel = (objA: ObjectTypeName, objB: ObjectTypeName) =>
  getLabel(objA).localeCompare(getLabel(objB));

export default useObjectTypes;
