import { useContext, useEffect, useState } from 'react';
import {
  CanvasMode,
  DEFAULT_CANVAS_MIN_ZOOM,
  DEFAULT_CANVAS_ZOOM,
  SweepCanvasGroup,
} from './canvasTypes';
import { FitViewOptions } from 'reactflow';
import { useSweepCanvasFitView } from './useSweepCanvasFitView';
import { SweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';

const fitViewOptions: { [K in CanvasMode]: FitViewOptions } = {
  [CanvasMode.DEFAULT]: {
    padding: 0.3,
    minZoom: DEFAULT_CANVAS_MIN_ZOOM,
    maxZoom: DEFAULT_CANVAS_ZOOM,
  },
  [CanvasMode.PREVIEW1]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.3,
  },
  [CanvasMode.PREVIEW2]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
  [CanvasMode.PREVIEW3]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
  [CanvasMode.FIT_NODES_PREVIEW]: {
    padding: 0.15,
    minZoom: 0.01,
    maxZoom: 0.9,
  },
};

interface ManageFirstFunnelDropProps {
  sweepGroups: SweepCanvasGroup[];
}

export const useManageFirstGroupDrop = ({ sweepGroups }: ManageFirstFunnelDropProps) => {
  const { autoFitViewOnFirstNodes, canvasMode = CanvasMode.DEFAULT } =
    useContext(SweepCanvasPropsCtx);
  const selectedFitViewOptions = fitViewOptions[canvasMode];
  const { fitView } = useSweepCanvasFitView();
  const [hideFirstFunnel, setHideFirstFunnel] = useState(
    autoFitViewOnFirstNodes && sweepGroups.length === 0,
  );

  //Center canvas after placing first funnel
  useEffect(() => {
    if (autoFitViewOnFirstNodes) {
      if (sweepGroups.length > 0) {
        if (hideFirstFunnel) {
          setTimeout(() => {
            fitView({
              ...selectedFitViewOptions,
              duration: 0,
            });
            setHideFirstFunnel(false);
          }, 100);
        }
      } else {
        setHideFirstFunnel(true);
      }
    }
  }, [
    fitView,
    hideFirstFunnel,
    selectedFitViewOptions,
    sweepGroups.length,
    autoFitViewOnFirstNodes,
  ]);

  return {
    hideFirstFunnel,
    selectedFitViewOptions,
  };
};
