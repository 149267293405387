export enum DateBy {
  From = 'From',
  To = 'To',
}

export interface CustomDatePicker {
  [DateBy.From]?: string;
  [DateBy.To]?: string;
  key: DateKeys;
}

export enum DateKeys {
  Today = 'TODAY',
  Yesterday = 'YESTERDAY',
  Last7Days = '7_DAYS',
  Last28Days = '28_DAYS',
  Last90Days = '90_DAYS',
  Last12Months = '12_MONTHS',
  Custom = 'CUSTOM',
}
