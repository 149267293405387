import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';

const fields: (keyof FunnelMap)[] = ['id', 'name', 'funnels', 'recordTypes', 'hubspotFunnels'];

export const areFunnelMapsEquivalent = (funnelMapA?: FunnelMap, funnelMapB?: FunnelMap) => {
  if (!funnelMapA || !funnelMapB) return false;

  const strippedFunnelMapA = pick(funnelMapA, fields);
  const strippedFunnelMapB = pick(funnelMapB, fields);

  return isEqual(strippedFunnelMapA, strippedFunnelMapB);
};
