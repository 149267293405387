import { useDispatch, useSelector } from 'react-redux';
import {
  selectEditingThirdPartyFunnelId,
  selectEditThirdPartyStep,
  selectThirdPartyFunnelById,
  setEditingThirdPartyFunnelId,
} from './thirdPartyFunnelsReducer';
import { AddOrImportThirdPartyFunnel } from './AddOrImportThirdPartyFunnel';
import { EditThirdPartyFunnelDialog } from './EditThirdPartyFunnelDialog';
import { EditThirdPartyStepDialog } from './EditThirdPartyStepDialog';

export const UnitedCanvasThirdPartyFunnelComponents = ({ funnelMap }: { funnelMap: FunnelMap }) => {
  const dispatch = useDispatch();
  const editingThirdPartyFunnelId = useSelector(selectEditingThirdPartyFunnelId);
  const thirdPartyFunnel = useSelector(selectThirdPartyFunnelById(editingThirdPartyFunnelId));
  const editingStep = useSelector(selectEditThirdPartyStep);

  const renderImportFunnel = () => {
    return (
      <AddOrImportThirdPartyFunnel
        addedFunnelIds={Object.keys(funnelMap.thirdPartyFunnels ?? {})}
      />
    );
  };

  const renderEditThirdPartyFunnel = () => {
    if (!thirdPartyFunnel) {
      return null;
    }
    return (
      <EditThirdPartyFunnelDialog
        thirdPartyFunnel={thirdPartyFunnel}
        isOpen
        onClose={() => dispatch(setEditingThirdPartyFunnelId(undefined))}
      />
    );
  };
  const renderEditStepDialog = () => {
    if (!editingStep) {
      return null;
    }
    return <EditThirdPartyStepDialog {...editingStep} />;
  };

  return (
    <>
      {renderImportFunnel()}
      {renderEditThirdPartyFunnel()}
      {renderEditStepDialog()}
    </>
  );
};
