import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';

interface MoveToNextStageProps {
  disabled?: boolean;
  checked?: boolean;
  onChange: (checked: boolean) => void;
}

export const MoveToNextStage = ({ onChange, checked, disabled }: MoveToNextStageProps) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            disabled={disabled}
            checked={checked}
            onChange={(e) => {
              onChange(e.target.checked);
            }}
          />
        }
        sx={{
          '& .MuiFormControlLabel-label': {
            //temporary, will be handled with new checkbox component
            fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontWeight,
            fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.fontSize,
            lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___.body?.lineHeight,
          },
        }}
        label={`Move automatically when conditions are met`}
      />
    </FormGroup>
  );
};
