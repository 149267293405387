import { useCallback } from 'react';
import { useSweepApi } from '../sweep/useSweepApi';

export const useThirdPartyFunnelFacade = () => {
  const sweepApi = useSweepApi();
  return {
    get_third_party_funnels: useCallback(async () => {
      const response = await sweepApi.get<ThirdPartyFunnelData[]>('/third-party-funnels');
      return response.data;
    }, [sweepApi]),

    post_third_party_funnel: useCallback(
      async (thirdPartyFunnel: Omit<ThirdPartyFunnelData, 'id'>) => {
        const response = await sweepApi.post<ThirdPartyFunnelData>('/third-party-funnels', {
          ...thirdPartyFunnel,
        });
        return response.data;
      },
      [sweepApi],
    ),
    delete_third_party_funnel: useCallback(
      async (funnelId: string) => {
        const response = await sweepApi.delete<{
          success: boolean;
          containingFunnelMaps?: { id: string; name: string }[];
        }>(`/third-party-funnels/${funnelId}`);
        return response.data;
      },
      [sweepApi],
    ),
    put_third_party_funnel: useCallback(
      async (funnelId: string, thirdPartyFunnel: ThirdPartyFunnelData) => {
        const response = await sweepApi.put<ThirdPartyFunnelData>(
          `/third-party-funnels/${funnelId}`,
          {
            ...thirdPartyFunnel,
          },
        );
        return response.data;
      },
      [sweepApi],
    ),
  };
};
