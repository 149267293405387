import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';

export interface ThirdPartyFunnelsReducerState {
  thirdPartyFunnels: ThirdPartyFunnelData[];
  loaded: boolean;
  loading: boolean;
  addOrImportDialogOpen: 'add' | 'import' | false;
  editingThirdPartyFunnelId?: string;
  editThirdPartyStep?: {
    funnelId: string;
    stepId: string;
  };
}

const initialState: ThirdPartyFunnelsReducerState = {
  thirdPartyFunnels: [],
  loaded: false,
  loading: false,
  addOrImportDialogOpen: false,
};

export const thirdPartyFunnelsSlice = createSlice({
  name: 'thirdPartyFunnels',
  initialState,
  reducers: {
    loadThirdPartyFunnels: (state, action: PayloadAction<ThirdPartyFunnelData[]>) => {
      state.thirdPartyFunnels = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    setThirdPartyFunnelsLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addThirdPartyFunnel: (state, action: PayloadAction<ThirdPartyFunnelData>) => {
      state.thirdPartyFunnels.push(action.payload);
    },
    editThirdPartyFunnel: (state, action: PayloadAction<ThirdPartyFunnelData>) => {
      const index = state.thirdPartyFunnels.findIndex((funnel) => funnel.id === action.payload.id);
      if (index !== -1) {
        state.thirdPartyFunnels[index] = action.payload;
      }
    },
    setAddOrImportDialogOpen: (state, action: PayloadAction<'add' | 'import' | false>) => {
      state.addOrImportDialogOpen = action.payload;
    },
    removeThirdPartyFunnel: (state, action: PayloadAction<string>) => {
      state.thirdPartyFunnels = state.thirdPartyFunnels.filter(
        (funnel) => funnel.id !== action.payload,
      );
    },
    setEditingThirdPartyFunnelId: (state, action: PayloadAction<string | undefined>) => {
      state.editingThirdPartyFunnelId = action.payload;
    },
    setEditThirdPartyStep: (
      state,
      action: PayloadAction<
        | {
            funnelId: string;
            stepId: string;
          }
        | undefined
      >,
    ) => {
      state.editThirdPartyStep = action.payload;
    },
  },
});

const { name: reducerName } = thirdPartyFunnelsSlice;

export const {
  addThirdPartyFunnel,
  editThirdPartyFunnel,
  loadThirdPartyFunnels,
  setAddOrImportDialogOpen,
  removeThirdPartyFunnel,
  setThirdPartyFunnelsLoading,
  setEditingThirdPartyFunnelId,
  setEditThirdPartyStep,
} = thirdPartyFunnelsSlice.actions;

export const selectThirdPartyFunnels = (state: RootState) => state[reducerName].thirdPartyFunnels;
export const selectThirdPartyFunnelsLoaded = (state: RootState) => state[reducerName].loaded;
export const selectAddOrImportDialogOpen = (state: RootState) =>
  state[reducerName].addOrImportDialogOpen;
export const selectThirdPartyFunnelById = (funnelId?: string) => (state: RootState) =>
  funnelId
    ? state[reducerName].thirdPartyFunnels.find((funnel) => funnel.id === funnelId)
    : undefined;
export const selectThirdPartyFunnelsLoading = (state: RootState) => state[reducerName].loading;

export const selectEditingThirdPartyFunnelId = (state: RootState) =>
  state[reducerName].editingThirdPartyFunnelId;

export const selectEditThirdPartyStep = (state: RootState) => state[reducerName].editThirdPartyStep;
