import { Box, Stack } from '@mui/material';
import { Button, IconButton } from '@sweep-io/sweep-design';
import { ButtonSize } from '@sweep-io/sweep-design/dist/components/Button/types';
import { Close as CloseIcon } from '@sweep-io/sweep-design/dist/icons';

interface CreateEditDialogButtonsProps {
  confirmButtonContent: React.ReactNode;
  confirmButtonStartIcon?: string;
  onConfirm: (any: any) => any;
  confirmButtonDisabled?: boolean;
  showCancelButton?: boolean;
  onCancel?: () => any;
  isButtonLoading?: boolean;
  confirmButtonSize?: ButtonSize;
}

export const CreateEditDialogButtons = ({
  confirmButtonContent,
  confirmButtonStartIcon,
  onConfirm,
  confirmButtonDisabled,
  confirmButtonSize,
  showCancelButton,
  onCancel: onCancelClick,
  isButtonLoading,
}: CreateEditDialogButtonsProps) => {
  return (
    <Box display="flex" gap={1}>
      <Stack alignItems="center">
        <Button
          onClick={onConfirm}
          disabled={confirmButtonDisabled}
          startIconName={confirmButtonStartIcon}
          loading={!!isButtonLoading}
          size={confirmButtonSize}
        >
          {confirmButtonContent}
        </Button>
      </Stack>
      {showCancelButton && (
        <IconButton
          variant="flat"
          size={confirmButtonSize}
          onClick={onCancelClick}
          data-dd-action-name="close"
        >
          <CloseIcon />
        </IconButton>
      )}
    </Box>
  );
};
