import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { HeaderWithIconOrNumber } from './atoms/HeaderWithIconOrNumber';

interface WithHeaderProps {
  children?: ReactNode;
  titleIcon?: ReactNode;
  title: string;
  noPaddingBottom?: boolean;
  withPaddingLeft?: boolean;
}

export const MainSectionWithHeaderWrapper = ({
  children,
  titleIcon,
  title,
  noPaddingBottom,
  withPaddingLeft = false,
}: WithHeaderProps) => (
  <Box pb={noPaddingBottom ? 0 : 2}>
    <Box sx={{ pb: noPaddingBottom ? 0 : 0.5 }}>
      <HeaderWithIconOrNumber headerText={title} HeaderIcon={titleIcon} />
    </Box>
    <Box pl={withPaddingLeft ? 3 : 0}>{children}</Box>
  </Box>
);
