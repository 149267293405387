import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SfChangeFeedNotification } from '../types';
import * as yup from 'yup';
import { Edit, Delete, Copy, LightningSlash, Automations } from '@sweep-io/sweep-design/dist/icons';
import { Commands } from '../../../../types/enums/Common';

export const notificationStructure: SfChangeFeedNotification = {
  id: '',
  name: 'New Notification',
  crmOrgIds: [],
  criteria: [],
  criteriaLogic: '',
  isActive: true,
  actions: [],
};

export const notificationValidationSchema = yup.object().shape({
  name: yup.string().required('Name is required'),
});

export const FlexBoxColumn = ({ children, txt }: { children: any; txt: string }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
    <Typography variant="h4">{txt}</Typography>
    {children}
  </Box>
);

export const actionsMenuItems = [
  {
    label: Commands.Edit,
    value: Commands.Edit,
    actionIcon: <Edit />,
  },
  {
    label: Commands.Duplicate,
    value: Commands.Duplicate,
    actionIcon: <Copy />,
  },
  {
    label: Commands.Deactivate,
    value: Commands.Deactivate,
    actionIcon: <LightningSlash />,
  },
  {
    label: Commands.Active,
    value: Commands.Active,
    actionIcon: <Automations />,
  },
  {
    label: Commands.Delete,
    value: Commands.Delete,
    actionIcon: <Delete color={colors.blush[600]} />,
    actionSx: { color: colors.blush[600] },
  },
];
