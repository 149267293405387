import { RoutingListItem } from './RoutingTabsList';
import { useMemo } from 'react';
import { RoutingTabsInfo } from './routingTabsInfo';
import { RoutingTabs } from './useRoutingTabs';
import { useSelector } from 'react-redux';
import { selectAssignments } from '../../reducers/global/assignmentReducers';
import { selectScheduledAssignments } from '../../reducers/global/scheduledAssignmentReducers';
import useTerritories from '../../hooks/useTerritories';
import { useAssignmentGroups } from '../pages/assignments/members/useAssignmentGroups';

const useRoutingSectionElements = () => {
  const assignmentRules = useSelector(selectAssignments);
  const scheduledAssignments = useSelector(selectScheduledAssignments);
  const territories = useTerritories();
  const { assignmentGroups, crmOrgUsersBase } = useAssignmentGroups();
  const rulesElements: RoutingListItem[] = useMemo(
    () => [
      {
        label: RoutingTabsInfo[RoutingTabs.assignment].label,
        elements: assignmentRules ?? [],
        value: RoutingTabs.assignment,
      },
      {
        label: RoutingTabsInfo[RoutingTabs.scheduled].label,
        elements: scheduledAssignments ?? [],
        value: RoutingTabs.scheduled,
      },
    ],
    [assignmentRules, scheduledAssignments],
  );

  const setupElements: RoutingListItem[] = useMemo(
    () => [
      {
        label: 'Members',
        elements: crmOrgUsersBase ?? [],
        value: RoutingTabs.members,
      },
      {
        label: 'Groups',
        elements: assignmentGroups ?? [],
        value: RoutingTabs.groups,
      },
      {
        label: 'Territories',
        elements: territories ?? [],
        value: RoutingTabs.territories,
      },
    ],
    [assignmentGroups, crmOrgUsersBase, territories],
  );

  return {
    rulesElements,
    setupElements,
  };
};

export default useRoutingSectionElements;
