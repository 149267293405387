import { PluginMeddpiccSvg } from '../icons';
import { useOnAddCriteriaPlugin } from '../base-criteria-plugin-panel/useOnAddCriteriaPlugin';
import { PluginTypes } from '../../../../../../types/enums/PluginTypes';

export const useOnAddMeddpiccPlugin = () => {
  const onAdd = useOnAddCriteriaPlugin({
    pluginImage: <PluginMeddpiccSvg />,
    type: PluginTypes.MEDDPICC,
  });

  return onAdd;
};
