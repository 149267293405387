import { SweepFieldsByObjectType } from '../../../sweep-fields/useCachedSweepFields';
import { SFDCObjectType } from '../../../types/enums/SFDCObjectType';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';

const RESOLVABLE = 'RESOLVABLE';

// Sorts the fields by these rules:
//    * Alphabetically
const sortLookupFieldsFirstAlphabetically = (items: SweepField[]) => {
  return items.sort((a, b) => {
    return (a.sweepFieldName || '').localeCompare(b.sweepFieldName || '');
  });
};

export const injectPolymorphicFields = (
  sweepFields: SweepFieldsByObjectType,
  disableResolvePolymorphic?: boolean,
): SweepFieldsByObjectType => {
  Object.keys(sweepFields).forEach((objectType) => {
    const sweepFieldsByObject = sweepFields[objectType];
    const newSweepFields: SweepField[] = [];

    sweepFieldsByObject.fields.forEach((sweepField) => {
      const objectNames = sweepField.objectNames;
      const isResolvable =
        sweepField.fieldType === SweepFieldTypes.Lookup ||
        sweepField.fieldType === SweepFieldTypes.Hierarchy ||
        sweepField.fieldType === SweepFieldTypes.MasterDetail;

      if (isResolvable) {
        const isPolymorphic = isResolvable && objectNames && objectNames.length > 1;
        
        if (isPolymorphic) {
          if (!disableResolvePolymorphic) {
            objectNames.forEach((objectName) => {
              const polymorphicField = {
                ...sweepField,
                id: `${sweepField.id}|${RESOLVABLE}|${objectName}`,
                sweepFieldName: `${sweepField.sweepFieldName} (${objectName})`,
                objectNames: [objectName],
                objectName: objectName as SFDCObjectType,
              };
              newSweepFields.push(polymorphicField);
            });
          }
        } else {
          newSweepFields.push({
            ...sweepField,
            id: `${sweepField.id}|${RESOLVABLE}`,
          });
        }
      }
      newSweepFields.push(sweepField);
    });
    sweepFieldsByObject.fields = sortLookupFieldsFirstAlphabetically(newSweepFields);
  });

  return sweepFields;
};

export const parseSweepFieldPolymorphicID = (
  composedId: string,
): {
  id: string;
  isResolvable: boolean;
  objectType?: string;
} => {
  const [id, resolvable, objectType] = composedId.split('|');

  const parsed: {
    id: string;
    isResolvable: boolean;
    objectType?: string;
  } = {
    id,
    isResolvable: resolvable === RESOLVABLE,
  };
  if (objectType) {
    parsed.objectType = objectType;
  }
  return parsed;
};
