import { useSelector } from 'react-redux';
import { selectRollupsForTable } from '../../../reducers/global/rollupReducers';
import { useCallback, useMemo } from 'react';

const useFieldIsRollup = () => {
  const rollups = useSelector(selectRollupsForTable);
  const rollupsNames = useMemo(() => rollups?.map((rollup) => rollup.name), [rollups]);

  const getFieldIsRollup = useCallback(
    (fieldId?: string) => rollupsNames?.includes(fieldId ?? '') ?? false,
    [rollupsNames],
  );

  return getFieldIsRollup;
};

export default useFieldIsRollup;
