import { AssignmentGroup, AssignmentGroupMember } from '../reducers/assignmentGroupTypes';

export class AssignmentGroupModel {
  _assignmentGroup: AssignmentGroup;

  constructor(assignmentGroup: AssignmentGroup) {
    this._assignmentGroup = assignmentGroup;
  }

  setAssignmentGroup(assignmentGroup: AssignmentGroup) {
    this._assignmentGroup = { ...assignmentGroup };
    return this;
  }

  setMembers(members: AssignmentGroupMember[]) {
    this._assignmentGroup.members = members;
    return this;
  }
  addMember(member: AssignmentGroupMember) {
    this._assignmentGroup.members.push(member);
    return this;
  }
  removeMemberById(id: string) {
    this._assignmentGroup.members = this._assignmentGroup.members.filter(
      (member) => member.userId !== id,
    );
    return this;
  }
  removeMemberAtIdx(idx: number) {
    this._assignmentGroup.members.splice(idx, 1);
    return this;
  }

  setMemberActiveStatusAtIdx(idx: number, active: boolean) {
    this._assignmentGroup.members[idx].membershipActive = active;
    return this;
  }
  setMemberWeightAtIdx(idx: number, weight: number) {
    this._assignmentGroup.members[idx].weight = weight;
    return this;
  }
  setMemberLimitAtIdx(idx: number, limit: number | null) {
    this._assignmentGroup.members[idx].limitValue = limit;
    return this;
  }
  updateIsUpNext(assignmentGroup: AssignmentGroup) {
    const id = assignmentGroup.members.find((member) => member.isUpNext)?.userId;
    this._assignmentGroup.members = this._assignmentGroup.members.map((member) => ({
      ...member,
      isUpNext: member.userId === id,
    }));
    return this;
  }
  setUpdatedAt(updatedAt: string) {
    this._assignmentGroup.updatedAt = updatedAt;
    return this;
  }

  get assignmentGroup() {
    return this._assignmentGroup;
  }
}
