import { useMemo, useState } from 'react';
import _isEqual from 'lodash/isEqual';

/**
 * Saves the initial data and returns true
 * if the data has changed. Useful to check if forms
 * were modified
 * @param {T} data
 * @returns {boolean}
 */

export function useIsPristine<T = any>(data: T): boolean {
  const [initialData] = useState(data);
  const isPristine = useMemo(
    () => _isEqual(JSON.stringify(data), JSON.stringify(initialData)),
    [data, initialData],
  );

  return isPristine;
}
