import { useCallback } from 'react';
import { useFunnelsApiFacade } from '../apis/facades/useFunnelsApiFacade';

export const useIsFunnelNameUnique = () => {
  const { get_funnelsByName } = useFunnelsApiFacade();
  const isFunnelNameUnique = useCallback(
    async ({
      funnelId,
      name,
      objectName,
    }: {
      funnelId?: string;
      name: string;
      objectName: string;
    }) => {
      const res = await get_funnelsByName(name, objectName);
      if (res.length === 1) {
        return res?.[0]?.id === funnelId; //Given name exists but it's the name of current funnel
      }
      return res.length === 0; //Given name doesnt exists in db
    },
    [get_funnelsByName],
  );

  return isFunnelNameUnique;
};
