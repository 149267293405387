import { useCrmOrgs } from '../../environments/useCrmOrgs';
import { useSelector } from 'react-redux';
import { selectCrmOrgUsers, selectCrmOrgUsersBase } from '../../../../reducers/crmOrgUsersReducer';
import { useRunOnceWhenTruthy } from '../../../common/useRunOnceWhenTruthy';
import {
  selectAssignmentGroupsList,
  selectIsAssignmentGroupsLoaded,
} from '../../../../reducers/assignmentGroupsPageReducer';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { useEffect, useRef } from 'react';
import useIsManagedPackageInstalled from '../../../common/install-managed-package/useIsManagedPackageInstalled';

export const useAssignmentGroups = () => {
  const { getCrmOrgUsersBase, getCrmOrgUsers } = useCrmOrgs();
  const isManagedPackagedInstalled = useIsManagedPackageInstalled();
  const { getAssignmentsGroupList, clearAllAssignmentGroupsData } = useAssignmentsApiWithReducer();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const crmOrgUsersBase = useSelector(selectCrmOrgUsersBase(crmOrgId || ''));
  const crmOrgUsers = useSelector(selectCrmOrgUsers(crmOrgId || ''));

  const assignmentGroups = useSelector(selectAssignmentGroupsList);
  const isGroupsLoaded = useSelector(selectIsAssignmentGroupsLoaded);

  useRunOnceWhenTruthy(() => {
    getCrmOrgUsersBase();
  }, !!crmOrgId && !crmOrgUsersBase);

  useRunOnceWhenTruthy(
    () => {
      getCrmOrgUsers();
    },
    !!crmOrgId && !crmOrgUsers && isManagedPackagedInstalled,
  );

  const currentAssignmentGroupCrmOrgId = useRef<string | null>(null);

  useEffect(() => {
    (async () => {
      if (crmOrgId) {
        if (!isGroupsLoaded && currentAssignmentGroupCrmOrgId.current !== crmOrgId) {
          clearAllAssignmentGroupsData();
          currentAssignmentGroupCrmOrgId.current = crmOrgId;
          getAssignmentsGroupList();
        }
      }
    })();
  }, [clearAllAssignmentGroupsData, crmOrgId, getAssignmentsGroupList, isGroupsLoaded]);

  return {
    crmOrgUsersBase,
    crmOrgUsers,
    assignmentGroups,
  };
};
