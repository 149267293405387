import { Box } from '@mui/material';
import { ArrowRight as ArrowRightIcon } from '@sweep-io/sweep-design/dist/icons';
import { PluginField } from './types';
import { useCallback, useMemo } from 'react';
import { DataTable } from '../../../../../common/table/DataTable';
import { colors } from '@sweep-io/sweep-design';
import { NestedFieldsSelector } from '../../../../../common/fieldsSelectors/NestedFieldsSelector';
import { get, keyBy } from 'lodash';
import { useSweepFieldsLabels } from '../../../../../../sweep-fields/useSweepFieldsLabels';
import { parseSweepFieldPolymorphicID } from '../../../../../common/fieldsSelectors/nestedFieldsSelectorHelper';
import { DataTableBaseColumn } from '../../../../../common/table/TableTypes';
import { SweepFieldTypes } from '../../../../../../types/enums/SweepFieldTypes';

const columns: DataTableBaseColumn[] = [
  {
    field: 'fieldName',
    headerName: 'Field Name',
  },
  {
    field: 'apiName',
    headerName: 'API Name',
  },
  {
    field: 'type',
    headerName: 'Type',
  },
  {
    field: 'mapToIcon',
    headerName: '',
    width: '72px',
  },
  {
    field: 'mapTo',
    headerName: 'Map To',
    width: '158px',
  },
];

interface TemplateFieldsTableProps {
  crmOrgId: string;
  objectType: string;
  fieldsMap: CriterionPluginFieldMap[];
  stepMaps: PluginField[];
  onChange: (fieldsMap: CriterionPluginFieldMap[]) => void;
}

export const PluginFieldsMapTable = ({
  crmOrgId,
  objectType,
  fieldsMap,
  stepMaps,
  onChange,
}: TemplateFieldsTableProps) => {
  const { getEnrichedNestedPath } = useSweepFieldsLabels();

  const fieldsMapById = useMemo(() => keyBy(fieldsMap, 'fromTemplateFieldId'), [fieldsMap]);

  const filterBy = useCallback(
    (type: string, lookupData?: { lookupObjectName?: string; lookupRelationshipName?: string }) =>
      (field: SweepField) => {
        const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');
        if (isResolvable) {
          return true;
        }

        if (type === SweepFieldTypes.Lookup && lookupData) {
          const referenceToTypeName = get(field, 'properties.referenceTo[0].elemID.typeName', '');
          const relationshipName = get(field, 'properties.relationshipName', '');
          return (
            lookupData.lookupObjectName === referenceToTypeName &&
            lookupData.lookupRelationshipName === relationshipName
          );
        }
        return type === field.fieldType;
      },
    [],
  );

  const rows = stepMaps.map(
    ({ apiName, fieldName, pluginFieldId: id, type, lookupFieldProps: lookupData }) => {
      const fieldMap = fieldsMapById[id];
      const nestedPath = fieldsMapById[id]?.toSweepFieldId
        ? getEnrichedNestedPath([fieldMap?.toSweepFieldId])
        : [];

      return {
        id,
        fieldName,
        apiName,
        type,
        mapToIcon: <ArrowRightIcon />,
        mapTo: (
          <Box
            sx={{
              backgroundColor: colors.white,
              borderRadius: '4px',
              border: `1px solid ${colors.grey[300]}`,
              marginTop: '2px',
            }}
          >
            <NestedFieldsSelector
              nestedPath={nestedPath}
              objectType={objectType}
              crmOrgId={crmOrgId}
              onChange={({ fieldIds }) => {
                const sweepFieldId = fieldIds[0];
                const newFieldMaps = [...fieldsMap];
                const idx = newFieldMaps.findIndex(
                  ({ fromTemplateFieldId }) => fromTemplateFieldId === id,
                );
                if (idx !== -1) {
                  newFieldMaps[idx] = {
                    ...newFieldMaps[idx],
                    toSweepFieldId: sweepFieldId,
                  };
                } else {
                  newFieldMaps.push({
                    fromTemplateFieldId: id,
                    toSweepFieldId: sweepFieldId,
                  });
                }
                onChange(newFieldMaps);
              }}
              filterBy={filterBy(type, lookupData)}
              placeholder="Choose field"
              disableLookupItemsResolve
              hideActionButton
            />
          </Box>
        ),
      };
    },
  );
  return <DataTable columns={columns} rows={rows} rowTypographyVariant="caption" />;
};
