import { Box } from '@mui/material';
import { ArrowRight } from '@sweep-io/sweep-design/dist/icons';
import { colors } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

const AutomationActionText = ({ element, color }: { element: ReactNode; color?: string }) => (
  <Box
    sx={{
      display: 'flex',
      gap: 1,
    }}
  >
    <Box
      sx={{
        display: 'flex',
        pt: '4px', //the arrow should be located in the middle of the first row, and "element" can have several rows (so can't position with "align item: center")
      }}
    >
      <ArrowRight color={color ?? colors.grey[500]} variant="tiny" />
    </Box>
    {element}
  </Box>
);

export default AutomationActionText;
