import React from 'react';
import { createContext, useState } from 'react';

interface FunnelMapCanvasCtxProps {
  editingThirdPartyStepId?: string;
  setEditingThirdPartyStepId: (id: string | undefined) => void;
}
const FunnelMapCanvasCtx = createContext<FunnelMapCanvasCtxProps>({
  setEditingThirdPartyStepId: () => {},
});

export const FunnelMapCanvasCtxProvider = ({ children }: { children: React.ReactNode }) => {
  const [editingThirdPartyStepId, setEditingThirdPartyStepId] = useState<string | undefined>();

  return (
    <FunnelMapCanvasCtx.Provider value={{ editingThirdPartyStepId, setEditingThirdPartyStepId }}>
      {children}
    </FunnelMapCanvasCtx.Provider>
  );
};

export const useFunnelMapCanvasCtx = () => {
  const ctx = React.useContext(FunnelMapCanvasCtx);
  return ctx;
};
