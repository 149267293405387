import { Box } from '@mui/material';
import { ReactNode, memo, useCallback, useContext, useMemo } from 'react';
import { PillProps } from '@sweep-io/sweep-design/dist/components/Pill';
import { ListElementWrapper } from '../shared/ListElementWrapper';
import { ObjectWithPills } from '../types';
import { ObjectTypeChip } from '../../common/ObjectTypeChip';
import { colors, Pill } from '@sweep-io/sweep-design';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { useDocumentation } from '../useDocumentation';
import { pillOptions } from '../selected-object/PillOptions';
import { AddObjectButton } from './AddObjectButton';
import { useExpandedMode } from '../../pages/configuration-canvas/panels-reserve-space/ReserveSpaceForPanelsCtx';
import { DocumentationDialogContext } from '../context/DocumentationContextProvider';

export type PillPropsExtended = PillProps & { key: DocumentationTabTypes; icon: ReactNode };

interface ObjectCardPillsProps {
  item: ObjectWithPills;
  onCardClick: (objectTypeName: ObjectTypeName, tab?: DocumentationTabTypes) => void;
  displayPills: boolean;
  hideAddObjectButton?: boolean;
}

export const ObjectCardPills = memo(
  ({ item, onCardClick, displayPills, hideAddObjectButton }: ObjectCardPillsProps) => {
    const { isExpandedMode } = useExpandedMode();
    const { objectsBeingParsed } = useDocumentation();
    const isLoading = objectsBeingParsed?.[item.objectType];

    const onClick = useCallback(
      (event: any, tab?: DocumentationTabTypes) => {
        event.stopPropagation();
        onCardClick(item, tab);
      },
      [item, onCardClick],
    );

    const leftContent = useMemo(
      () => (
        <Box display="inline-flex" minWidth={0} gap={1} alignItems="center">
          <ObjectTypeChip
            dataTestId="object-card"
            tagVariant="medium"
            label={item.label}
            objectType={item.objectType}
          />
          {isExpandedMode && !displayPills && (
            <Box className={isLoading ? '' : 'button-wrapper'}>
              <AddObjectButton objectName={item.objectType} isLoading={isLoading} />
            </Box>
          )}
        </Box>
      ),
      [item, isLoading, isExpandedMode, displayPills],
    );

    const rightContent = useMemo(() => {
      const pills = item.pills ?? ({} as { [key: string]: number });
      const mappedPills = pillOptions.map((option) => ({
        ...option,
        key: option.key,
        value: pills[option.key] ?? 0,
      }));

      return displayPills ? (
        <PillsRow objectName={item.objectType} onClick={onClick} pills={mappedPills} />
      ) : (
        <>
          {!hideAddObjectButton && (
            <Box
              className="button-wrapper"
              sx={{
                display: 'flex',
                '& button': {
                  whiteSpace: 'nowrap',
                },
              }}
            >
              {!isLoading && !isExpandedMode && <AddObjectButton objectName={item.objectType} />}
            </Box>
          )}

          {isLoading && !isExpandedMode && (
            <AddObjectButton isLoading objectName={item.objectType} />
          )}
        </>
      );
    }, [item, isLoading, displayPills, hideAddObjectButton, onClick, isExpandedMode]);

    return (
      <Box
        sx={{
          '& .button-wrapper': {
            display: 'none',
          },
          '&:hover .button-wrapper': {
            display: 'block',
          },
        }}
      >
        <ListElementWrapper
          sx={{ height: '56px' }}
          leftContent={leftContent}
          rightContent={rightContent}
          onWrapperClick={onClick}
          elementId={item.objectType}
        />
      </Box>
    );
  },
);

const PillsRow = ({
  pills,
  objectName,
  onClick,
}: {
  objectName: string;
  pills: PillPropsExtended[];
  onClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    tab?: DocumentationTabTypes,
  ) => void;
}) => {
  const { setHoveredPin } = useContext(DocumentationDialogContext);
  const _onPillClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>, key?: DocumentationTabTypes) =>
      onClick(event, key),
    [onClick],
  );
  const _onHover = useCallback(
    (key: DocumentationTabTypes, isEmpty?: boolean) => {
      setHoveredPin?.(isEmpty ? undefined : { hoveredObjectTypeName: objectName, hoveredTab: key });
    },
    [setHoveredPin, objectName],
  );
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }} pl={1}>
      {pills.map((pill) => {
        const { key, ..._pill } = pill;
        return (
          <Box
            key={key}
            onMouseEnter={() => _onHover(key, !pill.value)}
            onMouseLeave={() => setHoveredPin?.(undefined)}
          >
            <Pill
              {..._pill}
              backgroundColor={colors.storm[70]}
              onClick={(e) => _onPillClick(e, key as DocumentationTabTypes)}
            />
          </Box>
        );
      })}
    </Box>
  );
};
