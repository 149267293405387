import uniqBy from 'lodash/uniqBy';
import { AutomationTypes } from '../../../../types/enums/AutomationTypes';

interface AutomationClassification {
  level: 'object' | 'recordType' | 'step';
  objectName: string;
}
interface ObjectAutomationClassification extends AutomationClassification {
  level: 'object';
}

interface RecordTypeAutomationClassification extends AutomationClassification {
  level: 'recordType';
  recordTypes: ShortParserRecordType[];
}
interface StepLevelAutomationClassification extends AutomationClassification {
  level: 'step';
  recordType: ShortParserRecordType;
  steps: AutomationStageDetails[];
}

type AutomationClassifications =
  | ObjectAutomationClassification
  | RecordTypeAutomationClassification
  | StepLevelAutomationClassification;

export const classifyAutomation = (
  automation: AutomationStructureNew,
): AutomationClassifications => {
  const { automationDetails, objectName } = automation;
  const { triggerType } = automationDetails;

  if (triggerType === AutomationTypes.FieldUpdate) {
    return {
      level: 'object',
      objectName,
    };
  }
  if (
    [AutomationTypes.StageEnter, AutomationTypes.StageExit, AutomationTypes.TimeInStep].includes(
      triggerType,
    )
  ) {
    const steps = [
      automationDetails.automationParams.stageEnter?.toStage,
      automationDetails.automationParams.stageExit?.toStage,
      automationDetails.automationParams.stageExit?.fromStage,
      automationDetails.automationParams.timeInStep?.stage,
    ].filter(Boolean) as AutomationStageDetails[];

    if (!automation.funnelRecordType) {
      throw new Error('Record type is required for step level automation');
    }

    // StepLevelAutomationClassification
    return {
      level: 'step',
      recordType: automation.funnelRecordType,
      steps: uniqBy(steps, '_stageId'),
      objectName,
    };
  }

  const recordTypes = automation.automationDetails.recordTypes;

  if (recordTypes?.length) {
    // RecordTypeAutomationClassification
    return {
      level: 'recordType',
      recordTypes,
      objectName,
    };
  }
  // ObjectAutomationClassification
  return {
    level: 'object',
    objectName,
  };
};
