import CopilotChats from './CopilotChats';
import useCopilotChats from './useCopilotChats';

const CopilotChatsConnected = ({
  closeCopilot,
  isOpened,
}: {
  closeCopilot: () => void;
  isOpened: boolean;
}) => {
  const { isExpanded, toggleIsExpanded, chats, activeChat, isLoadingChats } = useCopilotChats();

  return (
    <CopilotChats
      closeCopilot={closeCopilot}
      isOpened={isOpened}
      isExpanded={isExpanded}
      toggleIsExpanded={toggleIsExpanded}
      chats={chats}
      isLoadingChats={isLoadingChats}
      activeChat={activeChat}
    />
  );
};

export default CopilotChatsConnected;
