import { useCallback } from 'react';
import useEventListener from '../../../../common/useEventListener';
import {
  PlacingPlugin,
  PlacingPluginContextConsumer,
  usePlacingPluginContext,
} from './PlacingPluginContext';
import React from 'react';
import { Box } from '@mui/material';

interface PlacingPluginLayerProps extends PlacingPlugin {}

const PlacingPluginLayer = ({ pluginImage }: PlacingPluginLayerProps) => {
  const { setPlacingPlugin } = usePlacingPluginContext();

  const onEscapeHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setPlacingPlugin(null);
      }
    },
    [setPlacingPlugin],
  );
  const [mousePosition, setMousePosition] = React.useState({ x: 0, y: 0 });

  useEventListener('keydown', onEscapeHandler);
  useEventListener('mousemove', (event) => {
    const { x: eventX, y: eventY } = event;
    setMousePosition({ x: eventX, y: eventY });
  });

  return (
    <div
      style={{
        width: '0px',
        height: '0px',
        top: 0,
        left: 0,
        position: 'absolute',
        zIndex: 1,
      }}
      id="placing-plugin-layer"
    >
      <Box
        sx={{
          position: 'fixed',
          top: mousePosition.y + 10,
          left: mousePosition.x + 10,
          svg: {
            viewbox: '0 0 136 136',
            width: '136px',
            height: '136px',
            opacity: 0.8,
          },
        }}
      >
        {pluginImage}
      </Box>
    </div>
  );
};

export const PlacingPluginLayerConsumer = () => {
  return (
    <PlacingPluginContextConsumer>
      {({ placingPlugin }) => placingPlugin && <PlacingPluginLayer {...placingPlugin} />}
    </PlacingPluginContextConsumer>
  );
};
