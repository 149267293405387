import { Box, FormHelperText, MenuItem, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { isEqual } from 'lodash';
import slackPng from '../images/slackIcon.png';
import sfPng from '../images/sfIcon.png';
import channelPng from '../images/channelIcon.png';
import CloseIcon from '@mui/icons-material/Close';
import { StyledChip } from '../../common/StyledChip';
import { Button, colors } from '@sweep-io/sweep-design';
import { ChevronDown, Close } from '@sweep-io/sweep-design/dist/icons';
import { ListboxComponent } from './VirtualList';
import { useSelector } from 'react-redux';
import {
  selectIsSlackRecipientsCached,
  selectOptionsForNoChannelRecipients,
  selectOptionsForRecipients,
} from '../../../reducers/slackReducer';
import { useCallback, useState } from 'react';
import { NestedFieldsSelector } from '../../common/fieldsSelectors/NestedFieldsSelector';
import { parseSweepFieldPolymorphicID } from '../../common/fieldsSelectors/nestedFieldsSelectorHelper';
import { SweepFieldTypes } from '../../../types/enums/SweepFieldTypes';
import { useIntegrations } from '../../../hooks/useIntegrations';
import { telemetry } from '../../../telemetry';
import { SlackConstant } from './utils';

interface SlackRecipientsSelectProps {
  onChange: (event: any, newValues: SlackRecipientStruct[]) => void;
  handleDeleteChip: (chipToRemove: SlackRecipientStruct) => void;
  readonly?: boolean;
  recipients: SlackRecipientStruct[];
  error?: boolean;
  hideDisclaimer?: boolean;
  isLoading?: boolean;
  notConnected?: boolean;
  recipientsNoChannels?: boolean;
  crmOrgId?: string;
  objectName?: string;
  customButtonText?: string;
  isAddTextFieldFromSf?: boolean;
}

export const SlackRecipientsSelect = ({
  onChange,
  handleDeleteChip,
  readonly,
  recipients,
  error,
  hideDisclaimer,
  isLoading,
  notConnected,
  recipientsNoChannels,
  crmOrgId,
  objectName,
  customButtonText,
  isAddTextFieldFromSf,
}: SlackRecipientsSelectProps) => {
  const optionsForRecipients = useSelector(selectOptionsForRecipients);
  const noChannelsRecipients = useSelector(selectOptionsForNoChannelRecipients);
  const isRecipientsCached = useSelector(selectIsSlackRecipientsCached);
  const { getSlackRecipients } = useIntegrations();
  const [isLoadingOnRefresh, setIsLoadingOnRefresh] = useState(false);

  const returnChipWithProps = useCallback(
    (item: SlackRecipientStruct, isDelete: boolean) => {
      let ChipProps: any = {
        key: JSON.stringify(item.value),
        label: item._label,
        title: item._label,
        size: 'small',
        icon:
          item.type === SlackConstant.Slack ? (
            <img src={slackPng} alt={'slack icon'} style={{ width: '12px', marginLeft: '8px' }} />
          ) : item.type === SlackConstant.Sweep_Created_Slack_Channel ? (
            <img
              src={channelPng}
              alt={'channel icon'}
              style={{ width: '12px', marginLeft: '8px' }}
            />
          ) : (
            <img src={sfPng} alt={'salesforce icon'} style={{ width: '12px', marginLeft: '8px' }} />
          ),
      };
      if (isDelete) {
        ChipProps = {
          ...ChipProps,
          onDelete: () => handleDeleteChip(item),
          deleteIcon: <CloseIcon sx={{ color: '#000', fontSize: 12 }} />,
        };
      }
      return <StyledChip {...ChipProps} sx={{ textTransform: 'capitalize' }} key={ChipProps.key} />;
    },
    [handleDeleteChip],
  );

  const handleSlackRecipientsChips = useCallback(() => {
    const labels = recipients
      ? recipients?.map((chip) => {
          return returnChipWithProps(chip, true);
        })
      : [];
    return labels;
  }, [recipients, returnChipWithProps]);

  const filterByEndSelectionTextOrUser = (field: SweepField) => {
    const { isResolvable } = parseSweepFieldPolymorphicID(field.id || '');
    if (isResolvable) {
      return true;
    }
    if (isAddTextFieldFromSf && field.fieldType === SweepFieldTypes.Text) {
      return true;
    }
    if (field.fieldType === SweepFieldTypes.Lookup) {
      return Boolean(field.objectNames?.includes('User'));
    }
    return false;
  };
  const isShowRecipientDealRoomText = recipients?.find(
    (el) => el.type === SlackConstant.Sweep_Created_Slack_Channel,
  );

  const handleRefreshRecipients = async () => {
    try {
      setIsLoadingOnRefresh(true);
      await getSlackRecipients(undefined, true);
      setIsLoadingOnRefresh(false);
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  return (
    <Box
      component="span"
      sx={{
        position: 'relative',
        ' .MuiAutocomplete-paper': {
          width: '100%',
          ...(!hideDisclaimer && {
            '.MuiAutocomplete-listbox::after': {
              content: '"To message a private channel, add the Sweep app as a member"',
              display: 'block',
              height: '39px',
              paddingLeft: '28px',
              lineHeight: '39px',
              fontSize: '14px',
              color: colors.grey[700],
            },
          }),
        },
        '& .MuiAutocomplete-option[aria-selected="true"]': {
          background: '#F8F9FA !important',
        },
        '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
          rowGap: '8px',
          paddingTop: '8px',
          paddingBottom: '8px',
          '& .MuiAutocomplete-input': {
            paddingTop: 0,
            paddingBottom: 0,
          },
          '& .MuiAutocomplete-endAdornment': {
            '& .MuiAutocomplete-clearIndicator': {
              marginRight: '4px',
            },
            ' & .MuiAutocomplete-popupIndicator': {
              marginRight: 0,
            },
          },
        },
      }}
    >
      {isRecipientsCached && (
        <Box sx={{ position: 'absolute', right: 0, top: '-28px' }}>
          <Button onClick={handleRefreshRecipients} variant="flat" loading={isLoadingOnRefresh}>
            Refresh
          </Button>
        </Box>
      )}
      <Autocomplete
        ListboxComponent={ListboxComponent}
        loading={isLoading}
        popupIcon={<ChevronDown />}
        clearIcon={<Close />}
        disabled={readonly || notConnected}
        multiple
        id="tags-slack"
        options={recipientsNoChannels ? noChannelsRecipients : optionsForRecipients}
        getOptionLabel={(option: SlackRecipientStruct) => option._label ?? ''}
        renderTags={handleSlackRecipientsChips}
        isOptionEqualToValue={(option: SlackRecipientStruct, savedItem: SlackRecipientStruct) => {
          if (option.type === SlackConstant.Salesforce) {
            if (
              savedItem.type === SlackConstant.Salesforce ||
              savedItem.type === SlackConstant.Salesforce_Created_Slack_Channel
            ) {
              return isEqual(
                (option.value as SlackFieldsStruct).fieldIds,
                (savedItem.value as SlackFieldsStruct).fieldIds,
              );
            }
          } else if (savedItem.type === SlackConstant.Slack) {
            return option.value === savedItem.value;
          } else if (savedItem.type === SlackConstant.Sweep_Created_Slack_Channel) {
            return option.type === SlackConstant.Sweep_Created_Slack_Channel;
          }

          return false;
        }}
        disablePortal
        value={notConnected ? [] : recipients ?? []}
        renderOption={(props, option: SlackRecipientStruct) => {
          return (
            <MenuItem {...props} sx={{ minWidth: '100%', width: '100%' }} key={props.key}>
              {returnChipWithProps(option, false)}
            </MenuItem>
          );
        }}
        onChange={onChange}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            placeholder={
              notConnected
                ? 'To add recipients, connect Slack to your production environment'
                : 'Select recipients'
            }
          />
        )}
      />
      {isShowRecipientDealRoomText && (
        <FormHelperText>Note: message will be sent to deal room only if it exists</FormHelperText>
      )}
      {objectName && (
        <Box>
          <NestedFieldsSelector
            filterBy={filterByEndSelectionTextOrUser}
            readonly={readonly}
            exclusiveParentFieldType={SweepFieldTypes.Email}
            crmOrgId={crmOrgId ?? ''}
            objectType={objectName ?? ''}
            nestedPath={[]}
            customButtonEndIcon={<ChevronDown color={colors.blue[500]} />}
            useCustomButton
            customButtonText={customButtonText}
            customButtonSx={{
              marginTop: '8px',
              height: '20px',
              color: colors.blue[500],
              border: 'none',
              textTransform: 'unset',
              p: '4px 0px',
              '& span.MuiTypography-root': {
                fontSize: '14px',
                fontWeight: 500,
              },
              '&:hover': {
                background: 'transparent',
                color: colors.blue[600],
              },
            }}
            onChange={(_sweepField) => {
              const elem: SlackRecipientStruct = {
                type:
                  _sweepField?.fieldType === SweepFieldTypes.Text
                    ? SlackConstant.Salesforce_Created_Slack_Channel
                    : SlackConstant.Salesforce,
                value: {
                  fieldIds: _sweepField?.fieldIds,
                },
                _label: _sweepField?.fieldLabels?.join('.'),
              };
              onChange(null, [...(recipients ?? []), elem]);
            }}
          />
        </Box>
      )}
    </Box>
  );
};
