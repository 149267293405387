import { useRunOnce } from '../components/common/useRunOnce';
import { useCrmOrgs } from '../components/pages/environments/useCrmOrgs';

export const useLoadCrmOrgs = () => {
  const { getConnectedCrmOrgs } = useCrmOrgs();

  useRunOnce(() => {
    getConnectedCrmOrgs();
  });
};
