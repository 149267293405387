import * as React from "react";
const SvgCloudDisconnectedIllustration = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 128, height: 128, viewBox: "0 0 128 128", fill: "none", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M54.5479 32.7108C58.2344 28.8731 63.3655 26.4965 69.0339 26.4965C76.5919 26.4965 83.1421 30.6954 86.6691 36.9517C89.7999 35.5521 93.191 34.8295 96.6204 34.8312C110.225 34.8312 121.268 45.9582 121.268 59.6885C121.268 73.4187 110.225 84.5457 96.6204 84.5457C94.9577 84.5457 93.3369 84.3786 91.7497 84.0628C88.6636 89.5634 82.7642 93.3003 76.046 93.3003C73.3108 93.3057 70.6106 92.6853 68.1521 91.4864C65.024 98.8344 57.739 103.999 49.2573 103.999C40.3977 103.999 32.8818 98.4145 29.9845 90.5627C28.6951 90.8348 27.3807 90.9716 26.0628 90.9708C15.5237 90.9708 7 82.3212 7 71.698C7 64.56 10.8378 58.3457 16.5314 54.9866C15.3235 52.204 14.7017 49.2024 14.7049 46.169C14.7049 33.9083 24.6562 23.999 36.9169 23.999C44.0969 23.999 50.5211 27.4211 54.5521 32.7326", stroke: "#00A1E0", style: {
  stroke: "#00A1E0",
  stroke: "color(display-p3 0.0000 0.6314 0.8784)",
  strokeOpacity: 1
}, strokeWidth: 2, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("mask", { id: "path-2-outside-1_2127_53850", maskUnits: "userSpaceOnUse", x: 14.7613, y: 6.09066, width: 82.2831, height: 109.265, fill: "black" }, /* @__PURE__ */ React.createElement("rect", { fill: "white", x: 14.7613, y: 6.09066, width: 82.2831, height: 109.265 }), /* @__PURE__ */ React.createElement("path", { d: "M96.2391 112.985C95.7944 113.312 95.1684 113.217 94.841 112.772L17.7725 8.08489C17.445 7.64012 17.5402 7.01415 17.9849 6.68672C18.4297 6.3593 19.0557 6.45441 19.3831 6.89918L96.4516 111.586C96.779 112.031 96.6839 112.657 96.2391 112.985Z" })), /* @__PURE__ */ React.createElement("path", { d: "M96.2391 112.985C95.7944 113.312 95.1684 113.217 94.841 112.772L17.7725 8.08489C17.445 7.64012 17.5402 7.01415 17.9849 6.68672C18.4297 6.3593 19.0557 6.45441 19.3831 6.89918L96.4516 111.586C96.779 112.031 96.6839 112.657 96.2391 112.985Z", fill: "#00A1E0", style: {
  fill: "#00A1E0",
  fill: "color(display-p3 0.0000 0.6314 0.8784)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M96.2391 112.985C94.4601 114.294 91.9562 113.914 90.6465 112.135L17.1351 12.2794C15.8254 10.5003 16.2059 7.99642 17.9849 6.68672C18.1985 6.52949 18.4658 6.46354 18.728 6.50338C18.9902 6.54322 19.2259 6.6856 19.3831 6.89918L96.4516 111.586L97.0445 112.392L96.4516 111.586C96.6088 111.8 96.6748 112.067 96.6349 112.329C96.5951 112.592 96.4527 112.827 96.2391 112.985L96.2391 112.985ZM18.7902 6.09387L97.0445 112.392L18.7902 6.09387ZM97.0445 112.392L95.4338 113.577L97.0445 112.392ZM17.1796 7.27958L18.7902 6.09387L17.1796 7.27958Z", fill: "white", style: {
  fill: "white",
  fillOpacity: 1
}, mask: "url(#path-2-outside-1_2127_53850)" }));
export default SvgCloudDisconnectedIllustration;
