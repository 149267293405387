import { Box, FormControl } from '@mui/material';
import RequiredDot from '../common/RequiredDot';
import { DynamicRecipientSelector } from './DynamicRecipientSelector';
import { EmailTemplateSelector } from './EmailTemplateSelector';
import { Typography, colors } from '@sweep-io/sweep-design';

interface AutomationNotifyEmailProps {
  readonly?: boolean;
  action: EmailAutomationAction;
  crmOrgId: string;
  onChange: (emails: EmailAutomationAction) => any;
  objectName: string;
}

export const AutomationNotifyEmail = ({
  readonly,
  action,
  crmOrgId,
  onChange,
  objectName,
}: AutomationNotifyEmailProps) => {
  return (
    <>
      <Box
        sx={{
          background: colors.grey[100],
          padding: '24px 24px 19px',
          borderRadius: '8px',
          margin: '16px 0',
        }}
      >
        <FormControl
          sx={{
            minWidth: '120px',
            margin: '0 22px 16px 0',
          }}
        >
          <Box sx={{ display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
            <Typography variant="caption" color={colors.grey[800]}>
              Email Template
            </Typography>
            <RequiredDot />
          </Box>
          <EmailTemplateSelector
            readonly={readonly}
            emailTemplate={action.actionParams?.emailTemplate}
            crmOrgId={crmOrgId}
            onChange={(emailTemplate) => {
              onChange({
                ...action,
                actionParams: {
                  ...action.actionParams,
                  emailTemplate: emailTemplate,
                },
              });
            }}
          />
        </FormControl>
        <FormControl
          sx={{
            minWidth: '120px',
            margin: '0 22px 0px 0',
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <Box sx={{ display: 'flex', marginBottom: '8px', alignItems: 'center' }}>
            <Typography variant="caption" color={colors.grey[800]}>
              Recipients
            </Typography>
            <RequiredDot />
          </Box>
          <DynamicRecipientSelector
            action={action}
            onChange={onChange}
            objectName={objectName}
            crmOrgId={crmOrgId}
            readonly={readonly}
          />
        </FormControl>
      </Box>
    </>
  );
};
