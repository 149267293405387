import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalState } from './globalReducerTypes';
import { RootState } from '../index';
import { selectDefaultCreationEnvironment } from '../../components/pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { AutomationType } from '../../types/enums/AutomationType';
import { telemetry } from '../../telemetry';

type dedupMatchingReducersProps = {
  addDedupMatching: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automation: AutomationStructureNew;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  updateDedupMatching: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  toggleDedupMatching: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationToUpdate: AutomationStructureNew;
      versionIdToUpdate: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
  deleteDedupMatching: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationIdToDelete: string;
    }>,
  ) => any;
  updateDedupMatchingDeployStatus: (
    state: GlobalState,
    action: PayloadAction<{
      crmOrgId: string;
      automationId: string;
      versionId: string;
      optimisticStatus: DeployStatusForTable;
    }>,
  ) => any;
};
export const dedupMatchingReducers: dedupMatchingReducersProps = {
  addDedupMatching: (state, action) => {
    const { crmOrgId, automation, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data) {
      return;
    }
    const type = automation.type;
    if (type === AutomationType.Dedupe) {
      if (!state.environments[crmOrgId].data.dedup) {
        state.environments[crmOrgId].data.dedup = [];
      }
      state.environments[crmOrgId].data.dedup?.push({
        ...automation,
        status: optimisticStatus,
      });
    } else {
      if (!state.environments[crmOrgId].data.matching) {
        state.environments[crmOrgId].data.matching = [];
      }
      state.environments[crmOrgId].data.matching?.push({
        ...automation,
        status: optimisticStatus,
      });
    }
  },

  updateDedupMatching: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    const type = automationToUpdate.type;

    const updateDedup = () => {
      if (!state.environments[crmOrgId]?.data?.dedup) {
        return;
      }
      const indexToUpdate = state.environments[crmOrgId].data.dedup?.findIndex(
        (r) =>
          r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
      );
      if (indexToUpdate !== undefined) {
        // @ts-ignore
        state.environments[crmOrgId].data.dedup[indexToUpdate] = {
          ...automationToUpdate,
          status: optimisticStatus,
        };
      }
    };
    const updateMatching = () => {
      if (!state.environments[crmOrgId]?.data?.matching) {
        return;
      }
      const indexToUpdate = state.environments[crmOrgId].data.matching?.findIndex(
        (r) =>
          r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
      );
      if (indexToUpdate !== undefined) {
        // @ts-ignore
        state.environments[crmOrgId].data.matching[indexToUpdate] = {
          ...automationToUpdate,
          status: optimisticStatus,
        };
      }
    };

    if (type === AutomationType.Dedupe) {
      updateDedup();
    } else {
      updateMatching();
    }
  },
  deleteDedupMatching: (state, action) => {
    const { crmOrgId, automationIdToDelete } = action.payload;
    if (!state.environments[crmOrgId]?.data?.dedup) {
      return;
    } else {
      const indexToDelete = state.environments[crmOrgId].data.dedup?.findIndex(
        (r) => r.automationId === automationIdToDelete,
      );
      if (indexToDelete !== undefined && indexToDelete !== -1) {
        state.environments[crmOrgId].data.dedup?.splice(indexToDelete, 1);
      }
    }
    if (!state.environments[crmOrgId]?.data?.matching) {
      return;
    } else {
      const indexToDelete = state.environments[crmOrgId].data.matching?.findIndex(
        (r) => r.automationId === automationIdToDelete,
      );
      if (indexToDelete !== undefined && indexToDelete !== -1) {
        state.environments[crmOrgId].data.matching?.splice(indexToDelete, 1);
      }
    }
  },
  toggleDedupMatching: (state, action) => {
    const { crmOrgId, automationToUpdate, versionIdToUpdate, optimisticStatus } = action.payload;
    const type = automationToUpdate.type;

    const toggleDedup = () => {
      if (!state.environments[crmOrgId]?.data?.dedup) {
        return;
      }
      const indexToUpdate = state.environments[crmOrgId].data.dedup?.findIndex(
        (r) =>
          r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
      );
      if (indexToUpdate !== undefined) {
        // @ts-ignore
        state.environments[crmOrgId].data.dedup[indexToUpdate] = {
          ...automationToUpdate,
          status: optimisticStatus,
        };
      }
    };
    const toggleMatching = () => {
      if (!state.environments[crmOrgId]?.data?.matching) {
        return;
      }
      const indexToUpdate = state.environments[crmOrgId].data.matching?.findIndex(
        (r) =>
          r.automationId === automationToUpdate.automationId && r.versionId === versionIdToUpdate,
      );
      if (indexToUpdate !== undefined) {
        // @ts-ignore
        state.environments[crmOrgId].data.matching[indexToUpdate] = {
          ...automationToUpdate,
          status: optimisticStatus,
        };
      }
    };

    if (type === AutomationType.Dedupe) {
      toggleDedup();
    } else {
      toggleMatching();
    }
  },
  updateDedupMatchingDeployStatus: (state, action) => {
    const { crmOrgId, automationId, versionId, optimisticStatus } = action.payload;
    if (!state.environments[crmOrgId]?.data?.dedup) {
      return;
    } else {
      const indexToUpdate = state.environments[crmOrgId].data.dedup?.findIndex(
        (r) => r.automationId === automationId && r.versionId === versionId,
      );
      if (
        indexToUpdate !== undefined &&
        state.environments[crmOrgId]?.data?.dedup?.[indexToUpdate]
      ) {
        state.environments[crmOrgId].data.dedup[indexToUpdate].status = optimisticStatus;
      } else {
        telemetry.captureError(
          `no dedupe found in data for org ${crmOrgId} index: ${indexToUpdate}, optimisticStatus: ${optimisticStatus}`,
        );
      }
    }
    if (!state.environments[crmOrgId]?.data?.matching) {
      return;
    } else {
      const indexToUpdate = state.environments[crmOrgId].data.matching?.findIndex(
        (r) => r.automationId === automationId && r.versionId === versionId,
      );
      if (indexToUpdate !== undefined) {
        // @ts-ignore
        state.environments[crmOrgId].data.matching[indexToUpdate].status = optimisticStatus;
      }
    }
  },
};

export const selectCrmOrgMatching = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.matching;
};

export const selectMatching = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgMatching(crmOrg.id)(state);
  }
};

export const selectCrmOrgDedup = (crmOrgId: string) => (state: RootState) => {
  return state.global.environments[crmOrgId]?.data?.dedup;
};

export const selectDedup = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    return selectCrmOrgDedup(crmOrg.id)(state);
  }
};

export const selectDedupMatching = (state: RootState) => {
  const crmOrg = selectDefaultCreationEnvironment(state);
  if (crmOrg?.id) {
    const dedup = selectCrmOrgDedup(crmOrg.id)(state);
    const matching = selectCrmOrgMatching(crmOrg.id)(state);
    return [...(matching ?? []), ...(dedup ?? [])];
  }
};
