import { ReadOnlyDedupMatching } from '@server/read-only-elements.types';
import { renderActionDetails, wrapContent } from './automationsFormatter';
import { DedupMergeActionType } from '../../../routing/dedupMatching/helpers';

export const MergeActionTypeToTextMap: { [key: string]: string } = {
  [DedupMergeActionType.OVERRIDE_NOTHING]: 'Match and merge manually',
  [DedupMergeActionType.OVERRIDE_NEW_VALUES]: 'Enrich existing and merge',
  [DedupMergeActionType.OVERRIDE_ALL]: 'Override existing and merge',
};

export const formatDedupMatchingDiff = (
  readVersion: ReadOnlyDedupMatching,
  type: 'Dedupe' | 'Matching' = 'Dedupe',
): string => {
  const term = type === 'Dedupe' ? 'duplicate' : 'match';
  let diffTemplate = `
<div class="diff-section-title"><img src="/icons/diff/name.svg" /> Name</div>
${wrapContent(readVersion.name)}
<div class="diff-section-title"><img src="/icons/diff/condition.svg" /> Status</div>
${wrapContent(readVersion.isActive ? 'Active' : 'Inactive')}
<div class="diff-section-title"><img src="/icons/diff/trigger.svg" /> When a ${term} is found</div>
${typeof readVersion.trigger === 'string' ? wrapContent(MergeActionTypeToTextMap[readVersion.trigger as string]) : renderActionDetails(readVersion.trigger).trimEnd()}
`;

  if (readVersion.notification) {
    diffTemplate += `<div class="diff-section-title"><img src="/icons/diff/notification.svg" /> Send notification</div>`;
    diffTemplate += readVersion.notification.details
      .map((section) => renderActionDetails(section))
      .join('');
  }

  diffTemplate += `<div class="diff-section-title"><img src="/icons/diff/settings.svg" /> Settings</div>`;
  diffTemplate += readVersion.settings.map((section) => renderActionDetails(section)).join('');

  return diffTemplate;
};
