import { Box } from '@mui/material';
import { AutomationsDeployButton } from '../deploy-button/AutomationDeployButton';

interface CreateEditDialogDeployButtonsProps {
  onDeployOrSave: (
    crmOrgIds: string[],
  ) => Promise<{ versionId?: string; automationId?: string; isError?: string }>;
  confirmButtonDisabled?: boolean;
  disableDiff?: boolean;
  disableRequestToDeployDialog?: boolean;
}

export const CreateEditDialogDeployButtons = ({
  confirmButtonDisabled,
  onDeployOrSave,
  disableDiff,
  disableRequestToDeployDialog,
}: CreateEditDialogDeployButtonsProps) => {
  return (
    <Box display="flex" gap={1}>
      <AutomationsDeployButton
        onDeployOrSave={onDeployOrSave}
        disabled={confirmButtonDisabled}
        isWithPaywall
        disableDiff={disableDiff}
        disableRequestToDeployDialog={disableRequestToDeployDialog}
      />
    </Box>
  );
};
