import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Report } from '../types/Reports';

export interface ReportsState {
  reportsList: Report[];
  isLoading: boolean;
}

const initialState: ReportsState = {
  reportsList: [],
  isLoading: true,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReportsList: (state, action: PayloadAction<{ reports: Report[] }>) => {
      state.isLoading = false;
      state.reportsList = action.payload.reports;
    },
    updateReport: (state, action: PayloadAction<{ newReport: Report }>) => {
      const { newReport } = action.payload;
      state.reportsList = state.reportsList.map((report) =>
        report.id === newReport.id ? { ...report, ...newReport } : report,
      );
    },
  },
});

export const selectReports = (state: RootState) => state.reports.reportsList;
export const isReportsLoading = (state: RootState) => state.reports.isLoading;

export const { setReportsList, updateReport } = reportsSlice.actions;
export default reportsSlice.reducer;
