import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../../reducers';
import type { ShortTemplateInterface, TemplateInterface } from '../templatesTypes';

export interface TemplatesDialogState {
  shortTemplates: ShortTemplateInterface[];
  longTemplates: {
    [id: string]: TemplateInterface;
  };
}

const initialState: TemplatesDialogState = {
  shortTemplates: [],
  longTemplates: {},
};

export const templatesDialogSlice = createSlice({
  name: 'templatesDialog',
  initialState,
  reducers: {
    setShortTemplates: (state, action: PayloadAction<ShortTemplateInterface[]>) => {
      state.shortTemplates = action.payload;
    },
    setLongTemplate: (state, action: PayloadAction<TemplateInterface>) => {
      state.longTemplates[action.payload.id] = action.payload;
    },
  },
});

export const selectShortTemplates = (state: RootState) =>
  state[templatesDialogSlice.name].shortTemplates;

export const selectLongTemplate = (id: string) => (state: RootState) =>
  state[templatesDialogSlice.name].longTemplates[id];

export const { setShortTemplates, setLongTemplate } = templatesDialogSlice.actions;

export default templatesDialogSlice.reducer;
