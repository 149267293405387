import isEqual from 'lodash/isEqual';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import useUserInfo from '../../../hooks/useUserInfo';
import { selectUserInfoData } from '../../../reducers/userInfoReducer';
import { ConfirmDialog } from '../../common/dialogs/ConfirmDialog';
import { IntegrationsTab } from '../user-menu/IntegrationsTab';
import { OrgInfoTab } from '../user-menu/OrgInfoTab';
import { UserProfileTab } from '../user-menu/UserProfileTab';
import { TabList } from '@sweep-io/sweep-design';
import { useUserDialogContext } from './UserDialogContext';
import { RoleManagerContent } from '../role-manager/RoleManagerContent';
import { RoleManagerHeader } from '../role-manager/RoleManagerHeader';
import { useRoleManagerContext } from '../role-manager/RoleManagerContext';
import { useWebhooksContext } from '../webhooks/WebhooksContext';
import { WebhooksHeader } from '../webhooks/WebhooksHeader';
import { WebhookContent } from '../webhooks/WebhookContent';
import useConfirm from '../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';

export enum UserDialogTabTypes {
  PROFILE = 'My profile',
  ORG = 'Workspace',
  INTEGRATIONS = 'Integrations',
}

export interface UserProfileTabProps {
  PaperProps: any;
}

const UserDialogWrapper = ({ PaperProps }: UserProfileTabProps) => {
  const user = useSelector(selectUserInfoData);
  const [currentUser, setCurrentUser] = useState(user);
  const isValid = Boolean(currentUser?.name);
  const { openConfirm } = useConfirm();

  const { chosenTab, setChosenTab, closeDialog, isOpen } = useUserDialogContext();
  const { isRoleManagerOpen } = useRoleManagerContext();
  const { updateUserInfo } = useUserInfo();
  const { isWebhooksOpen } = useWebhooksContext();

  const handleClose = async () => {
    if (!isEqual(user, currentUser) && chosenTab === UserDialogTabTypes.PROFILE) {
      const isConfirmed = await openConfirm(
        <ConfirmLeaveWithoutSaveDialog title="You have unsaved changes" />,
      );
      if (!isConfirmed) return;
    }
    closeDialog();
  };

  const tabs: Parameters<typeof TabList>['0']['list'] = [
    {
      label: UserDialogTabTypes.PROFILE,
      content: (
        <>
          {user && currentUser && (
            <UserProfileTab
              user={user}
              currentUser={currentUser}
              setCurrentUser={setCurrentUser}
              isValid={isValid}
            />
          )}
        </>
      ),
      value: UserDialogTabTypes.PROFILE,
    },
    {
      label: UserDialogTabTypes.ORG,
      content: <OrgInfoTab accountName={user?.account?.name} userRoleGroupId={user?.roleGroupId} />,
      value: UserDialogTabTypes.ORG,
    },
    {
      label: UserDialogTabTypes.INTEGRATIONS,
      content: <IntegrationsTab />,
      value: UserDialogTabTypes.INTEGRATIONS,
    },
  ];

  return (
    <ConfirmDialog
      isOpen={isOpen}
      handleClose={() => handleClose()}
      onConfirm={async () => {
        currentUser && (await updateUserInfo(currentUser));
        closeDialog();
      }}
      PaperProps={{
        ...PaperProps,
        sx: { ...PaperProps.sx, width: isRoleManagerOpen ? '600px' : PaperProps.sx.width },
      }}
      disableOnBackdrop
      disableKeyBindings={chosenTab !== UserDialogTabTypes.PROFILE}
      enableKeyListener
      showCloseButton
      dialogActionsSx={{
        boxShadow: 1,
        display:
          chosenTab === UserDialogTabTypes.ORG || chosenTab === UserDialogTabTypes.INTEGRATIONS
            ? 'none'
            : 'flex', //on product request
      }}
      dialogContentSx={{
        paddingTop: 0,
        '& .MuiTabs-root': {
          position: 'absolute',
          top: '32px',
        },

        '& h2': {
          height: '32px',
          display: 'flex',
          alignItems: 'center',
        },
      }}
      disableConfirmButton={!isValid}
      titleComponent={
        isRoleManagerOpen ? <RoleManagerHeader /> : isWebhooksOpen ? <WebhooksHeader /> : undefined
      }
    >
      {isRoleManagerOpen && <RoleManagerContent />}
      {isWebhooksOpen && <WebhookContent />}
      {!isRoleManagerOpen && !isWebhooksOpen && (
        <TabList
          onChange={(event, newValue) => setChosenTab(newValue)}
          defaultValue={chosenTab}
          list={tabs}
        />
      )}
    </ConfirmDialog>
  );
};

export { UserDialogWrapper };
