import { Box } from '@mui/material';
import { useState } from 'react';
import LabelChip from '../../../../common/LabelChip';
import { Button, colors } from '@sweep-io/sweep-design';
import { FunnelFlowModes } from '../../../../../types/enums/FunnelFlowModes';
import { humanizeDate } from '../../../../helpers/humanizeDate';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import { RestoreDialog } from '../../../funnel-map-flow/dialogs/restore/RestoreDialog';
import { useFunnelMapReadOnly } from '../../../funnel-map-flow/read-only/useFunnelMapReadOnly';
import { usePageHelpers } from '../usePageHelpers';

export interface ReadOnlyCanvasUnitedRightMenuProps {
  funnelMap: FunnelMap;
  lastUpdatedAt?: string;
  funnelFlowMode: FunnelFlowModes;
  funnelMapEmpty?: boolean;
}

export const ReadOnlyCanvasUnitedRightMenu = ({
  lastUpdatedAt,
  funnelFlowMode,
}: ReadOnlyCanvasUnitedRightMenuProps) => {
  const {
    onViewLatestVersionCallback,
    onRestoreCallback,
    restoreFunnelName,
    versionName,
    funnelName,
    deployedAt,
    environmentName,
  } = useFunnelMapReadOnly();
  const { enableRestore, crmOrgId, funnelId, snapshotId } = usePageHelpers();

  const [isRestorePopupOpen, setIsRestorePopupOpen] = useState(false);

  const readonly = funnelFlowMode !== FunnelFlowModes.EDIT;
  const showViewLatestButton = readonly && !Boolean(enableRestore);
  const notDeployed = funnelFlowMode === FunnelFlowModes.NOT_DEPLOYED;

  const envLabelChip = <LabelChip bgColor={colors.grey[300]}>{environmentName}</LabelChip>;

  const environmentInfoJsx = notDeployed ? (
    envLabelChip
  ) : (
    <>
      {deployedAt && (
        <Box display="flex" alignItems="center" gap={1}>
          Deployed to {envLabelChip}{' '}
          {humanizeDate({
            dateOrTimestamp: deployedAt.toString(),
          })}
        </Box>
      )}
    </>
  );

  const menuContentReadonly = (
    <>
      {environmentInfoJsx}

      {enableRestore && (
        <RestrictedTooltip
          to={['edit:funnels']}
          notAllowedTitle="To restore this funnel, please contact your admin."
          component={(isAllowed) => (
            <Button size="small" onClick={() => setIsRestorePopupOpen(true)} disabled={!isAllowed}>
              Restore
            </Button>
          )}
        />
      )}
      {showViewLatestButton && (
        <Button
          size="small"
          onClick={() =>
            funnelId &&
            funnelName &&
            crmOrgId &&
            onViewLatestVersionCallback({ funnelId, funnelName, crmOrgId })
          }
        >
          View latest version
        </Button>
      )}
    </>
  );

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '12px',
        }}
        data-testid="funnel-flow-right-menu"
      >
        {menuContentReadonly}
      </Box>

      {enableRestore && isRestorePopupOpen && lastUpdatedAt && (
        <RestoreDialog
          funnelName={restoreFunnelName}
          onConfirm={() =>
            funnelId &&
            funnelName &&
            snapshotId &&
            onRestoreCallback({ funnelId, funnelName, snapshotId })
          }
          closeDialog={() => setIsRestorePopupOpen(false)}
          versionName={versionName}
          versionDate={lastUpdatedAt}
        />
      )}
    </>
  );
};
