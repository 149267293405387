import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { Box, SxProps, Theme } from '@mui/material';
import { NodeProps } from 'reactflow';
import { GroupNodeData } from '../canvasTypes/nodeTypesData';
import { HALF_NODE_GAP, NODE_WIDTH } from '../const';
import { CanvasMode, NodeEntityTypes } from '../canvasTypes';
import { getObjectTypeColor, objectTypeColorNames } from '../helpers/getObjectTypeColor';
import { canvasModeToClass, CONTEXT_ZOOM_LEVEL_2_CLASS_NAME } from './contextZoomConsts';
import { IconButton } from '@sweep-io/sweep-design';
import { MoreActionsVertical } from '@sweep-io/sweep-design/dist/icons';

const StyledGroupNode = styled(Box)({
  position: 'relative',
  borderRadius: 8,
});

const getSweepNodeBackgroundColors = (bgOpacity: string) =>
  objectTypeColorNames.reduce((acc, objectType) => {
    const color = getObjectTypeColor(objectType).connection + bgOpacity;
    return {
      ...acc,
      [`.sweep-group-node.object-type-${objectType}`]: {
        backgroundColor: color,
      },
    };
  }, {});

export const sweepNodeCanvasSx: SxProps<Theme> = {
  [`&:not(.${canvasModeToClass[CanvasMode.PREVIEW2]})`]: {
    '.sweep-group-node': {
      backgroundColor: getObjectTypeColor().connection + '0D',
    },
    ...getSweepNodeBackgroundColors('0D'),
  },
  [`&.${CONTEXT_ZOOM_LEVEL_2_CLASS_NAME}`]: {
    '.sweep-group-node': {
      backgroundColor: getObjectTypeColor().connection + '1A',
    },
    ...getSweepNodeBackgroundColors('1A'),
  },
};

const GroupNode = ({
  data: {
    hideGroupInfo,
    objectType,
    reactFlowPositions,
    onClick,
    isGhost,
    showEditButton,
    groupId,
    
  },
}: NodeProps<GroupNodeData>) => {
  if (hideGroupInfo) {
    return <></>;
  }

  const { height, marginLeft, marginTop, width } = reactFlowPositions;
  const _width = isGhost ? width + HALF_NODE_GAP + NODE_WIDTH / 2 : width;

  return (
    <StyledGroupNode
      onClick={(event) =>
        onClick?.({ event, entity: { type: NodeEntityTypes.Group }, id: groupId })
      }
      className={`sweep-group-node object-type-${objectType}`}
      sx={{
        height: height + 'px',
        marginLeft: marginLeft + 'px',
        marginTop: marginTop + 'px',
        width: _width + 'px',
        cursor: 'move',
        position: 'relative',
        '.edit-group-button': {
          opacity: 0,
        },
        '&:hover': {
          '.edit-group-button': {
            opacity: 1,
          },
        },
      }}
    >
      {showEditButton && (
        <Box position="absolute" right={20} top={12} className="edit-group-button">
          <IconButton
            variant="outlined"
            size="large"
            onClick={(event) => {
              onClick?.({ event, entity: { type: NodeEntityTypes.GroupEditButton }, id: groupId });
            }}
          >
            <MoreActionsVertical />
          </IconButton>
        </Box>
      )}
    </StyledGroupNode>
  );
};

const _memoGroupNode = memo(GroupNode);

export { _memoGroupNode as GroupNode };
