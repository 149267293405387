import { ReactNode, useState } from 'react';
import React from 'react';
import _noop from 'lodash/noop';

export interface RoutingContextType {
  initialized: boolean;
  membersSearch: string;
  setMembersSearch: (v: string) => void;
  groupsSearch: string;
  setGroupsSearch: (v: string) => void;
  territoriesSearch: string;
  setTerritoriesSearch: (v: string) => void;
  selectedRoleItems: string[];
  setSelectedRoleItems: (v: string[]) => void;
  selectedProfileItems: string[];
  setSelectedProfileItems: (v: string[]) => void;
  selectedGroupId: string;
  setSelectedGroupId: (v: string) => void;
  selectedGroupSearch: string;
  setSelectedGroupSearch: (v: string) => void;
}

const RoutingContext = React.createContext<RoutingContextType>({
  initialized: false,
  membersSearch: '',
  setMembersSearch: _noop,
  groupsSearch: '',
  setGroupsSearch: _noop,
  territoriesSearch: '',
  setTerritoriesSearch: _noop,
  selectedRoleItems: [],
  setSelectedRoleItems: _noop,
  selectedProfileItems: [],
  setSelectedProfileItems: _noop,
  selectedGroupId: '',
  setSelectedGroupId: _noop,
  selectedGroupSearch: '',
  setSelectedGroupSearch: _noop,
});

export const useRoutingContext = () => {
  const context = React.useContext(RoutingContext);
  if (!context.initialized) {
    throw new Error('useRoutingContext must be used within a RoutingContext');
  }
  return context;
};

export const RoutingContextProvider = ({ children }: { children: ReactNode }) => {
  const [membersSearch, setMembersSearch] = useState('');
  const [groupsSearch, setGroupsSearch] = useState('');
  const [territoriesSearch, setTerritoriesSearch] = useState('');
  const [selectedRoleItems, setSelectedRoleItems] = useState<string[]>([]);
  const [selectedProfileItems, setSelectedProfileItems] = useState<string[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState('');
  const [selectedGroupSearch, setSelectedGroupSearch] = useState('');

  return (
    <RoutingContext.Provider
      value={{
        initialized: true,
        membersSearch,
        setMembersSearch,
        groupsSearch,
        setGroupsSearch,
        territoriesSearch,
        setTerritoriesSearch,
        selectedRoleItems,
        setSelectedRoleItems,
        selectedProfileItems,
        setSelectedProfileItems,
        selectedGroupId,
        setSelectedGroupId,
        selectedGroupSearch,
        setSelectedGroupSearch,
      }}
    >
      {children}
    </RoutingContext.Provider>
  );
};
