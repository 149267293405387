import { keyBy } from 'lodash';
import { RootState } from '../reducers';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GlobalDto } from '../reducers/global/globalReducerTypes';
import { FunnelMapWithFields, FunnelWithMaybeFields } from './types';

interface SweepFieldsLabelInfo {
  sweepFieldName?: string;
  objectName?: string;
  sfFieldName?: string;
}

export type SweepFieldsLabelInfoMap = { [id: string]: SweepFieldsLabelInfo };

export interface SweepFieldsReducerState {
  sweepFieldsLabels: { [id: string]: SweepFieldsLabelInfo };
}

const initialState: SweepFieldsReducerState = {
  sweepFieldsLabels: {},
};

const extractLabelFromSweepField = (sweepFieldPick: SweepFieldSummary): SweepFieldsLabelInfo => ({
  sweepFieldName: sweepFieldPick.sweepFieldName,
  objectName: sweepFieldPick.objectName,
  sfFieldName: sweepFieldPick.sfFieldName,
});

const extractLabelFromSweepFields = (
  sweepFieldsMap: SweepFieldsSummaryMap,
): SweepFieldsLabelInfoMap =>
  Object.keys(sweepFieldsMap).reduce<SweepFieldsLabelInfoMap>((acc, id) => {
    acc[id] = extractLabelFromSweepField(sweepFieldsMap[id]);
    return acc;
  }, {});

export const sweepFieldsLabelsSlice = createSlice({
  name: 'sweepFieldsLabels',
  initialState,
  reducers: {
    assignFieldLabelsFromFunnelMap: (
      state,
      action: PayloadAction<{ funnelMap: FunnelMapWithFields }>,
    ) => {
      const { funnelsData } = action.payload.funnelMap;
      if (!funnelsData) {
        return;
      }

      Object.values(funnelsData).forEach((funnelData) => {
        if (funnelData.sweepFields) {
          Object.assign(
            state.sweepFieldsLabels,
            extractLabelFromSweepFields(funnelData.sweepFields),
          );
        }
      });
    },
    assignFieldLabelsFromGlobalDTO: (state, action: PayloadAction<{ globalDto: GlobalDto }>) => {
      Object.assign(
        state.sweepFieldsLabels,
        extractLabelFromSweepFields(action.payload.globalDto.sweepFields),
      );
    },
    assignFieldLabelsFromFunnelMaps: (
      state,
      action: PayloadAction<{ funnelMaps: FunnelMapWithFields[] }>,
    ) => {
      const { funnelMaps } = action.payload;

      Object.values(funnelMaps).forEach(({ funnelsData }) => {
        if (funnelsData) {
          Object.values(funnelsData).forEach((funnelData) => {
            if (funnelData.sweepFields) {
              Object.assign(
                state.sweepFieldsLabels,
                extractLabelFromSweepFields(funnelData.sweepFields),
              );
            }
          });
        }
      });
    },
    assignFieldLabelsFromFunnel: (
      state,
      action: PayloadAction<{ funnel: FunnelWithMaybeFields }>,
    ) => {
      const { sweepFields } = action.payload.funnel;
      Object.assign(state.sweepFieldsLabels, sweepFields);
    },
    assignFieldLabelsFromFunnels: (
      state,
      action: PayloadAction<{ funnels: FunnelWithMaybeFields[] }>,
    ) => {
      const { funnels } = action.payload;
      Object.values(funnels).forEach(({ sweepFields }) => {
        if (sweepFields) {
          Object.assign(state.sweepFieldsLabels, extractLabelFromSweepFields(sweepFields));
        }
      });
    },
    assignFieldsLabelsFromSweepFields: (
      state,
      action: PayloadAction<{ sweepFields: SweepField[] }>,
    ) => {
      const { sweepFields } = action.payload;
      const sweepFieldById = keyBy(sweepFields, 'id');

      Object.assign(state.sweepFieldsLabels, extractLabelFromSweepFields(sweepFieldById));
    },
    assignFieldLabelsFromDedupMatchingList: (
      state,
      action: PayloadAction<{ dedupMatchingList: DedupMatching[] }>,
    ) => {
      const { dedupMatchingList } = action.payload;
      dedupMatchingList.forEach((dedupMatching) => {
        if (dedupMatching.sweepFields) {
          Object.assign(
            state.sweepFieldsLabels,
            extractLabelFromSweepFields(dedupMatching.sweepFields),
          );
        }
      });
    },
    assignFieldLabels: (
      state,
      action: PayloadAction<{ id: string; labelInfo: SweepFieldsLabelInfo }[]>,
    ) => {
      const { payload } = action;
      payload.forEach(({ id, labelInfo }) => {
        state.sweepFieldsLabels[id] = labelInfo;
      });
    },
    assignFieldLabelsFromSummary: (
      state,
      action: PayloadAction<{ sweepFields: SweepFieldsSummaryMap }>,
    ) => {
      const { sweepFields } = action.payload;
      Object.assign(state.sweepFieldsLabels, extractLabelFromSweepFields(sweepFields));
    },
  },
});

export const {
  assignFieldLabelsFromFunnelMap,
  assignFieldLabelsFromFunnelMaps,
  assignFieldLabelsFromFunnel,
  assignFieldLabelsFromFunnels,
  assignFieldsLabelsFromSweepFields,
  assignFieldLabelsFromDedupMatchingList,
  assignFieldLabels,
  assignFieldLabelsFromGlobalDTO,
  assignFieldLabelsFromSummary,
} = sweepFieldsLabelsSlice.actions;

export const selectSweepFieldLabels = (state: RootState) =>
  state[sweepFieldsLabelsSlice.name].sweepFieldsLabels;

export default sweepFieldsLabelsSlice.reducer;
