import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';

export interface SnapshotsState {
  snapshotsList: FunnelSnapshot[];
  isLoading: boolean;
}

const initialState: SnapshotsState = {
  snapshotsList: [],
  isLoading: true,
};

type SnapshotsActionPayload = {
  snapshotsList?: FunnelSnapshot[];
};

export const snapshotsSlice = createSlice({
  name: 'snapshots',
  initialState,
  reducers: {
    setSnapshotsList: (state, action: PayloadAction<SnapshotsActionPayload>) => {
      if (action.payload.snapshotsList) {
        state.snapshotsList = action.payload.snapshotsList;
      }
      state.isLoading = false;
    },
    setIsLoadingSnapshots: (state) => {
      state.snapshotsList = [];
      state.isLoading = true;
    },
  },
});

export const selectSnapshotsList = (state: RootState) => state.snapshots.snapshotsList;

export const { setSnapshotsList, setIsLoadingSnapshots } = snapshotsSlice.actions;

export default snapshotsSlice.reducer;
