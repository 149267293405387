import React, { useCallback, useContext } from 'react';
import { ConfigurationCanvasContext } from '../ConfigurationCanvasContext';
import { useConfigurationCanvasState } from '../useConfigurationCanvasState';
import { useCrmOrgsApiFacade } from '../../../../apis/facades/useCrmOrgsApiFacade';
import { useErrorHandling } from '../../../../hooks/useErrorHandling';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { RecordTypeLeadingObjectCtx } from '../canvas-container/record-type-leading-object-panel/RecordTypeLeadingObjectCtx';

// Public API for the pages
export const useConfigurationCanvas = () => {
  const context = React.useContext(ConfigurationCanvasContext);
  const { appFunnelMap, canvasFunnelMap } = useConfigurationCanvasState();
  const { addRecordTypeDataEntries } = useContext(RecordTypeLeadingObjectCtx);
  const { appCrmOrgId, canvasCrmOrgId } = context;

  const { get_objectRecordTypes } = useCrmOrgsApiFacade();
  const { errorHandlingBuilder } = useErrorHandling();
  const { addNotification } = useSweepNotifications();

  if (!context) {
    throw new Error('useConfigurationCanvas must be used within a ConfigurationCanvasProvider');
  }
  if (!appFunnelMap || !canvasFunnelMap) {
    throw new Error(
      'appFunnelMap and canvasFunnelMap must be provided. Please do not use this hook outside of a page',
    );
  }

  const addObject = useCallback(
    async (objectName: string) => {
      await errorHandlingBuilder()
        .withErrorNotification(`Error getting object type data for ${objectName}`)
        .execute(async () => {
          const recordTypes = await get_objectRecordTypes({
            crmOrgId: canvasCrmOrgId,
            objectApiName: objectName,
          });
          const singleRecordTypes: SingleRecordType[] = recordTypes.map((recordType) => ({
            ...recordType,
            objectName,
          }));
          if (!singleRecordTypes.length) {
            addNotification({
              message: `No record types found for ${objectName}`,
              variant: SweepNotificationVariant.Error,
              keepOpen: true,
            });
            throw new Error(`No record types found for ${objectName}`);
          }
          addRecordTypeDataEntries({ recordTypes: singleRecordTypes, crmOrgId: canvasCrmOrgId });
        });
    },
    [
      addNotification,
      addRecordTypeDataEntries,
      canvasCrmOrgId,
      errorHandlingBuilder,
      get_objectRecordTypes,
    ],
  );

  // If the mode is funnelMap, pass the draft funnel map to the pages
  // Otherwise, pass the environment funnel map

  return {
    appCrmOrgId,
    canvasCrmOrgId,
    appFunnelMap,
    canvasFunnelMap,
    addObject,
  };
};
