import React, { ReactNode, Suspense } from 'react';
import { CenteredCircularProgress } from './components/common/CenteredCircularProgress';

function lazyLoader(lazyImport: any) {
  const LazyPage = React.lazy(lazyImport);

  return (): ReactNode => (
    <Suspense fallback={<CenteredCircularProgress />}>
      <LazyPage />
    </Suspense>
  );
}

export { lazyLoader };
