import { Box, TextField } from '@mui/material';
import { useWebhooksContext } from './WebhooksContext';
import { CustomIntegrationAuthenticationType } from './WebhookContent';
import { colors, Typography } from '@sweep-io/sweep-design';
import { FormSection } from './FormSection';
import { User } from '@sweep-io/sweep-design/dist/icons';

export const AuthenticationComponents = () => {
  const { editWebhook, setEditWebhook } = useWebhooksContext();
  const webhookType = editWebhook?.authentication?.type;
  const isBlockSection =
    webhookType === CustomIntegrationAuthenticationType.BASIC ||
    webhookType === CustomIntegrationAuthenticationType.API_KEY;

  return (
    <Box>
      {webhookType && isBlockSection && (
        <Box
          sx={{
            backgroundColor: colors.grey[100],
            border: `1px solid ${colors.grey[200]}`,
            borderRadius: 1,
            p: 2,
          }}
        >
          <FormSection label="User name">
            <TextField
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: <User />,
              }}
              placeholder={'User name'}
              value={editWebhook?.authentication?.key}
              onChange={(event) => {
                const newValue = event?.target.value;
                setEditWebhook({
                  ...editWebhook,
                  authentication: { ...editWebhook.authentication, key: newValue } as any,
                });
              }}
            />
          </FormSection>
          <FormSection label="Password">
            <TextField
              sx={{ width: '100%' }}
              InputProps={{
                startAdornment: <User />,
              }}
              placeholder={'Password'}
              value={editWebhook?.authentication?.password}
              onChange={(event) => {
                const newValue = event?.target.value;
                setEditWebhook({
                  ...editWebhook,
                  authentication: { ...editWebhook.authentication, password: newValue } as any,
                });
              }}
            />
          </FormSection>
        </Box>
      )}
      {webhookType && !isBlockSection && (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mb: 1 }}>
          <Typography variant="body" textAlign="center">
            {webhookType === CustomIntegrationAuthenticationType.BEARER_TOKEN ? 'Bearer' : 'Token'}
          </Typography>
          <TextField
            sx={{ width: '100%' }}
            InputProps={{
              startAdornment: <User />,
            }}
            placeholder={
              webhookType === CustomIntegrationAuthenticationType.BEARER_TOKEN
                ? 'Bearer token'
                : 'Token'
            }
            value={editWebhook?.authentication?.key}
            onChange={(event) => {
              const newValue = event?.target.value;
              setEditWebhook({
                ...editWebhook,
                authentication: { ...editWebhook.authentication, key: newValue } as any,
              });
            }}
          />
        </Box>
      )}
    </Box>
  );
};
