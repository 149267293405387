import { useCallback } from 'react';
import { useLocation, useParams } from 'react-router';
import useSweepNavigate from '../../../common/useSweepNavigate';
import { ConfigurationCanvasPagesRoutes } from '../../../../constants/appRoutes';

const UUID_MATCH = /[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i;

/**
 * Custom hook that provides a function to navigate to a specific environment if appropriate.
 * It checks if the current route is a configuration canvas route and if so, it replaces the UUID in the path with the given `envId`.
 * The updated path is then navigated to using the `navigate` function from `useSweepNavigate`.
 */
export const useNavigateToEnv = () => {
  const { pathname, hash, search } = useLocation();
  const { crmOrgId } = useParams<{ crmOrgId: string }>();
  const { navigate } = useSweepNavigate();

  const navigateToEnvIfAppropriate = useCallback(
    (envId: string) => {
      if (!envId || !crmOrgId) return;
      if (envId === crmOrgId) return;

      const configurationCanvasPagesRoutes = ConfigurationCanvasPagesRoutes;

      const isConfigurationCanvasRoute = configurationCanvasPagesRoutes.some((route) =>
        pathname.startsWith(`${route}/`),
      );
      if (isConfigurationCanvasRoute) {
        let _newPath = pathname.replace(UUID_MATCH, envId);
        _newPath = `${_newPath}${search}${hash}`;

        navigate(_newPath);
      }
    },
    [crmOrgId, hash, navigate, pathname, search],
  );

  return { navigateToEnvIfAppropriate };
};
