import { ReactNode, createContext, useCallback, useRef, useState } from 'react';
import { ConfirmDialog } from '../../../common/dialogs/ConfirmDialog';
import {
  DEFAULT_TEXT,
  DEFAULT_TITLE,
} from '../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import noop from 'lodash/noop';

export const UnsavedPromptContext = createContext({
  setOnLeaveFunction: noop,
  setOnCancelFunction: noop,
  displayPrompt: noop,
});

const UnsavedPromptProvider = ({ children }: { children: ReactNode }) => {
  const onLeaveFunc = useRef<() => void>();
  const onCancelFunc = useRef<() => void>();
  const [displaySaveAlert, setDisplaySaveAlert] = useState(false);

  const displayPrompt = useCallback(() => setDisplaySaveAlert(true), []);

  const setOnLeaveFunction = useCallback((cb: any) => {
    onLeaveFunc.current = cb;
  }, []);

  const setOnCancelFunction = useCallback((cb: any) => {
    onCancelFunc.current = cb;
  }, []);

  const onLeave = useCallback(() => {
    if (onLeaveFunc.current) {
      onLeaveFunc.current();
    }
    setDisplaySaveAlert(false);
  }, []);

  const onCancel = useCallback(() => {
    if (onCancelFunc.current) {
      onCancelFunc.current();
    }
    setDisplaySaveAlert(false);
  }, []);

  return (
    <UnsavedPromptContext.Provider
      value={{ setOnLeaveFunction, setOnCancelFunction, displayPrompt }}
    >
      {children}

      <ConfirmDialog
        isOpen={displaySaveAlert}
        title={DEFAULT_TITLE}
        onConfirm={onLeave}
        onCancel={onCancel}
        confirmText="Leave"
        cancelText="Cancel"
        confirmButtonType="error"
        handleClose={() => setDisplaySaveAlert(false)}
        children={DEFAULT_TEXT}
      />
    </UnsavedPromptContext.Provider>
  );
};

export { UnsavedPromptProvider as UnsavedPromptProvider };
