import { Box } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { WizardProps } from './useWizard';
import { ReactNode, useState } from 'react';

interface WizardFooterProps extends WizardProps {
  isNextDisabled?: boolean; //to be used in case the form is not valid
  nextText?: string;
  lastNextText?: string;
  onNext?: () => Promise<boolean> | boolean;
  submitButton?: ReactNode;
}

const WizardFooter = ({
  nextText,
  isNextDisabled,
  onNext,
  submitButton,
  lastNextText,
  ...wizardProps
}: WizardFooterProps) => {
  const { goNext, goBack, isFirstStep, isLastStep } = wizardProps;
  const [isSubmitting, setIsSubmitting] = useState(false);
  return (
    <Box display="flex" justifyContent="space-between">
      <Box sx={{ visibility: isFirstStep ? 'hidden' : 'visible' }}>
        <Button variant="outlined" onClick={goBack} startIconName="ChevronLeft">
          Back
        </Button>
      </Box>
      {!submitButton && (
        <Button
          onClick={async () => {
            setIsSubmitting(true);
            const shouldContinue = onNext && (await onNext());
            setIsSubmitting(false);
            if (shouldContinue) {
              goNext();
            }
          }}
          loading={isSubmitting}
          disabled={isNextDisabled}
          endIconName={isLastStep ? '' : 'ChevronRight'}
        >
          {isLastStep ? lastNextText || 'Submit' : nextText || 'Next'}
        </Button>
      )}
      {!!submitButton && submitButton}
    </Box>
  );
};

export default WizardFooter;
