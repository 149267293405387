import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSweepApi } from '../apis/sweep';
import { useSweepNotifications } from '../components/notifications/useSweepNotifications';
import { reportsData } from '../components/pages/reports/data/reports';
import { ReportingStatus } from '../components/pages/reports/ReportActivationStatuses';
import { SweepNotificationVariant } from '../reducers/notificationsReducer';
import { setReportsList, updateReport } from '../reducers/reportsReducer';
import { Report } from '../types/Reports';
import { telemetry } from '../telemetry';
import {
  SfdcReportAttributes,
  SfdcReportColumnsAttributes,
} from '../components/Automations/SalesforceReportSelector';
import { isCustomObjectByName } from '../components/documentation/cpq/utils';

enum ActionType {
  ACTIVATION = 'activation',
  DEACTIVATION = 'deactivation',
}

const useReports = () => {
  const dispatch = useDispatch();
  const sweepApi = useSweepApi();
  const { addNotification } = useSweepNotifications();

  const getReportsDetails = async () => {
    try {
      const response = await sweepApi.get('/reporting');
      const mergedReportData = response?.data.map((report: Report) => {
        const localReport = reportsData.find((localReport) => localReport.type === report.type);
        return { ...report, ...localReport };
      });

      dispatch(setReportsList({ reports: mergedReportData }));
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const activateReport = async (report: Report) => {
    try {
      dispatch(updateReport({ newReport: { ...report, status: ReportingStatus.PENDING } })); //update status without waiting for response
      const response = await sweepApi.patch(`/reporting/activate`, report);
      dispatch(updateReport({ newReport: response.data }));
    } catch (e) {
      telemetry.captureError(e);
      displayNotification('error', ActionType.ACTIVATION, report);
      dispatch(updateReport({ newReport: { ...report, status: ReportingStatus.INACTIVE } }));
    }
  };

  const displayNotification = useCallback(
    (type: string, actionType: ActionType, report: Report) => {
      let message;
      const isError = type === 'error';
      const variant = isError ? SweepNotificationVariant.Error : SweepNotificationVariant.Default;

      switch (actionType) {
        case ActionType.ACTIVATION: //Only if something goes wrong outside websocket
          message = isError ? `Activation failed` : '';
          break;

        case ActionType.DEACTIVATION:
          message = isError ? `Deactivation failed` : `“${report.name}” is deactivated now`;
          break;
      }

      message &&
        addNotification({
          message,
          variant,
        });
    },
    [addNotification],
  );

  const deactivateReport = async (report: Report) => {
    try {
      const response = (await sweepApi.post(`/reporting/${report.id}/deactivate`)) as any;
      dispatch(updateReport({ newReport: response.data }));
      displayNotification('success', ActionType.DEACTIVATION, report);
    } catch (e) {
      telemetry.captureError(e);
      displayNotification('error', ActionType.DEACTIVATION, report);
    }
  };

  const getSfdcTabularReports = async () => {
    try {
      const response = await sweepApi.get(`/reporting/reports/tabular`);
      return response.data as SfdcReportAttributes[];
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  const getSfdcReportColumnsIds = async (reportId: string) => {
    try {
      const response = await sweepApi.get(`/reporting/reports/${reportId}/columns`);
      const types = response.data as SfdcReportColumnsAttributes[];
      const idTypes = types.filter((el: SfdcReportColumnsAttributes) => {
        // for standard fields: only when dataType = id, for custom fields (__c) - only when isLookup = true
        const isCustom = isCustomObjectByName(el?.fieldApiName ?? '');
        if (isCustom) {
          return el.isLookup;
        } else {
          return el.dataType === 'id';
        }
      });
      return idTypes;
    } catch (e) {
      telemetry.captureError(e);
    }
  };

  return {
    getReportsDetails,
    deactivateReport,
    activateReport,
    getSfdcTabularReports,
    getSfdcReportColumnsIds,
  };
};

export { useReports };
