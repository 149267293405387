import { PluginMeddicSvg } from '../../icons';
import { BaseCriteriaPage } from '../../base-criteria-plugin-panel/BaseCriteriaPage';
import { useOnAddMeddicPlugin } from '../useOnAddMeddicPlugin';
import { meddicTexts } from './texts';
import { meddicPluginStepMapping } from '../panel/pluginData';

export const MeddicPage = ({ onBackClick }: { onBackClick: () => void }) => {
  const onAddPlugin = useOnAddMeddicPlugin();

  return (
    <BaseCriteriaPage
      onBackClick={onBackClick}
      onAddPlugin={onAddPlugin}
      title={meddicTexts.title}
      imageIcon={<PluginMeddicSvg />}
      detailTitle="Description"
      detailDescription={meddicTexts.description}
      pluginData={meddicPluginStepMapping}
    />
  );
};
