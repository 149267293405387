import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { Check } from '@sweep-io/sweep-design/dist/icons';

interface ColorPickerProps {
  selectedColor?: string;
  onSelectColor: (colorName: string) => void;
}

export const ColorPicker = ({ selectedColor, onSelectColor }: ColorPickerProps) => {
  const colorNames = Object.keys(colors);
  const only100ColorNames = colorNames.filter((colorName) =>
    colors[colorName as keyof typeof colors].hasOwnProperty(100),
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        padding: 1,
        alignItems: 'flex-start',
        gap: 1,
        maxWidth: '170px',
      }}
    >
      {only100ColorNames.map((color) => (
        <Box
          key={color}
          onClick={() => onSelectColor(color)}
          sx={{
            cursor: 'pointer',
            width: '24px',
            height: '24px',
            borderRadius: '100%',
            backgroundColor: (colors[color as keyof typeof colors] as any)?.[100],
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&:hover': { border: `1px solid ${colors.black}` },
          }}
        >
          {selectedColor === color && <Check />}
        </Box>
      ))}
    </Box>
  );
};
