export enum StageMetadataType {
  OPEN = 'Open',
  CLOSED_LOST = 'Closed/Lost',
  CLOSED_WON = 'Closed/Won',
}

export enum StageMetadataForecastCategory {
  COMMIT = 'Commit',
  OMITTED = 'Omitted',
  PIPELINE = 'Pipeline',
  BEST = 'BestCase',
  CLOSED = 'Closed',
}

export type StageMetadataForecastCategoryType = {
  label: string;
  value: StageMetadataForecastCategory;
};
