import { isProdEnvIndeed } from '../environment';
import { UserInfo } from '../types/UserInfoTypes';

const INIT_MAP = {
  hubspot: false,
};

const init = () => {
  if ((window as any)._hsq) {
    INIT_MAP.hubspot = true;
  }
};

const setUserDetails = (user: Partial<UserInfo>) => {
  const _hsq = (window as any)._hsq || [];

  _hsq.push([
    'identify',
    {
      email: user?.email,
    },
  ]);
  _hsq.push(['trackPageView']);
};

const trackPageViews = (pathname: string) => {
  const _hsq = (window as any)._hsq || [];
  _hsq.push(['setPath', pathname]);
  _hsq.push(['trackPageView']);
};

export const initHubspot = (force = false) => {
  if (isProdEnvIndeed() || force) {
    init();
  }
};

export const setUserForHubspot = (user: Partial<UserInfo>) => {
  if (INIT_MAP.hubspot) {
    setUserDetails(user);
  }
};

export const trackPageViewsInHubspot = (pathname: string) => {
  if (INIT_MAP.hubspot) {
    trackPageViews(pathname);
  }
};
