import { Stack } from '@mui/material';
import { RoleList } from './RoleList';
import { SingleRoleDetails } from './SingleRoleDetails';
import { useRoleManagerContext } from './RoleManagerContext';
import { UserDefinedRoleGroupsInterface } from '@server/role-group-interface';

export const RoleManagerContent = () => {
  const { editRole, initializeEditRole } = useRoleManagerContext();
  return (
    <Stack>
      {!editRole && (
        <RoleList
          setEditRole={(role: UserDefinedRoleGroupsInterface) => initializeEditRole(role)}
        />
      )}
      {editRole && <SingleRoleDetails role={editRole} />}
    </Stack>
  );
};
