import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { trackPageViewsInHubspot } from '../services/hubspot';

const useTrackPageViews = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    trackPageViewsInHubspot(pathname);
  }, [pathname]);
};

export default useTrackPageViews;
