import { Box, styled } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import React from 'react';

export const VerticalFlexBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const PluginDetail = ({
  title,
  description,
  gap = '2px',
}: {
  title?: string;
  description: React.ReactNode;
  gap?: string | number;
}) => {
  return (
    <VerticalFlexBox gap={gap}>
      <Typography variant="body-bold">{title}</Typography>
      <Typography variant="body" color={colors.grey[800]}>
        {description}
      </Typography>
    </VerticalFlexBox>
  );
};
