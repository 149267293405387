import { Badge, Typography, colors } from '@sweep-io/sweep-design';
import { ConfigurationType } from '../../dependencies/types';
import { Box, Stack } from '@mui/material';
import {
  DocumentationTabTypes,
  SWEEP_ELEMENTS_TAB_TYPES,
} from '../../../../types/enums/DocumentationTabTypes';
import { SearchInput } from '../../../common/SearchInput';
import { VirtualScrollDataTable } from '../../../common/table/VirtualScrollDataTable';
import { NoResults } from '../../shared/NoResults';
import { DependenciesSideDialog } from '../../dependencies/DependenciesSideDialog';
import { useDependencies } from '../../dependencies/hooks/useDependencies';
import { useDocumentation } from '../../useDocumentation';
import { DocumentationFilters } from '../filters/DocumentationFilters';
import { useExpandedModeCalculations } from './useExpandedModeCalculations';
import { ChangeEvent } from 'react';
import { DownloadAsCsvButton } from '../../shared/csv/DownloadAsCsvButton';
import { getCsvHeaders } from '../../shared/csv/utils';
import {
  selectSingleObject,
  selectSingleObjectActiveTab,
} from '../../../../reducers/documentationReducer';
import { useSelector } from 'react-redux';
import { useFilterTabs } from '../filters/useFilterTabs';
import { selectCrmOrg } from '../../../pages/environments/environmentsReducer';

interface ExpandedObjectContentProps {
  crmOrgId: string;
}

export const ExpandedObjectContent = ({ crmOrgId }: ExpandedObjectContentProps) => {
  const crmOrg = useSelector(selectCrmOrg(crmOrgId));
  const { configurationItem, dependenciesData, activeItemId, handleSetConfigurationItem } =
    useDependencies(crmOrgId);

  const elements = useFilterTabs();
  const documentationTabType = useSelector(selectSingleObjectActiveTab);
  const objectType = useSelector(selectSingleObject);

  const { onFieldClick } = useDocumentation();

  const {
    setSearchStr,
    columns,
    rows,
    rawDataElements,
    searchStr,
    onRowClick,
    onSortChange,
    sortBy,
  } = useExpandedModeCalculations({
    elements: elements ?? [],
    onFieldClick,
    activeItemId,
    handleSetConfigurationItem,
    crmOrgId,
    objectName: objectType?.objectType ?? '',
    documentationTabType: documentationTabType ?? ('' as DocumentationTabTypes),
  });

  const isSweepElement = documentationTabType
    ? SWEEP_ELEMENTS_TAB_TYPES.includes(documentationTabType)
    : false;

  const csvHeaders = getCsvHeaders(
    columns as {
      field: string;
      headerName: string;
      showSortIcon?: boolean;
    }[],
  );

  return (
    <Box borderTop={`1px solid ${colors.grey[200]}`} display="flex" height="100%" width="100%">
      <Box width="100%" ml={2} mr={2} pt={3.5}>
        {isSweepElement && (
          <Box pb={3.5} display="flex" alignItems="center" gap={1}>
            <Stack direction="row" gap={0.5} alignItems="center">
              <Typography variant="h3">{documentationTabType}</Typography>
              <Badge label={elements?.length ?? 0} variant="secondary" showZero />
            </Stack>
          </Box>
        )}

        <Box display="flex" gap={1.5} alignItems="center">
          <Box>
            <SearchInput
              withFixedMagnifyingGlassIcon
              onClearButtonClick={() => setSearchStr('')}
              placeholder="Search"
              TextFieldProps={{
                sx: { maxWidth: '283px' },
                value: searchStr,
                onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                  setSearchStr(event.target.value),
              }}
            />
          </Box>
          <DocumentationFilters crmOrgId={crmOrgId} staticView />
          <DownloadAsCsvButton
            fileName={`${crmOrg?.name}_${documentationTabType}_Export_from_Sweep`}
            headers={csvHeaders}
            rows={rawDataElements}
          />
        </Box>

        <Box
          pt={2.5}
          height="calc(100% - 55px)" //padding 55px
        >
          <VirtualScrollDataTable
            defaultSortState={elements?.length ? sortBy : undefined}
            columns={columns}
            rows={rows}
            onRowClick={onRowClick}
            onSortChange={onSortChange}
            tableEmptyStateJsx={
              <Box display="flex" justifyContent="center" pt={4}>
                <NoResults />
              </Box>
            }
          />
        </Box>
      </Box>

      {configurationItem && dependenciesData?.parentType && (
        <Box height="calc(100% - 40px)">
          <DependenciesSideDialog
            crmOrgId={crmOrgId}
            configurationItem={configurationItem}
            type={dependenciesData?.parentType as ConfigurationType}
            staticView
          />
        </Box>
      )}
    </Box>
  );
};
