export const getFunnelMapSingleFunnelSkeleton = ({
  funnel,
  updatedAt,
  updatedById,
}: {
  funnel: Funnel;
  updatedAt: any;
  updatedById: string;
}): FunnelMap => {
  return {
    id: `TEMPORARY_FUNNEL_MAP_${funnel.id}`,
    funnels: {
      [funnel.id]: { position: { row: 0, column: 0 } },
    },
    recordTypes: {},
    name: funnel.name,
    createdAt: '',
    createdById: '',
    updatedAt,
    updatedById,
    isPinned: false,
    funnelsData: {
      [funnel.id]: funnel,
    },
    recordTypesData: {},
    hubspotFunnels: {},
    hubspotFunnelsData: {},
    thirdPartyFunnels: {},
    thirdPartyFunnelsData: {},
  };
};
