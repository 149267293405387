import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { TextFieldWithTags, TextFieldWithTagsProps } from './TextFieldWithTags';
import { colors } from '@sweep-io/sweep-design';

interface EmailInputWithValidationProps
  extends Omit<
    TextFieldWithTagsProps,
    'isValueValid' | 'handleChange' | 'inputError' | 'startText'
  > {
  hideStartTxt?: boolean;
}

export type EmailInputWithValidationRef = {
  isValid: () => void;
};

export const EmailInputWithValidation = forwardRef<
  EmailInputWithValidationRef,
  EmailInputWithValidationProps
>((props: EmailInputWithValidationProps, ref) => {
  const [inputError, setInputError] = useState<string>();
  const { tags, ...otherProps } = props;

  const handleChange = () => {
    setInputError(undefined);
  };

  const isEmailString = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const isEmailValid = (email: string) => {
    let error = null;
    if (!isEmailString(email)) {
      error = `${email} is not a valid email address.`;
    }
    if (error) {
      setInputError(error);
      return false;
    }
    return true;
  };

  const isValid = useCallback(() => {
    const isValid = !inputError && tags.length > 0;
    if (!tags.length) {
      setInputError('At least one email address is required');
    }

    return isValid;
  }, [inputError, tags.length]);

  useImperativeHandle(
    ref,
    () => ({
      isValid,
    }),
    [isValid],
  );
  const coloredTags = tags.map((label) => ({
    ...label,
    color: colors.storm[50],
  }));

  return (
    <TextFieldWithTags
      isValueValid={isEmailValid}
      onChange={handleChange}
      error={inputError}
      {...otherProps}
      tags={coloredTags}
      startText={otherProps.hideStartTxt ? undefined : 'To'}
    />
  );
});
