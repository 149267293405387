import { ElementsUsedByClient } from '@server/read-only-elements.consts';
import { colors } from '@sweep-io/sweep-design';

export const keyToName: ObjectLiteral = {
  name: 'Name',
  trigger: 'Trigger',
  actions: 'Action(s)',
  delay: 'Delay',
  settings: 'Settings',
  notification: 'Send notification',
  calculation: 'Calculated values',
  field: 'Relationship between the objects',
  filter: 'Filters',
  lookup_relations: 'Lookup relations',
};

export const NOTIFICATION_TYPES = [
  ElementsUsedByClient.SEND_EMAIL,
  ElementsUsedByClient.SEND_SLACK_MESSAGE,
  ElementsUsedByClient.ASSIGNMENT_DO_NOT_NOTIFY,
  ElementsUsedByClient.ASSIGNMENT_ASSIGN_TO,
];

export const border = {
  '&:before': {
    content: '""',
    display: 'block',
    height: 'calc(100% - 10px)',
    background: colors.grey[200],
    width: '1px',
    position: 'absolute',
    top: '5px',
    left: '3px',
    zIndex: '1',
  },
};
