import { useContext, useMemo } from 'react';
import { InternalCanvasCtx } from './internal-context/InternalCanvasCtx';
import { ContextZoomType } from './SweepCanvas';
import { CanvasMode } from './canvasTypes';
import { sweepNodeCanvasSx } from './nodes/GroupNode';
import { VisibilityLayers } from '../../types/enums/VisibilityLayers';
import { getGroupLabelStyleTransformations, groupLabelStyle } from './nodes/GroupLabelNode';
import {
  contextZoomTypeToClass,
  canvasModeToClass,
  visibilityMapClasses,
} from './nodes/contextZoomConsts';
import { useStore } from 'reactflow';
import { useHighlightEdgeStyle } from './useHighlightEdge';
import merge from 'lodash/merge';

const MAX_LABELS_COUNT = 50;

export const useContextZoomCss = ({
  canvasMode,
  groupLabelsCount = 0,
}: {
  canvasMode: CanvasMode;
  groupLabelsCount?: number;
}) => {
  const { contextZoomType, visibilityMap } = useContext(InternalCanvasCtx);
  const zoomLevel = useStore((store) => store.transform[2]);

  const edgeHighlights = useHighlightEdgeStyle();

  const contextZoomClass =
    contextZoomTypeToClass[(contextZoomType || ContextZoomType.DEFAULT) as string];
  const canvasModeClass = canvasModeToClass[canvasMode];

  const _visibilityMapClasses = Object.entries(visibilityMap).reduce((acc, [key, value]) => {
    if (value) {
      acc.push(visibilityMapClasses[key as VisibilityLayers]);
    }
    return acc;
  }, [] as string[]);

  const groupLabelStyleTransformationsStyle = useMemo(() => {
    return groupLabelsCount < MAX_LABELS_COUNT
      ? getGroupLabelStyleTransformations(zoomLevel)
      : undefined;
  }, [groupLabelsCount, zoomLevel]);

  const sx = useMemo(
    () =>
      merge(
        sweepNodeCanvasSx,
        edgeHighlights,
        groupLabelStyle,
        groupLabelStyleTransformationsStyle,
      ),
    [edgeHighlights, groupLabelStyleTransformationsStyle],
  );

  const classes = useMemo(() => {
    return [contextZoomClass, canvasModeClass, ..._visibilityMapClasses];
  }, [_visibilityMapClasses, canvasModeClass, contextZoomClass]);

  return { sx, classes };
};
