import { Box, Button, Grid } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { sharedLinkVariantStyles } from './sharedLinkStyles';

const SelectorEmptyState = ({
  callback,
  title,
  buttonText,
}: {
  callback?: (v: any) => void;
  title: string;
  buttonText?: string;
}) => {
  return (
    <Grid container flexWrap="wrap" justifyContent="center" sx={{ padding: '20px' }}>
      <Box sx={{ padding: '0 25px 5px' }}>
        <Typography
          variant="caption-bold"
          textAlign="center"
          color={colors.storm[400]}
          whiteSpace="break-spaces"
        >
          {title}
        </Typography>
      </Box>

      {callback && (
        <Button
          variant="link"
          onClick={callback}
          sx={{
            ...sharedLinkVariantStyles,
          }}
        >
          {buttonText}
        </Button>
      )}
    </Grid>
  );
};

export default SelectorEmptyState;
