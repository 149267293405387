import { Box } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import { FilterLines as FilterLinesIcon } from '@sweep-io/sweep-design/dist/icons';

export const FilterBySnippet = ({ title = 'Filter' }: { title?: string }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        whiteSpace: 'nowrap',
        marginRight: '4px',
      }}
    >
      <FilterLinesIcon color={colors.grey[700]} />
      <Typography variant="body" color={colors.grey[700]}>
        {title}
      </Typography>
    </Box>
  );
};
