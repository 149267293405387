import {
  ApexTriggerData,
  ConfigurationInstanceData,
  FlowData,
  FlowTypes,
  LayoutData,
  LeadingField,
  OBJECTS_DATA_KEY,
  ObjectType,
  ParsedRecordType,
  ParsedRule,
  ParserResponse,
  RecordTypeData,
  RuleType,
  ValidationRuleData,
} from './ParserTypes';
import { StageDialogTabTypes } from '../../types/enums/StageDialogTabTypes';
import clone from 'lodash/clone';
import difference from 'lodash/difference';
import uniq from 'lodash/uniq';
import { DocumentationTabTypes } from '../../types/enums/DocumentationTabTypes';
import endsWith from 'lodash/endsWith';
import { RollupDto } from '../pages/rollups/rollupTypes';
import { setSfFieldName } from '../pages/rollups/rollupHelpers';
import {
  DocumentationPills,
  GlobalDto,
  ParsedData,
  ParsedFieldsByObject,
} from '../../reducers/global/globalReducerTypes';
import {
  createStepId,
  getDeployedVersions,
  getOnlyAutomationsOfObject,
} from '../documentation/selected-object/filters/utils';
import sum from 'lodash/sum';
import uniqBy from 'lodash/uniqBy';
import { isItemActive } from '../documentation/helpers';
import { ConfigurationType, LayoutsByObjectName } from '../documentation/dependencies/types';
import {
  ConfigurationItem,
  FieldMetadataRecordProperties,
} from '../documentation/dependencies/DependenciesTypes';

export const DEFAULT_OBJECTS_API_NAME = ['Lead', 'Opportunity', 'Contact', 'Account', 'Case'];

const parseRule = ({
  rule,
  type,
  objectApiNames,
  isUsedByRecordType,
  usedByRecordTypes,
  stagesNames,
}: {
  rule: ValidationRuleData | ConfigurationInstanceData | FlowData | ApexTriggerData;
  type: RuleType;
  objectApiNames: string[];
  isUsedByRecordType?: boolean;
  usedByRecordTypes?: string[];
  stagesNames?: string[];
}): ParsedRule => {
  return {
    ...rule,
    type,
    objectApiNames,
    isUsedByRecordType,
    usedByRecordTypes,
    relatedApexClasses: 'relatedClasses' in rule ? rule.relatedClasses : undefined,
    flowDescription: 'flowType' in rule ? getFlowDescription(rule) : undefined,
    stagesNames,
    errorMessage: 'errorMessage' in rule ? rule.errorMessage : undefined,
    isActive: isItemActive(rule),
  };
};

//create a human-readable description of the trigger and action
const getFlowDescription = (rule: FlowData) => {
  let flowDescription = rule.flowType.toString();

  if ('triggers' in rule && rule.flowType === FlowTypes.RECORD_TRIGGERED) {
    const triggersDescriptions =
      rule.triggers?.map((trigger) => {
        if ('runWhen' in trigger) {
          const { runWhen, triggerOn } = trigger;
          const part1 = FLOW_TERMS_MAP[runWhen];
          const part2 =
            runWhen === 'RecordBeforeDelete' ? '' : ` on ${FLOW_TERMS_MAP[triggerOn]} action`;
          return `${part1}${part2}`;
        } else return '';
      }) ?? [];
    const triggersDescriptionsAsString = triggersDescriptions.join(', ');
    flowDescription += `, runs ${triggersDescriptionsAsString}.`;
  }

  if ('triggers' in rule && rule.flowType === FlowTypes.SCHEDULED_TRIGGERED) {
    const triggersDescriptions =
      rule.triggers?.map((trigger) => {
        if ('schedule' in trigger) {
          const { frequency } = trigger.schedule;
          return ` ${FLOW_TERMS_MAP[frequency]}`;
        } else return '';
      }) ?? [];
    const triggersDescriptionsAsString = triggersDescriptions.join(' and ');
    flowDescription += `, runs ${triggersDescriptionsAsString}.`;
  }

  return flowDescription;
};

const FLOW_TERMS_MAP = {
  RecordAfterSave: 'after record is saved',
  RecordBeforeSave: 'before record is saved',
  RecordBeforeDelete: 'before record is deleted',
  Update: 'update',
  CreateAndUpdate: 'create and update',
  Create: 'create',
  Delete: 'delete',
  Daily: 'daily',
  Weekly: 'weekly',
  Once: 'once',
};

const parseRecordTypes = ({
  recordTypes,
  objectApiName,
}: {
  recordTypes: RecordTypeData[];
  objectApiName: string;
}): ParsedRecordType[] =>
  recordTypes.map((recordType) => ({
    ...recordType,
    objectApiName,
    isActive: isItemActive(recordType),
  }));

//These are rules that are associated to a single object
const OBJECT_LEVEL_UNIQUE_RULE_TYPES: RuleType[] = [
  RuleType.validationRules,
  RuleType.approvalProcesses,
  RuleType.workflowRules,
  RuleType.apexTriggers,
];

//These are rules that are not associated to any specific object
const ORG_LEVEL_UNIQUE_RULE_TYPES: RuleType[] = difference(
  Object.values(RuleType),
  OBJECT_LEVEL_UNIQUE_RULE_TYPES,
);

export const USED_UNIQUE_BY_RT = '&!&UNIQUE';

export const automationTypes = [
  RuleType.approvalProcesses,
  RuleType.flows,
  RuleType.processBuilderFlows,
  RuleType.workflowRules,
];

export const calcPills = (global: GlobalDto): DocumentationPills => {
  const {
    parser,
    automations = [],
    alerts = [],
    assignments = [],
    scheduledAssignments = [],
    matching = [],
    dedup = [],
  } = global;
  const data: DocumentationPills = {};

  parser[OBJECTS_DATA_KEY].forEach((objectData) => {
    const { name: objectApiName } = objectData;

    const getOnlyActive = (item: any) => isItemActive(item);

    data[objectApiName] = {
      [DocumentationTabTypes.VALIDATION_RULES]:
        objectData.validationRules?.filter(getOnlyActive).length ?? 0,
      [DocumentationTabTypes.SF_AUTOMATIONS]: sum(
        automationTypes.map((type) => objectData[type]?.filter(getOnlyActive).length ?? 0),
      ),
      [DocumentationTabTypes.APEX]: objectData.apexTriggers?.filter(getOnlyActive).length ?? 0,
      [DocumentationTabTypes.FIELDS]: objectData.fields?.length ?? 0,
      [DocumentationTabTypes.RECORD_TYPES]:
        objectData.recordTypes?.filter(getOnlyActive).length ?? 0,
      [DocumentationTabTypes.CPQ_DATA]: objectData.cpqData?.length ?? 0,
      [DocumentationTabTypes.LAYOUTS]: objectData.layouts?.length ?? 0,
      [DocumentationTabTypes.MATCHING_DEDUPE]:
        getOnlyAutomationsOfObject(getDeployedVersions([...matching, ...dedup]), objectApiName)
          ?.length ?? 0,
      [DocumentationTabTypes.SWEEP_AUTOMATIONS]:
        getOnlyAutomationsOfObject(getDeployedVersions(automations), objectApiName)?.length ?? 0,
      [DocumentationTabTypes.ASSIGNMENTS]:
        getOnlyAutomationsOfObject(getDeployedVersions(assignments), objectApiName)?.length ?? 0,
      [DocumentationTabTypes.SCHEDULED_ASSIGNMENTS]:
        getOnlyAutomationsOfObject(getDeployedVersions(scheduledAssignments), objectApiName)
          ?.length ?? 0,
      [DocumentationTabTypes.PLAYBOOK_ALERTS]:
        getOnlyAutomationsOfObject(getDeployedVersions(alerts), objectApiName)?.length ?? 0,
      [DocumentationTabTypes.CARDS_LIST]: 0,
    };
  });

  return data;
};

export const calcLayouts = (data: ParserResponse): LayoutsByObjectName => {
  const layouts: LayoutsByObjectName = {};

  data[OBJECTS_DATA_KEY].forEach((objectData) => {
    const { name: objectApiName } = objectData;

    Object.keys(RuleType).forEach((ruleType) => {
      const _ruleType = ruleType as RuleType;
      const rulesOfType = objectData[_ruleType];

      if (_ruleType === RuleType.layouts) {
        if (!layouts[objectApiName]) {
          layouts[objectApiName] = {
            layouts: [],
            isLoading: false,
          };
        }
        rulesOfType?.forEach((rule) => layouts[objectApiName].layouts.push(rule as LayoutData));
      }
    });
  });

  return layouts;
};

export const calcParsedRules = (data: ParserResponse) => {
  const rules: ParsedRule[] = [];

  data[OBJECTS_DATA_KEY].forEach((objectData) => {
    const { name: objectApiName, recordTypes } = objectData;

    Object.keys(RuleType).forEach((ruleType) => {
      const _ruleType = ruleType as RuleType;
      const rulesOfType = objectData[_ruleType];
      const isOrgLevelUnique = ORG_LEVEL_UNIQUE_RULE_TYPES.includes(_ruleType);

      rulesOfType?.forEach((ruleOfType) => {
        const rtLevelAttributions: string[] = [];
        const stepLevelAttributions: string[] = [];

        recordTypes.forEach((rt) => {
          /**
           * Configurations can be attributed to both RT and step in the same time
           */
          const hasRecordTypeAttributions = rt.attributions?.[_ruleType]?.includes(ruleOfType.name);
          const recordTypeId = setUsedByRecordTypeIdWithLeadingFieldName(
            USED_UNIQUE_BY_RT,
            rt.name,
            objectApiName,
          );
          const isRTAttributionsUniqueAlready = !!rtLevelAttributions.find((el) =>
            el.match(recordTypeId),
          );

          if (hasRecordTypeAttributions) {
            rtLevelAttributions.push(recordTypeId);
          }

          rt.leadingFields?.forEach((leadingField) => {
            const rtIdWithLeadingField = setUsedByRecordTypeIdWithLeadingFieldName(
              leadingField.name,
              rt.name,
              objectApiName,
            );

            leadingField.values.forEach((stage) => {
              const rulesOfTypeForStage = stage[_ruleType];

              if (rulesOfTypeForStage?.includes(ruleOfType.name)) {
                if (!rtLevelAttributions.includes(rtIdWithLeadingField)) {
                  rtLevelAttributions.push(rtIdWithLeadingField);
                }

                if (isRTAttributionsUniqueAlready) {
                  const uniqueRtIdx = rtLevelAttributions.findIndex(
                    (item) => item === recordTypeId,
                  );

                  if (uniqueRtIdx !== -1) {
                    delete rtLevelAttributions[uniqueRtIdx];
                  }
                }

                stepLevelAttributions.push(createStepId(rt.name, objectApiName, stage.name));
              }
            });
          });
        });

        const existingRule = isOrgLevelUnique
          ? rules.find((rule) => rule.name === ruleOfType.name)
          : undefined;

        if (existingRule) {
          existingRule.objectApiNames.push(objectApiName);

          if (rtLevelAttributions.length) {
            const oldRTUsage = existingRule.usedByRecordTypes ?? [];
            const oldStepAttribution = existingRule.stagesNames ?? [];

            existingRule.isUsedByRecordType = true;
            existingRule.usedByRecordTypes = oldRTUsage.concat(rtLevelAttributions);
            existingRule.stagesNames = oldStepAttribution.concat(stepLevelAttributions);
          }
        } else {
          rules.push(
            parseRule({
              rule: ruleOfType,
              type: _ruleType,
              objectApiNames: [objectApiName],
              isUsedByRecordType: !!rtLevelAttributions.length,
              usedByRecordTypes: rtLevelAttributions.length ? rtLevelAttributions : undefined,
              stagesNames: stepLevelAttributions.length ? stepLevelAttributions : undefined,
            }),
          );
        }
      });
    });
  });

  return rules;
};

export const calcParsedFields = (
  parser: ParserResponse,
  rollups: RollupDto[],
): ParsedFieldsByObject => {
  return Object.fromEntries(
    parser[OBJECTS_DATA_KEY].map((item) => [
      item.name,
      item.fields?.map((field) => ({
        ...field,
        objectName: item.name,
        isRollup: !!rollups?.find(
          (rollup) => rollup.name === setSfFieldName(field.name, item.name),
        ),
        filename: field.filename,
        isActive: true,
        parentType: ConfigurationType.fields,
      })) ?? [],
    ]),
  );
};

export const calcParsedRecordTypes = (data: ParserResponse) => {
  let recordTypesResult: ParsedRecordType[] = [];

  data[OBJECTS_DATA_KEY].forEach((objectData) => {
    const { name, recordTypes } = objectData;
    if (recordTypes) {
      const parsedRecordTypes = parseRecordTypes({
        recordTypes,
        objectApiName: name,
      });
      recordTypesResult = recordTypesResult.concat(parsedRecordTypes);
    }
  });

  return recordTypesResult;
};

export const calcObjects = (data: ParserResponse) => {
  const parserObjects: ObjectType[] = [];

  data[OBJECTS_DATA_KEY].forEach((objectData) => {
    const { name, visible } = objectData;
    parserObjects.push({ name, visible });
  });

  return parserObjects;
};

export const ruleByObjectName = (objectName: string) => (rule: ParsedRule) =>
  rule.objectApiNames.includes(objectName);

//Get all rules (general or stage-specific), depending on the toggle state
export const generalRuleByObjectToggle = (showObjectLevelRules: boolean) => (rule: ParsedRule) =>
  showObjectLevelRules ? true : rule.isUsedByRecordType;

//Get only the "general rules" (non stage-specific) that should be shown in a stage, depending on the toggle state
export const stageRuleByObjectToggle = (showObjectLevelRules: boolean) => (rule: ParsedRule) =>
  showObjectLevelRules ? !rule.isUsedByRecordType : false;

export const ruleByStage =
  ({ leadingField, stageName }: { leadingField?: LeadingField; stageName: string }) =>
  (rule: ParsedRule) => {
    const relevantStage = leadingField?.values.find((value) => value.name === stageName);
    const rulesFromRelevantStage = relevantStage?.[rule.type];
    return rulesFromRelevantStage?.includes(rule.name);
  };

export const deprecatedGetRulesPerTabAndStage = ({
  parsedRules,
  tab,
  parsedRecordTypes,
  funnelStage,
  funnelRecordType,
  leadingObject,
}: {
  parsedRules: ParsedRule[];
  tab: StageDialogTabTypes | DocumentationTabTypes;
  parsedRecordTypes: ParsedRecordType[];
  funnelStage: SweepStage;
  funnelRecordType?: FunnelRecordType;
  leadingObject: FunnelLeadingObject;
}) => {
  const rulesByObject = getRulesByTab(parsedRules, tab).filter(
    ruleByObjectName(leadingObject.objectName),
  );
  const rulesByObjectToggle = rulesByObject.filter(stageRuleByObjectToggle(true));

  //get leading field used in funnel from record type
  const relevantLeadingField = getUsedLeadingFieldFromRecordType({
    parsedRecordTypes,
    leadingObjectFieldName: leadingObject.fieldName,
    funnelRecordType,
    objectName: leadingObject.objectName,
  });

  //get rules used in given stage
  const rulesByStage = rulesByObject.filter(
    ruleByStage({ stageName: funnelStage.stageName, leadingField: relevantLeadingField }),
  );

  //assign used stageNames to the rules
  const rulesWithStagesInfo = getRuleWithAllStageNamesUsingRule({
    rulesByStage,
    relevantLeadingField,
  });

  return rulesWithStagesInfo.concat(rulesByObjectToggle);
};

export const funnelStepRulesPerTabAndStage = ({
  objectName,
  parsedRecordTypes,
  parsedRules,
  tab,
  recordType,
  stageData,
}: {
  parsedRules: ParsedRule[];
  tab: StageDialogTabTypes | DocumentationTabTypes;
  parsedRecordTypes: ParsedRecordType[];
  recordType: RecordTypeStruct;
  objectName: string;
  stageData: {
    leadingObjectFieldName: string;
    stageName: string;
  };
}) => {
  const rulesByTab = getRulesByTab(parsedRules, tab);

  //get leading field used in funnel from record type
  const relevantLeadingField = getUsedLeadingFieldFromRecordType({
    parsedRecordTypes,
    leadingObjectFieldName: stageData.leadingObjectFieldName,
    funnelRecordType: recordType,
    objectName,
  });

  //get rules used in given stage
  const rulesByObject = rulesByTab.filter(ruleByObjectName(objectName)).filter(
    ruleByStage({
      stageName: stageData.stageName,
      leadingField: relevantLeadingField,
    }),
  );

  const rulesByObjectToggle = rulesByObject.filter(stageRuleByObjectToggle(false));

  //assign used stageNames to the rules
  const rulesWithStagesInfo = getRuleWithAllStageNamesUsingRule({
    rulesByStage: rulesByObject,
    relevantLeadingField,
  });

  return rulesWithStagesInfo.concat(rulesByObjectToggle);
};

export const getRulesPerTabAndStage = ({
  parsedRules,
  tab,
  recordType,
  objectName,
}: {
  parsedRules: ParsedRule[];
  tab: StageDialogTabTypes | DocumentationTabTypes;
  recordType: RecordTypeStruct;
  objectName: string;
}) => {
  const rulesByTab = getRulesByTab(parsedRules, tab);
  const rulesByObject = rulesByTab.filter(ruleByObjectName(objectName));

  return rulesByObject.filter(isRuleUsedByRecordType(recordType.name, objectName));
};

export const isRuleUsedByRecordType =
  (recordTypeName: string, objectName: string) => (rule: ParsedRule) =>
    rule.type === RuleType.layouts // there's only 1:1 connection between RT and layouts
      ? isRuleUniqueToRecordType(recordTypeName, objectName)(rule)
      : !rule.isUsedByRecordType || isRuleUniqueToRecordType(recordTypeName, objectName)(rule);

const isRuleUniqueToRecordType =
  (recordTypeName: string, objectName: string) => (rule: ParsedRule) =>
    rule.usedByRecordTypes?.find((name) => endsWith(name, `.${recordTypeName}.${objectName}`));

//TODO: get rid of the one above
export const isConfigurationItemUsedByRecordType =
  (recordTypeName: string, objectName: string) => (rule: ConfigurationItem) =>
    rule.type === RuleType.layouts // there's only 1:1 connection between RT and layouts
      ? isConfigurationItemUniqueToRecordType(recordTypeName, objectName)(rule)
      : !rule.usedOnlyByRecordType?.length ||
        isConfigurationItemUniqueToRecordType(recordTypeName, objectName)(rule);

const isConfigurationItemUniqueToRecordType =
  (recordTypeName: string, objectName: string) => (rule: ConfigurationItem) =>
    rule.usedOnlyByRecordType?.find((name) => endsWith(name, `.${recordTypeName}.${objectName}`));

export const getRuleWithAllStageNamesUsingRule = ({
  rulesByStage,
  relevantLeadingField,
}: {
  rulesByStage: ParsedRule[];
  relevantLeadingField?: LeadingField;
}) => {
  //assign used stageNames to the rules
  return rulesByStage.map((rule) => {
    const ruleClone = clone(rule);
    const stageNames: string[] = [];

    // Iterate the leading field to find other stage names that uses the same rule
    relevantLeadingField?.values.forEach((value) => {
      const rulesPerType = value[rule.type];
      if (rulesPerType?.includes(rule.name)) {
        stageNames.push(value.label);
      }
    });

    const prevRuleStageNames = rule.stagesNames ?? [];

    ruleClone.stagesNames = uniq([...prevRuleStageNames, ...stageNames]);
    return ruleClone;
  });
};

export const getUsedLeadingFieldFromRecordType = ({
  parsedRecordTypes,
  funnelRecordType,
  leadingObjectFieldName,
  objectName,
}: {
  parsedRecordTypes: ParsedRecordType[];
  funnelRecordType?: RecordTypeStruct;
  leadingObjectFieldName: string;
  objectName: string;
}) => {
  //find record type used by stage
  const relevantRecordType = parsedRecordTypes?.find(
    (recordType) =>
      recordType.objectApiName.includes(objectName) &&
      (recordType.name === funnelRecordType?.name || recordType.label === funnelRecordType?.label), //if the funnel is not deployed yet, there's no "apiName" only "label"
  );

  //get leading field
  return relevantRecordType?.leadingFields?.find(
    (leadingField) => leadingField.name === leadingObjectFieldName,
  );
};

export const RT_WITH_LFN_SEPARATOR = '.';

const setUsedByRecordTypeIdWithLeadingFieldName = (
  leadingFieldName: string,
  recordTypeName: string,
  objectApiName?: string,
) =>
  `${leadingFieldName}${RT_WITH_LFN_SEPARATOR}${recordTypeName}${
    objectApiName ? RT_WITH_LFN_SEPARATOR + objectApiName : ''
  }`;

export const getDataFromRecordTypeIdWithLeadingFieldName = (
  recordTypeIdWithLeadingFieldName: string,
) => ({
  leadingFieldName: recordTypeIdWithLeadingFieldName.split(RT_WITH_LFN_SEPARATOR)?.[0],
  recordTypeName: recordTypeIdWithLeadingFieldName.split(RT_WITH_LFN_SEPARATOR)?.[1],
  objectApiName: recordTypeIdWithLeadingFieldName.split(RT_WITH_LFN_SEPARATOR)?.[2],
});

const tabToRuleType = {
  [StageDialogTabTypes.GATES]: [],
  [StageDialogTabTypes.AUTOMATIONS]: [],
  [StageDialogTabTypes.SETTINGS]: [],
  [StageDialogTabTypes.ASSIGNMENTS]: [],
  [StageDialogTabTypes.ALERTS]: [],

  [DocumentationTabTypes.SF_AUTOMATIONS]: [
    RuleType.flows,
    RuleType.approvalProcesses,
    RuleType.workflowRules,
    RuleType.processBuilderFlows,
  ],
  [DocumentationTabTypes.VALIDATION_RULES]: [RuleType.validationRules],
  [DocumentationTabTypes.FIELDS]: [],
  [DocumentationTabTypes.APEX]: [RuleType.apexTriggers],
  [DocumentationTabTypes.SWEEP_AUTOMATIONS]: [],
  [DocumentationTabTypes.ASSIGNMENTS]: [],
  [DocumentationTabTypes.SCHEDULED_ASSIGNMENTS]: [],
  [DocumentationTabTypes.MATCHING_DEDUPE]: [],
  [DocumentationTabTypes.CARDS_LIST]: [],
  [DocumentationTabTypes.CPQ_DATA]: [RuleType.cpqData],
  [DocumentationTabTypes.RECORD_TYPES]: [],
  [DocumentationTabTypes.LAYOUTS]: [RuleType.layouts],
};

export const getRulesByTab = (
  parsedRules: ParsedRule[],
  tab: StageDialogTabTypes | DocumentationTabTypes,
): ParsedRule[] => {
  const relevantTypes: string[] = tabToRuleType[tab];
  return parsedRules.filter(({ type }) => (relevantTypes ? relevantTypes.includes(type) : false));
};

export const transformResponse = (data: GlobalDto): ParsedData => {
  const { parser, rollups } = data;
  const layouts = calcLayouts(parser);
  const rules = calcParsedRules(parser);
  const recordTypes = calcParsedRecordTypes(parser);
  const objectNames = parser[OBJECTS_DATA_KEY].map((objectInfo) => objectInfo.name);
  const fields = calcParsedFields(parser, rollups ?? []);
  const pills = calcPills(data);
  const parserObjects = calcObjects(parser);

  return {
    layouts,
    parsedRules: rules,
    parsedRecordTypes: recordTypes,
    parsedObjectNames: objectNames,
    parsedFields: fields,
    parserObjects,
    pills,
  };
};

//check if field usage was refreshed and display new one
//global parsed field state shouldn't be updated because once it's refreshed the data will be gone
export const mergeFields = (
  fields: ParsedFieldsByObject,
  fieldsFromObjectsParsedOnDemand: ParsedFieldsByObject,
  fieldAccurateUsage: {
    [objectName: string]: {
      [fieldId: string]: number;
    };
  },
) => {
  const mapNewUsage = (arr: FieldMetadataRecordProperties[], objectName: string) => {
    return arr?.map((field) => ({
      ...field,
      usage:
        fieldAccurateUsage?.[objectName]?.[setSfFieldName(field.name, objectName)] ??
        field.usage ??
        0,
    }));
  };

  return [fields, fieldsFromObjectsParsedOnDemand].reduce((acc, current) => {
    Object.keys(current).forEach((key) => {
      if (!acc[key]) {
        acc[key] = mapNewUsage(current[key], key);
      } else {
        acc[key] = mapNewUsage(uniqBy(acc[key].concat(current[key]), 'id'), key);
      }
    });
    return acc;
  }, {});
};
