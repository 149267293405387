import { Switch } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@sweep-io/sweep-design';

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  width: 34,
  height: 20,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(14px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: colors.blue[500],
        opacity: '1 !important',
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
      '& .MuiSwitch-thumb:before': {
        content: "''",
        color: colors.blue[500],
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="12" viewBox="0 0 12 12" width="12"><path d="M0 0h12v12H0z" fill="none" /><path fill="${encodeURIComponent(
          colors.blue[500],
        )}" stroke="${encodeURIComponent(
          colors.blue[500],
        )}" stroke-width="" stroke-linecap="round" d="M4.5 8.1 2.4 6l-.7.7L4.5 9.5 10.5 3.5l-.7-.7L4.5 8.1z"/></svg>')`,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    boxShadow: 'none',
    backgroundColor: '#fff',
  },
  '& .MuiSwitch-track': {
    borderRadius: '15px',
    backgroundColor: colors.grey[300],
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 300,
    }),
    boxShadow: 'none',
  },
}));
