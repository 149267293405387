import uniq from 'lodash/uniq';
import { ConfigurationType } from '../dependencies/types';
import { SearchResponse } from './types';
import { telemetry } from '../../../telemetry';

export const sortSearchResults = (
  results: SearchResponse,
  orgId: string,
  searchTxt?: string,
): SearchResponse => {
  const searchResponseKeys = Object.keys(results) as ConfigurationType[];
  const _requestedOrder = [
    ConfigurationType.objects,
    ConfigurationType.recordTypes,
    ConfigurationType.fields, //first lookups then others
    ConfigurationType.flows,
    ConfigurationType.validationRules,
    ConfigurationType.processBuilderFlows,
    ConfigurationType.workflowRules,
    ConfigurationType.layouts,
    ConfigurationType.apexClasses,
    ConfigurationType.apexTriggers,
  ];
  const order = uniq(_requestedOrder.concat(searchResponseKeys));

  const sortedFields =
    results.fields?.sort((a, b) => {
      const aIsLookup = a.type === 'Lookup';
      const bIsLookup = b.type === 'Lookup';

      if (aIsLookup && bIsLookup) {
        return a.name.localeCompare(b.name);
      }

      if (aIsLookup && !bIsLookup) {
        return -1;
      }

      if (!aIsLookup && bIsLookup) {
        return 1;
      }

      return 0;
    }) ?? [];

  const newlyOrdered = {} as SearchResponse;
  order.forEach((newOrderItem) => {
    //overwritting results until Salto fixes MissingInternalId bug
    const newResults =
      (results[newOrderItem as keyof SearchResponse] as any)?.map((item: any) =>
        item.id === 'MissingInternalId' ? { ...item, id: item.name + item.type } : item,
      ) ?? [];
    const noNameAndMissingInternalIdItems = newResults.filter(
      (item: any) => (item.id === 'MissingInternalId' && !item.name) || (!item.id && !item.name),
    );
    const resultsWithoutNoName = newResults.filter(
      (item: any) => !((item.id === 'MissingInternalId' && !item.name) || (!item.id && !item.name)),
    );

    if (noNameAndMissingInternalIdItems.length > 0) {
      telemetry.captureError(
        `Search results contains items with missing internal id and name in org: ${orgId}, for search query: ${searchTxt}`,
        JSON.stringify(noNameAndMissingInternalIdItems),
      );
    }

    newlyOrdered[newOrderItem as keyof SearchResponse] =
      newOrderItem === ConfigurationType.fields ? sortedFields : resultsWithoutNoName;
  });

  return newlyOrdered;
};
