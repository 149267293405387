import { styled } from '@mui/material/styles';
import Radio, { RadioProps } from '@mui/material/Radio';
import { RadioGroup, Select } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';

const BpIcon = styled('span')(() => ({
  borderRadius: '50%',
  width: 18,
  height: 18,
  boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: '#f5f8fa',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: colors.blue[500],
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 18,
    height: 18,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: colors.blue[300],
  },
});

export const StyledRadio = (props: RadioProps) => {
  return (
    <Radio
      sx={{
        padding: 0,
        '&:hover': {
          bgcolor: 'transparent',
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
};

export const ColoredCircle = styled('div', {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'width',
})<{ color?: string; width?: string }>(({ color, width }) => {
  return {
    borderRadius: '50%',
    width,
    height: width,
    backgroundColor: color,
  };
});

export const StyledSelect = styled(Select)({
  width: '100%',
  padding: '9px 12px',
  border: '1px solid #C4C4C4',
  borderRadius: '6px',
  boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.08)',
  '.MuiSelect-select': {
    padding: '0 20px 0 0!important', //to override theme
    backgroundColor: colors.white,
    color: colors.black,
  },
});

export const StyledRadioGroup = styled(RadioGroup)({
  flexWrap: 'nowrap',
  '.MuiTypography-caption': {
    color: colors.grey[700],
  },
  '.MuiRadio-root': {
    paddingRight: '12px',
  },
  '&.alignTop': {
    '.MuiFormControlLabel-root': {
      alignItems: 'flex-start',
      marginBottom: '12px',
    },
    '.MuiRadio-root': {
      paddingTop: '5px',
    },
  },
});
