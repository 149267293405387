import { Box, Dialog, DialogContent, DialogTitle, MenuItem, Select, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { selectWorkflowById } from '../../../../../reducers/hubspotReducer';
import { useSelector } from 'react-redux';
import {
  WorkflowActionType,
  WorkflowBaseDto,
  WorkflowCreateDto,
  WorkflowWhenEventType,
  WorkflowWhenFiltersType,
  WorkflowWhenType,
} from '../types';
import { DialogHeaderTextField } from '../../../../common/create-edit-dialog/DialogHeaderTextField';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
import get from 'lodash/get';

import { Close } from '@sweep-io/sweep-design/dist/icons/Close';
import { Button, colors, IconButton, Typography } from '@sweep-io/sweep-design';
import { FlexBox } from '../../../../common/FlexBox';
import { HubspotFieldsRuleBuilder } from '../hubspot-rule-builder/HubspotFieldsRuleBuilder';
import { uniqueId } from '../../../../../lib/uniqueId';
import { useHubspotWorkflows } from './useHubspotWorkflows';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';
import {
  hasOnlyOneCriteriaAndItIsEmpty,
  validateSweepCriteria,
} from '../../../../common/rule-builder/validateSweepCriteria';
import isEmpty from 'lodash/isEmpty';
import { HubspotHardcodedRuleBuilder } from '../hubspot-rule-builder/HubspotHardcodedRuleBuilder';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { telemetry } from '../../../../../telemetry';
import RestrictedTooltip from '../../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../../common/permissions/usePermission';
import { WorkflowStatusField } from './WorflowStatusField';
import { useHubspot } from '../useHubspot';
import { useRunOnce } from '../../../../common/useRunOnce';
import { SelectorValueTypes } from '../../../../../types/enums/SelectorValueTypes';
import useSendBiEvent from '../../../../../hooks/useSendBiEvent';
import { ACTIONS_EVENTS } from '../../../../../services/events';

export const workflowWhenOptionLabels = {
  [WorkflowWhenType.EVENT]: 'When an event occurs',
  [WorkflowWhenType.FILTERS]: 'When Filter criteria is met',
};

const labelTagStyle = {
  width: 'fit-content',
  borderRadius: '4px',
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: '0 8px',
};

const newWorkflow = (campaignId: string): DeepPartial<WorkflowCreateDto> =>
  ({
    name: '',
    objectName: 'contacts',
    workflowDetails: {
      when: {
        _id: uniqueId(),
        whenType: WorkflowWhenType.FILTERS,
        filterType: WorkflowWhenFiltersType.PROPERTY,
        filter: {
          criteria: [
            {
              criterionId: uniqueId(),
              valueType: 'Literal',
            },
          ],
          criteriaLogic: '1',
        },
      },
      actions: [
        {
          actionType: WorkflowActionType.ENROLL_CAMPAIGN,
          campaignId,
          status: '',
        },
      ],
      onlyEnrollsManually: true,
    },
  }) as WorkflowCreateDto;

const getLabelTag = (label: string, bgColor: string) => {
  return (
    <Box
      sx={{
        ...labelTagStyle,
        background: bgColor,
      }}
    >
      <Typography variant="caption-bold" textAlign="center" color={colors.white}>
        {label}
      </Typography>
    </Box>
  );
};

const WhenTag = () => {
  return getLabelTag('WHEN', colors.sun[500]);
};

const ThenTag = () => {
  return getLabelTag('THEN', colors.gum[500]);
};

const validateWorkflowForm = (workflow: DeepPartial<WorkflowBaseDto>) => {
  if (!workflow) return false;
  if (!workflow.name) return false;
  if (!workflow.objectName) return false;

  if (!workflow.workflowDetails) return false;
  if (!workflow.workflowDetails.actions) return false;
  if (workflow.workflowDetails.actions.length < 1) return false;

  if (!workflow.workflowDetails.when) return false;
  if (!workflow.workflowDetails.when.whenType) return false;
  if (!workflow.workflowDetails.actions[0]?.status) return false;
  if (
    workflow.workflowDetails.when.whenType === WorkflowWhenType.FILTERS &&
    !workflow.workflowDetails.when.filter?.criteria?.length
  ) {
    return false;
  }
  const errors = validateSweepCriteria(
    workflow.workflowDetails.when.filter?.criteria as SweepCriterion[],
    workflow.workflowDetails.when.whenType === WorkflowWhenType.EVENT,
    true,
  );

  const isValid = errors.length === 0;

  if (!isValid) return false;

  return true;
};

const AndThenTag = () => {
  return getLabelTag('AND THEN', colors.gum[500]);
};

const BoxSection = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

export const WorkflowDialog = ({
  workflowId,
  campaignId,
  onClose,
  campaignName,
  isDuplicate,
}: {
  workflowId?: string;
  campaignId: string;
  onClose: () => void;
  campaignName: string;
  isDuplicate?: boolean;
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const { createHubspotWorkflow, updateHubspotWorkflow } = useHubspotWorkflows();
  const sendBiEvent = useSendBiEvent();

  const baseWorkflow = useSelector(selectWorkflowById(workflowId));
  const { addNotification } = useSweepNotifications();
  const { loadContactHubspotFieldsForConnectedHsOrg } = useHubspot();

  useRunOnce(() => {
    loadContactHubspotFieldsForConnectedHsOrg();
  });

  const [editingWorkflow, setCurrentEditingWorkflow] = useState<DeepPartial<WorkflowBaseDto>>(
    () => {
      if (baseWorkflow) {
        const _workflow = cloneDeep({ ...baseWorkflow });
        const id = isDuplicate ? undefined : _workflow.id;
        const name = isDuplicate ? `Copy of ${_workflow.name}` : _workflow.name;

        return {
          id,
          name,
          objectName: _workflow.objectName,
          workflowDetails: { ..._workflow.workflowDetails },
        };
      }

      return newWorkflow(campaignId);
    },
  );
  const [isPristine, setIsPristine] = useState(true);

  // Merge properties recursively to the currentWorkflow.
  const onChangeProperty = useCallback((workflowPartial: DeepPartial<WorkflowBaseDto>) => {
    setCurrentEditingWorkflow((prev) => {
      setIsPristine(false);
      const newWorkflow = { ...prev };
      const when = newWorkflow.workflowDetails?.when;
      if (workflowPartial.workflowDetails?.when?.filter && when?.filter) delete when['filter'];

      merge(newWorkflow, workflowPartial);

      return { ...newWorkflow };
    });
  }, []);

  const when = editingWorkflow.workflowDetails?.when;

  const isInEditMode = Boolean(editingWorkflow.id);

  const deployWorkflowToHubspot = useCallback(async () => {
    setIsSaving(true);
    try {
      const _editingWorkflow = { ...editingWorkflow } as WorkflowBaseDto;

      if (_editingWorkflow?.workflowDetails?.when?.filter?.criteria) {
        if (hasOnlyOneCriteriaAndItIsEmpty(_editingWorkflow.workflowDetails.when.filter.criteria)) {
          _editingWorkflow.workflowDetails.when.filter.criteria = [];
          _editingWorkflow.workflowDetails.when.filter.criteriaLogic = '';
        }
      }

      if (isInEditMode) {
        await updateHubspotWorkflow(_editingWorkflow as Required<WorkflowBaseDto>);
      } else {
        await createHubspotWorkflow(_editingWorkflow as WorkflowCreateDto);
      }
      sendBiEvent({ name: ACTIONS_EVENTS.hubspotEnrollmentRulDeploySuccess });
      setIsSaving(false);
      onClose();
    } catch (e: any) {
      addNotification({
        message: `Deployment to HubSpot Failed`,
        keepOpen: true,
        details: (
          <>
            Please make sure Salesforce integration is enabled in HubSpot. Connect Hubspot to
            Salesforce to deploy campaign enrollment flows directly from Sweep.
            <Button
              variant="link"
              onClick={() =>
                window.open(
                  'https://knowledge.hubspot.com/salesforce/install-the-hubspot-salesforce-integration',
                  '_blank',
                )
              }
            >
              Learn how to set it up.
            </Button>
          </>
        ),
        variant: SweepNotificationVariant.Error,
      });
      telemetry.captureError(e);
      setIsSaving(false);
    }
  }, [
    editingWorkflow,
    isInEditMode,
    sendBiEvent,
    onClose,
    updateHubspotWorkflow,
    createHubspotWorkflow,
    addNotification,
  ]);

  const { openConfirm } = useConfirm();

  const _onClose = useCallback(async () => {
    if (isPristine) {
      onClose?.();
    } else {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (isConfirmed) {
        onClose?.();
      }
    }
  }, [isPristine, onClose, openConfirm]);

  const isValid = validateWorkflowForm(editingWorkflow);
  const [isCreateHubspotOrgsAllowed] = usePermission(['create:hubspot-orgs']);

  const isDisabled = isSaving || !isCreateHubspotOrgsAllowed;

  return (
    <Dialog
      open
      PaperProps={{
        sx: {
          width: '1032px',
          height: '823px',
          margin: 0,
        },
      }}
      onClose={_onClose}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="flex-end"
          gap={2}
          sx={{
            '.MuiButton-contained': {
              minWidth: '200px',
            },
          }}
        >
          <DialogHeaderTextField
            value={editingWorkflow.name as string}
            onChange={(name) => onChangeProperty({ name })}
            placeholder=""
            editDisabled={isDisabled}
          />

          <RestrictedTooltip
            to={['create:hubspot-orgs']}
            notAllowedTitle={'To create Hubspot workflows, please contact your admin'}
          >
            <Button
              onClick={deployWorkflowToHubspot}
              size="small"
              startIconName="Hubspot"
              disabled={!isValid || isDisabled}
              loading={isSaving}
            >
              Deploy to Hubspot
            </Button>
          </RestrictedTooltip>
          <IconButton onClick={_onClose} variant="flat">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingTop: 4 }}>
        <Box display="flex" gap={2} flexDirection="column">
          <BoxSection>
            <WhenTag />
            <Box sx={{ display: 'flex', gap: 1, alignContent: 'center', alignItems: 'center' }}>
              <Select
                value={when?.whenType}
                placeholder="This happens"
                disabled={isDisabled}
                onChange={(e) => {
                  const whenType = e.target.value as WorkflowWhenType;
                  if (whenType === WorkflowWhenType.EVENT) {
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          whenType: WorkflowWhenType.EVENT,
                          filter: null,
                        },
                      },
                    } as any);
                  } else {
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          whenType: WorkflowWhenType.FILTERS,
                          filterType: WorkflowWhenFiltersType.PROPERTY,
                          filter: {
                            _id: uniqueId(),
                          },
                        },
                      },
                    } as any);
                  }
                  onChangeProperty({
                    workflowDetails: {
                      when: {
                        whenType,
                        filter:
                          whenType === WorkflowWhenType.EVENT
                            ? null
                            : {
                                _id: uniqueId(),
                              },
                      },
                    },
                  } as any);
                }}
              >
                <MenuItem value={WorkflowWhenType.EVENT}>
                  {workflowWhenOptionLabels[WorkflowWhenType.EVENT]}
                </MenuItem>
                <MenuItem value={WorkflowWhenType.FILTERS}>
                  {workflowWhenOptionLabels[WorkflowWhenType.FILTERS]}
                </MenuItem>
              </Select>
              {when?.whenType === WorkflowWhenType.EVENT && (
                <Select
                  value={when?.eventType}
                  placeholder="This happens"
                  disabled={isDisabled}
                  onChange={(e) =>
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          eventType: e.target.value as WorkflowWhenEventType,
                          filter: {
                            criteria: [],
                            criteriaLogic: '',
                          },
                        },
                      },
                    })
                  }
                >
                  <MenuItem value={WorkflowWhenEventType.AD_INTERACTION}>
                    has completed: ad interaction
                  </MenuItem>
                  <MenuItem value={WorkflowWhenEventType.FORM_SUBMITTED}>
                    has completed: form interaction
                  </MenuItem>
                  <MenuItem value={WorkflowWhenEventType.EMAIL_REPLY}>
                    has completed: replied to email
                  </MenuItem>
                </Select>
              )}
              {when?.whenType === WorkflowWhenType.FILTERS && (
                <Select
                  value={when?.filterType}
                  placeholder="Filter category"
                  disabled={isDisabled}
                  onChange={(e) =>
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          filterType: e.target.value as WorkflowWhenFiltersType,
                          filter: {
                            criteria: [],
                            criteriaLogic: '',
                          },
                        },
                      },
                    })
                  }
                >
                  <MenuItem value={WorkflowWhenFiltersType.PROPERTY}>Object information</MenuItem>
                  <MenuItem value={WorkflowWhenFiltersType.ADS_SEARCH}>
                    Marketing interactions
                  </MenuItem>
                </Select>
              )}
            </Box>
            {when?.whenType === WorkflowWhenType.FILTERS &&
              when.filterType === WorkflowWhenFiltersType.PROPERTY && (
                <HubspotFieldsRuleBuilder
                  onChange={(filter) => {
                    const _filter = !isEmpty(filter)
                      ? filter
                      : {
                          criteria: [
                            {
                              criterionId: uniqueId(),
                              valueType: 'Literal',
                            },
                          ],
                          criteriaLogic: '1',
                        };
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          filter: _filter,
                        },
                      },
                    });
                  }}
                  sweepCondition={when.filter}
                  readOnly={isDisabled}
                />
              )}
            {when?.whenType === WorkflowWhenType.FILTERS &&
              when.filterType === WorkflowWhenFiltersType.ADS_SEARCH && (
                <HubspotHardcodedRuleBuilder
                  onChange={(filter) => {
                    const _filter = !isEmpty(filter)
                      ? filter
                      : {
                          criteria: [
                            {
                              criterionId: uniqueId(),
                              valueType: SelectorValueTypes.LITERAL,
                            },
                          ],
                          criteriaLogic: '1',
                        };
                    onChangeProperty({
                      workflowDetails: {
                        when: {
                          filter: _filter,
                          filterType: when.filterType as WorkflowWhenFiltersType,
                        },
                      },
                    });
                  }}
                  eventType={when.filterType || WorkflowWhenFiltersType.PROPERTY}
                  readOnly={isDisabled}
                  sweepCondition={when.filter}
                />
              )}
            {when?.whenType === WorkflowWhenType.EVENT && (
              <HubspotHardcodedRuleBuilder
                onChange={(filter) => {
                  const _filter = !isEmpty(filter)
                    ? filter
                    : {
                        criteria: [
                          {
                            criterionId: uniqueId(),
                          },
                        ],
                        criteriaLogic: '1',
                      };
                  onChangeProperty({
                    workflowDetails: {
                      when: {
                        filter: _filter,
                        eventType: when.eventType as WorkflowWhenEventType,
                      },
                    },
                  });
                }}
                eventType={when.eventType || WorkflowWhenEventType.AD_INTERACTION}
                readOnly={isDisabled}
                sweepCondition={when.filter}
              />
            )}
          </BoxSection>
          <BoxSection>
            <ThenTag />
            <Typography variant="body">Set {campaignName}</Typography>
          </BoxSection>
          <BoxSection>
            <AndThenTag />
            <FlexBox
              sx={{
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Typography variant="body">Status is</Typography>
              <WorkflowStatusField
                value={get(editingWorkflow, 'workflowDetails.actions[0].status')}
                onChange={(status) => {
                  onChangeProperty({
                    workflowDetails: {
                      actions: [
                        {
                          _id: uniqueId(),
                          actionType: WorkflowActionType.ENROLL_CAMPAIGN,
                          campaignId,
                          status,
                        },
                      ],
                    },
                  });
                }}
                isDisabled={isDisabled}
                campaignId={campaignId}
              />
            </FlexBox>
          </BoxSection>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
