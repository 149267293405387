import {
  NodeChangeEscapeFromShadowStepType,
  NodeChangeMoveGroupType,
  NodeChangeMoveNode,
  NodeChangeMoveNodeType,
  NodeChangeRemoveGroupType,
  SweepCanvasEdge,
  SweepCanvasEdgeTransformations,
  SweepCanvasGroup,
  SweepCanvasNode,
} from '.';
import { SweepCanvasNodePosition } from '../helpers/calculateHandlePositionsBasedOnCoords';

export interface NodeChangeAddNodeType<GroupMetadata = any> {
  originNodeId?: string;
  newNode: SweepCanvasNode;
  newEdge?: SweepCanvasEdge;
  newGroup?: SweepCanvasGroup<GroupMetadata>;
  nodesToMove: NodeChangeMoveNode[];
  edgeTransformations: SweepCanvasEdgeTransformations;
  parentCanvasNodePosition?: SweepCanvasNodePosition;
}

export enum SweepNodesChangeType {
  AddNode = 'AddNode',
  MoveNode = 'MoveNode',
  MoveGroup = 'MoveGroup',
  EscapeFromShadowStep = 'EscapeFromShadowStep',
  RemoveGroup = 'RemoveGroup',
}

export interface SweepNodesChangeAddNodeEvent {
  type: SweepNodesChangeType.AddNode;
  change: NodeChangeAddNodeType;
}

export interface SweepNodesChangeMoveNodeEvent {
  type: SweepNodesChangeType.MoveNode;
  change: NodeChangeMoveNodeType;
}

export interface SweepNodesChangeMoveGroupEvent {
  type: SweepNodesChangeType.MoveGroup;
  change: NodeChangeMoveGroupType;
}

export interface SweepNodesChangeEscapeFromShadowStep {
  type: SweepNodesChangeType.EscapeFromShadowStep;
  change: NodeChangeEscapeFromShadowStepType;
}

export interface SweepNodesChangeRemoveGroup {
  type: SweepNodesChangeType.RemoveGroup;
  change: NodeChangeRemoveGroupType;
}

export type SweepNodesChangeEvent =
  | SweepNodesChangeAddNodeEvent
  | SweepNodesChangeMoveNodeEvent
  | SweepNodesChangeMoveGroupEvent
  | SweepNodesChangeEscapeFromShadowStep
  | SweepNodesChangeRemoveGroup;
