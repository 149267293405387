import { Popover, PopoverProps } from '@mui/material';

const IntegrationsButtonPopover = (props: PopoverProps) => (
  <Popover
    {...props}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    sx={{
      '& .MuiPopover-paper': {
        p: '8px',
      },
    }}
  />
);

export default IntegrationsButtonPopover;
