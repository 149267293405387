import { useCallback } from 'react';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { useDispatch, useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../pages/environments/environmentsReducer';
import { DeployStatusForTable } from '../../types/enums/DeployStatusForTable';
import { getEditOptimisticStatus } from '../pages/helper';
import {
  addScheduledAssignment,
  requestRefreshGlobal,
  updateScheduledAssignment,
  updateScheduledAssignmentDeployStatus,
  toggleScheduledAssignment as _toggleScheduledAssignment,
  deleteScheduledAssignment as _deleteScheduledAssignment,
} from '../../reducers/global/globalReducer';
import { useSweepNotifications } from '../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../reducers/notificationsReducer';
import { FailedAction } from '../../types/enums/FailedAction';
import { telemetry } from '../../telemetry';

const useScheduledAssignments = () => {
  const {
    create_automation,
    update_automation,
    toggle_automation,
    delete_automation,
    deploy_automation,
  } = useAutomationsApiFacade();
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const crmOrgId = crmOrg?.id;
  const dispatch = useDispatch();
  const { addNotification } = useSweepNotifications();

  const handleError = useCallback(
    (e: any, failedAction: FailedAction = FailedAction.deploy): ErrorInApi => {
      addNotification({
        message: `Failed to ${failedAction} due to technical issues`,
        keepOpen: true,
        details: e.response.data.message,
        variant: SweepNotificationVariant.Error,
      });
      telemetry.captureError(e);
      return { isError: true };
    },
    [addNotification],
  );

  const deployOrRefresh = useCallback(
    ({
      deployToOrgIds,
      deployAssignmentDto,
    }: {
      deployToOrgIds: string[];
      deployAssignmentDto: AutomationStructureNew;
    }) => {
      if (deployToOrgIds.length > 0) {
        deploy_automation(deployAssignmentDto.automationId, deployAssignmentDto.versionId, {
          ...deployAssignmentDto,
          deployToOrgIds,
        });
        //when deploy is finished, a web socket will be sent in order to trigger "requestRefresh"
      } else if (crmOrgId) {
        dispatch(requestRefreshGlobal({ crmOrgId }));
      }
    },
    [crmOrgId, deploy_automation, dispatch],
  );

  const createScheduledAssignment = useCallback(
    async ({
      deployToOrgIds,
      createAutomationDto,
    }: {
      deployToOrgIds: string[];
      createAutomationDto: AutomationStructureNew;
    }) => {
      try {
        const toSend = {
          ...createAutomationDto,
          deployToOrgIds,
        };
        const newAssignment = await create_automation(toSend);
        const shouldDeploy = deployToOrgIds.length > 0;
        if (crmOrgId) {
          dispatch(
            addScheduledAssignment({
              crmOrgId,
              assignment: newAssignment,
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : DeployStatusForTable.Draft,
            }),
          );
        }
        deployOrRefresh({
          deployToOrgIds,
          deployAssignmentDto: newAssignment,
        });
        return newAssignment;
      } catch (e) {
        return handleError(e, FailedAction.create);
      }
    },
    [create_automation, crmOrgId, deployOrRefresh, dispatch, handleError],
  );

  const editScheduledAssignment = useCallback(
    async ({
      deployToOrgIds,
      updateAutomationDto,
      versionId,
    }: {
      updateAutomationDto: AutomationStructureNew;
      deployToOrgIds: string[];
      versionId: string;
    }) => {
      try {
        const toSend = {
          ...updateAutomationDto,
          deployToOrgIds,
        };
        const updatedAssignment = await update_automation(toSend);
        const shouldDeploy = deployToOrgIds.length > 0;
        if (crmOrgId) {
          dispatch(
            updateScheduledAssignment({
              crmOrgId,
              assignmentToUpdate: updatedAssignment,
              versionIdToUpdate: versionId,
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : getEditOptimisticStatus(updateAutomationDto.status),
            }),
          );
        }
        deployOrRefresh({
          deployToOrgIds,
          deployAssignmentDto: updatedAssignment,
        });
        return updatedAssignment;
      } catch (e) {
        return handleError(e, FailedAction.save);
      }
    },
    [update_automation, crmOrgId, deployOrRefresh, dispatch, handleError],
  );

  const deleteScheduledAssignment = useCallback(
    async ({
      automationId,
      automationType,
    }: {
      automationId: string;
      automationType: AutomationType;
    }) => {
      try {
        await delete_automation(automationId, automationType);
        if (crmOrgId) {
          dispatch(
            _deleteScheduledAssignment({
              crmOrgId,
              assignmentIdToDelete: automationId,
            }),
          );
        }
      } catch (e) {
        return handleError(e, FailedAction.delete);
      }
    },
    [delete_automation, crmOrgId, dispatch, handleError],
  );

  const toggleScheduledAssignment = useCallback(
    async ({
      deployToOrgIds,
      updateAutomationDto,
      versionId,
    }: {
      updateAutomationDto: AutomationStructureNew;
      deployToOrgIds: string[];
      versionId: string;
    }) => {
      try {
        const toSend = {
          ...updateAutomationDto,
          deployToOrgIds,
        };
        await toggle_automation(toSend);
        const shouldDeploy = deployToOrgIds.length > 0;

        if (crmOrgId) {
          dispatch(
            _toggleScheduledAssignment({
              assignmentToUpdate: toSend,
              crmOrgId,
              versionIdToUpdate: versionId,
              optimisticStatus: shouldDeploy
                ? DeployStatusForTable.Deployed
                : updateAutomationDto.status,
            }),
          );
        }
      } catch (e) {
        return handleError(
          e,
          updateAutomationDto.isActive ? FailedAction.activate : FailedAction.deactivate,
        );
      }
    },
    [toggle_automation, crmOrgId, dispatch, handleError],
  );

  const deployScheduledAssignment = useCallback(
    async ({
      automationId,
      versionId,
      deployToOrgIds,
      deployAutomationDto,
    }: {
      deployAutomationDto: AutomationStructureNew;
      automationId: string;
      versionId: string;
      deployToOrgIds: string[];
    }) => {
      try {
        const toSend = {
          ...deployAutomationDto,
          deployToOrgIds,
        };
        deploy_automation(automationId, versionId, toSend);
        if (crmOrgId) {
          dispatch(
            updateScheduledAssignmentDeployStatus({
              crmOrgId,
              automationId,
              versionId,
              optimisticStatus: DeployStatusForTable.Deployed,
            }),
          );
        }
        //when deploy is finished, a web socket will be sent in order to trigger "requestRefresh"
      } catch (e) {
        return handleError(e, FailedAction.deploy);
      }
    },
    [deploy_automation, crmOrgId, dispatch, handleError],
  );

  return {
    createScheduledAssignment,
    editScheduledAssignment,
    deleteScheduledAssignment,
    toggleScheduledAssignment,
    deployScheduledAssignment,
  };
};

export default useScheduledAssignments;
