import { useReserveSpaceForRightPanel } from './ReserveSpaceForPanelsCtx';

export const AllocateRightPanelSpace = ({
  children,
  size,
}: {
  children: React.ReactNode;
  size: number;
}) => {
  useReserveSpaceForRightPanel(size);
  return children;
};
