import { Box, TableCell as MuiTableCell } from '@mui/material';
import { TruncatedTextTooltip } from '../TruncatedTextTooltip';
import {
  DataTableBaseColumn,
  DataTableDraggableColumn,
  DataTableRow,
  DataTableSortableColumn,
  DataTableVariant,
} from './TableTypes';
import { colors } from '@sweep-io/sweep-design';
import classNames from 'classnames';
import { dataTableVariants } from './dataTableVariants';
import { ReactNode } from 'react';

interface RowProps<TRow extends DataTableRow> {
  row: TRow;
  columns: DataTableSortableColumn<TRow>[] | DataTableBaseColumn[] | DataTableDraggableColumn[];
  onClick?: (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    columnData: DataTableSortableColumn<TRow> | DataTableBaseColumn | DataTableDraggableColumn,
  ) => void;
  variant: DataTableVariant;
  dragHandle?: ReactNode;
  allowReorder?: boolean;
  isRenderEmpty: boolean;
}

function TableRowCells<TRow extends DataTableRow = any>({
  row,
  columns,
  onClick,
  variant,
  dragHandle,
  allowReorder,
  isRenderEmpty,
}: RowProps<TRow>) {
  const columnWidth = 100 / columns.length; //to prevent jumping when content width changes in table with virtual scroll

  return columns.map((column, index) => {
    const cell = row[column.field];
    const isActionColumn = column.field === '__actions';
    const isPlainText = typeof cell === 'string';
    const withHandle = allowReorder && 'showDragHandle' in column && column.showDragHandle;

    const cellContent = isPlainText ? (
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        className={classNames({
          actions: isActionColumn,
        })}
        justifyContent={column.justifyContent}
      >
        <TruncatedTextTooltip
          variant={dataTableVariants[variant].fontVariant}
          color={row.disabled ? dataTableVariants[variant].fontColor : undefined}
        >
          {cell}
        </TruncatedTextTooltip>
      </Box>
    ) : (
      <Box
        className={classNames({
          actions: isActionColumn,
        })}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '& .MuiTypography-root': {
            color: dataTableVariants[variant].fontColor,
          },
          '& .avatar .MuiTypography-root': {
            color: colors.blue[500],
          },
        }}
        justifyContent={column.justifyContent}
      >
        {cell}
      </Box>
    );

    return (
      <MuiTableCell
        sx={{
          padding: dataTableVariants[variant].cellPadding,
          height: dataTableVariants[variant].minRowHeight,
          width: column.width ?? columnWidth + '%',

          color: dataTableVariants[variant].fontColor,
          '& .MuiTypography-root': {
            color: dataTableVariants[variant].fontColor,
          },
          cursor: !!onClick ? 'pointer' : 'default',
        }}
        className={classNames(column.className, 'SweepDataTableCell')}
        key={`row${row.id}-col${index}`}
        onClick={!!onClick ? (e) => onClick(e, column) : undefined}
      >
        {!isRenderEmpty && withHandle && (
          <Box
            sx={{
              width: 'fit-content',
              display: 'flex',
              gap: '12px',
              alignItems: 'center',
              justifyContent: 'center',

              color: dataTableVariants[variant].fontColor,
              '& .MuiTypography-root': {
                color: dataTableVariants[variant].fontColor,
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'grab',
              }}
            >
              <Box display="flex">{dragHandle}</Box>
            </Box>
            {cellContent}
          </Box>
        )}
        {!isRenderEmpty && !withHandle && <Box>{cellContent}</Box>}
      </MuiTableCell>
    );
  });
}

export default TableRowCells;
