import { ReactNode } from 'react';
import NotificationDot from './NotificationDot';
import { Box } from '@mui/material';

const WithNotificationDot = ({ showDot, children }: { showDot: boolean; children: ReactNode }) => (
  <Box position="relative" display="flex" alignItems="center">
    {children}
    {showDot && (
      <Box sx={{ position: 'absolute', top: '-1px', right: '-1px' }}>
        <NotificationDot />
      </Box>
    )}
  </Box>
);

export default WithNotificationDot;
