import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSweepApi } from '../../apis/sweep';
import {
  createCommentThread,
  createCommentReply,
  deleteCommentReply,
  editCommentReply,
  fetchedComments,
  updateCommentThread,
  deleteCommentThread,
} from '../../reducers/commentsReducer';
import { uniqueId } from '../../lib/uniqueId';
import { undo } from '../../reducers/undoable';
import isNil from 'lodash/isNil';
import { telemetry } from '../../telemetry';
import {
  CommentCreateRequest,
  CommentThreadProps,
  CommentReplyCreateRequest,
  CommentReplyDeleteRequest,
  ReplyEditRequest,
} from './types';

const useComments = () => {
  const dispatch = useDispatch();
  const sweepApi = useSweepApi();

  const createNewComment = useCallback(
    async (newComment: CommentCreateRequest) => {
      try {
        const response = await sweepApi.post(`/comments/${newComment.funnelMapId}`, newComment);
        /**  the action needs to have the same name as the ws message **/
        /**  the payload needs to have the same structure as the ws message **/
        dispatch(createCommentThread({ success: true, data: response.data as CommentThreadProps }));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [dispatch, sweepApi],
  );

  const fetchCommentThreads = useCallback(
    async (funnelMapId: string, excludeResolved?: boolean) => {
      try {
        const { data } = await sweepApi.get(
          `/comments/${funnelMapId}?excludeResolved=${Boolean(excludeResolved)}`,
        );
        dispatch(fetchedComments({ ...data, isResolvedFetched: !Boolean(excludeResolved) }));
      } catch (e) {
        telemetry.captureError(e);
      }
    },
    [dispatch, sweepApi],
  );

  const addReply = useCallback(
    async ({
      commentThreadId,
      funnelMapId,
      commentBody,
      userId,
      mentionedUserIds,
      isRead,
    }: CommentReplyCreateRequest) => {
      /**  the action needs to have the same name as the ws message **/
      /**  the payload needs to have the same structure as the ws message **/
      const transientId = uniqueId();
      const optimisticAction = createCommentReply({
        success: true,
        data: {
          commentThreadId,
          funnelMapId,
          commentReply: {
            commentBody,
            createdById: userId,
            mentionedUserIds,
          },
          transientId,
          isRead,
        },
      });
      dispatch(optimisticAction);
      try {
        await sweepApi.post(`/comments/${funnelMapId}/${commentThreadId}/commentReplies`, {
          commentBody,
          mentionedUserIds,
          transientId,
        });
      } catch (e) {
        telemetry.captureError(e);
        dispatch(undo(optimisticAction));
      }
    },
    [dispatch, sweepApi],
  );

  const deleteReply = useCallback(
    async ({ commentThreadId, funnelMapId, commentReplyId }: CommentReplyDeleteRequest) => {
      /**  the action needs to have the same name as the ws message **/
      /**  the payload needs to have the same structure as the ws message **/
      const optimisticAction = deleteCommentReply({
        success: true,
        data: {
          commentThreadId,
          funnelMapId,
          commentReplyId,
        },
      });
      dispatch(optimisticAction);
      try {
        await sweepApi.delete(
          `/comments/${funnelMapId}/${commentThreadId}/commentReplies/${commentReplyId}`,
        );
      } catch (e) {
        telemetry.captureError(e);
        dispatch(undo(optimisticAction));
      }
    },
    [dispatch, sweepApi],
  );

  const deleteThread = useCallback(
    async ({ commentThreadId, funnelMapId }: { commentThreadId: string; funnelMapId: string }) => {
      /**  the action needs to have the same name as the ws message **/
      /**  the payload needs to have the same structure as the ws message **/
      const optimisticAction = deleteCommentThread({
        success: true,
        data: {
          commentThreadId,
          funnelMapId,
        },
      });
      dispatch(optimisticAction);
      try {
        await sweepApi.delete(`/comments/${funnelMapId}/${commentThreadId}`);
      } catch (e) {
        telemetry.captureError(e);
        dispatch(undo(optimisticAction));
      }
    },
    [dispatch, sweepApi],
  );

  const editReply = useCallback(
    async ({
      commentThreadId,
      funnelMapId,
      commentReplyId,
      commentBody,
      mentionedUserIds,
    }: ReplyEditRequest) => {
      /**  the action needs to have the same name as the ws message **/
      /**  the payload needs to have the same structure as the ws message **/
      const optimisticAction = editCommentReply({
        success: true,
        data: {
          commentThreadId,
          funnelMapId,
          commentReplyId,
          commentBody,
          mentionedUserIds,
        },
      });
      dispatch(optimisticAction);
      try {
        await sweepApi.patch(
          `/comments/${funnelMapId}/${commentThreadId}/commentReplies/${commentReplyId}`,
          {
            commentBody,
            mentionedUserIds,
          },
        );
      } catch (e) {
        telemetry.captureError(e);
        dispatch(undo(optimisticAction));
      }
    },
    [dispatch, sweepApi],
  );

  const updateThread = useCallback(
    async ({
      commentThreadId,
      funnelMapId,
      positionX,
      positionY,
      isResolved,
    }: {
      commentThreadId: string;
      funnelMapId: string;
      positionX?: number;
      positionY?: number;
      isResolved?: boolean;
    }) => {
      /**  the action needs to have the same name as the ws message **/
      /**  the payload needs to have the same structure as the ws message **/
      const optimisticAction = updateCommentThread({
        success: true,
        data: { id: commentThreadId, funnelMapId, positionX, positionY, isResolved },
      });
      dispatch(optimisticAction);
      try {
        if (!isNil(positionX) && !isNil(positionY)) {
          await sweepApi.patch(`/comments/${funnelMapId}/${commentThreadId}/position`, {
            positionX,
            positionY,
          });
        } else if (!isNil(isResolved)) {
          await sweepApi.patch(`/comments/${funnelMapId}/${commentThreadId}/resolved`, {
            isResolved,
          });
        }
      } catch (e) {
        telemetry.captureError(e);
        dispatch(undo(optimisticAction));
      }
    },
    [dispatch, sweepApi],
  );

  const updateThreadIsRead = useCallback(
    async ({
      commentThreadId,
      funnelMapId,
      isRead,
    }: {
      commentThreadId: string;
      funnelMapId: string;
      isRead: boolean;
    }) => {
      /**  the action needs to have the same name as the ws message **/
      /**  the payload needs to have the same structure as the ws message **/
      const optimisticAction = updateCommentThread({
        success: true,
        data: { id: commentThreadId, funnelMapId, isRead },
      });
      dispatch(optimisticAction);
      try {
        await sweepApi.patch(`/comments/${funnelMapId}/${commentThreadId}/read`, { isRead });
      } catch (e) {
        telemetry.captureError(e);
        dispatch(undo(optimisticAction));
      }
    },
    [dispatch, sweepApi],
  );

  return {
    createNewComment,
    fetchCommentThreads,
    addReply,
    deleteReply,
    editReply,
    deleteThread,
    updateThread,
    updateThreadIsRead,
  };
};

export default useComments;
