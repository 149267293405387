import { Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import usePaywall from '../paywall/usePaywall';
import pluralize from 'pluralize';

const PaywallDashboardHeader = () => {
  const { daysLeft } = usePaywall();

  if (!daysLeft) {
    return null;
  }

  return (
    <Stack alignItems="flex-end">
      <Typography variant="caption" color="#FF43F5">
        Free trial
      </Typography>
      <Typography variant="caption" color={colors.white}>
        {daysLeft} {pluralize('day', daysLeft)} left
      </Typography>
    </Stack>
  );
};

export default PaywallDashboardHeader;
