import { useSelector } from 'react-redux';
import { selectSweepFieldLabels } from './sweepFieldsLabelsReducer';
import { useCallback } from 'react';
import { SelectorValueTypes } from '../types/enums/SelectorValueTypes';
import isArray from 'lodash/isArray';
import { NestedSelectorPath } from '../components/common/NestedSelector/NestedSelector';
import { getNestedPath } from '../components/Automations/helper';

export const useSweepFieldsLabels = () => {
  const sweepFieldsLabels = useSelector(selectSweepFieldLabels);

  const getLabelInfoFromId = useCallback(
    (id: string) => sweepFieldsLabels[id],
    [sweepFieldsLabels],
  );

  const getLabelsFromIds = useCallback(
    (ids: string[]) => ids.map((id) => sweepFieldsLabels[id]?.sweepFieldName || ''),
    [sweepFieldsLabels],
  );

  const getLabelFromIdsWithObjectType = useCallback(
    (ids: string[]) => {
      if (ids.length === 0) {
        return [];
      }
      const objectName = sweepFieldsLabels[ids[0]]?.objectName;
      return [objectName || '', ...ids.map((id) => sweepFieldsLabels[id]?.sweepFieldName || '')];
    },
    [sweepFieldsLabels],
  );

  const enrichCriteriaWithLabels = useCallback(
    (criteria: SweepCriterion): SweepCriterionWithLabels => {
      const _valueLabels =
        criteria.valueType === SelectorValueTypes.REFERENCE && isArray(criteria.value)
          ? getLabelFromIdsWithObjectType(criteria.value)
          : undefined;
      return {
        ...criteria,
        _fieldLabels: getLabelFromIdsWithObjectType(criteria._fieldIds),
        _valueLabels,
      };
    },
    [getLabelFromIdsWithObjectType],
  );
  const removeLabelsFromCriteria = useCallback(
    (criteria: SweepCriterionWithLabels): SweepCriterion => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { _fieldLabels, _valueLabels, ...rest } = criteria;
      return rest;
    },
    [],
  );

  const getEnrichedNestedPath = useCallback(
    (_fieldIds: string | string[] | undefined): NestedSelectorPath => {
      if (_fieldIds == undefined) {
        return getNestedPath(undefined, undefined);
      }
      const _fieldIdsArray = isArray(_fieldIds) ? _fieldIds : [_fieldIds];
      const _fieldLabels = getLabelFromIdsWithObjectType(_fieldIdsArray);
      return getNestedPath(_fieldIds, _fieldLabels);
    },
    [getLabelFromIdsWithObjectType],
  );

  return {
    getLabelsFromIds,
    getLabelFromIdsWithObjectType,
    enrichCriteriaWithLabels,
    removeLabelsFromCriteria,
    getEnrichedNestedPath,
    getLabelInfoFromId,
  };
};
