import {
  AttributeProps,
  CASE_SENSITIVE,
  DEFAULT_VALUE,
  DEFAULT_VALUE_FORMULA,
  DISPLAY_LOCATION_IN_DECIMAL,
  EditMode,
  IS_UNIQUE,
  LENGTH,
  LENGTH_INPUT_PROPS,
  LENGTH_LABEL,
  SCALE,
  SCALE_INPUT_PROPS,
  SCALE_LABEL,
} from '../../../../../constants/fieldsManagementConsts';
import { Box, FormControlLabel } from '@mui/material';
import { DateSelector } from '../../../../common/rule-builder/selectors/DateSelector';
import { DatetimeSelector } from '../../../../common/rule-builder/selectors/DatetimeSelector';
import AttributeTextToggle from './AttributeTextToggle';
import AttributeTextField from './AttributeTextField';
import AttributePicklist from './AttributePicklist';
import { TimeSelector } from '../../../../common/rule-builder/selectors/TimeSelector';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___, Checkbox } from '@sweep-io/sweep-design';
import { SweepFieldTypes } from '../../../../../types/enums/SweepFieldTypes';
import { DateTime } from 'luxon';

const isCheckedOptions = [
  { value: false, label: 'Unchecked' },
  { value: true, label: 'Checked' },
];

const isUniqueOptions = [
  { value: false, label: 'Duplicate values' },
  { value: true, label: 'Only unique value' },
];

const displayLocationInDecimalOptions = [
  { value: false, label: 'Degrees, minutes, seconds' },
  { value: true, label: 'Decimal' },
];

const FORMULA_TOOLTIP =
  'The default value is a formula, so currently it cannot be edited in Sweep. This feature will be available in the future.';

const FormulaTextField = (props: AttributeProps) => (
  <AttributeTextField
    id="default"
    label="Default value"
    attributeName={DEFAULT_VALUE_FORMULA}
    disabled={true}
    disabledTooltip={FORMULA_TOOLTIP}
    {...props}
  />
);

const AttributesPerFieldType = (props: AttributeProps) => {
  const { setFieldValue, values, mode } = props;
  const { fieldType, properties } = values;
  const isEditMode = mode === EditMode.EDIT;
  const { unique, defaultValueFormula, defaultValue } = properties ?? {};
  const isDefaultValueFormula = isEditMode && Boolean(defaultValueFormula);
  const defaultValueProps = {
    id: 'default',
    label: 'Default value',
    attributeName: DEFAULT_VALUE,
  };

  return (
    <>
      {fieldType === SweepFieldTypes.Checkbox && (
        <AttributeTextToggle
          attributeName={DEFAULT_VALUE}
          prefix="Default value is"
          items={isCheckedOptions}
          defaultValue={false}
          {...props}
        />
      )}
      {fieldType === SweepFieldTypes.Date && (
        <Box>
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <DateSelector
              label="Default value"
              placeholder="Add"
              shouldBeDisabled={false}
              initialValue={defaultValue as string}
              onChange={(value) => {
                setFieldValue(`properties.${DEFAULT_VALUE}`, value);
              }}
            />
          )}
        </Box>
      )}
      {fieldType === SweepFieldTypes.DateTime && (
        <Box>
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <DatetimeSelector
              label="Default value"
              placeholder="Add"
              shouldBeDisabled={false}
              value={defaultValue ? DateTime.fromSQL(defaultValue as string) : undefined}
              onChange={(value) => {
                setFieldValue(
                  `properties.${DEFAULT_VALUE}`,
                  value?.toSQL({ includeOffset: false }) || '',
                );
              }}
            />
          )}
        </Box>
      )}
      {fieldType === SweepFieldTypes.Time && (
        <Box>
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <TimeSelector
              label="Default value"
              placeholder="Add"
              shouldBeDisabled={false}
              initialValue={defaultValue as string}
              onChange={(value) => {
                setFieldValue(`properties.${DEFAULT_VALUE}`, value);
              }}
            />
          )}
        </Box>
      )}
      {fieldType === SweepFieldTypes.Email && (
        <>
          <AttributeTextToggle
            attributeName={IS_UNIQUE}
            prefix="Field allows"
            items={isUniqueOptions}
            defaultValue={false}
            disabled={isEditMode}
            {...props}
          />
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <AttributeTextField {...defaultValueProps} {...props} />
          )}
        </>
      )}
      {fieldType === SweepFieldTypes.Geolocation && (
        <>
          <AttributeTextToggle
            attributeName={DISPLAY_LOCATION_IN_DECIMAL}
            label="Latitude and Longitude Display Notation"
            items={displayLocationInDecimalOptions}
            defaultValue={false}
            {...props}
          />
          {properties?.displayLocationInDecimal && (
            <AttributeTextField
              id="scale"
              label={SCALE_LABEL}
              attributeName={SCALE}
              type="number"
              inputProps={SCALE_INPUT_PROPS}
              {...props}
            />
          )}
        </>
      )}
      {fieldType === SweepFieldTypes.Number && (
        <>
          <AttributeTextField
            id="length"
            label={LENGTH_LABEL}
            attributeName={LENGTH}
            type="number"
            inputProps={LENGTH_INPUT_PROPS}
            isRequired
            {...props}
          />
          <AttributeTextField
            id="scale"
            label={SCALE_LABEL}
            attributeName={SCALE}
            type="number"
            inputProps={SCALE_INPUT_PROPS}
            isRequired
            {...props}
          />
          <AttributeTextToggle
            attributeName={IS_UNIQUE}
            prefix="Field allows"
            items={isUniqueOptions}
            defaultValue={false}
            disabled={isEditMode}
            {...props}
          />
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <AttributeTextField type="number" {...defaultValueProps} {...props} />
          )}
        </>
      )}
      {fieldType === SweepFieldTypes.Currency && (
        <>
          <AttributeTextField
            attributeName={LENGTH}
            id="length"
            label={LENGTH_LABEL}
            type="number"
            inputProps={LENGTH_INPUT_PROPS}
            isRequired
            {...props}
          />
          <AttributeTextField
            attributeName={SCALE}
            id="scale"
            label={SCALE_LABEL}
            type="number"
            inputProps={SCALE_INPUT_PROPS}
            isRequired
            {...props}
          />
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <AttributeTextField type="number" {...defaultValueProps} {...props} />
          )}
        </>
      )}
      {fieldType === SweepFieldTypes.Percent && (
        <>
          <AttributeTextField
            id="length"
            label={LENGTH_LABEL}
            attributeName={LENGTH}
            type="number"
            inputProps={LENGTH_INPUT_PROPS}
            isRequired
            {...props}
          />
          <AttributeTextField
            id="scale"
            label={SCALE_LABEL}
            attributeName={SCALE}
            type="number"
            inputProps={SCALE_INPUT_PROPS}
            isRequired
            {...props}
          />
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <AttributeTextField type="number" {...defaultValueProps} {...props} />
          )}
        </>
      )}
      {fieldType === SweepFieldTypes.Phone &&
        (isDefaultValueFormula ? (
          <FormulaTextField {...props} />
        ) : (
          <AttributeTextField {...defaultValueProps} {...props} />
        ))}
      {fieldType === SweepFieldTypes.Text && (
        <>
          <AttributeTextField
            id="length"
            label="Length (maximum number of characters)"
            attributeName={LENGTH}
            type="number"
            inputProps={{ min: 0, max: 255 }}
            {...props}
          />
          <AttributeTextToggle
            attributeName={IS_UNIQUE}
            prefix="Field allows"
            items={isUniqueOptions}
            defaultValue={false}
            disabled={isEditMode}
            onChangeSideEffect={(value: boolean) => {
              //if "isUnique" is switched back to "false", the "caseSensitive" can't remain "true"
              if (!value) {
                setFieldValue(`properties.${CASE_SENSITIVE}`, false);
              }
            }}
            {...props}
          />
          {unique && (
            <FormControlLabel
              label="Case sensitive"
              control={
                <Checkbox
                  disabled={isEditMode}
                  id="caseSensitive"
                  value={values.properties?.[CASE_SENSITIVE]}
                  onChange={(value) =>
                    setFieldValue(`properties.${CASE_SENSITIVE}`, value.target.checked)
                  }
                />
              }
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontSize,
                  fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['body']?.fontWeight,
                },
              }}
            />
          )}
          {isDefaultValueFormula ? (
            <FormulaTextField {...props} />
          ) : (
            <AttributeTextField {...defaultValueProps} {...props} />
          )}
        </>
      )}
      {fieldType === SweepFieldTypes.Picklist && <AttributePicklist {...props} />}
      {fieldType === SweepFieldTypes.MultiselectPicklist && <AttributePicklist {...props} />}
      {fieldType === SweepFieldTypes.TextArea &&
        (isDefaultValueFormula ? (
          <FormulaTextField {...props} />
        ) : (
          <AttributeTextField type="textarea" {...defaultValueProps} {...props} />
        ))}
      {fieldType === SweepFieldTypes.Url &&
        (isDefaultValueFormula ? (
          <FormulaTextField {...props} />
        ) : (
          <AttributeTextField {...defaultValueProps} {...props} />
        ))}
    </>
  );
};

export default AttributesPerFieldType;
