import { CanvasUnitedLeftMenu } from './left-menu/CanvasUnitedLeftMenu';
import { useConfigurationCanvas } from '../../configuration-canvas/public/useConfigurationCanvas';
import { useFunnelMapPage } from '../../funnel-map-flow/useFunnelMapPage';
import { CanvasUnitedRightMenu } from './CanvasUnitedRightMenu';
import { DashboardHeaderContainer } from './DashboardHeaderContainer';

export const UnitedDashboardHeader = () => {
  const { appCrmOrgId, canvasFunnelMap } = useConfigurationCanvas();
  const { isFunnelMapEmpty } = useFunnelMapPage();

  return (
    <DashboardHeaderContainer>
      <CanvasUnitedLeftMenu crmOrgId={appCrmOrgId} funnelMapEmpty={isFunnelMapEmpty} />
      <CanvasUnitedRightMenu funnelMap={canvasFunnelMap} funnelMapEmpty={isFunnelMapEmpty} />
    </DashboardHeaderContainer>
  );
};
