import { FunnelMapPutPayload } from './useFunnelMapApiFacade';

// Convert FunnelMap to FunnelMapPutPayload
export const funnelMapToFunnelMapPutPayload = (funnelMap: FunnelMap): FunnelMapPutPayload => {
  const funnelsArray = Object.keys(funnelMap.funnels).map((funnelId) => ({
    funnelId,
    ...funnelMap.funnels[funnelId].position,
    isHidden: !!funnelMap.funnels[funnelId].isHidden,
  }));
  const recordTypesArray = Object.keys(funnelMap.recordTypes).map((recordTypeApiName) => {
    const [objectName, recordTypeName] = recordTypeApiName.split('.');
    return {
      recordTypeName,
      objectName,
      ...funnelMap.recordTypes[recordTypeApiName].position,
      leadingFieldName: funnelMap.recordTypes[recordTypeApiName].leadingFieldName,
      isHidden: !!funnelMap.recordTypes[recordTypeApiName].isHidden,
    };
  });

  const hubspotFunnelsArray: FunnelMapPutPayload['hubspotFunnels'] = Object.keys(
    funnelMap.hubspotFunnels,
  ).map((id) => ({
    id,
    ...funnelMap.hubspotFunnels[id].position,
    leadingFieldName: funnelMap.hubspotFunnelsData[id].leadingField.name,
    objectName: funnelMap.hubspotFunnelsData[id].objectName,
  }));

  const thirdPartyFunnelsArray: FunnelMapPutPayload['thirdPartyFunnels'] = Object.keys(
    funnelMap.thirdPartyFunnels,
  ).map((thirdPartyFunnelId) => ({
    thirdPartyFunnelId,
    ...funnelMap.thirdPartyFunnels[thirdPartyFunnelId].position,
    isHidden: false,
  }));

  return {
    funnels: funnelsArray,
    name: funnelMap.name,
    recordTypes: recordTypesArray,
    hubspotFunnels: hubspotFunnelsArray,
    thirdPartyFunnels: thirdPartyFunnelsArray,
  };
};
