import { Box, Stack } from '@mui/material';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

const TrialCarouselStep = ({
  title,
  imgSrc,
  content1,
  content2,
  children,
}: {
  title: string;
  imgSrc: string;
  content1: string;
  content2?: string;
  children: ReactNode;
}) => {
  return (
    <Stack height="100%">
      <Box sx={{ height: '194px' }}>
        <img src={imgSrc} alt={title} style={{ height: '100%' }} />
      </Box>
      <Stack padding={3} flex={1}>
        <Stack gap={2} flex={1}>
          <Typography variant="h1">{title}</Typography>
          <Typography variant="body" color={colors.grey[800]}>
            {content1}
          </Typography>
          {content2 && (
            <Typography variant="body" color={colors.grey[800]}>
              {content2}
            </Typography>
          )}
        </Stack>
        {children}
      </Stack>
    </Stack>
  );
};

export default TrialCarouselStep;
