import {
  StageMetadataForecastCategory,
  StageMetadataType,
} from '../types/enums/StageMetadataTypes';

export const REGULAR_OPPORTUNITY_STAGE_DEFAULT: StageMetadata = {
  stageName: '',
  leadingFieldId: '',
  probability: 0,
  forecastCategory: StageMetadataForecastCategory.PIPELINE,
  type: StageMetadataType.OPEN,
};

export const NURTURING_OPPORTUNITY_STAGE_DEFAULT: StageMetadata = {
  stageName: '',
  leadingFieldId: '',
  probability: 0,
  forecastCategory: StageMetadataForecastCategory.OMITTED,
  type: StageMetadataType.OPEN,
};

export const LEAD_DEFAULT_METADATA: StageMetadata = {
  leadingFieldId: '',
  stageName: '',
  converted: false,
};

export const CASE_STATUS_DEFAULT_METADATA: StageMetadata = {
  leadingFieldId: '',
  stageName: '',
  closed: false,
};
