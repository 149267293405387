import { SortOrder } from '../components/common/types';

export const sortLexicographically = ({
  items,
  sortKey,
  sortOrder,
}: {
  items?: any[];
  sortKey?: string;
  sortOrder?: SortOrder;
}) => {
  if (!items) {
    return;
  }

  const multiplyBy = (!sortOrder || sortOrder === SortOrder.ASC) ? 1 : -1;

  return sortKey
    ? items.sort(
        (a, b) => a[sortKey].toLowerCase().localeCompare(b[sortKey].toLowerCase()) * multiplyBy,
      )
    : items.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()) * multiplyBy);
};
