import RestrictedTooltip from './permissions/RestrictedTooltip';
import React from 'react';
import { Permission } from '@server/permissions';
import { PropsWithChildren } from 'react';
import usePermission from './permissions/usePermission';

//This component is adding a tooltip and a "disabled" prop to the children, according to the user's permissions
const WithPermissions = (
  props: PropsWithChildren<{ permissionStr: Permission[]; disabledTooltip: string }>,
) => {
  const { children, permissionStr, disabledTooltip } = props;
  const [isAllowed] = usePermission(permissionStr);

  const childrenWithProps = React.isValidElement(children)
    ? React.cloneElement(children, {
        ...children.props,
        disabled: !isAllowed,
      })
    : undefined;

  return (
    <RestrictedTooltip to={permissionStr} notAllowedTitle={disabledTooltip}>
      {childrenWithProps}
    </RestrictedTooltip>
  );
};

export default WithPermissions;
