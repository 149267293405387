import { AnyAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import React from 'react';

export enum SweepNotificationVariant {
  Default = 'default',
  Error = 'error',
  Success = 'success',
  Warning = 'Warning',
  Info = 'info',
}

export interface NotificationAction {
  actionText: string;
  reduxAction: AnyAction;
}

export type SweepNotification = {
  key: string;
  message: string;
  action?: NotificationAction;
  variant: SweepNotificationVariant;
  dismissed?: boolean;
  keepOpen?: boolean;
  details?: string | React.ReactNode;
};

export interface NotificationsState {
  notifications: SweepNotification[];
}

const initialState: NotificationsState = {
  notifications: [],
};

export const notificationsReducer = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<SweepNotification>) => {
      state.notifications.push(action.payload);
    },
    closeNotification: (state, action: PayloadAction<string>) => {
      const idx = state.notifications.findIndex(({ key }) => key === action.payload);
      if (idx >= 0) {
        state.notifications[idx].dismissed = true;
      }
    },
    removeNotification: (state, action: PayloadAction<string>) => {
      const idx = state.notifications.findIndex(({ key }) => key === action.payload);
      if (idx >= 0) {
        state.notifications.splice(idx, 1);
      }
    },
    clearNotifications: () => {
      return initialState;
    },
  },
});

export const { addNotification, removeNotification, closeNotification, clearNotifications } =
  notificationsReducer.actions;

export const selectNotifications = (state: RootState) => state.notifications.notifications;

export default notificationsReducer.reducer;
