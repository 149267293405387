import { colors, Radio, Typography, Button } from '@sweep-io/sweep-design';
import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Box,
  Stack,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { BaseDialog } from '../dialogs/BaseDialog';

const AssignPermissionSetDialog = ({
  onConfirm,
  closeDialog,
  connectedUser,
}: {
  onConfirm: ({ assignToAll }: { assignToAll: boolean }) => Promise<void>;
  closeDialog: () => void;
  connectedUser?: string;
}) => {
  const [assignToAll, setAssignToAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onChange = (assignToAll: boolean) => setAssignToAll(assignToAll);

  const onClick = async () => {
    setIsLoading(true);
    await onConfirm({ assignToAll });
    setIsLoading(false);
  };

  return (
    <BaseDialog open={true} onClose={closeDialog} PaperProps={{ sx: { width: '538px' } }}>
      <Box sx={{ padding: '32px 32px 8px 32px' }}>
        <Typography variant="h1">Assign Sweep Permissions in Salesforce</Typography>
      </Box>
      <DialogContent sx={{ padding: '0 32px 20px 32px' }}>
        <Stack gap={2.5}>
          <Typography variant="body" color={colors.grey[800]}>
            Assign Sweep Permission Group to relevant Salesforce users for triggering Sweep
            automation, validation, alerts, or rules.
          </Typography>
          <Stack gap={1.5}>
            <Typography variant="body-medium">Assign to</Typography>
            <FormControl>
              <RadioGroup
                value={assignToAll}
                onChange={(event) => {
                  const valueAsBoolean = event.target.value === 'true';
                  onChange(valueAsBoolean);
                }}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={
                    <Typography variant="body">
                      Only admin user {connectedUser ? `(${connectedUser})` : ''}{' '}
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={<Typography variant="body">All users</Typography>}
                />
              </RadioGroup>
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ paddingTop: 0 }}>
        <Button onClick={closeDialog} variant="outlined">
          Cancel
        </Button>
        <Button onClick={onClick} loading={isLoading}>
          Assign Permissions
        </Button>
      </DialogActions>
    </BaseDialog>
  );
};

export default AssignPermissionSetDialog;
