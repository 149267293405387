import { Box, Stack } from '@mui/material';
import { Button } from '@sweep-io/sweep-design';
import { useState } from 'react';
import RestrictedTooltip from '../../../common/permissions/RestrictedTooltip';
import usePermission from '../../../common/permissions/usePermission';
import { AssignmentGroup } from '../../../../reducers/assignmentGroupTypes';
import { useAssignmentsApiWithReducer } from '../useAssignmentsApiWithReducer';
import { Permission } from '@server/permissions';
import EmptyStateWithButton from '../../../common/empty-state/EmptyStateWithButton';
import GroupsEmptyImage from '../../../common/empty-state/svgs/groupsEmpty.svg?react';
import { GroupSettingsWizard } from '../group-settings/group-settings-wizard/GroupSettingsWizard';
import { selectDefaultCreationCrmOrgId } from '../../../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';
import { DialogLoader } from '../../../common/dialogs/DialogLoader';
import GroupsTable from './GroupsTable';
import { SearchInput } from '../../../common/SearchInput';
import pluralize from 'pluralize';
import GroupsTerritoriesLayout from '../../../routing/GroupsTerritoriesLayout';
import { useRoutingContext } from '../../../routing/RoutingContext';
import { SEARCH_BOX_WIDTH } from '../../../Automations/helper';

const permissionString: Permission[] = ['create:assignment-groups'];
const ADD_NOT_ALLOWED_TITLE = 'To create a new assignment group, please contact your admin';

interface GroupsListProps {
  assignmentsGroupList?: AssignmentGroup[];
  onGroupClick: (groupId: string) => void;
  onClose?: () => void;
  isDialogLayout?: boolean;
}

export const GroupsList = ({
  assignmentsGroupList,
  onGroupClick,
  onClose,
  isDialogLayout,
}: GroupsListProps) => {
  const { groupsSearch, setGroupsSearch } = useRoutingContext();
  const { createNewAssignmentGroup } = useAssignmentsApiWithReducer();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);
  const [isAllowedBtn] = usePermission(permissionString);
  const [membersDialogOpen, setMembersDialogOpen] = useState(false);

  const openMembersDialog = () => setMembersDialogOpen(true);

  const isEmpty = assignmentsGroupList?.length === 0;

  const groupsWithSearch = assignmentsGroupList?.filter((group) =>
    group.name.toLowerCase().includes(groupsSearch.toLowerCase()),
  );

  const renderDialogs = () => {
    if (membersDialogOpen && crmOrgId) {
      return (
        <GroupSettingsWizard
          crmOrgId={crmOrgId}
          onClose={() => setMembersDialogOpen(false)}
          onSave={async (assignmentGroupData, resetAllMembersLimit) => {
            await createNewAssignmentGroup(
              assignmentGroupData as AssignmentGroup,
              resetAllMembersLimit,
            );
          }}
        />
      );
    }
  };

  const renderContent = () => {
    if (isEmpty) {
      return (
        <Stack pt="135px">
          <EmptyStateWithButton
            image={<GroupsEmptyImage />}
            notAllowedTitle={ADD_NOT_ALLOWED_TITLE}
            permissionString={['create:assignment-groups']}
            line1="Create your first Assignment Group"
            onClick={openMembersDialog}
          />
        </Stack>
      );
    }

    return (
      <>
        <DialogLoader visible={!assignmentsGroupList} />
        {groupsWithSearch && (
          <GroupsTable
            onEdit={onGroupClick}
            groups={groupsWithSearch}
            onClearFilters={() => setGroupsSearch('')}
          />
        )}
      </>
    );
  };

  const searchJsx = (
    <Box width={SEARCH_BOX_WIDTH}>
      <SearchInput
        withFixedMagnifyingGlassIcon
        TextFieldProps={{
          value: groupsSearch,
          placeholder: 'Search groups',
          onChange: (e) => {
            setGroupsSearch(e.target.value);
          },
        }}
        onClearButtonClick={() => setGroupsSearch('')}
        variant="small"
      />
    </Box>
  );

  const newGroupButtonJsx = (
    <RestrictedTooltip to={permissionString} notAllowedTitle={ADD_NOT_ALLOWED_TITLE}>
      <Button
        disabled={!isAllowedBtn}
        onClick={openMembersDialog}
        startIconName="Plus"
        size="small"
      >
        New group
      </Button>
    </RestrictedTooltip>
  );

  return (
    <GroupsTerritoriesLayout
      title="Groups"
      isDialogLayout={isDialogLayout}
      onClose={onClose}
      newButtonJsx={newGroupButtonJsx}
      searchJsx={searchJsx}
      countText={pluralize('assignment group', assignmentsGroupList?.length, true)}
      renderContent={renderContent}
      renderDialogs={renderDialogs}
    />
  );
};
