import { Box } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { ChevronDown, ChevronUp } from '@sweep-io/sweep-design/dist/icons';
import { DASHBOARD_BUTTON_HOVER_COLOR } from '../../../dashboard/dashboardHelper';

interface MenuButtonProps {
  pressed?: boolean;
  anchorRef?: any;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children: React.ReactNode;
}

export const MenuButton = ({ anchorRef, pressed, onClick: onClick, children }: MenuButtonProps) => {
  return (
    <Box
      ref={anchorRef}
      onClick={onClick}
      className={pressed ? 'menu-button-pressed' : ''}
      sx={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        height: '32px',
        gap: 1,
        '&:hover, &.menu-button-pressed': {
          background: DASHBOARD_BUTTON_HOVER_COLOR,
        },
        padding: '0 8px',
        borderRadius: '4px',
      }}
    >
      {children}
      {pressed ? <ChevronUp color={colors.white} /> : <ChevronDown color={colors.white} />}
    </Box>
  );
};
