import { Button, colors, Typography } from '@sweep-io/sweep-design';
import { InfoDialog } from '../dialogs/InfoDialog';
import { Stack, TextField } from '@mui/material';
import { AutomationsDiffDialog } from '../../Automations/AutomationsDiffDialog';
import { useState } from 'react';
import { useAutomationsContext } from '../../Automations/AutomationsContext';

interface RequestToDeployDialogProps {
  onCloseDialog: () => void;
  onRequestToDeploy: (note: string) => any;
  buttonDisabled?: boolean;
  envName: string;
}

export const RequestToDeployDialog = ({
  onCloseDialog,
  envName,
  onRequestToDeploy,
}: RequestToDeployDialogProps) => {
  const [isDiffDialogOpen, setIsDiffDialogOpen] = useState(false);

  const { automationJson } = useAutomationsContext();
  const [note, setNote] = useState('');
  const hideReviewChangesButton = !automationJson?.automationId;

  return (
    <>
      <InfoDialog
        handleClose={(e) => {
          e.stopPropagation();
          onCloseDialog();
        }}
        open={true}
        PaperPropsSx={{ width: '592px' }}
        showCloseButton
        titleJsx={
          <Typography variant="h1">{`Request deployment of ${automationJson.name}`}</Typography>
        }
        dialogContentSx={{ paddingBottom: 0 }}
        dialogActions={
          <>
            {!hideReviewChangesButton && (
              <Button variant="outlined" onClick={() => setIsDiffDialogOpen(true)}>
                Review Changes
              </Button>
            )}
            <Button onClick={() => onRequestToDeploy(note)}>Request to deploy</Button>
          </>
        }
      >
        <Stack gap={2} marginTop={1}>
          <Typography variant="body">
            Your Sweep admin(s) will be notified about your request to deploy to {envName}{' '}
            environment. Your automation will be saved
          </Typography>
          <Stack gap={0.5}>
            <Typography variant="caption" color={colors.grey[800]}>
              Note
            </Typography>
            <TextField
              value={note}
              multiline
              color="secondary"
              maxRows={2}
              onChange={(e) => setNote(e.target.value)}
              placeholder="Share what changes were made with your Sweep admin to help them understand what has changed in this funnel."
            />
          </Stack>
        </Stack>
      </InfoDialog>
      {isDiffDialogOpen && (
        <AutomationsDiffDialog
          onCloseDialog={() => setIsDiffDialogOpen(false)}
          automationItem={automationJson}
          hideDeployButtons
        />
      )}
    </>
  );
};
