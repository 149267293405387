import { Autocomplete, Box, FormControl, MenuItem, TextField } from '@mui/material';
import { useCallback, useState } from 'react';
import FieldLabel from '../../common/FieldLabel';
import { useSelector } from 'react-redux';
import {
  selectSlackIntegrationStatus,
  SlackConnectionStatus,
} from '../../../reducers/integrationsReducer';
import { selectProductionCrmOrg } from '../../pages/environments/environmentsReducer';
import { Typography, colors } from '@sweep-io/sweep-design';
import { SlackRecipientsSelect } from './SlackRecipientsSelect';
import { useSlackRecipients } from './useSlackRecipients';
import { SlackNewMessageEditor } from './SlackNewMessageEditor';
import { useSweepApi } from '../../../apis/sweep';
import { useRunOnce } from '../../common/useRunOnce';
import { SfdcReportAttributes } from '../SalesforceReportSelector';
import { CenteredCircularProgress } from '../../common/CenteredCircularProgress';
import { ChevronDown } from '@sweep-io/sweep-design/dist/icons/ChevronDown';
import { useGetSlackRecipientsLabels } from './useGetSlackRecipientsLabels';

interface SendReportToSlackActionProps {
  readonly?: boolean;
  action: SendReportToSlackAction;
  crmOrgId: string;
  onChange: (slackAutomationAction: SendReportToSlackAction) => any;
  objectName: string;
}

export const SendReportToSlackAction = ({
  readonly,
  action,
  crmOrgId,
  objectName,
  onChange,
}: SendReportToSlackActionProps) => {
  const slackStatus = useSelector(selectSlackIntegrationStatus);
  const productionOrg = useSelector(selectProductionCrmOrg);
  const sweepApi = useSweepApi();

  const [sfReports, setSfReports] = useState<SfdcReportAttributes[]>();
  const [loading, setLoading] = useState(true);
  const { recipientWithLabelsArray } = useGetSlackRecipientsLabels({
    recipients: action.actionParams?.recipients ?? [],
  });

  useRunOnce(async () => {
    try {
      const response = await sweepApi.get(`/reporting/reports`);
      const types = response.data;
      setSfReports(types);
    } catch {
      setSfReports([]);
    }
    setLoading(false);
  });

  const updateOnChange = useCallback(
    (updateProps: Partial<AutomationSendReportToSlackActionParams>) => {
      onChange({
        ...action,
        actionParams: {
          ...action.actionParams,
          ...updateProps,
        },
      });
    },
    [action, onChange],
  );

  const { isLoading } = useSlackRecipients({
    objectName,
    crmOrgId,
    disableSalesforceRecipients: true,
  });

  const handleDeleteChip = (chipToRemove: SlackRecipientStruct) => {
    const newRecipients = action.actionParams.recipients?.filter((item) => {
      if (chipToRemove.type === 'Slack') {
        return item.value !== chipToRemove.value;
      }
    });
    updateOnChange({ recipients: newRecipients });
  };

  const _onChange = useCallback(
    (event: any, newValues: any) => {
      const newItems = newValues.map((el: any) => {
        return {
          ...el,
          type: 'Slack',
          value: el.value,
        };
      });
      updateOnChange({ recipients: newItems as SlackRecipientStruct[] });
    },
    [updateOnChange],
  );

  return (
    <>
      <Box
        sx={{
          background: colors.grey[100],
          padding: '24px 24px 19px',
          borderRadius: '8px',
          margin: '16px 0',
        }}
      >
        <Box sx={{ marginBottom: '20px', display: 'flex', gap: 2, alignItems: 'center' }}>
          <Typography variant="body" textAlign="center">
            Send a Salesforce report to Slack:
          </Typography>
          <>
            {loading && <CenteredCircularProgress circularProgressProps={{ size: '20px' }} />}
            {!loading && sfReports && (
              <Autocomplete
                sx={{
                  minWidth: '160px',
                  '& .MuiAutocomplete-inputRoot': {
                    rowGap: '8px',
                    paddingTop: '6px',
                    paddingBottom: '6px',

                    '& fieldset': {
                      borderWidth: `thin solid ${colors.grey[300]}`,
                    },
                    '& .MuiAutocomplete-input': {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                  },
                  '#sf-reports': {
                    width: 'fit-content',
                  },
                }}
                options={sfReports}
                disablePortal
                value={
                  sfReports.find((el) => el.name === action.actionParams?.reportName) ?? undefined
                }
                onChange={(e, val) => updateOnChange({ reportName: val.name })}
                renderInput={(params) => <TextField {...params} placeholder="Choose report" />}
                popupIcon={<ChevronDown />}
                getOptionLabel={(options) => options.name}
                isOptionEqualToValue={(option: any, value) => option?.name === value?.name}
                id="sf-reports"
                disabled={readonly}
                disableClearable
                renderOption={(props, option: SfdcReportAttributes) => {
                  return (
                    <MenuItem {...props} key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  );
                }}
              />
            )}
          </>
        </Box>
        <FormControl
          sx={{
            width: '100%',
            marginBottom: '20px',
            maxWidth: '1148px',
          }}
        >
          <Box
            sx={{
              marginBottom: '3px',
              '& div': {
                color: colors.grey[800],
                fontSize: '12px',
                fontWeight: '400',
              },
            }}
          >
            <FieldLabel label={'Recipients'} markLabelAsRequired={true} />
          </Box>

          <SlackRecipientsSelect
            isLoading={isLoading}
            onChange={_onChange}
            readonly={!!readonly}
            recipients={recipientWithLabelsArray}
            handleDeleteChip={handleDeleteChip}
            notConnected={!productionOrg || slackStatus !== SlackConnectionStatus.Connected}
          />
        </FormControl>

        <Box sx={{ position: 'relative' }}>
          <SlackNewMessageEditor
            placeholder="e.g. These are our renewal opportunities for the next month. Let`s win them 🤝🏆"
            label="Message text"
            readonly={readonly}
            messageStruct={{ template: action.actionParams?.message ?? '', variables: [] }}
            crmOrgId={crmOrgId}
            onChange={(newMsg) => {
              updateOnChange({ message: newMsg.template });
            }}
            objectName={objectName}
            isHideDynamicField={true}
          />
        </Box>
      </Box>
    </>
  );
};
