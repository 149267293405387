import React, { useState } from 'react';
import noop from 'lodash/noop';
import { HighlightNodeData } from '../useHighlightNode';

interface CanvasHiglightContextType {
  highlightedEdgeId?: string;
  setHighlightedEdgeId: (edgeId: string | undefined) => void;
  highlightedNodeData?: HighlightNodeData;
  setHighlightedNodeData: (nodeData: HighlightNodeData | undefined) => void;
}

export const CanvasHighlightContext = React.createContext<CanvasHiglightContextType>({
  setHighlightedEdgeId: noop,
  setHighlightedNodeData: noop,
});

export const CanvasHighlightProvider = ({ children }: { children: React.ReactNode }) => {
  const [highlightedEdgeId, setHighlightedEdgeId] = useState<string>();
  const [highlightedNodeData, setHighlightedNodeData] = useState<HighlightNodeData>();

  return (
    <CanvasHighlightContext.Provider
      value={{
        highlightedEdgeId,
        setHighlightedEdgeId,
        highlightedNodeData,
        setHighlightedNodeData,
      }}
    >
      {children}
    </CanvasHighlightContext.Provider>
  );
};
