import { Button, Grid } from '@mui/material';
import { SweepLogoLight } from '../../icons/generalIcons';
import useSweepNavigate from './useSweepNavigate';
import { Typography } from '@sweep-io/sweep-design';

const ErrorPage = ({ path = '/funnels' }: { path?: string }) => {
  const { navigate } = useSweepNavigate();

  return (
    <Grid container justifyContent="center" alignContent="center" height="100vh">
      <Grid container flexWrap="wrap" justifyContent="center" gap="40px" maxWidth="350px">
        <SweepLogoLight />
        <Typography variant="h4" textAlign="center">
          We cannot load this page right now, please try again later
        </Typography>

        <Button onClick={() => navigate(path)}>Return to the main page</Button>
      </Grid>
    </Grid>
  );
};

export { ErrorPage };
