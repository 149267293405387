import { Box, TextField } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';

export const Note = ({
  label = 'Note',
  placeholder = 'Add note',
  onNoteChange,
}: {
  label?: string;
  placeholder?: string;
  onNoteChange: (note: string) => void;
}) => {
  return (
    <Box>
      <Typography variant="caption" color={colors.grey[800]}>
        {label}
      </Typography>
      <TextField
        sx={{
          marginTop: '5px',
        }}
        multiline
        fullWidth
        placeholder={placeholder}
        onChange={(event) => onNoteChange && onNoteChange(event.target.value)}
      />
    </Box>
  );
};
