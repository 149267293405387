import * as React from "react";
const SvgMeddic = (props) => /* @__PURE__ */ React.createElement("svg", { width: 40, height: 40, viewBox: "0 0 40 40", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 40, height: 40, rx: 4, fill: "#373650", style: {
  fill: "#373650",
  fill: "color(display-p3 0.2157 0.2118 0.3137)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.1816 17.7276L14.1816 12.6375C14.1816 11.9347 13.6119 11.365 12.9091 11.365L7.81906 11.365C7.11627 11.365 6.54655 11.9347 6.54655 12.6375L6.54655 17.7276C6.54655 18.4304 7.11627 19.0001 7.81906 19.0001L12.9091 19.0001C13.6119 19.0001 14.1816 18.4304 14.1816 17.7276Z", fill: "#FFADF7", style: {
  fill: "#FFADF7",
  fill: "color(display-p3 1.0000 0.6792 0.9679)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M23.8174 17.7276L23.8174 12.6375C23.8174 11.9347 23.2477 11.365 22.5449 11.365L17.4548 11.365C16.752 11.365 16.1823 11.9347 16.1823 12.6375L16.1823 17.7276C16.1823 18.4304 16.752 19.0001 17.4548 19.0001L22.5449 19.0001C23.2477 19.0001 23.8174 18.4304 23.8174 17.7276Z", fill: "#A5BEFF", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M33.4521 17.7276L33.4521 12.6375C33.4521 11.9347 32.8824 11.365 32.1796 11.365L27.0896 11.365C26.3868 11.365 25.8171 11.9347 25.8171 12.6375L25.8171 17.7276C25.8171 18.4304 26.3868 19.0001 27.0896 19.0001L32.1796 19.0001C32.8824 19.0001 33.4521 18.4304 33.4521 17.7276Z", fill: "#C5F2AC", style: {
  fill: "#C5F2AC",
  fill: "color(display-p3 0.7725 0.9490 0.6745)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M14.1816 27.3626L14.1816 22.2725C14.1816 21.5697 13.6119 21 12.9091 21L7.81906 21C7.11627 21 6.54655 21.5697 6.54655 22.2725L6.54655 27.3626C6.54655 28.0654 7.11627 28.6351 7.81906 28.6351L12.9091 28.6351C13.6119 28.6351 14.1816 28.0654 14.1816 27.3626Z", fill: "#C7ADFF", style: {
  fill: "#C7ADFF",
  fill: "color(display-p3 0.7818 0.6792 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M23.8174 27.3626L23.8174 22.2725C23.8174 21.5697 23.2477 21 22.5449 21L17.4548 21C16.752 21 16.1823 21.5697 16.1823 22.2725L16.1823 27.3626C16.1823 28.0654 16.752 28.6351 17.4548 28.6351L22.5449 28.6351C23.2477 28.6351 23.8174 28.0654 23.8174 27.3626Z", fill: "#F3B3A1", style: {
  fill: "#F3B3A1",
  fill: "color(display-p3 0.9529 0.7020 0.6314)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M33.4521 27.3626L33.4521 22.2725C33.4521 21.5697 32.8824 21 32.1796 21L27.0896 21C26.3868 21 25.8171 21.5697 25.8171 22.2725L25.8171 27.3626C25.8171 28.0654 26.3868 28.6351 27.0896 28.6351L32.1796 28.6351C32.8824 28.6351 33.4521 28.0654 33.4521 27.3626Z", fill: "#FFD351", style: {
  fill: "#FFD351",
  fill: "color(display-p3 1.0000 0.8275 0.3176)",
  fillOpacity: 1
} }));
export default SvgMeddic;
