import { Box, Collapse, TextField } from '@mui/material';
import { useState } from 'react';
import { ChevronDown, ChevronUp, CloudCheck } from '@sweep-io/sweep-design/dist/icons';
import { Switch, Typography, colors } from '@sweep-io/sweep-design';
import RequiredDot from '../../common/RequiredDot';
import { uniqueId } from '../../../lib/uniqueId';
import { settingsPanelLayout } from './helpers';

interface SettingsCloudingoPanelProps {
  readonly?: boolean;
  action?: DeduplicationMergeRecordsWebhookAction;
  onChange: (data?: DeduplicationMergeRecordsWebhookAction) => any;
}

const SettingsCloudingoPanel = ({ readonly, action, onChange }: SettingsCloudingoPanelProps) => {
  const [isPanelExpanded, setIsPanelExpanded] = useState(!!action);

  return (
    <Box sx={{ ...settingsPanelLayout }}>
      <Collapse in={isPanelExpanded} collapsedSize={24}>
        <Box className="panelHeader" onClick={() => setIsPanelExpanded(!isPanelExpanded)}>
          <Box sx={{ display: 'flex', gap: '16px' }}>
            <CloudCheck variant="large" />
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '2px' }}>
              <Typography variant="body-bold">Automatically merge leads with Cloudingo</Typography>
              <Typography variant="body">
                Sweep would identify duplicates and send them to Cloudingo for merging
              </Typography>
            </Box>
          </Box>
          <Box>
            {isPanelExpanded ? <ChevronUp variant="large" /> : <ChevronDown variant="large" />}
          </Box>
        </Box>
        <Box sx={{ marginTop: '16px', marginLeft: '44px' }}>
          <Box sx={{ display: 'flex', gap: 2, mb: '16px' }}>
            <Typography variant="body">Activate merge with Cloudingo</Typography>
            <Switch
              disabled={readonly}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const isOn = event.target.checked;
                const tmpObj = action?.actionParams ?? {
                  url: 'https://api.cloudingo.com/rest/MergeRecords',
                  integrationKey: '',
                  integrationPassword: '',
                };

                const tempAction: DeduplicationMergeRecordsWebhookAction = {
                  _id: action?._id ?? uniqueId(),
                  actionType: 'SEND_MERGE_RECORDS_WEBHOOK',
                  actionParams: tmpObj,
                };
                onChange(isOn ? tempAction : undefined);
              }}
              checked={!!action}
            />
          </Box>
          {!!action && (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <Box sx={{ width: '50%', display: 'flex', gap: '12px', alignItems: 'center' }}>
                  <Box sx={{ flex: '1' }}>
                    <Typography variant="body" color={colors.grey[800]}>
                      API key
                    </Typography>
                    <RequiredDot />
                  </Box>

                  <Box sx={{ flex: '1' }}>
                    <Typography variant="body" color={colors.grey[800]}>
                      Password
                    </Typography>
                    <RequiredDot />
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{ width: '50%', display: 'flex', gap: '12px', alignItems: 'center', mb: '8px' }}
              >
                <Box sx={{ flex: '1' }}>
                  <TextField
                    disabled={readonly}
                    value={action?.actionParams?.integrationKey}
                    onChange={(event: { target: { value: any } }) => {
                      const val = event.target.value;
                      const tmpObj = {
                        ...action,
                        actionParams: { ...action?.actionParams, integrationKey: val },
                      };
                      onChange(tmpObj as DeduplicationMergeRecordsWebhookAction);
                    }}
                    placeholder={'Insert API key'}
                  />
                </Box>
                <Box sx={{ flex: '1' }}>
                  <TextField
                    disabled={readonly}
                    value={action?.actionParams?.integrationPassword}
                    onChange={(event: { target: { value: any } }) => {
                      const val = event.target.value;
                      const tmpObj = {
                        ...action,
                        actionParams: { ...action?.actionParams, integrationPassword: val },
                      };
                      onChange(tmpObj as DeduplicationMergeRecordsWebhookAction);
                    }}
                    placeholder={'Insert password'}
                  />
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

export default SettingsCloudingoPanel;
