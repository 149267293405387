import * as React from "react";
const SvgStar = (props) => /* @__PURE__ */ React.createElement("svg", { width: 28, height: 28, viewBox: "0 0 28 28", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M14.4812 20.8578L19.9938 24.3578C20.7047 24.8063 21.5797 24.1391 21.3719 23.3188L19.775 17.0407C19.7318 16.8666 19.7387 16.684 19.7948 16.5137C19.8509 16.3434 19.954 16.1924 20.0922 16.0782L25.0359 11.9547C25.6813 11.4188 25.3531 10.336 24.5109 10.2813L18.0578 9.86565C17.8817 9.85541 17.7125 9.79409 17.5707 9.68921C17.4289 9.58432 17.3207 9.44039 17.2594 9.27503L14.8531 3.21565C14.7894 3.04055 14.6734 2.88928 14.5208 2.78239C14.3681 2.6755 14.1863 2.61816 14 2.61816C13.8137 2.61816 13.6319 2.6755 13.4792 2.78239C13.3266 2.88928 13.2106 3.04055 13.1469 3.21565L10.7406 9.27503C10.6793 9.44039 10.5711 9.58432 10.4293 9.68921C10.2875 9.79409 10.1183 9.85541 9.94219 9.86565L3.48906 10.2813C2.64687 10.336 2.31875 11.4188 2.96406 11.9547L7.90781 16.0782C8.04599 16.1924 8.14908 16.3434 8.2052 16.5137C8.26131 16.684 8.26818 16.8666 8.225 17.0407L6.74844 22.8594C6.49687 23.8438 7.54687 24.6422 8.38906 24.1063L13.5188 20.8578C13.6626 20.7664 13.8295 20.7178 14 20.7178C14.1705 20.7178 14.3374 20.7664 14.4812 20.8578Z", fill: "#FCE624", stroke: "#FED044", style: {
  fill: "#FCE624",
  fill: "color(display-p3 0.9882 0.9035 0.1412)",
  fillOpacity: 1,
  stroke: "#FED044",
  stroke: "color(display-p3 0.9961 0.8157 0.2667)",
  strokeOpacity: 1
}, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round" }));
export default SvgStar;
