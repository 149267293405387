import { useEffect, useRef } from 'react';
import { useUnhappyStates } from '../pages/environments/useUnhappyStates';
import { ACTIONS_EVENTS } from '../../services/events';
import useSendBiEvent from '../../hooks/useSendBiEvent';

const useWhenInitialFetchDone = () => {
  const { isInitialFetch } = useUnhappyStates();
  const isInitialFetchPrevState = useRef(isInitialFetch);
  const sendBiEvent = useSendBiEvent();

  useEffect(() => {
    if (isInitialFetch) {
      isInitialFetchPrevState.current = true;
      return;
    }
    const doneFetching = !isInitialFetch && isInitialFetchPrevState.current === true;
    if (doneFetching) {
      sendBiEvent({ name: ACTIONS_EVENTS.fetchDoneInSession });
      isInitialFetchPrevState.current = false;
    }
  }, [isInitialFetch, sendBiEvent]);
};

export default useWhenInitialFetchDone;
