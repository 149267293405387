import * as React from "react";
const SvgP = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FFE9E0", style: {
  fill: "#FFE9E0",
  fill: "color(display-p3 1.0000 0.9154 0.8792)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M6.78242 18.2093C6.78242 18.5959 7.09582 18.9093 7.48242 18.9093H9.73212C10.1187 18.9093 10.4321 18.5959 10.4321 18.2093V14.7131H12.1091C13.1504 14.7131 14.0779 14.5222 14.8598 14.1023L14.8598 14.1023L14.8628 14.1007C15.6292 13.6843 16.2256 13.1026 16.6296 12.358C17.0277 11.6241 17.2176 10.7992 17.2176 9.90183C17.2176 9.01217 17.0275 8.19243 16.6309 7.46021C16.2319 6.7121 15.6403 6.12758 14.8773 5.71035C14.1014 5.28383 13.1775 5.09053 12.1395 5.09053H7.48242C7.09582 5.09053 6.78242 5.40393 6.78242 5.79053V18.2093ZM11.806 11.4636H10.4321V8.37033H11.7938C12.2835 8.37033 12.6183 8.45407 12.8406 8.57278L12.8405 8.57284L12.8489 8.57718C13.0887 8.70109 13.2419 8.85964 13.3401 9.05362L13.3401 9.05363L13.3431 9.05957C13.4575 9.28028 13.5254 9.55463 13.5254 9.90183C13.5254 10.2488 13.4576 10.5266 13.3416 10.7532L13.3416 10.7532L13.3386 10.7591C13.2371 10.9621 13.0804 11.1276 12.8412 11.2581C12.6197 11.3789 12.2887 11.4636 11.806 11.4636Z", fill: "#FF572E", stroke: "white", style: {
  fill: "#FF572E",
  fill: "color(display-p3 1.0000 0.3422 0.1792)",
  fillOpacity: 1,
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.4, strokeLinejoin: "round" }));
export default SvgP;
