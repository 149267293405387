import { useCallback, useState } from 'react';
import { useUserInputsApiFacade } from '../../../../../apis/facades/useUserInputsApiFacade';
import { useDispatch } from 'react-redux';
import {
  setCommentsForConfiguration,
  addCommentForConfiguration,
  updateCommentForConfiguration,
  deleteCommentForConfiguration,
} from '../../../../../reducers/documentationReducer';
import { telemetry } from '../../../../../telemetry';
import { ConfigurationComment } from './types';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';

export const useConfigurationComments = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const {
    get_configurationComments,
    post_configurationComment,
    patch_configurationCommentProperties,
    put_configurationComment,
    delete_configurationComment,
  } = useUserInputsApiFacade();
  const { addNotification } = useSweepNotifications();

  const notifyUser = useCallback(
    (failedAction: string) => {
      addNotification({
        message: `Failed to ${failedAction} due to technical issues`,
        keepOpen: true,
        variant: SweepNotificationVariant.Error,
      });
    },
    [addNotification],
  );

  const fetchConfigurationComments = useCallback(
    async (configurationId: string, crmOrgId: string) => {
      try {
        const comments = await get_configurationComments(configurationId, crmOrgId);
        dispatch(setCommentsForConfiguration({ crmOrgId, configurationId, comments }));
      } catch (error) {
        telemetry.captureError(error);
      }
    },
    [get_configurationComments, dispatch],
  );

  const addConfigurationComment = useCallback(
    async (
      newComment: Pick<ConfigurationComment, 'commentBody' & 'mentionedUserIds' & 'properties'>,
      configurationId: string,
      crmOrgId: string,
    ) => {
      setIsLoading(true);
      try {
        const comment = await post_configurationComment(newComment, configurationId, crmOrgId);
        dispatch(addCommentForConfiguration({ crmOrgId, newComment: comment, configurationId }));
      } catch (error) {
        telemetry.captureError(error);
        notifyUser('add comment');
      }
      setIsLoading(false);
    },
    [post_configurationComment, dispatch, notifyUser],
  );

  const updateConfigurationComment = useCallback(
    (comment: ConfigurationComment, configurationId: string, crmOrgId: string) => {
      try {
        put_configurationComment(comment, configurationId, crmOrgId);
        dispatch(updateCommentForConfiguration({ crmOrgId, newComment: comment, configurationId }));
      } catch (error) {
        telemetry.captureError(error);
        notifyUser('update comment');
      }
    },
    [put_configurationComment, dispatch, notifyUser],
  );

  const pinConfigurationComment = useCallback(
    (comment: ConfigurationComment, configurationId: string, crmOrgId: string) => {
      try {
        patch_configurationCommentProperties({
          commentId: comment.id,
          configurationId,
          crmOrgId,
          commentProperties: comment.properties,
        });
        dispatch(updateCommentForConfiguration({ crmOrgId, newComment: comment, configurationId }));
      } catch (error) {
        telemetry.captureError(error);
        notifyUser('pin comment');
      }
    },
    [patch_configurationCommentProperties, dispatch, notifyUser],
  );

  const deleteConfigurationComment = useCallback(
    async (commentId: string, configurationId: string, crmOrgId: string) => {
      try {
        delete_configurationComment(commentId, configurationId, crmOrgId);
        dispatch(deleteCommentForConfiguration({ crmOrgId, commentId, configurationId }));
      } catch (error) {
        telemetry.captureError(error);
        notifyUser('delete comment');
      }
    },
    [delete_configurationComment, dispatch, notifyUser],
  );

  return {
    fetchConfigurationComments,
    addConfigurationComment,
    updateConfigurationComment,
    pinConfigurationComment,
    deleteConfigurationComment,
    isLoading,
  };
};
