import { useDispatch, useSelector } from 'react-redux';
import {
  closeFieldsDialog,
  createOrUpdateField,
  selectFieldsDialogContent,
  selectFieldToEdit,
  selectIsFieldsDialogOpen,
  setDialogContent,
} from '../../../../../reducers/funnel-flow-page/fieldsReducer';
import FieldsTables from './FieldsTables';
import { CenteredCircularProgress } from '../../../../common/CenteredCircularProgress';
import { useCallback, useState } from 'react';
import useManageFields from './useManageFields';
import { Dialog } from '@mui/material';
import {
  EditMode,
  FieldsDialogContentOptions,
} from '../../../../../constants/fieldsManagementConsts';
import CreateEditField from './create-field-dialog/CreateEditField';
import useConfirm from '../../../../common/dialogs/ConfirmLeaveWithoutSave/useConfirm';
import { ConfirmLeaveWithoutSaveDialog } from '../../../../common/dialogs/ConfirmLeaveWithoutSave/ConfirmLeaveWithoutSaveDialog';

const { CreateField, EditField, FieldsTable } = FieldsDialogContentOptions;

const FieldsManagementDialog = ({
  crmOrgId,
  funnelMapId,
}: {
  crmOrgId: string;
  funnelMapId: string;
}) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsFieldsDialogOpen);
  const closeDialog = () => dispatch(closeFieldsDialog());
  const { fieldsUsage, isLoading } = useManageFields({ funnelMapId });
  const dialogContent = useSelector(selectFieldsDialogContent);
  const fieldToEdit = useSelector(selectFieldToEdit);
  const [isPristine, setIsPristine] = useState(false);
  const { openConfirm } = useConfirm();

  const onCreateUpdateFieldCb = useCallback(
    (field: SweepField) => {
      dispatch(createOrUpdateField({ field }));
    },
    [dispatch],
  );

  const returnToFieldsTable = () => {
    dispatch(setDialogContent({ dialogContent: FieldsTable, fieldToEdit: undefined }));
  };

  const handleTryCloseDialog = async () => {
    if (dialogContent === FieldsDialogContentOptions.FieldsTable) {
      closeDialog();
      return;
    }
    if (!isPristine) {
      const isConfirmed = await openConfirm(<ConfirmLeaveWithoutSaveDialog />);
      if (!isConfirmed) return;
    }
    returnToFieldsTable();
  };

  const showLoader = isLoading || !fieldsUsage;

  if (!funnelMapId) {
    return null;
  }

  return (
    <Dialog
      keepMounted
      open={isOpen}
      onClose={handleTryCloseDialog}
      PaperProps={{
        sx: {
          height: 'calc(100vh - 100px)',
          width: '1266px',
          maxHeight: '770px',
          position: 'relative'
        },
      }}
    >
      {showLoader && <CenteredCircularProgress />}
      {!showLoader && (
        <>
          {dialogContent === FieldsTable && (
            <FieldsTables fields={fieldsUsage} crmOrgId={crmOrgId} />
          )}
          {dialogContent === CreateField && (
            <CreateEditField
              crmOrgId={crmOrgId}
              mode={EditMode.CREATE}
              onCreateFieldCb={onCreateUpdateFieldCb}
              closeDialog={returnToFieldsTable}
              setIsPristine={setIsPristine}
              handleTryCloseDialog={handleTryCloseDialog}
            />
          )}
          {dialogContent === EditField && (
            <CreateEditField
              crmOrgId={crmOrgId}
              mode={EditMode.EDIT}
              onUpdateFieldCb={onCreateUpdateFieldCb}
              initialValues={fieldToEdit?.field}
              isLeadingField={
                fieldToEdit?.leadingInFunnels && fieldToEdit.leadingInFunnels.length > 0
              }
              closeDialog={returnToFieldsTable}
              setIsPristine={setIsPristine}
              handleTryCloseDialog={handleTryCloseDialog}
            />
          )}
        </>
      )}
    </Dialog>
  );
};

export default FieldsManagementDialog;
