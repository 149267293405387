import { ButtonBase, TooltipProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@sweep-io/sweep-design';
import { Delete as DeleteIcon, Plus as PlusSignIcon } from '@sweep-io/sweep-design/dist/icons';
import NbIcon from '../../svg/nb-icon.svg?react';
import ConnectStepIcon from '../../svg/connect-step.svg?react';
import StyledTooltip from '../../../common/StyledTooltip';
import { memo } from 'react';
import usePermission from '../../../common/permissions/usePermission';

const actionButtonDimensions = {
  width: 32,
  height: 32,
};

const CanvasSquaredButtonBase = styled(ButtonBase)(() => ({
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
  animation: 'fadeIn .5s',
  background: '#FFFFFF',
  border: '2px solid',
  borderColor: colors.blue[500],
  borderRadius: '6px',
  width: actionButtonDimensions.width,
  height: actionButtonDimensions.height,
  '&.regular': {
    '& svg': {
      fill: colors.blue[500],
    },

    '&:hover svg': {
      fill: colors.white,
    },
    '&:hover': {
      background: colors.blue[500],
    },
  },
  '&.inverted': {
    '& svg path': {
      stroke: colors.blue[500],
    },

    '&:hover svg path': {
      stroke: colors.white,
    },
    '&:hover': {
      background: colors.blue[500],
    },
  },
}));

interface CanvasActionButtonProps extends Pick<TooltipProps, 'placement'> {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => any;
  className?: 'regular' | 'inverted';
  tooltipTitle?: string;
  children?: React.ReactElement;
}

const CanvasActionButton = ({
  onClick,
  children,
  tooltipTitle = '',
  placement,
  className = 'regular',
}: CanvasActionButtonProps) => {
  const [isAllowedToEditFunnel] = usePermission(['edit:funnels']);

  return (
    <div>
      <StyledTooltip title={tooltipTitle} placement={placement}>
        <CanvasSquaredButtonBase
          className={`fade-in ${className} `}
          onClick={(evt) => {
            evt.stopPropagation();
            onClick && onClick(evt);
          }}
          disabled={!isAllowedToEditFunnel}
        >
          {children}
        </CanvasSquaredButtonBase>
      </StyledTooltip>
    </div>
  );
};

const ActionButtonAdd = memo((props: CanvasActionButtonProps) => (
  <CanvasActionButton placement="top" tooltipTitle="Add Step" {...props} className="inverted">
    <PlusSignIcon />
  </CanvasActionButton>
));

const ActionButtonDelete = memo((props: CanvasActionButtonProps) => (
  <CanvasActionButton placement="top" tooltipTitle="Delete connection" {...props}>
    <DeleteIcon />
  </CanvasActionButton>
));

const ActionButtonNurturingStep = memo((props: CanvasActionButtonProps) => (
  <CanvasActionButton placement="bottom" tooltipTitle="Add Nurturing Step" {...props}>
    <NbIcon height="11px" />
  </CanvasActionButton>
));

const ActionButtonConnectStep = memo((props: CanvasActionButtonProps) => (
  <CanvasActionButton placement="top" tooltipTitle="Connect Steps" {...props}>
    <ConnectStepIcon height="14px" />
  </CanvasActionButton>
));

export {
  ActionButtonAdd,
  ActionButtonNurturingStep,
  ActionButtonConnectStep,
  ActionButtonDelete,
  actionButtonDimensions,
};
