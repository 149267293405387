import { useCallback } from 'react';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { withTrimmedValues } from '../../../../lib/trimmedValues';
import useFunnelNameValidationSchema from '../../../../hooks/useFunnelNameValidationSchema';

export interface NewFunnelDialogFormValues {
  name: string;
  objectType: string;
  field: string;
}

const useNewSfFunnelDialogForm = ({
  initialName,
  onSubmit,
}: {
  initialName: string;
  onSubmit: (formValues: NewFunnelDialogFormValues) => void;
}) => {
  const funnelNameValidationSchema = useFunnelNameValidationSchema({
    initialName,
    getObjectName: (context) => context.parent.objectType,
  });

  const getValidationSchema = useCallback(
    () =>
      yup.object().shape({
        name: funnelNameValidationSchema,
        objectType: yup.string().required(),
        field: yup.string().required(),
      }),

    [funnelNameValidationSchema],
  );

  const formik: FormikProps<NewFunnelDialogFormValues> = useFormik({
    initialValues: {
      name: initialName,
      objectType: '',
      field: '',
    },
    validationSchema: getValidationSchema(),
    onSubmit: withTrimmedValues(onSubmit),
  });

  const isSubmitted = Boolean(formik.submitCount);

  return { ...formik, isSubmitted };
};

export default useNewSfFunnelDialogForm;
