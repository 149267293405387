import * as React from "react";
const SvgCreateAccountImg = (props) => /* @__PURE__ */ React.createElement("svg", { width: 150, height: 132, viewBox: "0 0 150 132", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("path", { d: "M126 88.511L75 118.5L24 88.5111L75 60L126 88.511Z", stroke: "#A5BEFF", style: {
  stroke: "#A5BEFF",
  stroke: "color(display-p3 0.6458 0.7450 1.0000)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M137.5 88.3468L75 125L12.5 88.3469L75 53.5L137.5 88.3468Z", stroke: "#E1EBFF", style: {
  stroke: "#E1EBFF",
  stroke: "color(display-p3 0.8833 0.9230 1.0000)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M149 88.1826L75.0001 131.5L1.00012 88.1828L75.0001 47L149 88.1826Z", stroke: "#E1EBFF", style: {
  stroke: "#E1EBFF",
  stroke: "color(display-p3 0.8833 0.9230 1.0000)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M115.5 88.6626L75 112.5L34.5 88.6627L75 66L115.5 88.6626Z", fill: "#577EE4", stroke: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M75.1459 112.5V77.1522L105.596 60.0107V94.5067L75.1459 112.5Z", fill: "#E1EBFF", stroke: "#577EE4", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M75.1459 112.5V77.1522L44.6958 60.0107V94.5067L75.1459 112.5Z", fill: "#E1EBFF", stroke: "#577EE4", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M105.596 60.0108L75.1458 77.1524L44.6958 60.0109L75.1458 42.0176L105.596 60.0108Z", fill: "#577EE4", stroke: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M60.2402 33.5L29.7901 50.6416L44.6958 60.0119L75.1459 42.0186L60.2402 33.5Z", fill: "#E1EBFF", stroke: "#577EE4", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M105.596 60.0109L121.46 50.8546L91.0097 33.7131L75.1459 42.0176L105.596 60.0109Z", fill: "#E1EBFF", stroke: "#577EE4", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M95.1088 100.703L85.5798 106.334V71.2786L95.1088 65.9209V100.703Z", fill: "#A5BEFF", stroke: "#577EE4", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M55.183 100.703L64.7119 106.334V71.2786L55.183 65.9209V100.703Z", fill: "#A5BEFF", stroke: "#577EE4", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M60.3467 86.4151L75.1459 77.1525L44.6958 60.011L29.8966 68.4218L60.3467 86.4151Z", fill: "#E1EBFF", stroke: "#577EE4", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M105.596 60.0107L75.1459 77.1523L90.2645 86.2021L120.715 68.2088L105.596 60.0107Z", fill: "#E1EBFF", stroke: "#577EE4", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("mask", { id: "mask0_1781_65590", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: -59, y: 2, width: 265, height: 75 }, /* @__PURE__ */ React.createElement("path", { d: "M-58.7683 2.14119H205.75L182 57.4149L104.75 60.0001L75.25 76.7501L45 60.0001L-37.75 61.5021L-58.7683 2.14119Z", fill: "#2C1A4F", style: {
  fill: "#2C1A4F",
  fill: "color(display-p3 0.1730 0.1028 0.3083)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask0_1781_65590)" }, /* @__PURE__ */ React.createElement("path", { d: "M76.8485 54.6981L56.7779 28.4983C56.4429 28.0611 55.8176 27.977 55.379 28.3102L44.005 36.9525L36.3183 42.9098C35.8835 43.2467 35.8025 43.8716 36.137 44.3083L56.2086 70.5095C56.5445 70.9479 57.1722 71.031 57.6106 70.6952L76.6628 56.1001C77.1012 55.7643 77.1843 55.1366 76.8485 54.6981Z", fill: "#A5BEFF", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M36.3183 42.9098L36.012 42.5146L36.3183 42.9098ZM44.005 36.9525L43.7025 36.5543L43.6987 36.5573L44.005 36.9525ZM55.379 28.3102L55.6815 28.7083L55.379 28.3102ZM56.381 28.8024L76.4515 55.0022L77.2454 54.3941L57.1748 28.1943L56.381 28.8024ZM76.3587 55.7032L57.3065 70.2982L57.9147 71.0921L76.9668 56.497L76.3587 55.7032ZM56.6056 70.2054L36.5339 44.0042L35.7401 44.6124L55.8117 70.8135L56.6056 70.2054ZM36.6246 43.305L44.3113 37.3477L43.6987 36.5573L36.012 42.5146L36.6246 43.305ZM44.3075 37.3506L55.6815 28.7083L55.0765 27.9121L43.7025 36.5544L44.3075 37.3506ZM36.5339 44.0042C36.3667 43.7859 36.4072 43.4735 36.6246 43.305L36.012 42.5146C35.3598 43.02 35.2383 43.9573 35.7401 44.6124L36.5339 44.0042ZM57.3065 70.2982C57.0873 70.4662 56.7735 70.4246 56.6056 70.2054L55.8117 70.8135C56.3155 71.4712 57.257 71.5959 57.9147 71.0921L57.3065 70.2982ZM76.4515 55.0022C76.6195 55.2214 76.5779 55.5353 76.3587 55.7032L76.9668 56.497C77.6245 55.9932 77.7492 55.0517 77.2454 54.3941L76.4515 55.0022ZM57.1748 28.1943C56.6724 27.5384 55.7343 27.4123 55.0765 27.9121L55.6815 28.7083C55.9008 28.5417 56.2135 28.5838 56.381 28.8024L57.1748 28.1943Z", fill: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M87.3833 19.5292L69.2174 46.4225C68.4445 47.5666 68.7455 49.1207 69.8897 49.8935L96.783 68.0595C97.9271 68.8323 99.4812 68.5313 100.254 67.3872L118.42 40.4939C119.193 39.3497 118.892 37.7957 117.748 37.0228L104.301 27.9398L90.8544 18.8569C89.7102 18.084 88.1562 18.385 87.3833 19.5292Z", fill: "#A5BEFF", stroke: "#577EE4", style: {
  fill: "#A5BEFF",
  fill: "color(display-p3 0.6458 0.7450 1.0000)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("mask", { id: "mask1_1781_65590", style: {
  maskType: "alpha"
}, maskUnits: "userSpaceOnUse", x: 68, y: 17, width: 52, height: 52 }, /* @__PURE__ */ React.createElement("path", { d: "M87.3832 19.5292L69.2172 46.4225C68.4444 47.5666 68.7454 49.1207 69.8895 49.8935L96.7829 68.0595C97.927 68.8323 99.481 68.5313 100.254 67.3872L118.42 40.4939C119.193 39.3497 118.892 37.7957 117.748 37.0228L104.301 27.9398L90.8542 18.8569C89.7101 18.084 88.1561 18.385 87.3832 19.5292Z", fill: "#E1EBFF", stroke: "#2C1A4F", style: {
  fill: "#E1EBFF",
  fill: "color(display-p3 0.8833 0.9230 1.0000)",
  fillOpacity: 1,
  stroke: "#2C1A4F",
  stroke: "color(display-p3 0.1730 0.1028 0.3083)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("g", { mask: "url(#mask1_1781_65590)" }, /* @__PURE__ */ React.createElement("path", { d: "M70.1772 12.909L87.659 26.3336C88.0971 26.6699 88.1795 27.2977 87.8431 27.7358L74.4185 45.2176C74.0822 45.6557 73.4544 45.7381 73.0164 45.4017L55.5345 31.9771C55.0965 31.6408 55.014 31.013 55.3504 30.575L68.775 13.0931C69.1113 12.6551 69.7391 12.5726 70.1772 12.909Z", fill: "#577EE4", stroke: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" })), /* @__PURE__ */ React.createElement("path", { d: "M68.2084 50.5363L57.3285 31.6918C57.0855 31.2709 56.5751 31.0857 56.1188 31.2527L45.1501 35.2674C44.9847 35.328 44.8382 35.4312 44.7254 35.5666L41.7037 39.195C41.4372 39.5151 41.397 39.9669 41.6028 40.3291L52.5408 59.5741C52.815 60.0566 53.4296 60.2235 53.9102 59.946L67.8423 51.9023C68.3206 51.6261 68.4845 51.0145 68.2084 50.5363Z", fill: "#577EE4", stroke: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M69.1375 46.2798L53.8875 19.866C53.6113 19.3877 52.9997 19.2239 52.5214 19.5L44.9437 23.875L37.366 28.25C36.8877 28.5261 36.7238 29.1377 37 29.616L52.25 56.0298C52.5261 56.5081 53.1377 56.672 53.616 56.3958L68.7714 47.6458C69.2497 47.3697 69.4136 46.7581 69.1375 46.2798Z", fill: "#EBDEFF", style: {
  fill: "#EBDEFF",
  fill: "color(display-p3 0.9235 0.8708 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M53.4544 20.116L68.7044 46.5298L69.5705 46.0298L54.3205 19.616L53.4544 20.116ZM68.5214 47.2128L53.366 55.9628L53.866 56.8288L69.0214 48.0788L68.5214 47.2128ZM52.683 55.7798L37.433 29.366L36.5669 29.866L51.8169 56.2798L52.683 55.7798ZM37.616 28.683L45.1937 24.308L44.6937 23.442L37.116 27.817L37.616 28.683ZM45.1937 24.308L52.7714 19.933L52.2714 19.067L44.6937 23.442L45.1937 24.308ZM37.433 29.366C37.2949 29.1269 37.3768 28.8211 37.616 28.683L37.116 27.817C36.3985 28.2312 36.1527 29.1486 36.5669 29.866L37.433 29.366ZM53.366 55.9628C53.1268 56.1009 52.821 56.0189 52.683 55.7798L51.8169 56.2798C52.2312 56.9972 53.1485 57.2431 53.866 56.8288L53.366 55.9628ZM68.7044 46.5298C68.8425 46.7689 68.7606 47.0747 68.5214 47.2128L69.0214 48.0788C69.7389 47.6646 69.9847 46.7472 69.5705 46.0298L68.7044 46.5298ZM54.3205 19.616C53.9063 18.8986 52.9889 18.6528 52.2714 19.067L52.7714 19.933C53.0106 19.7949 53.3164 19.8769 53.4544 20.116L54.3205 19.616Z", fill: "#9462FF", style: {
  fill: "#9462FF",
  fill: "color(display-p3 0.5807 0.3833 1.0000)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M95 65.5C95 74.3366 87.8366 81.5 79 81.5C70.1634 81.5 63 74.3366 63 65.5C63 56.6634 70.1634 49.5 79 49.5C87.8366 49.5 95 56.6634 95 65.5Z", fill: "#577EE4", stroke: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1,
  stroke: "#577EE4",
  stroke: "color(display-p3 0.3397 0.4954 0.8958)",
  strokeOpacity: 1
}, strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("path", { d: "M92 62.5C92 71.3365 84.8366 78.5 76 78.5C67.1634 78.5 60 71.3365 60 62.5C60 53.6634 67.1634 46.5 76 46.5C84.8366 46.5 92 53.6634 92 62.5Z", fill: "#F8BCF2", style: {
  fill: "#F8BCF2",
  fill: "color(display-p3 0.9708 0.7362 0.9474)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M76 78C84.5604 78 91.5 71.0604 91.5 62.5H92.5C92.5 71.6127 85.1127 79 76 79V78ZM76 79C66.8873 79 59.5 71.6127 59.5 62.5H60.5C60.5 71.0604 67.4396 78 76 78V79ZM76 47C67.4396 47 60.5 53.9396 60.5 62.5H59.5C59.5 53.3873 66.8873 46 76 46V47ZM91.5 62.5C91.5 53.9396 84.5604 47 76 47V46C85.1127 46 92.5 53.3873 92.5 62.5H91.5Z", fill: "#F371E6", style: {
  fill: "#F371E6",
  fill: "color(display-p3 0.9542 0.4413 0.9029)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M71.6344 42.5576L54.1525 29.133C53.7145 28.7966 53.6321 28.1688 53.9685 27.7308L60.6807 18.9899L67.393 10.2489C67.7294 9.81091 68.3572 9.7285 68.7952 10.0649L77.5361 16.7771L86.2771 23.4894C86.7151 23.8258 86.7975 24.4536 86.4611 24.8916L73.0366 42.3735C72.7002 42.8115 72.0724 42.8939 71.6344 42.5576Z", fill: "#FFF4BF", style: {
  fill: "#FFF4BF",
  fill: "color(display-p3 1.0000 0.9569 0.7490)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M54.4571 28.7364L71.9389 42.161L71.3299 42.9541L53.848 29.5296L54.4571 28.7364ZM72.64 42.069L86.0646 24.5871L86.8577 25.1961L73.4331 42.678L72.64 42.069ZM85.9725 23.886L77.2316 17.1737L77.8407 16.3806L86.5816 23.0929L85.9725 23.886ZM77.2316 17.1737L68.4907 10.4614L69.0997 9.66831L77.8407 16.3806L77.2316 17.1737ZM67.7896 10.5535L61.0773 19.2944L60.2842 18.6854L66.9964 9.94442L67.7896 10.5535ZM61.0773 19.2944L54.365 28.0353L53.5719 27.4263L60.2842 18.6854L61.0773 19.2944ZM71.9389 42.161C72.1579 42.3292 72.4718 42.288 72.64 42.069L73.4331 42.678C72.9286 43.3351 71.9869 43.4587 71.3299 42.9541L71.9389 42.161ZM53.848 29.5296C53.191 29.025 53.0673 28.0833 53.5719 27.4263L54.365 28.0353C54.1968 28.2544 54.238 28.5682 54.4571 28.7364L53.848 29.5296ZM68.4907 10.4614C68.2717 10.2932 67.9578 10.3345 67.7896 10.5535L66.9964 9.94442C67.501 9.28737 68.4427 9.16375 69.0997 9.66831L68.4907 10.4614ZM86.0646 24.5871C86.2328 24.3681 86.1916 24.0542 85.9725 23.886L86.5816 23.0929C87.2386 23.5974 87.3623 24.5391 86.8577 25.1961L86.0646 24.5871Z", fill: "#FED044", style: {
  fill: "#FED044",
  fill: "color(display-p3 0.9961 0.8157 0.2667)",
  fillOpacity: 1
} })), /* @__PURE__ */ React.createElement("path", { d: "M86.0076 50.3779C85.4971 54.6733 88.5607 58.609 92.8561 59.1195C97.1514 59.6299 101.052 56.5217 101.562 52.2263C102.073 47.9309 99.0093 43.9952 94.714 43.4848C90.4186 42.9743 86.518 46.0825 86.0076 50.3779Z", fill: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M92.9151 58.6229C88.8972 58.1455 86.0258 54.4614 86.5041 50.4369L85.5111 50.3189C84.9684 54.8852 88.2242 59.0726 92.7971 59.616L92.9151 58.6229ZM92.7971 59.616C97.3699 60.1594 101.516 56.8516 102.059 52.2853L101.066 52.1673C100.588 56.1918 96.9329 59.1004 92.9151 58.6229L92.7971 59.616ZM94.655 43.9813C98.6728 44.4587 101.544 48.1428 101.066 52.1673L102.059 52.2853C102.602 47.719 99.3459 43.5317 94.773 42.9883L94.655 43.9813ZM86.5041 50.4369C86.9823 46.4124 90.6371 43.5038 94.655 43.9813L94.773 42.9883C90.2001 42.4448 86.0537 45.7526 85.5111 50.3189L86.5041 50.4369Z", fill: "#577EE4", style: {
  fill: "#577EE4",
  fill: "color(display-p3 0.3397 0.4954 0.8958)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M84.754 48.2148C84.2436 52.5101 87.3072 56.4459 91.6025 56.9563C95.8979 57.4668 99.7985 54.3586 100.309 50.0632C100.819 45.7678 97.7558 41.8321 93.4604 41.3216C89.1651 40.8112 85.2645 43.9194 84.754 48.2148Z", fill: "#FFD28D", style: {
  fill: "#FFD28D",
  fill: "color(display-p3 1.0000 0.8217 0.5542)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M91.6615 56.4598C87.6437 55.9824 84.7723 52.2982 85.2505 48.2738L84.2575 48.1558C83.7149 52.722 86.9706 56.9094 91.5435 57.4528L91.6615 56.4598ZM91.5435 57.4528C96.1164 57.9962 100.263 54.6885 100.805 50.1222L99.8124 50.0042C99.3342 54.0287 95.6794 56.9373 91.6615 56.4598L91.5435 57.4528ZM93.4014 41.8181C97.4193 42.2956 100.291 45.9797 99.8124 50.0042L100.805 50.1222C101.348 45.5559 98.0923 41.3685 93.5194 40.8251L93.4014 41.8181ZM85.2505 48.2738C85.7288 44.2493 89.3836 41.3407 93.4014 41.8181L93.5194 40.8251C88.9466 40.2817 84.8001 43.5895 84.2575 48.1558L85.2505 48.2738Z", fill: "#FF9900", style: {
  fill: "#FF9900",
  fill: "color(display-p3 1.0000 0.6000 0.0000)",
  fillOpacity: 1
} }));
export default SvgCreateAccountImg;
