import { ConfigurationType } from '@server/parser';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectSingleObject } from '../../../reducers/documentationReducer';
import { selectCrmOrgAlerts } from '../../../reducers/global/alertsReducers';
import { selectCrmOrgAssignments } from '../../../reducers/global/assignmentReducers';
import { selectCrmOrgAutomations } from '../../../reducers/global/automationReducers';
import {
  selectCrmOrgDedup,
  selectCrmOrgMatching,
} from '../../../reducers/global/dedupMatchingReducers';
import { selectParsedFields } from '../../../reducers/global/globalReducer';
import { selectCrmOrgScheduledAssignments } from '../../../reducers/global/scheduledAssignmentReducers';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import {
  updateParsedRuleWithParentElementType,
  updateStructureWithParentElementType,
  getDeployedVersions,
} from './filters/utils';
import { useDocumentation } from '../useDocumentation';
import { getRulesByTab, ruleByObjectName } from '../../parser/parserUtils';
import groupBy from 'lodash/groupBy';
import {
  createConfigurationItemFromAutomation,
  createConfigurationItemFromField,
  createConfigurationItemFromParsedRule,
  createConfigurationItemFromRecordType,
} from '../helpers';
import { selectRollupsForTable } from '../../../reducers/global/rollupReducers';
import { ConfigurationItem } from '../dependencies/DependenciesTypes';
import { selectAccountUsers } from '../../../reducers/accountUsersReducer';

export const usePrepareActiveObjectStructure = (
  crmOrgId: string,
): { [key in DocumentationTabTypes]: ConfigurationItem[] } => {
  const objectTypeName = useSelector(selectSingleObject);
  const _fields = useSelector(selectParsedFields(crmOrgId))?.[objectTypeName?.objectType ?? ''];
  const rollups = useSelector(selectRollupsForTable);
  const users = useSelector(selectAccountUsers);

  const { parsedRules, parsedRecordTypes } = useDocumentation();
  const _parsedRecordTypes = useMemo(
    () => parsedRecordTypes?.filter((rt) => rt.objectApiName === objectTypeName?.objectType) ?? [],
    [parsedRecordTypes, objectTypeName],
  );

  const sweepAutomations = useSelector(selectCrmOrgAutomations(crmOrgId));
  const sweepAlerts = useSelector(selectCrmOrgAlerts(crmOrgId));
  const sweepDedupe = useSelector(selectCrmOrgDedup(crmOrgId));
  const sweepMatching = useSelector(selectCrmOrgMatching(crmOrgId));
  const sweepAssignments = useSelector(selectCrmOrgAssignments(crmOrgId));
  const sweepScheduledAssignments = useSelector(selectCrmOrgScheduledAssignments(crmOrgId));

  const objectRules = useMemo(
    () =>
      parsedRules
        .filter(ruleByObjectName(objectTypeName?.objectType ?? ''))
        .map((rule) => ({
          ...rule,
          parentType: rule.type,
        }))
        .map(createConfigurationItemFromParsedRule),
    [parsedRules, objectTypeName],
  );

  const groupedByType = useMemo(() => groupBy(objectRules, 'type'), [objectRules]);
  const {
    flows,
    approvalProcesses,
    processBuilderFlows,
    workflowRules,
    layouts,
    validationRules,
    apexTriggers,
  } = groupedByType;

  const cpqData = useMemo(
    () =>
      updateParsedRuleWithParentElementType(
        ConfigurationType.cpqData,
        getRulesByTab(parsedRules, DocumentationTabTypes.CPQ_DATA),
      ).map(createConfigurationItemFromParsedRule),
    [parsedRules],
  );

  //Sweep elements
  const deployedSweepAutomations = useMemo(
    () =>
      updateStructureWithParentElementType(
        ConfigurationType.sweepAutomation,
        objectTypeName?.objectType ?? '',
        getDeployedVersions(sweepAutomations),
      ),
    [sweepAutomations, objectTypeName?.objectType],
  );

  const deployedSweepAlerts = useMemo(
    () =>
      updateStructureWithParentElementType(
        ConfigurationType.sweepAlert,
        objectTypeName?.objectType ?? '',
        getDeployedVersions(sweepAlerts),
      ),
    [sweepAlerts, objectTypeName?.objectType],
  );

  const deployedAssignments = useMemo(
    () =>
      updateStructureWithParentElementType(
        ConfigurationType.sweepAssignment,
        objectTypeName?.objectType ?? '',
        getDeployedVersions(sweepAssignments),
      ),
    [sweepAssignments, objectTypeName?.objectType],
  );

  const deployedScheduledAssignments = useMemo(
    () =>
      updateStructureWithParentElementType(
        ConfigurationType.sweepScheduledAssignment,
        objectTypeName?.objectType ?? '',
        getDeployedVersions(sweepScheduledAssignments),
      ),
    [sweepScheduledAssignments, objectTypeName?.objectType],
  );

  const dedupeNewStructure = useMemo(
    () =>
      updateStructureWithParentElementType(
        ConfigurationType.sweepDedupe,
        objectTypeName?.objectType ?? '',
        getDeployedVersions(sweepDedupe),
      ),
    [sweepDedupe, objectTypeName?.objectType],
  );

  const matchingNewStructure = useMemo(
    () =>
      updateStructureWithParentElementType(
        ConfigurationType.sweepMatching,
        objectTypeName?.objectType ?? '',
        getDeployedVersions(sweepMatching),
      ),
    [sweepMatching, objectTypeName?.objectType],
  );

  const list = useMemo(() => {
    return {
      [DocumentationTabTypes.FIELDS]:
        _fields?.map((field) => createConfigurationItemFromField(field, rollups)) ?? [],
      [DocumentationTabTypes.RECORD_TYPES]: (_parsedRecordTypes ?? []).map(
        createConfigurationItemFromRecordType,
      ) as ConfigurationItem[],
      [DocumentationTabTypes.SF_AUTOMATIONS]: [
        ...(flows ?? []),
        ...(approvalProcesses ?? []),
        ...(workflowRules ?? []),
        ...(processBuilderFlows ?? []),
      ],
      [DocumentationTabTypes.LAYOUTS]: layouts,
      [DocumentationTabTypes.VALIDATION_RULES]: validationRules ?? [],
      [DocumentationTabTypes.APEX]: apexTriggers ?? [],
      [DocumentationTabTypes.CPQ_DATA]: cpqData,
      [DocumentationTabTypes.MATCHING_DEDUPE]: [...matchingNewStructure, ...dedupeNewStructure].map(
        (automation) => createConfigurationItemFromAutomation(automation, users),
      ) as ConfigurationItem[],
      [DocumentationTabTypes.PLAYBOOK_ALERTS]: deployedSweepAlerts.map((automation) =>
        createConfigurationItemFromAutomation(automation, users),
      ) as ConfigurationItem[],
      [DocumentationTabTypes.ASSIGNMENTS]: deployedAssignments.map((automation) =>
        createConfigurationItemFromAutomation(automation, users),
      ) as ConfigurationItem[],
      [DocumentationTabTypes.SCHEDULED_ASSIGNMENTS]: deployedScheduledAssignments.map(
        (automation) => createConfigurationItemFromAutomation(automation, users),
      ) as ConfigurationItem[],
      [DocumentationTabTypes.SWEEP_AUTOMATIONS]: deployedSweepAutomations.map((automation) =>
        createConfigurationItemFromAutomation(automation, users),
      ) as ConfigurationItem[],
      [DocumentationTabTypes.CARDS_LIST]: [],
    };
  }, [
    rollups,
    _fields,
    _parsedRecordTypes,
    processBuilderFlows,
    workflowRules,
    flows,
    approvalProcesses,
    layouts,
    validationRules,
    apexTriggers,
    cpqData,
    dedupeNewStructure,
    matchingNewStructure,
    deployedAssignments,
    deployedScheduledAssignments,
    deployedSweepAutomations,
    deployedSweepAlerts,
    users,
  ]);

  return list;
};
