import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { Typography, colors } from '@sweep-io/sweep-design';
import SweepSelect from '../common/SweepSelect';
import { labelBoxDesign } from './AutomationsForm';
import { useState } from 'react';
import { TimeSelector } from '../common/rule-builder/selectors/TimeSelector';
import { TimezoneSelector } from '../common/timezone-selector/TimezoneSelector';
import AutomationScheduleRecurring from './AutomationScheduleRecurring';
import StartDatePicker from './StartDatePicker';

interface AutomationScheduleProps {
  readonly?: boolean;
  schedule?: AutomationSchedule;
  callBack: (data: AutomationSchedule) => void;
  entityName: string;
}

const enum recTypes {
  RECURRING = 'RECURRING',
  ONCE = 'ONCE',
}

const AutomationSchedule = ({
  readonly,
  schedule,
  callBack,
  entityName,
}: AutomationScheduleProps) => {
  const { startDate, executeTime, timezone, offset } = schedule || {};
  const [isRecurring, setIsRecurring] = useState(!!offset);
  const [isShowEmpty, setIsShowEmpty] = useState(!isRecurring && !startDate);

  const setSchedule = (partialSchedule: Partial<AutomationSchedule>) => {
    callBack({ ...schedule, ...partialSchedule } as AutomationSchedule); //TODO improve typing
  };

  const getValueToShow = () => {
    if (isShowEmpty) {
      return '';
    } else {
      return isRecurring ? recTypes.RECURRING : recTypes.ONCE;
    }
  };

  return (
    <Box sx={{ marginBottom: '16px' }}>
      <Box
        sx={{
          ...labelBoxDesign,
          background: colors.mint[700],
        }}
      >
        <Typography variant="caption-bold" textAlign="center" color={colors.white}>
          TYPE
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <SweepSelect
          FormControlProps={{
            sx: { minWidth: '120px', marginRight: '8px' },
          }}
          menuMaxHeight={'360px'}
          SelectProps={{
            placeholder: 'Choose type',
            disabled: readonly,
            value: getValueToShow(),
            onChange: (event: SelectChangeEvent<unknown>) => {
              setIsShowEmpty(false);
              const val = event.target.value;
              if (val === recTypes.ONCE) {
                setIsRecurring(false);
                setSchedule({ offset: undefined, endDate: undefined });
              } else {
                setIsRecurring(true);
                setSchedule({ offset: {} as ScheduleOffset });
              }
            },
            renderValue: (val: any) => {
              if (val) {
                return val === recTypes.ONCE ? `One-time ${entityName}` : `Recurring ${entityName}`;
              }
            },
          }}
        >
          <MenuItem key={'false'} value={recTypes.ONCE}>
            One-time {entityName}
          </MenuItem>
          <MenuItem key={'true'} value={recTypes.RECURRING}>
            Recurring {entityName}
          </MenuItem>
        </SweepSelect>

        {!isShowEmpty && !isRecurring && (
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Typography variant="body" textAlign="center">
              on
            </Typography>
            <StartDatePicker
              startDate={startDate}
              callback={(value: string) => setSchedule({ startDate: value })}
            />

            <Typography variant="body" textAlign="center">
              at
            </Typography>
            <TimeSelector
              initialValue={executeTime}
              timePickerSx={{
                boxShadow:
                  'rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px',
                '& .MuiDialogActions-root': {
                  padding: 1,
                },
                '& .MuiMultiSectionDigitalClock-root .Mui-selected.MuiMultiSectionDigitalClockSection-item':
                  {
                    paddingRight: 1,
                    '&::after': {
                      content: 'none',
                    },
                  },
              }}
              shouldBeDisabled={false}
              onChange={(value: string) => {
                setSchedule({ executeTime: value.slice(0, -4) });
              }}
            />
            <TimezoneSelector
              timezone={timezone}
              onChange={(timezone) => {
                setSchedule({ timezone: timezone });
              }}
            />
          </Box>
        )}
      </Box>
      {!isShowEmpty && isRecurring && (
        <AutomationScheduleRecurring
          setSchedule={setSchedule}
          schedule={schedule}
          readonly={readonly}
        />
      )}
    </Box>
  );
};

export default AutomationSchedule;
