import { Autocomplete, TextField } from '@mui/material';
import { Fragment, SyntheticEvent, useState } from 'react';
import { StyledChip } from '../../StyledChip';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@sweep-io/sweep-design';
import { Close } from '@sweep-io/sweep-design/dist/icons/Close';

export interface ListSelectorProps {
  initialValue?: string;
  onChange: Function;
  shouldBeDisabled?: boolean;
  removeBorders?: boolean;
}

const ListSelector = ({
  initialValue,
  onChange,
  shouldBeDisabled,
  removeBorders,
}: ListSelectorProps) => {
  const [newValue, setValue] = useState(!!initialValue ? initialValue?.split(';') : []);

  const removeDuplicates = (baseArray: string[]) => {
    const mySet = new Set(baseArray);
    return Array.from(mySet);
  };

  const handlePaste = (evt: any) => {
    evt.stopPropagation();
    evt.preventDefault();
    const _value = evt.clipboardData.getData('text/plain');
    const _arrVal = _value.trim().split(';');
    if (_arrVal.length) {
      let tempArr = [...(newValue || [])];
      tempArr = tempArr.concat(_arrVal);
      const arrUnique = removeDuplicates(tempArr);
      setValue(arrUnique);
      onChange(arrUnique?.join(';'));
    }
  };

  const handleKeyDown = (evt: any) => {
    if (['Enter', 'Tab', ',', ';'].includes(evt.key) || evt.type === 'blur') {
      evt.preventDefault();
      evt.stopPropagation();
      const value = evt.target.value.trim();
      if (value) {
        if (!newValue?.includes(value)) {
          const tempArr = [...(newValue || [])];
          tempArr.push(value);
          setValue(tempArr);
          onChange(tempArr?.join(';'));
        }
      }
    }
  };

  return (
    <Autocomplete
      disabled={shouldBeDisabled}
      sx={{
        minWidth: '160px',
        '& .MuiAutocomplete-inputRoot': {
          rowGap: '8px',
          paddingTop: '6px',
          paddingBottom: '6px',

          '& fieldset': {
            borderWidth: removeBorders ? '0px !important' : `thin solid ${colors.grey[300]}`,
          },
          '& .MuiAutocomplete-input': {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      }}
      clearIcon={<Close />}
      multiple
      options={[]}
      freeSolo
      onKeyDown={handleKeyDown}
      clearOnBlur
      limitTags={2}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Fragment key={option}>
            <StyledChip
              label={option}
              {...getTagProps({ index })}
              onDelete={() => {
                const tmpArr = [...(newValue || [])];
                tmpArr.splice(index, 1);
                setValue(tmpArr);
                onChange(tmpArr?.join(';'));
              }}
              deleteIcon={<CloseIcon sx={{ color: '#000', fontSize: 12, ml: 0 }} />}
            />
          </Fragment>
        ));
      }}
      disablePortal
      value={newValue}
      onPaste={handlePaste}
      onChange={(event: SyntheticEvent<unknown>, newValues: any) => {
        const newValue = newValues;
        setValue(newValue);
        onChange(newValue?.join(';'));
      }}
      renderInput={(params) => <TextField {...params} />}
    />
  );
};

export { ListSelector };
