import { VisibilityLayers } from '../../../../types/enums/VisibilityLayers';
import { VisibilityMap } from '../../../../types/VisibilityTypes';
import DedupMatching from './DedupMatching';
import {
  ConfigurationCanvasLeftPanel,
  ConfigurationCanvasPanelContent,
  ConfigurationCanvasPanelHeader,
} from '../../configuration-canvas-panel';
import { VisibilityElements } from '../../configuration-canvas/types';
import { WithConfigurationCanvas } from '../../configuration-canvas/WithConfigurationCanvas';
import { useConfigurationCanvas } from '../../configuration-canvas/public/useConfigurationCanvas';
import useAutomationsCanvasInteractions from '../useAutomationsCanvasInteractions';
import { MultipleRuleBuildersProvider } from '../../../common/sweep-condition-rule-builder/MultipleRuleBuildersContext';

const visibilityElements: Partial<VisibilityMap> = {
  [VisibilityLayers.NURTURING_STEPS]: true,
  [VisibilityLayers.COMMENTS]: false,
};

const visibilityElementsMap: VisibilityElements = {
  type: 'map',
  visibilityMap: visibilityElements,
};

export const CanvasDedupAndMatchingPage = () => {
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { funnelsData, recordTypesData } = canvasFunnelMap;
  const { setHoveredItem } = useAutomationsCanvasInteractions();

  return (
    <WithConfigurationCanvas visibilityElements={visibilityElementsMap}>
      <ConfigurationCanvasLeftPanel withExpandedModeButton={true}>
        <ConfigurationCanvasPanelHeader withExpandedModeButton={true}>
          Dedupe & Matching
        </ConfigurationCanvasPanelHeader>
        <ConfigurationCanvasPanelContent
          sx={{
            overflow: 'hidden',
            height: '100%',
            padding: 0, //needed in order to override the existing value (I dont know who needs it)
            display: 'flex',
          }}
        >
          <MultipleRuleBuildersProvider>
            <DedupMatching
              funnelsData={funnelsData}
              recordTypesData={recordTypesData}
              setHoveredItem={setHoveredItem}
            />
          </MultipleRuleBuildersProvider>
        </ConfigurationCanvasPanelContent>
      </ConfigurationCanvasLeftPanel>
    </WithConfigurationCanvas>
  );
};
