//sweepColor is randomly assigned for documentation custom note tags purposes,
//it's based on designers' team wish to have only colors of shaded 100 displayed

export const defaultEmojis = [
  { content: '⚽️', bgColor: '#DBEFFF', sweepColor: 'blue' },
  { content: '🦊', bgColor: '#FFC09D', sweepColor: 'sky' },
  { content: '🦋', bgColor: '#DBEFFF', sweepColor: 'seafoam' },
  { content: '😎', bgColor: '#B9F4A4', sweepColor: 'blush' },
  { content: '🦄', bgColor: '#F8BCF2', sweepColor: 'gum' },
  { content: '👑', bgColor: '#91EDDC', sweepColor: 'sun' },
  { content: '🍿', bgColor: '#FFB09D', sweepColor: 'lilac' },
  { content: '🐝', bgColor: '#F8BCF2', sweepColor: 'mint' },
  { content: '🥑', bgColor: '#B9F4A4', sweepColor: 'lemon' },
  { content: '🍕', bgColor: '#FFB09D', sweepColor: 'grey' },
  { content: '🦈', bgColor: '#A5BEFF', sweepColor: 'blue' },
  { content: '🐼', bgColor: '#7384C0', sweepColor: 'sky' },
  { content: '🐬', bgColor: '#9BD4FF', sweepColor: 'seafoam' },
  { content: '🐶', bgColor: '#B9F4A4', sweepColor: 'gum' },
  { content: '🐙', bgColor: '#FFB09D', sweepColor: 'sun' },
  { content: '🔥', bgColor: '#FFD28D', sweepColor: 'lilac' },
  { content: '🐞', bgColor: '#FFB09D', sweepColor: 'mint' },
  { content: '🦁', bgColor: '#FFC09D', sweepColor: 'lemon' },
  { content: '🌚', bgColor: '#B2C1F8', sweepColor: 'grey' },
  { content: '🌝', bgColor: '#FFEE94', sweepColor: 'blue' },
  { content: '🐣', bgColor: '#FFEE94', sweepColor: 'sky' },
  { content: '☕️', bgColor: '#7384C0', sweepColor: 'seafoam' },
  { content: '🎸', bgColor: '#F8F9FA', sweepColor: 'blush' },
  { content: '🚀', bgColor: '#9BD4FF', sweepColor: 'gum' },
  { content: '🥂', bgColor: '#FFD28D', sweepColor: 'sun' },
  { content: '🏀', bgColor: '#FFC09D', sweepColor: 'lilac' },
  { content: '👾', bgColor: '#C7ADFF', sweepColor: 'mint' },
  { content: '💃', bgColor: '#FFB09D', sweepColor: 'lemon' },
  { content: '🎉', bgColor: '#F8BCF2', sweepColor: 'grey' },
  { content: '🏎', bgColor: '#91EDDC', sweepColor: 'blue' },
  { content: '🌊', bgColor: '#A5BEFF', sweepColor: 'sky' },
  { content: '🐊', bgColor: '#B9F4A4', sweepColor: 'seafoam' },
];
