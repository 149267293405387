import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { router } from './components/AppRoutes';
import { appTheme } from './appTheme/appTheme';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { storeBuilder } from './storeBuilder';
import { ldProviderBuilder } from './ldProviderBuilder';
import { SweepDesignProvider } from '@sweep-io/sweep-design';
import { initMonitoring } from './services/monitoring';
import { initBi } from './services/mixpanel';
import { initIntercom } from './services/intercom';
import { initHubspot } from './services/hubspot';
import { RouterProvider } from 'react-router';
import { ConfirmContextProvider } from './components/common/dialogs/ConfirmLeaveWithoutSave/ConfirmContext';
import { UnsavedPromptProvider } from './components/pages/funnel-map-flow/prompt/UnsavedPromptContext';

const disableStrictMode = Boolean(import.meta.env.VITE_DISABLE_STRICT_MODE);

const MaybeStrictMode = disableStrictMode ? React.Fragment : React.StrictMode;

(async () => {
  initMonitoring();
  initBi();
  initIntercom();
  initHubspot();

  const container = document.getElementById('root');
  const root = createRoot(container!);
  const { store } = storeBuilder();
  const LDProvider = await ldProviderBuilder();

  root.render(
    <MaybeStrictMode>
      <LDProvider>
        <SweepDesignProvider legacyTheme={appTheme}>
          <ConfirmContextProvider>
            <UnsavedPromptProvider>
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <Provider store={store}>
                  <RouterProvider router={router} />
                </Provider>
              </LocalizationProvider>
            </UnsavedPromptProvider>
          </ConfirmContextProvider>
        </SweepDesignProvider>
      </LDProvider>
    </MaybeStrictMode>,
  );
})();
