const criteriaModel = (criteria: SweepCriterion) => {
  const setCriteria = (_criteria: SweepCriterion) => {
    criteria = _criteria;
  };
  const toCriteria = () => criteria;

  return {
    setCriteria,
    toCriteria,
  };
};
export type CriteriaModelType = ReturnType<typeof criteriaModel>;
export { criteriaModel };
