import Box from '@mui/material/Box';
import { IconButton } from '@sweep-io/sweep-design';
import { Plus, Delete } from '@sweep-io/sweep-design/dist/icons';
import { uniqueId } from 'lodash';

interface ActionButtonsProps {
  isFieldUndefined?: boolean;
  _field: AutomationActionField;
  onDelete?: () => void;
  onAdd?: (field: AutomationActionField) => void;
}

export const ActionButtons = ({
  isFieldUndefined,
  _field,
  onDelete,
  onAdd,
}: ActionButtonsProps) => {
  return (
    <>
      <Box>
        <IconButton
          disabled={isFieldUndefined || _field?._isMandatory}
          onClick={() => {
            onDelete && onDelete();
          }}
          variant="flat"
          size="small"
        >
          <Delete />
        </IconButton>
      </Box>

      <Box>
        <IconButton
          disabled={isFieldUndefined}
          onClick={() => {
            onAdd &&
              onAdd({
                _id: uniqueId(),
                _fieldIds: [],

                fieldType: '',
                value: '',
              });
          }}
          variant="flat"
          size="small"
        >
          <Plus />
        </IconButton>
      </Box>
    </>
  );
};
