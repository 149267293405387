import * as React from "react";
const SvgSweep = (props) => /* @__PURE__ */ React.createElement("svg", { width: 23, height: 22, viewBox: "0 0 23 22", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { x: 13.2809, y: 7.37891, width: 3, height: 3, transform: "rotate(45 13.2809 7.37891)", fill: "#F8F9FA", style: {
  fill: "#F8F9FA",
  fill: "color(display-p3 0.9725 0.9765 0.9804)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("rect", { x: 11.1602, y: 9.5, width: 3, height: 13.9988, transform: "rotate(45 11.1602 9.5)", fill: "#2C1A4F", style: {
  fill: "#2C1A4F",
  fill: "color(display-p3 0.1730 0.1028 0.3083)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M11.7809 1H14.7809V5.87865L18.2306 2.42893L20.3519 4.55025L16.9022 8L21.7809 8V11H16.9022L20.3519 14.4497L18.2306 16.5711L14.7809 13.1214V18H11.7809V13.1224L13.2815 11.6218L13.281 11.6212L15.4022 9.5L13.2809 7.37868L11.1595 9.5L11.1601 9.50057L9.66067 11H4.78085V8L9.65953 8L6.20978 4.55025L8.3311 2.42893L11.7809 5.87872V1Z", fill: "#FED044", style: {
  fill: "#FED044",
  fill: "color(display-p3 0.9961 0.8157 0.2667)",
  fillOpacity: 1
} }));
export default SvgSweep;
