import { noop } from 'lodash';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { uniqueId } from '../../../../lib/uniqueId';
import { selectCanvasPanelModeExpanded } from '../../../../reducers/userInfoReducer';
import { useSelector } from 'react-redux';
import useUserInfo from '../../../../hooks/useUserInfo';

interface ReserveSpaceForPanelsCtxProps {
  currentLeftOpenPanelId: React.MutableRefObject<string | undefined>;
  leftReservedSpace: number;
  setLeftReservedSpace: React.Dispatch<React.SetStateAction<number>>;
  rightReservedSpace: number;
  setRightReservedSpace: React.Dispatch<React.SetStateAction<number>>;
  currentRightOpenPanelId: React.MutableRefObject<string | undefined>;
}

const ReserveSpaceForPanelsCtx = createContext<ReserveSpaceForPanelsCtxProps>({
  leftReservedSpace: 0,
  setLeftReservedSpace: noop,
  rightReservedSpace: 0,
  setRightReservedSpace: noop,
  currentLeftOpenPanelId: { current: undefined },
  currentRightOpenPanelId: { current: undefined },
});

export const ReserveSpaceForPanelsProvider = ({ children }: { children: React.ReactNode }) => {
  const [leftReservedSpace, setLeftReservedSpace] = useState<number>(0);
  const [rightReservedSpace, setRightReservedSpace] = useState<number>(0);

  const currentLeftOpenPanelId = useRef<string>();
  const currentRightOpenPanelId = useRef<string>();

  return (
    <ReserveSpaceForPanelsCtx.Provider
      value={{
        leftReservedSpace,
        setLeftReservedSpace,
        currentLeftOpenPanelId,
        rightReservedSpace,
        setRightReservedSpace,
        currentRightOpenPanelId,
      }}
    >
      {children}
    </ReserveSpaceForPanelsCtx.Provider>
  );
};

export const useExpandedMode = () => {
  const isExpandedMode = useSelector(selectCanvasPanelModeExpanded);
  const { updateCanvasMode } = useUserInfo();

  const setIsExpandedMode = useCallback(
    (_occupyFullSpace: boolean) => {
      updateCanvasMode(_occupyFullSpace);
    },
    [updateCanvasMode],
  );

  return {
    isExpandedMode,
    setIsExpandedMode,
  };
};

export const useReserveSpaceForLeftPanel = (size: number) => {
  const { setLeftReservedSpace, currentLeftOpenPanelId } = useContext(ReserveSpaceForPanelsCtx);
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = uniqueId();
    currentLeftOpenPanelId.current = ref.current;
    setLeftReservedSpace(size);

    return () => {
      if (ref.current === currentLeftOpenPanelId?.current) {
        setLeftReservedSpace(0);
      }
    };
  }, [currentLeftOpenPanelId, setLeftReservedSpace, size]);
};

export const useReserveSpaceForRightPanel = (size: number) => {
  const { setRightReservedSpace, currentRightOpenPanelId } = useContext(ReserveSpaceForPanelsCtx);
  const ref = useRef<string>();

  useEffect(() => {
    ref.current = uniqueId();
    currentRightOpenPanelId.current = ref.current;
    setRightReservedSpace(size);

    return () => {
      if (ref.current === currentRightOpenPanelId?.current) {
        setRightReservedSpace(0);
      }
    };
  }, [currentRightOpenPanelId, setRightReservedSpace, size]);
};

export const useGetReservedSpaceForPanels = () => {
  const { leftReservedSpace, rightReservedSpace } = useContext(ReserveSpaceForPanelsCtx);
  return { leftReservedSpace, rightReservedSpace };
};
