import { createContext, Dispatch, SetStateAction, useContext, useState } from 'react';
import { CustomIntegrationAuthenticationType } from './WebhookContent';

export interface CustomWebhookInterface {
  id: string;
  name: string;
  baseUrl: string;
  authentication: {
    type: CustomIntegrationAuthenticationType;
    password?: string;
    key: string;
  };
}

interface WebhooksContextType {
  setWebhooks: Dispatch<SetStateAction<boolean>>;
  editWebhook?: Partial<CustomWebhookInterface>;
  setEditWebhook: (webhook?: Partial<CustomWebhookInterface>) => void;
  isWebhooksOpen: boolean;
}

const WebhooksContext = createContext<WebhooksContextType>({
  setEditWebhook: () => {},
  setWebhooks: () => {},
  isWebhooksOpen: false,
});

export const WebhooksProvider = ({ children }: { children: React.ReactNode }) => {
  const [editWebhook, setEditWebhook] = useState<Partial<CustomWebhookInterface>>();
  const [isWebhooksOpen, setWebhooks] = useState(false);

  return (
    <WebhooksContext.Provider value={{ isWebhooksOpen, setWebhooks, editWebhook, setEditWebhook }}>
      {children}
    </WebhooksContext.Provider>
  );
};

export const useWebhooksContext = () => {
  const { isWebhooksOpen, setWebhooks, editWebhook, setEditWebhook } = useContext(WebhooksContext);

  return {
    setEditWebhook,
    editWebhook,
    isWebhooksOpen,
    setWebhooks,
  };
};
