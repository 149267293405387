import { RootState } from '.';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewFunnelDialogReducerState {
  dialogData?: {
    crmOrgId: string;
    name?: string;
    leadingObject?: FunnelLeadingObject;
  };
}

const initialState: NewFunnelDialogReducerState = {};

export const newFunnelDialogReducerSlice = createSlice({
  name: 'newFunnel',
  initialState,
  reducers: {
    openNewFunnelDialog: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      state.dialogData = {
        crmOrgId: action.payload.crmOrgId,
      };
    },
    closeNewFunnelDialog: (state) => {
      state.dialogData = undefined;
    },
  },
});

export const selectNewFunnelDialogData = (state: RootState) => state.newFunnelDialog.dialogData;
export const selectIsNewFunnelDialogOpen = (state: RootState) =>
  Boolean(state.newFunnelDialog.dialogData);

export const { openNewFunnelDialog, closeNewFunnelDialog } = newFunnelDialogReducerSlice.actions;
export default newFunnelDialogReducerSlice.reducer;
