import Box from '@mui/material/Box/Box';
import { Typography, colors } from '@sweep-io/sweep-design';
import { getStrByLength } from '../../../../../common/rule-builder/helpers';
import { border } from '../consts';
import { ReadOnlyAutomationAction } from '@server/read-only-elements.types';
import { HeaderWithIconOrNumber } from './atoms/HeaderWithIconOrNumber';
import { propertiesToIcon } from './helpers/icons';
import { Subtext } from './atoms/Subtext';

export const ConditionsWrapper = ({
  readOnlyAutomationAction,
  startLogic,
  noConditionExternalBorder,
}: {
  readOnlyAutomationAction: ReadOnlyAutomationAction;
  startLogic?: string;
  noConditionExternalBorder?: boolean;
}) => {
  const { conditionsLogicString, title, value, details } = readOnlyAutomationAction;

  if (!conditionsLogicString) {
    return;
  }

  return (
    <Box>
      {!!title && (
        <Box pt={0.5}>
          <HeaderWithIconOrNumber headerText={title} HeaderIcon={propertiesToIcon['conditions']} />
        </Box>
      )}

      {value && (
        <Box pt={1} pb={1}>
          <Subtext text={value} />
        </Box>
      )}

      <Box position="relative">
        <ConditionsByLogicString
          logicString={conditionsLogicString}
          conditions={details as string[]}
          startLogic={startLogic}
          noConditionExternalBorder={noConditionExternalBorder}
        />
      </Box>
    </Box>
  );
};

type SingleLogic = {
  type: 'Literal' | 'Identifier' | 'CallExpression';
  name: string;
  value: number;
};

type LogicStringType = {
  arguments?: SingleLogic[];
  callee?: { type: string; name: string };
} & SingleLogic;

const ConditionsByLogicString = ({
  logicString,
  conditions,
  startLogic,
  noConditionExternalBorder,
}: {
  logicString: string;
  conditions: string[];
  startLogic?: string;
  noConditionExternalBorder?: boolean;
}) => {
  const logicStringArr: LogicStringType[] = getStrByLength(logicString);
  return (
    <Box
      pl={3}
      sx={{
        position: 'relative',
        ...(noConditionExternalBorder ? {} : border),
        ml: noConditionExternalBorder ? 0 : 0.5,
      }}
    >
      {startLogic && <Logic logic={startLogic} />}
      {logicStringArr.map((item, idx) => {
        const name = item.name?.toLowerCase();
        if (item.type === 'Literal') {
          return (
            <Condition key={conditions[item.value - 1]} condition={conditions[item.value - 1]} />
          );
        }

        if (item.type === 'CallExpression') {
          return <Group key={'group_' + idx} conditions={conditions} item={item} />;
        }

        return <Logic key={'logic_' + idx + name} logic={name} />;
      })}
    </Box>
  );
};

const Condition = ({ condition }: { condition: string }) => (
  <Box sx={{ mt: 0.5, mb: 0.5 }}>
    <Typography variant="body" color={colors.grey[800]}>
      {condition}
    </Typography>
  </Box>
);

const Logic = ({ logic }: { logic?: string }) => (
  <Box>
    <Typography variant="body-medium">
      {logic?.replace(/\b[a-z]/g, function (letter) {
        return letter.toUpperCase();
      })}
    </Typography>
  </Box>
);

const Group = ({ item, conditions }: { item: LogicStringType; conditions: string[] }) => (
  <>
    <Logic logic={item.callee?.name?.toLowerCase() ?? ''} />
    <Box pl={3} mt={0.5} sx={{ ...border, position: 'relative' }}>
      {item.arguments?.map((argument, idx) => {
        switch (argument.type) {
          case 'Identifier':
            return (
              <Logic
                key={'inner_logic_' + idx + argument.name}
                logic={argument.name?.toLowerCase()}
              />
            );

          case 'CallExpression': {
            return <Group key={'inner_group_' + idx} item={argument} conditions={conditions} />;
          }

          case 'Literal': {
            return (
              <Condition
                key={conditions[argument.value - 1]}
                condition={conditions[argument.value - 1]}
              />
            );
          }
        }
      })}
    </Box>
  </>
);
