import _ from 'lodash';
import { NestedItem, NestedSelectorPath, NestedSelectorPathFragment } from './NestedSelector';

export const pathFragmentFromItem = ({
  label,
  value,
  data,
}: NestedItem): NestedSelectorPathFragment => ({
  label,
  value,
  data,
});

export const isSamePath = (path1: NestedSelectorPath, path2: NestedSelectorPath) =>
  _.isEqual(path1, path2);
