import orderBy from 'lodash/orderBy';

const sortFunnelMapsListByDate = ({
  list,
  sortKey = 'updatedAt',
}: {
  list: FunnelMap[];
  sortKey: 'updatedAt' | 'createdAt';
}) => {
  return orderBy(list, [sortKey], ['desc']);
};

export default sortFunnelMapsListByDate;
