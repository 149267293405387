import { useSelector } from 'react-redux';
import { selectDefaultCreationEnvironment } from '../../pages/environments/environmentsReducer';
import { useEffect, useRef, useState } from 'react';
import { useUnhappyStates } from '../../pages/environments/useUnhappyStates';

const TOTAL_FETCH_MINUTES = 20; //this is an estimation for the total
const MAXIMUM_PROGRESS_TO_SHOW = 16; // after 16 minutes, the product is to keep showing same %
const SECONDS_INTERVAL = 5;
const SECONDS_TO_WAIT = 2; //time for showing "100%", before the value is reset

const updateProgressPercentage = (updatedAt?: string) => {
  const date1 = new Date(updatedAt ?? Date.now());
  const date2 = new Date(Date.now());

  const differenceInMinutes = (date2.getTime() - date1.getTime()) / 1000 / 60;

  const progressInMinutes =
    differenceInMinutes > MAXIMUM_PROGRESS_TO_SHOW ? MAXIMUM_PROGRESS_TO_SHOW : differenceInMinutes;
  const progressRatio = progressInMinutes / TOTAL_FETCH_MINUTES;

  return Math.round(progressRatio * 100);
};

const useInitialFetchProgress = (onFinish?: () => void) => {
  const { isInitialFetch } = useUnhappyStates();
  const crmOrg = useSelector(selectDefaultCreationEnvironment);
  const { updatedAt } = crmOrg ?? {};
  const [progressPercentage, setProgressPercentage] = useState<number | null>(null);
  const localIsFetching = useRef<boolean | undefined>(); //need a local variable to simulate the progress reaching 100%

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;

    if (isInitialFetch) {
      localIsFetching.current = true;
      setProgressPercentage(updateProgressPercentage(updatedAt));

      interval = setInterval(() => {
        setProgressPercentage(updateProgressPercentage(updatedAt));
      }, SECONDS_INTERVAL * 1000);
    }

    if (!isInitialFetch && localIsFetching.current) {
      clearInterval(interval);
      setProgressPercentage(100);
      setTimeout(() => {
        setProgressPercentage(null);
      }, SECONDS_TO_WAIT * 1000);
      onFinish && onFinish();
    }

    return () => clearInterval(interval);
  }, [setProgressPercentage, updatedAt, isInitialFetch, onFinish]);

  return { progressPercentage };
};

export default useInitialFetchProgress;
