import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';
import { Dispatch, useState } from 'react';
import { FunnelToDeploy } from './FunnelToDeploy';
import { EnvironmentToDeploy } from './EnvironmentToDeploy';
import { Box } from '@mui/material';
import { DeploymentDiff } from '../deployment-changes/DeploymentDiff';
import { Typography } from '@sweep-io/sweep-design';

interface DeployContentProps {
  selectedFunnelId: string;
  setSelectedFunnelId: (id: string) => void;
  selectedOrgId?: string;
  setSelectedOrgId: Dispatch<any>;
  onConfirmDeploy: () => void;
  onConfirmTitle?: string;
  onCancel: () => void;
  orgs: CrmOrg[];
  showNote?: boolean;
  onNoteChange?: (note: string) => void;
  funnels: Funnel[];
  initialCrmOrgId: string;
}

const DeployContent = ({
  selectedFunnelId,
  setSelectedFunnelId,
  selectedOrgId,
  setSelectedOrgId,
  onConfirmDeploy,
  onConfirmTitle = 'Deploy',
  onCancel,
  onNoteChange,
  showNote = false,
  orgs,
  funnels,
  initialCrmOrgId,
}: DeployContentProps) => {
  const hasOnlyOneFunnel = funnels.length === 1;

  const step1 = {
    id: 'step1',
    title: 'Choose funnel to deploy',
    showBackButton: () => false,
    getSecondaryText: () => '',
    getPrimaryText: () => 'Next',
    backActionCallback: () => undefined,
  };

  const step2 = {
    id: 'step2',
    title: 'Deploy funnel to environment',
    showBackButton: () => !hasOnlyOneFunnel,
    getSecondaryText: (): string => 'Preview deployed changes',
    getPrimaryText: () => onConfirmTitle,
    backActionCallback: () => {
      if (!hasOnlyOneFunnel) {
        setCurrentStep(step1);
      }
    },
  };

  const step3 = {
    id: 'step3',
    title: 'Deployment details',
    showBackButton: () => true,
    getSecondaryText: () => '',
    getPrimaryText: () => onConfirmTitle,
    backActionCallback: () => {
      setCurrentStep(step2);
    },
  };

  const [currentStep, setCurrentStep] = useState(hasOnlyOneFunnel ? step2 : step1);

  const closeHandler = (ev: any, reason?: string) => {
    if (reason === 'backdropClick' || reason === 'closeClick') {
      onCancel();
    }
  };

  const primaryHandler = () => {
    if (currentStep.id === step1.id) {
      setCurrentStep(step2);
    } else {
      onConfirmDeploy();
    }
  };
  const secondaryHandler = () => {
    if (currentStep.id === step2.id) {
      setCurrentStep(step3);
    }
  };

  return (
    <ConfirmDialog
      isOpen={true}
      disableHandleCloseOnConfirm
      PaperProps={{
        sx: { '.MuiDialogContent-root': { p: 0, overflowX: 'hidden', minWidth: '600px' } },
      }}
      title={currentStep.title}
      dialogActionsSx={{
        p: 4,
      }}
      showCloseButton
      handleClose={closeHandler}
      showOnlyConfirm={currentStep.getSecondaryText() === ''}
      showBackButton={currentStep.showBackButton()}
      handleBack={currentStep.backActionCallback}
      onCancel={secondaryHandler}
      onConfirm={primaryHandler}
      confirmText={currentStep.getPrimaryText()}
      cancelText={currentStep.getSecondaryText()}
    >
      {currentStep.id === step1.id && (
        <FunnelToDeploy
          funnels={funnels}
          selectedFunnelId={selectedFunnelId}
          setSelectedFunnelId={setSelectedFunnelId}
          initialCrmOrgId={initialCrmOrgId}
        />
      )}
      {currentStep.id === step2.id && (
        <EnvironmentToDeploy
          orgs={orgs}
          setSelectedOrgId={setSelectedOrgId}
          selectedOrgId={selectedOrgId ?? ''}
          showNote={showNote}
          onNoteChange={onNoteChange}
        />
      )}
      {currentStep.id === step3.id && (
        <Box pt={3} px={4}>
          <Box mb={1}>
            <Typography variant="h4">Summary of changes</Typography>
          </Box>
          <DeploymentDiff crmOrgId={selectedOrgId} funnelId={selectedFunnelId} />
        </Box>
      )}
    </ConfirmDialog>
  );
};
export default DeployContent;
