import type {
  ReadOnlyAutomation,
  ReadOnlyAutomationAction,
} from '@server/read-only-elements.types';

export const wrapContent = (content: string) =>
  `<span class="diff-section-content">${content}</span>`;

export const renderActionDetails = (action: ReadOnlyAutomationAction | string, level = 0) => {
  let actionsBody = '';
  if (typeof action === 'string') {
    actionsBody += `<li style="padding-left: ${14 * (level + 1)}px; word-break: break-word">${action}</li>\n`;
  } else {
    if (action.title || action.value) {
      actionsBody += `<span style="padding-left: ${14 * (level + 1)}px; font-weight: 500">${action.title ?? ''} </span>`;
      if (action.value) {
        actionsBody += `${action.value}`;
      }
      actionsBody += `\n`;
    }
    actionsBody += action.details.map((info) => renderActionDetails(info, level + 1)).join('');
  }
  return actionsBody;
};

export const formatAutomationDiff = (readVersion: ReadOnlyAutomation): string => {
  let diffTemplate = `
<div class="diff-section-title"><img src="/icons/diff/name.svg" /> Name</div>
${wrapContent(readVersion.name)}
<div class="diff-section-title"><img src="/icons/diff/condition.svg" /> Status</div>
${wrapContent(readVersion.isActive ? 'Active' : 'Inactive')}
<div class="diff-section-title"><img src="/icons/diff/trigger.svg" /> Trigger</div>
${wrapContent(readVersion.trigger)}`;

  if (readVersion.delay && readVersion.delay.length) {
    diffTemplate += `
<div class="diff-section-title"><img src="/icons/diff/delay.svg" /> Delay actions</div>
${readVersion.delay?.map((str) => wrapContent(str)).join('\n')}`;
  }

  if (readVersion.conditions && readVersion.conditions.length) {
    diffTemplate += `
<div class="diff-section-title"><img src="/icons/diff/condition.svg" /> Condition</div>
${readVersion.conditions?.map((val) => wrapContent(val)).join('\n')}`;
  }

  diffTemplate += `
<div class="diff-section-title"><img src="/icons/diff/action.svg" /> Action(s)</div>
`;

  let actionsBody = '';
  for (let idx = 0; idx < readVersion.actions.length; idx++) {
    const action = readVersion.actions[idx];
    actionsBody += `<div style="font-weight: 500">${idx + 1} ${action.title}</div>`;
    actionsBody += action.details.map((section) => renderActionDetails(section)).join('');
  }

  diffTemplate += actionsBody;

  return diffTemplate;
};
