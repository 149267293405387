import { PluginBantSvg } from '../../icons';
import {
  ConnectedBaseCriteriaPanel,
  CustomCriteriaPanelProps,
} from '../../base-criteria-plugin-panel/ConnectedBaseCriteriaPanel';
import { bantPluginStepMapping } from './pluginData';
import { PluginTypes } from '../../../../../../../types/enums/PluginTypes';

const ConnectedBantPanel = (props: CustomCriteriaPanelProps) => {
  return (
    <ConnectedBaseCriteriaPanel
      {...props}
      title="BANT Methodology"
      pluginIcon={<PluginBantSvg />}
      pluginStepMapping={bantPluginStepMapping}
      pluginType={PluginTypes.BANT}
    />
  );
};

export { ConnectedBantPanel as BantPanel };
