import { useContext, useEffect } from 'react';
import { useReactFlow } from 'reactflow';
import { CanvasWrapperResizeObserverContext } from './internal-context/CanvasWrapperDimensionsContext';
import { NODE_HEIGHT, NODE_WIDTH } from './const';

export const useRepositionCanvas = ({
  newNodeId,
}: // canvasWrapperRef,
{
  newNodeId?: string;
  // canvasWrapperRef: React.RefObject<HTMLDivElement>;
}) => {
  const reactFlowInstance = useReactFlow();
  const { canvasWrapperRef: canvasWrapperRef } = useContext(CanvasWrapperResizeObserverContext);

  // Centers the canvas on a new node if the node is outside of the view
  useEffect(() => {
    const nodes = reactFlowInstance.getNodes();
    const newNodePosition = nodes.find((node) => node.id === newNodeId)?.positionAbsolute;

    if (reactFlowInstance && newNodePosition) {
      const position = newNodePosition;
      const canvasWrapperClientRect = canvasWrapperRef?.current?.getBoundingClientRect();

      const topLeftPosition = reactFlowInstance?.screenToFlowPosition({
        x: canvasWrapperClientRect?.left || 0,
        y: canvasWrapperClientRect?.top || 0,
      });

      const topRightPosition = reactFlowInstance?.screenToFlowPosition({
        x: canvasWrapperClientRect?.right || 0,
        y: canvasWrapperClientRect?.top || 0,
      });

      const _zoom = reactFlowInstance?.getZoom() || 1;

      const isOverflowingOnTheTop = position.y - NODE_HEIGHT * _zoom < topLeftPosition.y || 0;
      const isOverflowingOnTheRight = position.x + NODE_WIDTH * _zoom > topRightPosition.x || 0;

      if (isOverflowingOnTheTop || isOverflowingOnTheRight) {
        reactFlowInstance?.setCenter(position.x, position.y, {
          zoom: _zoom,
          duration: 100,
        });
      }
    }
  }, [canvasWrapperRef, newNodeId, reactFlowInstance]);
};
