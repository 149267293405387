import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { funnelDetailModel } from '../../../../../models/funnelDetailModel';
import { aStage } from '../../../../../models/stageModel';
import { RootState } from '../../../../../reducers';
import { StageType } from '../../../../../types/enums/StageType';

export interface FunnelSettingsDialogProps {
  isOpen: boolean;
  id?: string;
  name?: string;
  defaultCrmOrgId?: string;
  lostSteps?: FunnelSettingsLostStep;
  initialState?: {
    id: string;
    name: string;
  };
  funnels?: Funnel[];
}

interface LostStepsByObjectType {
  [x: string]: {
    id: string;
    lostSteps: SweepStage[];
  };
}

interface FunnelSettingsLostStep {
  [funnelId: string]: {
    [objectType: string]: { id: string; lostSteps?: SweepStage[] };
  };
}

const initialState: FunnelSettingsDialogProps = {
  isOpen: false,
};

export const funnelSettingsDialogSlice = createSlice({
  name: 'funnelSettingsDialog',
  initialState,
  reducers: {
    openFunnelSettingsDialog: (
      state,
      action: PayloadAction<Omit<FunnelSettingsDialogProps, 'isOpen' | 'recordTypes'>>,
    ) => {
      const { id, name, funnels } = action.payload;
      state.isOpen = true;
      state.name = name;
      state.id = id;
      state.funnels = funnels;

      if (funnels) {
        let lostSteps = {};

        funnels.forEach((funnel) => {
          const newLostStep = getLostSteps(funnel.funnelDetails, funnel.id);
          lostSteps = { ...lostSteps, ...newLostStep };
        });

        state.lostSteps = lostSteps;
      }

      state.initialState = {
        id: id ?? '',
        name: name ?? '',
      };
    },
    setLostSteps: (
      state,
      action: PayloadAction<{
        lostSteps?: FunnelSettingsLostStep;
      }>,
    ) => {
      const { lostSteps } = action.payload;
      state.lostSteps = lostSteps;
    },
    closeFunnelSettingsDialog: (state) => {
      state.isOpen = false; //TODO clean whole state
    },
    updateName: (state, action: PayloadAction<{ name: string }>) => {
      state.name = action.payload.name;
    },
    addLostStep: (
      state,
      action: PayloadAction<{
        funnelId: string;
        stageName: string;
        objectType: string;
      }>,
    ) => {
      const { funnelId, objectType } = action.payload;

      const newStage = aStage({
        stageType: StageType.LOST,
      });

      if (state.lostSteps) {
        state.lostSteps[funnelId][objectType]?.lostSteps?.push(newStage);
      }
    },
    updateLostStep: (
      state,
      action: PayloadAction<{
        funnelId: string;
        objectType: string;
        _stageId: string;
        stageName: string;
      }>,
    ) => {
      const { funnelId, _stageId, stageName: newStageName, objectType } = action.payload;

      if (state.lostSteps) {
        const lostSteps = state.lostSteps[funnelId][objectType].lostSteps;

        state.lostSteps[funnelId][objectType].lostSteps = lostSteps?.map((lostStep: any) => {
          return lostStep._stageId === _stageId
            ? { ...lostStep, stageName: newStageName }
            : lostStep;
        });

        if (state.funnels) {
          state.funnels = writeLostStepsToFunnelDetail(state.funnels, state.lostSteps);
        }
      }
    },
    deleteLostStep: (
      state,
      action: PayloadAction<{ objectType: string; funnelId: string; _stageId: string }>,
    ) => {
      const { funnelId, _stageId, objectType } = action.payload;

      if (state.lostSteps) {
        const lostSteps = state.lostSteps[funnelId][objectType].lostSteps;

        if (lostSteps) {
          const idx = lostSteps.findIndex((lostStep: any) => lostStep._stageId === _stageId);
          if (idx > -1) {
            lostSteps.splice(idx, 1);
          }

          state.lostSteps[funnelId][objectType].lostSteps = lostSteps;

          if (state.funnels) {
            state.funnels = writeLostStepsToFunnelDetail(state.funnels, state.lostSteps);
          }
        }
      }
    },
  },
});

const reducerName = funnelSettingsDialogSlice.name;

export const selectIsFunnelSettingsDialogOpened = (state: RootState) => state[reducerName].isOpen;

export const selectFunnelSettingsName = (state: RootState) => state[reducerName].name;

export const selectFunnelSettingsLostSteps = (state: RootState) => state[reducerName].lostSteps;

export const selectFunnelSettingFunnels = (state: RootState) => state[reducerName].funnels;

export const {
  openFunnelSettingsDialog,
  closeFunnelSettingsDialog,
  updateName,
  addLostStep,
  updateLostStep,
  deleteLostStep,
} = funnelSettingsDialogSlice.actions;

const getLostSteps = (funnelDetail: FunnelDetails, funnelId: string) => {
  const funnelModel = funnelDetailModel(funnelDetail);
  const { leadingObject } = funnelDetail;
  const lostSteps = funnelModel.getLostSteps();
  const _lostStepIdsByObject: LostStepsByObjectType = {};

  const { objectName, _leadingFieldId } = leadingObject;
  _lostStepIdsByObject[objectName] = {
    id: _leadingFieldId,
    lostSteps,
  };

  return { [funnelId]: _lostStepIdsByObject };
};

const writeLostStepsToFunnelDetail = (funnels: Funnel[], lostSteps: FunnelSettingsLostStep) => {
  return funnels.map((funnel) => {
    const model = funnelDetailModel(funnel.funnelDetails);

    const fromFunnel = lostSteps[funnel.id];
    const leadingObject = funnel.funnelDetails.leadingObject;
    const objectName = leadingObject?.objectName ?? '';
    const onlyLostSteps: SweepStage[] = fromFunnel[objectName].lostSteps ?? [];

    onlyLostSteps.forEach((lostStep) => {
      const stageId = lostStep?._stageId;
      const stageIfExists = model.stageByIdOrUndefined(stageId ?? '');

      if (lostStep && lostStep.stageName && lostStep._stageId) {
        if (stageIfExists) {
          model.replaceStage(lostStep);
        } else {
          model.addStage(lostStep);
        }
      }
    });

    // Remove all lost stage not in settings and with blank names
    model.getLostSteps().forEach(({ _stageId }) => {
      const exists = onlyLostSteps.find((lostStep) => lostStep?._stageId === _stageId);
      const hasName = exists?.stageName;

      if (!hasName || !exists) {
        model.removeStage(_stageId);
      }
    });

    return { ...funnel, funnelDetails: model.toJSON() };
  });
};
