import { Typography } from '@mui/material';
import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';

const ConfirmMetadataChangeDialog = ({
  stageName,
  onConfirm,
  onCancel,
}: {
  stageName: string;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <ConfirmDialog
      isOpen
      title="Shared Opportunity Stage"
      confirmText="Apply across funnels"
      onConfirm={onConfirm}
      onCancel={onCancel}
      dialogContentSx={{ width: '375px' }}
    >
      <Typography>
        {stageName} is shared across multiple funnels. This change will impact all the funnels this
        opportunity stage has been used for. To make a change in this funnel only, rename the
        opportunity stage or create a new one.
      </Typography>
    </ConfirmDialog>
  );
};

export { ConfirmMetadataChangeDialog };
