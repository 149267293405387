import { Typography } from '@sweep-io/sweep-design';
import { useGroupSettingsContext } from '../GroupSettingsContext';
import { Stack } from '@mui/material';
import { MembersAutocompleteField } from '../MembersAutocompleteField';
import { LimitMembersPanelWithContext } from '../LimitMembersPanelWithContext';
import { AssignmentGroup } from '../../../../../reducers/assignmentGroupTypes';

export const isAddMembersStepValid = (assignmentGroup: DeepPartial<AssignmentGroup>) => {
  return !!assignmentGroup.name;
};

export const AddMembersStepHeader = () => {
  const { assignmentGroup } = useGroupSettingsContext();
  return <Typography variant="h1">Add Members to {assignmentGroup.name}</Typography>;
};

const PANEL_WIDTH = 865;

export const AddMembersStepContent = () => {
  return (
    <Stack gap={4} minWidth={PANEL_WIDTH}>
      <MembersAutocompleteField />
      <LimitMembersPanelWithContext />
    </Stack>
  );
};
