import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import {
  selectSingleObjectActiveTab,
  selectSingleObjectSearchTxt,
} from '../../../../reducers/documentationReducer';
import { SearchInput } from '../../../common/SearchInput';
import { useDocumentation } from '../../useDocumentation';
import { useTabFiltersFunctions } from '../filters/useTabFilterFunctions';
import { ElementContent } from './ElementContent';
import { DocumentationTabTypes } from '../../../../types/enums/DocumentationTabTypes';
import { DocumentationFilters } from '../filters/DocumentationFilters';
import { selectCrmOrgHasManagePackage } from '../../../pages/environments/environmentsReducer';
import { ListWithBadge } from './ListWithBadge';
import { ConfigurationCanvasPanelContent } from '../../../pages/configuration-canvas-panel';
import { HORIZONTAL_PADDING } from '../../../pages/configuration-canvas-panel/consts';
import { ElementSubheader } from './ElementSubheader';
import { useSortElements } from '../useSortElements';
import { filterItemsBySearch } from '../../../../lib/filterItemsBySearch';
import { SelectedObjectHeader } from '../SelectedObjectHeader';
import { useSelectedObjectTabsStructure } from '../useSelectedObjectTabStructure';
import { useContext, useMemo } from 'react';
import { DocumentationDialogContext } from '../../context/DocumentationContextProvider';
import { DispatchSingleObjectNameAndFocusOnElementFuncProps } from '../../types';

interface SelectedObjectWithListProps {
  crmOrgId: string;
  objectTypeName: ObjectTypeName;
  onCloseDialog?: () => void;
  withExpandedModeButton?: boolean;
  hideAddObjectButton?: boolean;
  dispatchSingleObjectNameAndFocusOnElement: DispatchSingleObjectNameAndFocusOnElementFuncProps;
}

export const SelectedObjectWithList = ({
  crmOrgId,
  objectTypeName,
  onCloseDialog,
  withExpandedModeButton,
  hideAddObjectButton,
  dispatchSingleObjectNameAndFocusOnElement,
}: SelectedObjectWithListProps) => {
  const { objectNamesUsedInCanvas } = useContext(DocumentationDialogContext);

  const { onTabChange } = useDocumentation();
  const { setSearchTxt } = useTabFiltersFunctions();

  const searchTxt = useSelector(selectSingleObjectSearchTxt);
  const isManagePackageInstalled = useSelector(selectCrmOrgHasManagePackage(crmOrgId));
  const activeTab = useSelector(selectSingleObjectActiveTab);

  const isCardActiveTab = activeTab && activeTab === DocumentationTabTypes.CARDS_LIST;

  const { sfElements, sweepElements } = useSelectedObjectTabsStructure({
    crmOrgId,
    objectTypeName,
  });

  const elements = useMemo(() => [...sfElements, ...sweepElements], [sfElements, sweepElements]);
  const filteredElementsMap = filterItemsBySearch(elements, searchTxt ?? '', (item) => item.value);

  const { sortedItems } = useSortElements({
    activeTab: activeTab ?? ('' as any),
    isManagePackageInstalled: false,
    items: filteredElementsMap,
  });

  return (
    <Box
      sx={{
        '& .MuiTabs-root': {
          padding: '0 16px',
        },
      }}
    >
      <SelectedObjectHeader
        withExpandedModeButton={withExpandedModeButton}
        onCloseDialog={onCloseDialog}
        objectTypeName={objectTypeName}
        objectNamesUsedInCanvas={objectNamesUsedInCanvas}
        hideAddObjectButton={hideAddObjectButton}
        dispatchSingleObjectNameAndFocusOnElement={dispatchSingleObjectNameAndFocusOnElement}
      />

      <ConfigurationCanvasPanelContent sx={{ m: `0 -${HORIZONTAL_PADDING}px` }}>
        <Box p={HORIZONTAL_PADDING + 'px'}>
          <SearchInput
            withFixedMagnifyingGlassIcon
            placeholder="Search"
            TextFieldProps={{
              value: searchTxt,
              onChange: (event) => setSearchTxt(event.target.value),
            }}
            onClearButtonClick={() => setSearchTxt('')}
          />
        </Box>

        {isCardActiveTab && (
          <Box gap={1}>
            <ElementSubheader
              objectLabel={objectTypeName.label}
              items={sortedItems}
              activeTab={DocumentationTabTypes.CARDS_LIST}
              isManagePackageInstalled={isManagePackageInstalled}
              crmOrgId={crmOrgId}
            />

            <ListWithBadge elements={sortedItems} onClick={onTabChange} />
          </Box>
        )}

        {!isCardActiveTab && activeTab && (
          <>
            <ElementContent
              crmOrgId={crmOrgId}
              activeTab={activeTab}
              objectTypeName={objectTypeName}
              isManagePackageInstalled={!!isManagePackageInstalled}
            />
            <DocumentationFilters crmOrgId={crmOrgId} />
          </>
        )}
      </ConfigurationCanvasPanelContent>
    </Box>
  );
};
