const CRM_ORG_EXPIRE_TOKEN_MS = 30 * 60000; // 30 Minutes
const CRM_ORG_TOKEN_KEY = 'CRM_ORG_TOKEN_KEY';
const CRM_ORG_RESULT_KEY = 'CRM_ORG_RESULT_KEY';

export enum CrmOrgConnectingError {
  USER_DENIED_AUTHORIZATION = 'USER_DENIED_AUTHORIZATION',
  NOT_ENOUGH_PERMISSIONS = 'NOT_ENOUGH_PERMISSIONS',
  ERROR_CONNECTING = 'ERROR_CONNECTING',
}

type CrmOrgTransientData = {
  crmOrgId: string;
  isSandbox: boolean;
  expires: number;
  isOnboardingFastFetch?: boolean;
};

interface AbstractCrmOrgResultData {
  success: boolean;
}

interface CrmOrgResultDataError extends AbstractCrmOrgResultData {
  success: false;
  error: CrmOrgConnectingError;
}

interface CrmOrgResultDataSuccess extends AbstractCrmOrgResultData {
  success: true;
}

type CrmOrgResultData = CrmOrgResultDataError | CrmOrgResultDataSuccess;

const setCrmOrgTransientData = ({
  crmOrgId,
  isSandbox,
  isOnboardingFastFetch,
}: Omit<CrmOrgTransientData, 'expires'>) => {
  const data: CrmOrgTransientData = {
    crmOrgId,
    expires: Date.now() + CRM_ORG_EXPIRE_TOKEN_MS,
    isOnboardingFastFetch,
    isSandbox,
  };
  localStorage.setItem(CRM_ORG_TOKEN_KEY, JSON.stringify(data));
};

const setCrmOrgTransientResult = (data: CrmOrgResultData, crmOrgId: string) => {
  localStorage.setItem(CRM_ORG_RESULT_KEY + crmOrgId, JSON.stringify(data));
};

const getCrmOrgTransientResult = (crmOrgId: string): CrmOrgResultData | undefined => {
  const result = localStorage.getItem(CRM_ORG_RESULT_KEY + crmOrgId);
  if (!result) return;
  try {
    const crmOrgResult = JSON.parse(result) as CrmOrgResultData;
    localStorage.removeItem(CRM_ORG_RESULT_KEY);
    return crmOrgResult;
  } catch {}
};

const getCrmOrgTransientData = (): CrmOrgTransientData | undefined => {
  const connectSalesforceCrmOrgToken = localStorage.getItem(CRM_ORG_TOKEN_KEY);
  if (!connectSalesforceCrmOrgToken) return;
  try {
    const crmOrgIdToken = JSON.parse(connectSalesforceCrmOrgToken) as CrmOrgTransientData;

    if (crmOrgIdToken.expires > Date.now()) {
      return crmOrgIdToken;
    }
    localStorage.removeItem(CRM_ORG_TOKEN_KEY);
  } catch {}
};

export {
  setCrmOrgTransientData,
  setCrmOrgTransientResult,
  getCrmOrgTransientData,
  getCrmOrgTransientResult,
};
