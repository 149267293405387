import Box from '@mui/material/Box/Box';
import { IconButton, Tooltip } from '@sweep-io/sweep-design';
import { Hide, Show } from '@sweep-io/sweep-design/dist/icons';
import { useRecordTypesVisibilityToggle } from './useRecordTypeVisibilityToggle';
import { useConfigurationCanvas } from '../useConfigurationCanvas';
import { useMemo } from 'react';

export const RecordTypeVisibilityToggle = ({ recordTypeId }: { recordTypeId: string }) => {
  const { canvasFunnelMap } = useConfigurationCanvas();
  const { onVisibilityChange } = useRecordTypesVisibilityToggle();

  const hiddenRTMap: { [recordTypeId: string]: boolean } = useMemo(() => {
    const _hiddenRtMap: { [recordTypeId: string]: boolean } = {};
    const recordTypes = canvasFunnelMap.recordTypes;
    const funnels = canvasFunnelMap.funnels;

    Object.keys(recordTypes).forEach((rtId) => {
      _hiddenRtMap[rtId] = !!recordTypes[rtId].isHidden;
    });

    Object.keys(funnels).forEach((funnelId) => {
      const funnelRT = canvasFunnelMap.funnelsData[funnelId].recordType;
      const _rtId = funnelRT.objectName + '.' + funnelRT.name;
      _hiddenRtMap[_rtId] = !!funnels[funnelId].isHidden;
    });

    return _hiddenRtMap;
  }, [canvasFunnelMap]);

  const isVisible = !hiddenRTMap[recordTypeId];
  const icon = isVisible ? <Show /> : <Hide />;

  return (
    <Box pr={2}>
      <Tooltip title={isVisible ? 'Hide record type from canvas' : 'Show record type in canvas'}>
        <IconButton
          size="small"
          variant="flat"
          onClick={(event) => {
            event.stopPropagation();
            onVisibilityChange(recordTypeId);
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </Box>
  );
};
