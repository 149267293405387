import { AutomationFormVariant } from '../../types/enums/AutomationFormVariant';
import {
  AutomationsContextProvider,
  AutomationsContextType,
} from '../Automations/AutomationsContext';
import { AutomationType } from '../../types/enums/AutomationType';
import { UI_EVENTS } from '../../services/events';
import { useAutomationsApiFacade } from '../../apis/facades/useAutomationsApiFacade';
import { useMemo } from 'react';
import BoxEmptyImage from '../common/empty-state/svgs/boxEmpty.svg?react';

export const AutomationAutomationsContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { patch_automation_admin_notification } = useAutomationsApiFacade();

  const contextValue: AutomationsContextType = useMemo(
    () => ({
      automationType: AutomationType.Default,
      permissions: {
        create: 'create:automations',
        edit: 'edit:automations',
        deploy: 'deploy:automations',
        delete: 'delete:automations',
        notifyAdmin: 'edit:automations:notify-admin',
      },
      emptyStateTitle: 'Create your first automation',
      emptyStateImage: <BoxEmptyImage />,
      defaultName: 'New Automation',
      placeholder: 'New Automation',
      nameInMessage: 'automations',
      nameInMessageSingular: 'automation',
      openEvent: UI_EVENTS.automationsOpen,
      automationVariant: AutomationFormVariant.AUTOMATIONS,
      templateSupport: true,
      requestDeployNotifyAdmin: patch_automation_admin_notification,
    }),
    [patch_automation_admin_notification],
  );

  return <AutomationsContextProvider value={contextValue}>{children}</AutomationsContextProvider>;
};
