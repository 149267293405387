import { useDispatch, useSelector } from 'react-redux';
import {
  selectCanvasFilterSelectedValuesFor,
  setCanvasFilterItemsDataFromFilterItems,
  setCanvasFilterSelectedValuesFor,
} from './canvasFiltersReducer';
import { ChangeEvent, useCallback, useEffect } from 'react';
import { Box, FormControlLabel, styled } from '@mui/material';
import { Typography, colors, Checkbox } from '@sweep-io/sweep-design';

interface ToggleFilterProps {
  label: string;
  checked: boolean;
  filterKey: string;
}

const StyledFieldLabel = styled('label')({});

export function ToggleFilter({ filterKey, checked, label }: ToggleFilterProps) {
  const dispatch = useDispatch();
  const values = useSelector(selectCanvasFilterSelectedValuesFor(filterKey));
  const isChecked = values.length > 0 && values[0] === 'true';

  const onChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      dispatch(
        setCanvasFilterSelectedValuesFor({
          filterKey,
          selectedValues: [checked.toString()],
        }),
      );
    },
    [dispatch, filterKey],
  );

  useEffect(() => {
    dispatch(
      setCanvasFilterItemsDataFromFilterItems({
        filterKey,
        items: [
          {
            label,
            value: checked.toString(),
          },
        ],
      }),
    );
  }, [dispatch, filterKey, checked, label]);

  return (
    <Box
      display="flex"
      gap={1}
      alignItems="center"
      sx={{
        background: colors.white,
        pl: 1,
        pr: 1,
        border: `1px solid ${colors.grey[200]}`,
        borderRadius: '4px',
      }}
    >
      <FormControlLabel
        label={
          <StyledFieldLabel sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography wordBreak="keep-all" whiteSpace="nowrap" variant="caption">
              {label}
            </Typography>
          </StyledFieldLabel>
        }
        control={<Checkbox checked={isChecked} onChange={onChange} />}
      />
    </Box>
  );
}
