import { colors, IconButton, Tooltip } from '@sweep-io/sweep-design';
import { Download } from '@sweep-io/sweep-design/dist/icons';
import { downloadCSV } from './downloadCSV';
import { useSweepNotifications } from '../../../notifications/useSweepNotifications';
import { SweepNotificationVariant } from '../../../../reducers/notificationsReducer';
import { useFeatureToggle } from '../../../common/useFeatureToggle';
import usePaywall from '../../../dashboard/paywall/usePaywall';
import DownloadCsvPaywallBlock from './DownloadCsvPaywallBlock';
import { ConfigurationItem } from '../../dependencies/DependenciesTypes';
import { addStatusAndUpdateType } from './utils';
import { useState } from 'react';

interface DownloadAsCsvProps {
  fileName: string;
  headers: { label: string; value: string }[];
  rows: ConfigurationItem[];
}

export const DownloadAsCsvButton = ({ fileName, headers, rows }: DownloadAsCsvProps) => {
  const { downloadCsv } = useFeatureToggle();
  const { addNotification } = useSweepNotifications();

  const { isPaywallActive } = usePaywall();
  const [isOpen, setIsOpen] = useState(false);

  if (!downloadCsv) {
    return <></>;
  }

  return (
    <>
      {isOpen && <DownloadCsvPaywallBlock closeDialog={() => setIsOpen(false)} />}
      <Tooltip title="Download as CSV" placement="top">
        <IconButton
          size="tiny"
          variant="flat"
          onClick={async () => {
            if (isPaywallActive) {
              setIsOpen(true);
              return;
            }

            const downloadedSuccessfully = downloadCSV({
              fileName,
              headers,
              rows: addStatusAndUpdateType(rows),
            });

            if (!downloadedSuccessfully) {
              addNotification({
                variant: SweepNotificationVariant.Error,
                message: 'Nothing to download',
              });
            }
          }}
        >
          <Download color={colors.grey[800]} />
        </IconButton>
      </Tooltip>
    </>
  );
};
