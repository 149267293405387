import Box from '@mui/material/Box/Box';
import { Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

interface HeaderWithIconOrNumberProps {
  headerText: string;
  HeaderIcon?: ReactNode;
  number?: number;
}

export const HeaderWithIconOrNumber = ({
  HeaderIcon,
  headerText,
  number,
}: HeaderWithIconOrNumberProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    {!number && HeaderIcon && (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>{HeaderIcon}</Box>
    )}
    {number && headerText && (
      <Box component="span" pr={0.5} display="flex" alignItems="center">
        <Typography variant="body-medium">{number}.</Typography>
      </Box>
    )}
    <Typography variant="body-medium">{headerText}</Typography>
  </Box>
);
