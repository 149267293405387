import { useFlags } from 'launchdarkly-react-client-sdk';
import useQueryParams from '../../hooks/useQueryParams';

export const useFeatureToggle = () => {
  const query = useQueryParams();

  const debugTools = query.has('debugTools');

  // Please remove feature toggles no longer in use when merging an experiment
  // This list should have all currently used feature toggle flags.
  const {
    reportsComingSoonTag,
    orgFactoryReset,
    hubspotIntegration,
    sweepSupportChat,
    priorValueSlackMessages,
    copilot,
    fieldsPreviewInDevopsCenter,
    verifyUrlAction,
    storeChannelIdInSfField,
    searchV1,
    searchV2,
    userInputsTags,
    dealRoomActions,
    userInputsComments,
    salesActivatedTrial,
    conditionalAutomations,
    caseToCaseDedupe,
    automationPermissionMode,
    annotationsV2,
    getRecordsLoops,
    expandedDocumentationForFreeUsers,
    opp2OppDedupe,
    slackThreads,
    removeFromFavorites,
    assignmentGroupRoles,
    webhooks,
    foldRecordType,
    downloadCsv,
    thirdPartyFunnels,
    expandedModeRouting,
    copilotChatsHistory,
    customLogicInMatching,
  } = useFlags();

  return {
    debugTools,
    reportsComingSoonTag,
    orgFactoryReset,
    hubspotIntegration,
    sweepSupportChat,
    priorValueSlackMessages,
    copilot,
    fieldsPreviewInDevopsCenter,
    verifyUrlAction,
    storeChannelIdInSfField,
    searchV1,
    searchV2,
    userInputsTags,
    dealRoomActions,
    userInputsComments,
    salesActivatedTrial, //AKA "paywallV2"
    conditionalAutomations,
    caseToCaseDedupe,
    automationPermissionMode,
    annotationsV2,
    getRecordsLoops,
    expandedDocumentationForFreeUsers,
    opp2OppDedupe,
    slackThreads,
    removeFromFavorites,
    assignmentGroupRoles,
    webhooks,
    foldRecordType,
    downloadCsv,
    thirdPartyFunnels,
    expandedModeRouting,
    copilotChatsHistory,
    customLogicInMatching,
  };
};
