export enum DevOpsCenterDeploymentStatusCode {
  NotDeployed,
  VersionMismatch,
  Synced,
  PartiallySynced,
  FullySynced,
  Conflict,
}

export type DevOpsCenterDeploymentStatus =
  | { code: Exclude<DevOpsCenterDeploymentStatusCode, DevOpsCenterDeploymentStatusCode.Conflict> }
  | { code: DevOpsCenterDeploymentStatusCode.Conflict; reason: string };

export enum DevOpsElementType {
  Automation = 'Automations',
  Alert = 'Alerts',
  Assignment = 'Assignment rules',
  DedupMatching = 'Matching & Dedup',
  Rollup = 'Rollups',
  ScheduledAssignment = 'Scheduled Assignments',
  ScheduledReport = 'Scheduled Reports',
  Field = 'Fields',
}
