export interface CommentCreateRequestBase {
  commentBody: string;
  mentionedUserIds: MentionedUser[];
}

export interface CommentBase {
  id: string;
  commentBody: string;
  mentionedUserIds: MentionedUser[];
  createdById: string;
  createdAt: string;
  updatedAt: string;
}

export interface CommentDeleteRequestBase {
  commentReplyId: string;
}

export interface CommentEditRequestBase {
  commentReplyId: string;
  commentBody: string;
  mentionedUserIds: MentionedUser[];
}

export type MentionedUser = string;

//global filter options
export type FilterSortState = {
  filterBy: FilterByOptions;
  sortBy: SortByOptions;
  searchTerm: string;
};

export enum SortByOptions {
  SORT_BY_DATE = 'Sort by date',
  SORT_BY_UNREAD = 'Sort by unread',
}

export enum FilterByOptions {
  SHOW_ALL = 'All comments',
  ONLY_UNREAD = 'Only unread comments',
  ONLY_YOURS = 'Only your mentions and replies',
  ONLY_RESOLVED = 'Only resolved comments',
}
