import { SweepLogoLight } from '../../icons/generalIcons';
import { Box } from '@mui/material';
import { CenteredBox } from '../pages/environments/styledComponents';
const loadingImg = 'https://cdn.auth0.com/blog/auth0-react-sample/assets/loading.svg';

const LoadingSweep = () => (
  <div className="spinner">
    <CenteredBox sx={{ marginTop: '200px' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={loadingImg} alt="Loading..." />
        <SweepLogoLight />
      </Box>
    </CenteredBox>
  </div>
);

export default LoadingSweep;
