import { Box, MenuItem, SelectChangeEvent } from '@mui/material';
import { colors } from '@sweep-io/sweep-design';
import { useSweepFields } from '../../sweep-fields/useCachedSweepFields';
import SweepSelect from '../common/SweepSelect';

interface StepSelectorProps {
  onChange: (
    stageDetails: AutomationStageDetails,
    objectName?: string,
    sweepFields?: SweepField[],
  ) => any;
  stageDetails?: AutomationStageDetails;
  stages: (SweepStage | undefined)[];
  disabled?: boolean;
  isShowObjectMark?: boolean;
  crmOrgId: string;
  objectName: ObjectTypeValues;
}

export const StepSelector = ({
  stageDetails,
  onChange,
  stages,
  disabled,
  isShowObjectMark,
  crmOrgId,
  objectName,
}: StepSelectorProps) => {
  const { searchSweepFields } = useSweepFields();

  return (
    <Box>
      <SweepSelect
        FormControlProps={{ sx: { minWidth: '120px', marginRight: '8px' } }}
        SelectProps={{
          placeholder: 'Choose Step',
          disabled,
          value: stageDetails?._stageId ?? '',
          onChange: async (event: SelectChangeEvent<unknown>) => {
            const val = event.target.value;
            const stage = stages.find((stage) => stage?._stageId === val);
            if (val === 'ANY_STEP') {
              onChange({
                _stageId: 'ANY_STEP',
                stageName: 'Any step',
              });
            } else if (stage) {
              const { sweepFields: _sweepFields } = await searchSweepFields({
                objectType: [objectName],
                crmOrgId,
              });
              const sweepFields = _sweepFields[objectName].fields;
              onChange(
                {
                  _stageId: stage._stageId,
                  stageName: stage.stageName,
                },
                objectName,
                sweepFields,
              );
            }
          },

          renderValue: (val: any) => {
            const stage = stages.find((stage) => stage?._stageId === val);
            return stage?.stageName ?? '';
          },
        }}
      >
        {stages.map((stage) =>
          stage ? (
            <MenuItem key={stage._stageId} value={stage._stageId}>
              {stage.stageName}
              {isShowObjectMark && stage._stageId !== 'ANY_STEP' && stages.length > 1 && (
                <span
                  style={{
                    marginLeft: '8px',
                    background: colors.grey[200],
                    borderRadius: '6px',
                    padding: '2px 6px',
                  }}
                >
                  {objectName}
                </span>
              )}
            </MenuItem>
          ) : null,
        )}
      </SweepSelect>
    </Box>
  );
};
