export const STORYLANE_URLS = {
  documentation: 'https://sweep.storylane.io/demo/hbjqseohhwto',
  rollups: 'https://sweep.storylane.io/demo/ihtbpw0tseot',
  changeFeed: 'https://sweep.storylane.io/demo/yd7vj9lnuem5',
  automations: 'https://sweep.storylane.io/demo/oagcjvmpki88',
  slack: 'https://sweep.storylane.io/demo/mc61ghlnusdj',
  collaboration: 'https://sweep.storylane.io/demo/lqidb6n5oa1n',
  routing: 'https://sweep.storylane.io/demo/qtpcile2y8bk',
  templates: 'https://sweep.storylane.io/demo/splzud59bsv7',
  managedPackageInstall: 'https://sweep.storylane.io/demo/bqyosrfo7isk',
  reports: 'https://sweep.storylane.io/demo/eahcypxqjliz',
  devopsCenter: 'https://sweep.storylane.io/demo/amnkdtmnzpgh',
  aiAssistant: 'https://sweep.storylane.io/demo/splzud59bsv7', //TODO: change to the right one.
  dedupeAndMatching: 'https://sweep.storylane.io/demo/z6jhjlvfa8pw',
};

export const VIDEO_URLS = {
  intro: 'https://d1k66g6czkl65n.cloudfront.net/videos/SF_Sweep%20Demo%20-%20full.mp4',
};

export const APP_EXCHANGE_URL =
  'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N4V00000HDrUBUA1';
