import { TextField } from '@mui/material';
import { __SWEEP_DESIGN_TYPOGRAPHY_STYLES___ } from '@sweep-io/sweep-design';

export const DialogHeaderTextField = ({
  value,
  onChange,
  placeholder,
  maxLength,
  editDisabled,
  errorHelperText,
}: {
  value: string;
  onChange: (value: string) => any;
  placeholder: string;
  maxLength?: number;
  editDisabled?: boolean;
  errorHelperText?: string;
}) => {
  return (
    <TextField
      error={!!errorHelperText}
      disabled={editDisabled}
      fullWidth
      variant="standard"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      sx={{
        input: {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1']?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1']?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1']?.lineHeight,
        },

        '& .MuiInput-input::placeholder': {
          fontSize: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1']?.fontSize,
          fontWeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1']?.fontWeight,
          lineHeight: __SWEEP_DESIGN_TYPOGRAPHY_STYLES___['h1']?.lineHeight,
        },
      }}
      inputProps={{
        maxLength,
      }}
      helperText={errorHelperText}
    />
  );
};
