import Box from '@mui/material/Box';
import { colors, Typography } from '@sweep-io/sweep-design';
import { ReactNode } from 'react';

interface SectionProps {
  children: ReactNode;
  headerTxt: string;
  withBorderBottom?: boolean;
}

export const Section = ({ children, headerTxt, withBorderBottom }: SectionProps) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <Box
      borderBottom={withBorderBottom ? `1px solid ${colors.grey[200]}` : ''}
      pb={withBorderBottom ? 1 : 0}
      pt={withBorderBottom ? 2 : 0}
    >
      <Typography variant="caption-medium">{headerTxt}</Typography>
    </Box>
    {children}
  </Box>
);
