import type { LayoutData, OpenAiDescription } from '../components/parser/ParserTypes';
import {
  ConfigurationType,
  DependenciesContentViewType,
  UnclassifiedTypes,
  type LayoutsByObjectName,
  type ObjectWithPillsMap,
  type SelectedDependency,
  type TabFiltersAndSort,
} from '../components/documentation/dependencies/types';
import type { SearchResponse } from '../components/documentation/universal-search/types';
import type { DocumentationPills } from './global/globalReducerTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { DocumentationTabTypes } from '../types/enums/DocumentationTabTypes';
import {
  ReadOnlyAutomation,
  ReadOnlyDedupMatching,
  ReadOnlyRollup,
} from '@server/read-only-elements.types';
import { defaultSortOptionsMap } from '../components/documentation/selected-object/sortUtilsAndConsts';
import { getObjectsWithPillsPerCategory } from '../components/documentation/cpq/utils';
import { generateFieldId } from '../components/documentation/universal-search/utils';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import clone from 'lodash/clone';
import { UserInputsByConfigurationId, UserInputTypes } from '@server/user-inputs';
import { ConfigurationComment } from '../components/documentation/dependencies/annotations/comments/types';
import { sortSearchResults } from '../components/documentation/universal-search/sortSearchResults';
import {
  ConfigurationConsumers,
  ConfigurationDependencies,
  ConfigurationItem,
  ConfigurationTypesWithIds,
  FieldMetadataRecordProperties,
} from '../components/documentation/dependencies/DependenciesTypes';

export interface DocumentationState {
  objects: ObjectWithPillsMap;
  transientObjects: { [crmOrgId: string]: { [objectName: string]: boolean } };
  parsedConfigurationItems: {
    [crmOrgId: string]: ConfigurationItem[]; //contains dependsOn/usedBy configuration items
  };
  dependsOnIdsForRules: {
    [crmOrgId: string]: { [ruleId: string]: ConfigurationTypesWithIds };
  };
  usedByIdsForFields: {
    //some of the fields don't have ids so it's generated via generateFieldId function
    [crmOrgId: string]: { [fieldIdOrObjectNameWithFieldName: string]: ConfigurationTypesWithIds };
  };
  usedByIdsForRules: {
    [crmOrgId: string]: { [ruleId: string]: ConfigurationTypesWithIds };
  };
  commentsByConfigurationId: {
    [crmOrgId: string]: { [configurationId: string]: ConfigurationComment[] };
  };
  sourceCodeByConfigurationId: {
    [crmOrgId: string]: { [configurationId: string]: string };
  };
  openAIDescriptions: {
    [crmOrgId: string]: { [ruleId: string]: OpenAiDescription };
  };
  dependencies?: SelectedDependency & {
    history: SelectedDependency[];
  };
  dependenciesTags?: UserInputsByConfigurationId; //contains only current env tags
  sweepElementsLatestDeployments: {
    [sweepElementId: string]: ReadOnlyAutomation | ReadOnlyDedupMatching | ReadOnlyRollup;
  };
  layouts: {
    [crmOrgId: string]: LayoutsByObjectName;
  };
  singleObject?: {
    activeTab?: DocumentationTabTypes;
    objectTypeName?: ObjectTypeName;
    searchTxt?: string;
    filtersAndSort: TabFiltersAndSort;
  };
  activeObjectElementsRawData?: {
    [key in DocumentationTabTypes]: ConfigurationItem[];
  };
  universalSearch?: {
    oldSearchText?: string;
    searchText?: string;
    results?: SearchResponse;
    selectedConfigurationType?: ConfigurationType;
    isResultsLoading?: boolean;
    loadingId?: string;
    isUniversalSearchListOpen?: boolean;
    filterKey?: string;
  };
  showLoader: boolean;
}

const initialState: DocumentationState = {
  objects: {
    favorites: [],
    standardObjects: [],
    cpqObjects: [],
    customObjects: [],
  },
  transientObjects: {},
  parsedConfigurationItems: {},
  dependsOnIdsForRules: {},
  usedByIdsForFields: {},
  usedByIdsForRules: {},
  openAIDescriptions: {},
  commentsByConfigurationId: {},
  sourceCodeByConfigurationId: {},
  layouts: {},
  sweepElementsLatestDeployments: {}, //only for sweep elements
  showLoader: false,
};

export const documentationSlice = createSlice({
  name: 'documentation',
  initialState,
  reducers: {
    setObjects: (
      state,
      action: PayloadAction<{
        recordTypesData: RecordTypesData;
        funnelsData: FunnelsData;
        objectTypeNames: ObjectTypeName[];
        pills?: DocumentationPills;
        transientObjects: { [objectName: string]: boolean };
      }>,
    ) => {
      const { funnelsData, recordTypesData, objectTypeNames, pills, transientObjects } =
        action.payload;

      const { cpq, favorites, customObjectsNotInFM, standardObjectsNotInFunnelMap } =
        getObjectsWithPillsPerCategory({
          recordTypesData,
          funnelsData,
          objectTypeNames,
          pills,
          transientObjects,
        });

      state.objects = {
        favorites,
        standardObjects: standardObjectsNotInFunnelMap,
        cpqObjects: cpq,
        customObjects: customObjectsNotInFM,
      };
    },
    setObjectName: (
      state,
      action: PayloadAction<{
        objectTypeName?: ObjectTypeName;
      }>,
    ) => {
      const { objectTypeName } = action.payload;

      state.singleObject = {
        objectTypeName: objectTypeName,
        activeTab: DocumentationTabTypes.CARDS_LIST,
        searchTxt: '',
        filtersAndSort: {},
      };

      state.dependencies = undefined;
    },
    setSingleObjectName: (
      state,
      action: PayloadAction<{
        singleObjectName?: ObjectTypeName;
        tab: DocumentationTabTypes;
      }>,
    ) => {
      const { singleObjectName, tab } = action.payload;

      if (!singleObjectName) {
        state.singleObject = undefined;
        return;
      }

      state.singleObject = {
        objectTypeName: singleObjectName,
        activeTab: tab,
        searchTxt: '',
        filtersAndSort: {
          sortKey: tab ? defaultSortOptionsMap[tab] : '', //if tab is empty, list of element types is displayed
          activeOnly: true,
        },
      };
    },
    clearDocumentationDialog: (state) => ({
      ...initialState,
      objects: state.objects,
      layouts: state.layouts,
    }),
    openPredefinedStepRulesInSingleObjectScreen: (
      state,
      action: PayloadAction<{
        singleObjectApiName: string;
        selectedStepName?: string;
        selectedRecordTypeValue?: string;
        defaultActiveTab: DocumentationTabTypes;
        objects?: ObjectTypeName[];
      }>,
    ) => {
      const {
        singleObjectApiName,
        selectedStepName,
        defaultActiveTab,
        objects,
        selectedRecordTypeValue,
      } = action.payload;

      state.singleObject = {
        objectTypeName: objects?.find((object) => object.objectType === singleObjectApiName),
        searchTxt: '',
        activeTab: defaultActiveTab,
        filtersAndSort: {
          ...state.singleObject?.filtersAndSort,
          selectedStageValue: selectedStepName,
          selectedRecordTypeValue,
          activeOnly: true,
        },
      };

      state.dependencies = undefined;
      state.universalSearch = undefined;
    },
    clearFilters: (state) => {
      if (state.singleObject) {
        state.singleObject.filtersAndSort = {};
      }
    },
    setDependenciesConfigurationItem: (
      state,
      action: PayloadAction<{
        id: string;
        parentType: ConfigurationType | UnclassifiedTypes;
        dependencyType?: string;
        name: string;
        objectName?: string; //objectName is relevant ONLY for fields as they don't have explicit id and field name is unique only within objectName
        clearHistory?: boolean;
        contentType: DependenciesContentViewType;
      }>,
    ) => {
      const { id, parentType, dependencyType, name, objectName, clearHistory, contentType } =
        action.payload;
      const history = clearHistory ? [] : (state.dependencies?.history ?? []);
      state.dependencies = {
        id,
        name,
        parentType,
        dependencyType,
        objectName: objectName ?? '',
        contentType,
        history: [
          ...history,
          { id, parentType, dependencyType, name, objectName: objectName ?? '', contentType },
        ],
      };
    },
    setLatestDeployment: (
      state,
      action: PayloadAction<{
        elementId: string;
        readOnlyElement: ReadOnlyAutomation | ReadOnlyDedupMatching | ReadOnlyRollup;
      }>,
    ) => {
      const { readOnlyElement: readOnlyAutomation, elementId } = action.payload;
      state.sweepElementsLatestDeployments[elementId] = readOnlyAutomation;
    },
    setConfigurationUserInputs: (
      state,
      action: PayloadAction<{
        userInputs: UserInputsByConfigurationId;
      }>,
    ) => {
      const { userInputs } = action.payload;
      const [[configurationId, userInputData]] = Object.entries(userInputs);
      if (!state.dependenciesTags) {
        state.dependenciesTags = {};
      }

      state.dependenciesTags[configurationId] = userInputData;
    },
    deleteTagFromConfigurationUserInputs: (
      state,
      action: PayloadAction<{
        tagId: string;
      }>,
    ) => {
      const { tagId } = action.payload;
      const dependenciesTags = state.dependenciesTags ?? ({} as UserInputsByConfigurationId);

      const filteredDependenciesTags = Object.entries(dependenciesTags).reduce((acc, [key]) => {
        const typedKey = key as keyof UserInputsByConfigurationId;
        const itemsOfKey = dependenciesTags[typedKey];
        const currentTags = itemsOfKey[UserInputTypes.Tags];
        const currentCollaborators = itemsOfKey[UserInputTypes.Collaborators];
        const currentOwner = itemsOfKey[UserInputTypes.Owner];

        acc[typedKey] = {
          [UserInputTypes.Tags]: currentTags.filter((itemId: string) => itemId !== tagId),
          [UserInputTypes.Collaborators]: currentCollaborators.filter(
            (itemId: string) => itemId !== tagId,
          ),
          [UserInputTypes.Owner]: currentOwner === tagId ? '' : currentOwner,
        };
        return acc;
      }, {} as UserInputsByConfigurationId);

      state.dependenciesTags = filteredDependenciesTags;
    },
    setAllConfigurationsUserInputs: (
      state,
      action: PayloadAction<{
        userInputs: UserInputsByConfigurationId;
      }>,
    ) => {
      const { userInputs } = action.payload;
      state.dependenciesTags = userInputs;
    },
    clearDependencies: (state) => {
      state.dependencies = undefined;
      state.sweepElementsLatestDeployments = {};
    },
    goBackInHistory: (state) => {
      const dependenciesHistory = state.dependencies?.history ?? [];

      if (state.dependencies && dependenciesHistory?.length > 1) {
        const lastIdx = state.dependencies.history.length - 1;
        state.dependencies.history.splice(lastIdx, 1);

        const prev = state.dependencies.history[lastIdx - 1];

        if (prev) {
          state.dependencies = {
            id: prev.id,
            name: prev.name,
            parentType: prev.parentType,
            dependencyType: prev.dependencyType,
            objectName: prev.objectName,
            history: state.dependencies.history,
            contentType: prev.contentType,
          };
        } else {
          state.dependencies.history = [];
        }
      }
    },
    setTab: (
      state,
      action: PayloadAction<{
        tab: DocumentationTabTypes;
      }>,
    ) => {
      state.singleObject = {
        ...state.singleObject,
        activeTab: action.payload.tab,
        searchTxt: '',
        filtersAndSort: {
          sortKey: defaultSortOptionsMap[action.payload.tab],
          activeOnly: true,
        },
      };
      state.dependencies = undefined;
    },
    setFiltersAndSort: (state, action: PayloadAction<{ filtersAndSort: TabFiltersAndSort }>) => {
      if (state.singleObject) {
        state.singleObject = {
          ...state.singleObject,
          filtersAndSort: action.payload.filtersAndSort,
        };
      }
    },
    setSingleObjectSearchTxt: (state, action: PayloadAction<{ searchTxt: string }>) => {
      if (state.singleObject) {
        state.singleObject = {
          ...state.singleObject,
          searchTxt: action.payload.searchTxt,
        };
      }
    },
    setUniversalSearchTxt: (state, action: PayloadAction<{ searchTxt: string }>) => {
      state.universalSearch = {
        ...state.universalSearch,
        searchText: action.payload.searchTxt,
      };
      state.dependencies = undefined;
    },
    setUniversalSearchIsResultLoading: (
      state,
      action: PayloadAction<{ isLoading: boolean; id: string }>,
    ) => {
      state.universalSearch = {
        ...state.universalSearch,
        isResultsLoading: action.payload.isLoading,
        loadingId: action.payload.id,
        isUniversalSearchListOpen: true,
      };
    },
    setUniversalSearchListIsOpen: (state, action: PayloadAction<{ isOpen: boolean }>) => {
      const { isOpen } = action.payload;
      state.universalSearch = {
        ...state.universalSearch,
        isUniversalSearchListOpen: isOpen,
      };
    },
    setUniversalSearchResults: (
      state,
      action: PayloadAction<{ results: SearchResponse; loadingId: string; crmOrgId: string }>,
    ) => {
      const { results, loadingId, crmOrgId } = action.payload;

      const currentId = state.universalSearch?.loadingId;
      state.universalSearch = {
        ...state.universalSearch,
        isResultsLoading: !(currentId === loadingId), //to continue displaying loader regardless how many times user change query while waiting for response
        results: sortSearchResults(results, crmOrgId, state.universalSearch?.searchText),
      };
    },
    setUniversalSearchFilter: (
      state,
      action: PayloadAction<{ filterKey: ConfigurationType | undefined }>,
    ) => {
      state.universalSearch = {
        ...state.universalSearch,
        filterKey: action.payload.filterKey,
      };
    },
    clearUniversalSearch: (state) => {
      state.universalSearch = undefined;
    },
    setConfigurationDependenciesForRule: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        ruleId: string;
        dependsOn: ConfigurationDependencies;
      }>,
    ) => {
      const { crmOrgId, ruleId, dependsOn } = action.payload;

      const oldParsedConfigItems = clone(state.parsedConfigurationItems[crmOrgId] ?? []);
      const { parsedConfigurationItemsIdsToType, parsedConfigurationItems, parsedFields } =
        getItemsForDependencies(dependsOn, oldParsedConfigItems);

      state.parsedConfigurationItems[crmOrgId] = [
        ...parsedConfigurationItems,
        ...parsedFields.map((field) => ({ ...field, parentType: ConfigurationType.fields })),
      ];

      const oldDependencyIds = state.dependsOnIdsForRules[crmOrgId];
      state.dependsOnIdsForRules[crmOrgId] = {
        ...oldDependencyIds,
        [ruleId]: parsedConfigurationItemsIdsToType,
      };
    },
    updateOrAddFieldToParsedConfigurationItems: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        field: FieldMetadataRecordProperties;
        objectName: string;
      }>,
    ) => {
      const { crmOrgId, field, objectName } = action.payload;

      let currentParsedConfigurationItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const isField = currentParsedConfigurationItems.find((item) => item.id === field.id);

      if (isField) {
        currentParsedConfigurationItems = currentParsedConfigurationItems.map((item) =>
          item.id === field.id && field.objectName === objectName
            ? { ...field, parentType: ConfigurationType.fields }
            : item,
        );
      } else {
        currentParsedConfigurationItems.push({
          ...field,
          parentType: ConfigurationType.fields,
        });
      }

      state.parsedConfigurationItems[crmOrgId] = currentParsedConfigurationItems;
    },
    addConfigurationItems: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        newConfigurationItems: SearchResponse;
      }>,
    ) => {
      const { crmOrgId, newConfigurationItems } = action.payload;

      const oldConfigurationItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const { parsedConfigurationItems, parsedFields } = getItemsForDependencies(
        newConfigurationItems,
        oldConfigurationItems,
      );

      state.parsedConfigurationItems[crmOrgId] = [
        ...parsedConfigurationItems,
        ...parsedFields.map((field) => ({ ...field, parentType: ConfigurationType.fields })),
      ];
    },
    setConfigurationUsedByForField: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        fieldName: string;
        usedBy: ConfigurationConsumers;
        objectName: string;
        id: string;
      }>,
    ) => {
      const { crmOrgId, usedBy, fieldName, objectName, id } = action.payload;

      const oldParsedConfigItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const { parsedConfigurationItemsIdsToType, parsedConfigurationItems, parsedFields } =
        getItemsForDependencies(usedBy, oldParsedConfigItems);

      state.parsedConfigurationItems[crmOrgId] = [
        ...parsedConfigurationItems,
        ...parsedFields.map((field) => ({ ...field, parentType: ConfigurationType.fields })),
      ];

      const oldUsedByIds = state.usedByIdsForFields[crmOrgId];
      const _id = generateFieldId({ id, name: fieldName, objectName });

      state.usedByIdsForFields[crmOrgId] = {
        ...oldUsedByIds,
        [_id]: parsedConfigurationItemsIdsToType,
      };
    },
    setConfigurationUsedByForRule: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        ruleId: string;
        usedBy: ConfigurationConsumers;
      }>,
    ) => {
      const { crmOrgId, ruleId, usedBy } = action.payload;

      const oldParsedConfigItems = state.parsedConfigurationItems[crmOrgId] ?? [];
      const { parsedConfigurationItemsIdsToType, parsedConfigurationItems, parsedFields } =
        getItemsForDependencies(usedBy, oldParsedConfigItems);

      state.parsedConfigurationItems[crmOrgId] = [
        ...parsedConfigurationItems,
        ...parsedFields.map((field) => ({ ...field, parentType: ConfigurationType.fields })),
      ];

      const oldUsedByIds = state.usedByIdsForRules[crmOrgId];
      state.usedByIdsForRules[crmOrgId] = {
        ...oldUsedByIds,
        [ruleId]: parsedConfigurationItemsIdsToType,
      };
    },
    setOpenAiDescriptionForRule: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        openAiDescription: OpenAiDescription;
        ruleId: string;
      }>,
    ) => {
      const { crmOrgId, openAiDescription, ruleId } = action.payload;

      const oldDescriptions = state.openAIDescriptions[crmOrgId];
      state.openAIDescriptions[crmOrgId] = {
        ...oldDescriptions,
        [ruleId]: openAiDescription,
      };
    },
    addCommentForConfiguration: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        configurationId: string;
        newComment: ConfigurationComment;
      }>,
    ) => {
      const { crmOrgId, configurationId, newComment } = action.payload;

      const oldComments = state.commentsByConfigurationId[crmOrgId];
      const oldConfigurationComments =
        state.commentsByConfigurationId[crmOrgId]?.[configurationId] ?? [];

      state.commentsByConfigurationId[crmOrgId] = {
        ...oldComments,
        [configurationId]: [...oldConfigurationComments, newComment],
      };
    },
    updateCommentForConfiguration: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        configurationId: string;
        newComment: ConfigurationComment;
      }>,
    ) => {
      const { crmOrgId, configurationId, newComment } = action.payload;

      const oldComments = state.commentsByConfigurationId[crmOrgId];
      const oldConfigurationComments =
        state.commentsByConfigurationId[crmOrgId]?.[configurationId] ?? [];

      state.commentsByConfigurationId[crmOrgId] = {
        ...oldComments,
        [configurationId]: oldConfigurationComments.map((comment) =>
          comment.id === newComment.id ? newComment : comment,
        ),
      };
    },
    deleteCommentForConfiguration: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        configurationId: string;
        commentId: string;
      }>,
    ) => {
      const { crmOrgId, configurationId, commentId } = action.payload;

      const oldComments = state.commentsByConfigurationId[crmOrgId];
      const oldConfigurationComments =
        state.commentsByConfigurationId[crmOrgId]?.[configurationId] ?? [];

      state.commentsByConfigurationId[crmOrgId] = {
        ...oldComments,
        [configurationId]: oldConfigurationComments.filter((comment) => comment.id !== commentId),
      };
    },
    setCommentsForConfiguration: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        configurationId: string;
        comments: ConfigurationComment[];
      }>,
    ) => {
      const { crmOrgId, configurationId, comments } = action.payload;

      const oldComments = state.commentsByConfigurationId[crmOrgId];
      state.commentsByConfigurationId[crmOrgId] = {
        ...oldComments,
        [configurationId]: comments,
      };
    },
    setSourceCodeForConfiguration: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        configurationId: string;
        sourceCode: string;
      }>,
    ) => {
      const { crmOrgId, configurationId, sourceCode } = action.payload;

      const oldConfigs = state.sourceCodeByConfigurationId[crmOrgId];
      state.sourceCodeByConfigurationId[crmOrgId] = {
        ...oldConfigs,
        [configurationId]: sourceCode,
      };
    },
    setSelectedDependencyContentType: (
      state,
      action: PayloadAction<{ contentType: DependenciesContentViewType }>,
    ) => {
      const { contentType } = action.payload;
      const { history, ...selectedItem } =
        state.dependencies ?? ({} as SelectedDependency & { history: SelectedDependency[] });

      state.dependencies = {
        ...selectedItem,
        contentType,
        history: [...(history ?? ([] as SelectedDependency[])), { ...selectedItem, contentType }],
      };
    },
    openEditTag: (state, action: PayloadAction<{ tagId: string; tagName: string }>) => {
      const { tagId, tagName } = action.payload;
      const { history } =
        state.dependencies ?? ({} as SelectedDependency & { history: SelectedDependency[] });

      state.dependencies = {
        id: tagId,
        name: tagName,
        parentType: UnclassifiedTypes.tag,
        contentType: DependenciesContentViewType.editTag,
        history: [
          ...(history ?? ([] as any)),
          {
            id: tagId,
            name: tagName,
            parentType: UnclassifiedTypes.tag,
            contentType: DependenciesContentViewType.editTag,
          },
        ],
      };
    },
    setShowLoader: (state, action: PayloadAction<{ showLoader: boolean }>) => {
      state.showLoader = action.payload.showLoader;
    },
    setLayouts: (
      state,
      action: PayloadAction<{
        crmOrgId: string;
        objectName: string;
        isLoading: boolean;
        layouts?: LayoutData[];
      }>,
    ) => {
      const { crmOrgId, objectName, isLoading, layouts } = action.payload;

      if (!state.layouts[crmOrgId]) {
        state.layouts[crmOrgId] = {
          [objectName]: {
            isLoading,
            layouts: layouts ?? [],
          },
        };
        return;
      }

      state.layouts[crmOrgId][objectName] = {
        isLoading,
        layouts: layouts ?? [],
      };
    },
    setTransientObjects: (
      state,
      action: PayloadAction<{
        objectName: string;
        crmOrgId: string;
        isObjectBeingParsed: boolean;
      }>,
    ) => {
      const { crmOrgId, objectName, isObjectBeingParsed } = action.payload;

      if (!state.transientObjects[crmOrgId]) {
        state.transientObjects[crmOrgId] = {};
      }

      state.transientObjects[crmOrgId][objectName] = isObjectBeingParsed;
    },
    setActiveObjectElementsRawData: (
      state,
      action: PayloadAction<{
        list: { [key in DocumentationTabTypes]: ConfigurationItem[] };
      }>,
    ) => {
      const { list } = action.payload;
      state.activeObjectElementsRawData = list;
    },
  },
});

// helper function to extract ids from ConfigurationConsumers | ConfigurationDependencies
const getItemsForDependencies = (
  values: ConfigurationConsumers | ConfigurationDependencies,
  storedParsedDependencies: ConfigurationItem[],
): {
  parsedConfigurationItemsIdsToType: ConfigurationTypesWithIds;
  parsedConfigurationItems: ConfigurationItem[];
  parsedFields: ConfigurationItem[];
} => {
  let helperArray: ConfigurationItem[] = [];
  const dependenciesIds = {} as ConfigurationTypesWithIds;
  let fields: ConfigurationItem[] = [];

  Object.keys(values).forEach((depKey) => {
    const key = depKey as ConfigurationType;
    //any as workaround,
    // TS is complaining for some of the keys not being valid key for ConfigurationConsumers | ConfigurationDependencies,
    //as Consumers and Dependencies don't contain ALL of the ConfigurationItemType keys
    const _values = values as any;
    const arr: ConfigurationItem[] = _values[key];
    const idsOnly: string[] = arr.map((item) => item.id);

    dependenciesIds[key] = idsOnly;

    if (depKey === ConfigurationType.fields) {
      fields = fields.concat(arr);
    } else {
      helperArray = helperArray.concat(arr);
    }
  });

  return {
    parsedConfigurationItemsIdsToType: dependenciesIds,
    parsedConfigurationItems: uniqWith(
      [...clone(storedParsedDependencies), ...helperArray],
      isEqual,
    ),
    parsedFields: fields,
  };
};

export const {
  setSingleObjectName,
  clearDocumentationDialog,
  openPredefinedStepRulesInSingleObjectScreen,
  clearFilters,
  setTab,
  setFiltersAndSort,
  setSingleObjectSearchTxt,
  setActiveObjectElementsRawData,

  setObjectName,
  setObjects,

  setLayouts,
  setLatestDeployment,
  setDependenciesConfigurationItem,
  setConfigurationUserInputs,
  setAllConfigurationsUserInputs,
  deleteTagFromConfigurationUserInputs,
  clearDependencies,
  setSelectedDependencyContentType,
  goBackInHistory,
  openEditTag,

  setUniversalSearchTxt,
  setUniversalSearchIsResultLoading,
  setUniversalSearchResults,
  clearUniversalSearch,
  setUniversalSearchFilter,
  setUniversalSearchListIsOpen,

  setCommentsForConfiguration,
  addCommentForConfiguration,
  updateCommentForConfiguration,
  deleteCommentForConfiguration,
  setSourceCodeForConfiguration,
  setConfigurationDependenciesForRule,
  updateOrAddFieldToParsedConfigurationItems,
  addConfigurationItems,
  setConfigurationUsedByForField,
  setConfigurationUsedByForRule,
  setOpenAiDescriptionForRule,
  setShowLoader,

  setTransientObjects,
} = documentationSlice.actions;

export const selectTransientObjects = (crmOrgId: string) => (state: RootState) =>
  state.documentation.transientObjects[crmOrgId];

export const selectLoader = (crmOrgId: string) => (state: RootState) =>
  state.documentation.showLoader && !state.global.environments[crmOrgId]?.data?.documentation;

export const selectSingleObject = (state: RootState) =>
  state.documentation.singleObject?.objectTypeName;

export const selectSingleObjectActiveTab = (state: RootState) =>
  state.documentation.singleObject?.activeTab;

export const selectSingleObjectFilters = (state: RootState) =>
  state.documentation.singleObject?.filtersAndSort;

export const selectSingleObjectTabSortKey = (state: RootState) =>
  state.documentation.singleObject?.filtersAndSort?.sortKey;

export const selectSingleObjectSearchTxt = (state: RootState) =>
  state.documentation.singleObject?.searchTxt;

export const selectDependenciesConfigurationItem = (state: RootState) =>
  state.documentation.dependencies;

export const selectLatestDeployment = (elementId: string) => (state: RootState) =>
  state.documentation.sweepElementsLatestDeployments[elementId];

export const selectDependenciesHistory = (state: RootState) =>
  state.documentation.dependencies?.history?.length ?? 0;

export const selectUniversalSearch = (state: RootState) => state.documentation.universalSearch;
export const selectObjects = (state: RootState) => state.documentation.objects;

export const selectOpenAiDescriptionForRule =
  (ruleId: string, crmOrgId: string) => (state: RootState) =>
    state.documentation.openAIDescriptions[crmOrgId]?.[ruleId];

export const selectParsedConfigurationItems = (crmOrgId: string) => (state: RootState) =>
  state.documentation.parsedConfigurationItems?.[crmOrgId];

export const selectDependencyByType =
  (type: 'dependsOnIdsForRules' | 'usedByIdsForRules' | 'usedByIdsForFields') =>
  (state: RootState) =>
    state.documentation[type];

export const selectLayoutsByObjectName = (crmOrgId: string) => (state: RootState) =>
  state.documentation.layouts[crmOrgId];

export const selectDependencyCustomNotesById = (dependencyId: string) => (state: RootState) =>
  state.documentation.dependenciesTags?.[dependencyId];

export const selectConfigurationComments =
  (crmOrgId: string, configurationId: string) => (state: RootState) =>
    state.documentation.commentsByConfigurationId?.[crmOrgId]?.[configurationId];

export const selectConfigurationSourceCode =
  (crmOrgId: string, configurationId: string) => (state: RootState) =>
    state.documentation.sourceCodeByConfigurationId?.[crmOrgId]?.[configurationId];

export const selectDocumentationActiveObjectData = (state: RootState) =>
  state.documentation.activeObjectElementsRawData;

export default documentationSlice.reducer;
