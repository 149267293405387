import { ArrowLeft } from '@sweep-io/sweep-design/dist/icons';
import { Box } from '@mui/material';
import { Button, IconButton, Typography } from '@sweep-io/sweep-design';
import { ConfigurationCanvasPanelHeader } from '../../../configuration-canvas-panel';

interface TemplatesPageHeaderProps {
  onBackClick: () => void;
  title: string;
  imageIcon?: React.ReactNode;
  onButtonClick: () => void;
  buttonText: string;
}
export const TemplatesPageHeader = ({
  onBackClick,
  buttonText,
  title,
  onButtonClick,
  imageIcon,
}: TemplatesPageHeaderProps) => {
  return (
    <ConfigurationCanvasPanelHeader>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
        }}
      >
        <IconButton variant="flat" onClick={onBackClick}>
          <ArrowLeft />
        </IconButton>
        {imageIcon}
        <Typography variant="h4">{title}</Typography>
      </Box>
      <Box>
        <Button variant="filled" onClick={onButtonClick}>
          {buttonText}
        </Button>
      </Box>
    </ConfigurationCanvasPanelHeader>
  );
};
