import { useCallback, useContext, useState } from 'react';
import { DocumentationTabTypes } from '../../../types/enums/DocumentationTabTypes';
import { useDispatch, useSelector } from 'react-redux';
import { clearDependencies } from '../../../reducers/documentationReducer';
import useParser from '../../parser/useParser';
import { selectDefaultCreationCrmOrgId } from '../../../reducers/userInfoReducer';
import { DocumentationDialogContext } from '../context/DocumentationContextProvider';
import { DispatchSingleObjectNameAndFocusOnElementFuncProps } from '../types';

export const useObjectListInteractions = ({
  dispatchSingleObjectNameAndFocusOnElement,
}: {
  dispatchSingleObjectNameAndFocusOnElement: DispatchSingleObjectNameAndFocusOnElementFuncProps;
}) => {
  const dispatch = useDispatch();
  const crmOrgId = useSelector(selectDefaultCreationCrmOrgId);

  const { parseObjectOnDemand } = useParser({ crmOrgId: crmOrgId ?? '' });
  const { objectNamesUsedInCanvas, setHoveredPin } = useContext(DocumentationDialogContext);

  const [isLoading, setIsLoading] = useState(false);

  const onObjectNotInFunnelMapClick = useCallback(
    async (objectTypeName: ObjectTypeName) => {
      // If no record types are converted for given object, no configuration items (validationRules, apex etc..) are being stored on BE,
      // In order to get them, additional parse call is required and the response of this call is NOT being stored as well
      await parseObjectOnDemand(objectTypeName.objectType);
      dispatchSingleObjectNameAndFocusOnElement({ singleObjectName: objectTypeName });
    },
    [parseObjectOnDemand, dispatchSingleObjectNameAndFocusOnElement],
  );

  const onObjectInFunnelMapClick = useCallback(
    (objectTypeName: ObjectTypeName, tab?: DocumentationTabTypes) => {
      dispatchSingleObjectNameAndFocusOnElement({ singleObjectName: objectTypeName, tab });
    },
    [dispatchSingleObjectNameAndFocusOnElement],
  );

  const _onAllObjectsCardClick = useCallback(
    async (objectTypeName: ObjectTypeName) => {
      setIsLoading(true);
      await onObjectNotInFunnelMapClick(objectTypeName);
      setIsLoading(false);
      setHoveredPin && setHoveredPin(undefined);
    },
    [onObjectNotInFunnelMapClick, setHoveredPin],
  );

  const onCardClick = useCallback(
    async (objectTypeName: ObjectTypeName, tab?: DocumentationTabTypes) => {
      const isUsedInFunnelMap = objectNamesUsedInCanvas?.includes(objectTypeName.objectType);

      if (isUsedInFunnelMap) {
        await onObjectInFunnelMapClick(objectTypeName, tab);
        setHoveredPin && setHoveredPin(undefined);
      } else {
        await _onAllObjectsCardClick(objectTypeName);
      }
    },
    [objectNamesUsedInCanvas, _onAllObjectsCardClick, setHoveredPin, onObjectInFunnelMapClick],
  );

  const backToObjectList = useCallback(() => {
    dispatchSingleObjectNameAndFocusOnElement({});
    dispatch(clearDependencies());
  }, [dispatch, dispatchSingleObjectNameAndFocusOnElement]);

  const backToElementsList = (event: any, el: { value: string; label: string }) => {
    dispatchSingleObjectNameAndFocusOnElement({
      singleObjectName: { label: el.label, objectType: el.value },
      tab: undefined,
    });
  };

  return {
    onCardClick,
    backToObjectList,
    backToElementsList,
    onObjectNotInFunnelMapClick,
    onObjectInFunnelMapClick,
    dispatchSingleObjectNameAndFocusOnElement,
    isLoading,
  };
};
