import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import {
  selectCrmOrg,
  selectDefaultCreationEnvironment,
  selectProductionCrmOrg,
} from '../components/pages/environments/environmentsReducer';
import keyBy from 'lodash/keyBy';
import isEqual from 'lodash/isEqual';
import { DevOpsCenterDeploymentStatus, DevOpsElementType } from '@server/devops-center';

export type DiffElementId = {
  rollupId?: string;
  fieldId?: string;
  automationId?: string;
  crmOrgId: string;
};

export type DevOpsCenterElement = {
  automationId?: string;
  rollupId?: string;
  fieldId?: string;
  elementId: string;
  versionId: string;
  name: string;
  objectName: string;
  objectLabel: string;
  targetDeploymentStatus: DevOpsCenterDeploymentStatus;
  elementType: DevOpsElementType;
};

interface DeploymentSummaryElement {
  rollupId?: string;
  automationId?: string;
  fieldId?: string;
  success: boolean;
  message?: string;
}

export interface DevOpsCenterState {
  originEnvId?: CrmOrg['id'];
  targetEnvId?: CrmOrg['id'];
  elements: DevOpsCenterElement[];
  selectedElementIds: string[];
  isLoading: boolean;
  isDeploying: boolean;
  groupValue: string;
  diff: {
    open: boolean;
    originElementIds?: DiffElementId[];
    targetElementIds?: DiffElementId[];
  };
  deploymentSummaryElements: DeploymentSummaryElement[];
  filters: {
    searchValue: string;
    filterValue: number;
  };
}

const initialState: DevOpsCenterState = {
  elements: [],
  selectedElementIds: [],
  isLoading: false,
  isDeploying: false,
  groupValue: 'element',
  diff: {
    open: false,
  },
  deploymentSummaryElements: [],
  filters: {
    filterValue: -1,
    searchValue: '',
  },
};

export const devOpsCenterSlice = createSlice({
  name: 'devOpsCenter',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setDeploying: (state, action) => {
      state.isDeploying = action.payload;
    },
    setTargetEnv: (state, action: PayloadAction<{ crmOrgId: string }>) => {
      state.targetEnvId = action.payload.crmOrgId;
    },
    setElements: (state, action: PayloadAction<DevOpsCenterElement[]>) => {
      state.elements = addElementId(action.payload);
      state.selectedElementIds = [];
    },
    updateElements: (
      state,
      action: PayloadAction<{ elementType: DevOpsElementType; elements: DevOpsCenterElement[] }>,
    ) => {
      state.elements = state.elements.filter((el) => el.elementType !== action.payload.elementType);
      const addedElements = addElementId(action.payload.elements);
      state.elements.push(...addedElements);
    },
    setSelectedElementIds: (state, action: PayloadAction<string[]>) => {
      state.selectedElementIds = action.payload;
    },
    applyFilters: (
      state,
      action: PayloadAction<{ searchValue?: string; filterValue?: string }>,
    ) => {
      const { searchValue, filterValue } = action.payload;
      if (searchValue !== undefined) {
        state.filters.searchValue = searchValue.toLowerCase();
      }
      if (filterValue !== undefined) {
        state.filters.filterValue = parseInt(filterValue, 10);
      }
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
    },
    setGroupBy: (state, action: PayloadAction<string>) => {
      if (action.payload && action.payload !== state.groupValue) {
        state.groupValue = action.payload;
      }
    },
    openDiff: (
      state,
      action: PayloadAction<{ originIds: DiffElementId[]; targetIds: DiffElementId[] }>,
    ) => {
      if (!action.payload.originIds.length && action.payload.targetIds.length) {
        return;
      }
      state.diff.originElementIds = action.payload.originIds;
      state.diff.targetElementIds = action.payload.targetIds;
      state.diff.open = true;
    },
    closeDiff: (state) => {
      state.diff.open = false;
      state.diff.originElementIds = [];
      state.diff.targetElementIds = [];
    },
    showDeploymentReport: (
      state,
      action: PayloadAction<
        {
          success: boolean;
          error_message?: string;
          data: { rollupId?: string; automationId?: string; fieldId?: string };
        }[]
      >,
    ) => {
      state.deploymentSummaryElements = action.payload.map((el) => ({
        success: el.success,
        message: el.error_message,
        automationId: el.data?.automationId,
        rollupId: el.data?.rollupId,
        fieldId: el.data?.fieldId,
      }));
    },
    closeDeploymentReport: (state) => {
      state.deploymentSummaryElements = [];
    },
  },
});

export const {
  setGroupBy,
  setLoading,
  setDeploying,
  setTargetEnv,
  setElements,
  resetFilters,
  applyFilters,
  setSelectedElementIds,
  openDiff,
  closeDiff,
  showDeploymentReport,
  closeDeploymentReport,
  updateElements,
} = devOpsCenterSlice.actions;

export const selectDevOpsCenterOriginEnv = (state: RootState) => {
  return state.devOpsCenter.originEnvId
    ? selectCrmOrg(state.devOpsCenter.originEnvId)(state)
    : selectDefaultCreationEnvironment(state);
};

export const selectDevOpsCenterTargetEnv = (state: RootState) => {
  return state.devOpsCenter.targetEnvId
    ? selectCrmOrg(state.devOpsCenter.targetEnvId)(state)
    : selectProductionCrmOrg(state);
};

export const selectDevOpsCenterElements = (state: RootState) => {
  return state.devOpsCenter.elements;
};

export const getDevOpsCenterSelectedElements = (state: RootState) => {
  const elementsById = keyBy(state.devOpsCenter.elements, 'elementId');
  return state.devOpsCenter.selectedElementIds.map((id) => elementsById[id]);
};

export const isDevOpsLoading = (state: RootState) => {
  return state.devOpsCenter.isLoading;
};

export const isDevOpsFiltering = (state: RootState) =>
  !isEqual(state.devOpsCenter.filters, initialState.filters);

export const isDevOpsDeploying = (state: RootState) => {
  return state.devOpsCenter.isDeploying;
};

export const selectDevOpsCenterFilters = (state: RootState) => state.devOpsCenter.filters;

export const selectDevOpsCenterGroupValue = (state: RootState) => {
  return state.devOpsCenter.groupValue;
};

export const selectDiffDialogOpen = (state: RootState) => {
  return state.devOpsCenter.diff.open;
};

export const selectDiffOriginElementIds = (state: RootState) =>
  state.devOpsCenter.diff.originElementIds;
export const selectDiffTargetElementIds = (state: RootState) =>
  state.devOpsCenter.diff.targetElementIds;

export const selectDeploymentSummaryElements = (state: RootState) => {
  const { id } = selectDevOpsCenterOriginEnv(state) as CrmOrg;
  const automationsById = keyBy(state.global.environments[id]?.data?.automations, 'automationId');
  const rollupsById = keyBy(state.global.environments[id]?.data?.rollups, 'rollupId');
  const fieldsById = state.global.environments[id]?.data?.sweepFields;
  return state.devOpsCenter.deploymentSummaryElements.map((el) => ({
    id: el.rollupId ?? el.automationId ?? el.fieldId ?? '',
    success: el.success,
    message: el.message,
    rollup: el.rollupId ? rollupsById[el.rollupId] : undefined,
    automation: el.automationId ? automationsById[el.automationId] : undefined,
    //in case "global" don't include this field's data - at least show the field id in the report
    field:
      el.fieldId && fieldsById
        ? (fieldsById[el.fieldId] ?? { sweepFieldName: el.fieldId })
        : undefined,
  }));
};

const addElementId = (elements: DevOpsCenterElement[]) =>
  //Element is expected to have one of the 3 (automationId or rollupId or fieldId)
  elements.map((el) => ({
    ...el,
    elementId: el.automationId ? el.automationId : el.rollupId ? el.rollupId : (el.fieldId ?? ''),
  }));

export default devOpsCenterSlice.reducer;
