import { ConfirmDialog } from '../../../../common/dialogs/ConfirmDialog';
import { useSweepFields } from '../../../../../sweep-fields/useCachedSweepFields';
import { EditMode, FunnelField } from '../../../../../constants/fieldsManagementConsts';
import SweepTextField from '../../../../common/SweepTextField';
import AttributePicklist from './AttributePicklist';
import { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { SweepNotificationVariant } from '../../../../../reducers/notificationsReducer';
import { useSweepNotifications } from '../../../../notifications/useSweepNotifications';
import { colors } from '@sweep-io/sweep-design';
import { isLeadingFieldPicklistOrderChanged } from './helpers';
import { useSelector } from 'react-redux';
import { selectCrmOrg } from '../../../environments/environmentsReducer';

const ManagePicklistDialog = ({
  isOpened,
  closeDialog,
  onUpdateFieldCb,
  fieldToManage,
  crmOrgId,
  isLeadingField,
}: {
  isOpened: boolean;
  closeDialog: () => void;
  onUpdateFieldCb: (field: SweepField) => void;
  fieldToManage: SweepField;
  crmOrgId: string;
  isLeadingField?: boolean;
}) => {
  const { editField } = useSweepFields();
  const { addNotification } = useSweepNotifications();
  const [updatedField, setUpdatedField] = useState<FunnelField | SweepField>();
  const crmOrg = useSelector(selectCrmOrg(crmOrgId));

  //initialization
  useEffect(() => {
    if (fieldToManage) {
      setUpdatedField(fieldToManage);
    }
  }, [fieldToManage]);

  const setFieldValue = useCallback(
    (path: string, value: any) =>
      setUpdatedField((prev: any) => ({
        ...prev,
        properties: { ...prev.properties, valueSet: value },
      })),
    [],
  );

  const handleConfirm = useCallback(async () => {
    if (updatedField && !isEqual(fieldToManage, updatedField)) {
      const disableDeduce =
        isLeadingField && isLeadingFieldPicklistOrderChanged(fieldToManage, updatedField);
      const enableDeduceValuesOrder = !disableDeduce;
      try {
        const res = await editField({ field: updatedField, crmOrgId, enableDeduceValuesOrder });
        onUpdateFieldCb(res);
      } catch (errorMsg: any) {
        addNotification({
          message: errorMsg?.toString() ?? 'Unknown error',
          variant: SweepNotificationVariant.Error,
        });
      }
    }
  }, [
    editField,
    updatedField,
    fieldToManage,
    addNotification,
    crmOrgId,
    onUpdateFieldCb,
    isLeadingField,
  ]);

  return (
    <ConfirmDialog
      isOpen={isOpened}
      title="Manage picklist values"
      confirmText={`Deploy to ${crmOrg?.name}`}
      onCancel={closeDialog}
      handleClose={closeDialog}
      onConfirm={handleConfirm}
      dialogActionsSx={{ boxShadow: 1 }}
      dialogContentSx={{
        overflowX: 'hidden',
        display: 'flex',
        paddingBottom: 0,
        flexDirection: 'column',
      }}
      dialogSx={{ zIndex: 1501 }} // to make this dialog be in front of the "fields management" dialog
      PaperProps={{
        sx: {
          maxHeight: '80vh',
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="32px"
        flex={1}
        sx={{
          width: '600px',
          backgroundColor: colors.grey[100],
          padding: '24px',
          overflow: 'hidden',
          borderRadius: 2,
        }}
      >
        <Box>
          <SweepTextField
            label="Field Type"
            value={fieldToManage?.fieldType}
            TextFieldProps={{
              disabled: true,
            }}
            FormControlProps={{ fullWidth: true }}
          />
        </Box>
        {updatedField && (
          <AttributePicklist
            setFieldValue={setFieldValue}
            values={updatedField}
            errors={{}}
            isLeadingField={isLeadingField}
            mode={EditMode.CREATE}
          />
        )}
      </Box>
    </ConfirmDialog>
  );
};

export default ManagePicklistDialog;
