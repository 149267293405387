import { DevOpsCenterDeploymentStatusCode } from '@server/devops-center';
import { colors } from '@sweep-io/sweep-design';

export const DeploymentStatusIcon = ({
  status = DevOpsCenterDeploymentStatusCode.NotDeployed,
}: {
  status?: DevOpsCenterDeploymentStatusCode;
}) => {
  const statusColorMap = new Map([
    [DevOpsCenterDeploymentStatusCode.NotDeployed, colors.grey[500]],
    [DevOpsCenterDeploymentStatusCode.VersionMismatch, colors.sun[500]],
    [DevOpsCenterDeploymentStatusCode.PartiallySynced, colors.sun[500]],
    [DevOpsCenterDeploymentStatusCode.Synced, colors.mint[700]],
    [DevOpsCenterDeploymentStatusCode.FullySynced, colors.mint[700]],
    [DevOpsCenterDeploymentStatusCode.Conflict, colors.blush[500]],
  ]);
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.242432" width="16" height="16" rx="8" fill={statusColorMap.get(status)} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.61979 4.1379C6.80016 3.96813 6.80877 3.68429 6.639 3.50391C6.46923 3.32353 6.18538 3.31493 6.00501 3.4847L4.1372 5.24264C4.04606 5.32473 3.98877 5.44366 3.98877 5.57598C3.98877 5.70747 4.04536 5.82574 4.1355 5.90778L6.00501 7.66731C6.18538 7.83708 6.46923 7.82848 6.639 7.6481C6.80877 7.46772 6.80016 7.18388 6.61979 7.01411L5.56831 6.02448L12.1138 6.02448V7.98103C12.1138 8.22874 12.3146 8.42954 12.5623 8.42954C12.81 8.42954 13.0108 8.22874 13.0108 7.98103V5.57598C13.0108 5.32827 12.81 5.12747 12.5623 5.12747L5.56837 5.12747L6.61979 4.1379ZM10.3608 13.0446C10.191 12.8642 10.1996 12.5803 10.38 12.4106L11.4316 11.4209L4.43749 11.4209C4.18979 11.4209 3.98899 11.2201 3.98899 10.9723L3.98899 8.56403C3.98899 8.31633 4.18979 8.11552 4.43749 8.11552C4.6852 8.11552 4.886 8.31633 4.886 8.56403L4.886 10.5238L11.4313 10.5238L10.38 9.53436C10.1996 9.36459 10.191 9.08075 10.3608 8.90037C10.5305 8.71999 10.8144 8.71139 10.9948 8.88116L12.8607 10.6373C12.9529 10.7195 13.011 10.8391 13.011 10.9723C13.011 11.1018 12.9562 11.2184 12.8685 11.3002L10.9948 13.0638C10.8144 13.2335 10.5305 13.2249 10.3608 13.0446Z"
        fill="white"
      />
    </svg>
  );
};
