import * as React from "react";
const SvgBudget = (props) => /* @__PURE__ */ React.createElement("svg", { width: 24, height: 24, viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("rect", { width: 24, height: 24, rx: 4, fill: "#FEDFF9", style: {
  fill: "#FEDFF9",
  fill: "color(display-p3 0.9961 0.8745 0.9765)",
  fillOpacity: 1
} }), /* @__PURE__ */ React.createElement("path", { d: "M7.51196 5.51136C7.10833 5.51136 6.78014 5.83856 6.77893 6.24219L6.74434 17.7576C6.74313 18.1613 7.06935 18.4885 7.47298 18.4885H12.9016C13.7212 18.4885 14.4732 18.3268 15.1348 17.9769C15.8021 17.6299 16.3266 17.1322 16.6863 16.488C17.0515 15.8534 17.2287 15.1464 17.231 14.3852C17.2333 13.6099 17.0364 12.9034 16.61 12.3023C16.4213 12.0313 16.2039 11.792 15.9591 11.585C16.1215 11.4052 16.2671 11.2087 16.3954 10.9962C16.7417 10.4301 16.9157 9.80531 16.9177 9.1375C16.9209 8.06261 16.5293 7.14931 15.728 6.4728C14.9402 5.80776 13.9253 5.51136 12.7596 5.51136H7.51196ZM13.1914 14.6699L13.1805 14.6801C13.0926 14.7642 12.9239 14.8553 12.5671 14.8553H10.5836L10.5877 13.471H12.5712C12.9443 13.471 13.1155 13.5649 13.2001 13.6462L13.2119 13.6573C13.2866 13.7259 13.3691 13.8534 13.3682 14.1549C13.3673 14.446 13.2848 14.5843 13.1914 14.6699ZM12.9435 9.27541L12.9559 9.28531C13.0007 9.32046 13.0536 9.37554 13.053 9.56521C13.0524 9.756 13.0013 9.83129 12.9508 9.87731C12.8805 9.93119 12.7264 10.0024 12.4007 10.0024H10.5982L10.6007 9.1445H12.4033C12.7246 9.1445 12.8743 9.21859 12.9435 9.27541Z", fill: "#F371E6", stroke: "white", style: {
  fill: "#F371E6",
  fill: "color(display-p3 0.9542 0.4413 0.9029)",
  fillOpacity: 1,
  stroke: "white",
  stroke: "white",
  strokeOpacity: 1
}, strokeWidth: 1.46167, strokeLinejoin: "round" }));
export default SvgBudget;
