import { Box, Typography, Collapse, CircularProgress, colors } from '@mui/material';
import { useState } from 'react';
import { SectionBox } from './helpers';
import { DependenciesTableMap, DependenciesTable } from './DependenciesTable';
import { ExpandButtonSimple } from '../../common/ExpandButtonSimple';
import { useRunOnce } from '../../common/useRunOnce';
import merge from 'lodash/merge';
import { ConfigurationType, NewDependencyProps } from './types';
import { NoResults } from '../shared/NoResults';
import { ActiveInactiveSelect } from '../activity-state/ActiveInactiveSelect';
import { ActiveState } from '../activity-state/helpers';
import { FetchDependencies } from './DependenciesTypes';

interface DependenciesSectionProps {
  id: string;
  objectName: string;
  name: string;
  type?: ConfigurationType;
  configurationItems?: DependenciesTableMap;
  sweepConfigurationItems?: DependenciesTableMap;
  title: string;
  fetchDependenciesApi?: FetchDependencies;
  fetchConfigurationsApi?: (tagId: string, crmOrgId: string) => void;
  isSupportedType: boolean;
  onChooseNewRule: (props: NewDependencyProps) => void;
  onToggleActiveInactive?: (value: ActiveState) => void;
  activeState?: ActiveState;
  crmOrgId: string;
}

export const DependenciesSection = ({
  id,
  configurationItems,
  sweepConfigurationItems,
  type,
  objectName,
  name,
  title,
  fetchDependenciesApi,
  fetchConfigurationsApi,
  isSupportedType,
  onChooseNewRule,
  onToggleActiveInactive,
  activeState,
  crmOrgId,
}: DependenciesSectionProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const isConfigurationItemEmpty =
    !isLoading &&
    ((configurationItems && Object.values(configurationItems).every((item) => item.length === 0)) ||
      !configurationItems);

  useRunOnce(async () => {
    if (isConfigurationItemEmpty && isSupportedType && fetchDependenciesApi && type) {
      setIsLoading(true);
      setIsError(false);
      try {
        await fetchDependenciesApi({
          ruleId: id,
          ruleType: type,
          crmOrgId,
          configurationName: name,
          objectName,
        });

        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
      }
    }
  });

  useRunOnce(async () => {
    if (isConfigurationItemEmpty && fetchConfigurationsApi) {
      setIsLoading(true);
      setIsError(false);
      try {
        await fetchConfigurationsApi(id, crmOrgId);
        setIsLoading(false);
      } catch (e) {
        setIsError(true);
        setIsLoading(false);
      }
    }
  });

  if (!isSupportedType) return null;

  const items = merge(sweepConfigurationItems, configurationItems);
  const isEmpty = (items && Object.values(items).every((item) => item.length === 0)) || !items;
  return (
    <SectionBox>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{ cursor: 'pointer' }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" pr={1}>
          <Typography variant="h4">{title}</Typography>
          {onToggleActiveInactive && activeState && (
            <Box onClick={(event) => event.stopPropagation()}>
              <ActiveInactiveSelect
                withIcon
                selectedItem={activeState}
                onSelect={onToggleActiveInactive}
              />
            </Box>
          )}
        </Box>
        <ExpandButtonSimple buttonSize="tiny" isExpanded={isExpanded} />
      </Box>
      <Collapse in={isExpanded}>
        <Box pt={'12px'} display="inline-flex" flexDirection="column">
          {isLoading && <CircularProgress size={20} />}
          {!isLoading && isError && (
            <Typography variant="caption" color={colors.grey[700]}>
              Something went wrong, try again later
            </Typography>
          )}
          {isEmpty && !isLoading && <NoResults />}
          {!isLoading && !isEmpty && items && (
            <DependenciesTable
              crmOrgId={crmOrgId}
              onChooseNewRule={onChooseNewRule}
              configurationItems={items}
            />
          )}
        </Box>
      </Collapse>
    </SectionBox>
  );
};
