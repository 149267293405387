import { SelectorValueTypes } from '../../../../types/enums/SelectorValueTypes';
import { SweepFieldsValueSelector } from '../../../SweepFieldsValueSelector/SweepFieldsValueSelector';
import { AutomationActionRowProps } from '../types';

export const LiteralSelect = ({
  readonly,
  onChange,
  _field,
  crmOrgId,
}: Pick<AutomationActionRowProps, 'readonly' | 'onChange' | 'crmOrgId'> & {
  _field: AutomationActionField;
}) => {
  return (
    <SweepFieldsValueSelector
      isPicklistMultiple={_field.fieldType === 'Picklist' ? false : undefined}
      placeholder="Value"
      shouldBeDisabled={!_field._fieldIds.length || readonly}
      parentFieldType={_field.fieldType ?? ''}
      initialValue={_field.value}
      onChange={(value) => {
        onChange({
          ..._field,
          value: value,
          valueType: SelectorValueTypes.LITERAL,
        });
      }}
      field={_field._fieldInfo}
      fieldIds={_field._fieldIds}
      crmOrgId={crmOrgId}
      valueType={_field.valueType || SelectorValueTypes.LITERAL}
    />
  );
};
