import _cloneDeep from 'lodash/cloneDeep';
import cloneDeep from 'lodash/cloneDeep';
import _debounce from 'lodash/debounce';
import _omit from 'lodash/omit';
import { useDispatch, useSelector } from 'react-redux';
import { AssignmentGroupModel } from '../../../models/AssignmentGroupModel';
import {
  addAssignmentGroup,
  clearAllAssignmentGroups,
  deleteAssignmentGroup,
  loadAssignmentGroups,
  removeAssignmentGroupMemberAtIdx,
  selectAssignmentGroupsList,
  setAssignmentGroupData,
  setAssignmentGroupMemberActiveStatusAtIdx,
  setAssignmentGroupMemberLimitAtIdx,
  setAssignmentGroupMemberWeightAtIdx,
  setLatestAssignmentGroupInServer,
} from '../../../reducers/assignmentGroupsPageReducer';
import { useAssignmentGroupsApiFacade } from '../../../apis/facades/useAssignmentGroupsApiFacade';
import { useCallback } from 'react';
import { AssignmentGroup } from '../../../reducers/assignmentGroupTypes';
import { useGetFirstAvailableEmoji } from './groups/useGetFirstAvailableEmoji';
import { ACTIONS_EVENTS } from '../../../services/events';
import useSendBiEvent from '../../../hooks/useSendBiEvent';

export const useAssignmentsApiWithReducer = () => {
  const dispatch = useDispatch();
  const {
    get_assignmentGroup,
    put_assignmentGroup,
    get_assignmentGroups,
    post_assignmentGroup,
    delete_assignmentGroup,
  } = useAssignmentGroupsApiFacade();
  const sendBiEvent = useSendBiEvent();

  const getFirstAvailableEmoji = useGetFirstAvailableEmoji();
  const assignmentGroupsList = useSelector(selectAssignmentGroupsList);

  const getAssignmentGroupById = useCallback(
    (groupId: string) => assignmentGroupsList?.find((group) => group.id === groupId),
    [assignmentGroupsList],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPutAssignmentGroup = useCallback(
    _debounce(
      async (groupId: string, assignmentGroup: AssignmentGroup) => {
        const _assignmentGroup = await put_assignmentGroup(groupId, assignmentGroup);
        dispatch(setLatestAssignmentGroupInServer(_assignmentGroup));
      },
      250,
      { maxWait: 2000 },
    ),
    [],
  );

  const getAssignmentGroupData = useCallback(
    async (groupId: string) => {
      const { group: assignmentGroup } = await get_assignmentGroup(groupId);
      dispatch(setAssignmentGroupData({ groupId, assignmentGroup }));
      dispatch(setLatestAssignmentGroupInServer(assignmentGroup));
    },
    [dispatch, get_assignmentGroup],
  );

  const optimisticSaveWithDebounceAssignmentGroupData = async (
    assignmentGroup: AssignmentGroup,
  ) => {
    const groupId = assignmentGroup.id;
    dispatch(setAssignmentGroupData({ groupId, assignmentGroup }));
    await debouncedPutAssignmentGroup(groupId, assignmentGroup);
  };

  const saveAssignmentGroupData = useCallback(
    async (assignmentGroup: AssignmentGroup, resetAllMembersLimit?: number) => {
      // Merge exiting members weight and limit
      const groupId = assignmentGroup.id;
      const currentAssignmentGroup = getAssignmentGroupById(groupId);
      const newAssignmentGroup = _cloneDeep(assignmentGroup);
      newAssignmentGroup.members.forEach((member) => {
        const existingMember = currentAssignmentGroup?.members.find(
          (existingMember) => existingMember.userId === member.userId,
        );
        if (existingMember) {
          member.weight = existingMember.weight;
          member.limitValue = resetAllMembersLimit ?? existingMember.limitValue;
          member.membershipActive = existingMember.membershipActive;
        }
      });

      const _assignmentGroup = await put_assignmentGroup(groupId, newAssignmentGroup);

      dispatch(setAssignmentGroupData({ groupId, assignmentGroup: _assignmentGroup }));
      dispatch(setLatestAssignmentGroupInServer(_assignmentGroup));
    },
    [dispatch, getAssignmentGroupById, put_assignmentGroup],
  );

  const deleteAssignmentGroupMemberAtIdx = async (groupData: AssignmentGroup, idx: number) => {
    const groupId = groupData.id;
    const newGroupData = new AssignmentGroupModel(_cloneDeep(groupData)).removeMemberAtIdx(
      idx,
    ).assignmentGroup;
    dispatch(
      removeAssignmentGroupMemberAtIdx({
        groupId,
        index: idx,
      }),
    );
    await debouncedPutAssignmentGroup(groupId, newGroupData);
  };

  const patchAssignmentGroupMemberActiveStatusAtIdx = async (
    groupData: AssignmentGroup,
    idx: number,
    active: boolean,
  ) => {
    const groupId = groupData.id;
    const newGroupData = new AssignmentGroupModel(_cloneDeep(groupData)).setMemberActiveStatusAtIdx(
      idx,
      active,
    ).assignmentGroup;

    dispatch(setAssignmentGroupMemberActiveStatusAtIdx({ groupId, index: idx, active }));
    await debouncedPutAssignmentGroup(groupId, newGroupData);
  };

  const patchAssignmentGroupMemberLimitAtIdx = async (
    groupData: AssignmentGroup,
    idx: number,
    limit: number | null,
  ) => {
    const groupId = groupData.id;
    const newGroupData = new AssignmentGroupModel(_cloneDeep(groupData)).setMemberLimitAtIdx(
      idx,
      limit,
    ).assignmentGroup;

    dispatch(setAssignmentGroupMemberLimitAtIdx({ groupId, index: idx, limit }));
    await debouncedPutAssignmentGroup(groupId, newGroupData);
  };

  const patchAssignmentGroupMemberWeightAtIdx = async (
    groupData: AssignmentGroup,
    idx: number,
    weight: number,
  ) => {
    const groupId = groupData.id;
    const newGroupData = new AssignmentGroupModel(_cloneDeep(groupData)).setMemberWeightAtIdx(
      idx,
      weight,
    ).assignmentGroup;

    dispatch(setAssignmentGroupMemberWeightAtIdx({ groupId, index: idx, weight }));
    await debouncedPutAssignmentGroup(groupId, newGroupData);
  };

  const getAssignmentsGroupList = async () => {
    const { groups: assignmentGroups } = await get_assignmentGroups();
    dispatch(loadAssignmentGroups(assignmentGroups));
    return assignmentGroups;
  };

  const createNewAssignmentGroup = async (assignmentGroup: AssignmentGroup, limit?: number) => {
    const assignmentGroupWithLimit = cloneDeep(assignmentGroup);
    if (limit !== undefined) {
      assignmentGroupWithLimit.members.forEach((member) => {
        member.limitValue = limit;
      });
    }
    const newAssignmentGroup = await post_assignmentGroup(_omit(assignmentGroupWithLimit, 'id'));
    sendBiEvent({ name: ACTIONS_EVENTS.assignmentGroupsAdd });
    dispatch(addAssignmentGroup(newAssignmentGroup));
  };

  const createNewAssignmentGroupWithMember = async (groupName: string, crmOrgUser: CrmOrgUser) => {
    const newAssignmentGroup = await post_assignmentGroup({
      name: groupName,
      description: '',
      members: [
        {
          userId: crmOrgUser.id,
          dateAdded: Date.now().toString(),
          name: crmOrgUser.name,
          weight: 1,
          membershipActive: true,
          userActive: true,
        },
      ],
      avatar: {
        emoji: getFirstAvailableEmoji(),
      },
    });
    sendBiEvent({ name: ACTIONS_EVENTS.assignmentGroupsAdd });
    dispatch(addAssignmentGroup(newAssignmentGroup));
    return newAssignmentGroup;
  };

  const duplicateAssignmentGroup = async (assignmentGroup: AssignmentGroup) =>
    createNewAssignmentGroup({
      ...assignmentGroup,
      name: `Copy of ${assignmentGroup.name}`,
      avatar: assignmentGroup.avatar?.imageUrl
        ? assignmentGroup.avatar
        : {
            emoji: getFirstAvailableEmoji(),
          },
    });

  const removeAssignmentGroup = async (groupId: string) => {
    dispatch(deleteAssignmentGroup({ groupId }));
    sendBiEvent({ name: ACTIONS_EVENTS.assignmentGroupsAdd });
    await delete_assignmentGroup(groupId);
  };

  const addUserToGroup = async (groupId: string, crmOrgUser: CrmOrgUser) => {
    const assignmentGroup = getAssignmentGroupById(groupId);
    if (assignmentGroup) {
      const newAssignmentGroup = new AssignmentGroupModel(_cloneDeep(assignmentGroup)).addMember({
        userId: crmOrgUser.id,
        dateAdded: Date.now().toString(),
        name: crmOrgUser.name,
        weight: 1,
        membershipActive: true,
        userActive: true,
      }).assignmentGroup;

      dispatch(setAssignmentGroupData({ groupId, assignmentGroup: newAssignmentGroup }));
      await debouncedPutAssignmentGroup(groupId, newAssignmentGroup);
    }
  };

  const removeUserFromGroup = async (groupId: string, userId: string) => {
    const assignmentGroup = getAssignmentGroupById(groupId);
    if (assignmentGroup) {
      const newAssignmentGroup = new AssignmentGroupModel(
        _cloneDeep(assignmentGroup),
      ).removeMemberById(userId).assignmentGroup;

      dispatch(setAssignmentGroupData({ groupId, assignmentGroup: newAssignmentGroup }));
      await debouncedPutAssignmentGroup(groupId, newAssignmentGroup);
    }
  };
  const clearAllAssignmentGroupsData = useCallback(
    () => dispatch(clearAllAssignmentGroups()),
    [dispatch],
  );
  return {
    getAssignmentGroupData,
    saveAssignmentGroupData,
    optimisticSaveWithDebounceAssignmentGroupData,
    deleteAssignmentGroupMemberAtIdx,
    patchAssignmentGroupMemberActiveStatusAtIdx,
    patchAssignmentGroupMemberWeightAtIdx,
    patchAssignmentGroupMemberLimitAtIdx,
    getAssignmentsGroupList,
    createNewAssignmentGroup,
    createNewAssignmentGroupWithMember,
    duplicateAssignmentGroup,
    removeAssignmentGroup,
    addUserToGroup,
    removeUserFromGroup,
    clearAllAssignmentGroupsData,
  };
};
