import SelectorEmptyState from '../SelectorEmptyState';

const FieldSelectorEmptyState = ({ onAddFieldClick }: { onAddFieldClick?: () => void }) => {
  return (
    <SelectorEmptyState
      callback={onAddFieldClick}
      title={`No results${
        onAddFieldClick ? '. You can create a new field directly from here' : ''
      }`}
      buttonText="Create field"
    />
  );
};

export default FieldSelectorEmptyState;
