import { Box } from '@mui/material';
import { PluginBantSvg, PluginDataManagementSvg, PluginMeddicSvg, PluginMeddpiccSvg } from '.';
import { PluginTypes } from '../../../../../../types/enums/PluginTypes';

export const IconForPluginType = ({
  pluginType,
  size,
  sx,
}: {
  pluginType: PluginTypes;
  size?: number;
  sx?: any;
}) => {
  const customSize = size
    ? {
        svg: {
          width: `${size}px`,
          height: `${size}px`,
        },
      }
    : undefined;
  const icon = () => {
    switch (pluginType) {
      case PluginTypes.BANT:
        return <PluginBantSvg />;
      case PluginTypes.MEDDIC:
        return <PluginMeddicSvg />;
      case PluginTypes.MEDDPICC:
        return <PluginMeddpiccSvg />;
      case PluginTypes.DATA_MANAGEMENT:
        return <PluginDataManagementSvg />;

      default:
        return null;
    }
  };
  return <Box sx={{ display: 'flex', ...customSize, ...sx }}>{icon()}</Box>;
};
