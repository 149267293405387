import { ReactNode } from 'react';
import { GET_STARTED_EVENTS } from '../../../services/events';
import { GetStartedActionId, ActionStatus } from '@server/get-started';

export enum GetStartedSectionId {
  DOCUMENTATION = 'DOCUMENTATION',
  CHANGE_FEED = 'CHANGE_FEED',
  ROLLUP = 'ROLLUP',
  AUTOMATIONS = 'AUTOMATIONS',
  SLACK = 'SLACK',
  DEDUPE = 'DEDUPE',
  ASSIGNMENTS = 'ASSIGNMENTS',
  VISUALIZE = 'VISUALIZE',
  OPTIMIZE = 'OPTIMIZE',
  SETUP = 'SETUP',
}

//This type is for the apis:
export type GetStartedDto = {
  [actionId in GetStartedActionId]: ActionStatus;
};
//////////////

//Following types are only relevant to the UI:
export enum GetStartedActionIdDerived {
  setupManagedPackage = 'setupManagedPackage',
  setupPermissionSet = 'setupPermissionSet',
}

export type GetStartedActionIdExtended = GetStartedActionId | GetStartedActionIdDerived;

export enum RequireSfConnectionType {
  NONE = 'NONE',
  ANY = 'ANY',
  PROD = 'PROD',
}

export type GetStartedAction = {
  id: GetStartedActionIdExtended;
  label?: string; //required only when there is more than 1 action
  text: ReactNode;
  img: ReactNode;
  requiredSfConnection: RequireSfConnectionType;
  requireFetched: boolean;
  requireManagedPackage: boolean;
  status: ActionStatus;
  biEvent?: GET_STARTED_EVENTS;
  tourUrl?: string;
  tourBiEvent?: GET_STARTED_EVENTS;
  buttonText?: string;
};

export type GetStartedSection = {
  id: GetStartedSectionId;
  adminOnly: boolean;
  label: string;
  icon: ReactNode;
  iconBgColor: string;
  actions: GetStartedAction[];
};

export type GetStartedBox = {
  name: string;
  text: string;
  navigateTo: string;
  biEvent: GET_STARTED_EVENTS;
  buttonText?: string;
  animationData?: any;
};
