import { Box } from '@mui/material';
import { Button, colors } from '@sweep-io/sweep-design';
import { ClampedText } from './ClampedText';

const BACKGROUND_HOVER_OPACITY = '14'; // 8% opacity in hex

interface TemplatesCardProps {
  cardContent: React.ReactNode;
  linkContent: React.ReactNode;
}

export const FunnelPreviewBaseCard = ({ cardContent, linkContent }: TemplatesCardProps) => {
  return (
    <Box display="flex" flexDirection="column" gap="12px">
      <Box
        height="120px"
        sx={{
          borderRadius: '4px',
          border: `1px solid ${colors.grey[300]}`,
          overflow: 'hidden',
          '&:hover': {
            border: `2px solid ${colors.blue[500]}`,
          },
        }}
      >
        {cardContent}
      </Box>
      <Box display="flex" height="40px">
        {linkContent}
      </Box>
    </Box>
  );
};

interface TemplateCardProps {
  linkText: string;
  buttonText: string;
  imageUrl: string;
  onLinkClick: () => void;
  onButtonClick: () => void;
  hideLinkDecoration?: boolean;
}

export const FunnelPreviewCard = ({
  linkText,
  buttonText,
  imageUrl,
  onLinkClick,
  onButtonClick,
  hideLinkDecoration,
}: TemplateCardProps) => {
  const endIconName = hideLinkDecoration ? undefined : 'ChevronRight';
  return (
    <FunnelPreviewBaseCard
      cardContent={
        <Box
          onClick={onButtonClick}
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            cursor: 'pointer',
            '.use-template-button-overlay': {
              display: 'none',
              width: '100%',
              height: '100%',
              backgroundColor: `${colors.blue[500]}${BACKGROUND_HOVER_OPACITY}`,
              justifyContent: 'center',
              alignItems: 'center',
            },
            '&:hover': {
              '.use-template-button-overlay': {
                display: 'flex',
              },
            },
            '&:hover .template-image': {
              filter: 'blur(3px)',
            },
          }}
        >
          <Box
            className="template-image"
            sx={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${imageUrl})`,
              position: 'absolute',
              backgroundSize: 'contain',
            }}
          />
          <Box className="use-template-button-overlay">
            <Button onClick={onButtonClick}>{buttonText}</Button>
          </Box>
        </Box>
      }
      linkContent={
        <Button
          variant="flat"
          type="secondary"
          size="large"
          endIconName={endIconName}
          onClick={onLinkClick}
        >
          <ClampedText text={linkText} />
        </Button>
      }
    />
  );
};
