import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { colors } from '@sweep-io/sweep-design';
import { NODE_WIDTH, NODE_HEIGHT } from '../../const';
import { getObjectTypeColor, objectTypeColorNames } from '../../helpers/getObjectTypeColor';

const nodeOpacity = '4D'; // 0.3 opacity in Hex
const defaultColors = getObjectTypeColor('__default');

const getColorsClassesForObjectTypes = (): any => {
  const allColorCss = objectTypeColorNames.map((objectType) => {
    const colors = getObjectTypeColor(objectType);
    const transparentZebraColor = `${colors.light}${nodeOpacity}`;
    return {
      [`&.node-${objectType}`]: {
        backgroundColor: colors.step,
      },
      [`&.nb-node.node-${objectType}`]: {
        backgroundImage: `repeating-linear-gradient(60deg, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 7.5px,${transparentZebraColor} 7.5px,${transparentZebraColor} 15px)`,
      },
    };
  });
  return allColorCss.reduce((prev, curr) => {
    prev = { ...prev, ...curr };
    return prev;
  }, {});
};

const colorClasses = getColorsClassesForObjectTypes();

export const NodeComponent = styled(Box)({
  width: `${NODE_WIDTH}px`,
  height: `${NODE_HEIGHT}px`,
  borderRadius: '4px',
  position: 'relative',
  cursor: 'pointer',
  '&.disable-step-click': {
    cursor: 'default',
  },
  justifyContent: 'space-between',
  padding: '2px', // to avoid flickering on hover
  '&.has-opacity': {
    opacity: 0.4,
  },
  '&.shadow': {
    boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1), inset 0px -2px 0px rgba(0, 0, 0, 0.15)',
  },
  background: defaultColors.step,

  '&.nb-node': {
    position: 'relative',
    backgroundImage: `repeating-linear-gradient(60deg, rgba(0,0,0,0) 0px, rgba(0,0,0,0) 7.5px,${`${defaultColors.light}${nodeOpacity}`} 7.5px,${`${defaultColors.light}${nodeOpacity}`} 15px)`,
  },

  ...colorClasses,

  '&.highlighted': {
    border: '2px solid ',
    borderColor: colors.blue[500],
    padding: 0,
    '&.shadow': {
      boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)',
    },
  },
  '&.invalid-position': {
    borderColor: colors.blush[500],
    background: colors.blush[500],
  },
  '&.highlighted.invalid-position': {
    borderColor: colors.blush[500],
  },
});
