import { useSweepApi } from '../sweep';
import { useCallback } from 'react';
import {
  CreateRollupDto,
  DeployRollupDto,
  RollupDto,
  UpdateRollupDto,
} from '../../components/pages/rollups/rollupTypes';

export const useRollupsApiFacade = () => {
  const sweepApi = useSweepApi();
  return {    
    get_rollup: useCallback(
      async (rollupId: string, crmOrgId?: string, readOnly: boolean = false) => {
        const response = await sweepApi.get(`/rollups/${rollupId}?crmOrgId=${crmOrgId}&includeReadOnly=${readOnly}`);
        return response.data as RollupDto;
      },
      [sweepApi],
    ),
    get_rollups: useCallback(
      async () => {
        const response = await sweepApi.get(`/rollups`);
        return response.data as RollupDto[];
      },
      [sweepApi],
    ),
    create_rollup: useCallback(
      async (createRollupDto: CreateRollupDto) => {
        const response = await sweepApi.post(`/rollups`, createRollupDto);
        return response.data as RollupDto;
      },
      [sweepApi],
    ),
    update_rollup: useCallback(
      async (rollupId: string, versionId: string, updateRollupDto: UpdateRollupDto) => {
        const response = await sweepApi.put(`/rollups/${rollupId}/${versionId}`, updateRollupDto);
        return response.data as RollupDto;
      },
      [sweepApi],
    ),
    deploy_rollup: useCallback(
      async (rollupId: string, versionId: string, deployRollupDto: DeployRollupDto) => {
        const response = await sweepApi.post(`/rollups/${rollupId}/${versionId}`, deployRollupDto);
        return response.data as RollupDto;
      },
      [sweepApi],
    ),
    delete_rollup: useCallback(
      async (rollupId: string) => {
        const response = await sweepApi.delete(`/rollups/${rollupId}`);
        return response.data as boolean;
      },
      [sweepApi],
    ),
    patch_rollup_admin_notification: useCallback(
      async (automationId: string, versionId: string, note: string) => {
        await sweepApi.patch(`/rollups/admin-notification/${automationId}/${versionId}`, {
          note,
        });
      },
      [sweepApi],
    ),
  };
};
