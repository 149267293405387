import { SearchSelect, SearchSelectItem } from '../../../../common/SearchSelect';
import { useMemo } from 'react';
import { HsPropertyTypes } from '../hubspot.types';
import { WorkflowWhenEventType, WorkflowWhenFiltersType } from '../types';
import { HubspotEventFieldIds, HubspotFilterAddSearchFieldIds } from './types';

export interface HsHardcodedOption {
  property: string;
  type: HsPropertyTypes;
  label: string;
}

interface HubspotHardcodedSelectorProps {
  onChange: (field: SearchSelectItem<HsHardcodedOption>) => void;
  value?: string;
  disabled?: boolean;
  eventType: WorkflowWhenEventType | WorkflowWhenFiltersType;
}

const optionsPerEventType: Record<
  WorkflowWhenEventType | WorkflowWhenFiltersType,
  HsHardcodedOption[]
> = {
  [WorkflowWhenEventType.AD_INTERACTION]: [
    // {
    //   property: 'hs_ad_id',
    //   type: HsPropertyTypes.ENUMERATION,
    //   label: 'Ad',
    // },
    // {
    //   property: 'hs_ad_campaign_id',
    //   type: HsPropertyTypes.ENUMERATION,
    //   label: 'Ad Campaign',
    // },
    // {
    //   property: 'hs_ad_group_id',
    //   type: HsPropertyTypes.ENUMERATION,
    //   label: 'Ad Group',
    // },
    {
      property: HubspotEventFieldIds.AD_NETWORK,
      type: HsPropertyTypes.ENUMERATION,
      label: 'Ad Network',
    },
    {
      property: HubspotEventFieldIds.AD_INTERACTION_TYPE,
      type: HsPropertyTypes.ENUMERATION,
      label: 'Interaction Type',
    },
    {
      property: HubspotEventFieldIds.AD_NETWORK_PLACEMENT,
      type: HsPropertyTypes.ENUMERATION,
      label: 'Network Placement',
    },
  ],
  [WorkflowWhenEventType.FORM_SUBMITTED]: [
    { property: 'hs_base_url', type: HsPropertyTypes.STRING, label: 'Base URL' },
    { property: 'hs_country', type: HsPropertyTypes.STRING, label: 'Country' },
    { property: 'hs_form_id', type: HsPropertyTypes.ENUMERATION, label: 'Form Name' },
    { property: 'hs_content_id', type: HsPropertyTypes.ENUMERATION, label: 'Page Name' },
    { property: 'hs_url_domain', type: HsPropertyTypes.STRING, label: 'Landing Page URL' },
  ],
  [WorkflowWhenEventType.EMAIL_REPLY]: [
    {
      property: 'hs_email_campaign_group_id',
      type: HsPropertyTypes.ENUMERATION,
      label: 'Email Campaign',
    },
  ],
  [WorkflowWhenFiltersType.ADS_SEARCH]: [
    {
      property: HubspotFilterAddSearchFieldIds.FACEBOOK_AD_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'Facebook ad name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.FACEBOOK_AD_GROUP_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'Facebook ad group name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.FACEBOOK_CAMPAIGN_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'Facebook campaign name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.GOOGLE_AD_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'Google ad name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.GOOGLE_AD_GROUP_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'Google ad group name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.GOOGLE_CAMPAIGN_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'Google campaign name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.LINKEDIN_AD_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'LinkedIn ad name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.LINKEDIN_AD_GROUP_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'LinkedIn ad group name',
    },
    {
      property: HubspotFilterAddSearchFieldIds.LINKEDIN_CAMPAIGN_NAME,
      type: HsPropertyTypes.MULTISTRING,
      label: 'LinkedIn campaign name',
    },
  ],
  [WorkflowWhenFiltersType.PROPERTY]: [
    // These options are not hardcoded
  ],
};

export const HubspotHardcodedSelector = ({
  onChange,
  value,
  disabled,
  eventType,
}: HubspotHardcodedSelectorProps) => {
  const items: SearchSelectItem<HsHardcodedOption>[] = useMemo(() => {
    const items = optionsPerEventType[eventType].map((option) => ({
      label: option.label,
      value: option.property,
      data: option,
    }));
    return items;
  }, [eventType]);

  return (
    <SearchSelect
      items={items}
      FormControlProps={{ fullWidth: true }}
      onChange={onChange}
      SelectProps={{
        value,
      }}
      removeBorders
      disabled={disabled}
    />
  );
};
