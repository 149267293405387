import { ReactNode, useEffect, useState } from 'react';
import { Drawer } from '@mui/material';
import { DASHBOARD_HEADER_HEIGHT_PX } from '../../constants';

const COLLAPSED_WIDTH = 420;

const RightPanel = ({
  children,
  isOpened,
  isExpanded,
}: {
  children: ReactNode;
  isOpened: boolean;
  isExpanded: boolean;
}) => {
  const sideMenuRef = window.document.getElementById('sidebar-menu');
  const [sideMenuWidth, setSideMenuWidth] = useState(0);
  const width = isExpanded ? `calc(100vw - ${sideMenuWidth}px + 1px)` : COLLAPSED_WIDTH + 'px';

  useEffect(() => {
    if (sideMenuRef) {
      const observer = new ResizeObserver(() => {
        if (sideMenuRef.clientWidth) {
          setSideMenuWidth(sideMenuRef.clientWidth);
        }
      });
      observer.observe(sideMenuRef);
    }
  }, [sideMenuRef]);

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={isOpened}
      PaperProps={{
        sx: {
          top: DASHBOARD_HEADER_HEIGHT_PX,
          width,
          boxShadow: isExpanded ? 0 : 1,
          height: `calc(100vh - ${DASHBOARD_HEADER_HEIGHT_PX})`,
          transition: 'all .9s ease!important', //important is needed to override inline styling from MUI
          zIndex: 102, //Careful when changing this value - make sure it is above the Configuration Canvas Panel
          overflow: 'hidden',
        },
      }}
    >
      {children}
    </Drawer>
  );
};

export default RightPanel;
