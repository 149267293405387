import { useCallback, useContext, useMemo } from 'react';
import _range from 'lodash/range';
import { SweepCanvasNodePosition } from './helpers/calculateHandlePositionsBasedOnCoords';
import { SweepCanvasNode, CanvasEdgeConnectionType, SweepCanvasEdge } from './canvasTypes';
import { SweepCanvasPropsCtx } from './internal-context/SweepCanvasPropsCtx';

export const useCalculateEdgeDetours = ({
  internalSweepNodes,
  internalSweepEdges,
}: {
  internalSweepNodes: SweepCanvasNode[];
  internalSweepEdges: SweepCanvasEdge[];
}) => {
  const { sweepNodes, sweepGroups } = useContext(SweepCanvasPropsCtx);
  const getAbsolutePositionForNode = useCallback(
    (node: SweepCanvasNode): SweepCanvasNodePosition => {
      const parentPosition: SweepCanvasNodePosition = sweepGroups.find(
        (group) => group.id === node.parentId,
      )?.position || { column: 0, row: 0 };
      return {
        column: node.position.column + parentPosition.column,
        row: node.position.row + parentPosition.row,
      };
    },
    [sweepGroups],
  );

  const sweepNodesPositionsMap = useMemo(() => {
    const _map: { [pos: string]: string } = {};
    sweepNodes.forEach((node) => {
      const { column, row } = getAbsolutePositionForNode(node);
      _map[`${row}|${column}`] = node.id;
    });
    return _map;
  }, [getAbsolutePositionForNode, sweepNodes]);

  const newSweepEdges = useMemo(() => {
    const hasHorizontalNodesBetween = (
      position1: SweepCanvasNodePosition,
      position2: SweepCanvasNodePosition,
      nodeId: string,
    ) => {
      const positionsNotOnTheSameRow = position1.row !== position2.row;

      if (positionsNotOnTheSameRow) {
        return false;
      }

      const columnDistance = Math.abs(position1.column - position2.column);
      const areSiblings = columnDistance < 2;

      if (areSiblings) {
        return false;
      }
      const cols = _range(
        Math.min(position1.column, position2.column) + 1,
        Math.max(position1.column, position2.column),
      );

      let ret = false;
      cols.forEach((col) => {
        const _id = sweepNodesPositionsMap[`${position1.row}|${col}`];
        if (_id && _id !== nodeId) {
          ret = true;
        }
      });
      return ret;
    };

    const hasVerticalNodesBetween = (
      position1: SweepCanvasNodePosition,
      position2: SweepCanvasNodePosition,
      nodeId: string,
    ) => {
      const positionsNotOnTheSameColumn = position1.column !== position2.column;

      if (positionsNotOnTheSameColumn) {
        return false;
      }

      const rowsDistance = Math.abs(position1.row - position2.row);
      const areSiblings = rowsDistance < 2;

      if (areSiblings) {
        return false;
      }
      const possibleBetweenPositions = _range(
        Math.min(position1.row, position2.row) + 1,
        Math.max(position1.row, position2.row),
      );

      const hasNodesInRows = possibleBetweenPositions.find((row) => {
        const _id = sweepNodesPositionsMap[`${row}|${position1.column}`];
        return _id && _id !== nodeId;
      });
      return hasNodesInRows;
    };

    const horizontalDetourIndexMap: { [row: string]: number } = {};
    const verticalDetourIndexMap: { [col: string]: number } = {};

    const _sweepEdges = internalSweepEdges.map((sweepEdge) => {
      const reactFlowSourceNode = internalSweepNodes.find((node) => node.id === sweepEdge.source);
      const reactFlowTargetNode = internalSweepNodes.find((node) => node.id === sweepEdge.target);

      if (reactFlowSourceNode?.position && reactFlowTargetNode?.position) {
        const sourceNodeAbsolutePosition = getAbsolutePositionForNode(reactFlowSourceNode);
        const targetNodeAbsolutePosition = getAbsolutePositionForNode(reactFlowTargetNode);

        const _hasHorizontalNodesBetween = hasHorizontalNodesBetween(
          sourceNodeAbsolutePosition,
          targetNodeAbsolutePosition,
          reactFlowSourceNode.id,
        );

        if (_hasHorizontalNodesBetween) {
          const row = sourceNodeAbsolutePosition.row.toString();
          const idx =
            horizontalDetourIndexMap[row] === undefined ? -1 : horizontalDetourIndexMap[row];

          horizontalDetourIndexMap[row] = idx + 1;
          return {
            ...sweepEdge,
            data: {
              ...sweepEdge.data,
              connectionType: CanvasEdgeConnectionType.HorizontalDetour,
              connectionIdx: horizontalDetourIndexMap[row],
            },
          };
        }

        const _hasVerticalNodesBetween = hasVerticalNodesBetween(
          sourceNodeAbsolutePosition,
          targetNodeAbsolutePosition,
          reactFlowSourceNode.id,
        );

        if (_hasVerticalNodesBetween) {
          const column = sourceNodeAbsolutePosition.column.toString();
          const idx =
            verticalDetourIndexMap[column] === undefined ? -1 : verticalDetourIndexMap[column];

          verticalDetourIndexMap[column] = idx + 1;
          return {
            ...sweepEdge,
            data: {
              ...sweepEdge.data,
              connectionType: CanvasEdgeConnectionType.VerticalDetour,
              connectionIdx: verticalDetourIndexMap[column],
            },
          };
        }

        return sweepEdge;
      }
      return sweepEdge;
    });
    return _sweepEdges;
  }, [getAbsolutePositionForNode, internalSweepEdges, internalSweepNodes, sweepNodesPositionsMap]);

  return newSweepEdges;
};
