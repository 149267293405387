import { FunnelSelector } from '../FunnelSelector';
import { StepSelector } from '../StepSelector';

interface TriggerStageEnterProps {
  crmOrgId: string;
  automationJson: DeepPartial<AutomationStructureNew>;
  readonly?: boolean;
  onChange: (stageDetails: AutomationStageDetails, _objectName: string | undefined) => any;
  onFunnelChange: (funnelId: string) => any;
  funnels?: ShortFunnel[];
  objectName: ObjectTypeValues;
}

export const TriggerStageEnter = ({
  crmOrgId,
  automationJson,
  readonly,
  onChange,
  objectName,
  onFunnelChange,
  funnels,
}: TriggerStageEnterProps) => {
  return (
    <>
      <FunnelSelector
        onChange={onFunnelChange}
        selectedFunnel={automationJson.automationDetails?.funnelId ?? ''}
        funnels={funnels}
        disabled={readonly}
      />
      {funnels && funnels.length > 0 && (
        <StepSelector
          crmOrgId={crmOrgId}
          stageDetails={automationJson.automationDetails?.automationParams?.stageEnter?.toStage}
          onChange={(stageDetails, _objectName) => {
            onChange(stageDetails, _objectName);
          }}
          stages={
            funnels.find((el) => el.id === automationJson.automationDetails?.funnelId)
              ?.funnelDetails?.stages ?? []
          }
          disabled={!automationJson.automationDetails?.funnelId || readonly}
          objectName={objectName}
        />
      )}
    </>
  );
};
