import { Box } from '@mui/material';
import { ReadOnlyAutomationAction } from '@server/read-only-elements.types';
import { Fragment, ReactNode } from 'react';
import { border } from '../consts';
import { HeaderWithIconOrNumber } from './atoms/HeaderWithIconOrNumber';
import { ReadOnlyAutomationActionWrapper, StartAdornmentMap } from './ReadOnlyAutomationWrapper';
import { ConfigurationTypesWithIcon } from '../../../types';
import { Subtext } from './atoms/Subtext';

interface SubsectionWithHeaderProps {
  HeaderIcon?: ReactNode;
  headerText?: string;
  readonlyAutomationActions?: (string | ReadOnlyAutomationAction)[];
  elementType: ConfigurationTypesWithIcon;
  noConditionExternalBorder?: boolean;
  renderStartAdornmentsByMap?: StartAdornmentMap;
}

export const SubsectionWrapper = ({
  headerText,
  HeaderIcon,
  readonlyAutomationActions,
  elementType,
  noConditionExternalBorder,
  renderStartAdornmentsByMap,
}: SubsectionWithHeaderProps) => {
  if (!readonlyAutomationActions || !readonlyAutomationActions.length) {
    return <Fragment />;
  }

  const subsectionLvl = 0;

  return (
    <Box position="relative" pl={3}>
      {headerText && (
        <Box pb={1}>
          <HeaderWithIconOrNumber HeaderIcon={HeaderIcon} headerText={headerText} />
        </Box>
      )}

      <Box
        position="relative"
        sx={{ ...(headerText && !noConditionExternalBorder ? { ...border } : {}) }}
      >
        <Box>
          {readonlyAutomationActions?.map((detail, idx) => {
            if (typeof detail === 'string') {
              return <Subtext key={detail} text={detail} />;
            }

            return (
              <ReadOnlyAutomationActionWrapper
                key={'wrapper-' + idx}
                readOnlyAutomationAction={detail}
                elementType={elementType}
                number={idx + 1}
                noConditionExternalBorder={noConditionExternalBorder}
                renderStartAdornmentsByMap={renderStartAdornmentsByMap}
                subsectionLvl={subsectionLvl}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
